import React, { memo, useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { StatusCardWrapper } from "../../../../../newUI/StatusCardWrapper/StatusCardWrapper";
import { TCustomerAlign } from "../../../../../../app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import cls from "./CustomerAlignCard.module.scss";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { AddFile, CheckSVG, NotApproveIcon } from "../../../../../newUI/SVG";
import {
  deleteByTaskId,
  fetchProjectProcessDocumentations,
} from "../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { projectProcessTaskBackToWork } from "../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessTaskCancel";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalCheck } from "./ModalCheck/ModalCheck";
import {
  getProjectProcessCheckAlignIsLoad,
  getProjectProcessDocumentsCompleteCheck,
  getProjectProcessEditIsLoad,
  getProjectProcessPreliminaryByTask,
} from "../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { ModalEdit } from "./ModalEdit/ModalEdit";
import { iconBtnSx } from "../../../../../../styles/restyle";
import { trimRubPennies } from "../../../../../../utils/trimNumber";
import { fetchProjectProcessViewDuplicate } from "../../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { ModalPreminaryByTask } from "./ModalPreminaryByTask/ModalPreminaryByTask";
import { NdsInfo } from "../../../Info/PayBlock/PayBlock";

export type TCurrentTab = "comment" | "status" | "file" | "check" | null;

export const warningMessages = {
  DELETE_TASK: "Вы уверены, что хотите удалить данную задачу?",
  TASK_BACK_TO_WORK:
    "Вы уверены, что хотите вернуть на доработку данную задачу?",
};

export const CustomerAlignCard: React.FC<
  TCustomerAlign & { request?: boolean }
> = memo(
  ({
    status,
    executorName,
    date,
    comment,
    files,
    title,
    header,
    can,
    checks,
    paidOut,
    content,
    id,
    remarks,
    setModalData,
  }) => {
    const dispatch = useAppDispatch();

    const infoId = useAppSelector(getProjectProcessInfoId);
    const infoData = useAppSelector(getProjectProcessInfoData);
    const isLoadCheckAlign = useAppSelector(getProjectProcessCheckAlignIsLoad);
    const isLoadEdit = useAppSelector(getProjectProcessEditIsLoad);
    const isLoadPreliminaryByTask = useAppSelector(
      getProjectProcessPreliminaryByTask
    );
    const completeCheck = useAppSelector(
      getProjectProcessDocumentsCompleteCheck
    );

    const [modalPreliminaryByTask, setModalPreliminaryByTask] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    const checksToArray = Object.values(checks);

    const [currentTab, setCurrentTab] = useState<TCurrentTab>(null);

    const handleToggleComments = () => {
      if (!setModalData) {
        return;
      }

      setModalData({
        id,
        name: "",
        open: true,
      });
    };

    const handleToggleFiles = () => {
      setCurrentTab(currentTab ? null : "file");
    };

    const handleToggleChecks = () => {
      setCurrentTab(currentTab ? null : "check");
    };

    const closeModalEdit = useCallback(() => {
      setModalEdit(false);
    }, []);

    const closeModalPreliminaryByTask = useCallback(() => {
      setModalPreliminaryByTask(false);
    }, []);

    const openModalEdit = () => {
      setModalEdit(true);
    };

    const openPreliminaryModal = () => {
      setModalPreliminaryByTask(true);
    };

    const handleDeleteTask = async () => {
      if (!window.confirm(warningMessages.DELETE_TASK)) {
        return;
      }

      await dispatch(deleteByTaskId(id));

      if (infoId) {
        dispatch(fetchProjectProcessDocumentations(infoId));
        dispatch(fetchProjectProcessViewDuplicate(infoId));
      }
    };

    const handleTaskBackToWork = async () => {
      if (!window.confirm(warningMessages.TASK_BACK_TO_WORK)) {
        return;
      }

      await dispatch(projectProcessTaskBackToWork(id));

      if (infoId) {
        dispatch(fetchProjectProcessDocumentations(infoId));
        dispatch(fetchProjectProcessViewDuplicate(infoId));
      }
    };

    const view = {
      file: currentTab === "file",
      check: currentTab === "check",
      comment: currentTab === "comment",
      status: currentTab === "status",
    };

    useEffect(() => {
      if (completeCheck) {
        setModalCheck(false);
      }
    }, [completeCheck]);

    const Info = () => (
      <>
        <div className={cls.content_header}>
          <h1 title={title}>{header}</h1>
          <p>{executorName}</p>
          <div className={cls.date}>
            <p>Выдано</p>
            <span dangerouslySetInnerHTML={{ __html: date }} />
          </div>
        </div>
        <div className={cls.toggleList}>
          <div className={cls.toggle} onClick={handleToggleComments}>
            <p>Комментарии</p>
            <ToggleAccordion toggle={view.comment} />
          </div>
          {files && (
            <div className={cls.toggle} onClick={handleToggleFiles}>
              <p>Вложения</p>
              <ToggleAccordion toggle={view.file} />
            </div>
          )}
          {checksToArray.length > 0 && (
            <div className={cls.toggle} onClick={handleToggleChecks}>
              <p>Проверки</p>
              <ToggleAccordion toggle={view.check} />
            </div>
          )}
        </div>
        {paidOut?.price && (
          <div className={cls.price}>
            <NdsInfo executorNds={infoData?.executor_nds} />
            <div className={cls.price_rub}>
              <p>Оплачено</p>
              {paidOut.price && <span>{trimRubPennies(paidOut.price)}</span>}
            </div>
          </div>
        )}
      </>
    );

    const Modals = () => (
      <>
        {can?.check && (
          <Modal
            active={modalCheck}
            setActive={setModalCheck}
            isLoad={isLoadCheckAlign}
          >
            <ModalCheck
              comment={comment}
              files={files}
              name={header}
              taskId={id}
            />
          </Modal>
        )}
        {can.update && (
          <Modal
            active={modalEdit}
            setActive={setModalEdit}
            isLoad={isLoadEdit}
          >
            <ModalEdit
              closeModal={closeModalEdit}
              comment={comment}
              content={content}
              price={paidOut.price}
              files={files}
              name={header}
              taskId={id}
            />
          </Modal>
        )}
        {can.execute && (
          <Modal
            active={modalPreliminaryByTask}
            setActive={setModalPreliminaryByTask}
            isLoad={isLoadPreliminaryByTask}
          >
            <ModalPreminaryByTask
              id={id}
              content={content}
              comment={comment}
              header={header}
              closeModal={closeModalPreliminaryByTask}
            />
          </Modal>
        )}
      </>
    );

    const Controls = () => (
      <>
        {can.update && (
          <IconButton
            sx={iconBtnSx}
            title="Редактировать"
            onClick={openModalEdit}
          >
            <PencilSquare width="24" height="24" />
          </IconButton>
        )}
        {can.execute && (
          <IconButton
            sx={iconBtnSx}
            title="Выдать"
            onClick={openPreliminaryModal}
          >
            <AddFile width="24" height="24" />
          </IconButton>
        )}
        {can.check && (
          <IconButton
            sx={iconBtnSx}
            title="Проверить"
            onClick={() => setModalCheck(true)}
          >
            <CheckSVG width="24" height="24" />
          </IconButton>
        )}
        {can.backToWork && (
          <IconButton
            sx={iconBtnSx}
            title="Вернуть на доработку"
            onClick={handleTaskBackToWork}
          >
            <NotApproveIcon width="24" heigth="24" />
          </IconButton>
        )}
        {can.delete && (
          <IconButton sx={iconBtnSx} title="Удалить" onClick={handleDeleteTask}>
            <Trash width="24" height="24" />
          </IconButton>
        )}
      </>
    );

    return (
      <div>
        <StatusCardWrapper
          status={status}
          date={status.date}
          comment={comment}
          color={status.color}
          viewStatus={!currentTab}
          viewComment={view.comment}
          checks={checksToArray}
          viewChecks={view.check}
          classname={cls.card}
          files={files}
          viewFiles={view.file}
          downloadAllLink={
            files.length > 0 ? `/task/download-files?id=${id}` : undefined
          }
          remarks={remarks}
        >
          <div className={cls.content}>
            <Info />
            <div className={cls.btn}>
              <Modals />
              <Controls />
            </div>
          </div>
        </StatusCardWrapper>
      </div>
    );
  }
);
