import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Visibility, VisibilityOff, DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import urlParser from "url-parse";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../../types/other/components/componentPropsType";
import { isClientRoute } from "../../../../../../app/services/clientAppRoutes/clientAppRoutes";
import { request } from "../../../../../../app/services/api/requestHandler";
import { ApiPostReadToggle } from "../../../../../../app/services/api/notices/notices";

export type NoticeItemType = {
  id: number;
  content: string;
  date_create: string;
  date_seen: string | null;
  isSeen: boolean;
};

export type NoticeItemProps = ComponentPropsType<
  NoticeItemType & {
    toggleHandler: (toggle: string, id: number) => void;
    deleteHandler: (id: number) => void;
  }
>;

export const NoticeItem: React.FC<NoticeItemProps> = ({
  id,
  content,
  date_create,
  isSeen,
  theme = "dark",
  toggleHandler = () => {},
  deleteHandler = () => {},
}) => {
  const navigate = useNavigate();
  const [date, time] = date_create.split(" ");
  const toggle = isSeen ? "unread" : "read";
  const isSeenBackground =
    theme === "old" ? "#26313a" : "rgba(145, 145, 145, 0.2)";
  const onCheckRoute = (event: any) => {
    if (event.target.tagName.toLowerCase() === "a") {
      const link = event.target.href;
      const parsedLink = urlParser(link);
      request(
        ApiPostReadToggle("read", id),
        (data, headersValue, headers, status) => {
          if (status === 200) {
            if (!isClientRoute(link)) {
              window.location.href = link;
            } else {
              navigate(`${parsedLink.pathname}${parsedLink.query}`);
            }
          }
        }
      )();
    }
  };

  return (
    <div
      className={classNames(style.noticeItemContainer, style[theme])}
      style={{ background: isSeen ? "" : isSeenBackground }}
    >
      <div className={style.noticeWrapper}>
        <div className={style.date}>
          {date} {time}
        </div>
        <div
          className={classNames(style.content, style[theme])}
          onClick={onCheckRoute}
        >
          {ReactHtmlParser(content)}
        </div>
      </div>
      <div className={style.controlsWrapper}>
        <IconButton
          className={classNames(style.isSeen, style[theme])}
          onClick={() => toggleHandler(toggle, id)}
        >
          {isSeen ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        <IconButton
          className={classNames(style.delete, style[theme])}
          onClick={() => deleteHandler(id)}
        >
          <DeleteOutline />
        </IconButton>
      </div>
    </div>
  );
};
