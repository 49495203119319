import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessMaterialCreate } from "../../../../services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";
import { fetchProjectProcessView } from "../../Info/services/fetchProjectProcessView";
import { fetchProcessMaterial } from "./fetchProcessMaterial";

export const fetchProcessMaterialCreate = createAsyncThunk<
  boolean,
  {
    processId: number;
    formData: any;
  }
>(
  "materialProcess/fetchProcessMaterialCreate",
  async ({ processId, formData }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessMaterialCreate(processId, formData),
      (data) => {
        dispatch(fetchProjectProcessView(processId));
        dispatch(fetchProcessMaterial(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
