import { messages } from "../../../../../../../../utils/SimplifiedYup";
import { AT_LEAST_ONE_CHECKED_REQUIRED } from "../../model/constants";
import { TFieldValues, TProcessesPriceLimits } from "../../model/types";

export const checkPrices = (
  processes: TFieldValues["processes"],
  processesPriceLimits: TProcessesPriceLimits,
  setError: any,
  clearErrors: any
) => {
  const errorProcesses = new Set<number>();
  let containsChecked = false;

  Object.keys(processes.price).forEach((id) => {
    const processId = Number(id);

    const isChecked = processes.checked[id];
    const price = Number(processes.price[id]);

    if (isChecked) {
      containsChecked = true;

      if (price < processesPriceLimits[id].minPrice) {
        setError(`processes.price.${id}`, {
          message: messages.minPrice(null, processesPriceLimits[id].minPrice),
        });
        errorProcesses.add(processId);
      }

      if (price > processesPriceLimits[id].maxPrice) {
        setError(`processes.price.${id}`, {
          message: messages.maxPrice(null, processesPriceLimits[id].maxPrice),
        });
        errorProcesses.add(processId);
      }

      if (Number.isNaN(price)) {
        setError(`processes.price.${id}`, {
          message: messages.shouldBeNumber,
        });
      }
    } else {
      clearErrors(`processes.price.${id}`);
      errorProcesses.delete(processId);
    }
  });

  if (!containsChecked) {
    setError(`atLeastOneProcessRequired`, {
      message: AT_LEAST_ONE_CHECKED_REQUIRED,
    });
  }

  return !errorProcesses.size && containsChecked;
};
