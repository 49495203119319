import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExpertiseRemarksTypes } from "../types/expertiseRemarksTypes";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseRemarks } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

export const fetchExpertiseRemarksFilter = createAsyncThunk<
  ExpertiseRemarksTypes,
  {
    processId: number;
    partGroupId: number | string;
    code: string;
    status: number | string;
  }
>(
  "expertise/fetchExpertiseRemarksFilter",
  async ({ processId, partGroupId, code, status }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseRemarks(processId, partGroupId, code, status),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
