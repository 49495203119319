import { RootState } from "../../../../store";

export const getProjectProcessStoppedIsLoad = (state: RootState) =>
  state.projectProcessView.headerBtn.isLoadGetStopped;

export const getProjectProcessStopped = (state: RootState) =>
  state.projectProcessView.headerBtn.stopped;

export const getProjectProcessTaskCompleteIsLoad = (state: RootState) =>
  state.projectProcessView.headerBtn.isLoadTaskComplete;
