import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import useProfile from "../../../../app/feature/profileReducer/useProfile";
import { Wrapper } from "../../../UI/templates";
import TitlePanel from "../../../newUI/TitlePanel/TitlePanel";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import TabsPanel, { areFormsEqual } from "../../../newUI/TabsPanel/TabsPanel";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { getUserId, userIsAuth } from "../../../../app/services/auth/auth";
import { appErrorHandler } from "../../../../app/services/api/errorHandlers";
import { RedirectToExternal } from "../../../features/RedirectToExternal/RedirectToExternal";
import {
  AwardSVG,
  EducationSVG,
  ExperienceSVG,
  InfoSquareSVG,
  OrganisationSVG,
  PencilEditSVG,
  PortfolioSVG,
  WalletSVG,
  KeySVG,
  EnvelopeSVG,
} from "../../../newUI/SVG";
import externalStyle from "../../Organisation/style.module.scss";
import Info from "./Info/Info";
import Experience from "./Experience/Experience";
import Education from "./Education/Education";
import Rewards from "./Rewards/Rewards";
import Portfolio from "./Portfolio/Portfolio";
import Payments from "./Payments/Payments";
import Security from "./Security/Security";
import Notifications from "./Notifications/Notifications";
import Organisation from "./Organisation/Organisation";
import {
  getModalVisibility,
  setNameVisible,
  setVisible,
} from "../../../../app/feature/modalController/modalVisibility";
import { UnsavedType } from "../../Project/types";
import ProjectView from "../../Project/View";
import PersonalDataChange from "./PersonalDataChange/PersonalDataChange";
import { ISkillForSelect } from "../../../../app/feature/profileReducer/types";
import { palette } from "../../../../styles/restyle";
import { useAppSelector } from "../../../../app/store";
import { getForms } from "../../../../app/feature/unsaved/unsavedController";
import useTabs from "../../../../components/hooks/useTabs";

const INFO = "info";
const sx = { color: "white" };

const tabs = [
  {
    title: "О себе",
    url: "info",
    icon: <InfoSquareSVG />,
  },
  {
    title: "Стаж",
    url: "experience",
    icon: <ExperienceSVG />,
  },
  {
    title: "Образование",
    url: "education",
    icon: <EducationSVG />,
  },
  {
    title: "Портфолио",
    url: "portfolio",
    icon: <PortfolioSVG />,
  },
  {
    title: "Награды",
    url: "awards",
    icon: <AwardSVG />,
  },
  {
    title: "Для оплаты",
    url: "payments",
    icon: <WalletSVG />,
  },
  {
    title: "Организация",
    url: "firm",
    icon: <OrganisationSVG />,
  },
  {
    title: "Уведомления",
    url: "notifications",
    icon: <EnvelopeSVG />,
  },
  {
    title: "Безопасность",
    url: "security",
    icon: <KeySVG />,
  },
];

const initialUnsaved: UnsavedType = {
  info: false,
  experience: false,
  education: false,
  portfolio: false,
  awards: false,
  payments: false,
  firm: false,
  notifications: false,
  security: false,
};

const ProfileEditing: React.FC = () => {
  const isAuth = userIsAuth();
  const params = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();
  const id = params.id ?? "";

  const { inbound, outbound } = useAppSelector(getForms);

  const isCurrentUser = getUserId() === Number(id);
  const [editing, setEditing] = useState(false);

  const [unsaved, setUnsaved] = useState<UnsavedType>(initialUnsaved);
  const [toTab, setToTab] = useState<string>("");

  const [currentTab, setCurrentTab] = useState("info");

  const {
    canEdit,
    name,
    surname,
    isLoading,
    photo,
    skills,
    skillsList,
    loadProfile,
    loadRegions,
    loadRoles,
    loadCountries,
    loadTimeZones,
    loadSkillsList,
  } = useProfile();

  const parseSkills = () => {
    const ids = skills.map((item) => item.id);
    return skillsList.filter((item) => ids.includes(item.id));
  };

  const [currentSkills, setCurrentSkills] = useState<ISkillForSelect[]>([]);

  const { modalName } = useSelector(getModalVisibility);

  const dispatch = useDispatch();

  useTabs(currentTab, setCurrentTab, INFO);

  useEffect(() => {
    if (Number.isNaN(id)) {
      navigate("/");
    }

    loadProfile(id)
      .unwrap()
      .then()
      .catch((err) => {
        appErrorHandler(dispatch)(err);
      });
    loadRegions();
    loadRoles();
    loadCountries();
    loadTimeZones();
    loadSkillsList();
  }, [id]);

  useEffect(() => {
    const mySkills = parseSkills();
    setCurrentSkills(mySkills);
  }, [skillsList, skills]);

  useEffect(() => {
    if (modalName) {
      setToTab(modalName);
    }
  }, [modalName]);

  const components = (
    <>
      {currentTab === "info" && !isLoading && (
        <Info
          currentSkills={currentSkills}
          setCurrentSkills={setCurrentSkills}
          unsaved={unsaved}
          setUnsaved={setUnsaved}
          userId={id}
        />
      )}
      {currentTab === "experience" && (
        <Experience setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "education" && (
        <Education setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "portfolio" && (
        <Portfolio setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "awards" && (
        <Rewards setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "payments" && (
        <Payments setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "firm" && <Organisation />}
      {currentTab === "notifications" && (
        <Notifications setUnsaved={setUnsaved} userId={id} />
      )}
      {currentTab === "security" && (
        <Security setUnsaved={setUnsaved} userId={id} />
      )}
    </>
  );

  const handleModalOnSwitch = (tab: string): boolean => {
    if (currentTab === tab) {
      return true;
    }

    if (unsaved[currentTab] || !areFormsEqual(inbound, outbound)) {
      setToTab(tab);
      dispatch(setVisible());
      return false;
    }

    return true;
  };

  const handleRequest = () => {
    dispatch(setNameVisible("dataChange"));
  };

  const isMobile = useIsMobile(800);

  const userId = getUserId();
  if (userId !== Number(params.id)) {
    window.location.href = `/user/${params.id}`;
  }

  return (
    <>
      {isAuth ? (
        <div className={externalStyle.wrapper}>
          <Wrapper>
            <TitlePanel
              isContentLoad={isLoading}
              setEditing={setEditing}
              primaryText={`${name} ${surname}`}
              link={`/user/${userId}?tab=info`}
              secondaryText={""}
              photo={`${photo}&${new Date().valueOf()}`}
              small
            >
              {Boolean(isCurrentUser && !canEdit && !isLoading) && (
                <div className={externalStyle.tableControls}>
                  {isMobile ? (
                    <IconButton sx={sx} onClick={handleRequest}>
                      <PencilEditSVG />
                    </IconButton>
                  ) : (
                    <CustomButton
                      width={298}
                      disabled={editing}
                      onClick={handleRequest}
                      background={palette.blue}
                      className={classNames(
                        externalStyle.button,
                        externalStyle.invite
                      )}
                    >
                      Запрос на измение реквизитов
                    </CustomButton>
                  )}
                </div>
              )}
            </TitlePanel>
            <div className={externalStyle.contents}>
              <TabsPanel
                isContentLoad={isLoading}
                setEditing={setEditing}
                tabsInfo={tabs}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                components={components}
                onSwitching={handleModalOnSwitch}
              />
            </div>
            {modalName === "dataChange" && <PersonalDataChange />}
            {modalName === "" && (
              <ProjectView toTab={toTab} setCurrentTab={setCurrentTab} />
            )}
          </Wrapper>
        </div>
      ) : (
        <RedirectToExternal to={"/site/login"} />
      )}
    </>
  );
};

export default ProfileEditing;
