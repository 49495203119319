import { CreateFreeTaskForm } from "./CreateFreeTask";
import { EditFreeTaskForm } from "./EditFreeTask";
import { CheckFreeTaskForm } from "./CheckFreeTask";
import { ViewTaskInfo } from "./ViewTaskInfo";

const KanbanModals = {
  CreateFreeTaskForm,
  CheckFreeTaskForm,
  EditFreeTaskForm,
  ViewTaskInfo,
};

export default KanbanModals;
