import React, { ReactNode } from "react";
import classNames from "classnames";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IconButton } from "@mui/material";
import {
  RootNodeArrowClose,
  RootNodeArrowOpen,
} from "src/components/newUI/ToggleAccordion/ToggleAccordionSvg/ToggleAccordionSvg";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FolderIcon, TFolderIcon } from "./FolderIcon";
import styles from "./Accordion.module.scss";

type TProps = TFolderIcon & {
  title: string;
  titleAfter?: ReactNode;
  subTitle: ReactNode;
  icon?: ReactNode;
  children: ReactNode;
  inWorkCount?: number;
  outDateCount?: number;
  doneCount?: number;
  expanded?: boolean;
};

export const Accordion = ({
  title,
  titleAfter,
  subTitle,
  icon,
  children,
  dot,
  stop,
  expanded,
}: TProps) => {
  const subTitleStyle = typeof subTitle === "string";
  const iconValue = icon || <FolderIcon dot={dot} stop={stop} />;

  return (
    <AccordionMui
      expanded={expanded}
      classes={{
        root: styles.accordion,
        expanded: styles.accordion__expanded,
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <RootNodeArrowOpen className={styles.iconOpen} />
            <RootNodeArrowClose className={styles.iconClose} />
          </IconButton>
        }
        classes={{
          root: styles.summaryRoot,
          content: styles.summaryContent,
          expanded: styles.summaryExpanded,
          expandIconWrapper: styles.summaryExpandIconWrapper,
        }}
      >
        {iconValue}
        <div>
          <div className={styles.headline}>
            <span className={styles.title}>{title}</span>
            {titleAfter}
          </div>
          {subTitle && (
            <div className={classNames(subTitleStyle && styles.subTitle)}>
              {subTitle}
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.detailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMui>
  );
};
