import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../app/store";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProcessWork } from "../../../../app/feature/ProjectProcessView/Work/services/fetchProcessWork";
import {
  getProjectProcessWorkAllPrice,
  getProjectProcessWorkGroups,
  getProjectProcessWorkIsLoad,
} from "../../../../app/feature/ProjectProcessView/Work/selectors/getProjectProcessWork";
import { fetchProjectProcessView } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import cls from "./Work.module.scss";
import { GroupsCard } from "./GroupsCard/GroupsCard";
import { trimPrice } from "../../../../utils/trimNumber";
import { SkeletonProjectProcessWorkTab } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";

export const Work = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useSelector(getProjectProcessInfoId);
  const groups = useSelector(getProjectProcessWorkGroups);
  const isLoad = useSelector(getProjectProcessWorkIsLoad);
  const fullCost = useSelector(getProjectProcessWorkAllPrice);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProcessWork(infoId));
    }
  }, [infoId]);

  if (isLoad) {
    return <SkeletonProjectProcessWorkTab />;
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.fullCost}>
        <p>{trimPrice(fullCost || 0)}</p>
        <span>
          Опубликовано <br /> без налога (НДС)
        </span>
      </div>

      {groups &&
        groups.map(([userid, group]) => (
          <GroupsCard key={group.userFio} {...group} userId={+userid} />
        ))}
    </div>
  );
};
