import { useEffect } from "react";
import { fetchAcceptTask } from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { useAppDispatch } from "../../../../../../app/store";

type CheckOutputTaskProps = {
  taskId: number;
  handleCloseModal: () => void;
};

export const CheckOutputTask = ({
  taskId,
  handleCloseModal,
}: CheckOutputTaskProps) => {
  const dispatch = useAppDispatch();

  const acceptHandler = () => {
    const result = window.confirm("Вы уверены, что хотите принять задание?");

    if (result) {
      dispatch(fetchAcceptTask(taskId));
    }

    handleCloseModal();
  };

  useEffect(() => {
    acceptHandler();
  }, []);

  return null;
};
