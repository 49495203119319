import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  removeExecutor,
  removeProcessExecutor,
  requestUpdate,
  removeRequestExecution,
  request,
  requestWork,
} from "./queries";

export const projectAccessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectAccessRemoveProcessExecutor: removeProcessExecutor(builder),
    projectAccessRequestUpdate: requestUpdate(builder),
    projectAccessRemoveRequestExecution: removeRequestExecution(builder),
    projectAccessRequest: request(builder),
    projectAccessRequestWork: requestWork(builder),
    projectAccessRemoveExecutor: removeExecutor(builder),
  }),
  overrideExisting: false,
});

export const {
  useProjectAccessRemoveProcessExecutorMutation,
  useProjectAccessRequestUpdateMutation,
  useProjectAccessRemoveRequestExecutionMutation,
  useProjectAccessRequestMutation,
  useProjectAccessRequestWorkMutation,
  useProjectAccessRemoveExecutorMutation,
} = projectAccessApi;
