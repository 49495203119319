export function getAuthorIds(arr: any, type: string) {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][type]) {
      result.push(arr[i].id);
    }
  }

  return result;
}
