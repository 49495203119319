import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "../../../hooks/useTitle";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";
import DefaultPhoto from "../../../../media/images/news_plug_main.png";
import { request } from "../../../../app/services/api/requestHandler";
import { ApiGetPostForView } from "../../../../app/services/api/posts/posts";
import {
  selectorPostEdit,
  setPostForEdit,
} from "../../../../app/feature/editPost/postEdit";
import { PostEditForm } from "../../../features/Forms/PostEditForm/PostEditForm";
import { Skeleton } from "./Skeleton/Skeleton";

export const Edit = () => {
  useTitle("Редактирование");

  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [image, setImage] = useState("");
  const [isLoad, setLoad] = useState(true);
  const [idPost, setIdPost] = useState("");

  const data = {
    post: useSelector(selectorPostEdit),
  };

  useEffect(() => {
    // @ts-ignore
    setIdPost(params.id);
  }, []);

  useEffect(() => {
    setLoad(true);
    if (idPost) {
      request(ApiGetPostForView(idPost), (res) => {
        dispatch(setPostForEdit(res));
        setLoad(false);
      })(dispatch);
    }
  }, [idPost, dispatch]);

  return (
    <div className={style.postEditPage}>
      {isLoad ? (
        <Skeleton />
      ) : (
        <Wrapper>
          <div className={style.postContainer}>
            <h1 className={style.title}>Редактирование</h1>
            <div className={style.postImageContainer}>
              <figure className={style.postImage}>
                <img
                  className={style.postPhoto}
                  src={image || DefaultPhoto}
                  alt="пустая картинка"
                />
              </figure>
            </div>
            <div className={style.formContainer}>
              <PostEditForm
                imageHandler={setImage}
                variant={style.postEditForm}
                post={data.post}
              />
            </div>
          </div>
        </Wrapper>
      )}
    </div>
  );
};
