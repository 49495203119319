import React from "react";
import { useSelector } from "react-redux";
import { Clear } from "@mui/icons-material";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

const clearSX = { width: "45px", height: "45px" };

export type PopupType = ComponentPropsType<{
  head?: JSX.Element;
  body: JSX.Element;
  bottom?: JSX.Element;
  closeHandler?: VoidFunction;
  mode?: "" | "alert";
  size?: "standard" | "small";
  animationShowRender?: boolean;
  height?: number;
}>;
// popup - имеет голову
// alert - голову не имеет

export const Popup: React.FC<PopupType> = ({
  head,
  body,
  bottom,
  closeHandler = () => {},
  mode = "",
  size = "standard",
  animationShowRender = true,
  height,
}) => {
  const theme = useSelector(selectorAppTheme);

  const Close: React.FC<unknown> = () => {
    return (
      <div className={style.iconWrapper} onClick={closeHandler}>
        <Clear sx={clearSX} />
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          style.popup,
          animationShowRender && style.showAnimate,
          style[theme],
          style[size],
          style[mode]
        )}
      >
        {head && mode === "" && (
          <div className={classNames(style.head, style[theme])}>
            {head} <Close />
          </div>
        )}
        {body && (
          <div
            style={height ? { height: `${height}px` } : {}}
            className={classNames(
              style.body,
              style[mode],
              style[theme],
              !bottom && style.notBottom
            )}
          >
            {body}
            {mode === "alert" && <Close />}
          </div>
        )}
        {bottom && (
          <div className={classNames(style.bottom, style[mode], style[theme])}>
            {bottom}
          </div>
        )}
      </div>
      <div className={classNames(style.bg)} onClick={closeHandler} />
    </>
  );
};
