export const responseAgreement = [
  {
    id: 0,
    title: "Нет",
  },
  {
    id: 1,
    title: "Да",
  },
];
