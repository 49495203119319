import React from "react";
import { useSelector } from "react-redux";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { ChildDot } from "./ChildDot/ChildDot";
import styles from "./ChildGraph.module.scss";

export const ChildGraph = () => {
  const { budgetChild } = useSelector(getProjectGraphData);
  return (
    <div className={styles.child}>
      <div className={styles.child_firstRow}>
        <p className={styles.firstCol}>Наименование</p>
        <p className={styles.col}>ОБС</p>
        <p className={styles.col}>ИИ</p>
        <p className={styles.col}>ПД</p>
        <p className={styles.col}>РД</p>
        <p className={styles.col}>Всего</p>
      </div>
      {budgetChild.map((child: any) => (
        <div className={styles.child_row}>
          <ChildDot key={child.project.name} child={child} />
        </div>
      ))}
    </div>
  );
};
