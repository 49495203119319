export class AsyncFileReader extends FileReader {
  readAsDataURL(file: File) {
    const promise = new Promise((resolve, reject) => {
      this.addEventListener("load", () => resolve(this.result as string));
      this.addEventListener("error", () => reject(this.error));

      super.readAsDataURL(file);
    }) as Promise<string>;

    return promise;
  }
}
