import { FC } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { CheckSquareFill, Trash } from "react-bootstrap-icons";
import Avatar from "../../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { config } from "../../../../../app.cofig";
import classes from "./Admin.module.scss";
import Modal from "../../../../newUI/Modal/Modal";
import { UserEditModal } from "./UserEditModal";
import { GipEditModal } from "./GipEditModal";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { formFullUserName } from "../../../../../FSD/shared/lib/helpers";
import { PencilEditSVG } from "../../../../newUI/SVG";

type UserCardProps = {
  modalEditUser: boolean;
  modalEditGip: boolean;
  setModalEditUser: SetState<boolean>;
  setModalEditGip: SetState<boolean>;
  handleRemoveUser: (id: number) => void;
} & Record<string, any>;

const UserCard: FC<UserCardProps> = ({
  modalEditUser,
  modalEditGip,
  setModalEditUser,
  setModalEditGip,
  setModalTaskComplete,
  handleRemoveUser,
  ...userData
}) => {
  const { project } = useSelector(getProjectViewData);

  const canComplete = userData.can.complete;
  const isGip = userData.accessCode.code === 4;
  const canUpdate = userData.can?.update;
  const canDelete = userData.can?.delete;
  const role = userData.accessCode.title;
  const taskId = userData.task_id;
  const isDelegated = userData.delegate;
  const canDelegate = userData.can.delegate;

  const data = [
    {
      id: userData.id,
      avatar: `${config.localDomain}${userData.user.photo}`,
      link: `/user/${userData.user_id}`,
      name: formFullUserName(userData.user),
      firm: userData.userType?.listName ?? "",
      rating: userData.rating,
      jobDuration: Number((userData.user.job_duration / 365).toFixed(1)),
      canComplete: userData.can.complete,
      role,
      canDelegate,
      isDelegated,
    },
  ];

  if (isGip) {
    return (
      <div className={classNames(classes.basic, classes.author)}>
        <Modal
          active={modalEditGip === userData.id}
          setActive={setModalEditGip}
        >
          <GipEditModal gipAccess={project!.gipAccess} />
        </Modal>
        <div className={classes.author_btn}>
          <h1 className={classes.author_name}>{role}</h1>
          <div className={classes.controls}>
            {canUpdate && (
              <IconButton onClick={() => setModalEditGip(userData.id)}>
                <PencilEditSVG />
              </IconButton>
            )}
            {canComplete && (
              <IconButton
                onClick={() => setModalTaskComplete({ role, taskId })}
              >
                <CheckSquareFill color={"white"} />
              </IconButton>
            )}
            {canDelete && (
              <IconButton onClick={() => handleRemoveUser(userData.id)}>
                <Trash color={"white"} />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.object_gip_have}>
          <Avatar data={data} projectView />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.basic, classes.author)}>
      <div className={classes.author_btn}>
        <Modal
          active={modalEditUser === userData.id}
          setActive={setModalEditUser}
        >
          <UserEditModal
            closeModalUser={() => {
              setModalEditUser(false);
            }}
            authorAccess={project!.authorAccess}
          />
        </Modal>
        <h1 className={classes.author_name}>{role}</h1>
        <div className={classes.controls}>
          {canUpdate && (
            <IconButton onClick={() => setModalEditUser(userData.id)}>
              <PencilEditSVG />
            </IconButton>
          )}
          {canComplete && (
            <IconButton onClick={() => setModalTaskComplete({ role, taskId })}>
              <CheckSquareFill color={"white"} />
            </IconButton>
          )}
          {canDelete && (
            <IconButton onClick={() => handleRemoveUser(userData.id)}>
              <Trash color={"white"} />
            </IconButton>
          )}
        </div>
      </div>
      <Avatar data={data} projectView />
    </div>
  );
};

export default UserCard;
