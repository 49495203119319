import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { responseAgreement } from "src/FSD/features/v2/SelectAgreement/model/options";
import styles from "./PublishContentForm.module.scss";
import { usePublishContent } from "../lib/hooks/usePublishContent";
import { TermsPayment } from "./TermsPayment/TermsPayment";
import { PublishContentProcess } from "./PublishContentProcess/PublishContentProcess";

const defaultValues = {
  price: 0,
  pay1: 0,
  pay2: 0,
  pay3: 0,
  dateStart: null,
  dateLimit: null,
  checkNegotiatedPrice: false,
  checkDelegation: false,
  agreementsActs: responseAgreement[0],
  safeTransaction: responseAgreement[0],
  userTypeToAssign: null,
  userToAssign: null,
  executorTypes: [],
  save: 0,
  isAppointmentExecutor: false,
  vor: false,
  vorPnr: false,
  kac: false,
  showVor: false,
  showVorPnr: false,
  showKac: false,
  bim: false,
  payError: "",
  showPay3: false,
  canNoSecure: false,
  processWork: false,
  workAccount: null,
  showTermsPayment: false,
};

type Props = {
  id: number;
  header: string;
  isProcessWork?: boolean;
};

export const PublishContentForm = ({
  id,
  header,
  isProcessWork = false,
}: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    control,
    valuesPublication,
    setValue,
    errors,
    isSubmittingSave,
    isSubmittingPublish,
    isProjectProcessViewPending,
    processData,
    showErrorFiles,
    publishInfo,
    textProcess,
  } = usePublishContent({
    id,
    isProcessWork,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{header}</Modal.Header>
      {isProjectProcessViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.modalForm}
        >
          <PublishContentProcess
            control={control}
            register={register}
            valuesPublication={valuesPublication}
            id={id}
            setValue={setValue}
            errors={errors}
            processData={processData!}
            showErrorFiles={showErrorFiles}
            secureMissingAttributes={publishInfo!.secureMissingAttributes}
            isProcessWork={isProcessWork}
            textProcess={textProcess}
          />
          <TermsPayment
            register={register}
            valuesPublication={valuesPublication}
            setValue={setValue}
            isSubmittingSave={isSubmittingSave}
            isSubmittingPublish={isSubmittingPublish}
            errors={errors}
          />
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
