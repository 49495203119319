export const ContentDownloadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g clipPath="url(#clip0_8298_17788)">
      <path
        d="M10.6251 8.625C10.6251 8.45924 10.5592 8.30027 10.442 8.18306C10.3248 8.06585 10.1658 8 10.0001 8C9.8343 8 9.67533 8.06585 9.55812 8.18306C9.44091 8.30027 9.37506 8.45924 9.37506 8.625V13.3663L7.94256 11.9325C7.88445 11.8744 7.81546 11.8283 7.73954 11.7968C7.66361 11.7654 7.58224 11.7492 7.50006 11.7492C7.41788 11.7492 7.3365 11.7654 7.26058 11.7968C7.18465 11.8283 7.11567 11.8744 7.05756 11.9325C6.99945 11.9906 6.95335 12.0596 6.9219 12.1355C6.89045 12.2114 6.87427 12.2928 6.87427 12.375C6.87427 12.4572 6.89045 12.5386 6.9219 12.6145C6.95335 12.6904 6.99945 12.7594 7.05756 12.8175L9.55756 15.3175C9.61561 15.3757 9.68458 15.4219 9.76052 15.4534C9.83645 15.4849 9.91785 15.5011 10.0001 15.5011C10.0823 15.5011 10.1637 15.4849 10.2396 15.4534C10.3155 15.4219 10.3845 15.3757 10.4426 15.3175L12.9426 12.8175C13.0007 12.7594 13.0468 12.6904 13.0782 12.6145C13.1097 12.5386 13.1258 12.4572 13.1258 12.375C13.1258 12.2928 13.1097 12.2114 13.0782 12.1355C13.0468 12.0596 13.0007 11.9906 12.9426 11.9325C12.8844 11.8744 12.8155 11.8283 12.7395 11.7968C12.6636 11.7654 12.5822 11.7492 12.5001 11.7492C12.4179 11.7492 12.3365 11.7654 12.2606 11.7968C12.1847 11.8283 12.1157 11.8744 12.0576 11.9325L10.6251 13.3663V8.625Z"
        fill="black"
      />
      <path
        d="M17.5 18V6.125L11.875 0.5H5C4.33696 0.5 3.70107 0.763392 3.23223 1.23223C2.76339 1.70107 2.5 2.33696 2.5 3V18C2.5 18.663 2.76339 19.2989 3.23223 19.7678C3.70107 20.2366 4.33696 20.5 5 20.5H15C15.663 20.5 16.2989 20.2366 16.7678 19.7678C17.2366 19.2989 17.5 18.663 17.5 18ZM11.875 4.25C11.875 4.74728 12.0725 5.22419 12.4242 5.57583C12.7758 5.92746 13.2527 6.125 13.75 6.125H16.25V18C16.25 18.3315 16.1183 18.6495 15.8839 18.8839C15.6495 19.1183 15.3315 19.25 15 19.25H5C4.66848 19.25 4.35054 19.1183 4.11612 18.8839C3.8817 18.6495 3.75 18.3315 3.75 18V3C3.75 2.66848 3.8817 2.35054 4.11612 2.11612C4.35054 1.8817 4.66848 1.75 5 1.75H11.875V4.25Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_8298_17788">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
