import React from "react";
import ReactDOM from "react-dom";
import style from "./style.module.scss";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const InviteToTeam: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  const { Popup } = useUserWorkflow();

  const onClose = () => {
    Popup.eventUserInviteToTeam.close();
    Popup.eventUserInviteToTeam.set({});
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={<p className={style.join}>Приглашение в команду</p>}
        body={<></>}
      />
    </Modal>
  );
};
