import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { ProcessWorkTabs } from "src/FSD/entities/processWork/model/tabs";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { useModalsRemoveExecution } from "src/FSD/entities/publishObject/lib/hooks/useModalsRemoveExecution";
import { useModalsRequestUpdates } from "src/FSD/entities/publishObject/lib/hooks/useModalsRequestUpdates";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useApplications } from "../lib/hooks/useApplications";
import { TabChildrenHeader } from "../../../TabsPanel";
import { Application } from "./Application";
import { TabWarning } from "../../../TabsPanel/ui/TabWarning/TabWarning";
import styles from "./Applications.module.scss";
import PublishObjectModals from "../../../publishObject/modals";

type Props = {
  processWorkId: number;
  secure: boolean;
};

export const Applications = ({ processWorkId, secure }: Props) => {
  const { applicationsList, currentChildTab, isApplicationsLoading } =
    useApplications(processWorkId);

  const media = currentChildTab === ProcessWorkTabs.applications.bad && (
    <TabWarning>{WarningMessages.BAD_APPLICATIONS}</TabWarning>
  );

  const { requestUpdate, handleCloseRequestUpdate } = useModalsRequestUpdates();

  const { removeRequestExecution, handleCloseRemoveExecutionRequest } =
    useModalsRemoveExecution();

  return (
    <>
      <Modal
        isActive={removeRequestExecution.isOpen}
        handleClose={handleCloseRemoveExecutionRequest}
      >
        <PublishObjectModals.RemoveRequestExecutionForm
          taskId={removeRequestExecution.taskId!}
          header={removeRequestExecution.header}
          processWork
        />
      </Modal>
      <Modal
        isActive={requestUpdate.isOpen}
        handleClose={handleCloseRequestUpdate}
      >
        <PublishObjectModals.RequestUpdateForm
          publicationObjectValues={requestUpdate}
          edit
          secure={secure}
          processWork
        />
      </Modal>
      <TabChildrenHeader
        title="Заявки"
        media={media}
        mediaClassName={styles.media}
      />
      <Column maxHeight="865px">
        {isApplicationsLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {applicationsList.map((application: any) => (
              <Application key={application.id} application={application} />
            ))}
          </ListMapper>
        )}
      </Column>
    </>
  );
};
