import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from "@mui/material";
import cn from "classnames";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetNewsTags } from "../../../app/services/api/getTags/ApiGetNewsTags";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import {
  MenuProps,
  boxSX,
} from "../../features/SpecialForm/SpecializationMultiple";
import style from "./style.module.scss";

export type TagsTypeProps = ComponentPropsType<{
  value: TagsTypeItem[];
  error: boolean;
  changeHandler: (data: string[]) => void;
}>;

export type DataTagsTypeItem = {
  id: number;
  label: string;
  date_create: string;
};

export type TagsTypeItem = {
  id: string;
  title: string;
};

export const TagsMultiple: React.FC<TagsTypeProps> = ({
  error,
  changeHandler,
  value = [],
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<TagsTypeItem[]>([]);

  const onLoadTags = () => {
    request(ApiGetNewsTags(), (data) => {
      const tags = data.map((item: DataTagsTypeItem): TagsTypeItem => {
        return {
          id: `${item.id}`,
          title: item.label,
        };
      });
      setOptions(tags);
    })(dispatch);
  };

  useEffect(() => {
    onLoadTags();
  }, []);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const data = (typeof value === "string" ? value.split(",") : value).map(
      (item: any) => options.find((t) => t.title === item)
    );

    changeHandler(data);
  };

  const valueForSelect = value.map((item) => item.title);

  return (
    <FormControl fullWidth className={style.specializationForm}>
      <InputLabel shrink className={cn(error && style.error)}>
        Теги
      </InputLabel>
      <Select
        multiple
        fullWidth
        value={valueForSelect}
        onChange={handleChange}
        inputProps={{
          classes: {
            root: style.selectroot,
            icon: style.icon,
          },
        }}
        renderValue={(selected) => (
          <Box sx={boxSX}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        error={error}
      >
        {options.map((name) => (
          <MenuItem
            classes={{ root: style.menuitem, selected: style.selected }}
            key={name.id}
            value={name.title}
          >
            {name.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
