import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import SelectMultipleLight from "src/FSD/shared/uiKit/v2/SelectMultiple/SelectMultipleLight";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { request } from "../../../app/services/api/requestHandler";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { ApiGetPartGroup } from "../../../app/services/api/partGroup/partGroup";

export type SpecializationProps = ComponentPropsType<{
  changeHandler?: (spec: TSelectItem[]) => void;
  value: TSelectItem[];
  label: string;
  placeholder?: string;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
}>;

export const SpecializationMultipleLight: React.FC<SpecializationProps> = ({
  changeHandler = () => {},
  value,
  label = "",
  placeholder = "",
  onBlur = () => {},
  error = false,
  helperText = "",
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allSpec, setAllSpec] = useState<TSelectItem[]>([]);

  const onLoadSpec = () => {
    request(
      ApiGetPartGroup(),
      (data) => {
        const allSpecFormed = data.map(
          (item: { name: string; id: number }) => ({
            id: item.id,
            title: item.name,
          })
        );

        setAllSpec(allSpecFormed);
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса специализаций", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  useEffect(() => {
    onLoadSpec();
  }, []);

  return (
    <SelectMultipleLight
      changeHandler={(value) => {
        changeHandler(value);
      }}
      onBlur={onBlur}
      value={value}
      label={label}
      placeholder={placeholder}
      options={allSpec}
      error={error}
      helperText={helperText}
    />
  );
};
