import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { StateType } from "../../../initialState";
import { openedProcessesInitialState } from "./initialState";

// создаем ключ который равен id проекта
const setStateForProcesses = (
  state: { [key: string]: string[] },
  id: number
) => {
  if (state.hasOwnProperty(id)) {
    return state;
  }

  return {
    ...state,
    [id]: [],
  };
};

const setToggleStateForProcesses = (
  state: { [key: string]: string[] },
  processesStateId: string,
  projectId: number
) => {
  const copy = { ...state };

  if (copy[projectId].includes(processesStateId)) {
    copy[projectId] = copy[projectId].filter(
      (id: string) => id !== processesStateId
    );

    return copy;
  }
  copy[projectId] = [...copy[projectId], processesStateId];

  return copy;
};

export const processesStateReducer = createReducer(
  openedProcessesInitialState,
  (builder) => {
    builder.addCase(
      C.CREATE_STATE_FOR_OPENED_PROCESSES,
      (state, action: PayloadAction<{ projectId: number }>) => {
        return setStateForProcesses(state, action.payload.projectId);
      }
    );

    builder.addCase(
      C.SAVE_PROCESS_ID_IN_OPENED_STATE,
      (
        state,
        action: PayloadAction<{
          projectId: number;
          processesStateId: string;
        }>
      ) => {
        return setToggleStateForProcesses(
          state,
          action.payload.processesStateId,
          action.payload.projectId
        );
      }
    );
  }
);

export const createStateForOpenedProcesses = createAction(
  C.CREATE_STATE_FOR_OPENED_PROCESSES,
  (payload) => {
    return {
      payload: {
        projectId: payload.projectId,
      },
    };
  }
);

export const saveProcessIdInOpenedState = createAction(
  C.SAVE_PROCESS_ID_IN_OPENED_STATE,
  (payload) => {
    return {
      payload: {
        projectId: payload.projectId,
        processesStateId: payload.processesStateId,
      },
    };
  }
);

export const selectorProcessesOpened = (state: StateType) =>
  state.projectProcessControlsOpened;
