import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { range } from "lodash";
import { getMonth, getYear } from "date-fns";
import ru from "date-fns/locale/ru";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import * as Popper from "popper.js";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

type DateCreateProps = ComponentPropsType<{
  startDateProp?: Date | null;
  endDateProp?: Date | null;
  changeDateHandler?: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  placement?: Popper.Placement;
}>;

const years = range(2020, getYear(new Date()) + 5, 1);
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const DateCreate: React.FC<DateCreateProps> = ({
  startDateProp = null,
  endDateProp = null,
  changeDateHandler = () => {},
  minDate,
  maxDate,
  placement = "bottom",
}) => {
  const [startDate, setStartDate] = useState(startDateProp);

  const onChangeDates: DateCreateProps["changeDateHandler"] = (date) => {
    // @ts-ignore
    setStartDate(date);
    changeDateHandler(date);
  };

  const onReset = () => {
    setStartDate(null);
  };

  useEffect(() => {
    if (startDateProp === null) {
      onReset();
    }
  }, [startDateProp]);

  return (
    <div>
      <ReactDatePicker
        className={style.datePicker}
        maxDate={minDate}
        minDate={maxDate}
        locale={ru}
        dateFormat={"dd.MM.yyyy"}
        selected={startDate}
        endDate={endDateProp}
        onChange={onChangeDates}
        popperPlacement={placement}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="head">
            <div className="top"></div>
            <div className="bottom">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    decreaseMonth();
                  }}
                  className={style.btn}
                  disabled={prevMonthButtonDisabled}
                >
                  <ChevronLeft className={style.chevron} />
                </button>
                <select
                  className={style.select}
                  value={getYear(date)}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className={style.select}
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    increaseMonth();
                  }}
                  className={style.btn}
                  disabled={nextMonthButtonDisabled}
                >
                  <ChevronRight className={style.chevron} />
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
