import { createSlice } from "@reduxjs/toolkit";
import { fetchMaterialsOfferUpdate } from "../services/fetchMaterialsOfferSubmit";

type InitialState = {
  isLoadUpdate: boolean;
  successUpdate: boolean;
  error422: any;
  price: any;
  userTypeId: any;
  dateDeadline: any;
  comment: any;
  errorFlag: boolean;
};

const initialState: InitialState = {
  isLoadUpdate: false,
  successUpdate: false,
  error422: undefined,
  price: undefined,
  userTypeId: undefined,
  dateDeadline: null,
  comment: undefined,
  errorFlag: false,
};

const updateMaterialsForm = createSlice({
  name: "updateMaterialsForm",
  initialState,
  reducers: {
    setInitialUpdate: (state, action) => {
      state.userTypeId = action.payload.userTypeId;
      state.price = action.payload.price;
      state.dateDeadline = action.payload.dateDeadline;
      state.comment = action.payload.comment;
    },
    setPriceUpdate: (state, action) => {
      state.price = action.payload;
    },
    setUserTypeIdUpdate: (state, action) => {
      state.userTypeId = action.payload;
    },
    setDateDeadlineUpdate: (state, action) => {
      state.dateDeadline = action.payload;
    },
    setCommentUpdate: (state, action) => {
      state.comment = action.payload;
    },
    setClearUpdate: (state) => {
      state.error422 = undefined;
      state.successUpdate = false;
    },
    setErrorFlagUpdate: (state) => {
      state.errorFlag = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaterialsOfferUpdate.pending, (state) => {
      state.isLoadUpdate = true;
      state.successUpdate = false;
    });
    builder.addCase(fetchMaterialsOfferUpdate.fulfilled, (state) => {
      state.isLoadUpdate = false;
      state.successUpdate = true;
    });
    builder.addCase(fetchMaterialsOfferUpdate.rejected, (state, action) => {
      state.error422 = action.payload?.message;
      state.isLoadUpdate = false;
      state.errorFlag = true;
    });
  },
});

export const {
  setClearUpdate,
  setPriceUpdate,
  setCommentUpdate,
  setUserTypeIdUpdate,
  setDateDeadlineUpdate,
  setInitialUpdate,
  setErrorFlagUpdate,
} = updateMaterialsForm.actions;
export default updateMaterialsForm;
