import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  chatAddOpenedRoom,
  selectorOpenRooms,
} from "../../../../app/feature/chat/chat";
import { getAllParentsElements } from "./other";

export type UseScrollToIdType = () => any[];

export const useScrollToId = () => {
  const [wrapperScroll, setWrapperScroll] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const openedRoom = useSelector(selectorOpenRooms);

  useEffect(() => {
    if (wrapperScroll) return;
    const wrapperScrollElement = document.getElementById("roomList");
    if (wrapperScrollElement) {
      setWrapperScroll(wrapperScrollElement);
    }
  }, [wrapperScroll]);

  const onScrollToId = (id: string | number) => {
    if (wrapperScroll && id) {
      const heightScrollWindow = wrapperScroll.offsetHeight;

      const elements = getAllParentsElements(`room-${id}`).filter(
        (item) => item.dataset && item.dataset.group
      );

      const ids: string[] = [];
      elements.forEach((item) => {
        Array.from(item.getElementsByClassName("js-head")).forEach((item) => {
          ids.push(item.id);
        });
      });
      if (!openedRoom.includes(ids[ids.length - 1])) {
        dispatch(chatAddOpenedRoom(ids));
      }

      setTimeout(() => {
        const toItem = document.getElementById(`room-${id}`);
        if (!toItem) return;
        wrapperScroll.scroll(0, toItem!.offsetTop - heightScrollWindow / 2);
      }, 0);
    }
  };

  return [onScrollToId];
};
