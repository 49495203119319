import React from "react";
import classNames from "classnames";
import { compose } from "lodash/fp";
import reactParser from "react-html-parser";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { withClassCurry } from "../../../../utils";

export type CounterProps = ComponentPropsType<{
  value: string | number;
  title: string;
  size: "small" | "normal" | "big";
}>;

export const Counter: React.FC<CounterProps> = ({
  value,
  title,
  variant = "",
  theme = "dark",
  size = "normal",
}) => {
  const withTheme = withClassCurry(style[theme]);
  const withSize = withClassCurry(style[size]);
  const withThemeAndSize = compose(withTheme, withSize);

  return (
    <div className={withThemeAndSize(classNames(variant, style.counter))}>
      <p className={withThemeAndSize(classNames(style.counterValue))}>
        {value}
      </p>
      <p
        className={withThemeAndSize(
          classNames(style.counterSubtitle, "counterSubtitle")
        )}
      >
        {reactParser(title)}
      </p>
    </div>
  );
};
