import React from "react";
import classNames from "classnames";
import cls from "./TaskCard.module.scss";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { trimNumber } from "../../../utils/trimNumber";
import { SecureIcon } from "../SVG";
import { useIsMobile } from "../../hooks/useIsMobile";
import { TWork } from "../../../app/feature/ProjectProcessView/Work/types/projectProcessWorkTypes";
import { Dot } from "../Dot/Dot";

type TaskCardProps = TWork & {
  userFio: string;
  dot: boolean;
  appCount: number;
};
export const TaskCard: React.FC<TaskCardProps> = ({
  id,
  title,
  price,
  activeAccess,
  date_start,
  date_limit,
  secure,
  executorType,
  status,
  dot,
  userFio,
  appCount,
  children,
}) => {
  const isRuble = price === null;
  const isMobile = useIsMobile(680);
  const priceIsNotNumber = price === "Договорная";

  return (
    <div className={cls.parent}>
      <div className={cls.parent_dot}>
        {!isMobile && <Dot status={dot ? "warning" : ""} />}
        <div className={classNames(cls.parent_flex, isMobile && cls.code)}>
          <div className={cls.parent_dot_flex}>
            {isMobile && <Dot status={dot ? "warning" : ""} />}
            <TextLink redirect url={`/process-work/${id}`} target="_blank">
              <div title={title} className={cls.parent_code}>
                {title}
              </div>
            </TextLink>
          </div>
          <div className={cls.parent_status}>{status.value}</div>
        </div>
      </div>
      <div className={classNames(cls.parent_flex, isMobile && cls.author)}>
        {activeAccess && (
          <>
            <div className={cls.parent_author}>{userFio}</div>
            <div className={cls.parent_type}>
              {activeAccess.userType.listName}
            </div>
          </>
        )}
        {!activeAccess && status.key === 2 && (
          <>
            <div>
              <div className={cls.parent_type}>Форма собственности</div>
              <div
                className={classNames(
                  cls.parent_type_executor,
                  cls.parent_type
                )}
              >
                <div className={cls.parent_type_title}>
                  {executorType.map((type) => (
                    <span key={type.value}>{type.value}</span>
                  ))}
                </div>
                <div className={cls.appCount}>
                  <p>Заявки</p>
                  <TextLink
                    url={`/process-work/${id}?tab=application`}
                    redirect
                  >
                    <p className={cls.appCount_count}>{appCount}</p>
                  </TextLink>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classNames(cls.parent_flex, isMobile && cls.price)}>
        <div className={cls.parent_price}>
          {!priceIsNotNumber && (
            <>
              {trimNumber(price)}
              <span className={cls.parent_price_ruble}>{!isRuble && "₽"}</span>
            </>
          )}
          {secure && <SecureIcon width="16" heigth="18" fill="#9DAFBD" />}
        </div>
        {date_start && (
          <div className={cls.parent_date}>
            {date_start} - {date_limit}
          </div>
        )}
      </div>
      <div className={cls.parent_btn}>{children}</div>
    </div>
  );
};
