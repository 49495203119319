import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import {
  getProjectCheckList,
  setError,
  setOpenAllNode,
} from "../../../../../app/feature/ProjectView/CheckListReducer/projectCheckList";
import styles from "./Checklist.module.scss";
import { TreeCheckList } from "./TreeCheckList";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { ChecklistSkeletonFullPage } from "./SkeletonChecklist";
import { fetchProjectCheckListTree } from "../../../../../app/feature/ProjectView/CheckListReducer/thunks";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface ICheckListProps {
  setDefaultTab: () => void;
}

export const CheckList = ({ setDefaultTab }: ICheckListProps) => {
  const isTablet = useIsMobile(1600);
  const { project } = useSelector(getProjectViewData);
  const { checklistTree, isLoadCheckList, error, isAllToggle } =
    useSelector(getProjectCheckList);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (project && !project.can.editByGipHelper) {
      setDefaultTab();
    }
  }, [project]);

  useEffect(() => {
    if (params.id && !checklistTree) {
      dispatch(fetchProjectCheckListTree(params.id));
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  const handleClick = () => {
    dispatch(setOpenAllNode());
  };

  if (isLoadCheckList) {
    return <ChecklistSkeletonFullPage />;
  }

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (checklistTree) {
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Общий чек-лист</h1>
            <div className={styles.header_grid}>
              <p>Разделы</p>
              <span>
                Выдать <br /> задания
              </span>
              <span>
                Принять <br /> задания
              </span>
              <span>
                Предв. <br /> решения
              </span>
              <span>
                Выдать <br /> документы
              </span>
              <span>
                Проверить <br /> документы
              </span>
              <span>
                Подписать <br /> акт
              </span>
            </div>
          </div>
          <ScrollWrap height={isTablet ? "auto" : "858px"}>
            <TreeCheckList data={checklistTree} />
          </ScrollWrap>
          <AllToggleWidget
            isAllToggle={isAllToggle}
            setIsAllToggle={handleClick}
          />
        </div>
      </>
    );
  }
  return <div></div>;
};
