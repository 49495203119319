import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { KanbanColumns, KanbanStatuses } from "../../model/constants";
import { State } from "../../types";

export const dropToRowTasks = (
  state: Draft<State>,
  action: PayloadAction<any>
) => {
  const { columnId, rowId, task } = action.payload;

  console.log({ rowId, s: state.tasks.rowTasks[rowId] });

  // проверка на то что такая группа есть, если нет то она создастся
  if (!state.tasks.rowTasks[rowId]) {
    const {
      groupBy: { id: groupById },
    } = state.data;

    let header;

    if (groupById === "skill") {
      header = task.partGroup.fullName;
    }

    if (groupById === "type") {
      header = task.workFlowClass;
    }

    state.tasks.rowTasks[rowId] = {
      header,
      columns: {
        [KanbanStatuses[KanbanColumns.IN_WORK]]: [],
        [KanbanStatuses[KanbanColumns.IS_DONE]]: [],
        [KanbanStatuses[KanbanColumns.DONE]]: [],
      },
    };
  }

  state.tasks.rowTasks[rowId].columns[columnId].unshift(task);
};
