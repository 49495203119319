import { RootState } from "../../../../../store";

export const getExpertiseFilterVisible = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.filterVisible;

export const getExpertisePartGroupId = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.partGroupId;

export const getExpertiseProjectPartCode = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.projectPartCode;

export const getExpertiseProjectStatusId = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.statusId;

export const getExpertiseRemarksFilterIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseRemarksFilter;
