export const frontFooterDocumentationMock = [
  {
    title: "Термины и определения",
    url: "/site/terms",
    outer: false,
  },
  {
    title: "Пользовательское соглашение",
    url: "/site/agreement",
    outer: false,
  },
  {
    title: "Тарифные планы",
    url: "/site/rates",
    outer: false,
  },
  {
    title: "Политика конфиденциальности",
    url: "/site/policy",
    outer: false,
  },
  {
    title: "Алгоритм безопасной сделки",
    url: "/site/deal",
    outer: false,
  },
  {
    title: "Публичная оферта",
    url: "/site/offer",
    outer: false,
  },
  {
    title: "Инструкция пользователя ЦП ПИРС",
    url: "https://wiki.pirs.online/",
    outer: true,
  },
];
