import { MainPageDataTypes } from "../../../types/stateTypes/mainPage";

export const mainPageInitialState: MainPageDataTypes = {
  counts: {
    authors: null,
    gips: null,
    executors: null,
    providers: null,
  },
  projectsCounts: null,
  projects: [],
  executors: {
    rating: [],
    experience: [],
    new: [],
  },
  news: [],
};
