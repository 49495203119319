import React, { useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { RemoveGip } from "src/FSD/widgets/v2/project/Info/InfoGip/RemoveGip";
import { CompleteRemoveGip } from "src/FSD/widgets/v2/project/Info/InfoGip/CompleteRemoveGip";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { NewModal } from "src/components/newUI/NewModal/NewModal";

export const GipThreeDotBtn = () => {
  const { project } = useSelector(getProjectViewData);
  const { isLoadCompleteRemoveGip } = useSelector(getProjectViewData);
  const { isLoadRemoveGip } = useSelector(getProjectViewData);
  const threeDotButtonRef = useRef<RefType>(null);
  const [modalRemove, setModalRemove] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  function onModalComplete() {
    setModalComplete(true);
    closeThreeDot();
  }

  function onModalRemove() {
    setModalRemove(true);
    closeThreeDot();
  }

  return (
    <>
      <NewModal
        active={modalRemove}
        setActive={setModalRemove}
        isLoad={isLoadRemoveGip}
        title={"Заявка на снятие ГИП проекта"}
      >
        <RemoveGip />
      </NewModal>
      <NewModal
        active={modalComplete}
        setActive={setModalComplete}
        isLoad={isLoadCompleteRemoveGip}
        title={"Рассмотрение заявки на снятие ГИПа"}
      >
        {project?.requestRemoveGipTask && (
          <CompleteRemoveGip removeGip={project?.requestRemoveGipTask} />
        )}
      </NewModal>
      <ThreeDotButton ref={threeDotButtonRef} color="success">
        {project?.can?.requestRemoveGip && (
          <MenuItem disableRipple onClick={onModalRemove}>
            Снять
          </MenuItem>
        )}
        {project?.can?.completeRemoveGip && (
          <MenuItem disableRipple onClick={onModalComplete}>
            Рассмотреть
          </MenuItem>
        )}
      </ThreeDotButton>
    </>
  );
};
