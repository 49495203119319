import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getProjectCheckList,
  setError,
  setAllToggle,
} from "../../../../../app/feature/ProjectView/CheckListReducer/projectCheckList";
import style from "./checklist.module.scss";
import { TreeCheckList } from "./TreeCheckList";
import { AllToggleFalse, AllToggleTrue } from "../../../../newUI/SVG";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { SkeletonCheckList } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";

const sx = { color: "white" };

interface ICheckListProps {
  setCurrentTabMemo: () => void;
}

export const CheckList: React.FC<ICheckListProps> = ({ setCurrentTabMemo }) => {
  const { project } = useSelector(getProjectViewData);
  const { checklistTree, isLoadCheckList, error, isAllToggle } =
    useSelector(getProjectCheckList);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (project && !project.can.editByGipHelper) {
      setCurrentTabMemo();
    }
  }, [project]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  const handleClick = () => {
    dispatch(setAllToggle());
  };

  const allToggleIcon = !isAllToggle ? (
    <AllToggleTrue width="33" heigth="33" />
  ) : (
    <AllToggleFalse width="33" heigth="33" />
  );

  if (isLoadCheckList) {
    return <SkeletonCheckList />;
  }

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (checklistTree) {
    return (
      <>
        <div className={style.wrapper}>
          <div className={classNames(style.parent, style.grid, style.basic)}>
            <p>Разделы</p>
            <p>Выдать задания</p>
            <p>Принять задания</p>
            <p>Предв. решения</p>
            <p>Выдать документы</p>
            <p>Проверить документы</p>
            <p>
              Подписать <br /> акт
            </p>
          </div>
          <div>
            <TreeCheckList data={checklistTree} />
          </div>
          <div className={style.allToggle}>
            <IconButton sx={sx} onClick={handleClick}>
              {allToggleIcon}
            </IconButton>
          </div>
        </div>
      </>
    );
  }
  return <div></div>;
};
