import React from "react";
import classNames from "classnames";
import { ExitToApp } from "@mui/icons-material";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { OrganizationLabel } from "../../OrganizationLabel/OgranizationLabel";
import { withClassCurry } from "../../../../utils";
import { Avatar } from "../../Avatar/Avatar";

export type BodyHeaderProps = ComponentPropsType<{
  userData: {
    userName: string;
    avatarUrl: string;
  };
  additionalComponent?: JSX.Element;
  backButtonHandler: VoidFunction;
}>;

const labelChatDefaultTitle = "Выберите чат";

export const BodyHeader: React.FC<BodyHeaderProps> = ({
  theme = "dark",
  variant = "",
  userData,
  additionalComponent,
  backButtonHandler = () => {},
}) => {
  const icon = (
    <Avatar
      variant={style.labelAvatar}
      src={userData.avatarUrl}
      alt={userData.userName}
    />
  );
  const withTheme = withClassCurry(style[theme]);

  return (
    <header className={withTheme(classNames(style.header, variant))}>
      <div>
        <OrganizationLabel
          notHover={true}
          title={userData.userName || labelChatDefaultTitle}
          theme={theme}
          icon={icon}
          variant={style.label}
        />
      </div>

      {additionalComponent && <div className={style.additional} />}

      <div className={style.back} onClick={backButtonHandler}>
        <ExitToApp className={style.backIcon} />
      </div>
    </header>
  );
};
