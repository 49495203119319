import React, { memo } from "react";
import { FormControl } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import styles from "./ModalGipRemove.module.scss";
import {
  fetchGipObjectRemove,
  fetchProjectComplexObject,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/thunks";

const schema = Yup.object().shape({
  content: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

type Inputs = {
  content: string;
};

interface ModalGipRemoveProps {
  id: number;
}
export const ModalGipRemove = memo(({ id }: ModalGipRemoveProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      StartForm: {
        content: dataValues.content,
      },
    };
    await dispatch(fetchGipObjectRemove({ objectId: id, data }));
    if (params.id) {
      await dispatch(fetchProjectComplexObject({ id: +params.id }));
    }
  };
  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      className={styles.removeGip}
    >
      <TextField
        multiline
        minRows={2}
        placeholder="Введите комментарий"
        label="Комментарий к заявке"
        {...register("content")}
      />
      {errors.content ? (
        <p className={styles.error}>{errors.content.message}</p>
      ) : null}
      <FormControl className={styles.removeGip_btn}>
        <Button type="submit">Отправить</Button>
      </FormControl>
    </form>
  );
});
