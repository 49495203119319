import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { Nullable } from "src/types/generics";

type TParams = RequestParams<{
  id: number;
  data: {
    agreementPrice: boolean;
    vor: boolean;
    vor_pnr: boolean;
    kac: boolean;
    price: number | null;
    date_start: string;
    date_limit: string;
    executor_type: (string | number)[];
    pay1: number;
    pay2: number;
    pay3: Nullable<number>;
  };
}>;

export const updatePublished = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/update-published`,
      params: {
        id,
      },
      method: HTTPMethods.PUT,
      data,
    }),
    invalidatesTags: ["ProcessWork.View", "ProjectProcess.GetWorks"],
  });
