import { RootState } from "../../../../store";

export const getProjectProcessExpertise = (state: RootState) =>
  state.projectProcessView.expertise.expertise;

export const getProjectProcessExpertiseIsLoad = (state: RootState) =>
  state.projectProcessView.expertise.isLoadExpertise;

export const getProjectProcessExpertiseCompleteIsLoad = (state: RootState) =>
  state.projectProcessView.expertise.isLoadExpertiseComplete;

export const getProjectProcessExpertiseIndexes = (state: RootState) =>
  state.projectProcessView.expertise.openExpertise;

export const getProjectProcessRemarksIndexes = (state: RootState) =>
  state.projectProcessView.expertise.openRemarks;
