import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import useClickOutside from "../../../../hooks/useClickOutside";
import { config } from "../../../../../app.cofig";
import { useAppSelector } from "../../../../../app/store";
import { setTab } from "../../../../../app/feature/dashboard/destinations";
import { getDashboardHeader } from "../../../../../app/feature/dashboard/header";
import style from "./style.module.scss";

const data = [
  { to: "wallet", name: "Кошелек" },
  { to: "staff", name: "Сотрудники" },
  { to: "instruction", name: "Инструкция" },
];

const Navigation = () => {
  const dispatch = useDispatch();
  const { bank } = useAppSelector(getDashboardHeader);

  const navRef = useRef<HTMLDivElement>(null);
  const [showNavigation, setShowNavigation] = useState<boolean>(false);

  const handleNavigate = (to: string) => {
    if (to === "wallet") {
      window.open(
        `${config.localDomain}/account/view?id=${bank?.id}`,
        "_blank"
      );
    }

    if (to === "instruction") {
      window.open("https://wiki.pirs.online/", "_blank");
    }

    if (to === "staff") {
      dispatch(setTab(to));
    }

    setShowNavigation(false);
  };

  useClickOutside(navRef, () => {
    setShowNavigation(false);
  });

  return (
    <div className={style.mobileNavigation}>
      <h2>Главная</h2>
      <IconButton
        onClick={() => setShowNavigation(!showNavigation)}
        className={style.menuButton}
      >
        <Menu />
      </IconButton>
      {showNavigation && (
        <div
          ref={navRef}
          className={cn(style.mobileNavList, style.responsibilities)}
        >
          {data.map(({ to, name }: any) => {
            if (to === "wallet" && !bank?.id) {
              return null;
            }

            return <p onClick={() => handleNavigate(to)}>{name}</p>;
          })}
        </div>
      )}
    </div>
  );
};

export default Navigation;
