import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import parse from "html-react-parser";
import cn from "classnames";
import { TasksType } from "../../../../../../../app/feature/ProjectProcessView/CheckTask/types/projectProcessCheckTaskTypes";
import cls from "./Task.module.scss";
import { ChatIcon, CheckSVG } from "../../../../../../newUI/SVG";
import { ToggleAccordion } from "../../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { selectColor } from "../../../../../../../utils/selectColor";
import { DefaultFileIcon } from "../../../../../../features/Uploader/UploaderUtils/UI";
import { iconBtnSx } from "../../../../../../../styles/restyle";
import { useAppSelector } from "../../../../../../../app/store";
import { getProjectProcessInfoProjectChatId } from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import Modal from "../../../../../../newUI/Modal/Modal";
import { ModalCheckDocumentations } from "./ModalCheckDocumentations/ModalCheckDocumentations";
import { getProjectProcessCheckDocumentationsIsLoad } from "../../../../../../../app/feature/ProjectProcessView/CheckTask/selectors/getProjectProcessCheckTaskIsLoad";

export const Task: React.FC<TasksType & { taskId: number }> = memo(
  ({
    header,
    status,
    executorName,
    date,
    comment,
    files,
    fileSize,
    projectName,
    can,
    fileDownloadTaskId,
    taskId,
    setModalData,
  }) => {
    const chatId = useAppSelector(getProjectProcessInfoProjectChatId);
    const isLoadCheckDocumentations = useAppSelector(
      getProjectProcessCheckDocumentationsIsLoad
    );

    const [toggleComment, setToggleComment] = useState(false);
    const [toggleFiles, setToggleFiles] = useState(false);
    const [modalCheckDocumentations, setModalCheckDocumentations] =
      useState(false);

    const closeModalCheck = useCallback(() => {
      setModalCheckDocumentations(false);
    }, []);

    function toggleCommentClick() {
      if (setModalData) {
        setModalData({
          id: fileDownloadTaskId,
          open: true,
          name: header,
        });
      }

      /*
      setToggleFiles(false);
      setToggleComment(!toggleComment);
      */
    }
    function toggleFilesClick() {
      setToggleComment(false);
      setToggleFiles(!toggleFiles);
    }

    let renderFooter;
    if (!toggleComment && !toggleFiles) {
      renderFooter = (
        <div className={cls.status}>
          <p>{status.value}</p>
          <p className={cls.status_date}>{date}</p>
        </div>
      );
    }
    if (!toggleFiles && toggleComment) {
      renderFooter = (
        <div className={cls.comment}>
          <p className={cls.comment_title}>Комментарий</p>
          <p className={cls.comment_text}>{comment}</p>
        </div>
      );
    }
    if (toggleFiles && !toggleComment) {
      renderFooter = (
        <div className={cls.files}>
          <h3>Вложения</h3>
          <div className={cls.files_container}>
            {files &&
              files.map((file: any) => (
                <TextLink
                  variant={cls.link}
                  key={file.id}
                  url={file.url}
                  redirect
                >
                  <div
                    className={cls.files_svg}
                    title="скачать"
                    key={file.name}
                  >
                    {file.svgIcon ? parse(file.svgIcon) : <DefaultFileIcon />}
                    <p>{file.name}</p>
                  </div>
                </TextLink>
              ))}
          </div>
          <div className={cls.files_all}>
            <TextLink
              redirect
              download
              url={`/task/download-files?id=${fileDownloadTaskId}`}
              variant={cls.link}
            >
              <p>{`Скачать всё (${fileSize.join("")})`}</p>
            </TextLink>
          </div>
        </div>
      );
    }

    return (
      <div
        className={cls.wrapper}
        style={{ background: selectColor(status.color) }}
      >
        <div className={cls.card}>
          <div className={cls.card_content}>
            <h1>{header}</h1>
            <span>{projectName}</span>
            <p>{executorName}</p>
            <div
              className={cn(cls.card_toggle, cls.toggleList)}
              onClick={toggleCommentClick}
            >
              <p>Комментарии</p>
              <ToggleAccordion toggle={toggleComment} />
            </div>
            {files.length > 0 && (
              <div className={cls.card_toggle} onClick={toggleFilesClick}>
                <p>Вложения</p>
                <ToggleAccordion toggle={toggleFiles} />
              </div>
            )}
          </div>
          <div className={cls.card_btn}>
            {can.accept && (
              <Modal
                active={modalCheckDocumentations}
                setActive={setModalCheckDocumentations}
                isLoad={isLoadCheckDocumentations}
              >
                <ModalCheckDocumentations
                  closeModal={closeModalCheck}
                  files={files}
                  taskId={taskId}
                  header={header}
                />
              </Modal>
            )}
            {can.accept && (
              <IconButton
                sx={iconBtnSx}
                title="Проверить документацию"
                onClick={() => setModalCheckDocumentations(true)}
              >
                <CheckSVG width="24" height="24" />
              </IconButton>
            )}
            {can.chat && (
              <TextLink url={`/site/front/chat/${chatId}`} redirect>
                <IconButton sx={iconBtnSx} title="Чат">
                  <ChatIcon width="22" height="22" />
                </IconButton>
              </TextLink>
            )}
          </div>
        </div>
        <div className={cls.footer}>{renderFooter}</div>
      </div>
    );
  }
);
