import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";

export const update = (builder: QueryBuilder) =>
  builder.mutation({
    query: ({ id, remarkRow }) => ({
      url: `v1/expertise/remark-row/update?id=${id}`,
      method: HTTPMethods.PUT,
      data: remarkRow,
    }),
  });
