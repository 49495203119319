import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { EditTaskForm } from "./EditTaskForm";
import { selectorEditOrganisationTaskData } from "../../../app/feature/eventEmitter/organisation/eventEditOrganisationTask";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const EditTask: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;
  const { Popup } = useUserWorkflow();

  const { title } = useSelector(selectorEditOrganisationTaskData);

  const onClose = () => {
    Popup.editOrganisationTask.close();
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={
          <p style={{ color: "white" }}>
            Запросить статус "Проверенная организация" {title}
          </p>
        }
        body={<EditTaskForm />}
      />
    </Modal>
  );
};
