import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой изменения банковского счета в организации
 */

export const showEventEditBankAccount = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventEditBankAccount: {
      isShow: true,
      data: {
        ...state.eventEditBankAccount.data,
      },
    },
  };
};

export const closeEventEditBankAccount = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventEditBankAccount: {
    isShow: false,
    data: {
      ...state.eventEditBankAccount.data,
    },
  },
});

export const setEventEditBankAccount = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventEditBankAccount: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorEditBankAccount = (state: StateType) =>
  state.eventEmitter.eventEditBankAccount.isShow;

export const selectorEditBankAccountData = (state: StateType) =>
  state.eventEmitter.eventEditBankAccount.data;
