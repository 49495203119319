import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы публикации раздела проекта
 */

export const showRequestRemoveGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventRequestRemoveGip: {
      isShow: true,
      data: {
        ...state.eventRequestRemoveGip.data,
      },
    },
  };
};
export const closeRequestRemoveGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventRequestRemoveGip: {
      isShow: false,
      data: {
        ...state.eventRequestRemoveGip.data,
      },
    },
  };
};

export const selectorEERequestRemoveGip = (state: StateType) =>
  state.eventEmitter.eventRequestRemoveGip;

export const selectorEERequestRemoveGipIsShow = (state: StateType) =>
  state.eventEmitter.eventRequestRemoveGip.isShow;
