import React from "react";
import classNames from "classnames";
import { ChecklistProjectData } from "../../../app/feature/ProjectView/CheckListReducer/thunks";
import cls from "./ChecklistCard.module.scss";
import { selectLvlClass } from "../../../utils/helpersOpenNode";
import { ChecklistCardCauses } from "./ChecklistCardCauses/ChecklistCardCauses";
import { ChecklistCardDate } from "./ChecklistCardDate/ChecklistCardDate";
import { ChecklistCardBudget } from "./ChecklistCardBudget/ChecklistCardBudget";
import { CheckListDash } from "./CheckListDash/CheckListDash";

interface ChecklistCardProps {
  data: ChecklistProjectData;
}
export const ChecklistCard = ({ data }: ChecklistCardProps) => {
  const lvlClass = selectLvlClass(data.lvl);
  const {
    datePercent,
    dateEnd,
    dateStart,
    priceStart,
    priceEnd,
    pricePercent,
    dateEndFact,
  } = data;

  return (
    <div className={classNames(cls.checklist, cls[lvlClass])}>
      <ChecklistCardCauses data={data} />
      <div className={cls.info}>
        <CheckListDash node={data} />
        <div className={cls.dash} />
        <div className={cls.info_card}>
          <ChecklistCardDate
            datePercent={datePercent}
            dateStart={dateStart}
            dateEnd={dateEnd}
            dateEndFact={dateEndFact?.date}
          />
          <ChecklistCardBudget
            priceStart={+priceStart}
            priceEnd={+priceEnd}
            pricePercent={pricePercent}
          />
        </div>
      </div>
    </div>
  );
};
