import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";

export const getBreadcrumbs = (project: ApiProjectResponses.View) => {
  if (!project) {
    return [];
  }

  return [
    {
      isActive: true,
      title: "Главная",
      url: `/user/new-dashboard`,
    },
    ...(project?.parent_id
      ? [
          {
            isActive: true,
            title: `${project?.parentName || ""}`,
            url: `/project/test/${project.parent_id}`,
          },
        ]
      : []),
    {
      isActive: false,
      title: `${project?.name || ""}`,
      url: ``,
    },
  ];
};
