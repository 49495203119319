import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getProjectCompareGip,
  setError,
} from "../../../../../app/feature/ProjectView/CompareReducer/projectCompareGip";
import { CompareGipCard } from "./CompareGipCard";
import styles from "./style.module.scss";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { config } from "../../../../../app.cofig";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { Header } from "./Header/Header";

interface ICompareGipProps {
  setDefaultTab: () => void;
}

export const CompareGip = ({ setDefaultTab }: ICompareGipProps) => {
  const { compareGip, error, isCompleteGip } =
    useSelector(getProjectCompareGip);
  const { project } = useSelector(getProjectViewData);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  if (project && !project?.can.edit) {
    setDefaultTab();
  }
  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (isCompleteGip && params.id)
      window.location.href = `${config.localDomain}/project/${params.id}?tab=info`;
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch(setError());
    }
  }, [error, isCompleteGip]);

  if (compareGip) {
    return (
      <div className={styles.wrapper}>
        <Header count={compareGip.length} />
        {compareGip.map((gip) => (
          <CompareGipCard key={gip.id} userGip={gip} />
        ))}
      </div>
    );
  }
  return <div></div>;
};
