import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../services/api/requestHandler";
import { pushError, pushSuccessMessage } from "../../errorTrace";
import { httpRequestGet, httpRequestPost } from "../../../services/api/api";
import { config } from "../../../../app.cofig";

const ApiCreateProjectChecklist = (
  projectId: number,
  parentId: number,
  data: Record<string, any>
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/project-checklist/create?projectId=${projectId}&parentId=${parentId}`,
    {},
    data
  );

const ApiGetStageList = (projectId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-checklist/stage-list?projectId=${projectId}`
  );

const ApiGetFilesDto = (processId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-checklist/stage-list?projectId=${processId}`
  );

// /v1/project-checklist/stage-list

export const createChecklist = createAsyncThunk(
  "project/createChecklist",
  async (
    {
      projectId,
      parentId,
      data,
      type,
    }: {
      projectId: number;
      parentId: number;
      data: Record<string, any>;
      type: number;
    },
    { dispatch }
  ) => {
    await request(
      ApiCreateProjectChecklist(projectId, parentId, {
        ProjectChecklist: { ...data, type },
      }),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const fetchStageList = createAsyncThunk(
  "project/fetchStageList",
  async (projectId: number, { dispatch }) => {
    let stageList: any = [];
    await request(
      ApiGetStageList(projectId),
      (response) => {
        stageList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return stageList;
  }
);

export const fetchFilesDto = createAsyncThunk(
  "project/fetchFilesDto",
  async (processId: number, { dispatch }) => {
    let stageList: any = [];
    await request(
      ApiGetFilesDto(processId),
      (response) => {
        stageList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return stageList;
  }
);
