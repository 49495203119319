import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Регистрация, управление показом пользовательского соглашения
 */

export const showEventUserAgreement = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventShowUserAgreement: {
      ...state.eventShowUserAgreement,
      isShow: true,
    },
  };
};

export const closeEventUserAgreement = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventShowUserAgreement: {
      isShow: false,
      data: {},
    },
  };
};

export const selectorEEShowUserAgreement = (state: StateType) =>
  state.eventEmitter.eventShowUserAgreement.isShow;
