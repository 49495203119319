import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Chat } from "../../features/Chat/Chat";
import { Wrapper } from "../../UI/templates";
import { Header } from "../../features";
import { LoadDataIndexPage } from "../../lifeСycle/LoadDataIndexPage/LoadDataIndexPage";
import { RedirectToExternal } from "../../features/RedirectToExternal/RedirectToExternal";

type TProps = {
  isAuth: boolean;
};

export const ChatPage: FC<TProps> = ({ isAuth }) => (
  <Routes>
    <Route path={"/chat"} element={<Navigate to={"/chat/all"} />} />
    <Route
      path={"/chat/:roomId"}
      element={
        isAuth ? (
          <LoadDataIndexPage>
            <Header />
            <Wrapper>
              <Chat />
            </Wrapper>
          </LoadDataIndexPage>
        ) : (
          <RedirectToExternal to="/site/login" />
        )
      }
    />
  </Routes>
);
