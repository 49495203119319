import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import cls from "./RemarkTable.module.scss";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../styles/restyle";
import { RowsType } from "../../../../../../../app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import Modal from "../../../../../../newUI/Modal/Modal";
import { ModalComplete } from "./ ModalComplete/ModalComplete";
import { RemarkTableCard } from "./RemarkTableContent/RemarkTableContent";
import { setCheckInput } from "../../../../../../../app/feature/ProjectView/projectView";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { getTaskCompleteIsLoad } from "../../../../../../../app/feature/taskComplete/selectrors/getTaskComplete";

interface RemarkTableProps {
  rows: RowsType[];
  files: any;
  canComplete: boolean;
  task: any;
  name: string;
}

export const RemarkTable = memo(
  ({ rows, canComplete, task, name, files }: RemarkTableProps) => {
    const [modalComplete, setModalComplete] = useState(false);
    const dispatch = useAppDispatch();
    const isLoadCompleteTask = useAppSelector(getTaskCompleteIsLoad);
    function onModalComplete() {
      setModalComplete(true);
      dispatch(setCheckInput(true));
    }

    const closeModalComplete = useCallback(() => {
      setModalComplete(false);
    }, []);

    return (
      <div className={cls.wrapper}>
        <div className={cls.header}>
          <h1>Чек-лист снятия замечаний</h1>
          <div className={cls.header_btn}>
            {canComplete && (
              <Modal
                active={modalComplete}
                setActive={setModalComplete}
                isLoad={isLoadCompleteTask}
              >
                <ModalComplete
                  taskId={task.id}
                  addText={task?.data?.addText}
                  closeModal={closeModalComplete}
                />
              </Modal>
            )}
            {canComplete && (
              <CustomButton
                width={200}
                background={palette.green}
                onClick={onModalComplete}
              >
                Загрузить документацию
              </CustomButton>
            )}
            {task?.files?.length === 0 && <p>(Документация не приложена)</p>}
          </div>
        </div>
        <table className={cls.table}>
          <thead className={cls.table_head}>
            <tr>
              <th className={classNames(cls.table_head_num, cls.headTd)}>
                № п/п
              </th>
              <th className={classNames(cls.table_head_remark, cls.headTd)}>
                Замечание
              </th>
              <th className={classNames(cls.table_head_comment, cls.headTd)}>
                Комментарий
              </th>
              <th className={classNames(cls.table_head_answer, cls.headTd)}>
                Ответы на замечание
              </th>
              <th
                colSpan={2}
                className={classNames(cls.table_head_doc, cls.headTd)}
              >
                Документация, в которую внесены изменения
              </th>
            </tr>
          </thead>
          <tbody className={cls.tbody}>
            {rows.map((el) => (
              <RemarkTableCard
                filesUpdate={files}
                name={name}
                data={el}
                key={el.row.id}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
