import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  processId: number;
}>;

export const toProjects = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.ToProjects, TParams>({
    query: (params) => ({
      url: `v1/project-process/to-projects`,
      params: {
        processId: params.processId,
      },
    }),
  });
