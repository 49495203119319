import React from "react";
import styles from "./Header.module.scss";

export const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.header_left}>
        <h3>График оплаты</h3>
        <div className={styles.flex}>
          <div className={styles.circleBlock}>
            <div className={styles.circleRed} />
            <p>Превышение общего плана</p>
          </div>
          <div className={styles.circleBlock}>
            <div className={styles.circleYellow} />
            <p>Превышение текущего плана</p>
          </div>
          <div className={styles.circleBlock}>
            <div className={styles.circleGreen} />
            <p>Соответствие с планом</p>
          </div>
        </div>
      </div>
    </div>
  );
};
