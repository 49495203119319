import React from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { trim } from "src/components/pages/User/View/Portfolio/Portfolio";
import { ProjectViewData } from "src/app/feature/ProjectView/createData/createData";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { config } from "src/app.cofig";
import styles from "./InfoFiles.module.scss";

type InfoFilesProps = {
  files: ProjectViewData["files"] | null;
};

export const InfoFiles = ({ files }: InfoFilesProps) => {
  const params = useParams<{ id: string }>();
  const handleNavigate = () => {
    window.location.href = `${config.localDomain}/project/download-files?id=${params.id}&withTasks=0`;
  };

  if (files) {
    return (
      <div className={classNames(styles.files, styles.basic)}>
        <div className={styles.files_title}>
          <h1>Вложения</h1>
        </div>
        <div>
          {!files.length && (
            <div className={styles.description_text}>Вложения отсутствуют</div>
          )}
          {files.length > 0 &&
            files.map((file) => (
              <div key={file.url}>
                <TextLink
                  className={styles.textLink}
                  redirect
                  target="blank"
                  url={file.url}
                >
                  {trim(file.title)}
                </TextLink>
                <br />
              </div>
            ))}
        </div>
        {files.length > 0 && (
          <div className={styles.files_btn}>
            <Button onClick={handleNavigate} color="primary">
              Скачать
            </Button>
          </div>
        )}
      </div>
    );
  }
  return null;
};
