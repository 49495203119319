import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormControl, TextField } from "@mui/material";
import {
  getProjectSections,
  setPublishNull,
} from "../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { ModalSchema } from "../../pages/Project/View/Sections/ModalSchema";
import {
  fetchPublishInfo,
  fetchUpdatePublishProcess,
} from "../../../app/feature/ProjectView/SectionsReducer/thunks";
import { parseUnprocessableFields } from "../../../app/services/api/requestHandler";
import cls from "./UpdatePublishProcess.module.scss";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { palette, textFieldSX } from "../../../styles/restyle";
import { SelectField } from "../SelectMui/SelectMui";
import { ModalPreloader } from "../Modal/ModalPreloader";
import CustomButton from "../CustomButton/CustomButton";
import { fetchProjectProcessWorkPublishUpdate } from "../../../app/feature/ProjectProcessView/Work/services/fetchProjectProcessWorkPublishUpdate";
import { useAppSelector } from "../../../app/store";
import { getProjectProcessWorkPublishError422 } from "../../../app/feature/ProjectProcessView/Work/selectors/getProjectProcessWorkPublish";
import { DateField } from "../../features/SpecialForm/DateField/DateField";

const types = {
  1: "Физическое лицо",
  2: "Юридическое лицо",
  3: "Индивидуальный предприниматель",
  4: "Самозанятый",
};

type Inputs = {
  price: number;
  type: string;
  date_start: any;
  date_limit: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  pay1: number;
  pay2: number;
  pay3: number;
  paymentSum: number;
};

interface UpdatePublishProcessProps {
  id: number;
  haveExpertise: boolean;
  projectPart: any;
  price: string;
  vor: boolean;
  vor_pnr: boolean;
  date_limit: any;
  date_start: any;
  executorType: { value: string; full: string; key: number }[];
  pay1: number;
  pay2: number;
  pay3: number;
  kac: boolean;
  closeModal: () => void;
  title: string;
  work?: boolean;
}

// Todo перенесен в src/FSD/shared/uiKit/v2/UpdatePublishProcess
export const UpdatePublishProcess: React.FC<UpdatePublishProcessProps> = memo(
  (props) => {
    const { error422, update, publishInfo, isLoadPublishInfo } =
      useSelector(getProjectSections);
    const error422Work = useAppSelector(getProjectProcessWorkPublishError422);
    const dispatch = useDispatch();

    const {
      id,
      haveExpertise,
      projectPart,
      price,
      vor,
      vor_pnr,
      kac,
      date_limit,
      date_start,
      executorType,
      pay1,
      pay2,
      pay3,
      title,
      closeModal,
      work,
    } = props;

    const defaultExecutorType = executorType?.map((el) => el.key.toString());
    const canAllCheck = vor && vor_pnr && kac;
    const additional =
      publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
    const defaultAgreementPrice = price === "Договорная";

    const [valueMulti, setValueMulti] = useState<string[]>(defaultExecutorType);
    const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
    const [firstRender, setFirstRender] = useState(true);
    const [checkVor, setCheckVor] = useState(vor);
    const [checkVorPnr, setCheckVorPnr] = useState(vor_pnr);
    const [checkKac, setCheckKac] = useState(kac);
    const [checkAll, setCheckAll] = useState(canAllCheck);
    const schema = ModalSchema(haveExpertise, checkPrice);

    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

    useEffect(() => {
      setFirstRender(false);
    }, []);

    const {
      register,
      handleSubmit,
      control,
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        pay1,
        pay2: !haveExpertise ? pay2 + pay3 : pay2,
        pay3,
        price: defaultAgreementPrice ? 0 : +price,
        date_start: start,
        date_limit: end,
        vor: !!vor,
        vor_pnr: !!vor_pnr,
        kac: !!kac,
      },
    });

    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        agreementPrice: checkPrice,
        vor: !publishInfo.vor ? false : checkVor,
        vor_pnr: !publishInfo.vor_pnr ? false : checkVorPnr,
        kac: !publishInfo.kac ? false : checkKac,
        price: (!checkPrice && dataValues.price) || null,
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        executor_type: dataValues.type[0].split(","),
        pay1: +dataValues.pay1,
        pay2: +dataValues.pay2,
        pay3: haveExpertise ? +dataValues.pay3 : null,
      };
      if (!work) {
        await dispatch(fetchUpdatePublishProcess({ id, data }));
        if (error422) {
          parseUnprocessableFields(error422, setError);
        }
      }
      if (work) {
        await dispatch(fetchProjectProcessWorkPublishUpdate({ id, data }));
        if (error422Work) {
          await parseUnprocessableFields(error422Work, setError);
        }
      }
    };

    useEffect(() => {
      setValue("type", defaultExecutorType.join());
    }, []);

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all")) {
        const all = Object.keys(types);
        setValueMulti(all);
        setValue("type", "1,2,3,4");
      } else {
        setValueMulti(event.target.value);
        setValue("type", event.target.value);
      }
    };
    const handleClearClick = () => {
      setValueMulti([]);
      setValue("type", "");
    };

    useEffect(() => {
      // при изменении checkAll меняем состояние всех чекбоксов
      if (!firstRender) {
        setCheckVor(checkAll);
        setCheckVorPnr(checkAll);
        setCheckKac(checkAll);
      }
    }, [checkAll]);

    useEffect(() => {
      if (!checkVor && !checkVorPnr && !checkKac) {
        setCheckAll(false);
      }
      if (checkVor && checkVorPnr && checkKac) {
        setCheckAll(true);
      }
    }, [checkVor, checkVorPnr, checkKac]);

    const handleCheckAll = () => {
      setCheckAll(!checkAll);
    };

    useEffect(() => {
      if (update) {
        clearErrors();
        dispatch(setPublishNull());
        closeModal();
      }
    }, [update]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      dispatch(fetchPublishInfo({ id, isWork: work }));
    }, []);

    const priceRender = (
      <div className={cls.form_flex}>
        <label className={cls.form_label}>
          <CustomCheckbox
            editing
            onChange={() => setCheckPrice(!checkPrice)}
            checked={checkPrice}
          />
          Договорная стоимость
        </label>
        {!checkPrice && (
          <div className={cls.form_price}>
            <TextField
              {...register("price")}
              {...textFieldSX}
              label="Стоимость"
              defaultValue={price}
              error={!!errors.price}
            />
            {errors.price && (
              <p className={cls.error}>{errors.price.message}</p>
            )}
          </div>
        )}
      </div>
    );

    const payRender = (
      <>
        <div className={cls.form_pay}>
          <FormControl className={cls.form_pay_first}>
            <TextField
              {...register("pay1")}
              {...textFieldSX}
              label="Аванс (%)"
              defaultValue={pay1}
              error={!!errors.pay1}
            />
            {errors.pay1 && <p className={cls.error}>{errors.pay1.message}</p>}
          </FormControl>
          <FormControl className={cls.form_pay_last}>
            <TextField
              {...register("pay2")}
              {...textFieldSX}
              label="Оплата при завершении (%)"
              defaultValue={!haveExpertise ? pay3 + pay2 : pay2}
              error={!!errors.pay2}
            />
            {errors.pay2 && <p className={cls.error}>{errors.pay2.message}</p>}
          </FormControl>
          {haveExpertise && projectPart.published && (
            <FormControl className={cls.form_pay_second}>
              <TextField
                {...register("pay3")}
                {...textFieldSX}
                label="Оплата после экспертизы (%)"
                defaultValue={pay3}
                error={!!errors.pay3}
              />
              {errors.pay3 && (
                <p className={cls.error}>{errors.pay3.message}</p>
              )}
            </FormControl>
          )}
          <input type="hidden" {...register("paymentSum")} />
        </div>
        {errors.paymentSum && (
          <p className={cls.form_pay_paymentSum}>{errors.paymentSum.message}</p>
        )}
      </>
    );

    const typeRender = (
      <div>
        <SelectField
          {...register("type")}
          name="type"
          label="Форма собственности"
          multiple
          multiValue={valueMulti}
          isAllClick
          onChange={onChangeMulti}
          options={Object.entries(types)}
          handleClearClick={handleClearClick}
          error={errors.type ? errors.type.message : null}
        />
      </div>
    );

    const dateRender = (
      <div className={cls.form_date}>
        <div className={cls.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={() => (
              <DateField
                variant={cls.formElement}
                label="Начальный срок"
                changeDateHandler={(date) => {
                  setValue("date_start", date);
                }}
                startDateProp={start}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={cls.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={cls.form_date_end}>
          <Controller
            control={control}
            name="date_limit"
            render={() => (
              <DateField
                variant={cls.formElement}
                label="Конечный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                startDateProp={end}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
      </div>
    );

    const vorRender = (
      <div className={cls.form_flexColumn}>
        {(publishInfo.vor || publishInfo.vor_pnr) && (
          <div className={cls.form_flex}>
            {publishInfo.vor && (
              <div className={cls.form_checkBox}>
                <CustomCheckbox
                  {...register("vor")}
                  editing
                  id="vor"
                  checked={checkVor}
                  onChange={() => setCheckVor(!checkVor)}
                  classname={cls.form_checkBox_input}
                />
                <label htmlFor="vor">Ведомость объемов работ</label>
              </div>
            )}
            {errors.vor && <p className={cls.error}>{errors.vor.message}</p>}
            {publishInfo.vor_pnr && (
              <div className={cls.form_checkBox}>
                <CustomCheckbox
                  {...register("vor_pnr")}
                  editing
                  id="vorPnr"
                  checked={checkVorPnr}
                  onChange={() => setCheckVorPnr(!checkVorPnr)}
                  classname={cls.form_checkBox_input}
                />
                <label htmlFor="vorPnr">
                  Ведомость объемов работ на пусконаладочные работы
                </label>
              </div>
            )}
            {errors.vor_pnr && (
              <p className={cls.error}>{errors.vor_pnr.message}</p>
            )}
          </div>
        )}
        <div className={cls.form_flex}>
          {publishInfo.kac && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                {...register("kac")}
                editing
                id="kac"
                checked={checkKac}
                onChange={() => setCheckKac(!checkKac)}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="kac">Конъюнктурный анализ цен</label>
            </div>
          )}
          {canAllCheck && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                id="selectAll"
                checked={checkAll}
                onChange={handleCheckAll}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="selectAll">Выбрать все</label>
            </div>
          )}
        </div>
      </div>
    );

    if (isLoadPublishInfo) {
      return <ModalPreloader />;
    }

    return (
      <div className={cls.modal}>
        <h1>{title}</h1>
        <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
          <h3>Стоимость, сроки и условия оплаты</h3>
          {priceRender}
          {payRender}
          {dateRender}
          <h3>Требования к исполнителю</h3>
          {typeRender}
          {additional && !work && (
            <h3>Дополнительные требования к разработке раздела</h3>
          )}
          {vorRender}
          <div className={cls.form_submit}>
            <FormControl>
              <CustomButton
                background={palette.green}
                width={160}
                type="submit"
              >
                Изменить
              </CustomButton>
            </FormControl>
          </div>
        </form>
      </div>
    );
  }
);
