// Возвращает данные для хедера в индивидуальной стринце пользователя
import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPostMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetUserPageHeaderData = (userId: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${userId}&fields=id,name,surname,premium,patronymic,skills,phone,photo,tested,type,region&expand=additionalInfo,rating,userTypes,executorDoneProjectCount,gipDoneProjectCount,customerDoneProjectCount,customerWorkProjectCount,executorWorkProjectCount,gipWorkProjectCount,providerWorkProjectCount,providerDoneProjectCount,account`
  );
};

export const ApiGetUserTypes = (userId: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${userId}&fields=id&expand=userTypes`
  );
};

export const ApiGetUserPageContentData = (userId: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${userId}&fields=about&expand=additionalInfo,phone,jobPlaces,educations,portfolios,portfolios.files,feedbacks,rewards,firms,feedbacks.task.taskProcess`
  );
};

export const ApiGetUserAccountData = (userId: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${userId}&fields=id&expand=account`
  );
};

export const ApiPostRequestChangeUserAttr = (
  userId: string | number,
  formData: FormData
) => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/user/request-change-user-attr?id=${userId}`,
    {},
    formData
  );
};

export const ApiGetMyFirm = () => {
  return httpRequestGet(`${config.localDomain}/v1/user/get-my-firms`);
};

export const ApiRequestJoinFirm = (
  id: string | number,
  data: {
    StartForm: {
      firmId: number;
      content: string;
    };
  }
) => {
  return httpRequestPost(
    `${config.localDomain}/v1/user/request-join-firm?id=${id}`,
    {},
    { ...data }
  );
};

export const ApiGetMyProjects = () => {
  return httpRequestGet(`${config.localDomain}/v1/user/get-my-projects`);
};

export const ApiGetMyProjectsByUserType = (userTypeId: number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user-type/get-my-projects?userTypeId=${userTypeId}`
  );
};

export const ApiGetUserData = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${id}&fields=name,email,phone,agreement_personal`
  );
};

export const ApiGetUserResponsibilityTypes = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${id}&fields=id&expand=userTypes`
  );
};

export const ApiGetCounters = (id: string | number) => {
  return httpRequestGet(`${config.localDomain}/v1/user/get-counts?id=${id}`);
};

export const ApiGetAccounts = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/account/get-account?userTypeId=${id}`
  );
};

export const ApiGetPirsSub = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/premium/get-by-user?userId=${id}`
  );
};

export const ApiPostUserAgree = () => {
  return httpRequestPost(`${config.localDomain}/v1/user/set-agree`);
};

export const ApiGetUserTeams = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/get-teams?userId=${id}&expand=members,firm`
  );
};

export const ApiSelfDestruction = (userId: number | string) => {
  return httpRequestDelete(
    `${config.localDomain}/v1/user/selfdestruction?id=${userId}`
  );
};
