import React from "react";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoName,
  getProjectProcessInfoProject,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { PublishProcess } from "../../../../newUI/PublishProcess/PublishProcess";

export const ModalPublish = ({ closeModal }: any) => {
  const info = useAppSelector(getProjectProcessInfoData);
  const name = useAppSelector(getProjectProcessInfoName);
  const project = useAppSelector(getProjectProcessInfoProject);
  if (info && project) {
    return (
      <PublishProcess
        {...info}
        pay1={project.advance_pay}
        pay2={project.pay1}
        pay3={project.pay2}
        closeModal={closeModal}
        title={`Публикация раздела «${name}» по проекту «${
          info.project?.name || ""
        }»`}
      />
    );
  }
  return <></>;
};
