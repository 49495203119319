import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./Header.module.scss";
import { getProjectCheckTasks } from "../../../../../../app/feature/ProjectView/CheckTaskReducer/projectCheckTask";
import { useAppDispatch } from "../../../../../../app/store";
import { HeaderCount } from "../../../../../newUI/HeaderCount/HeaderCount";
import { fetchProjectCheckTaskTree } from "../../../../../../app/feature/ProjectView/CheckTaskReducer/thunks";

export const Header = memo(() => {
  const { counts, status } = useSelector(getProjectCheckTasks);
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  async function setStatusDone() {
    if (params.id) {
      if (!status.done) {
        await dispatch(
          fetchProjectCheckTaskTree({
            id: +params.id,
            status: "4",
            load: "bodyLoad",
          })
        );
      } else {
        await dispatch(
          fetchProjectCheckTaskTree({
            id: +params.id,
            status: "",
            load: "bodyLoad",
          })
        );
      }
    }
  }

  async function setStatusCheck() {
    if (params.id) {
      if (!status.check) {
        await dispatch(
          fetchProjectCheckTaskTree({
            id: +params.id,
            status: "6",
            load: "bodyLoad",
          })
        );
      } else {
        await dispatch(
          fetchProjectCheckTaskTree({
            id: +params.id,
            status: "",
            load: "bodyLoad",
          })
        );
      }
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.counts}>
        <div className={styles.counts_head}>
          <p>Проверки</p>
          <span>{`(всего ${counts.total})`}</span>
        </div>
        <div className={styles.counts_flex}>
          <HeaderCount
            status={status.check}
            count={counts.check}
            setStatus={setStatusCheck}
            text={"На проверке"}
          />
          <HeaderCount
            status={status.done}
            count={counts.done}
            setStatus={setStatusDone}
            text={"Принятые"}
          />
        </div>
      </div>
    </div>
  );
});
