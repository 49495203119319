import React from "react";
import ReactDOM from "react-dom";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { JoinUserProjectForm } from "../../features/Forms/JoinUserProjects/JoinUserProjectForm";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const JoinUserProject = () => {
  const modalRoot = getModalRootElement();
  const { Popup } = useUserWorkflow();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  const onClose = () => {
    Popup.joinUserProject.close();
  };
  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={<p>Пригласить проектировщика на проект</p>}
        body={<JoinUserProjectForm closeHandler={onClose} />}
      />
    </Modal>
  );
};
