import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

export interface ITab {
  id: string | number;
  label?: string | number;
  incomplete: boolean | undefined;
}

export interface ITabsProps {
  className?: string;
  selectedId: string | number;
  tabs: ITab[];
  onClick: (id: string | number) => void;
}

export const Tabs: React.FC<ITabsProps> = ({
  className,
  selectedId,
  tabs,
  onClick,
}) => {
  return (
    <div className={classNames(style.Tabs, className)}>
      {tabs &&
        tabs.map((tab) => (
          <div
            className={classNames(style.Tab, {
              [style.Tab__selected]: tab.id === selectedId,
            })}
            key={tab.id}
            onClick={() => onClick(tab.id)}
          >
            <div className={style.dot}>
              {tab.incomplete && <div className={style.plot} />}
              <span
                className={classNames(style.TabLabel, {
                  [style.TabLabel__selected]: tab.id === selectedId,
                })}
              >
                {tab.label}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};
