import { useRef } from "react";
import { RefType } from "src/FSD/shared/uiKit/v2/ThreeDotButton/ThreeDotButton";
import { ProcessWorkModel } from "src/FSD/entities/processWork";
import { useAppDispatch } from "../../../../../../../app/store";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";

export const useRequirements = (processWork: ApiProcessWorkResponses.View) => {
  const dispatch = useAppDispatch();

  const threeDotButtonRef = useRef<RefType>(null);

  const handleCloseThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const handleUpdateAddition = () => {
    handleCloseThreeDot();
    dispatch(ProcessWorkModel.modals.setUpdateProcessWorkActive(true));
  };

  const showOptions =
    processWork.can!.update && processWork.projectPart.published;

  return { handleUpdateAddition, threeDotButtonRef, showOptions } as const;
};
