import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

export type PreloaderProps = {
  variant?: string;
};
export const Preloader: React.FC<PreloaderProps> = ({ variant = "" }) => {
  return (
    <div
      style={{ width: window.innerWidth, height: window.innerHeight }}
      className={classNames(style.preloader, variant)}
    />
  );
};
