import React, { memo, useEffect } from "react";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { ApiGetOutTaskFiles } from "src/app/services/api/organisation/organisation";
import { DateField } from "src/components/features/SpecialForm/DateField/DateField";
import { FormWrapper } from "src/components/newUI/FormWrapper/FormWrapper";
import { ModalPreloader } from "src/components/newUI/Modal/ModalPreloader";
import { Select } from "src/components/UI/components/FormComponentns/Select";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { Uploader } from "src/components/features/Uploader/Uploader";
import { config } from "src/app.cofig";
import { fetchUserType } from "src/app/feature/getList/getListUserTypeById/services/fetchUserType";
import { getUserId } from "src/app/services/auth/auth";
import {
  getUserTypeList,
  getUserTypeListIsLoad,
} from "src/app/feature/getList/getListUserTypeById/selectors/getUserTypeList";
import { palette, textFieldSX } from "src/styles/restyle";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getSubmitFormMaterialsComment,
  getSubmitFormMaterialsDateDeadline,
  getSubmitFormMaterialsError422,
  getSubmitFormMaterialsErrorFlag,
  getSubmitFormMaterialsPrice,
  getSubmitFormMaterialsUserTypeId,
} from "src/app/feature/ProjectProcessView/Material/selectors/getSubmitFormMaterials";
import {
  setClear,
  setComment,
  setDateDeadline,
  setInitial,
  setPrice,
  setUserTypeId,
} from "src/app/feature/ProjectProcessView/Material/slice/submitFormMaterials";
import { fetchMaterialsOfferSubmit } from "src/app/feature/ProjectProcessView/Material/services/fetchMaterialsOfferSubmit";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import cls from "./ModalSubmit.module.scss";

type TInputs = {
  price: string;
  status: number;
  comment: string;
  dateDeadline: Date | null;
  userTypeId: any;
};
interface ModalSubmitProps {
  name: string;
  content: string;
  files: { name: string; id: number }[];
  date_deadline: any;
  taskId: number;
  price: string;
  materialId: number;
}

const schema = Yup.object().shape({
  userTypeId: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
  dateDeadline: Yup.date()
    .typeError("Необходимо заполнить «Действительно до»")
    .required("Необходимо заполнить «Действительно до»"),
  price: Yup.string().required("Необходимо заполнить «Стоимость»"),
  comment: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});
export const ModalSubmit = memo((props: ModalSubmitProps) => {
  const { name, price, content, files, taskId, date_deadline, materialId } =
    props;
  const dispatch = useAppDispatch();
  const userId = getUserId();
  const dateDeadLineInitial = date_deadline
    ? moment(date_deadline, "DD.MM.YYYY").toDate()
    : null;

  const userTypeList = useAppSelector(getUserTypeList);
  const isLoadUserTypeList = useAppSelector(getUserTypeListIsLoad);
  const error422 = useAppSelector(getSubmitFormMaterialsError422);
  const errorFlag = useAppSelector(getSubmitFormMaterialsErrorFlag);
  const priceValue = useAppSelector(getSubmitFormMaterialsPrice);
  const userTypeIdValue = useAppSelector(getSubmitFormMaterialsUserTypeId);
  const dateDeadlineValue = useAppSelector(getSubmitFormMaterialsDateDeadline);
  const commentValue = useAppSelector(getSubmitFormMaterialsComment);

  const {
    handleSubmit,
    setValue,
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TInputs>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      userTypeId: userTypeList[0],
      dateDeadline: dateDeadLineInitial,
      price,
    },
  });

  useEffect(() => {
    if (userTypeList.length > 0) {
      if (!errorFlag) {
        dispatch(
          setInitial({
            userType: {
              [taskId]: userTypeList[0],
            },
            price: {
              [taskId]: price,
            },
            dateDeadline: {
              [taskId]: dateDeadLineInitial,
            },
            comment: {
              [taskId]: "",
            },
          })
        );
        setValue("userTypeId", userTypeList[0]);
      }
    }
  }, [userTypeList]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserType({ userId }));
    }
  }, [userId]);

  const formSubmitHandler = async (dataValues: TInputs) => {
    const data = {
      CompleteForm: {
        status: dataValues.status,
        comment: commentValue[taskId],
        price: priceValue[taskId],
        dateDeadline: moment(dateDeadlineValue[taskId], "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        userTypeId: userTypeIdValue[taskId].id,
      },
    };
    await dispatch(fetchMaterialsOfferSubmit({ taskId, data }));
  };

  useEffect(() => {
    dispatch(setClear());
    clearErrors();
  }, []);

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  if (isLoadUserTypeList) {
    return <ModalPreloader />;
  }

  if (userTypeIdValue) {
    return (
      <FormWrapper
        classname={cls.form}
        title={"Входящий запрос оборудования"}
        submitHandler={handleSubmit(formSubmitHandler)}
      >
        <p>Наименование</p>
        <span>{name}</span>
        <p>Комментарий </p>
        <span>{content}</span>
        <p>Опросный лист </p>
        {files?.map((file) => <span key={file.id}>{file.name}</span>)}
        {files?.length > 0 && (
          <TextLink
            url={`/material-process/download-files?id=${materialId}`}
            variant={cls.link}
            redirect
            target={"_blank"}
          >
            Скачать
          </TextLink>
        )}
        <p className={cls.form_offer}>Сформировать коммерческое предложение:</p>
        <div className={cls.inputs}>
          <TextField
            {...textFieldSX}
            {...register("price")}
            className={cls.inputs_price}
            type={"number"}
            value={priceValue[taskId]}
            onChange={(e) => {
              dispatch(
                setPrice({
                  [taskId]: e.target.value,
                })
              );
            }}
            label="Стоимость"
            error={!!errors.price}
            helperText={errors.price?.message}
          />
          <Controller
            control={control}
            name="dateDeadline"
            render={() => (
              <DateField
                variant={cls.inputs_date}
                label="Действительно до"
                changeDateHandler={(date) => {
                  dispatch(
                    setDateDeadline({
                      [taskId]: date,
                    })
                  );
                  setValue("dateDeadline", date);
                }}
                startDateProp={dateDeadlineValue[taskId]}
                error={!!errors.dateDeadline}
                helperText={errors.dateDeadline?.message}
              />
            )}
          />
          <Select
            {...register("userTypeId")}
            variant={cls.inputs_type}
            changeHandler={(_, value) => {
              dispatch(
                setUserTypeId({
                  [taskId]: value,
                })
              );
              setValue("userTypeId", value);
            }}
            label={"Форма собственности"}
            value={userTypeIdValue[taskId]}
            defaultValue={userTypeList[0]}
            options={userTypeList}
            error={!!errors.userTypeId}
            helperText={errors.userTypeId?.message}
            disableClear
          />
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("comment")}
          value={commentValue[taskId]}
          onChange={(e) => {
            dispatch(
              setComment({
                [taskId]: e.target.value,
              })
            );
          }}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment ? errors.comment.message : null}
        />
        <div className={cls.form_uploader}>
          <Uploader
            identifier={String(taskId)}
            autoUpload
            canBeEdited
            getDataCallback={ApiGetOutTaskFiles}
            outFiles={"outFiles"}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
          />
        </div>
        <div className={cls.form_submit}>
          <CustomButton
            width={140}
            type={"submit"}
            background={palette.red}
            onClick={() => {
              setValue("status", 8);
            }}
          >
            Отказать
          </CustomButton>
          <CustomButton
            width={140}
            type={"submit"}
            background={palette.blue}
            onClick={() => {
              setValue("status", 6);
            }}
          >
            Отправить
          </CustomButton>
        </div>
      </FormWrapper>
    );
  }
  return null;
});
