import React from "react";
import Rating from "@mui/material/Rating";
import { Star } from "@mui/icons-material";

type StarsRatingProps = {
  handleChange: any;
  value: number | string;
};

const StarsRating: React.FC<StarsRatingProps> = ({ handleChange, value }) => (
  <Rating
    name="controlled"
    value={+value}
    style={{ fontSize: 22 }}
    onChange={(event: any, newValue: number | null) => {
      handleChange(newValue ?? 0);
    }}
    emptyIcon={<Star style={{ color: "#9DAFBD" }} fontSize="inherit" />}
  />
);

export default StarsRating;
