import { Rating } from "src/FSD/shared/uiKit/v2/Rating";
import styles from "./Application.module.scss";

type Props = {
  rating: number;
  jobDuration?: number;
};

export const ApplicationRating = ({ rating, jobDuration }: Props) => {
  return (
    <div className={styles.card__rating}>
      <Rating.StarExperience rating={rating} jobDuration={jobDuration} />
    </div>
  );
};
