import React, { useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import SectionsTree from "./SectionsTree/Tree";
import style from "./style.module.scss";
import {
  getProjectSections,
  setErrorNull,
  setOpenAllNode,
} from "../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { AllToggleFalse, AllToggleTrue } from "../../../../newUI/SVG";
import { trimPrice } from "../../../../../utils/trimNumber";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { fetchProjectSectionsTreeDuplicate } from "../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { SkeletonSections } from "../../../../UI/components/Project/Skeleton";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import ProcessPublication from "./ProcessPublication/ProcessPublication";
import ProcessRequest from "./ProcessRequest/ProcessRequest";
import gstyle from "./ProcessPublication/style.module.scss";
import { useAppSelector } from "../../../../../app/store";
import {
  getGroupPublishProcessData,
  setClosed as setPublishingClosed,
} from "../../../../../app/feature/project/projectProcessPublication";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import {
  getGroupRequestProcessData,
  setClosed as setRequestClosed,
} from "../../../../../app/feature/project/projectProcessRequest";

const sx = { color: "white" };

interface SectionsProps {
  cost: string | undefined;
}

const Sections = ({ cost = "0" }: SectionsProps) => {
  const {
    sections,
    isLoad,
    error,
    isAllToggle,
    pending: { duplicateTree },
  } = useSelector(getProjectSections);
  const { publish } = useSelector(getProjectSections);

  const { project } = useAppSelector(getProjectViewData);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();

  const {
    data: { isOpen: isPublicationOpen, groupName: groupPublishName },
    pending: { publishing: publishingGroupProcess }, // для групповой публикации разделов
  } = useAppSelector(getGroupPublishProcessData);

  const {
    data: { isOpen: isRequestOpen, groupName: groupRequestName },
    pending: { publishing: publishingGroupRequest },
  } = useAppSelector(getGroupRequestProcessData);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (publish && params.id) {
      dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
    }
  }, [publish]);

  useNonInitialEffect(() => {
    if (publishingGroupProcess || publishingGroupRequest || !params.id) {
      return;
    }

    dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
  }, [publishingGroupProcess, publishingGroupRequest]);

  const handleClick = () => {
    dispatch(setOpenAllNode());
  };

  const handleClosePublishingModal = () => {
    dispatch(setPublishingClosed());
  };

  const handleCloseRequestModal = () => {
    dispatch(setRequestClosed());
  };

  const isPending = !sections || duplicateTree || isLoad;

  const allToggleIcon = isAllToggle ? (
    <AllToggleFalse width="33" heigth="33" />
  ) : (
    <AllToggleTrue width="33" heigth="33" />
  );

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setErrorNull());
  }

  return (
    <>
      <div className={style.wrapper}>
        {isPending ? (
          <SkeletonSections />
        ) : (
          <>
            {cost === "Договорная" ? (
              <div></div>
            ) : (
              <div className={style.cost}>
                <div className={style.cost_num}>
                  <p>{trimPrice(cost)}</p>
                  <span>₽</span>
                </div>
                <p className={style.cost_text}>Опубликовано без налога (НДС)</p>
              </div>
            )}
            <div>
              <SectionsTree data={sections!} />
              <div className={style.allToggle}>
                <IconButton sx={sx} onClick={handleClick}>
                  {allToggleIcon}
                </IconButton>
              </div>
            </div>
          </>
        )}
      </div>
      <ModalWrapper
        handleCloseModal={handleClosePublishingModal}
        show={isPublicationOpen}
        head={
          <div className={cn(gstyle.wrapper, gstyle.topWrapper)}>
            <h2>Групповая публикация разделов «{groupPublishName}»</h2>
            <p>{project?.name}</p>
          </div>
        }
      >
        {isPublicationOpen && <ProcessPublication />}
      </ModalWrapper>
      <ModalWrapper
        handleCloseModal={handleCloseRequestModal}
        show={isRequestOpen}
        head={
          <div className={cn(gstyle.wrapper, gstyle.topWrapper)}>
            <h2>Заявка на разделы «{groupRequestName}»</h2>
            <p>{project?.name}</p>
          </div>
        }
      >
        {isRequestOpen && <ProcessRequest />}
      </ModalWrapper>
    </>
  );
};

export default Sections;
