import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { ExpertiseDocumentsTypes } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseDocuments/types/expertiseDocumentsTypes";
import cls from "./DocumentCard.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { DeleteButtonIcon } from "../../../../../newUI/SVG";
import { iconBtnSx, palette } from "../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseDeleteDocument } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseDocuments/services/fetchExpertiseDeleteDocument";
import { fetchExpertiseDocuments } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseDocuments/services/fetchExpertiseDocuments";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { getCanProcessInfo } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";

export const DocumentCard = memo(
  ({ date, name, num, id, file }: ExpertiseDocumentsTypes) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const can = useAppSelector(getCanProcessInfo);

    async function deleteDocument() {
      const result = window.confirm("Вы уверены, что хотите удалить договор?");
      if (result) {
        await dispatch(fetchExpertiseDeleteDocument(id));
        if (processId) {
          dispatch(fetchExpertiseDocuments(processId));
        }
      }
    }

    return (
      <div className={cls.card}>
        <p>{`${name} №${num} от ${date}`}</p>
        <div className={cls.card_btn}>
          <TextLink url={`/file/get?id=${file.id}`} target={"_blank"} redirect>
            <CustomButton width={160} background={palette.green}>
              Скачать
            </CustomButton>
          </TextLink>
          {can?.edit && (
            <IconButton sx={iconBtnSx} onClick={deleteDocument}>
              <DeleteButtonIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);
