import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ApiDeleteProjectChecklist,
  ApiInitialDataProject,
  ApiParentCheckList,
  ApiProjectSourceView,
  ApiTaskDateUpdate,
  ApiUpdateInitialDataProject,
} from "../../../services/api/project/project";
import { getErrorMessage, request } from "../../../services/api/requestHandler";
import { pushError, pushSuccessMessage } from "../../errorTrace";

export type InitialDataInterfaceType = {
  name: string;
  id: number;
  inWork: number;
  all: number;
  dot: boolean;
};

export type ProjectSourceData = {
  checklist: { id: number; parent_id: number; name: string };
  id: number;
  can: { download: boolean };
  author: boolean;
  critical: boolean;
  executor: boolean;
  hidden: boolean;
  date_complete: string;
  date_create: string;
  date_deadline: string;
  status: string;
  task_id: number;
};

export type CheckListResult = {
  result: {
    id: number;
    name: string;
    author: boolean;
    critical: boolean;
    executor: boolean;
    hidden: boolean;
    parent_id: number;
    update: boolean;
    delete: boolean;
  }[];
};

type CheckListData = {
  index: number;
  item: CheckListResult;
};

type TId = {
  idx: {
    parentId: number;
    index: number;
    id: string;
    searchValue: string;
    type: number;
  };
};

export const fetchParentCheckList = createAsyncThunk<CheckListData, TId>(
  "ParentCheckList/fetch",
  async ({ idx }, { rejectWithValue }) => {
    try {
      const { parentId, index, id, searchValue, type } = idx;
      const response = await ApiParentCheckList(
        parentId,
        +id,
        searchValue,
        type
      );
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return { item: { result }, index };
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

type ProjectSourceDataView = {
  index: number;
  item: ProjectSourceData[];
};

type TIdx = {
  idx: {
    id: number;
    parentId: number;
    index: number;
    searchValue?: string;
    type: number;
  };
};

export const fetchProjectSourceDataView = createAsyncThunk<
  ProjectSourceDataView,
  TIdx
>("projectSourceDataView/fetch", async ({ idx }, { rejectWithValue }) => {
  try {
    const { id, parentId, index, searchValue, type } = idx;
    const response = await ApiProjectSourceView(
      id,
      parentId,
      searchValue,
      type
    );
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    return {
      item: result,
      index,
    };
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export type TInitialData = {
  data: InitialDataInterfaceType[];
  allSourceData: number;
  overdueSourceData: number;
  receivedSourceData: number;
};

export const fetchProjectViewInitialData = createAsyncThunk<
  TInitialData,
  { id: number; searchValue?: string; load?: boolean; type: number }
>(
  "projectViewInitialData/fetch",
  async ({ id, searchValue = "", type }, { rejectWithValue }) => {
    try {
      const response = await ApiInitialDataProject(id, searchValue, type);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

type InitialDataUpdate = {
  update: {
    id: string;
    data: {
      critical: boolean;
      executor: boolean;
      hidden: boolean;
      id: number;
      select: boolean;
    }[];
    parentId: number;
    type: number;
  };
};

export const fetchProjectViewInitialDataUpdate = createAsyncThunk<
  any,
  InitialDataUpdate
>(
  "projectViewInitialDataUpdate/fetch",
  async ({ update }, { rejectWithValue }) => {
    try {
      const { id, data, parentId, type } = update;
      const response = await ApiUpdateInitialDataProject(
        id,
        data,
        parentId,
        type
      );
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

interface ITaskDateUpdateProps {
  id: number;
  dataParams: {
    date_create: string;
    date_deadline: string;
  };
}

export const fetchTaskDataUpdate = createAsyncThunk<any, ITaskDateUpdateProps>(
  "fetchTaskDataUpdate/fetch",
  async (data, { rejectWithValue }) => {
    const { id, dataParams } = data;
    try {
      const response = await ApiTaskDateUpdate(id, dataParams);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectChecklistById = createAsyncThunk(
  "sourceDataItem/delete",
  async (id: number, { dispatch }) => {
    await request(
      ApiDeleteProjectChecklist(id),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (error) => {
        dispatch(pushError(error));
      }
    )();
  }
);
