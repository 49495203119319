import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import cn from "classnames";
import styles from "./Heading.module.scss";

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export const Heading = memo(({ children, className, ...rest }: Props) => {
  return (
    <h2 className={cn(styles.heading, className)} {...rest}>
      {children}
    </h2>
  );
});
