import React from "react";
import cls from "./MilestoneType.module.scss";
import {
  CheckSvg,
  CircleWork,
  HexagonComplete,
  HexagonNotActive,
  HexagonWork,
} from "./MilestoneTypeSvg";

interface MileStoneTypeProps {
  status: "greenCheck" | "grayCheck" | "gray" | "";
  auto: boolean;
  children?: React.ReactNode;
}

export const MilestoneType = ({
  status,
  auto,
  children,
}: MileStoneTypeProps) => {
  const greenCheck = status === "greenCheck";
  const grayCheck = status === "grayCheck";
  const gray = status === "gray";

  if (greenCheck) {
    if (auto) {
      return (
        <div className={cls.circleComplete}>
          <CheckSvg />
          {children}
        </div>
      );
    }
    return (
      <div className={cls.hexagon}>
        <HexagonComplete />
        {children}
      </div>
    );
  }

  if (grayCheck) {
    if (auto) {
      return (
        <div className={cls.circleWork}>
          <CircleWork />
          {children}
        </div>
      );
    }
    return (
      <div className={cls.hexagon}>
        <HexagonWork />
        {children}
      </div>
    );
  }

  if (gray) {
    if (auto) {
      return <div className={cls.circleNotActive}>{children}</div>;
    }
    return (
      <div className={cls.hexagon}>
        <HexagonNotActive />
        {children}
      </div>
    );
  }
  return null;
};
