import React from "react";
import { range } from "lodash";
import { getMonth, getYear } from "date-fns";
import ReactDatePicker from "react-datepicker";
import classNames from "classnames";
import ru from "date-fns/locale/ru";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import * as Popper from "popper.js";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { Button } from "../../Buttons/Button/Button";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const years = range(2020, getYear(new Date()) + 5, 1);

export type MyDatePickerFromToProps = ComponentPropsType<{
  variant?: string;
  fontVariant?: string;
  openHandler?: VoidFunction;
  endDate?: Date | null;
  closeHandler?: (date: Date | null) => void;
  startDate?: Date | null;
  changeHandler?: (dates: Date | Date[] | null) => void;
  resetDateHandler?: VoidFunction;
  isOpen?: boolean;
  placement?: Popper.Placement;
  selectsRange?: boolean;
}>;

export const DatePickerContentFromTo: React.FC<MyDatePickerFromToProps> = ({
  variant,
  fontVariant,
  startDate = new Date(),
  endDate = null,
  changeHandler = () => {},
  isOpen = false,
  theme = "dark",
  placement = "top",
  resetDateHandler = () => {},
  selectsRange = true,
}) => {
  return (
    <div>
      <ReactDatePicker
        className={classNames(
          style.datePicker,
          variant,
          fontVariant,
          style[theme]
        )}
        dateFormat={"dd.MM.yyyy"}
        locale={ru}
        popperPlacement={placement}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="head">
            <div className="top">
              <Button
                onClick={resetDateHandler}
                color={"auto"}
                variant={"contained"}
              >
                Cбросить время
              </Button>
            </div>
            <div className="bottom">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    decreaseMonth();
                  }}
                  className={style.btn}
                  disabled={prevMonthButtonDisabled}
                >
                  <ChevronLeft className={style.chevron} />
                </button>
                <select
                  className={style.select}
                  value={getYear(date)}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  className={style.select}
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    increaseMonth();
                  }}
                  className={style.btn}
                  disabled={nextMonthButtonDisabled}
                >
                  <ChevronRight className={style.chevron} />
                </button>
              </div>
            </div>
          </div>
        )}
        open={isOpen}
        selected={startDate}
        onChange={changeHandler}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange}
        placeholderText={""}
        customInput={<div />}
        dayClassName={() => "day"}
      />
    </div>
  );
};
