export default {
  SET_DATA_PROJECT: "SET_DATA_PROJECT",
  SET_PROJECT_MENU: "SET_PROJECT_MENU",
  SET_EXECUTOR_TYPES: "SET_EXECUTOR_TYPES",
  SET_MAIN_PROJECT_INFO: "SET_MAIN_PROJECT_INFO",
  SET_PROJECT_CAN: "SET_PROJECT_CAN",
  SET_ACTUALLY_PROJECT: "SET_ACTUALLY_PROJECT",
  SET_DATA_PROJECT_SEARCH_GIP: "SET_DATA_PROJECT_SEARCH_GIP",
  CLEAR_DATA_PROJECT: "CLEAR_DATA_PROJECT",
};
