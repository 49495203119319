import { PageViewStateTypes } from "../../../types/stateTypes/postsStateTypes";

export const postViewInitialState: PageViewStateTypes = {
  id: 0,
  title: "",
  url: "",
  content: "",
  date_publication: "",
  photoUrl: "",
  tags: [],
};
