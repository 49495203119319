import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseCreateComment } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

export const fetchExpertiseCreateComment = createAsyncThunk<boolean, any>(
  "expertise/fetchExpertiseCreateComment",
  async ({ rowId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseCreateComment(rowId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
