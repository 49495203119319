import { useEffect, useState } from "react";
import { throttle } from "lodash";

export const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [init, setInit] = useState(false);
  const header = document.getElementById("header");

  const resizeHandler = () => {
    if (header) setHeaderHeight(Number(header!.offsetHeight));
  };

  window.addEventListener("resize", throttle(resizeHandler, 2000));

  useEffect(() => {
    if (header && !init) {
      setHeaderHeight(Number(header!.offsetHeight));
      setInit(true);
    }
  }, [header]);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return headerHeight;
};
