import {
  getProjectDocument,
  setToggleProcessDocs,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { sxTrue, sxFalse } from "../helpers/constants";
import { dotType } from "../helpers/dotType";

export const useDocumentsProcessDocs = () => {
  const dispatch = useAppDispatch();
  const { processDocs } = useAppSelector(getProjectDocument);
  function toggleProcessDos(index: number) {
    dispatch(setToggleProcessDocs(index));
  }

  return {
    sxTrue,
    sxFalse,
    dotType,
    processDocs,
    toggleProcessDos,
  };
};
