export const setPriceLimits = (
  dataRemoveExecutor: any,
  processesPriceLimits: any,
  setProcessesPriceLimits: any
) => {
  Object.values(dataRemoveExecutor.objects).forEach((project: any) => {
    Object.values(project.objects).forEach((process: any) => {
      const maxPrice = Number(process.maxExecutorPrice);
      const minPrice = Number(process.minExecutorPrice);
      const { id } = process.object;

      setProcessesPriceLimits({
        ...processesPriceLimits,
        [id]: { maxPrice, minPrice },
      });
    });
  });
};
