import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessBackToWork } from "../../../../services/api/projectProcess/documentations";
import { pushError } from "../../../errorTrace";

export const projectProcessTaskBackToWork = createAsyncThunk<boolean, number>(
  "projectProcess/projectProcessTaskBackToWork",
  async (taskId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessBackToWork(taskId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
