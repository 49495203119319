import { httpRequestGet, httpRequestPut } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessInitialData = (
  processId: number
): Promise<Response> => {
  // получить дерево договоры
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-files-dto?id=${processId}`
  );
};

const expandSource = `checklist,status,date_create,date_deadline,date_complete,can.download`;
export const ApiProjectProcessSourceData = (
  id: number,
  parentId: number,
  type: number | undefined
): Promise<Response> => {
  return httpRequestGet(
    `${
      config.localDomain
    }/v1/project-source-data/index?per-page=30&ProjectSourceDataSearch[project_id]=${id}&expand=${expandSource}&ProjectSourceDataSearch[parentId]=${parentId}${
      type ? `&ProjectSourceDataSearch[type]=${type}` : ""
    }`
  );
};

export const ApiProjectProcessSourceDataUpdate = (
  projectId: number,
  parentId: number,
  data: {
    critical: boolean;
    executor: boolean;
    hidden: boolean;
    id: number;
    select: boolean;
  }[],
  type: number
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/project-source-data/update?projectId=${projectId}&parentChecklistId=${parentId}&type=${type}`,
    {},
    data
  );
};

export const ApiProjectProcessCheckList = (
  id: number,
  parentId: number,
  type: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-checklist/list-by-project?projectId=${id}&parentId=${parentId}&type=${type}`
  );
};
