import { SetStateAction, Dispatch } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  createBook,
  updateBook,
  fetchProjectComposition,
} from "src/app/feature/ProjectView/Contents/thunks";
import { getProjectComposition } from "src/app/feature/ProjectView/Contents/getComposition";
import { TValuesForEditing } from "./Composition";
import style from "./Composition.module.scss";

type BookModalProps = {
  workId: number;
  id: string | undefined;
  setWorkId: Dispatch<SetStateAction<number | null>>;
  valuesForEditing: TValuesForEditing;
};

type TFormValues = {
  name: string;
  num: number;
};

const schema = Yup.object().shape({
  name: Yup.string().required("Необходимо заполнить «Наименование»."),
  num: Yup.number()
    .required("Необходимо заполнить «Номер книги».")
    .typeError("Значение должно быть числом")
    .positive("Значение должно быть положительным числом"),
});

const BookModal = ({
  workId,
  id,
  setWorkId,
  valuesForEditing,
}: BookModalProps) => {
  const dispatch = useAppDispatch();

  const { pending } = useAppSelector(getProjectComposition);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      name: (valuesForEditing?.name as string) ?? "",
      num: (valuesForEditing?.num as number) ?? "",
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ name, num }: TFormValues) => {
    await dispatch(
      valuesForEditing
        ? updateBook({ id: workId, name, num })
        : createBook({ id: workId, name, num })
    );
    setWorkId(null);

    if (id) {
      await dispatch(fetchProjectComposition(+id));
    }
  };

  return (
    <form className={style.modal} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("num")}
        name="num"
        label="Номер книги"
        error={Boolean(errors.num)}
        helperText={errors.num?.message}
      />
      <TextField
        {...register("name")}
        name="name"
        label="Наименование"
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      <Button
        type="submit"
        color="primary"
        disabled={pending.createBook || pending.updateBook}
      >
        Сохранить
      </Button>
    </form>
  );
};

export default BookModal;
