import React from "react";
import { TextField } from "@mui/material";
import cls from "../ModalRemoveExecutor.module.scss";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { textFieldSX } from "../../../../../../styles/restyle";

interface LastFormCardProps {
  process: {
    id: number;
    checked: boolean;
    code: string;
    min: number;
    max: number;
    price: number;
  };
  name: string;
  checkedRefund: boolean;
  onChangePrice: (event: any, id: number, name: string) => void;
  onChangeObjects: (event: any, name: string) => void;
}
export const LastFormCard = ({
  process,
  name,
  checkedRefund,
  onChangePrice,
  onChangeObjects,
}: LastFormCardProps) => {
  return (
    <>
      <label className={cls.labelAgree}>
        <CustomCheckbox
          onChange={(event) => onChangeObjects(event, name)}
          checked={process.checked}
          value={process.id}
        />
        {process.code}
      </label>
      {!checkedRefund && (
        <div className={cls.flex}>
          <TextField
            type={"number"}
            inputProps={{ min: +process.min, max: +process.max }}
            {...textFieldSX}
            value={process.price}
            onChange={(event) => onChangePrice(event, process.id, name)}
          />
          <span>без НДС</span>
        </div>
      )}
    </>
  );
};
