import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { trimNumber } from "src/utils/trimNumber";
import {
  fetchGipCancelSearch,
  fetchGipRemoveRequest,
  fetchProjectViewSearchGip,
  TTabsList,
} from "src/app/feature/ProjectView/thunks";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { getProjectCompareGip } from "src/app/feature/ProjectView/CompareReducer/projectCompareGip";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import styles from "../style.module.scss";
import { RequestGip } from "../RequestGip";
import { GipSearch } from "../GipSearch";

interface GipNotAssignedProps {
  setCurrentTab: (tab: keyof TTabsList) => void;
}
export const GipNotAssigned = ({ setCurrentTab }: GipNotAssignedProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const { gipTypes, isLoadSearchGip, isLoadRequestGip } =
    useSelector(getProjectViewData);
  const { compareGip } = useSelector(getProjectCompareGip);
  const { project } = useSelector(getProjectViewData);
  const canApplications = project?.can.edit && compareGip.length > 0;

  const [modalSearch, setModalSearch] = useState(false);
  const [modalRequest, setModalRequest] = useState(false);

  async function handleClick(str: string) {
    if (str === "Отменить" && params.id) {
      const result = window.confirm(
        "Вы уверены, что хотите отменить поиск ГИП"
      );
      const { id } = params;
      if (result) {
        await dispatch(fetchGipCancelSearch(id));
        await dispatch(fetchProjectViewSearchGip(id));
      }
    }
    if (str === "Отозвать" && params.id) {
      const result = window.confirm(
        "Вы уверены, что хотите отозвать заявку ГИП"
      );
      const { id } = params;
      if (result) {
        await dispatch(fetchGipRemoveRequest(id));
        await dispatch(fetchProjectViewSearchGip(id));
      }
    }
  }

  function clickRequestCount() {
    if (canApplications) {
      setCurrentTab("compareGip");
    }
  }

  if (project) {
    const canGip = project?.gipProcess?.status?.key === 2 || undefined;
    return (
      <div className={styles.gipNot}>
        <NewModal
          active={modalSearch}
          setActive={setModalSearch}
          isLoad={isLoadSearchGip}
          title={"Поиск ГИП"}
        >
          <GipSearch />
        </NewModal>
        <NewModal
          active={modalRequest}
          setActive={setModalRequest}
          isLoad={isLoadRequestGip}
          title={"Заявиться"}
        >
          {gipTypes && (
            <RequestGip
              gipTypes={gipTypes.userTypes}
              price={project?.gipProcess?.price}
            />
          )}
        </NewModal>
        <div>
          <div className={styles.gipNot_header}>
            <h2 className="gip__title">ГИП</h2>
            {project.can?.startSearchGip && (
              <Button onClick={() => setModalSearch(true)}>Искать</Button>
            )}
            {project.can?.cancelSearchGip && (
              <Button
                variant="outlined"
                onClick={() => handleClick("Отменить")}
              >
                Отменить
              </Button>
            )}
            {project.can?.requestGip && (
              <Button onClick={() => setModalRequest(true)}>Заявиться</Button>
            )}
            {project.can?.deleteRequestGip && (
              <Button
                variant="outlined"
                onClick={() => handleClick("Отозвать")}
              >
                Отозвать
              </Button>
            )}
          </div>
          {project?.can.startSearchGip && <p>Не назначен</p>}
          {canGip && (
            <div>
              <p className={styles.gipNot_types}>
                Требуемая форма собственности
              </p>
              <RequiredTypes types={project?.gipProcess?.gipTypes} />
            </div>
          )}
        </div>
        {canGip && (
          <div className={styles.gipNot_price}>
            <div className={styles.gipNot_price_ruble}>
              <p>{trimNumber(project?.gipProcess?.price)}</p>
              <span> в т.ч. НДС 20%</span>
            </div>
            <div
              className={classNames(
                styles.gipNot_price_applications,
                canApplications && styles.cursor
              )}
            >
              <span onClick={clickRequestCount}>{compareGip.length}</span>
              <p>Кол-во заявок</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};
