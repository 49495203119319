import * as Yup from "yup";
import moment from "moment";
import {
  dateFutureRequired,
  priceMinMaxRequired,
  messages,
  dateRequired,
} from "../../../../../../../../utils/SimplifiedYup";
import { timestampToDate } from "../../../../../../../shared/lib/helpers/timestampToDate";

export const requestUpdateSchema = Yup.object().shape({
  dateStart: dateFutureRequired("Начальный срок").test(
    "dateStartCustomerDate",
    messages.dateStartCustomerDate("Начальный срок"),
    function (value) {
      if (!this.parent.dateStartCustomer) {
        return true;
      }

      return value
        ? moment(value) >=
            moment(timestampToDate(this.parent.dateStartCustomer))
        : true;
    }
  ),
  dateLimit: dateRequired("Конечный срок").test(
    "dateStartBeforeDateLimit",
    messages.dateStartBeforeDateLimit,
    function (value) {
      if (!this.parent.dateStart) {
        return true;
      }

      return value ? moment(value) >= moment(this.parent.dateStart) : true;
    }
  ),
  price: priceMinMaxRequired("Стоимость", 1000, 10000000).test(
    "shouldBeIntNumber",
    messages.shouldBeIntNumber,
    (value: any) => {
      return !Number.isNaN(value) && Number.isInteger(value);
    }
  ),
  userType: dateRequired("Форма собственности"),
  bankAccount: Yup.mixed().when("withDocs", {
    is: (withDocs) => withDocs,
    then: dateRequired("Банковский счет"),
  }),
});
