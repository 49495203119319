import {
  ApiProjectsDataType,
  ApiProjectsGipType,
  ApiProjectsProcessesType,
  ApiProjectsProcessType,
} from "../../../../../types/api/responseTypes/apiProjectsType";
import {
  ProjectGipProcessType,
  ProjectProcess,
  ProjectsDataType,
} from "../../../../../types/stateTypes/indexPages/indexPageProjectsDataTypes";
import { getUserName } from "../../../../services/user/user";
import { processCostValue } from "../../../../services/price/price";

type ProjectsListType = (
  data: ApiProjectsDataType["data"]
) => ProjectsDataType[];

type ConvertType = (processes: { readonly [key: string]: number }) => {
  value: number;
  subtitle: string;
}[];

const cost = "Договорная";

export const getGipProcess = (
  item: ApiProjectsGipType
): ProjectGipProcessType => {
  return {
    dateComplete: item.date_complete,
    dateStart: item.date_start,
    gipTypes: item.gipTypes.length
      ? [
          {
            key: item.gipTypes[0].key,
            value: item.gipTypes[0].value,
            full: item.gipTypes[0].full,
          },
        ]
      : null,
    price: item.price,
    projectId: item.project_id,
  };
};

const convertSections: ConvertType = (processes) => {
  return [
    {
      value: processes.availableForYou,
      subtitle: "по специализации",
    },
    {
      value: processes.available,
      subtitle: "без нанятого исполнителя",
    },
    {
      value: processes.published,
      subtitle: "всего по проекту",
    },
  ];
};

const sortProcesses = (
  processes: ApiProjectsProcessType[]
): ProjectProcess[] => {
  const result = processes.reduce<ProjectProcess[][]>(
    (acc, item) => [
      ...acc,
      item.processes.map((data: ApiProjectsProcessesType) => ({
        type: item.type,
        code: data.code,
        price: data.price,
        processId: data.processId,
        secure: data.secure,
      })),
    ],
    []
  );

  return result
    .flat()
    .sort((a: ProjectProcess, b: ProjectProcess) => a.type - b.type);
};

export const createProjectsListFromApi: ProjectsListType = (data) =>
  data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      code: item.code,
      region: { title: item.region.title },
      status: { value: item.status.value },
      author: {
        id: item.author?.id,
        name: getUserName(item.author),
        photo: item.author.photo,
        type: item.authorType.listName,
      },
      gip: item.gip
        ? {
            id: item.gip.id,
            photo: item.gip.photo,
            name: getUserName(item.gip),
            type: item.gipAccess[0].userType.listName,
          }
        : null,
      gipProcess: item.gipProcess ? getGipProcess(item.gipProcess) : null,
      designType: { value: item.designType.value },
      budget: processCostValue(item.budget_max),
      advance: item.advance_pay,
      start: item.date_start,
      end: item.date_end,
      countProcesses: convertSections(item.countProcesses),
      expertise: item.expertise,
      publishedCost:
        item.processPublishedCost === cost
          ? "По договоренности"
          : processCostValue(item.processPublishedCost),
      projectProcesses: sortProcesses(item.projectProcesses),
    };
  });
