import React, { memo, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import { CheckIconType } from "../../../../newUI/CheckIconType/CheckIconType";
import cls from "./TreatyBlock.module.scss";
import { useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoPublished } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getCanProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import {
  RefType,
  ThreeDotButton,
} from "../../../../newUI/ThreeDotButton/ThreeDotButton";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalAddition } from "./ModalAddition/ModalAddition";
import { getProjectProcessAdditionalIsLoad } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessAdditional";

interface ITreatyBlockProps {
  kac: boolean;
  vor: boolean;
  vor_pnr: boolean;
  bim: boolean;
}
export const TreatyBlock: React.FC<ITreatyBlockProps> = memo(
  ({ kac, vor, vor_pnr, bim }) => {
    const published = useAppSelector(getProjectProcessInfoPublished);
    const canProcess = useAppSelector(getCanProcessInfo);
    const isLoadAdditional = useAppSelector(getProjectProcessAdditionalIsLoad);
    const threeDotButtonRef = useRef<RefType>(null);
    const [modalAddition, setModalAddition] = useState(false);
    const closeThreeDot = () => {
      if (threeDotButtonRef.current) {
        threeDotButtonRef.current.handleClose();
      }
    };

    const showOptions = canProcess?.update && published;

    const closeModalAdditional = useCallback(() => {
      setModalAddition(false);
    }, []);

    function updateAddition() {
      setModalAddition(true);
      closeThreeDot();
    }

    return (
      <div className={classNames(cls.treaty, cls.card)}>
        {canProcess?.update && (
          <Modal
            active={modalAddition}
            setActive={setModalAddition}
            isLoad={isLoadAdditional}
          >
            <ModalAddition closeModal={closeModalAdditional} />
          </Modal>
        )}
        <div className={cls.treaty_header}>
          <h1>Дополнительные требования</h1>
          {showOptions && (
            <ThreeDotButton ref={threeDotButtonRef}>
              <MenuItem onClick={updateAddition} disableRipple>
                Редактировать
              </MenuItem>
            </ThreeDotButton>
          )}
        </div>

        {published && (
          <div className={cls.treaty_check}>
            <CheckIconType
              colorCheck="#3BAF6A"
              colorClose="#883636"
              isCheck={bim}
              text="BIM"
              fontSize="19px"
            />
            <CheckIconType
              colorCheck="#3BAF6A"
              colorClose="#883636"
              isCheck={vor}
              text="Ведомость объемов работ"
              fontSize="19px"
            />
            <CheckIconType
              colorCheck="#3BAF6A"
              colorClose="#883636"
              isCheck={vor_pnr}
              text="Ведомость пусконаладочных работ"
              fontSize="19px"
            />
            <CheckIconType
              colorCheck="#3BAF6A"
              colorClose="#883636"
              isCheck={kac}
              text="Конъюнктурный анализ цен"
              fontSize="19px"
            />
          </div>
        )}
      </div>
    );
  }
);
