import { KanbanColumns, KanbanStatuses } from "src/FSD/entities/kanban";

export const directions = [
  {
    from: KanbanColumns.ALL_TASKS,
    to: KanbanColumns.IN_WORK,
  },
  {
    from: KanbanColumns.IN_WORK,
    to: KanbanColumns.IS_DONE,
  },
  {
    from: KanbanColumns.IS_DONE,
    to: KanbanColumns.IN_WORK,
  },
  {
    from: KanbanColumns.IS_DONE,
    to: KanbanColumns.DONE,
  },
  {
    from: KanbanColumns.IN_WORK,
    to: KanbanColumns.ALL_TASKS,
  },
  {
    from: KanbanColumns.IS_DONE,
    to: KanbanColumns.ALL_TASKS,
  },
];

export const statusDirections = [
  {
    from: KanbanStatuses[KanbanColumns.ALL_TASKS],
    to: KanbanStatuses[KanbanColumns.IN_WORK],
  },
  {
    from: KanbanStatuses[KanbanColumns.IN_WORK],
    to: KanbanStatuses[KanbanColumns.IS_DONE],
  },
  {
    from: KanbanStatuses[KanbanColumns.IS_DONE],
    to: KanbanStatuses[KanbanColumns.IN_WORK],
  },
  {
    from: KanbanStatuses[KanbanColumns.IS_DONE],
    to: KanbanStatuses[KanbanColumns.DONE],
  },
  {
    from: KanbanStatuses[KanbanColumns.IN_WORK],
    to: KanbanStatuses[KanbanColumns.ALL_TASKS],
  },
  {
    from: KanbanStatuses[KanbanColumns.IS_DONE],
    to: KanbanStatuses[KanbanColumns.ALL_TASKS],
  },
];
