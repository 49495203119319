import { memo } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";

type Props = {
  workId: number;
};

export const ReasonText = memo(({ workId }: Props) => (
  <>
    <Paragraph color="warning">Внимание!</Paragraph>
    <Paragraph color="light">
      В случае отсутствия в кошельке исполнителя необходимой суммы для возврата
      Заказчику, возврат средств через платформу не выполняется. Пожалуйста,
      подготовьте{" "}
      <TextLink
        redirect
        url={`/process-work/get-email?id=${workId}`}
        target="_blank"
      >
        официальное письмо
      </TextLink>{" "}
      на исполнителя раздела о возврате выплаченных средств.
    </Paragraph>
    <Paragraph color="light">
      Не забудьте подписать дополнительное соглашение или соглашение о
      расторжении, в случае снятия исполнителя со всех разделов без частичной
      оплаты за выполненные работы.
    </Paragraph>
    <TextLink
      redirect
      url={`/process-work/get-email?id=${workId}`}
      target="_blank"
    >
      Скачать проект оформленного текста письма с указанием банковских
      реквизитов.
    </TextLink>
  </>
));
