import React from "react";
import cls from "../NewTaskCard.module.scss";
import { Dot } from "../../Dot/Dot";
import { TooltipWrapper } from "../../TooltipWrapper/TooltipWrapper";

interface NameBlockProps {
  title: string;
  author: any;
  dot: boolean;
  name: string;
}
export const NameBlock = ({ author, dot, title, name }: NameBlockProps) => {
  return (
    <div className={cls.name}>
      <div className={cls.name_dot}>
        {dot && <Dot status={"warning"} size={12} />}
        <TooltipWrapper content={title}>
          <p className={cls.title}>{name}</p>
        </TooltipWrapper>
      </div>
      <span>{`${author.surname} ${author.name} ${author?.patronymic}`}</span>
    </div>
  );
};
