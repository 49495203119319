import { httpRequestPostMultiData, httpRequestPut } from "../../api";
import { config } from "../../../../../app.cofig";

export const ApiUpdatePublishExpertise = (
  id: number,
  data: any
): Promise<Response> => {
  // редактировать публикацию
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/update-published?id=${id}`,
    {},
    data
  );
};

export const ApiPublishExpertise = (
  id: number,
  data: any
): Promise<Response> => {
  // опубликовать
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/publish?id=${id}`,
    {},
    data
  );
};

export const ApiCreateDocument = (
  processId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/expertise/document/create?processId=${processId}`,
    {},
    formData
  );
};

export const ApiCreateConclusion = (
  processId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/expertise/conclusion/create?processId=${processId}`,
    {},
    formData
  );
};
