import React, { Dispatch, SetStateAction } from "react";
import { SwitchLabel } from "src/FSD/shared/uiKit/v2/SwitchLabel";
import styles from "./Header.module.scss";

type TProps = {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
};

export const Header = ({ checked, setChecked }: TProps) => {
  return (
    <div className={styles.layout}>
      <SwitchLabel
        label="Показать только на подписании"
        checked={checked}
        onChange={() => setChecked((prev) => !prev)}
      />
    </div>
  );
};
