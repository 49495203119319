import React, { memo } from "react";
import cls from "./MileStoneCard.module.scss";
import { MileStoneCheck } from "./MileStoneCheck/MileStoneCheck";
import { IMileStone } from "../../../app/feature/ProjectProcessView/MileStone/types/projectProcessMileStoneTypes";

export enum MILESTONE_TYPE {
  PROJECT = "PROJECT",
  PROCESS = "PROCESS",
}
interface MileStoneCardProps {
  stages: IMileStone[];
  type: MILESTONE_TYPE;
  processName?: string;
}
export const MileStoneCard = memo(
  ({
    stages,
    type = MILESTONE_TYPE.PROCESS,
    processName,
  }: MileStoneCardProps) => {
    return (
      <div className={cls.flex}>
        {stages?.map((mile, index) => (
          <div className={cls.mile} key={mile.id}>
            <div className={cls.mile_percent}>
              <p>{mile.totalPercent}%</p>
              <div />
            </div>
            <MileStoneCheck
              down={Boolean(index % 2)}
              text={mile.name}
              processName={processName}
              link={mile.link}
              status={mile.status}
              stageId={mile.id}
              canMile={mile?.can?.complete}
              type={type}
            />
          </div>
        ))}
      </div>
    );
  }
);
