import React from "react";
import { useTitle } from "../../hooks/useTitle";
import style from "../Registration/EmailConfirmPage/style.module.scss";
import { Wrapper } from "../../UI/templates";
import { ForgotPasswordForm } from "../../features/Forms/ForgotPasswordForm/ForgotPasswordForm";

export const ForgotPage = () => {
  useTitle("Восстановление пароля");

  return (
    <div className={style.pageContainer}>
      <Wrapper>
        <div className={style.titleContainer}>
          <h1 className={style.pageTitle}>Восстановление пароля</h1>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <ForgotPasswordForm />
        </div>
      </Wrapper>
    </div>
  );
};
