import {
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "src/FSD/entities/kanban";
import { pushErrorMessage } from "../../../../../../../app/feature/errorTrace";
import { ColumnShift, Errors } from "./useKanbanDnD";

export const atSameColumn = ({
  dispatch,
  sourceColumnId,
  sourceRowId,
  destinationRowId,
  allTasks,
  rowTasks,
  result,
  source,
  destination,
}: ColumnShift) => {
  // на то же самое место
  if (source.index === destination.index) {
    return;
  }

  // пытается закинуть в другую группу
  if (sourceRowId !== destinationRowId) {
    dispatch(pushErrorMessage(Errors.NO_SUCH_DIRECTION));
    return;
  }

  // перемещаем таски в колонке "Все задачи"
  if (sourceRowId === KanbanStatuses[KanbanColumns.ALL_TASKS]) {
    const tasks = [...allTasks];
    const [reorderedItem] = tasks.splice(result.source.index, 1);
    tasks.splice(result.destination!.index, 0, reorderedItem);

    dispatch(KanbanModel.setAllTasks(tasks));

    return;
  }

  // меняем местами элементы в колонке и выходим
  const rowTasksColumns = rowTasks[sourceRowId].columns;
  const tasks = Array.from(rowTasksColumns[sourceColumnId]);
  const [reorderedItem] = tasks.splice(result.source.index, 1);
  tasks.splice(result.destination!.index, 0, reorderedItem);

  const updatedRowTasks = {
    ...rowTasksColumns,
    [sourceColumnId]: tasks,
  };

  dispatch(
    KanbanModel.setRowTasks({
      rowId: destinationRowId,
      updatedTasks: updatedRowTasks,
    })
  );
};
