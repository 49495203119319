import React, { memo, useEffect } from "react";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { fetchProcessMaterialViewForm } from "../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialViewForm";
import {
  getProjectProcessMaterialViewForms,
  getProjectProcessMaterialViewFormsIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialViewForms";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import { AttrType } from "../../../../../../app/feature/ProjectProcessView/Material/types/projectProcessViewFormTypes";
import cls from "./ModalViewForm.module.scss";
import { FormWrapper } from "../../../../../newUI/FormWrapper/FormWrapper";
import { textFieldSX } from "../../../../../../styles/restyle";

interface ModalViewFormProps {
  materialId: number;
}
export const ModalViewForm = memo(({ materialId }: ModalViewFormProps) => {
  const dispatch = useAppDispatch();
  const viewForm = useAppSelector(getProjectProcessMaterialViewForms);
  const isLoadViewForm = useAppSelector(
    getProjectProcessMaterialViewFormsIsLoad
  );
  useEffect(() => {
    dispatch(fetchProcessMaterialViewForm(materialId));
  }, [materialId]);

  if (isLoadViewForm) {
    return <ModalPreloader />;
  }

  const renderGroup = (group: string, attrs: AttrType[]) => {
    return (
      <tbody key={group}>
        {attrs.map((attr) => (
          <tr key={attr.label}>
            <td>{attr.label}</td>
            <td>
              {Array.isArray(attr.data.value) ? (
                <TextField
                  select
                  {...textFieldSX}
                  value={attr.data.value}
                  disabled
                >
                  {attr.data.value.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </TextField>
              ) : (
                <TextField {...textFieldSX} value={attr.data.value} disabled />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <FormWrapper title={"Опросный лист"}>
      {viewForm?.map((form) => (
        <table key={form.group} className={cls.table}>
          <thead>
            <tr>
              <th colSpan={2}>{form.group}</th>
            </tr>
          </thead>
          {form.attrs.map((groupAttrs) => renderGroup(form.group, groupAttrs))}
        </table>
      ))}
    </FormWrapper>
  );
});
