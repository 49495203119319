import { memo } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { CheckSVG } from "src/components/newUI/SVG";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { taskCompleteThunk } from "src/app/feature/taskComplete/services/taskCompleteThunk";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

export const CompleteBtn = memo(({ taskId }: any) => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);

  async function completeHandle(id: number) {
    const data = {
      CompleteForm: {
        status: 4,
      },
    };
    await dispatch(taskCompleteThunk({ taskId: id, data }));
    if (processId) {
      await dispatch(fetchExpertiseRemarks(processId));
    }
  }

  return (
    <>
      <IconButton
        sx={iconBtnSx}
        title={"Проверить"}
        onClick={() => {
          completeHandle(taskId);
        }}
      >
        <CheckSVG width={"24"} height={"24"} />
      </IconButton>
    </>
  );
});
