import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../../types/other/components/componentPropsType";

export type FlagProps = ComponentPropsType<{
  title: string;
  subtitle: string;
  lineDirection: "up" | "down";
  lineHeight?: number;
  marginVariant?: string;
}>;

export const Flag: React.FC<FlagProps> = ({
  title = "",
  subtitle = "",
  lineDirection = "down",
  lineHeight = 0,
  variant,
}) => {
  return (
    <div className={classNames(style.flag, variant)}>
      <div className={style.flagPoint}>
        <div
          style={{ height: `${lineHeight}px` }}
          className={classNames(
            style.flagLine,
            lineDirection === "down" ? style.down : style.up
          )}
        ></div>
      </div>
      <div>
        <p className={style.flagTitle}>{title}</p>
        <p className={style.subTitle}>{subtitle}</p>
      </div>
    </div>
  );
};
