import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { SelectWithPopper } from "../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetUserResponsibilityTypes } from "../../../app/services/api/user/user";
import { getUserId } from "../../../app/services/auth/auth";
import style from "./style.module.scss";
import { ApiUserTypeType } from "../../../types/api/primaryTypes/apiUserTypeType";

export type ResponsibilityTypeItem = {
  title: string;
  listName: string;
  typeName: string;
  id: string;
};

export type ResponsibilityTypesProps = ComponentPropsType<{
  changeHandler?: (type: ResponsibilityTypeItem) => void;
  blurHandler?: (event: any) => void;
  changeFieldHandler?: (event: any) => void;
  value?: any | undefined;
  paperOptions?: { [key: string]: any };
  label?: string;
  error?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  userID?: number | string | null;
  disabled?: boolean;
  newDesign?: boolean;
}>;

export const ResponsibilityTypes: React.FC<ResponsibilityTypesProps> = ({
  variant = "",
  changeHandler = () => {},
  blurHandler,
  changeFieldHandler,
  value,
  paperOptions = { marginTop: 4, color: "#26313a" },
  label = "",
  error,
  id,
  name = "",
  placeholder = "",
  userID,
  disabled,
  newDesign,
}) => {
  const userId = userID || getUserId();
  const { enqueueSnackbar } = useSnackbar();
  const [promptsShown, setPromptsShown] = useState<boolean>();
  const [allTypes, setAllTypes] = useState<ResponsibilityTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<
    ResponsibilityTypeItem | undefined
  >(undefined);
  const handleChangeVisibility = () => setPromptsShown((prev) => !prev);

  const onLoadTypes = () => {
    request(
      ApiGetUserResponsibilityTypes(userId),
      (data) => {
        setAllTypes([
          ...data.userTypes.map((item: ApiUserTypeType) => {
            return {
              id: item.id,
              title: item.title,
              listName: item.listName,
              typeName: item.typeName,
            };
          }),
        ]);
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов экспертизы", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  };

  useEffect(() => {
    if (userId) {
      onLoadTypes();
    }
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => item.id === value);
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  const listener = (e: any) => {
    if (!document?.getElementById("clickBox")?.contains(e.target)) {
      setPromptsShown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", listener);

    return () => {
      window.removeEventListener("click", listener);
    };
  });

  return newDesign ? (
    <>
      <button
        id={"clickBox"}
        name={name}
        className={style.styledSelect}
        onClick={handleChangeVisibility}
      >
        Изменить форму собственности
        {promptsShown && (
          <div className={style.promptsList}>
            {allTypes.map((item) => (
              <button onClick={() => changeHandler(item)} value={item.id}>
                {item.listName}
              </button>
            ))}
          </div>
        )}
      </button>
    </>
  ) : (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      id={id}
      name={name}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      options={allTypes}
      label={label}
      variant={classNames(variant)}
      disableClear={true}
      fullWidth={true}
      paperOptions={paperOptions}
      blurHandler={blurHandler}
      changeHandlerField={changeFieldHandler}
      error={error}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

/*


type StyledSelectOptionType = { id: number; title: string };

type StyledSelectProps = {
  value: number;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  options: StyledSelectOptionType[];
};

export const StyledSelect: React.FC<StyledSelectProps> = ({
  value,
  onChange,
  options,
}) => (
  <select className={style.styledSelect} value={value} onChange={onChange}>
    {options.map((item: StyledSelectOptionType) => (
      <option value={item.id}>{item.title}</option>
    ))}
  </select>
);


 */
