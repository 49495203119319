import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой изменения роли человека в организации
 */

export const showEventChangeUserRole = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventChangeUserRole: {
      isShow: true,
      data: {
        ...state.eventChangeUserRole.data,
      },
    },
  };
};

export const closeEventChangeUserRole = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventChangeUserRole: {
    isShow: false,
    data: {
      ...state.eventChangeUserRole.data,
    },
  },
});

export const setEventChangeUserRole = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventChangeUserRole: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorChangeUserRole = (state: StateType) =>
  state.eventEmitter.eventChangeUserRole.isShow;

export const selectorChangeUserRoleData = (state: StateType) =>
  state.eventEmitter.eventChangeUserRole.data;
