import { request } from "../../../../app/services/api/requestHandler";
import { ApiTaskGetFiles } from "../../api/v1/task/queries";

export const getTaskFiles = async (taskId: number) => {
  let response: any = [];

  await request(ApiTaskGetFiles(taskId), (data) => {
    response = data;
  })();

  return response.files;
};
