export const groupsList = [
  {
    id: "",
    title: "Без группировки",
  },
  {
    id: "skill",
    title: "По специализации",
  },
  {
    id: "type",
    title: "По типу",
  },
];
