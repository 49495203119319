import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const view = (builder: QueryBuilder) =>
  builder.query<ApiTaskResponses.View, TParams>({
    query: (params) => ({
      url: `v1/task/view`,
      params: {
        id: params.id,
        fields: concatenateParams(params.fields),
        expand: concatenateParams(params.expand),
      },
    }),
  });
