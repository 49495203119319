import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { withClassCurry } from "../../../../utils";

export type TimeLineProps = ComponentPropsType<{
  time: string;
}>;

export const TimeLine: React.FC<TimeLineProps> = ({
  time = "",
  theme = "old",
  variant = "",
}) => {
  const withTheme = withClassCurry(style[theme]);
  return (
    <div className={withTheme(classNames(style.timeLine, variant))}>
      <div className={withTheme(style.timeWrapper)}>
        <div className={withTheme(style.time)}>{time}</div>
      </div>
    </div>
  );
};
