import {
  CounterType,
  UserDataListType,
} from "../../../../../../types/stateTypes/indexPages/indexPageUserDataType";
import {
  createUserRating,
  getUserFullStringAddress,
  getUserName,
  userExperience,
} from "../../../../../services/user/user";
import { ApiUserGipType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../../../../app.cofig";
import { ApiGipPageDataType } from "../../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const createCounterByGip: (data: ApiUserGipType) => CounterType[] = (
  data
) => {
  return [
    {
      title: "Стаж в отрасли",
      value: userExperience(data.additionalInfo!.job_duration, false),
      url: `/user/${data.id}?tab=jobPlace`,
    },
    {
      title: "Выполнено работ",
      value: data.gipDoneProjectCount,
      url: `/project/search?ProjectSearch[gipId]=${data.id}&ProjectSearch[status]=3`,
    },
    {
      title: "Отзывы",
      value: data.feedbacks?.length || 0,
      url: `/user/${data.id}?tab=feedback`,
    },
  ];
};

export const createGipListFromApi: (
  data: ApiGipPageDataType["data"]
) => UserDataListType = (data) =>
  data.map((item) => ({
    name: getUserName(item),
    address: item.region ? getUserFullStringAddress(item) : null,
    aboutText: item.about!,
    counterItems: createCounterByGip(item as ApiUserGipType),
    id: item.id,
    isConfirm: item.tested!,
    ratingValue: createUserRating(Number(item.rating!.gip)),
    specialItems: item.skills!.map((item) => item.name),
    avatarUrl: `${config.localDomain}${item.photo}`,
    workWithBim: item.work_with_bim!,
    premium: item.premium,
  }));
