import { useCallback, useEffect } from "react";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import { KanbanModel } from "src/FSD/entities/kanban";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { pushSuccessMessage } from "../../../../../../app/feature/errorTrace";

export const useKanbanPage = () => {
  const isMobile = useIsMobile(680);
  const isTablet = useIsMobile(680); // заменить, когда дойдем до планшетной версии

  const dispatch = useAppDispatch();

  const isCreateFreeTaskOpen = useAppSelector(
    KanbanModel.getIsCreateFreeTaskOpen
  );

  const viewTask = useAppSelector(KanbanModel.getViewTask);

  const isEditFreeTaskOpen = useAppSelector(KanbanModel.getIsEditFreeTaskOpen);

  const editFreeTaskId = useAppSelector(KanbanModel.getEditFreeTaskId);

  const handleCloseCreateFreeTaskModal = useCallback(() => {
    dispatch(
      KanbanModel.setCreateFreeTask({
        isOpen: false,
      })
    );
  }, []);

  const handleCloseEditFreeTaskModal = useCallback(() => {
    dispatch(
      KanbanModel.setEditFreeTask({
        isOpen: false,
      })
    );
  }, []);

  const handleCloseViewTaskModal = useCallback(() => {
    dispatch(
      KanbanModel.setViewTask({
        isCompletion: false,
        isOpen: false,
        taskId: null,
        header: "",
      })
    );
  }, []);

  const onFreeTaskDelete = useCallback(({ taskId, status, partGroupId }) => {
    dispatch(KanbanModel.removeFreeTask({ taskId, status, partGroupId }));
    dispatch(pushSuccessMessage());
  }, []);

  const onFreeTaskUpdate = useCallback((newTask) => {
    dispatch(KanbanModel.updateFreeTask(newTask));
    dispatch(pushSuccessMessage());
  }, []);

  useEffect(
    () => () => {
      dispatch(KanbanModel.clearState());
    },
    []
  );

  return {
    isMobile,
    isTablet,
    editFreeTaskId,
    isCreateFreeTaskOpen,
    isEditFreeTaskOpen,
    handleCloseCreateFreeTaskModal,
    handleCloseEditFreeTaskModal,
    onFreeTaskDelete,
    onFreeTaskUpdate,
    viewTask,
    handleCloseViewTaskModal,
  } as const;
};
