import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiUpdateTask } from "../../../../services/api/organisation/organisation";

export const updateTaskById = createAsyncThunk(
  "task/updateTaskById",
  async ({ taskId, data }: any, { dispatch }) => {
    let response: any = {};
    await request(
      ApiUpdateTask(taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
