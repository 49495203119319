import { Draft, PayloadAction, current } from "@reduxjs/toolkit";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import { KanbanColumns, KanbanStatuses, KanbanType } from "../..";
import { mapFreeTask } from "../dataMappers";

const FREE_TASK_HEADER = "Оперативная задача";

export const createAndDropFreeTask = (
  state: Draft<KanbanType.State>,
  action: PayloadAction<any>
) => {
  const IN_WORK_COLUMN = KanbanStatuses[KanbanColumns.IN_WORK];

  const {
    groupBy: { id: groupById },
  } = state.data;

  // берем partGroupId и taskId созданной оперативной задачи
  const { id: taskId, partGroup } = action.payload;

  const newFreeTask = mapFreeTask(action.payload);

  if (groupById === "") {
    state.tasks.rowTasks[0].columns[IN_WORK_COLUMN].unshift(newFreeTask);
  }

  if (groupById === "skill") {
    const rowId = partGroup.id;

    // проверка на то что такая группа есть, если нет то она создастся
    if (!state.tasks.rowTasks[rowId]) {
      state.tasks.rowTasks[rowId] = {
        header: partGroup.code,
        columns: {
          [KanbanStatuses[KanbanColumns.IN_WORK]]: [],
          [KanbanStatuses[KanbanColumns.IS_DONE]]: [],
          [KanbanStatuses[KanbanColumns.DONE]]: [],
        },
      };
    }

    state.tasks.rowTasks[rowId].columns[IN_WORK_COLUMN].unshift(newFreeTask);
  }

  if (groupById === "type") {
    const rowId = WorkFlowClasses.FREE_TASK;

    // проверка на то что группа "Оперативная задача" есть, если нет то она создастся
    if (!state.tasks.rowTasks[rowId]) {
      state.tasks.rowTasks[rowId] = {
        header: FREE_TASK_HEADER,
        columns: {
          [KanbanStatuses[KanbanColumns.IN_WORK]]: [],
          [KanbanStatuses[KanbanColumns.IS_DONE]]: [],
          [KanbanStatuses[KanbanColumns.DONE]]: [],
        },
      };
    }

    state.tasks.rowTasks[rowId].columns[IN_WORK_COLUMN].unshift(newFreeTask);
  }

  // скроллим до нужной группы
  setTimeout(() => {
    document.querySelector(`#task-anchor-${taskId}`)?.scrollIntoView({
      behavior: "smooth",
    });
  });
};
