import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import style from "./style.module.scss";
import { TSelectItem } from "../../../../../../features/Forms/SetGipForm/types";
import { Select } from "../../../../../../UI/components/FormComponentns/Select";
import CustomCheckbox from "../../../../../../newUI/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { getMyProjectsByUserType } from "../../../../../../../app/feature/ProjectView/AdminReducer/thunks";
import { getDashboardDestinations } from "../../../../../../../app/feature/dashboard/destinations";
import {
  clearProjects,
  getProjectChecklist,
} from "../../../../../../../app/feature/project/projectChecklist";
import { config } from "../../../../../../../app.cofig";

type TFieldValues = {
  project: TSelectItem;
  executor: boolean;
};

const ModalSourceDataReport = () => {
  const dispatch = useAppDispatch();

  const {
    data: { projects },
    pending,
  } = useAppSelector(getProjectChecklist);

  const { handleSubmit, control, setValue, watch } = useForm<TFieldValues>({
    defaultValues: {
      project: projects[0],
      executor: false,
    },
  });

  const { userType: userTypeId } = useAppSelector(getDashboardDestinations);

  const executorValue = watch("executor");

  const onSubmit = (data: TFieldValues) => {
    const {
      project: { id },
      executor,
    } = data;

    const param = id === "all" ? `userTypeId=${userTypeId}` : `projectId=${id}`;

    window.open(
      `${config.localDomain}/project-source-data/pdf?executor=${Number(
        executor
      )}&${param}`,
      "_blank"
    );
  };

  useEffect(() => {
    dispatch(getMyProjectsByUserType(userTypeId));

    return () => {
      dispatch(clearProjects());
    };
  }, []);

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <h2>Отчет по исходным данным</h2>
      <Controller
        name="project"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            isLoading={pending.projects}
            disableClear
            label={"Проект"}
            options={projects}
            value={value}
            variant={style.selectField}
            changeHandler={(_, value) => {
              onChange(value);
            }}
          />
        )}
      />
      <div className={style.checkboxes}>
        <label className={style.checkboxes__checkBox}>
          <CustomCheckbox
            editing
            onChange={() => {
              setValue("executor", false);
            }}
            checked={!executorValue}
          />
          <p>Общий отчёт</p>
        </label>
        <label className={style.checkboxes__checkBox}>
          <CustomCheckbox
            editing
            onChange={() => {
              setValue("executor", true);
            }}
            checked={executorValue}
          />
          <p>Отчёт по пунктам заказчика</p>
        </label>
      </div>
      <div className={style.controls}>
        <CustomButton
          disabled={pending.projects}
          forceDisabled
          type="submit"
          background={palette.blue}
          width={110}
        >
          Скачать
        </CustomButton>
      </div>
    </form>
  );
};

export default ModalSourceDataReport;
