import React, { memo } from "react";
import { ClickAwayListener, Popper } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import style from "./EditDateDeadLine.module.scss";
import { NotApproveIcon } from "../../../../../newUI/SVG";
import {
  fetchProjectTaskOutTree,
  fetchTaskDateDeadlineUpdate,
  TaskValueType,
} from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { DateCreate } from "../../../../../features/SpecialForm/DateCreate/DateCreate";
import { CircularIndeterminate } from "../../../../../UI/components/Project/Skeleton";
import { getProjectTasks } from "../../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import { fetchProjectProcessOutTasks } from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";

interface IEditDateDeadLine {
  node: TaskValueType;
  projectId: number | undefined;
  processId: number | undefined;
  type: "project" | "process";
}
export const EditDateDeadLine = memo(
  ({ node, projectId, type, processId }: IEditDateDeadLine) => {
    const { isLoadDateUpdate } = useSelector(getProjectTasks);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { control } = useForm();

    const minDate = node.tasks && node.tasks[node.tasks.length - 1].minDate;
    const maxDate = node.tasks && node.tasks[node.tasks.length - 1].maxDate;
    const taskId = node.tasks && node.tasks[node.tasks.length - 1].task.id;
    const dateCreate =
      node.tasks && node.tasks[node.tasks.length - 1].task.date_deadline;

    const minDateParse = moment(minDate, "DD.MM.YYYY").toDate();
    const maxDateParse = moment(maxDate, "DD.MM.YYYY").toDate();
    const maxCreateParse = moment(dateCreate, "DD.MM.YYYY").toDate();
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
      setAnchorEl(null);
    };

    const onChangeDate = async (data: any) => {
      const dataParams = {
        date_deadline: moment(data.endDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
      };
      if (taskId) {
        await dispatch(fetchTaskDateDeadlineUpdate({ id: taskId, dataParams }));
      }
      if (projectId && type === "project") {
        await dispatch(fetchProjectTaskOutTree(projectId));
      }
      if (processId && type === "process") {
        await dispatch(fetchProjectProcessOutTasks(processId));
      }
      setAnchorEl(null);
    };

    return (
      <div className={style.container}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <p className={style.dateDeadline} onClick={handleClick}>
              {node.taskStatusInfo.date}
            </p>
            {open ? (
              // @ts-ignore
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
              >
                <form className={style.createData}>
                  <div className={style.createData_flex}>
                    <h3>Конечный срок</h3>
                    <div onClick={() => setAnchorEl(null)}>
                      <NotApproveIcon width="16" heigth="16" />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="startDate"
                    render={() => (
                      <DateCreate
                        changeDateHandler={(date) => {
                          onChangeDate({
                            endDate: date,
                          });
                        }}
                        startDateProp={maxCreateParse}
                        maxDate={minDateParse}
                        minDate={maxDateParse}
                      />
                    )}
                  />
                  <div className={style.createData_btn}>
                    {isLoadDateUpdate && (
                      <div className={style.createData_btn_load}>
                        <CircularIndeterminate />
                      </div>
                    )}
                  </div>
                </form>
              </Popper>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
);
