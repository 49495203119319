import { httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";
import {
  ICompleteTaskProps,
  INotAcceptTaskProps,
} from "../../../feature/ProjectView/TaskReducer/thunks";

export const ApiProjectGetOutTask = (id: number): Promise<Response> => {
  // получить дерево Исхоящие Задания
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-out-tasks?id=${id}`
  );
};

export const ApiProjectGetInTask = (id: number): Promise<Response> => {
  // получить дерево Входящие Задания
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-in-tasks?id=${id}`
  );
};

export const ApiProjectGetAllTask = (
  id: number,
  status: string
): Promise<Response> => {
  // получить все задания
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-output-tasks?id=${id}&status=${status}`
  );
};

export const ApiAcceptTask = (id: number): Promise<Response> => {
  // принять задание
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: {
        status: 4,
      },
    }
  );
};

export const ApiCompleteTask = (
  id: number,
  data: ICompleteTaskProps["data"]
): Promise<Response> => {
  // cформировать задание
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: {
        ...data,
      },
    }
  );
};

export const ApiBackToWork = (id: number): Promise<Response> => {
  // вернуть в работу
  return httpRequestPost(`${config.localDomain}/v1/task/back-to-work?id=${id}`);
};

export const ApiNotAcceptTask = (
  id: number,
  data: INotAcceptTaskProps["data"]
): Promise<Response> => {
  // отменить, не требуется
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: {
        ...data,
      },
    }
  );
};

export const ApiProjectProcessToProcess = (
  projectId: number,
  processId: number
): Promise<Response> => {
  // получить типы заданий для модалки сформировать задание
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-processes?processId=${processId}&projectId=${projectId}`
  );
};

export const ApiGetCommentsByTaskId = (taskId: number): Promise<Response> => {
  // получить комментарии к заданию
  return httpRequestGet(
    `${config.localDomain}/v1/task/get-comments?id=${taskId}`
  );
};
