import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type ParticipantCountProps = ComponentPropsType<{
  title: string;
  count: number;
  description: string;
}>;

export const ParticipantCount: React.FC<ParticipantCountProps> = ({
  title,
  count = 0,
  description,
  theme = "dark",
  variant = "",
}) => {
  return (
    <div className={classNames(variant, style.container)}>
      <h1 className={classNames(style[theme], style.count)}>{count}</h1>
      <h3 className={classNames(style[theme], style.title)}>{title}</h3>
      <p className={classNames(style[theme], style.description)}>
        {description}
      </p>
    </div>
  );
};
