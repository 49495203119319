import { MaterialsDataType } from "../../../../../types/stateTypes/indexPages/indexPageMaterialsDataTypes";
import { ApiMaterialsDataType } from "../../../../../types/api/responseTypes/apiMaterialsType";
import { getUserName } from "../../../../services/user/user";

type MaterialsListType = (
  data: ApiMaterialsDataType["data"]
) => MaterialsDataType[];

export const createMaterialsListFromApi: MaterialsListType = (data) => {
  return data.map((item) => {
    return {
      name: item.name,
      dateCreate: item.date_create,
      dateLimit: item.date_limit,
      status: item.status.value,
      process: item.process
        ? {
            id: item.process.id,
            projectId: item.process.project_id,
            name: item.process.project.name,
          }
        : null,
      id: item.id,
      projectPart: {
        name: item.projectPart.name,
      },
      author: {
        id: item.author.id,
        name: getUserName(item.author),
      },
      executor: item.task
        ? {
            id: item.task.executor ? item.task.executor.id : null,
            name: item.task.executor ? getUserName(item.task.executor) : null,
            tested: item.task.executor ? item.task.executor.tested : null,
          }
        : null,
      countKP: item.countKP,
      countTasks: item.countTasks,
    };
  });
};
