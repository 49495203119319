import AutocompleteMui, {
  autocompleteClasses,
  AutocompleteProps,
} from "@mui/material/Autocomplete";
import { inputBaseClasses } from "@mui/material/InputBase";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { paperClasses } from "@mui/material/Paper";
import { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { palette } from "src/styles/restyle";

export const Autocomplete = styled(
  ({ className, ...props }: AutocompleteProps<any, any, any, any>) => (
    <AutocompleteMui
      {...props}
      size={props.size ?? "small"}
      classes={{ root: className, popper: className }}
    />
  )
)(() => ({
  [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]: {
    paddingLeft: "2px",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    position: "static",
    transform: "none",
    fontSize: "14px",
    marginBottom: "2px",
  },
  [`&.${autocompleteClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    top: 0,
    borderColor: palette.grey,
    "& legend": {
      display: "none",
    },
  },
  [`& .${autocompleteClasses.paper}`]: {
    background: palette.darkGrey,
  },
  [`&.${autocompleteClasses.popper} .${paperClasses.root}`]: {
    background: palette.darkGrey,
  },
  [`& .${chipClasses.root}`]: {
    background: palette.darkGrey,
    color: palette.silverGrey,
    [`& .${chipClasses.deleteIcon}:hover`]: {
      color: palette.grey,
    },
  },
  [`&.${autocompleteClasses.popper} .${autocompleteClasses.option}`]: {
    color: palette.silverGrey,
    position: "relative",
    padding: "10px 20px",
    "&:before": {
      borderBottom: "1px solid #101920",
      position: "absolute",
      left: 20,
      right: 20,
      bottom: 0,
      display: "block",
      content: '""',
    },
    ":last-child:before": {
      display: "none",
    },
    "&[aria-selected='true']:before": {
      borderBottomColor: "transparent",
    },
  },
}));
