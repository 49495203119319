import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { ApiSchedulePlanResponses } from "src/FSD/entities/schedulePlan/model/responseTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { fetchProjectGanttData } from "src/app/feature/ProjectView/GraphReducer/thunks";
import {
  clear422,
  getProjectGraphData,
} from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useEditDate } from "../lib/hooks/useEditDate";
import { EditDateSchema } from "./EditDateSchema";
import styles from "./EditDate.module.scss";

interface IEditDateModalProps {
  id: number;
  title: string;
  isLoad: boolean;
}

export const EditDate = ({ id, title, isLoad }: IEditDateModalProps) => {
  const dispatch = useDispatch();
  const { handleClose } = useModalContext();
  const {
    pd_date_start,
    pd_date_end,
    rd_date_start,
    rd_date_end,
    ii_date_start,
    ii_date_end,
    obs_date_start,
    obs_date_end,
    exp_date_start,
    exp_date_end,
    isLoadSchedule,
    schedulePlanUpdate,
    graphUpdate,
  } = useEditDate({ id });

  const { error422 } = useSelector(getProjectGraphData);

  const canPd = !!pd_date_start || !!pd_date_end;
  const canRd = !!rd_date_end || !!rd_date_end;
  const canIi = !!ii_date_start || !!ii_date_end;
  const canObs = !!obs_date_start || !!obs_date_end;
  const canExp = !!exp_date_start || !!exp_date_end;

  const schema = EditDateSchema(canPd, canRd, canIi, canObs, canExp);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      pd_date_start,
      pd_date_end,
      rd_date_start,
      rd_date_end,
      obs_date_start,
      obs_date_end,
      ii_date_start,
      ii_date_end,
      exp_date_start,
      exp_date_end,
    },
  });

  const handleModalClose = () => {
    clearErrors();
    dispatch(clear422());
    handleClose();
  };

  useEffect(() => {
    setValue("pd_date_start", pd_date_start);
    setValue("pd_date_end", pd_date_end);
    setValue("rd_date_start", rd_date_start);
    setValue("rd_date_end", rd_date_end);
    setValue("obs_date_start", obs_date_start);
    setValue("obs_date_end", obs_date_end);
    setValue("ii_date_start", ii_date_start);
    setValue("ii_date_end", ii_date_end);
    setValue("exp_date_start", exp_date_start);
    setValue("exp_date_end", exp_date_end);
  }, [pd_date_start, pd_date_end, rd_date_start, rd_date_end]);

  const formSubmitHandler = async (
    dataValues: ApiSchedulePlanResponses.UpdateDates
  ) => {
    const {
      pd_date_start,
      pd_date_end,
      rd_date_start,
      rd_date_end,
      exp_date_start,
      exp_date_end,
      obs_date_start,
      obs_date_end,
      ii_date_start,
      ii_date_end,
    } = dataValues;
    const data = {
      pd_date_start: toServerDate(pd_date_start),
      pd_date_end: toServerDate(pd_date_end),
      rd_date_start: toServerDate(rd_date_start),
      rd_date_end: toServerDate(rd_date_end),
      exp_date_start: toServerDate(exp_date_start),
      exp_date_end: toServerDate(exp_date_end),
      obs_date_start: toServerDate(obs_date_start),
      obs_date_end: toServerDate(obs_date_end),
      ii_date_start: toServerDate(ii_date_start),
      ii_date_end: toServerDate(ii_date_end),
    };

    await schedulePlanUpdate({ id, data });
  };

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  useEffect(() => {
    if (graphUpdate) {
      dispatch(fetchProjectGanttData(id));
      clearErrors();
      handleModalClose();
    }
  }, [graphUpdate]);

  if (isLoadSchedule || isLoad) {
    return (
      <Modal.Layout modalSize="lg">
        <Modal.Header>{title}</Modal.Header>
        <Modal.Preloader />
      </Modal.Layout>
    );
  }

  const pdDate = (
    <>
      {canPd && (
        <div className={styles.container_date}>
          <p>Редактирование сроков проектной документации</p>
          <div className={styles.form_date}>
            {pd_date_start && (
              <Controller
                control={control}
                name="pd_date_start"
                render={({ field: { value, onChange } }) => (
                  <DateField
                    label="Дата начала"
                    changeDateHandler={(date) => {
                      onChange(date);
                    }}
                    startDateProp={value}
                    className={styles.fullWidth}
                    error={Boolean(errors.pd_date_start)}
                    helperText={errors.pd_date_start?.message}
                  />
                )}
              />
            )}
            {pd_date_end && (
              <Controller
                control={control}
                name="pd_date_end"
                render={({ field: { value, onChange } }) => (
                  <DateField
                    label="Дата окончания"
                    changeDateHandler={(date) => {
                      onChange(date);
                    }}
                    startDateProp={value}
                    className={styles.fullWidth}
                    error={Boolean(errors.pd_date_end)}
                    helperText={errors.pd_date_end?.message}
                  />
                )}
              />
            )}
          </div>
        </div>
      )}
    </>
  );

  const iiDate = (
    <>
      {canIi && (
        <div className={styles.container_date}>
          <p>Редактирование сроков инженерных изысканий</p>
          <div className={styles.form_date}>
            <Controller
              control={control}
              name="ii_date_start"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата начала ИИ"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.ii_date_start)}
                  helperText={errors.ii_date_start?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="ii_date_end"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата окончания ИИ"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.ii_date_end)}
                  helperText={errors.ii_date_end?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );

  const rdDate = (
    <>
      {canRd && (
        <div className={styles.container_date}>
          <p>Редактирование сроков инженерных изысканий</p>
          <div className={styles.form_date}>
            <Controller
              control={control}
              name="rd_date_start"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата начала РД"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.rd_date_start)}
                  helperText={errors.rd_date_start?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="rd_date_end"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата окончания РД"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.rd_date_end)}
                  helperText={errors.rd_date_end?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );

  const expertiseDate = (
    <>
      {canExp && (
        <div className={styles.container_date}>
          <p>Редактирование сроков экспертизы</p>
          <div className={styles.form_date}>
            <Controller
              control={control}
              name="exp_date_start"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата начала экспертизы"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.exp_date_start)}
                  helperText={errors.exp_date_start?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="exp_date_end"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата окончания экспертизы"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.exp_date_end)}
                  helperText={errors.exp_date_end?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );

  const obsDate = (
    <>
      {canObs && (
        <div className={styles.container_date}>
          <p>Редактирование сроков обследований</p>
          <div className={styles.form_date}>
            <Controller
              control={control}
              name="obs_date_start"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата начала ОБС"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.obs_date_start)}
                  helperText={errors.obs_date_start?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="obs_date_end"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Дата окончания ОБС"
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  startDateProp={value}
                  className={styles.fullWidth}
                  error={Boolean(errors.obs_date_end)}
                  helperText={errors.obs_date_end?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{title}</Modal.Header>
      {isLoad ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          onSubmit={handleSubmit(formSubmitHandler)}
          className={styles.form}
        >
          <div className={styles.container}>
            {pdDate}
            {iiDate}
            {rdDate}
            {expertiseDate}
            {obsDate}
          </div>
          <Modal.Controls>
            <Button onClick={handleModalClose} variant="outlined">
              Отменить
            </Button>
            <Button type="submit">Сохранить</Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
