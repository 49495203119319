import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { IconButton } from "@mui/material";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { ModalCloseIcon, StarSVG } from "../../../../newUI/SVG";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  TExecutor,
  clearSelectedExecutors,
  getGroupApplications,
  toggleGroupComparisonMode,
  deleteExecutor,
  returnDeleted,
} from "../../../../../app/feature/ProjectView/GroupApplications";
import {
  getExecutors,
  saveChosenTasks,
} from "../../../../../app/feature/ProjectView/GroupApplications/thunks";
import { createSumValues } from "../../../../../app/feature/ProjectView/GroupApplications/utils";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";
import { config } from "../../../../../app.cofig";
import { NewCheckbox } from "../../../../newUI/NewCheckbox/NewCheckbox";
import { trimPrice } from "../../../../../utils/trimNumber";
import { ArrowLeftSvg } from "./GroupApplicationsSvg";

const formProcesses = (executors: TExecutor[]) => {
  const result: Record<string, number> = {};

  executors.forEach((executor: TExecutor) => {
    Object.values(executor.advancedApplications).forEach((application) => {
      Object.entries(application).forEach(([id, value]: any) => {
        if (
          !result[id] &&
          value.taskId &&
          value.dateRange &&
          !value.badReason
        ) {
          result[id] = value.taskId;
        }
      });
    });
  });

  return result;
};

type TFormValues = {
  processes: Record<string, number>;
};

type ComparisonProps = {
  id: number;
};

const Comparison = ({ id }: ComparisonProps) => {
  const [sumValues, setSumValues] = useState<any>({});
  const [hasTick, setHasTick] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    data: { selectedExecutors, newTypeNames, bufer },
    pending,
    error,
  } = useAppSelector(getGroupApplications);
  console.log(selectedExecutors);
  console.log(bufer);

  const defaultProcesses = formProcesses(selectedExecutors);

  const { setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      processes: defaultProcesses,
    },
  });

  const processes = watch("processes");

  const executorsCount = selectedExecutors.length;

  const onSubmit = (data: TFormValues) => {
    const body = {
      taskIds: Object.values(data.processes),
    };

    dispatch(saveChosenTasks({ id, body }));
  };

  useEffect(() => {
    const result = createSumValues(selectedExecutors, processes);
    setSumValues(result);
  }, [processes]);

  useNonInitialEffect(() => {
    if (pending.choosing || error.choosing) {
      return;
    }

    dispatch(toggleGroupComparisonMode());
    dispatch(clearSelectedExecutors());
    dispatch(getExecutors(id));
  }, [pending.choosing]);

  function handleDeleteExecutor(id: number) {
    dispatch(deleteExecutor(id));
  }

  function handleReturnDeleted() {
    dispatch(returnDeleted());
  }
  const GeneralInfo = () => (
    <>
      <div className={cn(styles.row, styles.general)}>
        <div className={styles.general_executor}>
          <p>Проектировщик</p>
          <span>(Ф.И.О.)</span>
        </div>
        {selectedExecutors.map((executor: TExecutor) => (
          <div className={styles.general_name}>
            <span
              className={styles.delete}
              onClick={() => handleDeleteExecutor(executor.id)}
            >
              <ModalCloseIcon width={"20"} height={"20"} />
            </span>
            <h2 className={styles.extraPadding}>{executor.fio}</h2>
          </div>
        ))}
      </div>
      <div className={cn(styles.row, styles.general)}>
        <p>Форма собственности</p>
        {selectedExecutors.map((executor: TExecutor) => (
          <p className={styles.general_type}>{executor.userTypeText}</p>
        ))}
      </div>
      <div className={cn(styles.row, styles.general)}>
        <p>Рейтинг</p>
        {selectedExecutors.map((executor: TExecutor) => (
          <div className={styles.general_rating}>
            <p>{executor.rating}</p>
            <StarSVG width={"22"} height={"20"} />
          </div>
        ))}
      </div>
    </>
  );

  const handleOpenProcess = (processId: number) => {
    const hasApplications = Object.keys(defaultProcesses).includes(
      String(processId)
    );

    window.open(
      `${config.localDomain}/project-process/${processId}?tab=${
        hasApplications ? "applications" : "info"
      }`
    );
  };

  const Summary = () => (
    <>
      {Object.entries(newTypeNames).map(([type, data]: any) => (
        <>
          <div className={styles.row}>
            <p />
            <div className={styles.fullRow}>
              <h2>{data.name}</h2>
              <p>{`Общие сроки: ${data.dateStart} - ${data.dateEnd}`}</p>
              <div className={styles.fullRow_price}>
                <p>Общий бюджет:</p>
                <span>{data.fullPrice} ₽</span>
              </div>
            </div>
          </div>
          {Object.entries(
            selectedExecutors[0].advancedApplications[type] ?? []
          ).map(([id, process]: any) => (
            <div className={styles.row}>
              <p
                className={styles.processLink}
                onClick={() => handleOpenProcess(id)}
              >
                {process.name}
              </p>
              {selectedExecutors.map((executor: TExecutor) => {
                const { badReason, ndsText, dateRange, price, taskId } =
                  executor.advancedApplications[type][id];

                const checked = processes[id] === taskId;

                const handleChange = () => {
                  if (!taskId) {
                    return;
                  }

                  if (checked) {
                    const newProcesses = { ...processes };
                    delete newProcesses[id];

                    if (!Object.keys(newProcesses).length) {
                      return;
                    }

                    setValue("processes", newProcesses);

                    return;
                  }

                  setValue("processes", {
                    ...processes,
                    [id]: taskId,
                  });
                };

                if (!dateRange) {
                  return <p className={styles.textCentered}>—</p>;
                }

                if (badReason) {
                  return <p className={styles.badReason}>{badReason}</p>;
                }

                setHasTick(true);

                return (
                  <div className={styles.check}>
                    <NewCheckbox
                      onChange={handleChange}
                      checked={checked}
                      editing
                      width={"24px"}
                      height={"24px"}
                      flexBasis={"24px"}
                    />
                    <div className={styles.forPrice}>
                      <div className={styles.forPrice_text}>
                        <span>{trimPrice(price)}₽</span>
                        <p>{ndsText}</p>
                      </div>
                      <p className={styles.forPrice_dates}>{dateRange}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          <div className={styles.row}>
            <p className={styles.all}>{`Всего по "${data.name}"`}</p>
            {selectedExecutors.map((executor: TExecutor) => {
              const sumValue = sumValues?.[executor.id]?.[type];

              if (!sumValue) {
                return <p className={styles.textCentered}>—</p>;
              }

              return (
                <div className={styles.priceAll}>
                  <span>{trimPrice(sumValue.price)}₽</span>
                  <p>{sumValue.ndsText}</p>
                </div>
              );
            })}
          </div>
        </>
      ))}
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.comparison}>
      <div className={styles.header}>
        <h2>Сравнение исполнителей</h2>
        {!!bufer.length && selectedExecutors.length !== 3 && (
          <IconButton onClick={handleReturnDeleted}>
            <ArrowLeftSvg />
          </IconButton>
        )}
      </div>
      <div
        className={cn(
          styles.comparisonGrid,
          styles[`rowColumns${executorsCount}`]
        )}
      >
        <GeneralInfo />
        <Summary />
        <div className={styles.forSubmit}>
          {error.choosing && (
            <p
              className={styles.error}
              dangerouslySetInnerHTML={{
                __html: error.choosing,
              }}
            />
          )}
          <Button disabled={pending.choosing || !hasTick} type="submit">
            Выбрать
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Comparison;
