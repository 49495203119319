import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { AppStateType } from "../../../types/stateTypes/app";
import { UserConfigColorThemeType } from "../../../types/stateTypes/userType";
import C from "./constants";
import { setColorConfigTheme } from "../../services/user/userConfig";
import { actionObjectType } from "../../../types/stateTypes/reducer";
import { StateType } from "../../initialState";

export const appInitialState: AppStateType = {
  loadedMainData: 0,
  reloadProject: 0,
  userConfig: {
    colorTheme: "old",
  },
  attemptedToken: false,
};

type PayloadType = {
  loadedMainData?: number;
  reloadProject?: number;
  colorTheme?: UserConfigColorThemeType;
};

export const appReducer = createReducer(appInitialState, (builder) => {
  builder.addCase(
    C.LOADED_MAIN_DATA,
    (state, action: PayloadAction<PayloadType>) => ({
      ...state,
      loadedMainData: action.payload.loadedMainData!,
    })
  );
  builder.addCase(C.RELOAD_PROJECT, (state) => ({
    ...state,
    reloadProject: state.reloadProject + 1,
  }));
  builder.addCase(
    C.SET_COLOR_THEME,
    (state, action: PayloadAction<PayloadType>) => ({
      ...state,
      userConfig: {
        colorTheme: action.payload.colorTheme!,
      },
    })
  );
  builder.addCase(C.SET_ATTEMPTED_TOKEN, (state) => ({
    ...state,
    attemptedToken: true,
  }));
});

export const setColorTheme = (theme: UserConfigColorThemeType) => {
  setColorConfigTheme(theme);
  return {
    type: C.SET_COLOR_THEME,
    payload: {
      colorTheme: theme,
    },
  };
};

export const reloadProjectData = (): actionObjectType<boolean> => {
  return {
    type: C.RELOAD_PROJECT,
    payload: true,
  };
};

export const setAttemptedToken = () => {
  return {
    type: C.SET_ATTEMPTED_TOKEN,
  };
};

export const selectorAppReloadProject = (state: StateType): number =>
  state.app.reloadProject;
export const selectorReloadMainData = (state: StateType): number =>
  state.app.loadedMainData;
export const selectorAppTheme = (state: StateType): UserConfigColorThemeType =>
  state.app.userConfig.colorTheme;
export const selectorAttemptedToken = (state: StateType): boolean =>
  state.app.attemptedToken;
