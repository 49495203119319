import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { stringMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";

export const checkExpertiseRemarkRowCompleteSchema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.IS_REJECT,
    then: stringMaxLengthRequired("Комментарий", 2000),
  }),
});
