import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProcessWorkResponses } from "../../model/responseTypes";
import { concatenateParams } from "../../../../shared/lib/helpers/concatenateParams";

type TParams = RequestParams<{
  id: number;
}>;

export const removed = (builder: QueryBuilder) =>
  builder.query<ApiProcessWorkResponses.Removed[], TParams>({
    query: (params) => ({
      url: `v1/process-work/removed`,
      params: {
        id: params.id,
        expand: concatenateParams([
          "executorUserType",
          "taskWork.executor_nds",
          "can",
        ]),
      },
    }),
    providesTags: ["Feedback.Task"],
  });
