import {
  ApiPrimaryDocument,
  ApiPrimaryDocumentType,
} from "../../../../../types/api/primaryTypes/apiPrimaryDocument";
import {
  ContractsDataCodeType,
  ContractsDataType,
} from "../../../../../types/stateTypes/projectContractsStateTypes";
import { getUserSurnameAndInitials } from "../../../../services/user/user";
import { getDateAndTime } from "../../../../services/date/date";
import { ApiPrimaryDocumentSignType } from "../../../../../types/api/primaryTypes/apiPrimaryDocumentSignType";

type ContactsListType = (
  data: ApiPrimaryDocumentType["data"]
) => ContractsDataType[];

type GetCodeListType = (
  arr: ApiPrimaryDocument["projectProcesses"]
) => ContractsDataCodeType[];

type GetSignType = (
  arr: ApiPrimaryDocumentSignType[],
  code: number
) => ApiPrimaryDocumentSignType | undefined;

const getSign: GetSignType = (arr, code) => {
  return arr.find((item) => {
    if (item && item.documentAccess.access_code === code) {
      return item;
    }
    return null;
  });
};

const getCodeList: GetCodeListType = (arr) => {
  return arr.map((item) => {
    return {
      codeName: item.projectPart.code,
      codeId: item.id,
    };
  });
};

export const createContractsListFromApi: ContactsListType = (data) =>
  data.map((item) => {
    return {
      id: item.id,
      dateCreate: getDateAndTime(item.date_create, false)!,
      name: item.name,
      status: item.status,
      executor: {
        user: getUserSurnameAndInitials(item.executor),
        userId: item.executor.id,
      },
      code: getCodeList(item.projectProcesses),
      project: {
        projectName: item.project.name,
        projectId: item.project.id,
      },
      authorSign: getSign(item.documentSigns, 1)
        ? getSign(item.documentSigns, 1)!.date
        : null,
      executorSign: getSign(item.documentSigns, 3)
        ? getSign(item.documentSigns, 3)!.date
        : null,
    };
  });
