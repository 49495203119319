import React, { memo, useState } from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "./CheckTaskCard";

interface ChecksCardProps {
  setModalData?: SetState<TModalData>;
  taskId: number;
  code: string;
  comment: string;
  statusText: string;
  dot: string | null;
  tasks: {
    id: number;
    statusText: string;
    color: string;
    taskProcess: {
      projectPart: {
        code: string;
      };
    };
  }[];
}
export const ChecksCard = memo((props: ChecksCardProps) => {
  const [agreeToggle, setAgreeToggle] = useState(false);
  const [commentToggle, setCommentToggle] = useState(false);

  const agreeIconType = <ToggleAccordion toggle={agreeToggle} />;
  const commentIconType = <ToggleAccordion toggle={commentToggle} />;

  const toggleClick = (type: string) => {
    if (type === "agree") {
      setCommentToggle(false);
      setAgreeToggle((v) => !v);
    }
    if (type === "comment") {
      setAgreeToggle(false);
      setCommentToggle((v) => !v);
    }
  };
  const { statusText, code, dot, tasks, comment, taskId, setModalData } = props;

  return (
    <div className={style.card} key={code}>
      <div className={style.card_content}>
        <div className={style.card_content_text}>
          <h3>{code}</h3>
          <div
            className={style.card_toggle}
            onClick={() => toggleClick("agree")}
          >
            <p>Согласующие</p>
            <span>{agreeIconType}</span>
          </div>
          <div
            className={style.card_toggle}
            onClick={() => {
              if (setModalData) {
                setModalData({
                  id: taskId,
                  name: code,
                  open: true,
                });
              }
              // toggleClick("comment");
            }}
          >
            <p>Комментарии</p>
            <span>{commentIconType}</span>
          </div>
        </div>
        <div>{/* возможно здесь потом будут кнопки */}</div>
      </div>
      {statusText && !agreeToggle && !commentToggle && (
        <div className={style.globalStatus}>
          {dot && <div className={style.plot} />}
          <p>{statusText}</p>
        </div>
      )}
      {agreeToggle && !commentToggle && (
        <div className={style.agree}>
          <h3>Согласующие</h3>
          <div className={style.flex}>
            {tasks.map((el) => (
              <div
                className={classNames(
                  style.agree_wrapper,
                  el.color === "ok" && style.agree_green,
                  el.color === "error" && style.agree_red,
                  el.color === "warning" && style.agree_warning,
                  el.color === "grey" && style.agree_gray
                )}
                key={el.id}
              >
                <h4>{el.taskProcess.projectPart.code}</h4>
                <span>{el.statusText}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {commentToggle && !agreeToggle && (
        <div className={style.comment}>
          <h4>Комментарии</h4>
          <span>{comment}</span>
        </div>
      )}
    </div>
  );
});
