import { httpRequestDelete, httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

const editingExpand = `governmentContract,projectParameters,designType,bankAccount,constructionType,expertise,can,buildingFunction,processes,seismicity`;

export const ApiGetProjectDataForEditing = (
  id: string | number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/view?id=${id}&expand=${editingExpand}`
  );

export const ApiGetProjectFilesForEditing = (
  id: string | number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/view?id=${id}&expand=files.svgIcon`
  );

export const ApiPostProjectGovernmentContract = (
  id: string | number,
  data: any
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/government-contract/create?projectId=${id}`,
    {},
    data
  );

export const ApiDeleteProjectGovernmentContract = (
  id: string
): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/government-contract/delete?projectId=${id}`,
    {}
  );
