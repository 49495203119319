import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ExecutorsPage } from "../components/pages";
import { CustomersPage } from "../components/pages/Search/Customers/CustomersPage";
import { GipPage } from "../components/pages/Search/Gip/GipPage";
import { ProvidersPage } from "../components/pages/Search/ProvidersPage/ProvidersPage";
import { EmailConfirmPage } from "../components/pages/Registration/EmailConfirmPage/EmailConfirmPage";
import { RegistrationSuccessPage } from "../components/pages/Registration/RegistrationSuccessPage/RegistrationSuccessPage";
import { View } from "../components/pages/User/View/View";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import ProfileEditing from "../components/pages/User/Edit/ProfileEditing";
import Dashboard from "../components/pages/User/Dashboard/Dashboard";
import { userIsAuth } from "../app/services/auth/auth";
import style from "./style.module.scss";

export const UserRoutes = () => {
  const isAuth = userIsAuth();
  return (
    <Routes>
      <Route path={"/user"}>
        <Route
          path={`registration-success`}
          element={
            <IndexPageTemplate>
              <RegistrationSuccessPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`confirm`}
          element={
            <IndexPageTemplate>
              <EmailConfirmPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`search-executors`}
          element={
            <IndexPageTemplate>
              <ExecutorsPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`search-customers`}
          element={
            <IndexPageTemplate>
              <CustomersPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`search-gip`}
          element={
            <IndexPageTemplate>
              <GipPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`search-providers`}
          element={
            <IndexPageTemplate>
              <ProvidersPage />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`profile-edit/:id`}
          element={
            <IndexPageTemplate>
              <ProfileEditing />
            </IndexPageTemplate>
          }
        />
        <Route
          path={`new-dashboard`}
          element={
            isAuth ? (
              <IndexPageTemplate className={style.dashboardMobile}>
                <Dashboard />
              </IndexPageTemplate>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path={`kanban`}
          element={
            isAuth ? (
              <IndexPageTemplate className={style.dashboardMobile}>
                <Dashboard initialTab="kanban" />
              </IndexPageTemplate>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path={`:id`}
          element={
            <IndexPageTemplate>
              <View />
            </IndexPageTemplate>
          }
        />
      </Route>
    </Routes>
  );
};
