import React, { memo, useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import moment from "moment";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { TCustomerAlign } from "src/app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import {
  deleteByTaskId,
  fetchProjectProcessDocumentations,
} from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { projectProcessTaskBackToWork } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessTaskCancel";
import {
  getProjectProcessCheckAlignIsLoad,
  getProjectProcessDocumentsCompleteCheck,
  getProjectProcessPreliminaryDoneCustomer,
} from "src/app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { StatusCardWrapper } from "src/components/newUI/StatusCardWrapper/StatusCardWrapper";
import { trimRubPennies } from "src/utils/trimNumber";
import { iconBtnSx } from "src/styles/restyle";
import { AddFile, CheckSVG, NotApproveIcon } from "../../../../../newUI/SVG";
import Modal from "../../../../../newUI/Modal/Modal";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { ModalCheck } from "./ModalCheck/ModalCheck";
import { ModalWrapper } from "../../../../User/Edit/PersonalDataChange/PersonalDataChange";
import { TCurrentTab, warningMessages } from "./CustomerAlignCard";
import { NdsInfo } from "../../../Info/PayBlock/PayBlock";
import { ModalPreliminaryDone } from "./ModalPreliminaryDone/ModalPreliminaryDone";
import { ModalPreliminaryRequest } from "../../../HeaderBtn/ModalPreliminaryRequest/ModalPreliminaryRequest";
import cls from "./CustomerAlignCard.module.scss";

export const RequestCustomerAlignCard: React.FC<
  TCustomerAlign & { request?: boolean }
> = memo((props) => {
  const {
    status,
    executorName,
    date,
    comment,
    files,
    title,
    header,
    can,
    checks,
    paidOut,
    request,
    id,
    remarks,
    setModalData,
    date_deadline,
  } = props;

  const infoId = useAppSelector(getProjectProcessInfoId);
  const infoData = useAppSelector(getProjectProcessInfoData);
  const isLoadCheckAlign = useAppSelector(getProjectProcessCheckAlignIsLoad);

  const isLoadPreliminaryDoneCustomer = useAppSelector(
    getProjectProcessPreliminaryDoneCustomer
  );
  const completeCheck = useAppSelector(getProjectProcessDocumentsCompleteCheck);

  const [currentTab, setCurrentTab] = useState<TCurrentTab>(null);

  const dispatch = useAppDispatch();

  const [modalPreliminaryDone, setModalPreliminaryDone] = useState(false);
  const [modalCheck, setModalCheck] = useState(false);
  const [modalPreliminaryRequest, setModalPreliminaryRequest] = useState(false);

  const checksToArray = Object.values(checks);

  const handleToggleComments = () => {
    if (!setModalData) {
      return;
    }

    setModalData({
      id,
      name: "",
      open: true,
    });
  };

  const handleToggleFiles = () => {
    setCurrentTab(currentTab ? null : "file");
  };

  const handleToggleChecks = () => {
    setCurrentTab(currentTab ? null : "check");
  };

  const handleCloseModalPreliminary = useCallback(() => {
    setModalPreliminaryDone(false);
  }, []);

  const handleCloseModalPreliminaryRequest = useCallback(() => {
    setModalPreliminaryRequest(false);
  }, []);

  const handleDeleteTask = async () => {
    if (!window.confirm(warningMessages.DELETE_TASK)) {
      return;
    }

    await dispatch(deleteByTaskId(id));

    if (infoId) {
      dispatch(fetchProjectProcessDocumentations(infoId));
      dispatch(fetchProjectProcessViewDuplicate(infoId));
    }
  };

  useEffect(() => {
    if (completeCheck) {
      setModalCheck(false);
    }
  }, [completeCheck]);

  const openModalPreliminaryRequest = () => {
    setModalPreliminaryRequest(true);
  };

  const openModalPreliminaryDone = () => {
    setModalPreliminaryDone(true);
  };

  const openModalCheck = () => {};

  const handleTaskBackToWork = async () => {
    if (!window.confirm(warningMessages.TASK_BACK_TO_WORK)) {
      return;
    }

    await dispatch(projectProcessTaskBackToWork(id));

    if (infoId) {
      dispatch(fetchProjectProcessDocumentations(infoId));
      dispatch(fetchProjectProcessViewDuplicate(infoId));
    }
  };

  const view = {
    file: currentTab === "file",
    check: currentTab === "check",
    comment: currentTab === "comment",
    status: currentTab === "status",
  };

  const Info = () => (
    <>
      <div className={cls.content_header}>
        <h1 title={title}>{header}</h1>
        <p>{executorName}</p>
        <div className={cls.date}>
          <p>Запрос от заказчика</p>
          <span dangerouslySetInnerHTML={{ __html: date }} />
        </div>
      </div>
      <div className={cls.toggleList}>
        <div className={cls.toggle} onClick={handleToggleComments}>
          <p>Комментарии</p>
          <ToggleAccordion toggle={view.comment} />
        </div>
        {files && !request && (
          <div className={cls.toggle} onClick={handleToggleFiles}>
            <p>Вложения</p>
            <ToggleAccordion toggle={view.file} />
          </div>
        )}
        {checksToArray.length > 0 && (
          <div className={cls.toggle} onClick={handleToggleChecks}>
            <p>Проверки</p>
            <ToggleAccordion toggle={view.check} />
          </div>
        )}
      </div>
      {paidOut?.price && (
        <div className={cls.price}>
          <NdsInfo executorNds={infoData?.executor_nds} />
          <div className={cls.price_rub}>
            <p>Оплачено</p>
            {paidOut.price && <span>{trimRubPennies(paidOut.price)}</span>}
          </div>
        </div>
      )}
    </>
  );

  const Modals = () => (
    <>
      {can.check && (
        <Modal
          active={modalCheck}
          setActive={setModalCheck}
          isLoad={isLoadCheckAlign}
        >
          <ModalCheck
            comment={comment}
            files={files}
            name={header}
            taskId={id}
          />
        </Modal>
      )}
      {can.complete && (
        <Modal
          active={modalPreliminaryDone}
          setActive={setModalPreliminaryDone}
          isLoad={isLoadPreliminaryDoneCustomer}
        >
          <ModalPreliminaryDone taskId={id} name={header} />
        </Modal>
      )}
      {can.edit && (
        <ModalWrapper
          show={modalPreliminaryRequest}
          handleCloseModal={handleCloseModalPreliminaryRequest}
        >
          <ModalPreliminaryRequest
            closeModal={handleCloseModalPreliminaryRequest}
            header={header}
            taskId={id}
            defaultValues={{
              date_deadline: moment(
                date_deadline.substring(0, 10),
                "DD.MM.YYYY"
              ).toDate(),
              content: comment,
            }}
          />
        </ModalWrapper>
      )}
    </>
  );

  const Controls = () => (
    <>
      {can.complete && (
        <IconButton
          sx={iconBtnSx}
          title="Выдать"
          onClick={openModalPreliminaryDone}
        >
          <AddFile width="24" height="24" />
        </IconButton>
      )}
      {can.check && (
        <IconButton sx={iconBtnSx} title="Проверить" onClick={openModalCheck}>
          <CheckSVG width="24" height="24" />
        </IconButton>
      )}
      {can.edit && (
        <IconButton
          sx={iconBtnSx}
          title="Редактировать"
          onClick={openModalPreliminaryRequest}
        >
          <PencilSquare width="24" height="24" />
        </IconButton>
      )}
      {can.backToWork && (
        <IconButton
          sx={iconBtnSx}
          title="Вернуть на доработку"
          onClick={handleTaskBackToWork}
        >
          <NotApproveIcon width="24" heigth="24" />
        </IconButton>
      )}
      {can.delete && (
        <IconButton sx={iconBtnSx} title="Удалить" onClick={handleDeleteTask}>
          <Trash width="24" height="24" />
        </IconButton>
      )}
    </>
  );

  return (
    <div>
      <StatusCardWrapper
        status={status}
        date={status.date}
        comment={comment}
        color={status.color}
        viewStatus={!currentTab}
        viewComment={view.comment}
        checks={checksToArray}
        viewChecks={view.check}
        viewFiles={view.file}
        classname={cls.card}
        downloadAllLink={
          files.length > 0 ? `/task/download-files?id=${id}` : undefined
        }
        remarks={remarks}
      >
        <div className={cls.content}>
          <Info />
          <div className={cls.btn}>
            <Modals />
            <Controls />
          </div>
        </div>
      </StatusCardWrapper>
    </div>
  );
});
