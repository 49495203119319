import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessUserUpdate } from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessUsersUpdate = createAsyncThunk<boolean, any>(
  "projectProcess/fetchProjectProcessUsersUpdate",
  async ({ id, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessUserUpdate(id, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
