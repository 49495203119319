import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { useQuery } from "../../../../app/services/url/getQueryParameters";
import { request } from "../../../../app/services/api/requestHandler";
import { ApiPostEmailConfirm } from "../../../../app/services/api/registration/registration";
import { textFieldSX } from "../../../../styles/restyle";

type TFormValues = {
  code: string;
};

const schema = Yup.object().shape({
  code: Yup.string().required("Необходимо ввести код подтверждения"),
});

export const EmailConfirmForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const theme = useSelector(selectorAppTheme);
  const id = useQuery("id");
  const navigate = useNavigate();

  const onSubmit = (data: TFormValues) => {
    request(
      ApiPostEmailConfirm(id!, {
        EmailConfirmForm: {
          code: data.code,
        },
      }),
      (result, headersData, headers, status) => {
        if (status === 200) {
          navigate("/user/registration-success");
        }
      },
      () => (data) => {
        const message = JSON.parse(data.message);
        Object.keys(message).map((key, i) =>
          setError(key as keyof TFormValues, message[key][i])
        );
      }
    )();
  };

  return (
    <div>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <div className={style.fieldWrapper}>
          <TextField
            {...register("code")}
            size={"small"}
            fullWidth
            id="code"
            name="code"
            type="text"
            label="Код"
            autoComplete="off"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(errors.code)}
            helperText={errors.code?.message}
            {...textFieldSX}
          />
        </div>
        <Button
          theme={theme}
          type="submit"
          color={"blue"}
          className={style.confirmButton}
        >
          Подтвердить
        </Button>
      </form>
    </div>
  );
};
