import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import classes from "./style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import {
  fetchGipRemoveComplete,
  fetchProjectViewSearchGip,
} from "../../../../../../app/feature/ProjectView/thunks";

type Inputs = {
  comment: string;
  status: number;
};

type CompleteRemoveGipProps = {
  removeGip: {
    id: number;
    content: string;
  };
};

export const CompleteRemoveGip: React.FC<CompleteRemoveGipProps> = ({
  removeGip,
}) => {
  const { id, content } = removeGip;
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm<Inputs>();

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      comment: dataValues.comment,
      status: dataValues.status,
    };
    if (params.id) {
      await dispatch(fetchGipRemoveComplete({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(params.id));
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={classes.completeRemoveGip}
      >
        <h1>Рассмотрение заявки на снятие ГИПа</h1>
        <TextField
          autoComplete="off"
          {...textFieldSX}
          label="Комментарий от руководителя проекта"
          defaultValue={content}
          variant="outlined"
        />
        <TextField
          {...textFieldSX}
          className={classes.textarea}
          {...register("comment")}
          multiline
          minRows={2}
          label="Комментарий"
          placeholder="Введите комментарий"
        />
        <div className={classes.completeRemoveGip_container}>
          <CustomButton
            type="submit"
            width={160}
            onClick={() => setValue("status", 4)}
            background={palette.blue}
            className={classes.gipButton}
          >
            Согласиться
          </CustomButton>
          <CustomButton
            type="submit"
            width={160}
            onClick={() => setValue("status", 5)}
            background={palette.red}
            className={classes.gipButton}
          >
            Отказать
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
