import { IndexPageUserType } from "../../../../../types/stateTypes/indexPages/indexPageUserDataType";

export const customerPageInitialState: IndexPageUserType = {
  users: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
