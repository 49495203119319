import { httpRequestGet, httpRequestPost, httpRequestPut } from "../api";
import { config } from "../../../../app.cofig";
import { ApiUpdateParams } from "../project/admin";

const expand = `
activeAccess.userType,
activeAccess.user,
can,
files.file,
project,
warning, 
task.can,
tabs,
stopped,
project.chatId,
project.can,
haveExpertise,
account.value,
bim,
activeAccess.taskRemove.data,
activeAccess.taskRemove.can`;
export const ApiProjectProcessView = (id: number): Promise<Response> => {
  // получение инфо для заказы
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/view?id=${id}&expand=${expand}`
  );
};

export const ApiProjectProcessViewDuplicate = (
  id: number
): Promise<Response> => {
  // получение инфо для заказы дубликат запроса без cмены isLoad
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/view?id=${id}&expand=${expand}`
  );
};

export const ApiProjectProcessRemoveExecutorData = (
  processId: number,
  executorId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-access/data-remove-executor?processId=${processId}&userId=${executorId}`
  );
};

export const ApiProjectProcessRemoveExecutor = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/remove-executor?objectId=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessRemoveExecutorComplete = (
  id: number,
  data: any
): Promise<Response> => {
  // рассмотреть конфликт
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    data
  );
};

export const ApiProjectProcessFeedbackExecutor = (
  taskId: number,
  userId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/feedback/task?taskId=${taskId}&userId=${userId}`,
    {},
    { Feedback: data }
  );
};

export const ApiProjectProcessUsers = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-users-to-assign?id=${id}`
  );
};

export const ApiProjectProcessUsersTypes = (
  processId: number,
  userId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-user-type-to-assign?id=${processId}&userId=${userId}`
  );
};

export const ApiProjectProcessUserUpdate = (
  id: number,
  data: ApiUpdateParams
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/project-access/update?id=${id}`,
    {},
    data
  );
};

export const ApiProjectProcessPriceUpdate = (
  id: number,
  data: ApiUpdateParams
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/update-price?id=${id}`,
    {},
    data
  );
};

export const ApiProjectProcessUpdateAdditional = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/update-addition?id=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessUpdateAdditionalWork = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/process-work/update?id=${processId}`,
    {},
    data
  );
};
