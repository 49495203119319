import { useProjectProcessApplicationQuery } from "src/FSD/entities/projectProcess/api";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { useTabsPanelContext } from "../../../../../TabsPanel";

export const useApplications = (projectProcessId: number) => {
  const { data: applications, isFetching } = useProjectProcessApplicationQuery({
    id: projectProcessId,
    expand: ["projectProcess", "project"],
  });
  const { currentChildTab } = useTabsPanelContext();

  const applicationsList =
    applications?.[
      currentChildTab as keyof ApiProjectProcessResponses.Application
    ] ?? [];

  return {
    applicationsList,
    currentChildTab,
    isApplicationsLoading: !applications || isFetching,
  } as const;
};
