import { ApiPostType } from "../../../../../types/api/responseTypes/apiPostType";
import { PostPageStateTypes } from "../../../../../types/stateTypes/postsStateTypes";
import { getDateAndTime } from "../../../../services/date/date";
import { replacingTags } from "../../../../services/other";

type PostListType = (data: ApiPostType["post"]) => PostPageStateTypes["post"];

const annotationLength = 350;

export const createPostList: PostListType = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      content: replacingTags(item.content).slice(0, annotationLength),
      date_publication: item.date_publication
        ? getDateAndTime(item.date_publication, false)
        : null,
      photoUrl: item.photoUrl,
      tags: item.tags,
      author: item.author,
    };
  });
};
