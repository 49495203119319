import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { CounterList, CounterListProps } from "../CounterList/CounterList";

export type PageTitleProps = ComponentPropsType<{
  title: string;
  description: string;
  items: { title: string; value: string | number; url: string }[];
  clickLinkHandler: CounterListProps["clickHandlerLink"];
}>;

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  variant = "",
  theme = "dark",
  items,
  clickLinkHandler = () => {},
}) => {
  return (
    <div className={classNames(variant, style.titleContainer)}>
      <div className={style.titleWrapper}>
        <h1 className={style.title}>{title}</h1>
        <p className={classNames(style.description, style[theme])}>
          {ReactHtmlParser(description)}
        </p>
      </div>
      <CounterList
        size={"big"}
        items={items}
        theme={theme}
        clickHandlerLink={clickLinkHandler}
      />
    </div>
  );
};
