import React, { memo, useCallback, useState } from "react";
import moment from "moment";
import { IconButton } from "@mui/material";
import { PencilSquare } from "react-bootstrap-icons";
import Modal from "../../../../../../../newUI/Modal/Modal";
import { ModalCompleteRow } from "./ModalCompleteRow/ModalCompleteRow";
import cls from "../RemarkTable.module.scss";
import { AddFile, NotApproveIcon } from "../../../../../../../newUI/SVG";
import { RowsType } from "../../../../../../../../app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import { getProjectProcessExpertiseCompleteIsLoad } from "../../../../../../../../app/feature/ProjectProcessView/Expertise/selectors/getProjectProcessExpertise";
import { iconBtnSx } from "../../../../../../../../styles/restyle";
import { selectColor } from "../../../../../../../../utils/selectColor";
import { fetchProjectProcessExpertise } from "../../../../../../../../app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import { getProjectProcessInfoId } from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchBackToWork } from "../../../../../../../../app/feature/ProjectView/TaskReducer/thunks";

export type TModalMode = "complete" | "edit" | null;

interface RemarkTableContent {
  data: RowsType;
  name: string;
  filesUpdate: any;
}
export const RemarkTableCard = memo(
  ({ data, name, filesUpdate }: RemarkTableContent) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const isLoadExpertiseComplete = useAppSelector(
      getProjectProcessExpertiseCompleteIsLoad
    );
    const [modalCompleteRow, setModalCompleteRow] = useState(false);
    const [mode, setMode] = useState<TModalMode>(null);

    const closeModal = useCallback(() => {
      setModalCompleteRow(false);
    }, []);

    async function backToWorkHandle(id: number) {
      const confirmResult = window.confirm(
        "Вы уверены, что хотите вернуть на доработку?"
      );
      if (confirmResult) {
        await dispatch(fetchBackToWork(id));
      }
      if (processId) {
        dispatch(fetchProjectProcessExpertise(processId));
      }
    }

    return (
      <>
        {(data.can.complete || data.can.save) && (
          <Modal
            active={modalCompleteRow}
            setActive={setModalCompleteRow}
            isLoad={isLoadExpertiseComplete}
          >
            <ModalCompleteRow
              task={data.task}
              row={data.row}
              closeModal={closeModal}
              can={data.can}
              expertiseName={name}
              mode={mode}
            />
          </Modal>
        )}
        {data.can?.edit && (
          <Modal
            active={modalCompleteRow}
            setActive={setModalCompleteRow}
            isLoad={isLoadExpertiseComplete}
          >
            <ModalCompleteRow
              task={data.task}
              row={data.row}
              closeModal={closeModal}
              can={data.can}
              expertiseName={name}
              files={filesUpdate}
              editMode
              mode={mode}
            />
          </Modal>
        )}
        <tr>
          <td rowSpan={2}>{data.row.num}</td>
          <td rowSpan={2}>{data.row.text}</td>
          <td rowSpan={2}>{data.row.comment}</td>
          <td colSpan={3}>
            <div className={cls.tbody_answer}>
              <p style={{ color: selectColor(data.task?.status?.color) }}>
                {data.task?.status?.value}
              </p>
              <span>
                {moment(
                  data?.task?.date_deadline,
                  "DD.MM.YYYY HH:mm:ss Z"
                ).format("DD.MM.YYYY")}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p
              style={{ color: selectColor(data.task?.workflow_data?.color) }}
              className={cls.tbody_reason}
            >
              {data?.task?.workflow_data?.reason}
            </p>
            <span>{data?.task?.comment}</span>
          </td>
          <td className={cls.tbody_changes}>
            {data?.task?.workflow_data?.changes}
          </td>
          <td className={cls.tbody_btn}>
            {(data.can.complete || data.can.save) && (
              <IconButton
                sx={iconBtnSx}
                onClick={() => {
                  setModalCompleteRow(true);
                  setMode("complete");
                }}
                title={"Выдать ответ"}
              >
                <AddFile />
              </IconButton>
            )}
            {data.can?.edit && (
              <IconButton
                sx={iconBtnSx}
                onClick={() => {
                  setModalCompleteRow(true);
                  setMode("edit");
                }}
                title={"Изменить ответ"}
              >
                <PencilSquare />
              </IconButton>
            )}
            {data.can?.backToWork && (
              <IconButton
                sx={iconBtnSx}
                title={"Вернуть на доработку"}
                onClick={() => {
                  backToWorkHandle(data?.task?.id);
                }}
              >
                <NotApproveIcon width={"24"} heigth={"24"} />
              </IconButton>
            )}
          </td>
        </tr>
      </>
    );
  }
);
