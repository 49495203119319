import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import {
  getCustomerAlign,
  getDocumentations,
  getInTasks,
  getOutTasks,
  getSubCheckTasks,
  getTasksByUserType,
} from "./thunks";
import {
  processInOutTasks,
  processCheckTasks,
  processWorks,
  processDocumentations,
  processAligns,
} from "./utils";

type TasksType<T> = {
  incoming: T;
  outcoming: T;
  check: T;
  aligns: T;
  documentations: T;
  worksList: T;
};

export type TState = {
  data: TasksType<any[]>;
  pending: TasksType<boolean>;
  fetched: TasksType<boolean>;
  error: TasksType<string | null>;
};

const initialState: TState = {
  data: {
    incoming: [],
    outcoming: [],
    check: [],
    aligns: [],
    documentations: [],
    worksList: [],
  },
  pending: {
    incoming: false,
    outcoming: false,
    check: false,
    aligns: false,
    documentations: false,
    worksList: false,
  },
  fetched: {
    incoming: false,
    outcoming: false,
    check: false,
    aligns: false,
    documentations: false,
    worksList: false,
  },
  error: {
    incoming: null,
    outcoming: null,
    check: null,
    aligns: null,
    documentations: null,
    worksList: null,
  },
};

const dashboardTasksAndWorks = createSlice({
  name: "dashboardTasksAndWorks",
  initialState,
  reducers: {
    clearTasks() {
      return initialState;
    },
    clearTasksData(state) {
      state.data.incoming = [];
      state.data.outcoming = [];
      state.data.check = [];
      state.data.worksList = [];

      state.fetched.incoming = false;
      state.fetched.outcoming = false;
      state.fetched.check = false;
      state.fetched.worksList = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getInTasks), (state) => {
        state.pending.incoming = true;
      })
      .addMatcher(isPending(getOutTasks), (state) => {
        state.pending.outcoming = true;
      })
      .addMatcher(isPending(getSubCheckTasks), (state) => {
        state.pending.check = true;
      })
      .addMatcher(isPending(getTasksByUserType), (state) => {
        state.pending.worksList = true;
      })
      .addMatcher(isFulfilled(getInTasks), (state, action) => {
        state.data.incoming = processInOutTasks(action.payload, true);
        state.fetched.incoming = true;
        state.pending.incoming = false;
      })
      .addMatcher(isFulfilled(getOutTasks), (state, action) => {
        state.data.outcoming = processInOutTasks(
          action.payload ? action.payload : []
        );
        state.fetched.outcoming = true;
        state.pending.outcoming = false;
      })
      .addMatcher(isFulfilled(getSubCheckTasks), (state, action) => {
        state.data.check = processCheckTasks(action.payload);
        state.fetched.check = true;
        state.pending.check = false;
      })
      .addMatcher(isFulfilled(getTasksByUserType), (state, action) => {
        state.data.worksList = processWorks(action.payload);
        state.fetched.worksList = true;
        state.pending.worksList = false;
      })
      .addMatcher(isFulfilled(getCustomerAlign), (state, action) => {
        state.data.aligns = processAligns(action.payload);
        state.fetched.aligns = true;
        state.pending.aligns = false;
      })
      .addMatcher(isFulfilled(getDocumentations), (state, action) => {
        state.data.documentations = processDocumentations(action.payload);
        state.fetched.documentations = true;
        state.pending.documentations = false;
      })
      .addMatcher(isRejected(getInTasks), (state, action) => {
        state.pending.incoming = false;
        state.error.incoming = action.payload as string;
      })
      .addMatcher(isRejected(getOutTasks), (state, action) => {
        state.pending.outcoming = false;
        state.error.outcoming = action.payload as string;
      })
      .addMatcher(isRejected(getSubCheckTasks), (state, action) => {
        state.pending.check = false;
        state.error.check = action.payload as string;
      })
      .addMatcher(isRejected(getTasksByUserType), (state, action) => {
        state.pending.worksList = false;
        state.error.worksList = action.payload as string;
      });
  },
});

export const { clearTasks, clearTasksData } = dashboardTasksAndWorks.actions;

export const getSectionTasksAndWorks = (state: CombinedState<any>) =>
  state.dashboard.tasks;

export default dashboardTasksAndWorks;
