import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { config } from "../../../app.cofig";
import {
  httpRequestGet,
  httpRequestPost,
  httpRequestPostMultiData,
  httpRequestPut,
  httpRequestPutMultiData,
  httpRequestDelete,
} from "../../services/api/api";
import {
  parseUnprocessableFields,
  request,
} from "../../services/api/requestHandler";
import {
  IProfile,
  IPaymentInfo,
  IBuildingOption,
  ISkill,
  ICountry,
  IUserRole,
} from "./types";
import {
  prepareDataForUpdateProfile,
  prepareDataForReward,
  prepareDataForPortfolio,
  prepareDataForPaymentInfo,
} from "./dataPreparers";
import { createPaginationMeta } from "../indexPages/createData";

export const getProfile = async (userId: string): Promise<IProfile> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/user/view?id=${userId}&fields=name,surname,patronymic,birthdate,email,sex,photo,phone,region,city,address,timezone,type,work_with_bim,skills,about&expand=userTypes,can`
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};

export const getRegions = async () => {
  const response = await httpRequestGet(
    `${config.localDomain}/v2/region/index?RegionSearch[country_code]=RU&page=1&per-page=100`
  );
  return response.json();
};

const getRoles = async (): Promise<IUserRole[]> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v2/user/get-roles?full=false`
  );
  return response.json();
};

const getCountries = async (): Promise<ICountry[]> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/country/get-full-list`
  );
  return response.json();
};

const getTimeZones = async (): Promise<string[]> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/user/get-time-zones`
  );
  return response.json();
};

const getSkillsList = async (): Promise<ISkill[]> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/part-group/index?fields=id,name`
  );
  return response.json();
};

const getJobPlacesList = async (userId: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/user-job-place/index?sort=-date_start&UserJobPlaceSearch[user_id]=${userId}`
  );
  return response.json();
};

const putProfile = async ({ id, ...data }: any) => {
  const preparedData = prepareDataForUpdateProfile(data);

  let response;
  await request(
    httpRequestPutMultiData(
      `${config.localDomain}/v1/user/update-profile?id=${id}`,
      {},
      preparedData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const putPaymentInfo = async ({ id, canEdit, ...data }: any) => {
  const preparedData = prepareDataForPaymentInfo(canEdit, data);
  let response;

  await request(
    httpRequestPutMultiData(
      `${config.localDomain}/v1/individual-info/update?userId=${id}`,
      {},
      preparedData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const createNewJobPlace = async (data: any) => {
  const newData = { ...data };
  newData.date_start = format(data.date_start, "MM.yyyy");
  newData.date_end = data.date_end ? format(data.date_end, "MM.yyyy") : "";

  let response;
  await request(
    httpRequestPost(
      `${config.localDomain}/v1/user-job-place/create`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const deleteOldJobPlace = async (id: number) => {
  const response = await httpRequestDelete(
    `${config.localDomain}/v1/user-job-place/delete?id=${id}`
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
};

const editOldJobPlace = async (data: any) => {
  const newData = { ...data };
  delete newData.id;
  newData.date_start = format(data.date_start, "MM.yyyy");
  newData.date_end = data.date_end ? format(data.date_end, "MM.yyyy") : "";

  let response;
  await request(
    httpRequestPut(
      `${config.localDomain}/v1/user-job-place/update?id=${data.id}`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const getEducationList = async (userId: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/user-education/index?sort=-date_start&UserEducationSearch[user_id]=${userId}`
  );
  return response.json();
};

const postEducation = async (data: any) => {
  const newData = { ...data };
  newData.date_start = format(data.date_start, "MM.yyyy");
  newData.date_end = data.date_end ? format(data.date_end, "MM.yyyy") : "";

  let response;
  await request(
    httpRequestPost(
      `${config.localDomain}/v1/user-education/create`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error();
    }
  )();

  return response;
};

const deleteEducationPlace = async (id: number) => {
  const response = await httpRequestDelete(
    `${config.localDomain}/v1/user-education/delete?id=${id}`
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
};

const editEducationPlace = async (data: any) => {
  const newData = { ...data };
  delete newData.id;
  newData.date_start = format(data.date_start, "MM.yyyy");
  newData.date_end = data.date_end ? format(data.date_end, "MM.yyyy") : "";

  let response;
  await request(
    httpRequestPut(
      `${config.localDomain}/v1/user-education/update?id=${data.id}`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error();
    }
  )();

  return response;
};

const getRewardList = async (userId: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/reward/index?RewardSearch[user_id]=${userId}`
  );
  return response.json();
};

const postReward = async (data: any) => {
  const preparedData = prepareDataForReward(data);

  let response;
  await request(
    httpRequestPostMultiData(
      `${config.localDomain}/v1/reward/create`,
      {},
      preparedData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const deleteRewardItem = async (id: number) => {
  const response = await httpRequestDelete(
    `${config.localDomain}/v1/reward/delete?id=${id}`
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
};

const editRewardItem = async (data: any) => {
  const newData = { ...data };
  delete newData.id;

  let response;
  await request(
    httpRequestPut(
      `${config.localDomain}/v1/reward/update?id=${data.id}`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

const getPortfolioList = async (userId: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/portfolio/index?sort=-id&UserPortfolioSearch[user_id]=${userId}&expand=portfolio.files.svgIcon`
  );
  return response.json();
};

const postPortfolioItem = async ({ userId, ...data }: any) => {
  const newData = prepareDataForPortfolio(data, true);

  let response;
  await request(
    httpRequestPostMultiData(
      `${config.localDomain}/v1/portfolio/create?userId=${userId}`,
      {},
      newData
    ),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error();
    }
  )();

  return response;
};

const deletePortfolioItem = async (id: number) => {
  const response = await httpRequestDelete(
    `${config.localDomain}/v1/portfolio/delete?id=${id}`
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
};

const editPortfolioItem = async (data: any) => {
  const newData = prepareDataForPortfolio(data);
  const response = await httpRequestPutMultiData(
    `${config.localDomain}/v1/portfolio/update?id=${data.id}`,
    {},
    newData
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return true;
};

export const getPortfolioFiles = (id: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/portfolio/view?id=${id}&fields=files`
  );
};

export const getAddressOptions = async (query: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/site/search-address?query=${query}`
  );
  return response.json();
};

export const getBankOptions = async (query: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/bank-account/dadata?query=${query}`
  );
  return response.json();
};

export const getLocationOptions = async (
  regionCode: number,
  location: string
) => {
  let response: Record<string, any>[] = [];
  await request(
    httpRequestGet(
      `${config.localDomain}/hint-module/dadata/search-location?code=${regionCode}&term=${location}`
    ),
    (data) => {
      response = data.map(({ label, value: name }: any) => ({
        label,
        name,
      }));
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error();
    }
  )();

  return response;
};

export const getOrganizationsOptions = async (query: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/firm/search?term=${query}`
  );
  return response.json();
};

export const getBuildingFunctions = async (): Promise<IBuildingOption[]> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/building-function/index?fields=id,name&BuildingFunctionSearch[type][]=1&BuildingFunctionSearch[type][]=2`
  );
  return response.json();
};

export const getPaymentInfo = async (id: string): Promise<IPaymentInfo> => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/individual-info/view?userId=${id}`
  );
  return response.json();
};

export const updatePassword = async (data: any) => {
  let response;
  await request(
    httpRequestPost(`${config.localDomain}/v1/user/change-pwd`, {}, data),
    (data) => {
      response = data;
    },
    () => (err) => {
      parseUnprocessableFields(err.message, () => {});
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

export const getNotifications = async (id: string) => {
  const response = await httpRequestGet(
    `${config.localDomain}/v1/email-notify-params/view?userId=${id}`
  );
  return response.json();
};

export const changeNotifications = async ({ id, ...data }: any) => {
  const response = await httpRequestPut(
    `${config.localDomain}/v1/email-notify-params/update?userId=${id}`,
    {},
    data
  );
  if (!response.ok) {
    console.log(response);
    throw new Error(response.statusText);
  }
  return response;
};

export const getProfileRequest = async (id: string) => {
  let [isOk, data] = [false, {}];
  await request(
    httpRequestGet(
      `${config.localDomain}/v1/user/view?id=${id}&expand=task,individualInfo`,
      {}
    ),
    (result) => {
      data = { ...result };
      isOk = true;
    },
    () => (err) => {
      data = { ...err };
    }
  )();

  if (!isOk) {
    throw new Error(data as string);
  }

  return data;
};

// Организация
export const searchOrganisationsByQuery = async ({
  query,
  page,
}: {
  query: string;
  page: number;
}) => {
  let response;
  await request(
    httpRequestGet(
      `${config.localDomain}/v1/firm/search-by-string?searchField=${query}&page=${page}&expand=requestToJoin`
    ),
    (data, _, headersValue) => {
      const meta = createPaginationMeta(headersValue);

      const preparedData = data.map((item: any) => {
        const { id, inn, title, address, can } = item;

        const fullFirm = {
          country_code: item.region.country.code,
          region_code: item.region.code,
          id: item.id,
          ogrn: item.ogrn,
          inn: item.inn,
          title: item.title,
          title_full: item.title_full,
          task_id: item.task_id,
          address: item.address,
          kpp: item.kpp,
          isIp: item.is_ip,
          manager_name: item.manager_name,
          manager_post: item.manager_post,
          boss_prof: item.boss_prof,
          city: item.city,
          geo_lon: item.geo_lon,
          geo_lat: item.geo_lat,
        };

        return { id, title, inn, address, can, fullFirm };
      });

      response = { data: [...preparedData], meta };
    },
    () => (err) => {
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

export const getUserFirms = async (_: null, { getState }: any) => {
  let response;
  await request(
    httpRequestGet(`${config.localDomain}/v1/user/get-user-firms`),
    (data) => {
      const state = getState();
      const { roles } = state.constants;

      const preparedData = data.map((item: any) => {
        const { id, title, inn, address, tested } = item;
        return { id, title, inn, address, tested, role: roles[item.role_id] };
      });

      response = [...preparedData];
    },
    () => (err) => {
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

export const setTelegramName = async () => {
  let response;
  await request(
    httpRequestPost(`${config.localDomain}/v1/user/set-telegram`),
    (data) => {
      response = data;
    },
    () => (err) => {
      throw Error(JSON.stringify(err));
    }
  )();

  return response;
};

export const loadProfileRequest = createAsyncThunk(
  "profile/getRequest",
  getProfileRequest
);

export const loadProfile = createAsyncThunk("profile/get", getProfile);
export const loadRegions = createAsyncThunk("regions/get", getRegions);
export const loadRoles = createAsyncThunk("roles/get", getRoles);
export const loadCountries = createAsyncThunk("countries/get", getCountries);
export const loadTimeZones = createAsyncThunk("timeZones/get", getTimeZones);
export const loadSkillsList = createAsyncThunk("skillsList/get", getSkillsList);
export const loadAddressOptions = createAsyncThunk(
  "addressOptions/get",
  getAddressOptions
);
export const loadJobPlaces = createAsyncThunk(
  "jobPlaces/get",
  getJobPlacesList
);
export const loadBuildingFunctions = createAsyncThunk(
  "buildingFunctions/get",
  getBuildingFunctions
);
export const uploadProfile = createAsyncThunk("profile/put", putProfile);
export const createJobPlace = createAsyncThunk(
  "jobPlace/post",
  createNewJobPlace
);
export const deleteJobPlace = createAsyncThunk(
  "jobPlace/delete",
  deleteOldJobPlace
);
export const editJobPlace = createAsyncThunk("jobPlace/edit", editOldJobPlace);
export const loadEducationList = createAsyncThunk(
  "education/get",
  getEducationList
);
export const createEducationPlace = createAsyncThunk(
  "educationPlace/post",
  postEducation
);
export const deleteEducation = createAsyncThunk(
  "educationPlace/delete",
  deleteEducationPlace
);
export const editEducation = createAsyncThunk(
  "educationPlace/edit",
  editEducationPlace
);
export const loadRewardList = createAsyncThunk("rewards/get", getRewardList);
export const createReward = createAsyncThunk("reward/post", postReward);
export const deleteReward = createAsyncThunk("reward/delete", deleteRewardItem);
export const updateReward = createAsyncThunk("reward/update", editRewardItem);
export const loadPortfolioList = createAsyncThunk(
  "portfolio/get",
  getPortfolioList
);
export const createPortfolio = createAsyncThunk(
  "portfolio/post",
  postPortfolioItem
);
export const deletePortfolio = createAsyncThunk(
  "portfolio/delete",
  deletePortfolioItem
);
export const updatePortfolio = createAsyncThunk(
  "portfolio/update",
  editPortfolioItem
);
export const loadPaymentInfo = createAsyncThunk(
  "paymentInfo/get",
  getPaymentInfo
);
export const updatePaymentInfo = createAsyncThunk(
  "paymentInfo/put",
  putPaymentInfo
);
export const changePassword = createAsyncThunk("password/post", updatePassword);
export const loadNotifications = createAsyncThunk(
  "notifications/get",
  getNotifications
);
export const updateNotifications = createAsyncThunk(
  "notifications/put",
  changeNotifications
);
export const setTelegram = createAsyncThunk(
  "notifications/set-telegram",
  setTelegramName
);

// Организация
export const searchOrganisations = createAsyncThunk(
  "organisation/search",
  searchOrganisationsByQuery
);

export const getUserFirmsList = createAsyncThunk(
  "organisation/get",
  getUserFirms
);
