import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { view, update } from "./queries";

export const remarkRowApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    remarkRowView: view(builder),
    remarkRowUpdate: update(builder),
  }),
  overrideExisting: false,
});

export const { useRemarkRowViewQuery, useRemarkRowUpdateMutation } =
  remarkRowApi;
