import moment from "moment";
import {
  experienceDefaultValues,
  ratingDefaultValues,
} from "./configFilterParams";
import { ParametersType, ParamsForUrlType } from "./searchParamsTypes";

export const ratingCheckDefaultValues = (data: string) => {
  if (!data) return "";
  const [first, last] = data.split(";").map((item) => Number(item));
  return first === ratingDefaultValues[0] && last === ratingDefaultValues[1];
};
export const experienceCheckDefaultValues = (data: string) => {
  if (!data) return "";
  const [first, last] = data.split(";").map((item) => Number(item));
  return (
    first === experienceDefaultValues[0] && last === experienceDefaultValues[1]
  );
};

export const createRatingToUrl = (
  data: number[] | null | undefined
): string => {
  if (!data) return "";
  return data.join(";");
};

/*
  Функция формирования поисков параметров для строки URL
  Используется на страницах с фильтрами, таких как материалы, пользователи и тд
 */
export const createSearchObjectDataFromFormParams = (
  data: ParamsForUrlType,
  type:
    | "executor"
    | "customer"
    | "gip"
    | "provider"
    | "projects"
    | "materials"
    | "tasks"
    | "documents"
    | "posts"
    | "userRoleSearch"
    | "LogSearch"
    | "projectsProcessExercise"
) => {
  const param = {
    executor: "ExecutorUserSearch",
    customer: "CustomerUserSearch",
    gip: "GipUserSearch",
    provider: "ProviderUserSearch",
    projects: "ProjectSearch",
    materials: "MaterialProcessSearch",
    tasks: "TaskSearch",
    documents: "DocumentSearch",
    posts: "PostSearch",
    userRoleSearch: "UserRoleSearch",
    LogSearch: "LogSearch",
    projectsProcessExercise: "PublishedObjectSearch",
  };

  const country = "RU";
  // [country_code] и country пока оставлено для всех
  const parametersForUrlPage: ParametersType = {
    executor: (type, data, param) => ({
      [`${param[type]}[fio]`]: data.fio || "",
      [`${param[type]}[region_code]`]: data.region ? data.region! : "",
      [`${param[type]}[skillIds]`]: data.specialization || "",
      [`${param[type]}[rating]`]: `0;${data.rating ?? 5}`,
      [`${param[type]}[country_code]`]: country,
    }),
    customer: (type, data, param) => ({
      [`${param[type]}[fio]`]: data.fio || "",
      [`${param[type]}[region_code]`]: data.region ? data.region! : "",
      [`${param[type]}[rating]`]: `0;${data.rating ?? 5}`,
      [`${param[type]}[executedProject]`]: ratingCheckDefaultValues(
        data.executedProject!
      )
        ? ""
        : data.executedProject,
      [`${param[type]}[country_code]`]: country,
    }),
    gip: (type, data, param) => ({
      [`${param[type]}[fio]`]: data.fio || "",
      [`${param[type]}[region_code]`]: data.region ? data.region! : "",
      [`${param[type]}[rating]`]: `0;${data.rating ?? 5}`,
      [`${param[type]}[executedProject]`]: ratingCheckDefaultValues(
        data.executedProject!
      )
        ? ""
        : data.executedProject,
      [`${param[type]}[jobDuration]`]: data.experience ?? "",
      [`${param[type]}[country_code]`]: country,
    }),
    provider: (type, data, param) => ({
      [`${param[type]}[firmName]`]: data.firmName || "",
      [`${param[type]}[about]`]: data.text || "",
      [`${param[type]}[skillIds]`]: data.specialization || "",
      [`${param[type]}[region_code]`]: data.region ? data.region! : "",
      [`${param[type]}[country_code]`]: country,
    }),
    projects: (type, data, param) => ({
      [`${param[type]}[name]`]: data.name || "",
      [`${param[type]}[partGroupId]`]: data.group || "",
      [`${param[type]}[dateEnd1]`]:
        data.dates && data.dates[0]
          ? moment(data.dates[0], "DD.MM.YYYY").format("DD.MM.YYYY")
          : "",
      [`${param[type]}[dateEnd2]`]:
        data.dates && data.dates[1]
          ? moment(data.dates[1], "DD.MM.YYYY").format("DD.MM.YYYY")
          : "",
      [`${param[type]}[design_type]`]: data.designType || "",
      [`${param[type]}[country_code]`]: country,
    }),
    projectsProcessExercise: (type, data, param) => ({
      [`${param[type]}[partGroupId]`]: data.group || "",
      [`${param[type]}[typeName]`]: data.sections,
      [`${param[type]}[datePublish1]`]:
        data.dates && data.dates[0]
          ? moment(data.dates[0], "DD.MM.YYYY").format("DD.MM.YYYY")
          : "",
      [`${param[type]}[datePublish2]`]:
        data.dates && data.dates[1]
          ? moment(data.dates[1], "DD.MM.YYYY").format("DD.MM.YYYY")
          : "",
      [`${param[type]}[designType]`]: data.designType || "",
    }),
    materials: (type, data, param) => ({
      [`${param[type]}[name]`]: data.name || "",
      [`${param[type]}[partGroupIds]`]: data.group || "",
      [`${param[type]}[status]`]: data.status || "",
      [`${param[type]}[country_code]`]: country,
    }),
    tasks: (type, data, param) => ({
      [`${param[type]}[workflow_class]`]: data.business || "",
      [`${param[type]}[authorSearch]`]: data.author || "",
      [`${param[type]}[executorSearch]`]: data.executor || "",
      [`${param[type]}[status]`]: data.status || "",
      [`${param[type]}[deadlineMode]`]: data.deadline || "",
      [`${param[type]}[country_code]`]: country,
    }),
    documents: (type, data, param) => ({
      [`${param[type]}[id]`]: data.id || "",
      [`${param[type]}[date_create]`]: data.date_create
        ? moment(data.date_create, "DD.MM.YYYY").format("DD.MM.YYYY")
        : "",
      [`${param[type]}[name]`]: data.name || "",
      [`${param[type]}[status]`]: data.status || "",
      [`${param[type]}[executorFio]`]: data.executorFio || "",
      [`${param[type]}[projectName]`]: data.projectName || "",
    }),
    posts: (type, data, param) => ({
      [`${param[type]}[title]`]: data.title || "",
      [`${param[type]}[tag_id]`]: data.tag_id || "",
    }),
    userRoleSearch: (type, data, param) => ({
      [`${param[type]}[fio]`]: data.fio || "",
      [`${param[type]}[role_id]`]: data.role_id || "",
    }),
    LogSearch: (type, data, param) => ({
      [`${param[type]}[date1]`]: data.date1
        ? moment(data.date1, "DD.MM.YYYY").format("DD.MM.YYYY")
        : "",
      [`${param[type]}[date2]`]: data.date2
        ? moment(data.date2, "DD.MM.YYYY").format("DD.MM.YYYY")
        : "",
      [`${param[type]}[type]`]: data.type || "",
      [`${param[type]}[userFio]`]: data.userFio || "",
      [`${param[type]}[data]`]: data.data || "",
    }),
  };

  return parametersForUrlPage[type](type, data, param);
};
