import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { config } from "../../../../../app.cofig";
import cls from "./ApplicationList.module.scss";
import Avatar from "../../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import {
  ChatIcon,
  CheckSVG,
  PencilSquareIcon,
  NotApproveIcon,
} from "../../../../newUI/SVG";
import { trimNumber } from "../../../../../utils/trimNumber";
import { ApplicationsType } from "../../../../../app/feature/ProjectProcessView/Applications/types/projectProcessApplicationTypes";
import { fetchProjectProcessSelectExecutor } from "../../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessSelectExecutor";
import { fetchProjectProcessRemoveRequest } from "../../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessRemoveRequest";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalUpdateRequest } from "./ModalUpdateRequest/ModalUpdateRequest";
import { useAppSelector } from "../../../../../app/store";
import { getApplicationsRequestUpdateIsLoad } from "../../../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsRequestUpdate";
import { iconBtnSx } from "../../../../../styles/restyle";
import { setNullRequestUpdate } from "../../../../../app/feature/ProjectProcessView/Applications/slice/projectProcessApplications";

interface CardApplicationsProps {
  application: ApplicationsType;
  setInfoTab?: () => void;
}
export const CardApplications = memo(
  ({ application, setInfoTab }: CardApplicationsProps) => {
    const dispatch = useDispatch();
    const isLoadRequestUpdate = useAppSelector(
      getApplicationsRequestUpdateIsLoad
    );
    const [toggleDiv, setToggleDiv] = useState(false);
    const [modalUpdateRequest, setModalUpdateRequest] = useState(false);
    const { author } = application.task;
    const { date_start, date_limit } = application.task.workflow_data;
    const isFirm =
      application.userType.type === 2 || application.userType.type === 3;
    const data = [
      {
        avatar: `${config.localDomain}${author.photo}`,
        link: `/user/${author.id}`,
        name: `${author.surname} ${author.name} ${author.patronymic || ""}`,
        firm: application.userType.listName,
        jobDuration: Math.floor(author.job_duration / 365),
        rating: author.rating.gip,
        firmId: isFirm ? application.userType.firmId : undefined,
      },
    ];

    async function selectExecutor() {
      await dispatch(fetchProjectProcessSelectExecutor(application.task.id));
      if (setInfoTab) {
        dispatch(setNullRequestUpdate());
        setInfoTab();
      }
    }

    async function removeRequest() {
      const result = window.confirm("Вы уверены, что хотите отозвать заявку");
      if (result && setInfoTab) {
        await dispatch(
          fetchProjectProcessRemoveRequest(application.process_id)
        );
        dispatch(setNullRequestUpdate());
        setInfoTab();
      }
    }

    function updateRequest() {
      setModalUpdateRequest(true);
    }

    const modalClose = useCallback(() => {
      setModalUpdateRequest(false);
    }, []);

    return (
      <div className={cls.card_block}>
        <div className={cls.compare}>
          <div className={cls.compare_head}>
            <Avatar compareGip data={data} />
            <div className={cls.compare_btn}>
              {application?.buttons?.request && (
                <Modal
                  active={modalUpdateRequest}
                  setActive={setModalUpdateRequest}
                  isLoad={isLoadRequestUpdate}
                >
                  <ModalUpdateRequest
                    closeModal={modalClose}
                    price={application.task.workflow_data.price}
                    type={application.task.workflow_data.executor_type}
                    dateStart={application.task.workflow_data.date_start}
                    dateLimit={application.task.workflow_data.date_limit}
                    bankId={application.task.workflow_data.bank_account_id}
                    taskId={application.task.id}
                    content={application.task.content}
                  />
                </Modal>
              )}

              {application?.buttons["select-executor"] && (
                <IconButton
                  sx={iconBtnSx}
                  onClick={selectExecutor}
                  title="Выбрать исполнителя"
                >
                  <CheckSVG width="22" height="22" />
                </IconButton>
              )}
              {application?.buttons?.chat && (
                <Link to={`/chat/${application.task.chatRoomId.id}`}>
                  <IconButton sx={iconBtnSx} title="Чат">
                    <ChatIcon width="22" height="22" />
                  </IconButton>
                </Link>
              )}
              {application?.buttons?.request && (
                <IconButton
                  sx={iconBtnSx}
                  onClick={updateRequest}
                  title="Редактировать заявку"
                >
                  <PencilSquareIcon />
                </IconButton>
              )}
              {application?.buttons?.request && (
                <IconButton
                  sx={iconBtnSx}
                  onClick={removeRequest}
                  title="Отозвать заявку"
                >
                  <NotApproveIcon width="22" heigth="22" />
                </IconButton>
              )}
            </div>
          </div>
          <div className={cls.compare_footer}>
            <div
              className={cls.compare_footer_comment}
              onClick={() => setToggleDiv(!toggleDiv)}
            >
              <p className={cls.compare_footer_comment_text}>Комментарий</p>
              <ToggleAccordion toggle={toggleDiv} />
            </div>
            <div className={cls.compare_footer_price}>
              <p>{application.price.ndsText}</p>
              <p>
                {trimNumber(application.price?.price)}
                <span>₽</span>
              </p>
            </div>
          </div>
        </div>
        {!toggleDiv && (
          <div className={cls.card_footer}>
            <div className={cls.card_footer_date}>
              <p>Сроки</p>
              <span>{`${date_start} - ${date_limit}`}</span>
            </div>
            {parse(application.textFooter || "")}
          </div>
        )}
        {toggleDiv && (
          <div className={cls.compare_comment}>
            <p>Комментарий</p>
            <p>{application?.task?.content}</p>
          </div>
        )}
      </div>
    );
  }
);
