import React, { FC, useState } from "react";
import Treaty from "../Treaty/Treaty";
import { TTreaty } from "../../../../../../app/feature/dashboard/treaties";
import { getDocuments } from "../utils";
import { TreatiesGroup, TreatiesGroupProps } from "../Treaty/Group";

type TreatiesPointProps = TreatiesGroupProps & { treaty: TTreaty };

export const TreatiesPoint: FC<TreatiesPointProps> = ({
  treaties,
  treaty,
  areTreaties,
  isMobile = false,
  offset = 0,
  ticked,
}) => {
  const [isInnerOpen, setInnerOpen] = useState<boolean>(isMobile);
  const documents = getDocuments(treaties, treaty.id);

  return (
    <>
      <Treaty
        areTreaties={areTreaties}
        treaty={treaty}
        offset={offset}
        isMobile={isMobile}
        showOpen={areTreaties}
        isOpen={isInnerOpen}
        setOpen={setInnerOpen}
      />
      {isInnerOpen &&
        documents.map(([name, folderDocuments]: any) => (
          <TreatiesGroup
            name={name}
            treaties={folderDocuments}
            ticked={ticked}
            areTreaties={false}
            isMobile={isMobile}
            offset={offset + 20}
          />
        ))}
    </>
  );
};
