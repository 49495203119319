import React, { memo } from "react";
import classNames from "classnames";
import parse from "html-react-parser";
import { ApplicationsMessages } from "src/app/feature/ProjectProcessView/Applications/types/projectProcessApplicationTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import styles from "./Warnings.module.scss";
import { ApiCommonResponses } from "../../../../../../../entities/apiCommonResponses";

interface IWarningsProps {
  warnings: ApiCommonResponses.Warning;
  applicationWarning?: ApplicationsMessages[] | undefined;
  classname?: string;
}

export const Warnings = memo((props: IWarningsProps) => {
  const { warnings, applicationWarning, classname } = props;

  const haveWarnings =
    warnings.actual.title ||
    warnings.input.title ||
    warnings.output.title ||
    warnings.required.title;

  return (
    <>
      {haveWarnings && (
        <div className={classNames(styles.warning, classname)}>
          <h2>Внимание !</h2>
          {warnings.input.data.length > 0 && (
            <>
              <h3>Требуемые задания</h3>
              <p className={styles.warning_lineHeight}>
                {warnings.input.title}
              </p>
              <div className={styles.warning_flex}>
                {warnings.input.data.map((warning) => (
                  <TextLink
                    key={warning.processId}
                    url={`/project-process/${warning.processId}`}
                    variant={styles.warningLink}
                  >
                    {warning.name}
                  </TextLink>
                ))}
              </div>
            </>
          )}
          {warnings.actual && (
            <div className={styles.actual}>
              <h3>{warnings.actual.title}</h3>
              <div className={styles.actual_flex}>
                {warnings.actual.data.map((warning) => (
                  <p key={warning}>{warning}</p>
                ))}
              </div>
            </div>
          )}
          {warnings.output && (
            <div className={styles.actual}>
              <p>{warnings.output.title}</p>
            </div>
          )}
          {warnings.required && (
            <>
              <div className={styles.warning_lineHeight}>
                {parse(warnings.required.title)}
              </div>
              <div className={styles.warning_flex}>
                {warnings.required.data.map((warning) => (
                  <TextLink
                    url={`/project-process/${warning.processId}`}
                    key={warning.processId}
                    variant={styles.warningLink}
                  >
                    {warning.name}
                  </TextLink>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {applicationWarning && (
        <div className={classNames(styles.warning, classname)}>
          <h1>Внимание !</h1>
          <p>
            Выбор исполнителя невозможен, так как не заполнены данные в личном
            кабинете
          </p>
          <div className={styles.applications}>
            {applicationWarning.map((warnings: any) => (
              <div key={warnings.title} className={styles.applications_block}>
                <div className={styles.applications_block_text}>
                  <h3>{warnings.title}</h3>
                  {warnings.attributes.map((attribute: any) => (
                    <p key={attribute}>{attribute}</p>
                  ))}
                </div>
                {warnings.link && (
                  <Button
                    href={warnings.link}
                    redirect
                    target={"_blank"}
                    variant="contained"
                  >
                    Заполнить
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
});
