import { httpRequestGet, httpRequestPost, httpRequestPut } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessApplications = (
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/application?id=${id}`
  );
};

export const ApiProjectProcessSelectExecutor = (
  taskId: number
): Promise<Response> => {
  // принять заявку на исполнителя
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/select-executor?id=${taskId}`
  );
};

export const ApiProjectProcessRemoveRequestExecutor = (
  processId: number
): Promise<Response> => {
  // отозвать заявку на исполнителя
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/remove-execution-request?processId=${processId}`
  );
};

export const ApiProjectProcessUpdateRequest = (
  taskId: number,
  data: any
): Promise<Response> => {
  // редактировать заявку на исполнителя
  return httpRequestPut(
    `${config.localDomain}/v1/project-access/request-update?id=${taskId}`,
    {},
    data
  );
};
