import * as Yup from "yup";
import moment from "moment/moment";
import { userIsAdmin } from "../../../../../app/services/user/userRoles";

const parseDate = (value: Date, originalValue: string) =>
  moment(originalValue).isValid()
    ? value
    : moment(originalValue, "DD.MM.YYYY").toDate();

export function ModalSchema(
  haveExpertise: boolean,
  withDocs: boolean,
  checkPrice: boolean
): any {
  console.log(checkPrice);
  const isAdmin = userIsAdmin();
  const schema = Yup.object().shape({
    price: !checkPrice
      ? Yup.number()
          .min(1000, `Стоимость не может быть меньше 1000 рублей!`)
          .max(10000000, `Стоимость не может быть больше 10 млн рублей!`)
          .required("Это поле обязательно для заполнения")
          .typeError("Значение «Cтоимость» должно быть числом.")
      : Yup.number()
          // eslint-disable-next-line no-restricted-globals
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
    type: Yup.string()
      .ensure()
      .required("Необходимо выбрать форму ответственности"),
    date_start: isAdmin
      ? Yup.date()
          .typeError("Необходимо заполнить «Начальный срок»")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date()
          .typeError("Необходимо заполнить «Начальный срок»")
          .required("Необходимо заполнить «Начальный срок»")
          .min(new Date(), "Начальный срок должен быть в будущем"),
    date_limit: isAdmin
      ? Yup.date()
          .typeError("Необходимо заполнить «Конечный срок»")
          .required("Необходимо заполнить «Конечный срок»")
      : Yup.date()
          .typeError("Необходимо заполнить «Конечный срок»")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("date_start"),
            "Конечный срок не может быть раньше начального"
          )
          .min(new Date(), "Конечный срок должен быть в будущем")
          .test(
            "minDay",
            "Продолжительность работ должна быть от 8 дней с учетом проверки результата у ГИП и смежных исполнителей",
            // @ts-ignore
            function (value) {
              const expiryDate = moment(value);
              const enteredDate = moment(this.parent.date_start);
              const tmpExpiryDate = moment(enteredDate).add(8, "days");
              if (!tmpExpiryDate.isAfter(expiryDate)) {
                return true;
              }
            }
          ),
    pay1: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? 0 : value))
      .when("with_docs", {
        is: "Да",
        then: Yup.number()
          .typeError("Значение должно быть целым числом")
          .required("Это поле обязательно для заполнения")
          .nullable()
          .optional()
          .min(20, "Аванс должен быть не меньше 20%")
          .max(80, "Аванс должен быть не больше 80%"),
        otherwise: withDocs
          ? Yup.number()
              // eslint-disable-next-line no-restricted-globals
              .transform((value) => (isNaN(value) ? 0 : value))
              .min(20, "Аванс должен быть не меньше 20%")
              .max(80, "Аванс должен быть не больше 80%")
          : Yup.number()
              // eslint-disable-next-line no-restricted-globals
              .transform((value) => (isNaN(value) ? 0 : value)),
      }),
    pay2: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? 0 : value))
      .when("with_docs", {
        is: "Да",
        then: Yup.number()
          .typeError("Значение должно быть целым числом")
          .required("Это поле обязательно для заполнения")
          .nullable()
          .optional()
          .min(0, "Минимум 0%")
          .max(80, "Максимум 80%"),
        otherwise: Yup.number().transform((value) =>
          // eslint-disable-next-line no-restricted-globals
          isNaN(value) ? 0 : value
        ),
      }),
    pay3: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? 0 : value))
      .when("with_docs", {
        is: "Да",
        then: Yup.number()
          .required("Это поле обязательно для заполнения")
          .nullable()
          .optional()
          .typeError("Значение должно быть целым числом")
          .min(0, "Минимум 0%")
          .max(80, "Максимум 80%"),
        otherwise: Yup.number().transform((value) =>
          // eslint-disable-next-line no-restricted-globals
          isNaN(value) ? 0 : value
        ),
      }),
    paymentSum: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? 0 : value))
      .test("max", "Общая сумма оплаты должна быть равна 100%", function () {
        if (!withDocs) {
          return true;
        }
        const { pay1 } = this.parent;
        const { pay3 } = this.parent;
        const { pay2 } = this.parent;

        if (haveExpertise) {
          return pay2 === 100 - pay1 - pay3;
        }
        if (!haveExpertise) {
          return pay2 === 100 - pay1;
        }
        return !pay2;
      }),
  });

  return schema;
}
