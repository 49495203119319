import React, { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { ProjectProcessMaterialsTypes } from "../../../../../app/feature/ProjectProcessView/Material/types/projectProcessMaterialsTypes";
import cls from "./MaterialCard.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { trim } from "../../../User/View/Portfolio/Portfolio";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { MaterialTaskCard } from "./MaterialTaskCard/MaterialTaskCard";
import { DeleteButtonIcon } from "../../../../newUI/SVG";
import { iconBtnSx, palette } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalViewForm } from "./ModalViewForm/ModalViewForm";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { fetchProjectProcessMaterialDelete } from "../../../../../app/feature/ProjectProcessView/Material/services/fetchProjectProcessMaterialDelete";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchMaterialTakeToWork } from "../../../../../app/feature/ProjectProcessView/Material/services/fetchMaterialTakeToWork";
import { fetchProcessMaterial } from "../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterial";

export const MaterialCard: React.FC<ProjectProcessMaterialsTypes> = memo(
  ({
    tasks,
    name,
    can,
    date_limit,
    comment,
    files,
    countKP,
    countTasks,
    id,
  }) => {
    const [toggleOffer, setToggleOffer] = useState(false);
    const [modalViewForm, setModalViewForm] = useState(false);
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    async function deleteButtonClick() {
      const confirmResult = window.confirm("Вы уверены, что хотите удалить?");
      if (processId && confirmResult) {
        await dispatch(
          fetchProjectProcessMaterialDelete({ materialId: id, processId })
        );
      }
    }

    async function takeToWorkClick(materialId: number) {
      const confirmResult = window.confirm(
        "Вы уверены, что хотите взять в работу?"
      );
      if (confirmResult && processId) {
        await dispatch(fetchMaterialTakeToWork({ materialId }));
        dispatch(fetchProcessMaterial(processId));
      }
    }

    return (
      <>
        <div className={cls.wrapper}>
          <div className={cls.title}>
            <h1>{name}</h1>
            <div className={cls.title_btn}>
              {can?.updateQuestionnaire && (
                <TextLink
                  url={`/material-process/edit-form-columns?id=${id}`}
                  redirect
                >
                  <CustomButton width={220} background={palette.blue}>
                    Заполнить опросный лист
                  </CustomButton>
                </TextLink>
              )}
              {can?.questionnaire && (
                <>
                  <Modal active={modalViewForm} setActive={setModalViewForm}>
                    <ModalViewForm materialId={id} />
                  </Modal>
                  <CustomButton
                    width={160}
                    background={palette.blue}
                    onClick={() => setModalViewForm(true)}
                  >
                    Опросный лист
                  </CustomButton>
                </>
              )}
              {can?.takeToWork && (
                <CustomButton
                  background={palette.green}
                  width={160}
                  onClick={() => takeToWorkClick(id)}
                >
                  Взять в работу
                </CustomButton>
              )}
              {can?.delete && (
                <IconButton sx={iconBtnSx} onClick={deleteButtonClick}>
                  <DeleteButtonIcon />
                </IconButton>
              )}
            </div>
          </div>
          {can?.updateQuestionnaire && (
            <p className={cls.error}>
              Необходимо заполнить и опубликовать опросный лист!
            </p>
          )}
          <div className={cls.grid}>
            <div className={cls.card}>
              <h3>Срок предоставления ТКП</h3>
              <p>{date_limit}</p>
              <h3>Комментарий</h3>
              <p>{comment}</p>
            </div>
            <div className={cls.fileCard}>
              <div className={cls.fileCard_flex}>
                <div className={cls.fileCard_list}>
                  <h3>Опросный лист</h3>
                  {!!files.length &&
                    files.map((file) => (
                      <TextLink
                        key={file?.url}
                        variant={cls.textLink}
                        redirect
                        target="blank"
                        url={file?.url}
                      >
                        {trim(file?.name)}
                      </TextLink>
                    ))}
                </div>
                {files?.length > 0 && (
                  <TextLink
                    variant={cls.textLink}
                    redirect
                    target="blank"
                    url={`/material-process/download-files?id=${id}`}
                  >
                    Скачать все
                  </TextLink>
                )}
              </div>
              <div className={cls.fileCard_count}>
                <div className={cls.fileCard_count_request}>
                  <h3>{countTasks}</h3>
                  <p>
                    Направлено <br /> запросов
                  </p>
                </div>
                <div className={cls.fileCard_count_offer}>
                  <h3>{countKP}</h3>
                  <div
                    className={cls.fileCard_count_flex}
                    onClick={() => setToggleOffer(!toggleOffer)}
                  >
                    <p>Предложения</p>
                    <ToggleAccordion toggle={toggleOffer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toggleOffer && (
          <div className={cls.offer}>
            {tasks?.map((task) => (
              <MaterialTaskCard
                dateLimit={date_limit}
                cardName={name}
                key={task.id}
                {...task}
                materialId={id}
              />
            ))}
            {!tasks?.length && <p>Ничего не найдено.</p>}
          </div>
        )}
      </>
    );
  }
);
