import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { TabChildrenHeader } from "../../../TabsPanel";
import { useExecutionTask } from "../lib/hooks/useExecutionTask";
import { ExecutionTask } from "./ExecutionTask";
import styles from "./ExecutionTask.module.scss";
import TaskModals from "../../../task";
import ProcessWorkModals from "../../modals";
import { BackToWorkButton } from "../../../../../features/v2/BackToWorkButton";
import { useModals } from "../lib/hooks/useModals";
import { formFullUserName } from "../../../../../shared/lib/helpers";
import { ExecutionTaskDates } from "./ExecutionTaskDates";

type Props = {
  processWorkId: number;
};

export const ExecutionTasks = ({ processWorkId }: Props) => {
  const { isExecutionTaskLoading, executionTaskList } =
    useExecutionTask(processWorkId);

  const {
    handleHideComments,
    handleShowCheckTask,
    handleHideCheckTask,
    handleShowExecuteComplete,
    handleHideExecuteComplete,
    comments,
    checkCustomerAlign,
    executeComplete,
  } = useModals();

  return (
    <>
      <Modal isActive={comments.isOpen} handleClose={handleHideComments}>
        <TaskModals.CommentsForm taskId={comments.taskId!} />
      </Modal>
      <Modal
        isActive={checkCustomerAlign.isOpen}
        handleClose={handleHideCheckTask}
      >
        <ProcessWorkModals.CheckCustomerAlignForm
          id={checkCustomerAlign.taskId!}
          header={checkCustomerAlign.header}
        />
      </Modal>
      <Modal
        isActive={executeComplete.isOpen}
        handleClose={handleHideExecuteComplete}
      >
        <ProcessWorkModals.ExecuteCompleteForm
          id={executeComplete.taskId!}
          header={executeComplete.header}
        />
      </Modal>
      <TabChildrenHeader title="Документация" />
      <Column maxHeight="865px">
        {isExecutionTaskLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {executionTaskList.map((executionTask: any) => {
              const { title, task, status } = executionTask;

              const executorName = formFullUserName(task.executor);

              const actions = (
                <div className={styles.actions}>
                  {task.can.check && (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleShowCheckTask(task.id, task.name);
                      }}
                    >
                      Проверить
                    </Button>
                  )}
                  {task.can.complete && (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleShowExecuteComplete(
                          task.id,
                          task.name,
                          task.comment
                        );
                      }}
                    >
                      Выдать
                    </Button>
                  )}
                  {task.can.backToWork && <BackToWorkButton taskId={task.id} />}
                </div>
              );

              return (
                <ExecutionTask
                  key={task.id}
                  taskId={task.id}
                  filesCount={task.files.length}
                  dates={
                    <ExecutionTaskDates
                      status={status}
                      task={task}
                      isRequest={executionTask?.isRequest}
                    />
                  }
                  taskName={title}
                  userName={executorName}
                  actions={actions}
                />
              );
            })}
          </ListMapper>
        )}
      </Column>
    </>
  );
};
