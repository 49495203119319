import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
import parse from "html-react-parser";
import {
  fetchGetLogTypes,
  fetchProjectHistory,
} from "../../../../../app/feature/ProjectView/HistoryReducer/thunks";
import { getProjectHistory } from "../../../../../app/feature/ProjectView/HistoryReducer/projectHistory";
import styles from "./History.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { selectorAppTheme } from "../../../../../app/feature/app/app";
import { SortButton } from "../../../../UI/components/Buttons/SortButton/SortButton";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { userIsAdmin } from "../../../../../app/services/user/userRoles";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { NewPaginate } from "../../../../newUI/NewPagination/NewPagination";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { Header } from "./Header/Header";
import { HistorySkeleton } from "./HistorySkeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface IHistoryProps {
  setDefaultTab: () => void;
}

export const History = ({ setDefaultTab }: IHistoryProps) => {
  const { project } = useSelector(getProjectViewData);
  const isTablet = useIsMobile(1600);
  const dispatch = useDispatch();
  const { logs, isLoad, meta, personValue } = useSelector(getProjectHistory);
  const params = useParams<{ id: string }>();
  const isAdmin = userIsAdmin();
  const [page, setPage] = useState(1);
  const [currentMetaPage, setCurrentMeta] = useState({
    sort: "type",
  });

  if (!isAdmin && !project?.can.editByGipHelper) {
    setDefaultTab();
  }

  let date1 = "";
  let date2 = "";
  if (personValue.date1) {
    date1 = moment(personValue.date1).format("DD.MM.YYYY");
  }
  if (personValue.date2) {
    date2 = moment(personValue.date2).format("DD.MM.YYYY");
  }
  const baseUrl = `?id=${params.id}&page=${page}&per-page=10&LogSearch[userFio]=${personValue.userFio}&LogSearch[type]=${personValue.type?.id}&LogSearch[data]=${personValue.data}&LogSearch[date1]=${date1}&LogSearch[date2]=${date2}&sort=${currentMetaPage.sort}`;
  const theme = useSelector(selectorAppTheme);
  const [searchString, setSearchString] = useState(baseUrl);

  useEffect(() => {
    if (params.id) {
      setSearchString(baseUrl);
    }
  }, [page, personValue, currentMetaPage]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
    dispatch(fetchGetLogTypes());
  }, []);

  useEffect(() => {
    dispatch(fetchProjectHistory(searchString));
  }, [searchString]);

  const onChangePage = (event: any, page: number) => {
    setPage(page);
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const paginate = (
    <NewPaginate
      page={Number(meta.paginationCurrentPage)}
      handleChange={onChangePage}
      theme={theme}
      count={meta.paginationPageCount}
      variant="outlined"
      shape="rounded"
    />
  );

  if (logs) {
    const mapLogs = logs.map((el) => (
      <div key={el.id} className={styles.parent}>
        <p className={classNames(styles.parent_container, styles.parent_first)}>
          {el.id}
        </p>
        <div className={classNames(styles.parent_container, styles.date)}>
          <p>{el.date_create.slice(0, 10)}</p>
          <span>{el.date_create.slice(10, -3)}</span>
        </div>
        <div className={styles.parent_container}>
          <TextLink url={`/user/${el.user.id}`} variant={styles.mainLink}>
            {`${el.user.name} ${el.user.surname} ${el.user.patronymic} `}
          </TextLink>
        </div>
        <div className={styles.parent_container}>{el.type}</div>
        <div
          className={classNames(styles.parent_container, styles.parent_last)}
        >
          <div>
            {el.data.map((item) => (
              <React.Fragment key={item.attribute}>
                {item.attribute && (
                  <div className={styles.attribute}>
                    <p>{item.attribute}:</p>
                    {parse(String(item.newValue))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    ));

    return (
      <div className={styles.wrapper}>
        <Header />
        {isLoad && <HistorySkeleton />}
        {!isLoad && (
          <div className={styles.parent}>
            <p
              className={classNames(
                styles.parent_container,
                styles.header,
                styles.parent_first
              )}
            >
              №
            </p>
            <div className={classNames(styles.parent_container, styles.header)}>
              <SortButton
                posVariant={styles.sortBtn}
                clickHandler={onClickSort}
                sortType="date_create"
                text="Время"
                isUp={currentMetaPage.sort === "date_create"}
                isShow={
                  currentMetaPage.sort === "date_create" ||
                  currentMetaPage.sort === "-date_create"
                }
                isDisable={isLoad}
              />
            </div>
            <div className={classNames(styles.parent_container, styles.header)}>
              <SortButton
                posVariant={styles.sortBtn}
                clickHandler={onClickSort}
                sortType="user_id"
                text="Пользователь"
                isUp={currentMetaPage.sort === "user_id"}
                isShow={
                  currentMetaPage.sort === "user_id" ||
                  currentMetaPage.sort === "-user_id"
                }
                isDisable={isLoad}
              />
            </div>
            <div className={classNames(styles.parent_container, styles.header)}>
              <SortButton
                posVariant={styles.sortBtn}
                clickHandler={onClickSort}
                sortType="type"
                text="События"
                isUp={currentMetaPage.sort === "type"}
                isShow={
                  currentMetaPage.sort === "type" ||
                  currentMetaPage.sort === "-type"
                }
                isDisable={isLoad}
              />
            </div>
            <div
              className={classNames(
                styles.parent_container,
                styles.header,
                styles.parent_last
              )}
            >
              <SortButton
                posVariant={styles.sortBtn}
                clickHandler={onClickSort}
                sortType="id"
                text="Данные"
                isUp={currentMetaPage.sort === "id"}
                isShow={
                  currentMetaPage.sort === "id" ||
                  currentMetaPage.sort === "-id"
                }
                isDisable={isLoad}
              />
            </div>
            <ScrollWrap
              height={isTablet ? "auto" : "750px"}
              classname={styles.scroll}
            >
              {mapLogs}
            </ScrollWrap>
          </div>
        )}

        <div className={styles.paginationContainer}>{paginate}</div>
      </div>
    );
  }
  return <div></div>;
};
