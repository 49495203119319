import React from "react";
import {
  Pagination,
  PaginationProps as MuiPaginationProps,
} from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";

export type PaginateProps = {
  posVariant?: string;
  theme?: "old" | "dark" | "light";
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& button[class^='MuiButtonBase-root']": {
        border: "none",
        borderRadius: "4px",
        background: "#101920",
        color: "#D5D6DE",
        fontSize: "16px",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        background: "#0AA699",
        color: "#101920",
      },
      "& .MuiButtonBase-root.MuiPaginationItem-root": {
        margin: "0 5px",
      },
      "& .MuiPaginationItem-previousNext": {
        margin: "0 10px!important",
      },
    },
  })
);
export const NewPaginate: React.FC<
  PaginateProps & Omit<MuiPaginationProps, keyof PaginateProps>
> = (props) => {
  const { posVariant = "", handleChange, ...other } = props;
  const classesMui = useStyles(props);
  return (
    <Pagination
      siblingCount={window.innerWidth > 680 ? 1 : 0}
      className={classNames(classesMui.root, posVariant)}
      onChange={handleChange}
      {...other}
    />
  );
};
