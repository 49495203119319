import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const canCheck = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.CanCheck, TParams>({
    query: (params) => ({
      url: `v1/project-process/can-check`,
      params: {
        id: params.id,
      },
      providesTags: ["ProjectProcess.CanCheck"],
    }),
  });
