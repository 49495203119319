import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { useRequestCustomerAlignComplete } from "../lib/hooks";
import { Statuses } from "../../../../../../shared/lib/constants/statuses";

type Props = {
  id: number;
  header: string;
};

export const RequestCustomerAlignCompleteForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    control,
    isSubmitting,
    processesToCheck,
    isRejecting,
    isAccepting,
    isTaskViewPending,
  } = useRequestCustomerAlignComplete({
    id,
    defaultValues: {
      status: null,
      processes: [],
      executorComment: "",
      files: [],
    },
    handleClose,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange } }) => (
              <Uploader
                canEdit
                getDataCallback={() => getTaskFiles(id)}
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors.files)}
                helperText={errors.files?.message}
              />
            )}
          />
          <TextField
            {...register("executorComment")}
            label="Комментарий исполнителя"
            multiline
            minRows={3}
            error={Boolean(errors.executorComment)}
            helperText={errors.executorComment?.message}
          />
          <Controller
            name="processes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectMultiple
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                label="Проверяющие"
                value={value}
                options={processesToCheck.data}
                isLoading={processesToCheck.pending}
                showSelectAll
              />
            )}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isRejecting}
              type="submit"
              color="error"
              variant="outlined"
              onClick={() => {
                setValue("status", Statuses.IS_REJECT);
              }}
            >
              Не требуется
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isAccepting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.DONE);
              }}
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
