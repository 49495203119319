import { TFieldValues } from "../../model/types";

export const formPricesData = (dataValues: TFieldValues) => {
  const data = dataValues.refund
    ? Object.entries(dataValues.processes.checked)
        .filter(([_, value]) => value)
        .map(([id]: any) => {
          return {
            id: Number(id),
            price: Number(dataValues.processes.price[id]),
          };
        })
    : [];

  return data;
};
