import { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessView } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { fetchProjectProcessDocumentations } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import {
  getProjectProcessDocumentsIsLoad,
  getProjectProcessDocumentsTab,
} from "src/app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { setTabs } from "src/app/feature/ProjectProcessView/Documentations/slice/projectProcessDocumentations";
import { getTabShowProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { CustomerAlign } from "./CustomerAlign/CustomerAlign";
import { ProcessTask } from "./ProcessTask/ProcessTask";
import cls from "./Documentations.module.scss";
import { HorizontalTabsPanel } from "../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { SkeletonDocumentationsTab } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import {
  TModalData,
  modalInitialData,
} from "../../Project/View/CheckTasks/CheckTaskCard";
import { ModalWrapper } from "../../User/Edit/PersonalDataChange/PersonalDataChange";
import ModalComment from "../../Project/View/Tasks/ModalComment/ModalComment";

export const Documentations = ({ currentTab }: any) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const [modalData, setModalData] = useState<TModalData>(modalInitialData);

  const infoId = useAppSelector(getProjectProcessInfoId);
  const isLoad = useAppSelector(getProjectProcessDocumentsIsLoad);
  const activeTab = useAppSelector(getProjectProcessDocumentsTab);
  const tabsShow = useAppSelector(getTabShowProcessInfo);
  const {
    pending: { subTaskCompletion, subTaskBackToWork },
  } = useAppSelector(getProjectViewData);

  const tabs = [
    {
      id: 1,
      label: "Предварительные решения",
      incomplete: tabsShow?.customerAlign.dot,
    },
    {
      id: 2,
      label: "Готовая документация",
      incomplete: tabsShow?.processTask.dot,
    },
  ];

  const setActiveTabMemo = useCallback((tabId: number | string) => {
    dispatch(setTabs(tabId));
  }, []);

  const components = [
    {
      id: 1,
      component: (
        <CustomerAlign setModalData={setModalData} currentTab={currentTab} />
      ),
    },
    {
      id: 2,
      component: <ProcessTask setModalData={setModalData} />,
    },
  ];

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId && !subTaskCompletion && !subTaskBackToWork) {
      dispatch(fetchProjectProcessDocumentations(infoId));
    }
  }, [infoId, subTaskCompletion, subTaskBackToWork]);

  return (
    <div className={cls.wrapper}>
      <HorizontalTabsPanel
        tabs={tabs}
        components={components}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        isLoad={isLoad}
        skeleton={<SkeletonDocumentationsTab />}
      />
      <ModalWrapper
        show={modalData.open}
        handleCloseModal={() => setModalData(modalInitialData)}
        head={<h1 className={cls.commentModalHeader}>Комментарии</h1>}
      >
        <ModalComment name="" taskId={modalData.id} />
      </ModalWrapper>
    </div>
  );
};
