import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../services/api/requestHandler";
import {
  ApiProjectDocument,
  ApiProjectDocumentV2,
} from "../../../services/api/project/documents";
import {
  ProjectDocumentReturn,
  ProjectDocumentReturnV2,
} from "../../../../types/api/responseTypes/apiDocumentsType";

export const fetchProjectDocument = createAsyncThunk<
  ProjectDocumentReturn[],
  string
>("projectDocumentFetch/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiProjectDocument(id);
    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchProjectDocumentV2 = createAsyncThunk<
  ProjectDocumentReturnV2[],
  { id: number; statusType: string; load?: string; searchText?: string }
>(
  "fetchProjectDocumentV2/fetch",
  async ({ id, statusType, searchText }, { rejectWithValue }) => {
    try {
      const response = await ApiProjectDocumentV2(id, statusType, searchText);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
