export const remarkReasons = [
  {
    id: 1,
    title: "Замечание принято",
  },
  {
    id: 2,
    title: "Замечание принято частично",
  },
  {
    id: 3,
    title: "Замечание не принято",
  },
];
