import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { TAttorneyFileError } from "./useAttorneyFile";

export type TDocType = {
  id: number | null;
  title: string;
};

export const attorney = {
  id: 2,
  title: "Доверенность",
};

export const notRequired = {
  id: null,
  title: "Не требуется",
};

export const charter = {
  id: 1,
  title: "Устав",
};

export const getFileSizeInMb = (size: number) => size / 1024 ** 2;

export const getDocTypes = (isIp: boolean) => [
  isIp ? notRequired : charter,
  attorney,
];

export const preCheck = (
  isIp: boolean,
  id: number | null,
  showAttorneyForm: boolean,
  fileForUploading: File | boolean,
  setAttorneyFileError: Dispatch<SetStateAction<TAttorneyFileError>>,
  attorneyFileError: TAttorneyFileError
) => {
  const checkIsIp =
    (isIp && (id === null || id === 2)) || (!isIp && (id === 1 || id === 2));

  const checkFile = showAttorneyForm ? fileForUploading : true;

  const largeFileSize = attorneyFileError.size;

  if (!checkIsIp || !checkFile || largeFileSize) {
    if (!checkFile) {
      setAttorneyFileError({
        required: true,
        size: false,
      });
    }
    return false;
  }

  return true;
};

const parseDate = (date: Date | string | undefined) =>
  moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") ?? "";

export const prepareForm = (
  initialValues: any,
  data: any,
  photoFile: File | null,
  fileForUploading: File | boolean
) => {
  const formData = new FormData();

  formData.append("inn", data.inn);
  formData.append("title", data.title);
  formData.append("region_code", data.region);
  formData.append("doc_type", data.doc_type.id);

  if (photoFile) {
    formData.append("photo_upload", photoFile);
  }

  if (
    initialValues.title_full ||
    (!initialValues.title_full && data.title_full)
  )
    formData.append("title_full", data.title_full);
  if (initialValues.kpp || (!initialValues.kpp && data.kpp))
    formData.append("kpp", data.kpp);
  if (initialValues.ogrn || (!initialValues.ogrn && data.ogrn))
    formData.append("ogrn", data.ogrn);
  if (initialValues.phone || (!initialValues.phone && data.phone))
    formData.append("phone", data.phone);
  if (initialValues.email || (!initialValues.email && data.email))
    formData.append("email", data.email);

  if (
    initialValues.nds?.title ||
    (!initialValues.nds?.title && data.nds?.title)
  )
    formData.append("nds", data.nds.id);

  if (initialValues.city || (!initialValues.city && data.city))
    formData.append("city", data.city);
  if (initialValues.address || (!initialValues.address && data.address))
    formData.append("address", data.address);
  if (
    initialValues.address_fact ||
    (!initialValues.address_fact && data.address_fact)
  )
    formData.append("address_fact", data.address_fact);
  if (
    initialValues.manager_post ||
    (!initialValues.manager_post && data.manager_post)
  )
    formData.append("manager_post", data.manager_post);
  if (
    initialValues.manager_name ||
    (!initialValues.manager_name && data.manager_name)
  )
    formData.append("manager_name", data.manager_name);

  if (initialValues.boss_prof || (!initialValues.boss_prof && data.boss_prof))
    formData.append("boss_prof", data.boss_prof);

  if (initialValues.fio || (!initialValues.fio && data.fio.id))
    formData.append("boss_id", data.fio.id);

  if (data.doc_type.id === 2) {
    formData.append("FirmAttorney[num]", data.doc_num || "б/н");

    if (
      initialValues.doc_date_start ||
      (!initialValues.doc_date_start && data.doc_date_start)
    )
      formData.append(
        "FirmAttorney[date_start]",
        parseDate(data.doc_date_start) === "Invalid date"
          ? ""
          : parseDate(data.doc_date_start)
      );
    if (
      initialValues.doc_date_end ||
      (!initialValues.doc_date_end && data.doc_date_end)
    )
      formData.append(
        "FirmAttorney[date_end]",
        parseDate(data.doc_date_end) === "Invalid date"
          ? ""
          : parseDate(data.doc_date_end)
      );
    if (
      initialValues.doc_date_reg ||
      (!initialValues.doc_date_reg && data.doc_date_reg)
    )
      formData.append(
        "FirmAttorney[date_sign]",
        parseDate(data.doc_date_reg) === "Invalid date"
          ? ""
          : parseDate(data.doc_date_reg)
      );

    if (fileForUploading === true && initialValues.firmAttorney.id) {
      formData.append("FirmAttorney[id]", initialValues.firmAttorney.id);
    } else {
      formData.append("FirmAttorney[file_upload]", fileForUploading as File);
    }
  }

  return formData;
};
