import React, { memo, useCallback, useState } from "react";
import { TGroups } from "../../../../../app/feature/ProjectProcessView/Work/types/projectProcessWorkTypes";
import cls from "./GroupsCard.module.scss";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { WorkList } from "./WorkList/WorkList";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessDelegateLoad,
  getProjectProcessWorkCardIsLoad,
} from "../../../../../app/feature/ProjectProcessView/Work/selectors/getProjectProcessWork";
import { SkeletonProjectProcessWorkCard } from "../../../../UI/components/Project/Skeleton";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalDelegate } from "./ModalDelegate/ModalDelegate";

export const GroupsCard: React.FC<TGroups & { userId: number }> = memo(
  ({
    works,
    userFio,
    delegate,
    canDelegate,
    doneCount,
    outDateCount,
    dot,
    inWorkCount,
    userId,
  }) => {
    const arrayWorks = Object.values(works);
    const [toggleCard, setToggleCard] = useState(false);
    const [modalEditDelegate, setModalEditDelegate] = useState(false);
    const isLoadCard = useAppSelector(getProjectProcessWorkCardIsLoad);
    const isLoadDelegate = useAppSelector(getProjectProcessDelegateLoad);

    const memoSetModalEditDelegate = useCallback((e: Event) => {
      e.stopPropagation();
      setModalEditDelegate(true);
    }, []);

    const closeModal = useCallback(() => {
      setModalEditDelegate(false);
    }, []);

    return (
      <div className={cls.card}>
        {canDelegate && (
          <Modal
            active={modalEditDelegate}
            setActive={setModalEditDelegate}
            isLoad={isLoadDelegate}
          >
            <ModalDelegate
              delegate={delegate}
              userId={userId}
              closeModal={closeModal}
            />
          </Modal>
        )}
        <div onClick={() => setToggleCard(!toggleCard)}>
          <Accordion
            text={userFio}
            toogle={toggleCard}
            isDelegate
            delegate={delegate}
            doneCount={doneCount}
            outDateCount={outDateCount}
            inWorkCount={inWorkCount}
            classname={cls.workAccordion}
            canPencilBtn={canDelegate}
            pencilBtnClick={memoSetModalEditDelegate}
            dotStatus={dot ? "warning" : undefined}
          />
        </div>
        {toggleCard && arrayWorks && (
          <ul className={cls.works}>
            {isLoadCard && <SkeletonProjectProcessWorkCard />}
            {!isLoadCard &&
              arrayWorks.map((work) => (
                <WorkList key={work.work.id} {...work} userFio={userFio} />
              ))}
          </ul>
        )}
      </div>
    );
  }
);
