import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import style from "./style.module.scss";
import { stringRequired } from "../../../../../utils/SimplifiedYup";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import CustomCheckbox from "../../../../newUI/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { createChecklist } from "../../../../../app/feature/project/projectChecklist/thunks";
import { getProjectChecklist } from "../../../../../app/feature/project/projectChecklist";

type TAddCheckListModal = {
  name: string;
  parentId: number;
  projectId: number;
  closeModal: () => void;
};

type TFieldValues = {
  name: string;
  executor: boolean;
  hidden: boolean;
  critical: boolean;
};

const schema = Yup.object().shape({
  name: stringRequired("Наименование"),
});

const AddCheckListModal = ({
  name,
  parentId,
  projectId,
  closeModal,
}: TAddCheckListModal) => {
  const dispatch = useAppDispatch();

  const {
    data: { type },
    pending,
  } = useAppSelector(getProjectChecklist);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TFieldValues>({
    defaultValues: {
      name: "",
      executor: false,
      hidden: false,
      critical: false,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: TFieldValues) => {
    await dispatch(createChecklist({ parentId, projectId, data, type }));
    closeModal();
  };

  return (
    <form
      onClick={(e) => {
        e.stopPropagation();
      }}
      onSubmit={handleSubmit(onSubmit)}
      className={style.form}
    >
      <h2>Создать: {name}</h2>
      <TextField
        {...register("name")}
        {...textFieldSX}
        label="Наименование"
        rows={2}
        className={style.textField}
        multiline
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      <div className={style.form__controls}>
        <p className={style.form__controls_choice}>
          Выберите один или несколько параметров
        </p>
        <Controller
          control={control}
          name="executor"
          render={({ field: { value } }) => (
            <label className={style.form__checkBox}>
              <CustomCheckbox
                editing
                onChange={() => {
                  setValue("executor", !value);
                }}
                checked={value}
              />
              <p>
                <span>Заказчик&nbsp;</span>
                <span>
                  - за предоставление исходных данных отвечает Заказчик
                </span>
              </p>
            </label>
          )}
        />
        <Controller
          control={control}
          name="critical"
          render={({ field: { value } }) => (
            <label className={style.form__checkBox}>
              <CustomCheckbox
                editing
                onChange={() => {
                  setValue("critical", !value);
                }}
                checked={value}
              />
              <p>
                <span>Критичные&nbsp;</span>
                <span>
                  - важные исходные данные, которые влияют на длительность
                  выполнения работ
                </span>
              </p>
            </label>
          )}
        />
        <Controller
          control={control}
          name="hidden"
          render={({ field: { value } }) => (
            <label className={style.form__checkBox}>
              <CustomCheckbox
                editing
                onChange={() => {
                  setValue("hidden", !value);
                }}
                checked={value}
              />
              <p>
                <span>Скрытые&nbsp;</span>
                <span>
                  - исходные данные, которые доступны только участникам проекта
                </span>
              </p>
            </label>
          )}
        />
      </div>
      <div className={style.controls}>
        <CustomButton onClick={closeModal} width={120}>
          Отменить
        </CustomButton>
        <CustomButton
          disabled={pending.create}
          type="submit"
          width={120}
          background={palette.green}
        >
          Создать
        </CustomButton>
      </div>
    </form>
  );
};

export default AddCheckListModal;
