import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой изменения роли человека в организации
 */

export const showEventUserJoin = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventUserJoin: {
      isShow: true,
      data: {
        ...state.eventUserJoin.data,
      },
    },
  };
};

export const closeEventUserJoin = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventUserJoin: {
    isShow: false,
    data: {
      ...state.eventUserJoin.data,
    },
  },
});

export const setEventUserJoin = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventUserJoin: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorUserJoin = (state: StateType) =>
  state.eventEmitter.eventUserJoin.isShow;

export const selectorUserJoinData = (state: StateType) =>
  state.eventEmitter.eventUserJoin.data;
