import React from "react";
import styles from "../style.module.scss";

interface HeaderProps {
  count: number;
}
export const Header = ({ count }: HeaderProps) => {
  return (
    <div className={styles.header}>
      <p>Заявки на ГИПа</p>
      <span>{`(всего ${count})`}</span>
    </div>
  );
};
