import React, { memo, useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import cls from "./GipCard.module.scss";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import Modal from "../../../../../../newUI/Modal/Modal";
import { GipHelperModal } from "./Modal/GipHelperModal";
import {
  AddGipHelper,
  DeleteButtonIcon,
  GipHelpersIcon,
  QuestionCircle,
} from "../../../../../../newUI/SVG";
import { getProjectViewData } from "../../../../../../../app/feature/ProjectView/projectView";
import { DelegateModal } from "./Modal/DelegateModal";
import {
  fetchGipHelperApplicationDelete,
  fetchProjectViewSearchGip,
} from "../../../../../../../app/feature/ProjectView/thunks";
import { CircularIndeterminate } from "../../../../../../UI/components/Project/Skeleton";
import { getUserId } from "../../../../../../../app/services/auth/auth";
import { GipHelperAcceptModal } from "./Modal/GipHelperAcceptModal";

const sx = { color: "white" };

export interface IGipCardProps {
  data: {
    avatar?: string;
    rating?: number;
    reviews?: number;
    link?: string;
    name?: string;
    firm?: string;
    firmId?: number;
    id?: number;
    jobDuration?: number;
    gipHelpers?: {
      name: string;
      id: string;
      fullName: string;
      delegate: boolean;
      accessId: number;
    }[];
    gipHelpersApplication: {
      can: {
        delete: boolean;
        accept: boolean;
      };
      fio: string;
      userId: number;
      taskId: number;
    }[];
    gipInvite?: boolean;
    gipRemove?: boolean;
    editByGipHelper: boolean;
    chatId?: number;
  }[];
  projectName: string;
}
export const GipCard = memo(({ data, projectName }: IGipCardProps) => {
  const params = useParams<{ id: string }>();
  const { isLoadGipHelperInvite } = useSelector(getProjectViewData);
  const dispatch = useDispatch();
  const {
    gipHelpersError,
    isLoadGipHelperApplication,
    gipHelperDelete,
    gipHelperInvite,
    isLoadGipHelperAppInvite,
  } = useSelector(getProjectViewData);
  const [gipHelperModal, setGipHelperModal] = useState(false);
  const [gipDelegateModal, setGipDelegateModal] = useState(false);
  const [gipAcceptModal, setGipAcceptModal] = useState(false);
  const modalClose = useCallback(() => setGipHelperModal(false), []);
  const { enqueueSnackbar } = useSnackbar();
  const gip = data[0];
  const canIsGip = getUserId() === gip.id;
  const canDelegate = canIsGip && !!gip?.gipHelpers?.length;

  const handleClick = () => {
    if (!gipHelpersError) {
      setGipHelperModal(true);
    }
  };

  const deleteGipHelperRequest = async (id: string | number) => {
    const result = window.confirm("Вы уверены, что хотите отменить заявку?");
    if (result && params.id) {
      const deleteGip = {
        projectId: params.id,
        userId: id,
        active: false,
      };
      await dispatch(fetchGipHelperApplicationDelete({ deleteGip }));
      if (params.id) {
        await dispatch(fetchProjectViewSearchGip(params.id));
      }
    }
  };

  useEffect(() => {
    if (gipHelperDelete) {
      enqueueSnackbar("Удаление помощника ГИПа прошло успешно", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (gipHelperInvite) {
      enqueueSnackbar("Помощник ГИПа успешно добавлен", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [gipHelperDelete, gipHelperInvite]);

  return (
    <div className={cls.gip}>
      <h1>ГИП</h1>
      {gip?.link ? (
        <TextLink url={gip.link}>
          <p className={cls.gip_name}>{gip?.name}</p>
        </TextLink>
      ) : (
        <p className={cls.gip_name}>{gip?.name}</p>
      )}
      <p className={cls.gip_type}>{gip.firm}</p>
      <div className={cls.gip_helpers}>
        <div className={cls.gip_helpers_title}>
          <h4>Помощник(и)</h4>
          {!gip.firmId && canIsGip && (
            <div
              className={cls.gip_helpers_question}
              title={
                ' Вы можете пригласить помощника со страницы пользователя по кнопке "Пригласить помощником ГИП" '
              }
            >
              <QuestionCircle />
            </div>
          )}
          {canDelegate && (
            <>
              <Modal active={gipDelegateModal} setActive={setGipDelegateModal}>
                <DelegateModal gip={gip} />
              </Modal>
              <IconButton
                title="Управление помощниками"
                className={cls.gip_helpers_icon}
                sx={sx}
                onClick={() => setGipDelegateModal(true)}
              >
                <GipHelpersIcon width="18" height="18" />
              </IconButton>
            </>
          )}
        </div>
        {gip.gipHelpers?.length === 0 && <p>Не назначены</p>}
        {gip?.gipHelpers?.slice(0, 3).map((el) =>
          el.delegate ? (
            <React.Fragment key={el.id}>
              <p
                className={classNames(el.delegate && cls.gip_helpers_delegate)}
              >
                <AddGipHelper width="12" height="12" />
                {el.name}
              </p>
            </React.Fragment>
          ) : (
            <p key={el.id}>{el.name}</p>
          )
        )}
        {!!gip?.gipHelpersApplication.length && (
          <>
            <h4>Заявки</h4>
            {gip?.gipHelpersApplication.map((el) => (
              <div className={cls.gip_helpers_application} key={el.fio}>
                <p>{el.fio}</p>
                {el.can.delete && (
                  <IconButton
                    onClick={() => deleteGipHelperRequest(el.userId)}
                    className={cls.gip_helpers_icon}
                    title="Отменить заявку"
                    sx={sx}
                  >
                    <DeleteButtonIcon width="20" height="20" />
                  </IconButton>
                )}
                {el.can.accept && (
                  <>
                    <Modal
                      active={gipAcceptModal}
                      setActive={setGipAcceptModal}
                      isLoad={isLoadGipHelperAppInvite}
                    >
                      <GipHelperAcceptModal
                        id={el.taskId}
                        projectName={projectName}
                      />
                    </Modal>
                    <IconButton
                      className={cls.gip_helpers_icon}
                      title="Рассмотреть"
                      sx={sx}
                      onClick={() => setGipAcceptModal(true)}
                    >
                      <GipHelpersIcon width="20" height="20" />
                    </IconButton>
                  </>
                )}
              </div>
            ))}
          </>
        )}
        {isLoadGipHelperApplication && (
          <div className={cls.gip_helpers_load}>
            <CircularIndeterminate />
          </div>
        )}
      </div>
      {gip.gipInvite && gip.firmId && (
        <>
          <Modal
            active={gipHelperModal}
            setActive={setGipHelperModal}
            isLoad={isLoadGipHelperInvite}
          >
            <GipHelperModal modalClose={modalClose} />
          </Modal>
          <IconButton
            title="Назначить"
            className={cls.gip_helpers_icon}
            onClick={handleClick}
            sx={sx}
          >
            <AddGipHelper />
          </IconButton>
        </>
      )}
    </div>
  );
});
