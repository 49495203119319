let modalRootElement: HTMLDivElement | null = null;
const modalRootId = "modalRoot";

function createModalRoot() {
  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", modalRootId);
  document.body.appendChild(modalRoot);
  return modalRoot;
}

export function getModalRootElement() {
  if (!modalRootElement) {
    modalRootElement = document.querySelector(`#${modalRootId}`);
  }

  if (!modalRootElement) {
    modalRootElement = createModalRoot();
  }

  return modalRootElement;
}
