import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { KanbanModel, KanbanType, KanbanApi } from "src/FSD/entities/kanban";
import { getFilterValuesFromLocalStorage } from "src/FSD/pages/v2/kanban/lib/helpers/getFilterValuesFromLocalStorage";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import { getDashboardDestinations } from "../../../../../../../../app/feature/dashboard/destinations";
import { createFreeTaskSchema } from "../schemas";
import { getUserId } from "../../../../../../../../app/services/auth/auth";

const defaultValues = {
  content: "",
  project: null,
  partGroup: null,
  executor: null,
  dateDeadline: undefined,
  importance: false,
};

export const useCreateFreeTask = () => {
  const dispatch = useAppDispatch();

  const isViewPending = useAppSelector(KanbanModel.getIsViewPending);
  const isTaskCreationPending = useAppSelector(
    KanbanModel.getIsTaskCreationPending
  );

  // массивы с данными для селектов (использовались изначально в фильтре)
  const selectOptions = useAppSelector(KanbanModel.getSelectOptions);
  const newTaskPending = useAppSelector(KanbanModel.getFilterPending);

  const { userType: userTypeId } = useAppSelector(getDashboardDestinations);

  const isSubmitting = isTaskCreationPending || isViewPending;

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<KanbanType.FreeTaskFields>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(createFreeTaskSchema),
  });

  const projectValue = watch("project");
  const partGroupValue = watch("partGroup");

  const onSubmit = async (data: KanbanType.FreeTaskFields) => {
    await dispatch(KanbanApi.createFreeTask(data));
    dispatch(
      KanbanModel.setCreateFreeTask({
        isOpen: false,
      })
    );
  };

  // Выгрузка списка проектов
  useEffect(() => {
    dispatch(KanbanApi.fetchProjectsByUserTypeId(userTypeId));
  }, [userTypeId]);

  useEffect(() => {
    const partGroups = [];

    if (partGroupValue?.id) {
      partGroups.push({
        id: partGroupValue.id,
        title: "",
      });
    }

    dispatch(
      KanbanApi.fetchUsersListByUserTypeId({
        userTypeId,
        projectId: projectValue?.id as number,
        partGroups,
      })
    );
  }, [userTypeId, projectValue, partGroupValue]);

  useEffect(() => {
    const userId = getUserId();
    const { project } = getFilterValuesFromLocalStorage(userId, userTypeId);

    if (project) {
      setValue("project", project);
    }
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    selectOptions,
    newTaskPending,
    isSubmitting,
    watch,
  } as const;
};
