import {
  FC,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useMemo,
} from "react";
import { CircularProgress, IconButton } from "@mui/material";
import style from "./SourceDataView.module.scss";
import {
  AddFile,
  DeleteButtonIcon,
  DownloadArrow,
  PencilSquareSVG,
} from "../SVG";
import { iconBtnSx } from "../../../styles/restyle";
import { SourceDataIconType } from "./SourceDataIconType";
import { ModalWrapper } from "../../pages/User/Edit/PersonalDataChange/PersonalDataChange";
import AddCommentModal from "./AddCommentModal/AddCommentModal";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  deleteSourceComment,
  fetchSourceComments,
} from "../../../app/feature/ProjectView/SectionsReducer/thunks";
import {
  getProjectSections,
  clearSourceComments,
  TSourceComment,
} from "../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { config } from "../../../app.cofig";

const statusesList: Record<string, string> = {
  2: "inprocess",
  16: "undefined",
  3: "success",
};

type TModalOpen = number | null;

type SourceCommentItemProps = {
  setCommentId: Dispatch<SetStateAction<TModalOpen>>;
  handleDeleteComment: (id: number) => void;
  id: number;
  date_limit: string;
  status: number;
  comment: string;
  can: Record<string, boolean>;
};

const SourceCommentItem: FC<SourceCommentItemProps> = ({
  setCommentId,
  handleDeleteComment,
  id,
  date_limit,
  status,
  comment,
  can: { update, download },
}) => {
  const handleDownloadAllFiles = () => {
    window.open(
      `${config.localDomain}/comment-source-data/download-files?id=${id}&all=true`,
      "_blank"
    );
  };

  return (
    <>
      <SourceDataIconType status={statusesList[status]} critical={false} />
      <p>{comment}</p>
      <p>{date_limit}</p>
      <div className={style.commentControls}>
        {update ? (
          <IconButton
            className={style.edit}
            sx={iconBtnSx}
            onClick={() => setCommentId(id)}
          >
            <PencilSquareSVG width="24px" height="24px" />
          </IconButton>
        ) : (
          <div className={style.edit} />
        )}
        {download ? (
          <IconButton
            className={style.edit}
            sx={iconBtnSx}
            onClick={handleDownloadAllFiles}
          >
            <DownloadArrow />
          </IconButton>
        ) : (
          <div className={style.edit} />
        )}
        {update ? (
          <IconButton
            className={style.edit}
            sx={iconBtnSx}
            onClick={() => handleDeleteComment(id)}
          >
            <DeleteButtonIcon width="24px" height="24px" />
          </IconButton>
        ) : (
          <div className={style.edit} />
        )}
      </div>
    </>
  );
};

type CommentProps = {
  sourceDataId: number;
  name: string;
  can: Record<string, boolean>;
};

const Comment: FC<CommentProps> = ({ sourceDataId, name, can }) => {
  const dispatch = useAppDispatch();
  const [commentId, setCommentId] = useState<TModalOpen>(null);

  const {
    sourceComments,
    pending: { getSourceComments, deletingSourceComment },
  } = useAppSelector(getProjectSections);

  const currentComment = useMemo(() => {
    if (!sourceComments[sourceDataId]) {
      return null;
    }

    const foundComment = sourceComments[sourceDataId].find(
      (comment) => comment.id === commentId
    );

    return foundComment ?? null;
  }, [commentId]);

  const handleDeleteComment = async (id: number) => {
    if (!window.confirm("Вы уверены, что хотите удалить данный комментарий?")) {
      return;
    }

    await dispatch(deleteSourceComment(id));
    await dispatch(fetchSourceComments(sourceDataId));
  };

  useEffect(() => {
    if (!sourceDataId) {
      return;
    }

    dispatch(fetchSourceComments(sourceDataId));

    return () => {
      dispatch(clearSourceComments(sourceDataId));
    };
  }, [deletingSourceComment]);

  const SourceCommentsList = () => (
    <>
      {getSourceComments.includes(sourceDataId) ? (
        <div className={style.pending}>
          <CircularProgress />
        </div>
      ) : sourceComments[sourceDataId]?.length ? (
        sourceComments[sourceDataId].map((comment: TSourceComment) => (
          <SourceCommentItem
            key={comment.id}
            setCommentId={setCommentId}
            handleDeleteComment={handleDeleteComment}
            {...comment}
          />
        ))
      ) : (
        <p className={style.noCommentsFound}>Комментарии отсутствуют</p>
      )}
    </>
  );

  return (
    <div className={style.comment}>
      {can?.addComment && (
        <div className={style.addComment}>
          <p>Добавить комментарий</p>
          <IconButton onClick={() => setCommentId(0)} sx={iconBtnSx}>
            <AddFile />
          </IconButton>
        </div>
      )}
      <div className={style.comment__grid}>
        <p>Статус</p>
        <p>Комментарий</p>
        <p>Контр. срок</p>
        <p />
        <SourceCommentsList />
      </div>
      <ModalWrapper
        show={commentId !== null}
        handleCloseModal={() => setCommentId(null)}
      >
        <AddCommentModal
          currentComment={currentComment}
          setCommentId={setCommentId}
          isEditing={Boolean(commentId)}
          sourceDataId={sourceDataId}
          name={name}
        />
      </ModalWrapper>
    </div>
  );
};

export default Comment;
