import React, { memo } from "react";
import { ClickAwayListener, Popper } from "@mui/material";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { NotApproveIcon } from "../SVG";
import { DateCreate } from "../../features/SpecialForm/DateCreate/DateCreate";
import style from "./SourceDataView.module.scss";
import { CircularIndeterminate } from "../../UI/components/Project/Skeleton";

interface DateEndPopperProps {
  canEditDate: boolean;
  date_create: string;
  date_deadline: string;
  isMobile: boolean;
  onChangeDate: (data: any) => void;
  isLoadDateUpdate: boolean;
  classname?: string;
}
export const DateEndPopper = memo(
  ({
    canEditDate,
    date_create,
    date_deadline,
    onChangeDate,
    isLoadDateUpdate,
    isMobile,
    classname,
  }: DateEndPopperProps) => {
    const { control } = useForm();
    const start = moment(date_create, "DD.MM.YYYY").toDate();
    const [anchorEnd, setAnchorEnd] = React.useState<null | HTMLElement>(null);
    const handleClickEnd = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEnd(anchorEnd ? null : event.currentTarget);
    };
    const openEnd = Boolean(anchorEnd);
    const idEnd = openEnd ? "simple-popper" : undefined;

    const handleClickAwayEnd = () => {
      setAnchorEnd(null);
    };

    return (
      <div className={classNames(style.text, classname)}>
        <ClickAwayListener onClickAway={handleClickAwayEnd}>
          <div>
            <span
              className={classNames(
                style.text_edit,
                !canEditDate && style.text_noEdit
              )}
              onClick={handleClickEnd}
            >
              {date_deadline}
            </span>
            {openEnd && canEditDate ? (
              // @ts-ignore
              <Popper
                id={idEnd}
                open={openEnd}
                anchorEl={anchorEnd}
                placement={isMobile ? "bottom" : "right-start"}
              >
                <form className={style.createData}>
                  <div className={style.createData_flex}>
                    <h3>Контр. срок</h3>
                    <div onClick={() => setAnchorEnd(null)}>
                      <NotApproveIcon width="16" heigth="16" />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="endDate"
                    render={() => (
                      <DateCreate
                        changeDateHandler={(date) => {
                          onChangeDate({
                            startDate: start,
                            endDate: date,
                          });
                        }}
                        startDateProp={moment(
                          date_deadline,
                          "DD.MM.YYYY"
                        ).toDate()}
                        maxDate={start}
                      />
                    )}
                  />
                  <div className={style.createData_btn}>
                    {isLoadDateUpdate && (
                      <div className={style.createData_btn_load}>
                        <CircularIndeterminate />
                      </div>
                    )}
                  </div>
                </form>
              </Popper>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
);
