import {
  useProjectProcessPublishInfoQuery,
  useProjectProcessPublishMutation,
  useProjectProcessViewQuery,
} from "src/FSD/entities/projectProcess/api";
import {
  useProcessWorkPublishInfoQuery,
  useProcessWorkPublishMutation,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";

export const useQueries = (isProcessWork: boolean, id: number) => {
  const usePublishInfoQuery = isProcessWork
    ? useProcessWorkPublishInfoQuery
    : useProjectProcessPublishInfoQuery;

  const useViewQuery = isProcessWork
    ? useProcessWorkViewQuery
    : useProjectProcessViewQuery;

  const usePublishMutation = isProcessWork
    ? useProcessWorkPublishMutation
    : useProjectProcessPublishMutation;

  const { data: publishInfo, isFetching: isFetchingPublishInfo } =
    usePublishInfoQuery({
      id,
    });

  const { data: processData, isFetching } = useViewQuery({
    id,
  });

  const [publish] = usePublishMutation();

  return {
    publishInfo,
    processData,
    isFetching,
    isFetchingPublishInfo,
    publish,
  };
};
