import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import ExecutorCard from "./ExecutorCard";
import styles from "./style.module.scss";
import { getExecutors } from "../../../../../app/feature/ProjectView/GroupApplications/thunks";
import {
  TExecutor,
  getGroupApplications,
  setOpen,
  setAllOpen,
} from "../../../../../app/feature/ProjectView/GroupApplications";
import Comparison from "./Comparison";
import { SkeletonDocuments } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { Header } from "./Header/Header";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface IGroupApplicationsProps {
  setDefaultTab: () => void;
}

export const GroupApplications = ({
  setDefaultTab,
}: IGroupApplicationsProps) => {
  const dispatch = useAppDispatch();
  const isTablet = useIsMobile(1600);

  const { id } = useParams<{ id: string }>();
  const projectId = Number(id);

  const { data, pending } = useAppSelector(getGroupApplications);
  const { executors, groupNames, openProcesses, comparisonMode, isAllToggle } =
    data;

  const { project } = useAppSelector(getProjectViewData);

  if (project && !project?.tabs.applications.show) {
    setDefaultTab();
  }

  useEffect(() => {
    if (projectId) {
      dispatch(getExecutors(projectId));
      dispatch(fetchProjectView(projectId));
    }
  }, [dispatch, projectId]);

  const handleToggle = (id: string) => {
    dispatch(setOpen(id));
  };

  const handleAllToggle = () => {
    dispatch(setAllOpen());
  };

  if (!projectId) {
    return null;
  }

  if (pending.executors) {
    return <SkeletonDocuments />;
  }

  return (
    <div className={cn(styles.wrapper, styles.marginBottom)}>
      <Header />
      <ScrollWrap height={isTablet ? "auto" : "865px"}>
        {comparisonMode && <Comparison id={projectId} />}
        {!comparisonMode &&
          Object.entries(executors).map(
            ([groupId, executorsList]: any, index) => {
              const isOpen = openProcesses.includes(groupId);
              const groupName = `${groupNames[groupId]} (${executorsList.length})`;

              return (
                <React.Fragment key={groupId}>
                  <Accordion
                    toogle={isOpen}
                    onToggle={() => handleToggle(groupId)}
                    text={groupName}
                    numNode={"1"}
                    noMargin={index === 0}
                  />
                  {isOpen && (
                    <div className={styles.flexCol}>
                      {executorsList.map((executor: TExecutor) => (
                        <ExecutorCard
                          key={executor.id}
                          groupId={groupId}
                          executor={executor}
                        />
                      ))}
                    </div>
                  )}
                </React.Fragment>
              );
            }
          )}
        <AllToggleWidget
          isAllToggle={isAllToggle}
          setIsAllToggle={handleAllToggle}
        />
      </ScrollWrap>
    </div>
  );
};
