import { Dispatch, SetStateAction } from "react";
import { MenuItem } from "@mui/material";
import { TComposition } from "src/app/feature/ProjectView/Contents/getComposition";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { config } from "src/app.cofig";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import style from "./Composition.module.scss";
import { TValuesForEditing } from "./Composition";

const LINKS_BY_CLASS: Record<string, string> = {
  ProjectProcess: "project-process",
  ProcessWork: "process-work",
};

type CompositionsListProps = {
  setWorkId: Dispatch<SetStateAction<number | null>>;
  setEditing: Dispatch<SetStateAction<TValuesForEditing>>;
  data: Record<string, TComposition[]>;
  workId: number | null;
  tab: string | number;
  handleDeleteBook: (id: number) => void;
};

const CompositionList = ({
  data,
  tab,
  setWorkId,
  setEditing,
  handleDeleteBook,
  workId,
}: CompositionsListProps) => {
  return (
    <>
      <div className={style.composition}>
        <h2>№ тома</h2>
        <h2>Обозначение</h2>
        <h2>Наименование</h2>
        <h2>Примечание</h2>
      </div>
      <div className={style.compositions}>
        {data[tab].map((composition: TComposition) => {
          const {
            num,
            header,
            type,
            typeNum,
            name,
            comment,
            objectId,
            objectClass,
            can,
          } = composition;

          const showButton = can.create || can.update || can.delete;
          const url = `${config.localDomain}/${LINKS_BY_CLASS[objectClass]}/${objectId}`;

          const formedName = `${type} ${typeNum}. ${name}`;

          return (
            <div className={style.composition}>
              <p>{num}</p>
              <p className={style.name}>{header}</p>
              {objectId ? (
                <TextLink
                  url={url}
                  variant={style.url}
                  redirect
                  target="_blank"
                >
                  {formedName}
                </TextLink>
              ) : (
                <p>{formedName}</p>
              )}
              <p>{comment || "-"}</p>
              {!workId && showButton && (
                <ThreeDotButton>
                  {can.create && (
                    <MenuItem disableRipple onClick={() => setWorkId(objectId)}>
                      Создать книгу
                    </MenuItem>
                  )}
                  {can.update && (
                    <MenuItem
                      disableRipple
                      onClick={() => {
                        setWorkId(objectId);
                        setEditing({ name, num: typeNum });
                      }}
                    >
                      Редактировать
                    </MenuItem>
                  )}
                  {can.delete && (
                    <MenuItem
                      disableRipple
                      onClick={() => handleDeleteBook(objectId as number)}
                    >
                      Удалить
                    </MenuItem>
                  )}
                </ThreeDotButton>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CompositionList;
