import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { NewChatIcon } from "src/components/newUI/SVG";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import {
  ThemeDotButton,
  ThreeDotButton,
} from "src/components/newUI/ThreeDotButton/ThreeDotButton";
import styles from "./Executor.module.scss";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";
import { newDresignButtonSx } from "../../../../../../../styles/restyle";
import { NewAvatar } from "../../../../../../../components/newUI/NewAvatar/NewAvatar";
import { config } from "../../../../../../../app.cofig";
import { useExecutorHave } from "../../lib/hooks/useExecutorHave";
import { NewModal } from "../../../../../../../components/newUI/NewModal/NewModal";
import { RemoveExecutor } from "../../../../projectProcess/std/Info/modals/RemoveExecutor/RemoveExecutor";
import { CompleteRemove } from "../../../../projectProcess/std/Info/modals/CompleteRemove/CompleteRemove";
import { EditExecutor } from "../../../../projectProcess/std/Info/modals/EditExecutor/EditExecutor";
import { FeedbackExecutor } from "../../../../projectProcess/std/Info/modals/FeedbackExecutor/FeedbackExecutor";
import ProcessWorkModals from "../../../modals";
import { ProcessWorkModel } from "../../../../../../entities/processWork";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const ExecutorHave = ({ processWork }: Props) => {
  const dispatch = useAppDispatch();

  const {
    executor,
    fullName,
    chatId,
    showOptions,
    handleCloseThreeDot,
    threeDotButtonRef,
    jobDuration,
  } = useExecutorHave(processWork);

  /*
  const dispatch = useAppDispatch();

  const {
    requestRemoveExecutor,
    updateExecutor,
    feedBackExecutor,
    considerConflict,
    requestExecution,
    updateProcessWork,
  } = useAppSelector(ProcessWorkModel.modals.getModalsData);
  */

  const { requestRemoveExecutor } = useAppSelector(
    ProcessWorkModel.modals.getModalsData
  );

  const handleShowRemoveExecutor = () => {
    handleCloseThreeDot();
    dispatch(ProcessWorkModel.modals.setRequestRemoveExecutorActive(true));
  };

  const handleHideRemoveExecutor = () => {
    dispatch(ProcessWorkModel.modals.setRequestRemoveExecutorActive(false));
  };

  return (
    <>
      <Modal
        isActive={requestRemoveExecutor}
        handleClose={handleHideRemoveExecutor}
      >
        <ProcessWorkModals.RemoveExecutorForms workId={processWork.id} />
      </Modal>
      <Card
        header={<Card.Header>Исполнитель</Card.Header>}
        className={styles.executorCard}
        controls={
          <Card.Controls location="top" width={30}>
            {executor && (
              <div className={styles.controls}>
                {processWork.can!.chat && (
                  <Link to={`/chat/${chatId}`}>
                    {/* Временно, потом заменить */}
                    <IconButton title="Чат" sx={newDresignButtonSx}>
                      <NewChatIcon />
                    </IconButton>
                  </Link>
                )}
                {showOptions && (
                  <ThreeDotButton
                    newDesign
                    ref={threeDotButtonRef}
                    themeBtn={ThemeDotButton.BLUE}
                  >
                    {processWork.can!.requestRemoveExecutor && (
                      <MenuItem
                        onClick={handleShowRemoveExecutor}
                        disableRipple
                      >
                        Снять исполнителя
                      </MenuItem>
                    )}
                    {processWork.can!.updateExecutor && (
                      <MenuItem onClick={() => {}} disableRipple>
                        Сменить исполнителя
                      </MenuItem>
                    )}
                    {processWork.activeAccess.taskRemove?.can?.complete && (
                      <MenuItem onClick={() => {}} disableRipple>
                        Рассмотреть конфликт
                      </MenuItem>
                    )}
                    {processWork.can!.feedback && (
                      <MenuItem onClick={() => {}} disableRipple>
                        Оценить
                      </MenuItem>
                    )}
                  </ThreeDotButton>
                )}
              </div>
            )}
          </Card.Controls>
        }
      >
        <>
          <NewModal
            title={"Запросить снятие исполнителя"}
            active={false}
            setActive={() => {}}
          >
            <RemoveExecutor closeModal={() => {}} />
          </NewModal>
          <NewModal
            title={"Рассмотрение заявки на снятие исполнителя"}
            active={false}
            setActive={() => {}}
          >
            <CompleteRemove closeModal={() => {}} />
          </NewModal>
          <NewModal active={false} setActive={() => {}}>
            <FeedbackExecutor modalClose={() => {}} />
          </NewModal>
          <NewModal
            title={`Изменить доступ к проекту`}
            active={false}
            setActive={() => {}}
          >
            <EditExecutor onClose={() => {}} />
          </NewModal>
          <div className={styles.userInfo}>
            <TextLink
              className={styles.userInfo__name}
              url={`${config.localDomain}/user/${executor.id}`}
              target="_blank"
              redirect
            >
              {fullName}
            </TextLink>
            <Paragraph>{processWork.activeAccess.userType?.listName}</Paragraph>
          </div>
          <NewAvatar
            photoUrl={`${config.localDomain}${executor.photo}`}
            rating={executor.rating.executor}
            jobDuration={jobDuration}
            borderColor={"#F5A623"}
            classname={styles.avatar}
          />
          <hr className={styles.yellowLine} />
        </>
      </Card>
    </>
  );
};

export default ExecutorHave;

/*

  const threeDotButtonRef = useRef<RefType>(null);

  const executor = useAppSelector(getExecutorProcessInfo);
  const chatId = useAppSelector(getProjectProcessInfoProjectChatId);
  const canTask = useAppSelector(getCanTaskProcessInfo);
  const isLoadUserUpdate = useAppSelector(getUserUpdateIsLoad);
  const isLoadExecutorFeedback = useAppSelector(getFeedbackExecutorIsLoad);
  const removeExecutorIsLoad = useAppSelector(getRemoveExecutorIsLoad);
  const removeRequestSuccess = useAppSelector(
    getApplicationsRemoveRequestSuccess
  );
  const isLoadRemoveExecutorComplete = useAppSelector(
    getRemoveExecutorCompleteIsLoad
  );


  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <h2>Исполнитель</h2>
        {executor && (
          <>
            {infoData.can.requestRemoveExecutor && (
              <NewModal
                title={"Запросить снятие исполнителя"}
                active={modalExecutor}
                setActive={setModalExecutor}
                isLoad={removeExecutorIsLoad}
              >
                <RemoveExecutor closeModal={modalExecutorClose} />
              </NewModal>
            )}
            <NewModal
              title={"Рассмотрение заявки на снятие исполнителя"}
              active={modalCompleteRemove}
              setActive={setModalCompleteRemove}
            >
              <CompleteRemove closeModal={modalCompleteRemoveClose} />
            </NewModal>
            {canTask?.feedbackToExecutor && (
              <NewModal
                active={modalFeedback}
                setActive={setModalFeedback}
                isLoad={isLoadExecutorFeedback}
              >
                <FeedbackExecutor modalClose={modalFeedbackClose} />
              </NewModal>
            )}
            {infoData.can.updateExecutor && (
              <NewModal
                title={`Изменить доступ к проекту`}
                active={modalEditExecutor}
                setActive={setModalEditExecutor}
                isLoad={isLoadUserUpdate}
              >
                <EditExecutor onClose={closeModalEditExecutor} />
              </NewModal>
            )}
          <div className={styles.executor_header_btn}>
          {infoData.can.chat && (
            <Link to={`/chat/${chatId}`}>
              <IconButton title="Чат" sx={sx}>
                <NewChatIcon />
              </IconButton>
            </Link>
          )}
          <div className={styles.buttons}>
            {canThreeDotBtn && (
              <ThreeDotButton
                newDesign
                ref={threeDotButtonRef}
                themeBtn={ThemeDotButton.BLUE}
              >
                {infoData.can.requestRemoveExecutor && (
                  <MenuItem onClick={removeExecutor} disableRipple>
                    Снять исполнителя
                  </MenuItem>
                )}
                {infoData.can.updateExecutor && (
                  <MenuItem onClick={editExecutor} disableRipple>
                    Сменить исполнителя
                  </MenuItem>
                )}
                {infoData?.activeAccess?.taskRemove?.can?.complete && (
                  <MenuItem onClick={completeRemove} disableRipple>
                    Рассмотреть конфликт
                  </MenuItem>
                )}
                {canTask?.feedbackToExecutor && (
                  <MenuItem onClick={feedBackExecutor} disableRipple>
                    Оценить
                  </MenuItem>
                )}
              </ThreeDotButton>
            )}
          </div>
        </div>
      </>
    )}
  </div>
  {executor && (
    <div className={styles.executor_info}>
      <div className={styles.executor_text}>
        <TextLink url={executor.link}>
          <p className={styles.executor_name}>{executor.name}</p>
        </TextLink>
        <span>{executor.firm}</span>
      </div>
      <div className={styles.executor_avatar_container}>
        <hr className={styles.yellowLine} />
        <NewAvatar
          photoUrl={executor.avatar}
          rating={executor.rating}
          jobDuration={executor.jobDuration}
          borderColor={"#F5A623"}
          classname={styles.executor_avatar}
        />
      </div>
    </div>
  )}
</div>
);

*/
