import { Routes, Route, Navigate } from "react-router-dom";
import { ChatPage } from "../components/pages/Chat/ChatPage";
import { MainPageTemplate } from "../components/UI/templates/MainPageTemplate/MainPageTemplate";
import { BlogRoutes } from "./BlogRoutes";
import { PostRoutes } from "./PostRoutes";
import { ProjectRoutes } from "./ProjectRoutes";
import { SiteRoutes } from "./SiteRoutes";
import { UserRoutes } from "./UserRoutes";
import { FirmRoutes } from "./FirmRoutes";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import { NoticesPage } from "../components/pages/Notices/NoticesPage";
import { userIsAuth } from "../app/services/auth/auth";
import { ProjectProcessRoutes } from "./ProjectProcessRoutes";
import { ProcessWorkRoutes } from "./ProcessWorkRoutes";

export const AppRoutes = () => {
  const isAuth = userIsAuth();

  return (
    <>
      <ProjectProcessRoutes />
      <ProcessWorkRoutes />
      <UserRoutes />
      <ProjectRoutes />
      <SiteRoutes />
      <BlogRoutes />
      <PostRoutes />
      <FirmRoutes />
      <ChatPage isAuth={isAuth} />
      <Routes>
        <Route
          path="/notice/index"
          element={
            isAuth ? (
              <IndexPageTemplate>
                <NoticesPage />
              </IndexPageTemplate>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/" element={<MainPageTemplate />} />
      </Routes>
    </>
  );
};
