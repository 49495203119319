import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjectProcessExpertise,
  fetchProjectProcessExpertiseDuplicate,
} from "../services/fetchProjectProcessExpertise";
import { ExpertiseType } from "../types/projectProcessExpertiseTypes";
import { fetchProjectProcessExpertiseComplete } from "../services/fetchProjectProcessExpertiseComplete";

type InitialState = {
  isLoadExpertise: boolean;
  isLoadExpertiseComplete: boolean;
  expertise: ExpertiseType[] | undefined;
  openExpertise: any;
  openRemarks: any;
};

const initialState: InitialState = {
  isLoadExpertise: false,
  isLoadExpertiseComplete: false,
  expertise: undefined,
  openExpertise: {},
  openRemarks: {},
};

const projectProcessExpertise = createSlice({
  name: "projectProcessExpertise",
  initialState,
  reducers: {
    setOpenExpertise: (state, action) => {
      const index = action.payload;
      if (state.openExpertise[index] !== undefined) {
        state.openExpertise[index] = undefined;
      } else {
        state.openExpertise[index] = index;
      }
    },
    setOpenRemarks: (state, action) => {
      const index = action.payload;
      if (state.openRemarks[index] !== undefined) {
        state.openRemarks[index] = undefined;
      } else {
        state.openRemarks[index] = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessExpertise.pending, (state) => {
      state.isLoadExpertise = true;
    });
    builder.addCase(fetchProjectProcessExpertise.fulfilled, (state, action) => {
      state.isLoadExpertise = false;
      state.expertise = action.payload;
    });
    builder.addCase(
      fetchProjectProcessExpertiseDuplicate.fulfilled,
      (state, action) => {
        state.expertise = action.payload;
      }
    );
    builder.addCase(fetchProjectProcessExpertiseComplete.pending, (state) => {
      state.isLoadExpertiseComplete = true;
    });
    builder.addCase(fetchProjectProcessExpertiseComplete.fulfilled, (state) => {
      state.isLoadExpertiseComplete = false;
    });
  },
});
export const { setOpenExpertise, setOpenRemarks } =
  projectProcessExpertise.actions;
export default projectProcessExpertise;
