import { KanbanModel, KanbanSortValues } from "src/FSD/entities/kanban";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import Filter from "../../../../../../components/UI/components/Filter/Filter";

const ColumnSortFilter = () => {
  const dispatch = useAppDispatch();

  const sort = useAppSelector(KanbanModel.getSortValue);

  const isUp = sort === KanbanSortValues.DESC;

  const handleToggle = () => {
    dispatch(KanbanModel.toggleSortFilter());
  };

  return (
    <Filter handleToggle={handleToggle} isUp={isUp}>
      Сортировка по дате
    </Filter>
  );
};

export default ColumnSortFilter;
