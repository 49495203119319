import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import cls from "./ModalComment.module.scss";
import { useAppDispatch } from "../../../../../app/store";
import { fetchCommentsByTaskId } from "../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { getProjectTasks } from "../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import { NewModalPreloader } from "../../../NewModal/NewModalPreloader";
import { StatusIconType } from "./StatusIconType";
import { TooltipWrapper } from "../../../TooltipWrapper/TooltipWrapper";

interface ModalCommentProps {
  taskId: number;
}
export const ModalComment = ({ taskId }: ModalCommentProps) => {
  const dispatch = useAppDispatch();
  const { isLoadComments, comments } = useSelector(getProjectTasks);

  useEffect(() => {
    dispatch(fetchCommentsByTaskId(taskId));
  }, []);

  if (isLoadComments) {
    return <NewModalPreloader />;
  }

  return (
    <div className={cls.wrapper}>
      {comments.map((comment, index) => (
        <div className={cls.grid}>
          <div key={index} className={cls.block}>
            <StatusIconType status={comment.status} />
            <div className={cls.block_status}>
              <p>{comment.status}</p>
              <span>{comment.date}</span>
            </div>
          </div>
          <TooltipWrapper content={comment.author}>
            <p className={cls.author}>{comment.author}</p>
          </TooltipWrapper>

          <p className={cls.text}>{comment.text}</p>
        </div>
      ))}
    </div>
  );
};
