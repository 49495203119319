import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const sxHeader = { bgcolor: "#101920" };
const sxBody = { bgcolor: "#1A2734" };

export const SkeletonInitialDataFullPage = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxHeader} height={101} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={66} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={50} />
      </Grid>
    </Grid>
  );
};

export const InitialDataSkeletonBody = () => {
  return (
    <Grid
      sx={{ marginTop: "0px" }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={67} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={67} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={67} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={67} />
      </Grid>
    </Grid>
  );
};
