import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { responseAgreement } from "src/FSD/features/v2/SelectAgreement/model/options";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { Nullable } from "../../../../../../../../types/generics";
import { TSelectItem } from "../../../../../../../../components/features/Forms/SetGipForm/types";
import { toServerDate } from "../../../../../../../shared/lib/helpers/toServerDate";
import { publishContentSchemas } from "../schemas/publishContent";
import { useQueries } from "./useQueries";
import { setDateValues } from "../helpers/setDateValues";
import { fetchProcessWork } from "../../../../../../../../app/feature/ProjectProcessView/Work/services/fetchProcessWork";
import { useAppDispatch } from "../../../../../../../../app/store";

export type TValuesPublication = {
  disabled: boolean;
  showTermsPayment: boolean;
  userTypeToAssign: Nullable<TSelectItem>;
  userToAssign: Nullable<TSelectItem>;
  isAppointmentExecutor: boolean;
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  pay3: boolean;
  canNoSecure: boolean;
  disabledSaveBtn: boolean;
};

type TFieldValues = {
  price: number;
  pay1: number;
  pay2: number;
  pay3: number;
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  checkNegotiatedPrice: boolean;
  checkDelegation: boolean;
  agreementsActs: TSelectItem;
  safeTransaction: TSelectItem;
  userTypeToAssign: Nullable<TSelectItem>;
  userToAssign: Nullable<TSelectItem>;
  executorTypes: TSelectItem[];
  save: number;
  isAppointmentExecutor: boolean;
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  showVor: boolean;
  showVorPnr: boolean;
  showKac: boolean;
  bim: boolean;
  payError: string;
  showPay3: boolean;
  canNoSecure: boolean;
  processWork: boolean;
  workAccount: Nullable<TSelectItem>;
  showTermsPayment: boolean;
};

type UseModalProps = {
  id: number;
  isProcessWork: boolean;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const usePublishContent = ({
  id,
  isProcessWork,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(publishContentSchemas),
    mode: "onChange",
  });

  const {
    publishInfo,
    processData,
    isFetching,
    isFetchingPublishInfo,
    publish,
  } = useQueries(isProcessWork, id);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!publishInfo || !watch("agreementsActs").id) {
      return;
    }

    setValue(
      "showTermsPayment",
      !!(
        watch("agreementsActs").id &&
        !publishInfo.secureMissingAttributes.length
      )
    );
  }, [watch("agreementsActs").id, publishInfo]);

  useEffect(() => {
    if (isProcessWork) {
      setValue("processWork", isProcessWork);
    }
  }, [isProcessWork]);

  useEffect(() => {
    if (!processData) {
      return;
    }
    if (!publishInfo) {
      return;
    }

    setDateValues(processData, publishInfo, setValue, setError);
  }, [processData, publishInfo]);

  const valuesPublication = {
    disabled: watch("checkNegotiatedPrice") && !watch("userTypeToAssign"),
    showTermsPayment: watch("showTermsPayment"),
    userTypeToAssign: watch("userTypeToAssign"),
    userToAssign: watch("userToAssign"),
    isAppointmentExecutor: watch("isAppointmentExecutor"),
    vor: watch("showVor"),
    vorPnr: watch("showVorPnr"),
    kac: watch("showKac"),
    pay3: watch("showPay3"),
    canNoSecure: watch("canNoSecure"),
    disabledSaveBtn: !!(watch("userTypeToAssign") || watch("userToAssign")),
  };

  useEffect(() => {
    if (valuesPublication.userTypeToAssign) {
      setValue("checkNegotiatedPrice", false);
    }
  }, [valuesPublication.userTypeToAssign]);

  useEffect(() => {
    if (!valuesPublication.userToAssign && isProcessWork) {
      setValue("checkDelegation", false);
    }
  }, [valuesPublication.userToAssign]);

  useEffect(() => {
    if (
      isProcessWork &&
      !valuesPublication.userTypeToAssign &&
      valuesPublication.userToAssign
    ) {
      setValue("userToAssign", null);
    }
  }, [
    valuesPublication.userTypeToAssign,
    valuesPublication.userToAssign,
    isProcessWork,
  ]);

  useEffect(() => {
    if (
      !isProcessWork &&
      valuesPublication.userTypeToAssign &&
      !valuesPublication.userToAssign
    ) {
      setValue("userTypeToAssign", null);
    }
  }, [
    valuesPublication.userTypeToAssign,
    valuesPublication.userToAssign,
    isProcessWork,
  ]);

  const safeTransaction = watch("safeTransaction").title === "Да";
  const agreementsActs = watch("agreementsActs").title === "Нет";

  useEffect(() => {
    if (safeTransaction && agreementsActs) {
      setValue("agreementsActs", responseAgreement[1]);
    }
  }, [safeTransaction, agreementsActs]);

  const save = watch("save");

  const showErrorFiles =
    !isProcessWork &&
    !(processData?.files?.length || processData?.project?.files?.length);

  const textProcess = isProcessWork ? "задачи" : "раздела";

  const onSubmit = async (data: TFieldValues) => {
    const { length } = publishInfo!.secureMissingAttributes;

    if ((showErrorFiles || length) && !data.save) {
      return;
    }

    const secure = Number(data.safeTransaction.id);
    const with_docs = Number(data.agreementsActs.id);
    const formedData: ApiCommonResponses.Publish = {
      agreementPrice: data.checkNegotiatedPrice,
      date_start: toServerDate(data.dateStart),
      date_limit: toServerDate(data.dateLimit),
      with_docs,
      secure,
      executor_type: data.executorTypes.map((item: TSelectItem) =>
        Number(item.id)
      ),
      save: data.save,
      bim: data.bim,
    };

    if (isProcessWork) {
      formedData.money_from_process = data.workAccount!.id === "true";
    }

    if (!data.checkNegotiatedPrice) {
      formedData.price = Number(data.price);
    } else {
      formedData.price = 0;
    }

    if (data.userTypeToAssign) {
      formedData.firmIdByAdmin = Number(data.userTypeToAssign.id);
      formedData.executorByAdminId = Number(data.userToAssign!.id);

      if (isProcessWork) {
        formedData.delegate = data.checkDelegation;
      }
    }

    if (with_docs && !length) {
      formedData.pay1 = data.pay1;
      formedData.pay2 = data.pay2;
      if (publishInfo!.pay3) {
        formedData.pay3 = data.pay3;
      }
    }

    if (processData!.projectPart.vor) {
      formedData.vor = data.vor;
    }

    if (processData!.projectPart.vor_pnr) {
      formedData.vor_pnr = data.vorPnr;
    }

    if (processData!.projectPart.kac) {
      formedData.kac = data.kac;
    }

    await publish({ id, data: formedData });

    if (isProcessWork) {
      dispatch(fetchProcessWork(processData!.process_id));
    }

    handleClose();
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    errors,
    valuesPublication,
    isSubmittingSave: !!(isSubmitting && save),
    isSubmittingPublish: !!(isSubmitting && !save),
    isProjectProcessViewPending:
      isFetching || !processData || isFetchingPublishInfo || !publishInfo,
    processData,
    showErrorFiles,
    publishInfo,
    textProcess,
  } as const;
};
