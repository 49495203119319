import * as React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={20} />
    </Box>
  );
};
const sx = { bgcolor: "#26313a" };
export const SkeletonProject = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={148} />
      </Grid>
      <Grid item xs={6} md={4}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={8}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={4}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={8}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={145} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={73} />
      </Grid>
    </Grid>
  );
};

export const SkeletonInitialData = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
    </Grid>
  );
};

export const SkeletonGip = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={287} />
      </Grid>
    </Grid>
  );
};

export const SkeletonSections = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
    </Grid>
  );
};

export const SkeletonDocuments = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={150} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={150} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={150} />
      </Grid>
    </Grid>
  );
};

export const SkeletonCheckTask = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
    </Grid>
  );
};

export const SkeletonCheckList = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={86} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={50} />
      </Grid>
    </Grid>
  );
};

export const SkeletonTask = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ marginTop: "4px" }}
    >
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={48} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={48} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={48} />
      </Grid>
    </Grid>
  );
};

export const SkeletonComplex = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={84} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={331} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={331} />
      </Grid>
    </Grid>
  );
};

export const SkeletonGraphTab = () => {
  const sx = { bgcolor: "#26313a" };
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={700} />
      </Grid>
    </Grid>
  );
};

export const SkeletonProjectProcessWorkCard = () => {
  const sx = { bgcolor: "#26313a" };
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={107} />
      </Grid>
    </Grid>
  );
};
