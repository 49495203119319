import React, { useState } from "react";
import classNames from "classnames";
import style from "../../../../Project/View/Tasks/style.module.scss";
import { Dot } from "../../../../../newUI/Dot/Dot";
import { selectColor } from "../../../../../../utils/selectColor";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { ChatIcon } from "../../../../../newUI/SVG";
import { EditDateDeadLine } from "../../../../Project/View/Tasks/EditDateDeadLine/EditDateDeadLine";
import { OutTaskCardVersion } from "./OutTaskCardVersion";
import useSubRoutes from "../../../../../hooks/useSubRoutes";

export const OutTaskCard = ({
  task,
  chatId,
  projectId,
  processId,
  type,
  setActiveTabMemo,
  setModalData,
}: any) => {
  const {
    taskStatusInfo,
    title,
    header,
    executorName,
    tasks,
    code,
    project,
    taskId,
  } = task;

  const [toggleDiv, setToggleDiv] = useState(false);

  const arrowIconType = <ToggleAccordion toggle={toggleDiv} />;
  const isRedStatus = taskStatusInfo?.bgClass === "pirs-red";
  const canEditDate =
    tasks && tasks[tasks.length - 1]?.can["update-date-deadline"];
  const access = tasks[0]?.can;

  const handleOpenVersion = () => {
    setToggleDiv(true);
  };

  useSubRoutes(taskId, handleOpenVersion, 0);

  return (
    <div
      className={classNames(style.cardList_color)}
      style={{ background: selectColor(taskStatusInfo.bgClass) }}
    >
      <div className={style.content}>
        <div className={style.content_text}>
          <h3 title={title}>{header}</h3>
          <p className={style.projectText}>{project}</p>
          <p>{executorName}</p>
          <div onClick={() => setToggleDiv((v) => !v)}>
            <p>Открыть версии</p>
            {arrowIconType}
          </div>
        </div>
        <div className={style.content_btn}>
          {access.chat && (
            <TextLink title={"Чат"} redirect url={`/site/front/chat/${chatId}`}>
              <ChatIcon width="22" height="22" />
            </TextLink>
          )}
        </div>
      </div>
      {!toggleDiv && (
        <div className={style.status}>
          <div className={style.status_dot}>
            <Dot status={taskStatusInfo?.dot} />
            <p
              className={classNames(isRedStatus && style.statusRed)}
              dangerouslySetInnerHTML={{
                __html: taskStatusInfo.statusString,
              }}
            />
          </div>
          {!canEditDate && <p>{taskStatusInfo.date}</p>}
          {canEditDate && (
            <EditDateDeadLine
              node={task}
              projectId={projectId}
              processId={processId}
              type={type}
            />
          )}
        </div>
      )}
      {toggleDiv && (
        <div className={style.taskContainer}>
          <h3>Версии заданий</h3>
          {tasks?.map((task: any, i: number) => {
            return (
              <OutTaskCardVersion
                key={i}
                task={task}
                type={type}
                projectId={projectId}
                processId={processId}
                setActiveTabMemo={setActiveTabMemo}
                code={code}
                setModalData={setModalData}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
