import { createSlice } from "@reduxjs/toolkit";
import { fetchMaterialsOfferSubmit } from "../services/fetchMaterialsOfferSubmit";

type InitialState = {
  isLoadSubmit: boolean;
  successSubmit: boolean;
  error422: any;
  errorFlag: boolean;
  price: any;
  userTypeId: any;
  dateDeadline: any;
  comment: any;
};

const initialState: InitialState = {
  isLoadSubmit: false,
  successSubmit: false,
  error422: undefined,
  errorFlag: false,
  price: {},
  userTypeId: undefined,
  dateDeadline: null,
  comment: undefined,
};

const submitMaterialsForm = createSlice({
  name: "submitMaterialsForm",
  initialState,
  reducers: {
    setInitial: (state, action) => {
      state.userTypeId = { ...state.userTypeId, ...action.payload.userType };
      state.price = { ...state.price, ...action.payload.price };
      state.dateDeadline = {
        ...state.dateDeadline,
        ...action.payload.dateDeadline,
      };
      state.comment = { ...state.comment, ...action.payload.comment };
    },
    setPrice: (state, action) => {
      state.price = { ...state.price, ...action.payload };
    },
    setUserTypeId: (state, action) => {
      state.userTypeId = { ...state.userTypeId, ...action.payload };
    },
    setDateDeadline: (state, action) => {
      state.dateDeadline = { ...state.dateDeadline, ...action.payload };
    },
    setComment: (state, action) => {
      state.comment = { ...state.comment, ...action.payload };
    },
    setClear: (state) => {
      state.error422 = undefined;
      state.successSubmit = false;
    },
    setErrorFlag: (state) => {
      state.errorFlag = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaterialsOfferSubmit.pending, (state) => {
      state.isLoadSubmit = true;
      state.successSubmit = false;
    });
    builder.addCase(fetchMaterialsOfferSubmit.fulfilled, (state) => {
      state.isLoadSubmit = false;
      state.successSubmit = true;
    });
    builder.addCase(fetchMaterialsOfferSubmit.rejected, (state, action) => {
      state.error422 = action.payload?.message;
      state.errorFlag = true;
      state.isLoadSubmit = false;
    });
  },
});

export const {
  setInitial,
  setDateDeadline,
  setClear,
  setUserTypeId,
  setPrice,
  setComment,
  setErrorFlag,
} = submitMaterialsForm.actions;
export default submitMaterialsForm;
