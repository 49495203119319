import React, { memo, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useProjectSourceDataIndexQuery } from "src/FSD/entities/projectSourceData/api";
import { SourceDataType } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessOpenSourceData } from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessSourceChild";
import { GearFillSVG } from "src/components/newUI/SVG";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import {
  getProjectProcessCheckList,
  getProjectProcessCheckListIsLoad,
} from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessCheckList";
import { fetchProjectProcessChecklist } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessChecklist";
import { getProjectProcessSourceDataUpdateIsLoad } from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessSourceDataUpdate";
import { getProjectProcessInfoProject } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { setOpenSourceData } from "src/app/feature/ProjectProcessView/InitialData/slice/projectProcessInitialData";
import { messages } from "src/components/pages/Project/View/InitialData/InitialDataCard";
import { deleteProjectChecklistById } from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { setDisabled } from "src/app/feature/project/projectChecklist";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { CardHeader } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/CardHeader/CardHeader";
import { newIconBtnSx } from "src/styles/restyle";
import { SourceDataCard } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/SourceDataCard/SourceDataCard";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import styles from "./TabsSourceData.module.scss";

export const TabsSourceData = memo(
  ({
    name,
    parentId,
    projectId,
    index,
    all,
    inWork,
    dot,
    type,
  }: SourceDataType & { index: number; type: string | number }) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile(681);

    const project = useAppSelector(getProjectProcessInfoProject);
    const checkList = useAppSelector(getProjectProcessCheckList);
    const openSourceData = useAppSelector(getProjectProcessOpenSourceData);
    const isLoadCheckList = useAppSelector(getProjectProcessCheckListIsLoad);
    const IsLoadSourceDataUpdate = useAppSelector(
      getProjectProcessSourceDataUpdateIsLoad
    );

    const isOpen = !Number.isNaN(Number(openSourceData[index]));

    const [modalGear, setModalGear] = useState(false);

    const canViewProject = project?.can.view;
    const canAddFile = project?.can.editByGipHelper || false;

    const [skip, setSkip] = useState(!isOpen);
    const {
      data: sourceChildItems,
      isLoading,
      refetch,
    } = useProjectSourceDataIndexQuery(
      {
        id: projectId,
        parentId,
        type: Number(type),
      },
      { skip }
    );

    useEffect(() => {
      if (isOpen && !sourceChildItems) {
        setSkip(false);
      }
    }, [isOpen]);

    const closeModal = () => {
      setModalGear(false);
    };

    const accordionClick = (index: number) => {
      dispatch(setOpenSourceData(index));
    };

    const gearBtnClick = () => {
      setModalGear(true);
      dispatch(
        fetchProjectProcessChecklist({
          projectId,
          parentId,
          type: type as number,
        })
      );
    };

    const updateAddCheckList = async () => {
      await dispatch(
        fetchProjectProcessChecklist({
          projectId,
          parentId,
          type: type as number,
        })
      );
    };

    const handleDeleteSourceDataName = async (sourceDataId: number) => {
      if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
        return;
      }

      dispatch(setDisabled("delete"));
      await dispatch(deleteProjectChecklistById(sourceDataId));

      refetch();

      await dispatch(
        fetchProjectProcessChecklist({
          projectId,
          parentId,
          type: type as number,
        })
      );

      dispatch(setDisabled(false));
    };

    return (
      <>
        <div>
          <Modal
            isActive={modalGear}
            handleClose={() => {
              setModalGear(false);
            }}
          >
            {checkList && (
              <ProjectProcessModals.ModalGear
                type={Number(type)}
                checkList={checkList}
                parentId={parentId}
                projectId={projectId}
                name={name}
                closeModal={closeModal}
                handleDeleteSourceDataName={handleDeleteSourceDataName}
                isLoad={isLoadCheckList || IsLoadSourceDataUpdate}
                updateAddCheckList={updateAddCheckList}
              />
            )}
          </Modal>
          <CardHeader
            title={name}
            expanded={isOpen}
            inWork={inWork}
            all={all}
            dot={dot}
            onClick={() => accordionClick(index)}
          />
        </div>
        {isOpen && (
          <>
            <div className={styles.container}>
              {!isMobile && (
                <div className={styles.parent}>
                  <div>Статус</div>
                  <div>Наименование</div>
                  <div>Дата запроса</div>
                  <div>Контр. срок</div>
                  <div>Согласовано</div>
                  <div className={styles.parent__icon}>
                    {canViewProject && (
                      <IconButton
                        sx={{ ...newIconBtnSx, padding: 0 }}
                        onClick={gearBtnClick}
                      >
                        <GearFillSVG width={"22"} height={"22"} />
                      </IconButton>
                    )}
                  </div>
                  {sourceChildItems &&
                    sourceChildItems.map((child) => (
                      <SourceDataCard
                        key={child.id}
                        {...child}
                        parentId={parentId}
                        index={index}
                        type={Number(type)}
                        canAddFile={canAddFile}
                      />
                    ))}
                </div>
              )}
              {isMobile &&
                sourceChildItems &&
                sourceChildItems.map((child) => (
                  <SourceDataCard
                    key={child.id}
                    {...child}
                    isMobile={isMobile}
                    parentId={parentId}
                    index={index}
                    type={Number(type)}
                    canAddFile={canAddFile}
                  />
                ))}
              {!sourceChildItems && isLoading && (
                <div className={styles.container}>
                  <CircularIndeterminate />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
);
