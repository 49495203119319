import { forwardRef } from "react";
import {
  Select,
  SelectProps,
  TSelectItem,
} from "src/FSD/shared/uiKit/v2/Select";
import { useSelectUserTypeToAssign } from "../lib/hooks/useSelectUserTypeToAssign";
import { Nullable } from "../../../../../types/generics";

type Props = {
  publishObjectId: number;
  isProcessWork?: boolean;
  executor?: Nullable<TSelectItem>;
} & Omit<SelectProps, "label" | "options">;

export const SelectUserTypeToAssign = forwardRef(
  (
    {
      publishObjectId,
      value,
      className = "",
      disabled = false,
      isProcessWork = false,
      executor,
      ...props
    }: Props,
    ref
  ) => {
    const { userTypeToAssign, isPendingTypeAssign } = useSelectUserTypeToAssign(
      {
        id: publishObjectId,
        isProcessWork,
        executor,
      }
    );

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={userTypeToAssign ?? []}
        isLoading={isPendingTypeAssign}
        label="Форма собственности"
        className={className}
        disabled={disabled}
      />
    );
  }
);
