import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { FormWrapper } from "../../../../../../newUI/FormWrapper/FormWrapper";
import { Uploader } from "../../../../../../features/Uploader/Uploader";
import { ApiGetTaskFiles } from "../../../../../../../app/services/api/organisation/organisation";
import { config } from "../../../../../../../app.cofig";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import { SelectField } from "../../../../../../newUI/SelectMui/SelectMui";
import { fetchProjectProcessToProcesses } from "../../../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessToProcesses";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getProjectProcessToProcessType,
  getProjectProcessToProcessTypeIsLoad,
} from "../../../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessToProcessType";
import cls from "./ModalPreminaryByTask.module.scss";
import { fetchProjectProcessPreliminaryDoneByTask } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessPreliminaryDone";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";

type TInputs = {
  content: string;
  comment: string;
  reviewProcessIds: string[];
  upload_files: any;
};

interface ModalPreminaryByTaskProps {
  id: number;
  content: string;
  comment: string;
  header: string;
  closeModal: () => void;
}

export const ModalPreminaryByTask = (props: ModalPreminaryByTaskProps) => {
  const dispatch = useAppDispatch();
  const { id, content, header, comment, closeModal } = props;
  const [valueMulti, setValueMulti] = useState<any>([]);
  const processId = useAppSelector(getProjectProcessInfoId);
  const projectId = useAppSelector(getProjectProcessInfoProjectId);
  const processType = useAppSelector(getProjectProcessToProcessType);
  const [initialListLength, setInitialListLength] = useState(-1);
  const isLoadProcessType = useAppSelector(
    getProjectProcessToProcessTypeIsLoad
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(
      Yup.object().shape({
        upload_files: Yup.array().test(
          "notEmpty",
          "Необходимо прикрепить минимум 1 файл",
          () => {
            const watchFiles: any = getValues("upload_files");
            const fileLength = initialListLength + watchFiles.length;
            const bool = fileLength === 0;
            return !bool;
          }
        ),
      })
    ),
  });

  const formSubmitHandler = async (dataValues: TInputs) => {
    const formData = new FormData();
    formData.append("status", "6");
    formData.append("comment", dataValues.comment);
    dataValues.reviewProcessIds?.forEach((review) => {
      formData.append("reviewProcessIds[]", review);
    });
    if (processId) {
      await dispatch(
        fetchProjectProcessPreliminaryDoneByTask({
          processId,
          taskId: id,
          formData,
        })
      );
    }
    closeModal();
  };

  useEffect(() => {
    if (projectId && processId) {
      dispatch(
        fetchProjectProcessToProcesses({ processId, projectId, all: 1 })
      );
    }
  }, []);

  const onChangeMulti = (event: any) => {
    if (event.target.value.includes("all") && processType) {
      const all = Object.keys(processType);
      setValueMulti(all);
      setValue("reviewProcessIds", all);
    } else {
      setValueMulti(event.target.value);
      setValue("reviewProcessIds", event.target.value);
    }
  };

  const handleClearClick = () => {
    setValueMulti([]);
    setValue("reviewProcessIds", []);
  };

  return (
    <FormWrapper title={header} submitHandler={handleSubmit(formSubmitHandler)}>
      {header && (
        <TextField
          {...textFieldSX}
          label="Наименование"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          defaultValue={header}
        />
      )}
      {content && (
        <TextField
          {...textFieldSX}
          label="Комментарий проверяющего"
          multiline
          minRows={3}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          defaultValue={content}
        />
      )}
      <div className={cls.uploader}>
        <Uploader
          identifier={String(id)}
          autoUpload
          canBeEdited
          getDataCallback={ApiGetTaskFiles}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          fileHandler={(data) => {
            setValue("upload_files", data);
          }}
          setInitialListLength={setInitialListLength}
        />
        {errors.upload_files && (
          <p className={cls.error}>{errors.upload_files.message}</p>
        )}
      </div>
      <TextField
        {...textFieldSX}
        label="Комментарий исполнителя"
        {...register("comment")}
        multiline
        minRows={2}
        defaultValue={comment}
      />
      <SelectField
        {...register("reviewProcessIds")}
        name="reviewProcessIds"
        label="Проверяющие"
        multiple
        multiValue={valueMulti}
        isAllClick
        onChange={onChangeMulti}
        options={Object.entries(processType)}
        handleClearClick={handleClearClick}
        disabled={isLoadProcessType}
        error={errors.reviewProcessIds ? errors.reviewProcessIds.message : null}
      />
      <div className={cls.submit}>
        <CustomButton background={palette.blue} type={"submit"} width={160}>
          Отправить
        </CustomButton>
      </div>
    </FormWrapper>
  );
};
