import classNames from "classnames";
import React from "react";
import styles from "./ToggleAccordionSvg.module.scss";

export const RootNodeArrowClose = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46912 14.2184C5.53879 14.1485 5.62155 14.0931 5.71267 14.0553C5.80379 14.0175 5.90147 13.998 6.00012 13.998C6.09877 13.998 6.19645 14.0175 6.28757 14.0553C6.37869 14.0931 6.46145 14.1485 6.53112 14.2184L12.0001 19.6889L17.4691 14.2184C17.6099 14.0776 17.801 13.9984 18.0001 13.9984C18.1993 13.9984 18.3903 14.0776 18.5311 14.2184C18.6719 14.3592 18.7511 14.5502 18.7511 14.7494C18.7511 14.9486 18.6719 15.1396 18.5311 15.2804L12.5311 21.2804C12.4615 21.3502 12.3787 21.4056 12.2876 21.4435C12.1965 21.4813 12.0988 21.5007 12.0001 21.5007C11.9015 21.5007 11.8038 21.4813 11.7127 21.4435C11.6216 21.4056 11.5388 21.3502 11.4691 21.2804L5.46912 15.2804C5.39928 15.2107 5.34386 15.128 5.30605 15.0368C5.26824 14.9457 5.24878 14.848 5.24878 14.7494C5.24878 14.6507 5.26824 14.5531 5.30605 14.4619C5.34386 14.3708 5.39928 14.2881 5.46912 14.2184ZM5.46912 10.7804C5.53879 10.8502 5.62155 10.9056 5.71267 10.9435C5.80379 10.9813 5.90147 11.0007 6.00012 11.0007C6.09877 11.0007 6.19645 10.9813 6.28757 10.9435C6.37869 10.9056 6.46145 10.8502 6.53112 10.7804L12.0001 5.30989L17.4691 10.7804C17.6099 10.9212 17.801 11.0003 18.0001 11.0003C18.1993 11.0003 18.3903 10.9212 18.5311 10.7804C18.6719 10.6396 18.7511 10.4486 18.7511 10.2494C18.7511 10.0502 18.6719 9.85922 18.5311 9.71839L12.5311 3.71839C12.4615 3.64854 12.3787 3.59313 12.2876 3.55532C12.1965 3.51751 12.0988 3.49805 12.0001 3.49805C11.9015 3.49805 11.8038 3.51751 11.7127 3.55532C11.6216 3.59313 11.5388 3.64854 11.4691 3.71839L5.46912 9.71839C5.39928 9.78805 5.34386 9.87082 5.30605 9.96194C5.26824 10.0531 5.24878 10.1507 5.24878 10.2494C5.24878 10.348 5.26824 10.4457 5.30605 10.5368C5.34386 10.628 5.39928 10.7107 5.46912 10.7804Z"
      fill="#0AA699"
    />
  </svg>
);

export const RootNodeArrowOpen = ({ color = "#D5D6DE", className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46912 21.2804C5.53879 21.3502 5.62155 21.4056 5.71267 21.4435C5.80379 21.4813 5.90147 21.5007 6.00012 21.5007C6.09877 21.5007 6.19645 21.4813 6.28757 21.4435C6.37869 21.4056 6.46145 21.3502 6.53112 21.2804L12.0001 15.8099L17.4691 21.2804C17.6099 21.4212 17.801 21.5003 18.0001 21.5003C18.1993 21.5003 18.3903 21.4212 18.5311 21.2804C18.6719 21.1396 18.7511 20.9485 18.7511 20.7494C18.7511 20.5502 18.6719 20.3592 18.5311 20.2184L12.5311 14.2184C12.4615 14.1485 12.3787 14.0931 12.2876 14.0553C12.1965 14.0175 12.0988 13.998 12.0001 13.998C11.9015 13.998 11.8038 14.0175 11.7127 14.0553C11.6216 14.0931 11.5388 14.1485 11.4691 14.2184L5.46912 20.2184C5.39928 20.2881 5.34386 20.3708 5.30605 20.4619C5.26824 20.5531 5.24878 20.6507 5.24878 20.7494C5.24878 20.848 5.26824 20.9457 5.30605 21.0368C5.34386 21.128 5.39928 21.2107 5.46912 21.2804ZM5.46912 3.71839C5.53879 3.64854 5.62155 3.59313 5.71267 3.55532C5.80379 3.51751 5.90147 3.49805 6.00012 3.49805C6.09877 3.49805 6.19645 3.51751 6.28757 3.55532C6.37869 3.59313 6.46145 3.64854 6.53112 3.71839L12.0001 9.18889L17.4691 3.71839C17.6099 3.57756 17.801 3.49844 18.0001 3.49844C18.1993 3.49844 18.3903 3.57756 18.5311 3.71839C18.6719 3.85922 18.7511 4.05022 18.7511 4.24939C18.7511 4.44855 18.6719 4.63956 18.5311 4.78039L12.5311 10.7804C12.4615 10.8502 12.3787 10.9056 12.2876 10.9435C12.1965 10.9813 12.0988 11.0007 12.0001 11.0007C11.9015 11.0007 11.8038 10.9813 11.7127 10.9435C11.6216 10.9056 11.5388 10.8502 11.4691 10.7804L5.46912 4.78039C5.39928 4.71072 5.34386 4.62796 5.30605 4.53684C5.26824 4.44572 5.24878 4.34804 5.24878 4.24939C5.24878 4.15074 5.26824 4.05305 5.30605 3.96194C5.34386 3.87082 5.39928 3.78806 5.46912 3.71839Z"
      fill={color}
    />
  </svg>
);

export const DefaultArrowOpen = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 16.194L1.5 1.80603L9.7215 9.00003L1.5 16.194ZM2.4885 17.3235L10.7085 10.1295C10.8696 9.98873 10.9987 9.8151 11.0872 9.62029C11.1757 9.42548 11.2215 9.21399 11.2215 9.00003C11.2215 8.78606 11.1757 8.57458 11.0872 8.37976C10.9987 8.18495 10.8696 8.01132 10.7085 7.87053L2.4885 0.676525C1.518 -0.172474 5.51559e-07 0.517527 5.51559e-07 1.80603L5.51559e-07 16.194C-0.000246689 16.4824 0.0826318 16.7647 0.238711 17.0072C0.394791 17.2497 0.617455 17.442 0.88004 17.5612C1.14262 17.6804 1.434 17.7214 1.71927 17.6792C2.00454 17.637 2.27161 17.5136 2.4885 17.3235Z"
      fill="white"
    />
  </svg>
);

export const DefaultArrowClose = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.87052 10.71L0.676516 2.487C-0.172484 1.5195 0.517516 5.51559e-07 1.80602 5.51559e-07H16.194C16.4824 -0.000246689 16.7647 0.0826318 17.0072 0.238711C17.2497 0.394791 17.442 0.617455 17.5612 0.88004C17.6804 1.14262 17.7213 1.434 17.6792 1.71927C17.637 2.00454 17.5135 2.27161 17.3235 2.4885L10.1295 10.7085C9.98872 10.8696 9.81509 10.9987 9.62028 11.0872C9.42547 11.1757 9.21398 11.2215 9.00002 11.2215C8.78605 11.2215 8.57457 11.1757 8.37975 11.0872C8.18494 10.9987 8.01131 10.8696 7.87052 10.7085V10.71Z"
      fill="white"
    />
  </svg>
);

export const CaretArrowUp = ({ fill = "#0B1016" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill={fill}
    className="bi bi-caret-up-fill"
    viewBox="0 0 16 16"
  >
    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
  </svg>
);

export const CaretArrowDown = ({ fill = "#0B1016", isOpen = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill={fill}
    className={classNames("bi bi-caret-down-fill", styles.buttonCaret, {
      [styles.buttonCaretOpen]: isOpen,
    })}
    viewBox="0 0 16 16"
  >
    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
  </svg>
);

export const VersionArrowOpen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
  >
    <path
      d="M6.00153 0.892285C6.44419 0.452832 7.16306 0.452832 7.60572 0.892285L13.2717 6.51729C13.7144 6.95674 13.7144 7.67041 13.2717 8.10986C12.8291 8.54932 12.1102 8.54932 11.6675 8.10986L6.80186 3.27939L1.93618 8.10635C1.49352 8.5458 0.774648 8.5458 0.331992 8.10635C-0.110664 7.66689 -0.110664 6.95322 0.331992 6.51377L5.99799 0.888769L6.00153 0.892285Z"
      fill="#D5D6DE"
    />
  </svg>
);

export const VersionArrowClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
  >
    <path
      d="M6.50153 8.10967C6.94419 8.54912 7.66306 8.54912 8.10572 8.10967L13.7717 2.48467C14.2144 2.04521 14.2144 1.33154 13.7717 0.89209C13.3291 0.452637 12.6102 0.452637 12.1675 0.89209L7.30186 5.72256L2.43618 0.895605C1.99352 0.456152 1.27465 0.456152 0.831992 0.895605C0.389336 1.33506 0.389336 2.04873 0.831992 2.48818L6.49799 8.11318L6.50153 8.10967Z"
      fill="#D5D6DE"
    />
  </svg>
);
