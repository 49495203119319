import React from "react";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import styles from "./Header.module.scss";
import {
  getGroupApplications,
  toggleGroupComparisonMode,
} from "../../../../../../app/feature/ProjectView/GroupApplications";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";

export const Header = () => {
  const dispatch = useAppDispatch();
  const {
    data: { selectedExecutors, comparisonMode, executors },
  } = useAppSelector(getGroupApplications);
  const comparisonCount = selectedExecutors.length;
  const canCompare = Boolean(comparisonCount);
  const allApllications = Object.entries(executors).reduce(
    (acc, [groupId, executorsList]: any) => {
      acc += executorsList.length;
      return acc;
    },
    0
  );

  const handleToggleComparisonMode = () => {
    dispatch(toggleGroupComparisonMode());
  };
  return (
    <div className={styles.header}>
      <div className={styles.header_left}>
        <p>Заявки</p>
        <span>{`(всего ${allApllications})`}</span>
      </div>
      {canCompare && (
        <Button onClick={handleToggleComparisonMode}>
          {comparisonMode ? <>Назад</> : <>Сравнить ({comparisonCount})</>}
        </Button>
      )}
    </div>
  );
};
