import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой приглашения человека на проект
 */

export const showEventUserAtUserPage = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventUserJoinAtUserPage: {
      ...state.eventUserJoinAtUserPage,
      isShow: true,
    },
  };
};

export const closeEventUserAtUserPage = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventUserJoinAtUserPage: {
    isShow: false,
    data: {
      ...state.eventUserJoinAtUserPage.data,
    },
  },
});

export const setEventUserAtUserPage = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventUserJoinAtUserPage: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorEventUserAtUserPage = (state: StateType) =>
  state.eventEmitter.eventUserJoinAtUserPage.isShow;

export const selectorEventUserAtUserPageData = (state: StateType) =>
  state.eventEmitter.eventUserJoinAtUserPage.data;
