import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import {
  getProjectSections,
  setPublishNull,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { ModalSchema } from "src/components/pages/Project/View/Sections/ModalSchema";
import { fetchPublishInfo } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { useAppSelector } from "src/app/store";
import { getProjectProcessWorkPublishError422 } from "src/app/feature/ProjectProcessView/Work/selectors/getProjectProcessWorkPublish";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { useProjectProcessUpdatePublishedMutation } from "src/FSD/entities/projectProcess/api";
import { useProcessWorkUpdatePublishedMutation } from "src/FSD/entities/processWork/api";
import cls from "./UpdatePublishProcess.module.scss";

const types = [
  { id: 1, title: "Физическое лицо" },
  { id: 2, title: "Юридическое лицо" },
  { id: 3, title: "Индивидуальный предприниматель" },
  { id: 4, title: "Самозанятый" },
];

type TInputs = {
  price: number;
  type: TSelectItem[];
  date_start: any;
  date_limit: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  pay1: number;
  pay2: number;
  pay3: number;
  paymentSum: number;
};

interface UpdatePublishProcessProps {
  id: number;
  haveExpertise: boolean;
  projectPart: any;
  price: string;
  vor: boolean;
  vor_pnr: boolean;
  date_limit: any;
  date_start: any;
  executorType: { value: string; full: string; key: number }[];
  pay1: number;
  pay2: number;
  pay3: number;
  kac: boolean;
  title: string;
  work?: boolean;
}
export const UpdatePublishProcess = memo((props: UpdatePublishProcessProps) => {
  const { error422, update, publishInfo, isLoadPublishInfo } =
    useSelector(getProjectSections);
  const error422Work = useAppSelector(getProjectProcessWorkPublishError422);
  const dispatch = useDispatch();
  const { handleClose } = useModalContext();

  const [
    updatePublishedProcess,
    { isLoading: updatePublishedProcessIsLoading },
  ] = useProjectProcessUpdatePublishedMutation();

  const [updatePublishedWork, { isLoading: updatePublishedWorkIsLoading }] =
    useProcessWorkUpdatePublishedMutation();

  const {
    id,
    haveExpertise,
    projectPart,
    price,
    vor,
    vor_pnr,
    kac,
    date_limit,
    date_start,
    executorType,
    pay1,
    pay2,
    pay3,
    title,
    work,
  } = props;

  const defaultExecutorType = executorType?.map(({ full, key }) => ({
    id: key,
    title: full,
  }));
  const additional = publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
  const defaultAgreementPrice = price === "Договорная";
  const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
  const schema = ModalSchema(haveExpertise, checkPrice);

  const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
  const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      pay1,
      pay2: !haveExpertise ? pay2 + pay3 : pay2,
      pay3,
      price: defaultAgreementPrice ? 0 : +price,
      date_start: start,
      date_limit: end,
      vor,
      vor_pnr,
      kac,
      type: defaultExecutorType,
    },
  });

  const formSubmitHandler = async (dataValues: TInputs) => {
    const executorType = dataValues.type.map((el) => el.id);

    const data = {
      agreementPrice: checkPrice,
      vor: !publishInfo.vor ? false : dataValues.vor,
      vor_pnr: !publishInfo.vor_pnr ? false : dataValues.vor_pnr,
      kac: !publishInfo.kac ? false : dataValues.kac,
      price: (!checkPrice && dataValues.price) || null,
      date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      executor_type: executorType,
      pay1: +dataValues.pay1,
      pay2: +dataValues.pay2,
      pay3: haveExpertise ? +dataValues.pay3 : null,
    };
    if (!work) {
      await updatePublishedProcess({ id, data });
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }
    if (work) {
      await updatePublishedWork({ id, data });
      if (error422Work) {
        await parseUnprocessableFields(error422Work, setError);
      }
    }
    handleClose();
  };

  useEffect(() => {
    if (update) {
      clearErrors();
      dispatch(setPublishNull());
      handleClose();
    }
  }, [update]);

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  useEffect(() => {
    dispatch(fetchPublishInfo({ id, isWork: work }));
  }, []);

  const priceRender = (
    <div className={cls.form_flex}>
      <TextField
        {...register("price")}
        label="Стоимость"
        defaultValue={price}
        error={!!errors.price}
        helperText={errors?.price?.message}
        disabled={checkPrice}
        className={cls.form__price}
      />
      <Checkbox
        label="Договорная стоимость"
        onChange={() => setCheckPrice(!checkPrice)}
        value={checkPrice}
        checked={checkPrice}
        editing
        className={cls.form__price__checkbox}
      />
    </div>
  );

  const payRender = (
    <>
      <div className={cls.form_pay}>
        <TextField
          {...register("pay1")}
          label="Аванс (%)"
          defaultValue={pay1}
          error={!!errors.pay1}
          helperText={errors?.pay1?.message}
        />
        <TextField
          {...register("pay2")}
          label="Оплата при завершении (%)"
          defaultValue={!haveExpertise ? pay3 + pay2 : pay2}
          error={!!errors.pay2}
          helperText={errors?.pay2?.message}
        />
        {haveExpertise && projectPart.published && (
          <TextField
            {...register("pay3")}
            label="Оплата после экспертизы (%)"
            defaultValue={pay3}
            error={!!errors.pay3}
            helperText={errors?.pay3?.message}
          />
        )}
        <input type="hidden" {...register("paymentSum")} />
      </div>
      {errors.paymentSum && (
        <p className={cls.form_pay_paymentSum}>{errors.paymentSum.message}</p>
      )}
    </>
  );

  const typeRender = (
    <Controller
      name="type"
      control={control}
      render={({ field: { value, onChange } }) => (
        <SelectMultiple
          label="Форма собственности"
          value={value}
          options={types}
          showSelectAll
          changeHandler={(selectItem) => {
            onChange(selectItem);
          }}
          error={!!errors.type}
          helperText={errors?.type?.message}
        />
      )}
    />
  );

  const dateRender = (
    <div className={cls.form_date}>
      <div className={cls.form_date_start}>
        <Controller
          control={control}
          name="date_start"
          render={({ field: { value, onChange } }) => (
            <DateField
              label="Начальный срок"
              changeDateHandler={(date) => {
                onChange(date);
              }}
              startDateProp={value}
              error={!!errors.date_start}
              helperText={errors.date_start?.message}
            />
          )}
        />
      </div>
      <div className={cls.form_date_end}>
        <Controller
          control={control}
          name="date_limit"
          render={({ field: { value, onChange } }) => (
            <DateField
              label="Конечный срок"
              changeDateHandler={(date) => {
                onChange(date);
              }}
              startDateProp={value}
              error={!!errors.date_limit}
              helperText={errors.date_limit?.message}
            />
          )}
        />
      </div>
    </div>
  );

  const vorRender = (
    <div className={cls.form_flexColumn}>
      {(publishInfo.vor || publishInfo.vor_pnr) && (
        <div className={cls.form_flex}>
          {publishInfo.vor && (
            <Controller
              control={control}
              name="vor"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Ведомость объемов работ"
                  onChange={onChange}
                  value={value}
                  checked={value}
                  editing
                />
              )}
            />
          )}
          {errors.vor && <p className={cls.error}>{errors.vor.message}</p>}
          {publishInfo.vor_pnr && (
            <Controller
              control={control}
              name="vor_pnr"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Ведомость объемов работ на пусконаладочные работы"
                  onChange={onChange}
                  value={value}
                  checked={value}
                  editing
                />
              )}
            />
          )}
          {errors.vor_pnr && (
            <p className={cls.error}>{errors.vor_pnr.message}</p>
          )}
        </div>
      )}
      <div className={cls.form_flex}>
        {publishInfo.kac && (
          <Controller
            control={control}
            name="kac"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Конъюнктурный анализ цен"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
      </div>
    </div>
  );

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{title}</Modal.Header>
      {isLoadPublishInfo ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
          <h3>Стоимость, сроки и условия оплаты</h3>
          {priceRender}
          {payRender}
          {dateRender}
          <h3>Требования к исполнителю</h3>
          {typeRender}
          {additional && !work && (
            <h3>Дополнительные требования к разработке раздела</h3>
          )}
          {vorRender}
          <Modal.Controls>
            <Button
              type="submit"
              disabled={
                updatePublishedProcessIsLoading || updatePublishedWorkIsLoading
              }
              isLoading={
                updatePublishedProcessIsLoading || updatePublishedWorkIsLoading
              }
            >
              Изменить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
});
