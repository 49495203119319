import { useEffect } from "react";
import { KANBAN_REQUEST_TIMEOUT } from "../constants/timeout";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { KanbanApi, KanbanModel } from "../../../../../../entities/kanban";
import { getFilterValues } from "../helpers";
import { getDashboardDestinations } from "../../../../../../../app/feature/dashboard/destinations";

export const useTimer = () => {
  const dispatch = useAppDispatch();

  const sort = useAppSelector(KanbanModel.getSortValue);
  const { userType: userTypeId } = useAppSelector(getDashboardDestinations);

  useEffect(() => {
    const timeoutId: NodeJS.Timeout = setInterval(() => {
      const filterValues = getFilterValues(userTypeId);

      dispatch(
        KanbanApi.fetchViewByUserTypeId({
          userTypeId,
          filterValues,
          showSpinner: false,
        })
      );
    }, KANBAN_REQUEST_TIMEOUT);

    return () => {
      if (timeoutId) {
        clearInterval(timeoutId);
      }
    };
  }, [userTypeId, sort]);
};
