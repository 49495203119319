import { TProcess, SectionProps } from "./types";

class Filter {
  static filterSectionIdsByQuery(
    sections: SectionProps,
    query: string,
    fulfilled: boolean
  ): number[] {
    if (query === "") {
      return [];
    }

    let ids: TProcess[] | number[] = Object.values(sections).filter(
      (process: TProcess) => {
        const nameIncludes = process?.projectPart?.name
          .toLowerCase()
          .includes(query.toLowerCase());

        const codeIncludes = process?.projectPart?.code
          .toLowerCase()
          .includes(query.toLowerCase());

        return nameIncludes || codeIncludes;
      }
    );

    if (fulfilled) {
      ids = ids.filter((process: TProcess) => process?.status?.key === 3);
    }

    ids = ids.map((process: TProcess) => process.id);

    return ids;
  }

  static filterByFulfilled(sections: SectionProps, fulfilled: boolean) {
    if (!fulfilled) {
      return [];
    }

    const ids = Object.values(sections)
      .filter((process: TProcess) => process?.status?.key === 3)
      .map((process: TProcess) => process.id);

    return ids;
  }

  static updateTreeByFilterParams(data: SectionProps, ticked: number[]) {
    const treeRaw = JSON.parse(JSON.stringify(data));
    const getCounts = (node: string): boolean => {
      const currentNode = treeRaw[node];

      if (treeRaw[node]?.id) {
        return ticked.includes(treeRaw[node]?.id);
      }

      let count = false;
      currentNode.children.forEach((item: string) => {
        count = getCounts(item) || count;
      });

      currentNode.count = count;
      return count;
    };

    getCounts("0");
    return treeRaw;
  }

  static shouldIncludeApplication = (application: any, query: string) => {
    const { surname, name, patronymic } = application.task.author;

    const p1 = surname.toLowerCase().includes(query.toLowerCase());
    const p2 = name.toLowerCase().includes(query.toLowerCase());
    const p3 = patronymic.toLowerCase().includes(query.toLowerCase());

    const p4 = application.userType.listName
      .toLowerCase()
      .includes(query.toLowerCase());

    return p1 || p2 || p3 || p4;
  };

  static filterApplicationsByQuery(data: any, query: string) {
    const allData = [...data.good, ...data.bad];

    const filteredApplications = allData
      .filter((application: any) =>
        Filter.shouldIncludeApplication(application, query)
      )
      .map((application: any) => application.id);

    return filteredApplications;
  }
}

export const getDocuments = (treaties: any, treatyId: number) => {
  let documents: any = [];

  const treaty = treaties.find((treaty: any) => treaty.id === treatyId);

  if (!treaty?.documents) {
    return [];
  }

  if (treaty) {
    documents = [...treaty.documents];
  }

  return documents;
};

export default Filter;
