import React from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  FormHelperText,
} from "@mui/material";
import cn from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import {
  MenuProps,
  boxSX,
} from "../../../features/SpecialForm/SpecializationMultiple";
import style from "../../../features/SpecialForm/style.module.scss";
import { newMenuItemSx } from "../../../../styles/restyle";

const chooseAll = {
  id: -1,
  title: "Выбрать все",
};

const cancelChoice = {
  id: -1,
  title: "Отменить выбор",
};

export type MyMultipleSelectProps = ComponentPropsType<{
  options: { [key: string]: any }[];
  label?: string;
  isLoading?: boolean;
  value?: { [key: string]: any }[];
  changeHandler?: (value: any) => void;
  blurHandler?: (event: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  canSelectAll?: boolean;
  newDesign?: boolean;
}>;

export const MultipleSelect: React.FC<MyMultipleSelectProps> = ({
  options,
  label,
  isLoading = false,
  changeHandler = () => {},
  error = false,
  helperText = "",
  value = [],
  canSelectAll = false,
  newDesign,
}) => {
  const completeOptions = canSelectAll
    ? [options.length === value.length ? cancelChoice : chooseAll, ...options]
    : options;

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const data = (typeof value === "string" ? value.split(",") : value).map(
      (item: any) => options.find((t) => t.title === item)
    );

    changeHandler(data);
  };

  const valueForSelect = value.map((item) => item.title);

  if (isLoading) {
    return null;
  }

  const inputProps = newDesign
    ? {
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: "#1A2734",
              padding: "0 20px",
            },
          },
        },
        classes: {
          root: style.selectroot,
          icon: style.icon,
        },
      }
    : {
        classes: {
          root: style.selectroot,
          icon: style.icon,
        },
      };

  return (
    <FormControl fullWidth className={style.specializationForm}>
      {label && (
        <InputLabel shrink className={cn(error && style.error)}>
          {label}
        </InputLabel>
      )}
      <Select
        multiple
        fullWidth
        value={valueForSelect}
        onChange={handleChange}
        inputProps={inputProps}
        renderValue={(selected) => (
          <Box sx={boxSX}>
            {selected.map((value) => (
              <Chip className={style.chip} key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        error={error}
      >
        {completeOptions.map((name) => (
          <MenuItem
            classes={
              newDesign
                ? undefined
                : { root: style.menuitem, selected: style.selected }
            }
            key={name.id}
            value={name.title}
            sx={newDesign ? newMenuItemSx : undefined}
          >
            {name.title}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={style.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
