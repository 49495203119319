import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { httpRequestGet } from "../../../../services/api/api";
import { config } from "../../../../../app.cofig";
import { ProjectMilestoneType } from "../types/milestoneProjectType";

const ApiProjectStage = (projectId: number): Promise<Response> => {
  // получить вехи
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-stages?id=${projectId}`
  );
};

export const fetchProjectMilestone = createAsyncThunk<
  ProjectMilestoneType,
  { projectId: number; load?: boolean }
>("project/fetchProjectMilestone", async ({ projectId }, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProjectStage(projectId),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
