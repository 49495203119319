import { PageEditStateTypes } from "../../../types/stateTypes/postsStateTypes";

export const editPostInitialState: PageEditStateTypes = {
  id: 0,
  title: "",
  url: "",
  date_publication: "",
  content: "",
  photoUrl: "",
  tags: [],
};
