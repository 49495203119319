import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import styles from "./Executor.module.scss";

export const ExecutorEmpty = () => {
  return (
    <Card
      className={styles.executorCard}
      header={<Card.Header>Исполнитель</Card.Header>}
    >
      <span>Не назначен</span>
      <hr className={styles.yellowLine} />
    </Card>
  );
};

export default ExecutorEmpty;
