import { useState, useMemo } from "react";

const useHover = () => {
  const [hovered, setHovered] = useState<boolean>(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseEnter() {
        setHovered(true);
      },
      onMouseLeave() {
        setHovered(false);
      },
    }),
    []
  );

  return [hovered, eventHandlers, setHovered] as any;
};

export default useHover;
