import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessToProcess } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessToProcesses = createAsyncThunk<
  Record<string, string>,
  { processId: number; projectId: number; all: number | string }
>(
  "projectProcess/fetchProjectProcessToProcesses",
  async ({ processId, projectId, all }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessToProcess(processId, projectId, all),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
