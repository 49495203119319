import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import {
  Middleware,
  MiddlewareAPI,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { rootReducer } from "./rootReducer";
import { pushErrorMessage } from "./feature/errorTrace";

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      dispatch(pushErrorMessage(action.payload));
    }

    return next(action);
  };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }).concat(rootApi.middleware, rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
