import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { chatInitialState } from "./chatInitialState";
import {
  chatMessageType,
  chatRoomType,
  chatType,
} from "../../../types/stateTypes/chatType";
import { StateType } from "../../initialState";
import C from "./constants";
import { deleteAllTags } from "../../../components/features/Chat/lib/chat";
import { config } from "../../../app.cofig";
import userAvatarMock from "../../../media/images/ChatDarkGroup.svg";

export const createAvatarRoom = (item: chatRoomType) => {
  const { avatarUrl, isGroup }: chatRoomType = item;
  if (isGroup) return;
  if (avatarUrl) return `${config.localDomain}${item.avatarUrl}`;
  return userAvatarMock;
};

export const createAvatar = (url: string) => {
  return url ? `${config.localDomain}${url}` : userAvatarMock;
};

const mapTreeRooms =
  (data: chatRoomType[]) =>
  (
    callback: (item: chatRoomType, isGroup: boolean) => chatRoomType
  ): chatRoomType[] => {
    // @ts-ignore
    return data!.map((item) => {
      if (item.isGroup) {
        return {
          ...callback(item, item.isGroup),
          rooms: mapTreeRooms(item.rooms || [])(callback),
        };
      }
      return {
        ...callback(item, item.isGroup),
      };
    });
  };

export const chatReducer = createReducer(chatInitialState, (builder) => {
  /*
    Инициализация  
   */
  builder.addCase(C.CHAT_INIT, (state) => {
    return {
      ...state,
    };
  });

  /*
    Установка обьекта openRooms, на этот массив ориентируется UI когда создает комнанты  
    В случе если id группы есть в обьекте, то она становится открытой
   */
  builder.addCase(
    C.CHAT_ADD_OPENED_ROOM,
    (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        openRooms: [...state.openRooms, ...action.payload],
      };
    }
  );

  /*
    Удаление группы комнат из открытых
  */
  builder.addCase(
    C.CHAT_REMOVE_OPENED_ROOM,
    (state, action: PayloadAction<string>) => {
      return {
        ...state,
        openRooms: state.openRooms.filter((item) => item !== action.payload),
      };
    }
  );

  /*
    Устанавливает комнаты чата
    В основном используется при событии вебсотетка - getContacts
   */
  builder.addCase(
    C.CHAT_SET_CONTACTS,
    (state, action: PayloadAction<chatRoomType[]>) => {
      const roomsMap = mapTreeRooms(action.payload);
      const result = roomsMap((item, isGroup) => {
        if (isGroup) {
          let count = 0;
          // @ts-ignore
          mapTreeRooms(item.rooms!)((item) => {
            // @ts-ignore
            count += item.countNew || 0;
          });
          return {
            ...item,
            isOpen: state.openRooms.includes(item.groupId!),
            countNew: count || 0,
          };
        }
        return {
          ...item,
          avatarUrl: createAvatarRoom(item),
        };
      });

      return {
        ...state,
        rooms: result,
      };
    }
  );

  /*
    Добавляет список сообщений для выбраной комнаты
    Используется в событии вебсокета - getHistory
   */
  builder.addCase(
    C.CHAT_SET_HISTORY,
    (state, action: PayloadAction<chatType["messages"]>) => ({
      ...state,
      messages: action.payload.map((item) => ({
        ...item,
        authorAvatarUrl: createAvatar(item.authorAvatarUrl),
      })),
    })
  );

  /*
    Устанавливает данные по выбраной комнате
   */
  builder.addCase(
    C.CHAT_SET_ROOM_DATA,
    (state, action: PayloadAction<chatType["userData"]>) => ({
      ...state,
      userData: action.payload,
    })
  );

  /*
    Добавляет сообщение
    Используется, кокгда пользователь отправляет сообщение
   */
  builder.addCase(
    C.CHAT_ADD_MESSAGE,
    (state, action: PayloadAction<chatMessageType>) => ({
      ...state,
      messages: [
        ...state.messages,
        {
          ...action.payload,
          authorAvatarUrl: createAvatar(action.payload.authorAvatarUrl),
        },
      ],
    })
  );

  /*
    Перебирает массив сообщений и устанавливаетс им статус просмотренный
  */
  builder.addCase(
    C.CHAT_SET_OVERLOOK_MESSAGES,
    (state, action: PayloadAction<number[]>) => ({
      ...state,
      messages: [
        ...state.messages.map((item) =>
          action.payload.includes(item.id) ? { ...item, msgStatus: 1 } : item
        ),
      ],
    })
  );

  /*
    Установка флага нахождения пользователя в комате
  */
  builder.addCase(C.CHAT_USER_ENTER_ROOM, (state) => ({
    ...state,
    userAction: {
      ...state.userAction,
      inRoom: true,
    },
  }));

  /*
    Снятия флага нахождения пользователя в комнате
  */
  builder.addCase(C.CHAT_USER_EXIT_ROOM, (state) => ({
    ...state,
    userAction: {
      ...state.userAction,
      inRoom: false,
    },
  }));

  /*
    Устанавливает данные по выбраной комнате
  */
  builder.addCase(
    C.CHAT_SET_ACTIVE_ROOM_DATA,
    (
      state,
      action: PayloadAction<{
        id: number;
        userName: string;
        avatarUrl: string;
      }>
    ) => ({
      ...state,
      chatActiveRoom: {
        ...action.payload,
      },
    })
  );

  /*
    Событие обработки выхода пользователя с команты
   */
  builder.addCase(C.CHAT_USER_LEAVE, (state) => ({
    ...state,
    contacts: state.rooms.map((item) => ({
      ...item,
      isActive: false,
    })),
  }));

  /*
    Обработка события фильтрации комнат
  */
  builder.addCase(
    C.CHAT_FILTER_CONTACTS,
    (state, action: PayloadAction<string>) => {
      const fn: (str: string, data: chatRoomType[]) => chatRoomType[] = (
        str,
        data
      ) => {
        const result = data.reduce((acc, item): chatRoomType[] => {
          if (item.isGroup) {
            return [
              ...acc,
              {
                ...item,
                rooms: fn(str, item.rooms!),
              },
            ];
          }
          if (
            deleteAllTags(item.name).toLowerCase().includes(str.toLowerCase())
          ) {
            return [...acc, { ...item }];
          }
          return [...acc];
        }, [] as chatRoomType[]);

        return result.filter(
          (item) => !item.isGroup || (item.rooms && item.rooms!.length > 0)
        );
      };

      return {
        ...state,
        filteredContacts: {
          value: action.payload,
          items: Array.from(fn(action.payload, state.rooms as chatRoomType[])),
        },
      };
    }
  );

  /*
    Снятие фильтрации комнат
  */
  builder.addCase(C.CHAT_CLEAR_FILTER_CONTACTS, (state) => ({
    ...state,
    filteredContacts: {
      ...state.filteredContacts,
      value: "",
    },
  }));
  builder.addCase(C.CHAT_UPDATE_ROOMS_LIST, (state) => ({
    ...state,
    updDate: new Date(),
  }));
});

export const chatEnterRoom = createAction(C.CHAT_USER_ENTER_ROOM, () => ({
  payload: true,
}));

export const chatClearFilterRooms = createAction(C.CHAT_CLEAR_FILTER_CONTACTS);

export const chatSetActiveRoomData = createAction(
  C.CHAT_SET_ACTIVE_ROOM_DATA,
  (payload: { id: string; userName: string; avatarUrl: string }) => ({
    payload,
  })
);

export const chatFilterContacts = createAction(
  C.CHAT_FILTER_CONTACTS,
  (payload: string) => ({
    payload,
  })
);

export const chatSetContacts = createAction(
  C.CHAT_SET_CONTACTS,
  (payload: chatRoomType[]) => ({ payload })
);

export const chatSetHistory = createAction(
  C.CHAT_SET_HISTORY,
  (payload: chatType["messages"]) => ({ payload })
);

export const chatSetOverlookMessages = createAction(
  C.CHAT_SET_OVERLOOK_MESSAGES,
  (payload: number[]) => ({
    payload,
  })
);

export const chatAddOpenedRoom = createAction(
  C.CHAT_ADD_OPENED_ROOM,
  (payload: string[]) => ({
    payload,
  })
);

export const chatRemoveOpenedRoom = createAction(
  C.CHAT_REMOVE_OPENED_ROOM,
  (payload: string) => ({
    payload,
  })
);

export const chatAddMessage = createAction(
  C.CHAT_ADD_MESSAGE,
  (payload: chatMessageType) => ({ payload })
);

export const selectorChatRooms = (state: StateType) => state.chat.rooms;

export const selectorChatMessages = (state: StateType) => state.chat.messages;

export const selectorChatDateUpdate = (state: StateType) => state.chat.updDate;

export const selectorChatActiveRoomData = (state: StateType) =>
  state.chat.chatActiveRoom;

export const selectorUserInRoom = (state: StateType) =>
  state.chat.userAction.inRoom;

export const selectorFilteredValue = (state: StateType) =>
  state.chat.filteredContacts.value;

export const selectorFilteredContacts = (state: StateType) =>
  state.chat.filteredContacts.items;

export const selectorOpenRooms = (state: StateType) => state.chat.openRooms;
