import { ProjectContractsStateTypes } from "../../../../types/stateTypes/projectContractsStateTypes";

export const projectContractsInitialState: ProjectContractsStateTypes = {
  data: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
