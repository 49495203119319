import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import ExecutorCard from "./ExecutorCard";
import style from "./style.module.scss";
import { getExecutors } from "../../../../../app/feature/ProjectView/GroupApplications/thunks";
import {
  TExecutor,
  getGroupApplications,
  setOpen,
} from "../../../../../app/feature/ProjectView/GroupApplications";
import Comparison from "./Comparison";
import { SkeletonDocuments } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";

interface IGroupApplicationsProps {
  setCurrentTabMemo: () => void;
}

const GroupApplications: FC<IGroupApplicationsProps> = ({
  setCurrentTabMemo,
}) => {
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const projectId = Number(id);

  const { data, pending } = useAppSelector(getGroupApplications);
  const { executors, groupNames, openProcesses, comparisonMode } = data;

  const { project } = useAppSelector(getProjectViewData);

  if (project && !project?.tabs.applications.show) {
    setCurrentTabMemo();
  }

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectView(projectId));
      dispatch(getExecutors(projectId));
    }
  }, [dispatch, projectId]);

  const handleToggle = (id: string) => {
    dispatch(setOpen(id));
  };

  if (!projectId) {
    return null;
  }

  if (pending.executors) {
    return <SkeletonDocuments />;
  }

  if (comparisonMode) {
    return <Comparison id={projectId} />;
  }

  return (
    <div className={cn(style.wrapper, style.marginBottom)}>
      {Object.entries(executors).map(([groupId, executorsList]: any) => {
        const isOpen = openProcesses.includes(groupId);
        const groupName = `${groupNames[groupId]} (${executorsList.length})`;

        return (
          <>
            <Accordion
              toogle={isOpen}
              onToggle={() => handleToggle(groupId)}
              text={groupName}
            />
            {isOpen && (
              <div className={style.grid}>
                {executorsList.map((executor: TExecutor) => (
                  <ExecutorCard groupId={groupId} executor={executor} />
                ))}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default GroupApplications;
