import React from "react";
import style from "./style.module.scss";
import DropDownList from "../../../newUI/DropDownList/DropDownList";
import EmployeeCard from "../../../newUI/EmployeeCard/EmployeeCard";

type StaffProps = {
  data: any;
  roles: any;
  access: any;
  isMobile: boolean;
};

const SUPERVISOR_ID = 3;

const orderedList = [
  "Руководитель организации",
  "Руководитель группы",
  "Сотрудник предприятия",
  "Бухгалтер",
  "Экономист",
];

export const useStaff = (data: any) => {
  const staff: { [key: string]: any } = {};

  // в фирме больше одного руководителя
  const multipleSupervisors =
    data.filter((item: any) => item.role_id === SUPERVISOR_ID).length > 1;

  // все специальности сотрудников
  const formedData: string[] = Array.from(
    new Set(data.map((item: any) => item.role))
  );

  // разбиение сотрудников по специальностям;
  formedData.forEach((role) => {
    staff[role as string] = data.filter((item: any) => item.role === role);
  });

  // отсортированные специальности
  const fullOrderedList = orderedList
    .filter((role) => formedData.includes(role))
    .concat(formedData.filter((role) => !orderedList.includes(role)));

  return { fullOrderedList, staff, multipleSupervisors } as const;
};

const Staff: React.FC<StaffProps> = ({ data, roles, access, isMobile }) => {
  const { fullOrderedList, staff, multipleSupervisors } = useStaff(data);

  return (
    <div className={style.staffWrapper}>
      {fullOrderedList.map((key: string) => {
        if (!staff[key]) {
          return null;
        }

        return (
          <DropDownList key={key} title={`${key} (${staff[key].length})`}>
            <div className={style.grid}>
              {staff[key].map((item: any) => (
                <EmployeeCard
                  isMobile={isMobile}
                  multipleSupervisors={multipleSupervisors}
                  access={access}
                  roles={roles}
                  key={item.id}
                  data={item}
                />
              ))}
            </div>
          </DropDownList>
        );
      })}
    </div>
  );
};

export default Staff;
