import { ReactNode } from "react";
import styles from "./Application.module.scss";

type Props = {
  children: ReactNode;
};

export const ApplicationFooter = ({ children }: Props) => {
  return <div className={styles.card__footer}>{children}</div>;
};
