import moment from "moment";
import * as KanbanType from "../../types";

export function formNewTaskPayload(payload: KanbanType.FreeTaskFields) {
  return {
    name: "",
    content: payload.content,
    // если есть объект, то отправляем его id, иначе - id проекта
    projectId: payload.object?.id
      ? Number(payload.object!.id)
      : Number(payload.project!.id),
    partGroupId: Number(payload.partGroup!.id),
    executorId: Number(payload.executor!.id),
    dateDeadline: moment(payload.dateDeadline).format("DD.MM.YYYY"),
    importance: payload.importance,
  };
}
