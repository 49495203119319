import { pushErrorMessage } from "../../../../../../../app/feature/errorTrace";
import {
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "../../../../../../entities/kanban";
import { checkGroup } from "../../helpers/checkGroup";
import { ColumnShift, Errors } from "./useKanbanDnD";

export const isDoneToFulfilled = ({
  dispatch,
  sourceColumnId,
  destinationColumnId,
  sourceRowId,
  destinationRowId,
  rowTasks,
  result,
  groupBy,
}: ColumnShift) => {
  // из "Проверка" в "Выполнено"
  if (
    sourceColumnId === KanbanStatuses[KanbanColumns.IS_DONE] &&
    destinationColumnId === KanbanStatuses[KanbanColumns.DONE]
  ) {
    // получаем задачи из "Проверка" и "Выполнено"
    const sourceColumnTasks = [
      ...rowTasks[sourceRowId].columns[sourceColumnId],
    ];
    const destinationColumnTasks = [
      ...rowTasks[destinationRowId].columns[destinationColumnId],
    ];

    // удаляем задачу из колонки-истока "Проверка", добавляем в колонку-сток "Выполнено"
    const [reorderedTask] = sourceColumnTasks.splice(result.source.index, 1);
    destinationColumnTasks.splice(result.destination!.index, 0, reorderedTask);

    const taskRowId = checkGroup(reorderedTask, destinationRowId, groupBy);

    if (taskRowId !== destinationRowId) {
      dispatch(pushErrorMessage(Errors.NO_SUCH_DIRECTION));
      return;
    }

    // обновляем колонки для группы
    const updatedTasks = {
      ...rowTasks[sourceRowId].columns,
      [sourceColumnId]: sourceColumnTasks,
      [destinationColumnId]: destinationColumnTasks,
    };

    // открываем модальное окно на kanban/complete
    dispatch(
      KanbanModel.setViewTask({
        isOpen: true,
        taskId: reorderedTask.id,
        header: reorderedTask.header,
        isCompletion: true,
      })
    );

    // сохраняем кэш
    dispatch(
      KanbanModel.setCache({
        sourceRowId,
        sourceColumnId,
        destinationRowId,
        destinationColumnId,
        tasks: updatedTasks,
      })
    );
  }
};
