import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import classes from "./style.module.scss";

type TreatyProps = {
  secure: boolean;
  withDocs: boolean;
  dateStart: string;
  dateEnd: string;
};
export const Treaty: React.FC<TreatyProps> = ({
  secure,
  withDocs,
  dateStart,
  dateEnd,
}) => {
  return (
    <div>
      <div className={classes.title}>
        <h1 className={classes.header1}>Договор</h1>
        <h1 className={classNames(classes.header, classes.header_right)}>
          Сроки выполнения
        </h1>
      </div>
      <div className={classes.icons}>
        <div className={classes.icon}>
          {withDocs ? (
            <CheckIcon color="inherit" fontSize="inherit" />
          ) : (
            <div className={classes.icon_disabled}>
              {" "}
              <CloseIcon color="inherit" fontSize="inherit" />
            </div>
          )}
          <p className={classes.icon__text}>Заключение договора</p>
        </div>
        <p className={classes.date}>{dateStart}</p>
      </div>
      <div className={classes.icons}>
        <div className={classes.icon}>
          {secure ? (
            <CheckIcon color="inherit" fontSize="inherit" />
          ) : (
            <div className={classes.icon_disabled}>
              {" "}
              <CloseIcon color="inherit" fontSize="inherit" />
            </div>
          )}
          <p className={classes.icon__text}>Безопасная сделка</p>
        </div>
        <p className={classes.date}>{dateEnd}</p>
      </div>
    </div>
  );
};

export default Treaty;
