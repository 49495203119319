import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const UserPolicy = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <>
      <div className={classNames(style.condition, style[theme])}>
        <p>
          <strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
        </p>
        <p>
          1.1. Настоящая Политика конфиденциальности персональных данных (далее
          – Политика конфиденциальности) действует в отношении всей информации,
          которую Платформа может получить о Пользователе во время использования
          последним Платформы и его сервисов.
        </p>
        <p>
          1.2. Использование Пользователем Платформы означает согласие с
          настоящей Политикой конфиденциальности и условиями обработки
          персональных данных Пользователя.
        </p>
        <p>
          1.3. В случае несогласия с условиями Политики конфиденциальности
          Пользователь обязан прекратить использование Платформы.
        </p>
        <p>
          1.4. Настоящая Политика конфиденциальности непосредственно применяется
          только к Платформе. Платформа не контролирует и не несет
          ответственность за сайты третьих лиц, на которые Пользователь может
          перейти по ссылкам, доступным на Платформе.{" "}
        </p>
        <p>
          1.5. Администрация Платформы не проверяет достоверность персональных
          данных, предоставляемых Пользователем Платформы в части соответствия
          лица, размещающего данные сведения, указанным в персональных данных.
          Администрация Платформы обеспечивает верификацию пользователя по
          предоставленным Пользователем данным.{" "}
        </p>
        <p>
          <strong>2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong>
        </p>
        <p>
          2.1. Настоящая Политика конфиденциальности устанавливает обязательства
          Администрации Платформы по неразглашению и обеспечению режима защиты
          конфиденциальности персональных данных, которые Пользователь
          предоставляет по запросу Администрации Платформы при регистрации на
          Платформе в целях доступа к заказу и/или предложению услуг.{" "}
        </p>
        <p>
          2.2. Персональные данные, разрешённые к обработке в рамках настоящей
          Политики конфиденциальности, предоставляются Пользователем путём
          заполнения регистрационной формы на Платформе и включают в себя
          следующую информацию:
        </p>
        <p>2.2.1. фамилию, имя, отчество Пользователя;</p>
        <p>2.2.2. контактный телефон Пользователя;</p>
        <p>2.2.3. адрес электронной почты (e-mail);</p>
        <p>2.2.4. адрес регистрации (местонахождения);</p>
        <p>
          2.3. Персональные данные, разрешённые к обработке в рамках настоящей
          Политики конфиденциальности, предоставляются Пользователем путём
          заполнения профиля пользователя, формы на Платформе и включают в себя
          следующую информацию:{" "}
        </p>
        <p>2.3.1. фамилию, имя, отчество Пользователя;</p>
        <p>2.3.2. контактный телефон Пользователя;</p>
        <p>2.3.3. адрес электронной почты (e-mail);</p>
        <p>2.3.4. адрес регистрации (местонахождения);</p>
        <p>2.3.5. дата рождения;</p>
        <p>2.3.6. данные о расчетном счете Пользователя.</p>
        <p>
          2.4. Платформа защищает Данные, которые автоматически передаются в
          процессе просмотра страниц, на которых установлен статистический
          скрипт системы («пиксель»):
        </p>
        <p>• IP адрес;</p>
        <p>• информация из cookies;</p>
        <p>
          • информация о браузере (или иной программе, которая осуществляет
          доступ к показу рекламы);
        </p>
        <p>• время доступа;</p>
        <p>• адрес страницы, на которой расположен рекламный блок;</p>
        <p>• реферер (адрес предыдущей страницы);</p>
        <p>• геолокации;</p>
        <p>
          2.4.1. Отключение cookies может повлечь невозможность доступа к частям
          Платформы, требующим авторизации.
        </p>
        <p>
          2.4.2. Платформа осуществляет сбор статистики об IP-адресах своих
          посетителей. Данная информация используется с целью выявления и
          решения технических проблем, для контроля законности проводимых
          финансовых платежей.
        </p>
        <p>
          2.5. Любая иная персональная информация не оговоренная выше (история
          заказов, используемые браузеры и операционные системы и т.д.) подлежит
          надежному хранению и нераспространению, за исключением случаев,
          предусмотренных в п.п. 4.2. и 4.3. настоящей Политики
          конфиденциальности.
        </p>
        <p>
          <strong>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong>
        </p>
        <p>
          3.1. Персональные данные Пользователя Администрация Платформы может
          использовать в целях:
        </p>
        <p>
          3.1.1. Регистрации и Идентификации Пользователя, зарегистрированного
          на Платформе, для оформления заказа и (или) заключения договора на
          оказание услуг дистанционным способом;
        </p>
        <p>
          3.1.2. Предоставления Пользователю доступа к персонализированным
          ресурсам Платформы;{" "}
        </p>
        <p>
          3.1.3. Установления с Пользователем обратной связи, включая
          направление уведомлений, запросов, касающихся использования Платформы,
          оказания услуг, обработки запросов и заявок от Пользователя;
        </p>
        <p>
          3.1.4. Определения места нахождения Пользователя для обеспечения
          безопасности, предотвращения мошенничества;
        </p>
        <p>
          3.1.5. Подтверждения достоверности и полноты персональных данных,
          предоставленных Пользователем;
        </p>
        <p>
          3.1.6. Создания учетной записи в целях доступа к заказу и/или
          предложению услуг, если Пользователь дал согласие на создание учетной
          записи путем прохождения процедуры регистрации на Платформе;
        </p>
        <p>
          3.1.7. Предоставления Пользователю эффективной клиентской и
          технической поддержки при возникновении проблем, связанных с
          использованием Платформы.
        </p>
        <p>
          3.1.8. Предоставления Пользователю обновлений ассортимента услуг,
          специальных предложений, информации о ценах, новостной рассылки и иных
          сведений от имени собственника Платформы или от имени его партнеров.
        </p>
        <p>
          3.1.9. Осуществления рекламной деятельности с согласия Пользователя.
        </p>
        <p>
          3.1.10. Предоставления доступа Пользователю на сайты или сервисы
          партнеров собственника Платформы с целью получения продуктов,
          обновлений и услуг.
        </p>
        <p>
          <strong>4. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong>
        </p>
        <p>
          4.1. Обработка персональных данных Пользователя осуществляется без
          ограничения срока, любым законным способом, в том числе в
          информационных системах персональных данных с использованием средств
          автоматизации или без использования таких средств.
        </p>
        <p>
          4.2. Пользователь соглашается с тем, что Администрация Платформы в
          целях соблюдения порядка взаимодействия с Пользователем и органами
          Государственной Власти вправе передавать персональные данные некоторым
          третьим лицам, в частности, курьерским службам, организациями почтовой
          связи, операторам связи, исключительно в рамках содействия достижению
          цели регистрации Пользователя на Платформе, обеспечения взаимодействия
          между сторонами сделок, заключенных с использованием услуг Платформы.
        </p>
        <p>
          4.3. Персональные данные Пользователя могут быть переданы
          уполномоченным органам государственной власти Российской Федерации
          только по основаниям и в порядке, установленным законодательством
          Российской Федерации.
        </p>
        <p>
          4.4. При утрате или разглашении персональных данных Администрация
          Платформы информирует Пользователя об утрате или разглашении
          персональных данных.
        </p>
        <p>
          4.5. Администрация Платформы принимает необходимые организационные и
          технические меры для защиты персональной информации Пользователя от
          неправомерного или случайного доступа, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </p>
        <p>
          4.6. Администрация Платформы совместно с Пользователем принимает все
          необходимые меры по предотвращению убытков или иных отрицательных
          последствий, вызванных утратой или разглашением персональных данных
          Пользователя.{" "}
        </p>
        <p>
          <strong>5. ОБЯЗАТЕЛЬСТВА СТОРОН</strong>
        </p>
        <p>5.1. Пользователь обязан:</p>
        <p>
          5.1.1. Предоставить достоверную информацию о персональных данных,
          необходимую для пользования Платформой;
        </p>
        <p>
          5.1.2. Обновить, дополнить предоставленную информацию о персональных
          данных в случае изменения данной информации.
        </p>
        <p>5.2. Администрация Платформы обязана:</p>
        <p>
          5.2.1. Использовать полученную информацию исключительно для целей,
          указанных в п. 3 настоящей Политики конфиденциальности.
        </p>
        <p>
          5.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
          разглашать без предварительного разрешения Пользователя путем
          направления Администрации Платформы письменного разрешения в
          электронной форме, а также не осуществлять продажу, обмен,
          опубликование, либо разглашение иными возможными способами переданных
          персональных данных Пользователя, за исключением п.п. 4.2. и 4.3.
          настоящей Политики Конфиденциальности.
        </p>
        <p>
          5.2.3. Принимать меры предосторожности для защиты конфиденциальности
          персональных данных Пользователя согласно порядку, обычно
          используемого для защиты такого рода информации в существующем деловом
          обороте.
        </p>
        <p>
          5.2.4. Осуществить блокирование персональных данных, относящихся к
          соответствующему Пользователю, с момента обращения или запроса
          Пользователя, или его законного представителя, либо уполномоченного
          органа по защите прав субъектов персональных данных на период
          проверки, в случае выявления недостоверных персональных данных или
          неправомерных действий.
        </p>
        <p>
          <strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
        </p>
        <p>
          6.1. Администрация Платформы, не исполнившая свои обязательства, несёт
          ответственность за убытки, понесённые Пользователем в связи с
          неправомерным использованием персональных данных, в соответствии с
          законодательством Российской Федерации, за исключением случаев,
          предусмотренных п.п. 4.2., 4.3. и 6.2. настоящей Политики
          Конфиденциальности.
        </p>
        <p>
          6.2. В случае утраты или разглашения Конфиденциальной информации
          Администрация Платформы не несёт ответственность, если данная
          конфиденциальная информация:
        </p>
        <p>6.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
        <p>
          6.2.2. Была получена от третьей стороны до момента её получения
          Администрацией Платформы.
        </p>
        <p>6.2.3. Была разглашена с согласия Пользователя.</p>
        <p>
          6.2.4. Был разглашена сторонними сайтами и сервисами, ссылки на
          использование которых присутствуют на Платформе, в случае
          использования таких ссылок и сервисов Пользователем.
        </p>
        <p>
          6.3. Пользователь самостоятельно несет ответственность за возможные
          последствия в случае представления недостоверных и/или неполных
          персональных данных.
        </p>
        <p>
          <strong>7. РАЗРЕШЕНИЕ СПОРОВ</strong>
        </p>
        <p>
          7.1. До обращения в суд с иском по спорам, возникающим из отношений
          между Пользователем Платформы и Администрацией Платформы, обязательным
          является предъявление претензии (письменного предложения о
          добровольном урегулировании спора), осуществленного в электронной
          форме.
        </p>
        <p>
          7.2. Получатель претензии в течение 30 календарных дней со дня
          получения претензии, письменно уведомляет заявителя претензии о
          результатах рассмотрения претензии в электронной форме путем
          использования соответствующих страниц Платформы, либо путем
          направления на электронную почту получателя.
        </p>
        <p>
          7.3. При недостижении соглашения, спор будет передан на рассмотрение в
          судебный орган по месту нахождения собственника Платформы в
          соответствии с действующим законодательством Российской Федерации.
        </p>
        <p>
          7.4. К настоящей Политике конфиденциальности и отношениям между
          Пользователем и Администрацией Платформы применяется действующее
          законодательство Российской Федерации.
        </p>
        <p>
          <strong>8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
        </p>
        <p>
          8.1. Администрация Платформы вправе вносить изменения в настоящую
          Политику конфиденциальности без согласия Пользователя.
        </p>
        <p>
          8.2. Новая Политика конфиденциальности вступает в силу с момента ее
          размещения на Платформе, если иное не предусмотрено новой редакцией
          Политики конфиденциальности.
        </p>
        <p>
          8.3. Все предложения или вопросы по настоящей Политике
          конфиденциальности следует сообщать Администрации Платформы через
          форму обратной связи.
        </p>
        <p>
          8.4. Действующая Политика конфиденциальности размещена на странице по
          адресу:{" "}
          <a href={"/site/policy"}>{`${window.location.origin}/site/policy`}</a>
          .
        </p>
        <p>
          8.5. Присоединяясь к пользовательскому Соглашению, Пользователь
          выражает и подтверждает согласие на обработку, как это определено
          Федеральном законе № 152-ФЗ от 27.07.2006 года «О персональных данных»
          (далее - «Обработка»), Администратором всех персональных данных (в том
          числе фамилии, имени, отчества (при наличии), даты рождения, ИНН,
          СНИЛС, реквизитов документа, удостоверяющего личность, номера
          телефона, адреса электронной почты, адреса проживания, банковских
          реквизитов Пользователя/представителя Пользователя), передаваемых
          Администратору и/или его правопреемникам по основаниям,
          предусмотренным настоящим Соглашением, законом или сделкой, а также
          иных персональных данных Пользователя, содержащихся в информационных
          системах (далее – Персональные данные), а также на то, что обработка
          персональных данных Пользователя может быть поручена Администратором
          иному лицу на основании соответствующего договора между
          Администратором и таким лицом.
        </p>

        <p>
          Администратор обрабатывает персональные данные Пользователей любым из
          далее перечисленных способов, включая (но не ограничиваясь), сбор,
          запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передачу, обезличивание,
          блокирование, удаление, уничтожение персональных данных, а также
          объединение персональных данных, полученных Администратором от других
          операторов, как с использованием средств автоматизации (в том числе в
          информационно-телекоммуникационных системах и сетях), так и без
          использования таковых.
        </p>
        <p>
          Согласие Пользователя на обработку персональных данных действует
          бессрочно и может быть отозвано Пользователем частично или полностью,
          путем направления Пользователем Администратору соответствующего
          уведомления. При этом Пользователь подтверждает свою информированность
          о том, что полный или частичный отзыв согласия на обработку
          персональных данных может привести к невозможности пользования
          Сервисом данным Пользователем. В период действия сделок, заключенных и
          исполняемых с использованием сервисов Платформы отзыв согласия на
          хранение, уничтожение и обработку персональных данных не может быть
          принят Администрацией Платформы. В случае отзыва Пользователем
          настоящего согласия Администратор удаляет аккаунт Пользователя и
          обязан прекратить совершение действий по обработке Персональных
          данных, за исключением действий по обработке Персональных данных,
          обязанность по совершению которых возложена на Администратора как на
          оператора по обработке персональных данных законодательными и иными
          нормативными правовыми актами Российской Федерации, и иных случаев,
          когда Администратор, действующий как оператор по обработке
          персональных данных, вправе осуществлять обработку персональных данных
          без согласия субъекта персональных данных на основаниях,
          предусмотренных федеральными законами, в том числе в случаях, когда
          обработка персональных данных необходима для осуществления прав и
          законных интересов Администратора (оператора) или третьих лиц, либо
          для достижения общественно значимых целей при условии, что при этом не
          нарушаются права и свободы субъекта персональных данных.
        </p>
        <p>
          Согласие на обработку персональных данных является отозванным на
          следующий рабочий день после получения письменного уведомления об
          отзыве настоящего согласия Администратором.
        </p>
        <p>
          Присоединяясь к настоящему Соглашению, Пользователь выражает свое
          согласие с тем, что персональные данные Пользователя, содержащиеся в
          разделе «Профиль пользователя», доступны другим Пользователям для
          просмотра и/или использования в целях заключения Сделок.
        </p>
        <p>
          Присоединяясь к настоящему Соглашению, Пользователь и/или
          представитель Пользователя дает согласие Администратору на обработку
          персональных данных Пользователя / представителя Пользователя в целях
          получения от Администратора Сервиса с использованием любых средств
          связи, электронных и sms-рассылок, а также иных видов рассылок и
          уведомлений информации об услугах Сервиса и/или услугах Администратора
          Сервиса и/или услугах третьих лиц, в том числе не связанных с работой
          Сервиса, в том числе информации рекламного характера, планируемых
          и/или проводимых акциях, мероприятиях, скидках и специальных
          предложениях.
        </p>
      </div>
    </>
  );
};
