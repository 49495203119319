// Функции позволяющие поддерживать компоненты
import { curry } from "lodash/fp";
import classNames from "classnames";

// Функция для создания миксирующих функций.
const withClass = (mixClass: string, classComponent: string): string =>
  classNames(mixClass, classComponent);

export const withClassCurry = curry(withClass);

export const areArraysEqual = <T, R extends T[]>(
  initialArray1: R,
  initialArray2: R
) => {
  if (!Array.isArray(initialArray1) || !Array.isArray(initialArray2)) {
    return false;
  }

  const array1 = initialArray1.sort();
  const array2 = initialArray2.sort();

  return (
    array1.length === array2.length &&
    array1.every((item, index) => item === array2[index])
  );
};

// окончание для количества лет (русский язык)
export const getYearsCount = (count: number) => {
  if (count % 10 === 1) {
    return "год";
  }

  if (count % 10 > 1 && count % 10 < 5) {
    return "года";
  }

  return "лет";
};
