import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import styles from "./Checklist.module.scss";
import { ChecklistProjectData } from "../../../../../app/feature/ProjectView/CheckListReducer/thunks";
import {
  getProjectCheckList,
  setOpenNode,
} from "../../../../../app/feature/ProjectView/CheckListReducer/projectCheckList";
import { useAppSelector } from "../../../../../app/store";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { ChecklistCard } from "../../../../newUI/ChecklistCard/ChecklistCard";

type TreeCheckListProps = {
  data: ChecklistProjectData[];
};

function selectMargin(lvl: number) {
  switch (lvl) {
    case 1:
      return "15px";
    case 2:
      return "6px";
    case 3:
      return "4px";
    case 4:
      return "0px";
    default:
      return "0px";
  }
}

export const TreeCheckList = ({ data }: TreeCheckListProps) => {
  return (
    <div className={styles.sections_container}>
      {data.map((tree, index) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode key={index} node={tree} index={index} />
      ))}
    </div>
  );
};

type TreeNodeProps = {
  node: ChecklistProjectData;
  index: number;
};

const TreeNode = ({ node, index }: TreeNodeProps) => {
  const dispatch = useDispatch();
  const { openNode } = useAppSelector(getProjectCheckList);
  const marginAccordion = selectMargin(node.lvl);
  const hasChild = !!node.data;
  const firstChild = index === 0 && node.lvl === 1;

  return (
    <div className={classNames(!hasChild && styles.sections)}>
      {node?.name && (
        <Accordion
          text={node?.name}
          toogle={openNode[node?.num]}
          onToggle={() => dispatch(setOpenNode(node?.num))}
          numNode={node?.num}
          marginTop={firstChild ? "0px" : marginAccordion}
          colorNodeIcon={node.alert ? "#CE0000" : "#D5D6DE"}
          stopFolder={node.alert}
        />
      )}
      {!hasChild && <ChecklistCard data={node} />}
      {hasChild && openNode[node.num] && node.data && (
        <div>
          <TreeCheckList data={Object.values(node?.data)} />
        </div>
      )}
    </div>
  );
};
