import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessCreateTask } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessCreateTask = createAsyncThunk<
  boolean,
  { processId: number; formData: any }
>(
  "projectProcess/fetchProjectProcessCreateTask",
  async ({ processId, formData }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessCreateTask(processId, formData),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
