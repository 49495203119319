import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TabChildrenHeader } from "../../../TabsPanel";
import { RemovedExecutor } from "./RemovedExecutor";
import { useRemoved } from "../lib/hooks/useRemoved";
import PublishObjectModals from "../../../publishObject/modals";
import { useModalsFeedbackTask } from "../../../../../entities/publishObject/lib/hooks/useModalsFeedbacktask";

type Props = {
  processWorkId: number;
};

export const Removed = ({ processWorkId }: Props) => {
  const { removedExecutors, isExecutorsLoading } = useRemoved(processWorkId);
  const { feedbackTask, handleCloseFeedbackTaskRequest } =
    useModalsFeedbackTask();

  return (
    <>
      <Modal
        isActive={feedbackTask.isOpen}
        handleClose={handleCloseFeedbackTaskRequest}
      >
        <PublishObjectModals.FeedbackTaskForm
          taskId={feedbackTask.taskId!}
          header="Оценить исполнителя"
        />
      </Modal>
      <TabChildrenHeader title="Снятые исполнители" />
      <Column maxHeight="865px">
        {isExecutorsLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {removedExecutors!.map((removed) => (
              <RemovedExecutor key={removed.id} removed={removed} />
            ))}
          </ListMapper>
        )}
      </Column>
    </>
  );
};
