import React, { memo } from "react";
import { ObjectChild } from "./ObjectChild";
import cls from "./ObjectGraph.module.scss";

interface IObjectGraphProps {
  object: any;
}
export const ObjectGraph: React.FC<IObjectGraphProps> = memo(({ object }) => {
  const result = Object.entries(object.gantt);
  const nameObject = object.name;
  return (
    <>
      <span className={cls.object_name}>{nameObject}</span>
      <div className={cls.object}>
        {result &&
          result.map(([name, task]: any, index: number) => (
            <ObjectChild key={index} task={task} name={name} />
          ))}
      </div>
    </>
  );
});
