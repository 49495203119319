import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default (id: number, callback: () => void, index: number) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const subCard = params.get("subCard");

  useEffect(() => {
    if (!subCard) {
      return;
    }

    const cardLevels = subCard.split(",").map((card: string) => Number(card));

    if (cardLevels[index] === id) {
      callback();
    }
  }, []);
};
