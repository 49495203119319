import React, { ChangeEvent, KeyboardEvent } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { config } from "src/app.cofig";
import {
  getProjectInitialData,
  setIsSearched,
  setSearchValue,
} from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { fetchProjectViewInitialData } from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import { DownloadArrow, FolderArrowFill } from "src/components/newUI/SVG";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import { iconBtnSx } from "src/styles/restyle";

export type TTabValue = number;

type TProps = {
  activeTab: TTabValue;
};

export const HeaderActions = ({ activeTab }: TProps) => {
  const { project } = useSelector(getProjectViewData);
  const showMenu = project?.can.view;
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(681);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const { searchValue, isSearched } = useSelector(getProjectInitialData);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(event.target.value));
    dispatch(setIsSearched(false));
  };

  const searchClick = () => {
    if (params.id) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          searchValue,
          load: true,
          type: activeTab,
        })
      );
      dispatch(setIsSearched(true));
    }
  };

  const onKeyDownInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && params.id) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          searchValue,
          load: true,
          type: activeTab,
        })
      );
      dispatch(setIsSearched(true));
    }
  };

  const resetClick = () => {
    dispatch(setSearchValue(""));
    if (params.id) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          load: true,
          type: activeTab,
        })
      );
      dispatch(setIsSearched(false));
    }
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs md="auto">
        <SearchBar
          value={searchValue}
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
          searchClick={searchClick}
          resetClick={resetClick}
          isSearched={isSearched}
        />
      </Grid>
      {showMenu && (
        <Grid item xs="auto">
          {isMobile ? (
            <IconButton
              onClick={handleClick}
              sx={iconBtnSx}
              title={"Скачать отчет"}
            >
              <DownloadArrow />
            </IconButton>
          ) : (
            <Button
              id="initial-button"
              aria-controls={isOpen ? "initial-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
              onClick={handleClick}
              endIcon={<FolderArrowFill color={"#0B1016"} />}
            >
              Скачать отчет
            </Button>
          )}
          <Menu
            id="initial-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "initial-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={handleClose}
              component={"a"}
              href={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}`}
            >
              Общий
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={"a"}
              href={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}&executor=1`}
            >
              По заказчику
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};
