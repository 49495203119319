export const serverTimeFormat = "DD.MM.YYYY HH:mm:ss Z";
export const appOutputTimeFormat = "DD.MM.YYYY HH:mm:ss";

export const errorFormat = ({
  message,
  name,
}: {
  message: string;
  name: string;
}) => {
  const newName = name ? `Имя: ${name}` : "";
  const newMessage = message ? `Сообщение: ${message}` : "";
  return `${newName} ${newMessage}`;
};

export const errorCreater = (err: {
  status: string;
  message: string;
  name: string;
}) => `${err.status} ${err.message}`;

export const ucFirst = (str: string) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const cutOff = (str: string, value = 100) => {
  if (str.length > value) {
    return `${str.slice(0, value)}...`;
  }
  return str;
};

export const expertiseStatus = (expertise: { title: string }[]) =>
  expertise.length
    ? expertise.map((item, i) => {
        return `${i ? ", " : ""}${item.title}`;
      })
    : "Экспертиза не требуется";

export const removeProperty =
  (propertyToRemove: number | string) =>
  ({ [propertyToRemove]: _, ...restProperties }) =>
    restProperties;

export const replacingTags = (content: string) => {
  return content.replace(/(<([^>]+)>)/gi, "");
};
