import React, { forwardRef, useImperativeHandle, Ref } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import style from "../../pages/Project/View/Sections/style.module.scss";
import { VerticalThreeDots } from "../SVG";
import { StyledMenu } from "../Menu/Menu";
import { NewMenu } from "../NewMenu/NewMenu";

export enum ThemeDotButton {
  WHITE = "white",
  BLUE = "#0AA699",
}

export interface RefType {
  handleClose: () => void;
}

// Todo перенесен в src/FSD/shared/uiKit/v2/ThreeDotButton
export const ThreeDotButton: React.FC<any> = forwardRef(
  (
    {
      children,
      themeBtn = ThemeDotButton.WHITE,
      newDesign,
      classname,
      disabled,
    },
    ref: Ref<RefType>
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sx = {
      color: themeBtn,
      padding: 0,
      transition: "0.3s ease-in-out 0s!important",
      "&:hover": {
        transform: "scale(1.2)",
      },
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    return (
      <div className={classNames(style.btnFlex, classname)}>
        <IconButton
          sx={sx}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disabled={disabled}
        >
          <VerticalThreeDots />
        </IconButton>
        {newDesign ? (
          <NewMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {children}
          </NewMenu>
        ) : (
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {children}
          </StyledMenu>
        )}
      </div>
    );
  }
);
