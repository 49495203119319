import { TDocType, getDocTypes } from "./utils";
import { TFile } from "./useAttorneyFile";

const todayDate = new Date();

const ATTORNEY = {
  IN_WORK: "Действует",
  ABSCENT: "Отсутствует",
};

const useAttorneyForm = (
  isIp: boolean,
  watchDocType: TDocType,
  watchDocDateReg: Date | null,
  watchDocDateStart: Date | null,
  watchDocDateEnd: Date | null,
  attorneyFile: TFile
) => {
  const docTypes = getDocTypes(isIp);

  const isAttorneyFormOk =
    watchDocDateStart &&
    watchDocDateEnd &&
    watchDocDateReg &&
    attorneyFile &&
    todayDate <= watchDocDateEnd;

  const showAttorneyForm = watchDocType && watchDocType.id === 2;

  return { docTypes, showAttorneyForm, isAttorneyFormOk, ATTORNEY } as const;
};

export default useAttorneyForm;
