import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import ExecutorHave from "./ExecutorHave";
import ExecutorSearch from "./ExecutorSearch";
import ExecutorEmpty from "./ExecutorEmpty";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";
import ProcessWorkModals from "../../../modals";
import { Modal } from "../../../../../../shared/uiKit/v2/Modal";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Executor = ({ processWork }: Props) => {
  if (processWork.activeAccess?.user) {
    return <ExecutorHave processWork={processWork} />;
  }

  if (processWork.status.key === Statuses.WORK) {
    return <ExecutorSearch processWork={processWork} />;
  }

  return <ExecutorEmpty />;
};
