import React from "react";
import classNames from "classnames";
import cls from "./ScrollWrap.module.scss";

interface ScrollWrapProps {
  children: React.ReactNode;
  height?: string;
  maxHeight?: string;
  width?: string;
  classname?: string;
}
export const ScrollWrap = ({
  children,
  height,
  maxHeight,
  width = "auto",
  classname,
}: ScrollWrapProps) => {
  return (
    <div
      style={{ maxHeight, height, width }}
      className={classNames(cls.scroll, classname)}
    >
      {children}
    </div>
  );
};
