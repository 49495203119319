import { CircularProgress } from "@mui/material";
import style from "./style.module.scss";
import useNewUploader from "./useNewUploader";
import File from "./File";
import { NewUploaderProps, TFile } from "./types";
import Dropzone from "./Dropzone";
import { ScrollWrap } from "../../newUI/ScrollWrap/ScrollWrap";

/*

canEdit: Если true => отображаются кнопки удалить и/или галочка "Активный",

postUrl: URL для отправки файлов на сервер (/v1/...). Необходим в случае, если autoUpload = true.

autoUpload: Автоматическая отправка файлов

initialFiles: Начальный массив с файлами

uploadAsActive: Ставит галочку "Активный" по умолчанию

getDataCallback: Асинхронная функция, в которой:
  i: Выполняется запрос
  ii: Происходит извлечение массива с файлами из данного запроса (имеет тот же тип данных, что и initialFiles)

*/

const NewUploader = ({
  canEdit = true,
  postUrl = "",
  autoUpload = false,
  initialFiles = [],
  uploadAsActive = false,
  getDataCallback,
  setFiles,
  error = false,
  helperText = "",
  handleUpdate = () => {},
}: NewUploaderProps) => {
  const {
    isLoading,
    handleUpload,
    handleDelete,
    handleChangeActive,
    files,
    progresses,
  } = useNewUploader({
    handleUpdate,
    initialFiles,
    autoUpload,
    postUrl,
    getDataCallback,
    uploadAsActive,
    setFilesCallback: setFiles,
  });

  const FilesError = () => (
    <p className={style.filesError}>{String(helperText ?? "")}</p>
  );

  const NoFiles = () => <div className={style.centered}>Файлы отсутствуют</div>;

  const Preloader = () => (
    <div className={style.centered}>
      <CircularProgress sx={{ color: "#30ab69" }} />
    </div>
  );

  return (
    <div className={style.uploader}>
      <Dropzone handleUpload={handleUpload} />
      {isLoading ? (
        <Preloader />
      ) : files.length ? (
        <ScrollWrap maxHeight="350px">
          <div className={style.filesList}>
            {files.map((file: TFile) => (
              <File
                key={file.id}
                canEdit={canEdit}
                handleChangeActive={handleChangeActive}
                handleDelete={handleDelete}
                progresses={progresses}
                {...file}
              />
            ))}
          </div>
        </ScrollWrap>
      ) : (
        <NoFiles />
      )}
      {error && <FilesError />}
    </div>
  );
};

export default NewUploader;
