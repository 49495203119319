import { FC, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { IconButton, TextField } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import style from "./style.module.scss";
import { ProcessModalProps } from "./CreateProcessModal";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { useAppSelector } from "../../../../../../app/store";
import {
  TFirmMilestone,
  TStage,
  getMilestone,
  setStages,
} from "../../../../../../app/feature/milestone";
import {
  addMilestone,
  changeActiveMilestone,
  deleteMilestone,
  dragNDrop,
} from "../../../../../../app/feature/milestone/thunks";

const schema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("Необходимо заполнить «Наименование вехи»"),
});

type TFormValues = {
  name: string;
};

export type EditingProcessModalProps = ProcessModalProps & {
  processTitle: string;
  processId: number;
};

const EditingProcessModal: FC<EditingProcessModalProps> = ({
  processTitle,
  bfType,
  processId,
}) => {
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState<number>(0);

  const { data, pending } = useAppSelector(getMilestone);

  const stages =
    data.firmMilestones[bfType]?.find(
      (process: TFirmMilestone) => process.id === processId
    )?.stages ?? [];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: { name: "" },
    resolver: yupResolver(schema),
  });

  const handleAddMilestone = (data: TFormValues) => {
    const { name } = data;
    dispatch(addMilestone({ bfType, processId, name }));
  };

  const handleChangeActiveProcess = (stage: TStage) => {
    const { active, id } = stage;

    setSelectedId(id);
    dispatch(changeActiveMilestone({ id, bfType, active: !active, processId }));
  };

  const handleRemoveProcess = (id: number) => {
    if (!window.confirm("Удалить данный этап?")) {
      return;
    }

    dispatch(deleteMilestone({ processId, id, bfType }));
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    let items = [...stages];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const nums = items
      .map((item: TStage) => item.num)
      .sort((a: number, b: number) => a - b);

    items = items.map((item: TStage, i: number) => ({ ...item, num: nums[i] }));

    const requestItems = items.map(({ id, num }: TStage) => ({ id, num }));

    dispatch(setStages({ items, bfType, processId }));
    dispatch(dragNDrop({ items: requestItems, bfType, processId }));
  };

  return (
    <div className={style.createProcess}>
      <h2>Добавление этапа в категорию «{processTitle}»</h2>
      <form
        onSubmit={handleSubmit(handleAddMilestone)}
        className={style.controls}
      >
        <TextField
          {...register("name")}
          {...textFieldSX}
          label="Наименование вехи"
          className={style.objectType}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
        <CustomButton
          background={palette.blue}
          className={style.addButton}
          disabled={pending.addProcess}
          width={150}
          type="submit"
        >
          Добавить
        </CustomButton>
      </form>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <div
              className={style.stagesList}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {stages.map((stage: TStage, index: number) => {
                return (
                  <Draggable
                    key={stage.id}
                    draggableId={String(stage.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={style.process}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CustomCheckbox
                          onChange={() => handleChangeActiveProcess(stage)}
                          checked={stage.active}
                          editing
                          disabled={
                            pending.changeActive && stage.id === selectedId
                          }
                        />
                        <p>{index + 1}</p>
                        <p>{stage.name}</p>
                        <IconButton
                          onClick={() => handleRemoveProcess(stage.id)}
                          className={style.deleteButton}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EditingProcessModal;
