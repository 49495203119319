import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteButtonIcon } from "../../../../../../../newUI/SVG";
import {
  fetchGipHelperDelete,
  fetchProjectViewSearchGip,
} from "../../../../../../../../app/feature/ProjectView/thunks";
import cls from "./Modal.module.scss";
import { CheckBox } from "./CheckBox";
import { getProjectViewData } from "../../../../../../../../app/feature/ProjectView/projectView";
import { CircularIndeterminate } from "../../../../../../../UI/components/Project/Skeleton";

const sx = { color: "white" };

interface DelegateModalProps {
  gip: {
    avatar?: string;
    rating?: number;
    reviews?: number;
    link?: string;
    name?: string;
    firm?: string;
    firmId?: number;
    jobDuration?: number;
    gipHelpers?: {
      name: string;
      id: string;
      fullName: string;
      delegate: boolean;
      accessId: number;
    }[];
    gipInvite?: boolean;
    gipRemove?: boolean;
    editByGipHelper: boolean;
    chatId?: number;
  };
}
export const DelegateModal = memo(({ gip }: DelegateModalProps) => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isLoadGipHelperDelete, isLoadGipHelperDelegate } =
    useSelector(getProjectViewData);

  const deleteClickHandler = async (id: string) => {
    const result = window.confirm(
      "Вы уверены, что хотите снять помощника ГИП?"
    );
    if (result && params.id) {
      const deleteGip = {
        projectId: params.id,
        userId: id,
        active: true,
      };
      await dispatch(fetchGipHelperDelete({ deleteGip }));
      if (params.id) {
        await dispatch(fetchProjectViewSearchGip(params.id));
      }
    }
  };

  return (
    <div className={cls.delegate}>
      <h3>Помощники</h3>
      {(isLoadGipHelperDelete || isLoadGipHelperDelegate) && (
        <div className={cls.delegate_loader}>
          <CircularIndeterminate />
        </div>
      )}
      {gip?.gipHelpers?.slice(0, 3).map((el) => (
        <div key={el.id} className={cls.delegate_block}>
          <div>
            <h4>{el.fullName}</h4>
            <CheckBox checked={el.delegate} accessId={el.accessId} />
          </div>
          {gip.gipRemove && (
            <IconButton
              onClick={() => {
                deleteClickHandler(el.id);
              }}
              title="Снять помощника"
              sx={sx}
            >
              <DeleteButtonIcon width="20" height="20" />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
});
