import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProcessMaterialOfferSelect } from "../../../../services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";

export const fetchProcessMaterialSelect = createAsyncThunk<
  boolean,
  {
    materialId: number;
    taskId: number;
    data: any;
  }
>(
  "materialProcess/fetchProcessMaterialSelect",
  async ({ materialId, taskId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProcessMaterialOfferSelect(materialId, taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
