import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  useTaskCompleteMutation,
  useTaskViewQuery,
} from "src/FSD/entities/task/api";
import { requestCustomerAlignComplete } from "../schemas";

type TFieldValues = {
  comment: string;
  files: TFile[];
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useRequestCustomerAlignComplete = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
  });

  const [completeTaskMulti] = useTaskCompleteMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(requestCustomerAlignComplete),
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formData = new FormData();

    formData.append("CompleteForm[comment]", data.comment);
    formData.append("CompleteForm[status]", String(Statuses.DONE));

    data.files.forEach(({ file }) => {
      formData.append("CompleteForm[upload_files][]", file!);
    });

    try {
      await completeTaskMulti({ id, task: formData, isMultipart: true });
      handleClose();
    } catch (e) {
      console.log(e);
    }

    /*
    dispatch(
      TaskApi.completeMultiData({
        data: { id, formData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
    */
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    isSubmitting,
    isTaskViewPending: isFetching || !taskData,
  } as const;
};
