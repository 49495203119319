import { ForwardedRef, ReactNode, forwardRef, memo } from "react";
import cn from "classnames";
import styles from "./Paragraph.module.scss";

type Props = {
  children: ReactNode;
  fontSize?: number;
  fontWeight?: number;
  className?: string;
  style?: Record<string, string | number>;
  color?: "default" | "light" | "warning" | "error";
};

export const Paragraph = memo(
  forwardRef(
    (
      {
        children,
        fontSize = 16,
        fontWeight = 400,
        className = "",
        style = {},
        color = "default",
      }: Props,
      ref: ForwardedRef<any>
    ) => {
      return (
        <p
          ref={ref}
          className={cn(
            styles.paragraph,
            className,
            color === "light" && styles.paragraph_light,
            color === "warning" && styles.paragraph_warning,
            color === "error" && styles.paragraph_error
          )}
          style={{ fontSize: `${fontSize}px`, fontWeight, ...style }}
        >
          {children}
        </p>
      );
    }
  )
);
