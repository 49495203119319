import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import style from "./History.module.scss";
import { DatePicker } from "../../../../UI/components/FormComponentns/DatePicker/DatePicker";
import { Input } from "../../../../UI/components/FormComponentns/Input";
import {
  getProjectHistory,
  setPersonValueReducer,
  setDateReducer,
  setEventsReducer,
  setDataReducer,
} from "../../../../../app/feature/ProjectView/HistoryReducer/projectHistory";
import { Select } from "../../../../UI/components/FormComponentns/Select";

export const HistoryForm = () => {
  const { personValue, newTypes, error } = useSelector(getProjectHistory);

  const { userFio, data, date1, date2 } = personValue;
  const [dateValue, setDateValue] = useState<(Date | null)[]>([date1, date2]);
  const [query, setQuery] = useState(userFio);
  const [dataValue, setDataValue] = useState(data);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const makeRequestPerson = useMemo(
    () =>
      debounce((query) => {
        dispatch(setPersonValueReducer(query));
      }, 1000),
    []
  );

  const makeRequestData = useMemo(
    () =>
      debounce((dataValue) => {
        dispatch(setDataReducer(dataValue));
      }, 1000),
    []
  );

  const handleChangeEvents = (events: any) => {
    dispatch(setEventsReducer(events));
  };

  const handleChangePerson = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    makeRequestPerson(value);
    setQuery(value);
  };

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    makeRequestData(value);
    setDataValue(value);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [error]);

  return (
    <form className={style.parent}>
      <div className={style.parent_container}>
        <DatePicker
          multiple
          startDateProp={dateValue[0]}
          endDateProp={dateValue[1]}
          variant={style.formElement}
          changeDateHandler={(dates: any) => {
            dispatch(setDateReducer(dates));
            setDateValue(dates);
          }}
        />
      </div>
      <div className={style.parent_container}>
        <Input
          value={query}
          label={"Пользователь"}
          autoComplete={"off"}
          onChange={handleChangePerson}
        />
      </div>
      <div className={style.parent_container}>
        <Select
          label={"События"}
          value={personValue.type || null}
          name={"events"}
          changeHandler={(_, value) => {
            handleChangeEvents(value);
          }}
          options={newTypes}
          isLoading={false}
          disableClear
          variant={style.select}
        />
      </div>
      <div className={style.parent_container}>
        <Input
          value={dataValue}
          label={"Данные"}
          autoComplete={"off"}
          onChange={handleChangeData}
        />
      </div>
    </form>
  );
};
