import React from "react";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";
import { useTitle } from "../../../hooks/useTitle";
import { useMetrika } from "../../../hooks/useMetrik";

export const RegistrationSuccessPage = () => {
  useTitle("Вы успешно зарегистрировались");
  useMetrika("/user/registration-success");

  return (
    <div className={style.pageContainer}>
      <Wrapper>
        <div className={style.titleContainer}>
          <h1 className={style.pageTitle}>Вы успешно зарегистрировались!</h1>
          <h2 className={style.pageSubTitle}>
            Ваша регистрация прошла успешно! Теперь вы можете зайти на Пирс!
          </h2>
        </div>
        <a className={style.enterLink} href={"/site/login"}>
          Войти
        </a>
      </Wrapper>
    </div>
  );
};
