import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";

export const ErrorTextFiles = () => {
  return (
    <Paragraph style={{ color: "#CE0000", lineHeight: "18px" }}>
      Публикация раздела невозможна без исходных файлов: прикрепите файлы в
      режиме редактирования проекта на закладке «Файлы»!
    </Paragraph>
  );
};
