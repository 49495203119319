import React from "react";
import { useSelector } from "react-redux";
import cls from "../NewTaskCard.module.scss";
import { ToggleAccordion } from "../../ToggleAccordion/ToggleAccordion";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { NewChatIcon } from "../../SVG";
import { getProjectViewData } from "../../../../app/feature/ProjectView/projectView";

interface VersionBlockProps {
  versionToggle: boolean;
  setVersionToggle: (arg: boolean) => void;
  versionCount: number;
}
export const VersionBlock = ({
  versionToggle,
  setVersionToggle,
  versionCount,
}: VersionBlockProps) => {
  const { project } = useSelector(getProjectViewData);
  return (
    <>
      {versionToggle ? (
        <div className={cls.flex}>
          <div
            className={cls.versionToggle}
            onClick={() => setVersionToggle(!versionToggle)}
          >
            <p>Версии</p>
            <span>
              <ToggleAccordion toggle={versionToggle} version />
            </span>
          </div>
          <TextLink
            variant={cls.btn}
            url={`/site/front/chat/${project?.chatId}`}
          >
            <NewChatIcon fill={"#D5D6DE"} />
          </TextLink>
        </div>
      ) : (
        <>
          <div
            className={cls.version}
            onClick={() => setVersionToggle(!versionToggle)}
          >
            <p>{`Версии (${versionCount})`}</p>
            <span>
              <ToggleAccordion toggle={versionToggle} version />
            </span>
          </div>
          <TextLink
            variant={cls.btn}
            url={`/site/front/chat/${project?.chatId}`}
          >
            <NewChatIcon fill={"#D5D6DE"} />
          </TextLink>
        </>
      )}
    </>
  );
};
