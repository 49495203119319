import { httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessCheckTask = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v2/project-process/get-sub-check-tasks?id=${id}&expand=svgIcon`
  );
};

export const ApiProjectProcessCheckDocumentations = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    { CompleteForm: data }
  );
};
