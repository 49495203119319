export const trimNumber = (value: string | number): string => {
  if (value) {
    if (value === "Договорная") return "Договорная";
    return new Intl.NumberFormat("ru-RU").format(+value);
  }
  return "Договорная";
};

export const trimPrice = (value: number | string | undefined) => {
  if (value) {
    const num = Math.ceil(+value);
    return new Intl.NumberFormat("ru-RU").format(num);
  }
  return 0;
};

export const trimRubPennies = (value: number | undefined) => {
  if (value) {
    return new Intl.NumberFormat("ru-RU", { minimumFractionDigits: 2 }).format(
      value
    );
  }
  return "0,00";
};
