import React, { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment/moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../styles/restyle";
import cls from "./ModalCreateDocument.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { fetchExpertiseCreateDocument } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/services/fetchExpertiseCreateDocument";
import {
  getExpertiseCreateDocument,
  getExpertiseCreateDocumentError422,
} from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/selectors/getExpertiseHeaderBtn";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { setClearDocumentSuccess } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/slice/expertiseHeaderBtn";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

const nameList = ["Договор", "Дополнительное соглашение"];

type TInputs = {
  name: string;
  num: string;
  date: any;
  date_start: any;
  date_end: any;
  file_upload: any;
};
interface ModalCreateDocumentProps {
  closeModal: () => void;
}

const schema = Yup.object().shape({
  date: Yup.date().required("Необходимо заполнить «Дата»"),
  date_start: Yup.date().required("Необходимо заполнить «Дата начала»"),
  date_end: Yup.date().required("Необходимо заполнить «Дата окончания»"),
  name: Yup.string().ensure().required("Необходимо заполнить «Наименование»"),
  num: Yup.string().required("Необходимо заполнить «Номер»"),
  file_upload: Yup.array()
    .min(1, "Необходимо загрузить хотя бы один файл")
    .required("Необходимо загрузить хотя бы один файл"),
});

export const ModalCreateDocument = memo(
  ({ closeModal }: ModalCreateDocumentProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const error422 = useAppSelector(getExpertiseCreateDocumentError422);
    const createDocumentSuccess = useAppSelector(getExpertiseCreateDocument);

    const {
      handleSubmit,
      setValue,
      control,
      register,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();
      formData.append("name", dataValues.name);
      formData.append("num", dataValues.num);
      formData.append(
        "date",
        moment(dataValues.date, "DD.MM.YYYY").format("DD.MM.YYYY")
      );
      formData.append(
        "date_start",
        moment(dataValues.date_start, "DD.MM.YYYY").format("DD.MM.YYYY")
      );
      formData.append(
        "date_end",
        moment(dataValues.date_end, "DD.MM.YYYY").format("DD.MM.YYYY")
      );
      dataValues.file_upload.forEach((file: BinaryType) => {
        formData.append("file_upload", file);
      });
      if (processId) {
        await dispatch(fetchExpertiseCreateDocument({ processId, formData }));
      }
    };

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      if (createDocumentSuccess) {
        clearErrors();
        dispatch(setClearDocumentSuccess());
        closeModal();
      }
    }, [createDocumentSuccess]);

    return (
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={cls.form}
        encType={"multipart/form-data"}
      >
        <h1>Добавление документа</h1>
        <div className={cls.form_flexColumn}>
          <div className={cls.flex}>
            <FormControl {...textFieldSX} className={cls.name}>
              <InputLabel id="name">Наименование</InputLabel>
              <Select
                {...register("name")}
                labelId="name"
                label="Наименование"
                error={!!errors.name}
              >
                {nameList.map((name) => (
                  <MenuItem key={name} sx={menuItemSx} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <p className={cls.error}>{errors.name?.message}</p>
            </FormControl>
            <div className={cls.container}>
              <div>
                <TextField
                  {...register("num")}
                  {...textFieldSX}
                  label="Номер"
                  error={!!errors.num}
                  name={"num"}
                />
                <p className={cls.error}>{errors.num?.message}</p>
              </div>
              <div className={cls.dateWrapper}>
                <Controller
                  control={control}
                  name="date"
                  render={() => (
                    <DateField
                      variant={cls.date}
                      label="Дата"
                      changeDateHandler={(date) => {
                        setValue("date", date);
                      }}
                      error={!!errors.date}
                    />
                  )}
                />
                <p className={cls.error}>{errors.date?.message}</p>
              </div>
            </div>
          </div>
          <div className={cls.flex}>
            <div className={cls.block}>
              <Controller
                control={control}
                name="date_start"
                render={() => (
                  <DateField
                    variant={cls.date}
                    label="Дата начала"
                    changeDateHandler={(date) => {
                      setValue("date_start", date);
                    }}
                    error={!!errors.date_start}
                  />
                )}
              />
              <p className={cls.error}>{errors.date_start?.message}</p>
            </div>
            <div className={cls.block}>
              <Controller
                control={control}
                name="date_end"
                render={() => (
                  <DateField
                    variant={cls.date}
                    label="Дата окончания"
                    changeDateHandler={(date) => {
                      setValue("date_end", date);
                    }}
                    error={!!errors.date_end}
                  />
                )}
              />
              <p className={cls.error}>{errors.date_end?.message}</p>
            </div>
          </div>
        </div>
        <div>
          <Uploader
            canBeEdited
            autoUpload={false}
            identifier={null}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              setValue("file_upload", data);
            }}
          />
          <p className={cls.error}>{errors.file_upload?.message}</p>
        </div>
        <CustomButton
          type="submit"
          background={palette.green}
          width={160}
          className={cls.form_submit}
        >
          Сохранить
        </CustomButton>
      </form>
    );
  }
);
