import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessStageComplete } from "../../../../services/api/projectProcess/mileStone";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessStageComplete = createAsyncThunk<
  boolean,
  number
>(
  "projectProcess/fetchProjectProcessStageComplete",
  async (stageId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessStageComplete(stageId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
