import { KanbanType } from "src/FSD/entities/kanban";

export const getValues = () => {
  const filterState = localStorage.getItem("kanbanFilter");
  return filterState ? JSON.parse(filterState) : {};
};

export const setValues = (
  filterParams: Record<number, KanbanType.FilterValues>
) => {
  localStorage.setItem("kanbanFilter", JSON.stringify(filterParams));
};
