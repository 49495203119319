import React, { FC, useState, useEffect } from "react";
import cn from "classnames";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Switch from "../../../../newUI/Switch/Switch";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import SearchField from "../SearchField";
import Folder from "../Secondary/Sections/Folder";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardSections,
  setDataTree,
  setOpenTabs,
  setSectionsLoaded,
} from "../../../../../app/feature/dashboard/sections/index";
import Filter from "../Secondary/utils";
import useFilter from "../Filter/useFilter";
import {
  getDashboardDestinations,
  setShowFulfilledSections,
} from "../../../../../app/feature/dashboard/destinations";
import FilterSections from "../Filter/Sections";
import { getSections } from "../../../../../app/feature/dashboard/sections/thunks";
import DashboardSkeleton from "../Skeleton";
import style from "./style.module.scss";

const Sections: FC = () => {
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile(1100);

  const [query, setQuery] = useState<string>("");

  const { projectId, showFulfilledSections } = useAppSelector(
    getDashboardDestinations
  );
  const { data, sectionsLoaded, pending } =
    useAppSelector(getDashboardSections);

  const { ticked, handleChange } = useFilter(
    data,
    query,
    showFulfilledSections,
    FilterSections,
    setQuery
  );

  useEffect(() => {
    if (!Object.keys(data).length) {
      return;
    }

    const filteredData = Filter.updateTreeByFilterParams(data, ticked);

    dispatch(setDataTree(filteredData));
  }, [ticked]);

  useEffect(() => {
    if (data) {
      dispatch(setOpenTabs(Object.keys(data)));
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length) {
      return;
    }

    if (projectId && !sectionsLoaded) {
      dispatch(setOpenTabs([]));
      dispatch(getSections(projectId));
    }

    return () => {
      dispatch(setSectionsLoaded(false));
    };
  }, [projectId]);

  const treeData = data["0"]?.children ?? [];

  const sections = treeData.length ? (
    treeData.map((item: string) => (
      <Folder
        ticked={ticked}
        query={query}
        key={item}
        data={data}
        item={item}
        fulfilled={showFulfilledSections}
        short
        disableShowCount
      />
    ))
  ) : (
    <p className={style.center}>Разделы не найдены</p>
  );

  return (
    <div className={cn(style.wrapper, style.projectsPane)}>
      <div className={cn(style.header, style.shortSectionsHeader)}>
        {isMobile ? (
          <>
            <h3>Раздел</h3>
            <div className={style.mobileHeader}>
              <p>Выполнено</p>
              <Switch
                checked={showFulfilledSections}
                onChange={() =>
                  dispatch(setShowFulfilledSections(!showFulfilledSections))
                }
              />
              <IconButton className={style.mobileSearchBar} onClick={() => {}}>
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h3>Раздел</h3>
            <p>Выполнено</p>
            <Switch
              className={style.fulfilledSwitch}
              checked={showFulfilledSections}
              onChange={() =>
                dispatch(setShowFulfilledSections(!showFulfilledSections))
              }
            />
            <SearchField
              className={style.searchBar}
              value={query}
              onChange={handleChange}
            />
          </>
        )}
      </div>
      {pending ? (
        <DashboardSkeleton count={8} />
      ) : (
        <div className={style.list}>{sections}</div>
      )}
    </div>
  );
};
export default Sections;
