import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";

export const updateDates = (builder: QueryBuilder) =>
  builder.mutation({
    query: ({ id, data }) => ({
      url: `v1/schedule-plan/update-dates`,
      params: {
        projectId: id,
      },
      method: HTTPMethods.PATCH,
      data,
    }),
  });
