import moment from "moment";
import { TProcessShort } from ".";
import { TSelectItem } from "../../../../components/features/Forms/SetGipForm/types";

export const getPayments = (payload: any) => {
  const { pay1, pay2, pay3 } = payload;
  return { pay1, pay2, pay3 };
};

export const getProcessesData = (payload: any, field?: string) => {
  delete payload.pay1;
  delete payload.pay2;
  delete payload.pay3;

  return Object.entries(payload).reduce((result: any, [id, value]: any) => {
    return {
      ...result,
      [id]: {
        maybeExpertise: value.maybeExpertise,
        list: value.processes.reduce(
          (result: Record<string, TProcessShort>, item: any) => {
            const finalItem = field ? item[field] : item;

            return {
              ...result,
              [finalItem.id]: {
                active: false,
                name: `${finalItem.projectPart.code} ${finalItem.projectPart.name}`,
                dateStart: finalItem.date_start,
                dateLimit: finalItem.date_limit,
                price: finalItem.price,
              },
            };
          },
          {}
        ),
        title: value.title,
      },
    };
  }, {});
};

export const getPreparedBody = (payload: any, isAddExecutorBtn: boolean) => {
  const {
    processes,
    save,
    isAnalyze,
    isSafe,
    isDocs,
    isThief,
    isVolumes,
    selectedTypes,
    executor,
    type,
  } = payload;

  const ids: Record<string, number[]> = {};

  const parsedProcesses = Object.entries(processes).reduce(
    (processes: Record<string, any>, [typeId, item]: any) => {
      const newProcesses = Object.entries(item.list)
        .filter(([_, item]: any) => item.active)
        .reduce((newProcesses: Record<string, any>, [id, item]: any) => {
          if (ids[typeId]) {
            ids[typeId].push(Number(id));
          } else {
            ids[typeId] = [Number(id)];
          }

          return {
            ...newProcesses,
            [id]: {
              price: Number(item.price),
              date_start: moment(item.dateStart).format("DD.MM.YYYY"),
              date_limit: moment(item.dateLimit).format("DD.MM.YYYY"),
            },
          };
        }, {});

      return { ...processes, ...newProcesses };
    },
    {}
  );

  const types = Object.entries(payload)
    .filter(([key]) => !Number.isNaN(Number(key)))
    .reduce((types: any, [typeId, value]: any) => {
      const typeKey = `type${typeId}`;

      return {
        ...types,
        [typeKey]: {
          pay1: Number(value.prePaid),
          pay2: Number(value.afterAccept),
          pay3: value.afterExpertise ? Number(value.afterExpertise) : 0,
          id: ids[typeId],
        },
      };
    }, {});

  const result: Record<string, any> = {
    save,
    ProjectProcess: {
      ...types,
      ...parsedProcesses,
      secure: Boolean(isSafe.id),
      with_docs: Boolean(isDocs.id),
      vor: isVolumes,
      kac: isAnalyze,
      vor_pnr: isThief,
      0: {
        executor_type: selectedTypes.map((item: TSelectItem) => item.id),
      },
    },
  };

  if (executor && isAddExecutorBtn) {
    result.ProjectProcess[0].executorByAdminId = executor.id;
  }

  if (type && isAddExecutorBtn) {
    result.ProjectProcess[0].executorTypeByAdminId = type.id;
  }

  return result;
};
