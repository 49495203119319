import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProcessMaterialOfferBack } from "../../../../services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";

export const fetchProcessMaterialOfferBack = createAsyncThunk<
  boolean,
  {
    taskId: number;
  }
>(
  "materialProcess/fetchProcessMaterialOfferBack",
  async ({ taskId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProcessMaterialOfferBack(taskId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
