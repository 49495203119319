import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiCommonResponses } from "../../../apiCommonResponses";

type TParams = RequestParams<{
  id: number;
  data: ApiCommonResponses.Publish;
}>;

export const publish = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/process-work/publish`,
      params: {
        id,
      },
      data,
      method: HTTPMethods.POST,
    }),
    invalidatesTags: ["ProcessWork.View", "ProjectProcess.GetWorks"],
  });
