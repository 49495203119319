import React, { useState } from "react";
import { useDispatch } from "react-redux";
import style from "./style.module.scss";
import { TextLink } from "../../TextLink/TextLink";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { Button } from "../../Buttons/Button/Button";
import { request } from "../../../../../app/services/api/requestHandler";
import { ApiPostUserAgree } from "../../../../../app/services/api/user/user";
import { setUserAgreement } from "../../../../../app/feature/appUser/appUser";

export type LinksType = {
  id: number;
  title: string;
  link: string;
}[];

type ConditionsModalProps = ComponentPropsType<{
  onCloseAgreement: (close: boolean) => void;
  links: LinksType;
}>;

export const ConditionsModal: React.FC<ConditionsModalProps> = ({
  theme,
  onCloseAgreement,
  links,
}) => {
  const [isRequest, setRequest] = useState(false);
  const dispatch = useDispatch();

  // также тут записываю информацию, в redux
  // в appUser { agreement_personal }
  // чтобы закрыть модальное окно сразу
  // что пользователь принял agreement_personal
  const onSubmit = () => {
    request(ApiPostUserAgree(), (data) => {
      if (data) {
        dispatch(setUserAgreement({ agreement_personal: data }));
        onCloseAgreement(false);
      }
    })(dispatch);
  };

  return (
    <div className={style.conditionsModalContainer}>
      <h2 className={style.conditionsModalTitle}>
        Изменение политик и документов Платформы ПИРС
      </h2>
      <p className={style.conditionsModalText}>
        В связи с изменением юридического лица, обеспечивающего взаимодействие
        участников сделок в рамках услуги «Безопасная сделка» на платформе ПИРС
        — ООО «Технология» передаёт, а ООО «Цифровая Платформа «ПИРС» принимает
        на себя все обязательства перед пользователями по оказанию услуг,
        сопровождению сделок между пользователями платформы ПИРС «Безопасная
        сделка», а также передаёт право требования суммы агентского
        вознаграждения, предусмотренного условиями договора оказания услуг
        сопровождения сделок между пользователями платформы ПИРС «Безопасная
        сделка». Ваша учётная запись, работы, рейтинги и история, а также
        цифровой контент и услуги будут по-прежнему доступны вам без изменений.
      </p>
      <p className={style.conditionsModalText}>
        С 26 ноября 2021 года вашим партнёром в отношениях будет ООО «Цифровая
        платформа «ПИРС».
      </p>
      <p className={style.conditionsModalText}>
        Просим вас ознакомиться с изменёнными документами
      </p>
      <ol className={style.conditionsModalList}>
        {links.map((item, i) => {
          return (
            <li className={style.conditionsModalItem} key={i}>
              <span className={style.conditionsLinkNumber}>{item.id}.</span>
              <TextLink url={item.link || ""} theme={theme}>
                {item.title}
              </TextLink>
            </li>
          );
        })}
      </ol>
      <p className={style.conditionsModalText}>
        Нажимая кнопку «Принять», вы соглашаетесь с настоящим Соглашением и
        принимаете на себя указанные в них права и обязанности, связанные с
        использованием и функционированием Платформы.
      </p>
      <div className={style.agreeButtonWrapper}>
        <Button
          onClick={() => {
            if (isRequest) return;
            setRequest(true);
            onSubmit();
          }}
          theme={theme}
        >
          Принять
        </Button>
      </div>
    </div>
  );
};
