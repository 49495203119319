import { memo } from "react";
import { Clock, QuestionCircleFill } from "react-bootstrap-icons";
import style from "../../pages/Project/View/InitialData/style.module.scss";
import { CriticalIcon, HiddenEyeIcon, SuccessIcon, WarningIcon } from "../SVG";

interface SourceDataIconTypeProps {
  critical?: boolean;
  status: string;
}
export const SourceDataIconType = memo(
  ({ critical = false, status }: SourceDataIconTypeProps) => {
    const canTitle = status === "hidden";
    return (
      <div
        className={style.statusIcon}
        title={
          (canTitle &&
            "Файлы не публикуемые и доступные только участникам проекта") ||
          ""
        }
      >
        {critical && status !== "success" && status !== "hidden" && (
          <CriticalIcon />
        )}
        {status === "success" && <SuccessIcon />}
        {status === "warning" && <WarningIcon />}
        {status === "hidden" && <HiddenEyeIcon />}
        {status === "undefined" && <QuestionCircleFill color="orange" />}
        {status === "inprocess" && <Clock />}
      </div>
    );
  }
);
