import { useState } from "react";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { useAppSelector } from "src/app/store";

export const useSourceDataNameEdit = () => {
  const [edit, setEdit] = useState(false);

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
  };

  const {
    data: { disabled: disabledValue },
  } = useAppSelector(getProjectChecklist);
  const disabled = disabledValue === "delete";

  return { edit, toggleEdit, disabled } as const;
};
