import classNames from "classnames";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import ButtonMui, {
  ButtonProps as ButtonPropsMui,
  buttonClasses,
} from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import { palette } from "src/styles/restyle";

export const ButtonStyles = styled(ButtonMui)<ButtonProps>({
  [`&.${buttonClasses.root}`]: {
    fontSize: "1rem",
    fontFamily: "Manrope",
    fontWeight: 400,
    lineHeight: 1.5,
    padding: "4px 10px",
    textTransform: "unset",
    minHeight: "32px",
    boxShadow: "none",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.85,
    },
    "@media (min-width: 768px)": {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  [`&.${buttonClasses.sizeLarge}`]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  [`&.${buttonClasses.outlined}`]: {
    color: palette.white,
    [`&.${buttonClasses.disabled}`]: {
      // Todo убрать из записей important
      borderColor: `${alpha(palette.darkTelegraph, 0.2)} !important`,
      color: alpha(palette.darkTelegraph, 0.6),
    },
    "& .button__text": {
      position: "relative",
      zIndex: 2,
    },
    [`&.${buttonClasses.colorPrimary}`]: {
      borderWidth: 0,
      position: "relative",
      zIndex: 1,
      "&:before": {
        content: '""',
        position: "absolute",
        inset: 0,
        borderRadius: "4px",
        padding: 1,
        zIndex: -1,
        background: palette.oceanic,
      },
      "&:after": {
        content: '""',
        position: "absolute",
        inset: 1,
        borderRadius: "4px",
        backgroundColor: palette.darkSlate,
        pointerEvents: "none",
        zIndex: -1,
      },
      [`&.${buttonClasses.disabled}`]: {
        "&:before": {
          background: alpha(palette.darkTelegraph, 0.2),
        },
      },
    },
    [`&.${buttonClasses.colorSuccess}`]: {
      color: palette.persianGreen,
      borderColor: "rgba(10,166,153,0.5)",
    },
    [`&.${buttonClasses.colorSecondary}`]: {
      color: palette.oceanicSmooth,
      borderColor: "rgb(70,198,233,0.5)",
    },
  },
  [`&.${buttonClasses.contained}`]: {
    borderWidth: "1px",
    borderStyle: "solid",
    [`&.${buttonClasses.colorPrimary}`]: {
      color: palette.midnightBlack,
      background: palette.oceanic,
      borderColor: palette.oceanic,
    },
    [`&.${buttonClasses.colorSecondary}`]: {
      color: "rgba(213,214,222,0.5)",
      backgroundColor: palette.darkGrey,
      borderColor: palette.darkGrey,
    },
  },
  [`&.${buttonClasses.colorInfo}`]: {
    color: "#0B1016",
    backgroundColor: palette.lightGrey,
    borderColor: palette.lightGrey,
  },
  "&.btn__loading": {
    [`& .${buttonClasses.startIcon}, & .button__text`]: {
      opacity: 0,
    },
  },
  [`& .${circularProgressClasses.root}`]: {
    position: "absolute",
    color: "inherit",
  },
  [`& .${buttonClasses.startIcon}, & .button__text`]: {
    opacity: 1,
    transition: "opacity 0.2s",
  },
});

interface ButtonProps extends ButtonPropsMui {
  width?: number;
  onClick?: (e?: any) => void;
  className?: string;
  children?: any;
  redirect?: boolean;
  disabled?: boolean;
  type?: any;
  style?: any;
  // small?: boolean;
  color?: "primary" | "secondary" | "success" | "error" | "info";
  variant?: "contained" | "outlined";
  // background?: string;
  borderRadius?: number;
  border?: string;
  // fontSize?: number;
  isLoading?: boolean;
  target?: string;
  href?: string;
  to?: string;
}

export const Button = ({
  width,
  onClick = () => {},
  className,
  children,
  redirect,
  disabled = false,
  type,
  style,
  // small,
  // background,
  borderRadius,
  border,
  // color,
  isLoading = false,
  // fontSize = 14,
  startIcon,
  endIcon,
  variant = "contained",
  target,
  href,
  ...props
}: ButtonProps) => {
  return (
    <ButtonStyles
      disabled={disabled}
      onClick={onClick}
      redirect={redirect}
      className={classNames(className, { btn__loading: isLoading })}
      type={type}
      // fontSize={30}
      variant={variant}
      style={{
        // fontSize,
        width: `${width}px`,
        // border,
        borderRadius: `${borderRadius}px`,
        color: disabled ? alpha(palette.darkTelegraph, 0.6) : undefined,
        // background: background,
        // color: color ?? "white",
      }}
      sx={{ ...style }}
      startIcon={startIcon}
      endIcon={endIcon}
      target={target}
      href={href}
      {...props}
    >
      {isLoading && <CircularProgress size={20} />}
      <span className="button__text">{children}</span>
    </ButtonStyles>
  );
};
