import React, { useState } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { SkeletonWorks } from "./SkeletonWorks";
import { WorksHeader } from "./WorksHeader";
import { GroupAccordion } from "./GroupAccordion";
import { useProjectProcessWorks } from "../lib/hooks/useProjectProcessWorks";
import PublishObjectModals from "../../../../publishObject/modals";

export const Works = () => {
  const { fullCost, isLoading, groups } = useProjectProcessWorks();
  const [workPublication, setWorkPublication] = useState(false);
  const [workPublicationId, setWorkPublicationId] = useState(0);
  const closeWorkPublication = () => {
    setWorkPublication(false);
  };

  if (isLoading) {
    return <SkeletonWorks />;
  }

  return (
    <>
      <WorksHeader title="Задачи" fullCost={fullCost} isLoading={isLoading} />

      {groups && (
        <>
          {!!workPublicationId && (
            <Modal
              isActive={workPublication}
              handleClose={closeWorkPublication}
            >
              <PublishObjectModals.PublishContentForm
                id={workPublicationId}
                header={"Публикация задачи"}
                isProcessWork
              />
            </Modal>
          )}
          {groups &&
            groups.map(([userId, group]) => (
              <GroupAccordion
                key={userId}
                userId={+userId}
                {...group}
                setWorkPublication={setWorkPublication}
                setWorkPublicationId={setWorkPublicationId}
              />
            ))}
        </>
      )}
    </>
  );
};
