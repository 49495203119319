import React from "react";
import { Skeleton } from "@mui/material";
import style from "./style.module.scss";

export const PostSkeletonItem = () => {
  return (
    <div className={style.postSkeletonItem}>
      <Skeleton variant={"rectangular"} className={style.postSkeletonDate} />
      <div className={style.postSkeletonTitleWrapper}>
        <Skeleton variant={"text"} className={style.postSkeletonTitle} />
        <Skeleton variant={"text"} className={style.postSkeletonTitle} />
      </div>
      <div className={style.postSkeletonAnnotationWrapper}>
        <Skeleton variant={"text"} className={style.postSkeletonAnnotation} />
        <Skeleton variant={"text"} className={style.postSkeletonAnnotation} />
        <Skeleton variant={"text"} className={style.postSkeletonAnnotation} />
      </div>
      <Skeleton variant={"rectangular"} className={style.postSkeletonImage} />
    </div>
  );
};
