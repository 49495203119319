import React, { memo } from "react";
import classNames from "classnames";
import style from "./style.module.scss";

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  editing?: boolean;
  disabled?: boolean;
  grey?: boolean;
  inactive?: boolean;
  classname?: string;
  value?: any;
  name?: string;
  id?: string;
  title?: string;
  type?: string;
  width?: string;
  height?: string;
  flexBasis?: string;
};

export const NewCheckbox: React.FC<Props> = memo(
  ({
    onChange,
    checked,
    editing,
    disabled,
    grey,
    inactive,
    classname,
    value,
    name,
    id,
    title,
    type = "checkbox",
    width = "16px",
    height = "16px",
    flexBasis = "16px",
  }) => (
    <input
      style={{ width, height, flexBasis }}
      className={classNames(
        style.checkbox,
        editing && style.checked,
        grey && style.grey,
        (disabled || inactive) && style.disabled,
        classname && [classname]
      )}
      title={title}
      type={type}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      value={value}
      name={name}
      id={id}
    />
  )
);
