import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessApplications } from "../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import {
  getProjectProcessApplicationsMessages,
  getProjectProcessBadApplications,
  getProjectProcessGoodApplications,
} from "../../../../app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import { HorizontalTabsPanel } from "../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { getProjectProcessApplicationsIsLoad } from "../../../../app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplicationsIsLoad";
import cls from "./Applications.module.scss";
import { ApplicationList } from "./ApplicationList/ApplicationList";
import { fetchProjectProcessViewDuplicate } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { Preloader } from "../../../features";
import { getApplicationsSelectExecutor } from "../../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsSelectExecutor";
import { getApplicationsRemoveRequestIsLoad } from "../../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsRemoveRequest";
import { SkeletonApplicationsTab } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { WarningCard } from "../../../newUI/WarningCard/WarningCard";

interface ApplicationsProps {
  setInfoTab: () => void;
}
export const Applications = memo(({ setInfoTab }: ApplicationsProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useAppSelector(getProjectProcessInfoId);
  const goodApplications = useAppSelector(getProjectProcessGoodApplications);
  const badApplications = useAppSelector(getProjectProcessBadApplications);
  const applicationWarning = useAppSelector(
    getProjectProcessApplicationsMessages
  );
  const isLoadSelectExecutor = useAppSelector(getApplicationsSelectExecutor);
  const isLoadRemoveRequest = useAppSelector(
    getApplicationsRemoveRequestIsLoad
  );
  const isLoad = useAppSelector(getProjectProcessApplicationsIsLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessApplications(infoId));
    }
  }, [infoId]);

  const tabs = [
    {
      id: 1,
      label: "Соответствуют",
      incomplete: false,
    },
    {
      id: 2,
      label: "Не соответствуют",
      incomplete: false,
    },
  ];

  const [activeTab, setActiveTab] = useState<number | string>(tabs[0].id);
  const setActiveTabMemo = useCallback((tabId: number | string) => {
    setActiveTab(tabId);
  }, []);

  const components = [
    {
      id: 1,
      component: (
        <ApplicationList
          applications={goodApplications}
          setInfoTab={setInfoTab}
        />
      ),
    },
    {
      id: 2,
      component: (
        <ApplicationList
          applications={badApplications}
          setInfoTab={setInfoTab}
        />
      ),
    },
  ];

  return (
    <div className={cls.wrapper}>
      {(isLoadSelectExecutor || isLoadRemoveRequest) && (
        <Preloader variant={cls.preloaderSelect} />
      )}
      {applicationWarning.length > 0 && (
        <WarningCard
          applicationWarning={applicationWarning}
          classname={cls.warningCard}
        />
      )}
      <HorizontalTabsPanel
        tabs={tabs}
        components={components}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        isLoad={isLoad}
        skeleton={<SkeletonApplicationsTab />}
      />
    </div>
  );
});
