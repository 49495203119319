export enum AccessCodes {
  AUTHOR = 1,
  EXECUTOR = 3,
  GIP = 4,
  GIP_HELPER = 5,
  WORK_EXECUTOR = 6,
  VIEWER = 7,
  NORM_CONTROLLER = 8,
  BIM_COORDINATOR = 9,
}
