import cn from "classnames";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import { useModalsRequestUpdates } from "src/FSD/entities/publishObject/lib/hooks/useModalsRequestUpdates";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import styles from "./Executor.module.scss";
import { useExecutorSearch } from "../../lib/hooks/useExecutorSearch";
import PublishObjectModals from "../../../../publishObject/modals";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const ExecutorSearch = ({ processWork }: Props) => {
  const { countApplications, handleNavigation, executorTypes, header } =
    useExecutorSearch({ processWork });

  const {
    requestUpdate: requestProcessWork,
    handleCloseRequestUpdate: handleCloseRequestProcessWork,
    handleOpenRequestUpdate: handleOpenRequestProcessWork,
  } = useModalsRequestUpdates();

  return (
    <Card
      header={<Card.Header>Исполнитель</Card.Header>}
      className={cn(styles.executor, styles.executorCard)}
      controls={
        <Card.Controls location="top">
          {processWork.can!.requestExecution && (
            <Button
              onClick={() => {
                handleOpenRequestProcessWork(
                  processWork.id,
                  header,
                  executorTypes,
                  processWork.bim,
                  processWork.with_docs,
                  processWork.date_start,
                  processWork.date_limit!,
                  +processWork.price
                );
              }}
              color="primary"
            >
              Заявиться
            </Button>
          )}
        </Card.Controls>
      }
    >
      <Modal
        isActive={requestProcessWork.isOpen}
        handleClose={handleCloseRequestProcessWork}
      >
        <PublishObjectModals.RequestUpdateForm
          publicationObjectValues={requestProcessWork}
          secure={processWork.secure}
          processWork
        />
      </Modal>
      <div className={styles.userInfo}>
        <Paragraph>Требуемая форма собственности</Paragraph>
        <RequiredTypes
          types={processWork.executorType}
          classname={styles.executor_type}
        />
      </div>
      {countApplications > 0 && (
        <div className={styles.applications}>
          <div onClick={handleNavigation}>{countApplications}</div>
          <p>Кол-во заявок</p>
        </div>
      )}
      <hr className={styles.yellowLine} />
    </Card>
  );
};

export default ExecutorSearch;
