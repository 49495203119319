import { IProjectParams } from "../thunks";

export function createParams(arr: IProjectParams[]) {
  return arr
    .filter((el) => el.parameter_code !== 1 && el.parameter_code !== 8)
    .map((el) => {
      return {
        type: el.type.name,
        value: el.value,
        id: el.id,
      };
    });
}
