import React, { useCallback, useState } from "react";
import style from "src/components/pages/Project/View/Tasks/style.module.scss";
import { IconButton } from "@mui/material";
import { AddFile, CheckSVG, NotApproveIcon } from "src/components/newUI/SVG";
import Modal from "src/components/newUI/Modal/Modal";
import { ModalNotAccept } from "src/components/pages/Project/View/Tasks/ModalNotAccept/ModalNotAccept";
import { ModalComplete } from "src/components/pages/Project/View/Tasks/ModalComplete/ModalComplete";
import { CaretRightFill, Download } from "react-bootstrap-icons";
import { ModalDownloadFiles } from "src/components/pages/Project/View/Tasks/ModalDownloadFiles/ModalDownloadFiles";
import { Dot } from "src/components/newUI/Dot/Dot";
import {
  fetchAcceptTask,
  fetchBackToWork,
  fetchNotAcceptTask,
  fetchProjectTaskInTree,
  fetchProjectTaskOutTree,
  TaskValueType,
} from "src/app/feature/ProjectView/TaskReducer/thunks";
import {
  fetchProjectProcessInTasks,
  fetchProjectProcessOutTasks,
} from "src/app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import { useDispatch } from "react-redux";
import { SetState } from "src/components/features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "src/components/pages/Project/View/CheckTasks/CheckTaskCard";

const sxBtn = {
  color: "#9DAFBD",
  "&:hover": {
    cursor: "pointer",
    color: "#FFFFFF",
  },
};

type TaskProps = {
  id?: number;
  type: "project" | "process";
  projectId?: number;
  processId?: number;
  setActiveTabMemo?: (id: string | number) => void;
  setModalData?: SetState<TModalData>;
  node: TaskValueType;
  task: TaskValueType;
};

const Task = ({
  id,
  projectId,
  type,
  processId,
  setActiveTabMemo,
  setModalData,
  node,
  task,
}: TaskProps) => {
  const [modalNotAccept, setModalNotAccept] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);
  const [modalFiles, setModalFiles] = useState(false);

  const dispatch = useDispatch();
  const typeProject = type === "project";
  const typeProcess = type === "process";

  const acceptHandler = async (id: number) => {
    const result = window.confirm("Вы уверены, что хотите принять задание?");
    if (typeProject && projectId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectTaskInTree(projectId));
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectProcessInTasks(processId));
    }
  };

  const notCompleteTask = async (id: number) => {
    const data = {
      comment: "Не требуется",
      status: 7,
    };
    const result = window.confirm(
      "Вы уверены, что хотите отменить данное задание?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectTaskOutTree(projectId));
      if (setActiveTabMemo) {
        setActiveTabMemo(2);
      }
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectProcessOutTasks(processId));
      if (setActiveTabMemo) {
        setActiveTabMemo(2);
      }
    }
  };

  const notAcceptHandler = () => {
    setModalNotAccept(true);
  };

  const completeHandler = () => {
    setModalComplete(true);
  };
  const backToWork = async (id: number) => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задание в работу?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectTaskOutTree(projectId));
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectProcessOutTasks(processId));
    }
  };

  const setCompleteModalCloseMemo = useCallback(() => {
    setModalComplete(false);
  }, []);

  const visibleAccept = task.can.accept;
  const visibleComplete = task.can.complete;
  const visibleBackToWork =
    !task.can["not-required"] && !task.can.complete && task.can["back-to-work"];

  return (
    <div className={style.task} key={task.task.id}>
      <div className={style.task_header}>
        <div className={style.task_header_text}>
          <h4>{task.header}</h4>
          <p>{task.taskStatusInfo.date}</p>
        </div>
        <div className={style.task_header_btn}>
          {visibleAccept && (
            <IconButton
              title="Принять"
              sx={sxBtn}
              onClick={() => acceptHandler(task.task.id)}
            >
              <CheckSVG width="22" height="22" />
            </IconButton>
          )}
          {task.can.reject && (
            <>
              <Modal active={modalNotAccept} setActive={setModalNotAccept}>
                <ModalNotAccept
                  id={task.task.id}
                  content={task.comment}
                  name={task.task.name}
                  projectID={projectId}
                  processID={processId}
                  type={type}
                />
              </Modal>
              <IconButton
                title="Отказать"
                sx={sxBtn}
                onClick={notAcceptHandler}
              >
                <NotApproveIcon />
              </IconButton>
            </>
          )}
          {visibleComplete && (
            <>
              <Modal active={modalComplete} setActive={setModalComplete}>
                <ModalComplete
                  task={task}
                  code={node.code}
                  setCompleteModalCloseMemo={setCompleteModalCloseMemo}
                  projectId={projectId}
                  projectProcessID={processId}
                  type={type}
                />
              </Modal>
              <IconButton
                title="Сформировать задание"
                sx={sxBtn}
                onClick={completeHandler}
              >
                <AddFile width="22" height="22" />
              </IconButton>
            </>
          )}
          {task.can["not-required"] && (
            <IconButton
              title="Не требуется"
              sx={sxBtn}
              onClick={() => notCompleteTask(task.task.id)}
            >
              <NotApproveIcon />
            </IconButton>
          )}
          {visibleBackToWork && (
            <IconButton
              title="Вернуть в работу"
              sx={sxBtn}
              onClick={() => backToWork(task.task.id)}
            >
              <NotApproveIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className={style.task_footer}>
        <div className={style.task_footer_text}>
          <div
            onClick={() => {
              if (setModalData) {
                setModalData({
                  id: id === 1 ? task?.taskCheckId : task?.task?.id,
                  name: task?.task?.name ?? "",
                  open: true,
                });
              }
            }}
            className={style.taskLineWithIcon}
          >
            <p>Комментарии</p>
            <CaretRightFill />
          </div>
          {task.files.length !== 0 && (
            <>
              <div
                className={style.taskLineWithIcon}
                onClick={() => setModalFiles(true)}
              >
                <p>Вложений {task.files.length}</p>
                <Download />
              </div>
              <Modal active={modalFiles} setActive={setModalFiles}>
                <ModalDownloadFiles task={task} />
              </Modal>
            </>
          )}
        </div>
        <div className={style.task_footer_status}>
          <Dot status={task?.taskStatusInfo?.dot} />
          <p
            dangerouslySetInnerHTML={{
              __html: task.taskStatusInfo.statusString,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Task;
