import { useEffect, useRef } from "react";
import cn from "classnames";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";
import { getDashboardSections } from "../../../../../app/feature/dashboard/sections";
import { useAppSelector } from "../../../../../app/store";
import style from "./style.module.scss";

const CurrentSection = () => {
  const { data } = useAppSelector(getDashboardSections);
  const { tab, sectionId } = useAppSelector(getDashboardDestinations);

  const sectionPanel = useRef<HTMLDivElement>(null);

  const foundSection: any = Object.values(data).find(
    (item: any) => item.id === sectionId
  );

  useEffect(() => {
    if (!sectionPanel.current) {
      return;
    }

    sectionPanel.current.scrollIntoView({ behavior: "smooth" });
  }, [tab]);

  if (!sectionId) {
    return null;
  }

  return (
    <div ref={sectionPanel} className={cn(style.header, style.sectionHeader)}>
      {foundSection?.title ?? ""}
    </div>
  );
};

export default CurrentSection;
