import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { view } from "./queries";

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    userView: view(builder),
  }),
  overrideExisting: false,
});

export const { useUserViewQuery } = userApi;
