import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { request } from "../../../../app/services/api/requestHandler";
import { SelectWithPopper } from "../../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";
import { ApiGetCountriesTypes } from "../../../../app/services/api/countries/countries";

export type CountriesTypeItem = {
  title: string;
  id: string;
};

export type CountriesTypeProps = ComponentPropsType<{
  changeHandler?: (type: CountriesTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
  paperOptions?: { [key: string]: any };
  label?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}>;

export const CountriesType: React.FC<CountriesTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  value,
  paperOptions,
  label = "",
  disabled,
  error,
  helperText,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<CountriesTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<
    CountriesTypeItem | undefined
  >(undefined);

  const onLoadTypes = () => {
    request(
      ApiGetCountriesTypes(),
      (data) => {
        setAllTypes(
          data.map(
            (item: { code: string; title: string; title_full: string }) => ({
              title: item.title,
              id: item.code,
            })
          )
        );
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса стран", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    onLoadTypes();
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => String(item.id) === String(value));
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  return (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      searchHandler={onSearch}
      options={allTypes}
      label={label}
      variant={classNames(variant)}
      disableClear={true}
      fullWidth={true}
      paperOptions={paperOptions}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};
