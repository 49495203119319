import { useRef } from "react";
import { RefType } from "./ThreeDotButton";

export const useThreeDotButton = () => {
  const threeDotButtonRef = useRef<RefType>(null);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  return { threeDotButtonRef, closeThreeDot } as const;
};
