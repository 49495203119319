import React, { useState } from "react";
import { deleteHandler, actuallyHandler } from "../UploaderUtils/utils";
import { UploaderFile } from "../UploaderFile/UploaderFile";
import { PreviewType } from "../UploaderTypes/UploaderTypes";

export const UploaderPreview = ({
  meta,
  fileWithMeta,
  uploadAsActive = false,
  handleUpdate = () => {},
  handleRemove = () => {},
  setListLength,
  fileHandler,
}: PreviewType) => {
  const { name, percent, status } = meta;
  const [visibility, setVisibility] = useState(uploadAsActive);

  const { remove, xhr, file } = fileWithMeta;

  const setChecked = () => {
    setVisibility(!visibility);
  };

  let FILE_ID = "";
  let FILE_URL = "";
  let SVG_ICON = "";

  if (!xhr?.response) {
    return (
      <UploaderFile
        url={FILE_URL}
        name={name}
        svgIcon={SVG_ICON}
        isPreview={true}
        status={status}
        percent={percent}
        visibility={visibility}
        handleChange={() => {
          setChecked();
          handleUpdate();
        }}
        handleDelete={() => {
          deleteHandler(FILE_ID);
          remove();

          if (setListLength) {
            setListLength((p) => p - 1);
          }

          if (fileHandler) {
            fileHandler([]);
          }
        }}
      />
    );
  }

  try {
    const response = JSON.parse(xhr?.response);
    if (response) {
      FILE_ID = response.id;
      FILE_URL = response.url;
      SVG_ICON = response.svgIcon;
    }
  } catch (e) {
    console.log(e);
  }

  return (
    <UploaderFile
      url={FILE_URL}
      name={name}
      svgIcon={SVG_ICON}
      isPreview={true}
      status={status}
      percent={percent}
      handleChange={() => {
        actuallyHandler(FILE_ID, setChecked, handleUpdate);
      }}
      visibility={visibility}
      handleDelete={() => {
        deleteHandler(FILE_ID, handleRemove, handleUpdate);
        remove();
      }}
    />
  );
};
