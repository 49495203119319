import React, { memo, ReactNode } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import { ColorType, selectColor } from "src/utils/selectColor";
import { IChecks } from "src/app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import { ApiPrimaryFileType } from "src/types/api/primaryTypes/apiPrimaryFileType";
import { DefaultFileIcon } from "../../features/Uploader/UploaderUtils/UI";
import cls from "./StatusCardWrapper.module.scss";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import SubTaskCard from "./Additional/SubTaskCard";
import { SetState } from "../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "../../pages/Project/View/CheckTasks/CheckTaskCard";

export type TRemark = {
  code: string;
  remark: string;
};

interface IStatusCardWrapperProps {
  setModalData?: SetState<TModalData>;
  color: ColorType;
  children: ReactNode;
  viewStatus?: boolean;
  viewFiles?: boolean;
  viewComment?: boolean;
  viewChecks?: boolean;
  viewSubTasks?: boolean;
  date?: string;
  comment?: string;
  status: any;
  files?: ApiPrimaryFileType[];
  checks?: IChecks[];
  classname?: string;
  downloadAllLink?: string;
  subTasks?: any;
  remarks?: TRemark[];
}
export const StatusCardWrapper = memo(
  ({
    children,
    color,
    viewComment,
    viewFiles,
    viewStatus,
    viewChecks,
    viewSubTasks,
    date,
    comment,
    status,
    files,
    checks,
    classname,
    downloadAllLink,
    setModalData,
    subTasks,
    remarks = [],
  }: IStatusCardWrapperProps) => {
    let renderFooter = null;
    const isBadStatus = color === "pirs-red" || color === "error";

    if (viewSubTasks) {
      renderFooter = (
        <>
          <h3 className={cls.subTasks}>Подзадачи</h3>
          {subTasks.objects.map((task: any) => (
            <SubTaskCard setModalData={setModalData} key={task.id} {...task} />
          ))}
        </>
      );
    }

    if (viewStatus) {
      renderFooter = (
        <div className={classNames(cls.status, isBadStatus && cls.badStatus)}>
          <p>{status.value}</p>
          {date && <p className={cls.status_date}>{date}</p>}
        </div>
      );
    }

    if (viewComment) {
      renderFooter = (
        <div className={cls.comment}>
          <p className={cls.comment_title}>Комментарий</p>
          <p className={cls.comment_text}>{comment}</p>
          {Boolean(remarks.length) && (
            <div className={cls.remarks_list}>
              <p className={cls.comment_title}>Замечания</p>
              {remarks.map(({ code, remark }: TRemark) => (
                <div className={cls.remark}>
                  <b>{code}</b>
                  <p>{remark}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }

    if (viewFiles) {
      renderFooter = (
        <div className={cls.files}>
          <h3>Вложения</h3>
          <div className={cls.files_container}>
            {files &&
              files.map((file: any) => (
                <TextLink
                  variant={cls.link}
                  key={file.id}
                  url={file.url}
                  redirect
                >
                  <div
                    className={cls.files_svg}
                    title="скачать"
                    key={file.name}
                  >
                    {file.svgIcon ? parse(file.svgIcon) : <DefaultFileIcon />}
                    <p>{file.name}</p>
                  </div>
                </TextLink>
              ))}
          </div>
          {downloadAllLink && (
            <div className={cls.downloadAll}>
              <TextLink url={downloadAllLink} redirect target={"_blank"}>
                Скачать все
              </TextLink>
            </div>
          )}
        </div>
      );
    }

    if (viewChecks) {
      renderFooter = (
        <div className={cls.checksWrapper}>
          <h3>Проверки</h3>
          <div className={cls.checks}>
            {checks &&
              checks.map((check) => (
                <div
                  className={cls.checks_check}
                  key={check.header}
                  style={{ background: selectColor(check.status.color) }}
                >
                  <p title={check.title}>{check.header}</p>
                  <p>{check.status.value}</p>
                </div>
              ))}
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames(cls.wrapper, classname)}
        style={{
          background: selectColor(viewStatus ? color : "grey"),
        }}
      >
        <div className={classNames(cls.card, viewStatus && cls.height)}>
          {children}
        </div>
        <div
          className={classNames(cls.footer, viewSubTasks && cls.equalPadding)}
        >
          {renderFooter}
        </div>
      </div>
    );
  }
);
