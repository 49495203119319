import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NothingFound } from "src/FSD/shared/uiKit/v2/NothingFound/NothingFound";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getProjectProcessInitialDataIsLoad,
  getProjectProcessInitialDataProcesses,
  getProjectProcessInitialDataProjects,
  getProjectProcessInitialDataSourceData,
} from "../../../../app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessInitialData";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import cls from "./InitialData.module.scss";
import { Project } from "./Project/Project";
import { Processes } from "./Processes/Processes";
import { SourceData } from "./SourceData/SourceData";
import { SkeletonProjectProcessInitialData } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import {
  getProjectChecklist,
  setChecklistType,
} from "../../../../app/feature/project/projectChecklist";
import { HorizontalTabsPanel } from "../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { SkeletonInitialData } from "../../../UI/components/Project/Skeleton";
import { clearOpenSourceData } from "../../../../app/feature/ProjectProcessView/InitialData/slice/projectProcessInitialData";

export const InitialData = () => {
  const dispatch = useAppDispatch();

  const infoId = useSelector(getProjectProcessInfoId);
  const processes = useAppSelector(getProjectProcessInitialDataProcesses);
  const projects = useAppSelector(getProjectProcessInitialDataProjects);
  const sourceData = useAppSelector(getProjectProcessInitialDataSourceData);
  const isLoad = useAppSelector(getProjectProcessInitialDataIsLoad);

  const [activeTab, setActiveTab] = useState<number | string>(1);

  const { data } = useAppSelector(getProjectChecklist);

  const setActiveTabMemo = useCallback((tabId: number | string) => {
    setActiveTab(tabId);
  }, []);

  useEffect(() => {
    dispatch(setChecklistType(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessInitialData(infoId));
    }
  }, [infoId]);

  const DataByProcess = () => (
    <>
      {projects &&
        projects.map(([id, project]) => (
          <Project key={id} {...project} id={id} />
        ))}
      <Processes
        canAdd={processes?.canAdd}
        name={processes?.name}
        files={processes?.files}
      />
    </>
  );

  useEffect(() => {
    dispatch(clearOpenSourceData());
  }, [activeTab]);

  if (isLoad) {
    return <SkeletonProjectProcessInitialData />;
  }

  return (
    <div className={cls.wrapper}>
      <HorizontalTabsPanel
        tabs={data.tabs}
        components={[]}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        className={cls.tabs}
        skeleton={<></>}
      />
      {isLoad ? (
        <SkeletonInitialData />
      ) : (
        <>
          {activeTab === "by-process" && <DataByProcess />}
          {sourceData?.[activeTab] && (
            <>
              {sourceData[activeTab]?.length ? (
                sourceData[activeTab].map((el, index) => (
                  <SourceData
                    key={el.name}
                    name={el.name}
                    projectId={el.projectId}
                    parentId={el.parentId}
                    all={el.all}
                    inWork={el.inWork}
                    dot={el.dot}
                    index={index}
                    type={activeTab}
                  />
                ))
              ) : (
                <NothingFound />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
