import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";

export const mapTask = (payload: ApiTaskResponses.View) => ({
  projectName: payload.project!.parent?.name || payload.project!.name,
  objectName: payload.project!.parent ? payload.project!.name : null,
  files: payload.files,
  comments: payload.comments!,
  checks: payload.checks!,
  taskUrl: payload.taskUrl!,
});
