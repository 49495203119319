import { useSnackbar as useNotistackSnackbar } from "notistack";

export function useSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const errorSnackbar = (message: string) =>
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 5000,
    });

  const successSnackbar = (message: string) =>
    enqueueSnackbar(message, {
      variant: "success",
      autoHideDuration: 5000,
    });

  return {
    enqueueSnackbar,
    closeSnackbar,
    errorSnackbar,
    successSnackbar,
  };
}
