import React, { memo } from "react";
import { IconButton } from "@mui/material";
import cls from "./ConclusionCard.module.scss";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { iconBtnSx, palette } from "../../../../../../styles/restyle";
import { ExpertiseConclusionTypes } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/types/expertiseConclusionTypes";
import { DeleteButtonIcon } from "../../../../../newUI/SVG";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getCanProcessInfo } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchDeleteConclusion } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/services/fetchDeleteConclusion";
import { fetchConclusionExpertise } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/services/fetchConclusionExpertise";

export const ConclusionCard = memo(
  ({ num, date, file, fail, id }: ExpertiseConclusionTypes) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const can = useAppSelector(getCanProcessInfo);
    const failText = fail ? "Отрицательное" : "Положительное";

    async function deleteConclusion() {
      const result = window.confirm(
        "Вы уверены, что хотите удалить данное заключение?"
      );
      if (result) {
        await dispatch(fetchDeleteConclusion(id));
        if (processId) {
          dispatch(fetchConclusionExpertise(processId));
        }
      }
    }

    return (
      <div className={cls.card}>
        <div className={cls.card_name}>
          <p>{`Заключение №${num} от ${date}`}</p>
          <span className={fail ? cls.card_fail : cls.card_notFail}>
            ({failText})
          </span>
        </div>

        <div className={cls.card_btn}>
          <TextLink url={`/file/get?id=${file.id}`} target={"_blank"} redirect>
            <CustomButton width={160} background={palette.green}>
              Скачать
            </CustomButton>
          </TextLink>
          {can?.edit && (
            <IconButton sx={iconBtnSx} onClick={deleteConclusion}>
              <DeleteButtonIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);
