import { forwardRef } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { useSelectBankAccountsUserType } from "../lib/hooks/useSelectBankAccountUserType";
import { Nullable } from "../../../../../types/generics";
import { TSelectItem } from "../../../../../components/features/Forms/SetGipForm/types";

type Props = {
  userType?: Nullable<TSelectItem>;
  setValue?: any;
  bankAccountId?: number;
  isBankAccount: boolean;
} & Omit<SelectProps, "label" | "options">;

export const SelectBankAccountsUserType = forwardRef(
  (
    {
      value,
      userType,
      setValue,
      bankAccountId,
      isBankAccount = true,
      ...props
    }: Props,
    ref
  ) => {
    const { bankAccount, isLoadingBankAccount } = useSelectBankAccountsUserType(
      {
        userType,
        setValue,
        bankAccountId,
        isBankAccount,
      }
    );

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={bankAccount}
        isLoading={isLoadingBankAccount}
        label="Банковский счет"
      />
    );
  }
);
