import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setChecklistType } from "src/app/feature/project/projectChecklist";
import {
  TabChildrenHeader,
  useTabsPanelContext,
} from "src/FSD/widgets/v2/TabsPanel";
import { fetchProjectView } from "src/app/feature/ProjectView/thunks";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { fetchProjectViewInitialData } from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { HeaderActions, TTabValue } from "./HeaderActions/HeaderActions";
import { InitialDataCard } from "./InitialDataCard";
import { Statistics } from "./Statistics/Statistics";
import {
  InitialDataSkeletonBody,
  SkeletonInitialDataFullPage,
} from "./SkeletonInitialData";
import styles from "./style.module.scss";

export const InitialData = () => {
  const { initialDataInterface, isLoad } = useSelector(getProjectInitialData);

  const { currentChildTab } = useTabsPanelContext();
  const [activeTab, setActiveTab] = useState<TTabValue>(0);

  useEffect(() => {
    const tabMapping: { [key: string]: TTabValue } = {
      common: 4,
      otr: 1,
      project: 2,
      work: 3,
    };

    setActiveTab(tabMapping[currentChildTab] || 0);
  }, [currentChildTab]);

  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (params.id && activeTab) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          type: activeTab,
        })
      );
    }
  }, [dispatch, params.id, activeTab]);

  useEffect(() => {
    dispatch(setChecklistType(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  if (isLoad && !initialDataInterface) {
    return <SkeletonInitialDataFullPage />;
  }

  if (initialDataInterface) {
    return (
      <div className={styles.wrapper}>
        <TabChildrenHeader
          title="Исходные данные по проекту"
          media={<HeaderActions activeTab={activeTab} />}
        />
        {isLoad ? (
          <InitialDataSkeletonBody />
        ) : (
          <>
            <Statistics />
            <ScrollWrap height={"876px"} classname={styles.wrapper}>
              {initialDataInterface.map((el, index) => (
                <InitialDataCard
                  key={el.name}
                  name={el.name}
                  all={el.all}
                  inWork={el.inWork}
                  dot={el.dot}
                  parentId={el.id}
                  index={index}
                />
              ))}
            </ScrollWrap>
          </>
        )}
      </div>
    );
  }

  return <div></div>;
};
