import React from "react";
import classNames from "classnames";
import style from "../style.module.scss";
import { UserSkeleton } from "./UserSkeleton/UserSkeleton";

export const UserSkeletonList: React.FC<{ theme: string; long?: boolean }> = ({
  long,
}) => {
  return (
    <div
      className={classNames(
        style.userList,
        style.userList_skeleton,
        long && style.userSkeleton__long
      )}
    >
      <UserSkeleton />
      <UserSkeleton />
      <UserSkeleton />
      <UserSkeleton />
      <UserSkeleton />
      <UserSkeleton />
    </div>
  );
};
