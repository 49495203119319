import React, { memo } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { palette, textFieldSX } from "src/styles/restyle";
import { config } from "src/app.cofig";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessCreateWork } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessCreateWork";
import { fetchProcessWork } from "src/app/feature/ProjectProcessView/Work/services/fetchProcessWork";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import cls from "../../Info/ModalRequest/ModalRequest.module.scss";
import { Uploader } from "../../../../features/Uploader/Uploader";

type Inputs = {
  name: string;
  description: string;
  upload_files: any;
};

const schema = Yup.object().shape({
  name: Yup.string().required("Поле обязательно для заполнения").max(200),
});

interface ModalCreateWorkProps {
  closeModal: () => void;
}
export const ModalCreateWork = memo(({ closeModal }: ModalCreateWorkProps) => {
  const infoId = useAppSelector(getProjectProcessInfoId);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const formData = new FormData();
    formData.append("name", dataValues.name);
    formData.append("description", dataValues.description);
    dataValues.upload_files.forEach((file: BinaryType) => {
      formData.append("upload_files", file);
    });
    if (infoId) {
      await dispatch(
        fetchProjectProcessCreateWork({ id: infoId, data: formData })
      );
      dispatch(fetchProcessWork(infoId));
    }
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
      <h1>Создание задачи</h1>
      <TextField
        {...register("name")}
        {...textFieldSX}
        label="Наименование"
        defaultValue={""}
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : null}
      />
      <TextField
        {...textFieldSX}
        multiline
        minRows={2}
        label="Описание"
        {...register("description")}
        placeholder="Введите текст"
        error={!!errors.description}
        helperText={errors.description ? errors.description.message : null}
      />
      <Uploader
        canBeEdited
        autoUpload={false}
        identifier={null}
        postUrlString={(id) =>
          `${config.localDomain}/v1/task/add-file?id=${id}`
        }
        fileHandler={(data: any) => {
          setValue("upload_files", data);
        }}
      />
      <CustomButton
        className={cls.form_submit}
        background={palette.blue}
        width={160}
        type="submit"
      >
        Создать
      </CustomButton>
    </form>
  );
});
