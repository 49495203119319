import React, { useState, useEffect } from "react";
import { Skeleton, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { TForm } from "src/app/feature/unsaved/unsavedController";
import { Dot } from "src/components/newUI/Dot/Dot";
import style from "./style.module.scss";

export const areFormsEqual = (inbound: TForm, outbound: TForm): boolean =>
  JSON.stringify(inbound) === JSON.stringify(outbound);

const SkeletonContents = () => (
  <Skeleton variant="rectangular" className={style.itemSkeleton} />
);

type TabTogglerProps = {
  currentTab: boolean;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  item: any;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  onSwitching?: (tab: string) => boolean;
};

export const TabToggler: React.FC<TabTogglerProps> = ({
  currentTab,
  setCurrentTab,
  item,
  setMenuOpen,
  mobile,
  setCurrentSection,
  setEditing,
  disabled,
  onSwitching,
}) => {
  const handleClick = () => {
    if (disabled || currentTab) {
      if (currentTab && !mobile) {
        setMenuOpen(false);
      }

      return;
    }

    if (onSwitching) {
      const canSwitch = onSwitching(item.url);
      if (!canSwitch) {
        return;
      }
    }

    if (item.redirect) {
      window.location.href = item.url;
    } else {
      setCurrentTab(item.url);
      setCurrentSection(item.title);
      setEditing(false);
      if (!mobile) {
        setMenuOpen(false);
      }
    }
  };

  const customStyle = currentTab
    ? { background: "#3B93AF", color: "white" }
    : disabled
      ? { opacity: "0.5" }
      : {};

  return (
    <div onClick={handleClick} className={style.toggler} style={customStyle}>
      <div className={style.icon}>{item.icon}</div>
      <div className={style.title}>{item.title}</div>
      {item.incomplete && item.url !== "checklist" && (
        <Dot status={"warning"} size={8} />
      )}
      {item.incomplete && item.url === "checklist" && (
        <Dot status={"error"} size={8} />
      )}
    </div>
  );
};

type TabsPanelProps = {
  tabsInfo: any;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  components: any;
  isContentLoad: boolean;
  stepByStep?: boolean;
  onSwitching?: (tab: string) => boolean;
  showTabs?: boolean;
  setDefaultTab?: () => void;
};

const TabsPanel: React.FC<TabsPanelProps> = ({
  isContentLoad,
  tabsInfo,
  currentTab,
  setCurrentTab,
  setEditing = () => {},
  components,
  stepByStep,
  onSwitching,
  showTabs = true,
  setDefaultTab,
}) => {
  const MOBILE_SIZE = 1140;
  const [mobile, setMobile] = useState(window.innerWidth > MOBILE_SIZE);
  const [menuIsOpen, setMenuOpen] = useState(true);

  const [currentSection, setCurrentSection] = useState("");
  const sx = { color: "white" };
  const skeletonArray = new Array(5).fill(null);

  const handleOpenMenu = () => {
    setMenuOpen(true);
    setEditing(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const toMobile = window.innerWidth > MOBILE_SIZE;
      setMenuOpen(toMobile);
      setMobile(toMobile);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const tab = tabsInfo?.find((item: any) => item?.url === currentTab);
    if (!tab && setDefaultTab && tabsInfo.length !== 0) {
      setDefaultTab();
    }
    if (tab && tab?.title) {
      setCurrentSection(tab?.title);
    }
  }, [currentTab, tabsInfo]);

  return (
    <>
      {menuIsOpen ? (
        <div className={style.wrapper}>
          {isContentLoad
            ? skeletonArray.map((_: any, i: number) => (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  className={style.togglerSkeleton}
                />
              ))
            : showTabs &&
              tabsInfo.map((item: any) => {
                const disabled = stepByStep && item.disabled;
                return (
                  <TabToggler
                    key={item.url}
                    currentTab={currentTab === item.url}
                    setCurrentTab={setCurrentTab}
                    disabled={disabled}
                    item={item}
                    mobile={mobile}
                    setMenuOpen={setMenuOpen}
                    setCurrentSection={setCurrentSection}
                    setEditing={setEditing}
                    onSwitching={onSwitching}
                  />
                );
              })}
        </div>
      ) : (
        showTabs && (
          <div onClick={handleOpenMenu} className={style.temp}>
            <div>{currentSection}</div>
            <IconButton>
              <MenuIcon sx={sx} />
            </IconButton>
          </div>
        )
      )}
      {isContentLoad ? <SkeletonContents /> : components}
    </>
  );
};

export default TabsPanel;
