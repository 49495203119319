import { InfoType, UnsavedType } from "./types";

export const initialInfo: InfoType = {
  name: "",
  name_for_doc: "",
  code: "",
  date_start: "",
  date_end: "",
  design_type: {
    title: "",
    id: "",
  },
  author: { id: "" },
  author_type: "",
  building_function_id: "",
  building_function_type: 0,
  parentId: null,
  expertise_type: { title: "Не требуется", id: 0, num: "" },
  can: {
    update: false,
    edit: true,
  },
  construction_type_id: {
    id: "",
    title: "",
  },
  expertise: [],
  advance_pay: "",
  pay1: "",
  pay2: "",
  pay_limit: "",
  country_code: "",
  region_code: "",
  description: "",
  dependencies: "",
  location: "",
  status: "",
  value: [],
  fields: [],
  a: "",
  b: "",
  c: "",
};

export const simpleList = [
  {
    name: "info",
    canFinish: false,
  },
  {
    name: "sections",
    canFinish: false,
  },
  {
    name: "upload",
    canFinish: true,
  },
];

export const standardList = [
  {
    name: "info",
    canFinish: false,
  },
  {
    name: "sections",
    canFinish: false,
  },
  {
    name: "upload",
    canFinish: false,
  },
  {
    name: "settings",
    canFinish: true,
  },
  {
    name: "contract",
    canFinish: true,
  },
];

export const simpleTypes = [4, 5];

export const initialUnsaved: UnsavedType = {
  info: false,
  sections: false,
  files: false,
  settings: false,
};
