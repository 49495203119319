import React, { memo } from "react";
import classNames from "classnames";
import cls from "./HeaderCount.module.scss";

interface HeaderCountProps {
  status: boolean;
  text: string;
  count?: number;
  setStatus: () => void;
}
const CheckSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.716 3.40144C12.9745 3.14521 13.3234 3.00101 13.6874 3.00001C14.0513 2.999 14.4011 3.14128 14.661 3.39608C14.9209 3.65088 15.07 3.99773 15.0762 4.36164C15.0824 4.72555 14.9452 5.07728 14.6941 5.34078L7.32093 14.5573C7.19421 14.6938 7.04127 14.8033 6.87125 14.8793C6.70123 14.9553 6.51762 14.9963 6.33141 14.9998C6.1452 15.0032 5.9602 14.9691 5.78748 14.8994C5.61476 14.8297 5.45787 14.7259 5.32618 14.5942L0.440892 9.70707C0.304793 9.58025 0.195632 9.42732 0.11992 9.2574C0.0442087 9.08748 0.00349728 8.90405 0.00021558 8.71805C-0.00306612 8.53205 0.0311491 8.3473 0.100819 8.17481C0.17049 8.00232 0.274188 7.84564 0.405729 7.7141C0.537269 7.58256 0.693956 7.47886 0.866443 7.40919C1.03893 7.33952 1.22368 7.3053 1.40968 7.30858C1.59568 7.31186 1.77911 7.35258 1.94903 7.42829C2.11895 7.504 2.27188 7.61316 2.3987 7.74926L6.2663 11.615L12.6809 3.44208C12.6923 3.42777 12.7047 3.4142 12.7178 3.40144H12.716Z"
      fill="#0AA699"
    />
  </svg>
);

export const HeaderCount = memo(
  ({ status, count, setStatus, text }: HeaderCountProps) => {
    return (
      <div
        className={classNames(cls.count, status && cls.activeStats)}
        onClick={setStatus}
      >
        {status && (
          <span>
            <CheckSvg />
          </span>
        )}
        <p>{text}</p>
        <span>{count}</span>
      </div>
    );
  }
);
