// Todo: компонент перенесен в src/FSD/shared/uiKit/v2/Select
import React from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  ThemeProvider,
  ListItemText,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { textFieldSX } from "../../../../styles/restyle";
import { TAnyObject } from "../../../pages/Organisation/tabs/Info/types";

const progressColor = "rgb(59, 147, 175)";

export type MyFindSelectProps = ComponentPropsType<{
  options: TAnyObject[];
  label: string;
  id?: string;
  isLoading?: boolean;
  defaultValue?: TAnyObject | null;
  changeHandler?: (event: any, value: any, reason: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  searchHandler?: (val: string) => void;
  value?: any | undefined;
  disableClear?: boolean;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  forNewDesign?: boolean;
  editing?: boolean;
  newDesign?: boolean;
  newLabel?: string;
  placeholder?: string;
}>;

const componentProps = {
  paper: {
    sx: {
      color: "black",
    },
  },
};

// todo -> в разработке
export const Select = ({
  options,
  label,
  id = "",
  variant = "",
  isLoading = false,
  changeHandler = () => {},
  error = false,
  helperText = "",
  name = "",
  searchHandler = () => {},
  value = undefined,
  disableClear = false,
  size,
  disabled,
  newDesign,
  newLabel,
  placeholder,
}: MyFindSelectProps) => {
  const newTheme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            background: "#1A2734",
            padding: "10px 10px",
          },
          option: {
            "&.MuiAutocomplete-option": {
              "&:not(:last-child)": {
                borderBottom: "1px solid #101920",
              },
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: "400",
              color: "#D5D6DE",
            },
          },
        },
      },
    },
  });

  const defaultTheme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            background: "#fff",
            minWidth: "calc((100vw - 50px) / 5)",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={newDesign ? newTheme : defaultTheme}>
      <Autocomplete
        id={id}
        onChange={changeHandler}
        size={size ?? "small"}
        options={options}
        className={variant}
        getOptionLabel={(option: any) => option.title}
        componentsProps={componentProps}
        closeText={"Закрыть"}
        clearText={"Очистить"}
        defaultValue={undefined}
        openText={"Открыть"}
        loading={isLoading}
        disablePortal
        loadingText={"Загрузка"}
        value={value}
        ListboxProps={{
          style: {
            maxHeight: "360px",
          },
        }}
        disabled={disabled || isLoading}
        disableClearable={disableClear}
        renderOption={(props, item) => {
          const marginLeft = item?.level ? item.level * 25 : 0;

          return (
            <li {...props} style={{ marginLeft }} key={item.id}>
              <ListItemText>{item.title}</ListItemText>
            </li>
          );
        }}
        renderInput={(params: any) => (
          <div className={style.selectField}>
            {isLoading && (
              <div className={style.loading}>
                <CircularProgress sx={{ color: progressColor }} />
              </div>
            )}
            {disabled || isLoading ? (
              <>
                {!label && <p className={style.newLabel}>{newLabel}</p>}
                <TextField
                  error={error}
                  helperText={helperText}
                  name={name}
                  {...params}
                  className={variant}
                  InputLabelProps={{ shrink: true }}
                  value={(value && value.title) || undefined}
                  onChange={(e) => {
                    searchHandler(e.currentTarget.value);
                  }}
                  InputProps={{}}
                  label={label}
                  placeholder={placeholder}
                  {...textFieldSX}
                />
              </>
            ) : (
              <>
                {!label && <p className={style.newLabel}>{newLabel}</p>}
                <TextField
                  error={error}
                  helperText={helperText}
                  name={name}
                  {...params}
                  className={variant}
                  InputLabelProps={{ shrink: true }}
                  value={(value && value.title) || undefined}
                  onChange={(e) => {
                    searchHandler(e.currentTarget.value);
                  }}
                  label={label}
                  placeholder={placeholder}
                  {...textFieldSX}
                />
              </>
            )}
          </div>
        )}
      />
    </ThemeProvider>
  );
};
