import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";
import { ProjectSetGipPayloadType } from "../../../../types/stateTypes/events/eventsPayloadTypes";

/*
  Управление показом попапа формы поиска ГИПа на проект
 */

export const showEventSetGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventSetGipProject: {
      isShow: true,
      data: {
        ...state.eventSetGipProject.data,
      },
    },
  };
};

export const closeEventSetGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventSetGipProject: {
    isShow: false,
    data: {
      ...state.eventSetGipProject.data,
    },
  },
});

export const setEventDataGipReducer = (
  state: EventEmitterEventType,
  action: {
    payload: ProjectSetGipPayloadType;
  }
): EventEmitterEventType => ({
  ...state,
  eventSetGipProject: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorEESetGipProjectIsShow = (state: StateType) =>
  state.eventEmitter.eventSetGipProject.isShow;
