import React from "react";
import classNames from "classnames";

import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";

export type BimProps = ComponentPropsType<unknown>;

export const BiM: React.FC<BimProps> = ({ theme = "dark", variant = "" }) => {
  return <p className={classNames(style.bim, variant, style[theme])}>BiM</p>;
};
