import React from "react";
import { ButtonProps as MuiButtonProps, Button } from "@mui/material";
import classNames from "classnames";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

export type SortButtonType = string;

export type ButtonUiProps = {
  posVariant?: string;
  theme?: "old" | "dark" | "light";
  sortType: SortButtonType;
  text: string;
  clickHandler?: (sortType: SortButtonType, isUp: boolean) => void;
  isUp: boolean;
  isShow: boolean;
  isDisable: boolean;
};

const sx = {
  border: "1px solid",
  borderColor: "#9dafbd",
  color: "#9dafbd",
};

export const SortButton: React.FC<
  ButtonUiProps & Omit<MuiButtonProps, keyof ButtonUiProps>
> = (props) => {
  const {
    posVariant = "",
    sortType,
    text,
    clickHandler = () => {},
    isUp,
    isShow,
    className,
    isDisable,
  } = props;
  const onClick = () => {
    if (isDisable) return;
    if (!isShow) {
      clickHandler(sortType, false);
      return;
    }
    clickHandler(sortType, !isUp);
  };
  const direction = isUp ? <ExpandLess /> : <ExpandMore />;

  return (
    <Button
      className={classNames(posVariant, className)}
      onClick={onClick}
      endIcon={isShow ? direction : <></>}
      sx={sx}
    >
      {text}
    </Button>
  );
};
