import { FC } from "react";
import { TextField } from "@mui/material";
import style from "../ProcessPublication/style.module.scss";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import { useAppDispatch } from "../../../../../../app/store";
import { setExecutorTypeForm } from "../../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessInfo";
import { textFieldSX } from "../../../../../../styles/restyle";

const Info: FC<any> = ({
  watch,
  userTypes,
  executorBanks,
  setValue,
  register,
  errors,
  isUserTypesLoading,
  isUserBanksLoading,
}) => {
  const dispatch = useAppDispatch();

  const formedUserTypes = (userTypes ?? []).map(
    ({ id, listName: title }: any) => ({
      id,
      title,
    })
  );

  const formedExecutorBanks = (executorBanks ?? []).map(
    ({ id, name: title }: any) => ({
      id,
      title,
    })
  );

  const watchUserType = watch("userType");
  const watchBankAccountId = watch("bankAccount");

  return (
    <>
      <p>Данные об исполнителе</p>
      <div className={style.requestFields}>
        <Select
          variant={style.field}
          value={watchUserType}
          label="Форма собственности"
          changeHandler={(_, value) => {
            setValue("userType", value);
            dispatch(setExecutorTypeForm(value.id));
          }}
          disableClear
          options={formedUserTypes}
          isLoading={isUserTypesLoading}
        />
        <Select
          variant={style.field}
          value={watchBankAccountId}
          label="Банковский счет"
          changeHandler={(_, value) => {
            setValue("bankAccount", value);
          }}
          disableClear
          options={formedExecutorBanks}
          error={Boolean(errors.bankAccount && !isUserBanksLoading)}
          helperText={!isUserBanksLoading && errors.bankAccount?.message}
          isLoading={isUserBanksLoading}
        />
        <TextField
          {...textFieldSX}
          {...register("content")}
          multiline
          label="Содержание заявки"
          minRows={4}
        />
      </div>
    </>
  );
};

export default Info;
