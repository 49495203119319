import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import {
  ApiProcessMaterial,
  ApiProcessMaterialFirms,
} from "../../../../services/api/projectProcess/material";
import { ProjectProcessMaterialsTypes } from "../types/projectProcessMaterialsTypes";
import { ProjectProcessMaterialsFirmTypes } from "../types/projectProcessMaterialsFirmTypes";

export const fetchProcessMaterial = createAsyncThunk<
  ProjectProcessMaterialsTypes[],
  number
>("materialProcess/fetchProcessMaterial", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProcessMaterial(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});

export const fetchProcessMaterialDuplicate = createAsyncThunk<
  ProjectProcessMaterialsTypes[],
  number
>("materialProcess/fetchProcessMaterialDuplicate", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProcessMaterial(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});

export const fetchProcessMaterialFirms = createAsyncThunk<
  ProjectProcessMaterialsFirmTypes[],
  number
>("materialProcess/fetchProcessMaterialFirms", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProcessMaterialFirms(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
