import { DragDropContext } from "react-beautiful-dnd";
import cn from "classnames";
import { KanbanModel } from "src/FSD/entities/kanban";
import { Select, TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import styles from "./Kanban.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { KanbanPending } from "./KanbanPending";
import { useKanbanDnD } from "../lib/hooks/useKanbanDnD/useKanbanDnD";
import { useScrollControls } from "../lib/hooks/useScrollControls";
import { RowTasksList } from "./RowTasksList";
import { RowTasksHeader } from "./RowTasksHeader";
import { AllTasksList } from "./AllTasks";
import { groupsList } from "../lib/constants/groupsList";

const KanbanDesktop = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(KanbanModel.getIsViewPending);

  const { handleOnDragEnd } = useKanbanDnD();

  const { followScrollTop, followScrollBottom } = useScrollControls();

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const handleChangeGroupByValue = (_: Event, groupByValue: TSelectItem) => {
    if (groupByValue.id === groupBy.id) {
      return;
    }

    dispatch(KanbanModel.setGroupByValue(groupByValue));
  };

  if (isLoading) {
    return <KanbanPending />;
  }

  return (
    <>
      <div
        className={cn(
          styles.columns,
          followScrollTop && styles.sticky,
          followScrollBottom && styles.sticky_bottom
        )}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <AllTasksList />
          <div className={styles.columns__history}>
            <div
              className={cn(
                styles.columns__history_draggable,
                styles.columns__history_draggable_empty
              )}
            >
              <h2 className={styles.columns__history__header}>
                Задачи отчётного периода
              </h2>
              <Select
                value={groupBy}
                className={styles.select}
                changeHandler={handleChangeGroupByValue}
                textClassName={styles.select__text}
                options={groupsList}
                disableClear
              />
            </div>
            <RowTasksHeader />
            <RowTasksList />
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default KanbanDesktop;
