import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiRegionListNameAndCode = (findName = "") => {
  return httpRequestGet(
    `${config.localDomain}/v1/region/index?per-page=50&RegionSearch[country_code]=RU&fields=code,title&RegionSearch[title]=${findName}`
  );
};
export const ApiRegionListPage = (page: string | number = "") => {
  return httpRequestGet(
    `${config.localDomain}/v1/region/index?page=${page}&per-page=50&RegionSearch[country_code]=RU&fields=code,title`
  );
};
