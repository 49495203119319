import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { selectArrayToUrlParams } from "src/FSD/shared/lib/helpers/selectArrayToUrlParams";
import { createUrlSearchParamsFromObject } from "src/FSD/shared/lib/helpers/createUrlSearchString";
import { Undefinable } from "../../../../../types/generics";

export type TUserSearchParams = {
  userTypeId: number;
  projectId: Undefinable<number>;
  partGroups?: TSelectItem[];
  groups?: TSelectItem[];
};

export const createUserSearchParams = (params: TUserSearchParams) => {
  const urlParams: Record<string, number> = {};

  urlParams["KanbanUserSearch[userTypeId]"] = params.userTypeId;

  if (params.projectId) {
    urlParams["KanbanUserSearch[projectId]"] = params.projectId;
  }

  const partGroupsParams = selectArrayToUrlParams(
    "KanbanUserSearch[partGroupId][]",
    params.partGroups
  );

  const groupsParams = selectArrayToUrlParams(
    "KanbanUserSearch[teamId][]",
    params.groups
  );

  return createUrlSearchParamsFromObject({
    ...urlParams,
    ...partGroupsParams,
    ...groupsParams,
  });
};
