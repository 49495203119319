import { useTaskUpdateDateMutation } from "src/FSD/entities/task/api";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";
import { stringDateToDDMMYYYY } from "../../../../../../shared/lib/helpers";
import { toServerDate } from "../../../../../../shared/lib/helpers/toServerDate";
import { Nullable } from "../../../../../../../types/generics";

type TDates = {
  date: Nullable<Date>;
};

export const useDeadlines = (processWork: ApiProcessWorkResponses.View) => {
  const [updateDate, { isLoading: isDatesUpdating }] =
    useTaskUpdateDateMutation();

  const canEditDates = Boolean(processWork.task) && processWork.can!.update;

  const factDateCreate = processWork?.task?.date_create
    ? stringDateToDDMMYYYY(processWork.task.date_create)
    : "";

  const factDateEnd =
    processWork?.task?.date_deadline || processWork?.task?.date_complete
      ? stringDateToDDMMYYYY(
          processWork.task.date_complete || processWork.task.date_deadline
        )
      : "";

  const handleChangeDates = async (dataValues: TDates) => {
    if (!processWork.task) {
      return;
    }

    const data = {
      date_deadline: toServerDate(dataValues.date),
    };
    await updateDate({ id: processWork.task.id, data });
  };

  return {
    canEditDates,
    handleChangeDates,
    isDatesUpdating,
    factDateCreate,
    factDateEnd,
  } as const;
};
