import { memo } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import styles from "./ViewTaskInfo.module.scss";
import { StatusColors } from "../lib/constants/statusColors";
import { ApiTaskResponses } from "../../../../../../entities/task/model/responseTypes";

type Props = ApiTaskResponses.Check;

export const TaskCheck = memo((check: Props) => {
  return (
    <>
      <Paragraph color="light">{check.project}</Paragraph>
      <div className={styles.processes}>
        {check.checks.map(({ task, projectPart: { code } }) => {
          const background =
            StatusColors[task.status.key as keyof typeof StatusColors];

          return (
            <div className={styles.processes__code} style={{ background }}>
              {code}
            </div>
          );
        })}
      </div>
    </>
  );
});
