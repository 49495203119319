import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ComponentPropsType } from "src/types/other/components/componentPropsType";
import { palette } from "src/styles/restyle";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import style from "./style.module.scss";
import Avatar from "./subcomponents/Avatar/Avatar";
import Region from "./subcomponents/Region/Region";
import Organisation, {
  useInnerWidth,
} from "./subcomponents/Organisation/Organisation";

export type CounterProps = {
  value: string | number;
  url: string;
  title: string;
};

export type UserProps = ComponentPropsType<{
  id: number;
  avatarUrl: string;
  name: string;
  isConfirm: boolean;
  ratingValue: number;
  address: string | null;
  counterItems: CounterProps[];
  workWithBim: boolean;
  premium: boolean;
  specialItems: string[];
  aboutText: string;
  isShowLabels?: boolean;
  skills?: any;
  executorSkill?: any;
  executors?: boolean;
  customers?: boolean;
  gip?: boolean;
  providers?: boolean;
  firms?: {
    id: string;
    title: string;
  }[];
}>;

const useCounterItems = (counterItems: CounterProps[]) => {
  const requests = counterItems.find((item: any) =>
    item.title.includes("запросов")
  );
  let requestsTitle = "";
  let requestsUrl = "";
  let requestsNumber;
  if (requests) {
    requestsUrl = requests.url;
    requestsNumber = +requests.value;
    requestsTitle = `${requests.title.replace("<br>", "")}`;
  }

  const offers = counterItems.find((item: any) =>
    item.title.includes("предложений")
  );
  let offersTitle = "";
  let offersUrl = "";
  let offersNumber;
  if (offers) {
    offersUrl = offers.url;
    offersNumber = +offers.value;
    offersTitle = `${offers.title.replace("<br>", "")}`;
  }

  const active = counterItems.find(
    (item: any) => item.title === "Активных проектов"
  );
  let activeTitle = "";
  let activeUrl = "";
  let activeNumber;
  if (active) {
    activeUrl = active.url;
    activeNumber = +active.value;
    activeTitle = `${active.title}`;
  }

  const reviews = counterItems.find((item: any) => item.title === "Отзывы");
  const reviewsTitle = "";
  let reviewsUrl = "";
  let reviewsNumber;
  if (reviews) {
    reviewsUrl = reviews.url;
    reviewsNumber = +reviews.value;
  }

  const works = counterItems.find(
    (item: any) =>
      item.title === "Выполнено работ" || item.title === "Завершено"
  );
  let worksTitle = "";
  let worksUrl = "";
  let worksValue = "";
  if (works) {
    worksValue = `${works.value}`;
    worksTitle =
      `${works.title}` === "Завершено"
        ? "Выполнено проектов"
        : `${works.title}`;
    worksUrl = works.url;
  }

  const experience = counterItems.find(
    (item: any) => item.title === "Стаж в отрасли"
  );
  let experienceNumber = "";
  let experienceUrl = "";
  if (experience) {
    experienceNumber = `${experience.value}`;
    experienceUrl = `${experience.url}`;
  }

  return {
    reviewsTitle,
    reviewsUrl,
    reviewsNumber,
    worksTitle,
    worksUrl,
    worksValue,
    experienceNumber,
    experienceUrl,
    activeUrl,
    activeNumber,
    activeTitle,
    requestsUrl,
    requestsNumber,
    requestsTitle,
    offersUrl,
    offersNumber,
    offersTitle,
  };
};

export const User: React.FC<UserProps> = (props) => {
  const {
    id,
    avatarUrl,
    name,
    isConfirm,
    ratingValue,
    address,
    counterItems,
    workWithBim = false,
    skills,
    specialItems,
    customers,
    executors,
    gip,
    providers,
    executorSkill,
    firms,
  } = props;

  const rating = Number((ratingValue / 20).toFixed(2));

  const [country, region] = (address ?? "").split(", ");

  const {
    reviewsUrl,
    reviewsNumber,
    worksValue,
    worksTitle,
    worksUrl,
    experienceNumber,
    experienceUrl,
    activeUrl,
    activeNumber,
    activeTitle,
    requestsUrl,
    requestsNumber,
    requestsTitle,
    offersUrl,
    offersNumber,
    offersTitle,
  } = useCounterItems(counterItems);

  const innerWidth = useInnerWidth();

  const trimmedName =
    name.length > 30 && innerWidth < 1050
      ? `${name.substring(0, 25)}...`
      : name;

  const indicators = executors
    ? [
        {
          title: worksTitle,
          value: worksValue,
          link: worksUrl,
        },
      ]
    : customers
      ? [
          {
            title: activeTitle,
            value: activeNumber,
            link: activeUrl,
          },
          {
            title: worksTitle,
            value: worksValue,
            link: worksUrl,
          },
        ]
      : gip
        ? [
            {
              title: worksTitle,
              value: worksValue,
              link: worksUrl,
            },
          ]
        : providers
          ? [
              {
                title: offersTitle,
                value: offersNumber,
                link: offersUrl,
              },
              {
                title: requestsTitle,
                value: requestsNumber,
                link: requestsUrl,
              },
            ]
          : [];

  const data = [
    {
      avatar: avatarUrl,
      rating,
      reviews: reviewsNumber,
      link: reviewsUrl,
    },
  ];

  const regionData = {
    experience: experienceNumber,
    region: country && region ? `${country}, ${region}` : "Не указано",
    url: experienceUrl,
  };

  const specialization = useMemo(() => {
    let result: any = [];

    if (customers) {
      result = firms;
    } else if (gip) {
      result = [...specialItems];
    } else {
      result = skills ?? [];
    }

    result = result.slice(0, 5);

    if (providers) {
      result = result.slice(0, 2);
    }

    return result;
  }, [customers, firms, gip, specialItems, skills, providers]);

  return (
    <div className={style.container}>
      <div className={style.container__info}>
        <TextLink variant={style.mainLink} url={`/user/${id}?tab=info`}>
          {trimmedName as string}
        </TextLink>
        <div className={style.container__info__who}>
          <p>{isConfirm ? "Проверенный специалист" : "Специалист"}</p>
          <div style={{ backgroundColor: isConfirm ? "#3baf6a" : "#6b7d8a" }} />
        </div>
      </div>
      <div className={style.contents}>
        <Avatar individual data={data} />
        <Region showMap={customers || providers} data={regionData} />
        <Organisation
          workWithBim={workWithBim}
          text={""}
          alignRight={executors || gip}
          customers={customers}
          scalable={false}
          price={{
            number: "",
          }}
          specialization={specialization}
          indicators={indicators}
          executorSkill={executorSkill}
        />
      </div>
      <Link to={`/user/${id}?tab=info`}>
        <CustomButton
          className={style.moreButton}
          background={palette.green}
          color={"#1B252C"}
        >
          Подробнее
        </CustomButton>
      </Link>
    </div>
  );
};
