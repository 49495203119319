import React from "react";
import Rating from "@mui/material/Rating";
import { Star } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type StarsRatingProps = {
  handleChange?: any;
  value: number | string;
  max: number;
  size?: "small" | "medium" | "large";
  label?: string;
  precision?: 0.5 | 1;
  className?: string;
  style?: React.CSSProperties;
  name?: "controlled" | "read-only" | "disabled";
  readOnly?: boolean;
  disabled?: boolean;
};

export const RatingStars: React.FC<StarsRatingProps> = ({
  handleChange,
  value,
  max,
  size = "medium",
  label = "",
  precision = 1,
  className = "",
  style,
  name = "controlled",
  readOnly = false,
  disabled = false,
}) => (
  <Box
  // sx={{
  //   "& > legend": { mt: 2 },
  // }}
  >
    <Typography component="legend">{label}</Typography>
    <Rating
      name={name}
      value={+value}
      onChange={(event: any, newValue: number | null) => {
        handleChange(newValue ?? 0);
      }}
      emptyIcon={<Star style={{ ...style }} fontSize="inherit" />}
      max={max}
      readOnly={readOnly}
      disabled={disabled}
      size={size}
      className={className}
      precision={precision}
    />
  </Box>
);
