import React from "react";
import { FolderWarningIcon } from "src/components/newUI/SVG";

export type TFolderIcon = {
  dot?: boolean;
  stop?: boolean;
};

export const FolderIcon = ({ dot, stop }: TFolderIcon) => {
  let circleColor: string;
  switch (true) {
    case stop:
      circleColor = "#CE0000";
      break;
    case dot:
      circleColor = "#EEA527";
      break;
    default:
      circleColor = "transparent";
  }

  return <FolderWarningIcon circleColor={circleColor} />;
};
