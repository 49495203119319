import { useDispatch } from "react-redux";
import { C } from "../../app/feature/eventEmitter/constants";
import {
  ProcessEventExecutorPayloadType,
  ProcessPublishPayloadType,
  ProcessUnPublishPayloadType,
  ProjectSetGipPayloadType,
} from "../../types/stateTypes/events/eventsPayloadTypes";

export const useUserWorkflow = () => {
  const dispatch = useDispatch();

  class Popup {
    static error = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_ERROR,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_ERROR,
        });
      },
      set(message: string | React.ReactElement, headerText: string) {
        dispatch({
          type: C.SET_ERROR_EVENT_MESSAGE,
          headerText,
          message,
        });
      },
    };

    static processPublishTask = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_PUBLISH_TASK,
        });
      },
      close() {
        dispatch({
          type: C.ClOSE_EVENT_PUBLISH_TASK,
        });
      },
      set(data: ProcessPublishPayloadType) {
        dispatch({
          type: C.SET_EVENT_PUBLISH_DATA,
          payload: data,
        });
      },
    };

    static processUnPublishTask = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_UNPUBLISH_TASK,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_UNPUBLISH_TASK,
        });
      },
      set(data: ProcessUnPublishPayloadType) {
        dispatch({
          type: C.UNPUBLISH_EVENT_SET_TASK_ID,
          payload: data,
        });
      },
    };

    static eventAssignProcessExecutor = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_SET_EXECUTOR,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_SET_EXECUTOR,
        });
      },
      set(data: ProcessEventExecutorPayloadType) {
        dispatch({
          type: C.EVENT_SET_EXECUTOR_DATA,
          payload: data,
        });
      },
    };

    static requestRemoveGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_REQUEST_REMOVE_GIP,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_REQUEST_REMOVE_GIP,
        });
      },
    };

    static eventAssignProjectGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_SET_GIP_PROJECT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_SET_GIP_PROJECT,
        });
      },
      set(data: ProjectSetGipPayloadType) {
        dispatch({
          type: C.EVENT_SET_EXECUTOR_DATA,
          payload: data,
        });
      },
    };

    static eventRequestGipProject = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_REQUEST_GIP_PROJECT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_REQUEST_GIP_PROJECT,
        });
      },
    };

    static eventRevokeRequestGipProject = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_REVOKE_REQUEST_GIP,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_REVOKE_REQUEST_GIP,
        });
      },
    };

    static eventCloseConflictGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_CLOSE_CONFLICT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_CLOSE_CONFLICT,
        });
      },
    };

    static removeRequestSearchGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_REMOVE_REQUEST_SEARCH_GIP,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_REMOVE_REQUEST_SEARCH_GIP,
        });
      },
    };

    static searchProjectGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_SEARCH_GIP,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_SEARCH_GIP,
        });
      },
    };

    static compareProjectGip = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_COMPARE_GIP,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_COMPARE_GIP,
        });
      },
    };

    static deleteProject = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_DELETE_PROJECT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_DELETE_PROJECT,
        });
      },
    };

    static joinUserFirm = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_JOIN_USER_FIRM,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_JOIN_USER_FIRM,
        });
      },
    };

    static joinUserProject = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_JOIN_USER_PROJECT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_JOIN_USER_PROJECT,
        });
      },
    };

    static showUserAgreement = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_USER_AGREEMENT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_USER_AGREEMENT,
        });
      },
    };

    static showPrivacyPolicy = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_USER_POLICY,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_USER_POLICY,
        });
      },
    };

    static showUserRole = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_USER_ROLE,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_USER_ROLE,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_USER_ROLE_DATA,
          payload: data,
        });
      },
    };

    static editOrganisationTask = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_EDIT_ORGANISATION_TASK,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_EDIT_ORGANISATION_TASK,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_EDIT_ORGANISATION_TASK,
          payload: data,
        });
      },
    };

    static editBankAccount = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_EDIT_BANK_ACCOUNT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_EDIT_BANK_ACCOUNT,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_EDIT_BANK_ACCOUNT,
          payload: data,
        });
      },
    };

    static sendMoney = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_SEND_MONEY,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_SEND_MONEY,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_SEND_MONEY,
          payload: data,
        });
      },
    };

    static userJoin = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_USER_JOIN,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_USER_JOIN,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_USER_JOIN,
          payload: data,
        });
      },
    };

    static eventUserJoinAtUserPage = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_USER_JOIN_AT_USER_PAGE,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_USER_JOIN_AT_USER_PAGE,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_USER_JOIN_AT_USER_PAGE,
          payload: data,
        });
      },
    };

    static joinRequest = {
      open() {
        dispatch({
          type: C.SHOW_EVENT_REQUEST_JOIN,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_EVENT_REQUEST_JOIN,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_REQUEST_JOIN,
          payload: data,
        });
      },
    };

    static createTeam = {
      open() {
        dispatch({
          type: C.SHOW_CREATE_TEAM,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_CREATE_TEAM,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_CREATE_TEAM,
          payload: data,
        });
      },
    };

    static manageTeammate = {
      open() {
        dispatch({
          type: C.SHOW_MANAGE_TEAMMATE,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_MANAGE_TEAMMATE,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_MANAGE_TEAMMATE,
          payload: data,
        });
      },
    };

    static eventUserInviteToTeam = {
      open() {
        dispatch({
          type: C.SHOW_INVITE_TO_TEAM,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_INVITE_TO_TEAM,
        });
      },
      set(data: any) {
        dispatch({
          type: C.EVENT_SET_INVITE_TO_TEAM,
          payload: data,
        });
      },
    };

    static eventViewProject = {
      open() {
        dispatch({
          type: C.SHOW_VIEW_PROJECT,
        });
      },
      close() {
        dispatch({
          type: C.CLOSE_VIEW_PROJECT,
        });
      },
    };
  }

  return {
    Popup,
  };
};
