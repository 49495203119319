import { memo } from "react";
import cn from "classnames";
import { KanbanColumns } from "src/FSD/entities/kanban";
import { Column } from "src/FSD/widgets/v2/kanban";
import styles from "./Kanban.module.scss";

export const RowTasksHeader = memo(() => {
  return (
    <div
      className={cn(
        styles.columns__history_draggable,
        styles.columns__history_draggable_empty
      )}
    >
      <Column
        isEmpty
        header={
          <Column.Header>
            <Column.Title>{KanbanColumns.IN_WORK}</Column.Title>
            <Column.AddTask />
          </Column.Header>
        }
      />
      <Column
        isEmpty
        header={
          <Column.Header>
            <Column.Title>{KanbanColumns.IS_DONE}</Column.Title>
          </Column.Header>
        }
      />
      <Column
        isEmpty
        header={
          <Column.Header>
            <Column.Title>{KanbanColumns.DONE}</Column.Title>
          </Column.Header>
        }
      />
    </div>
  );
});
