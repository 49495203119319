import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import classes from "./InfoFiles.module.scss";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { trim } from "../../../../User/View/Portfolio/Portfolio";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { palette } from "../../../../../../styles/restyle";
import { config } from "../../../../../../app.cofig";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";

type InfoFilesProps = {
  files: ProjectViewData["files"] | null;
};

export const InfoFiles: React.FC<InfoFilesProps> = ({ files }) => {
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(500);
  const [filesOpen, setFilesOpen] = useState(false);

  const handleNavigate = () => {
    window.location.href = `${config.localDomain}/project/download-files?id=${params.id}`;
  };

  const arrowIconType = <ToggleAccordion toggle={filesOpen} />;

  if (files) {
    return (
      <div className={classNames(classes.files, classes.basic)}>
        <div className={classes.files_btn}>
          <div className={classes.files_btn_flex}>
            <h1>Вложения</h1>
            {files.length > 3 && (
              <div>
                <IconButton onClick={() => setFilesOpen(!filesOpen)}>
                  {arrowIconType}
                </IconButton>
              </div>
            )}
          </div>
          {files.length > 0 && (
            <CustomButton
              width={isMobile ? 100 : 160}
              onClick={handleNavigate}
              background={palette.green}
            >
              Скачать
            </CustomButton>
          )}
        </div>
        <div>
          {!files.length && (
            <div className={classes.description_text}>Вложения отсутствуют</div>
          )}
          {files.length > 0 &&
            files.slice(0, filesOpen ? files.length : 3).map((file) => (
              <div key={file.url}>
                <TextLink
                  variant={classes.textLink}
                  redirect
                  target="blank"
                  url={file.url}
                >
                  {trim(file.title)}
                </TextLink>
                <br />
              </div>
            ))}
        </div>
      </div>
    );
  }
  return <div></div>;
};
