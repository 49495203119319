import React, { FC, memo, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { useUserWorkflow } from "../../../../../hooks/useUserWorkflow";
import {
  setFirmData,
  clearSearchList,
} from "../../../../../../app/feature/profileReducer/profileReducer";

import {
  ApiLeaveFirm,
  ApiRemoveRequest,
} from "../../../../../../app/services/api/organisation/organisation";
import { request } from "../../../../../../app/services/api/requestHandler";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { palette } from "../../../../../../styles/restyle";
import { selectorEditRequestJoinData } from "../../../../../../app/feature/eventEmitter/organisation/eventEditRequestJoin";

const MESSAGES = {
  CONFIRM_LEAVE: "Вы уверены что хотите выйти из организации?",
  LEFT_SUCCESSFULLY: "Вы успешно покинули организацию",
  LEFT_ERROR: "Ошибка при выходе из организации",
  CONFIRM_DECLINE_JOIN: "Вы уверены что хотите отозвать заявку?",
  DECLINED_SUCCESSFULLY: "Заявка успешно отозвана",
  DECLINE_ERROR: "Ошибка отозвания заявки",
};

type OrganisationSearchProps = {
  id: number | null;
  title: string;
  inn: string;
  address: string;
  idsList: number[];
  fullFirm: any;
  can: {
    create?: boolean;
    sendRequest?: boolean;
    isMember?: boolean;
  };
};

export const OrganisationSearch: FC<OrganisationSearchProps> = memo(
  ({ id, title, inn, address, idsList, fullFirm, can }) => {
    const [requestSent, setRequestSent] = useState<boolean>(!can?.sendRequest);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { Popup } = useUserWorkflow();
    const { sent, id: joinFirmId } = useSelector(selectorEditRequestJoinData);
    const { successSnackbar, errorSnackbar } = useSnackbar();

    const invitation = useMemo(
      () => id && !idsList.includes(id) && !requestSent,
      [idsList, id, sent]
    );

    const handleInvitation = () => {
      Popup.joinRequest.set({
        title,
        id,
      });
      Popup.joinRequest.open();
    };

    const handleCreate = () => {
      dispatch(setFirmData(fullFirm));
      dispatch(clearSearchList());
      navigate(`/firm/create`);
    };

    const handleDeclineJoin = () => {
      if (!id || !fullFirm.task_id) {
        return;
      }

      if (window.confirm(MESSAGES.CONFIRM_DECLINE_JOIN)) {
        request(
          ApiRemoveRequest(fullFirm.task_id),
          () => {
            successSnackbar(MESSAGES.DECLINED_SUCCESSFULLY);
          },
          () => (err) => {
            if (err.status === 403) {
              errorSnackbar(`${err.message}`);
            } else {
              errorSnackbar(MESSAGES.DECLINE_ERROR);
            }
          }
        )();
      }
    };

    const handleLeaveFirm = () => {
      if (!id) {
        return;
      }

      if (window.confirm(MESSAGES.CONFIRM_LEAVE)) {
        request(
          ApiLeaveFirm(id),
          () => {
            successSnackbar(MESSAGES.LEFT_SUCCESSFULLY);
          },
          () => (err) => {
            if (err.status === 403) {
              errorSnackbar(`${err.message}`);
            } else {
              errorSnackbar(MESSAGES.LEFT_ERROR);
            }
          }
        )();
      }
    };

    useEffect(() => {
      if (sent) {
        if (id === joinFirmId) {
          setRequestSent(true);
        }
        Popup.joinRequest.set({});
      }
    }, [sent]);

    return (
      <div className={style.wrapper}>
        <div className={style.infoWrapper}>
          {id ? (
            <TextLink url={`/firm/${id}`} variant={style.title}>
              {title}
            </TextLink>
          ) : (
            <h1 className={style.title}>{title}</h1>
          )}
          <p className={style.info}>
            ИНН{"   "}
            {inn}
          </p>
          <p className={style.info}>{address}</p>
        </div>
        <div className={style.button}>
          {can?.sendRequest && invitation && (
            <CustomButton
              onClick={handleInvitation}
              width={180}
              background={palette.green}
            >
              Запрос на вступление
            </CustomButton>
          )}
          {can?.create && (
            <CustomButton
              onClick={handleCreate}
              width={120}
              background={palette.blue}
            >
              Создать
            </CustomButton>
          )}
          {can?.isMember && (
            <CustomButton
              onClick={handleLeaveFirm}
              width={120}
              background={palette.red}
            >
              Выйти
            </CustomButton>
          )}
          {fullFirm?.requestToJoin && (
            <CustomButton
              onClick={handleDeclineJoin}
              width={120}
              background={palette.red}
            >
              Отозвать заявку
            </CustomButton>
          )}
        </div>
      </div>
    );
  }
);

export const SkeletonOrganisationSearch: FC = memo(() => (
  <Skeleton
    variant="rectangular"
    sx={{ bgcolor: "#37474F", borderRadius: "10px", margin: "20px 0" }}
    height="90px"
    width="100%"
  />
));
