import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
} from "@reduxjs/toolkit";
import { getProcessesData, getPayments } from "./utils";
import {
  getGroupPublishProcess,
  fetchGetUsersToAssign,
  fetchGetUsersTypeToAssign,
  getExecutorFullTypes,
  groupPublication,
} from "./thunks";

export type TProcessShort = {
  id: number;
  name: string;
  dateStart: string;
  dateLimit: string;
  price: number | null;
  active: boolean;
};

type TState = {
  data: {
    isOpen: boolean;
    processId: number | null;
    groupName: string;
    processesData: Record<number, TProcessShort>;
    executors: any;
    payments: Record<string, number>;
    types: any;
    fullTypes: any;
    isTouched: boolean;
  };
  pending: {
    getProcesses: boolean;
    publishProcesses: boolean;
    executors: boolean;
    types: boolean;
    fullTypes: boolean;
    publishing: boolean;
  };
  error: {
    getProcesses: string | null;
    publishProcesses: string | null;
    executors: string | null;
    types: string | null;
    fullTypes: string | null;
  };
};

const initialState: TState = {
  data: {
    isOpen: false,
    processId: null,
    executors: [],
    groupName: "",
    payments: {
      pay1: 0,
      pay2: 0,
      pay3: 0,
    },
    types: [],
    processesData: {},
    fullTypes: [],
    isTouched: false,
  },
  pending: {
    getProcesses: false,
    publishProcesses: false,
    executors: false,
    types: false,
    fullTypes: false,
    publishing: false,
  },
  error: {
    getProcesses: null,
    publishProcesses: null,
    executors: null,
    types: null,
    fullTypes: null,
  },
};

export const toSelect = (payload: any, convertIdToNumber = true) => {
  if (!payload) {
    return [];
  }

  return Object.entries(payload).map(([id, title]: any) => ({
    id: convertIdToNumber ? Number(id) : id,
    title,
  }));
};

export const arrayToSelect = (payload: any) =>
  Object.values(payload).map(({ id, name: title }: any) => ({ id, title }));

export const objectToSelect = (payload: any) =>
  payload.map((item: any) => ({ id: item.id, title: item.name }));

const projectGroupProcessPublication = createSlice({
  name: "groupPublishProcess",
  initialState,
  reducers: {
    setOpen(state, action) {
      const { id, partGroup } = action.payload;

      state.data.groupName = partGroup.name;
      state.data.processId = id;
      state.data.isOpen = true;
    },
    setClosed(state) {
      state.data.isOpen = false;
      state.data.processId = null;
    },
    clearGroupPublishProcess() {
      return initialState;
    },
    setTouched(state, action) {
      state.data.isTouched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetUsersToAssign.pending, (state) => {
        state.pending.executors = true;
        state.error.executors = null;
      })
      .addCase(fetchGetUsersToAssign.fulfilled, (state, action) => {
        state.pending.executors = false;
        state.data.executors = toSelect(action.payload);
      })
      .addCase(fetchGetUsersToAssign.rejected, (state, action) => {
        state.pending.executors = false;
        state.error.executors = action.payload as string;
      })
      .addCase(getExecutorFullTypes.pending, (state) => {
        state.pending.fullTypes = true;
        state.error.fullTypes = null;
      })
      .addCase(getExecutorFullTypes.fulfilled, (state, action) => {
        state.pending.fullTypes = false;
        state.data.fullTypes = toSelect(action.payload);
      })
      .addCase(getExecutorFullTypes.rejected, (state, action) => {
        state.pending.fullTypes = false;
        state.error.fullTypes = action.payload as string;
      })
      .addCase(fetchGetUsersTypeToAssign.pending, (state) => {
        state.pending.types = true;
        state.error.types = null;
      })
      .addCase(fetchGetUsersTypeToAssign.fulfilled, (state, action) => {
        state.pending.types = false;
        state.data.types = toSelect(action.payload);
      })
      .addCase(fetchGetUsersTypeToAssign.rejected, (state, action) => {
        state.pending.types = true;
        state.error.types = action.payload as string;
      })
      .addCase(groupPublication.pending, (state) => {
        state.pending.publishing = true;
      })
      .addCase(groupPublication.fulfilled, (state) => {
        state.pending.publishing = false;
        state.data.isOpen = false;
        state.data.processId = null;
      })
      .addCase(groupPublication.rejected, (state) => {
        state.pending.publishing = false;
      })
      .addMatcher(isPending(getGroupPublishProcess), (state) => {
        state.pending.getProcesses = true;
      })
      .addMatcher(isFulfilled(getGroupPublishProcess), (state, action) => {
        state.data.payments = getPayments(action.payload);
        state.data.processesData = getProcessesData(action.payload);
        state.pending.getProcesses = false;
      });
  },
});

export const { setOpen, setClosed, clearGroupPublishProcess, setTouched } =
  projectGroupProcessPublication.actions;

export const getGroupPublishProcessData = (state: CombinedState<any>) =>
  state.projectGroupProcessPublication;

export default projectGroupProcessPublication.reducer;
