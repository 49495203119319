import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessOutTasks = (id: number): Promise<Response> => {
  // получить дерево Исхоящие Задания
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-out-tasks?id=${id}`
  );
};

export const ApiProjectProcessInTasks = (id: number): Promise<Response> => {
  // получить дерево Исхоящие Задания
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-in-tasks?id=${id}`
  );
};
