import { CustomerAlignCompleteForm } from "./CustomerAlignComplete";
import { RequestCustomerAlignCompleteForm } from "./RequestCustomerAlignComplete";
import { CheckCustomerAlignForm } from "./CheckCustomerAlign";
import { SubTaskForm } from "./SubTask";
import { CheckSubTaskForm } from "./CheckSubTask";
import { ExecuteProcessForm } from "./ExecuteProcess";
import { CheckSubCheckForm } from "./CheckSubCheck";
import { OutputTaskForm } from "./OutputTask";
import { CheckSubCheckProcessForm } from "./CheckSubCheckProcess";
import { CheckExpertiseRemarkForm } from "./CheckExpertiseRemark";
import { ExpertiseRemarkForm } from "./ExpertiseRemark";
import { CheckExecuteProcess } from "./CheckExecuteProcess";
import { ModalFiles } from "./ModalFiles";
import { ModalGear } from "./ModalGear";
import { TaskPublishUpdate } from "./TaskPublishUpdate";

const ProjectProcessModals = {
  CustomerAlignCompleteForm,
  RequestCustomerAlignCompleteForm,
  CheckCustomerAlignForm,
  SubTaskForm,
  CheckSubTaskForm,
  ExecuteProcessForm,
  CheckSubCheckForm,
  OutputTaskForm,
  CheckSubCheckProcessForm,
  CheckExpertiseRemarkForm,
  ExpertiseRemarkForm,
  CheckExecuteProcess,
  ModalFiles,
  ModalGear,
  TaskPublishUpdate,
};

export default ProjectProcessModals;
