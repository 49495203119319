import { headerContentTypeJSON, headersContentMultiDataType } from "./header";

export const httpRequestGet = (
  url: string,
  headers?: Record<string, unknown>
): Promise<Response> => {
  return fetch(url, {
    method: "GET",
    headers: {
      ...headerContentTypeJSON(),
      ...headers,
    },
  });
};

export const httpRequestPut = (
  url: string,
  headers?: Record<string, unknown>,
  data?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "PUT",
    headers: {
      ...headerContentTypeJSON(),
      ...headers,
    },
    body: JSON.stringify(data),
  });
};

export const httpRequestPost = (
  url: string,
  headers?: Record<string, unknown>,
  data?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "POST",
    headers: {
      ...headerContentTypeJSON(),
      ...headers,
    },
    body: JSON.stringify(data),
  });
};

export const httpRequestPatch = (
  url: string,
  headers?: Record<string, unknown>,
  data?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "PATCH",
    headers: {
      ...headerContentTypeJSON(),
      ...headers,
    },
    body: JSON.stringify(data),
  });
};

export const httpRequestPostMultiData = (
  url: string,
  headers?: Record<string, unknown>,
  formData?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "POST",
    headers: {
      ...headersContentMultiDataType(),
      ...headers,
    },
    body: formData,
  });
};

export const httpRequestPutMultiData = (
  url: string,
  headers?: Record<string, unknown>,
  formData?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "PUT",
    headers: {
      ...headersContentMultiDataType(),
      ...headers,
    },
    body: formData,
  });
};

export const httpRequestPatchMultiData = (
  url: string,
  headers?: Record<string, unknown>,
  formData?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "PATCH",
    headers: {
      ...headersContentMultiDataType(),
      ...headers,
    },
    body: formData,
  });
};

export const httpRequestDelete = (
  url: string,
  headers?: Record<string, unknown>,
  data?: null | any
): Promise<Response> => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      ...headerContentTypeJSON(),
      ...headers,
    },
    body: JSON.stringify(data),
  });
};

export const isBadStatusRequest = (status: number): boolean => status >= 400;
