import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const Deal = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.condition, style[theme])}>
      <strong>ШАГ 0 Внесение средств</strong>
      <p>
        С целью обеспечения будущих сделок пользователю необходимо внести сумму
        денежных средств на расчетный счет Платформы ПИРС (далее «Платформа»).
        Из зачисляемых денежных средств Платформой сразу взимается
        вознаграждение Платформы в размере 15% за операцию ввода средств и
        использование сервиса «Безопасная сделка». Доступный остаток внесенных
        денежных средств фиксируются и отображаются в его Личном кабинете и
        хранится на расчетном счете Платформы.
      </p>

      <p>
        Пользователи, являющиеся сотрудниками данной Компании или
        Индивидуальными предпринимателями и имеющие отметку «Имеет право
        распоряжаться финансами Компании или ИП» могут распоряжаться средствами,
        отображаемые в личном кабинете организации.
      </p>
      <p>
        Пользователь может зарезервировать Денежные средства в качестве
        обеспечительного платежа для конкретного проекта, раздела или задания по
        разделу, если пользователь является Заказчиком или ГИПом по проекту.
      </p>

      <strong>ШАГ 1 Поиск исполнителя</strong>
      <p>
        Заказчик создает проект с техническим заданием и подробным описанием
        требуемых к выполнению работ, заполняет обязательные физические
        параметры. При опубликовании разделов Заказчик или ГИП по проекту
        указывает стоимость работ, сроки их выполнения, условия оплаты (аванс -
        оплата после приемки предварительных решений, оплата после приемки
        документации, оплата после прохождения экспертизы (при наличии),
        выбирает сервис «Безопасная Сделка» и выбирает формы ответственности
        исполнителя (ФЛ — физическое лицо, СЗ — Самозанятый, ИП — индивидуальный
        предприниматель, ЮЛ — Юридическое лицо/Компания). Обеспечивает
        достаточность средств на счете в личном кабинете пользователя, в том
        числе вознаграждение Платформы в размере 15%. Подтверждает публикацию
        путем нажатия кнопки «Опубликовать». Подрядчик рассматривает
        опубликованное на Платформе предложение в Заказе (проект, раздел,
        задание), соглашается с условиями Публичной оферты и оферты Заказчика на
        заключение Договора подряда путем нажатия соответствующих кнопок
        «Заявиться».
      </p>

      <strong>ШАГ 2 Согласование условий</strong>
      <p>
        Подрядчик согласовывает предложение, уточняет его в индивидуальном чате
        по разделу, если что-то требует пояснений или изменений, либо принимает
        сделку, путем принятия оферты Заказчика на заключение Договора подряда
        нажатием соответствующих кнопок «Подписать». Платформа создает
        соответствующую страницу сделки. Принимая условия, Подрядчик дает
        согласие о получении им оплаты работ по сделке из суммы Обеспечительного
        платежа по проекту или разделу. Стороны после согласования условий
        подписывают своей электронной подписью (аналогом собственноручной
        подписи) Договор Подряда (Оферта Заказчика) сформированный на страницах
        Сайта.
      </p>

      <strong>
        ШАГ 3 Выбор подрядчика и внесение Обеспечительного платежа
      </strong>
      <p>
        До выбора Подрядчика и подписания сторонами Договора Подряда с сервисом
        «Безопасная сделка» Заказчику необходимо внести денежные средства
        (Обеспечительный платеж) на счет Платформы ПИРС в сумме аванса
        планируемой сделки и вознаграждения Платформы ПИРС. Зарезервированные
        денежные средства отмечаются на странице сделки и хранятся Платформой
        ПИРС до завершения исполнения обязательств по Договору подряда. Сделка
        переходит на этап "в работе". Средства внесенные на счет платформы
        конкретным пользователем в качестве обеспечительного платежа,
        фиксируются и отображаются в его Личном кабинете, хранятся средства на
        расчетном счете Платформы ПИРС.
      </p>

      <strong>ШАГ 4 Выполнение работы</strong>
      <p>
        Подрядчик приступает к работе. Платформа обеспечивает Сторонам: ведение
        переписки на странице сделки, передачу файлов, отображение этапов и
        сроков работ смежников, отметки о направлении и исполнении смежниками
        технических заданий, требований Заказчика, подрядчика, смежников,
        отображение задач и уведомлений. Выплата аванса возможна после отправки
        на согласование Подрядчиком и приемки Заказчиком или ГИП предварительных
        решений на соответствующей странице раздела Сайта.
      </p>
      <p>
        В процессе выполнения работ могут вноситься изменения в техническое
        задание, стоимость разделов, заданий по разделам, сроки исполнения
        разделов, заданий по разделам - для этого обе стороны должны согласиться
        с новыми условиями, заполнить соответствующую форму на сайте, в случае
        внесения существенных изменений в условия Договора подряда создать с
        помощью Платформы дополнительное соглашение обеспечив его подписание
        электронной подписью и обмена через сервис сайта Платформы ПИРС.
      </p>

      <strong>ШАГ 5 (возможный) Урегулирование споров</strong>
      <p>
        Любая из сторон, пока сделка не закрыта, может инициировать процедуру
        снятия исполнителя/ГИП путем нажатия кнопки «Снять» на странице раздела
        или задания или проекта. Платформа ПИРС направляет уведомление Заказчику
        или Подрядчику и предлагает урегулировать спор в течение одних суток на
        одну итерацию, если оппонент не предложил иных условий в течение 1
        суток, то запрос на урегулирование спора направляется к Платформе за
        рекомендациями по его урегулированию. Платформа на условиях публичной
        оферты поможет найти компромисс в споре, либо дать рекомендации по
        компромиссному решению, а при его отсутствии заморозить средства до
        решения суда.
      </p>
      <p>
        При этом в результате Урегулирования спора Платформа вправе принять
        решение о:
      </p>
      <p>
        <b>Возврате суммы заказчику</b> за исключением суммы Вознаграждения
        Платформы, если результат предоставлен не в срок и/или не соответствует
        заданию. Возврат обеспечивается на лицевой счет Заказчика
        (автоматически, без дополнительных действий со стороны Заказчика):
      </p>
      <ul>
        <li>
          после публикации решения Платформы по результату урегулирования спора
        </li>
        <li>
          если Подрядчик в течение 72 часов не принял оферту Заказчика и не
          приступил к работе
        </li>
        <li>
          если в течение 72 часов с момента создания страницы сделки на
          Платформе любая из сторон отказалась от сделки
        </li>
      </ul>
      <p>
        <b>
          Выплата полной суммы Подрядчику за вычетом суммы вознаграждения
          Платформы
        </b>
        если конечный результат полностью соответствует заданию и предоставлен в
        срок.
      </p>
      <p>
        <b>Разделение суммы между заказчиком и Подрядчиком</b> возможно по
        соглашению сторон, если работа изначально состоит из равнозначных или
        однозначно делимых частей и была выполнена лишь в части;
      </p>
      <p>
        Стороны договорились, каким образом стоимость работ по сделке
        распределяется между разделами работы. Утверждена стоимость каждого
        этапа, и стоимости указаны в Техническом Задании, либо в комментариях к
        сделке (требуется подтверждение от обеих сторон);
      </p>
      <p>
        <b>Отказ в урегулировании спора</b> возможен по соглашению сторон, либо
        по факту продолжения сотрудничества, если сделка еще в работе. После
        отказа сделка возвращается в стандартный режим. Стороны вправе
        возвращать сделку в работу и снова обращаться к Платформе за
        урегулированием спора без ограничений, но только пока сделка не закрыта.
      </p>

      <strong>ШАГ 6 Завершение сделки</strong>
      <p>
        Подрядчик загружает готовую документацию на странице разрабатываемого
        раздела путем нажатия кнопки «Завершить раздел» или «Выдать». Заказчик
        вносит или/и резервирует из своего Кошелька недостающую сумму по
        разделу, для обеспечения 100% (за вычетом выплаченного аванса) стоимости
        раздела. Заказчик получает готовую работу полностью, проверяет ее и
        завершает сделку нажатием кнопки «Принять», чем подтверждает завершение
        сделки и одобрение выплаты стоимости работы из обеспечительного платежа,
        оставляет Отзыв о Подрядчике или ГИП (не обязательно). Подрядчик
        получает оплату из Обеспечительного платежа на лицевой счет Подрядчика в
        его личном кабинете Платформы.
      </p>
      <p>
        Публичная оферта об использовании типа оплаты Безопасная сделка -
        Трехсторонний договор, в котором описаны правила работы с типом оплаты
        Безопасная Сделка, в том числе, регламент Урегулирования спора с
        участием Платформы. Имеет полную юридическую силу в электронном виде, не
        требует дополнительного подписания в бумажном виде.
      </p>
      <p>
        Оферта Заказчика на заключение Договора подряда - Соглашение между
        Заказчиком и Исполнителем/Подрядчиком в отношении работы, в том числе
        регламентирует передачу авторских прав, ответственность сторон. Имеет
        полную юридическую силу в электронном виде, не требует дополнительного
        подписания в бумажном виде
      </p>
      <p>
        Акт сдачи приемки выполненных работ – При возврате 100% стоимости работ
        Заказчику этот акт не формируется. Акт формируется по факту выполнения
        работ и требует подписания Заказчиком и Подрядчиком электронной
        подписью.
      </p>

      <strong>ШАГ 7 Вывод средств с лицевого счета</strong>
      <p>
        Любой пользователь платформы, имеющий денежные средства на своем лицевом
        счету в личном кабинете Платформы имеет право вывести не
        зарезервированные и не замороженные денежные средства на банковский
        счёт.
      </p>
      <p>Выводимые средства переводятся на реквизиты пользователя.</p>
    </div>
  );
};
