import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "./ModalComplete.module.scss";
import { Uploader } from "../../../../../../features/Uploader/Uploader";
import { ApiGetTaskFiles } from "../../../../../../../app/services/api/organisation/organisation";
import { config } from "../../../../../../../app.cofig";
import { fetchProjectProcessDocumentationsDuplicate } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../styles/restyle";
import { fetchProjectProcessTaskComplete } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessTaskComplete";

export const ModalComplete = memo(
  ({ taskId, addText, closeModal, header }: any) => {
    const dispatch = useAppDispatch();
    const processName = useAppSelector(getProjectProcessInfoName);
    const process = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);

    async function completeHandler() {
      const data = {
        CompleteForm: {
          status: 6,
        },
      };
      await dispatch(
        fetchProjectProcessTaskComplete({
          taskId,
          data,
          processId,
        })
      );
      closeModal();
    }

    async function updateFileLoad() {
      if (processId) {
        await dispatch(fetchProjectProcessDocumentationsDuplicate(processId));
      }
    }

    const actualHeader =
      header ??
      `Выдать документацию по разделу «${processName}» по проекту «
  ${process?.project.name}»`;

    return (
      <form className={cls.form}>
        <h1>{actualHeader}</h1>
        <Uploader
          identifier={taskId}
          autoUpload
          canBeEdited
          uploadAsActive
          getDataCallback={ApiGetTaskFiles}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          handleUpdate={updateFileLoad}
        />
        {addText ? (
          <p className={cls.addText}>{addText}</p>
        ) : (
          <CustomButton
            className={cls.form_submit}
            width={200}
            background={palette.blue}
            onClick={completeHandler}
          >
            Отправить на проверку
          </CustomButton>
        )}
      </form>
    );
  }
);
