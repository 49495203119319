export const C = {
  // publishTaskProcess
  SHOW_EVENT_PUBLISH_TASK: "SHOW_EVENT_PUBLISH_TASK",
  ClOSE_EVENT_PUBLISH_TASK: "ClOSE_EVENT_PUBLISH_TASK",
  SET_EVENT_PUBLISH_DATA: "SET_EVENT_PUBLISH_DATA",

  // projectProcess unpublish
  SHOW_EVENT_UNPUBLISH_TASK: "SHOW_EVENT_UNPUBLISH_TASK",
  CLOSE_EVENT_UNPUBLISH_TASK: "CLOSE_EVENT_UNPUBLISH_TASK",
  UNPUBLISH_EVENT_SET_TASK_ID: "UNPUBLISH_EVENT_SET_TASK_ID",

  // set executor event
  EVENT_SET_EXECUTOR_DATA: "EVENT_SET_EXECUTOR_DATA",
  SHOW_EVENT_SET_EXECUTOR: "SHOW_EVENT_SET_EXECUTOR",
  CLOSE_EVENT_SET_EXECUTOR: "CLOSE_EVENT_SET_EXECUTOR",

  // error event
  SHOW_EVENT_ERROR: "SHOW_EVENT_ERROR",
  CLOSE_EVENT_ERROR: "CLOSE_EVENT_ERROR",
  SET_ERROR_EVENT_MESSAGE: "SET_ERROR_EVENT_MESSAGE",

  // RemoveRequestGip
  SHOW_EVENT_REQUEST_REMOVE_GIP: "SHOW_EVENT_REQUEST_REMOVE_GIP",
  CLOSE_EVENT_REQUEST_REMOVE_GIP: "CLOSE_EVENT_REQUEST_REMOVE_GIP",
  SET_EVENT_REQUEST_REMOVE_GIP: "SET_EVENT_REQUEST_REMOVE_GIP",

  // setGipProject
  SHOW_EVENT_SET_GIP_PROJECT: "SHOW_EVENT_SET_GIP_PROJECT",
  CLOSE_EVENT_SET_GIP_PROJECT: "CLOSE_EVENT_SET_GIP_PROJECT",
  SET_EVENT_GIP_PROJECT_DATA: "SET_EVENT_GIP_PROJECT_DATA",

  // revokeRequestGipPorject
  SHOW_EVENT_REVOKE_REQUEST_GIP: "SHOW_EVENT_REVOKE_REQUEST_GIP",
  CLOSE_EVENT_REVOKE_REQUEST_GIP: "CLOSE_EVENT_REVOKE_REQUEST_GIP",

  // requestGipProject
  SHOW_EVENT_REQUEST_GIP_PROJECT: "SHOW_EVENT_REQUEST_GIP_PROJECT",
  CLOSE_EVENT_REQUEST_GIP_PROJECT: "CLOSE_EVENT_REQUEST_GIP_PROJECT",
  SET_EVENT_REQUEST_GIP_PROJECT_DATA: "SET_EVENT_REQUEST_GIP_PROJECT_DATA",

  // Снятие поиска ГИПа в проекте
  SHOW_EVENT_REMOVE_REQUEST_SEARCH_GIP: "SHOW_EVENT_REMOVE_REQUEST_SEARCH_GIP",
  CLOSE_EVENT_REMOVE_REQUEST_SEARCH_GIP:
    "CLOSE_EVENT_REMOVE_REQUEST_SEARCH_GIP",

  // Начать поиск ГИПа
  SHOW_EVENT_SEARCH_GIP: "SHOW_EVENT_SEARCH_GIP",
  CLOSE_EVENT_SEARCH_GIP: "CLOSE_EVENT_SEARCH_GIP",

  // closeConflictDeleteGip
  SHOW_EVENT_CLOSE_CONFLICT: "SHOW_EVENT_CLOSE_CONFLICT",
  CLOSE_EVENT_CLOSE_CONFLICT: "CLOSE_EVENT_CLOSE_CONFLICT",

  // compareGipForm
  SHOW_EVENT_COMPARE_GIP: "SHOW_EVENT_COMPARE_GIP",
  CLOSE_EVENT_COMPARE_GIP: "CLOSE_EVENT_COMPARE_GIP",

  // deleteProject
  SHOW_EVENT_DELETE_PROJECT: "SHOW_EVENT_DELETE_PROJECT",
  CLOSE_EVENT_DELETE_PROJECT: "CLOSE_EVENT_DELETE_PROJECT",

  // snackbars
  SHOW_SUCCESS_SNACKBAR: "SHOW_SUCCESS_SNACKBAR",
  CLOSE_SUCCESS_SNACKBAR: "CLOSE_SUCCESS_SNACKBAR",
  SHOW_ERROR_SNACKBAR: "SHOW_ERROR_SNACKBAR",
  CLOSE_ERROR_SNACKBAR: "CLOSE_ERROR_SNACKBAR",

  // user join firm
  SHOW_EVENT_JOIN_USER_FIRM: "SHOW_EVENT_JOIN_USER_FIRM",
  CLOSE_EVENT_JOIN_USER_FIRM: "CLOSE_EVENT_JOIN_USER_FIRM",

  // user join project
  SHOW_EVENT_JOIN_USER_PROJECT: "SHOW_EVENT_JOIN_USER_PROJECT",
  CLOSE_EVENT_JOIN_USER_PROJECT: "CLOSE_EVENT_JOIN_USER_PROJECT",

  // user registration agreement
  SHOW_EVENT_USER_AGREEMENT: "SHOW_EVENT_USER_AGREEMENT",
  CLOSE_EVENT_USER_AGREEMENT: "CLOSE_EVENT_USER_AGREEMENT",

  // user registration policy
  SHOW_EVENT_USER_POLICY: "SHOW_EVENT_USER_POLICY",
  CLOSE_EVENT_USER_POLICY: "CLOSE_EVENT_USER_POLICY",

  // organisation user role change
  SHOW_EVENT_USER_ROLE: "SHOW_EVENT_USER_ROLE",
  CLOSE_EVENT_USER_ROLE: "CLOSE_EVENT_USER_ROLE",
  EVENT_SET_USER_ROLE_DATA: "EVENT_SET_USER_ROLE_DATA",

  // organisation task change
  SHOW_EVENT_EDIT_ORGANISATION_TASK: "SHOW_EVENT_EDIT_ORGANISATION_TASK",
  CLOSE_EVENT_EDIT_ORGANISATION_TASK: "CLOSE_EVENT_EDIT_ORGANISATION_TASK",
  EVENT_SET_EDIT_ORGANISATION_TASK: "EVENT_SET_EDIT_ORGANISATION_TASK",

  // organisation bank account change
  SHOW_EVENT_EDIT_BANK_ACCOUNT: "SHOW_EVENT_EDIT_BANK_ACCOUNT",
  CLOSE_EVENT_EDIT_BANK_ACCOUNT: "CLOSE_EVENT_EDIT_BANK_ACCOUNT",
  EVENT_SET_EDIT_BANK_ACCOUNT: "EVENT_SET_EDIT_BANK_ACCOUNT",

  // organisation staff send money
  SHOW_EVENT_SEND_MONEY: "SHOW_EVENT_SEND_MONEY",
  CLOSE_EVENT_SEND_MONEY: "CLOSE_EVENT_SEND_MONEY",
  EVENT_SET_SEND_MONEY: "EVENT_SET_SEND_MONEY",

  // organisation user join
  SHOW_EVENT_USER_JOIN: "SHOW_EVENT_USER_JOIN",
  CLOSE_EVENT_USER_JOIN: "CLOSE_EVENT_USER_JOIN",
  EVENT_SET_USER_JOIN: "EVENT_SET_USER_JOIN",

  // organisation join request
  SHOW_EVENT_REQUEST_JOIN: "SHOW_EVENT_REQUEST_JOIN",
  CLOSE_EVENT_REQUEST_JOIN: "CLOSE_EVENT_REQUEST_JOIN",
  EVENT_SET_REQUEST_JOIN: "EVENT_SET_REQUEST_JOIN",

  SHOW_EVENT_USER_JOIN_AT_USER_PAGE: "SHOW_EVENT_USER_JOIN_AT_USER_PAGE",
  CLOSE_EVENT_USER_JOIN_AT_USER_PAGE: "CLOSE_EVENT_USER_JOIN_AT_USER_PAGE",
  EVENT_SET_USER_JOIN_AT_USER_PAGE: "EVENT_SET_USER_JOIN_AT_USER_PAGE",

  SHOW_CREATE_TEAM: "SHOW_CREATE_TEAM",
  CLOSE_CREATE_TEAM: "CLOSE_CREATE_TEAM",
  EVENT_SET_CREATE_TEAM: "EVENT_SET_CREATE_TEAM",

  SHOW_MANAGE_TEAMMATE: "SHOW_MANAGE_TEAMMATE",
  CLOSE_MANAGE_TEAMMATE: "CLOSE_MANAGE_TEAMMATE",
  EVENT_SET_MANAGE_TEAMMATE: "EVENT_SET_MANAGE_TEAMMATE",

  SHOW_INVITE_TO_TEAM: "SHOW_INVITE_TO_TEAM",
  CLOSE_INVITE_TO_TEAM: "CLOSE_INVITE_TO_TEAM",
  EVENT_SET_INVITE_TO_TEAM: "EVENT_SET_INVITE_TO_TEAM",

  SHOW_VIEW_PROJECT: "SHOW_VIEW_PROJECT",
  CLOSE_VIEW_PROJECT: "CLOSE_VIEW_PROJECT",
};
