import { memo } from "react";
import { FormWrapper } from "src/components/newUI/FormWrapper/FormWrapper";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoName,
  getProjectProcessInfoProjectName,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { palette } from "src/styles/restyle";
import { fetchProjectProcessTaskComplete } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessTaskComplete";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { ApiPrimaryFileType } from "src/types/api/primaryTypes/apiPrimaryFileType";
import { useForm } from "react-hook-form";
import cls from "../ExpertiseInCard.module.scss";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";

type TInputs = {
  status: number;
};

interface FormModalCheckProps {
  taskId: number;
  header?: string;
  closeModal: () => void;
  files: ApiPrimaryFileType[];
}

export const ModalCheck = memo(
  ({ taskId, closeModal, files, header = "" }: FormModalCheckProps) => {
    const dispatch = useAppDispatch();
    const projectName = useAppSelector(getProjectProcessInfoProjectName);
    const processName = useAppSelector(getProjectProcessInfoName);
    const processId = useAppSelector(getProjectProcessInfoId);
    const { handleSubmit, setValue } = useForm<TInputs>({});

    const actualHeader =
      header ||
      `Проверить документацию по разделу «${processName}» по проекту «${projectName}»`;

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          status: dataValues.status,
        },
      };
      await dispatch(
        fetchProjectProcessTaskComplete({
          taskId,
          data,
          processId,
        })
      );
      closeModal();
    };

    return (
      <FormWrapper
        submitHandler={handleSubmit(formSubmitHandler)}
        title={actualHeader}
      >
        <fieldset className={cls.fieldset}>
          <legend>Файлы</legend>
          <div className={cls.fieldset_files}>
            {files.map((file: any) => (
              <TextLink url={file.url} target="_blank" redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <div className={cls.control}>
          <CustomButton
            background={palette.red}
            width={160}
            onClick={() => setValue("status", 5)}
            type="submit"
          >
            Отказать
          </CustomButton>
          <CustomButton
            background={palette.blue}
            width={160}
            onClick={() => setValue("status", 4)}
            type="submit"
          >
            Принять
          </CustomButton>
        </div>
      </FormWrapper>
    );
  }
);
