import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Deal } from "../../../UI/components/Conditions/Deal";
import { Wrapper } from "../../../UI/templates";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const DealPage = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.conditionContainer, style[theme])}>
      <Wrapper>
        <div className={style.pageTitle}>Алгоритм безопасной сделки</div>
        <Deal />
      </Wrapper>
    </div>
  );
};
