import { formFullUserName } from "../../../../../../../shared/lib/helpers";

export const mapTask = (payload: any) => {
  return {
    project: {
      id: payload.project!.id,
      title: `${payload.project!.code} ${payload.project!.name}`,
    },
    content: payload.content,
    executor: {
      id: payload.executor.id,
      title: formFullUserName(payload.executor),
    },
  };
};
