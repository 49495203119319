import { apiChatRoomType } from "../../../../types/api/primaryTypes/apiPrimaryChatType";
import {
  chatMessageType,
  chatRoomType,
} from "../../../../types/stateTypes/chatType";
import { MessageProps } from "../../../UI/components/Chat/Message/Message";

export const createMessageDataUI = (
  dataMessages: chatMessageType[]
): MessageProps[] =>
  dataMessages.map((item) => ({
    ...item,
    isReverse: item.my,
    avatarUrl: `${item.authorAvatarUrl}`,
    isOverlook: Boolean(item.msgStatus),
  }));

export const createRoomData = (data: apiChatRoomType[]): chatRoomType[] => {
  return data.map((item) => {
    if (item.isGroup) {
      return {
        ...item,
        rooms: createRoomData(item.rooms!),
      };
    }
    return {
      ...item,
    };
  });
};
