import { createContext, useContext } from "react";
import { TTabsPanel } from "../../model/types";

const TabsPanelContext = createContext<TTabsPanel.Context | null>(null);

export function useTabsPanelContext() {
  const context = useContext(TabsPanelContext);
  if (!context) {
    throw new Error("Error! Error in the context of useTabsPanelContext");
  }
  return context;
}

export default TabsPanelContext;
