import React, { memo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import cls from "./ModalCompleteRow.module.scss";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../../../../styles/restyle";
import { ApiPrimaryTaskType } from "../../../../../../../../../types/api/primaryTypes/apiPrimaryTaskType";
import CustomButton from "../../../../../../../../newUI/CustomButton/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessExpertiseComplete } from "../../../../../../../../../app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertiseComplete";
import { fetchProjectProcessExpertise } from "../../../../../../../../../app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import { fetchExpertiseRemarks } from "../../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { TextLink } from "../../../../../../../../UI/components/TextLink/TextLink";
import { TModalMode } from "../RemarkTableContent";

type TInputs = {
  comment: string;
  reason: string;
  changes: string;
};

const reasonList = [
  "Замечание принято",
  "Замечание принято частично",
  "Замечание не принято",
];

interface ModalCompleteRowProps {
  task: ApiPrimaryTaskType;
  row: { text: string };
  closeModal: () => void;
  expertise?: boolean;
  can?: { complete?: boolean; save?: boolean; edit?: boolean };
  expertiseName: string;
  editMode?: boolean;
  files?: any;
  mode: TModalMode;
}

const schema = Yup.object().shape({
  reason: Yup.string()
    .ensure()
    .required("Необходимо выбрать «Статус замечания»."),
  comment: Yup.string().required("Необходимо заполнить «Ответ на замечание»."),
  changes: Yup.string().required(
    "Необходимо заполнить «Документация, в которую внесены изменения»."
  ),
});

export const ModalCompleteRow = memo(
  ({
    task,
    row,
    closeModal,
    expertise,
    can,
    expertiseName,
    editMode,
    files,
    mode,
  }: ModalCompleteRowProps) => {
    const dispatch = useAppDispatch();
    const process = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);

    const title = editMode ? (
      <h1>{`Изменение ответа на замечания  «${expertiseName}» по проекту «${process?.project.name}»`}</h1>
    ) : (
      <h1>{`Подготовка ответа на замечания  «${expertiseName}» по проекту «${process?.project.name}»`}</h1>
    );

    const isEditingMode = mode === "edit";

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm<TInputs>({
      mode: "onSubmit",
      resolver: yupResolver(schema),
      defaultValues: {
        reason: task?.workflow_data.reason,
        comment: task.comment,
        changes: task.workflow_data.changes,
      },
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          status: isEditingMode ? task?.status.key : 6,
          comment: dataValues.comment,
          reason: dataValues.reason,
          changes: dataValues.changes,
        },
      };
      if (processId) {
        await dispatch(
          fetchProjectProcessExpertiseComplete({ taskId: task?.id, data })
        );
        if (expertise) {
          dispatch(fetchExpertiseRemarks(processId));
        } else {
          await dispatch(fetchProjectProcessExpertise(processId));
        }
      }
      closeModal();
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        {title}
        <fieldset className={cls.fieldset}>
          <legend>Замечание</legend>
          <div className={cls.fieldset_files}>
            {row.text}
            {files?.map((file: any) => (
              <TextLink url={file.url} redirect target={"_blank"}>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <FormControl {...textFieldSX}>
          <InputLabel id="reason">Статус замечания</InputLabel>
          <Select
            {...register("reason")}
            labelId="reason"
            label="Статус замечания"
            defaultValue={task?.workflow_data.reason}
            error={!!errors.reason}
          >
            {reasonList.map((value) => (
              <MenuItem key={value} sx={menuItemSx} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {errors.reason && (
            <p className={cls.error}>{errors.reason.message}</p>
          )}
        </FormControl>

        <TextField
          {...textFieldSX}
          {...register("comment")}
          multiline
          minRows={2}
          label="Ответ на замечание"
          placeholder="Введите текст"
          defaultValue={task.comment}
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        <TextField
          {...textFieldSX}
          {...register("changes")}
          multiline
          minRows={2}
          label="Документация, в которую внесены изменения"
          defaultValue={task.workflow_data.changes}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        <CustomButton
          type="submit"
          width={can?.complete ? 200 : 120}
          background={palette.blue}
          className={cls.form_submit}
        >
          {isEditingMode ? "Сохранить" : "Отправить на проверку"}
        </CustomButton>
      </form>
    );
  }
);
