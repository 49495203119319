import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { index, accountsByUserType } from "./queries";

export const bankAccountApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    bankAccountIndex: index(builder),
    bankAccountAccountsByUserType: accountsByUserType(builder),
  }),
  overrideExisting: false,
});

export const {
  useBankAccountIndexQuery,
  useBankAccountAccountsByUserTypeQuery,
  useLazyBankAccountAccountsByUserTypeQuery,
} = bankAccountApi;
