import * as React from "react";
import {
  Accordion,
  SubTitleCount,
  TitleButton,
} from "src/FSD/shared/uiKit/v2/Accordion";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useState } from "react";
import ProcessWorkModals from "src/FSD/widgets/v2/processWork/modals";
import { AccordionWorks } from "./AccordionWorks";

type TProps = ApiProjectProcessResponses.TGroups & {
  setWorkPublication: (id: boolean) => void;
  setWorkPublicationId: (id: number) => void;
  userId: number;
};

export function GroupAccordion({
  userFio,
  userId,
  doneCount,
  inWorkCount,
  outDateCount,
  delegate,
  canDelegate,
  dot,
  works,
  setWorkPublication,
  setWorkPublicationId,
}: TProps) {
  const [showDelegate, setShowDelegate] = useState(false);
  const arrayWorks = Object.values(works);
  const pencilBtnClick = () => {
    setShowDelegate(true);
  };

  const hideDelegate = () => {
    setShowDelegate(false);
  };

  return (
    <>
      {canDelegate && (
        <Modal isActive={showDelegate} handleClose={hideDelegate}>
          <ProcessWorkModals.Delegate delegate={delegate} userId={userId} />
        </Modal>
      )}
      <Accordion
        title={userFio}
        dot={dot}
        titleAfter={
          canDelegate ? <TitleButton onClick={pencilBtnClick as any} /> : null
        }
        subTitle={
          <div>
            {delegate && (
              <span data-delegate={true}>
                Делегирование прав на исполнение раздела
              </span>
            )}
            <SubTitleCount
              inWorkCount={inWorkCount}
              outDateCount={outDateCount}
              doneCount={doneCount}
            />
          </div>
        }
      >
        {arrayWorks.map((item) => (
          <AccordionWorks
            key={item.work.id}
            {...item}
            userFio={userFio}
            setWorkPublication={setWorkPublication}
            setWorkPublicationId={setWorkPublicationId}
          />
        ))}
      </Accordion>
    </>
  );
}
