import React from "react";
import { MainPage } from "../../../pages/Main/MainPage";
import { IndexPageTemplate } from "../IndexPagesTemplate/IndexPagesTemplate";

export const MainPageTemplate: React.FC<unknown> = () => {
  return (
    <IndexPageTemplate>
      <MainPage />
    </IndexPageTemplate>
  );
};
