import { RootState } from "../../../../store";

export const getProjectMilestone = (state: RootState) =>
  state.projectMilestone.mileStone;

export const getProjectMilestoneIsLoad = (state: RootState) =>
  state.projectMilestone.isLoadMileStone;

export const getProjectMilestoneIsLoadBody = (state: RootState) =>
  state.projectMilestone.isLoadBody;

export const getProjectMilestoneOpenNode = (state: RootState) =>
  state.projectMilestone.openNode;

export const getProjectMilestoneIsAllToggle = (state: RootState) =>
  state.projectMilestone.isAllToggle;
