import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiBankAccountResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id?: number;
  firmId?: number;
  bik?: number;
  bankName?: string;
  bankAccount?: string;
  correspondentAccount?: string;
  default?: boolean;
}>;

export const index = (builder: QueryBuilder) =>
  builder.query<ApiBankAccountResponses.Index[], TParams>({
    query: (params) => ({
      url: `v1/bank-account/index`,
      params: {
        "BankAccountSearch[id]": params.id,
        "BankAccountSearch[firm_id]": params.firmId,
        "BankAccountSearch[bik]": params.bik,
        "BankAccountSearch[bank_name]": params.bankName,
        "BankAccountSearch[bank_account]": params.bankAccount,
        "BankAccountSearch[correspondent_account]": params.correspondentAccount,
        "BankAccountSearch[default]": params.default,
      },
    }),
  });
