import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  task: any;
}>;

export const update = (builder: QueryBuilder) =>
  builder.mutation<ApiTaskResponses.View, TParams>({
    query: ({ id, task }) => ({
      url: `v1/task/update?id=${id}`,
      method: HTTPMethods.PATCH,
      data: task,
    }),
    invalidatesTags: ["ProcessWork.View"],
  });
