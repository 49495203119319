import React from "react";
import ReactDOM from "react-dom";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { UserPolicy } from "../../../UI/components/Conditions/UserPolicy";
import { Modal } from "../../../UI/components/Modal/Modal";

export const ShowPrivacyPolicy = () => {
  const modalRoot = document.querySelector("#root-conditions");
  const { Popup } = useUserWorkflow();

  const Portal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  const onClose = () => {
    Popup.showPrivacyPolicy.close();
  };

  return (
    <Portal>
      <Modal
        head={"Политика конфиденциальности"}
        body={<UserPolicy />}
        closeHandler={onClose}
      />
    </Portal>
  );
};
