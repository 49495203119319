import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { noticesPageInitialState } from "./noticesPageInitialState";
import C from "./constants";
import { ApiPrimaryNoticesType } from "../../../types/api/primaryTypes/apiPrimaryNoticeType";
import { StateType } from "../../initialState";
import { ApiResponseNoticesType } from "../../../types/api/responseTypes/apiNoticeType";

export const noticesPageReducer = createReducer(
  noticesPageInitialState,
  (builder) => {
    /*
        Установка значений для страницы уведомлений
     */

    builder.addCase(
      C.SET_NOTICES_PAGE_NOTICE,
      (state, action: PayloadAction<ApiResponseNoticesType>) => {
        return {
          ...state,
          notices: action.payload.notices,
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorNotices = (state: StateType) => state.noticesPage.notices;
export const selectorNoticesMeta = (state: StateType) => state.noticesPage.meta;

export const setNotices = (
  notices: ApiPrimaryNoticesType[],
  meta: ApiResponseNoticesType["meta"]
) => ({
  type: C.SET_NOTICES_PAGE_NOTICE,
  payload: {
    notices,
    meta,
  },
});
