import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import moment from "moment";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import {
  fetchPublishInfo,
  fetchUpdatePublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { ModalSchema } from "../ModalSchema";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { SelectField } from "../../../../../newUI/SelectMui/SelectMui";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";
import { NewModalPreloader } from "../../../../../newUI/NewModal/NewModalPreloader";
import { NewCheckbox } from "../../../../../newUI/NewCheckbox/NewCheckbox";
import { ApiPrimaryProjectProcess } from "../../../../../../types/api/primaryTypes/apiPrimaryProjectProcess";
import styles from "./UpdatePublishModalContent.module.scss";
import { ApiPrimaryProjectType } from "../../../../../../types/api/primaryTypes/apiPrimaryProjectType";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import { ScrollWrap } from "../../../../../newUI/ScrollWrap/ScrollWrap";

interface PublishModalContentProps {
  node: TValuesData | ApiPrimaryProjectProcess;
  project: ProjectViewData | ApiPrimaryProjectType;
  closeModal: () => void;
}

const types = {
  1: "Физическое лицо",
  2: "Юридическое лицо",
  3: "Индивидуальный предприниматель",
  4: "Самозанятый",
};

type Inputs = {
  price: number;
  type: string;
  date_start: any;
  date_limit: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  pay1: number;
  pay2: number;
  pay3: number;
  paymentSum: number;
};

export const UpdatePublishModalContent = memo(
  ({ node, project, closeModal }: PublishModalContentProps) => {
    const { error422, update, publishInfo, isLoadPublishInfo } =
      useSelector(getProjectSections);
    const dispatch = useDispatch();

    const {
      id,
      haveExpertise,
      with_docs,
      projectPart,
      price,
      vor,
      vor_pnr,
      kac,
      date_limit,
      date_start,
      executorType,
    } = node;

    const defaultExecutorType = executorType?.map((el) => el.key.toString());
    const canAllCheck = vor && vor_pnr && kac;
    const additional =
      publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
    const defaultAgreementPrice = price === "Договорная";

    const [valueMulti, setValueMulti] = useState<string[]>(defaultExecutorType);
    const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
    const [firstRender, setFirstRender] = useState(true);
    const [checkVor, setCheckVor] = useState(vor);
    const [checkVorPnr, setCheckVorPnr] = useState(vor_pnr);
    const [checkKac, setCheckKac] = useState(kac);
    const [checkAll, setCheckAll] = useState(canAllCheck);
    const schema = ModalSchema(haveExpertise, with_docs, checkPrice);

    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

    useEffect(() => {
      setFirstRender(false);
    }, []);

    const {
      register,
      handleSubmit,
      control,
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        pay1: node.pay1,
        pay2: node.pay2,
        pay3: node.pay3,
        price: defaultAgreementPrice ? 0 : +price,
        date_start: start,
        date_limit: end,
        vor: !!vor,
        vor_pnr: !!vor_pnr,
        kac: !!kac,
      },
    });

    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        agreementPrice: checkPrice,
        vor: !publishInfo.vor ? false : checkVor,
        vor_pnr: !publishInfo.vor_pnr ? false : checkVorPnr,
        kac: !publishInfo.kac ? false : checkKac,
        price: (!checkPrice && dataValues.price) || null,
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        executor_type: dataValues.type.split(","),
        pay1: with_docs ? +dataValues.pay1 : null,
        pay2: with_docs ? +dataValues.pay2 : null,
        pay3: haveExpertise ? +dataValues.pay3 : null,
      };
      await dispatch(fetchUpdatePublishProcess({ id, data }));
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    };

    useEffect(() => {
      setValue("type", defaultExecutorType.join());
    }, []);

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all")) {
        const all = Object.keys(types);
        setValueMulti(all);
        setValue("type", "1,2,3,4");
      } else {
        setValueMulti(event.target.value);
        setValue("type", event.target.value);
      }
    };
    const handleClearClick = () => {
      setValueMulti([]);
      setValue("type", "");
    };

    useEffect(() => {
      // при изменении checkAll меняем состояние всех чекбоксов
      if (!firstRender) {
        setCheckVor(checkAll);
        setCheckVorPnr(checkAll);
        setCheckKac(checkAll);
      }
    }, [checkAll]);

    useEffect(() => {
      if (!checkVor && !checkVorPnr && !checkKac) {
        setCheckAll(false);
      }
      if (checkVor && checkVorPnr && checkKac) {
        setCheckAll(true);
      }
    }, [checkVor, checkVorPnr, checkKac]);

    const handleCheckAll = () => {
      setCheckAll(!checkAll);
    };

    useEffect(() => {
      if (update) {
        clearErrors();
        closeModal();
      }
    }, [update]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      dispatch(fetchPublishInfo({ id }));
    }, []);

    const priceRender = (
      <div className={styles.form_flex}>
        <div>
          <div className={styles.form_flex_center}>
            {!checkPrice && (
              <div>
                <p className={styles.formTitle}>Стоимость</p>
                <TextField
                  {...register("price")}
                  defaultValue={price}
                  error={!!errors.price}
                  InputLabelProps={{ shrink: false }}
                  className={styles.textfield}
                />
              </div>
            )}
            <label className={styles.form_label}>
              <NewCheckbox
                editing
                onChange={() => setCheckPrice(!checkPrice)}
                checked={checkPrice}
                classname={styles.checkbox}
              />
              Договорная стоимость
            </label>
          </div>
          {errors.price && (
            <p className={styles.error}>{errors.price.message}</p>
          )}
        </div>
      </div>
    );

    const payRender = (
      <>
        {with_docs && (
          <div className={styles.form_pay}>
            <FormControl>
              <p className={styles.formTitle}>Аванс (%)</p>
              <TextField
                {...register("pay1")}
                defaultValue={project.advance_pay}
                InputLabelProps={{ shrink: false }}
                error={!!errors.pay1}
              />
              {errors.pay1 && (
                <p className={styles.error}>{errors.pay1.message}</p>
              )}
            </FormControl>
            <FormControl>
              <p className={styles.formTitle}>После приемки (%)</p>
              <TextField
                {...register("pay2")}
                defaultValue={
                  !haveExpertise ? project.pay1 + project.pay2 : project.pay1
                }
                InputLabelProps={{ shrink: false }}
                error={!!errors.pay2}
              />
              {errors.pay2 && (
                <p className={styles.error}>{errors.pay2.message}</p>
              )}
            </FormControl>
            {haveExpertise && projectPart.published && (
              <FormControl>
                <p className={styles.formTitle}>После экспертизы (%)</p>
                <TextField
                  {...register("pay3")}
                  defaultValue={project.pay2}
                  error={!!errors.pay3}
                  InputLabelProps={{ shrink: false }}
                />
                {errors.pay3 && (
                  <p className={styles.error}>{errors.pay3.message}</p>
                )}
              </FormControl>
            )}
            <input type="hidden" {...register("paymentSum")} />
            {errors.paymentSum && (
              <p className={styles.paymentError}>{errors.paymentSum.message}</p>
            )}
          </div>
        )}
      </>
    );

    const typeRender = (
      <SelectField
        {...register("type")}
        name="type"
        label=""
        multiple
        multiValue={valueMulti}
        isAllClick
        onChange={onChangeMulti}
        options={Object.entries(types)}
        handleClearClick={handleClearClick}
        error={errors.type ? errors.type.message : null}
        newDesign
        newLabel={"Форма собственности"}
      />
    );

    const dateRender = (
      <div className={styles.form_date}>
        <div className={styles.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={() => (
              <DateField
                variant={styles.formElement}
                newLabel="Начальный срок"
                changeDateHandler={(date) => {
                  setValue("date_start", date);
                }}
                startDateProp={start}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={styles.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={styles.form_date_end}>
          <Controller
            control={control}
            name="date_limit"
            render={() => (
              <DateField
                variant={styles.formElement}
                newLabel="Конечный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                startDateProp={end}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={styles.error}>{errors.date_limit.message}</p>
          )}
        </div>
      </div>
    );

    const vorRender = (
      <div className={styles.form_flexColumn}>
        {(publishInfo.vor || publishInfo.vor_pnr) && (
          <div className={styles.form_flex}>
            {publishInfo.vor && (
              <div className={styles.form_checkBox}>
                <NewCheckbox
                  {...register("vor")}
                  editing
                  id="vor"
                  checked={checkVor}
                  onChange={() => setCheckVor(!checkVor)}
                  classname={styles.form_checkBox_input}
                />
                <label htmlFor="vor">Ведомость объемов работ</label>
              </div>
            )}
            {errors.vor && <p className={styles.error}>{errors.vor.message}</p>}
            {publishInfo.vor_pnr && (
              <div className={styles.form_checkBox}>
                <NewCheckbox
                  {...register("vor_pnr")}
                  editing
                  id="vorPnr"
                  checked={checkVorPnr}
                  onChange={() => setCheckVorPnr(!checkVorPnr)}
                  classname={styles.form_checkBox_input}
                />
                <label htmlFor="vorPnr">
                  Ведомость объемов работ на пусконаладочные работы
                </label>
              </div>
            )}
            {errors.vor_pnr && (
              <p className={styles.error}>{errors.vor_pnr.message}</p>
            )}
          </div>
        )}
        <div className={styles.form_flex}>
          {publishInfo.kac && (
            <div className={styles.form_checkBox}>
              <NewCheckbox
                {...register("kac")}
                editing
                id="kac"
                checked={checkKac}
                onChange={() => setCheckKac(!checkKac)}
                classname={styles.form_checkBox_input}
              />
              <label htmlFor="kac">Конъюнктурный анализ цен</label>
            </div>
          )}
          {canAllCheck && (
            <div className={styles.form_checkBox}>
              <NewCheckbox
                id="selectAll"
                checked={checkAll}
                onChange={handleCheckAll}
                classname={styles.form_checkBox_input}
              />
              <label htmlFor="selectAll">Выбрать все</label>
            </div>
          )}
        </div>
      </div>
    );

    if (isLoadPublishInfo) {
      return <NewModalPreloader />;
    }

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={styles.form}>
        <ScrollWrap height={"612px"} classname={styles.scroll}>
          <div className={styles.blockDate}>
            <h3 className={styles.subtitle}>Стоимость</h3>
            <div className={styles.blockDate_content}>
              <div>{dateRender}</div>
              {priceRender}
            </div>
          </div>
          {additional && (
            <div className={styles.docs}>
              <h3 className={styles.subtitle}>
                Дополнительные требования к разработке раздела
              </h3>
              {vorRender}
            </div>
          )}
          <h3 className={styles.subtitle}>Требования к исполнителю</h3>
          {typeRender}
        </ScrollWrap>
        <div className={styles.payBlock}>
          <div>
            {with_docs && (
              <>
                <h3 className={styles.payBlock_title}>Условия оплаты</h3>
                {payRender}
              </>
            )}
          </div>
          <div className={styles.form_submit}>
            <FormControl>
              <Button type="submit">Изменить</Button>
            </FormControl>
          </div>
        </div>
      </form>
    );
  }
);
