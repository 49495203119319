import { createReducer, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { userPageInitialState } from "./initialState";
import C from "./constants";
import { ApiUserPageHeader } from "../../../types/api/responseTypes/userIndividualPage/apiUserPageHeader";
import { StateType } from "../../initialState";
import { ApiUserPageContent } from "../../../types/api/responseTypes/userIndividualPage/apiUserContent";

export const userPageReducer = createReducer(
  userPageInitialState,
  (builder) => {
    builder.addCase(
      C.SET_USER_PAGE_HEADER_DATA,
      (state, action: PayloadAction<ApiUserPageHeader>) => {
        return {
          ...state,
          header: {
            ...state.header,
            ...action.payload,
          },
        };
      }
    );
    builder.addCase(
      C.SET_USER_PAGE_CONTENT_DATA,
      (state, action: PayloadAction<ApiUserPageContent>) => {
        return {
          ...state,
          content: {
            ...state.content,
            ...action.payload,
          },
        };
      }
    );
    // @ts-ignore
    builder.addCase(
      C.SET_USER_PAGE_TEAMS_DATA,
      (state, action: PayloadAction<any[]>) => {
        return {
          ...state,
          content: {
            ...state.content,
            teams: [...action.payload],
          },
        };
      }
    );
  }
);

export const setUserPageHeaderData =
  (data: ApiUserPageHeader) => (dispatch: Dispatch) =>
    dispatch({ type: C.SET_USER_PAGE_HEADER_DATA, payload: data });

export const setUserPageContentData =
  (data: ApiUserPageHeader) => (dispatch: Dispatch) =>
    dispatch({ type: C.SET_USER_PAGE_CONTENT_DATA, payload: data });

export const setUserPageTeamsData =
  (data: ApiUserPageHeader) => (dispatch: Dispatch) =>
    dispatch({ type: C.SET_USER_PAGE_TEAMS_DATA, payload: data });

export const selectorUserPageHeaderData = (state: StateType) =>
  state.userPage.header;

export const selectorUserPageContentData = (state: StateType) =>
  state.userPage.content;
