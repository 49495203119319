import React, { memo, useState } from "react";
import { IProjectsValue } from "../../../../../app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { DownloadArrow } from "../../../../newUI/SVG";
import cls from "./Project.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { trim } from "../../../User/View/Portfolio/Portfolio";

export const Project: React.FC<IProjectsValue & { id: string }> = memo(
  ({ name, files, id }) => {
    const [toggleProject, setToggleProject] = useState(false);
    return (
      <>
        <div onClick={() => setToggleProject(!toggleProject)}>
          <Accordion
            text={`Исходные данные по проекту ${name}`}
            toogle={toggleProject}
          />
        </div>
        {toggleProject && (
          <div className={cls.card}>
            <div className={cls.card_header}>
              <p>Наименование</p>
              <div className={cls.card_header_btn}>
                <TextLink
                  variant={cls.button}
                  title={"Скачать все"}
                  redirect
                  target="blank"
                  url={`/project/download-files-only-project?id=${id}`}
                >
                  <DownloadArrow />
                </TextLink>
              </div>
            </div>
            <div className={cls.card_content}>
              {files?.map((el) => (
                <TextLink
                  key={el?.url}
                  variant={cls.textLink}
                  redirect
                  target="blank"
                  url={el?.url}
                >
                  {trim(el?.name)}
                </TextLink>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
);
