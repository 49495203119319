import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchGipHelperDelegate,
  fetchProjectView,
} from "src/app/feature/ProjectView/thunks";
import { NewCheckbox } from "src/components/newUI/NewCheckbox/NewCheckbox";
import styles from "./Modal.module.scss";

interface ICheckBoxProps {
  accessId: number;
  checked: boolean;
}
export const CheckBox = memo(({ checked, accessId }: ICheckBoxProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const [firstRender, setFirstRender] = useState(true);
  const [check, setCheck] = useState(false);
  const onChangeHandler = async () => {
    await setCheck(!check);
  };
  async function requestFirstRender() {
    await setCheck(checked);
    await setFirstRender(false);
  }
  async function requestGipHelperDelegate() {
    await dispatch(fetchGipHelperDelegate({ accessId, delegate: check }));
    if (params.id) {
      await dispatch(fetchProjectView(params.id));
    }
  }

  useEffect(() => {
    if (firstRender) {
      requestFirstRender();
    }
    if (!firstRender) {
      requestGipHelperDelegate();
    }
  }, [check]);

  return (
    <label className={styles.delegate_label}>
      <NewCheckbox
        editing
        onChange={onChangeHandler}
        checked={check}
        classname={styles.delegate_label_checkBox}
      />
      Делегировать
    </label>
  );
});
