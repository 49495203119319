import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessExpertise } from "../../../../services/api/projectProcess/expertise";
import { ExpertiseType } from "../types/projectProcessExpertiseTypes";

export const fetchProjectProcessExpertise = createAsyncThunk<
  ExpertiseType[],
  number
>(
  "projectProcess/fetchProjectProcessExpertise",
  async (processId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessExpertise(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessExpertiseDuplicate = createAsyncThunk<
  ExpertiseType[],
  number
>(
  "projectProcess/fetchProjectProcessExpertiseDuplicate",
  async (processId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessExpertise(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
