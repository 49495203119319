import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiBankAccountResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  userTypeId: number;
}>;

export const accountsByUserType = (builder: QueryBuilder) =>
  builder.query<ApiBankAccountResponses.AccountsByUserType[], TParams>({
    query: (params) => ({
      url: `v1/bank-account/accounts-by-usertype`,
      params: {
        user_type_id: params.userTypeId,
      },
    }),
  });
