import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";

export const compareFileArrays = (data: TFile[], originalFiles: TFile[]) => {
  const initialFileIds = data.map((file) => file.id);
  const finalFileIds = originalFiles.map((file) => file.id);

  const isNewFileUploaded = initialFileIds.some(
    (id) => !finalFileIds.includes(id)
  );

  return isNewFileUploaded;
};
