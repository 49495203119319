import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  deleteBookByWorkId,
  fetchProjectComposition,
} from "src/app/feature/ProjectView/Contents/thunks";
import {
  getProjectComposition,
  clearProjectComposition,
} from "src/app/feature/ProjectView/Contents/getComposition";
import { ModalWrapper } from "src/components/pages/User/Edit/PersonalDataChange/PersonalDataChange";
import { fetchProjectView } from "src/app/feature/ProjectView/thunks";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import TabChildrenHeader from "src/FSD/widgets/v2/TabsPanel/ui/TabChildrenHeader/TabChildrenHeader";
import BookModal from "./BookModal";
import CompositionList from "./CompositionList";
import { Header } from "./Header/Header";
import { CompositionSkeleton } from "./CompositionSkeleton";
import styles from "./Composition.module.scss";
import { useTabsPanelContext } from "../../TabsPanel/ui/TabsPanel/TabsPanelContext";

export type TValuesForEditing = Record<string, number | string> | null;

export const Composition = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, error, pending } = useAppSelector(getProjectComposition);
  const params = useParams<{ id: string }>();
  const { project } = useSelector(getProjectViewData);
  const isTablet = useIsMobile(1600);

  const [workId, setWorkId] = useState<number | null>(null);
  const [valuesForEditing, setValuesForEditing] =
    useState<TValuesForEditing>(null);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectComposition(+params.id));
    }
    return () => {
      dispatch(clearProjectComposition());
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, []);

  useEffect(() => {
    if (workId === null) {
      setValuesForEditing(null);
    }
  }, [workId]);

  useEffect(() => {
    if (error.getComposition === null) {
      return;
    }
    navigate(`/project/${params?.id}?tab=info`);
  }, [error.getComposition]);

  const handleDeleteBook = async (workId: number) => {
    if (!window.confirm("Вы действительно хотите удалить данную книгу?")) {
      return;
    }

    await dispatch(deleteBookByWorkId(workId));
    setWorkId(null);

    if (params.id) {
      await dispatch(fetchProjectComposition(+params.id));
    }
  };

  const showFormAgain =
    data.project.length + data.work.length === 0 || project?.can.isAdmin;

  const { currentChildTab } = useTabsPanelContext();

  if (pending.getComposition) {
    return <CompositionSkeleton />;
  }

  return (
    <div className={styles.wrapper}>
      <TabChildrenHeader
        title="Состав проекта"
        className={styles.header}
        media={
          <Header
            showFormAgain={showFormAgain}
            id={params.id}
            activeTab={currentChildTab}
          />
        }
      />

      <ScrollWrap height={isTablet ? "auto" : "850px"}>
        {data?.[currentChildTab] && (
          <CompositionList
            data={data}
            tab={currentChildTab}
            setWorkId={setWorkId}
            workId={workId}
            setEditing={setValuesForEditing}
            handleDeleteBook={handleDeleteBook}
          />
        )}
      </ScrollWrap>
      <ModalWrapper
        show={Boolean(workId)}
        head={
          <div className={styles.head}>
            {valuesForEditing ? <>Редактирование</> : <>Создание</>} книги
          </div>
        }
        handleCloseModal={() => setWorkId(null)}
        classname={styles.modalBook}
        classnameWindow={styles.window}
      >
        <BookModal
          id={params.id}
          workId={workId as number}
          setWorkId={setWorkId}
          valuesForEditing={valuesForEditing}
        />
      </ModalWrapper>
    </div>
  );
};

export default Composition;
