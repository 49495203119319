import { createAsyncThunk } from "@reduxjs/toolkit";
import { pushError } from "../../errorTrace";
import {
  httpRequestPost,
  httpRequestPostMultiData,
} from "../../../services/api/api";
import { config } from "../../../../app.cofig";

const ApiTaskCompleteNotMulti = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};

const ApiTaskCompleteMulti = (
  taskId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    formData
  );
};

export const taskCompleteThunk = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data?: any;
    formData?: any;
  },
  { rejectValue: { status: string; message: string } }
>(
  "taskComplete/taskCompleteThunk",
  async ({ taskId, data, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = formData
        ? await ApiTaskCompleteMulti(taskId, formData)
        : await ApiTaskCompleteNotMulti(taskId, data);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);
