import React from "react";
import classNames from "classnames";
import styles from "./PageWrapper.module.scss";

interface PageWrapperProps {
  children: React.ReactNode;
  classname?: string;
}

export const PageWrapper = ({ classname, children }: PageWrapperProps) => {
  return (
    <div className={classNames(styles.wrapper, classname)}>{children}</div>
  );
};
