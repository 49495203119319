import React, { Dispatch, SetStateAction } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CKEditorWrapper } from "../../CKEditorWrapper/CKEditorWrapper";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { TagsMultiple, TagsTypeItem } from "../../SpecialForm/TagsMultiple";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { request } from "../../../../app/services/api/requestHandler";
import { ApiPostCreatePosts } from "../../../../app/services/api/posts/posts";
import style from "./style.module.scss";
import { PostCreateFormSchema } from "./yupSchemas";
import { AsyncFileReader } from "../../../../utils/AsyncFileReader";
import { textFieldSX } from "../../../../styles/restyle";

export type TPostCreateForm = {
  image: File | string;
  title: string;
  linkSource: string;
  tags: TagsTypeItem[];
  published: boolean;
  content: string | null;
};

type PostCreateFormProps = ComponentPropsType<{
  imageHandler?: Dispatch<SetStateAction<string>>;
}>;

export const PostCreateForm: React.FC<PostCreateFormProps> = ({
  variant = "",
  imageHandler = () => {},
}) => {
  const theme = useSelector(selectorAppTheme);
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<TPostCreateForm>({
    resolver: yupResolver(PostCreateFormSchema),
    defaultValues: {
      image: "",
      title: "",
      linkSource: "",
      tags: [] as TagsTypeItem[],
      published: false,
      content: "",
    },
    mode: "onBlur",
  });

  const onSubmit = (data: TPostCreateForm) => {
    const formData = new FormData();

    formData.append("photo_upload", data.image);
    formData.append("title", data.title);
    formData.append("url", data.linkSource);
    formData.append("published", `${Number(data.published)}`);
    formData.append("content", data.content as string);

    for (let i = 0; i < data.tags.length; i += 1) {
      formData.append(`tag_ids[${data.tags[i].id}]`, data.tags[i].id);
    }

    request(
      ApiPostCreatePosts(formData),
      (result, headersData, headers, status) => {
        if (status === 201) {
          navigate(`/post/view?id=${result}`);
        }
      }
    )();
  };

  const handleChangeFile = async (file: File) => {
    const reader = new AsyncFileReader();
    const content = await reader.readAsDataURL(file);
    imageHandler(content);
  };

  const watchPublished = watch("published");
  const watchTags = watch("tags");

  const TitleError = Boolean(errors.title) && (
    <ErrorMessage
      variant={style.postInputError}
      message={errors.title?.message}
    />
  );

  const ContentError = Boolean(errors.content) && (
    <ErrorMessage message={errors.content?.message} />
  );

  const TagsError = Boolean(errors.tags) && (
    <ErrorMessage
      variant={style.postInputError}
      message={errors.tags?.message}
    />
  );

  return (
    <form
      className={variant}
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classNames("inputWrapper")}>
        <input
          id="file"
          name="file"
          type="file"
          onChange={(e) => {
            if (e.target.files !== null) {
              setValue("image", e.target.files[0]);
              handleChangeFile(e.target.files[0]);
            }
          }}
        />
      </div>
      <div className={classNames("inputWrapper")}>
        <TextField
          {...register("title")}
          id="title"
          name="title"
          label={"Название"}
          variant="outlined"
          size="small"
          autoComplete="off"
          fullWidth
          error={Boolean(errors.title?.message)}
          helperText={errors.title?.message}
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {TitleError}
      </div>
      <div className={classNames("inputWrapper")}>
        <TextField
          {...register("linkSource")}
          id="linkSource"
          name="linkSource"
          label={"Ссылка на источник"}
          helperText={errors.linkSource?.message}
          variant="outlined"
          size="small"
          autoComplete="off"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
      </div>
      <div className={classNames("inputWrapper", style.tags)}>
        <TagsMultiple
          changeHandler={(data) => {
            // @ts-ignore
            setValue("tags", data);
            trigger("tags");
          }}
          error={Boolean(errors.tags)}
          value={watchTags}
        />
        {TagsError}
      </div>
      <div className={classNames("inputWrapper")}>
        <CKEditorWrapper
          changeHandler={(data: string) => {
            setValue("content", data);
          }}
        />
        {ContentError}
      </div>
      <div className="publishedWrapper">
        <FormControlLabel
          control={
            <Checkbox
              checked={watchPublished}
              onChange={(_, value) => {
                setValue("published", value);
              }}
              name="published"
              color="primary"
            />
          }
          label="Опубликованно"
        />
      </div>
      <div>
        <Button theme={theme} type="submit" color={"blue"}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};
