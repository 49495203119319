import { config } from "../../../../app.cofig";
import { httpRequestGet, httpRequestPost } from "../api";

export const ApiHeaderData = (): Promise<Response> => {
  // Запросить данные для отображения хедера
  return httpRequestGet(`${config.localDomain}/v1/site/get-top-menu`);
};

export const ApiFooterData = (): Promise<Response> => {
  // Запросить данные для отображения футера
  return httpRequestGet(`${config.localDomain}/v1/post/index?per-page=2`);
};

export const apiLogin = (data: {
  email: string;
  password: string;
}): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/site/login`,
    {},
    {
      LoginForm: {
        username: data.email,
        password: data.password,
      },
    }
  );
};

export const ApiLogout = (): Promise<Response> => {
  return httpRequestPost(`${config.localDomain}/v1/site/logout`, {}, {});
};
