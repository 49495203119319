import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setInboundForm,
  clearForms,
  setOutboundForm,
} from "../../app/feature/unsaved/unsavedController";
import { TAnyArray } from "../pages/Organisation/tabs/Info/types";

const useUnsavedControl = (controlledDataArray: TAnyArray) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInboundForm(controlledDataArray));

    return () => {
      dispatch(clearForms());
    };
  }, []);

  useEffect(() => {
    dispatch(setOutboundForm(controlledDataArray));
  }, controlledDataArray);
};

export default useUnsavedControl;
