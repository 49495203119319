import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { tasksPageInitialState } from "./tasksPageInitialState";
import { StateType } from "../../../initialState";
import { ApiResponseTasksPage } from "../../../../types/api/responseTypes/apiTasksType";
import { createTasksListFromApi } from "./createData/createData";

export const tasksPageReducer = createReducer(
  tasksPageInitialState,
  (builder) => {
    /*
        Установка значений на страницу задач
      */

    builder.addCase(
      C.SET_TASKS_PAGE_DATA,
      (state, action: PayloadAction<ApiResponseTasksPage>) => {
        return {
          ...state,
          tasks: createTasksListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorTasksItems = (state: StateType) => state.tasksPage.tasks;

export const selectorTasksMeta = (state: StateType) => state.tasksPage.meta;
