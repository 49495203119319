import MenuItem from "@mui/material/MenuItem";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import cn from "classnames";
import { CheckLabel } from "src/FSD/shared/uiKit/v2/CheckIcon";
import styles from "./Requirements.module.scss";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";
import { useRequirements } from "../../lib/hooks/useRequirements";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Requirements = ({ processWork }: Props) => {
  const { handleUpdateAddition, threeDotButtonRef, showOptions } =
    useRequirements(processWork);

  // + будет модалка ProcessWork.AdditionalRequirements
  return (
    <Card
      header={<Card.Header>Требования к разделу</Card.Header>}
      controls={
        <Card.Controls location="top">
          {showOptions && (
            <ThreeDotButton ref={threeDotButtonRef} color="success">
              <MenuItem onClick={handleUpdateAddition} disableRipple>
                Редактировать
              </MenuItem>
            </ThreeDotButton>
          )}
        </Card.Controls>
      }
    >
      {processWork.projectPart.published && (
        <div className={styles.checksList}>
          <div
            className={cn(
              styles.checksList__column,
              styles.checksList__column_short
            )}
          >
            <h3>Договор</h3>
            <CheckLabel
              isCheck={processWork.secure}
              text="Безопасная сделка"
              className={styles.checksList__check}
              fontSize={20}
            />
            <CheckLabel
              isCheck={processWork.with_docs}
              text="Заключение договора"
              className={styles.checksList__check}
              fontSize={20}
            />
          </div>
          <div className={styles.checksList__column}>
            <h3>Разработка</h3>
            <h3 />
            <CheckLabel
              isCheck={processWork.bim}
              text="BIM"
              className={styles.checksList__check}
              fontSize={20}
            />
            <CheckLabel
              isCheck={processWork.vor}
              text="Ведомость объемов работ"
              className={styles.checksList__check}
              fontSize={20}
            />
            <CheckLabel
              isCheck={processWork.kac}
              text="Конъюнктурный анализ цен"
              className={styles.checksList__check}
              fontSize={20}
            />
            <CheckLabel
              isCheck={processWork.vor_pnr}
              text="ВОР на пусконаладочные работы"
              className={styles.checksList__check}
              fontSize={20}
            />
          </div>
        </div>
      )}
      <hr className={styles.yellowLine} />
    </Card>
  );
};
