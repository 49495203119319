import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { useRequestCustomerAlignComplete } from "../lib/hooks";

type Props = {
  id: number;
  header: string;
};

export const RequestCustomerAlignCompleteForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    control,
    errors,
    isSubmitting,
    isTaskViewPending,
  } = useRequestCustomerAlignComplete({
    id,
    defaultValues: {
      files: [],
      comment: "",
    },
    handleClose,
  });

  // добавить isLoading
  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange } }) => (
              <Uploader
                canEdit
                initialFiles={[]}
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors.files)}
                helperText={errors.files?.message}
              />
            )}
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
