import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  CheckTaskCounts,
  CheckTaskDataType,
  fetchProjectCheckTaskTree,
} from "./thunks";
import { StatusType } from "../TaskReducer/types";

type InitialStateProjectView = {
  checkTasks: CheckTaskDataType[];
  status: Omit<StatusType, "work">;
  counts: CheckTaskCounts;
  isLoad: boolean;
  fullPageLoad: boolean;
  bodyLoad: boolean;
  error: any;
  toggleAccordion: any;
  isAllToggle: boolean;
};
const initialState: InitialStateProjectView = {
  checkTasks: [],
  toggleAccordion: {},
  isAllToggle: false,
  counts: {
    check: 0,
    done: 0,
    total: 0,
  },
  status: {
    check: false,
    done: false,
  },
  isLoad: false,
  fullPageLoad: false,
  bodyLoad: false,
  error: null,
};

const projectCheckTaskSlice = createSlice({
  name: "projectCheckTaskSlice",
  initialState,
  reducers: {
    setError(state) {
      state.error = false;
    },
    setStatus(state, action: PayloadAction<Omit<StatusType, "work">>) {
      state.status = action.payload;
    },
    setToggleAccordion(state, action) {
      const index = action.payload;
      if (state.toggleAccordion[index] !== undefined) {
        state.toggleAccordion[index] = undefined;
      } else {
        state.toggleAccordion[index] = index;
      }
    },
    setAllToggle(state, action) {
      const newObject: any = {};
      Object.keys(state.toggleAccordion).forEach((key) => {
        newObject[key] = action.payload;
      });
      state.toggleAccordion = newObject;
      state.isAllToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCheckTaskTree.pending, (state, action) => {
      const { load } = action.meta.arg;
      if (load === "fullPageLoad") {
        state.fullPageLoad = true;
      }
      if (load === "bodyLoad") {
        state.bodyLoad = true;
      }
      state.isLoad = true;
    });
    builder.addCase(fetchProjectCheckTaskTree.fulfilled, (state, action) => {
      state.isLoad = false;
      state.fullPageLoad = false;
      state.bodyLoad = false;

      const initialToggle: any = {};
      const valueData = Object.values(action.payload.data);
      valueData.forEach((value, index) => {
        initialToggle[index + 1] = undefined;
      });
      state.checkTasks = valueData;
      state.counts = action.payload.counts;
      state.toggleAccordion = initialToggle;
    });
    builder.addCase(fetchProjectCheckTaskTree.rejected, (state, action) => {
      state.isLoad = false;
      state.bodyLoad = false;
      state.fullPageLoad = false;
      state.error = action.payload;
    });
  },
});
export const { setError, setStatus, setToggleAccordion, setAllToggle } =
  projectCheckTaskSlice.actions;
export const getProjectCheckTasks = (state: RootState) =>
  state.projectCheckTask;
export default projectCheckTaskSlice.reducer;
