import React, { useRef, useState } from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { ScrollWrap } from "../ScrollWrap/ScrollWrap";
import cls from "./TooltipWrapper.module.scss";
import { palette } from "../../../styles/restyle";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.midnightBlack,
    color: palette.silverGrey,
    maxWidth: 220,
    fontSize: 14,
    fontWeight: 500,
    padding: 10,
  },
}));

interface TooltipWrapperProps {
  content: any;
  children: React.ReactElement<any, any>;
  theme?: TooltipTheme;
  title?: string;
  width?: string;
  textColor?: string;
  classname?: string;
  style?: any;
}

export enum TooltipTheme {
  TITLE = "titleTooltip", // с заголовком title и скроллом
  DEFAULT = "tooltip", // без переноса текста в одну линию
  MUI = "mui",
  THREE_LINES = "three",
  CONTROL_WIDTH = "control",
}

export const TooltipWrapper = ({
  content,
  children,
  theme = TooltipTheme.DEFAULT,
  title,
  width = "466px",
  textColor = "#D5D6DE",
  classname,
  style,
}: TooltipWrapperProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const refSetTimeout = useRef<NodeJS.Timeout>();

  const onMouseEnterHandler = () => {
    refSetTimeout.current = setTimeout(() => {
      setShowTooltip(true);
    }, 750);
  };

  const onMouseLeaveHandler = () => {
    clearTimeout(refSetTimeout.current);
    setShowTooltip(false);
  };

  if (theme === TooltipTheme.MUI) {
    return <HtmlTooltip title={content}>{children}</HtmlTooltip>;
  }

  return (
    <div
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      style={{ ...style, position: "relative" }}
      id="parent-container"
    >
      <div className={classname}>{children}</div>
      {showTooltip && !!content.length && (
        <>
          {theme === TooltipTheme.DEFAULT && (
            <div className={classNames(cls.basic, cls[theme])}>
              <>
                <p
                  style={{
                    color: textColor,
                  }}
                >
                  {content}
                </p>
              </>
            </div>
          )}
          {theme === TooltipTheme.THREE_LINES && (
            <div className={classNames(cls.basic, cls[theme])}>
              <>
                <p
                  style={{
                    color: textColor,
                  }}
                >
                  {content}
                </p>
              </>
            </div>
          )}
          {theme === TooltipTheme.CONTROL_WIDTH && (
            <div
              style={{ width }}
              className={classNames(cls.basic, cls[theme])}
            >
              <>
                <p
                  style={{
                    color: textColor,
                    whiteSpace: "pre-line",
                  }}
                >
                  {content}
                </p>
              </>
            </div>
          )}
          {theme === TooltipTheme.TITLE && (
            <div className={classNames(cls.basic, cls[theme])}>
              <>
                {title && <h1>{title}</h1>}
                <ScrollWrap height={"132px"} width={width}>
                  <p
                    style={{
                      color: textColor,
                    }}
                  >
                    {content}
                  </p>
                </ScrollWrap>
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
};
