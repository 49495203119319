import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessUsersTypes } from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessUsersTypes = createAsyncThunk<
  Record<number, string>,
  { processId: number; userId: number }
>(
  "projectProcess/fetchProjectProcessUsersTypes",
  async ({ processId, userId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessUsersTypes(processId, userId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
