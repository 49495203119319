import React from "react";
import { Skeleton } from "@mui/material";
import style from "./style.module.scss";

export const ParticipantSkeleton = () => {
  return (
    <div className={style.participant}>
      <Skeleton variant="rectangular" className={style.participant} />
    </div>
  );
};
