import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { fetchProjectView } from "src/app/feature/ProjectView/thunks";
import { fetchProjectGanttDataNew } from "src/app/feature/ProjectView/GraphReducer/thunks";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { GraphProject } from "./GraphProject/GraphProject";
import { GraphSkeleton } from "./GraphSkeleton";

const Graph = () => {
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMobile = useIsMobile(500);

  const { isLoadGraph } = useSelector(getProjectGraphData);
  const { error } = useSelector(getProjectGraphData);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
      dispatch(fetchProjectGanttDataNew(+params.id));
    }
  }, [dispatch, params.id]);

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
  }

  if (isLoadGraph) {
    return <GraphSkeleton />;
  }
  if (!isMobile) {
    return <GraphProject />;
  }

  return null;
};

export default Graph;
