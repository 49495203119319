import { httpRequestDelete, httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetNotices = (paramsString: string): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/notice/index${paramsString}&per-page=10`
  );

export const ApiPostAllRead = (): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/notice/read-all`);

// toggle = unread or read
export const ApiPostReadToggle = (toggle: string, id: number) =>
  httpRequestPost(`${config.localDomain}/v1/notice/${toggle}?id=${id}`);

export const ApiDeleteNotice = (id: number) =>
  httpRequestDelete(`${config.localDomain}/v1/notice/delete?id=${id}`);
