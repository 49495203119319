import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import { getTreaties } from "./thunks";
import { createTreaties } from "./utils";

export type TTreaty = {
  id: number;
  statusKey: number;
  status: ("green" | "orange")[];
  title: string;
  reason: string | null;
  typeId?: number;
  sections: {
    name: string;
    letters: ("П" | "Р")[];
  }[];
  date: string;
  price: {
    secure: boolean;
    value: string;
    nds: string;
  } | null;
  documents?: any;
};

export type TDocument = TTreaty;

export type TStateData<T> = {
  treaties: T;
  documents: T;
};

export type TState = {
  data: TStateData<Record<string, TTreaty[]>>;
  pending: TStateData<boolean>;
  error: TStateData<string | null>;
};

const initialState: TState = {
  data: {
    treaties: {},
    documents: {},
  },
  pending: {
    treaties: false,
    documents: false,
  },
  error: {
    treaties: null,
    documents: null,
  },
};

const dashboardTreaties = createSlice({
  name: "dashboardTreaties",
  initialState,
  reducers: {
    setDocumentsByTreatyId(state, action) {
      const id = action.payload;

      let documents = {};
      Object.values(state.data.treaties).forEach((treaties: TTreaty[]) => {
        const treaty = treaties.find((treaty: TTreaty) => treaty.id === id);

        if (treaty) {
          documents = { ...treaty.documents };
        }
      });

      state.data.documents = documents;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getTreaties), (state) => {
        state.pending.treaties = true;
      })
      .addMatcher(isFulfilled(getTreaties), (state, action) => {
        state.data.treaties = createTreaties(action.payload);
        state.pending.treaties = false;
      })
      .addMatcher(isRejected(getTreaties), (state, action) => {
        state.pending.treaties = false;
        state.error.treaties = action.payload as string;
      });
  },
});

export const { setDocumentsByTreatyId } = dashboardTreaties.actions;

export const getDashboardTreaties = (state: CombinedState<any>) =>
  state.dashboard.treaties;

export default dashboardTreaties;
