import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchGipRemove,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import styles from "./style.module.scss";

type Inputs = {
  content: string;
};

const schema = Yup.object().shape({
  content: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

export const RemoveGip = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      StartForm: {
        content: dataValues.content,
      },
    };
    const { id } = params;
    if (id) {
      await dispatch(fetchGipRemove({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(id));
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={styles.removeGipForm}
      >
        <TextField
          multiline
          minRows={2}
          placeholder="Введите комментарий"
          label="Комментарий к заявке"
          className={styles.removeGipForm_textarea}
          {...register("content")}
        />
        {errors.content ? (
          <p className={styles.error}>{errors.content.message}</p>
        ) : null}
        <FormControl className={styles.removeGipForm_btn}>
          <Button type="submit">Снять</Button>
        </FormControl>
      </form>
    </div>
  );
};
