import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../services/api/requestHandler";
import { ApiProjectCheckTask } from "../../../services/api/project/checkTask";
import { setStatus } from "./projectCheckTask";

export type TaskCheckType = {
  id: number;
  statusText: string;
  status: { key: number; value: string };
  color: string;
  executor: {
    name: string;
    surname: string;
    patronymic: string;
  };
  taskProcess: {
    projectPart: {
      code: string;
    };
  };
};

export type ChecksType = {
  code: string;
  comment: string;
  statusText: string;
  dot: string | null;
  executor: string;
  name: string;
  date: string;
  color: string;
  taskId: number;
  tasks: TaskCheckType[];
};

export type CheckTaskDataType = {
  title: string;
  dot: string | null;
  checks: ChecksType[];
};

export interface CheckTaskCounts {
  check: number;
  done: number;
  total: number;
}

export type CheckTaskTreeType = {
  counts: CheckTaskCounts;
  data: Record<string, CheckTaskDataType>;
};

export const fetchProjectCheckTaskTree = createAsyncThunk<
  CheckTaskTreeType,
  { id: number; status: string; load?: string }
>(
  "fetchProjectCheckTaskTree/fetch",
  async ({ id, status }, { rejectWithValue, dispatch }) => {
    try {
      if (status === "4") {
        dispatch(setStatus({ check: false, done: true }));
      }
      if (status === "6") {
        dispatch(setStatus({ check: true, done: false }));
      }
      if (status === "") {
        dispatch(setStatus({ check: false, done: false }));
      }
      const response = await ApiProjectCheckTask(id, status);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
