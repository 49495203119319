import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { Select } from "../../UI/components/FormComponentns/Select";
import { ApiUpdateUserRole } from "../../../app/services/api/organisation/organisation";
import { request } from "../../../app/services/api/requestHandler";
import { selectorChangeUserRoleData } from "../../../app/feature/eventEmitter/organisation/eventShowUserRole";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { margin } from "./SendMoneyForm";
import { palette } from "../../../styles/restyle";

export type SelectItemProps = ComponentPropsType<unknown>;

export type SetUserRoleFormProps = ComponentPropsType<{
  submitHandler?: () => void;
  submitClose?: () => void;
  closeHandler?: VoidFunction;
}>;

const TEAM_LEAD = "Руководитель группы";

export const SetUserRoleForm: React.FC<SetUserRoleFormProps> = ({
  variant = "",
  closeHandler = () => {},
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { Popup } = useUserWorkflow();
  const { id, roles, roleId } = useSelector(selectorChangeUserRoleData);
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      roleId: {
        id: roleId.key,
        title: roleId.title,
      },
    },
  });

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const opacity = disabled ? ".5" : "1";

  const options: any = Object.keys(roles)
    .map((key) => {
      return { id: +key, title: roles[key] };
    })
    .filter((item: any) => item.title !== TEAM_LEAD);

  const onSubmit = async (data: any) => {
    setDisabled(true);

    await request(
      ApiUpdateUserRole(id, data.roleId.id),
      () => {
        enqueueSnackbar("Роль пользователя успешно обновлена", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.showUserRole.set({});
          closeHandler();
        }, 500);
      },
      () => (err) => {
        if (err.status === 403) {
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar("Ошибка обновления роли пользователя", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        setTimeout(() => {
          setDisabled(false);
          Popup.showUserRole.set({});
          closeHandler();
        }, 500);
      }
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, variant)}
    >
      <Controller
        control={control}
        name="roleId"
        render={({ field: { value: fieldValue } }) => (
          <Select
            value={fieldValue}
            name={"roleId"}
            changeHandler={(_, value) => {
              setValue("roleId", value);
            }}
            label={"Роль"}
            options={options}
            isLoading={false}
            disableClear
          />
        )}
      />
      <br />
      <CustomButton
        disabled={disabled}
        type="submit"
        background={palette.blue}
        width={160}
        style={{
          opacity,
          margin,
        }}
      >
        Сохранить
      </CustomButton>
    </form>
  );
};
