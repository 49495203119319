import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiCommonResponses } from "../../../apiCommonResponses";

type TParams = RequestParams<{
  id: number;
  secure: boolean;
}>;

export const secureMissingAttributes = (builder: QueryBuilder) =>
  builder.query<ApiCommonResponses.SecureMissingAttributes[], TParams>({
    query: (params) => ({
      url: `v1/user-type/secure-missing-attributes`,
      params: {
        id: params.id,
        secure: params.secure,
      },
    }),
  });
