import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiCommonResponses } from "../../../apiCommonResponses";

type TParams = RequestParams<{
  id: number;
}>;

export const publishInfo = (builder: QueryBuilder) =>
  builder.query<ApiCommonResponses.PublishInfo, TParams>({
    query: (params) => ({
      url: `v1/project-process/publish-info`,
      params: {
        id: params.id,
      },
    }),
  });
