import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  GipHelpersDelegate,
  GipHelpersIcon,
  QuestionCircle,
} from "src/components/newUI/SVG";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { getUserId } from "src/app/services/auth/auth";
import {
  TooltipTheme,
  TooltipWrapper,
} from "src/components/newUI/TooltipWrapper/TooltipWrapper";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { DelegateModal } from "../Modal/DelegateModal";
import styles from "../GipCard.module.scss";

const sx = { color: "white" };

export const GipHelpers = () => {
  const { project } = useSelector(getProjectViewData);
  const gip = project?.gip;
  const [gipDelegateModal, setGipDelegateModal] = useState(false);
  const canIsGip = getUserId() === gip?.id;
  const canDelegate = canIsGip && !!project?.gipHelpers?.length;
  const sortedGipHelpers = project?.gipHelpers?.slice(0, 3).sort((a, b) => {
    if (a.delegate && !b.delegate) {
      return -1;
    }
    if (!a.delegate && b.delegate) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={styles.gip_helpers_block}>
      <div className={styles.gip_helpers_btn}>
        <h4>Помощник(и)</h4>
        {canDelegate && (
          <>
            <NewModal
              active={gipDelegateModal}
              setActive={setGipDelegateModal}
              title={"Помощники"}
            >
              <DelegateModal
                gipHelpers={project?.gipHelpers}
                gipRemove={project?.can?.removeGipHelpers}
              />
            </NewModal>
            <TooltipWrapper content={"Управление помощниками"}>
              <IconButton
                className={styles.gip_helpers_icon}
                sx={sx}
                onClick={() => setGipDelegateModal(true)}
              >
                <GipHelpersIcon width="18" height="18" />
              </IconButton>
            </TooltipWrapper>
          </>
        )}
        {!gip?.firmId && canIsGip && (
          <TooltipWrapper
            content={
              ' Вы можете пригласить помощника со страницы пользователя по кнопке "Пригласить помощником ГИП" '
            }
            classname={styles.causesContent}
            theme={TooltipTheme.THREE_LINES}
          >
            <div className={styles.gip_helpers_question}>
              <QuestionCircle />
            </div>
          </TooltipWrapper>
        )}
      </div>
      {project?.gipHelpers?.length === 0 && <p>Не назначены</p>}
      {sortedGipHelpers?.map((el) =>
        el.delegate ? (
          <React.Fragment key={el.id}>
            <p
              className={classNames(el.delegate && styles.gip_helpers_delegate)}
            >
              <GipHelpersDelegate />
              {el.name}
            </p>
          </React.Fragment>
        ) : (
          <p className={styles.gip_helpers_notdelegate} key={el.id}>
            {el.name}
          </p>
        )
      )}
    </div>
  );
};
