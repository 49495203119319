import React, { memo } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

interface DotProps {
  status: string;
  size?: number | null;
}

export const Dot: React.FC<DotProps> = memo(({ status, size }) => {
  const scalePercent = size ? size / 20 : 0.45;

  const sxGreen = { color: "#3BAF6A", transform: `scale(${scalePercent})` };
  const sxYellow = { color: "#E8A037", transform: `scale(${scalePercent})` };
  const sxGray = { color: "#6B7D8A", transform: `scale(${scalePercent})` };
  const sxRed = { color: "#d7322f", transform: `scale(${scalePercent})` };
  return (
    <>
      {status === "ok" && <FiberManualRecordIcon sx={sxGreen} />}
      {status === "warning" && <FiberManualRecordIcon sx={sxYellow} />}
      {status === "gray" && <FiberManualRecordIcon sx={sxGray} />}
      {status === "error" && <FiberManualRecordIcon sx={sxRed} />}
    </>
  );
});
