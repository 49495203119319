import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useParams } from "react-router-dom";
import { StarSVG } from "../../../../newUI/SVG";
import style from "./style.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";

export const MAX_SYMBOLS = 270;
export const NO_INFO = "Пользователь не предоставил информацию.";
export const sx = { color: "white" };

export const trim = (label: string) => {
  const ratingPeople = ["Проектировщик", "Заказчик", "ГИП", "Поставщик"];
  const result = ratingPeople.filter((item: any) =>
    label.toLowerCase().includes(item.toLowerCase())
  );

  return result.length > 0 ? result[0] : label;
};

export const getText = (open: boolean, text: any) =>
  open || text.length < MAX_SYMBOLS
    ? text.length
      ? text
      : NO_INFO
    : `${text.substring(0, MAX_SYMBOLS)}...`;

type RatingCardProps = {
  title: string;
  value: number;
  work: number;
  done: number;
};

const RatingCard: React.FC<RatingCardProps> = ({
  title,
  value,
  work,
  done,
}) => {
  const params = useParams<{ id: string }>();

  const getLink = (title: string, active?: boolean): string => {
    if (title === "Заказчик") {
      if (active) {
        return `/project/search?ProjectSearch[status]=<>3&ProjectSearch[authorId]=${params.id}&ProjectSearch[withoutGip]=0`;
      }
      return `/project/search?ProjectSearch[status]=3&ProjectSearch[authorId]=${params.id}&ProjectSearch[withoutGip]=0`;
    }

    if (title === "ГИП") {
      if (active) {
        return `/project/search?ProjectSearch[status]=<>3&ProjectSearch[gipId]=${params.id}`;
      }
      return `/project/search?ProjectSearch[status]=3&ProjectSearch[gipId]=${params.id}`;
    }

    if (active) {
      return `/project/search?ProjectSearch[status]=&ProjectSearch[executorId]=${params.id}&ProjectSearch[executorProcessStatus]=2`;
    }
    return `/project/search?ProjectSearch[executorId]=${params.id}&ProjectSearch[executorProcessStatus]=3&ProjectSearch[status]=<>1`;
  };

  return (
    <div className={style.ratingBlock}>
      <div className={style.position}>
        <div>{title}</div>
        <div>Проекты</div>
      </div>
      <div className={style.star}>
        <div className={style.icon}>
          <StarSVG />
        </div>
        <div>{value.toFixed(1)}</div>
      </div>
      <div className={style.stats}>
        <TextLink variant={style.link} url={getLink(title, true)}>
          {String(work)}
        </TextLink>
        <p>Активно</p>
      </div>
      <div className={style.stats}>
        <TextLink variant={style.link} url={getLink(title)}>
          {String(done)}
        </TextLink>
        <p>Выполнено</p>
      </div>
    </div>
  );
};

type InfoProps = {
  data: any;
  headers: any;
};

export const Info: React.FC<InfoProps> = ({ data, headers }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div className={style.wrapper}>
      <div className={style.rating}>
        <p>Рейтинг</p>
        <div className={style.ratingWrapper}>
          {headers.ratingItems.map((item: any) => (
            <RatingCard
              title={trim(item.label)}
              value={item.roundUp * item.value * 0.01}
              work={item.work}
              done={item.done}
            />
          ))}
        </div>
      </div>

      <div className={style.working}>
        <p>Специализация</p>
        <div className={style.tiles}>
          {headers.skills.map((item: any) => (
            <>
              <div>{item}</div>
            </>
          ))}
        </div>
      </div>
      <div className={style.about}>
        <p>
          <div>О себе</div>
          {data.about.length > MAX_SYMBOLS && (
            <IconButton onClick={handleOpen}>
              {open ? (
                <ArrowDropDownIcon sx={sx} />
              ) : (
                <ArrowDropUpIcon sx={sx} />
              )}
            </IconButton>
          )}
        </p>
        <div className={style.text}>{getText(open, data.about)}</div>
      </div>
    </div>
  );
};
