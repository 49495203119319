export const VerifiedSVG = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5837 1.64887C17.453 1.55486 17.3003 1.48809 17.1368 1.45347C16.9732 1.41885 16.8031 1.41726 16.6387 1.44881C15.4366 1.67282 14.1956 1.67568 12.9922 1.4572C11.7888 1.23871 10.6484 0.803521 9.64125 0.178411C9.45293 0.0622475 9.2292 0 9 0C8.7708 0 8.54707 0.0622475 8.35875 0.178411C7.3516 0.803521 6.21121 1.23871 5.00779 1.4572C3.80437 1.67568 2.56339 1.67282 1.36126 1.44881C1.19694 1.41726 1.02675 1.41885 0.863219 1.45347C0.699684 1.48809 0.546954 1.55486 0.416256 1.64887C0.285733 1.74302 0.180592 1.86197 0.10856 1.997C0.0365281 2.13202 -0.000564964 2.27967 6.50417e-06 2.42912V9.88145C-0.000993661 11.3156 0.383376 12.7293 1.12111 14.0047C1.85884 15.2801 2.92858 16.3803 4.24125 17.2137L8.3475 19.8146C8.53803 19.9352 8.76611 20 9 20C9.23389 20 9.46197 19.9352 9.6525 19.8146L13.7587 17.2137C15.0714 16.3803 16.1412 15.2801 16.8789 14.0047C17.6166 12.7293 18.001 11.3156 18 9.88145V2.42912C18.0006 2.27967 17.9635 2.13202 17.8914 1.997C17.8194 1.86197 17.7143 1.74302 17.5837 1.64887ZM15.75 9.88145C15.7509 10.9966 15.4522 12.0957 14.8789 13.0875C14.3055 14.0792 13.4741 14.9349 12.4537 15.5832L9 17.7739L5.54625 15.5832C4.52592 14.9349 3.69449 14.0792 3.12115 13.0875C2.54781 12.0957 2.24914 10.9966 2.25001 9.88145V3.57948C4.60849 3.75897 6.97055 3.2724 9 2.18904C11.0295 3.2724 13.3915 3.75897 15.75 3.57948V9.88145ZM10.7325 7.59073L7.70625 10.2916L6.705 9.39129C6.49316 9.20293 6.20584 9.09711 5.90625 9.09711C5.60666 9.09711 5.31934 9.20293 5.1075 9.39129C4.89566 9.57966 4.77665 9.83513 4.77665 10.1015C4.77665 10.3679 4.89566 10.6234 5.1075 10.8117L6.9075 12.4122C7.01209 12.506 7.13651 12.5804 7.2736 12.6312C7.41069 12.682 7.55774 12.7081 7.70625 12.7081C7.85476 12.7081 8.00181 12.682 8.1389 12.6312C8.27599 12.5804 8.40042 12.506 8.505 12.4122L12.375 9.00117C12.5868 8.81281 12.7058 8.55734 12.7058 8.29095C12.7058 8.02457 12.5868 7.76909 12.375 7.58073C12.1632 7.39237 11.8758 7.28654 11.5762 7.28654C11.2767 7.28654 10.9893 7.39237 10.7775 7.58073L10.7325 7.59073Z"
      fill="#9DAFBD"
    />
  </svg>
);
