import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setHeaderCountMessage,
  setHeaderCountNotice,
} from "../../app/feature/header/header";
import { request } from "../../app/services/api/requestHandler";
import {
  ApiGetCountNotices,
  ApiGetCountMessages,
} from "../../app/services/api/globalCounts/counts";
import { userIsAuth } from "../../app/services/auth/auth";

export const useGlobalCounts = () => {
  const dispatch = useDispatch();

  const requestCounts = () =>
    setTimeout(async () => {
      await request(
        ApiGetCountNotices(),
        dispatch(setHeaderCountNotice)
      )(dispatch);
      await request(
        ApiGetCountMessages(),
        dispatch(setHeaderCountMessage)
      )(dispatch);
      requestCounts();
    }, 30000);

  useEffect(() => {
    if (!userIsAuth()) return;
    requestCounts();
  }, []);
};
