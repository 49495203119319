import React, { memo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import cls from "./CheckIconType.module.scss";

interface ICheckIconTypeProps {
  isCheck: boolean;
  colorCheck: string;
  colorClose: string;
  fontSize: string;
  text: string;
  className?: string;
}
export const CheckIconType = memo(
  ({
    isCheck,
    colorCheck,
    colorClose,
    fontSize,
    text,
    className,
  }: ICheckIconTypeProps) => {
    return (
      <>
        {isCheck && (
          <div
            className={classNames(cls.icons, className)}
            style={{ color: colorCheck, fontSize }}
          >
            <CheckIcon color="inherit" fontSize="inherit" />
            <p>{text}</p>
          </div>
        )}
        {!isCheck && (
          <div
            className={classNames(cls.icons, className)}
            style={{ color: colorClose, fontSize }}
          >
            <CloseIcon color="inherit" fontSize="inherit" />
            <p>{text}</p>
          </div>
        )}
      </>
    );
  }
);
