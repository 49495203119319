import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Hamburger } from "../../UI/components/Hamburger/Hamburger";
import { Logo } from "../../UI/components/Logo/Logo";
import { Wrapper } from "../../UI/templates";
import style from "./style.module.scss";
import { Social } from "../../UI/components/Social/Social";
import { useHeaderOutsideClick } from "./utils/useHeaderOutsideClick";
import { userIsAuth } from "../../../app/services/auth/auth";
import { selectorNavigation } from "../../../app/feature/header/header";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { Modal } from "../../UI/components/Modal/Modal";
import {
  ConditionsModal,
  LinksType,
} from "../../UI/components/Conditions/ConditionsModal/ConditionsModal";
import { selectorAgreementPersonal } from "../../../app/feature/appUser/appUser";
import { NavigationNew } from "../../UI/components/NavigationNew/Navigation";

export type HeaderProps = {
  /**
   * Айтемы меню
   */
  variant?: string;
};

const links: LinksType = [
  {
    id: 1,
    title: "Термины и определения",
    link: "/site/terms",
  },
  {
    id: 2,
    title: "Пользовательское соглашение",
    link: "/site/agreement",
  },
  {
    id: 3,
    title: "Политика конфиденциальности",
    link: "/site/policy",
  },
  {
    id: 4,
    title: "Алгоритм безопасной сделки ПИРС",
    link: "/site/deal",
  },
  {
    id: 5,
    title: "Публичная оферта Платформа ПИРС Безопасная сделка",
    link: "/site/offer",
  },
  {
    id: 6,
    title: "Тарифные планы",
    link: "/site/rates",
  },
];

const pathsForCheck = (path: string) => {
  return links.map((item) => item.link).includes(path);
};

export const HeaderNew: React.FC<HeaderProps> = ({ variant = "" }) => {
  const theme = useSelector(selectorAppTheme);
  const headerItems = useSelector(selectorNavigation, shallowEqual);
  const isAuth = userIsAuth();

  const location = useLocation();
  const pathName = location.pathname;
  const [isAgreementPersonal, setAgreementPersonal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const agreement = useSelector(selectorAgreementPersonal);

  useHeaderOutsideClick(isOpen, setOpen);

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const checkForShowAgreement = isAuth && agreement === null;

  useEffect(() => {
    if (checkForShowAgreement) {
      setAgreementPersonal(true);
    }

    if (pathsForCheck(pathName)) {
      setAgreementPersonal(false);
    }
  }, [checkForShowAgreement, agreement, pathName]);

  return (
    <header className={classNames(variant, style.header)} id={"header"}>
      <Wrapper variant={classNames(style.header__wrapper, "js-header-wrapper")}>
        <Hamburger
          openHandler={onOpen}
          closeHandler={onClose}
          variant={style.header__hamburger}
          isActive={isOpen}
        />
        <Logo link="/" />
        <NavigationNew
          isActive={isOpen}
          items={headerItems}
          variant={classNames(style.header__navigation, isOpen && style.active)}
        />
        {!isAuth ? <Social size={"20"} theme={theme} /> : <></>}
      </Wrapper>
      {isAgreementPersonal ? (
        <Modal
          bodyVariant={style.agreementPersonal}
          body={
            <ConditionsModal
              links={links}
              theme={theme}
              onCloseAgreement={setAgreementPersonal}
            />
          }
        />
      ) : null}
    </header>
  );
};
