import { FC } from "react";
import cn from "classnames";
import style from "../style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { Price } from "../Sections/Section";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { config } from "../../../../../../app.cofig";

type ApplicationItemProps = {
  executorNds: string;
  task: any;
  userType: any;
  old: boolean;
  type: string;
};

const Application: FC<ApplicationItemProps> = ({
  executorNds,
  task,
  userType,
  old,
  type,
}) => {
  const isTablette = useIsMobile(1600);
  const isMobile = useIsMobile(1100);

  const { author, workflow_data } = task;

  const { date_start, date_limit, price, processId } = workflow_data;

  const secure = false;

  const Dates = () => {
    if (isMobile) {
      return (
        <h2 className={style.applicationDates}>
          {date_start && (
            <>
              {date_start}
              <br />
              {date_limit}
            </>
          )}
        </h2>
      );
    }

    return (
      <h2 className={style.applicationDates}>
        {date_start && (
          <>
            {date_start}&nbsp;&nbsp;•&nbsp;&nbsp;
            {date_limit}
          </>
        )}
      </h2>
    );
  };

  const codeElement = (
    <div>
      <h1>{`${author?.surname} ${author?.name} ${author?.patronymic}`}</h1>
    </div>
  );

  const executor = (
    <div className={cn(style.sectionExecutor, style.applicationExecutor)}>
      <p>{userType.listName}</p>
    </div>
  );

  const handleOpenApplication = () => {
    window.open(
      `${config.localDomain}/project-process/view?id=${processId}&tab=application-${type}`
    );
  };

  const invite = (
    <CustomButton
      background={palette.blue}
      width={120}
      className={style.button}
    >
      Пригласить
    </CustomButton>
  );

  if (isTablette) {
    return (
      <div className={cn(style.shortTask, style.shortApplication)}>
        <div className={style.shortTaskSection}>
          {codeElement}
          {old ? (
            invite
          ) : (
            <Price message={executorNds} secure={secure} price={price} />
          )}
        </div>
        <div className={style.shortTaskSection}>
          {executor}
          {!old && <Dates />}
        </div>
      </div>
    );
  }

  return (
    <div className={style.section}>
      <p
        onClick={handleOpenApplication}
        className={cn(style.code, style.longen, style.applicationCode)}
      >
        {codeElement}
      </p>
      {executor}
      {!old && <Dates />}
      {old ? (
        invite
      ) : (
        <Price message={executorNds} secure={secure} price={price} />
      )}
    </div>
  );
};

export default Application;
