type TPieChartProps = {
  size: string;
  strokeWidth: number;
  dataSet: {
    payPart: number;
    colorHex: string;
  }[];
};

export const PieChart = (props: TPieChartProps) => {
  const { size, strokeWidth, dataSet } = props;

  const [donutRingData, ...otherData] = dataSet.reverse();
  const donutRing = (
    <circle
      className="donutRing"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke={donutRingData?.colorHex}
      strokeWidth={strokeWidth}
    />
  );

  let sumCirclePercent = 0;
  const circles = otherData
    .map((circleData, index) => {
      sumCirclePercent += circleData.payPart;
      const strokeDasharray = `${sumCirclePercent} ${100 - sumCirclePercent}`;
      return (
        <circle
          key={index}
          className="donutSegment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={circleData.colorHex}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset="25"
        />
      );
    })
    .reverse();

  return (
    <svg width={size} height={size} viewBox="0 0 42 42" className="donut">
      <circle
        className="donutHole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
      />
      {donutRing}
      {circles}
    </svg>
  );
};
