import { useProjectProcessGetWorksQuery } from "src/FSD/entities/projectProcess/api";
import { useParams } from "react-router-dom";

export const useProjectProcessWorks = () => {
  const { id } = useParams<{ id: string }>();
  const { data: getWorks, isLoading: isLoadingGetWorks } =
    useProjectProcessGetWorksQuery({ id }, { skip: !id });

  const fullCost = getWorks ? getWorks.fullCost : 0;
  const isLoading = !getWorks || isLoadingGetWorks;
  const groups = getWorks?.groups && Object.entries(getWorks.groups);

  return {
    fullCost,
    isLoading,
    groups,
  };
};
