import { FC, useEffect, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardDestinations,
  setShowFulfilledWorks,
} from "../../../../../app/feature/dashboard/destinations";
import globalStyle from "../Primary/style.module.scss";
import FilterTasks from "../Filter/Tasks";
import FolderPanel from "./Sections/FolderPanel";
import Switch from "../../../../newUI/Switch/Switch";
import style from "./style.module.scss";
import { getSectionTasksAndWorks } from "../../../../../app/feature/dashboard/tasksAndWorks";
import {
  getInTasks,
  getOutTasks,
  getSubCheckTasks,
  getTasksByUserType,
  getCustomerAlign,
  getDocumentations,
} from "../../../../../app/feature/dashboard/tasksAndWorks/thunks";
import Task from "./Tasks/Task";
import DashboardSkeleton from "../Skeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import SearchField from "../SearchField";
import useTasksFilter from "../Filter/useTasksFilter";

// const TaskSkeleton = () => <Skeleton className={style.taskSkeleton} />;

const initialOpen = {
  incoming: false,
  outcoming: false,
  check: false,
  documentations: false,
  aligns: false,
};

const Tasks: FC = () => {
  const dispatch = useAppDispatch();

  const { userType, sectionId, sectionData, showFulfilledSections } =
    useAppSelector(getDashboardDestinations);
  const { isDraft } = sectionData;

  const { data: tasks, pending } = useAppSelector(getSectionTasksAndWorks);
  const { incoming, outcoming, check, worksList, documentations, aligns } =
    tasks;

  const [fulfilled, setFulfilled] = useState<boolean>(showFulfilledSections);
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState<Record<string, boolean>>(initialOpen);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const isRealMobile = useIsMobile(1100);
  const isMobile = useIsMobile(1600);

  const data = [
    ...incoming,
    ...outcoming,
    ...check,
    ...worksList,
    ...documentations,
    ...aligns,
  ];

  const { ticked, handleChange } = useTasksFilter(
    data,
    query,
    fulfilled,
    FilterTasks,
    setQuery
  );

  const totalPending =
    pending.incoming ||
    pending.outcoming ||
    pending.check ||
    pending.worksList ||
    pending.documentations ||
    pending.aligns;

  useEffect(() => {
    if (!sectionId || isDraft) {
      return;
    }

    dispatch(getInTasks(sectionId));
    dispatch(getOutTasks(sectionId));
    dispatch(getSubCheckTasks(sectionId));
    dispatch(getTasksByUserType({ sectionId, userType }));
    dispatch(getCustomerAlign(sectionId));
    dispatch(getDocumentations(sectionId));

    setOpen(initialOpen);
  }, [dispatch, sectionId]);

  useEffect(() => {
    dispatch(setShowFulfilledWorks(fulfilled));
  }, [fulfilled]);

  useEffect(() => {
    setFulfilled(showFulfilledSections);
  }, [sectionId]);

  const showTask = (task: any) => ticked[task.id] === task.folder;

  const parseTasks = (tasksArray: any) =>
    tasksArray.filter((task: any) => showTask(task));

  const real = useMemo(() => {
    return {
      incoming: parseTasks(incoming),
      outcoming: parseTasks(outcoming),
      check: parseTasks(check),
      worksList: parseTasks(worksList),
      documentations: parseTasks(documentations),
      aligns: parseTasks(aligns),
    };
  }, [
    incoming,
    outcoming,
    check,
    worksList,
    documentations,
    fulfilled,
    query,
    ticked,
  ]);

  const head = (
    <div className={cn(style.generalHeader, style.tasksHeader)}>
      <h1>Название</h1>
      <p>Ответственный</p>
      <p>Срок</p>
      <p>Стоимость</p>
    </div>
  );

  const incomingTasks =
    open.incoming &&
    real.incoming.map((task: any) => (
      <Task key={task.id} {...task} type="incoming" fulfilled={fulfilled} />
    ));

  const outcomingTasks =
    open.outcoming &&
    real.outcoming.map((task: any) => (
      <Task key={task.id} {...task} type="outcoming" fulfilled={fulfilled} />
    ));

  const checkTasks =
    open.check &&
    real.check.map((task: any) => (
      <Task key={task.id} {...task} type="check" fulfilled={fulfilled} />
    ));

  const documentationsTasks =
    open.documentations &&
    real.documentations.map((task: any) => (
      <Task
        key={task.id}
        {...task}
        type="documentations"
        fulfilled={fulfilled}
      />
    ));

  const alignsTasks =
    open.aligns &&
    real.aligns.map((task: any) => (
      <Task key={task.id} {...task} type="aligns" fulfilled={fulfilled} />
    ));

  const tasksElements =
    open.tasks &&
    real.worksList.map((task: any) => (
      <Task
        type="work"
        key={task.id}
        id={task.id}
        name={task.title}
        date={task.date}
        executorName={task.executorName}
        count={null}
        workflow={{}}
        taskToProcess={null}
        partId={0}
        price={task.price}
        secure={task.secure}
        message={task.executorNds}
        countAccesses={task.countAccesses}
        fulfilled={fulfilled}
      />
    ));

  const showTasks = Boolean(sectionId);

  const tasksFolders = showTasks && (
    <div className={globalStyle.list}>
      {!isMobile && head}
      <FolderPanel
        canOpen={Boolean(real.incoming.length)}
        isOpen={open.incoming}
        short={isMobile}
        name={`Входящие задания (${real.incoming.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, incoming: !open.incoming });
        }}
      />
      {incomingTasks}
      <FolderPanel
        canOpen={Boolean(real.outcoming.length)}
        isOpen={open.outcoming}
        short={isMobile}
        name={`Исходящие задания (${real.outcoming.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, outcoming: !open.outcoming });
        }}
      />
      {outcomingTasks}
      <FolderPanel
        canOpen={Boolean(real.check.length)}
        isOpen={open.check}
        short={isMobile}
        name={`Проверка документации (${real.check.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, check: !open.check });
        }}
      />
      {checkTasks}
      <FolderPanel
        canOpen={Boolean(real.documentations.length)}
        isOpen={open.documentations}
        short={isMobile}
        name={`Готовая документация (${real.documentations.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, documentations: !open.documentations });
        }}
      />
      {documentationsTasks}
      <FolderPanel
        canOpen={Boolean(real.aligns.length)}
        isOpen={open.aligns}
        short={isMobile}
        name={`Предварительные решения (${real.aligns.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, aligns: !open.aligns });
        }}
      />
      {alignsTasks}
      <FolderPanel
        canOpen={Boolean(real.worksList.length)}
        isOpen={open.tasks}
        short={isMobile}
        name={`Задачи (${real.worksList.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, tasks: !open.tasks });
        }}
      />
      {tasksElements}
    </div>
  );

  return (
    <div className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}>
      <div className={cn(globalStyle.header, style.sectionsFullHeader)}>
        {isRealMobile ? (
          <>
            <h3>Задача</h3>
            <div className={style.mobileHeader}>
              <p>Выполнено</p>
              <Switch
                checked={fulfilled}
                onChange={() => setFulfilled(!fulfilled)}
              />
              <IconButton
                className={style.mobileSearchBar}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h3>Задача</h3>
            <p>Выполнено</p>
            <Switch
              checked={fulfilled}
              onChange={() => setFulfilled(!fulfilled)}
            />
            <SearchField value={query} onChange={handleChange} />
          </>
        )}
      </div>
      {isRealMobile && showSearchBar && (
        <SearchField
          className={style.searchBar}
          value={query}
          onChange={handleChange}
          mobile
        />
      )}
      {Boolean(sectionId && !isDraft) && (
        <>{totalPending ? <DashboardSkeleton /> : tasksFolders}</>
      )}
    </div>
  );
};

export default Tasks;
