import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../services/api/requestHandler";
import {
  ApiDeleteProject,
  ApiGetUsersProjectGip,
  ApiGetUsersTypeProjectGip,
  ApiProjectComplexObject,
  ApiRemoveGipObject,
  ApiUpdateGipProject,
} from "../../../services/api/project/complex";

export type StageType = {
  enable: boolean;
  work: boolean;
  done: boolean;
};

export interface IProjectCopmlex {
  status: {
    key: number;
    value: string;
  };
  can: {
    delete: boolean;
    requestRemoveGip: boolean;
    updateGip: boolean;
  };
  id: number;
  gipAccess: {
    id: number;
    user_id: number;
    user_type_id: number;
  };
  dates: {
    end: string;
    percent: number;
    start: string;
    endFact: string;
  };
  gip: string;
  name: string;
  color: "pirs-gray" | "pirs-red" | "pirs-green";
  stopped: boolean;
  stopProcesses: string[];
  price: {
    end: number;
    percent: number;
    start: number;
  };
  stage: {
    АН: StageType;
    ПД: StageType;
    РД: StageType;
  };
}
type IProjectComplexReturn = Record<string, IProjectCopmlex>;
export const fetchProjectComplexObject = createAsyncThunk<
  IProjectComplexReturn,
  { id: number; searchText?: string; load?: boolean }
>(
  "fetchProjectComplexObject/fetch",
  async ({ id, searchText }, { rejectWithValue }) => {
    try {
      const response = await ApiProjectComplexObject(id, searchText);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchDeleteProjectObject = createAsyncThunk<void, number>(
  "fetchDeleteProjectObject/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiDeleteProject(id);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipList = createAsyncThunk<Record<string, string>, number>(
  "fetchGipList/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiGetUsersProjectGip(id);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

interface IfetchGipTypeListReturn {
  id: number;
  gipId: number;
}
export const fetchGipTypeList = createAsyncThunk<
  Record<string, string>,
  IfetchGipTypeListReturn
>("fetchGipTypeList/fetch", async (props, { rejectWithValue }) => {
  const { id, gipId } = props;
  try {
    const response = await ApiGetUsersTypeProjectGip(id, gipId);
    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

type TUsersUpdateParams = {
  id: number;
  data: {
    ProjectAccess: {
      access_code: number;
      user_id: number;
      user_type_id: number;
    };
  };
};

export const fetchProjectGipUpdate = createAsyncThunk<void, TUsersUpdateParams>(
  "fetchProjectGipUpdate/fetch",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ApiUpdateGipProject(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

interface IGipObjectRemove {
  objectId: number;
  data: {
    StartForm: {
      content: string;
    };
  };
}

export const fetchGipObjectRemove = createAsyncThunk<boolean, IGipObjectRemove>(
  // Снять
  "fetchGipObjectRemove/fetch",
  async ({ data, objectId }, { rejectWithValue }) => {
    try {
      const response = await ApiRemoveGipObject(data, objectId);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
