import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import classNames from "classnames";
import style from "../../../pages/Registration/RegistrationPage/style.module.scss";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { UserTypes } from "../../SpecialForm/UserTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { SpecializationMultiple } from "../../SpecialForm/SpecializationMultiple";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { request } from "../../../../app/services/api/requestHandler";
import { ApiPostRegistrationForm } from "../../../../app/services/api/registration/registration";
import { Phone } from "../../SpecialForm/Phone/Phone";
import { registrationFormSchema } from "./yupSchemas";
import { textFieldSX } from "../../../../styles/restyle";

type TFormValues = Record<string, any>;

export const RegistrationForm = () => {
  const {
    register,
    setValue,
    setError,
    trigger,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      type: "1",
      skillIds: [],
      password: "",
      password_confirm: "",
      agreement_personal: false,
    },
    resolver: yupResolver(registrationFormSchema),
  });

  const theme = useSelector(selectorAppTheme);
  const [loading, setLoading] = useState(false);
  const { Popup } = useUserWorkflow();
  const navigate = useNavigate();

  const onSubmit = (data: TFormValues) => {
    setLoading(true);
    request(
      ApiPostRegistrationForm({
        RegistrationForm: {
          name: data.name,
          phone: data.phone,
          email: data.email,
          type: data.type,
          skillIds: data.skillIds.map(
            (item: { id: number; title: string }) => item.id!
          ),
          password: data.password,
          password_confirm: data.password_confirm,
          agreement_personal: data.agreement_personal,
        },
      }),
      (result, headersData, headers, status) => {
        if (status === 201) {
          setLoading(false);
          navigate(`/user/confirm?id=${result}`);
        }
      },
      () => (data) => {
        if (data) {
          setLoading(false);
        }

        const message = JSON.parse(data.message);
        Object.keys(message).forEach((key) => {
          setError(key, message[key][0]);
        });
      }
    )();
  };

  const watchAgreementPersonal = watch("agreement_personal");
  const watchSkillIds = watch("skillIds");
  const watchType = watch("type");

  const NameError = Boolean(errors.name) && (
    <ErrorMessage
      message={(errors.name?.message ?? "") as string}
      variant={style.registerError}
    />
  );

  const PhoneError = Boolean(errors.phone) && (
    <ErrorMessage
      message={(errors.name?.phone ?? "") as string}
      variant={style.registerError}
    />
  );

  const EmailError = Boolean(errors.email) && (
    <ErrorMessage
      message={(errors.email?.message ?? "") as string}
      variant={style.registerError}
    />
  );

  const SkillsError = Boolean(errors.skillIds) && (
    <ErrorMessage
      message={(errors.skillIds?.message ?? "") as string}
      variant={style.registerError}
    />
  );

  const PasswordError = Boolean(errors.password) && (
    <ErrorMessage
      message={(errors.password?.message ?? "") as string}
      variant={style.registerError}
    />
  );

  const ConfirmPasswordError = Boolean(errors.password_confirm) && (
    <ErrorMessage
      message={(errors.password_confirm?.message ?? "") as string}
      variant={style.registerError}
    />
  );

  const AgreementPersonal = Boolean(errors.agreement_personal) && (
    <ErrorMessage
      message={(errors.agreement_personal?.message ?? "") as string}
      variant={classNames(style.registerError, style.agreement)}
    />
  );

  const ConditionLink: React.FC<{
    text: string;
    handleClick: () => void;
    someValidPath?: string;
  }> = ({ text, handleClick, someValidPath = "" }) => {
    return (
      <a
        className={classNames(style.conditionsLink, style[theme])}
        href={someValidPath}
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        {text}
      </a>
    );
  };

  const typeFieldCondition = watchType === "2" || watchType === "3";

  return (
    <form className={style.registrationForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.fieldWrapper}>
        <TextField
          {...register("name")}
          size={"small"}
          fullWidth
          id="name"
          name="name"
          type="text"
          label="Имя"
          autoComplete="off"
          error={Boolean(errors.name)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {NameError}
      </div>
      <div className={style.fieldWrapper}>
        <Phone
          changeHandler={(data) => {
            setValue("phone", data);
          }}
          error={Boolean(errors.phone)}
        />
        {PhoneError}
      </div>
      <div className={style.fieldWrapper}>
        <TextField
          {...register("email")}
          size={"small"}
          fullWidth
          id="email"
          name="email"
          type="text"
          label="Email"
          autoComplete="off"
          error={Boolean(errors.email)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {EmailError}
      </div>
      <div className={style.fieldWrapper}>
        <UserTypes
          changeHandler={(data) => {
            setValue("type", data ? data.id : undefined);
            if (data.id === "2" || data.id === "3") {
              setValue("skillIds", []);
              trigger("skillIds");
            }
          }}
          value={watchType}
          variant={style.formElement}
        />
      </div>
      <div className={style.fieldWrapper}>
        <SpecializationMultiple
          disabled={typeFieldCondition}
          value={typeFieldCondition ? [] : watchSkillIds}
          changeHandler={(data) => {
            setValue("skillIds", data);
            trigger("skillIds");
          }}
          error={Boolean(errors.skillIds)}
        />
        {SkillsError}
      </div>
      <div className={style.fieldWrapper}>
        <TextField
          {...register("password")}
          size={"small"}
          fullWidth
          id="password"
          name="password"
          label="Пароль"
          type="password"
          autoComplete="new-password"
          error={Boolean(errors.password)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {PasswordError}
      </div>
      <div className={style.fieldWrapper}>
        <TextField
          {...register("password_confirm")}
          size={"small"}
          fullWidth
          id="password_confirm"
          name="password_confirm"
          label="Повторить пароль"
          type="password"
          autoComplete="new-password"
          error={Boolean(errors.password_confirm)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {ConfirmPasswordError}
      </div>
      <div className={classNames(style.fieldWrapper, style.policyWrapper)}>
        <div className={style.policy}>
          <Checkbox
            className={style.agreement_personal}
            checked={watchAgreementPersonal}
            onClick={() => {
              setValue("agreement_personal", !watchAgreementPersonal);
              trigger("agreement_personal");
            }}
            name="agreement_personal"
            disableRipple={true}
            style={{
              marginRight: 8,
              padding: 0,
              color: theme === "old" ? "#3B93AF" : "#30ab69",
              background: "none",
            }}
          />
          <div className={style.personal}>
            Согласен с {""}
            <ConditionLink
              text={"Пользовательским соглашением"}
              someValidPath={"/site/agreement"}
              handleClick={Popup.showUserAgreement.open}
            />{" "}
            и{" "}
            <ConditionLink
              text={"Политикой конфиденциальности"}
              someValidPath={"/site/policy"}
              handleClick={Popup.showPrivacyPolicy.open}
            />
          </div>
        </div>
        {AgreementPersonal}
      </div>

      {loading ? (
        <div className={style.progressContainer}>
          <CircularProgress size={33} className={style.registerProgress} />
        </div>
      ) : (
        <Button theme={theme} type="submit" color={"blue"} fullWidth={true}>
          Зарегистрироваться
        </Button>
      )}
    </form>
  );
};
