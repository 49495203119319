import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { RootState } from "../../../store";
import {
  fetchProjectGanttData,
  fetchProjectGanttDataNew,
  fetchSchedulePlan,
  fetchUpdateDates,
  TaskType,
} from "./thunks";
import { trimPrice } from "../../../../utils/trimNumber";

type InitialState = {
  isLoadGraph: boolean;
  isLoadSchedule: boolean;
  graphUpdate: boolean;
  error: any;
  error422: any;
  fact: [string, TaskType][] | undefined;
  factChild: any;
  budgetChild: any;
  plan: [string, TaskType][] | undefined;
  forecast: [string, TaskType][] | undefined;
  budget: any;
  today: any;
  pd_date_start: Date | null;
  pd_date_end: Date | null;
  rd_date_start: Date | null;
  rd_date_end: Date | null;
  exp_date_start: Date | null;
  exp_date_end: Date | null;
  ii_date_start: Date | null;
  ii_date_end: Date | null;
  obs_date_start: Date | null;
  obs_date_end: Date | null;
  isLoadUpdateDate: boolean;
  priceFactCurrent: string;
  pricePlan: string;
  pricePlanCurrent: string;
  colorAll: string;
  widthAll: number;
  dateStartAll: string;
  dateEndAll: string;
  graphStatus: "Выполнение" | "Оплата";
};

const initialState: InitialState = {
  isLoadGraph: true,
  isLoadSchedule: true,
  isLoadUpdateDate: false,
  graphUpdate: false,
  error: null,
  error422: null,
  fact: undefined,
  graphStatus: "Выполнение",
  factChild: null,
  budgetChild: null,
  plan: undefined,
  budget: null,
  today: undefined,
  forecast: undefined,
  pd_date_start: null,
  pd_date_end: null,
  rd_date_start: null,
  rd_date_end: null,
  exp_date_start: null,
  exp_date_end: null,
  ii_date_start: null,
  ii_date_end: null,
  obs_date_start: null,
  obs_date_end: null,
  priceFactCurrent: "",
  pricePlan: "",
  pricePlanCurrent: "",
  dateStartAll: "",
  dateEndAll: "",
  widthAll: 0,
  colorAll: "",
};

const projectGraphSlice = createSlice({
  name: "projectGraphData",
  initialState,
  reducers: {
    clear422: (state) => {
      state.error422 = null;
    },
    setGraphStatus: (state, action) => {
      state.graphStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectGanttData.pending, (state) => {
      state.isLoadGraph = true;
    });
    builder.addCase(fetchProjectGanttData.fulfilled, (state, action) => {
      const valueFact = action.payload?.fact;
      const valuePlan = action.payload?.plan;
      const valueForecast = action.payload?.forecast;
      const valueBudget = action.payload?.budget;

      state.today =
        valueFact?.parent?.today || valuePlan?.today || valueForecast?.today;
      state.fact = action.payload.fact
        ? Object.entries(valueFact.parent)
        : undefined;
      state.plan = action.payload.plan ? Object.entries(valuePlan) : undefined;
      state.forecast = action.payload.forecast
        ? Object.entries(valueForecast)
        : undefined;
      state.budget = action.payload?.budget
        ? Object.entries(valueBudget.data.parent)
        : null;
      state.factChild = action.payload.fact && Object.values(valueFact.childs);
      state.budgetChild =
        action.payload?.budget && Object.values(valueBudget?.data.childs);
      state.priceFactCurrent = trimPrice(valueBudget?.priceFactCurrent) || "";
      state.pricePlan = trimPrice(valueBudget?.pricePlan) || "";
      state.pricePlanCurrent = trimPrice(valueBudget?.pricePlanCurrent) || "";
      state.widthAll = valueBudget?.width;
      state.colorAll = valueBudget?.color;
      state.isLoadGraph = false;
      state.graphUpdate = false;
    });
    builder.addCase(fetchProjectGanttData.rejected, (state, action) => {
      state.isLoadGraph = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectGanttDataNew.pending, (state) => {
      state.isLoadGraph = true;
    });
    builder.addCase(fetchProjectGanttDataNew.fulfilled, (state, action) => {
      const valueFact = action.payload?.fact;
      const valuePlan = action.payload?.plan;
      const valueForecast = action.payload?.forecast;
      const valueBudget = action.payload?.budget;

      state.today =
        valueFact?.parent?.today || valuePlan?.today || valueForecast?.today;
      state.fact = action.payload.fact
        ? Object.entries(valueFact.parent)
        : undefined;
      state.plan = action.payload.plan ? Object.entries(valuePlan) : undefined;
      state.forecast = action.payload.forecast
        ? Object.entries(valueForecast)
        : undefined;
      state.budget = action.payload?.budget
        ? Object.entries(valueBudget.data.parent)
        : null;
      state.factChild = action.payload.fact && Object.values(valueFact.childs);
      state.budgetChild =
        action.payload?.budget && Object.values(valueBudget?.data.childs);
      state.isLoadGraph = false;
      state.graphUpdate = false;
      // allGraphBudget
      state.priceFactCurrent = trimPrice(valueBudget?.priceFactCurrent) || "";
      state.pricePlan = trimPrice(valueBudget?.pricePlan) || "";
      state.pricePlanCurrent = trimPrice(valueBudget?.pricePlanCurrent) || "";
      state.dateStartAll = valueBudget?.dateStartFact;
      state.dateEndAll = valueBudget?.dateEndFact;
      state.widthAll = valueBudget?.width;
      state.colorAll = valueBudget?.color;
    });
    builder.addCase(fetchProjectGanttDataNew.rejected, (state, action) => {
      state.isLoadGraph = false;
      state.error = action.payload;
    });
    builder.addCase(fetchUpdateDates.pending, (state) => {
      state.isLoadUpdateDate = true;
      state.graphUpdate = false;
    });
    builder.addCase(fetchUpdateDates.fulfilled, (state) => {
      state.graphUpdate = true;
      state.isLoadUpdateDate = false;
    });
    builder.addCase(fetchUpdateDates.rejected, (state, action) => {
      state.isLoadUpdateDate = false;
      if (action?.payload?.status === "none") {
        state.error = action.payload.message;
      }
      if (action?.payload?.status === "422") {
        state.error422 = action.payload.message;
      }
    });
    builder.addCase(fetchSchedulePlan.pending, (state) => {
      state.isLoadSchedule = true;
    });
    builder.addCase(fetchSchedulePlan.fulfilled, (state, action) => {
      state.isLoadSchedule = false;
      const {
        pd_date_start,
        pd_date_end,
        rd_date_start,
        rd_date_end,
        exp_date_start,
        exp_date_end,
        ii_date_start,
        ii_date_end,
        obs_date_start,
        obs_date_end,
      } = action.payload;
      state.pd_date_start = pd_date_start
        ? moment(pd_date_start, "DD.MM.YYYY").toDate()
        : null;
      state.pd_date_end = pd_date_end
        ? moment(pd_date_end, "DD.MM.YYYY").toDate()
        : null;
      state.rd_date_start = rd_date_start
        ? moment(rd_date_start, "DD.MM.YYYY").toDate()
        : null;
      state.rd_date_end = rd_date_end
        ? moment(rd_date_end, "DD.MM.YYYY").toDate()
        : null;
      state.exp_date_start = exp_date_start
        ? moment(exp_date_start, "DD.MM.YYYY").toDate()
        : null;
      state.exp_date_end = exp_date_end
        ? moment(exp_date_end, "DD.MM.YYYY").toDate()
        : null;
      state.ii_date_start = ii_date_start
        ? moment(ii_date_start, "DD.MM.YYYY").toDate()
        : null;
      state.ii_date_end = ii_date_end
        ? moment(ii_date_end, "DD.MM.YYYY").toDate()
        : null;
      state.obs_date_start = obs_date_start
        ? moment(obs_date_start, "DD.MM.YYYY").toDate()
        : null;
      state.obs_date_end = obs_date_end
        ? moment(obs_date_end, "DD.MM.YYYY").toDate()
        : null;
    });
    builder.addCase(fetchSchedulePlan.rejected, (state, action) => {
      state.isLoadSchedule = false;
      state.error = action.payload;
    });
  },
});
export const getProjectGraphData = (state: RootState) => state.projectGraph;
export const { clear422, setGraphStatus } = projectGraphSlice.actions;
export default projectGraphSlice.reducer;
