import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useChat } from "./lib/useChat";
import { Chat as ChatUI } from "../../UI/components/Chat/Chat";
import {
  chatAddOpenedRoom,
  chatClearFilterRooms,
  chatEnterRoom,
  chatFilterContacts,
  chatRemoveOpenedRoom,
  chatSetActiveRoomData,
  selectorChatActiveRoomData,
  selectorChatDateUpdate,
  selectorChatMessages,
  selectorChatRooms,
  selectorFilteredContacts,
  selectorFilteredValue,
  selectorUserInRoom,
} from "../../../app/feature/chat/chat";
import { createMessageDataUI, createRoomData } from "./lib/createData";
import C from "../../../app/feature/chat/constants";
import {
  getPositionScrollRoom,
  setPositionScrollRoom,
} from "./lib/positionScroll";
import { getIdMessageCollection, getNotSeenMessages } from "./lib/other";
import { useWindowSize } from "../../hooks/useWindowResize";
import { useHeaderHeight } from "../../hooks/useHeightHeader";
import { useScrollToId } from "./lib/useScrollToId";
import { selectorAppTheme } from "../../../app/feature/app/app";

export const Chat: React.FC<unknown> = () => {
  const { roomId } = useParams<{ roomId: string | "all" }>();

  const [scrollToRoom] = useScrollToId();

  const [currentRoomPosition, setCurrentRoomPosition] = useState("");
  const [currentRoomId, setCurrentRoomId] = useState("");
  const [isFirstConnect, setFirstConnection] = useState(false);

  const CHAT_HEIGHT = useWindowSize().height! - 10 - useHeaderHeight();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useSelector(selectorAppTheme);

  const data = {
    rooms: useSelector(selectorChatRooms, shallowEqual),
    activeRoom: useSelector(selectorChatActiveRoomData, shallowEqual),
    messages: useSelector(selectorChatMessages, shallowEqual),
    isUserInRoom: useSelector(selectorUserInRoom, shallowEqual),
    filteredValue: useSelector(selectorFilteredValue, shallowEqual),
    filteredRooms: useSelector(selectorFilteredContacts, shallowEqual),
    updDateData: useSelector(selectorChatDateUpdate, shallowEqual),
  };

  const [
    apiJoinRoom,
    apiLeaveChat,
    apiGetRoomHistory,
    apiGetChatContacts,
    apiSetMessage,
    apiSetSeen,
  ] = useChat(roomId as string);

  const onJoinRoom = ({
    prevRoomId,
    roomId,
    avatarUrl,
    userName,
  }: {
    prevRoomId?: number;
    roomId: string;
    userName: string;
    avatarUrl: string;
  }) => {
    if (prevRoomId) apiLeaveChat(prevRoomId);

    dispatch(chatClearFilterRooms());
    dispatch(chatSetActiveRoomData({ id: roomId, avatarUrl, userName }));

    navigate(`/chat/${roomId}`);
    setCurrentRoomId(roomId);
    setCurrentRoomPosition(getPositionScrollRoom(roomId) || "bottom");

    apiJoinRoom(roomId);
    apiGetRoomHistory(roomId);
    if (isFirstConnect) {
      apiGetChatContacts();
    }
    setTimeout(() => {
      dispatch(chatEnterRoom());
    }, 150);
  };

  const onSetPositionScrollRoom = (pos: string) => {
    setPositionScrollRoom(roomId as string, pos);
  };

  const onOpenGroup = (groupId: string) =>
    dispatch(chatAddOpenedRoom([groupId]));
  const onCloseGroup = (groupId: string) =>
    dispatch(chatRemoveOpenedRoom(groupId));

  const onLeaveChatRoom = () => {
    apiLeaveChat(roomId);
    dispatch({
      type: C.CHAT_USER_EXIT_ROOM,
    });
    dispatch({
      type: C.CHAT_USER_LEAVE,
    });
  };

  const onFilterChatUserList = (str: string) => {
    apiGetChatContacts();
    dispatch(chatFilterContacts(str));
  };

  const onSetMessage = (val: string) => {
    apiSetMessage({
      content: val,
      roomId,
    });
    apiSetSeen({
      messagesId: [],
      roomId,
    });
    setCurrentRoomPosition("bottom");
  };

  // Реагируем на обновление истории сообщений и отправляем просмотренные
  useEffect(() => {
    if (data.messages.length > 0 && roomId !== "") {
      apiSetSeen({
        messagesId: getIdMessageCollection(getNotSeenMessages(data.messages)),
        roomId,
      });
      // apiGetChatContacts(); // todo -> фикс счетчика, если пойдет зацикливаение то убрать
    }
  }, [data.messages]);

  // Реагируем на обновление комнат и подлюченаемся к чату
  useEffect(() => {
    if (roomId !== "all" && !isFirstConnect) {
      if (data.rooms.length > 0) {
        onJoinRoom({
          prevRoomId: data.activeRoom.id,
          roomId: String(roomId),
          userName: "",
          avatarUrl: "",
        });
        setFirstConnection(true);
      }
    }
  }, [data.rooms, navigate]);

  // Реагируем на обновление комнат и зацепки сролла
  useEffect(() => {
    if (!isFirstConnect) return;
    if (!data.activeRoom.id) return;
    // if (!isChatRoomsDataHaveProject(data.rooms, data.activeRoom.id)) return;

    scrollToRoom(data.activeRoom.id);
  }, [data.activeRoom.id, data.rooms]);

  return (
    <div style={{ height: CHAT_HEIGHT || 0, paddingTop: 5 }}>
      <ChatUI
        initialPositionScrollY={currentRoomPosition}
        scrollYPositionHandler={onSetPositionScrollRoom}
        roomsData={
          data.filteredValue
            ? createRoomData(data.filteredRooms)
            : createRoomData(data.rooms)
        }
        activeRoomId={data.activeRoom.id}
        isDisableBottom={!currentRoomId}
        updDate={data.updDateData}
        openGroupHandler={onOpenGroup}
        closeGroupHandler={onCloseGroup}
        userData={data.activeRoom}
        clickContactHandler={(event) => {
          onJoinRoom({ ...event, prevRoomId: data.activeRoom.id });
        }}
        messagesData={createMessageDataUI(data.messages)}
        userInRoom={data.isUserInRoom}
        backButtonHandler={onLeaveChatRoom}
        filterValue={data.filteredValue}
        filterHandler={onFilterChatUserList}
        setMessageHandler={onSetMessage}
        theme={theme}
      />
    </div>
  );
};
