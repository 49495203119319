import { Skeleton } from "@mui/material";
import { SkeletonList } from "./SkeletonList";
import styles from "./Skeleton.module.scss";

type Props = {
  count?: number;
  height?: number;
  className?: string;
};

export const SkeletonGrid = ({ count = 1, height, className }: Props) => {
  const skeletonArray = Array.from(new Array(2));

  return (
    <div className={styles.wrapper}>
      <SkeletonList count={count} height={100} />
      <div className={styles.skeletonGrid}>
        {skeletonArray.map((_, i) => (
          <div key={i} className={styles.skeletonGrid__item}>
            <Skeleton
              height={height}
              variant="rectangular"
              className={className}
            />
            <Skeleton
              height={height}
              variant="rectangular"
              className={className}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
