import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectDocument = (id: string): Promise<Response> => {
  // получить дерево договоры
  return httpRequestGet(
    `${config.localDomain}/v1/document/get-project-documents?project_id=${id}&DocumentSearch[type]=1&fields=id,name&expand=treeByObjects`
  );
};

export const ApiProjectDocumentV2 = (
  id: number,
  statusType: string,
  searchText = ""
): Promise<Response> => {
  let status;
  if (statusType === "work") {
    status =
      "DocumentSearch[status][]=1&DocumentSearch[status][]=2&DocumentSearch[status][]=3";
  }
  if (statusType === "terminated") {
    status = "DocumentSearch[status]=4";
  }
  if (statusType === "done") {
    status = "DocumentSearch[status]=5";
  }
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-doc-tree?id=${id}&${status}&DocumentSearch[searchText]=${searchText}`
  );
};
