import { FC } from "react";
import { IconButton, LinearProgress } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import parse from "html-react-parser";
import cn from "classnames";
import style from "./style.module.scss";
import { DefaultFileIcon } from "../Uploader/UploaderUtils/UI";
import { truncateString } from "../../../utils/truncateString";
import { FileProps } from "./types";
import CustomCheckbox from "../../newUI/CustomCheckbox/CustomCheckbox";
import { useAppSelector } from "../../../app/store";
import { getProjectViewData } from "../../../app/feature/ProjectView/projectView";
import { config } from "../../../app.cofig";

const File: FC<FileProps> = ({
  id,
  name,
  svgIcon,
  visibility,
  handleChangeActive,
  handleDelete,
  canEdit,
  progresses,
  serverId,
}) => {
  const { actually } = useAppSelector(getProjectViewData);

  const formattedName = truncateString(name, 22);

  const isUploaded = progresses[id] === 100 || serverId;

  const handleDownloadFile = () => {
    window.open(`${config.localDomain}/v1/file/get?id=${serverId}`, "Download");
  };

  return (
    <div onClick={handleDownloadFile} className={style.file}>
      <div className={cn(style.svg, Boolean(svgIcon) && style.isUploaded)}>
        {svgIcon ? parse(svgIcon) : <DefaultFileIcon />}
      </div>
      <div className={style.controls}>
        {isUploaded ? (
          <div className={style.controls__main}>
            <p>{formattedName}</p>
            {Boolean(actually && canEdit) && (
              <label
                className={style.actually}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <CustomCheckbox
                  editing
                  onChange={() => {
                    handleChangeActive(id, serverId, !visibility);
                  }}
                  checked={visibility}
                  classname={style.checkbox}
                />
                <p>Актуальный</p>
              </label>
            )}
          </div>
        ) : (
          <LinearProgress
            color="success"
            variant="determinate"
            value={progresses[id] ?? 0}
          />
        )}
      </div>
      {canEdit && (
        <IconButton
          className={style.deleteFile}
          onClick={(event) => {
            event.stopPropagation();
            handleDelete(id, serverId);
          }}
        >
          <Trash color="white" />
        </IconButton>
      )}
    </div>
  );
};

export default File;
