import React, { FC, useCallback, useMemo } from "react";
import { FolderProps } from "../types";
import FolderPanel from "../Sections/FolderPanel";
import Section from "../Sections/Section";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getDashboardDestinations } from "../../../../../../app/feature/dashboard/destinations";
import {
  getDashboardSections,
  setOpenTabs,
} from "../../../../../../app/feature/dashboard/sections";

const Folder: FC<FolderProps> = ({
  data,
  item,
  ticked,
  query,
  fulfilled,
  disableShowCount,
  short = false,
  offset = 0,
}) => {
  const dispatch = useAppDispatch();
  const { openTabs } = useAppSelector(getDashboardSections);

  const isOpen = openTabs.includes(item);
  const currentNode: any = data[item];

  const { tab } = useAppSelector(getDashboardDestinations);
  const { name, color, children, count } = currentNode;

  const dotColor = useMemo(() => {
    if (tab === "project->section") {
      return color.section;
    }

    if (tab === "section->application") {
      return color.applications;
    }

    if (tab === "section->tasksAndWorks") {
      return color.tasks;
    }

    if (tab === "section->treaty") {
      return color.treaty;
    }

    return null;
  }, [tab, color]);

  const formattedName = disableShowCount
    ? name
    : `${name} (${children.length})`;

  const handleShowSubtree = useCallback(() => {
    dispatch(
      setOpenTabs(
        isOpen
          ? openTabs.filter((i: string) => i !== item)
          : [...openTabs, item]
      )
    );
  }, [openTabs, isOpen]);

  if (currentNode?.id) {
    if (ticked.includes(currentNode?.id)) {
      return <Section short={short} dotColor={dotColor} {...currentNode} />;
    }

    return null;
  }

  if (!count) {
    return null;
  }

  return (
    <>
      <FolderPanel
        canOpen={Boolean(children.length)}
        isOpen={isOpen}
        name={formattedName}
        handleShowSubtree={handleShowSubtree}
        color={dotColor}
        short={short}
        offset={offset}
        textColor={item.length === 1 ? "white" : "#3baf6a"}
      />
      {isOpen &&
        children.map((item: string) => (
          <Folder
            ticked={ticked}
            query={query}
            key={item}
            data={data}
            item={item}
            fulfilled={fulfilled}
            short={short}
            disableShowCount={disableShowCount}
            offset={offset + 30}
          />
        ))}
    </>
  );
};

export default Folder;
