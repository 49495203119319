import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  authJSON,
  getContactsJSON,
  historyJSON,
  joinRoomJSON,
  leaveChatJSON,
  setMessageJSON,
  setSeenJSON,
} from "../../../../app/services/api/chat/chat";
import { createRoomData } from "./createData";
import { config } from "../../../../app.cofig";
import { roomIdType } from "../../../../types/stateTypes/chatType";
import {
  chatAddMessage,
  chatSetActiveRoomData,
  chatSetContacts,
  chatSetHistory,
  chatSetOverlookMessages,
} from "../../../../app/feature/chat/chat";
import { getUserTokenByLocalStorage } from "../../../../app/services/auth/auth";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";

export const useChat = function useChat(roomId: string): any {
  const dispatch = useDispatch();
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();
  const [socket, setSocket] = useState<null | WebSocket>(null);

  const userToken = getUserTokenByLocalStorage();

  const chatConnection = () => {
    setSocket(new WebSocket(`${config.localSocketPath}/pirsService/`));
  };

  const chatJoinRoom = (roomId: roomIdType) => {
    socket!.send(joinRoomJSON(roomId));
  };

  const chatGetRoomHistory = (roomId: roomIdType) => {
    socket!.send(historyJSON(roomId));
  };

  const chatGetContacts = () => {
    socket?.send(getContactsJSON());
  };

  const chatSetMessage = ({
    content,
    roomId,
  }: {
    content: string;
    roomId: roomIdType;
  }) => {
    if (!roomId) return;
    socket!.send(setMessageJSON({ content, roomId }));
  };

  const chatSetSeen = ({
    messagesId,
    roomId,
  }: {
    messagesId: string[];
    roomId: roomIdType;
  }) => {
    if (messagesId.length === 0) return;
    socket!.send(setSeenJSON({ ids: messagesId, roomId }));
  };

  const chatLeaveRoom = (roomId: roomIdType) => {
    socket!.send(leaveChatJSON(roomId));
  };

  const createErrorChat = (message: string, header: string) => {
    Popup.error.set(message, header);
    Popup.error.open();
  };

  const statusIsError = (data: {
    [key: string]: any;
    status: "ok" | "error";
  }) => data.status === "error";

  useEffect(() => {
    chatConnection();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onopen = function onOpen() {
        socket.send(authJSON(userToken!));
        chatGetContacts();
      };

      socket.onmessage = function onMessage(e) {
        const data = JSON.parse(e.data);
        switch (data.type) {
          case "getContacts":
            dispatch(chatSetContacts(createRoomData(data.roomList)));
            break;
          case "history":
            dispatch(chatSetHistory(data.history));
            dispatch(
              chatSetActiveRoomData({
                id: data.roomId,
                avatarUrl: data.avatarUrl
                  ? `${config.localDomain}${data.avatarUrl}`
                  : "",
                userName: data.name || "",
              })
            );
            break;
          case "setSeen":
            if (data.ids && data.ids.length > 0) {
              dispatch(chatSetOverlookMessages(data.ids));
              setTimeout(() => {
                chatGetContacts();
              }, 0);
            }
            break;
          case "message":
            dispatch(chatAddMessage(data));
            break;

          // errors
          case "in-message":
            if (statusIsError(data.status))
              createErrorChat(data.reason, "Ошибка отправки сообщения");
            break;
          case "in-joinToChat":
            if (statusIsError(data.status))
              createErrorChat(data.reason, "Ошибка подключения к комнате");
            break;
          case "close":
            if (statusIsError(data.status))
              createErrorChat(data.reason, "Ошибка подключения к чату");
            break;
          default:
            return false;
        }
      };

      socket.onclose = function onClose(event) {
        enqueueSnackbar("Сервер закрыл соединение", {
          variant: "error",
          autoHideDuration: 5000,
        });
        if (event.wasClean) {
          // alert(
          //   `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
          // );
        } else {
          setTimeout(chatConnection, 5000);
        }
      };
      socket.onerror = function onError(error) {
        console.log(error);
        createErrorChat("Соединение закрыто, перезагрузите страницу", "Ошибка");
      };
    }
  }, [socket, roomId]);

  return [
    chatJoinRoom,
    chatLeaveRoom,
    chatGetRoomHistory,
    chatGetContacts,
    chatSetMessage,
    chatSetSeen,
  ];
};
