import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeleteProjectObject,
  fetchProjectComplexObject,
  IProjectCopmlex,
} from "../../../../../../app/feature/ProjectView/ComplexReducer/thunks";
import cls from "./ObjectCard.module.scss";
import { StopIcon, VerticalThreeDots } from "../../../../../newUI/SVG";
import { StyledMenu } from "../../../../../newUI/Menu/Menu";
import { Preloader } from "../../../../../features";
import {
  getGipRemoveIsLoad,
  getGipUpdateIsLoad,
  getProjectComplexLoadDelete,
} from "../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { selectColor } from "../../../../../../utils/selectColor";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalEditGip } from "./ModalEditGip/ModalEditGip";
import { Dot } from "../../../../../newUI/Dot/Dot";
import { ModalGipRemove } from "./ModalGipRemove/ModalGipRemove";
import { userIsAdmin } from "../../../../../../app/services/user/userRoles";

const sx = { color: "white" };

export const ObjectCard: React.FC<IProjectCopmlex> = memo(
  ({
    status,
    name,
    dates,
    price,
    stage,
    gip,
    can,
    id,
    color,
    gipAccess,
    stopped,
  }) => {
    const isLoadDelete = useSelector(getProjectComplexLoadDelete);
    const isLoadGipUpdate = useSelector(getGipUpdateIsLoad);
    const isLoadRemoveGip = useSelector(getGipRemoveIsLoad);
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [modalGipEdit, setModalGipEdit] = useState(false);
    const [modalGipRemove, setModalGipRemove] = useState(false);
    const open = Boolean(anchorEl);

    const canBtn = can.delete || can.updateGip || can.requestRemoveGip;
    const isAdmin = userIsAdmin();

    const modalClose = useCallback(() => {
      setModalGipEdit(false);
    }, []);

    const deleteObject = async () => {
      const result = window.confirm("Вы уверены, что хотите удалить проект?");
      if (result && params.id) {
        await dispatch(fetchDeleteProjectObject(id));
        await dispatch(fetchProjectComplexObject({ id: +params.id }));
      }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const headerCard = (
      <div className={classNames(cls.flex, cls.card_header)}>
        <div className={cls.card_title}>
          {stopped && (
            <div className={cls.card_hiddentxt_svg}>
              <StopIcon />
            </div>
          )}
          <div className={cls.card_hiddentxt}>
            <Link to={`/project/${id}?tab=info`}>{name}</Link>
          </div>
        </div>
        {(can.updateGip || isAdmin) && gip && (
          <Modal
            active={modalGipEdit}
            setActive={setModalGipEdit}
            isLoad={isLoadGipUpdate}
          >
            <ModalEditGip
              id={id}
              gipAccess={gipAccess}
              modalClose={modalClose}
            />
          </Modal>
        )}
        {can.requestRemoveGip && (
          <Modal
            active={modalGipRemove}
            setActive={setModalGipRemove}
            isLoad={isLoadRemoveGip}
          >
            <ModalGipRemove id={id} />
          </Modal>
        )}
        {canBtn && (
          <IconButton
            sx={sx}
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className={cls.card_btn}
          >
            <VerticalThreeDots />
          </IconButton>
        )}
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {can.delete && (
            <MenuItem
              onClick={() => {
                deleteObject();
                handleClose();
              }}
              disableRipple
            >
              Удалить
            </MenuItem>
          )}
          {(can.updateGip || isAdmin) && gip && (
            <>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setModalGipEdit(true);
                }}
                disableRipple
              >
                Сменить ГИПа
              </MenuItem>
            </>
          )}
          {can.requestRemoveGip && (
            <>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setModalGipRemove(true);
                }}
                disableRipple
              >
                Снять ГИПа
              </MenuItem>
            </>
          )}
        </StyledMenu>
      </div>
    );

    return (
      <div className={cls.status} style={{ background: selectColor(color) }}>
        {isLoadDelete && <Preloader variant={cls.preloaderDelete} />}
        <div className={cls.card}>
          {headerCard}
          <div className={cls.card_content}>
            {gip && <p className={cls.card_content_gip}>{gip}</p>}
            {dates && (
              <>
                <h3>Cрок</h3>
                <div className={cls.flex}>
                  <p className={cls.flex_dateStart}>{dates?.start}</p>
                  <div className={cls.terms}>
                    <div style={{ width: dates?.percent }} />
                    <p className={cls.terms_progress}>{dates?.percent}%</p>
                    <div style={{ width: `${100 - dates?.percent}%` }}></div>
                  </div>
                  <p className={cls.flex_dateEnd}>{dates?.end}</p>
                </div>
              </>
            )}
            {price && (
              <>
                <h3>Бюджет</h3>
                <div className={cls.flex}>
                  <p className={cls.flex_dateStart}>{price?.start}</p>
                  <div className={cls.terms}>
                    <div style={{ width: price?.percent }} />
                    <p className={cls.terms_progress}>{price?.percent}%</p>
                    <div style={{ width: `${100 - price?.percent}%` }}></div>
                  </div>
                  <p className={cls.flex_dateEnd}>{price?.end}</p>
                </div>
              </>
            )}
            <div className={cls.milestones}>
              <h3>Стадия</h3>
              {stage.ПД && (
                <div className={cls.milestones_container}>
                  <Dot status={"warning"} size={null} />
                  <p>ПД</p>
                </div>
              )}
              {stage.РД && (
                <div className={cls.milestones_container}>
                  <Dot status={"gray"} size={null} />
                  <p>РД</p>
                </div>
              )}
              {stage.АН && (
                <div className={cls.milestones_container}>
                  <Dot status={"ok"} size={null} />
                  <p>АН</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cls.status_content}>
          <p
            className={classNames(
              status.key === 3 && cls.status_textWhite,
              status.key !== 3 && cls.status_textBlack
            )}
          >
            {status.value}
          </p>
        </div>
      </div>
    );
  }
);
