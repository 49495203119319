import React, { SetStateAction, Dispatch } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { config } from "src/app.cofig";
import { dateFutureRequired, stringRequired } from "src/utils/SimplifiedYup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  createSourceComment,
  editSourceComment,
  fetchSourceComments,
} from "src/app/feature/ProjectView/SectionsReducer/thunks";
import {
  TSourceComment,
  getProjectSections,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import style from "./AddCommentModal.module.scss";
import { Uploader } from "../../../../../../../components/features/Uploader/Uploader";
import {
  ApiGetCommentById,
  TCommentValues,
  formDefaultValues,
  statusOptions,
} from "../lib/helpers/utils";
import useNonInitialEffect from "../../../../../../../components/hooks/useNonInitialEffect";

type AddCommentModalProps = {
  currentComment: TSourceComment | null;
  sourceDataId: number;
  setCommentId: Dispatch<SetStateAction<number | null>>;
  name: string;
  isEditing: boolean;
};

const validationSchema = Yup.object().shape({
  comment: stringRequired("Комментарий"),
  date_limit: dateFutureRequired("Контрольный срок"),
});

export const AddCommentModal = ({
  sourceDataId,
  name,
  isEditing,
  currentComment,
  setCommentId,
}: AddCommentModalProps) => {
  const dispatch = useAppDispatch();

  const {
    error: stateError,
    pending: { editingSourceComment, creatingSourceComment },
  } = useAppSelector(getProjectSections);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<TCommentValues>({
    defaultValues: formDefaultValues(isEditing, currentComment),
    resolver: yupResolver(validationSchema),
  });

  // @ts-ignore
  const watchStatus = watch("status");
  // @ts-ignore
  const watchDateLimit = watch("date_limit");

  const onSubmit = async (rawData: TCommentValues) => {
    const data = new FormData();

    data.append("comment", rawData.comment);
    data.append("status", `${rawData.status.id}`);
    data.append("date_limit", moment(rawData.date_limit).format("DD.MM.YYYY"));

    rawData.uploadFiles.forEach((file) => {
      data.append("uploadFiles[]", file);
    });

    await dispatch(
      isEditing
        ? editSourceComment({ id: currentComment!.id!, data })
        : createSourceComment({ id: sourceDataId, data })
    );
    await dispatch(fetchSourceComments(sourceDataId));
  };

  useNonInitialEffect(() => {
    if (editingSourceComment || creatingSourceComment || stateError) {
      return;
    }

    setCommentId(null);
  }, [editingSourceComment, creatingSourceComment]);

  if (!currentComment && isEditing) {
    return null;
  }

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Комментарий к «{name}»</Modal.Header>
      <Modal.Form
        onClick={(e) => {
          e.stopPropagation();
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          {...register("comment")}
          label="Комментарий"
          placeholder="Введите текст"
          multiline
          minRows={3}
          error={Boolean(errors.comment)}
          helperText={errors.comment?.message}
        />
        <DateField
          label="Контрольный срок"
          startDateProp={watchDateLimit as Date}
          changeDateHandler={(value) => {
            setValue("date_limit", value);
            trigger("date_limit");
          }}
          className={style.picker}
          error={Boolean(errors.date_limit)}
          helperText={errors.date_limit?.message}
        />
        <Select
          label="Статус"
          value={watchStatus}
          options={statusOptions}
          sx={{ width: 300 }}
          changeHandler={(_, value) => {
            setValue("status", value);
          }}
          disableClear
        />
        <Uploader
          autoUpload={false}
          identifier={currentComment?.id ? `${currentComment.id}` : null}
          getDataCallback={ApiGetCommentById}
          field="files"
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          fileHandler={(data: any) => {
            setValue("uploadFiles", data);
          }}
          canBeEdited
          uploadAsActive
          background={""}
          newDesign
        />
        <Modal.Controls>
          <Button
            type="submit"
            disabled={editingSourceComment || creatingSourceComment}
            className={style.saveButton}
          >
            Сохранить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
