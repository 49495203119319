import { TTabValue } from "src/FSD/widgets/v2/project/InitialData/HeaderActions/HeaderActions";

export const useTabMapping = (currentChildTab: string | number) => {
  const tabMapping: { [key: string]: TTabValue } = {
    common: 4,
    otr: 1,
    project: 2,
    work: 3,
    "by-process": 5,
  };
  return tabMapping[currentChildTab] || 0;
};
