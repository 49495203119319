import { useState, useEffect, useRef, FC } from "react";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DashboardSkeleton from "../Skeleton";
import { useAppSelector } from "../../../../../app/store";
import { getDashboardStaff } from "../../../../../app/feature/dashboard/staff";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";
import FolderPanel from "./Sections/FolderPanel";
import Group from "./Groups/Group";
import globalStyle from "../Primary/style.module.scss";
import employeeStyle from "../../../../newUI/EmployeeCard/style.module.scss";
import { ModalWrapper } from "../../../../../components/pages/User/Edit/PersonalDataChange/PersonalDataChange";
import GroupToToggle from "../modals/GroupToggle";
import style from "./style.module.scss";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";
import { getDashboardHeader } from "../../../../../app/feature/dashboard/header";
import { useUserWorkflow } from "../../../../hooks/useUserWorkflow";
import { getUserId } from "../../../../../app/services/auth/auth";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export const getTeamsByStaffId = (data: any[], staffId: number) =>
  data.find((staff: any) => staff.id === staffId)?.teams ?? [];

type TMember = any;

type GroupFolderProps = {
  members: TMember[];
  part_group_id: Record<string, string>;
  isRequest?: boolean;
  name: string;
};

const GroupFolder: FC<GroupFolderProps> = ({
  members,
  part_group_id,
  isRequest,
  name,
}) => {
  const { data } = useAppSelector(getDashboardStaff);
  const [isOpen, setOpen] = useState<boolean>(true);

  const isMobile = useIsMobile(1600);

  const groupName =
    part_group_id?.[Object.keys(part_group_id)[0]] + (name ? ` - ${name}` : "");

  const groupMembers = members.map((member: TMember) => {
    if (isRequest) {
      return <Group member={member} isRequest short={isMobile} />;
    }

    const groupMember = data.staff.find(
      (staff: any) => staff.user_id === member.id
    );
    return <Group member={groupMember} short={isMobile} />;
  });

  return (
    <>
      <FolderPanel
        canOpen={Boolean(members.length)}
        name={isRequest ? groupName : `Группа ${groupName} (${members.length})`}
        isOpen={isOpen}
        handleShowSubtree={() => setOpen(!isOpen)}
      />
      {isOpen && groupMembers}
    </>
  );
};

const Groups: FC = () => {
  const [modalName, setModalName] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const isMobile = useIsMobile(1600);

  const optionsRef: any = useRef();

  const { data: userTypes } = useAppSelector(getDashboardHeader);
  const { data, pending } = useAppSelector(getDashboardStaff);
  const { staffId, userType } = useAppSelector(getDashboardDestinations);

  const { enqueueSnackbar } = useSnackbar();
  const { Popup } = useUserWorkflow();

  const userId = getUserId();

  const firmId =
    userTypes.find((type: any) => type.id === userType)?.firmId ?? null;

  useNonInitialEffect(() => {
    if (!pending.specializations) {
      enqueueSnackbar("Пользовтаель успешно принят", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
  }, [pending.specializations]);

  useEffect(() => {
    const handleShowOptions = (e: any) => {
      setShowOptions(true);
      e.stopPropagation();
    };
    optionsRef?.current?.addEventListener("click", handleShowOptions, true);

    return () => {
      optionsRef?.current?.removeEventListener(
        "click",
        handleShowOptions,
        true
      );
    };
  }, []);

  const handleHideOptions = () => {
    setShowOptions(false);
  };

  const handleCreateGroup = () => {
    Popup.createTeam.set({
      id: firmId,
      userId,
      name: "Создание группы",
      isDashboard: true,
    });
    Popup.createTeam.open();
  };

  const groupFolders = getTeamsByStaffId(data.staff, staffId);

  const head = (
    <div className={cn(style.generalHeader, style.groupsHeader)}>
      <h1>Название</h1>
      <p>Специализация</p>
      <p>Роль</p>
    </div>
  );

  const groups = (
    <div className={globalStyle.list}>
      {!isMobile && head}
      {Boolean(data.requests.length) && (
        <GroupFolder
          isRequest
          members={data.requests}
          part_group_id={{ 0: "Запрос на прием в организацию" }}
          name=""
        />
      )}
      {groupFolders.map((group: any) => (
        <GroupFolder key={group.id} {...group} />
      ))}
    </div>
  );

  const handleCloseModal = () => {
    setModalName("");
  };

  const modals = (
    <>
      {modalName === "add" && (
        <ModalWrapper handleCloseModal={handleCloseModal} show width={660}>
          <GroupToToggle action="add" handleCloseModal={handleCloseModal} />
        </ModalWrapper>
      )}
      {modalName === "remove" && (
        <ModalWrapper handleCloseModal={handleCloseModal} show width={660}>
          <GroupToToggle action="remove" handleCloseModal={handleCloseModal} />
        </ModalWrapper>
      )}
    </>
  );

  return (
    <div
      className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}
      onClick={() => setShowOptions(false)}
      onMouseLeave={handleHideOptions}
    >
      <div className={globalStyle.header}>
        <h3>Группы</h3>
        <IconButton ref={optionsRef} className={style.whiteButton}>
          <Menu />
        </IconButton>
        {showOptions && (
          <div className={cn(employeeStyle.subWindow, style.subWindow)}>
            <button onClick={handleCreateGroup}>Создать группу</button>
            <button onClick={() => setModalName("add")}>
              Добавить в группу
            </button>
            <button onClick={() => setModalName("remove")}>
              Удалить из группы
            </button>
          </div>
        )}
      </div>
      {pending.specializations ? <DashboardSkeleton count={8} /> : groups}
      {modals}
    </div>
  );
};

export default Groups;
