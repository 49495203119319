import { RootState } from "../../../../store";

export const getSubmitFormMaterialsIsLoad = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.isLoadSubmit;

export const getSubmitFormMaterialsError422 = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.error422;

export const getSubmitFormMaterialsSuccessSubmit = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.successSubmit;

export const getSubmitFormMaterialsPrice = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.price;

export const getSubmitFormMaterialsUserTypeId = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.userTypeId;

export const getSubmitFormMaterialsDateDeadline = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.dateDeadline;

export const getSubmitFormMaterialsComment = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.comment;

export const getSubmitFormMaterialsErrorFlag = (state: RootState) =>
  state.projectProcessView.submitMaterialsForm.errorFlag;
