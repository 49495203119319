import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  getErrorTrace,
  popError,
  popMessage,
} from "../../app/feature/errorTrace";
import { useAppDispatch, useAppSelector } from "../../app/store";

const ErrorTrace = () => {
  const dispatch = useAppDispatch();
  const { trace, messages } = useAppSelector(getErrorTrace);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (trace.length) {
      enqueueSnackbar(trace[0], {
        variant: "error",
        autoHideDuration: 5000,
      });
      dispatch(popError());
    }

    if (messages.length) {
      enqueueSnackbar(messages[0], {
        variant: "success",
        autoHideDuration: 5000,
      });
      dispatch(popMessage());
    }
  }, [trace, messages]);

  return <></>;
};

export default ErrorTrace;
