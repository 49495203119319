import { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import classNames from "classnames";
import { trimPrice } from "src/utils/trimNumber";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import { ModalSeismicity } from "./ModalSeismicity/ModalSeismicity";
import styles from "./InfoObject.module.scss";

export const InfoObject = () => {
  const { project } = useSelector(getProjectViewData);
  const containsSeismicity = !!project?.isSeismic;
  const [modalSeismicity, setModalSeismicity] = useState(false);
  const isTablet = useIsMobile(1600);

  const canProjectLinear =
    project?.objectType === 2 ||
    project?.objectType === 5 ||
    project?.objectType === 7;

  const canProjectSquare =
    project?.objectType === 1 ||
    project?.objectType === 4 ||
    project?.objectType === 6;

  return (
    <div className={styles.object}>
      <div className={styles.object_params}>
        {!isTablet && <h1>Объект</h1>}
        {isTablet && (
          <div className={styles.tablet}>
            <h1>Объект</h1>
            {!!project?.projectLength.length && canProjectLinear && (
              <div className={styles.object_count}>
                <h1>{trimPrice(project?.projectLength[0].value) || "0"}</h1>
                <p>Протяженность, пог. м</p>
              </div>
            )}
            {!!project?.projectSquare.length && canProjectSquare && (
              <div className={styles.object_count}>
                <h1>{trimPrice(project?.projectSquare[0].value) || "0"}</h1>
                <p>Общая площадь, кв. м</p>
              </div>
            )}
          </div>
        )}
        <div className={styles.flex}>
          <div className={styles.flexCol}>
            <div className={styles.type}>
              <p>Тип:</p>
              <span>{project?.contructionType}</span>
            </div>
            <div className={styles.type}>
              <p>Регион:</p>
              <span>{project?.region}</span>
            </div>
            <div className={styles.seismicity}>
              <div
                className={classNames(styles.seismicity_region, styles.type)}
              >
                <p>Нас. пункт:</p>
                <span>
                  {project?.location ? project.location : "(не указан)"}
                </span>
                {containsSeismicity && (
                  <span>
                    Сейсм. интенсивность: (A - {project?.seismicity?.a}, B -{" "}
                    {project?.seismicity?.b}, C - {project?.seismicity?.c})
                  </span>
                )}
              </div>
              <IconButton
                sx={{ paddingTop: "0", height: "40px" }}
                onClick={() => setModalSeismicity(true)}
              >
                <ExclamationTriangleFill color="orange" />
              </IconButton>
            </div>
            <div className={styles.type}>
              <p>Назначение:</p>
              <span>{project?.appointment}</span>
            </div>
          </div>
          <div className={styles.count}>
            <h2>Основные параметры</h2>
            {project?.projectParameters.map((el) => (
              <div key={el.id} className={styles.count_block}>
                <p>{el.type}:</p>
                <span>{el.value || "0"}</span>
              </div>
            ))}
            {!!project?.projectLength.length && canProjectSquare && (
              <div className={styles.count_block}>
                <p>Протяженность</p>
                <h4>{trimPrice(project?.projectLength[0].value) || "0"}</h4>
              </div>
            )}
            {!!project?.projectSquare.length && canProjectLinear && (
              <div className={styles.count_block}>
                <p>Общая площадь</p>
                <h4>{trimPrice(project?.projectSquare[0].value) || "0"}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isTablet && (
        <>
          {!!project?.projectLength.length && canProjectLinear && (
            <div className={styles.object_count}>
              <h1>{trimPrice(project?.projectLength[0].value) || "0"}</h1>
              <p>Протяженность, пог. м</p>
            </div>
          )}
          {!!project?.projectSquare.length && canProjectSquare && (
            <div className={styles.object_count}>
              <h1>{trimPrice(project?.projectSquare[0].value) || "0"}</h1>
              <p>Общая площадь, кв. м</p>
            </div>
          )}
        </>
      )}
      <NewModal
        active={modalSeismicity}
        setActive={setModalSeismicity}
        title={"Сейсмичность по данным ОСР-2016"}
      >
        <ModalSeismicity
          seismicity={project?.seismicity ?? null}
          location={project?.location ?? ""}
          region={project?.region ?? ""}
          closeModal={() => setModalSeismicity(false)}
        />
      </NewModal>
    </div>
  );
};
