import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";
import { ProjectSetGipPayloadType } from "../../../../types/stateTypes/events/eventsPayloadTypes";

/*
  Управление показом попапа формы прошения на роль ГИПа
 */

export const showEventRequestGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventRequestGipProject: {
      ...state.eventSetGipProject,
      isShow: true,
    },
  };
};

export const closeEventRequestGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventRequestGipProject: {
    isShow: false,
    data: {
      ...state.eventSetGipProject.data,
    },
  },
});

export const setEventRequestDataGipReducer = (
  state: EventEmitterEventType,
  action: {
    payload: ProjectSetGipPayloadType;
  }
): EventEmitterEventType => ({
  ...state,
  eventRequestGipProject: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorEERequestGipProjectIsShow = (state: StateType) =>
  state.eventEmitter.eventRequestGipProject.isShow;
