import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { PublishObjectMessages } from "src/FSD/shared/lib/helpers";
import styles from "./RequestUpdateForm.module.scss";

type TProps = {
  showBimWarning: boolean;
  showUserTypeWarning: boolean;
};

export const WarningMessage = ({
  showBimWarning,
  showUserTypeWarning,
}: TProps) => {
  return (
    <Paragraph className={styles.warningMessage}>
      {PublishObjectMessages.getWarningMessageApplication(
        showBimWarning,
        showUserTypeWarning
      )}
    </Paragraph>
  );
};
