import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { ApiTaskResponses } from "../../../../../../../entities/task/model/responseTypes";

export const mapTask = (payload: ApiTaskResponses.View) => {
  return {
    project: {
      id: payload.project!.id,
      title: `${payload.project!.code} ${payload.project!.name}`,
    },
    content: payload.content,
    partGroup: {
      id: payload.partGroup!.id,
      title: payload.partGroup!.name,
    },
    executor: {
      id: payload.executor.id,
      title: formFullUserName(payload.executor),
    },
    dateDeadline: timestampToDate(
      payload.date_complete ?? payload.date_deadline
    ),
    canEdit: payload.can.edit,
    status: payload.status.key,
  };
};
