import { memo } from "react";
import styles from "./WarningComponents.module.scss";

export const WarningMessagePublication = memo(() => {
  return (
    <p className={styles.warningPublication}>
      Внимание! У Заказчика проекта закончилась или отсутствует подписка по
      тарифу <span className={styles.warningPublication__span}>ПИРС-PRO</span>.
      В связи с этим, публикация осуществляется с последующим обязательным
      заключением договора с сервисом «Безопасная сделка». Для решения вопроса
      обратитесь к Заказчику.
    </p>
  );
});
