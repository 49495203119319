import { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear, Done, Add } from "@mui/icons-material";
import cn from "classnames";
import { CaretRightFill, Download } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { taskBackToWork } from "src/app/feature/ProjectView/thunks";
import { config } from "src/app.cofig";
import style from "../StatusCardWrapper.module.scss";
import { Dot } from "../../Dot/Dot";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { ModalWrapper } from "../../../pages/User/Edit/PersonalDataChange/PersonalDataChange";
import Check from "./Check";
import useSubRoutes from "../../../hooks/useSubRoutes";

type TShowModal = "complete" | "check" | null;

const SubTaskCard = (props: any) => {
  const dispatch = useAppDispatch();

  const [showCheck, setShowCheck] = useState<TShowModal>(null);

  const subTaskRef = useRef<HTMLDivElement>(null);

  const {
    pending: { subTaskBackToWork, subTaskCompletion },
  } = useAppSelector(getProjectViewData);

  const {
    id,
    status,
    files,
    date_complete,
    date_deadline,
    name,
    can,
    comment,
    content,
    setModalData,
    parent_id,
  } = props;

  const date = (date_complete ?? date_deadline).substr(0, 10);

  const filesCount = files.reduce(
    (count: number, item: any) => count + Number(item.visibility),
    0
  );

  const numberOfFiles = <p>Вложения ({filesCount})</p>;

  const handleBackToWork = () => {
    if (!window.confirm("Вы уверены, что хотите вернуть на доработку?")) {
      return;
    }

    dispatch(taskBackToWork(id));
  };

  const handleToggleCheck = (type: TShowModal) => {
    setShowCheck(type);
  };

  const handleShowComment = () => {
    setModalData({
      name,
      id,
      open: true,
    });
  };

  const handleScrollToTask = () => {
    subTaskRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (subTaskBackToWork || subTaskCompletion) {
      return;
    }

    setShowCheck(null);
  }, [subTaskBackToWork, subTaskCompletion]);

  useSubRoutes(id, handleScrollToTask, 1);

  const downloadFilesId = parent_id ?? id;

  return (
    <div ref={subTaskRef} className={style.subTaskCard}>
      <h2>{name}</h2>
      <p>{date}</p>
      <div
        className={cn(
          style.subTaskComment,
          style.taskLineWithIcon,
          comment && style.white
        )}
        onClick={handleShowComment}
      >
        <p>Комментарии</p>
        <CaretRightFill />
      </div>
      {filesCount ? (
        <div className={style.taskLineWithIcon}>
          <TextLink
            variant={style.url}
            redirect
            url={`${config.localDomain}/task/download-files?id=${downloadFilesId}`}
          >
            {numberOfFiles}
          </TextLink>
          <Download />
        </div>
      ) : (
        <p>Вложений нет</p>
      )}
      <div className={style.subTaskStatus}>
        <Dot status={status.dot} />
        <p dangerouslySetInnerHTML={{ __html: status.value }} />
      </div>
      <div className={style.buttonControls}>
        {can?.check && (
          <Tooltip title="Проверить" followCursor>
            <IconButton
              onClick={() => handleToggleCheck("complete")}
              className={style.iconButton}
              disabled={subTaskBackToWork}
            >
              <Done />
            </IconButton>
          </Tooltip>
        )}
        {can?.backToWork && (
          <Tooltip title="Вернуть на доработку" followCursor>
            <IconButton
              onClick={handleBackToWork}
              className={style.iconButton}
              disabled={subTaskBackToWork}
            >
              <Clear />
            </IconButton>
          </Tooltip>
        )}
        {can?.complete && (
          <Tooltip title="Выдать" followCursor>
            <IconButton
              onClick={() => handleToggleCheck("check")}
              className={style.iconButton}
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <ModalWrapper
        show={Boolean(showCheck)}
        handleCloseModal={() => handleToggleCheck(null)}
      >
        <Check
          taskStatus={status.id}
          isProceeded={showCheck === "complete"}
          name={name}
          files={files}
          can={can}
          taskId={id}
          comment={comment}
          content={content}
          handleCloseModal={() => {
            setShowCheck(null);
          }}
        />
      </ModalWrapper>
    </div>
  );
};

export default SubTaskCard;
