import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import parentStyles from "./PublishModalContent.module.scss";

type Item = {
  level?: number;
  title: string;
  id: number | string;
};

type TProps = {
  watchType: Item[];
  changeHandler: (data: Item[]) => void;
  fullTypes: Item[];
  errors?: string;
};

export const FormOfResponsibility = ({
  watchType,
  changeHandler,
  fullTypes,
  errors,
}: TProps) => {
  return (
    <div>
      <h3 className={parentStyles.groupTitle}>Требование к исполнителю</h3>
      <SelectMultiple
        label="Форма собственности"
        changeHandler={changeHandler}
        value={watchType}
        options={fullTypes}
        showSelectAll
        error={Boolean(errors)}
        disablePortal={false}
        helperText={errors}
      />
    </div>
  );
};
