import { TProjectView } from "../thunks";
import { createParams } from "./helper";

export const createData = (data: TProjectView) => {
  return {
    dateStart: data.date_start,
    advance_pay: data.advance_pay,
    lastPay: data.pay1,
    pay1: data.pay1,
    expertisePay: data.pay2,
    pay2: data.pay2,
    payLimit: data.pay_limit,
    dateEnd: data.date_end,
    accountId: data.accountId,
    code: data.code,
    nameForDoc: data.name_for_doc,
    bankId: data.bank_account_id,
    processPublishedCost: data.processPublishedCost,
    id: data.id,
    expertise: data.expertise[0]?.title,
    expertiseType: data.expertise,
    parentId: data.parent_id,
    chatId: data.chatId,
    resultFilesSize: data.resultFilesSize,
    designType: data.designType.value,
    parentName: data.parentName,
    authorPremium: data.authorPremium,
    author: {
      id: data.author.id,
      name: `${data.author.surname} ${data.author.name} ${
        data.author.patronymic || ""
      }`,
      photo: data.author?.photo,
      jobDuration: Math.floor(data.author.job_duration / 365),
      rating: data.author.rating.customer,
      type: data.authorType.listName,
    },
    authorType: data.authorType,
    authorAccess: data.authorAccess,
    gipAccess: data.gipAccess,
    can: data.can,
    gip: data.gip
      ? {
          id: data.gip?.id,
          name: `${data.gip?.surname} ${data.gip?.name} ${
            data.gip?.patronymic || ""
          }`,
          photo: data.gip?.photo,
          jobDuration: Math.floor(data.gip.job_duration / 365),
          rating: data.gip.rating.gip,
          type: data?.gipAccess[0].userType.listName,
          firmId: data?.gipAccess[0].userType.firmId,
        }
      : null,
    gipHelpers: data.gipHelpers.map((el) => {
      return {
        name: `${el?.surname} ${el?.name[0]}.${el?.patronymic[0] || ""}`,
        fullName: `${el?.surname} ${el?.name} ${el?.patronymic || ""}`,
        id: el.id,
        delegate: el.delegate,
        accessId: el.accessId,
      };
    }),
    gipHelpersApplication: data.gipHelpersApplication,
    gipProcess: { ...data.gipProcess },
    name: data.name,
    requestRemoveGipTask: data.requestRemoveGipTask,
    status: data.status.value,
    statusKey: data.status.key,
    hasObject:
      data.parent_id ||
      data.buildingFunction.type === 4 ||
      data.buildingFunction.type === 5,
    contructionType: data.constructionType.name,
    appointment: data.buildingFunction.name,
    objectType: data.buildingFunction.type,
    region: data.region.title,
    regionCode: data.region.code,
    isSeismic: data.region.isSeismic,
    seismicity: data.seismicity,
    countryCode: data.region.country.code,
    location: data.location,
    projectParameters: createParams(data.projectParameters),
    projectLength: data.projectParameters.filter(
      (el) => el.parameter_code === 8
    ),
    projectSquare: data.projectParameters.filter(
      (el) => el.parameter_code === 1
    ),
    description: data.description,
    files: data.files.map((item: any) => {
      return {
        title: item.name,
        url: item.url,
      };
    }),
    tabs: data.tabs,
  };
};
export const createGipHelpers = (data: any) => {
  return data.map((item: any) => {
    return {
      name: `${item.user.name} ${item.user.surname}`,
      id: item.user.id,
    };
  });
};
export type ProjectViewData = ReturnType<typeof createData>;
