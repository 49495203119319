import { memo } from "react";
import { Check, Close } from "@mui/icons-material";
import classNames from "classnames";
import styles from "./CheckLabel.module.scss";
import { palette } from "../../../../../../styles/restyle";
import { Paragraph } from "../../Paragraph/Paragraph";

type Props = {
  isCheck: boolean;
  fontSize?: number;
  text: string;
  className?: string;
};

export const CheckLabel = memo(
  ({ isCheck, fontSize = 16, text, className }: Props) => {
    return (
      <div
        className={classNames(styles.icons, className)}
        style={{ fontSize: `${fontSize}px` }}
      >
        {isCheck ? (
          <Check style={{ color: palette.persianGreen }} fontSize="inherit" />
        ) : (
          <Close style={{ color: palette.bordeaux }} fontSize="inherit" />
        )}
        <Paragraph>{text}</Paragraph>
      </div>
    );
  }
);
