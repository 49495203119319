import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import { ApiPrimaryFileType } from "../../../../../../../types/api/primaryTypes/apiPrimaryFileType";
import cls from "./ModalCheck.module.scss";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { fetchProjectProcessCheckAlign } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessCheckAlign";
import { parseUnprocessableFields } from "../../../../../../../app/services/api/requestHandler";
import { getProjectProcessDocumentsCheckError422 } from "../../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { NdsInfo } from "../../../../Info/PayBlock/PayBlock";

type TInputs = {
  comment: string;
  status: number;
  paid_out: number;
};

interface ModalCheckProps {
  comment: string;
  files: ApiPrimaryFileType[];
  name: string;
  taskId: number;
  processIdDeffered?: number;
  header?: string;
}
export const ModalCheck = memo(
  ({
    comment,
    files,
    name,
    taskId,
    processIdDeffered,
    header,
  }: ModalCheckProps) => {
    const dispatch = useAppDispatch();
    const infoData = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);
    const error422 = useAppSelector(getProjectProcessDocumentsCheckError422);

    const schema = Yup.object().shape({
      comment: Yup.string().when("status", {
        is: 5,
        then: Yup.string().required("Необходимо заполнить «Комментарий»"),
        otherwise: Yup.string(),
      }),
      paid_out: Yup.number().when("infoData.account.value", {
        is: (value) => value !== null && value !== undefined,
        then: Yup.number()
          .required("Необходимо заполнить «Сумма для оплаты»")
          .typeError("Сумма для оплаты должна быть числом")
          .min(0, "Сумма для оплаты должна быть положительной"),
        otherwise: Yup.number(),
      }),
    });

    const actualHeader = header ?? `Проверка ${name}`;

    const {
      handleSubmit,
      setValue,
      register,
      setError,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          status: dataValues.status,
          comment: dataValues.comment,
          paid_out: dataValues.paid_out,
        },
      };

      const actualProcessId = processId || processIdDeffered;

      if (actualProcessId) {
        await dispatch(
          fetchProjectProcessCheckAlign({
            taskId,
            data,
            processId: actualProcessId,
          })
        );
      }
    };

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1 className={cls.aaa}>{actualHeader}</h1>
        <TextField
          autoComplete="off"
          {...textFieldSX}
          label="Комментарий исполнителя"
          defaultValue={comment}
          variant="outlined"
          disabled
        />
        <fieldset className={cls.fieldset}>
          <legend>Файлы решения</legend>
          <div className={cls.fieldset_files}>
            {files.map((file) => (
              <TextLink url={file.url} redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <TextField
          {...textFieldSX}
          {...register("comment")}
          multiline
          minRows={2}
          label="Комментарий"
          placeholder="Введите комментарий"
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        {infoData?.account?.value && (
          <>
            <p>
              <span>Баланс раздела: {infoData?.account?.value}&nbsp;</span>
              <NdsInfo executorNds={infoData?.executor_nds} />
            </p>
            <TextField
              {...register("paid_out")}
              {...textFieldSX}
              label="Сумма для оплаты без налога (НДС)"
              defaultValue={0}
              error={!!errors.paid_out}
              helperText={errors.paid_out?.message}
            />
          </>
        )}
        <div className={cls.form_submit}>
          <CustomButton
            type="submit"
            width={120}
            background={palette.red}
            onClick={() => setValue("status", 5)}
          >
            Отказать
          </CustomButton>
          <CustomButton
            type="submit"
            width={120}
            background={palette.blue}
            onClick={() => setValue("status", 4)}
          >
            Принять
          </CustomButton>
        </div>
      </form>
    );
  }
);
