import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import {
  fetchGipHelperApplicateInvite,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import styles from "./GipHelperAccept.module.scss";

interface IGipHelperAcceptModalProps {
  id: number;
  projectName: string;
  isLoad: boolean;
}
export const GipHelperAccept = memo(
  ({ projectName, id, isLoad }: IGipHelperAcceptModalProps) => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    async function onAcceptClick(status: string) {
      if (status === "Принять") {
        await dispatch(fetchGipHelperApplicateInvite({ id, status: 4 }));
        if (params.id) {
          await dispatch(fetchProjectViewSearchGip(params.id));
        }
      }
      if (status === "Отказать") {
        dispatch(fetchGipHelperApplicateInvite({ id, status: 5 }));
        if (params.id) {
          await dispatch(fetchProjectViewSearchGip(params.id));
        }
      }
    }

    return (
      <Modal.Layout>
        <Modal.Header>Рассмотрение приглашения администрировании</Modal.Header>
        {isLoad ? (
          <Modal.Preloader />
        ) : (
          <Modal.Form>
            <div>
              <div className={styles.item}>
                <span>Роль:</span> Помощник ГИП
              </div>
              <div className={styles.item}>
                <span>Проект:</span> {projectName}
              </div>
            </div>
            <Modal.Controls>
              <Button
                variant="outlined"
                color="error"
                onClick={() => onAcceptClick("Отказать")}
              >
                Отказать
              </Button>
              <Button onClick={() => onAcceptClick("Принять")}>Принять</Button>
            </Modal.Controls>
          </Modal.Form>
        )}
      </Modal.Layout>
    );
  }
);
