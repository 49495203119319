import React from "react";
import classNames from "classnames";
import { Wrapper } from "../../templates";
import style from "./style.module.scss";
import { Operations } from "./Elements/Operations/Operations";
import { Flag } from "./Elements/Flag/Flag";

export const SafeDealSchema = () => {
  return (
    <div className={style.schemaContainer}>
      <Wrapper>
        <div className={style.titlesWrapper}>
          <h1 className={style.title}>
            <p> Как перестать беспокоиться</p>
            <p style={{ fontWeight: 400 }}> и начать зарабатывать</p>
          </h1>
          <h3 className={style.subtitle}>Безопасная сделка – это качество</h3>
        </div>
        <div className={style.container}>
          <div className={style.flagsAbove}>
            <p className={classNames(style.flagsTitle, style.customer)}>
              Заказчик
            </p>
            <Flag
              title="Публикация"
              subtitle="раздела"
              lineDirection="down"
              lineHeight={76}
              variant={style.flagAbove1}
            />
            <Flag
              title="Выбор"
              subtitle="исполнителя"
              lineDirection="down"
              lineHeight={42}
              variant={style.flagAbove2}
            />
            <Flag
              title="Приём"
              subtitle={`предварительных \n решений`}
              lineDirection="down"
              lineHeight={58}
              variant={style.flagAbove3}
            />
            <Flag
              title="Приём"
              subtitle="раздела"
              lineDirection="down"
              lineHeight={58}
              variant={style.flagAbove4}
            />
            <Flag
              title="Прохождение"
              subtitle="экспертизы"
              lineDirection="down"
              lineHeight={76}
              variant={style.flagAbove5}
            />
          </div>
          <div className={style.process}>
            <Operations variant={style.operations1} />
            <Operations variant={style.operations2} type={"payment"} />
            <div className={style.pairWrapper}>
              <Operations variant={style.operations3} />
              <Operations variant={style.operations4} type={"payment"} />
            </div>
            <Operations variant={style.operations5} type={"payment"} />
          </div>
        </div>
        <div className={style.flagsBelow}>
          <p className={classNames(style.flagsTitle, style.executor)}>
            Исполнитель
          </p>
          <Flag
            title="Заявиться"
            subtitle="на раздел"
            lineDirection="up"
            lineHeight={65}
            variant={style.flagBelow1}
          />
          <Flag
            title="Выдать"
            subtitle={`предварительные \n решения`}
            lineDirection="up"
            lineHeight={65}
            variant={style.flagBelow2}
          />
          <Flag
            title="Завершить"
            subtitle="раздел"
            lineDirection="up"
            lineHeight={65}
            variant={style.flagBelow3}
          />
        </div>
      </Wrapper>
    </div>
  );
};
