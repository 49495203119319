import React from "react";
import classNames from "classnames";
import cls from "./NewAvatar.module.scss";
import { StarRating } from "../SVG";
import { helpersYears } from "../../../utils/helpersYears";
import DefaultAvatar from "../../../media/images/avatarDefault.jpg";

export enum ThemeAvatar {
  "STAR" = "star",
  "NAME" = "name",
}

interface NewAvatarProps {
  photoUrl: string | undefined;
  jobDuration?: number;
  rating?: number;
  borderColor?: string;
  fio?: string;
  userType?: string;
  theme?: ThemeAvatar;
  classname?: string;
}

export const NewAvatar = ({
  photoUrl,
  jobDuration,
  rating,
  borderColor,
  fio,
  userType,
  classname,
  theme = ThemeAvatar.STAR,
}: NewAvatarProps) => {
  if (theme === ThemeAvatar.STAR) {
    return (
      <div className={classNames(cls.container, classname)}>
        <div className={cls.skills}>
          {typeof rating !== "undefined" && (
            <div className={cls.star}>
              <StarRating />
              <p>{rating ? Number(rating).toFixed(1) : "0"}</p>
            </div>
          )}
          <p>{`Опыт ${helpersYears(jobDuration)}`}</p>
        </div>
        <div className={cls.avatar}>
          <img
            style={{
              border: `2px solid ${borderColor}`,
            }}
            src={photoUrl || DefaultAvatar}
            alt="аватарка"
          />
        </div>
      </div>
    );
  }

  if (theme === ThemeAvatar.NAME) {
    return (
      <div className={classNames(cls.name, classname)}>
        <div className={cls.avatar}>
          <img
            style={{
              border: `2px solid ${borderColor}`,
            }}
            src={photoUrl || DefaultAvatar}
            alt="аватарка"
          />
        </div>
        <div className={cls.name_block}>
          <p>{fio}</p>
          <span>{userType}</span>
        </div>
      </div>
    );
  }

  return null;
};
