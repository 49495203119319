import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  getProjectProcessTree,
  toProcesses,
  canCheck,
  depositFunds,
  toProjects,
  updatePrice,
  application,
  view,
  getDocTree,
  getUserTypeToAssign,
  getWorks,
  delegate,
  updatePublished,
  removed,
  publish,
  publishInfo,
  getUsersToAssign,
} from "./queries";

export const projectProcessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectProcessView: view(builder),
    projectProcessGetDocTree: getDocTree(builder),
    projectProcessUpdatePrice: updatePrice(builder),
    projectProcessGetProjectProcessTree: getProjectProcessTree(builder),
    projectProcessToProcesses: toProcesses(builder),
    projectProcessToProjects: toProjects(builder),
    projectProcessCanCheck: canCheck(builder),
    projectProcessDepositFunds: depositFunds(builder),
    projectProcessApplication: application(builder),
    projectProcessGetUserTypeToAssign: getUserTypeToAssign(builder),
    projectProcessGetWorks: getWorks(builder),
    projectProcessDelegate: delegate(builder),
    projectProcessUpdatePublished: updatePublished(builder),
    projectProcessRemoved: removed(builder),
    projectProcessPublish: publish(builder),
    projectProcessPublishInfo: publishInfo(builder),
    projectProcessGetUsersToAssign: getUsersToAssign(builder),
  }),
  overrideExisting: false,
});

export const {
  useProjectProcessViewQuery,
  useProjectProcessGetDocTreeQuery,
  useProjectProcessUpdatePriceMutation,
  useProjectProcessGetProjectProcessTreeQuery,
  useProjectProcessToProcessesQuery,
  useProjectProcessToProjectsQuery,
  useProjectProcessCanCheckQuery,
  useProjectProcessDepositFundsMutation,
  useProjectProcessApplicationQuery,
  useProjectProcessGetUserTypeToAssignQuery,
  useProjectProcessGetWorksQuery,
  useProjectProcessDelegateMutation,
  useProjectProcessUpdatePublishedMutation,
  useProjectProcessRemovedQuery,
  useProjectProcessPublishMutation,
  useProjectProcessPublishInfoQuery,
  useLazyProjectProcessGetUsersToAssignQuery,
} = projectProcessApi;
