import React, { memo, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import cls from "./Filter.module.scss";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../../styles/restyle";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import {
  clearFilter,
  setPartGroupId,
  setProjectPartCode,
  setStatusId,
} from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import { fetchExpertiseRemarksFilter } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarksFilter";
import { fetchExpertisePartGroup } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertisePartGroup";
import { fetchExpertiseProjectPartByGroup } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectPartByGroup";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getExpertisePartGroup,
  getExpertiseProjectPart,
} from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertisePartGroupId,
  getExpertiseProjectPartCode,
  getExpertiseProjectStatusId,
} from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarkFilter";

const status = [
  {
    value: 2,
    text: "В работе",
  },
  {
    value: 3,
    text: "Выполнено",
  },
];

export const Filter = memo(() => {
  const dispatch = useAppDispatch();
  const partGroup = useAppSelector(getExpertisePartGroup);
  const projectPart = useAppSelector(getExpertiseProjectPart);
  const processId = useAppSelector(getProjectProcessInfoId);
  const projectId = useAppSelector(getProjectProcessInfoProjectId);
  const partGroupId = useAppSelector(getExpertisePartGroupId);
  const projectPartCode = useAppSelector(getExpertiseProjectPartCode);
  const statusId = useAppSelector(getExpertiseProjectStatusId);

  useEffect(() => {
    dispatch(fetchExpertisePartGroup());
  }, []);

  useEffect(() => {
    if (partGroupId && projectId) {
      dispatch(
        fetchExpertiseProjectPartByGroup({ projectId, groupId: +partGroupId })
      );
    }
  }, [partGroupId]);

  function handleChangePartGroup(event: SelectChangeEvent) {
    dispatch(setPartGroupId(event.target.value));
  }

  function handleChangeProjectPart(event: SelectChangeEvent) {
    dispatch(setProjectPartCode(event.target.value));
  }

  function handleChangeStatus(event: SelectChangeEvent) {
    dispatch(setStatusId(event.target.value));
  }

  function handleClearClick() {
    dispatch(clearFilter());
    if (processId) {
      dispatch(
        fetchExpertiseRemarksFilter({
          processId,
          partGroupId: "",
          code: "",
          status: "",
        })
      );
    }
  }

  function handleSearchClick() {
    if (processId) {
      dispatch(
        fetchExpertiseRemarksFilter({
          processId,
          partGroupId: +partGroupId,
          code: projectPartCode,
          status: +statusId,
        })
      );
    }
  }

  const mapPartGroup = partGroup
    ? partGroup.map((group) => (
        <MenuItem key={group.id} sx={menuItemSx} value={group.id}>
          {group.name}
        </MenuItem>
      ))
    : null;

  const mapProjectPart = projectPart
    ? projectPart.map((part) => (
        <MenuItem key={part.code} sx={menuItemSx} value={part.code}>
          {part.code}
        </MenuItem>
      ))
    : null;

  return (
    <div className={cls.filter}>
      <FormControl {...textFieldSX} className={cls.filter_group}>
        <InputLabel id="partGroup">Группа разделов</InputLabel>
        <Select
          labelId="partGroup"
          label="Группа разделов"
          value={partGroupId}
          onChange={handleChangePartGroup}
        >
          {mapPartGroup}
        </Select>
      </FormControl>
      <FormControl {...textFieldSX}>
        <InputLabel id="projectPart">Разделы</InputLabel>
        <Select
          labelId="projectPart"
          label="Разделы"
          value={projectPartCode}
          disabled={!partGroupId}
          onChange={handleChangeProjectPart}
        >
          {mapProjectPart}
        </Select>
      </FormControl>
      <FormControl {...textFieldSX} className={cls.filter_status}>
        <InputLabel id="status">Статус</InputLabel>
        <Select
          labelId="status"
          label="Статус"
          value={statusId}
          onChange={handleChangeStatus}
        >
          {status.map((el) => (
            <MenuItem key={el.value} sx={menuItemSx} value={el.value}>
              {el.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={cls.filter_btn}>
        <CustomButton
          background={palette.blue}
          width={90}
          onClick={handleSearchClick}
        >
          Поиск
        </CustomButton>
        <CustomButton
          background={palette.grey}
          width={110}
          onClick={handleClearClick}
        >
          Сбросить
        </CustomButton>
      </div>
    </div>
  );
});
