import { RootState } from "../../../../store";

export const getProjectProcessDocumentsCustomerAlign = (state: RootState) =>
  state.projectProcessView.documentations.customerAlign;

export const getProjectProcessDocumentsCompleteCheck = (state: RootState) =>
  state.projectProcessView.documentations.completeCheck;

export const getProjectProcessDocumentsCheckError422 = (state: RootState) =>
  state.projectProcessView.documentations.error422;

export const getProjectProcessDocumentsRequestCustomerAlign = (
  state: RootState
) => state.projectProcessView.documentations.requestCustomerAlign;

export const getProjectProcessDocumentsProcessTask = (state: RootState) =>
  state.projectProcessView.documentations.processTask;

export const getProjectProcessDocumentsExpertiseIn = (state: RootState) =>
  state.projectProcessView.documentations.expertiseIn;

export const getProjectProcessDocumentsIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoad;

export const getProjectProcessDocumentsTab = (state: RootState) =>
  state.projectProcessView.documentations.tab;

export const getProjectProcessTaskCompleteIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoadCompleteTask;

export const getProjectProcessCheckAlignIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoadCompleteCheckAlign;

export const getProjectProcessDepositFundsIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoadDepositFunds;

export const getProjectProcessCanCheckIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoadCanCheck;

export const getProjectProcessPreliminaryDoneCustomer = (state: RootState) =>
  state.projectProcessView.documentations.isLoadPreliminaryDone;

export const getProjectProcessPreliminaryByTask = (state: RootState) =>
  state.projectProcessView.documentations.isLoadPreliminaryByTask;

export const getProjectProcessEditIsLoad = (state: RootState) =>
  state.projectProcessView.documentations.isLoadEdit;

export const getProjectProcessCanCheck = (state: RootState) =>
  state.projectProcessView.documentations.canCheck;

export const getProjectProcessCheckComment = (state: RootState) =>
  state.projectProcessView.documentations.modalCheckComment;
