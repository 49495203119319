import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessFeedbackExecutor } from "../../../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessFeedbackExecutor";
import { FeedBackForm } from "../../../../../../newUI/FeedBackForm/FeedBackForm";
import { fetchProcessMaterial } from "../../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterial";

interface ModalFeedbackMaterialProps {
  executorData: any;
  closeModal: () => void;
}
export const ModalFeedbackMaterial = ({
  executorData,
  closeModal,
}: ModalFeedbackMaterialProps) => {
  const dispatch = useAppDispatch();
  const infoData = useAppSelector(getProjectProcessInfoData);
  const processId = useAppSelector(getProjectProcessInfoId);

  const formSubmitHandler = useCallback(
    (dataValues) => {
      const submitData = async () => {
        const data = {
          quality: dataValues.quality,
          reliability: dataValues.reliability,
          loyalty: dataValues.loyalty,
          comment: dataValues.comment,
          type: 6,
        };
        if (infoData && executorData) {
          await dispatch(
            fetchProjectProcessFeedbackExecutor({
              taskId: executorData[0].id,
              userId: executorData[0].executorId,
              data,
            })
          );
        }
        if (processId) {
          await dispatch(fetchProcessMaterial(processId));
        }
        closeModal();
      };

      submitData();
    },
    [fetchProjectProcessFeedbackExecutor]
  );

  if (executorData) {
    return (
      <FeedBackForm
        formSubmitHandler={formSubmitHandler}
        data={executorData}
        title={"Оценка поставщика"}
      />
    );
  }
  return <></>;
};
