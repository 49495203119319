import { TTabsPanel } from "../../model/types";

export const getCurrentTabChildren = (
  tabsData: TTabsPanel.Tab[],
  currentTab: string
) => {
  const findCurrentItems = tabsData.find(({ name }) => name === currentTab);
  if (findCurrentItems) {
    return Object.values(findCurrentItems?.items).filter(({ show }) => show);
  }
  return [];
};
