import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { processWorkApi } from "src/FSD/entities/processWork/api";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";
import { Nullable } from "../../../../../../types/generics";

type TUseQueriesProps = {
  id: number;
  isProcessWork: boolean;
  executor?: Nullable<TSelectItem>;
};

export const useQueries = ({
  id,
  isProcessWork,
  executor,
}: TUseQueriesProps) => {
  const [
    getUserTypeToAssignWork,
    { data: userTypeToAssignWorkRaw, isLoading: isLoadingWork },
  ] = processWorkApi.useLazyProcessWorkGetUserTypeToAssignQuery();

  const [
    getUserTypeToAssignProcess,
    { data: userTypeToAssignProcessRaw, isLoading: isLoadingProcess },
  ] = projectProcessApi.useLazyProjectProcessGetUserTypeToAssignQuery();

  const fetchGetGetUserTypeToAssignWork = async () => {
    await getUserTypeToAssignWork({
      id,
    });
  };

  const fetchGetUserTypeToAssignProcess = async () => {
    if (!executor) {
      return;
    }

    await getUserTypeToAssignProcess({
      id,
      userId: +executor.id,
    });
  };

  const fetchGetGetUserTypeToAssign = isProcessWork
    ? fetchGetGetUserTypeToAssignWork
    : fetchGetUserTypeToAssignProcess;

  const userTypeToAssignRaw = isProcessWork
    ? userTypeToAssignWorkRaw
    : userTypeToAssignProcessRaw;

  const isLoading = isProcessWork ? isLoadingWork : isLoadingProcess;

  return { fetchGetGetUserTypeToAssign, userTypeToAssignRaw, isLoading };
};
