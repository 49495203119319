import React, { memo } from "react";
import cls from "./InfoExpertise.module.scss";
import { PayBlock } from "../../Info/PayBlock/PayBlock";
import { TermsBlock } from "../../Info/TermsBlock/TermsBlock";
import { useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoData } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

export const InfoExpertise = memo(() => {
  const infoData = useAppSelector(getProjectProcessInfoData);

  if (infoData) {
    return (
      <div className={cls.expertise}>
        <div className={cls.block}>
          <PayBlock {...infoData} />
          <TermsBlock {...infoData} isExpertise />
        </div>
      </div>
    );
  }
  return <></>;
});
