import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  fetchDeleteProjectObject,
  fetchGipList,
  fetchGipObjectRemove,
  fetchGipTypeList,
  fetchProjectComplexObject,
  fetchProjectGipUpdate,
  IProjectCopmlex,
} from "./thunks";

type TInitialState = {
  isLoad: boolean;
  isLoadDelete: boolean;
  isLoadGip: boolean;
  isLoadGipType: boolean;
  isLoadUpdate: boolean;
  isLoadRemoveGip: boolean;
  isLoadBody: boolean;
  searchValue: string;
  isSearched: boolean;
  gipRemove: boolean;
  parentId: number | null;
  gipUpdate: boolean;
  gipList: Record<string, string>;
  gipTypeList: Record<string, string>;
  error: any;
  objects: IProjectCopmlex[];
};
const initialState: TInitialState = {
  isLoad: false,
  isLoadDelete: false,
  isLoadGip: false,
  isLoadGipType: false,
  isLoadUpdate: false,
  isLoadRemoveGip: false,
  isLoadBody: false,
  searchValue: "",
  isSearched: false,
  gipRemove: false,
  gipUpdate: false,
  error: null,
  parentId: null,
  objects: [],
  gipList: {},
  gipTypeList: {},
};

const projectComplexObjectSlice = createSlice({
  name: "projectComplexObjectSlice",
  initialState,
  reducers: {
    setErrorNull(state) {
      state.error = null;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsSearched(state, action) {
      state.isSearched = action.payload;
    },
    setParentProjectId(state, action) {
      state.parentId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectComplexObject.pending, (state, action) => {
      const { load } = action.meta.arg;
      if (load) {
        state.isLoadBody = true;
        state.error = null;
      } else {
        state.isLoad = true;
        state.error = null;
      }
    });
    builder.addCase(fetchProjectComplexObject.fulfilled, (state, action) => {
      state.gipRemove = false;
      state.isLoad = false;
      state.isLoadBody = false;
      state.objects = Object.values(action.payload);
    });
    builder.addCase(fetchProjectComplexObject.rejected, (state, action) => {
      state.isLoad = false;
      state.isLoadBody = false;
      state.error = action.payload;
    });
    builder.addCase(fetchDeleteProjectObject.pending, (state) => {
      state.isLoadDelete = true;
      state.error = null;
    });
    builder.addCase(fetchDeleteProjectObject.fulfilled, (state) => {
      state.isLoadDelete = false;
    });
    builder.addCase(fetchDeleteProjectObject.rejected, (state, action) => {
      state.isLoadDelete = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGipList.pending, (state) => {
      state.isLoadGip = true;
      state.error = null;
    });
    builder.addCase(fetchGipList.fulfilled, (state, action) => {
      state.isLoadGip = false;
      state.gipList = action.payload;
    });
    builder.addCase(fetchGipList.rejected, (state, action) => {
      state.isLoadGip = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGipTypeList.pending, (state) => {
      state.isLoadGipType = true;
      state.error = null;
    });
    builder.addCase(fetchGipTypeList.fulfilled, (state, action) => {
      state.isLoadGipType = false;
      state.gipTypeList = action.payload;
    });
    builder.addCase(fetchGipTypeList.rejected, (state, action) => {
      state.isLoadGipType = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectGipUpdate.pending, (state) => {
      state.isLoadUpdate = true;
      state.error = null;
      state.gipUpdate = false;
    });
    builder.addCase(fetchProjectGipUpdate.fulfilled, (state) => {
      state.isLoadUpdate = false;
      state.gipUpdate = true;
    });
    builder.addCase(fetchProjectGipUpdate.rejected, (state, action) => {
      state.isLoadUpdate = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGipObjectRemove.pending, (state) => {
      state.isLoadRemoveGip = true;
      state.error = null;
      state.gipRemove = false;
    });
    builder.addCase(fetchGipObjectRemove.fulfilled, (state) => {
      state.isLoadRemoveGip = false;
      state.gipRemove = true;
    });
    builder.addCase(fetchGipObjectRemove.rejected, (state, action) => {
      state.isLoadRemoveGip = false;
      state.error = action.payload;
    });
  },
});

export const getProjectComplexObjects = (state: RootState) =>
  state.projectComplexObjects.objects;
export const getProjectComplexLoad = (state: RootState) =>
  state.projectComplexObjects.isLoad;
export const getProjectComplexLoadDelete = (state: RootState) =>
  state.projectComplexObjects.isLoadDelete;
export const getProjectComplexError = (state: RootState) =>
  state.projectComplexObjects.error;
export const getGipList = (state: RootState) =>
  state.projectComplexObjects.gipList;
export const getisLoadGip = (state: RootState) =>
  state.projectComplexObjects.isLoadGip;
export const getGipTypeList = (state: RootState) =>
  state.projectComplexObjects.gipTypeList;
export const getGipTypeIsLoad = (state: RootState) =>
  state.projectComplexObjects.isLoadGipType;
export const getGipUpdateStatus = (state: RootState) =>
  state.projectComplexObjects.gipUpdate;
export const getGipUpdateIsLoad = (state: RootState) =>
  state.projectComplexObjects.isLoadUpdate;
export const getGipRemoveIsLoad = (state: RootState) =>
  state.projectComplexObjects.isLoadRemoveGip;
export const getBodyIsLoad = (state: RootState) =>
  state.projectComplexObjects.isLoadBody;
export const getGipRemove = (state: RootState) =>
  state.projectComplexObjects.gipRemove;
export const getSearchValue = (state: RootState) =>
  state.projectComplexObjects.searchValue;
export const getParentProjectId = (state: RootState) =>
  state.projectComplexObjects.parentId;

export const getIsSearched = (state: RootState) =>
  state.projectComplexObjects.isSearched;

export const {
  setErrorNull,
  setSearchValue,
  setIsSearched,
  setParentProjectId,
} = projectComplexObjectSlice.actions;
export default projectComplexObjectSlice.reducer;
