import React from "react";
import parse from "html-react-parser";
import style from "../style.module.scss";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { config } from "../../../../../../app.cofig";
import { DefaultFileIcon } from "../../../../../features/Uploader/UploaderUtils/UI";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { TaskValueType } from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";

interface ModalDownloadFilesProps {
  task: TaskValueType;
}
export const ModalDownloadFiles = ({ task }: ModalDownloadFilesProps) => {
  const taskId = task?.taskCheckId ?? task?.task.id;
  return (
    <>
      <h1 className={style.modalFiles_h1}>Вложения</h1>
      <div className={style.modalFiles}>
        {task.files.map((file) => (
          <TextLink
            variant={style.modalFiles_link}
            redirect
            download
            url={`${config.localDomain}${file.url}`}
            key={file.id}
          >
            <div className={style.modalFiles_block} title="скачать">
              <div className={style.svgIconContainer} title="скачать">
                {file.svgIcon ? parse(file.svgIcon) : <DefaultFileIcon />}
              </div>
              <p>{file.name}</p>
            </div>
          </TextLink>
        ))}
      </div>
      <TextLink
        url={`/task/download-files?id=${taskId}`}
        redirect
        target={"_blank"}
        variant={style.download}
      >
        <CustomButton background={palette.green} width={130}>
          Скачать все
        </CustomButton>
      </TextLink>
    </>
  );
};
