import { createSlice, CombinedState } from "@reduxjs/toolkit";
import { createChecklist, fetchStageList } from "./thunks";
import { ITab } from "../../../../components/newUI/HorizontalTabsPanel/Tabs";
import { getMyProjectsByUserType } from "../../ProjectView/AdminReducer/thunks";
import { TSelectItem } from "../../../../components/features/Forms/SetGipForm/types";
import { fetchProjectProcessInitialData } from "../../ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";

type TState = {
  data: {
    disabled: "delete" | "update" | false;
    tabs: ITab[];
    type: number | null;
    projects: TSelectItem[];
  };
  pending: {
    tabs: boolean;
    create: boolean;
    projects: boolean;
  };
  error: {
    tabs: string | null;
    create: string | null;
    projects: string | null;
  };
};

const allProjects = {
  id: "all",
  title: "Все проекты",
};

const initialState: TState = {
  data: {
    disabled: false,
    tabs: [],
    type: null,
    projects: [allProjects],
  },
  pending: {
    tabs: false,
    create: false,
    projects: false,
  },
  error: {
    tabs: null,
    create: null,
    projects: null,
  },
};

const projectChecklist = createSlice({
  name: "projectChecklist",
  initialState,
  reducers: {
    clearProjects(state) {
      state.data.projects = [allProjects];
    },
    setDisabled(state, action) {
      state.data.disabled = action.payload;
    },
    setChecklistType(state, action) {
      state.data.type = action.payload;
    },
    clearChecklistType(state) {
      state.data.type = null;
    },
    clearProjectChecklist() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createChecklist.pending, (state) => {
      state.pending.create = true;
    });
    builder.addCase(createChecklist.fulfilled, (state) => {
      state.pending.create = false;
    });
    builder.addCase(createChecklist.rejected, (state, action) => {
      state.pending.create = false;
      state.error.create = action.payload as string;
    });

    builder.addCase(getMyProjectsByUserType.pending, (state) => {
      state.pending.projects = true;
    });
    builder.addCase(getMyProjectsByUserType.fulfilled, (state, action) => {
      state.pending.projects = false;

      state.data.projects = action.payload.reduce(
        (result: TSelectItem[], { id, name: title }: any) => {
          return [...result, { id, title }];
        },
        [allProjects]
      );
    });
    builder.addCase(getMyProjectsByUserType.rejected, (state, action) => {
      state.pending.projects = false;
      state.error.projects = action.payload as string;
    });

    builder.addCase(fetchStageList.pending, (state) => {
      state.pending.tabs = true;
    });
    builder.addCase(fetchStageList.fulfilled, (state, action) => {
      state.pending.tabs = false;

      state.data.tabs = action.payload.map(
        ({ name: label, dot: incomplete, key: id }: any) => ({
          id,
          label,
          incomplete,
        })
      );
    });
    builder.addCase(fetchStageList.rejected, (state, action) => {
      state.pending.tabs = false;
      state.error.tabs = action.payload as string;
    });

    builder.addCase(fetchProjectProcessInitialData.pending, (state) => {
      state.pending.tabs = true;
    });
    builder.addCase(
      fetchProjectProcessInitialData.fulfilled,
      (state, action) => {
        state.data.tabs = Object.values(action.payload.newSourceData).map(
          ({ name: label, dot: incomplete, type: id }: any) => ({
            id,
            label,
            incomplete,
          })
        );

        state.data.tabs.push({
          incomplete: false,
          label: "По разделу",
          id: "by-process",
        });
      }
    );
    builder.addCase(
      fetchProjectProcessInitialData.rejected,
      (state, action) => {
        state.pending.tabs = false;
        state.error.tabs = action.payload as string;
      }
    );
  },

  // потом Леша дробавь мэтчеры
});

export const {
  clearProjects,
  clearProjectChecklist,
  setChecklistType,
  clearChecklistType,
  setDisabled,
} = projectChecklist.actions;

export const getProjectChecklist = (state: CombinedState<any>) =>
  state.projectChecklist;

export default projectChecklist.reducer;
