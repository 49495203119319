import React, { memo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "./ModalConclusionCreate.module.scss";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { fetchExpertiseConclusionCreate } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/services/fetchExpertiseConclusionCreate";
import { fetchConclusionExpertise } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/services/fetchConclusionExpertise";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

type TInputs = {
  num: string;
  date: any;
  file_upload: any;
  fail: boolean;
};

interface ModalConclusionCreateProps {
  closeModal: () => void;
}
export const ModalConclusionCreate = memo(
  ({ closeModal }: ModalConclusionCreateProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const [checkFail, setCheckFail] = useState(false);

    const {
      handleSubmit,
      setValue,
      control,
      register,
      formState: { errors },
    } = useForm<TInputs>({
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();
      formData.append("fail", checkFail ? "1" : "0");
      formData.append("num", dataValues.num);
      formData.append(
        "date",
        moment(dataValues.date, "DD.MM.YYYY").format("DD.MM.YYYY")
      );
      dataValues.file_upload.forEach((file: BinaryType) => {
        formData.append("file_upload", file);
      });
      if (processId) {
        await dispatch(fetchExpertiseConclusionCreate({ processId, formData }));
        dispatch(fetchConclusionExpertise(processId));
      }
      closeModal();
    };

    return (
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={cls.form}
        encType={"multipart/form-data"}
      >
        <h1>Заключение</h1>
        <label className={cls.form_label}>
          <CustomCheckbox
            onChange={() => setCheckFail(!checkFail)}
            checked={checkFail}
            editing
          />
          Получено отрицательное заключение экспертизы
        </label>
        <div className={cls.form_flex}>
          <TextField
            {...register("num")}
            {...textFieldSX}
            label="Номер"
            error={!!errors.num}
            name={"num"}
          />
          <div className={cls.dateWrapper}>
            <Controller
              control={control}
              name="date"
              render={() => (
                <DateField
                  label="Дата"
                  changeDateHandler={(date) => {
                    setValue("date", date);
                  }}
                  error={!!errors.date}
                />
              )}
            />
          </div>
        </div>
        <Uploader
          canBeEdited
          autoUpload={false}
          identifier={null}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          fileHandler={(data: any) => {
            setValue("file_upload", data);
          }}
        />
        <CustomButton
          type="submit"
          background={palette.green}
          width={160}
          className={cls.form_submit}
        >
          Сохранить
        </CustomButton>
      </form>
    );
  }
);
