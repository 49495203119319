import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { outputTaskSchema } from "../schemas/outputTask";
import { useAppDispatch } from "../../../../../../../../app/store";
import { pushError } from "../../../../../../../../app/feature/errorTrace";
import { Nullable } from "../../../../../../../../types/generics";
import { TSelectItem } from "../../../../../../../../components/features/Forms/SetGipForm/types";

export type TFieldValues = {
  files: TFile[];
  comment: string;
  status: Nullable<Statuses>;
  processes: TSelectItem[];
  check: boolean;
  needFiles: boolean;
};

type UseModalProps = {
  taskId: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useOutputTask = ({
  taskId,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(outputTaskSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["project", "taskProcess"],
  });

  const status = watch("status");

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
    setValue("needFiles", taskData.need_files);
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      comment: data.comment,
      status: data.status!,
      forAll: data.check,
      toProcessesIds: data.processes.map((process: TSelectItem) => process.id),
    };

    await dispatch(
      TaskApi.complete({
        data: { id: taskId, formedData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  const isRejecting = isSubmitting && status === Statuses.IS_REJECT;
  const isDone = isSubmitting && status === Statuses.IS_DONE;

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isRejecting,
    isDone,
    isTaskViewPending: isFetching || !taskData,
    taskData,
  } as const;
};
