import React, { FC, useEffect, useState } from "react";
import {
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form/dist/types/form";
import style from "../../Experience/ExpirienceItem/style.module.scss";
import localStyle from "./style.module.scss";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

type TProps = {
  dateStart: Date | null;
  dateEnd: Date | null;
  setValue: UseFormSetValue<any>;
  dateStartError: string | undefined;
  dateEndError: string | undefined;
  trigger: UseFormTrigger<any>;
};

const DateRange: FC<TProps> = ({
  dateStart,
  dateEnd,
  setValue,
  dateStartError,
  dateEndError,
  trigger,
}) => {
  const [exceeds, setExceeds] = useState<boolean>(false);

  useEffect(() => {
    const error = Boolean(dateStart && dateEnd && dateStart > dateEnd);
    setExceeds(error);
  }, [dateStart, dateEnd]);

  return (
    <div className={style.dateWrapper}>
      <div className={localStyle.dateContainer}>
        <div className={localStyle.dateChunk}>
          <DateField
            label="Дата начала"
            startDateProp={dateStart}
            changeDateHandler={async (date) => {
              setValue("date_start", date);

              await trigger("date_start");
            }}
            error={Boolean(exceeds || dateStartError)}
          />
          {Boolean(exceeds || dateStartError) && (
            <div className={style.error}>{dateStartError}</div>
          )}
        </div>
        <span className={localStyle.span}>---</span>
        <div className={localStyle.dateChunk}>
          <DateField
            label="Дата окончания"
            startDateProp={dateEnd}
            changeDateHandler={async (date) => {
              setValue("date_end", date);

              await trigger("date_end");
            }}
            error={Boolean(exceeds || dateEndError)}
          />
          {Boolean(exceeds || dateEndError) && (
            <div className={style.error}>{dateEndError}</div>
          )}
        </div>
      </div>
      {exceeds && (
        <div className={style.error}>
          «Дата начала» должна быть раньше «Даты окончания»
        </div>
      )}
    </div>
  );
};

export default DateRange;

/*
        {(dateStartError || dateEndError) && (
          <>
            <div className={cn(style.error, localStyle.desktop)}>
              {dateStartError && errorMessages.dateStart}
            </div>
            <div />
            <div className={cn(style.error, localStyle.desktop)}>
              {dateEndError && !disableDateEndCheck && errorMessages.dateEnd}
            </div>
          </>
        )}

 */
