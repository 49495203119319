import React, { useState } from "react";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import { MAX_SYMBOLS, getText } from "src/components/pages/User/View/Info/Info";
import { ProjectViewData } from "src/app/feature/ProjectView/createData/createData";
import { KeyboardArrowIcon } from "src/components/newUI/KeyboardArrowIcon/KeyboardArrowIcon";
import styles from "./InfoDescription.module.scss";

type InfoDescriptionProps = {
  description: ProjectViewData["description"];
  name: string;
};

export const InfoDescription = ({
  description,
  name,
}: InfoDescriptionProps) => {
  const [open, setOpen] = useState(false);

  const arrowIconType = description?.length > MAX_SYMBOLS && (
    <IconButton style={{ padding: 0 }} onClick={() => setOpen(!open)}>
      <KeyboardArrowIcon isOpen={open} color={"#838A93"} />
    </IconButton>
  );

  function createMarkup() {
    if (description) return { __html: getText(open, description) };
    return { __html: "Пользователь не предоставил информацию" };
  }

  return (
    <div className={classNames(styles.container, styles.basis)}>
      <h1>{name}</h1>
      <div className={styles.description}>
        <div
          className={styles.container_text}
          dangerouslySetInnerHTML={createMarkup()}
        />
        <div>{arrowIconType}</div>
      </div>
    </div>
  );
};
