import React from "react";
import styles from "./FactChild.module.scss";
import { FactChildGraph } from "./FactChildGraph/FactChildGraph";

export const FactChild = ({ child, today }: any) => {
  const childEntries = Object.entries(child.gantt);

  return (
    <div className={styles.child}>
      <h3>{child.name}</h3>
      {childEntries.map(([name, graph], index) => (
        <FactChildGraph name={name} graph={graph} key={index} today={today} />
      ))}
    </div>
  );
};
