import React, { memo, useCallback, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import cls from "../RemarkCard.module.scss";
import { EyeSVG, GearFillSVG } from "../../../../../../newUI/SVG";
import {
  RefType,
  ThreeDotButton,
} from "../../../../../../newUI/ThreeDotButton/ThreeDotButton";
import { iconBtnSx } from "../../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { getExpertiseCreateIsLoad } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import Modal from "../../../../../../newUI/Modal/Modal";
import { ModalCreateRemarkRow } from "./ModalCreateRemarkRow/ModalCreateRemarkRow";
import { fetchExpertiseRemarks } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoStatus,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseRemarkDelete } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkDelete";
import { fetchExpertiseRemarkStart } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkStart";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import { getCanProcessInfo } from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";

interface RemarkCardHeaderProps {
  editMode: boolean;
  setEditMode: (mode: boolean) => void;
  remarkId: number;
}

export const RemarkCardHeader = memo(
  ({ editMode, setEditMode, remarkId }: RemarkCardHeaderProps) => {
    const dispatch = useAppDispatch();
    const isLoadExpertiseRowCreate = useAppSelector(getExpertiseCreateIsLoad);
    const processId = useAppSelector(getProjectProcessInfoId);
    const threeDotButtonRef = useRef<RefType>(null);
    const status = useAppSelector(getProjectProcessInfoStatus);
    const canProcess = useAppSelector(getCanProcessInfo);
    const [modalCreateRemarkRow, setModalCreateRemarkRow] = useState(false);

    const closeModal = useCallback(() => {
      setModalCreateRemarkRow(false);
    }, []);

    const closeThreeDot = () => {
      if (threeDotButtonRef.current) {
        threeDotButtonRef.current.handleClose();
      }
    };

    function createRemarkRowClick() {
      setModalCreateRemarkRow(true);
      closeThreeDot();
    }

    async function deleteRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите удалить замечание ?"
      );
      if (result) {
        await dispatch(fetchExpertiseRemarkDelete(remarkId));
        closeThreeDot();
        if (processId) {
          dispatch(fetchExpertiseRemarks(processId));
        }
      }
    }

    async function startRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите направить все в работу ?"
      );
      if (result) {
        await dispatch(fetchExpertiseRemarkStart(remarkId));
        closeThreeDot();
        if (processId) {
          dispatch(fetchExpertiseRemarks(processId));
        }
      }
    }

    return (
      <div className={cls.header}>
        {!editMode ? (
          <h1>Чек-лист снятия замечаний</h1>
        ) : (
          <h1>Таблица замечаний</h1>
        )}

        <div className={cls.header_btn}>
          {canProcess?.edit && (
            <>
              {!editMode ? (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(true)}>
                  <GearFillSVG width="22" height="22" />
                </IconButton>
              ) : (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(false)}>
                  <EyeSVG width="24" height="24" />
                </IconButton>
              )}
            </>
          )}
          {editMode ? (
            <>
              <Modal
                active={modalCreateRemarkRow}
                setActive={setModalCreateRemarkRow}
                isLoad={isLoadExpertiseRowCreate}
                activeClass={cls.modal}
              >
                <ModalCreateRemarkRow
                  closeModal={closeModal}
                  remarkId={remarkId}
                />
              </Modal>
              <ThreeDotButton ref={threeDotButtonRef}>
                <MenuItem disableRipple onClick={createRemarkRowClick}>
                  Добавить замечание
                </MenuItem>
                <MenuItem disableRipple onClick={startRemarkClick}>
                  Направить все
                </MenuItem>
                <MenuItem disableRipple onClick={deleteRemarkClick}>
                  Удалить
                </MenuItem>
              </ThreeDotButton>
            </>
          ) : (
            <ThreeDotButton ref={threeDotButtonRef}>
              <TextLink url={`/expertise-remark/pdf?id=${remarkId}`} redirect>
                <MenuItem disableRipple className={cls.link}>
                  Экспорт в PDF
                </MenuItem>
              </TextLink>
              <TextLink url={`/expertise-remark/xlsx?id=${remarkId}`} redirect>
                <MenuItem disableRipple className={cls.link}>
                  Экспорт в Excel
                </MenuItem>
              </TextLink>
            </ThreeDotButton>
          )}
        </div>
      </div>
    );
  }
);
