import React, { FC } from "react";
import style from "./style.module.scss";
import Decor1 from "../../../../media/images/mainPageProcessImages/decor1.png";
import Decor2 from "../../../../media/images/mainPageProcessImages/decor2.png";
import Decor3 from "../../../../media/images/mainPageProcessImages/decor3.png";
import Decor4 from "../../../../media/images/mainPageProcessImages/decor4.png";
import Decor5 from "../../../../media/images/mainPageProcessImages/decor5.png";
import Decor6 from "../../../../media/images/mainPageProcessImages/decor6.png";
import CustomersImg from "../../../../media/images/mainPageProcessImages/customers.png";
import { TProps } from "./Gip";
import BackButton from "../BackButton";

const Customer: FC<TProps> = ({ onBack }) => (
  <>
    <header className={style.header}>
      <BackButton onBack={onBack} />
      <div className={style.left}>
        <img className={style.img} src={CustomersImg} alt="" />
      </div>
      <div>
        <h2>Заказчику</h2>
        <p>Разработка проектной и рабочей документации</p>
      </div>
    </header>
    <main className={style.items}>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor2} alt="" />
        </div>
        <div className={style.right}>
          <h5>Создать проект</h5>
          <p>
            Подготовте Техническое задание к проекту, загрузите исходные данные,
            выберите разделы которые Вам необходимо разработать
          </p>
        </div>
      </div>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor5} alt="" />
        </div>
        <div className={style.right}>
          <h5>Проверенные пользователи</h5>
          <p>
            Пользователи, которые прошли проверку на Платформе, завершили работы
            и получили положительные отзывы средний бал которых более 4.
          </p>
        </div>
      </div>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor4} alt="" />
        </div>
        <div className={style.right}>
          <h5>Управление проектом</h5>
          <p>
            Вы можете управлять проектом, корректировать сроки разработки, сроки
            выдачи заданий, отслеживать критические отклонения на диаграмме
            Ганта по проекту.
          </p>
        </div>
      </div>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor1} alt="" />
        </div>
        <div className={style.right}>
          <h5>Выбор материалов и оборудования</h5>
          <p>
            Запросы поставщикам и производителям. Выбор материалов и
            оборудования прменяемого в проекте. Рассмотрение коммерческих и
            технических предложений от поставщиков и производителей.
          </p>
        </div>
      </div>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor3} alt="" />
        </div>
        <div className={style.right}>
          <h5>Работа с договорами</h5>
          <p>
            Заключение договора с ГИП или Исполнителем работ по Безопасной
            сделке по шаблону и подписание его простой электронной подписью.
          </p>
        </div>
      </div>
      <div className={style.item}>
        <div className={style.left}>
          <img className={style.img} src={Decor6} alt="" />
        </div>
        <div className={style.right}>
          <h5>Облачное хранение документации</h5>
          <p>
            Хранение исходной и разработанной технической документации на
            облачном хранилище. Доступ из любой точки мира.
          </p>
        </div>
      </div>
    </main>
  </>
);
export default Customer;
