import React, { memo, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import moment from "moment/moment";
import { FormWrapper } from "../../../../newUI/FormWrapper/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getProjectProcessMaterialsFirm } from "../../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialsFirm";
import cls from "./ModalCreateMaterial.module.scss";
import { ProjectProcessMaterialsFirmTypes } from "../../../../../app/feature/ProjectProcessView/Material/types/projectProcessMaterialsFirmTypes";
import CustomCheckbox from "../../../../newUI/CustomCheckbox/CustomCheckbox";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { Uploader } from "../../../../features/Uploader/Uploader";
import { config } from "../../../../../app.cofig";
import { fetchProcessMaterialTemplateList } from "../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialTemplateList";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getProjectProcessMaterialTemplateList,
  getProjectProcessMaterialTemplateListIsLoad,
} from "../../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialTemplateList";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import { fetchProcessMaterialCreate } from "../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialCreate";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";

interface ModalCreateMaterialProps {
  closeModal: () => void;
}

type TInputs = {
  uploadFiles: any;
  comment: string;
  date_limit: any;
  template_id: string;
  firmId: string;
  name: string;
};

const schema = Yup.object().shape({
  name: Yup.string()
    .required("Необходимо заполнить «Наименование запроса».")
    .max(200),
  date_limit: Yup.date().required(
    "Необходимо заполнить «Сроки предоставления КП»."
  ),
  uploadFiles: Yup.array()
    .min(1, "Необходимо загрузить хотя бы один файл")
    .required("Необходимо загрузить хотя бы один файл"),
});

export const ModalCreateMaterial = memo(
  ({ closeModal }: ModalCreateMaterialProps) => {
    const dispatch = useAppDispatch();
    const processData = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);
    const firms = useAppSelector(getProjectProcessMaterialsFirm);
    const templateList = useAppSelector(getProjectProcessMaterialTemplateList);
    const isLoadTemplateList = useAppSelector(
      getProjectProcessMaterialTemplateListIsLoad
    );

    const [selectedCompany, setSelectedCompany] = useState<number>(-1);

    const handleCompanyChange = (value: number) => {
      setSelectedCompany(value);
    };

    const {
      register,
      handleSubmit,
      setValue,
      control,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (processData?.projectPart.id) {
        dispatch(fetchProcessMaterialTemplateList(processData.projectPart.id));
      }
    }, []);

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();
      if (dataValues.comment) {
        formData.append("comment", dataValues?.comment);
      }
      dataValues.uploadFiles.forEach((file: BinaryType) => {
        formData.append("uploadFiles[]", file);
      });
      if (processData?.projectPart.id) {
        formData.append("project_part_id", String(processData.projectPart.id));
      }
      formData.append(
        "date_limit",
        moment(dataValues.date_limit, "DD.MM.YYYY HH:mm:ss Z").format(
          "DD.MM.YYYY"
        )
      );
      if (dataValues.template_id) {
        formData.append("template_id", String(dataValues.template_id));
      }
      if (selectedCompany === -1) {
        firms?.forEach((firm) => formData.append("firmId", String(firm.id)));
      }
      if (selectedCompany !== -1) {
        formData.append("firmId", String(selectedCompany));
      }
      formData.append("name", String(dataValues.name));
      if (processId) {
        await dispatch(fetchProcessMaterialCreate({ processId, formData }));
        closeModal();
      }
    };

    const renderCheckbox = (firm: ProjectProcessMaterialsFirmTypes) => {
      return (
        <div key={firm.id}>
          <label className={cls.form_label}>
            <CustomCheckbox
              editing
              value={firm.id}
              checked={selectedCompany === firm.id}
              onChange={(event) => handleCompanyChange(+event.target.value)}
              type={"radio"}
            />
            {firm?.photo ? (
              <img src={`${config.localDomain}${firm.photo}`} />
            ) : (
              <p>{firm?.title}</p>
            )}
          </label>
        </div>
      );
    };

    if (isLoadTemplateList) {
      return (
        <div style={{ width: "40vw" }}>
          <ModalPreloader />
        </div>
      );
    }

    return (
      <FormWrapper
        title={"Запросить предложение оборудования"}
        submitHandler={handleSubmit(formSubmitHandler)}
      >
        <div className={cls.checkBoxWrapper}>
          {firms?.map((firm) => renderCheckbox(firm))}
        </div>
        <TextField
          {...textFieldSX}
          label="Наименование запроса"
          {...register("name")}
          defaultValue={""}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("comment")}
          defaultValue={""}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment ? errors.comment.message : null}
        />
        <div className={cls.date}>
          <Controller
            control={control}
            name="date_limit"
            render={() => (
              <DateField
                label="Сроки представления КП"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
        {templateList && templateList.length > 0 && (
          <FormControl {...textFieldSX} className={cls.form_tasks}>
            <InputLabel id="project_id">Шаблон опросного листа</InputLabel>
            <Select
              {...register("template_id")}
              labelId="template_id"
              label="Шаблон опросного листа"
            >
              {templateList.map((template) => (
                <MenuItem key={template.id} sx={menuItemSx} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div className={cls.uploader}>
          <Uploader
            canBeEdited
            autoUpload={false}
            identifier={null}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              setValue("uploadFiles", data);
            }}
          />
          {errors.uploadFiles && (
            <div className={cls.error}>
              <p>{errors.uploadFiles.message}</p>
            </div>
          )}
        </div>
        <CustomButton
          className={cls.submit}
          background={palette.blue}
          width={160}
          type="submit"
        >
          Опубликовать
        </CustomButton>
      </FormWrapper>
    );
  }
);
