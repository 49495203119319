import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import {
  formFullUserName,
  stringDateToDDMMYYYY,
} from "src/FSD/shared/lib/helpers";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";

export const useRemoveExecutor = (removed: ApiCommonResponses.Removed) => {
  const isTablet = useIsMobile(1200);
  const isMobile = useIsMobile(600);

  const fullName = formFullUserName(removed.executor);

  const jobDuration = ((removed.executor.job_duration ?? 0) / 365).toFixed(0);

  const dateStart = stringDateToDDMMYYYY(removed.date_create);

  const dateEnd = stringDateToDDMMYYYY(removed.date_complete);

  return {
    isTablet,
    isMobile,
    fullName,
    jobDuration,
    dateStart,
    dateEnd,
  } as const;
};
