import React, { ReactNode } from "react";
import { LoadDataIndexPage } from "../../../lifeСycle/LoadDataIndexPage/LoadDataIndexPage";
import { Footer, Header } from "../../../features";
import { HeaderNew } from "../../../features/HeaderNew/HeaderNew";

type TIndexPageTemplate = {
  children: ReactNode;
  className?: string;
};

export const IndexPageTemplate: React.FC<TIndexPageTemplate> = ({
  children,
  className,
}) => {
  return (
    <LoadDataIndexPage>
      <Header />
      {children}
      <Footer variant={className} />
    </LoadDataIndexPage>
  );
};

export const IndexPageTemplateNew: React.FC<TIndexPageTemplate> = ({
  children,
  className,
}) => {
  return (
    <LoadDataIndexPage>
      <HeaderNew />
      {children}
      <Footer variant={className} />
    </LoadDataIndexPage>
  );
};
