import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import classNames from "classnames";
import { useSelector } from "react-redux";
import style from "./Document.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import { config } from "../../../../../app.cofig";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { getProjectDocument } from "../../../../../app/feature/ProjectView/DocumentReducer/projectDocuments";
import { trimPrice, trimRubPennies } from "../../../../../utils/trimNumber";
import { SecureIcon } from "../../../../newUI/SVG";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { ProjectDocumentReturn } from "../../../../../types/api/responseTypes/apiDocumentsType";

type DocumentCardProps = {
  data: ProjectDocumentReturn;
};

const sxTrue = { color: "#3BAF6A", fontSize: 12 };
const sxFalse = { color: "#E8A037", fontSize: 12 };

function iconType(type: boolean) {
  if (type) return <FiberManualRecordIcon sx={sxTrue} />;
  return <FiberManualRecordIcon sx={sxFalse} />;
}

export const DocumentCard = ({ data }: DocumentCardProps) => {
  const { isAllToggle } = useSelector(getProjectDocument);
  const [toogleDiv, setToogleDiv] = useState(false);
  const isMobile = useIsMobile(651);

  useEffect(() => {
    setToogleDiv(isAllToggle);
  }, [isAllToggle]);

  const handleNavigate = (id: string | number, e: any) => {
    e.stopPropagation();
    window.open(`${config.localDomain}/document/view?id=${id}`, "_blank");
  };

  const { treeByObjects } = data;

  const isMobileLink = !isMobile ? (
    <span className={style.card_document}>{data.name}</span>
  ) : (
    <TextLink
      redirect
      url={`${config.localDomain}/document/view?id=${data.id}`}
      target={"_blank"}
    >
      <span className={style.card_document}>{data.name}</span>
    </TextLink>
  );

  const verifiedUserIcon = data.treeByObjects.sums.secure > 0 && !isMobile && (
    <span className={style.card_secure}>
      {" "}
      <SecureIcon />
    </span>
  );

  const arrowIconType = <ToggleAccordion toggle={toogleDiv} />;

  const mapDocs =
    toogleDiv &&
    treeByObjects.docs.map((el) => (
      <React.Fragment key={el.id}>
        <div className={style.docs}>
          <div className={style.card_signDocs}>
            <span>{iconType(el.sign.author)}</span>
            <span>{iconType(el.sign.signer)}</span>
          </div>
          <div>{`${el.name} № ${el.num || ""} от ${el.date_create}`}</div>
        </div>
      </React.Fragment>
    ));

  const projectProcessesMap =
    toogleDiv &&
    treeByObjects.projectProcesses.map((el) => (
      <React.Fragment key={el.id}>
        <div className={classNames(style.parent, style.margin)}>
          <div className={style.parent_code} title={el.project}>
            {el.secure && (
              <div className={style.parent_code_svg}>
                <SecureIcon width="17" heigth="20" />
              </div>
            )}
            <p>{el.code}</p>
          </div>
          <div>{el.docs.length}</div>
          <div className={classNames(style.hidden, el.danger && style.danger)}>
            {trimRubPennies(el.allValue)}
          </div>
          <div className={classNames(style.hidden, el.danger && style.danger)}>
            {trimRubPennies(+el.inputValue)}
          </div>
          <div className={style.hidden}>{trimPrice(el.pay1)}</div>
          <div className={style.hidden}>{trimPrice(el.pay2)}</div>
          <div className={style.hidden}>{trimPrice(el.pay3)}</div>
        </div>
        {el.docs.map((doc) => (
          <div className={style.docs} key={doc.id}>
            <div className={style.card_signDocs}>
              <span>{iconType(doc.sign.author)}</span>
              <span>{iconType(doc.sign.signer)}</span>
            </div>
            <TextLink redirect url={`/document/view?id=${doc.id}`}>
              <div className={style.docs_link}>{`${doc.type} № ${
                doc.num || ""
              } от ${doc.date_create}`}</div>
            </TextLink>
            <div />
            <div />
            <div />
            <div className={style.hidden}>{trimPrice(doc?.pay1 || "0")}</div>
            <div className={style.hidden}>{trimPrice(doc?.pay2 || "0")}</div>
            <div className={style.hidden}>{trimPrice(doc?.pay3 || "0")}</div>
          </div>
        ))}
      </React.Fragment>
    ));

  return (
    <>
      <div className={style.card} onClick={() => setToogleDiv(!toogleDiv)}>
        <div className={style.card_wrap}>
          <div className={style.card_sign}>
            <span>{iconType(treeByObjects.sign.author)}</span>
            <span>{iconType(treeByObjects.sign.signer)}</span>
          </div>
          <div className={style.card_container}>
            <div className={style.card_name}>
              {isMobileLink}
              {verifiedUserIcon}
            </div>
            <span className={style.card_author}>
              <p>{treeByObjects.executor},</p>
              <p>{treeByObjects.executorType}</p>
            </span>
          </div>
        </div>
        <div className={style.card_secure_mobile}>
          {data.treeByObjects.sums.secure > 0 && isMobile && (
            <span className={style.card_secure_icon}>
              {" "}
              <SecureIcon />
            </span>
          )}
          <div className={style.btn_container}>
            <div className={style.card_btn}>
              <CustomButton
                width={120}
                onClick={(e: any) => handleNavigate(data.id, e)}
                background={palette.green}
              >
                Открыть
              </CustomButton>
            </div>
            {arrowIconType}
          </div>
        </div>
      </div>
      <div className={style.parent}>
        <div>Наименование</div>
        <div>Документы</div>
        <div className={style.hidden}>Всего</div>
        <div className={style.hidden}>Внесено</div>
        <div className={style.hidden}>1-я оплата</div>
        <div className={style.hidden}>2-я оплата</div>
        <div className={style.hidden}>3-я оплата</div>
      </div>
      <div className={style.parent}>
        <div>{treeByObjects.sums.count}</div>
        <div className={style.parent_document}>
          <div>
            <FiberManualRecordIcon sx={sxTrue} />
            <span>{treeByObjects.sums.pairSignCountList.PAIR_GREEN}</span>
          </div>
          <div>
            <FiberManualRecordIcon sx={sxFalse} />
            <span>{treeByObjects.sums.pairSignCountList.PAIR_ORANGE}</span>
          </div>
        </div>
        <div
          className={classNames(
            style.hidden,
            treeByObjects.sums.danger && style.danger
          )}
        >
          {trimRubPennies(treeByObjects.sums.allValue)}
        </div>
        <div
          className={classNames(
            style.hidden,
            treeByObjects.sums.danger && style.danger
          )}
        >
          {trimRubPennies(treeByObjects.sums.inputValue)}
        </div>
        <div className={style.hidden}>{trimPrice(treeByObjects.sums.pay1)}</div>
        <div className={style.hidden}>{trimPrice(treeByObjects.sums.pay2)}</div>
        <div className={style.hidden}>{trimPrice(treeByObjects.sums.pay3)}</div>
      </div>
      {mapDocs}
      {projectProcessesMap}
    </>
  );
};
