import React, { memo } from "react";
import { PendingIcon, SuccessIcon, WarningIcon } from "../../../SVG";

export const StatusIconType = memo(({ status }: any) => {
  const defaultStatus = status !== "Принято" && status !== "Отказано";
  return (
    <>
      {status === "Принято" && <SuccessIcon />}
      {status === "Отказано" && <WarningIcon />}
      {defaultStatus && <PendingIcon />}
    </>
  );
});
