import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import {
  filesAreRequired,
  messages,
  stringMinMaxLengthRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const executeProcessSchema = Yup.object().shape({
  files: Yup.array().when("status", {
    is: (status) => status === Statuses.ACCEPT,
    then: filesAreRequired().test(
      "visibilityCheckFile",
      messages.filesFieldActually,
      (value: any) => value && value.some((field: TFile) => field.visibility)
    ),
  }),
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.IS_REJECT,
    then: stringMinMaxLengthRequired("Комментарий", 15, 2000),
  }),
});
