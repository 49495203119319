import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPostMultiData,
  httpRequestPut,
} from "../../api";
import { config } from "../../../../../app.cofig";

export const ApiExpertiseRemarks = (
  processId: number,
  partGroupId?: number | string,
  code?: string,
  status?: number | string
): Promise<Response> => {
  const filter = `&RemarkSearch[partGroupId]=${
    partGroupId || ""
  }&RemarkSearch[projectPartIds]=${code || ""}&RemarkSearch[status]=${
    status || ""
  }`;
  // получить замечания
  return httpRequestGet(
    `${config.localDomain}/v1/expertise/remark/get-remarks?processId=${processId}${filter}`
  );
};

export const ApiExpertiseRemarkRowStop = (rowId: number): Promise<Response> => {
  // вернуть в черновик
  return httpRequestPost(
    `${config.localDomain}/v1/expertise/remark-row/stop?id=${rowId}`
  );
};

export const ApiExpertiseRemarkRowStart = (
  rowId: number
): Promise<Response> => {
  // направить в работу
  return httpRequestPost(
    `${config.localDomain}/v1/expertise/remark-row/start?id=${rowId}`
  );
};

export const ApiExpertiseRemarkRowDelete = (
  groupId: number
): Promise<Response> => {
  // удалить замечание
  return httpRequestDelete(
    `${config.localDomain}/v1/expertise/remark-row/delete?id=${groupId}`
  );
};

export const ApiExpertiseProjectList = (
  processId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-projects?processId=${processId}`
  );
};

export const ApiExpertiseProcesses = (
  processId: number,
  projectId: number
): Promise<Response> => {
  // получить разделы
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-processes?processId=${processId}&projectId=${projectId}`
  );
};

export const ApiExpertiseRemarkRowUpdate = (
  rowId: number,
  data: any
): Promise<Response> => {
  // направить в работу
  return httpRequestPut(
    `${config.localDomain}/v1/expertise/remark-row/update?id=${rowId}`,
    {},
    data
  );
};

export const ApiExpertiseRemarkRowCreate = (
  remarkId: number,
  data: any
): Promise<Response> => {
  // добавить замечание
  return httpRequestPost(
    `${config.localDomain}/v1/expertise/remark-row/create?remarkId=${remarkId}`,
    {},
    data
  );
};

export const ApiExpertisePartGroup = (): Promise<Response> => {
  // получить разделы
  return httpRequestGet(`${config.localDomain}/v1/part-group/index`);
};

export const ApiExpertiseProjectPartByGroup = (
  projectId: number,
  groupId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/part-group/get-project-part-by-group?projectId=${projectId}&id=${groupId}`
  );
};

export const ApiCreateRemark = (
  processId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/expertise/remark/create?processId=${processId}`,
    {},
    formData
  );
};

export const ApiExpertiseRemarkDelete = (
  remarkId: number
): Promise<Response> => {
  // удалить
  return httpRequestDelete(
    `${config.localDomain}/v1/expertise/remark/delete?id=${remarkId}`
  );
};

export const ApiExpertiseRemarkStart = (
  remarkId: number
): Promise<Response> => {
  // направить в работу все
  return httpRequestPost(
    `${config.localDomain}/v1/expertise/remark/start?id=${remarkId}`
  );
};

export const ApiExpertiseCommentView = (rowId: number): Promise<Response> => {
  // просмотр комментария
  return httpRequestGet(
    `${config.localDomain}/v1/expertise/comment/index?CommentSearch[remark_row_id]=${rowId}`
  );
};

export const ApiExpertiseCreateComment = (
  rowId: number,
  data: { text: string }
): Promise<Response> => {
  // создать комментарий
  return httpRequestPost(
    `${config.localDomain}/v1/expertise/comment/create?remarkRowId=${rowId}`,
    {},
    data
  );
};
