import React, { ReactNode } from "react";
import classNames from "classnames";
import cls from "./FormWrapper.module.scss";

interface FormWrapperProps {
  submitHandler?: () => void;
  title?: string;
  children: ReactNode;
  classname?: string;
}
export const FormWrapper = ({
  submitHandler,
  title,
  children,
  classname,
}: FormWrapperProps) => {
  return (
    <form onSubmit={submitHandler} className={classNames(cls.form, classname)}>
      <h1>{title}</h1>
      {children}
    </form>
  );
};
