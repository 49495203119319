import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../app/services/api/requestHandler";
import { ApiGetPartGroup } from "./queries";
import { ThunkArg } from "../../../lib/types/thunkArg";

export const getPartGroup = createAsyncThunk(
  "partGroup/get",
  async ({ events }: ThunkArg<null>) => {
    events.onPending?.();

    await request(
      ApiGetPartGroup(),
      (data) => {
        events.onFulfilled?.(data);
      },
      () => (err) => {
        events.onRejected?.(err);
      }
    )();
  }
);
