import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList/FilesList";
import { useCheckExpertiseRemark } from "../lib/hooks/useCheckExpertiseRemark";

const defaultValues = {
  status: null,
};

type Props = {
  taskId: number;
  header: string;
};

export const CheckExpertiseRemarkForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    setValue,
    isRejecting,
    isAccepting,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useCheckExpertiseRemark({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>

      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <FilesList fileLinks={taskData!.parent!.files} label="Файлы" />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isRejecting}
              type="submit"
              color="error"
              variant="outlined"
              width={120}
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
            >
              Отказать
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isAccepting}
              type="submit"
              width={120}
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
