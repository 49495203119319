import React, { FC, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import style from "./style.module.scss";
import Firm from "./Firm";
import { useAppSelector } from "../../../../../app/store";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";
import { TUserType } from "../../../../../app/feature/dashboard/header";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export type TFirm = Omit<TUserType, "subtitle">;

type UserFirmsProps = {
  currentIndex: number;
  setCurrentIndex: SetState<number>;
  firms: TFirm[];
};

const UserFirms: FC<UserFirmsProps> = ({
  firms,
  currentIndex,
  setCurrentIndex,
}) => {
  const [chunkSize, setChunkSize] = useState<number>(4);
  const { userType } = useAppSelector(getDashboardDestinations);

  const isMobile = useIsMobile(1300);

  const disabledForward = currentIndex + chunkSize >= firms.length;
  const disabledBackward = currentIndex === 0;

  const firmsGroupped = firms
    .slice(currentIndex, currentIndex + chunkSize)
    .reverse();

  const handleBackward = () => {
    setCurrentIndex(currentIndex - 2);
  };

  const handleForward = () => {
    setCurrentIndex(currentIndex + 2);
  };

  useEffect(() => {
    setChunkSize(isMobile ? 3 : 4);
  }, [isMobile]);

  return (
    <div className={style.userFirms}>
      <IconButton onClick={handleForward} disabled={disabledForward}>
        <ArrowForwardIos />
      </IconButton>
      {firmsGroupped.map((firm) => (
        <Firm key={firm.id} isSelected={firm.id === userType} firm={firm} />
      ))}
      <IconButton onClick={handleBackward} disabled={disabledBackward}>
        <ArrowBackIos />
      </IconButton>
    </div>
  );
};

export default UserFirms;
