import React from "react";
import { useSelector } from "react-redux";
import { selectorEEErrorPopupShow } from "../../app/feature/eventEmitter/error/error";
import { ErrorPopup } from "./App/ErrorPopup/ErrorPopup";
import { JoinUserFirm } from "./UserEvents/joinUserFirm";
import { JoinUser } from "./UserEvents/JoinUser";
import { selectorEEJoinUserFirm } from "../../app/feature/eventEmitter/user/eventJoinFirm";
import { selectorEEJoinUserProject } from "../../app/feature/eventEmitter/user/eventJoinProject";
import { JoinUserProject } from "./UserEvents/joinUserProject";
import { selectorEEShowUserAgreement } from "../../app/feature/eventEmitter/registration/eventShowUserAgreement";
import { ShowUserAgreement } from "./RegistrationEvents/ShowUserAgreement/ShowUserAgreement";
import { selectorEEShowUserPolicy } from "../../app/feature/eventEmitter/registration/eventShowUserPolicy";
import { ShowPrivacyPolicy } from "./RegistrationEvents/ShowPrivacyPolicy/ShowPrivacyPolicy";
import { SetUserRole } from "./OrganisationEvents/SetUserRole";
import { EditTask } from "./OrganisationEvents/EditTask";
import { EditUserJoin } from "./OrganisationEvents/EditUserJoin";
import { EditBankAccount } from "./OrganisationEvents/EditBankAccount";
import { EditRequestJoin } from "./OrganisationEvents/EditRequestJoin";
import { selectorChangeUserRole } from "../../app/feature/eventEmitter/organisation/eventShowUserRole";
import { selectorEditOrganisationTask } from "../../app/feature/eventEmitter/organisation/eventEditOrganisationTask";
import { selectorEditBankAccount } from "../../app/feature/eventEmitter/organisation/eventEditBankAccount";
import { selectorSendMoney } from "../../app/feature/eventEmitter/organisation/eventSendMoney";
import { SendMoney } from "./OrganisationEvents/SendMoney";
import { selectorUserJoin } from "../../app/feature/eventEmitter/organisation/eventUserJoin";
import { selectorEditRequestJoin } from "../../app/feature/eventEmitter/organisation/eventEditRequestJoin";
import { selectorEventUserAtUserPage } from "../../app/feature/eventEmitter/user/eventUserJoin";
import { selectorEventCreateTeam } from "../../app/feature/eventEmitter/organisation/eventCreateTeam";
import { CreateTeam } from "./OrganisationEvents/CreateTeam";
import { ManageTeammate } from "./OrganisationEvents/ManageTeammate";
import { selectorManageTeammate } from "../../app/feature/eventEmitter/organisation/eventManageTeammate";
import { selectorEventUserInviteToTeam } from "../../app/feature/eventEmitter/user/eventInviteToTeam";
import { InviteToTeam } from "./UserEvents/InviteToTeam";

export const EventEmitterLayer: React.FC<unknown> = ({ children }) => {
  const eventErrorPopup = useSelector(selectorEEErrorPopupShow);
  const eventJoinFirm = useSelector(selectorEEJoinUserFirm);
  const eventJoinProject = useSelector(selectorEEJoinUserProject);
  const eventShowAgreement = useSelector(selectorEEShowUserAgreement);
  const eventShowPolicy = useSelector(selectorEEShowUserPolicy);
  const eventUserRole = useSelector(selectorChangeUserRole);
  const eventEditOrganisationTask = useSelector(selectorEditOrganisationTask);
  const eventEditBankAccount = useSelector(selectorEditBankAccount);
  const eventSendMoney = useSelector(selectorSendMoney);
  const eventUserJoin = useSelector(selectorUserJoin);
  const eventRequestJoin = useSelector(selectorEditRequestJoin);
  const eventUserAtUserPage = useSelector(selectorEventUserAtUserPage);
  const eventCreateTeam = useSelector(selectorEventCreateTeam);
  const eventManageTeammate = useSelector(selectorManageTeammate);
  const eventUserInviteToTeam = useSelector(selectorEventUserInviteToTeam);

  return (
    <>
      {eventJoinFirm && <JoinUserFirm />}
      {eventJoinProject && <JoinUserProject />}

      {eventShowAgreement && <ShowUserAgreement />}
      {eventShowPolicy && <ShowPrivacyPolicy />}

      {/* слушатели на ошибки */}
      {eventErrorPopup && <ErrorPopup />}

      {/* обновить роль пользователя */}
      {eventUserRole && <SetUserRole />}
      {/* обновить задачу в организации */}
      {eventEditOrganisationTask && <EditTask />}
      {/* обновить банковский счет в организации */}
      {eventEditBankAccount && <EditBankAccount />}
      {/* перевести деньги сотруднику */}
      {eventSendMoney && <SendMoney />}
      {/* рассмотреть запрос на вступление сотрудника */}
      {eventUserJoin && <EditUserJoin />}
      {/* запрос на вступление в организацию */}
      {eventRequestJoin && <EditRequestJoin />}

      {eventUserAtUserPage && <JoinUser />}

      {eventCreateTeam && <CreateTeam />}

      {eventManageTeammate && <ManageTeammate />}

      {eventUserInviteToTeam && <InviteToTeam />}

      {/* дочерние компоненты */}
      {children}
    </>
  );
};
