export const getFullDomainPath = (): string => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

export const redirectTo = (url: string) => {
  window.location.href = url;
};

export const redirectToMainPage = () =>
  redirectTo(`${getFullDomainPath()}/pirs.online`);

export const redirectToIndexPage = () => {
  redirectTo(`${getFullDomainPath()}`);
};
