import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { request } from "src/app/services/api/requestHandler";
import { ApiChangeProcess } from "src/app/services/api/project/project";
import { palette } from "src/styles/restyle";
import style from "./style.module.scss";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import CustomCheckbox from "../../../newUI/CustomCheckbox/CustomCheckbox";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { UnsavedType } from "../../../pages/Project/types";

export type NodeType = {
  id?: number;
  name: string;
  children: string[];
  ancestor: number;
  disabled?: boolean;
  count?: boolean;
  num: string;
};

type TreeType = { [key: string]: NodeType };

type ReversedTreeType = { [key: number]: string };

export const getReversedTree = (tree: TreeType): ReversedTreeType => {
  const reversed: ReversedTreeType = {};

  Object.keys(tree).forEach((node: string) => {
    const id = tree[node]?.id;
    if (id) {
      reversed[id] = node;
    }
  });

  return reversed;
};

export const getTreeIdByNum = (num: string, tree: TreeType) =>
  tree[num]?.id ?? 0;

const sx = {
  color: "white",
};

export const expertiseNums: string[] = ["7.3", "7.4", "7.5"];

export type SetState<T> = Dispatch<SetStateAction<T>>;

export type SectionEditingProps = {
  tree: TreeType;
  setTree?: SetState<TreeType>;
  ticked: number[];
  setTicked: SetState<number[]>;
  openTabs: string[];
  setOpenTabs: SetState<string[]>;
  projectId: number;
  disabledTicked: number[];
  setDisabledTicked: SetState<number[]>;
  unsaved: UnsavedType;
};

export type ResetType = {
  checked: string[];
  disabled: string[];
};

const Tree: React.FC<{
  tree: TreeType;
  setTree?: SetState<TreeType>;
  node: string;
  offset: number;
  ticked: number[];
  setTicked: SetState<number[]>;
  openTabs: string[];
  setOpenTabs: SetState<string[]>;
}> = ({
  tree,
  setTree,
  node,
  offset,
  ticked,
  setTicked,
  openTabs,
  setOpenTabs,
}) => {
  const isMobile = useIsMobile(800);

  const isOpen = openTabs.includes(node);

  const currentNode: NodeType = tree[node];

  const reversedTree = useMemo(() => getReversedTree(tree), [tree]);

  const handleShowSubtree = useCallback(() => {
    setOpenTabs(
      isOpen
        ? openTabs.filter((item: string) => item !== node)
        : [...openTabs, node]
    );
  }, [node, openTabs]);

  const handleChange = () => {
    if (!currentNode?.id || currentNode?.disabled) {
      return;
    }

    const { id } = currentNode;
    const isTicked = ticked.includes(id);

    let filteredTicked = isTicked
      ? ticked.filter((item: number) => item !== id)
      : [...ticked, id];

    if (expertiseNums.includes(reversedTree[id])) {
      const expertiseDisabled = expertiseNums.reduce(
        (result, item) => Boolean(tree[item]?.disabled) || result,
        false
      );

      if (expertiseDisabled) {
        return;
      }

      filteredTicked = filteredTicked.filter(
        (item: number) =>
          !expertiseNums.includes(reversedTree[item]) || item === id
      );
    }

    setTicked(filteredTicked);
  };

  if (currentNode?.id) {
    const { id, num } = currentNode;
    const isTicked = ticked.includes(id);

    const inactive =
      expertiseNums.includes(num) &&
      expertiseNums.some((num: string) =>
        ticked.includes(getTreeIdByNum(num, tree))
      ) &&
      !isTicked;

    return (
      <>
        <label
          className={style.ticker}
          style={{ paddingLeft: `${offset * 25}px` }}
        >
          <CustomCheckbox
            editing
            onChange={handleChange}
            checked={isTicked}
            grey={!isTicked}
            inactive={inactive}
          />
          <p>{currentNode.name}</p>
        </label>
        <br />
      </>
    );
  }

  return (
    <>
      <label
        className={style.ticker}
        style={{ paddingLeft: `${offset * 25}px` }}
      >
        <CustomCheckbox
          editing
          checked={false}
          onChange={handleShowSubtree}
          showSign
          grey={!currentNode.count}
          sign={!isOpen}
        />
        <p style={{ color: "white" }}>{currentNode.name}</p>
      </label>
      <br />
      {isOpen &&
        currentNode.children.map((item: string) => (
          <Tree
            key={item}
            tree={tree}
            setTree={setTree}
            node={item}
            offset={offset + +!isMobile}
            ticked={ticked}
            setTicked={setTicked}
            openTabs={openTabs}
            setOpenTabs={setOpenTabs}
          />
        ))}
    </>
  );
};

export const SectionEditing: React.FC<SectionEditingProps> = ({
  tree,
  setTree,
  ticked,
  setTicked,
  openTabs,
  setOpenTabs,
  projectId,
  disabledTicked,
  setDisabledTicked,
  unsaved,
}) => {
  const [disabled, setDisabled] = useState<boolean>();
  const [showButton, setShowButton] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();

  const unsavedRef = useRef<boolean>();

  const saveHandler = async () => {
    setDisabled(true);
    await request(
      ApiChangeProcess(projectId, ticked),
      () => {
        enqueueSnackbar("Изменения сохранены", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setDisabledTicked(ticked);
      },
      () => () => {
        enqueueSnackbar("Возникла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
    setDisabled(false);
  };

  const resetHandler = () => {
    setTicked(disabledTicked);
    setShowButton(false);
  };

  const handleShowButton = () => setShowButton(true);
  const handleHideButton = () => setShowButton(false);

  useEffect(() => {
    unsavedRef.current = unsaved.sections;
  }, [unsaved]);

  useEffect(
    () => () => {
      if (unsavedRef.current) {
        setTicked(disabledTicked);
      }
    },
    []
  );

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <h1>Управление разделами</h1>
        <div className={style.desktopControls} onMouseLeave={handleHideButton}>
          <CustomButton
            background={palette.green}
            width={160}
            onClick={saveHandler}
            disabled={disabled}
          >
            Сохранить
          </CustomButton>
          <IconButton onClick={handleShowButton} sx={sx}>
            <MoreVertIcon />
          </IconButton>
          {showButton && (
            <button onClick={resetHandler} className={style.reset}>
              Сбросить
            </button>
          )}
        </div>
      </div>
      <div className={style.treePart}>
        {tree["0"]?.children.map((item: string) => (
          <Tree
            key={item}
            tree={tree}
            setTree={setTree}
            node={item}
            offset={0}
            ticked={ticked}
            setTicked={setTicked}
            openTabs={openTabs}
            setOpenTabs={setOpenTabs}
          />
        ))}
      </div>
      <div className={style.mobileControls}>
        <CustomButton
          background={palette.green}
          onClick={saveHandler}
          disabled={disabled}
        >
          Сохранить
        </CustomButton>
        <CustomButton background={palette.red} onClick={resetHandler}>
          Сбросить
        </CustomButton>
      </div>
    </div>
  );
};
