const formDate = (work: any) => {
  const start = work.date_create.substr(0, 10);
  const finish = (work.date_complete ?? work.date_limit ?? "").substr(0, 10);

  return [start, finish].join(" • ");
};

const processAdvancedTask = (payload: any) => {
  return payload.map((work: any) => {
    return {
      id: work.id,
      processId: work.workflow_data.processId,
      workflow: work.workflow_data,
      name: work.name,
      date: formDate(work),
      status: work.status.key,
      executorName: [
        `${work.executor.surname} ${work.executor.name} ${work.executor.patronymic}`,
      ],
      price: null,
      secure: null,
      executorNds: null,
      countAccesses: null,
      count: null,
    };
  });
};

export const createWorkTasks = (payload: any) => {
  const { task, taskCheck, customerAlignTasks } = payload;

  const documentation = processAdvancedTask(Number(task) === 0 ? [] : [task]);
  const checks = processAdvancedTask(
    Number(taskCheck) === 0 ? [] : [taskCheck]
  );
  const solutions = processAdvancedTask(customerAlignTasks);
  return { documentation, checks, solutions };
};
