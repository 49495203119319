import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  fetchProcessMaterial,
  fetchProcessMaterialFirms,
} from "../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterial";
import { getProjectProcessMaterial } from "../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterial";
import { fetchProjectProcessView } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { MaterialCard } from "./MaterialCard/MaterialCard";
import cls from "./Materials.module.scss";
import { getProjectProcessMaterialIsLoad } from "../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialIsLoad";
import {
  getProjectProcessMaterialsFirmForHeader,
  getProjectProcessMaterialsFirmIsLoad,
} from "../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialsFirm";
import { SkeletonProjectProcessMaterialsTab } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { HeaderCard } from "./HeaderCard/HeaderCard";

export const Materials = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useAppSelector(getProjectProcessInfoId);
  const materials = useAppSelector(getProjectProcessMaterial);
  const isLoad = useAppSelector(getProjectProcessMaterialIsLoad);
  const isLoadFirms = useAppSelector(getProjectProcessMaterialsFirmIsLoad);
  const firmsForHeader = useAppSelector(
    getProjectProcessMaterialsFirmForHeader
  );

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProcessMaterial(infoId));
    }
  }, [infoId]);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProcessMaterialFirms(infoId));
    }
  }, [infoId]);

  if (isLoad || isLoadFirms) {
    return <SkeletonProjectProcessMaterialsTab />;
  }

  return (
    <div className={cls.wrapper}>
      {firmsForHeader &&
        firmsForHeader.map((firm) => <HeaderCard firm={firm} />)}
      {materials &&
        materials.map((material) => (
          <MaterialCard key={material.id} {...material} />
        ))}
    </div>
  );
});
