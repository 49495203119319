import React from "react";
import { Skeleton as MUISkeleton } from "@mui/material";
import style from "./style.module.scss";
import { Wrapper } from "../../../../UI/templates";

export const Skeleton = () => {
  return (
    <Wrapper>
      <div className={style.contentWrapper}>
        <MUISkeleton variant={"rectangular"} className={style.imageContainer} />
        <MUISkeleton variant={"rectangular"} className={style.changeFile} />
        <MUISkeleton variant={"rectangular"} className={style.input} />
        <MUISkeleton variant={"rectangular"} className={style.input} />
        <MUISkeleton variant={"rectangular"} className={style.input} />
        <MUISkeleton variant={"rectangular"} className={style.editor} />
      </div>
    </Wrapper>
  );
};
