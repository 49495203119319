import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectHistory = (url: string): Promise<Response> => {
  // История по проекту
  return httpRequestGet(`${config.localDomain}/v1/project/get-logs${url}`);
};

export const ApiProjectGetLogTypes = (): Promise<Response> => {
  // События для Таб история
  return httpRequestGet(`${config.localDomain}/v1/project/get-log-types `);
};
