export default {
  SET_PROJECT_CONTROLS_DATA: "SET_PROJECT_CONTROLS_DATA",

  SET_PROCESS_TOGGLE_OPEN: "SET_PROCESS_TOGGLE_OPEN",

  SET_PROCESS_CHECKED: "SET_PROCESS_CHECKED",
  SET_PROCESS_UNCHECKED: "SET_PROCESS_UNCHECKED",

  SET_OPENED_SECTION_FROM_STORAGE: "SET_OPENED_SECTION_FROM_STORAGE",

  SET_INITIAL_CHECKED: "SET_INITIAL_CHECKED",
  SET_INITIAL_EXPERTISE_TYPE: "SET_INITIAL_EXPERTISE_TYPE",

  SET_CLEAR_CONTROLS_DATA: "SET_CLEAR_CONTROLS_DATA",
  SET_CLEAR_CHECKED: "SET_CLEAR_CHECKED",
};
