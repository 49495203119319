import { RootState } from "../../../../store";

export const getProjectProcessInitialDataProcesses = (state: RootState) =>
  state.projectProcessView.initialData.process;

export const getProjectProcessInitialDataSourceData = (state: RootState) =>
  state.projectProcessView.initialData.sourceData;

export const getProjectProcessInitialDataProjects = (state: RootState) =>
  state.projectProcessView.initialData.projects;

export const getProjectProcessInitialDataIsLoad = (state: RootState) =>
  state.projectProcessView.initialData.isLoad;
