import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export type TForm = { [key: string]: any };

export type TUnsaved = {
  inbound: TForm;
  outbound: TForm;
};

const initialState: TUnsaved = {
  inbound: {},
  outbound: {},
};

const unsavedSlice = createSlice({
  name: "unsaved",
  initialState,
  reducers: {
    setInboundForm(state, action) {
      state.inbound = action.payload;
    },
    setOutboundForm(state, action) {
      state.outbound = action.payload;
    },
    clearForms(state) {
      state.inbound = {};
      state.outbound = {};
    },
  },
});

export const getForms = (state: RootState) => state.unsaved;

export const { clearForms, setInboundForm, setOutboundForm } =
  unsavedSlice.actions;
export default unsavedSlice.reducer;
