import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import { getExecutors, saveChosenTasks } from "./thunks";
import { createExecutors } from "./utils";

export type TApplication = {
  ndsText: string;
  price: number;
  dateRange: string | null;
};

export type TNewApplication = {
  nameProcess: string;
  correct: {
    statusText: string;
    reason: string;
  };
  date: {
    start: string;
    end: string;
  };
  price: string;
  ndsText: string;
  comment: string;
};

export type TAdvancedApplication = TApplication & {
  name: string;
  badReason: string;
  taskId: number | null;
};

export type TDocumentation = {
  dateRange: string;
  ndsText: string;
  price: string;
};

export type TExecutor = {
  id: number;
  photoId: string;
  fio: string;
  experience: number;
  rating: number;
  userTypeText: string;
  ndsText: string;
  price: number;
  comments: Record<string, string>;
  applications: Record<string, string>;
  newApplications: Record<string, TNewApplication>;
  deadlines: string;
  advancedApplications: Record<string, Record<string, TAdvancedApplication>>;
  executorsDocumnetation: Record<string, TApplication>;
};

export type TExecutorsGroups = Record<string, TExecutor[]>;

type TState = {
  data: {
    executors: TExecutorsGroups;
    groupNames: Record<string, string>;
    openProcesses: number[];
    isAllToggle: boolean;
    selectedExecutors: TExecutor[];
    selectIsDisabled: boolean;
    comparisonMode: boolean;
    typeNames: Record<string, string>;
    newTypeNames: Record<string, any>;
    sumValues: Record<string, any>;
    bufer: any;
  };
  pending: {
    executors: boolean;
    choosing: boolean;
  };
  error: {
    executors: string | null;
    choosing: string | null;
  };
};

const initialState: TState = {
  data: {
    executors: {},
    isAllToggle: false,
    groupNames: {},
    openProcesses: [],
    selectedExecutors: [],
    selectIsDisabled: false,
    comparisonMode: false,
    typeNames: {},
    newTypeNames: {},
    sumValues: {},
    bufer: [],
  },
  pending: {
    executors: false,
    choosing: false,
  },
  error: {
    executors: null,
    choosing: null,
  },
};

const groupApplications = createSlice({
  name: "groupApplications",
  initialState,
  reducers: {
    setOpen(state, action) {
      const id = action.payload;
      state.data.openProcesses = state.data.openProcesses.includes(id)
        ? state.data.openProcesses.filter(
            (processId: number) => processId !== id
          )
        : [...state.data.openProcesses, id];
    },
    setAllOpen(state) {
      if (!state.data.isAllToggle) {
        // @ts-ignore
        state.data.openProcesses = Object.keys(state.data.executors);
        state.data.isAllToggle = true;
      } else {
        state.data.openProcesses = [];
        state.data.isAllToggle = false;
      }
    },
    selectExecutor(state, action) {
      const newExecutor = action.payload;

      state.data.selectedExecutors = state.data.selectedExecutors.find(
        (executor: TExecutor) => executor.id === newExecutor.id
      )
        ? state.data.selectedExecutors.filter(
            (executor: TExecutor) => executor.id !== newExecutor.id
          )
        : [...state.data.selectedExecutors, newExecutor];
    },
    deleteExecutor(state, action) {
      const id = action.payload;
      const newSelectedExecutors = state.data.selectedExecutors.filter(
        (executor) => executor.id !== id
      );
      const remoteExecutor = state.data.selectedExecutors.filter(
        (executor) => executor.id === id
      );
      state.data.selectedExecutors = newSelectedExecutors;
      state.data.bufer = [...state.data.bufer, remoteExecutor];
      console.log(state.data.selectedExecutors);
    },
    returnDeleted(state) {
      const executor = state.data.bufer.pop();
      state.data.selectedExecutors = [
        ...state.data.selectedExecutors,
        ...executor,
      ];
    },
    clearSelectedExecutors(state) {
      state.data.selectedExecutors = [];
    },
    toggleGroupComparisonMode(state) {
      state.data.comparisonMode = !state.data.comparisonMode;
      state.error.choosing = null;
    },
    clearAllApplications() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getExecutors), (state) => {
        state.pending.executors = true;
      })
      .addMatcher(isFulfilled(getExecutors), (state, action) => {
        const { result, groupNames, typeNames, newTypeNames } = createExecutors(
          action.payload
        );

        state.data.executors = result;
        state.data.groupNames = groupNames;
        state.data.typeNames = typeNames;
        state.data.newTypeNames = newTypeNames;

        state.pending.executors = false;
      })
      .addMatcher(isRejected(getExecutors), (state, action) => {
        state.error.executors = action.payload as string;
        state.pending.executors = false;
      })
      .addMatcher(isPending(saveChosenTasks), (state) => {
        state.pending.choosing = true;
      })
      .addMatcher(isFulfilled(saveChosenTasks), (state) => {
        state.pending.choosing = false;
        state.error.choosing = null;
      })
      .addMatcher(isRejected(saveChosenTasks), (state, action) => {
        state.error.choosing = action.payload as string;
        state.pending.choosing = false;
      });
  },
});

export const {
  setOpen,
  setAllOpen,
  selectExecutor,
  clearSelectedExecutors,
  toggleGroupComparisonMode,
  clearAllApplications,
  deleteExecutor,
  returnDeleted,
} = groupApplications.actions;

export const getGroupApplications = (state: CombinedState<any>) =>
  state.projectGroupApplications;

export default groupApplications.reducer;
