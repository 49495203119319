import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const sxBody = { bgcolor: "#1A2734" };
export const HistorySkeleton = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxBody} height={820} />
      </Grid>
    </Grid>
  );
};
