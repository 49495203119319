import { useMemo } from "react";
import cn from "classnames";
import {
  getDashboardDestinations,
  setProjectId,
  setSectionId,
  setTab,
} from "../../../../app/feature/dashboard/destinations";
import {
  getDashboardProjects,
  setProjectStatus,
} from "../../../../app/feature/dashboard/projects";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import style from "./style.module.scss";
import { getDashboardSections } from "../../../../app/feature/dashboard/sections";

type TSequenceType = "project" | "section";

type TSequence = {
  id: number;
  name: string;
  type: TSequenceType;
  isLink: boolean;
};

const CurrentLocation = () => {
  const dispatch = useAppDispatch();

  const { data, subData } = useAppSelector(getDashboardProjects);
  const { data: sections } = useAppSelector(getDashboardSections);
  const { projectId, sectionId, tab } = useAppSelector(
    getDashboardDestinations
  );

  const handleShowTab = (id: number, isLink: boolean, type: TSequenceType) => {
    if (!isLink) {
      return;
    }

    let newTab = "project->section";

    if (type === "section") {
      if (tab === "work->tasks") {
        newTab = "section->tasksAndWorks";
      }
      if (tab === "work->treaty") {
        newTab = "section->treaty";
      }
      if (tab === "work->application") {
        newTab = "section->application";
      }

      dispatch(setTab(newTab));
      return;
    }

    dispatch(setTab(newTab));
    dispatch(setProjectStatus(id));
    dispatch(setProjectId(id));
    dispatch(setSectionId(0));
  };

  const projectsSequence = useMemo(() => {
    const result: TSequence[] = [];

    const completeData = Object.values(subData)
      .reduce(
        (completeData: any[], item: any) => [...completeData, ...item],
        []
      )
      .concat(data);

    const getPrarentProject = (id: number) => {
      const project = completeData.find((project: any) => project.id === id);

      if (project) {
        const { parentId, name } = project;
        result.unshift({
          id,
          name,
          type: "project",
          isLink: !tab.includes("project"),
        });

        if (parentId) {
          getPrarentProject(parentId);
        }
      }
    };

    if (projectId) {
      getPrarentProject(projectId);
    }

    if (sectionId) {
      const foundSection: any = Object.values(sections).find(
        (item: any) => item.id === sectionId
      );

      const name = foundSection?.title ?? "";
      result.push({
        id: sectionId,
        name,
        type: "section",
        isLink: !tab.includes("section"),
      });
    }

    return result;
  }, [projectId, sectionId, data, tab]);

  return (
    <div className={style.sequence}>
      {projectsSequence.map(({ id, isLink, name, type }, index: number) => (
        <>
          <p
            onClick={() => handleShowTab(id, isLink, type)}
            className={cn(isLink && style.isLink)}
          >
            {name}
          </p>
          {index !== projectsSequence.length - 1 && <p>/</p>}
        </>
      ))}
    </div>
  );
};

export default CurrentLocation;
