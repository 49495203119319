import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { useAppDispatch } from "../../../../../../../../app/store";
import { checkExpertiseRemarkRowCompleteSchema } from "../schemas";
import { pushError } from "../../../../../../../../app/feature/errorTrace";
import { Nullable } from "../../../../../../../../types/generics";

type TFieldValues = {
  comment: string;
  status: Nullable<Statuses>;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useCheckExpertiseRemarkRowComplete = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(checkExpertiseRemarkRowCompleteSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["parent.remarkRow.remark.files"],
  });

  const status = watch("status");

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      comment: data.comment,
      status: data.status!,
    };

    await dispatch(
      TaskApi.complete({
        data: { id, formedData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    status,
    isTaskViewPending: isFetching || !taskData,
    taskData,
  } as const;
};
