import { ApiMainPage } from "../../../../types/api/responseTypes/mainPage";
import {
  MainPageDataTypes,
  MainPageProjectsDataType,
  ProjectProcess,
} from "../../../../types/stateTypes/mainPage";
import { createUserRating, getUserName } from "../../../services/user/user";
import {
  ApiProjectsProcessesType,
  ApiProjectsProcessType,
} from "../../../../types/api/responseTypes/apiProjectsType";
import { processCostValue } from "../../../services/price/price";
import { config } from "../../../../app.cofig";

type ProjectListMainPageType = (
  data: ApiMainPage["projects"]
) => MainPageProjectsDataType[];

type ExecutorsByRating = (
  data: ApiMainPage["rating"]
) => MainPageDataTypes["executors"]["rating"];

type ExecutorsByExperience = (
  data: ApiMainPage["experience"]
) => MainPageDataTypes["executors"]["experience"];

type ExecutorsByNew = (
  data: ApiMainPage["new"]
) => MainPageDataTypes["executors"]["new"];

type News = (data: ApiMainPage["news"]) => MainPageDataTypes["news"];

const sortProcessForMainPage = (
  processes: ApiProjectsProcessType[]
): ProjectProcess[] => {
  const result = processes.reduce<ProjectProcess[][]>(
    (acc, item) => [
      ...acc,
      item.processes.map((data: ApiProjectsProcessesType) => ({
        type: item.type,
        code: data.code,
        price: data.price,
        processId: data.processId,
        secure: data.secure,
      })),
    ],
    []
  );

  return result
    .flat()
    .sort((a: ProjectProcess, b: ProjectProcess) => a.type - b.type);
};

export const createProjectsList: ProjectListMainPageType = (data) =>
  data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      region: { title: item.region.title },
      author: { name: getUserName(item.author) },
      projectProcesses: sortProcessForMainPage(item.projectProcesses),
      publishedCost: processCostValue(item.processPublishedCost),
    };
  });

export const createExecutorsList:
  | ExecutorsByRating
  | ExecutorsByExperience
  | ExecutorsByNew = (data) =>
  data.map((item) => {
    return {
      id: item.id,
      name: { name: item.name, surname: item.surname },
      photo: `${config.localDomain}${item.photo}`,
      rating: createUserRating(Number(item.rating.executor)),
      region: item.region
        ? {
            title: item.region ? item.region.title : undefined,
            country: item.region ? item.region.country.title : undefined,
          }
        : null,
      skills: item.skills.length ? item.skills[0].name : "",
      workWithBim: item.work_with_bim,
      tested: item.tested,
    };
  });

export const createNewsList: News = (data) =>
  data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      content: item.content,
      imgUrl: item.photoUrl ? `${config.localDomain}${item.photoUrl}` : "",
      url: item.url,
    };
  });
