import { useEffect } from "react";
import { useProjectGetExecutorFullTypesQuery } from "src/FSD/entities/project/api";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { toSelect } from "../../../../../../app/feature/project/projectProcessPublication";
import { TSelectItem } from "../../../../../../components/features/Forms/SetGipForm/types";

type UseSelectProps = {
  setValue?: any;
  processWork?: ApiProcessWorkResponses.View;
};

export const useSelectExecutorTypes = ({
  setValue,
  processWork,
}: UseSelectProps) => {
  const { data: executorTypesRaw, isFetching } =
    useProjectGetExecutorFullTypesQuery();

  const executorTypes = useDataMapper(executorTypesRaw, toSelect);

  const isExecutorTypes = isFetching || !executorTypes;

  useEffect(() => {
    if (!processWork) {
      return;
    }

    if (
      processWork.status.key === Statuses.UNREADY &&
      executorTypes &&
      processWork.executorType
    ) {
      const executorTypesProcessWork = processWork.executorType.map(
        (item) => item.key
      );

      const executorTypesFilter = executorTypes.filter((item: TSelectItem) =>
        executorTypesProcessWork.includes(Number(item.id))
      );

      setValue("executorTypes", executorTypesFilter);
    }
  }, [processWork, executorTypes]);

  return {
    executorTypes,
    isExecutorTypes,
  } as const;
};
