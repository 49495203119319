import React, { useEffect } from "react";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { ITab, Tabs } from "./Tabs";

interface HorizontalTabProps {
  tabs: ITab[];
  components: { id: string | number; component: any }[];
  classname?: string;
  activeTab: number | string;
  setActiveTab: (tab: number | string) => void;
  isLoad?: boolean;
  className?: string;
  skeleton: any;
}
export const HorizontalTabsPanel: React.FC<HorizontalTabProps> = ({
  tabs,
  components,
  classname,
  activeTab,
  setActiveTab,
  isLoad = false,
  className,
  skeleton,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const horizontalTab = params.get("horizontalTab");
  const subCard = params.get("subCard");
  const currentUrlTab = params.get("tab");

  const handleTabToggle = (id: string | number) => {
    setActiveTab(id);

    let searchPath = `?tab=${currentUrlTab}&horizontalTab=${id}`;

    if (subCard) {
      searchPath += `&subCard=${subCard}`;
    }

    navigate({
      search: searchPath,
    });
  };

  useEffect(() => {
    handleTabToggle(Number(horizontalTab) || 1);
  }, []);

  return (
    <div className={cn(className)}>
      <Tabs
        selectedId={activeTab}
        tabs={tabs}
        onClick={handleTabToggle}
        className={classname}
      />
      <div>
        {isLoad && skeleton}
        {!isLoad &&
          components?.map((el) => {
            if (el.id === activeTab) return el.component;
            return null;
          })}
      </div>
    </div>
  );
};
