import React, { FC, useEffect, useState, useMemo } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getSectionApplications } from "../../../../../app/feature/dashboard/applications/index";
import {
  getApplicationsBySection,
  getApplicationsByTask,
} from "../../../../../app/feature/dashboard/applications/thunks";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";
import globalStyle from "../Primary/style.module.scss";
import FilterApplications from "../Filter/Applications";
import useFilter from "../Filter/useFilter";
import Application from "../Secondary/Applications/Application";
import DashboardSkeleton from "../Skeleton";
import FolderPanel from "./Sections/FolderPanel";
import Switch from "../../../../newUI/Switch/Switch";
import style from "./style.module.scss";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import SearchField from "../SearchField";

const initialOpen = {
  good: true,
  bad: true,
};

const Applications: FC = () => {
  const dispatch = useAppDispatch();

  const { tab, sectionId, workId } = useAppSelector(getDashboardDestinations);
  const { section, work, pending } = useAppSelector(getSectionApplications);

  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [fulfilled, setFulfilled] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState<Record<string, boolean>>(initialOpen);

  const data = useMemo(() => {
    let oldApplications: any = [];
    let newApplications: any = [];

    if (tab === "section->application") {
      oldApplications = section.oldApplications;
      newApplications = section.newApplications;
    }

    if (tab === "work->application") {
      oldApplications = work.oldApplications;
      newApplications = work.newApplications;
    }

    return fulfilled ? oldApplications : newApplications;
  }, [tab, fulfilled, section, work]);

  const isTablette = useIsMobile(1600);
  const isMobile = useIsMobile(1100);

  const { ticked, handleChange } = useFilter(
    data,
    query,
    fulfilled,
    FilterApplications,
    setQuery
  );

  useEffect(() => {
    if (tab === "section->application" && sectionId) {
      dispatch(getApplicationsBySection(sectionId));
      return;
    }

    if (tab === "work->application" && workId) {
      dispatch(getApplicationsByTask(workId));
    }
  }, [sectionId, workId]);

  useEffect(() => {
    setOpen(initialOpen);
  }, [fulfilled]);

  const showAtSections = tab === "section->application" && sectionId;
  const showAtWorks = tab === "work->application" && workId;

  const showApplications = Boolean(showAtSections || showAtWorks);

  const head = (
    <div className={cn(style.generalHeader, style.applicationHeader)}>
      <h1>Название</h1>
      <p className={style.form}>Форма</p>
      <p>Сроки</p>
      <p>Стоимость</p>
    </div>
  );

  const goodApplications = data.good
    .map((application: any) => {
      if (
        (ticked.includes(application.id) && (query || fulfilled)) ||
        (!query && !fulfilled)
      ) {
        return <Application type="good" {...application} old={fulfilled} />;
      }

      return null;
    })
    .filter((application: any) => application);

  const badApplications = data.bad
    .map((application: any) => {
      if (
        (ticked.includes(application.id) && (query || fulfilled)) ||
        (!query && !fulfilled)
      ) {
        return <Application type="bad" {...application} old={fulfilled} />;
      }

      return null;
    })
    .filter((application: any) => application);

  const applications = showApplications && (
    <div className={globalStyle.list}>
      {!isTablette && head}
      <FolderPanel
        canOpen={Boolean(goodApplications.length)}
        isOpen={open.good}
        name={`Соответствуют (${goodApplications.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, good: !open.good });
        }}
      />
      {open.good && goodApplications}
      <FolderPanel
        canOpen={Boolean(badApplications.length)}
        isOpen={open.bad}
        name={`Не соответствуют (${badApplications.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, bad: !open.bad });
        }}
      />
      {open.bad && badApplications}
    </div>
  );

  return (
    <div className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}>
      <div className={cn(globalStyle.header, style.sectionsFullHeader)}>
        {isMobile ? (
          <>
            <h3>Заявка</h3>
            <div className={style.mobileHeader}>
              <p>Ранее поданные заявки</p>
              <Switch
                checked={fulfilled}
                onChange={() => setFulfilled(!fulfilled)}
              />
              <IconButton
                className={style.mobileSearchBar}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h3>Заявка</h3>
            <p>Ранее поданные заявки</p>
            <Switch
              checked={fulfilled}
              onChange={() => setFulfilled(!fulfilled)}
            />
            <SearchField value={query} onChange={handleChange} />
          </>
        )}
      </div>
      {isMobile && showSearchBar && (
        <SearchField
          className={style.searchBar}
          value={query}
          onChange={handleChange}
          mobile
        />
      )}
      {showApplications && (
        <>{pending ? <DashboardSkeleton /> : applications}</>
      )}
    </div>
  );
};

export default Applications;
