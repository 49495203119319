import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { Select } from "../../UI/components/FormComponentns/Select";
import { request } from "../../../app/services/api/requestHandler";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { ApiGetPartGroup } from "../../../app/services/api/partGroup/partGroup";

export type SpecializationSelectItem = ComponentPropsType<{
  title: string;
  id: number;
}>;

export type SpecializationProps = ComponentPropsType<{
  changeHandler?: (spec: SpecializationSelectItem) => void;
  value: number | null;
  label: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}>;

export const Specialization: React.FC<SpecializationProps> = ({
  changeHandler = () => {},
  variant = "",
  value,
  label = "",
  placeholder = "",
  error = false,
  helperText = "",
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allSpec, setAllSpec] = useState<SpecializationSelectItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    SpecializationSelectItem | undefined
  >(undefined);

  const onLoadSpec = () => {
    request(
      ApiGetPartGroup(),
      (data) => {
        const allSpecFormed = data.map(
          (item: { name: string; id: number }) => ({
            id: item.id,
            title: item.name,
          })
        );

        setAllSpec(allSpecFormed);
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса специализаций", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };
  const onSearch = (data: string) => {
    const result = allSpec.find((item) => item.title === data);
    setSelectedValue(result || undefined);
  };

  useEffect(() => {
    onLoadSpec();
  }, []);

  useEffect(() => {
    const result = allSpec.find((item) => Number(item.id) === Number(value));
    setSelectedValue(result || undefined);
  }, [value, allSpec]);

  return (
    <Select
      changeHandler={(_, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      searchHandler={onSearch}
      variant={classNames(variant)}
      label={label}
      placeholder={placeholder}
      options={allSpec}
      error={error}
      helperText={helperText}
    />
  );
};
