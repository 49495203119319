import { IconButton } from "@mui/material";
import {
  ArrowDownCircle,
  ArrowRightCircle,
  CaretDownFill,
  CaretUpFill,
} from "react-bootstrap-icons";
import cn from "classnames";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Heading } from "src/FSD/shared/uiKit/v2/Heading";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { useViewTaskInfo } from "../lib/hooks/useViewTaskInfo";
import styles from "./ViewTaskInfo.module.scss";
import { TaskCheck } from "./TaskCheck";
import { TaskComment } from "./TaskComment";

type Props = {
  taskId: number;
  header: string;
  isCompletion?: boolean;
};

export const ViewTaskInfo = ({ taskId, header, isCompletion }: Props) => {
  const {
    taskData,
    isTaskLoading,
    register,
    handleSubmit,
    onSubmit,
    errors,
    isSubmitting,
    isRejecting,
    isAccepting,
    setValue,
    areCommentsOpen,
    setCommentsOpen,
    areChecksOpen,
    setChecksOpen,
  } = useViewTaskInfo({ taskId });

  return (
    <Modal.Layout position="right">
      <Modal.Header>{header}</Modal.Header>
      {isTaskLoading ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Form onSubmit={handleSubmit(onSubmit)}>
            <Heading>Проект</Heading>
            <Paragraph>{taskData!.projectName}</Paragraph>
            {Boolean(taskData!.objectName) && (
              <>
                <Heading>Объект</Heading>
                <Paragraph>{taskData.objectName}</Paragraph>
              </>
            )}
            <Heading>Вложения</Heading>
            {taskData.files.length ? (
              <div className={styles.linkWrapper}>
                <TextLink
                  url={`${config.localDomain}/task/download-files?id=${taskId}`}
                  target="_blank"
                  className={styles.link}
                  redirect
                >
                  <span>
                    Скачать материалы к задаче ({taskData.files.length})
                  </span>
                  <ArrowDownCircle />
                </TextLink>
              </div>
            ) : (
              <Paragraph>Вложения отсутствуют</Paragraph>
            )}
            {Boolean(taskData.checks.length) && (
              <>
                <Heading>
                  <label
                    htmlFor="checksControl"
                    className={styles.commentsHeader}
                  >
                    Согласующие разделы
                  </label>
                  <IconButton
                    id="checksControl"
                    onClick={() => setChecksOpen(!areChecksOpen)}
                    className={styles.commentsButton}
                  >
                    {areChecksOpen ? (
                      <CaretUpFill color="white" />
                    ) : (
                      <CaretDownFill color="white" />
                    )}
                  </IconButton>
                </Heading>
                {areChecksOpen && (
                  <div className={styles.list}>
                    {taskData.checks.map(
                      (check: ApiTaskResponses.Check, index: number) => (
                        <TaskCheck key={index} {...check} />
                      )
                    )}
                  </div>
                )}
              </>
            )}
            {Boolean(taskData.comments.length) && (
              <>
                <Heading>
                  <label
                    htmlFor="commentsControl"
                    className={styles.commentsHeader}
                  >
                    Комментарии
                  </label>
                  <IconButton
                    id="commentsControl"
                    onClick={() => setCommentsOpen(!areCommentsOpen)}
                    className={styles.commentsButton}
                  >
                    {areCommentsOpen ? (
                      <CaretUpFill color="white" />
                    ) : (
                      <CaretDownFill color="white" />
                    )}
                  </IconButton>
                </Heading>
                {areCommentsOpen && (
                  <div className={styles.list}>
                    {taskData.comments.map(
                      (comment: ApiTaskResponses.Comment) => (
                        <TaskComment key={comment.id} {...comment} />
                      )
                    )}
                  </div>
                )}
              </>
            )}
            {isCompletion && (
              <>
                <TextField
                  {...register("comment")}
                  label="Комментарий"
                  multiline
                  minRows={3}
                  error={Boolean(errors.comment)}
                  helperText={errors.comment?.message}
                />
                <Modal.Controls>
                  <Button
                    disabled={isSubmitting}
                    isLoading={isRejecting}
                    type="submit"
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setValue("status", Statuses.REJECT);
                    }}
                  >
                    Отказать
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    isLoading={isAccepting}
                    type="submit"
                    onClick={() => {
                      setValue("status", Statuses.ACCEPT);
                    }}
                  >
                    Принять
                  </Button>
                </Modal.Controls>
              </>
            )}
          </Modal.Form>
          {Boolean(taskData!.taskUrl) && (
            <div className={cn(styles.linkWrapper, styles.taskLink)}>
              <TextLink
                url={`${config.localDomain}${taskData!.taskUrl}`}
                target="_blank"
                className={styles.link}
                redirect
              >
                <span>Перейти к задаче</span>
                <ArrowRightCircle />
              </TextLink>
            </div>
          )}
        </>
      )}
    </Modal.Layout>
  );
};
