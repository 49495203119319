import { RootState } from "../../../../../store";

export const getExpertisePublishIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.isLoadPublish;

export const getExpertisePublishSuccess = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.publishSuccess;

export const getExpertisePublishError422 = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.error422;

export const getExpertiseCreateDocumentIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.isLoadCreateDocument;

export const getExpertiseCreateDocument = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.createDocumentSuccess;

export const getExpertiseCreateDocumentError422 = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.error422Document;
export const getExpertiseUpdatePublishIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.isLoadUpdatePublish;

export const getExpertisePublishUpdateSuccess = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.publishUpdateSuccess;

export const getExpertisePublishUpdateError422 = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.error422Update;

export const getExpertiseCreateConclusionIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseHeaderBtn.isLoadCreateConclusion;
