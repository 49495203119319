import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";

export const setMissingAttributes = (
  item: ApiCommonResponses.SecureMissingAttributes
) => {
  const { length } = item.attributes;
  const point = item.title && !length ? "." : "";
  const colon = length ? ":" : point;

  return `${item.title}${colon}${length ? item.attributes.map((attribute, i) => ` ${attribute}${i === item.attributes.length - 1 ? "." : ""}`) : ""}`;
};
