import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import {
  getProjectCheckTasks,
  setAllToggle,
  setError,
} from "../../../../../app/feature/ProjectView/CheckTaskReducer/projectCheckTask";
import styles from "./style.module.scss";
import { CheckTaskCard } from "./CheckTaskCard";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { Header } from "./Header/Header";
import { fetchProjectCheckTaskTree } from "../../../../../app/feature/ProjectView/CheckTaskReducer/thunks";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import {
  CheckTaskSkeletonFullPage,
  CheckTasksSkeletonBody,
} from "./CheckTasksSkeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface ICheckTasksProps {
  setDefaultTab: () => void;
}
export const CheckTasks = ({ setDefaultTab }: ICheckTasksProps) => {
  const { checkTasks, error, status, isAllToggle, fullPageLoad, bodyLoad } =
    useSelector(getProjectCheckTasks);
  const { project } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isTablet = useIsMobile(1600);

  if (project && (!project.can.edit || !project.can.editByGipHelper)) {
    setDefaultTab();
  }

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id && !status.done && !status.check) {
      dispatch(
        fetchProjectCheckTaskTree({
          id: +params.id,
          status: "",
          load: "fullPageLoad",
        })
      );
    }
  }, [params.id, dispatch]);

  const handleClick = () => {
    if (isAllToggle) {
      dispatch(setAllToggle(false));
    } else {
      dispatch(setAllToggle(true));
    }
  };

  if (fullPageLoad) {
    return <CheckTaskSkeletonFullPage />;
  }

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (checkTasks) {
    return (
      <div className={styles.wrapper}>
        <Header />
        {bodyLoad ? (
          <CheckTasksSkeletonBody />
        ) : (
          <ScrollWrap height={isTablet ? "auto" : "880px"}>
            {checkTasks.map((checkTask, index) => (
              <CheckTaskCard
                checkTask={checkTask}
                key={checkTask.title}
                index={index + 1}
              />
            ))}
            <AllToggleWidget
              isAllToggle={isAllToggle}
              setIsAllToggle={handleClick}
            />
          </ScrollWrap>
        )}
      </div>
    );
  }
  return <div></div>;
};
