import { config } from "../../../app.cofig";
import { ApiPrimaryUserObjectType } from "../../../types/api/primaryTypes/apiPrimaryUserType";

/*
  Переводит количество дней опыта в количество лет
 */
export const userExperience: (
  days: number,
  includePrecision?: boolean
) => string = (days: number, includePrecision = true) => {
  const date = days / 365;
  const precision = date.toFixed(4);
  const arrayNum = precision.split(".");
  const [first, last] = arrayNum.map((item) => Number(item));
  if (includePrecision) return [first, Math.ceil(last / 1000)].join(".");
  return first.toString();
};

// вернуть имя и фамилию обьекта пользователя
export const getUserName = (
  userObjet: Pick<ApiPrimaryUserObjectType, "name" | "surname">
): string => `${userObjet?.name} ${userObjet?.surname}`;

export const getUserFullStringAddress = (
  userObject: ApiPrimaryUserObjectType
): string => `${userObject.region!.country.title}, ${userObject.region!.title}`;

export const getUserNameSurname = ({
  name,
  surname,
  patronymic,
}: {
  name: string;
  surname: string;
  patronymic?: string | null;
}): string => {
  return `${name} ${surname} ${patronymic || ""}`;
};

export const getUserSurnameAndInitials = ({
  name,
  surname,
  patronymic,
}: {
  name: string;
  surname: string;
  patronymic?: string | null;
}): string => {
  return `${surname} ${name[0]}. ${(patronymic && `${patronymic[0]}.`) || ""}`;
};

export const createUserAvatar = (id: string | number) => {
  return `${config.localDomain}/file/get?id=${id}`;
};

export const createUserRating = (num: number) => {
  return num * 20;
};

export const getExecutorType = (
  data: {
    key: number;
    value: string;
    full: string;
  }[]
): string => {
  if (data.length === 0) return "";
  const created = data.map((item) => item.value).join(",");
  return `[${created}]`;
};

export const parseExecutorTypeObjectToSelectItem = (
  item: ApiPrimaryUserObjectType
) => ({
  itemId: String(item.id),
  title: `${item.name} ${item.surname} ${item.patronymic}`,
  userTypes: item.userTypes!,
});
