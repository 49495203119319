import { SourceDataType } from "../types/projectProcessInitialDataTypes";

export default (payload: any) => {
  const response: Record<string, SourceDataType[]> = {};

  Object.values(payload).forEach((value: any) => {
    if (!value?.data) {
      response[value.type] = [];
      return;
    }

    if (!Array.isArray(value.data)) {
      response[value.type] = [{ ...value.data }];
      return;
    }

    response[value.type] = [...value.data];
  });

  return response;
};
