import { memo } from "react";
import { KanbanModel } from "src/FSD/entities/kanban";
import { Select, TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Column } from "src/FSD/widgets/v2/kanban";
import { columnsSorted } from "../lib/constants/columnsSorted";
import styles from "./Kanban.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const RowTasksList = memo(() => {
  const dispatch = useAppDispatch();

  const { rowTasks } = useAppSelector(KanbanModel.getTasks);

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const isGrouppedByWeeks = groupBy.id === "";

  const handleChangeGroupByValue = (_: Event, groupByValue: TSelectItem) => {
    if (groupByValue.id === groupBy.id) {
      return;
    }

    dispatch(KanbanModel.setGroupByValue(groupByValue));
  };

  if (!Object.keys(rowTasks).length) {
    return (
      <div className={styles.columns__history_static}>
        <h2>Ничего не найдено</h2>
      </div>
    );
  }

  return (
    <>
      {Object.entries(rowTasks).map(([rowId, { header, columns }], index) => (
        <div key={header} className={styles.columns__history_static}>
          <h2>{header}</h2>
          {columnsSorted.map((columnId) => {
            const position = `${columnId}.${rowId}`;
            const isStatic = isGrouppedByWeeks && index > 0;

            return (
              <Column
                key={position}
                position={position}
                tasks={columns[columnId]}
                isStatic={isStatic}
              />
            );
          })}
        </div>
      ))}
    </>
  );
});
