import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
  CombinedState,
} from "@reduxjs/toolkit";
import { getApplicationsBySection, getApplicationsByTask } from "./thunks";

type TApplications = {
  newApplications: {
    good: any[];
    bad: any[];
  };
  oldApplications: {
    good: any[];
    bad: any[];
  };
};

export type TState<T> = {
  section: T;
  work: T;
  pending: boolean;
  error: string | null;
};

const initialState: TState<TApplications> = {
  section: {
    newApplications: {
      good: [],
      bad: [],
    },
    oldApplications: {
      good: [],
      bad: [],
    },
  },
  work: {
    newApplications: {
      good: [],
      bad: [],
    },
    oldApplications: {
      good: [],
      bad: [],
    },
  },
  pending: false,
  error: null,
};

const dashboardApplications = createSlice({
  name: "dashboardApplications",
  initialState,
  reducers: {
    clearApplicationsData(state) {
      state.section.newApplications.good = [];
      state.section.newApplications.bad = [];
      state.section.oldApplications.good = [];
      state.section.oldApplications.bad = [];

      state.work.newApplications.good = [];
      state.work.newApplications.bad = [];
      state.work.oldApplications.good = [];
      state.work.oldApplications.bad = [];
    },
    clearApplications() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(getApplicationsBySection, getApplicationsByTask),
        (state) => {
          state.pending = true;
        }
      )
      .addMatcher(isFulfilled(getApplicationsBySection), (state, action) => {
        const { newApplications, oldApplications } = action.payload;
        state.pending = false;

        state.section.newApplications.good = newApplications?.good ?? [];
        state.section.newApplications.bad = newApplications?.bad ?? [];
        state.section.oldApplications.good = oldApplications?.good ?? [];
        state.section.oldApplications.bad = oldApplications?.bad ?? [];
      })
      .addMatcher(isFulfilled(getApplicationsByTask), (state, action) => {
        const { newApplications, oldApplications } = action.payload;
        state.pending = false;

        state.work.newApplications.good = newApplications.good ?? [];
        state.work.newApplications.bad = newApplications.bad ?? [];
        state.work.oldApplications.good = oldApplications.good ?? [];
        state.work.oldApplications.bad = oldApplications.bad ?? [];
      })
      .addMatcher(
        isRejected(getApplicationsBySection, getApplicationsByTask),
        (state, action) => {
          state.pending = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const { clearApplications, clearApplicationsData } =
  dashboardApplications.actions;

export const getSectionApplications = (state: CombinedState<any>) =>
  state.dashboard.applications;

export default dashboardApplications;
