export const weekDaysOptions = [
  { id: 0, title: "Воскресенье" },
  { id: 1, title: "Понедельник" },
  { id: 2, title: "Вторник" },
  { id: 3, title: "Среда" },
  { id: 4, title: "Четверг" },
  { id: 5, title: "Пятница" },
  { id: 6, title: "Суббота" },
];

export const weeksCountOptions = [
  {
    id: 1,
    title: "1",
  },
  {
    id: 2,
    title: "2",
  },
  {
    id: 3,
    title: "3",
  },
  {
    id: 4,
    title: "4",
  },
];
