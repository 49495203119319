import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { AppUserInitialState } from "./appUserInitialState";
import { StateType } from "../../initialState";

export const appUserReducer = createReducer(AppUserInitialState, (builder) => {
  builder.addCase(
    C.SET_USER_AGREEMENT_PERSONAL,
    (state, action: PayloadAction<{ agreement_personal: string }>) => {
      return {
        ...state,
        agreement_personal: action.payload.agreement_personal,
      };
    }
  );
});

export const setUserAgreement = createAction(
  C.SET_USER_AGREEMENT_PERSONAL,
  (action) => {
    return {
      payload: {
        agreement_personal: action.agreement_personal,
      },
    };
  }
);

export const selectorAgreementPersonal = (state: StateType): null | string =>
  state.appUser.agreement_personal;
