import * as Yup from "yup";
import {
  filesAreRequired,
  stringMaxLength,
} from "../../../../../../../../utils/SimplifiedYup";

export const customerAlignCompleteSchema = Yup.object().shape({
  files: filesAreRequired(),
  comment: stringMaxLength(2000),
});
