export default {
  SET_MAIN_PAGE_COUNTS: "SET_MAIN_PAGE_COUNTS",
  SET_MAIN_PAGE_PROJECTS_COUNTS: "SET_MAIN_PAGE_PROJECTS_COUNTS",
  SET_MAIN_PAGE_PROJECTS: "SET_MAIN_PAGE_PROJECTS",
  SET_MAIN_PAGE_EXECUTORS_BY_RATING: "SET_MAIN_PAGE_EXECUTORS_BY_RATING",
  SET_MAIN_PAGE_EXECUTORS_BY_EXPERIENCE:
    "SET_MAIN_PAGE_EXECUTORS_BY_EXPERIENCE",
  SET_MAIN_PAGE_EXECUTORS_BY_NEW: "SET_MAIN_PAGE_EXECUTORS_BY_NEW",
  SET_MAIN_PAGE_NEWS: "SET_MAIN_PAGE_NEWS",
};
