import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProcessWork } from "../../../../services/api/projectProcess/processWork";
import { ProjectProcessWorkTypes } from "../types/projectProcessWorkTypes";

export const fetchProcessWork = createAsyncThunk<
  ProjectProcessWorkTypes,
  number
>("processWork/fetchProcessWork", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProcessWork(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});

export const fetchProcessWorkDuplicate = createAsyncThunk<
  ProjectProcessWorkTypes,
  number
>("processWork/fetchProcessWorkDuplicate", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProcessWork(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
