import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { useTechnicalSpecifications } from "../lib/hooks/useTechnicalSpecifications";
import { config } from "../../../../../../shared/lib/configs/app.config";
import { setOriginalFilesRef } from "../lib/helpers/setOriginalFilesRef";

const defaultValues = {
  status: null,
  files: [],
};

type Props = {
  taskId: number;
  header: string;
};

export const TechnicalSpecificationsForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const { handleSubmit, onSubmit, errors, control, originalFilesRef } =
    useTechnicalSpecifications({
      taskId,
      defaultValues,
      handleClose,
    });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="files"
          control={control}
          render={({ field: { onChange } }) => (
            <Uploader
              canEdit
              getDataCallback={() => getTaskFiles(taskId)}
              setFiles={(files, isLoading) => {
                if (!isLoading) {
                  setOriginalFilesRef(originalFilesRef, files);
                }

                onChange(files);
              }}
              autoUpload
              postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
              error={Boolean(errors.files)}
              helperText={errors.files?.message}
            />
          )}
        />
        <Modal.Controls>
          <Button type="submit" variant="outlined" color="primary">
            Закрыть
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
