import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessDocument = (
  projectId: number,
  processId: number
): Promise<Response> => {
  // получить дерево договоры
  return httpRequestGet(
    `${config.localDomain}/v1/document/get-project-documents?project_id=${projectId}&DocumentSearch[processId]=${processId}&fields=id,name&expand=treeByObjects`
  );
};
