import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessMaterialDelete } from "../../../../services/api/projectProcess/material";
import { fetchProcessMaterial } from "./fetchProcessMaterial";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessMaterialDelete = createAsyncThunk<
  boolean,
  {
    processId: number;
    materialId: any;
  }
>(
  "materialProcess/fetchProjectProcessMaterialDelete",
  async ({ materialId, processId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessMaterialDelete(materialId),
      (data) => {
        dispatch(fetchProcessMaterial(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
