import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { palette } from "../../../../../../../../styles/restyle";

export const StatusColors = {
  [Statuses.WORK]: palette.grey,
  [Statuses.REJECT]: palette.bordeaux,
  [Statuses.DONE]: palette.persianGreen,
  [Statuses.ACCEPT]: palette.persianGreen,
  [Statuses.IS_DONE]: palette.warning,
  [Statuses.IS_REJECT]: palette.warning,
  [Statuses.CANCELLED]: palette.grey,
};
