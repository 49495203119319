import moment from "moment";

export const getPreparedBody = (payload: any) => {
  const { processes, userType, bankAccount, content } = payload;

  const ids: Record<string, number[]> = {};

  const parsedProcesses = Object.entries(processes).reduce(
    (processes: Record<string, any>, [typeId, item]: any) => {
      const newProcesses = Object.entries(item.list)
        .filter(([_, item]: any) => item.active)
        .reduce((newProcesses: Record<string, any>, [id, item]: any) => {
          if (ids[typeId]) {
            ids[typeId].push(Number(id));
          } else {
            ids[typeId] = [Number(id)];
          }

          return {
            ...newProcesses,
            [id]: {
              price: Number(item.price),
              date_start: moment(item.dateStart).format("DD.MM.YYYY"),
              date_limit: moment(item.dateLimit).format("DD.MM.YYYY"),
            },
          };
        }, {});

      return { ...processes, ...newProcesses };
    },
    {}
  );

  const types = Object.entries(payload)
    .filter(([key]) => !Number.isNaN(Number(key)))
    .reduce((types: any, [typeId]: any) => {
      const typeKey = `type${typeId}`;

      if (!ids[typeId]) {
        return types;
      }

      return {
        ...types,
        [typeKey]: {
          objectId: ids[typeId],
        },
      };
    }, {});

  const result: Record<string, any> = {
    StartForm: {
      ...types,
      ...parsedProcesses,
      executor_type: userType.id,
      bank_account_id: bankAccount.id,
      content,
    },
  };

  return result;
};
