import moment from "moment";
import {
  TAdvancedApplication,
  TApplication,
  TExecutor,
  TExecutorsGroups,
  TNewApplication,
} from ".";
import formatDate from "../../../../utils/formatDate";
import { trimPrice } from "../../../../utils/trimNumber";

export const createExecutors = (data: any) => {
  const result: TExecutorsGroups = {};
  const groupNames: Record<string, string> = {};
  const typeNames: Record<string, string> = {};
  const newTypeNames: Record<string, any> = {};

  Object.values(data).forEach((value: any) => {
    const groupId = value.group.id;

    groupNames[value.group.id] = value.group.name;

    const executors = Object.values(value.userTypes).reduce(
      (result: TExecutor[], user: any) => {
        // общая информация
        const dateStart = formatDate(user.countsByType.dateStart.date);
        const dateLimit = formatDate(user.countsByType.dateLimit.date);

        const comments: Record<string, string> = {};
        const applications: Record<string, string> = {};
        const newApplications: Record<string, TNewApplication> = {};
        const advancedApplications: Record<
          string,
          Record<string, TAdvancedApplication>
        > = {};
        const executorsDocumnetation: Record<string, TApplication> = {};

        // обработка разделов, на которые поданы заявки
        Object.entries(user.appProcesses).forEach(([id, item]: any) => {
          const processName = item.process.projectPart.code;
          applications[processName] = item.statusText.replace(/&nbsp;/g, " ");
          newApplications[processName] = {
            nameProcess: item.process.title,
            correct: {
              statusText: item.statusText.replace(/&nbsp;/g, " "),
              reason: item.badReasons.length > 0 ? item.badReasons[0] : null,
            },
            date: {
              start: item.task?.workflow_data?.date_start,
              end: item.task?.workflow_data?.date_limit,
            },
            price: String(trimPrice(item.price)),
            ndsText: item.ndsText,
            comment: item.task?.content || "",
          };

          if (item.task?.content) {
            comments[processName] = item.task.content;
          }

          // формирование низа таблицы

          const { type, code, name } = item.process.projectPart;
          const { badReasons, ndsText, price } = item;

          const dateRange = item.task
            ? `${item.task.workflow_data.date_start} - ${item.task.workflow_data.date_limit}`
            : "";

          const taskId = item.task?.id ?? null;

          const badReason = badReasons.length ? badReasons[0] : null;

          if (!advancedApplications[type]) {
            advancedApplications[type] = {};
          }

          advancedApplications[type][id] = {
            badReason,
            ndsText,
            price,
            dateRange,
            taskId,
            name: `${code} ${name}`,
          };
        });

        // формирование верхушки таблицы

        Object.keys(value.processTypes).forEach((key: string) => {
          const projectDocs =
            value.processTypes[key]?.counts?.[user.userType.id];

          typeNames[key] = value.processTypes[key].typeName;
          newTypeNames[key] = {
            dateEnd: moment(value.processTypes[key]?.dateEnd.date).format(
              "DD.MM.YYYY"
            ),
            dateStart: moment(value.processTypes[key]?.dateStart.date).format(
              "DD.MM.YYYY"
            ),

            fullPrice: value.processTypes[key]?.fullPrice,
            name: value.processTypes[key].typeName,
          };

          executorsDocumnetation[key] = {
            dateRange: projectDocs
              ? `${formatDate(projectDocs.dateStart.date)} - ${formatDate(
                  projectDocs.dateLimit.date
                )}`
              : "",
            ndsText: projectDocs?.ndsText ?? "",
            price: projectDocs?.price ?? "",
          };
        });

        const formedUser = {
          id: user.userType.id,
          photoId: user.photo_id,
          fio: user.fio,
          experience: Math.floor(user.job_duration / 365),
          rating: user.rating,
          userTypeText: user.userTypeText,
          ndsText: user.countsByType.ndsText,
          price: user.countsByType.price,
          deadlines: `${dateStart} • ${dateLimit}`,
          comments,
          applications,
          newApplications,
          advancedApplications,
          executorsDocumnetation,
        };

        return [...result, formedUser];
      },
      []
    );

    result[groupId] = executors;
  });

  return { result, groupNames, typeNames, newTypeNames };
};

export const createSumValues = (
  selectedExecutors: TExecutor[],
  processes: Record<string, number>
) => {
  const result: any = {};

  selectedExecutors.forEach((executor: TExecutor) => {
    Object.entries(executor.advancedApplications).forEach(
      ([type, processList]: any) => {
        if (!result[executor.id]) {
          result[executor.id] = {};
        }

        if (!result[executor.id][type]) {
          result[executor.id][type] = {};
        }

        const sum = Object.entries(processList).reduce(
          (count: { price: number; ndsText: string }, [id, item]: any) => {
            if (!processes[id] || processes[id] !== item.taskId) {
              return count;
            }

            return {
              price: count.price + item.price,
              ndsText:
                count.ndsText === "без НДС" ? item.ndsText : count.ndsText,
            };
          },
          { ndsText: "без НДС", price: 0 }
        );

        result[executor.id][type] = sum;
      }
    );
  });

  return result;
};
