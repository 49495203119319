import React from "react";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import parentStyles from "./PublishModalContent.module.scss";

export type TInputs = {
  secure: string;
  with_docs: string;
};

type TProps<TFieldValues extends TInputs> = {
  control: any;
  secure: boolean;
  docsChangeValue: string;
  docsValueList: string[];
  setDocsChangeValue: (arg: string) => void;
  errors: FieldErrors<TFieldValues>;
};

export const SecureAndDocs = ({
  control,
  secure,
  docsChangeValue,
  docsValueList,
  setDocsChangeValue,
  errors,
}: TProps<TInputs>) => {
  const secureValueList = ["Нет", "Да"];

  return (
    <div className={parentStyles.fieldGroup}>
      <h3 className={parentStyles.groupTitle}>Договор</h3>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="secure"
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={secure ? "Да" : "Нет"}
                options={secureValueList}
                disableClearable
                onChange={(_: unknown, currentValue: string) => {
                  onChange(currentValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Безопасная сделка"
                    error={!!errors.secure}
                    helperText={errors?.secure?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="with_docs"
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={docsChangeValue}
                options={docsValueList}
                disableClearable
                onChange={(_: unknown, currentValue: string) => {
                  onChange(currentValue);
                  setDocsChangeValue(currentValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Договора и Акты"
                    error={!!errors.with_docs}
                    helperText={errors?.with_docs?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};
