import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPut,
  httpRequestPostMultiData,
  httpRequestPost,
  httpRequestPatchMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetFirmStaff = (
  page: number,
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/firm/get-members?id=${id}&expand=role,user,gipAccountId,teams.members,can&per-page=50&page=${page}`
  );
};

export const ApiGetOrganisationInfo = (url: string): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/firm/${url}`);
};

export const ApiUpdateUserRole = (
  id: number,
  role_id: number
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/firm/update-firm-role?id=${id}`,
    {},
    { role_id }
  );
};

export const ApiRemoveUserFromFirm = (id: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/user-role/delete?id=${id}`
  );
};

export const ApiUpdateTaskMultiData = (
  id: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/firm/request-to-check-firm?id=${id}`,
    {},
    formData
  );
};

export const ApiUpdateTask = (
  id: number,
  data: Record<string, any>
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/task/update?id=${id}`,
    {},
    data
  );
};

export const ApiUserJoin = (id: number, formData: any): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    formData
  );
};

export const ApiRemoveFirm = (id: number): Promise<Response> => {
  return httpRequestDelete(`${config.localDomain}/v1/firm/delete?id=${id}`);
};

export const ApiRequestJoinFirmMulti = (
  id: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/firm/request-to-join?id=${id}`,
    {},
    formData
  );
};

export const ApiRemoveBankAccount = (id: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/bank-account/delete?id=${id}`
  );
};

export const ApiUpdateBankAccount = (
  id: string,
  data: any
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/bank-account/update?id=${id}`,
    {},
    data
  );
};

export const ApiCreateBankAccount = (data: any): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/bank-account/create`,
    {},
    data
  );
};

export const ApiSendMoney = (
  userRoleId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/account/create-gip-account?userRoleId=${userRoleId}`,
    {},
    data
  );
};

export const ApiRemoveRequest = (taskId: number): Promise<Response> => {
  return httpRequestDelete(`${config.localDomain}/v1/task/delete?id=${taskId}`);
};

export const ApiLeaveFirm = (userId: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/user-role/delete?id=${userId}`
  );
};

export const ApiGetPrompts = (query: string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/bank-account/dadata?query=${query}`
  );
};

export const ApiGetTaskFiles = (id: number | string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&fields=files&expand=files.svgIcon,can`
  );
};

export const ApiGetOutTaskFiles = (id: number | string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&expand=outFiles.svgIcon`
  );
};

export const ApiGetCountries = (): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/country/get-full-list`);
};

export const ApiGetRegions = (page: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/region/index?RegionSearch[country_code]=RU&page=${page}&per-page=50`
  );
};

export const ApiGetRegionCode = (country: string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/region/index?RegionSearch[country_code]=${country}`
  );
};

export const ApiGetAddresses = (query: string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/site/search-address?query=${query}`
  );
};

export const ApiUpdateOrganisation = (
  id: string,
  formData: any
): Promise<Response> => {
  return httpRequestPatchMultiData(
    `${config.localDomain}/v1/firm/update?id=${id}`,
    {},
    formData
  );
};

export const ApiCreateOrganisation = (formData: any): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/firm/create`,
    {},
    formData
  );
};

export const ApiGetNdsList = (): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/firm/get-nds-list`);
};

export const ApiGetUserProjects = (userId: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/index?ProjectSearch[authorId]=${userId}&ProjectSearch[status][]=2&ProjectSearch[status][]=15`
  );
};

export const ApiGetProjectsAsGipHelper = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-projects-invite-gip-helpers`
  );
};

export const ApiInviteGip = (id: string, data: any): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project/invite-gip-helper-by-user?userId=${id}`,
    {},
    data
  );
};

export const ApiInviteToProject = (
  id: string,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/notice/project-invite?user_id=${id}`,
    {},
    data
  );
};

export const ApiGetSpecializations = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/get-accessible-specialisation`
  );
};

export const ApiCreateTeam = (
  id: number,
  specializationId: number,
  name: string
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/team/create`,
    {},
    // @ts-ignore
    {
      name,
      firm_id: id,
      part_group_id: specializationId,
    }
  );
};

export const ApiGetAvailableTeams = (
  candidateId: number,
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/available-teams-for-invite?candidate_id=${candidateId}&firm_id=${id}`
  );
};

export const ApiTeamInvitation = (
  userId: number,
  teamId: number,
  firmId: number | null
): Promise<Response> => {
  const data = firmId ? { userId, teamId, firmId } : { userId, teamId };

  return httpRequestPost(`${config.localDomain}/v1/team/invite`, {}, data);
};

export const ApiGetInnerSpecializations = (
  candidateId: number,
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/get-teams-for-delete?userId=${candidateId}&firmId=${id}`
  );
};

export const ApiRemoveTeammate = (
  userId: number,
  teamId: number
): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/team/delete-teammate?userId=${userId}&teamId=${teamId}`
  );
};

export const ApiGetUsersByTeamId = (teamId: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/available-users-for-invite?teamId=${teamId}`
  );
};
