import { FC } from "react";

type TProps = {
  color: string;
};

export const FolderSVG: FC<TProps> = ({ color }) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49914 2.25C1.49914 1.65326 1.73619 1.08097 2.15815 0.65901C2.58011 0.237053 3.1524 0 3.74914 0H7.89514C9.33214 0 10.5351 0.84 11.3616 1.776C11.9766 2.472 12.7191 3 13.4991 3H20.2491C20.8459 3 21.4182 3.23705 21.8401 3.65901C22.2621 4.08097 22.4991 4.65326 22.4991 5.25V6.21C23.3541 6.6075 23.9091 7.524 23.7831 8.529L22.8231 16.215C22.7097 17.1226 22.2685 17.9574 21.5826 18.5624C20.8967 19.1674 20.0133 19.5008 19.0986 19.5H4.89814C3.98403 19.5001 3.10137 19.1663 2.41605 18.5614C1.73074 17.9565 1.28999 17.1221 1.17664 16.215L0.21664 8.529C0.1575 8.05648 0.249804 7.57738 0.480268 7.16066C0.710733 6.74394 1.06747 6.41108 1.49914 6.21V2.25ZM2.99914 6H20.9991V5.25C20.9991 5.05109 20.9201 4.86032 20.7795 4.71967C20.6388 4.57902 20.4481 4.5 20.2491 4.5H13.4991C12.0531 4.5 10.9341 3.5565 10.2381 2.769C9.56014 2.001 8.72914 1.5 7.89514 1.5H3.74914C3.55023 1.5 3.35946 1.57902 3.21881 1.71967C3.07816 1.86032 2.99914 2.05109 2.99914 2.25V6ZM2.44864 7.5C2.34231 7.50003 2.23721 7.52267 2.1403 7.56641C2.04339 7.61015 1.95689 7.674 1.88653 7.75372C1.81618 7.83344 1.76357 7.9272 1.73221 8.0288C1.70085 8.13039 1.69146 8.2375 1.70464 8.343L2.66464 16.029C2.73266 16.5733 2.9972 17.0741 3.40851 17.437C3.81983 17.8 4.34957 18.0002 4.89814 18H19.1001C19.6487 18.0002 20.1785 17.8 20.5898 17.437C21.0011 17.0741 21.2656 16.5733 21.3336 16.029L22.2936 8.343C22.3068 8.2375 22.2974 8.13039 22.2661 8.0288C22.2347 7.9272 22.1821 7.83344 22.1117 7.75372C22.0414 7.674 21.9549 7.61015 21.858 7.56641C21.7611 7.52267 21.656 7.50003 21.5496 7.5H2.44864Z"
      fill={color}
    />
  </svg>
);
