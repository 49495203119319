import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  view,
  update,
  remove,
  updateDate,
  getComments,
  complete,
  backToWork,
} from "./queries";

export const taskApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    taskView: view(builder),
    taskUpdate: update(builder),
    taskRemove: remove(builder),
    taskBackToWork: backToWork(builder),
    taskGetComments: getComments(builder),
    taskUpdateDate: updateDate(builder),
    taskComplete: complete(builder),
  }),
  overrideExisting: false,
});

export const {
  useTaskViewQuery,
  useLazyTaskViewQuery,
  useTaskUpdateMutation,
  useTaskRemoveMutation,
  useTaskBackToWorkMutation,
  useTaskGetCommentsQuery,
  useTaskUpdateDateMutation,
  useTaskCompleteMutation,
} = taskApi;
