import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import globalStyles from "../style.module.scss";

import { IndexPageTitle } from "../../../features/IndexPageTitle/IndexPageTitle";
import { useTitle } from "../../../hooks/useTitle";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { ProjectProcessForm } from "./ProjectProcessForm";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../../app/services/url/createUrlSearchString";
import { request } from "../../../../app/services/api/requestHandler";
import { GetProjectProcessPageExercise } from "../../../../app/services/api/indexPages/projectProcessPage";
import { createPaginationMeta } from "../../../../app/feature/indexPages/createData";
import {
  selectorProjectProcessMeta,
  selectorProjectProcessItems,
  setProjectProcess,
} from "../../../../app/feature/indexPages/projectProcess/projectProcessPage";
import { Wrapper } from "../../../UI/templates";
import style from "../Project/style.module.scss";
import { SortButton } from "../../../UI/components/Buttons/SortButton/SortButton";
import { Paginate } from "../../../UI/components/Pagination/Pagination";
import { UserSkeletonList } from "../../../UI/components/indexPageLists/UserList/UserSkeletonList";
import { ProjectProcList } from "../../../UI/components/indexPageLists/ProjectProcessList/ProjectProcessList";

const ProjectProcessPage = () => {
  useTitle("Заказы");
  const theme = useSelector(selectorAppTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchString, setSearchString] = useState(location.search);
  const [isLoad, setLoad] = useState(true);
  const [currentFilters, setCurrentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({
    sort: "-price",
  });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);
  const data = {
    projects: useSelector(selectorProjectProcessItems),
    meta: useSelector(selectorProjectProcessMeta),
  };
  const params = queryString.parse(location.search);
  useEffect(() => {
    // @ts-ignore
    setInitialSearchParams(params);
    setFirstLoad(false);
  }, []);
  useEffect(() => {
    navigate({
      search: searchString,
    });
    setLoad(true);
    if (searchString.length === 0) return;
    request(
      GetProjectProcessPageExercise(searchString),
      (res, _, headersValue) => {
        const meta = createPaginationMeta(headersValue);
        dispatch(setProjectProcess(res, meta));
        setLoad(false);
      }
    )(dispatch);
  }, [dispatch, searchString]);

  useEffect(() => {
    if (firstLoad) return;
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };
  const onClickLink = () => {};
  const onSubmitFilter = (data: any) => {
    setCurrentFilters(data);
  };
  const onResetFilter = () => {
    setInitialSearchParams({});
    setCurrentFilters({});
  };
  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };
  const paginate = data.projects?.length > 0 && (
    <Paginate
      page={Number(data.meta.paginationCurrentPage)}
      handleChange={onChangePage}
      count={data.meta.paginationPageCount}
      theme={theme}
      variant="outlined"
      shape="rounded"
    />
  );

  return (
    <div className={globalStyles.wrapper}>
      <div className={globalStyles.listWrapper}>
        <IndexPageTitle
          clickLink={onClickLink}
          title={"Заказы"}
          description={"Раздел или задание. Выбирайте и выполняйте."}
          page={"projectsProcess"}
          theme={theme}
        />
        <ProjectProcessForm
          initialValues={params}
          submitHandler={onSubmitFilter}
          resetHandler={onResetFilter}
          theme={theme}
        />
      </div>
      <Wrapper>
        <div className={style.paginationContainer}>
          {paginate}
          <div className={style.sortingContainer}>
            <SortButton
              posVariant={style.sortButtonMargin}
              clickHandler={onClickSort}
              sortType="date_publish"
              text="По новизне"
              isUp={params.sort === "date_publish"}
              isShow={
                params.sort === "date_publish" ||
                params.sort === "-date_publish"
              }
              isDisable={isLoad}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              clickHandler={onClickSort}
              sortType="price"
              text="По стоимости"
              isUp={params.sort === "price"}
              isShow={params.sort === "price" || params.sort === "-price"}
              isDisable={isLoad}
            />
          </div>
        </div>
      </Wrapper>
      {isLoad ? (
        <UserSkeletonList long theme={theme} />
      ) : (
        <ProjectProcList items={data.projects} theme={theme} />
      )}
      {data.projects?.length > 0 && (
        <Wrapper>
          <div className={style.paginationContainer}>{paginate}</div>
        </Wrapper>
      )}
    </div>
  );
};

export default ProjectProcessPage;
