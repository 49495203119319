// Возвращает JSON обьекты для использования в сокете
import { roomIdType } from "../../../../types/stateTypes/chatType";

export const authJSON = (accessToken: string) =>
  JSON.stringify({
    accessToken,
    type: "auth",
  });

export const getContactsJSON = (): string =>
  JSON.stringify({
    type: "getContacts",
  });

export const joinRoomJSON = (roomId: roomIdType): string => {
  return JSON.stringify({
    type: "joinToChat",
    roomId: Number(roomId),
  });
};

export const historyJSON = (roomId: roomIdType): string =>
  JSON.stringify({
    type: "getRoomHistory",
    roomId: Number(roomId),
  });

export const setMessageJSON = ({
  content,
  roomId,
}: {
  content: string;
  roomId: roomIdType;
}): string =>
  JSON.stringify({
    type: "message",
    content,
    roomId: Number(roomId),
  });

export const setSeenJSON = ({
  ids,
  roomId,
}: {
  ids: string[];
  roomId: string | number;
}): string =>
  JSON.stringify({
    type: "setSeen",
    roomId: Number(roomId),
    ids,
  });

export const leaveChatJSON = (roomId: roomIdType) =>
  JSON.stringify({
    type: "leaveChat",
    roomId,
  });
