import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  getProjectTasks,
  setError,
} from "../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import style from "./style.module.scss";
import TreeTasks from "./TreeTasks";
import { HorizontalTabsPanel } from "../../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import {
  fetchProjectTaskInTree,
  fetchProjectTaskOutTree,
} from "../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { SkeletonTask } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import ModalComment from "./ModalComment/ModalComment";
import { TModalData, modalInitialData } from "../CheckTasks/CheckTaskCard";

interface ITasksProps {
  setCurrentTabMemo: () => void;
}
export const Tasks: React.FC<ITasksProps> = ({ setCurrentTabMemo }) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { inTasks, isLoad, error, outTasks } = useSelector(getProjectTasks);
  const { project } = useSelector(getProjectViewData);

  const [modalData, setModalData] = useState<TModalData>(modalInitialData);

  useEffect(() => {
    if (project && !project?.can.editByGipHelper) {
      setCurrentTabMemo();
    }
  }, [project]);

  const tabs = [
    {
      id: 1,
      label: "Входящие задания",
      incomplete: project?.tabs.tasksIn?.dot,
    },
    {
      id: 2,
      label: "Исходящие задания",
      incomplete: project?.tabs.tasksOut?.dot,
    },
  ];

  const [activeTab, setActiveTab] = useState<number | string>(tabs[0].id);

  const setActiveTabMemo = useCallback((tabId: number | string) => {
    setActiveTab(tabId);
  }, []);

  useEffect(() => {
    if (activeTab === 1 && params.id) {
      dispatch(fetchProjectTaskInTree(+params.id));
    }
    if (activeTab === 2 && params.id) {
      dispatch(fetchProjectTaskOutTree(+params.id));
    }
  }, [activeTab]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  const components = [
    {
      id: 1,
      component: (
        <TreeTasks
          projectId={Number(params?.id)}
          key={1}
          id={1}
          data={inTasks || []}
          type={"project"}
          chatId={project?.chatId}
          setModalData={setModalData}
        />
      ),
    },
    {
      id: 2,
      component: (
        <TreeTasks
          projectId={Number(params?.id)}
          id={2}
          key={2}
          data={outTasks || []}
          type={"project"}
          setActiveTabMemo={setActiveTabMemo}
          chatId={project?.chatId}
          setModalData={setModalData}
        />
      ),
    },
  ];

  return (
    <>
      <div className={style.wrapper}>
        <HorizontalTabsPanel
          tabs={tabs}
          components={components}
          activeTab={activeTab}
          setActiveTab={setActiveTabMemo}
          isLoad={isLoad}
          skeleton={<SkeletonTask />}
        />
        <ModalWrapper
          show={modalData.open}
          handleCloseModal={() => setModalData(modalInitialData)}
          head={<h1 className={style.commentModalHeader}>Комментарии</h1>}
        >
          <ModalComment name="" taskId={modalData.id} />
        </ModalWrapper>
      </div>
    </>
  );
};
