import { FC, useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import cls from "./ModalComment.module.scss";
import { fetchCommentsByTaskId } from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import {
  getProjectTasks,
  removeTaskComments,
} from "../../../../../../app/feature/ProjectView/TaskReducer/projectTask";

export type ModalCommentProps = {
  taskId: number | null;
  name: string;
};

const ModalComment: FC<ModalCommentProps> = ({ taskId }) => {
  const dispatch = useAppDispatch();
  const { comments, isLoadComments } = useAppSelector(getProjectTasks);

  useEffect(() => {
    dispatch(fetchCommentsByTaskId(taskId!));

    return () => {
      dispatch(removeTaskComments());
    };
  }, []);

  if (isLoadComments) {
    return (
      <div className={cls.skeleton}>
        <Skeleton sx={{ height: "100px" }} />
      </div>
    );
  }

  return (
    <div className={cls.commentsList}>
      {comments.length ? (
        comments.map((comment) => (
          <div className={cls.comment}>
            <h2>{comment.author}</h2>
            <h2>{comment.status}</h2>
            <p>{comment.text}</p>
            <p>{comment.date}</p>
          </div>
        ))
      ) : (
        <p className={cls.notFound}>Комментарии не найдены</p>
      )}
    </div>
  );
};

export default ModalComment;
