import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import cn from "classnames";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import classes from "./Admin.module.scss";
import {
  deleteProjectAccess,
  getProjectAccessByProject,
} from "../../../../../app/feature/ProjectView/AdminReducer/thunks";
import { getProjectUsersList } from "../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import AddRights from "./modals/AddRights";
import { AddFile } from "../../../../newUI/SVG";
import { useAppSelector } from "../../../../../app/store";
import UserCard from "./UserCard";
import CompleteTask from "./modals/CompleteTask";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";

export type TModalTaskComplete = Record<string, any> | null;

interface IAdminProps {
  setCurrentTabMemo: () => void;
}
export const Admin: React.FC<IAdminProps> = () => {
  const { project } = useSelector(getProjectViewData);
  const { projectAccessByProject } = useAppSelector(getProjectUsersList);

  const dispatch = useDispatch();
  const { error } = useSelector(getProjectUsersList);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalEditGip, setModalEditGip] = useState(false);
  const [modalTaskComplete, setModalTaskComplete] =
    useState<TModalTaskComplete>(null);

  const [showAddRights, setShowAddRights] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [error]);

  useEffect(() => {
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  }, []);

  const handleCloseAddRightsModal = () => {
    setShowAddRights(false);
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  };

  const handleCloseTaskCompleteModal = () => {
    setModalTaskComplete(null);
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  };

  const handleRemoveUser = async (id: number) => {
    if (
      !window.confirm("Вы уверены, что хотите исключить данного пользователя?")
    ) {
      return;
    }

    await dispatch(deleteProjectAccess(id));
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  };

  const canAddRoles = Boolean(
    project?.can?.edit && projectAccessByProject.length
  );

  if (project) {
    return (
      <div className={cn(classes.wrapper, classes.object_block1)}>
        {canAddRoles && (
          <div className={classes.addButton}>
            <p>Добавить пользователя</p>
            <IconButton
              onClick={() => {
                setShowAddRights(true);
              }}
            >
              <AddFile width="24" height="24" />
            </IconButton>
          </div>
        )}
        {projectAccessByProject.map((data: any) => (
          <UserCard
            {...data}
            modalEditGip={modalEditGip}
            modalEditUser={modalEditUser}
            setModalEditUser={setModalEditUser}
            setModalEditGip={setModalEditGip}
            setModalTaskComplete={setModalTaskComplete}
            handleRemoveUser={handleRemoveUser}
          />
        ))}
        <ModalWrapper
          show={Boolean(modalTaskComplete)}
          handleCloseModal={handleCloseTaskCompleteModal}
          head={
            <h2 className={classes.addRightsHeader}>
              Рассмотрение заявки на роль «{modalTaskComplete?.role ?? ""}»
            </h2>
          }
        >
          <CompleteTask
            handleCloseModal={handleCloseTaskCompleteModal}
            modalTaskComplete={modalTaskComplete}
            setModalTaskComplete={setModalTaskComplete}
          />
        </ModalWrapper>
        <ModalWrapper
          show={showAddRights}
          handleCloseModal={handleCloseAddRightsModal}
          head={
            <h2 className={classes.addRightsHeader}>
              Добавление прав доступа к проекту
            </h2>
          }
        >
          <AddRights
            projectId={+project.id}
            handleCloseModal={handleCloseAddRightsModal}
          />
        </ModalWrapper>
      </div>
    );
  }
  return <div></div>;
};
