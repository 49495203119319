import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getUserTokenByLocalStorage,
  parseTokenBody,
  userIsAuth,
} from "../../app/services/auth/auth";
import { isDevelopment } from "../../app/services/app/app";
import { request } from "../../app/services/api/requestHandler";
import { ApiGetUserData } from "../../app/services/api/user/user";
import { setUserAgreement } from "../../app/feature/appUser/appUser";

/*
  Также тут записыватся информация, в redux
  в appUser { agreement_personal },
  что пользователь принял agreement_personal.
  agreement_personal берется какой есть,
  на тот случай если токен устарел,
  если null то будет показано окно с предупреждением
*/

export const useJivo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDevelopment() && userIsAuth()) {
      const { data } = parseTokenBody(getUserTokenByLocalStorage()!);
      const { id } = data;
      request(ApiGetUserData(id), (data) => {
        // eslint-disable-next-line
        const { email, name, phone, agreement_personal } = data;
        dispatch(setUserAgreement({ agreement_personal }));
        // @ts-ignore
        jivo_api.setContactInfo({
          name,
          email,
          phone,
        });
      })();
    }
  }, []);
};
