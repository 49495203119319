import { PublishObjectModel } from "src/FSD/entities/publishObject";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalsFeedbackTask = () => {
  const dispatch = useAppDispatch();

  const { feedbackTask } = useAppSelector(
    PublishObjectModel.modals.getModalsData
  );

  const handleOpenFeedbackTaskRequest = (taskId: number) => {
    dispatch(
      PublishObjectModel.modals.setFeedbackTaskActive({
        isOpen: true,
        taskId,
      })
    );
  };

  const handleCloseFeedbackTaskRequest = () => {
    dispatch(
      PublishObjectModel.modals.setFeedbackTaskActive({
        isOpen: false,
        taskId: null,
      })
    );
  };

  return {
    feedbackTask,
    handleOpenFeedbackTaskRequest,
    handleCloseFeedbackTaskRequest,
  } as const;
};
