import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
  CombinedState,
} from "@reduxjs/toolkit";
import { getParentsProjects, getProject, TProject } from "./thunks";

export type TState = {
  data: TProject[];
  fulfilled: boolean;
  openSubProjects: number[];
  subData: Record<string, any>;
  projectStatus: number | null;
  subPending: number[];
  preQuery: string;
  status: number | null;
  totalCount: number | null;
  pending: boolean;
  error: string | null;
};

const initialState: TState = {
  data: [],
  fulfilled: false,
  openSubProjects: [],
  subData: {},
  subPending: [],
  preQuery: "",
  projectStatus: null,
  status: null,
  totalCount: null,
  pending: false,
  error: null,
};

const dashboardProjects = createSlice({
  name: "dashboardProjects",
  initialState,
  reducers: {
    setFulfilled(state, action) {
      state.fulfilled = action.payload;
    },
    setPreQuery(state, action) {
      state.preQuery = action.payload;
    },
    setProjectStatus(state, action) {
      const projectId = action.payload;

      const completeData = Object.values(state.subData)
        .reduce(
          (completeData: any[], item: any) => [...completeData, ...item],
          []
        )
        .concat(state.data);

      const project = completeData.find(
        (project: any) => project.id === projectId
      );

      if (project) {
        state.projectStatus = project.status;
      }
    },
    setOpenSubProjects(state, action) {
      const id = action.payload;
      const includes = state.openSubProjects.includes(id);

      if (includes) {
        state.openSubProjects = state.openSubProjects.filter(
          (projectId: any) => projectId !== id
        );
      } else {
        state.openSubProjects.push(id);
      }
    },
    clearProjects() {
      return initialState;
    },
    clearProjectsData(state) {
      state.data = [];
      state.openSubProjects = [];
      state.subData = {};
      state.subPending = [];
      state.projectStatus = null;
      state.status = null;
      state.totalCount = null;
      state.pending = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getParentsProjects), (state) => {
        state.pending = true;
      })
      .addMatcher(isFulfilled(getParentsProjects), (state, action) => {
        const { data, totalCount } = action.payload;

        const projects = data.map(
          ({
            id,
            name,
            code,
            status,
            parent_id,
            haveChilds,
            dashboardCounts,
          }: any) => {
            return {
              id,
              haveChilds,
              name: code ? `${code} ${name}` : name,
              status: status.key,
              parentId: parent_id,
              dashboardCounts,
            };
          }
        );

        state.data = state.data.concat(projects);
        state.pending = false;
        state.totalCount = totalCount;
      })
      .addMatcher(isRejected(getParentsProjects), (state, action) => {
        state.pending = false;
        state.error = action.payload as string;
      })
      .addMatcher(isPending(getProject), (state, { meta }) => {
        state.subPending.push(meta.arg.id);
      })
      .addMatcher(isFulfilled(getProject), (state, { payload: data, meta }) => {
        const projectId = meta.arg.id;

        state.subPending = state.subPending.filter((id) => id !== projectId);

        if (!data.length) {
          state.subData[projectId] = [];
          return;
        }

        const projects = data.map(
          ({
            id,
            name,
            code,
            status,
            parent_id,
            haveChilds,
            dashboardCounts,
          }: any) => {
            return {
              id,
              haveChilds,
              name: code ? `${code} ${name}` : name,
              status: status.key,
              parentId: parent_id,
              dashboardCounts,
            };
          }
        );

        state.subData[projectId] = projects;
      })
      .addMatcher(isRejected(getProject), (state, { meta }) => {
        state.subPending = state.subPending.filter(
          (projectId) => projectId !== meta.arg.id
        );
      });
  },
});

export const {
  setOpenSubProjects,
  clearProjects,
  clearProjectsData,
  setProjectStatus,
  setPreQuery,
  setFulfilled,
} = dashboardProjects.actions;

export const getDashboardProjects = (state: CombinedState<any>) =>
  state.dashboard.projects;

export default dashboardProjects;
