import React from "react";
import {
  Avatar as AvatarUi,
  AvatarProps as MuiAvatarProps,
} from "@mui/material";
import classNames from "classnames";
import style from "./style.module.scss";

export type AvatarUiProps = {
  variant?: string;
  theme?: "old" | "dark" | "light";
  isShowGlow?: boolean;
  size?: "normal" | "large";
};

const sizesList = {
  normal: 50,
  large: 100,
};

export const Avatar: React.FC<
  AvatarUiProps & Omit<MuiAvatarProps, keyof AvatarUiProps>
> = (props) => {
  const { variant = "", size = "normal", isShowGlow = false, ...other } = props;

  const sx = {
    borderRadius: "4px",
    width: `${sizesList[size]}px`,
    height: `${sizesList[size]}px`,
  };

  return (
    <AvatarUi
      className={classNames(isShowGlow && style.glow, variant)}
      sx={sx}
      {...other}
    />
  );
};
