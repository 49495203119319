import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { selectorEditRequestJoinData } from "../../../app/feature/eventEmitter/organisation/eventEditRequestJoin";
import { EditRequestJoinForm } from "./EditRequestJoinForm";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const EditRequestJoin: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  const { title } = useSelector(selectorEditRequestJoinData);
  const { Popup } = useUserWorkflow();

  const onClose = () => {
    Popup.joinRequest.close();
    Popup.joinRequest.set({});
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={
          <p
            style={{ color: "white", lineHeight: "25px", margin: "24px 0" }}
          >{`Запрос на присоединение к организации ${title}`}</p>
        }
        body={<EditRequestJoinForm />}
      />
    </Modal>
  );
};
