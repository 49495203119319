export const editorConfig = {
  image: {
    // Configure the available styles.
    styles: ["alignLeft", "alignCenter", "alignRight"],
    // Configure the available image resize options.
    resizeOptions: [
      {
        name: "resizeImage:original",
        label: "Original",
        value: null,
      },
      {
        name: "resizeImage:100",
        label: "100%",
        value: "100",
      },
      {
        name: "resizeImage:75",
        label: "75%",
        value: "75",
      },
      {
        name: "resizeImage:50",
        label: "50%",
        value: "50",
      },
    ],

    // You need to configure the image toolbar, too, so it shows the new style
    // buttons as well as the resize buttons.
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "resizeImage",
      "|",
      "toggleImageCaption",
      "imageTextAlternative",
      "image",
      "InsertImage",
      "uploadImage",
    ],
  },
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "Heading",
      "Image",
      "ImageCaption",
      "ImageStyle",
      "ImageToolbar",
      "ImageUpload",
      "ImageInsert",
      "|",
      "outdent",
      "indent",
      "|",
      "alignment",
      "Bold",
      "Italic",
      "Underline",
      "strikethrough",
      "|",
      "superscript",
      "subscript",
      "blockQuote",
      "removeFormat",
      "specialCharacters",
      "horizontalLine",
      "|",
      "numberedList",
      "bulletedList",
      "todoList",
      "|",
      "pageBreak",
      "fontSize",
      "FontColor",
      "fontFamily",
      "fontBackgroundColor",
      "highlight",
      "link",
      "insertTable",
    ],

    viewportTopOffset: 30,
    shouldNotGroupWhenFull: true,
  },
  fontSize: {
    options: [10, 12, 14, 16, "default", 18, 20, 22, 24, 26, 28, 30],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],
  },
  fontColor: {
    colors: [
      { color: "#9dafbd", label: "Главный серый" },
      { color: "#30ab69", label: "Главный зелёный" },
      {
        color: "rgb(0, 0, 0)",
        label: "Чёрный",
      },
      {
        color: "rgb(77, 77, 77)",
        label: "Темно-серый",
      },
      {
        color: "rgb(153, 153, 153)",
        label: "Серый",
      },
      {
        color: "rgb(230, 230, 230)",
        label: "Светло-серый",
      },
      {
        color: "rgb(255, 255, 255)",
        label: "Белый",
        hasBorder: true,
      },
      {
        color: "rgb(230, 76, 76)",
        label: "Красный",
      },
      {
        color: "rgb(230, 153, 76)",
        label: "Оранжевый",
      },
      {
        color: "rgb(230, 230, 76)",
        label: "Жёлтый",
      },
      {
        color: "rgb(153, 230, 76)",
        label: "Салатовый",
      },
      {
        color: "rgb(76, 230, 76)",
        label: "Зелёный",
      },
      {
        color: "rgb(76, 230, 153)",
        label: "Аквамариновый",
      },
      {
        color: "rgb(76, 230, 230)",
        label: "Бирюзоый",
      },
      {
        color: "rgb(76, 153, 230)",
        label: "Голубой",
      },
      { color: "rgb(76, 76, 230)", label: "Синий" },
      { color: "rgb(153, 76, 230)", label: "Фиолетоый" },
    ],
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
};
