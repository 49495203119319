import { TSelectItem } from "../../features/Forms/SetGipForm/types";

export const selectAllItem = { id: "add-all", title: "Выбрать все" };

export const getCompleteOptions = (
  value: TSelectItem[],
  options: TSelectItem[],
  canSelectAll: boolean
) => {
  if (!canSelectAll) {
    return options;
  }

  return value.length === options.length ? [] : [selectAllItem, ...options];
};

export const getNewValue = (
  options: TSelectItem[],
  newValue: TSelectItem[]
) => {
  const containsAll = newValue.find((item) => item.id === selectAllItem.id);

  if (containsAll) {
    return options;
  }

  return newValue;
};

export const listItemSX = { color: "black", fontWeight: "bold" };
