import { createReducer } from "@reduxjs/toolkit";
import { canUserInitialState, projectInitialState } from "./initialState";
import C from "./constants";
import { apiProjectType } from "../../../../types/api/responseTypes/apiProjectType";
import {
  canConsiderConflictDeleteGip,
  canUserCompareGipAction,
  canUserDeleteRequestGip,
  canUserExecutionGipRequestAction,
  canUserRequestDeleteGip,
  canUserRequestGip,
  canUserSearchGipAction,
  canUserSetGip,
  canUserStopSearchAction,
} from "../../../services/user/userCan";
import { createData } from "./createData";
import { projectType } from "../../../../types/stateTypes/project";

export type ProjectIndividualType = projectType;

export type ActionProjectType = {
  type: string;
  payload: apiProjectType;
  user: {
    isAdmin: boolean;
    isAuth: boolean;
  };
};

export const projectReducer = createReducer(projectInitialState, (builder) => {
  builder.addCase(C.SET_DATA_PROJECT, (state, action: ActionProjectType) => ({
    ...state,
    id: action.payload.id,
    name: action.payload.name,
    canUser:
      action.payload && action.payload.can
        ? action.payload.can
        : canUserInitialState,
    info: createData(action.payload),
    parameters: action.payload.projectParameters
      ? action.payload.projectParameters.map((item) => ({
          name: item.type.name,
          value: item.value,
          unit: item.type.unit,
        }))
      : [],
    files: action.payload.files
      ? action.payload.files.map((item) => ({
          name: item.name,
          url: item.url,
        }))
      : [],
    gipProcess: action.payload.gipProcess
      ? {
          gipTypesSearch: action.payload.gipProcess.gipTypes.map(
            (item) => item.value
          ),
          price: action.payload.gipProcess.price,
          status: action.payload.gipProcess.status,
        }
      : null,
    canMenu: action.payload.can
      ? {
          canStopSearch: canUserStopSearchAction(action.payload),
          canSearchGip: canUserSearchGipAction(action.payload),
          canCompareGip: canUserCompareGipAction(action.payload),
          canExecutionGipRequest: canUserExecutionGipRequestAction(
            action.payload
          ),
          canRequestGip: canUserRequestGip(action.payload),
          canDeleteRequestGip: canUserDeleteRequestGip(action.payload),
          canSetGip: canUserSetGip(action.user.isAdmin, action.payload),
          canRequestDeleteGip: canUserRequestDeleteGip(action.payload),
          canConsiderConflictDeleteGip: canConsiderConflictDeleteGip(
            action.payload
          ),
        }
      : projectInitialState.canMenu,
    gipAccess: action.payload.gipAccess
      ? action.payload.gipAccess.map((item) => ({
          ...item,
          accessCode: item.access_code,
          processId: item.process_id,
          projectId: item.project_id,
          groupId: item.group_id,
          userId: item.user_id,
          userTypeId: item.user_type_id,
          taskId: item.task_id,
          userType: item.userType,
        }))
      : [],
    requestRemoveGipTask: action.payload.requestRemoveGipTask,
    description: action.payload.description,
    gip: action.payload.gip
      ? {
          name: `${action.payload.gip.name} ${action.payload.gip.surname}`,
          link: `/user/${action.payload.gip.id}?tab=info`,
          id: action.payload.gip.id,
          price: action.payload.gipProcess
            ? String(action.payload.gipProcess.price)
            : "",
        }
      : null,
  }));
  builder.addCase(C.CLEAR_DATA_PROJECT, () => ({
    ...projectInitialState,
  }));
});
