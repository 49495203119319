import {
  CounterType,
  UserDataListType,
} from "../../../../../../types/stateTypes/indexPages/indexPageUserDataType";
import {
  createUserRating,
  getUserFullStringAddress,
  getUserName,
} from "../../../../../services/user/user";
import { ApiUserCustomerType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../../../../app.cofig";
import { ApiCustomerPageDataType } from "../../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const createCounterByCustomer: (
  data: ApiUserCustomerType
) => CounterType[] = (data) => {
  return [
    {
      title: "Активных проектов",
      value: data.customerWorkProjectCount,
      url: `/project/search?ProjectSearch[authorId]=${data.id}`,
    },
    {
      title: "Завершено",
      value: data.customerDoneProjectCount,
      url: `/project/search?ProjectSearch[authorId]=${data.id}&ProjectSearch[status]=3`,
    },
    {
      title: "Отзывы",
      value: data.feedbacks?.length || 0,
      url: `/user/${data.id}?tab=feedback`,
    },
  ];
};

export const createCustomerListFromApi: (
  data: ApiCustomerPageDataType["data"]
) => UserDataListType = (data) =>
  data.map((item) => ({
    name: getUserName(item),
    address: item.region ? getUserFullStringAddress(item) : null,
    aboutText: item.about!,
    counterItems: createCounterByCustomer(item as ApiUserCustomerType),
    id: item.id,
    isConfirm: item.tested!,
    ratingValue: createUserRating(Number(item.rating!.customer)),
    specialItems: item.skills!.map((item) => item.name),
    avatarUrl: `${config.localDomain}${item.photo}`,
    workWithBim: item.work_with_bim!,
    premium: item.premium,
    skills: item.skills,
    firms: item.firms
      .slice(0, 2)
      .map((item: any) => ({ id: item.id, title: item.title })),
  }));
