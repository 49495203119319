import { ReactNode } from "react";
import ModalHeader from "./ModalHeader";
import ModalForm from "./ModalForm";
import ModalControls from "./ModalControls";
import ModalLayout from "./ModalLayout";
import ModalWrapper from "./ModalWrapper";
import ModalPreloader from "./ModalPreloader";
import styles from "./Modal.module.scss";
import ModalContext from "./ModalContext";

type ModalProps = {
  children: ReactNode;
  isActive: boolean;
  handleClose: () => void;
};

export const Modal = ({ children, isActive, handleClose }: ModalProps) => {
  if (!isActive) {
    return null;
  }

  return (
    <ModalContext.Provider value={{ isActive, handleClose }}>
      <ModalWrapper>
        <div className={styles.modal__wrapper}>{children}</div>
      </ModalWrapper>
    </ModalContext.Provider>
  );
};

Modal.Header = ModalHeader;
Modal.Form = ModalForm;
Modal.Controls = ModalControls;
Modal.Layout = ModalLayout;
Modal.Preloader = ModalPreloader;
