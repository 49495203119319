import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { selectorEditBankAccountData } from "../../../app/feature/eventEmitter/organisation/eventEditBankAccount";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const EditBankAccount: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<
      any,
      any
    > as React.ReactElement<any, any>;

  const { editing } = useSelector(selectorEditBankAccountData);
  const action = editing ? "Обновить" : "Создать";

  const { Popup } = useUserWorkflow();

  const onClose = () => {
    Popup.editBankAccount.close();
    Popup.editBankAccount.set({});
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={<p style={{ color: "white" }}>{`${action} счет`}</p>}
        body={<></>}
      />
    </Modal>
  );
};
