import { Link } from "react-router-dom";
import classNames from "classnames";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./Avatar.module.scss";
import DefaultAvatar from "../../../../../media/images/avatarDefault.jpg";

type Props = {
  photoUrl: string;
  borderColor?: string;
  fio?: string;
  upperText?: string;
  lowerText?: string;
  className?: string;
  userId: number;
};

export const Avatar = ({
  photoUrl,
  borderColor,
  fio,
  upperText,
  lowerText,
  className,
  userId,
}: Props) => {
  return (
    <div className={classNames(styles.avatar, className)}>
      <img
        style={{
          border: `2px solid ${borderColor}`,
        }}
        src={photoUrl || DefaultAvatar}
        alt="Профиль пользователя"
      />
      <div className={styles.avatar__info}>
        {upperText && <span>{upperText}</span>}
        <Link
          to={`${config.localDomain}/user/${userId}`}
          className={styles.avatar__info__link}
          target="_blank"
        >
          {fio}
        </Link>
        {lowerText && <span>{lowerText}</span>}
      </div>
    </div>
  );
};
