import { FC, ReactElement } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { CreateTeamForm } from "./CreateTeamForm";
import { selectorEventCreateTeamData } from "../../../app/feature/eventEmitter/organisation/eventCreateTeam";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const CreateTeam: FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<
      any,
      any
    > as ReactElement<any, any>;

  const { Popup } = useUserWorkflow();

  const data = useSelector(selectorEventCreateTeamData);

  const onClose = () => {
    Popup.createTeam.close();
    Popup.createTeam.set({});
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={
          <p style={{ color: "white" }}>{data?.name ?? "Создание команды"}</p>
        }
        body={<CreateTeamForm data={data} />}
      />
    </Modal>
  );
};
