import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPostMultiData,
  httpRequestPutMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetPosts = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/post/index?PostSearch[published]=true&page=1&per-page=3`
  );

export const ApiGetPostsList = (paramsString: string): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/post/index${paramsString}&per-page=5`
  );

export const ApiGetPostsListByTag = (
  searchString: string,
  label: string,
  num: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/post/index${searchString}PostSearch[tag_label]=${label}&per-page=${num}&PostSearch[published]=true`
  );

export const ApiGetPostsListForBlog = (
  label: string,
  num: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/post/index?PostSearch[tag_label]=${label}&per-page=${num}&PostSearch[published]=true`
  );

export const ApiGetRandomProject = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/get-random-project?expand=processPublishedCost,bannerUrl`
  );

export const ApiGetPostForView = (id: string | number): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/post/view?id=${id}`);

export const ApiDeletePost = (id: string | number): Promise<Response> =>
  httpRequestDelete(`${config.localDomain}/v1/post/delete?id=${id}`);

export const ApiUpdatePost = (
  id: string | number,
  formData: any
): Promise<Response> =>
  httpRequestPutMultiData(
    `${config.localDomain}/v1/post/update?id=${id}`,
    {},
    formData
  );

export const ApiPostCreatePosts = (formData: any): Promise<Response> =>
  httpRequestPostMultiData(
    `${config.localDomain}/v1/post/create`,
    {},
    formData
  );
