import React from "react";
import moment from "moment/moment";
import classNames from "classnames";
import { fetchProjectProcessTaskComplete } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessTaskComplete";
import { useAppDispatch } from "src/app/store";
import { InitialStateStoppedType } from "src/app/feature/ProjectProcessView/HeaderBtn/types/projectProcessStoppedTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import style from "./Stop.module.scss";

interface StopRowProps {
  stop: InitialStateStoppedType;
}

const StopRow = (props: StopRowProps) => {
  const { stop } = props;
  const dispatch = useAppDispatch();
  const completeHandler = (id: number) => {
    dispatch(fetchProjectProcessTaskComplete(id));
  };

  return (
    <>
      <div className={classNames(!stop.checked && style.stop)}>
        {stop.content}
      </div>
      <div className={classNames(!stop.checked && style.stop)}>
        {stop.date_create
          ? moment(stop.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
              "DD.MM.YYYY"
            )
          : null}
      </div>
      <div>
        {stop.date_complete
          ? moment(stop.date_complete, "DD.MM.YYYY HH:mm:ss Z").format(
              "DD.MM.YYYY"
            )
          : "-"}
      </div>
      <div className={style.stopRowAction}>
        {!stop?.checked && (
          <Button variant="outlined" onClick={() => completeHandler(stop.id)}>
            Закрыть
          </Button>
        )}
      </div>
    </>
  );
};

export default StopRow;
