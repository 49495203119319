import axios, { AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { config } from "../../lib/configs/app.config";
import { getErrorMessage } from "../../../../app/services/api/requestHandler";

export const requestsList = new Map<string, AbortController>();

export const removeRequest = (url: string) => {
  const urlTrimmed = url.split("?")[0];
  requestsList.delete(urlTrimmed);
};

export const addRequest = (url: string) => {
  const requestController = requestsList.get(url);

  if (requestController) {
    requestController.abort();
  }

  const controller = new AbortController();
  requestsList.set(url, controller);

  return controller;
};

const getTokenForHeaders = () => localStorage.getItem("userToken");

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      isMultipart?: boolean;
    },
    unknown,
    unknown
  > =>
  async (args) => {
    const { url, method, data, params } = args;

    const isMultipart = args?.isMultipart ?? false;

    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${getTokenForHeaders()}`,
      "Content-Type": isMultipart ? "multipart/form-data" : "application/json",
    };

    const controller = addRequest(url);

    try {
      const result = await axios({
        url: `${config.localDomain}/${url}`,
        signal: controller.signal,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data };
    } catch (axiosError: any) {
      const error = getErrorMessage(axiosError.response.data);
      return { error };
    }
  };

/*
возможно еще понадобится

export const customFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: config.localDomain,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${getTokenForHeaders()}`);
      return headers;
    },
    fetchFn: async (args: any) => {
      const { url, method, headers, body } = args;

      if (!args.isMultipart) {
        headers.Accept = "application/json";
        headers["Content-Type"] = "application/json";
        headers["X-Requested-With"] = "XMLHttpRequest";
      }

      try {
        const controller = addRequest(url);

        const settings: Record<string, any> = {
          method,
          headers,
          signal: controller.signal,
        };

        if (body) {
          settings.body = JSON.stringify(body);
        }

        const response = await fetch(url, settings);

        if (!response.ok || response.status >= 400) {
          const error = await response.json();
          throw new Error(error.message);
        }

        return response;
      } catch (error) {
        const textError = getErrorMessage(error);
        throw new Error(textError);
      }
    },
  });


*/
