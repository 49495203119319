import React, { useState } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import moment from "moment/moment";
import cls from "./Version.module.scss";
import { VersionTaskType } from "../../../../app/feature/ProjectView/TaskReducer/types";
import CustomButton from "../../CustomButton/CustomButton";
import { Dot } from "../../Dot/Dot";
import {
  TextLink,
  ThemeTextLink,
} from "../../../UI/components/TextLink/TextLink";
import { VersionThreeDot } from "./VersionThreeDot";
import { NewDatePicker } from "../../NewDatePicker/NewDatePicker";
import { PencilSquareSVG } from "../../SVG";
import {
  fetchProjectTaskAll,
  fetchTaskDateDeadlineUpdate,
} from "../../../../app/feature/ProjectView/TaskReducer/thunks";
import { useAppDispatch } from "../../../../app/store";
import { NewModal } from "../../NewModal/NewModal";
import { ModalComment } from "./ModalComment/ModalComment";

interface VersionProps {
  task: VersionTaskType;
  num: string;
  processId?: number;
  projectId?: number;
  type?: "project" | "process";
}

const pencilSx = {
  color: "#838A93",
  "&:hover": {
    cursor: "pointer",
    color: "#d5d6de",
  },
};
export const Version = ({
  task,
  num,
  projectId,
  processId,
  type,
}: VersionProps) => {
  const isComment = task.comment.length > 0;
  const isRedDate = task.status.bgClass === "pirs-red";

  const dispatch = useAppDispatch();
  const [viewDatePicker, setViewDatePicker] = useState(false);
  const dateCreateParse = moment(task.status.date, "DD.MM.YYYY").toDate();
  const [modalComment, setModalComment] = useState(false);
  const [date, setDate] = useState(dateCreateParse);

  async function onSaveDate() {
    setViewDatePicker(false);
    const dataParams = {
      date_deadline: moment(date, "DD.MM.YYYY").format("DD.MM.YYYY"),
    };
    await dispatch(fetchTaskDateDeadlineUpdate({ id: task.id, dataParams }));
    if (projectId && type === "project") {
      await dispatch(fetchProjectTaskAll({ id: projectId, status: "" }));
    }
  }

  return (
    <div className={cls.version}>
      <div className={cls.flexDot}>
        {task.dot && <Dot status={"warning"} size={9} />}
        <p className={cls.name}>{`Задание ${num} от ${task.dateStart}`}</p>
      </div>

      <div className={cls.status}>
        {parse(task.status.statusString)}
        <div className={cls.status_date}>
          <span className={classNames(isRedDate && cls.redDate)}>
            {task.status.date}
          </span>
          {task.can["update-date-deadline"] && (
            <IconButton sx={pencilSx} onClick={() => setViewDatePicker(true)}>
              <PencilSquareSVG width={"18"} height={"18"} />
            </IconButton>
          )}
          {viewDatePicker && (
            <NewDatePicker
              inline
              onClose={() => setViewDatePicker(false)}
              changeDateHandler={(date) => {
                if (date) {
                  setDate(date);
                }
              }}
              startDateProp={date}
              onSave={onSaveDate}
            />
          )}
        </div>
      </div>
      {isComment && (
        <>
          <NewModal
            active={modalComment}
            setActive={setModalComment}
            title={"История комментариев"}
            activeClass={cls.modal}
          >
            <ModalComment taskId={task.id} />
          </NewModal>
          <CustomButton
            borderRadius={20}
            border={"1px solid rgba(10, 166, 153, 0.40)"}
            background={"inherit"}
            color={"#0AA699"}
            width={150}
            fontSize={16}
            fontWeight={400}
            height={32}
            onClick={() => setModalComment(true)}
          >
            Комментарии
          </CustomButton>
        </>
      )}
      {!isComment && (
        <CustomButton
          border={"1px solid rgba(131, 138, 147, 0.20)"}
          borderRadius={20}
          background={"inherit"}
          color={"rgba(131, 138, 147, 0.50)"}
          width={150}
          fontSize={16}
          fontWeight={400}
          height={32}
          style={{ cursor: "default" }}
        >
          Комментарии
        </CustomButton>
      )}
      <div className={cls.container}>
        <div className={cls.container_files}>
          <p>Вложение</p>
          {task.haveFiles ? (
            <TextLink
              url={`/task/download-files?id=${task.id}`}
              customTheme={ThemeTextLink.OCEANIC}
              redirect
              target={"_blank"}
            >
              Архив.zip
            </TextLink>
          ) : (
            <span>-</span>
          )}
        </div>
        <VersionThreeDot
          task={task}
          projectId={projectId}
          processId={processId}
          type={type}
        />
      </div>
    </div>
  );
};
