import React, { FC, useRef, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import cn from "classnames";
import Switch from "../../../../newUI/Switch/Switch";
import SearchField from "../SearchField";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardSections,
  setDataTree,
  setOpenTabs,
} from "../../../../../app/feature/dashboard/sections/index";
import DashboardSkeleton from "../Skeleton";
import {
  getDashboardDestinations,
  setShowFulfilledSections,
} from "../../../../../app/feature/dashboard/destinations";
import Folder from "./Sections/Folder";
import FilterSections from "../Filter/Sections";
import Filter from "../Filter/Filter";
import useFilter from "../Filter/useFilter";
import { getSections } from "../../../../../app/feature/dashboard/sections/thunks";
import { FolderArrow, FolderArrowFill } from "../../../../newUI/SVG";
import globalStyle from "../Primary/style.module.scss";
import style from "./style.module.scss";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { getDashboardProjects } from "../../../../../app/feature/dashboard/projects";

const Sections: FC = () => {
  const dispatch = useAppDispatch();

  const isRealMobile = useIsMobile(1100);

  const [areAllOpen, setAllOpen] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const { userType, projectId, sectionId, showFulfilledSections } =
    useAppSelector(getDashboardDestinations);
  const { data, pending, openTabs } = useAppSelector(getDashboardSections);
  const { fulfilled: projectsFulfillled } =
    useAppSelector(getDashboardProjects);

  const projectIdRef = useRef<number>(projectId);

  const { ticked, handleChange } = useFilter(
    data,
    query,
    showFulfilledSections,
    FilterSections,
    setQuery
  );

  const allIds = Object.entries(data).reduce((ids: string[], item: any) => {
    if (!item[1]?.id && item[0] !== "0") {
      return [...ids, item[0]];
    }

    return ids;
  }, []);

  const isMobile = useIsMobile(1600);

  useEffect(() => {
    if (!Object.keys(data).length) {
      return;
    }

    const filteredData = Filter.updateTreeByFilterParams(data, ticked);
    dispatch(setDataTree(filteredData));
  }, [ticked]);

  useEffect(() => {
    setQuery("");
    dispatch(setShowFulfilledSections(projectsFulfillled));
  }, [userType, projectId]);

  useEffect(() => {
    if (
      (Object.keys(data).length && projectId === projectIdRef.current) ||
      !projectId
    ) {
      return;
    }

    projectIdRef.current = projectId;
    dispatch(getSections(projectId));
  }, [projectId]);

  useEffect(() => {
    if (!openTabs.length) {
      return;
    }

    const allTabsAreCurrentlyOpen =
      [...openTabs].sort().join(",") === allIds.sort().join(",");

    setAllOpen(allTabsAreCurrentlyOpen);
  }, [openTabs]);

  useEffect(() => {
    setAllOpen(false);
  }, [sectionId]);

  const handleToggleAll = () => {
    dispatch(setOpenTabs(areAllOpen ? [] : allIds));
    setAllOpen(!areAllOpen);
  };

  useEffect(() => {
    dispatch(setOpenTabs(allIds));
    setAllOpen(true);
  }, [data]);

  const treeData = data["0"]?.children ?? [];

  const head = (
    <div className={cn(style.generalHeader, style.sectionsHeader)}>
      <h1>Название</h1>
      <p>Исполнитель</p>
      <p>Сроки</p>
      <p>Стоимость</p>
      <IconButton
        onClick={handleToggleAll}
        className={cn(!areAllOpen && style.folderArrow, globalStyle.dropDown)}
      >
        {areAllOpen ? <FolderArrowFill /> : <FolderArrow />}
      </IconButton>
    </div>
  );

  const sections = Boolean(projectId) && (
    <div className={globalStyle.list}>
      {!isMobile && head}
      {treeData.length ? (
        treeData.map((item: string) => (
          <Folder
            disableShowCount
            ticked={ticked}
            query={query}
            key={item}
            data={data}
            item={item}
            fulfilled={showFulfilledSections}
            short={isMobile}
            offset={0}
          />
        ))
      ) : (
        <p className={globalStyle.center}>Разделы не найдены</p>
      )}
    </div>
  );

  return (
    <div className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}>
      <div className={cn(globalStyle.header, style.sectionsFullHeader)}>
        {isRealMobile ? (
          <>
            <h3>Раздел</h3>
            <div className={style.mobileHeader}>
              <p>Выполнено</p>
              <Switch
                checked={showFulfilledSections}
                onChange={() =>
                  dispatch(setShowFulfilledSections(!showFulfilledSections))
                }
              />
              <IconButton
                className={style.mobileSearchBar}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h3>Раздел</h3>
            <p>Выполнено</p>
            <Switch
              checked={showFulfilledSections}
              onChange={() =>
                dispatch(setShowFulfilledSections(!showFulfilledSections))
              }
            />
            <SearchField value={query} onChange={handleChange} />
          </>
        )}
      </div>
      {isRealMobile && showSearchBar && (
        <SearchField
          className={style.searchBar}
          value={query}
          onChange={handleChange}
          mobile
        />
      )}
      {pending ? <DashboardSkeleton /> : sections}
    </div>
  );
};

export default Sections;
