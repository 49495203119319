import React, { FC } from "react";
import style from "./style.module.scss";
import Decor7 from "../../../../media/images/mainPageProcessImages/decor7.png";
import Decor8 from "../../../../media/images/mainPageProcessImages/decor8.png";
import Decor9 from "../../../../media/images/mainPageProcessImages/decor9.png";
import Decor10 from "../../../../media/images/mainPageProcessImages/decor10.png";
import Decor11 from "../../../../media/images/mainPageProcessImages/decor11.png";
import ProviderImg from "../../../../media/images/mainPageProcessImages/providers.png";
import BackButton from "../BackButton";
import { TProps } from "./Gip";

const Provider: FC<TProps> = ({ onBack }) => (
  <>
    <>
      <header className={style.header}>
        <BackButton onBack={onBack} />
        <div className={style.left}>
          <img className={style.img} src={ProviderImg} alt="" />
        </div>
        <div>
          <h2>Поставщику</h2>
          <p>Найти Заказчика и предложить свои изделия</p>
        </div>
      </header>
      <main className={style.items}>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor11} alt="" />
          </div>
          <div className={style.right}>
            <h5>Найти Заказчика</h5>
            <p>Найти Заказчиков для своего оборудования и материалов.</p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor8} alt="" />
          </div>
          <div className={style.right}>
            <h5>Стать проверенным поставщиком</h5>
            <p>
              Поставщики, которые прошли проверку Платформы, были выбраны
              Проектировщиком или Заказчиком, и получили отзывы, средний бал
              которых более 4, являются проверенными.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor9} alt="" />
          </div>
          <div className={style.right}>
            <h5>Запрос предложений по объекту</h5>
            <p>
              Персональные запросы поставщикам и производителями материалов и
              оборудования по направлению деятельности.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor7} alt="" />
          </div>
          <div className={style.right}>
            <h5>Информация об объекте </h5>
            <p>
              Получить подробную информацию об объекте на котором примененили
              оборудование или материалы.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor10} alt="" />
          </div>
          <div className={style.right}>
            <h5>Подать свое предложение</h5>
            <p>
              Подать коммерческое и техническое предложение с целью применения
              материалов и оборудования на объекте.
            </p>
          </div>
        </div>
      </main>
    </>
  </>
);
export default Provider;
