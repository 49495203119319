import { InitialDataUpdateModal } from "./InitialDataUpdate";
import { AddCommentModal } from "./AddComment/ui/AddCommentModal";
import { AddFilesModal } from "./AddFiles/ui/AddFilesModal";
import { EditDate } from "./EditDate";
import { GipHelperAccept } from "./GipHelperAccept";

const ProjectModals = {
  AddCommentModal,
  AddFilesModal,
  InitialDataUpdateModal,
  EditDate,
  GipHelperAccept,
};

export default ProjectModals;
