import React from "react";
import { Route, Routes } from "react-router-dom";
import { Library } from "../components/pages/Library/Library";
import { Create } from "../components/pages/Post/Create/Create";
import { Edit } from "../components/pages/Post/Edit/Edit";
import { View } from "../components/pages/Post/View/View";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";

export const PostRoutes = () => (
  <Routes>
    <Route path="/post">
      <Route
        index
        element={
          <IndexPageTemplate>
            <Library />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`view`}
        element={
          <IndexPageTemplate>
            <View />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`update`}
        element={
          <IndexPageTemplate>
            <Edit />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`create`}
        element={
          <IndexPageTemplate>
            <Create />
          </IndexPageTemplate>
        }
      />
    </Route>
  </Routes>
);
