import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import classNames from "classnames";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { MAX_SYMBOLS, sx, getText } from "../Info/Info";
import style from "./style.module.scss";
import { config } from "../../../../../app.cofig";
import { ImagePlaceholders } from "../../../../../FSD/shared/lib/constants/imagePlaceholders";

const getBack = (inAmount: number) => {
  const amount = inAmount % 10;

  if (amount === 1) {
    return "";
  }
  if (amount > 1 && amount < 5) {
    return "а";
  }
  return "ов";
};

export const trim = (text: string) =>
  text?.length > 30 ? `${text.substring(0, 27)}...` : text;

type FilesProps = {
  id: number;
  files: {
    title: string;
    url: string;
  }[];
};

const Files: React.FC<FilesProps> = ({ files, id }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => setShowMore(true);

  return (
    <div className={style.files}>
      <p>Вложения</p>
      {files.length > 0 ? (
        files.slice(0, showMore ? files.length - 1 : 3).map((file: any) => (
          <>
            <TextLink
              variant={style.textLink}
              redirect
              target="blank"
              url={file.url}
            >
              {trim(file.title)}
            </TextLink>
            <br />
          </>
        ))
      ) : (
        <div className={style.absent}>Вложения отсутствуют</div>
      )}
      {!showMore && files.length > 3 && (
        <button
          className={classNames(style.textLink, style.small)}
          onClick={handleShowMore}
        >
          {files.length - 4 > 0 &&
            `Еще ${files.length - 4} файл${getBack(files.length - 4)}`}
        </button>
      )}
      {files.length > 0 && (
        <div className={style.dark}>
          <TextLink
            variant={classNames(style.textLink, style.small, style.dark)}
            redirect
            target="blank"
            url={`${config.localDomain}/portfolio/download-all?id=${id}`}
          >
            Скачать всё
          </TextLink>
        </div>
      )}
    </div>
  );
};

type PortfolioProps = {
  data: any;
};

export const Portfolio: React.FC<PortfolioProps> = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div className={style.wrapper}>
      {data?.dataPortfolios.length ? (
        data?.dataPortfolios.map((item: any) => {
          const imageStyles = {
            backgroundImage: `url("${item.photo || ImagePlaceholders.BASIC}")`,
          };

          return (
            <div className={style.experience}>
              <p>{item.buildingType}</p>
              <div className={style.flex}>
                <div className={style.data} style={imageStyles} />
                <div className={style.data}>
                  <p>Объект</p>
                  <div>{item.name}</div>
                </div>
                <Files files={item.files} id={item.id} />
              </div>

              <div className={style.flex2}>
                <div className={style.data}>
                  <p>
                    Описание
                    {item.description.length > MAX_SYMBOLS && (
                      <IconButton onClick={handleOpen}>
                        {open ? (
                          <ArrowDropDownIcon sx={sx} />
                        ) : (
                          <ArrowDropUpIcon sx={sx} />
                        )}
                      </IconButton>
                    )}
                  </p>
                  <div className={style.text}>
                    {getText(open, item.description)}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};
