import React from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link, useParams } from "react-router-dom";
import { VerticalThreeDots } from "src/components/newUI/SVG";
import { config } from "src/app.cofig";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import {
  useProjectRecalculationsRelationsMutation,
  useProjectDeleteMutation,
  useProjectPauseMutation,
  useProjectUnPauseMutation,
} from "src/FSD/entities/project/api";

type TProps = {
  project: ApiProjectResponses.View;
};

export const ProjectHeader = ({ project }: TProps) => {
  const { id } = useParams<{ id: string }>();

  const [recalculationsRelations] = useProjectRecalculationsRelationsMutation();
  const [deleteProject] = useProjectDeleteMutation();
  const [pause] = useProjectPauseMutation();
  const [unPause] = useProjectUnPauseMutation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const canBtnHeader =
    project?.accountId ||
    project?.can?.edit ||
    project?.can?.isAdmin ||
    project?.can?.delete;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNavigate = () => {
    window.open(
      `${config.localDomain}/account/view?id=${project?.accountId}`,
      "_blank"
    );
  };

  const handleDelete = () => {
    const result = window.confirm("Вы уверены, что хотите удалить проект?");
    if (id && result) {
      deleteProject({ id: +id });
    }
  };

  const pauseProject = () => {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить проект?"
    );
    if (id && result) {
      pause({ id: +id });
    }
  };

  const unPauseProject = () => {
    const result = window.confirm("Вы уверены, что хотите продолжить проект?");
    if (id && result) {
      unPause({ id: +id });
    }
  };

  const reCalculationRelations = () => {
    const result = window.confirm("Вы уверены, что хотите обновить связи?");
    if (id && result) {
      recalculationsRelations({ id: +id });
    }
  };

  return (
    <div>
      {canBtnHeader && (
        <IconButton
          sx={{ color: "white" }}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <VerticalThreeDots />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {project?.accountId && (
          <MenuItem
            onClick={() => {
              handleNavigate();
              handleClose();
            }}
            disableRipple
          >
            Кошелек
          </MenuItem>
        )}
        {project?.can?.edit && (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            disableRipple
          >
            <Link
              style={{
                color: "#D5D6DE",
              }}
              to={`/project/update?id=${id}&tab=project`}
            >
              Редактировать
            </Link>
          </MenuItem>
        )}
        {project?.can?.isAdmin && (
          <MenuItem
            onClick={() => {
              reCalculationRelations();
              handleClose();
            }}
            disableRipple
          >
            Обновить связи
          </MenuItem>
        )}
        {project?.can?.edit && project?.status.key !== 15 && (
          <MenuItem
            onClick={() => {
              pauseProject();
              handleClose();
            }}
            disableRipple
          >
            Приостановить
          </MenuItem>
        )}
        {project?.can?.edit && project?.status.key === 15 && (
          <MenuItem
            onClick={() => {
              unPauseProject();
              handleClose();
            }}
            disableRipple
          >
            Продолжить
          </MenuItem>
        )}
        {project?.can?.delete && (
          <MenuItem
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            disableRipple
          >
            Удалить
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
