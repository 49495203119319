import { config } from "src/app.cofig";
import { httpRequestGet } from "src/app/services/api/api";
import { request } from "src/app/services/api/requestHandler";

const ApiGetTaskFile = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&expand=files.svgIcon`
  );
};

export const getTaskFiles = async (taskId: number) => {
  let response: any;

  await request(ApiGetTaskFile(taskId), (data) => {
    response = data;
  })();

  return response.files;
};
