import Filter from "./Filter";

class FilterStaff extends Filter {
  static filterByQuery(data: any, query: string) {
    if (query === "") {
      return data.map((item: any) => item.id);
    }

    const ids = Object.values(data).filter((item: any) =>
      Filter.valueContains(item.name, query)
    );

    return ids.map((item: any) => item.id);
  }

  static filterByFulfilled(data: any) {
    return data.map((item: any) => item.id);
  }
}

export default FilterStaff;
