import * as Yup from "yup";

const isNumber = (value: any) => {
  const numValue = Number(value);
  return Number.isInteger(numValue) && numValue >= 0 && numValue <= 9;
};

const seismicityFieldValidation = Yup.string().test(
  "symbolsTest",
  "Значение должно быть либо целым числом в диапазоне от 0 до 9, в котором допускаются символы > и *, либо -",
  (rawValue: any) => {
    const value = (rawValue ?? "").split("");

    if (value.length === 3) {
      return rawValue === ">9*";
    }

    if (value.length === 2) {
      return (
        (value[0] === ">" && isNumber(value[1])) ||
        (isNumber(value[0]) && value[1] === "*")
      );
    }

    if (value.length === 1) {
      return rawValue === "-" || isNumber(value[0]);
    }

    if (!value.length) {
      return true;
    }

    return false;
  }
);

export const standardSchema = Yup.object().shape({
  name: Yup.string()
    .required('Необходимо заполнить "Наименование"')
    .max(200, "Значение должно содержать максимум 200 символов"),
  name_for_doc: Yup.string().max(
    2000,
    "Значение должно содержать максимум 200 символов"
  ),
  date_start: Yup.mixed()
    .nullable()
    .required('Необходимо заполнить "Дата начала"'),
  date_end: Yup.mixed()
    .nullable()
    .test(
      "notExceed",
      '"Дата окончания" не может быть раньше "Даты начала"',
      function (value) {
        const dateStart = this.parent.date_start;
        return (
          !dateStart || !value || (value && dateStart && dateStart < value)
        );
      }
    ),
  region_code: Yup.mixed().test(
    "requiredRegion",
    'Необходимо заполнить "Регион"',
    (value) => !Number.isNaN(Number(value)) && value > 0
  ),
  advance_pay: Yup.string()
    .required('Необходимо заполнить "Аванс"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    )
    .test(
      "limit",
      "Значение не должно превышать 80%",
      (value) => Number(value) <= 80
    ),
  pay1: Yup.string()
    .required('Необходимо заполнить "После приемки документации"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    ),
  pay2: Yup.string()
    .required('Необходимо заполнить "После экспертизы или оплаты Заказчиком"')
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    ),
  pay_limit: Yup.string()
    .required('Необходимо заполнить "Период окончательной оплаты (мес.)"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "lowerBound",
      "Значение должно быть больше 0",
      (value) => Number(value) >= 1
    )
    .test(
      "upperBound",
      "Значение не должно превышать 6",
      (value) => Number(value) <= 6
    ),
  bank_account_id: Yup.number()
    .required('Необходимо заполнить "Банковский счет"')
    .typeError('Необходимо заполнить "Банковский счет"'),
  a: seismicityFieldValidation,
  b: seismicityFieldValidation,
  c: seismicityFieldValidation,
});

export const simpleSchema = Yup.object().shape({
  name: Yup.string()
    .required('Необходимо заполнить "Наименование"')
    .max(200, "Значение должно содержать максимум 200 символов"),
  region_code: Yup.mixed().test(
    "requiredRegion",
    'Необходимо заполнить "Регион"',
    (value) => !Number.isNaN(Number(value)) && value > 0
  ),
  advance_pay: Yup.string()
    .required('Необходимо заполнить "Аванс"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    )
    .test(
      "limit",
      "Значение не должно превышать 80%",
      (value) => Number(value) <= 80
    ),
  pay1: Yup.string()
    .required('Необходимо заполнить "После приемки документации"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    ),
  pay2: Yup.string()
    .required('Необходимо заполнить "После экспертизы или оплаты Заказчиком"')
    .test(
      "divisible",
      "Значение должно быть кратно 5",
      (val) => Number(val) % 5 === 0
    ),
  pay_limit: Yup.string()
    .required('Необходимо заполнить "Период окончательной оплаты (мес.)"')
    .test("isNumber", "Значение должно быть числом", (value) =>
      Boolean(value && !Number.isNaN(Number(value)))
    )
    .test(
      "lowerBound",
      "Значение должно быть больше 0",
      (value) => Number(value) >= 1
    )
    .test(
      "upperBound",
      "Значение не должно превышать 6",
      (value) => Number(value) <= 6
    ),
});
