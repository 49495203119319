import { apiProjectType } from "../../../types/api/responseTypes/apiProjectType";
import { userIsAuth } from "../auth/auth";

export const canUserStopSearchAction = (
  projectApiData: apiProjectType
): boolean =>
  projectApiData.can!.edit &&
  Boolean(projectApiData.gipProcess) &&
  !projectApiData.gip;

export const canUserSearchGipAction = (
  projectApiData: apiProjectType
): boolean =>
  projectApiData.can!.edit && !projectApiData.gip && !projectApiData.gipProcess;

export const canUserCompareGipAction = (
  projectApiData: apiProjectType
): boolean =>
  projectApiData.can!.edit &&
  Boolean(projectApiData.gipProcess) &&
  !projectApiData.gip &&
  Boolean(projectApiData.gipAccess && projectApiData.gipAccess.length > 0);

export const canUserExecutionGipRequestAction = (
  projectApiData: apiProjectType
): boolean => projectApiData.can!.deleteRequestGip;

export const canUserRequestGip = (projectApiData: apiProjectType): boolean =>
  projectApiData.can!.requestGip;

export const canUserDeleteRequestGip = (
  projectApiData: apiProjectType
): boolean => projectApiData.can!.deleteRequestGip;

export const canUserSetGip = (
  userIsAdmin: boolean,
  projectApiData: apiProjectType
): boolean => userIsAdmin && !projectApiData.gip && !projectApiData.gipProcess;

export const canUserRequestDeleteGip = (projectApiData: apiProjectType) => {
  return projectApiData.can.requestRemoveGip;
};

export const canConsiderConflictDeleteGip = (
  projectApiData: apiProjectType
) => {
  return !!projectApiData.requestRemoveGipTask && projectApiData.can.edit;
};

export const userCanViewProcessProjectTab = () => {
  return userIsAuth();
};

export const userCanViewHistoryProjectTab = (
  relevantToProject: boolean,
  showFullTabs: boolean
) => {
  return relevantToProject && showFullTabs;
};

export const userCanViewDiagramProjectTab = (
  relevantToProject: boolean,
  showFullTabs: boolean
) => {
  return relevantToProject && showFullTabs;
};

export const userCanViewContractProjectTab = (relevantToProject: boolean) => {
  return relevantToProject;
};
