import { FC, useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  deleteBookByWorkId,
  fetchProjectComposition,
} from "../../../../../app/feature/ProjectView/Contents/thunks";
import {
  getProjectComposition,
  clearProjectComposition,
} from "../../../../../app/feature/ProjectView/Contents/getComposition";
import style from "./style.module.scss";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import BookModal from "./BookModal";
import { HorizontalTabsPanel } from "../../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import CompositionsList from "./CompositionsList";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";

export type TValuesForEditing = Record<string, number | string> | null;

const tabs = [
  {
    id: 1,
    label: "Проектная документация",
    incomplete: false,
  },
  {
    id: 2,
    label: "Рабочая документация",
    incomplete: false,
  },
];

const SkeletonList = () => (
  <>
    <Skeleton className={style.skeleton} />
    <Skeleton className={style.skeleton} />
    <Skeleton className={style.skeleton} />
    <Skeleton className={style.skeleton} />
    <Skeleton className={style.skeleton} />
  </>
);

type ContentsProps = {
  id: number;
  can: Record<string, boolean>;
  setCurrentTabMemo: () => void;
};

const Contents: FC<ContentsProps> = ({ id, can, setCurrentTabMemo }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, pending, error } = useAppSelector(getProjectComposition);
  const params = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState<number>(1);
  const [workId, setWorkId] = useState<number | null>(null);
  const [valuesForEditing, setValuesForEditing] =
    useState<TValuesForEditing>(null);

  const setActiveTabMemo = (tab: number | string) => {
    setActiveTab(tab as number);
  };

  useEffect(() => {
    dispatch(fetchProjectComposition(id));

    return () => {
      dispatch(clearProjectComposition());
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, []);

  useEffect(() => {
    if (workId === null) {
      setValuesForEditing(null);
    }
  }, [workId]);

  useEffect(() => {
    if (error.getComposition === null) {
      return;
    }

    navigate(`/project/${id}?id=info`);
    setCurrentTabMemo();
  }, [error.getComposition]);

  const handleDeleteBook = async (workId: number) => {
    if (!window.confirm("Вы действительно хотите удалить данную книгу?")) {
      return;
    }

    await dispatch(deleteBookByWorkId(workId));
    setWorkId(null);

    await dispatch(fetchProjectComposition(id));
  };

  const showFormAgain =
    data.project.length + data.work.length === 0 || can.isAdmin;

  const components = [
    {
      id: 1,
      component: (
        <CompositionsList
          id={id}
          data={data}
          tab={"project"}
          setWorkId={setWorkId}
          workId={workId}
          setEditing={setValuesForEditing}
          handleDeleteBook={handleDeleteBook}
          showFormAgain={showFormAgain}
        />
      ),
    },
    {
      id: 2,
      component: (
        <CompositionsList
          id={id}
          data={data}
          tab={"work"}
          setWorkId={setWorkId}
          workId={workId}
          setEditing={setValuesForEditing}
          handleDeleteBook={handleDeleteBook}
          showFormAgain={showFormAgain}
        />
      ),
    },
  ];

  return (
    <div className={style.wrapper}>
      <HorizontalTabsPanel
        tabs={tabs}
        components={components}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        isLoad={pending.getComposition}
        skeleton={<SkeletonList />}
      />
      <ModalWrapper
        show={Boolean(workId)}
        head={
          <div className={style.head}>
            {valuesForEditing ? <>Редактирование</> : <>Создание</>} книги
          </div>
        }
        handleCloseModal={() => setWorkId(null)}
      >
        <BookModal
          id={id}
          workId={workId as number}
          setWorkId={setWorkId}
          valuesForEditing={valuesForEditing}
        />
      </ModalWrapper>
    </div>
  );
};

export default Contents;
