import { PayloadAction } from "@reduxjs/toolkit";
import { EventEmitterEventType } from "../eventEmitterInitialState";
import { ProcessPublishPayloadType } from "../../../../types/stateTypes/events/eventsPayloadTypes";

/*
  Управление показом попапа формы публикации раздела проекта
 */

export const showEventPublishTaskReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventPublishTask: {
      isShow: true,
      data: {
        ...state.eventPublishTask.data,
      },
    },
  };
};

export const closeEventPublishTaskReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventPublishTask: {
      isShow: false,
      data: {
        ...state.eventPublishTask.data,
      },
    },
  };
};

export const setEventPublishReduces = (
  state: EventEmitterEventType,
  action: PayloadAction<ProcessPublishPayloadType>
) => {
  return {
    ...state,
    eventPublishTask: {
      isShow: false,
      data: {
        ...action.payload,
      },
    },
  };
};
