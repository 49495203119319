import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProjectDocument,
  setAllToggle,
  setRoot,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import { useProjectGetDocTreeQuery } from "src/FSD/entities/project/api";

export const useProjectDocuments = () => {
  const { id } = useParams<{ id: string }>();
  const { currentChildTab } = useTabsPanelContext();
  const isTablet = useIsMobile(1600);
  const [checked, setChecked] = useState(false);
  const skip = !id && !currentChildTab;

  const {
    data,
    isFetching: isFetchingDocuments,
    isLoading: isLoadingDocuments,
  } = useProjectGetDocTreeQuery(
    {
      id,
      statusType: currentChildTab as string,
    },
    { skip }
  );

  let documents: ApiDocumentsResponses.View[] = [];
  if (data) {
    const filtered = data.filter((document) => {
      return document.docs.some(
        (doc) =>
          doc.sums.pairSignCountList.PAIR_ORANGE > 0 ||
          !doc.sign.author ||
          !doc.sign.signer
      );
    });

    documents = checked ? filtered : data;
  }

  const { isAllToggle } = useSelector(getProjectDocument);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetchingDocuments) {
      dispatch(setRoot(documents));
    }
  }, [dispatch, id, isFetchingDocuments]);

  const handleClick = () => {
    if (isAllToggle) {
      dispatch(setAllToggle(false));
    } else {
      dispatch(setAllToggle(true));
    }
  };

  return {
    checked,
    setChecked,
    documents,
    isFetchingDocuments,
    isLoadingDocuments,
    isTablet,
    isAllToggle,
    handleClick,
  };
};
