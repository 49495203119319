import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessRemoveExecutorComplete } from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessRemoveExecutorComplete = createAsyncThunk<
  boolean,
  { taskId: number; data: any }
>(
  "projectProcess/fetchProjectProcessRemoveExecutorComplete",
  async ({ taskId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessRemoveExecutorComplete(taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
