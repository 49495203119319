import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Card.module.scss";

const weights = {
  regular: 400,
};

type Props = {
  children: ReactNode;
  fontSize?: number;
  fontWeight?: keyof typeof weights;
  className?: string;
};

export const CardHeader = ({
  children,
  fontSize = 24,
  fontWeight = "regular",
  className,
}: Props) => {
  return (
    <h2
      style={{ fontSize, fontWeight: weights[fontWeight] }}
      className={cn(styles.card__header, className)}
    >
      {children}
    </h2>
  );
};
