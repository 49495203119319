import { memo } from "react";
import { Column } from "src/FSD/widgets/v2/kanban";
import styles from "./Kanban.module.scss";
import { KanbanColumns, KanbanModel } from "../../../../entities/kanban";
import { useAppSelector } from "../../../../../app/store";

export const AllTasksList = memo(() => {
  const { allTasks } = useAppSelector(KanbanModel.getTasks);

  return (
    <div className={styles.columns_allTasks}>
      <div className={styles.columns_allTasks__header}>
        <h2 className={styles.columns__history__header}>Все задачи</h2>
        <Column.SortFilter />
      </div>
      <Column position={KanbanColumns.ALL_TASKS} tasks={allTasks} isAllTasks />
    </div>
  );
});
