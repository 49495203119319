import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage, request } from "../../../services/api/requestHandler";
import {
  ApiDeleteProjectAccess,
  ApiGetAccessCodesByProject,
  ApiGetUsersProject,
  ApiGetUsersTypeProject,
  ApiUpdateParams,
  ApiUpdateUserProject,
} from "../../../services/api/project/admin";
import { pushError, pushSuccessMessage } from "../../errorTrace";
import {
  ApiCreateProjectAccess,
  ApiGetAvailableUsersOnProject,
  ApiGetFirmsInProject,
  ApiGetProjectAccessByProject,
  ApiGetUsersProjectGip,
  ApiProjectAccessDelegate,
} from "../../../services/api/project/complex";
import {
  ApiGetMyProjects,
  ApiGetMyProjectsByUserType,
} from "../../../services/api/user/user";

export type ProjectUsersListType = Record<string, string>;
export type ProjectUsersTypes = Record<string, string>;

export const fetchProjectUsersList = createAsyncThunk<ProjectUsersListType>(
  "fetchProjectUsersList/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiGetUsersProject();
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectUsersType = createAsyncThunk<
  ProjectUsersTypes,
  string | number
>("fetchProjectUsersType/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiGetUsersTypeProject(id);
    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (err) {
    const error = getErrorMessage(err);
    console.log(error);
    return rejectWithValue(error);
  }
});

type UsersUpdateParams = {
  id: number;
  data: ApiUpdateParams;
};
export const fetchProjectUsersUpdate = createAsyncThunk<any, UsersUpdateParams>(
  "fetchProjectUsersUpdate/fetch",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ApiUpdateUserProject(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const getAccessCodesByProject = createAsyncThunk(
  "project/admin/getAccessCodesByProject",
  async (
    { projectId, isInviteGip }: { projectId: number; isInviteGip: boolean },
    { dispatch, rejectWithValue }
  ) => {
    let response: unknown = [];
    let error = null;

    await request(
      ApiGetAccessCodesByProject(projectId, isInviteGip),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const getUsersToAssign = createAsyncThunk(
  "project/admin/getUsersToAssign",
  async (id: number, { dispatch, rejectWithValue }) => {
    let response: unknown = [];
    let error = null;

    await request(
      ApiGetUsersProjectGip(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const createProjectAccess = createAsyncThunk(
  "project/admin/createProjectAccess",
  async (
    {
      projectId,
      data,
    }: {
      projectId: number;
      data: Record<string, any>;
    },
    { dispatch, rejectWithValue }
  ) => {
    let error = null;

    await request(
      ApiCreateProjectAccess(projectId, data),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        error = err;
        pushError(err);
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProjectAccessByProject = createAsyncThunk(
  "project/admin/getProjectAccessByProject",
  async (id: number, { dispatch, rejectWithValue }) => {
    let response: any = [];
    let error = null;

    await request(
      ApiGetProjectAccessByProject(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const delegateProjectAccess = createAsyncThunk(
  "project/admin/delegateProjectAccess",
  async (
    { accessId, delegate }: { accessId: number; delegate: boolean },
    { dispatch }
  ) => {
    await request(
      ApiProjectAccessDelegate(accessId, delegate),
      () => {},
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const getAvailableUsersOnProject = createAsyncThunk(
  "project/admin/getAvailableUsersOnProject",
  async (
    { firmId, projectId }: { firmId: number; projectId: number },
    { dispatch, rejectWithValue }
  ) => {
    let response: any = [];
    let error = null;

    await request(
      ApiGetAvailableUsersOnProject(firmId, projectId),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const getFirmsInProjects = createAsyncThunk(
  "project/admin/getFirmsInProject",
  async (projectId: number, { dispatch, rejectWithValue }) => {
    let response: any = [];
    let error = null;

    await request(
      ApiGetFirmsInProject(projectId),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const getMyProjects = createAsyncThunk(
  "project/admin/getMyProjects",
  async (_, { dispatch, rejectWithValue }) => {
    let response: any = [];
    let error = null;

    await request(
      ApiGetMyProjects(),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const getMyProjectsByUserType = createAsyncThunk(
  "project/admin/getMyProjectsByUserType",
  async (userTypeId: number, { dispatch, rejectWithValue }) => {
    let response: any = [];
    let error = null;

    await request(
      ApiGetMyProjectsByUserType(userTypeId),
      (data) => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const deleteProjectAccess = createAsyncThunk(
  "project/admin/deleteProjectAccess",
  async (id: number, { dispatch, rejectWithValue }) => {
    let error = null;

    await request(
      ApiDeleteProjectAccess(id),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }
  }
);
