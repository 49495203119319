import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { KanbanApi } from "src/FSD/entities/kanban";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { mapTask } from "../mappers/mapTask";
import { viewTaskInfoSchema } from "../schemas/viewTaskInfo";
import { useAppDispatch } from "../../../../../../../../app/store";
import { pushError } from "../../../../../../../../app/feature/errorTrace";

type TFieldValues = {
  comment: string;
  status: Statuses;
};

const defaultValues = {
  comment: "",
};

type UseModalProps = {
  taskId: number;
};

export const useViewTaskInfo = ({ taskId }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const [areCommentsOpen, setCommentsOpen] = useState(false);
  const [areChecksOpen, setChecksOpen] = useState(true);

  const dispatch = useAppDispatch();

  const { data: taskDataRaw, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["project.parent", "checks", "comments.projectPart", "taskUrl"],
  });

  const onSubmit = async (formValues: TFieldValues) => {
    await dispatch(
      KanbanApi.complete({
        data: {
          id: taskId,
          formedData: formValues,
        },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(viewTaskInfoSchema),
  });

  const taskData = useDataMapper(taskDataRaw, mapTask);

  const status = watch("status");

  const isTaskLoading = !taskData || isFetching;

  const isAccepting = isSubmitting && status === Statuses.ACCEPT;

  const isRejecting = isSubmitting && status === Statuses.REJECT;

  return {
    taskData,
    isTaskLoading,
    register,
    handleSubmit,
    onSubmit,
    errors,
    isSubmitting,
    isRejecting,
    isAccepting,
    setValue,
    areCommentsOpen,
    setCommentsOpen,
    areChecksOpen,
    setChecksOpen,
  } as const;
};
