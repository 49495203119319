import { useState, useEffect } from "react";

export const useIsMobile = (size = 500) => {
  const [isMobile, setMobile] = useState<boolean>(window.innerWidth < size + 1);

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth < size + 1);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  return isMobile;
};
