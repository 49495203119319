import { Routes, Route } from "react-router-dom";
import { ProcessWorkViewPage } from "src/FSD/pages/v2/processWork";
import { IndexPageTemplateNew } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import styles from "../components/pages/Project/NewView/ProjectView.module.scss";

export const ProcessWorkRoutes = () => (
  <Routes>
    <Route
      path="/process-work/test/:id"
      element={
        <IndexPageTemplateNew className={styles.footer}>
          <ProcessWorkViewPage />
        </IndexPageTemplateNew>
      }
    />
  </Routes>
);
