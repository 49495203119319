import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import { TextField } from "@mui/material";
import ru from "date-fns/locale/ru";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import classNames from "classnames";
import { useSelector } from "react-redux";

import moment from "moment";
import { ComponentPropsType } from "src/types/other/components/componentPropsType";
import { selectorAppTheme } from "src/app/feature/app/app";
import { palette, textFieldSX } from "src/styles/restyle";
import style from "./style.module.scss";
import { ModalCloseIcon } from "../../../newUI/SVG";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { getYears } from "./utils";

type DateFieldProps = ComponentPropsType<{
  startDateProp?: Date | null;
  changeDateHandler?: (date: Date | null) => void;
  changeBlur?: () => void;
  changeFocus?: () => void;
  onSave?: () => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  disableClear?: boolean;
  inputProps?: any;
  inputLabelPropsModified?: any;
  disableForward?: boolean;
  isCorrect?: boolean;
  className?: string;
  newDesign?: boolean;
  newLabel?: string;
  placeholderText?: string;
  startWithCurrentYear?: boolean;
}>;

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const DateField: React.FC<DateFieldProps> = ({
  startDateProp = null,
  changeDateHandler = () => {},
  changeBlur = () => {},
  changeFocus = () => {},
  onSave = () => {},
  label = "",
  newLabel = "",
  error,
  disabled,
  helperText,
  disableClear,
  className,
  isCorrect = false,
  newDesign,
  startWithCurrentYear = false,
  placeholderText = "дд.мм.гггг",
}) => {
  const [startDate, setStartDate] = useState<any>(startDateProp);
  const errorClass = `${error ? style.error : ""}`;
  const theme = useSelector(selectorAppTheme);
  const refDate = useRef(null);

  const years = getYears(startWithCurrentYear);

  const onChangeDates: DateFieldProps["changeDateHandler"] = (date) => {
    // @ts-ignore
    setStartDate(date);
    changeDateHandler(date);
  };

  const onClose = () => {
    if (refDate) {
      setStartDate(startDateProp);
      // @ts-ignore
      refDate.current.setOpen(false);
    }
  };

  const saveBtnHandler = () => {
    if (refDate) {
      // @ts-ignore
      refDate.current.setOpen(false);
      onSave();
    }
  };

  const onReset = () => {
    setStartDate(null);
  };

  useEffect(() => {
    if (startDateProp === null) {
      onReset();
    }

    if (startDateProp) {
      setStartDate(startDateProp);
    }
  }, [startDateProp]);

  const disabledDate = startDateProp
    ? moment(startDateProp).format("DD.MM.YYYY")
    : "";

  if (disabled) {
    return (
      <TextField
        disabled
        value={disabledDate}
        label={label}
        error={error}
        helperText={helperText}
        className={classNames(isCorrect && style.correct)}
        {...textFieldSX}
      />
    );
  }

  return (
    <>
      <div className={classNames(className)}>
        {newLabel && <p className={style.newLabel}>{newLabel}</p>}
        <div className={style.datePickerComponent}>
          <label
            htmlFor="picker"
            className={classNames(
              style.datePickerLabel,
              style[theme],
              newDesign && style.newDesign,
              errorClass
            )}
          >
            {label}
          </label>
          <div className={style.datePickerInputContainer}>
            <ReactDatePicker
              id="date-picker"
              className={classNames(
                style.datePickerInput,
                !label && style.noLabelDate,
                style[theme]
              )}
              ref={refDate}
              locale={ru}
              popperPlacement="bottom"
              autoComplete="off"
              dateFormat={"dd.MM.yyyy"}
              placeholderText={placeholderText}
              selected={startDate}
              onFocus={changeFocus}
              onChange={onChangeDates}
              portalId="root-portal"
              shouldCloseOnSelect={false}
              onBlur={changeBlur}
              isClearable={!disabled && !disableClear}
              disabled={disabled}
              wrapperClassName={style.fullWidth}
              popperClassName={style.datePickerPopper}
              showPopperArrow={false}
              renderCustomHeader={({
                date,
                changeYear,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className={style.head}>
                  <div className={style.year}>
                    <p>{months[getMonth(date)]}</p>
                    <select
                      className={style.select}
                      value={getYear(date)}
                      onChange={({ target: { value } }) =>
                        changeYear(Number(value))
                      }
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={style.arrows}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        decreaseMonth();
                      }}
                      className={style.button}
                      disabled={prevMonthButtonDisabled}
                    >
                      <ChevronLeft className={style.chevron} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        increaseMonth();
                      }}
                      className={style.button}
                      disabled={nextMonthButtonDisabled}
                    >
                      <ChevronRight className={style.chevron} />
                    </button>
                  </div>
                  <div className={style.close} onClick={onClose}>
                    <ModalCloseIcon />
                  </div>
                </div>
              )}
            >
              <CustomButton
                onClick={saveBtnHandler}
                borderRadius={4}
                background={palette.oceanic}
                color={palette.midnightBlack}
                width={130}
                height={32}
                style={{ margin: "0 12px 12px auto" }}
              >
                Сохранить
              </CustomButton>
            </ReactDatePicker>
          </div>

          <fieldset
            className={classNames(
              style.datePickerFieldSet,
              isCorrect && style.correctData,
              style[theme],
              errorClass
            )}
          >
            <legend
              className={classNames(
                style.datePickerLegend,
                label && style.noLabel
              )}
            >
              <span className={classNames(label && style.noLabelSpan)}>
                {label}
              </span>
            </legend>
          </fieldset>
        </div>
        {Boolean(error && helperText) && (
          <div className={style.errorText}>{helperText}</div>
        )}
      </div>
    </>
  );
};
