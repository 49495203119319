import React, { useState } from "react";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { trimPrice } from "src/utils/trimNumber";
import classNames from "classnames";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProcessWorkModals from "src/FSD/widgets/v2/processWork/modals";
import styles from "./WorksHeader.module.scss";

type TProps = {
  title: string;
  fullCost: number;
  isLoading: boolean;
};

export const WorksHeader = ({ title, fullCost, isLoading }: TProps) => {
  const [active, setActive] = useState(false);

  const showWorkPublication = () => {
    setActive(true);
  };
  const closeWorkPublication = () => {
    setActive(false);
  };

  return (
    <Card
      className={styles.layout}
      header={<Card.Header>{title}</Card.Header>}
      controls={
        <Card.Controls location="top" width={0}>
          <Button onClick={showWorkPublication}>Создать задачу</Button>
        </Card.Controls>
      }
    >
      <div
        className={classNames(styles.info, {
          [styles.info_load]: !isLoading,
        })}
      >
        <span className={styles.info__sum}>{trimPrice(fullCost)}₽</span>{" "}
        Опубликовано без налога (НДС)
      </div>

      <Modal isActive={active} handleClose={closeWorkPublication}>
        <ProcessWorkModals.ProcessWorkCreate />
      </Modal>
    </Card>
  );
};
