import { httpRequestDelete, httpRequestPatch } from "../api";
import { config } from "../../../../app.cofig";

export const ApiDeleteFile = (id: string | number): Promise<Response> =>
  httpRequestDelete(`${config.localDomain}/v1/file/delete?id=${id}`);

export const ApiVisibilityFile = (id: string | number): Promise<Response> =>
  httpRequestPatch(`${config.localDomain}/v1/file/change-visibility?id=${id}`);

export const ApiChangeFileVisibility = (
  id: string | number,
  visibility: boolean
): Promise<Response> =>
  httpRequestPatch(
    `${config.localDomain}/v1/file/change-visibility?id=${id}`,
    {},
    { visibility }
  );
