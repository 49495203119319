import React, { memo } from "react";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Controller } from "react-hook-form";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useProcessWorkCreate } from "../lib/hooks/useProcessWorkCreate";

export const ProcessWorkCreate = memo(() => {
  const {
    register,
    handleSubmit,
    formSubmitHandler,
    control,
    errors,
    isLoading,
  } = useProcessWorkCreate();

  return (
    <Modal.Layout>
      <Modal.Header>Создание задачи</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
        <TextField
          {...register("name")}
          label="Наименование"
          defaultValue={""}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
        <TextField
          multiline
          minRows={2}
          label="Описание"
          {...register("description")}
          placeholder="Введите текст"
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : null}
        />
        <Controller
          name="upload_files"
          control={control}
          render={({ field: { onChange } }) => (
            <Uploader
              canEdit
              initialFiles={[]}
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(errors.upload_files)}
              helperText={errors.upload_files?.message}
            />
          )}
        />
        <Modal.Controls>
          <Button type="submit" isLoading={isLoading}>
            Создать
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
});
