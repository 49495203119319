import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import {
  FolderIcon,
  FolderWarningIcon,
  GearFillSVG,
} from "src/components/newUI/SVG";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { messages } from "src/components/pages/Project/View/InitialData/InitialDataCard";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import {
  deleteProjectChecklistById,
  fetchParentCheckList,
  fetchProjectSourceDataView,
} from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { KeyboardArrowIcon } from "src/components/newUI/KeyboardArrowIcon/KeyboardArrowIcon";
import { useAppSelector } from "src/app/store";
import {
  getProjectChecklist,
  setDisabled,
} from "src/app/feature/project/projectChecklist";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { newIconBtnSx } from "src/styles/restyle";
import { SourceData } from "./SourceData";
import styles from "./style.module.scss";

type InitialCardProps = {
  name: string;
  parentId: number;
  index: number;
  all: number;
  inWork: number;
  dot: boolean;
};

const sxBtn = { color: "white", padding: 0 };

export const InitialDataCard = ({
  name,
  parentId,
  index,
  all,
  inWork,
  dot,
}: InitialCardProps) => {
  const { project } = useSelector(getProjectViewData);
  const [toggleDiv, setToggleDiv] = useState(false);
  const [modalInitial, setModalInitial] = useState(false);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(681);
  const isTablet = useIsMobile(1600);
  const canGearBtn = project?.can.view && !project?.parentId;
  const {
    projectSourceData,
    isLoadSourceData,
    checkListParent,
    isLoadCheckList,
    searchValue,
  } = useSelector(getProjectInitialData);

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const closeModal = useCallback(() => {
    setModalInitial(false);
    const { id } = params;
    if (id) {
      dispatch(
        fetchProjectSourceDataView({
          idx: { parentId, id: +id, index, searchValue, type },
        })
      );
    }
  }, []);

  const handleClick = () => {
    setToggleDiv(!toggleDiv);
    if (!toggleDiv) {
      if (projectSourceData[index]) {
        return;
      }
      const { id } = params;
      if (id) {
        dispatch(
          fetchProjectSourceDataView({
            idx: { parentId, id: +id, index, searchValue, type },
          })
        );
      }
    }
  };

  const updateDataClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setModalInitial(true);
    const { id } = params;
    if (id)
      dispatch(
        fetchParentCheckList({
          idx: { parentId, index, id, searchValue, type },
        })
      );
  };

  const updateAddCheckList = () => {
    const { id } = params;
    if (id) {
      dispatch(
        fetchParentCheckList({
          idx: { parentId, index, id, searchValue, type },
        })
      );
    }
  };

  const handleDeleteSourceDataName = async (sourceDataId: number) => {
    if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
      return;
    }

    dispatch(setDisabled("delete"));
    await dispatch(deleteProjectChecklistById(sourceDataId));

    const { id } = params;
    if (id) {
      await dispatch(
        fetchParentCheckList({
          idx: { parentId, index, id, searchValue, type },
        })
      );
    }
    dispatch(setDisabled(false));
  };

  const arrowIcon = <KeyboardArrowIcon isOpen={toggleDiv} color={"#D5D6DE"} />;

  return (
    <>
      <div
        className={classNames(styles.card, toggleDiv && styles.card_notMargin)}
        onClick={handleClick}
      >
        <div className={styles.card_left}>
          {!dot && <FolderIcon width={"24"} height={"24"} />}
          {dot && <FolderWarningIcon />}
          <div className={styles.card_left_text}>
            <p>{name}</p>
            <span
              className={classNames(
                inWork === all && inWork !== 0 && styles.card_left_complete
              )}
            >{`(${inWork}/${all})`}</span>
          </div>
        </div>
        <div className={styles.card_toggle}>{arrowIcon}</div>
        {isMobile && canGearBtn && toggleDiv && (
          <IconButton
            sx={sxBtn}
            onClick={updateDataClick}
            className={styles.card_gearBtn}
          >
            <GearFillSVG width="22" height="22" />
          </IconButton>
        )}
      </div>
      {toggleDiv && (
        <>
          <Modal isActive={modalInitial} handleClose={closeModal}>
            <ProjectModals.InitialDataUpdateModal
              title={name}
              isLoad={isLoadCheckList}
              data={checkListParent[index]}
              parentId={parentId}
              projectId={Number(project?.id)}
              closeModal={closeModal}
              handleDeleteSourceDataName={handleDeleteSourceDataName}
              updateAddCheckList={updateAddCheckList}
            />
          </Modal>
          <div className={styles.container}>
            {!isMobile && (
              <div className={styles.parent}>
                <div className={styles.parent_status}>
                  {isTablet ? "Стат." : "Статус"}
                </div>
                <div>Наименование</div>
                <div>Дата запроса</div>
                <div>Контр. срок</div>
                <div>{isTablet ? "Согл." : "Согласовано"}</div>
                <div className={styles.parent_icon}>
                  {canGearBtn && (
                    <IconButton
                      sx={{ ...newIconBtnSx, padding: 0 }}
                      onClick={updateDataClick}
                    >
                      <GearFillSVG width={"22"} height={"22"} />
                    </IconButton>
                  )}
                </div>
                {projectSourceData[index] &&
                  projectSourceData[index].map((el) => (
                    <SourceData
                      key={el.id}
                      el={el}
                      parentId={parentId}
                      index={index}
                    />
                  ))}
              </div>
            )}
            {isMobile &&
              projectSourceData[index] &&
              projectSourceData[index].map((el) => (
                <SourceData
                  key={el.id}
                  el={el}
                  parentId={parentId}
                  index={index}
                  isMobile={isMobile}
                />
              ))}
            {!projectSourceData[index] && isLoadSourceData && (
              <CircularIndeterminate />
            )}
          </div>
        </>
      )}
    </>
  );
};
