import React, { ChangeEvent, KeyboardEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import styles from "./Header.module.scss";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getIsSearched,
  getSearchValue,
  setSearchValue,
  setIsSearched,
  setParentProjectId,
} from "../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { fetchProjectComplexObject } from "../../../../../../app/feature/ProjectView/ComplexReducer/thunks";

export const Header = () => {
  const { project } = useAppSelector(getProjectViewData);
  const searchValue = useAppSelector(getSearchValue);
  const isSearched = useAppSelector(getIsSearched);
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(event.target.value));
    dispatch(setIsSearched(false));
  };

  const searchClick = async () => {
    if (params.id) {
      await dispatch(
        fetchProjectComplexObject({
          id: +params.id,
          searchText: searchValue,
          load: true,
        })
      );
      dispatch(setIsSearched(true));
    }
  };

  const resetClick = async () => {
    dispatch(setSearchValue(""));
    if (params.id) {
      await dispatch(fetchProjectComplexObject({ id: +params.id, load: true }));
      dispatch(setIsSearched(false));
    }
  };

  const onKeyDownInput = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && params.id) {
      await dispatch(
        fetchProjectComplexObject({
          id: +params.id,
          searchText: searchValue,
          load: true,
        })
      );
      dispatch(setIsSearched(true));
    }
  };

  const handleCreateSubProject = () => {
    dispatch(setParentProjectId(params.id));
    navigate("/project/create");
  };

  return (
    <div className={styles.header}>
      <h1>Объекты</h1>
      <div className={styles.header_search}>
        <SearchBar
          value={searchValue}
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
          searchClick={searchClick}
          resetClick={resetClick}
          isSearched={isSearched}
          placeholder={"Введите название объекта"}
        />
        {project?.can?.edit && (
          <Button onClick={handleCreateSubProject}>Добавить объект</Button>
        )}
      </div>
    </div>
  );
};
