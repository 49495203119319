import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ProjectListType } from "../types/ProjectListType";
import { httpRequestGet } from "../../../../services/api/api";
import { config } from "../../../../../app.cofig";

const ApiProjectList = (processId: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-projects?processId=${processId}`
  );
};

export const fetchProjectListByProcessId = createAsyncThunk<
  ProjectListType[],
  number
>("getList/fetchProjectListByProcessId", async (processId, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProjectList(processId),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
