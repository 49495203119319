import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Column.module.scss";

type Props = {
  children: ReactNode;
  gap?: number;
  maxHeight?: string;
  className?: string;
};

export const Column = ({ children, gap = 15, maxHeight, className }: Props) => {
  return (
    <div
      className={cn(styles.column, className)}
      style={{ gap: `${gap}px`, margin: `${gap}px 0`, maxHeight }}
    >
      {children}
    </div>
  );
};
