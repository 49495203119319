import React, { memo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { IProjectCopmlex } from "../../../../../../app/feature/ProjectView/ComplexReducer/thunks";
import styles from "./ObjectCard.module.scss";
import { StopIcon } from "../../../../../newUI/SVG";
import { Preloader } from "../../../../../features";
import { getProjectComplexLoadDelete } from "../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { ButtonThreeDot } from "./ButtonThreeDot/ButtonThreeDot";
import { Info } from "./Info/Info";
import { Progress } from "./Progress/Progress";

export const ObjectCard = memo(
  ({
    status,
    name,
    dates,
    price,
    stage,
    gip,
    can,
    id,
    color,
    gipAccess,
    stopped,
    stopProcesses,
  }: IProjectCopmlex) => {
    const isLoadDelete = useSelector(getProjectComplexLoadDelete);

    const stopContent = (
      <div className={classNames(styles.object_header)}>
        <div className={styles.object_header_svg}>
          <StopIcon />
        </div>
        <p>Внимание!</p>
        <span>Приостановлено выполнение разделов {stopProcesses.join("")}</span>
      </div>
    );

    const statusContent = (
      <div className={styles.card_status}>
        <p>{status.value}</p>
        <span>{dates.end}</span>
      </div>
    );

    return (
      <div className={classNames(styles.object, stopped && styles.stopped)}>
        {isLoadDelete && <Preloader variant={styles.preloaderDelete} />}
        {stopped && stopContent}
        <div className={styles.card}>
          <div className={styles.card_content}>
            <Info name={name} gip={gip} id={id} stage={stage} />
            {statusContent}
            <Progress price={price} dates={dates} />
          </div>
          <ButtonThreeDot can={can} id={id} gip={gip} gipAccess={gipAccess} />
        </div>
      </div>
    );
  }
);
