import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequestGet } from "../../../services/api/api";
import { config } from "../../../../app.cofig";
import { request } from "../../../services/api/requestHandler";
import { pushError } from "../../errorTrace";

export const ApiGetWorkTasks = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/process-work/view?id=${id}&fields=task,taskCheck&expand=customerAlignTasks`
  );
};

export const getWorkTasks = createAsyncThunk(
  "dashboard/workTasks",
  async (id: number, { dispatch }) => {
    let result;

    await request(
      ApiGetWorkTasks(id),
      (data) => {
        result = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return result;
  }
);
