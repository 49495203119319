import { ReactNode } from "react";
import { IconButton } from "@mui/material";
import styles from "./Filter.module.scss";
import { ToggleAccordion } from "../../../newUI/ToggleAccordion/ToggleAccordion";

type Props = {
  children: ReactNode;
  isUp: boolean;
  handleToggle: () => void;
};

const Filter = ({ children, isUp, handleToggle }: Props) => {
  return (
    <div className={styles.filter} onClick={handleToggle}>
      <p>{children}</p>
      <IconButton>
        <ToggleAccordion color="white" caret toggle={isUp} />
      </IconButton>
    </div>
  );
};

export default Filter;
