import React from "react";
import { Skeleton as MUISkeleton } from "@mui/material";
import style from "./style.module.scss";
import { Wrapper } from "../../../../UI/templates";

export const Skeleton = () => {
  return (
    <Wrapper>
      <div className={style.container}>
        <div className={style.contentWrapper}>
          <MUISkeleton
            variant={"rectangular"}
            className={style.imageContainer}
          />
          <div className={style.controls}>
            <MUISkeleton variant={"rectangular"} className={style.control} />
            <MUISkeleton variant={"rectangular"} className={style.control} />
          </div>
          <MUISkeleton variant={"rectangular"} className={style.title} />
          <MUISkeleton variant={"rectangular"} className={style.content} />
        </div>
      </div>
    </Wrapper>
  );
};
