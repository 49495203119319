import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from "@mui/material";
import cn from "classnames";
import style from "./style.module.scss";
import { ApiGetPartGroup } from "../../../app/services/api/partGroup/partGroup";
import { request } from "../../../app/services/api/requestHandler";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const boxSX = {
  display: "flex",
  flexWrap: "wrap",
  gap: 0.75,
};

type SpecializationMultipleItem = ComponentPropsType<{
  title: string;
  id: number;
}>;

export type SpecializationMultipleProps = ComponentPropsType<{
  value: SpecializationMultipleItem[];
  error: boolean;
  changeHandler: (data: string[]) => void;
  disabled: boolean;
}>;

export const SpecializationMultiple: React.FC<SpecializationMultipleProps> = ({
  value,
  error,
  changeHandler,
  disabled = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [allSpec, setAllSpec] = useState<SpecializationMultipleItem[]>([]);

  const onLoadSpec = () => {
    request(
      ApiGetPartGroup(),
      (data) => {
        setAllSpec(
          data.map((item: { name: string; id: number }) => ({
            id: item.id,
            title: item.name,
          }))
        );
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса специализаций", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  };

  useEffect(() => {
    onLoadSpec();
  }, []);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const data = (typeof value === "string" ? value.split(",") : value).map(
      (item: any) => allSpec.find((t) => t.title === item)
    );

    changeHandler(data);
  };

  const valueForSelect = value.map((item) => item.title);

  return (
    <FormControl
      fullWidth
      disabled={disabled}
      className={style.specializationForm}
    >
      <InputLabel shrink className={cn(error && style.error)}>
        Специализация
      </InputLabel>
      <Select
        multiple
        fullWidth
        value={valueForSelect}
        onChange={handleChange}
        inputProps={{
          classes: {
            root: style.selectroot,
            icon: style.icon,
          },
        }}
        renderValue={(selected) => (
          <Box sx={boxSX}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        error={error}
      >
        {allSpec.map((name) => (
          <MenuItem
            classes={{ root: style.menuitem, selected: style.selected }}
            key={name.id}
            value={name.title}
          >
            {name.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
