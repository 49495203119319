import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { CheckSquareFill, PencilSquare, Trash } from "react-bootstrap-icons";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { config } from "../../../../../app.cofig";
import styles from "./Admin.module.scss";
import { UserEditModal } from "./UserEditModal";
import { GipEditModal } from "./GipEditModal";
import { formFullUserName } from "../../../../../FSD/shared/lib/helpers";
import { CheckSVG } from "../../../../newUI/SVG";
import { NewAvatar } from "../../../../newUI/NewAvatar/NewAvatar";
import {
  TextLink,
  ThemeTextLink,
} from "../../../../UI/components/TextLink/TextLink";
import { newIconBtnSx } from "../../../../../styles/restyle";
import { NewModal } from "../../../../newUI/NewModal/NewModal";
import { getProjectUsersList } from "../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";

type UserCardProps = {
  handleRemoveUser: (id: number) => void;
  setModalTaskComplete: any;
} & Record<string, any>;

const UserCard = ({
  setModalTaskComplete,
  handleRemoveUser,
  ...userData
}: UserCardProps) => {
  const { project } = useSelector(getProjectViewData);
  const { isLoadUpdate } = useSelector(getProjectUsersList);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalEditGip, setModalEditGip] = useState(false);
  const canComplete = userData.can.complete;
  const isGip = userData.accessCode.code === 4;
  const canUpdate = userData.can?.update;
  const canDelete = userData.can?.delete;
  const role = userData.accessCode.title;
  const taskId = userData.task_id;

  const closeModalUser = () => {
    setModalEditUser(false);
  };

  const closeModalGip = () => {
    setModalEditGip(false);
  };

  if (isGip) {
    return (
      <div className={classNames(styles.basic, styles.flexCol)}>
        <NewModal
          active={modalEditGip}
          setActive={setModalEditGip}
          title={"Изменить доступ к проекту"}
          isLoad={isLoadUpdate}
        >
          <GipEditModal closeModalGip={closeModalGip} />
        </NewModal>
        <div className={styles.flexCol}>
          <h1 className={styles.role}>{role}</h1>
          <div className={styles.name}>
            <p className={styles.name_fio}>
              <TextLink
                url={`/user/${project?.author?.id}`}
                customTheme={ThemeTextLink.TITLE}
              >
                {formFullUserName(userData.user)}
              </TextLink>
            </p>
            <p className={styles.name_firm}>
              {userData.userType?.listName ?? ""}
            </p>
          </div>
        </div>

        <div className={styles.controls}>
          {canUpdate && (
            <IconButton
              onClick={() => setModalEditGip(userData.id)}
              sx={newIconBtnSx}
            >
              <PencilSquare />
            </IconButton>
          )}
          {canComplete && (
            <IconButton
              onClick={() => setModalTaskComplete({ role, taskId })}
              sx={newIconBtnSx}
            >
              <CheckSVG width={"20"} height={"20"} />
            </IconButton>
          )}
          {canDelete && (
            <IconButton
              onClick={() => handleRemoveUser(userData.id)}
              sx={newIconBtnSx}
            >
              <Trash color={"white"} />
            </IconButton>
          )}
        </div>
        <div className={styles.avatar}>
          <NewAvatar
            photoUrl={`${config.localDomain}${userData.user.photo}`}
            rating={userData.rating}
            jobDuration={Number((userData.user.job_duration / 365).toFixed(1))}
            borderColor={"#F5A623"}
            classname={styles.avatar}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.basic, styles.flexCol)}>
      <NewModal
        active={modalEditUser}
        setActive={setModalEditUser}
        title={"Изменить доступ к проекту"}
        isLoad={isLoadUpdate}
      >
        <UserEditModal closeModalUser={closeModalUser} />
      </NewModal>
      <div className={styles.flexCol}>
        <h1 className={styles.role}>{role}</h1>
        <div className={styles.name}>
          <p className={styles.name_fio}>
            <TextLink
              url={`/user/${project?.author?.id}`}
              customTheme={ThemeTextLink.TITLE}
            >
              {formFullUserName(userData.user)}
            </TextLink>
          </p>
          <p className={styles.name_firm}>
            {userData.userType?.listName ?? ""}
          </p>
        </div>
      </div>
      <div className={styles.controls}>
        {canUpdate && (
          <IconButton
            onClick={() => setModalEditUser(userData.id)}
            sx={newIconBtnSx}
          >
            <PencilSquare />
          </IconButton>
        )}
        {canComplete && (
          <IconButton
            onClick={() => setModalTaskComplete({ role, taskId })}
            sx={newIconBtnSx}
          >
            <CheckSquareFill color={"white"} />
          </IconButton>
        )}
        {canDelete && (
          <IconButton
            onClick={() => handleRemoveUser(userData.id)}
            sx={newIconBtnSx}
          >
            <Trash color={"white"} />
          </IconButton>
        )}
      </div>
      <div className={styles.avatar}>
        <NewAvatar
          photoUrl={`${config.localDomain}${userData.user.photo}`}
          rating={userData.rating}
          jobDuration={Number((userData.user.job_duration / 365).toFixed(1))}
          borderColor={"#F5A623"}
          classname={styles.avatar}
        />
      </div>
    </div>
  );
};

export default UserCard;
