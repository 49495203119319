import React, { memo } from "react";
import classes from "./Modal.module.scss";
import { Preloader } from "../../features";

export const ModalPreloader = memo(() => {
  return (
    <div className={classes.preloaderModal}>
      <Preloader variant={classes.preloaderModal_background} />
    </div>
  );
});
