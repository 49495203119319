import React, { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  fetchProjectProcessRemoveExecutor,
  fetchProjectProcessRemoveExecutorData,
} from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessRemoveExecutor";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getExecutorProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { getProjectProcessRemoveExecutorDataIsLoad } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import { FirstForm } from "./FirstForm/FirstForm";
import { RefundForm } from "./RefundForm/RefundForm";
import { LastForm } from "./LastForm/LastForm";

interface IRemoveExecutorProps {
  closeModal: () => void;
}

export const RemoveExecutor = memo(({ closeModal }: IRemoveExecutorProps) => {
  const [currentForm, setCurrentForm] = useState(1);
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);
  const executor = useAppSelector(getExecutorProcessInfo);
  const isLoadRemoveExecutorData = useAppSelector(
    getProjectProcessRemoveExecutorDataIsLoad
  );

  useEffect(() => {
    if (processId && executor) {
      dispatch(
        fetchProjectProcessRemoveExecutorData({
          processId,
          executorId: executor.id,
        })
      );
    }
  }, []);

  const handleFormSubmit = async (dataValue: any) => {
    if (dataValue?.status === "end" && processId) {
      await dispatch(
        fetchProjectProcessRemoveExecutor({ processId, data: dataValue.data })
      );
      closeModal();
    }
    if (dataValue.status === "notRefund") {
      setCurrentForm((prevForm) => prevForm + 2);
    } else {
      setCurrentForm((prevForm) => prevForm + 1);
    }
  };

  const handleBackClick = (isRefund: string) => {
    if (isRefund === "refund") {
      setCurrentForm((prevForm) => prevForm - 1);
    } else {
      setCurrentForm((prevForm) => prevForm - 2);
    }
  };

  const renderCurrentForm = () => {
    switch (currentForm) {
      case 1:
        return <FirstForm onSubmit={handleFormSubmit} />;
      case 2:
        return (
          <RefundForm
            onSubmit={handleFormSubmit}
            handleBackClick={handleBackClick}
          />
        );
      case 3:
        return (
          <LastForm
            handleBackClick={handleBackClick}
            onSubmit={handleFormSubmit}
          />
        );
      default:
        return null;
    }
  };

  if (isLoadRemoveExecutorData) {
    return <NewModalPreloader />;
  }

  return <div>{renderCurrentForm()}</div>;
});
