import React from "react";
import { Skeleton } from "@mui/material";
import style from "./style.module.scss";

export const NoticesSkeleton = () => {
  return (
    <div className={style.container}>
      <div className={style.contentWrapper}>
        <Skeleton variant="text" className={style.date} />
        <Skeleton variant="text" className={style.content} />
        <Skeleton variant="text" className={style.content} />
      </div>
      <div className={style.controlsWrapper}>
        <Skeleton variant="text" className={style.controls} />
        <Skeleton variant="text" className={style.controls} />
      </div>
    </div>
  );
};
