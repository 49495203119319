import { useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import useNonInitialEffect from "./useNonInitialEffect";

const useTabs = (
  currentTab: string,
  setCurrentTab: Dispatch<SetStateAction<string>>,
  defaultTab: string,
  currentChildTab?: string | number,
  setCurrentChildTab?: Dispatch<SetStateAction<string | number>>
) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const type = useNavigationType();
  const params = new URLSearchParams(search);
  const currentUrlTab = params.get("tab");

  useEffect(() => {
    setCurrentTab(currentUrlTab || defaultTab);
  }, []);

  useEffect(() => {
    if (type === "POP") {
      setCurrentTab(currentUrlTab || defaultTab);
    }
  }, [search]);

  let searchPath = `?tab=${currentTab}`;

  useEffect(() => {
    if (currentChildTab) {
      navigate({
        search: (searchPath += `&horizontalTab=${currentChildTab}`),
      });
    }
  }, [currentChildTab]);

  useNonInitialEffect(() => {
    if (currentTab && (currentUrlTab || currentTab !== currentUrlTab)) {
      const horizontalTab = params.get("horizontalTab");
      const subCard = params.get("subCard");
      const isAtSameTab = currentTab === currentUrlTab;

      if (horizontalTab) {
        searchPath += `&horizontalTab=${isAtSameTab ? horizontalTab : 1}`;
        if (setCurrentChildTab) {
          setCurrentChildTab(isAtSameTab ? horizontalTab : 1);
        }
      }

      if (subCard && isAtSameTab) {
        searchPath += `&subCard=${subCard}`;
      }

      navigate({
        search: searchPath,
      });
    }
  }, [currentTab]);
};

export default useTabs;
