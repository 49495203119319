import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import ExperienceItem from "./ExpirienceItem/ExpirienceItem";
import style from "./style.module.scss";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import {
  getErrorMessage,
  request,
} from "../../../../../app/services/api/requestHandler";
import { config } from "../../../../../app.cofig";
import { httpRequestPost } from "../../../../../app/services/api/api";
import C from "../../../../../constants";
import { UnsavedType } from "../../../Project/types";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";

const ApiCreateJobPlace = (newData: any): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/user-job-place/create`,
    {},
    newData
  );
};

type ComponentProps = {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
};

export type SubmitDataProps = {
  firm_name: string;
  firm_ogrn: string;
  date_start: Date | null;
  date_end: Date | null;
  prof: string;
  job_functions: string;
};

const Experience: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const {
    jobPlacesListIsLoading,
    jobPlacesList,
    loadJobPlaces,
    deleteJobPlace,
    editJobPlace,
  } = useProfile();

  useEffect(() => {
    loadJobPlaces(userId);
    // eslint-disable-next-line
  }, [userId]);

  const { enqueueSnackbar } = useSnackbar();

  const onCreate = async (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);

    const dateStart = moment(data.date_start).format("MM.YYYY");
    const dateEnd = data.date_end
      ? moment(data.date_end).format("MM.YYYY")
      : "";

    await request(
      ApiCreateJobPlace({
        ...data,
        user_id: userId,
        date_start: dateStart,
        date_end: dateEnd,
      }),
      () => {
        enqueueSnackbar("Запись успешно создана", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev: UnsavedType) => ({ ...prev, experience: false }));
        loadJobPlaces(userId);
      },
      () => (err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setIsFetching(false);
  };

  const onUpdate = (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    editJobPlace({ user_id: userId, ...data, id })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev: UnsavedType) => ({ ...prev, experience: false }));
        loadJobPlaces(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onDelete = (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!window.confirm(C.CONFIRM_DELETE)) {
      return;
    }

    setIsFetching(true);
    deleteJobPlace(id)
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        loadJobPlaces(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <div className={style.wrapper}>
      {jobPlacesListIsLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="232px"
        />
      ) : (
        <>
          <ExperienceItem setUnsaved={setUnsaved} onCreate={onCreate} />
          {jobPlacesList.map((jobPlace: any) => {
            return (
              <ExperienceItem
                setUnsaved={setUnsaved}
                key={jobPlace.id}
                onUpdate={onUpdate}
                onDelete={onDelete}
                jobPlace={jobPlace}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Experience;
