import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  processId: number;
  projectId: number;
  all?: boolean;
}>;

export const toProcesses = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.ToProcesses, TParams>({
    query: (params) => ({
      url: `v1/project-process/to-processes`,
      params: {
        processId: params.processId,
        projectId: params.projectId,
        all: params.all ?? false,
      },
    }),
  });
