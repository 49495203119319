import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProcessWorkResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  firmId: number;
}>;

export const getUsersToAssign = (builder: QueryBuilder) =>
  builder.query<ApiProcessWorkResponses.GetUserTypeToAssign, TParams>({
    query: (params) => ({
      url: `v1/process-work/get-users-to-assign`,
      params: {
        id: params.id,
        firmId: params.firmId,
      },
    }),
  });
