import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import { Wrapper } from "../../UI/templates";
import { userIsAuth } from "../../../app/services/auth/auth";
import TitlePanel from "../../newUI/TitlePanel/TitlePanel";
import TabsPanel from "../../newUI/TabsPanel/TabsPanel";
import { RedirectToExternal } from "../../features/RedirectToExternal/RedirectToExternal";
import {
  ChecksGridSVG,
  ColumnsGapSVG,
  CreditCardSVG,
  GearFillSVG,
  InfoSquareSVG,
  PeopleFilledSVG,
  ReportSVG,
  WalletSVG,
} from "../../newUI/SVG";
import OrganisationForm from "./tabs/Info/Form";
import { selectorOrganisationCreation } from "../../../app/feature/profileReducer/profileReducer";
import { TAnyObject } from "./tabs/Info/types";
import { ApiGetNdsList } from "../../../app/services/api/organisation/organisation";
import { request } from "../../../app/services/api/requestHandler";

type TNdsItem = {
  id: string;
  name: string;
};

const tabs = [
  {
    title: "Информация",
    url: "info",
    icon: <InfoSquareSVG />,
    incomplete: true,
  },
  {
    title: "Сотрудники",
    url: "staff",
    icon: <PeopleFilledSVG />,
    disabled: true,
  },
  {
    title: "Банк. счета",
    url: "accounts",
    icon: <CreditCardSVG />,
    disabled: true,
  },
  {
    title: "Заявки",
    url: "application",
    icon: <ColumnsGapSVG />,
    disabled: true,
  },
  {
    title: "Настройка",
    url: "settings",
    icon: <GearFillSVG />,
    disabled: true,
  },
  {
    title: "Отчёт",
    url: "",
    icon: <ReportSVG />,
    disabled: true,
  },
  {
    title: "Проверка",
    url: "task",
    icon: <ChecksGridSVG />,
    disabled: true,
  },
  {
    title: "Кошелек",
    url: "wallet",
    icon: <WalletSVG />,
    disabled: true,
  },
];

const OrganisationCreation = () => {
  const [ndsList, setNdsList] = useState<TAnyObject[]>([]);
  const isAuth = userIsAuth();

  useEffect(() => {
    (async () => {
      await request(ApiGetNdsList(), (res) => {
        const formattedList = res.map((item: TNdsItem) => ({
          id: item.id,
          title: item.name,
        }));

        setNdsList(formattedList);
      })();
    })();
  }, []);

  const organisationFirmData = useSelector(selectorOrganisationCreation);

  return (
    <>
      {isAuth ? (
        <div className={style.wrapper}>
          <Wrapper>
            <TitlePanel
              isContentLoad={false}
              primaryText={organisationFirmData.title}
              secondaryText={"Не проверенная"}
              can={{}}
              setEditing={() => {}}
            />
            <div className={style.contents}>
              <TabsPanel
                isContentLoad={false}
                setEditing={() => {}}
                tabsInfo={tabs}
                currentTab={"info"}
                setCurrentTab={() => {}}
                components={
                  <OrganisationForm
                    ndsList={ndsList}
                    isNew
                    firmData={null}
                    editingCondition={() => true}
                    setUnsaved={() => {}}
                    unsaved={false}
                    handleRequestTestedFirm={() => {}}
                  />
                }
                stepByStep
              />
            </div>
          </Wrapper>
        </div>
      ) : (
        <RedirectToExternal to={"/site/login"} />
      )}
    </>
  );
};

export default OrganisationCreation;
