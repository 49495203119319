import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { postViewInitialState } from "./postViewInitialState";
import C from "./constants";
import { ApiPostViewType } from "../../../types/api/responseTypes/apiPostType";
import { StateType } from "../../initialState";
import { getDateAndTime } from "../../services/date/date";

export const postViewReducer = createReducer(
  postViewInitialState,
  (builder) => {
    builder.addCase(
      C.SET_POST_FOR_VIEW,
      (state, action: PayloadAction<ApiPostViewType>) => {
        // eslint-disable-next-line
        const { id,  title, url, content, date_publication,  photoUrl, tags  } =
          action.payload;

        return {
          ...state,
          id,
          title,
          url,
          content,
          date_publication: date_publication
            ? getDateAndTime(date_publication, false)
            : null,
          photoUrl,
          tags: tags.length
            ? tags.map((item) => ({ id: item.id, title: item.label }))
            : [],
        };
      }
    );
  }
);

export const selectorPostView = (state: StateType) => state.postView;

export const setPostForView = (post: ApiPostViewType) => ({
  type: C.SET_POST_FOR_VIEW,
  payload: {
    ...post,
  },
});
