import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import style from "../style.module.scss";
import { TValuesData } from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { trimNumber } from "../../../../../../utils/trimNumber";
import {
  getProjectSections,
  setOpenNode,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { ThreeDotButtons } from "./ThreeDotButtons";
import { SecureIcon } from "../../../../../newUI/SVG";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";

type TreeProps = {
  data: TValuesData[];
};
type TreeNodeProps = {
  node: TValuesData;
};

const SectionsTree = ({ data = [] }: TreeProps) => {
  return (
    <ul className={style.sections_container}>
      {data.map((tree) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode
          key={`${tree.id}${tree.name}${tree.date_start}`}
          node={tree}
        />
      ))}
    </ul>
  );
};

const TreeNode: React.FC<TreeNodeProps> = ({ node }) => {
  const isMobile = useIsMobile(680);
  const dispatch = useDispatch();
  const { openNode } = useSelector(getProjectSections);
  const hasChild = !!node.data;
  const isRuble = node.price === "Договорная";

  const arrowIcon = <ToggleAccordion toggle={openNode[node?.num]} />;

  return (
    <li
      className={classNames(
        !hasChild && style.sections_childOddEven,
        hasChild && style.sections_marg
      )}
    >
      <div
        className={classNames(
          hasChild && style.sections_node,
          !hasChild && style.sections_child
        )}
        onClick={() => dispatch(setOpenNode(node?.num))}
      >
        <span className={style.sections_name}>{node.name}</span>
        {hasChild && <div>{arrowIcon}</div>}
        {!hasChild && (
          <div className={style.parent}>
            <div
              className={classNames(style.parent_flex, isMobile && style.code)}
            >
              <TextLink
                redirect
                url={`/project-process/${node.id}`}
                target="_blank"
              >
                <div title={node.title} className={style.parent_code}>
                  {node.projectPart.code}
                </div>
              </TextLink>
              <div className={style.parent_status}>{node.status.value}</div>
            </div>
            <div
              className={classNames(
                style.parent_flex,
                isMobile && style.author
              )}
            >
              {node.activeAccess && (
                <>
                  <div
                    className={style.parent_author}
                  >{`${node.activeAccess?.user?.surname} ${node.activeAccess?.user?.name} ${node.activeAccess?.user?.patronymic}`}</div>
                  <div className={style.parent_type}>
                    {node.activeAccess.userType.listName}
                  </div>
                </>
              )}
              {!node.activeAccess && node.status.key === 2 && (
                <>
                  <div>
                    <div className={style.parent_type}>Форма собственности</div>
                    <div
                      className={classNames(
                        style.parent_type_executor,
                        style.parent_type
                      )}
                    >
                      <div className={style.parent_type_title}>
                        {node.executorType.map((type) => (
                          <span key={type.value}>{type.value}</span>
                        ))}
                      </div>
                      <div className={style.parent_type_countAccesses}>
                        <p>Заявки</p>
                        <TextLink
                          url={`/project-process/${node.id}?tab=application`}
                          redirect
                        >
                          <p className={style.parent_type_count}>
                            {node.countAccesses}
                          </p>
                        </TextLink>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className={classNames(style.parent_flex, isMobile && style.price)}
            >
              {node.price && (
                <div className={style.parent_price}>
                  {trimNumber(node.price)}
                  <span className={style.parent_price_ruble}>
                    {!isRuble && "₽"}
                  </span>
                  {node.secure && (
                    <SecureIcon width="16" heigth="18" fill="#9DAFBD" />
                  )}
                </div>
              )}
              {node.date_start && (
                <div className={style.parent_date}>
                  {node.date_start} - {node.date_limit}
                </div>
              )}
            </div>
            <ThreeDotButtons node={node} />
          </div>
        )}
      </div>

      {hasChild && openNode[node?.num] && node.data && (
        <div>
          <SectionsTree data={Object.values(node?.data)} />
        </div>
      )}
    </li>
  );
};

export default SectionsTree;
