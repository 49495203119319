import React from "react";
import classNames from "classnames";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { trimPrice, trimRubPennies } from "src/utils/trimNumber";
import { ProjectProcessesType } from "src/types/api/responseTypes/apiDocumentsType";
import { SecureIcon } from "src/components/newUI/SVG";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { TooltipWrapper } from "src/components/newUI/TooltipWrapper/TooltipWrapper";
import { useDocumentsProcessDocs } from "../libs/hooks/useDocumentsProcessDocs";
import styles from "./DocumentsProcessDocs.module.scss";

interface DocumentsProcessDocsProps {
  el: ProjectProcessesType;
  index: number;
}
export const DocumentsProcessDocs = ({
  el,
  index,
}: DocumentsProcessDocsProps) => {
  const { processDocs, dotType, sxTrue, sxFalse, toggleProcessDos } =
    useDocumentsProcessDocs();

  return (
    <>
      <div
        className={styles.borderDocs}
        key={el.id}
        onClick={() => toggleProcessDos(index)}
      >
        <div
          className={classNames(
            styles.gridDocs,
            styles.background,
            styles.color
          )}
        >
          <div className={styles.gridDocs_code}>
            <TooltipWrapper content={el.title}>
              <TextLink
                redirect
                target={"_blank"}
                variant="title"
                url={`/project-process/${el.id}`}
              >
                {el.code}
              </TextLink>
            </TooltipWrapper>

            {el.secure && (
              <div className={styles.parent_code_svg}>
                <SecureIcon width={"20"} heigth={"24"} fill={"#6B7D8A"} />
              </div>
            )}
          </div>
          <div className={styles.parent_document}>
            <div>
              <FiberManualRecordIcon sx={sxTrue} />
              {el.pairSignCountList.PAIR_GREEN > 0 ? (
                <span>{el.pairSignCountList.PAIR_GREEN}</span>
              ) : (
                <span>-</span>
              )}
            </div>
            <div>
              <FiberManualRecordIcon sx={sxFalse} />
              {el.pairSignCountList.PAIR_ORANGE > 0 ? (
                <span>{el.pairSignCountList.PAIR_ORANGE}</span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.hidden, el.danger && styles.danger)}
          >
            {trimRubPennies(el.allValue)}
          </div>
          <div
            className={classNames(styles.hidden, el.danger && styles.danger)}
          >
            {trimRubPennies(+el.inputValue)}
          </div>
          <div className={styles.hidden}>{trimPrice(el.pay1)}</div>
          <div className={styles.hidden}>{trimPrice(el.pay2)}</div>
          <div className={styles.hidden}>{trimPrice(el.pay3)}</div>
        </div>
        {el?.docs?.length > 0 && (
          <ToggleAccordion root toggle={processDocs[index]} />
        )}
      </div>
      {processDocs[index] && (
        <div style={{ background: "#0D141A", paddingLeft: "40px" }}>
          {el.docs.map((doc) => (
            <div
              className={classNames(styles.docs, styles.gridProcessDocs)}
              key={doc.id}
            >
              <div className={styles.flexSign}>
                <div className={styles.card_signDocs}>
                  <span>{dotType(doc.sign.author)}</span>
                  <span>{dotType(doc.sign.signer)}</span>
                </div>
                <TextLink redirect url={`/document/view?id=${doc.id}`}>
                  <div className={styles.docs_link}>{`${doc.type} № ${
                    doc.num || ""
                  } от ${doc.date_create}`}</div>
                </TextLink>
              </div>
              <div />
              <div />
              <div />
              <div className={styles.hidden}>{trimPrice(doc?.pay1 || "0")}</div>
              <div className={styles.hidden}>{trimPrice(doc?.pay2 || "0")}</div>
              <div className={styles.hidden}>{trimPrice(doc?.pay3 || "0")}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
