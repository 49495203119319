import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы снятия поиска ГИПа
 */

export const showEventRemoveRequestSearchGip = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventRemoveRequestSearchGip: {
      ...state.eventRemoveRequestSearchGip,
      isShow: true,
    },
  };
};

export const closeEventRemoveRequestSearchGip = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventRemoveRequestSearchGip: {
    isShow: false,
    data: {
      ...state.eventRemoveRequestSearchGip.data,
    },
  },
});

export const selectorEERemoveRequestSearchGip = (state: StateType) =>
  state.eventEmitter.eventRemoveRequestSearchGip.isShow;
