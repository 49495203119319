import { config } from "../../../app.cofig";

/*
    Функция необходима для того, чтобы фронт мог работать не от корневого роута.
    Использует в App компоненте
 */
export const getRootPath = (fullPath: string): string =>
  fullPath.match(config.testRootPath) ? config.testRootPath : "";

/*
    Проверяет, является ли роут корневым
 */
export const isNotRootPath = (path: string): boolean => path === "/";
