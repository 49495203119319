import { combineReducers } from "@reduxjs/toolkit";
import projectProcessInfo from "./Info/slice/projectProcessInfo";
import projectProcessDocuments from "./Documents/slice/projectProcessDocuments";
import projectProcessTasks from "./Tasks/slice/projectProcessTasks";
import projectProcessRemoveExecutors from "./RemoveExecutors/slice/projectProcessRemoveExecutors";
import projectProcessApplications from "./Applications/slice/projectProcessApplications";
import projectProcessMaterial from "./Material/slice/projectProcessMaterial";
import projectProcessCheckTask from "./CheckTask/slice/projectProcessCheckTask";
import projectProcessDocumentations from "./Documentations/slice/projectProcessDocumentations";
import projectProcessInitialData from "./InitialData/slice/projectProcessInitialData";
import projectProcessWork from "./Work/slice/projectProcessWork";
import projectProcessHeaderBtn from "./HeaderBtn/slice/projectProcessHeaderBtn";
import projectProcessMileStone from "./MileStone/slice/projectProcessMileStone";
import projectProcessExpertise from "./Expertise/slice/projectProcessExpertise";
import expertiseDocuments from "./ExpertisePage/ExpertiseDocuments/slice/expertiseDocuments";
import expertiseHeaderBtn from "./ExpertisePage/ExpertiseHeaderBtn/slice/expertiseHeaderBtn";
import expertiseConclusion from "./ExpertisePage/ExpertiseConclusion/slice/expertiseConclusion";
import expertiseRemarks from "./ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import projectProcessRemoveExecutor from "./Info/slice/projectProcessRemoveExecutor";
import submitMaterialsForm from "./Material/slice/submitFormMaterials";
import updateMaterialsForm from "./Material/slice/updateFormMaterials";

const projectProcessSlice = combineReducers({
  info: projectProcessInfo.reducer,
  documents: projectProcessDocuments.reducer,
  documentations: projectProcessDocumentations.reducer,
  tasks: projectProcessTasks.reducer,
  removeExecutors: projectProcessRemoveExecutors.reducer,
  applications: projectProcessApplications.reducer,
  material: projectProcessMaterial.reducer,
  checkTask: projectProcessCheckTask.reducer,
  initialData: projectProcessInitialData.reducer,
  work: projectProcessWork.reducer,
  headerBtn: projectProcessHeaderBtn.reducer,
  mileStone: projectProcessMileStone.reducer,
  expertise: projectProcessExpertise.reducer,
  expertiseDocuments: expertiseDocuments.reducer,
  expertiseHeaderBtn: expertiseHeaderBtn.reducer,
  expertiseConclusion: expertiseConclusion.reducer,
  expertiseRemarks: expertiseRemarks.reducer,
  removeExecutor: projectProcessRemoveExecutor.reducer,
  submitMaterialsForm: submitMaterialsForm.reducer,
  updateMaterialsForm: updateMaterialsForm.reducer,
});

export default projectProcessSlice;
