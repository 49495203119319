import { createSlice } from "@reduxjs/toolkit";
import { fetchConclusionExpertise } from "../services/fetchConclusionExpertise";
import { ExpertiseConclusionTypes } from "../types/expertiseConclusionTypes";

type InitialState = {
  isLoadConclusion: boolean;
  conclusion: ExpertiseConclusionTypes[] | undefined;
};

const initialState: InitialState = {
  isLoadConclusion: false,
  conclusion: undefined,
};

const expertiseConclusion = createSlice({
  name: "expertiseConclusion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConclusionExpertise.pending, (state) => {
      state.isLoadConclusion = true;
    });
    builder.addCase(fetchConclusionExpertise.fulfilled, (state, action) => {
      state.isLoadConclusion = false;
      state.conclusion = action.payload;
    });
  },
});
export default expertiseConclusion;
