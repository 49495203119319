import React, { Fragment } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import { TaskType } from "src/app/feature/ProjectView/GraphReducer/thunks";
import {
  TooltipTheme,
  TooltipWrapper,
} from "src/components/newUI/TooltipWrapper/TooltipWrapper";
import style from "./GraphRender.module.scss";

interface PlanGraphProps {
  graph: TaskType;
  name: string;
  today: any;
}

export const GraphRender = ({ graph, name, today }: PlanGraphProps) => {
  let expertiseValue;
  let workValue;
  const { div1 } = graph;

  const isProject = name === "project";
  const isSource = name === "source";
  const isResearch = name === "research";
  const isInspection = name === "inspection";

  if (name === "expertise") {
    expertiseValue = Object.values(graph);
  }
  if (name === "work") {
    workValue = Object.values(graph);
  }

  const irdGraph = isSource && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"500px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.container}>
        <div className={style.container_limit}>
          <div className={style.parent}>
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.today}
            />
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.shadow}
            />
            <div
              className={classNames(style.block, style.ird)}
              style={{
                marginLeft: `${div1?.marginLeft}%`,
                minWidth: `${div1?.width > 3 ? div1.width : 3}%`,
              }}
            >
              <div className={style.date}>
                <p>{`c ${div1.dateStart}`}</p>
                <p>{`по ${div1.dateEnd}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const researchGraph = isResearch && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"300px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.container}>
        <div className={style.container_limit}>
          <div className={style.parent}>
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.today}
            />
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.shadow}
            />
            <div
              className={classNames(style.block, style.research)}
              style={{
                marginLeft: `${div1?.marginLeft}%`,
                minWidth: `${div1?.width > 3 ? div1.width : 3}%`,
              }}
            >
              <div className={style.date}>
                <p>{`c ${div1.dateStart}`}</p>
                <p>{`по ${div1.dateEnd}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const inspectionGraph = isInspection && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"300px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.container}>
        <div className={style.container_limit}>
          <div className={style.parent}>
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.today}
            />
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.shadow}
            />
            <div
              className={classNames(style.block, style.inspection)}
              style={{
                marginLeft: `${div1?.marginLeft}%`,
                width: `${div1?.width}%`,
                minWidth: "3%",
              }}
            >
              <div className={style.date}>
                <p>{`c ${div1.dateStart}`}</p>
                <p>{`по ${div1.dateEnd}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const projectGraph = isProject && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"300px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.flexCol}>
        <div className={style.container}>
          <div className={style.container_limit}>
            <div className={style.parent}>
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "0",
                }}
                className={style.today}
              />
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "0",
                }}
                className={style.shadow}
              />
              <div
                className={classNames(style.block, style.project)}
                style={{
                  marginLeft: `${div1?.marginLeft}%`,
                  minWidth: `${div1?.width > 3 ? div1.width : 3}%`,
                }}
              >
                <div className={style.date}>
                  <p>{`c ${div1.dateStart}`}</p>
                  <p>{`по ${div1.dateEnd}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {graph?.projectOtr && (
          <div className={style.container}>
            <div className={style.container_limit}>
              <div className={style.otr}>
                <div
                  style={{
                    marginLeft: `${today?.div1?.marginLeft}%`,
                    top: "0",
                  }}
                  className={style.today}
                />
                {Object.values(graph?.projectOtr).map((value, index) => (
                  <div
                    style={{
                      width: `${value?.width}%`,
                      marginLeft: index === 0 ? `${value?.marginLeft}%` : "",
                      background: value?.color,
                    }}
                    className={style.otr_block}
                    key={index}
                  >
                    <p style={{ overflow: "hidden" }}>{value.shortTitle}</p>
                    {index !== 2 && <div className={style.otrCircle} />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const expertiseGraph = expertiseValue && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"300px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.container}>
        <div className={style.container_limit}>
          <div className={style.parentExpertise}>
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.today}
            />
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.shadow}
            />
            {expertiseValue.map((value, index) => (
              <>
                {index === 0 && (
                  <div
                    key={value.shortTitle}
                    className={classNames(
                      expertiseValue.length === 1 && style.block,
                      expertiseValue.length > 1 && style.blockExpertise,
                      style.expertise
                    )}
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                      minWidth: "3%",
                    }}
                  >
                    {expertiseValue.length > 1 && (
                      <div className={style.expertiseCircle} />
                    )}
                    {expertiseValue.length === 1 && (
                      <div className={style.date}>
                        <p>{`c ${div1.dateStart}`}</p>
                        <p>{`по ${div1.dateEnd}`}</p>
                      </div>
                    )}
                  </div>
                )}
                {index === 1 && (
                  <div
                    key={value.shortTitle}
                    className={classNames(
                      style.blockExpertise2,
                      style.expertiseEx
                    )}
                    style={{
                      width: `${value?.width}%`,
                      minWidth: "3%",
                    }}
                  >
                    <p>ПЭ</p>
                    {expertiseValue.length > 2 && (
                      <div className={style.expertiseCircle} />
                    )}
                    {expertiseValue.length === 2 && (
                      <div className={style.date}>
                        <p>{`c ${div1.dateStart}`}</p>
                        <p>{`по ${div1.dateEnd}`}</p>
                      </div>
                    )}
                  </div>
                )}
                {index === 2 && (
                  <div
                    style={{
                      width: `${value?.width}%`,
                    }}
                    className={style.expReserve}
                  >
                    <p>Резерв</p>
                    {expertiseValue.length === 3 && (
                      <div className={style.expertiseDate}>
                        <p>{`c ${div1.dateStart}`}</p>
                        <p>{`по ${div1.dateEnd}`}</p>
                      </div>
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const workGraph = workValue && (
    <div className={style.flex}>
      <TooltipWrapper
        content={parse(div1?.title)}
        theme={TooltipTheme.CONTROL_WIDTH}
        width={"300px"}
      >
        <p className={style.text}>{div1?.shortTitle}</p>
      </TooltipWrapper>
      <div className={style.container}>
        <div className={style.container_limit}>
          <div className={style.parentExpertise}>
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.today}
            />
            <div
              style={{
                marginLeft: `${today?.div1?.marginLeft}%`,
                top: "0",
              }}
              className={style.shadow}
            />
            {workValue.map((value, index) => (
              <Fragment key={index}>
                {index === 0 && (
                  <div
                    className={classNames(
                      workValue.length === 1 && style.block,
                      workValue.length > 1 && style.blockExpertise,
                      style.work
                    )}
                    style={{
                      marginLeft: `${index === 0 ? value?.marginLeft : 0}%`,
                      width: `${value?.width > 3 ? value.width : 3}%`,
                    }}
                  >
                    {workValue.length > 1 && (
                      <div className={style.workCircle} />
                    )}
                    {workValue.length === 1 && (
                      <div className={style.date}>
                        <p>{`c ${value.dateStart}`}</p>
                        <p>{`по ${value.dateEnd}`}</p>
                      </div>
                    )}
                  </div>
                )}
                {index === 1 && (
                  <div
                    style={{
                      width: `${value?.width}%`,
                    }}
                    className={style.workReserve}
                  >
                    <p>Резерв</p>
                    <div className={style.date}>
                      <p>{`c ${value.dateStart}`}</p>
                      <p>{`по ${value.dateEnd}`}</p>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {irdGraph}
      {inspectionGraph}
      {researchGraph}
      {projectGraph}
      {expertiseGraph}
      {workGraph}
    </>
  );
};
