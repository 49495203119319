export const ReasonsOfRemoval = {
  BY_AGREEMENT: "agreement",
  BY_715: "article-715",
  BY_717: "article-717",
};

export const ReasonsDescriptions = {
  [ReasonsOfRemoval.BY_AGREEMENT]:
    "Стороны по обоюдному согласию решили расторгнуть заключенный договор и не имеют претензий друг к другу.",
  [ReasonsOfRemoval.BY_715]:
    "Отказ заказчика от исполнения договора подряда на основании Статьи 717 Гражданского кодекса Российской Федерации. Заказчик имеет право в любое время, на любом этапе выполнения работ (до момента принятия объекта) отказаться от своих обязательств, уведомив об этом подрядчика.",
  [ReasonsOfRemoval.BY_717]:
    "Отказ заказчика от исполнения договора подряда на основании Статьи 715 Гражданского кодекса Российской Федерации. Подрядчик не приступает своевременно к исполнению договора подряда или выполняет работу настолько медленно, что окончание её к сроку становится явно невозможным или во время выполнения работы станет очевидным, что она не будет выполнена надлежащим образом.",
};

export const reasonsOfExecutorRemoval = [
  { title: "По соглашению сторон", id: ReasonsOfRemoval.BY_AGREEMENT },
  {
    title: "Статья 715 Гражданского кодекса Российской Федерации",
    id: ReasonsOfRemoval.BY_715,
  },
  {
    title: "Статья 717 Гражданского кодекса Российской Федерации",
    id: ReasonsOfRemoval.BY_717,
  },
];

export enum ReasonsOfRemovalBy715 {
  HAS_NOT_STARTED_YET = "Не приступил своевременно к исполнению работ",
  WORKS_SLOWLY = "Выполняет работы медленно и окончание работ к сроку явно невозможно",
  WORKS_BADLY = "Очевидно, что работа не будет выполнена надлежащим образом",
}

export enum RemovalBy715Ids {
  HAS_NOT_STARTED_YET = 1,
  WORKS_SLOWLY = 2,
  WORKS_BADLY = 3,
}

export const RemovalBy715Details = [
  "Выбрать данный пункт можно после рассмотрения выданного предварительного решения или после окончания контрольного срока по запросу предварительного решения.",
  "Срок выполнения работ по заключенному договору не вышел.",
  "Если вы предполагаете, что исполнитель не работает, то запросите предварительное решение и установите контрольный срок его предоставления.",
];

export enum Steps {
  REASON,
  REFUND,
  PROCESSES,
}

export const AT_LEAST_ONE_CHECKED_REQUIRED =
  "Необходимо указать хотя бы одну задачу";
