import { Statuses } from "src/FSD/shared/lib/constants/statuses";

export const KanbanColumns = {
  ALL_TASKS: "Все задачи",
  IN_WORK: "В работе",
  IS_DONE: "Проверка",
  DONE: "Выполнено",
};

export const KanbanSortValues = {
  ASC: "date_deadline",
  DESC: "-date_deadline",
};

export const KanbanStatuses = {
  [KanbanColumns.ALL_TASKS]: "all",
  [KanbanColumns.IN_WORK]: Statuses.WORK,
  [KanbanColumns.IS_DONE]: Statuses.IS_DONE,
  [KanbanColumns.DONE]: Statuses.DONE,
};
