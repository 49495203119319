import React from "react";
import moment from "moment";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { DateEndPopper } from "src/components/newUI/SourceDataView/DateEndPopper";
import { fetchTaskDataUpdate } from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import styles from "./Deadlines.module.scss";

export const Deadlines = () => {
  const dispatch = useAppDispatch();
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const { isLoadDateUpdate } = useSelector(getProjectInitialData);

  const canEditDate = !!infoData.task && infoData.can.update;

  const factDateCreate =
    infoData.task && infoData.task.date_create
      ? moment(infoData.task.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
          "DD.MM.YYYY"
        )
      : "";
  const factDateEnd =
    infoData.task &&
    (infoData.task.date_deadline || infoData.task.date_complete)
      ? moment(
          infoData.task.date_complete || infoData.task.date_deadline,
          "DD.MM.YYYY HH:mm:ss Z"
        ).format("DD.MM.YYYY")
      : "";

  const onChangeDate = async (data: any) => {
    const dataParams = {
      date_create: moment(data.startDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
      date_deadline: moment(data.endDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
    };
    if (infoData.task.id) {
      const { id } = infoData.task;
      await dispatch(fetchTaskDataUpdate({ id, dataParams }));
    }
    if (infoData.id) {
      dispatch(fetchProjectProcessViewDuplicate(infoData.id));
    }
  };

  return (
    <div
      className={classNames(styles.container, infoData.paused && styles.paused)}
    >
      <h2>Сроки выполнения</h2>
      <div className={styles.container_row}>
        <div className={styles.label}>Плановый:</div>
        <div className={styles.dateText}>{infoData.date_start || ""}</div>
        <div className={styles.dateText}>&nbsp;-&nbsp;</div>
        <DateEndPopper
          date_create={infoData.date_start}
          date_deadline={infoData.date_limit}
          canEditDate={canEditDate}
          onChangeDate={onChangeDate}
          isLoadDateUpdate={isLoadDateUpdate}
          isMobile={false}
          classname={styles.dateText}
        />
      </div>
      {factDateCreate && (
        <div className={styles.container_row}>
          <div className={styles.label}>Фактический:</div>
          <div className={styles.dateText}>{factDateCreate}</div>
          <div className={styles.dateText}>&nbsp;-&nbsp;</div>
          <div className={styles.dateText}>{factDateEnd}</div>
        </div>
      )}
    </div>
  );
};
