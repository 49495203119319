import React, { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { useParams } from "react-router";
import { SourceDataType } from "../../../../../app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { fetchProjectProcessSourceData } from "../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceData";
import {
  getProjectProcessOpenSourceData,
  getProjectProcessSourceChild,
  getProjectProcessSourceChildIsLoad,
} from "../../../../../app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessSourceChild";
import { SourceDataCard } from "./SourceDataCard/SourceDataCard";
import cls from "./SourceData.module.scss";
import { AddFile, GearFillSVG } from "../../../../newUI/SVG";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { CircularIndeterminate } from "../../../../UI/components/Project/Skeleton";
import { ModalGear } from "./ModalGear/ModalGear";
import {
  getProjectProcessCheckList,
  getProjectProcessCheckListIsLoad,
} from "../../../../../app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessCheckList";
import { fetchProjectProcessChecklist } from "../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessChecklist";
import { getProjectProcessSourceDataUpdateIsLoad } from "../../../../../app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessSourceDataUpdate";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProject,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { setOpenSourceData } from "../../../../../app/feature/ProjectProcessView/InitialData/slice/projectProcessInitialData";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import AddCheckListModal from "../../../Project/View/InitialData/AddCheckListModal";
import {
  messages,
  sxBtn,
} from "../../../Project/View/InitialData/InitialDataCard";
import { deleteProjectChecklistById } from "../../../../../app/feature/ProjectView/InitialDataReducer/thunks";
import { setDisabled } from "../../../../../app/feature/project/projectChecklist";
import { fetchProjectProcessInitialData } from "../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { fetchStageList } from "../../../../../app/feature/project/projectChecklist/thunks";

export const SourceData: React.FC<
  SourceDataType & { index: number; type: string | number }
> = memo(({ name, parentId, projectId, index, all, inWork, dot, type }) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile(681);
  const processId = useAppSelector(getProjectProcessInfoId);
  const params = useParams();

  const project = useAppSelector(getProjectProcessInfoProject);
  const sourceChild = useAppSelector(getProjectProcessSourceChild);
  const checkList = useAppSelector(getProjectProcessCheckList);
  const openSourceData = useAppSelector(getProjectProcessOpenSourceData);
  const isLoadCheckList = useAppSelector(getProjectProcessCheckListIsLoad);
  const isLoadSourceChild = useAppSelector(getProjectProcessSourceChildIsLoad);
  const IsLoadSourceDataUpdate = useAppSelector(
    getProjectProcessSourceDataUpdateIsLoad
  );

  const isOpen = !Number.isNaN(Number(openSourceData[index]));

  const [modalGear, setModalGear] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);

  const canViewProject = project?.can.view;
  const canAddFile = project?.can.editByGipHelper || false;
  const canGearBtn = isMobile && isOpen && project?.can.view;

  const closeModal = () => {
    setModalGear(false);

    dispatch(
      fetchProjectProcessSourceData({
        projectId,
        parentId,
        index,
        type: Number(type),
      })
    );
  };

  const accordionClick = (index: number) => {
    dispatch(setOpenSourceData(index));
    if (!openSourceData[index] && !sourceChild[index]) {
      dispatch(
        fetchProjectProcessSourceData({
          projectId,
          parentId,
          index,
          type: Number(type),
        })
      );
    }
  };

  const gearBtnClickIsMobile = (e: Event) => {
    e.stopPropagation();
    setModalGear(true);
    dispatch(
      fetchProjectProcessChecklist({
        projectId,
        parentId,
        type: type as number,
      })
    );
  };

  const gearBtnClick = () => {
    setModalGear(true);
    dispatch(
      fetchProjectProcessChecklist({
        projectId,
        parentId,
        type: type as number,
      })
    );
  };

  const closeModalAddFiles = async () => {
    setAddFileModal(false);

    if (processId) {
      await dispatch(fetchProjectProcessInitialData(processId as number));

      await dispatch(
        fetchProjectProcessSourceData({
          projectId,
          parentId,
          index,
          type: Number(type),
        })
      );

      return;
    }
    if (params.id) {
      dispatch(fetchStageList(Number(params.id)));
    }
  };

  const openAddFileModal = () => {
    setAddFileModal(true);
  };

  const handleDeleteSourceDataName = async (sourceDataId: number) => {
    if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
      return;
    }

    dispatch(setDisabled("delete"));
    await dispatch(deleteProjectChecklistById(sourceDataId));

    await dispatch(
      fetchProjectProcessSourceData({
        projectId,
        parentId,
        index,
        type: Number(type),
      })
    );
    await dispatch(
      fetchProjectProcessChecklist({
        projectId,
        parentId,
        type: type as number,
      })
    );

    dispatch(setDisabled(false));
  };

  return (
    <>
      <div onClick={() => accordionClick(index)}>
        <ModalWrapper
          show={modalGear}
          handleCloseModal={() => {
            setModalGear(false);
          }}
          pending={isLoadCheckList || IsLoadSourceDataUpdate}
        >
          {!isLoadCheckList && checkList && (
            <ModalGear
              type={Number(type)}
              checkList={checkList}
              parentId={parentId}
              projectId={projectId}
              name={name}
              closeModal={closeModal}
              index={index}
              handleDeleteSourceDataName={handleDeleteSourceDataName}
            />
          )}
        </ModalWrapper>
        <ModalWrapper
          show={addFileModal}
          handleCloseModal={() => {
            setAddFileModal(false);
          }}
        >
          <AddCheckListModal
            closeModal={closeModalAddFiles}
            name={name}
            parentId={parentId}
            projectId={Number(project?.id)}
          />
        </ModalWrapper>
        <Accordion
          text={name}
          toogle={isOpen}
          canGearBtn={canGearBtn}
          gearBtnClick={gearBtnClickIsMobile}
          count={((all > 0 || inWork > 0) && `${inWork}/${all}`) || ""}
          dotStatus={dot ? "warning" : ""}
        />
      </div>
      {isOpen && (
        <>
          <div className={cls.container}>
            {!isMobile && (
              <div className={cls.parent}>
                <div>Статус</div>
                <div>Наименование</div>
                <div>Дата запроса</div>
                <div>Контр. срок</div>
                <div>Согласовано</div>
                <div className={cls.parent_icon}>
                  {canAddFile && (
                    <IconButton sx={sxBtn} onClick={openAddFileModal}>
                      <AddFile />
                    </IconButton>
                  )}
                  {canViewProject && (
                    <IconButton sx={sxBtn} onClick={gearBtnClick}>
                      <GearFillSVG />
                    </IconButton>
                  )}
                </div>
                {sourceChild[index] &&
                  sourceChild[index].map((child) => (
                    <SourceDataCard
                      key={child.id}
                      {...child}
                      parentId={parentId}
                      index={index}
                      type={Number(type)}
                      canAddFile={canAddFile}
                    />
                  ))}
              </div>
            )}
            {isMobile &&
              sourceChild[index] &&
              sourceChild[index].map((child) => (
                <SourceDataCard
                  key={child.id}
                  {...child}
                  isMobile={isMobile}
                  parentId={parentId}
                  index={index}
                  type={Number(type)}
                  canAddFile={canAddFile}
                />
              ))}
            {!sourceChild[index] && isLoadSourceChild && (
              <div className={cls.container}>
                <CircularIndeterminate />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
