import React, { memo, useState } from "react";
import { IProjectsValue } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { CardHeader } from "../CardHeader/CardHeader";
import styles from "./Project.module.scss";

export const Project = memo(
  ({ name, files, id }: IProjectsValue & { id: string }) => {
    const [toggleProject, setToggleProject] = useState(false);
    return (
      <>
        <CardHeader
          title={`Исходные данные по проекту ${name}`}
          expanded={toggleProject}
          onClick={() => setToggleProject((prev) => !prev)}
        />

        {toggleProject && (
          <div className={styles.container}>
            <FilesList fileLinks={files} />
            <TextLink
              className={styles.downloadAll}
              title={"Скачать все"}
              redirect
              target="blank"
              url={`/project/download-files-only-project?id=${id}`}
            >
              Скачать все
            </TextLink>
          </div>
        )}
      </>
    );
  }
);
