import { FC, useState, useMemo, useEffect } from "react";
import { IconButton, TextField } from "@mui/material";
import { CheckSquareFill, XSquareFill } from "react-bootstrap-icons";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import style from "./style.module.scss";
import { textFieldSX } from "../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getGroupControl,
  saveStagesPercents,
} from "../../../../../app/feature/milestone/thunks";
import {
  getMilestone,
  setGroupSum,
} from "../../../../../app/feature/milestone";

type TFormValues = Record<string, number>;

type TStagesPercents = {
  id: number;
  visible: boolean;
  stageGroupPercents: any;
  firmId: number;
};

const countSum = (data: Record<string, number>) =>
  Object.values(data).reduce(
    (count: number, value: number) => count + Number(value),
    0
  );

const StagesPercents: FC<TStagesPercents> = ({
  id,
  visible,
  stageGroupPercents,
  firmId,
}) => {
  const [sumError, setSumError] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { data: milestoneData } = useAppSelector(getMilestone);

  const defaultValues = stageGroupPercents.reduce(
    (result: TFormValues, item: any) => {
      return { ...result, [item.id]: Number(item.percent) };
    },
    {}
  );

  const schema = useMemo(() => {
    const shape = stageGroupPercents.reduce(
      (result: TFormValues, item: any) => {
        return {
          ...result,
          [item.id]: Yup.number()
            .nullable()
            .required("Поле обязательно")
            .typeError("Значение должно быть целым числом")
            .test(
              "inRange",
              "Значение должно быть от 0 до 100",
              (value: any) => {
                return (
                  Boolean(String(value).length) &&
                  Number(value) >= 0 &&
                  Number(value) <= 100
                );
              }
            )
            .test(
              "isNumber",
              "Значение должно быть целым числом",
              (value: any) => {
                return !Number.isNaN(value) && Number.isInteger(value);
              }
            ),
        };
      },
      {}
    );

    return Yup.object().shape(shape);
  }, [defaultValues]);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const percents = watch(); // значения процентов

  useEffect(() => {
    const total = countSum(percents);
    dispatch(setGroupSum({ total, id }));

    setSumError(total > 100);
  }, [percents]);

  const onSubmit = async (data: TFormValues) => {
    const total = countSum(percents);
    const isError = total > 100;

    setSumError(isError);

    if (isError) {
      return;
    }

    await dispatch(saveStagesPercents(data));
    await dispatch(
      getGroupControl({ bfType: milestoneData.buildingFunction.id, firmId })
    );
  };

  const handleResetForm = async () => {
    reset();
    dispatch(setGroupSum({ total: countSum(defaultValues), id }));
  };

  const containsStages = Boolean(stageGroupPercents.length);

  if (!visible) {
    return null;
  }

  if (!containsStages) {
    return <div className={style.notFound}>Вехи не найдены</div>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(style.processList, style.parts, style.form)}
    >
      {containsStages && (
        <>
          <IconButton type="submit" className={style.formButton}>
            <CheckSquareFill />
          </IconButton>
          <IconButton
            onClick={handleResetForm}
            className={cn(style.formButton, style.cancel)}
          >
            <XSquareFill />
          </IconButton>
        </>
      )}
      {stageGroupPercents.map(({ id, stage: { name } }: any) => (
        <div className={style.field}>
          <p>{name}</p>
          <TextField
            className={style.textField}
            {...textFieldSX}
            {...register(`${id}`)}
            error={Boolean(errors?.[id] || sumError)}
            helperText={errors?.[id]?.message}
          />
        </div>
      ))}
      {sumError && (
        <p className={style.error}>Сумма значений не должна превышать 100%</p>
      )}
    </form>
  );
};

export default StagesPercents;
