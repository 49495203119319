import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./TextLink.module.scss";

export type TextLinkProps = {
  children: string | React.ReactNode;
  className?: string;
  download?: boolean;
  redirect?: boolean;
  target?: React.AnchorHTMLAttributes<unknown>["target"];
  title?: string;
  url: string;
  variant?: "white" | "title" | "oceanic" | "";
};

export const TextLink = ({
  children,
  className,
  download,
  redirect = false,
  target = "_self",
  title,
  url,
  variant = "",
}: TextLinkProps) =>
  redirect ? (
    <a
      target={target}
      title={title}
      className={classNames(styles.link, className, styles[variant])}
      href={url}
      download={download}
    >
      {children}
    </a>
  ) : (
    <NavLink
      title={title}
      className={classNames(styles.link, className, styles[variant])}
      to={url}
    >
      {children}
    </NavLink>
  );
