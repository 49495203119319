import React, { memo, useState } from "react";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import cls from "./ModalDelegate.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessDelegate } from "../../../../../../app/feature/ProjectProcessView/Work/services/fetchProjectProcessDelegate";
import { fetchProcessWorkDuplicate } from "../../../../../../app/feature/ProjectProcessView/Work/services/fetchProcessWork";

interface ModalDelegateProps {
  delegate: boolean;
  userId: number;
  closeModal: () => void;
}
export const ModalDelegate = memo(
  ({ delegate, userId, closeModal }: ModalDelegateProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const [checkedDelegate, setCheckedDelegate] = useState(delegate);

    const onSubmit = async () => {
      const data = {
        delegate: checkedDelegate,
      };
      if (processId) {
        await dispatch(
          fetchProjectProcessDelegate({ processId, userId, data })
        );
        dispatch(fetchProcessWorkDuplicate(processId));
      }
      closeModal();
    };

    return (
      <form className={cls.form} onSubmit={onSubmit}>
        <label className={cls.form_label}>
          <CustomCheckbox
            onChange={() => setCheckedDelegate(!checkedDelegate)}
            checked={checkedDelegate}
          />
          Делегирование прав на исполнение раздела
        </label>
        <CustomButton
          width={140}
          background={palette.green}
          type="submit"
          className={cls.form_submit}
        >
          Сохранить
        </CustomButton>
      </form>
    );
  }
);
