import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { DzenSVG, VkontakteIMG, YoutubeIMG } from "../../../newUI/SVG";

export type SocialProps = ComponentPropsType<{
  size: string | "your PX size";
}>;

const socialIcons = [
  {
    name: "vkontakte",
    link: "https://vk.com/pir.tools",
    svg: <VkontakteIMG />,
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCWELisiQhHRQJ9-zYkWmj0A",
    svg: <YoutubeIMG />,
  },
  {
    name: "dzen",
    link: "https://zen.yandex.ru/id/5e25859e3642b600ad8cf08c",
    svg: <DzenSVG />,
  },
];

export const Social: React.FC<SocialProps> = ({
  theme = "dark",
  variant = "",
}) => {
  return (
    <ul className={classNames(variant, style.socialList)}>
      {socialIcons.map((item, i) => (
        <li className={style.socialItem} key={i}>
          <a
            className={classNames(style.socialLink, style[theme])}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.svg}
          </a>
        </li>
      ))}
    </ul>
  );
};
