import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import styles from "./Modal.module.scss";
import { CheckBox } from "./CheckBox";

interface DelegateModalProps {
  gipHelpers?: {
    name: string;
    id: string;
    fullName: string;
    delegate: boolean;
    accessId: number;
  }[];

  gipRemove?: boolean;
}
export const DelegateModal = memo(
  ({ gipHelpers, gipRemove }: DelegateModalProps) => {
    const { isLoadGipHelperDelete, isLoadGipHelperDelegate } =
      useSelector(getProjectViewData);

    // Удаление перенесли в таб админ, оставлю на случай если передумают
    // const deleteClickHandler = async (id: string) => {
    //   const result = window.confirm(
    //     "Вы уверены, что хотите снять помощника ГИП?"
    //   );
    //   if (result && params.id) {
    //     const deleteGip = {
    //       projectId: params.id,
    //       userId: id,
    //       active: true,
    //     };
    //     await dispatch(fetchGipHelperDelete({ deleteGip }));
    //     if (params.id) {
    //       await dispatch(fetchProjectViewSearchGip(params.id));
    //     }
    //   }
    // };

    return (
      <div className={styles.delegate}>
        {(isLoadGipHelperDelete || isLoadGipHelperDelegate) && (
          <div className={styles.delegate_loader}>
            <CircularIndeterminate />
          </div>
        )}
        {gipHelpers?.slice(0, 3).map((el) => (
          <div key={el.id} className={styles.delegate_block}>
            <div>
              <h4>{el.fullName}</h4>
              <CheckBox checked={el.delegate} accessId={el.accessId} />
            </div>
            {/* {gipRemove && ( */}
            {/*  <IconButton */}
            {/*    onClick={() => { */}
            {/*      deleteClickHandler(el.id); */}
            {/*    }} */}
            {/*    title="Снять помощника" */}
            {/*    sx={{ ...sx, ...newIconBtnSx }} */}
            {/*  > */}
            {/*    <DeleteButtonIcon width="20" height="20" /> */}
            {/*  </IconButton> */}
            {/* )} */}
          </div>
        ))}
      </div>
    );
  }
);
