import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList/FilesList";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { SelectRemarkReason } from "src/FSD/features/v2/SelectRemarkReason";
import { useCheckExpertiseRemarkRowComplete } from "../lib/hooks";

type Props = {
  id: number;
  header: string;
};

export const CheckExpertiseRemarkRowCompleteForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    status,
    setValue,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useCheckExpertiseRemarkRowComplete({
    id,
    defaultValues: {
      comment: "",
      status: null,
    },
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            value={taskData!.parent!.remarkRow!.text}
            label="Замечание"
            multiline
            disabled
          />
          <FilesList
            fileLinks={taskData!.parent!.remarkRow!.remark!.files ?? []}
            label="Файлы замечания"
          />
          <SelectRemarkReason
            value={taskData!.parent!.workflow_data!.reason} // передается строка
            disabled
          />
          <TextField
            value={taskData!.parent!.comment}
            label="Ответ на замечание"
            multiline
            disabled
          />
          <TextField
            value={taskData!.parent?.workflow_data?.changes}
            label="Документация, в которую внесены изменения"
            multiline
            disabled
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting && status === Statuses.REJECT}
              type="submit"
              color="error"
              variant="outlined"
              width={120}
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
            >
              Отклонить
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting && status === Statuses.ACCEPT}
              type="submit"
              width={120}
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
