import React from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { setOpenNode } from "../../../../../../app/feature/ProjectView/milestone/slice/projectMilestoneSlice";
import { getProjectMilestoneOpenNode } from "../../../../../../app/feature/ProjectView/milestone/selectors/milestoneSelectors";
import styles from "./MilestoneTree.module.scss";
import { Accordion } from "../../../../../newUI/Accordion/Accordion";
import { selectMargin } from "../../Sections/SectionsTree/Tree";
import { NewMilestoneCard } from "../../../../../newUI/NewMilestoneCard/NewMilestoneCard";
import { selectLvlClass } from "../../../../../../utils/helpersOpenNode";

export const MilestoneTree = ({ tree }: any) => {
  return (
    <div className={styles.container}>
      {tree?.map((node: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode key={index} node={node} index={index} />
      ))}
    </div>
  );
};

const TreeNode = ({ node, index }: any) => {
  const dispatch = useAppDispatch();
  const hasChild = !!node?.data;
  const open = useAppSelector(getProjectMilestoneOpenNode);
  const marginAccordion = selectMargin(node.lvl);
  const lvlClass = selectLvlClass(node?.lvl);
  const firstChild = index === 0 && node?.lvl === 1;

  return (
    <>
      {node?.name && (
        <Accordion
          text={node?.name}
          toogle={open[node?.num]}
          onToggle={() => dispatch(setOpenNode(node?.num))}
          numNode={node?.num}
          classname={styles.accordion}
          marginTop={firstChild ? "0px" : marginAccordion}
        />
      )}
      {!hasChild && (
        <div className={classNames(styles.mileStone, styles[lvlClass])}>
          {node?.availablePercent && (
            <div className={styles.mileStone_price}>
              <h3>{node.title}</h3>
              <div className={styles.mileStone_price_block}>
                <p>Доступно к выплате:</p>
                <span>{node.availablePercent} ₽</span>
              </div>
            </div>
          )}
          <NewMilestoneCard processName={node?.title} stages={node?.stages} />
        </div>
      )}
      {hasChild && open[node?.num] && node.data && (
        <div>
          <MilestoneTree tree={Object.values(node?.data)} />
        </div>
      )}
    </>
  );
};
