import React, { ChangeEvent } from "react";
import { TextField, FormControl } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { textFieldSX } from "src/styles/restyle";
import { setPriceObject } from "src/app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { useAppDispatch } from "src/app/store";
import cls from "../RemoveExecutor.module.scss";

interface Inputs {
  price: number;
}

interface LastFormCardProps {
  process: {
    id: number;
    checked: boolean;
    code: string;
    min: number;
    max: number;
    price: number;
  };
  name: string;
  checkedRefund: boolean;
  onChangeObjects: (event: any, name: string) => void;
}

export const LastFormCard = ({
  process,
  name,
  checkedRefund,
  onChangeObjects,
}: LastFormCardProps) => {
  const dispatch = useAppDispatch();

  const schema = Yup.object().shape({
    price: Yup.number()
      .min(process.min, `Оплата должна быть больше ${process.min}`)
      .max(process.max, `Оплата должна быть меньше ${process.max}`)
      .required(),
  });

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { price: process.price },
    mode: "all",
    resolver: yupResolver(schema),
  });

  function onChangePrice(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const newPrice = Number(event.target.value);
    dispatch(setPriceObject({ id: process.id, newPrice, name }));
    setValue("price", newPrice);
  }

  return (
    <>
      <label className={cls.labelAgree}>
        <Checkbox
          onChange={(event) => onChangeObjects(event, name)}
          checked={process.checked}
          value={process.id}
        />
        {process.code}
      </label>
      {!checkedRefund && (
        <>
          <div className={cls.flex}>
            <FormControl>
              <TextField
                {...register("price")}
                {...textFieldSX}
                value={process.price}
                onChange={(event) => onChangePrice(event)}
              />
            </FormControl>
            <div className={cls.formCard_nds}>без НДС</div>
          </div>

          {errors.price && (
            <>
              <div />
              <div>
                <p className={cls.error}>{errors.price.message}</p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
