import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import style from "./style.module.scss";
import { AddFile, GearFillSVG } from "../../../../newUI/SVG";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { ModalInitial } from "./ModalInitial";
import { CircularIndeterminate } from "../../../../UI/components/Project/Skeleton";
import { SourceData } from "./SourceData";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { getProjectInitialData } from "../../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import {
  deleteProjectChecklistById,
  fetchParentCheckList,
  fetchProjectSourceDataView,
  fetchProjectViewInitialData,
} from "../../../../../app/feature/ProjectView/InitialDataReducer/thunks";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { Dot } from "../../../../newUI/Dot/Dot";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import AddCheckListModal from "./AddCheckListModal";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectChecklist,
  setDisabled,
} from "../../../../../app/feature/project/projectChecklist";

type InitialCardProps = {
  name: string;
  parentId: number;
  index: number;
  all: number;
  inWork: number;
  dot: boolean;
};

export const messages = {
  CONFIRM_DELETE_SOURCE_ITEM:
    "Вы уверены, что хотите удалить исходные данные? Удалятся и прикрепленные файлы",
};

export const sxBtn = { color: "white", width: "32px", height: "32px" };

export const InitialDataCard: React.FC<InitialCardProps> = ({
  name,
  parentId,
  index,
  all,
  inWork,
  dot,
}) => {
  const { project } = useSelector(getProjectViewData);
  const [toggleDiv, setToggleDiv] = useState(false);

  const [modalInitial, setModalInitial] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);

  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(681);

  const canGearBtn = project?.can.view && !project?.parentId;
  const canAddFile = project?.can.editByGipHelper;

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const {
    projectSourceData,
    isLoadSourceData,
    checkListParent,
    isLoadCheckList,
    searchValue,
  } = useSelector(getProjectInitialData);

  const closeModalInitial = () => {
    setModalInitial(false);

    const { id } = params;
    if (id) {
      dispatch(
        fetchProjectSourceDataView({
          idx: { parentId, id: +id, index, searchValue, type },
        })
      );
    }
  };

  const closeModalAddFiles = () => {
    setAddFileModal(false);

    if (params.id) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          type,
        })
      );
    }
  };

  const handleClick = () => {
    setToggleDiv(!toggleDiv);
    if (!toggleDiv) {
      if (projectSourceData[index]) {
        return;
      }
      const { id } = params;
      if (id) {
        dispatch(
          fetchProjectSourceDataView({
            idx: { parentId, id: +id, index, searchValue, type },
          })
        );
      }
    }
  };

  const openAddFileModal = () => {
    setAddFileModal(true);
  };

  const updateDataClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setModalInitial(true);
    dispatch(setDisabled("update"));

    event.stopPropagation();
    const { id } = params;
    if (id) {
      await dispatch(
        fetchParentCheckList({
          idx: { parentId, index, id, searchValue, type },
        })
      );
    }

    dispatch(setDisabled(false));
  };

  const handleDeleteSourceDataName = async (sourceDataId: number) => {
    if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
      return;
    }

    dispatch(setDisabled("delete"));
    await dispatch(deleteProjectChecklistById(sourceDataId));

    const { id } = params;
    if (id) {
      await dispatch(
        fetchParentCheckList({
          idx: { parentId, index, id, searchValue, type },
        })
      );
    }
    dispatch(setDisabled(false));
  };

  const arrowIcon = <ToggleAccordion toggle={toggleDiv} />;

  return (
    <>
      <div
        className={classNames(style.card, toggleDiv && style.card_notMargin)}
        onClick={handleClick}
      >
        <div className={style.card_left}>
          {dot && <Dot status={"warning"} size={8} />}
          <p>{`${name} (${inWork}/${all})`}</p>
        </div>
        <div className={style.card_toggle}>{arrowIcon}</div>
        {isMobile && canGearBtn && toggleDiv && (
          <IconButton
            sx={sxBtn}
            onClick={updateDataClick}
            className={style.card_gearBtn}
          >
            <GearFillSVG width="22" height="22" />
          </IconButton>
        )}
      </div>
      {toggleDiv && (
        <>
          <ModalWrapper
            show={modalInitial}
            handleCloseModal={() => {
              setModalInitial(false);
            }}
            pending={isLoadCheckList}
          >
            {checkListParent[index] && !isLoadCheckList && (
              <ModalInitial
                data={checkListParent[index]}
                index={index}
                parentId={parentId}
                name={name}
                closeModal={closeModalInitial}
                handleDeleteSourceDataName={handleDeleteSourceDataName}
              />
            )}
          </ModalWrapper>
          <ModalWrapper
            show={addFileModal}
            handleCloseModal={() => {
              setAddFileModal(false);
            }}
          >
            <AddCheckListModal
              closeModal={closeModalAddFiles}
              name={name}
              parentId={parentId}
              projectId={Number(project?.id)}
            />
          </ModalWrapper>
          <div className={style.container}>
            {!isMobile && (
              <div className={style.parent}>
                <div>Статус</div>
                <div>Наименование</div>
                <div>Дата запроса</div>
                <div>Контр. срок</div>
                <div>Согласовано</div>
                <div className={style.parent_icon}>
                  {canAddFile && ( // переименовать
                    <IconButton sx={sxBtn} onClick={openAddFileModal}>
                      <AddFile />
                    </IconButton>
                  )}
                  {canGearBtn && (
                    <IconButton sx={sxBtn} onClick={updateDataClick}>
                      <GearFillSVG />
                    </IconButton>
                  )}
                </div>
                {projectSourceData[index] &&
                  projectSourceData[index].map((el) => (
                    <SourceData
                      key={el.id}
                      el={el}
                      parentId={parentId}
                      index={index}
                    />
                  ))}
              </div>
            )}
            {isMobile &&
              projectSourceData[index] &&
              projectSourceData[index].map((el) => (
                <SourceData
                  key={el.id}
                  el={el}
                  parentId={parentId}
                  index={index}
                  isMobile={isMobile}
                />
              ))}
            {!projectSourceData[index] && isLoadSourceData && (
              <CircularIndeterminate />
            )}
          </div>
        </>
      )}
    </>
  );
};
