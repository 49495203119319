import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProcessWorkResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const getDocumentations = (builder: QueryBuilder) =>
  builder.query<ApiProcessWorkResponses.GetDocumentations, TParams>({
    query: (params) => ({
      url: `v1/process-work/get-documentations`,
      params: {
        id: params.id,
      },
    }),
    providesTags: ["ProcessWork.GetDocumentations"],
  });
