import { TProjectProcessCheckList } from "../../../app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";

export const handleFilterChange = (
  type: string,
  checked: boolean,
  canExecutorEdit: boolean,
  value: string | number,
  allCheck: TProjectProcessCheckList[],
  setAllCheck: (value: any) => void
) => {
  console.log(type);
  if (type === "All") {
    const tempFilter = allCheck.map((filter) => {
      if (checked) {
        return { ...filter, author: true };
      }
      return {
        ...filter,
        author: false,
        executor: false,
        hidden: false,
        critical: false,
      };
    });
    setAllCheck(tempFilter);
  }
  if (type === "author") {
    const tempFilter = allCheck.map((el) =>
      +el.id === +value ? { ...el, author: checked } : el
    );
    setAllCheck(tempFilter);
  }
  if (type === "executor" && canExecutorEdit) {
    const tempFilter = allCheck.map((el) =>
      +el.id === +value ? { ...el, executor: checked, author: true } : el
    );
    setAllCheck(tempFilter);
  }
  if (type === "critical") {
    const tempFilter = allCheck.map((el) =>
      +el.id === +value ? { ...el, critical: checked, author: true } : el
    );
    setAllCheck(tempFilter);
  }
  if (type === "hidden" && canExecutorEdit) {
    const tempFilter = allCheck.map((el) =>
      +el.id === +value ? { ...el, hidden: checked, author: true } : el
    );
    setAllCheck(tempFilter);
  }
};
