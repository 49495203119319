import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InitialDataCard } from "./InitialDataCard";
import style from "./style.module.scss";
import { SkeletonInitialData } from "../../../../UI/components/Project/Skeleton";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { Header } from "./Header/Header";
import { getProjectInitialData } from "../../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { fetchProjectViewInitialData } from "../../../../../app/feature/ProjectView/InitialDataReducer/thunks";
import { HorizontalTabsPanel } from "../../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { fetchStageList } from "../../../../../app/feature/project/projectChecklist/thunks";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectChecklist,
  setChecklistType,
} from "../../../../../app/feature/project/projectChecklist";

export const InitialData = () => {
  const { initialDataInterface, isLoad, searchValue } = useSelector(
    getProjectInitialData
  );

  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const { data, pending } = useAppSelector(getProjectChecklist);

  const [activeTab, setActiveTab] = useState<number | string>(1);

  const setActiveTabMemo = useCallback((tabId: number | string) => {
    setActiveTab(tabId);
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(
        fetchProjectViewInitialData({
          id: +params.id,
          searchValue,
          type: activeTab as number,
        })
      );
    }
  }, [activeTab, searchValue, dispatch, params.id]);

  useEffect(() => {
    dispatch(setChecklistType(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
      dispatch(fetchStageList(Number(params.id)));
    }
  }, [dispatch, params.id]);

  return (
    <div className={style.wrapper}>
      <HorizontalTabsPanel
        tabs={data.tabs}
        components={[]}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        className={style.tabs}
        skeleton={<></>}
      />
      {isLoad ? (
        <SkeletonInitialData />
      ) : (
        <>
          {!pending.tabs && <Header />}
          {initialDataInterface?.map((el, index) => (
            <InitialDataCard
              key={el.name}
              name={el.name}
              all={el.all}
              inWork={el.inWork}
              dot={el.dot}
              parentId={el.id}
              index={index}
            />
          ))}
        </>
      )}
    </div>
  );
};
