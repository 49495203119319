import { useState, Fragment } from "react";
import { IconButton } from "@mui/material";
import style from "./SourceDataView.module.scss";
import { DownloadArrow } from "../SVG";
import { iconBtnSx } from "../../../styles/restyle";
import { config } from "../../../app.cofig";
import { SourceDataIconType } from "./SourceDataIconType";
import { DateStartPopper } from "./DateStartPopper";
import { DateEndPopper } from "./DateEndPopper";
import { AddFilesButton } from "./AddFilesButton/AddFilesBtn";
import { ToggleAccordion } from "../ToggleAccordion/ToggleAccordion";
import Comment from "./Comment";

type SourceDataViewProps = {
  isMobile: boolean | undefined;
  canUpdateSourceData: boolean;
  canAddFile: boolean;
  onChangeDate: (data: any) => void;
  isLoadDateUpdate: boolean;
  index?: number;
  parentId: number;
  checklist: any;
  status: any;
  critical: boolean;
  can: any;
  id: number;
  date_complete: string;
  date_create: string;
  date_deadline: string;
  task_id: number;
  projectId: number;
  projectParentId?: number;
  type: "process" | "project";
};
export const SourceDataView = ({
  isMobile,
  checklist,
  status,
  critical,
  can,
  id,
  date_complete,
  date_create,
  date_deadline,
  canUpdateSourceData,
  canAddFile,
  onChangeDate,
  isLoadDateUpdate,
  task_id,
  parentId,
  projectId,
  index,
  projectParentId,
  type = "project",
}: SourceDataViewProps) => {
  const [isCommentOpen, setCommentOpen] = useState<boolean>(false);

  const canEditDate = canUpdateSourceData && status !== "success";
  const handleNavigate = (id: number) => {
    window.location.href = `${config.localDomain}/project-source-data/download-files?id=${id}`;
  };

  const downloadTaskBtn = can?.download && (
    <IconButton onClick={() => handleNavigate(id)} sx={iconBtnSx}>
      <DownloadArrow />
    </IconButton>
  );

  if (isMobile) {
    return (
      <div className={style.mobileCard}>
        <div className={style.mobileCard_title}>
          <div className={style.text}>{checklist.name}</div>
          <SourceDataIconType status={status} critical={critical} />
        </div>
        <div className={style.mobileCard_date}>
          <p>Дата запроса</p>
          <DateStartPopper
            date_create={date_create}
            date_deadline={date_deadline}
            canEditDate={canEditDate}
            onChangeDate={onChangeDate}
            isLoadDateUpdate={isLoadDateUpdate}
            isMobile={isMobile}
          />
        </div>
        <div className={style.mobileCard_date}>
          <p>Контр.срок</p>
          <DateEndPopper
            date_create={date_create}
            date_deadline={date_deadline}
            canEditDate={canEditDate}
            onChangeDate={onChangeDate}
            isLoadDateUpdate={isLoadDateUpdate}
            isMobile={isMobile}
          />
        </div>
        <div className={style.mobileCard_date}>
          <p>Согласовано</p>
          <div className={style.text}>{date_complete}</div>
        </div>
        <div className={style.mobileCard_btn}>
          <div className={style.icon}>
            {downloadTaskBtn}
            {canAddFile && !projectParentId && (
              <AddFilesButton
                parentId={parentId}
                projectId={projectId}
                index={index}
                task_id={task_id}
                type={type}
              />
            )}
            <IconButton
              className={style.toggleCommentsList}
              onClick={() => setCommentOpen(!isCommentOpen)}
            >
              <ToggleAccordion toggle={isCommentOpen} />
            </IconButton>
          </div>
        </div>
        {isCommentOpen && (
          <Comment can={can} sourceDataId={id} name={checklist.name} />
        )}
      </div>
    );
  }

  return (
    <Fragment key={checklist.name}>
      <SourceDataIconType status={status} critical={critical} />
      <div className={style.text}>{checklist.name}</div>
      <DateStartPopper
        date_create={date_create}
        date_deadline={date_deadline}
        canEditDate={canEditDate}
        onChangeDate={onChangeDate}
        isLoadDateUpdate={isLoadDateUpdate}
        isMobile={isMobile || false}
      />
      <DateEndPopper
        date_create={date_create}
        date_deadline={date_deadline}
        canEditDate={canEditDate}
        onChangeDate={onChangeDate}
        isLoadDateUpdate={isLoadDateUpdate}
        isMobile={isMobile || false}
      />
      <div className={style.text}>{date_complete}</div>
      <div className={style.icon}>
        {downloadTaskBtn}
        {canAddFile && !projectParentId && (
          <AddFilesButton
            parentId={parentId}
            projectId={projectId}
            index={index}
            task_id={task_id}
            type={type}
          />
        )}
        {can?.addComment && (
          <IconButton
            className={style.toggleCommentsList}
            onClick={() => setCommentOpen(!isCommentOpen)}
          >
            <ToggleAccordion toggle={isCommentOpen} />
          </IconButton>
        )}
      </div>
      {isCommentOpen && (
        <Comment can={can} sourceDataId={id} name={checklist.name} />
      )}
    </Fragment>
  );
};
