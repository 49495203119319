export const isDevelopment = (): boolean => {
  if (!process.env.NODE_ENV) return true;
  return process.env.NODE_ENV === "development";
};

export const config = {
  localDomain: isDevelopment() ? "https://dev.tizh.ru" : "",
  localSocketPath: `wss://${
    isDevelopment()
      ? "test.tizh.ru" // todo -> поменять после разработки
      : `${window.location.hostname}:${window.location.port}`
  }`,
  testRootPath: "/site/front",
};
