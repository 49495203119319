import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

const expands = `designType,
  processPublishedCost,
  projectProcesses,
  gipAccess.userType`;

export const ApiGetMainPageProjects = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/index?per-page=6&expand=${expands}`
  );
