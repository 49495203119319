import React, { FC, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { palette } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getStaff,
  getUsersByTeamId,
  removeTeammate,
  sendInvitation,
} from "../../../../../app/feature/dashboard/staff/thunks";
import {
  clearTeams,
  getDashboardStaff,
  getUsersForDelete,
} from "../../../../../app/feature/dashboard/staff";
import style from "./style.module.scss";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";
import { getUserId } from "../../../../../app/services/auth/auth";
import { getTeamsList } from "./utils";
import {
  getDashboardHeader,
  TUserType,
} from "../../../../../app/feature/dashboard/header";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";

export type TValues = {
  team: TSelectItem | null;
  user: TSelectItem | null;
  title?: string;
  firmId?: number | null;
};

const defaultValues: TValues = {
  team: null,
  user: null,
};

type TGroupToggle = {
  action: "add" | "remove";
  handleCloseModal: () => void;
};

const MESSAGES = {
  add: "Добавить в группу",
  remove: "Удалить из группы",
};

const GroupToggle: FC<TGroupToggle> = ({ action, handleCloseModal }) => {
  const dispatch = useAppDispatch();
  const { data, pending } = useAppSelector(getDashboardStaff);
  const { data: headerData } = useAppSelector(getDashboardHeader);
  const { userType } = useAppSelector(getDashboardDestinations);

  const firmId =
    headerData.find((item: any) => item.id === userType)?.firmId ?? null;

  const userId = getUserId();
  const teamsList = useMemo(
    () => getTeamsList(data.staff, userId),
    [data.staff, userId]
  );

  const { watch, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const team = watch("team");
  const user = watch("user");

  useEffect(() => {
    if (!team) {
      return;
    }

    if (action === "add") {
      dispatch(getUsersByTeamId(team.id as number));
    }
    if (action === "remove") {
      dispatch(
        getUsersForDelete({ staff: data.staff, teamsList, teamId: team.id })
      );
    }

    return () => {
      dispatch(clearTeams());
    };
  }, [team]);

  useEffect(() => {
    setValue("user", data.teams.length ? data.teams[0] : null);
  }, [data.teams]);

  useEffect(() => {
    if (teamsList.length) {
      setValue("team", teamsList[0]);
    }
  }, [teamsList]);

  const onSubmit = async (data: TValues) => {
    if (!team || !user) {
      return;
    }

    if (action === "add") {
      dispatch(sendInvitation({ ...data, title: team.title, firmId }));
    }
    if (action === "remove") {
      dispatch(removeTeammate({ ...data }));
    }

    const currentFirmId = headerData.find(
      (type: TUserType) => type.id === userType
    )?.firmId;

    dispatch(getStaff(currentFirmId));

    handleCloseModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.addToGroup}>
      <h2>{MESSAGES[action]}</h2>
      <Select
        value={team}
        name="team"
        changeHandler={(_, value) => {
          setValue("team", value);
        }}
        label={"Специализация"}
        options={teamsList}
        disableClear
      />
      <Select
        value={user}
        name="user"
        changeHandler={(_, value) => {
          setValue("user", value);
        }}
        label={"Сотрудник"}
        options={data.teams}
        disabled={pending.teams}
        disableClear
      />
      {action === "add" && (
        <CustomButton
          type="submit"
          width={135}
          className={style.button}
          background={palette.blue}
          disabled={pending.teams || pending.acceptTeammate}
        >
          Добавить
        </CustomButton>
      )}
      {action === "remove" && (
        <CustomButton
          type="submit"
          width={135}
          className={style.button}
          background={palette.red}
          disabled={pending.teams}
        >
          Удалить
        </CustomButton>
      )}
    </form>
  );
};

export default GroupToggle;
