import { PublishObjectModel } from "src/FSD/entities/publishObject";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalsRemoveExecution = () => {
  const dispatch = useAppDispatch();

  const { removeRequestExecution } = useAppSelector(
    PublishObjectModel.modals.getModalsData
  );

  const handleOpenRemoveExecutionRequest = (taskId: number, header: string) => {
    dispatch(
      PublishObjectModel.modals.setRemoveRequestExecutionActive({
        isOpen: true,
        taskId,
        header,
      })
    );
  };

  const handleCloseRemoveExecutionRequest = () => {
    dispatch(
      PublishObjectModel.modals.setRemoveRequestExecutionActive({
        isOpen: false,
        taskId: null,
        header: "",
      })
    );
  };

  return {
    removeRequestExecution,
    handleOpenRemoveExecutionRequest,
    handleCloseRemoveExecutionRequest,
  } as const;
};
