import React from "react";
import { useTitle } from "../../hooks/useTitle";
import style from "../Registration/EmailConfirmPage/style.module.scss";
import { Wrapper } from "../../UI/templates";
import { ResetPasswordForm } from "../../features/Forms/ResetPasswordForm/ResetPasswordForm";

export const ResetPasswordPage = () => {
  useTitle("Сброс пароля");

  return (
    <div className={style.pageContainer}>
      <Wrapper>
        <div className={style.titleContainer}>
          <h1 className={style.pageTitle}>Сброс пароля</h1>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <ResetPasswordForm />
        </div>
      </Wrapper>
    </div>
  );
};
