import { TFirmMilestonesList, TStage } from ".";
import { TSelectItem } from "../../../components/features/Forms/SetGipForm/types";

export const createList = (list: Record<string, string>) =>
  Object.entries(list)
    .filter((item) => item[0])
    .map(([id, title]) => ({ id: Number(id), title }))
    .sort((a, b) => a.id - b.id);

export const createMilestones = (list: any[]) => {
  const result: TFirmMilestonesList = {};

  list.forEach((milestone) => {
    const {
      id,
      building_function_type,
      project_part_id: partId,
      projectPart: { name },
      stages,
      active,
    } = milestone;

    const milestoneFormed = {
      id,
      partId,
      active,
      name,
      stages: stages.sort((a: TStage, b: TStage) => a.num - b.num),
    };

    if (result[building_function_type]) {
      result[building_function_type].push(milestoneFormed);
      return;
    }

    result[building_function_type] = [milestoneFormed];
  });

  return result;
};

export const createOpenGroupIds = (payload: any) =>
  payload.map((item: any) => item.group.id);

export const prepareAnchorData = (id: number, data: any) => {
  const params = Object.entries(data).reduce(
    (result: any, [field, value]: any) => ({
      ...result,
      [field]: String(value.id),
    }),
    {}
  );

  delete params.class;
  delete params.method;

  return {
    name: data.name,
    stageId: id,
    class: data.class.id,
    description: data.description,
    method: data.method.id,
    params,
  };
};

export const prepareListWithHints = (payload: any) => {
  return Object.entries(payload).reduce(
    (result: TSelectItem[], [id, item]: any) => {
      return [...result, { id, ...item }];
    },
    []
  );
};
