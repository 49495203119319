import React, { useState } from "react";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";
import { useTitle } from "../../../hooks/useTitle";

import DefaultPhoto from "../../../../media/images/news_plug_main.png";
import { PostCreateForm } from "../../../features/Forms/PostCreateForm/PostCreateForm";

export const Create = () => {
  useTitle("Добавление");
  const [image, setImage] = useState("");

  return (
    <div className={style.postCreatePage}>
      <Wrapper>
        <div className={style.postContainer}>
          <h1 className={style.title}>Добавление</h1>
          <div className={style.postImageContainer}>
            <figure className={style.postImage}>
              <img
                className={style.postPhoto}
                src={image || DefaultPhoto}
                alt="пустая картинка"
              />
            </figure>
          </div>
          <div className={style.formContainer}>
            <PostCreateForm
              imageHandler={setImage}
              variant={style.postCreateForm}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
