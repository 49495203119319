import {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
  getModalsData,
} from "./modalsSlice";

export const modals = {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
  getModalsData,
};

export { publishObjectSlice as sliceReducer } from "./slice";
