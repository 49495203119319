import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import cls from "./RemoveExecutors.module.scss";
import Modal from "../../../newUI/Modal/Modal";
import { ModalFeedbackRemoveExecutor } from "./ModalFeedbackRemoveExecutor/ModalFeedbackRemoveExecutor";
import { FeedbackStarIcon } from "../../../newUI/SVG";
import Avatar from "../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { initialStateExecutors } from "../../../../app/feature/ProjectProcessView/RemoveExecutors/types/projectProcessRemoveExecutorsType";
import { useAppSelector } from "../../../../app/store";
import { getFeedbackExecutorIsLoad } from "../../../../app/feature/ProjectProcessView/Info/selectors/getFeedbackExecutorIsLoad";

interface RemoveExecutorCardProps {
  executor: initialStateExecutors;
}
export const RemoveExecutorCard = memo(
  ({ executor }: RemoveExecutorCardProps) => {
    const [modalFeedback, setModalFeedback] = useState(false);
    const isLoadRemoveExecutorFeedback = useAppSelector(
      getFeedbackExecutorIsLoad
    );

    const modalFeedbackClose = useCallback(() => {
      setModalFeedback(false);
    }, []);

    return (
      <div key={executor.id} className={cls.card}>
        {executor.can && (
          <>
            <Modal
              active={modalFeedback}
              setActive={setModalFeedback}
              isLoad={isLoadRemoveExecutorFeedback}
            >
              <ModalFeedbackRemoveExecutor
                executor={executor}
                modalClose={modalFeedbackClose}
              />
            </Modal>
            <IconButton
              className={cls.card_btn}
              onClick={() => setModalFeedback(true)}
            >
              <FeedbackStarIcon />
            </IconButton>
          </>
        )}
        <div>
          <Avatar data={[executor]} projectView bottomRating={50} />
        </div>
        <div className={cls.treaty}>
          <p>Сроки выполнения</p>
          <p>{`${executor.date_create} - ${executor.date_complete}`}</p>
        </div>
      </div>
    );
  }
);
