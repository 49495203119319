import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  clientLogoutClearAllData,
  getUserTokenByLocalStorage,
  parseTokenBody,
  removeToken,
  setTokenByProd,
} from "../../app/services/auth/auth";
import { getCookie } from "../../app/services/app/storage/cookies";

import { isDevelopment } from "../../app/services/app/app";
import { setAttemptedToken } from "../../app/feature/app/app";
import { setUserAgreement } from "../../app/feature/appUser/appUser";

export const useAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDevelopment()) return;
    setTokenByProd(() => dispatch(setAttemptedToken()));
  }, []);

  useEffect(() => {
    if (!isDevelopment()) {
      if (!getCookie("access_token") || getCookie("access_token") === "") {
        removeToken();
      }
    }
  });

  // проверка на стухший токен
  // также тут записываю информацию, в redux
  // в appUser { agreement_personal }
  // что пользователь принял agreement_personal

  useEffect(() => {
    const checkedWitheredToken = () => {
      const token = getUserTokenByLocalStorage();
      if (token && parseTokenBody(token).data.agreement_personal) {
        const agreementPersonal = parseTokenBody(token).data.agreement_personal;
        dispatch(setUserAgreement({ agreement_personal: agreementPersonal }));
      }
      if (!token || parseTokenBody(token).exp === 0) return;
      setTimeout(() => {
        const tokenDate = moment
          .unix(Number(parseTokenBody(token).exp))
          .toDate();
        if (tokenDate < new Date()) {
          clientLogoutClearAllData();
          window.location.pathname = "site/login";
        } else {
          checkedWitheredToken();
        }
      }, 10000);
    };
    checkedWitheredToken();
  }, []);
};
