import React, { memo, useEffect, useState } from "react";
import parse from "html-react-parser";
import { IconButton } from "@mui/material";
import moment from "moment/moment";
import { ITask } from "src/app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchBackToWorkDocumentations } from "src/app/feature/ProjectProcessView/Documentations/services/fetchBackToWorkDocumentations";
import { fetchProjectProcessDocumentations } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getProjectProcessCheckAlignIsLoad,
  getProjectProcessDepositFundsIsLoad,
  getProjectProcessDocumentsCompleteCheck,
  getProjectProcessTaskCompleteIsLoad,
} from "src/app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { iconBtnSx } from "src/styles/restyle";
import { setCheckInput } from "src/app/feature/ProjectView/projectView";
import { StatusCardWrapper } from "../../../../../newUI/StatusCardWrapper/StatusCardWrapper";
import cls from "./ProcessTaskCard.module.scss";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { AddFile, CheckSVG, NotApproveIcon } from "../../../../../newUI/SVG";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalComplete } from "./ModalComplete/ModalComplete";
import { ModalCheckProcessTask } from "./ModalCheckProcessTask/ModalCheckProcessTask";
import { Dot } from "../../../../../newUI/Dot/Dot";
import useSubRoutes from "../../../../../hooks/useSubRoutes";

export const ProcessTaskCard = memo(
  ({
    status,
    executorName,
    comment,
    files,
    title,
    header,
    can,
    checks,
    task,
    children,
    setModalData,
  }: ITask) => {
    const dispatch = useAppDispatch();
    const infoId = useAppSelector(getProjectProcessInfoId);
    const isLoadCheck = useAppSelector(getProjectProcessCheckAlignIsLoad);
    const isLoadDepositFunds = useAppSelector(
      getProjectProcessDepositFundsIsLoad
    );
    const isLoadTaskComplete = useAppSelector(
      getProjectProcessTaskCompleteIsLoad
    );

    const completeCheck = useAppSelector(
      getProjectProcessDocumentsCompleteCheck
    );

    const [toggleComment, setToggleComment] = useState(false);
    const [toggleFiles, setToggleFiles] = useState(false);
    const [toggleChecks, setToggleChecks] = useState(false);
    const [toggleSubTasks, setToggleSubTasks] = useState(false);

    const [modalComplete, setModalComplete] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const viewStatus =
      !toggleFiles && !toggleComment && !toggleChecks && !toggleSubTasks;
    const viewComment =
      !toggleFiles && !toggleChecks && toggleComment && !toggleSubTasks;
    const viewFiles =
      !toggleComment && !toggleChecks && toggleFiles && !toggleSubTasks;
    const viewChecks =
      !toggleComment && !toggleFiles && toggleChecks && !toggleSubTasks;
    const viewSubTasks =
      !toggleComment && !toggleFiles && !toggleChecks && toggleSubTasks;

    const dateDeadLine = task?.date_deadline
      ? moment(task?.date_deadline, "DD.MM.YYYY HH:mm:ss Z").format(
          "DD.MM.YYYY"
        )
      : undefined;

    const dateComplete = task?.date_complete
      ? moment(task.date_complete, "DD.MM.YYYY HH:mm:ss Z").format("DD.MM.YYYY")
      : undefined;

    useEffect(() => {
      if (completeCheck) {
        setModalComplete(false);
      }
    }, [completeCheck]);

    function toggleCommentClick() {
      if (setModalData) {
        setModalData({
          id: task.id,
          name: header,
          open: true,
        });
      }
    }
    function toggleFilesClick() {
      setToggleComment(false);
      setToggleChecks(false);
      setToggleSubTasks(false);
      setToggleFiles(!toggleFiles);
    }
    function toggleChecksClick() {
      setToggleFiles(false);
      setToggleComment(false);
      setToggleSubTasks(false);
      setToggleChecks(!toggleChecks);
    }
    function toggleSubTasksClick() {
      setToggleFiles(false);
      setToggleComment(false);
      setToggleChecks(false);
      setToggleSubTasks(!toggleSubTasks);
    }
    async function backToWorkHandler() {
      const confirmResult = window.confirm(
        "Вы уверены, что хотите вернуть задачу?"
      );
      if (confirmResult && task?.id && infoId) {
        await dispatch(fetchBackToWorkDocumentations(task.id));
        await dispatch(fetchProjectProcessDocumentations(infoId));
      }
    }

    function checkHandler() {
      setModalCheck(true);
    }

    function onModalComplete() {
      setModalComplete(true);
      dispatch(setCheckInput(true));
    }

    useSubRoutes(task.id, toggleSubTasksClick, 0);

    return (
      <div>
        <StatusCardWrapper
          status={status}
          date={dateComplete ?? dateDeadLine}
          files={files}
          comment={comment}
          color={status.color}
          viewStatus={viewStatus}
          viewComment={viewComment}
          viewFiles={viewFiles}
          setModalData={setModalData}
          viewChecks={viewChecks}
          viewSubTasks={viewSubTasks}
          checks={Object.values(checks)}
          subTasks={children}
          classname={cls.card}
          downloadAllLink={
            children.length > 0 || files.length > 0
              ? `/task/download-files?id=${task.id}`
              : undefined
          }
        >
          <div className={cls.content}>
            <div className={cls.content_header}>
              <h1 title={title}>{header}</h1>
              <p>{executorName}</p>
              {dateComplete && (
                <div className={cls.date}>
                  <p>Выдано</p>
                  <span>{dateComplete}</span>
                </div>
              )}
            </div>
            <div className={cls.toggleList}>
              <div className={cls.toggle} onClick={toggleCommentClick}>
                <p>Комментарии</p>
                <ToggleAccordion toggle={toggleComment} />
              </div>
              {files && (
                <div className={cls.toggle} onClick={toggleFilesClick}>
                  <p>Вложения</p>
                  <ToggleAccordion toggle={toggleFiles} />
                </div>
              )}
              {checks && (
                <div className={cls.toggle} onClick={toggleChecksClick}>
                  <p className={cls.withDot}>Проверки</p>
                  <ToggleAccordion toggle={toggleChecks} />
                </div>
              )}
              {children && (
                <div className={cls.toggle} onClick={toggleSubTasksClick}>
                  {children.dot && (
                    <p className={cls.dot}>
                      <Dot status="warning" />
                    </p>
                  )}
                  <p className={cls.withDot}>Подзадачи</p>
                  <ToggleAccordion toggle={toggleSubTasks} />
                </div>
              )}
            </div>
          </div>
          <div className={cls.btn}>
            {can?.check && (
              <Modal
                active={modalCheck}
                setActive={setModalCheck}
                isLoad={isLoadCheck || isLoadDepositFunds}
              >
                <ModalCheckProcessTask comment={comment} files={files} />
              </Modal>
            )}
            {can?.complete && (
              <Modal
                active={modalComplete}
                setActive={setModalComplete}
                isLoad={isLoadTaskComplete}
              >
                <ModalComplete taskId={task?.id} />
              </Modal>
            )}
            {can?.check && (
              <IconButton
                sx={iconBtnSx}
                title="Проверить"
                onClick={checkHandler}
              >
                <CheckSVG width="24" height="24" />
              </IconButton>
            )}
            {can?.complete && (
              <IconButton
                sx={iconBtnSx}
                title="Завершить раздел"
                onClick={onModalComplete}
              >
                <AddFile width="24" height="24" />
              </IconButton>
            )}
            {can?.backToWork && (
              <IconButton
                sx={iconBtnSx}
                title="Вернуть"
                onClick={backToWorkHandler}
              >
                <NotApproveIcon width="24" heigth="24" />
              </IconButton>
            )}
            {can?.bimitUser && (
              <div className={cls.btn_cloud}>
                {parse(task?.data?.cloudLink)}
              </div>
            )}
            {!can?.bimitUser && (
              <div className={cls.btn_cloud}>
                {parse(task?.data?.cloudLink)}
              </div>
            )}
          </div>
        </StatusCardWrapper>
      </div>
    );
  }
);
