import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { CloseOutlined } from "@mui/icons-material";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export type ModalType = ComponentPropsType<{
  head?: string;
  body: JSX.Element;
  bodyVariant?: string;
  bottom?: JSX.Element;
  closeHandler?: VoidFunction;
  mode?: "" | "alert";
  size?: "standard" | "small";
  animationShowRender?: boolean;
}>;

export const Modal: React.FC<ModalType> = ({
  head,
  body,
  bodyVariant = "",
  bottom,
  closeHandler = () => {},
  mode = "",
  size = "standard",
  animationShowRender = true,
}) => {
  const theme = useSelector(selectorAppTheme);
  const refBody = useRef(null);

  const Close: React.FC<unknown> = () => {
    return (
      <div className={style.iconWrapper} onClick={closeHandler}>
        <CloseOutlined style={{ fontSize: 30 }} />
      </div>
    );
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useOnClickOutside(refBody, () => {
    closeHandler();
  });

  return (
    <div className={style.bg}>
      <div
        ref={refBody}
        className={classNames(
          style.popup,
          animationShowRender && style.showAnimate,
          style[theme],
          style[size],
          style[mode]
        )}
      >
        {head && mode === "" && (
          <div className={classNames(style.head, style[theme])}>
            <div className={classNames(style.headTitle, style[theme])}>
              {head}
            </div>
            <Close />
          </div>
        )}
        {body && (
          <div
            className={classNames(
              style.body,
              style[mode],
              style[theme],
              !bottom && style.notBottom,
              bodyVariant
            )}
          >
            {body}
            {mode === "alert" && <Close />}
          </div>
        )}
        {bottom && (
          <div className={classNames(style.bottom, style[mode], style[theme])}>
            {bottom}
          </div>
        )}
      </div>
    </div>
  );
};
