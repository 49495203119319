import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useCheckExecuteComplete } from "../lib/hooks";
import { messages } from "../../../../../../../utils/SimplifiedYup";

type Props = {
  id: number;
  header: string;
};

export const CheckExecuteCompleteForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    status,
    setValue,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useCheckExecuteComplete({
    id,
    defaultValues: {
      comment: "",
      status: null,
    },
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Комментарий исполнителя"
            disabled
            value={taskData!.taskWork!.task!.comment || messages.commentStub}
          />
          <FilesList
            label="Файлы решения"
            fileLinks={taskData!.taskWork!.task!.files}
          />
          <TextField
            {...register("comment")}
            label="Замечания"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
              color="error"
              variant="outlined"
              width={120}
              isLoading={isSubmitting && status === Statuses.REJECT}
            >
              Отказать
            </Button>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
              width={120}
              isLoading={isSubmitting && status === Statuses.ACCEPT}
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
