import * as Yup from "yup";

export const registrationFormSchema = Yup.object().shape({
  name: Yup.string()
    .required("Необходимо заполнить «Имя»")
    .max(25, "Значение «Имя» должно содержать максимум 25 символов"),
  phone: Yup.string().max(
    20,
    "Значение «Телефон» должно содержать максимум 20 символов"
  ),
  email: Yup.string()
    .required("Необходимо заполнить «Email»")
    .max(50, "Значение «Email» должно содержать максимум 50 символов"),
  type: Yup.string(),
  skillIds: Yup.array().when("type", {
    is: "1" || "6",
    then: Yup.array()
      .required("Необходимо выбрать хотя бы одну специализацию")
      .min(1, "Необходимо выбрать хотя бы одну специализацию")
      .max(5, "Можно выбрать не более пяти"),
  }),
  password: Yup.string()
    .required("Необходимо заполнить «Пароль»")
    .min(6, "Значение «Пароль» должно содержать минимум 6 символов")
    .max(50, "Значение «Пароль» должно содержать максимум 50 символов"),
  password_confirm: Yup.string()
    .required("Необходимо заполнить «Повторить пароль»")
    .when("password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref("password")], "Пароли не совпадают"),
    }),
  agreement_personal: Yup.mixed().test(
    "agreementPersonal",
    `Необходимо принять «Пользовательское соглашение» и
       «Политику конфиденциальности»`,
    (val) => val
  ),
});
