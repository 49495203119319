import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { TProjectProcessCheckList } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import {
  CheckListResult,
  fetchProjectViewInitialData,
  fetchProjectViewInitialDataUpdate,
} from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { useAppSelector } from "src/app/store";
import { SourceDataUpdateForm } from "src/FSD/shared/uiKit/v2/SourceDataUpdateForm";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";

type TProps = {
  title: string;
  isLoad: boolean;
  data: CheckListResult;
  parentId: number;
  projectId: number;
  closeModal: () => void;
  updateAddCheckList: () => void;
  handleDeleteSourceDataName: (id: number) => void;
};

export const InitialDataUpdateModal = ({
  title,
  isLoad,
  data,
  parentId,
  projectId,
  closeModal,
  updateAddCheckList,
  handleDeleteSourceDataName,
}: TProps) => {
  const { handleClose } = useModalContext();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { searchValue } = useSelector(getProjectInitialData);
  const { project } = useSelector(getProjectViewData);

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const formSubmitHandler = async (dataValue: TProjectProcessCheckList[]) => {
    const data = dataValue.map((el) => {
      return {
        id: el.id,
        select: el.author,
        executor: el.executor,
        critical: el.critical,
        hidden: el.hidden,
        name: el.name,
      };
    });
    const { id } = params;
    if (id) {
      dispatch(
        fetchProjectViewInitialDataUpdate({
          update: { id, data, parentId, type },
        })
      );
      dispatch(fetchProjectViewInitialData({ id: +id, searchValue, type }));
      handleClose();
    }
  };

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{title}</Modal.Header>
      {isLoad ? (
        <Modal.Preloader />
      ) : (
        <SourceDataUpdateForm
          formSubmitHandler={formSubmitHandler}
          checkList={data.result}
          closeModal={closeModal}
          canExecutorEdit={!!project?.can?.edit}
          handleDeleteSourceDataName={handleDeleteSourceDataName}
          parentId={parentId}
          projectId={projectId}
          updateAddCheckList={updateAddCheckList}
        />
      )}
    </Modal.Layout>
  );
};
