import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import styles from "./ProcessWorkView.module.scss";

type SkeletonPageProps = {
  isMobile: boolean;
  isTablet: boolean;
};

export const SkeletonPage = ({ isMobile, isTablet }: SkeletonPageProps) => (
  <div className={styles.skeleton}>
    {!isMobile && (
      <Skeleton.List
        height={995}
        width={isTablet ? "106px" : "445px"}
        count={1}
      />
    )}
    <Skeleton.Grid height={300} count={2} />
  </div>
);
