import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import cls from "./Modal.module.scss";
import CustomCheckbox from "../../../../../../../newUI/CustomCheckbox/CustomCheckbox";
import {
  fetchGipHelperDelegate,
  fetchProjectView,
} from "../../../../../../../../app/feature/ProjectView/thunks";

interface ICheckBoxProps {
  accessId: number;
  checked: boolean;
}
export const CheckBox: React.FC<ICheckBoxProps> = memo(
  ({ checked, accessId }) => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const [firstRender, setFirstRender] = useState(true);
    const [check, setCheck] = useState(false);
    const onChangeHandler = async () => {
      await setCheck(!check);
    };
    async function requestFirstRender() {
      await setCheck(checked);
      await setFirstRender(false);
    }
    async function requestGipHelperDelegate() {
      await dispatch(fetchGipHelperDelegate({ accessId, delegate: check }));
      if (params.id) {
        await dispatch(fetchProjectView(params.id));
      }
    }

    useEffect(() => {
      if (firstRender) {
        requestFirstRender();
      }
      if (!firstRender) {
        requestGipHelperDelegate();
      }
    }, [check]);

    return (
      <label className={cls.delegate_label}>
        <CustomCheckbox
          editing
          onChange={onChangeHandler}
          checked={check}
          classname={cls.delegate_label_checkBox}
        />
        Делегировать
      </label>
    );
  }
);
