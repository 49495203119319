import { memo } from "react";
import { Controller } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { SelectSpecialization } from "src/FSD/features/v2/SelectSpecialization";
import { useEditFreeTask } from "../lib/hooks";

export type Props = {
  taskId: number;
  onTaskUpdate: (newTask: unknown) => void;
  onTaskDelete: (freeTaskDeletion: {
    taskId: number;
    status: Statuses;
    partGroupId: number;
  }) => void;
};

export const EditFreeTaskForm = memo(
  ({ taskId, onTaskUpdate, onTaskDelete }: Props) => {
    const {
      control,
      handleSubmit,
      onSubmit,
      register,
      errors,
      selectOptions,
      taskData,
      isTaskViewPending,
      isSubmitting,
      isUpdating,
      isRemoving,
      handleDeleteFreeTask,
      setValue,
      taskPending,
      canEdit,
    } = useEditFreeTask({
      taskId,
      onTaskUpdate,
      onTaskDelete,
    });

    return (
      <Modal.Layout>
        <Modal.Header>Редактирование задачи</Modal.Header>
        {isTaskViewPending ? (
          <Modal.Preloader />
        ) : (
          <>
            <Modal.Form onSubmit={handleSubmit(onSubmit)}>
              <Select value={taskData!.project} label="Проект" disabled />
              <TextField
                {...register("content")}
                label="Текст задачи"
                size={"small"}
                multiline
                minRows={3}
                maxRows={12}
                error={Boolean(errors.content)}
                helperText={errors.content?.message}
                disabled={!canEdit}
              />
              <Controller
                name="partGroup"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectSpecialization
                    key={value?.id ?? null}
                    value={value}
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                      setValue("executor", null);
                    }}
                    error={Boolean(errors.partGroup)}
                    helperText={errors.partGroup?.message}
                    disabled={!canEdit}
                  />
                )}
              />
              <Controller
                name="executor"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    key={value?.id ?? null}
                    value={value}
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    isLoading={taskPending.getUsersList}
                    label="Исполнитель"
                    options={selectOptions.users}
                    error={Boolean(errors.executor)}
                    helperText={errors.executor?.message}
                    disabled={!canEdit}
                  />
                )}
              />
              <Controller
                name="dateDeadline"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateField
                    label="Контрольный срок"
                    changeDateHandler={(date) => {
                      onChange(date);
                    }}
                    startDateProp={value}
                    error={Boolean(errors.dateDeadline)}
                    helperText={errors.dateDeadline?.message}
                    disabled={!canEdit}
                  />
                )}
              />
              {canEdit && (
                <Modal.Controls>
                  <Button
                    onClick={handleDeleteFreeTask}
                    disabled={isSubmitting}
                    isLoading={isRemoving}
                    color="error"
                    variant="outlined"
                  >
                    Удалить
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isUpdating}
                  >
                    Сохранить
                  </Button>
                </Modal.Controls>
              )}
            </Modal.Form>
          </>
        )}
      </Modal.Layout>
    );
  }
);
