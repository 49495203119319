import React, { ChangeEventHandler, FC, useState } from "react";
import { UseFormRegister, useForm } from "react-hook-form";
import { FormControl, IconButton } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import classes from "./SourceDataUpdateForm.module.scss";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomButton from "../CustomButton/CustomButton";
import { palette } from "../../../styles/restyle";
import { TProjectProcessCheckList } from "../../../app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { handleFilterChange } from "./hanleFilterChange";
import { useAppSelector } from "../../../app/store";
import { getProjectChecklist } from "../../../app/feature/project/projectChecklist";

type TInputs = {
  author: number[];
  executor: number[];
  critical: number[];
  hidden: number[];
  names: Record<string, string>;
};

type SourceDataNameProps = {
  id: number;
  register: UseFormRegister<TInputs>;
  handleDeleteSourceDataName: (id: number) => void;
};

const SourceDataName: FC<SourceDataNameProps> = (props) => {
  const { id, register, handleDeleteSourceDataName } = props;

  const {
    data: { disabled },
  } = useAppSelector(getProjectChecklist);

  return (
    <div className={classes.sourceDataName}>
      <input
        {...register(`names.${id}`)}
        className={classes.sourceDataName__name}
      />
      <IconButton
        disabled={disabled === "delete"}
        onClick={() => handleDeleteSourceDataName(id)}
        className={classes.sourceDataName__deleteIcon}
      >
        <Trash color="white" />
      </IconButton>
    </div>
  );
};

function getAuthorIds(arr: any, type: string) {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][type]) {
      result.push(arr[i].id);
    }
  }
  return result;
}

function getAuthorNames(arr: any) {
  const result: Record<number, string> = {};
  for (let i = 0; i < arr.length; i++) {
    const { id, name, delete: canDelete } = arr[i];

    if (canDelete) {
      result[id] = name;
    }
  }
  return result;
}

interface SourceDataUpdateFormProps {
  formSubmitHandler: (data: TProjectProcessCheckList[]) => void;
  checkList: TProjectProcessCheckList[];
  name: string;
  closeModal: () => void;
  canExecutorEdit: boolean;
  handleDeleteSourceDataName: (id: number) => void;
}

// Todo перенесен сюда src/FSD/widgets/v2/projectProcess/modals/ModalGear
export const SourceDataUpdateForm = ({
  formSubmitHandler,
  checkList,
  name,
  closeModal,
  canExecutorEdit,
  handleDeleteSourceDataName,
}: SourceDataUpdateFormProps) => {
  const [allCheck, setAllCheck] = useState(checkList);

  const { handleSubmit, register } = useForm<TInputs>({
    defaultValues: {
      author: getAuthorIds(allCheck, "author"),
      critical: getAuthorIds(allCheck, "critical"),
      executor: getAuthorIds(allCheck, "executor"),
      hidden: getAuthorIds(allCheck, "hidden"),
      names: getAuthorNames(allCheck),
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked, value } = event.target;

    handleFilterChange(
      name,
      checked,
      canExecutorEdit,
      value,
      allCheck,
      setAllCheck
    );
  };

  const {
    data: { disabled },
  } = useAppSelector(getProjectChecklist);

  const formSubmitCallback = (data: TInputs) => {
    const { names } = data;
    const updatedFormData = allCheck.map((check: any) =>
      names[check.id] ? { ...check, name: names[check.id] } : check
    );

    formSubmitHandler(updatedFormData);
  };

  return (
    <div className={classes.updateFileModal}>
      <h1 className={classes.name}>{name}</h1>
      <form onSubmit={handleSubmit(formSubmitCallback)}>
        <div className={classes.modalParent}>
          <CustomCheckbox
            onChange={handleChange}
            checked={
              allCheck.filter((item) => item?.author !== true).length < 1
            }
            editing
            name="All"
          />
          <div className={classes.modalParent_name}>Заказчик</div>
          <div className={classes.modalParent_name}>Критичные</div>
          <div className={classes.modalParent_name}>Скрытые</div>
          <div className={classes.modalParent_name}>Наименование</div>
          {allCheck.map((el) => (
            <React.Fragment key={el.id}>
              <CustomCheckbox
                onChange={handleChange}
                checked={el?.author || el.executor || el.critical || false}
                value={el.id}
                editing
                name="author"
              />
              <CustomCheckbox
                onChange={handleChange}
                checked={el.executor || false}
                value={el.id}
                editing
                name="executor"
                disabled={!canExecutorEdit}
              />
              <CustomCheckbox
                onChange={handleChange}
                checked={el.critical || false}
                value={el.id}
                editing
                name="critical"
              />
              <CustomCheckbox
                title={
                  "Файлы не публикуемые и доступные только участникам проекта"
                }
                onChange={handleChange}
                checked={el.hidden || false}
                value={el.id}
                editing
                name="hidden"
                disabled={!canExecutorEdit}
              />
              {el.delete ? (
                <SourceDataName
                  handleDeleteSourceDataName={handleDeleteSourceDataName}
                  register={register}
                  {...el}
                />
              ) : (
                <div className={classes.text}>{el.name}</div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className={classes.modalParent_btn}>
          <FormControl>
            <CustomButton
              onClick={closeModal}
              background={palette.grey}
              width={100}
              type="button"
            >
              Отменить
            </CustomButton>
          </FormControl>
          <FormControl className={classes.modalParent_btn_marg}>
            <CustomButton
              background={palette.blue}
              width={100}
              type="submit"
              forceDisabled={disabled !== "update"}
              disabled={Boolean(disabled)}
            >
              Сохранить
            </CustomButton>
          </FormControl>
        </div>
      </form>
    </div>
  );
};
