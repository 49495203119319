import { ReactNode } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ReasonForm } from "./ReasonForm";
import { RefundForm } from "./RefundForm";
import { ProcessesForm } from "./ProcessesForm";
import { Steps } from "../model/constants";
import { useRemoveExecutorForms } from "../lib/hooks/useRemoveExecutorForms";

type Props = {
  workId: number;
};

export const RemoveExecutorForms = ({ workId }: Props) => {
  const {
    onSubmit,
    currentStep,
    isDataRemoveExecutorLoading,
    removeExecutorForm,
    dataRemoveExecutor,
    isRemovingExecutor,
  } = useRemoveExecutorForms(workId);

  const modalsBySteps: Record<string, ReactNode> = {
    [Steps.REASON]: (
      <ReasonForm
        form={removeExecutorForm}
        onSubmit={onSubmit}
        dataRemoveExecutor={dataRemoveExecutor}
        isRemovingExecutor={isRemovingExecutor}
      />
    ),
    [Steps.REFUND]: (
      <RefundForm
        workId={workId}
        form={removeExecutorForm}
        isRemovingExecutor={isRemovingExecutor}
        onSubmit={onSubmit}
      />
    ),
    [Steps.PROCESSES]: (
      <ProcessesForm
        dataRemoveExecutor={dataRemoveExecutor}
        isRemovingExecutor={isRemovingExecutor}
        form={removeExecutorForm}
        onSubmit={onSubmit}
      />
    ),
  };

  return (
    <Modal.Layout>
      <Modal.Header>Запросить снятие исполнителя</Modal.Header>
      {isDataRemoveExecutorLoading ? (
        <Modal.Preloader />
      ) : (
        <>{modalsBySteps[currentStep]}</>
      )}
    </Modal.Layout>
  );
};
