import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { projectContractsInitialState } from "./projectContractsInitialState";
import C from "./constants";
import { ApiPrimaryDocumentType } from "../../../../types/api/primaryTypes/apiPrimaryDocument";
import { StateType } from "../../../initialState";
import { createContractsListFromApi } from "./createaData/createData";

export const projectDocumentsReducer = createReducer(
  projectContractsInitialState,
  (builder) => {
    builder.addCase(
      C.SET_PROJECT_CONTRACTS,
      (state, action: PayloadAction<ApiPrimaryDocumentType>) => {
        return {
          ...state,
          data: createContractsListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorProjectDocuments = (state: StateType) =>
  state.projectDocuments.data;

export const selectorProjectDocumentsMeta = (state: StateType) =>
  state.projectDocuments.meta;

export const setDocuments = (
  data: ApiPrimaryDocumentType["data"],
  meta: ApiPrimaryDocumentType["meta"]
) => ({
  type: C.SET_PROJECT_CONTRACTS,
  payload: {
    data,
    meta,
  },
});
