import { DetailedHTMLProps, FormHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./Modal.module.scss";

type Props = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

const ModalForm = ({ children, className = "", ...rest }: Props) => {
  return (
    <form className={cn(styles.modal__form, className)} {...rest}>
      {children}
    </form>
  );
};

export default ModalForm;
