import React, { memo, useEffect } from "react";
import parse from "html-react-parser";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessCanCheck,
  getProjectProcessCanCheckIsLoad,
} from "../../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import cls from "./ModalCheckProcessTask.module.scss";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ApiPrimaryFileType } from "../../../../../../../types/api/primaryTypes/apiPrimaryFileType";
import { FormModalCheck } from "./FormModalCheck/FormModalCheck";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../styles/restyle";
import { fetchProjectProcessDepositFunds } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDepositFunds";
import { fetchProjectProcessCanCheck } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessCanCheck";
import { ModalPreloader } from "../../../../../../newUI/Modal/ModalPreloader";

interface ModalCheckProcessTaskProps {
  comment: string;
  files: ApiPrimaryFileType[];
  processIdDerrived?: number;
  taskId?: number;
  header?: string;
}
export const ModalCheckProcessTask = memo(
  ({
    comment,
    files,
    taskId,
    processIdDerrived,
    header,
  }: ModalCheckProcessTaskProps) => {
    const dispatch = useAppDispatch();
    const canCheck = useAppSelector(getProjectProcessCanCheck);
    const canCheckIsload = useAppSelector(getProjectProcessCanCheckIsLoad);
    const processName = useAppSelector(getProjectProcessInfoName);
    const process = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);

    const taskCheckId = taskId ?? process?.taskCheck.id;
    const actualProcessId = processId ?? processIdDerrived;
    const actualHeader =
      header ??
      `Проверка исполнения раздела «${processName}» по проекту «${process?.project?.name}»`;

    useEffect(() => {
      if (actualProcessId) {
        dispatch(fetchProjectProcessCanCheck(actualProcessId));
      }
    }, [actualProcessId]);

    async function depositFundsHandle() {
      if (actualProcessId) {
        await dispatch(fetchProjectProcessDepositFunds(actualProcessId));
        dispatch(fetchProjectProcessCanCheck(actualProcessId));
      }
    }

    if (canCheckIsload) {
      return <ModalPreloader />;
    }

    let renderModalContent;

    if (typeof canCheck === "boolean") {
      renderModalContent = (
        <FormModalCheck
          header={actualHeader}
          taskId={taskCheckId}
          comment={comment}
          files={files}
        />
      );
    } else if (canCheck && canCheck.showBtn) {
      renderModalContent = (
        <div className={cls.check}>
          <h1>{actualHeader}</h1>
          <div className={cls.check_parse}>{parse(canCheck.msg)}</div>
          <div className={cls.check_btn}>
            <CustomButton
              background={palette.blue}
              width={180}
              onClick={depositFundsHandle}
            >
              Перевести средства
            </CustomButton>
          </div>
        </div>
      );
    } else if (canCheck && !canCheck.showBtn) {
      renderModalContent = (
        <div className={cls.notCheck}>
          <h1>{actualHeader}</h1>
          <div className={cls.notCheck_parse}>{parse(canCheck.msg)}</div>
        </div>
      );
    }

    return <div>{renderModalContent}</div>;
  }
);
