import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
} from "@reduxjs/toolkit";
import { getWorkTasks } from "./thunks";
import { createWorkTasks } from "./utils";

type TState = {
  data: {
    checks: any[];
    solutions: any[];
    documentation: any[];
  };
  pending: boolean;
};

const initialState: TState = {
  data: {
    checks: [],
    solutions: [],
    documentation: [],
  },
  pending: false,
};

const dashboardWorkTasks = createSlice({
  name: "dashboardWorkTasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getWorkTasks), (state) => {
        state.pending = true;
      })
      .addMatcher(isFulfilled(getWorkTasks), (state, action) => {
        state.data = createWorkTasks(action.payload);
        state.pending = false;
      });
  },
});

export const getDashboardWorkTasks = (state: CombinedState<any>) =>
  state.dashboard.workTasks;

export default dashboardWorkTasks;
