import { useEffect } from "react";
import { useAppDispatch } from "../../app/store";
import { fetchConstantValues } from "../../app/feature/constantValues/thunks";

export const useConstantValues = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchConstantValues());
  }, []);
};
