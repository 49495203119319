import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { taskApi } from "src/FSD/entities/task/api";
import styles from "./CommentsForm.module.scss";
import { useComments } from "../lib/hooks/useComments";

type Props = {
  taskId: number;
};

export const CommentsForm = ({ taskId }: Props) => {
  const { isCommentsLoading, comments } = useComments(taskId);

  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      {isCommentsLoading ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form>
          <Column>
            {comments.length ? (
              comments.map((comment: any) => (
                <div key={comment.id} className={styles.comment}>
                  <h2>{comment.author}</h2>
                  <h2>{comment.status.value}</h2>
                  <p>{comment.text}</p>
                  <p>{comment.date_create}</p>
                </div>
              ))
            ) : (
              <p className={styles.notFound}>Комментарии не найдены</p>
            )}
          </Column>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};

/*

const ModalComment: FC<ModalCommentProps> = ({ taskId }) => {
  const dispatch = useAppDispatch();
  const { comments, isLoadComments } = useAppSelector(getProjectTasks);

  useEffect(() => {
    dispatch(fetchCommentsByTaskId(taskId!));

    return () => {
      dispatch(removeTaskComments());
    };
  }, []);

  if (isLoadComments) {
    return (
      <div className={cls.skeleton}>
        <Skeleton sx={{ height: "100px" }} />
      </div>
    );
  }

  return (
    <div className={cls.commentsList}>
      {comments.length ? (
        comments.map((comment) => (
          <div className={cls.comment}>
            <h2>{comment.author}</h2>
            <h2>{comment.status}</h2>
            <p>{comment.text}</p>
            <p>{comment.date}</p>
          </div>
        ))
      ) : (
        <p className={cls.notFound}>Комментарии не найдены</p>
      )}
    </div>
  );

*/
