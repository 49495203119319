import { memo, ReactNode } from "react";
import { ListItemText } from "@mui/material";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { getCompleteOptions, getNewValue } from "./utils";

type IsOptionEqualToValue = (
  option: TSelectItem,
  value: TSelectItem
) => boolean;

type Props = {
  value: TSelectItem[];
  label: string;
  placeholder?: string;
  changeHandler: (newValue: TSelectItem[]) => void;
  options: TSelectItem[];
  showSelectAll?: boolean;
  isLoading?: boolean;
  isOptionEqualToValue?: IsOptionEqualToValue;
  helperText?: ReactNode;
  error?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
};

export const SelectMultiple = ({
  value = [],
  label,
  placeholder,
  changeHandler,
  options,
  showSelectAll = false,
  helperText,
  error = false,
  disablePortal = true,
  isLoading = false,
  disabled = false,
  ...other
}: Props) => {
  const completeOptions = getCompleteOptions(value, options, showSelectAll);

  const handleChange = (_: unknown, newValue: TSelectItem[]) => {
    const newOptions = getNewValue(options, newValue);
    changeHandler(newOptions);
  };

  return (
    <Autocomplete
      {...other}
      multiple
      onChange={handleChange}
      options={completeOptions}
      getOptionLabel={(option) => option.title}
      value={value}
      loading={isLoading}
      filterSelectedOptions
      disabled={disabled}
      disablePortal={disablePortal}
      renderOption={(props, item) => {
        return (
          <li {...props} key={item.id}>
            <ListItemText>{item.title}</ListItemText>
          </li>
        );
      }}
      // isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default memo(SelectMultiple);
