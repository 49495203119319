import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { Avatar } from "../../Avatar/Avatar";
import style from "./style.module.scss";

export type AvatarProps = {
  title?: string;
  url: string;
};

export type AvatarMenuProps = ComponentPropsType<
  {
    showOnline?: boolean;
  } & AvatarProps
>;

export const AvatarMenu: React.FC<AvatarMenuProps> = React.memo(
  ({ theme = "dark", title = "", url = "" }) => {
    return (
      <div className={classNames(style.chatAvatarWrapper)}>
        {url ? (
          <Avatar src={url} alt={title} />
        ) : (
          <div className={style.optimizeAvatar} title={title}></div>
        )}
      </div>
    );
  }
);
