import { config } from "../../../lib/configs/app.config";
import { httpRequestGet } from "../../../lib/services/api/httpRequests";

export const ApiGetProjectProcessToProcesses = (
  taskId: number,
  projectId: number,
  processId: number,
  all?: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-processes?projectId=${projectId}&processId=${processId}&all=${all ?? 1}`
  );
};
