import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import {
  ApiProjectProcessView,
  ApiProjectProcessViewDuplicate,
} from "../../../../services/api/projectProcess/info";
import { ProjectProcessInfoTypes } from "../types/projectProcessInfoTypes";

export const fetchProjectProcessView = createAsyncThunk<
  ProjectProcessInfoTypes,
  number
>(
  "projectProcess/fetchProjectProcessView",
  async (processId: number, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessView(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessViewDuplicate = createAsyncThunk<
  ProjectProcessInfoTypes,
  number
>(
  "projectProcess/fetchProjectProcessViewDuplicate",
  async (processId: number, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessViewDuplicate(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const fetchProjectProcessViewInfoIsLoadDuplicate = createAsyncThunk<
  ProjectProcessInfoTypes,
  number
>(
  "projectProcess/fetchProjectProcessViewInfoIsLoadDuplicate",
  async (projectId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessViewDuplicate(projectId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);
