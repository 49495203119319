import React from "react";
import moment from "moment";
import cls from "./ChecklistCardCauses.module.scss";
import {
  TextLink,
  ThemeTextLink,
} from "../../../UI/components/TextLink/TextLink";
import { StopIcon } from "../../SVG";
import { ChecklistProjectData } from "../../../../app/feature/ProjectView/CheckListReducer/thunks";
import {
  TooltipTheme,
  TooltipWrapper,
} from "../../TooltipWrapper/TooltipWrapper";

interface ChecklistCardCausesProps {
  data: ChecklistProjectData;
}
export const ChecklistCardCauses = ({ data }: ChecklistCardCausesProps) => {
  const haveStopTask = data?.stopTasks?.length > 0;
  return (
    <div className={cls.stop}>
      <div className={cls.stop_header}>
        <TextLink
          customTheme={ThemeTextLink.WHITE}
          redirect
          url={`/project-process/${data.id}`}
        >
          <TooltipWrapper content={data.part.name}>
            <p>{data.part.code}</p>
          </TooltipWrapper>
        </TextLink>
        {haveStopTask && <StopIcon height={"20"} width={"20"} />}
      </div>
      {haveStopTask && (
        <>
          {data.stopTasks.map((task) => (
            <div key={task.id} className={cls.causes}>
              <p>
                {`Остановка работ ${moment(
                  task.date_create,
                  "DD.MM.YYYY HH:mm:ss Z"
                ).format("DD.MM.YYYY")}:`}
              </p>
              <TooltipWrapper
                content={task.content}
                classname={cls.causesContent}
                theme={TooltipTheme.TITLE}
                title={"Причина"}
                width={"auto"}
              >
                <span
                  className={cls.causesContent}
                >{`Причина: ${task.content}`}</span>
              </TooltipWrapper>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
