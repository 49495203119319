import styles from "src/FSD/shared/uiKit/v2/Accordion/ui/Accordion.module.scss";
import React from "react";

export type TCount = {
  inWorkCount?: number;
  outDateCount?: number;
  doneCount?: number;
};

export const SubTitleCount = ({
  inWorkCount,
  outDateCount,
  doneCount,
}: TCount) => {
  if (!inWorkCount && !outDateCount && !doneCount) {
    return null;
  }

  return (
    <div className={styles.status}>
      {!!inWorkCount && (
        <span className={styles.status_muted}>
          В работе <span className={styles.count}>{inWorkCount}</span>
        </span>
      )}
      {!!outDateCount && (
        <span className={styles.status_warning}>
          Просрочено <span className={styles.count}>{outDateCount}</span>
        </span>
      )}
      {!!doneCount && (
        <>
          <span className={styles.status_success}>
            Выполнено <span className={styles.count}>{doneCount}</span>
          </span>
        </>
      )}
    </div>
  );
};
