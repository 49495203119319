import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPostMultiData,
  httpRequestPut,
  httpRequestPutMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

const expands = `can,
  countAccesses,
  activeAccess.user,
  activeAccess.userType,
  activeAccess.job_duration,
  activeAccess.price,
  haveExpertise,
  executorNds,
  stopped,
  countProcessWorks,
  partGroup`;

const dashboardExpands = `can,
  countAccesses,
  activeAccess.user,
  activeAccess.userType,
  activeAccess.job_duration,
  activeAccess.price,
  executorNds,
  stopped,
  countProcessWorks,
  partGroup,
  tabs`;

export const ApiSectionsTree = (id: number): Promise<Response> => {
  // получить дерево разделов
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-project-process-tree?id=${id}&expand=${expands}`
  );
};

export const ApiSectionsTreeForDashboard = (id: number): Promise<Response> => {
  // получить дерево разделов
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-project-process-tree?id=${id}&expand=${dashboardExpands}`
  );
};

export const ApiPublishProcess = (id: string, data: any): Promise<Response> => {
  // публикация раздела
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/publish?id=${id}`,
    {},
    { ...data }
  );
};

export const ApiUnPublishProcess = (id: number): Promise<Response> => {
  // вернуть с публикации
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/unpublish?id=${id}`
  );
};

export const ApiUpdatePublishProcess = (
  id: number,
  data: any
): Promise<Response> => {
  // редактировать публикацию
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/update-published?id=${id}`,
    {},
    { ...data }
  );
};

export const ApiGetUsersToAssign = (id: number): Promise<Response> => {
  // получить исполнителей
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-users-to-assign?id=${id}`
  );
};

export const ApiGetUsersTypeToAssign = (
  id: number,
  userId: number
): Promise<Response> => {
  // получить типы исполнителей
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-user-type-to-assign?id=${id}&userId=${userId}`
  );
};

export const ApiPublishInfo = (id: number): Promise<Response> => {
  // получить информацию по запретам на публикацию
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/publish-info?id=${id}`
  );
};

export const ApiPublishInfoWork = (id: number): Promise<Response> => {
  // получить информацию по запретам на публикацию задачи
  return httpRequestGet(
    `${config.localDomain}/v1/process-work/publish-info?id=${id}`
  );
};

export const ApiCreateSourceComment = (
  id: number,
  data: FormData
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/comment-source-data/create?sourceDataId=${id}`,
    {},
    data
  );
};

export const ApiEditSourceComment = (
  id: number,
  data: FormData
): Promise<Response> => {
  return httpRequestPutMultiData(
    `${config.localDomain}/v1/comment-source-data/update?id=${id}`,
    {},
    data
  );
};

export const ApiFetchSourceComments = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/comment-source-data/index?CommentSourceDataSearch[source_data_id]=${id}&expand=can,files`
  );
};

export const ApiFetchSourceCommentById = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/comment-source-data/view?id=${id}`
  );
};

export const ApiDeleteSourceComment = (id: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/comment-source-data/delete?id=${id}`
  );
};
