import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Controller } from "react-hook-form";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { SelectUserTypes } from "src/FSD/features/v2/SelectUserTypes";
import { SelectBankAccountsUserType } from "src/FSD/features/v2/SelectBankAccountsUserType/ui/SelectBankAccountsUserType";
import styles from "./RequestUpdateForm.module.scss";
import {
  TPublicationObjectValues,
  useRequestUpdate,
} from "../lib/hooks/useRequestUpdate";
import { WarningMessage } from "./WarningMessage";
import { MissingAttributesError } from "./MissingAttributesError";

const defaultValues = {
  dateStart: null,
  dateLimit: null,
  price: 0,
  userType: null,
  comment: "",
  bankAccount: null,
  bimUser: true,
  withDocs: false,
  showUserTypeWarning: true,
  showFirmAttorneyWarning: false,
  dateStartCustomer: null,
};

type Props = {
  publicationObjectValues: TPublicationObjectValues;
  processWork?: boolean;
  edit?: boolean;
  secure: boolean;
};

export const RequestUpdateForm = ({
  publicationObjectValues,
  processWork = false,
  edit = false,
  secure,
}: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    control,
    register,
    isSubmitting,
    isTaskViewPending,
    valuesRequestUpdate,
    setValue,
    taskData,
    errors,
    typeMissingAttributes,
    warningMessagesMandatoryBankDetails,
  } = useRequestUpdate({
    handleClose,
    defaultValues,
    processWork,
    edit,
    secure,
    publicationObjectValues,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{publicationObjectValues.header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.requestUpdateForm}
        >
          <Paragraph style={{ color: "#D5D6DE" }}>
            {`Срок и стоимость исполнения ${valuesRequestUpdate.process}`}
          </Paragraph>
          <div className={styles.wrapper}>
            <Controller
              control={control}
              name="dateStart"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Начальный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateStart}
                  helperText={errors?.dateStart?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="dateLimit"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Конечный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateLimit}
                  helperText={errors?.dateLimit?.message}
                />
              )}
            />
            <TextField
              {...register("price")}
              label="Стоимость"
              error={!!errors.price}
              helperText={errors?.price?.message}
            />

            <Paragraph
              fontSize={14}
              className={styles.wrapper__text}
              style={{ textAlign: "start" }}
            >
              {`Обращаем ваше внимание, что вы имеете возможность изменить
              указанные заказчиком сроки и стоимость исполнения ${valuesRequestUpdate.process}`}
            </Paragraph>
          </div>
          <div>
            <Controller
              name="userType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectUserTypes
                  value={value ?? null}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  setValue={setValue}
                  userTypeId={taskData?.workflow_data?.executor_type}
                  error={!!errors.userType}
                  helperText={errors?.userType?.message}
                />
              )}
            />
            {valuesRequestUpdate.showMissingAttributesError && (
              <MissingAttributesError
                typeMissingAttributes={typeMissingAttributes}
              >
                {warningMessagesMandatoryBankDetails}
              </MissingAttributesError>
            )}
          </div>

          {publicationObjectValues.withDocs && (
            <Controller
              name="bankAccount"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectBankAccountsUserType
                  value={value ?? null}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  userType={valuesRequestUpdate.userType}
                  disabled={!valuesRequestUpdate.userType}
                  setValue={setValue}
                  bankAccountId={taskData?.workflow_data?.bank_account_id}
                  error={!!errors.bankAccount}
                  helperText={errors?.bankAccount?.message}
                  isBankAccount={valuesRequestUpdate.isBankAccount}
                />
              )}
            />
          )}

          <TextField {...register("comment")} label="Комментарий" multiline />
          {(valuesRequestUpdate.showBimWarning ||
            valuesRequestUpdate.showUserTypeWarning) && (
            <WarningMessage
              showBimWarning={valuesRequestUpdate.showBimWarning}
              showUserTypeWarning={valuesRequestUpdate.showUserTypeWarning}
            />
          )}
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              {edit ? "Изменить" : "Отправить"}
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
