import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextField, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import style from "./style.module.scss";
import { DateField } from "../../../features/SpecialForm/DateField/DateField";

import {
  BankAccountTypeItem,
  BankAccountTypes,
} from "../../../features/SpecialForm/BankAccount";
import {
  parseUnprocessableFields,
  request,
} from "../../../../app/services/api/requestHandler";
import {
  ApiPostCreateProject,
  ApiPutEditProject,
} from "../../../../app/services/api/projectCreate/projectCreate";
import { ApiGetBuildingFieldsByTwoParams } from "../../../../app/services/api/buildingFunction/buildingFunction";
import { ApiGetUserBankAccount } from "../../../../app/services/api/bankAccount/bankAccount";
import { setInitialExpertiseType } from "../../../../app/feature/projectProcessControlsPage/processControlsPage";
import { ErrorMessage } from "../../../features/Forms/ErrorMessage/ErrorMessage";
import { DesignType } from "../../../features/SpecialForm/newComponents/DesignTypes";
import { CountriesType } from "../../../features/SpecialForm/Country/Country";
import { Regions } from "../../../features/SpecialForm/Country/RegionsOfTheCountry";
import { BuildingFunctionTypes } from "../../../features/SpecialForm/BuildingFunctionTypes";
import { ConstructionType } from "../../../features/SpecialForm/ConstructionType";
import { ExpertiseType } from "../../../features/SpecialForm/ExpertiseTypes";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import {
  SetState,
  expertiseNums,
  getTreeIdByNum,
} from "../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { standardSchema } from "../schemas";
import { FieldsType } from "../../../features/Forms/CreateProjectForm/types";
import { UnsavedType } from "../types";
import { palette, textFieldSX } from "../../../../styles/restyle";
import { fetchProjectView } from "../../../../app/feature/ProjectView/thunks";
import { getProjectViewData } from "../../../../app/feature/ProjectView/projectView";
import LocationSearchField from "./fields/LocationSearchField/LocationSearchField";
import { ApiGetLocationSeismicity } from "../../../../app/services/api/seismicity";
import useNonInitialEffect from "../../../hooks/useNonInitialEffect";
import { useAppSelector } from "../../../../app/store";
import {
  getParentProjectId,
  setParentProjectId,
} from "../../../../app/feature/ProjectView/ComplexReducer/projectComplex";

export type Values = any;

const arrayToObject = (array: FieldsType[], key: string) =>
  array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [item[key]]: "",
    }),
    {}
  );

const dateFormat = "DD.MM.YYYY";

const paperOpt = {
  position: "absolute",
  left: "0px",
  width: "100%",
  marginTop: 4,
  color: "#26313a",
};

const getError = (item: any, value: any): null | string => {
  if (value === undefined || value === null || value === "") {
    return null;
  }

  const { max } = item;
  const { min } = item;
  const { precision } = item.unit;

  const trimmedValue = Number(`${value}`.replace(",", "."));
  const remain = String(trimmedValue).split(".").reverse();

  if (Number.isNaN(trimmedValue)) {
    return "Значение должно быть числом";
  }

  if (max && trimmedValue > max) {
    return `Максимальное допустимое значение - ${max}`;
  }
  if (min && trimmedValue < min) {
    return `Минимальное допустимое значение - ${min}`;
  }
  if (String(trimmedValue).includes(".") && remain[0].length > precision) {
    return `Количество знаков после запятой не должно превышать ${precision}`;
  }

  return null;
};

let sumError = false;

type StandardProjectFormProps = {
  isEditMode: boolean;
  projectId: number;
  info: any;
  infoHandler: SetState<any>;
  setProjectId: SetState<number>;
  type?: string;
  authorType: any;
  setUnsaved: SetState<UnsavedType>;
  ticked: number[];
  tree: any;
  setHasComplexMemo?: () => void;
};

export const StandardForm: React.FC<StandardProjectFormProps> = ({
  isEditMode = false,
  projectId,
  info,
  infoHandler,
  setProjectId,
  type,
  authorType,
  setUnsaved,
  ticked,
  tree,
  setHasComplexMemo,
}) => {
  const fieldError = useRef<Record<string, boolean>>({});

  const parentId = useAppSelector(getParentProjectId);

  const { project } = useSelector(getProjectViewData);

  const navigate = useNavigate();

  const PROJECT_ID = projectId!;

  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const [bankAccounts, setBankAccounts] = useState<BankAccountTypeItem[]>([]);
  const [expertiseOptions, setExpertiseOptions] = useState<any>([]);
  const [hasComplex, setHasComplex] = useState(false);

  useEffect(() => {
    if (project && parentId) {
      const result = project?.expertiseType.map((el) => ({
        title: el.title,
        id: el.partId,
      }));
      setExpertiseOptions(result);
    }
  }, [project]);

  const isMobile = useIsMobile();

  let design: { title: string; id: string } = { title: "", id: "" };
  let construction: any = {};

  useEffect(() => {
    if (parentId) {
      dispatch(fetchProjectView(parentId));
      setHasComplex(true);
    }
  }, [parentId, dispatch]);

  const initialFormValues =
    isEditMode && info.name
      ? {
          num: info.num,
          name: info.name,
          code: info.code,
          name_for_doc: info.name_for_doc,
          date_start: info.date_start
            ? moment(info.date_start, dateFormat).toDate()
            : null,
          date_end: info.date_end
            ? moment(info.date_end, dateFormat).toDate()
            : null,
          design_type: {
            title: info.design_type.title,
            id: info.design_type.id,
          },
          can: {
            update: info?.can?.update,
            edit: info?.can?.edit,
          },
          dependencies: info.dependencies,
          author_type: info.author_type,
          bank_account_id: info.building_function_id, // зависить от author_type
          building_function_id: info.building_function_id,
          construction_type_id: {
            id: info.construction_type_id.id,
            title: info.construction_type_id.title,
          },
          expertise_type: info.expertise_type,
          advance_pay: info.advance_pay, // Условия оплаты -> Аванс
          pay1: info.pay1, // Условия оплаты -> Оплата после приёмки документации
          pay2: info.pay2, // Условия оплаты -> Оплата после получения средств от заказчика
          pay_limit: info.pay_limit, // Условия оплаты -> Период окончательной оплаты
          country_code: info.country_code,
          region_code: info.region_code, // зависит от country_code
          description: info.description,
          value: info.value, //  функция для создания полей пришедших в запросе
          location: info.location,
          a: info.a,
          b: info.b,
          c: info.c,
        }
      : {
          num: "",
          name: "",
          code: "",
          name_for_doc: "",
          date_start: null,
          date_end: null,
          design_type: { title: "2D", id: "2" },
          author_type: "",
          bank_account_id: "", // зависить от author_type
          building_function_id: type,
          can: {
            update: true,
            edit: true,
          },
          construction_type_id: {
            id: "1",
            title: "Новое строительство",
          },
          expertise_type: { title: "Не требуется", id: "", num: "" },
          advance_pay: "", // Условия оплаты -> Аванс
          pay1: "", // Условия оплаты -> Оплата после приёмки документации
          pay2: "", // Условия оплаты -> Оплата после получения средств от заказчика
          pay_limit: "", // Условия оплаты -> Период окончательной оплаты
          country_code: "RU",
          region_code: 0, // зависит от country_code
          description: "",
          value: arrayToObject(fields, "code"), //  функция для создания полей пришедших в запросе
          location: "",
          a: "",
          b: "",
          c: "",
        };

  const {
    register,
    trigger,
    watch,
    reset,
    setValue,
    setError,
    formState: { dirtyFields, errors },
    handleSubmit,
    getValues,
  } = useForm({
    // @ts-ignore
    resolver: yupResolver(standardSchema),
    mode: "onBlur",
    defaultValues: { ...initialFormValues },
  });

  useEffect(() => {
    if ((info.parentId || hasComplex) && setHasComplexMemo) {
      setHasComplexMemo();
    }
  }, [hasComplex, info]);

  useEffect(() => {
    if (project && hasComplex) {
      const hasBankId =
        project.authorType.type === 1 || project.authorType.type === 4;
      setValue(
        "date_start",
        project.dateStart
          ? moment(project.dateStart, dateFormat).toDate()
          : null
      );
      setValue(
        "date_end",
        project.dateEnd ? moment(project.dateEnd, dateFormat).toDate() : null
      );
      setValue("name_for_doc", project.nameForDoc);
      setValue("region_code", project.regionCode);
      setValue("country_code", project.countryCode);
      setValue("bank_account_id", hasBankId ? -1 : project.bankId);
      setValue("advance_pay", project.advance_pay);
      setValue("pay1", project.lastPay);
      setValue("pay2", project.expertisePay);
      setValue("pay_limit", project.payLimit);
      setValue("expertise_type", {
        title: project.expertiseType[0]?.title,
        id: project.expertiseType[0]?.partId,
        num: project.expertiseType[0]?.num,
      });
      setValue("code", project.code);

      setValue("location", project.location);
      setValue("a", project.seismicity?.a ?? "");
      setValue("b", project.seismicity?.b ?? "");
      setValue("c", project.seismicity?.c ?? "");
    }
  }, [project, hasComplex]);

  const watchDateStart = watch("date_start");
  const watchDateEnd = watch("date_end");
  const watchDesignType = watch("design_type");
  const watchExpertiseType = watch("expertise_type");
  const watchCountryCode = watch("country_code");
  const watchRegionCode = watch("region_code");
  const watchConstructionType = watch("construction_type_id");
  const watchBuildingFunctionId = watch("building_function_id");
  const watchAdvancePay = watch("advance_pay");
  const watchPay1 = watch("pay1");
  const watchPay2 = watch("pay2");
  const watchBankAccountId = watch("bank_account_id");
  const watchLocation = watch("location");

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({
      ...prev,
      info: Boolean(dirtyForm),
    }));
  }, [dirtyForm]);

  const setValueOfField = (fieldName: string, defaultValue: any) => {
    // @ts-ignore
    setValue(fieldName, defaultValue);
  };

  useEffect(() => {
    if (authorType === "") {
      setValueOfField("bank_account_id", "");
      setBankAccounts([]);
    }

    if (authorType) {
      request(
        ApiGetUserBankAccount(authorType.id),
        (data) => {
          setBankAccounts([
            ...data.map((item: { id: number | null; name: string }) => {
              return {
                id: item.id,
                title: item.name,
              };
            }),
          ]);
        },
        () => () => {
          enqueueSnackbar("Ошибка запроса типов банковского счета", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      )(dispatch);
    }
  }, [authorType]);

  // устанавливаем значение по умолчанию, для отображения в форме
  useEffect(() => {
    if (bankAccounts.length > 0) {
      setValueOfField("bank_account_id", bankAccounts[0].id);
    }
  }, [bankAccounts]);

  // подготавливаем експертизу для использования на следующем этапе создания проекта
  useEffect(() => {
    if (watchExpertiseType?.num) {
      dispatch(
        setInitialExpertiseType({
          expertiseType: watchExpertiseType.num,
        } as any)
      );
    } else {
      dispatch(setInitialExpertiseType({ expertiseType: "" } as any));
    }
  }, [dispatch, watchExpertiseType]);

  useNonInitialEffect(() => {
    setValue("a", "");
    setValue("b", "");
    setValue("c", "");
    setValue("location", "");
  }, [watchRegionCode]);

  useNonInitialEffect(() => {
    request(
      ApiGetLocationSeismicity(watchRegionCode, watchLocation),
      (data) => {
        setValue("a", data.a);
        setValue("b", data.b);
        setValue("c", data.c);
      },
      () => () => {
        enqueueSnackbar("Ошибка получения данных о сейсмичности", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  }, [watchLocation]);

  const clearFields = (fields: FieldsType[]) => {
    for (let i = 0; i < fields.length; i++) {
      setValueOfField(`value.${fields[i].code}`, "");
    }
  };

  const renderBuildingFields = (fields: FieldsType[]) => {
    if (!fields || !fields.length) {
      return null;
    }

    return (
      <div className={style.objectGrid}>
        {fields.map((item) => {
          const watchField = watch(`value.${item.code}`);
          const helperText = getError(item, watchField);
          const error = Boolean(helperText);

          fieldError.current[item.code] = error;

          return (
            <div className={style.fieldGrid} key={item.code}>
              <h5>{item.name}</h5>
              <TextField
                className={style.parameter}
                key={item.code}
                size="small"
                variant="outlined"
                autoComplete="off"
                label={item.unit.name}
                InputProps={{
                  inputProps: {
                    min: item.min ? item.min : 0,
                    max: item.max,
                    step: item.unit.precision
                      ? 1 / 10 ** item.unit.precision
                      : 1,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                error={error}
                {...register(`value.${item.code}`, {
                  value: initialFormValues.value[item.code],
                })}
                {...textFieldSX}
              />
              {error && <p>{helperText}</p>}
            </div>
          );
        })}
      </div>
    );
  };

  const error = (error: string | undefined) => {
    if (!error) {
      return null;
    }
    return <ErrorMessage variant={style.postInputError} message={error} />;
  };

  const expertiseFormed = useMemo(() => {
    const has = expertiseNums.filter((num: string) =>
      ticked.includes(getTreeIdByNum(num, tree))
    );

    if (has.length) {
      return has[0];
    }

    return 0;
  }, [ticked, tree]);

  const submitForm = async (data: Values) => {
    const atLeastOneFieldError = Object.values(fieldError.current).some(
      (value) => value
    );

    if (sumError || atLeastOneFieldError) {
      return;
    }

    const formValues = getValues();

    fieldError.current = {};

    reset(formValues, {
      // @ts-ignore
      dirtyFields: false,
    });

    const valuesFormed = Object.entries(data.value).reduce(
      (result: Record<string, number>, [key, value]: [string, any]) => {
        result[key] = Number(`${value}`.replace(",", "."));
        return { ...result };
      },
      {}
    );

    design = data.design_type;
    construction = data.construction_type_id;

    setLoading(true);

    if (!isEditMode) {
      await request(
        ApiPostCreateProject({
          Project: {
            name: data.name,
            code: data.code,
            parent_id: parentId,
            name_for_doc: data.name_for_doc,
            location: data.location,
            date_start: data.date_start
              ? moment(data.date_start).format(dateFormat)
              : null,
            date_end: data.date_end
              ? moment(data.date_end).format(dateFormat)
              : null,
            design_type: data.design_type.id, // Вид проектирования
            author_type: authorType.id,
            bank_account_id: data.bank_account_id,
            building_function_id: data.building_function_id, // Назначение строительства
            construction_type_id: data.construction_type_id.id, // Тип строительства
            expertise_type: data.expertise_type.id,
            advance_pay: Number(data.advance_pay), // Аванс
            pay1: Number(data.pay1), // Оплата после приёмки документации
            pay2: Number(data.pay2), // Оплата после получения средств от заказчика
            pay_limit: Number(data.pay_limit), // Период окончательной оплаты
            country_code: data.country_code,
            region_code: data.region_code,
            dependencies: data.dependencies,
            description: data.description,
          },
          Seismicity: {
            a: data.a ?? "",
            b: data.b ?? "",
            c: data.c ?? "",
          },
          value: {
            ...valuesFormed, // физические параметры проекта
          },
        }),
        (result, headersData, headers, status) => {
          if (status === 200) {
            enqueueSnackbar("Данные о проекте успешно сохранены", {
              variant: "success",
              autoHideDuration: 5000,
            });

            setLoading(false);
            setUnsaved((prev) => ({
              ...prev,
              info: false,
            }));
            setProjectId(result);
            navigate(
              `/project/update?userTypeId=${authorType.id}&id=${result}`
            );
          }
          if (Number(status) >= 400) {
            enqueueSnackbar("При сохранении возникла ошибка", {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        },
        () => (data) => {
          if (data) {
            setLoading(false);
          }
          const message = JSON.parse(data.message);
          if (message.hasOwnProperty("name")) {
            enqueueSnackbar(message.name, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          parseUnprocessableFields(data.message, setError);
        }
      )();
    }

    if (isEditMode) {
      await request(
        ApiPutEditProject(PROJECT_ID, {
          Project: {
            name: data.name,
            code: data.code,
            name_for_doc: data.name_for_doc,
            location: data.location,
            date_start: data.date_start
              ? moment(data.date_start).format(dateFormat)
              : null,
            date_end: data.date_end
              ? moment(data.date_end).format(dateFormat)
              : null,
            design_type: data.design_type.id, // Вид проектирования
            author_type: authorType.id,
            bank_account_id: data.bank_account_id,
            building_function_id: data.building_function_id, // Назначение строительства
            construction_type_id: data.construction_type_id.id, // Тип строительства
            advance_pay: Number(data.advance_pay), // Аванс
            pay1: Number(data.pay1), // Оплата после приёмки документации
            pay2: Number(data.pay2), // Оплата после получения средств от заказчика
            pay_limit: Number(data.pay_limit), // Период окончательной оплаты
            country_code: data.country_code,
            region_code: data.region_code,
            dependencies: data.dependencies,
            description: data.description,
          },
          Seismicity: {
            a: data.a ?? "",
            b: data.b ?? "",
            c: data.c ?? "",
          },
          value: {
            ...valuesFormed, // физические параметры проекта
          },
        }),
        (result, headersData, headers, status) => {
          if (status === 200) {
            // actions.setTouched({});
            enqueueSnackbar("Данные редактирования успешно сохранены", {
              variant: "success",
              autoHideDuration: 5000,
            });
          }

          if (Number(status) >= 400) {
            enqueueSnackbar("При сохранении возникла ошибка", {
              variant: "error",
              autoHideDuration: 5000,
            });
          }

          const newInfo = {
            ...info,
            name: result.name,
            code: result.code,
            name_for_doc: result.name_for_doc,
            parentId: result.parent_id,
            date_start: result.date_start
              ? moment(result.date_start, dateFormat).format(dateFormat)
              : null,
            date_end: result.date_end
              ? moment(result.date_end, dateFormat).format(dateFormat)
              : null,
            design_type: {
              title: design.title,
              id: `${design.id}`,
            },
            author_type: result.authorType,
            building_function_id: result.building_function_id,
            construction_type_id: {
              id: `${construction.id}`,
              title: construction.title,
            },
            advance_pay: `${result.advance_pay}`,
            pay1: `${result.pay1}`,
            pay2: `${result.pay2}`,
            pay_limit: `${result.pay_limit}`,
            country_code: data.country_code, // исключение
            region_code: result.region_code,
            description: result.description,
            dependencies: result.dependencies,
            value: {},
            fields,
            can: {
              update: info.can.update,
              edit: info.can.edit,
            },
          };

          infoHandler(newInfo);
          setLoading(false);
          setUnsaved((prev) => ({
            ...prev,
            info: false,
          }));
        },
        () => (data) => {
          if (data) {
            setLoading(false);
          }

          const message = JSON.parse(data.message);
          if (message.hasOwnProperty("name")) {
            enqueueSnackbar(message.name, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          parseUnprocessableFields(data.message, setError);
        }
      )();
    }
  };

  sumError = useMemo(
    () =>
      Number(watchAdvancePay) + Number(watchPay1) + Number(watchPay2) !== 100 &&
      watchAdvancePay &&
      watchPay1 &&
      watchPay2,
    [watchAdvancePay, watchPay1, watchPay2]
  );

  useEffect(() => {
    (async () => {
      if (
        (watchBuildingFunctionId && watchConstructionType?.id && !info.name) ||
        info.can.edit
      ) {
        await request(
          ApiGetBuildingFieldsByTwoParams(
            watchBuildingFunctionId,
            watchConstructionType.id
          ),
          (fields) => {
            setFields(fields);
          }
        )(dispatch);
      } else if (isEditMode && info.name) {
        setFields(info.fields);
      } else {
        setValueOfField("value", {});
      }
      setLoading(false);
    })();
  }, [watchBuildingFunctionId, watchConstructionType]);

  useEffect(
    () => () => {
      dispatch(setParentProjectId(null));
    },
    []
  );

  if (loading) {
    return (
      <div className={style.skeleton}>
        <Skeleton variant="rectangular" className={style.box1} />
        <Skeleton variant="rectangular" className={style.box2} />
        <Skeleton variant="rectangular" className={style.box3} />
        <Skeleton variant="rectangular" className={style.box4} />
        <Skeleton variant="rectangular" className={style.box5} />
        <Skeleton variant="rectangular" className={style.box6} />
      </div>
    );
  }

  return (
    <form className={style.grid} onSubmit={handleSubmit(submitForm)}>
      <div className={classNames(style.wrapper, style.box1)}>
        <div className={style.toSave}>
          <h1>Название проекта</h1>
          {!isMobile && (
            <CustomButton background={palette.green} type="submit">
              Сохранить
            </CustomButton>
          )}
        </div>
        <div className={style.name}>
          <TextField
            label={"ШИФР"}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(errors.code?.message)}
            helperText={errors.code?.message}
            {...register("code", {
              value: initialFormValues.code,
            })}
            {...textFieldSX}
          />
          <TextField
            label={"Наименование"}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            {...register("name", {
              value: initialFormValues.name,
            })}
            {...textFieldSX}
          />
        </div>
        <TextField
          label={"Наименование для договора"}
          error={Boolean(errors.name_for_doc?.message)}
          helperText={errors.name_for_doc?.message}
          InputLabelProps={{
            shrink: true,
          }}
          {...register("name_for_doc", {
            value: initialFormValues.name_for_doc,
          })}
          {...textFieldSX}
          disabled={hasComplex || info.parentId}
        />
      </div>
      <div className={classNames(style.wrapper, style.box2)}>
        <h1>Проектирование</h1>
        <div className={style.projects}>
          <DateField
            label="Начало"
            variant={style.designType}
            error={Boolean(errors.date_start?.message)}
            startDateProp={watchDateStart}
            changeBlur={async () => {
              await trigger("date_start");
            }}
            changeDateHandler={async (data) => {
              setValue(
                "date_start",
                data ? moment(data, dateFormat).toDate() : null
              );
              await trigger("date_start");
              await trigger("date_end");
            }}
          />
          <DateField
            label="Конец"
            variant={style.designType}
            error={Boolean(errors.date_end?.message)}
            startDateProp={watchDateEnd}
            changeBlur={async () => {
              await trigger("date_end");
            }}
            changeDateHandler={async (data) => {
              setValue(
                "date_end",
                data ? moment(data, dateFormat).toDate() : null
              );
              await trigger("date_end");
            }}
          />
          <DesignType
            label="Вид"
            changeHandler={async (data) => {
              setValue("design_type", data);
            }}
            value={watchDesignType?.id}
            variant={style.designType}
            paperOptions={paperOpt}
            disabled={!!projectId && !info.can.edit}
          />
        </div>
        {(errors.date_start?.message || errors.date_end?.message) && (
          <p className={style.projectsError}>
            {errors.date_start?.message}
            {errors.date_end?.message}
          </p>
        )}
      </div>
      <div className={classNames(style.wrapper, style.box3)}>
        <h1>Экспертиза</h1>
        <ExpertiseType
          variant={style.expertise}
          changeHandler={(data) => {
            setValue("expertise_type", data);
          }}
          value={
            projectId && !hasComplex
              ? tree[expertiseFormed]?.id
              : watchExpertiseType?.id
          }
          paperOptions={paperOpt}
          disabled={hasComplex || Boolean(projectId)}
          options={expertiseOptions}
          prompt={`Настраивается во вкладке "Разделы"`}
        />
      </div>
      <div className={classNames(style.wrapper, style.box4)}>
        <h1>Параметры объекта</h1>
        <ConstructionType
          variant={style.constructionType}
          changeHandler={(data) => {
            setValue("construction_type_id", data);
          }}
          value={watchConstructionType.id}
          isClearable={true}
          paperOptions={paperOpt}
        />
        <BuildingFunctionTypes
          variant={style.constructionFiled}
          changeHandler={async (data) => {
            if (watchBuildingFunctionId !== data.id) {
              clearFields(fields);
            }

            setValue("building_function_id", data.id);
            await trigger("building_function_id");
          }}
          value={watchBuildingFunctionId}
          isClearable={true}
          paperOptions={paperOpt}
          type={type}
          disabled={!info.can.edit}
          isRequired
        />
        {renderBuildingFields(fields)}
      </div>
      <div className={classNames(style.wrapper, style.box5, style.noGaps)}>
        <h1>География объекта</h1>
        <CountriesType
          variant={style.countries}
          changeHandler={async (data) => {
            setValue("country_code", data.id);
            setValue("region_code", 0);

            await trigger("country_code");
            await trigger("region_code");
          }}
          value={watchCountryCode}
          label="Страна"
          paperOptions={paperOpt}
          disabled={hasComplex || info.parentId}
        />
        <Regions
          id="region_code"
          name="region_code"
          label="Регион"
          value={watchRegionCode}
          countryCode={watchCountryCode}
          blurHandler={async () => {
            await trigger("region_code");
          }}
          changeHandler={async (data) => {
            setValue("region_code", data.id);
            await trigger("region_code");
          }}
          error={Boolean(errors.region_code?.message)}
          paperOptions={paperOpt}
        />
        {Boolean(errors.region_code?.message) && (
          <ErrorMessage
            variant={style.postInputError}
            message={errors.region_code?.message as string}
          />
        )}
        <LocationSearchField
          name={"location"}
          label={"Населенный пункт"}
          register={register}
          defaultValue={info.location}
          trigger={trigger}
          setValue={setValue}
          regionCode={watchRegionCode}
        />
        <div className={style.fieldGrid}>
          <h5>Сейсмическая интенсивность по ОСР-2016-А, балл</h5>
          <TextField
            className={style.parameter}
            size="small"
            variant="outlined"
            autoComplete="off"
            fullWidth
            error={Boolean(errors.a)}
            {...register("a")}
            {...textFieldSX}
          />
          {Boolean(errors.a) && <p>{errors.a?.message}</p>}
        </div>
        <div className={style.fieldGrid}>
          <h5>Сейсмическая интенсивность по ОСР-2016-B, балл</h5>
          <TextField
            className={style.parameter}
            size="small"
            variant="outlined"
            autoComplete="off"
            fullWidth
            error={Boolean(errors.b)}
            {...register("b")}
            {...textFieldSX}
          />
          {Boolean(errors.b) && <p>{errors.b?.message}</p>}
        </div>
        <div className={style.fieldGrid}>
          <h5>Сейсмическая интенсивность по ОСР-2016-C, балл</h5>
          <TextField
            className={style.parameter}
            size="small"
            variant="outlined"
            autoComplete="off"
            fullWidth
            error={Boolean(errors.c)}
            {...register("c")}
            {...textFieldSX}
          />
          {Boolean(errors.c) && <p>{errors.c?.message}</p>}
        </div>
      </div>
      <div className={classNames(style.wrapper, style.box6)}>
        <h1>Условия оплаты</h1>
        <div className={style.conditions}>
          <div className={style.fieldGrid}>
            <h5>Аванс</h5>
            <TextField
              label={"%"}
              disabled={
                !initialFormValues?.can.edit || hasComplex || info.parentId
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...textFieldSX}
              fullWidth
              className={style.parameter}
              {...register("advance_pay", {
                value: initialFormValues.advance_pay,
              })}
              error={Boolean(sumError || errors.advance_pay?.message)}
            />
            {Boolean(errors.advance_pay?.message) && (
              <p>{error(errors.advance_pay?.message as string)}</p>
            )}
          </div>
          <div className={style.fieldGrid}>
            <h5>После приемки документации</h5>
            <TextField
              label={"%"}
              disabled={
                !initialFormValues?.can.edit || hasComplex || info.parentId
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...textFieldSX}
              fullWidth
              {...register("pay1", {
                value: initialFormValues.advance_pay,
              })}
              className={style.parameter}
              error={Boolean(sumError || errors.pay1?.message)}
            />
            {Boolean(errors.pay1?.message) && (
              <p>{error(errors.pay1?.message as string)}</p>
            )}
          </div>
          <div className={style.fieldGrid}>
            <h5>После экспертизы или оплаты Заказчиком</h5>
            <TextField
              label={"%"}
              disabled={
                !initialFormValues?.can.edit || hasComplex || info.parentId
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...textFieldSX}
              fullWidth
              {...register("pay2", {
                value: initialFormValues.advance_pay,
              })}
              className={style.parameter}
              error={Boolean(sumError || errors.pay2?.message)}
            />
            {Boolean(errors.pay2?.message) && (
              <p>{error(errors.pay2?.message as string)}</p>
            )}
          </div>
          {sumError && (
            <p className={style.sumOfPayError}>
              Общая сумма оплаты должна быть равна 100%
            </p>
          )}
        </div>
      </div>

      <div className={classNames(style.wrapper, style.box7)}>
        <h1>Банковский счет</h1>
        <BankAccountTypes
          id="bank_account_id"
          name="bank_account_id"
          label="Банковский счет"
          value={watchBankAccountId}
          options={bankAccounts}
          blurHandler={async () => {
            await trigger("bank_account_id");
          }}
          changeHandler={async (data) => {
            setValue("bank_account_id", data.id);
            await trigger("bank_account_id");
          }}
          error={Boolean(errors.bank_account_id?.message)}
          paperOptions={paperOpt}
          disabled={hasComplex || info.parentId}
        />
        {Boolean(errors.bank_account_id?.message) && (
          <ErrorMessage
            variant={style.postInputError}
            message={errors.bank_account_id?.message as string}
          />
        )}
      </div>
      <div className={classNames(style.wrapper, style.box8)}>
        <h1>Обязательства</h1>
        <div className={style.conditions}>
          <div className={style.fieldGrid}>
            <h5>
              Максимальный период окончательных расчётов с исполнителем, мес
            </h5>
            <TextField
              label={"мес"}
              disabled={!initialFormValues?.can.edit}
              InputLabelProps={{
                shrink: true,
              }}
              {...textFieldSX}
              fullWidth
              className={style.parameter}
              {...register("pay_limit", {
                value: initialFormValues.pay_limit,
              })}
              error={Boolean(sumError || errors.pay_limit?.message)}
            />
            {Boolean(errors.pay_limit?.message) && (
              <p>{error(errors.pay_limit?.message as string)}</p>
            )}
          </div>
        </div>
      </div>
      <div className={classNames(style.wrapper, style.box9)}>
        <h1>Описание</h1>
        <TextField {...register("description")} multiline minRows={3} />
        {isMobile && (
          <CustomButton background={palette.green} type="submit">
            Сохранить
          </CustomButton>
        )}
      </div>
    </form>
  );
};
