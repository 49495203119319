import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessDocument } from "../../../../services/api/projectProcess/documents";
import { ProjectProcessDocumentsType } from "../types/projectProcessDocumentsTypes";

export const fetchProjectProcessDocuments = createAsyncThunk<
  ProjectProcessDocumentsType[],
  any
>(
  "projectProcess/fetchProjectProcessDocuments",
  async ({ projectId, processId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDocument(projectId, processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
