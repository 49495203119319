import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getUsersList,
  getUsersListIsLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getUsersList";
import { fetchProjectProcessUsers } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsers";

import { getExecutorProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { fetchProjectProcessUsersTypes } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsersTypes";
import {
  getUserTypes,
  getUserTypesIsLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getUserTypes";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { getProjectProcessInfoActiveAccessId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessUsersUpdate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsersUpdate";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { Select } from "src/components/UI/components/FormComponentns/Select";
import styles from "src/components/pages/Project/View/Admin/Admin.module.scss";

type Inputs = {
  user: {
    title: string;
    id: number;
  };
  type: {
    title: string;
    id: number;
  };
};

const schema = Yup.object().shape({
  user: Yup.object().required("Необходимо выбрать пользователя"),
  type: Yup.object().required("Необходимо выбрать форму ответственности"),
});

interface EditExecutorProps {
  onClose: () => void;
}

export const EditExecutor = ({ onClose }: EditExecutorProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const executor = useAppSelector(getExecutorProcessInfo);
  const usersList = useAppSelector(getUsersList);
  const isLoadUsersList = useAppSelector(getUsersListIsLoad);
  const userTypes = useAppSelector(getUserTypes);
  const isLoadUserTypes = useAppSelector(getUserTypesIsLoad);
  const activeAccessId = useAppSelector(getProjectProcessInfoActiveAccessId);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: {
        title: executor?.name,
        id: executor?.id,
      },
      type: {
        title: executor?.firm,
        id: executor?.userTypeId,
      },
    },
  });

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessUsers(+params.id));
      if (executor) {
        fetchProjectProcessUsersTypes({
          processId: +params.id,
          userId: +executor.id,
        });
      }
    }
  }, [params.id]);

  const watchUser = watch("user");

  useEffect(() => {
    if (watchUser && params.id) {
      dispatch(
        fetchProjectProcessUsersTypes({
          processId: +params.id,
          userId: +watchUser.id,
        })
      );
    }
  }, [watchUser?.id]);

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      ProjectAccess: {
        access_code: 3,
        user_id: +watchUser?.id,
        user_type_id: +dataValues.type.id,
      },
    };

    if (activeAccessId && params.id) {
      await dispatch(
        fetchProjectProcessUsersUpdate({ id: activeAccessId, data })
      );
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      onClose();
    }
  };

  if (isLoadUsersList) {
    return <NewModalPreloader />;
  }
  if (usersList) {
    const labelType = isLoadUserTypes ? "...loading" : "Форма собственности";

    return (
      <div className={styles.adminWrapper}>
        <form
          className={styles.adminWrapper_form}
          onSubmit={handleSubmit(formSubmitHandler)}
        >
          <Select
            value={watchUser}
            name={"user"}
            label=""
            newLabel={"Пользователь"}
            changeHandler={(_, value) => {
              setValue("user", value);
            }}
            options={usersList}
            error={Boolean(errors.user)}
            helperText={errors.user ? errors.user.message : null}
            isLoading={false}
            disableClear
            newDesign
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label=""
                newLabel={labelType}
                changeHandler={(_, newValue) => {
                  onChange(newValue);
                }}
                options={userTypes}
                error={Boolean(errors.type)}
                helperText={errors.type ? errors.type.message : null}
                isLoading={false}
                disableClear
                newDesign
              />
            )}
          />
          <FormControl className={styles.adminWrapper_btn}>
            <Button variant="contained" type="submit">
              Сохранить
            </Button>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
