import React, { memo } from "react";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoName,
  getProjectProcessInfoProject,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { UpdatePublishProcess } from "../../../../newUI/UpdatePublishProcess/UpdatePublishProcess";

export const ModalUpdatePublish = memo(({ closeModal }: any) => {
  const info = useAppSelector(getProjectProcessInfoData);
  const name = useAppSelector(getProjectProcessInfoName);
  const project = useAppSelector(getProjectProcessInfoProject);

  if (info && project) {
    return (
      <UpdatePublishProcess
        {...info}
        pay1={project.advance_pay}
        pay2={project.pay1}
        pay3={project.pay2}
        closeModal={closeModal}
        title={`Публикация раздела «${name}» по проекту «${info.project?.name}»`}
      />
    );
  }
  return <></>;
});
