import React, { FC, useMemo, useState } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Switch from "../../../../newUI/Switch/Switch";
import SearchField from "../SearchField";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import FilterTasks from "../Filter/Tasks";
import Work from "../Secondary/Tasks/Task";
import { getSectionTasksAndWorks } from "../../../../../app/feature/dashboard/tasksAndWorks";
import {
  getDashboardDestinations,
  setShowFulfilledWorks,
} from "../../../../../app/feature/dashboard/destinations";
import useTasksFilter from "../Filter/useTasksFilter";

const Works: FC = () => {
  const dispatch = useAppDispatch();
  const { showFulfilledWorks, sectionId } = useAppSelector(
    getDashboardDestinations
  );
  const { data: tasks } = useAppSelector(getSectionTasksAndWorks);
  const { incoming, outcoming, check, worksList } = tasks;

  const [query, setQuery] = useState<string>("");

  const data = [...incoming, ...outcoming, ...check, ...worksList];

  const { ticked, handleChange } = useTasksFilter(
    data,
    query,
    showFulfilledWorks,
    FilterTasks,
    setQuery
  );

  const showTask = (task: any) => ticked[task.id] === task.folder;
  const parseTasks = (tasksArray: any) =>
    tasksArray.filter((task: any) => showTask(task));

  const real = useMemo(() => {
    return {
      worksList: parseTasks(worksList),
    };
  }, [
    incoming,
    outcoming,
    check,
    worksList,
    showFulfilledWorks,
    query,
    ticked,
  ]);

  const tasksElements = real.worksList.map((task: any) => (
    <Work
      type="work"
      key={task.id}
      id={task.id}
      name={task.title}
      date={task.date}
      executorName={task.executorName}
      count={null}
      workflow={{}}
      taskToProcess={null}
      partId={0}
      price={task.price}
      secure={task.secure}
      message={task.executorNds}
      countAccesses={task.countAccesses}
      fulfilled={showFulfilledWorks}
      short
    />
  ));

  const showTasks = Boolean(sectionId);

  return (
    <div className={cn(style.wrapper, style.projectsPane)}>
      <div className={style.header}>
        <h3>Список задач</h3>
        <p>Выполнено</p>
        <Switch
          checked={showFulfilledWorks}
          onChange={() => {
            dispatch(setShowFulfilledWorks(!showFulfilledWorks));
          }}
        />
        <SearchField value={query} onChange={handleChange} />
      </div>
      {showTasks && <div className={style.list}>{tasksElements}</div>}
    </div>
  );
};
export default Works;
