import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessRemoveRequestExecutor } from "../../../../services/api/projectProcess/applications";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessRemoveRequest = createAsyncThunk<
  boolean,
  number
>(
  "projectProcess/fetchProjectProcessRemoveRequest",
  async (processId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessRemoveRequestExecutor(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
