import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccessCodes } from "src/FSD/shared/lib/constants/accessCodes";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getUsersList,
  getUsersListIsLoad,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getUsersList";
import { fetchProjectProcessUsers } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsers";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import { getExecutorProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { fetchProjectProcessUsersTypes } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsersTypes";
import {
  getUserTypes,
  getUserTypesIsLoad,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getUserTypes";
import style from "../../../Project/View/Admin/Admin.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import { getProjectProcessInfoActiveAccessId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessUsersUpdate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessUsersUpdate";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { Nullable } from "../../../../../types/generics";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";

type TFieldValues = {
  user: Nullable<TSelectItem>;
  type: Nullable<TSelectItem>;
};

const schema = Yup.object().shape({
  user: Yup.object().required("Необходимо выбрать пользователя"),
  type: Yup.object().required("Необходимо выбрать форму ответственности"),
});

interface ModalEditExecutorProps {
  onClose: () => void;
}
export const ModalEditExecutor = ({ onClose }: ModalEditExecutorProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const executor = useAppSelector(getExecutorProcessInfo);
  const usersList = useAppSelector(getUsersList);
  const isLoadUsersList = useAppSelector(getUsersListIsLoad);
  const isLoadUserTypes = useAppSelector(getUserTypesIsLoad);
  const userTypes = useAppSelector(getUserTypes);
  const activeAccessId = useAppSelector(getProjectProcessInfoActiveAccessId);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: {
        title: executor?.name,
        id: executor?.id,
      },
      type: {
        title: executor?.firm,
        id: executor?.userTypeId,
      },
    },
  });

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessUsers(+params.id));
    }
  }, []);

  const watchUser = watch("user");

  useEffect(() => {
    if (watchUser && params.id) {
      dispatch(
        fetchProjectProcessUsersTypes({
          processId: Number(params.id),
          userId: Number(watchUser.id),
        })
      );
    }
  }, [watchUser?.id, dispatch]);

  const formSubmitHandler = async (dataValues: TFieldValues) => {
    const data = {
      ProjectAccess: {
        access_code: AccessCodes.EXECUTOR,
        user_id: Number(dataValues.user!.id),
        user_type_id: Number(dataValues.type!.id),
      },
    };

    if (activeAccessId && params.id) {
      await dispatch(
        fetchProjectProcessUsersUpdate({ id: activeAccessId, data })
      );
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
      onClose();
    }
  };

  if (isLoadUsersList) {
    return <ModalPreloader />;
  }
  if (usersList) {
    return (
      <div className={style.adminWrapper}>
        <h1 className={style.adminWrapper_title}>Изменить доступ к проекту</h1>
        <form
          className={style.adminWrapper_form}
          onSubmit={handleSubmit(formSubmitHandler)}
        >
          <Select
            label="Пользователь"
            name={"user"}
            options={usersList}
            value={watchUser}
            changeHandler={(_, value) => {
              setValue("user", value);
              setValue("type", null);
            }}
            error={!!errors.user}
            helperText={errors.user ? errors.user.message : null}
            isLoading={false}
            disableClear
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label={"Форма собственности"}
                changeHandler={(_, newValue) => {
                  onChange(newValue);
                }}
                options={userTypes}
                error={Boolean(errors.type)}
                helperText={errors.type ? errors.type.message : null}
                isLoading={isLoadUserTypes}
                disableClear
              />
            )}
          />
          <FormControl className={style.adminWrapper_btn}>
            <CustomButton background={palette.green} width={160} type="submit">
              Сохранить
            </CustomButton>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
