import { FC, Dispatch, SetStateAction, useState, MouseEvent } from "react";
import cn from "classnames";
import { MenuItem, Popper, ClickAwayListener } from "@mui/material";
import {
  TComposition,
  getProjectComposition,
} from "../../../../../app/feature/ProjectView/Contents/getComposition";
import style from "./style.module.scss";
import {
  fetchProjectComposition,
  formAgain,
} from "../../../../../app/feature/ProjectView/Contents/thunks";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { config } from "../../../../../app.cofig";
import { ThreeDotButton } from "../../../../newUI/ThreeDotButton/ThreeDotButton";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { TValuesForEditing } from "./Contents";

const LINKS_BY_CLASS: Record<string, string> = {
  ProjectProcess: "project-process",
  ProcessWork: "process-work",
};

const TYPES: Record<string, string> = {
  project: "&types[0]=2&types[1]=1&types[2]=6",
  work: "&types[0]=3",
};

type CompositionsListProps = {
  id: number;
  setWorkId: Dispatch<SetStateAction<number | null>>;
  setEditing: Dispatch<SetStateAction<TValuesForEditing>>;
  data: Record<string, TComposition[]>;
  workId: number | null;
  tab: string;
  handleDeleteBook: (id: number) => void;
  showFormAgain: boolean;
};

const CompositionsList: FC<CompositionsListProps> = ({
  id,
  data,
  tab,
  setWorkId,
  setEditing,
  handleDeleteBook,
  showFormAgain,
  workId,
}) => {
  const dispatch = useAppDispatch();
  const { pending } = useAppSelector(getProjectComposition);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleFormAgain = async () => {
    await dispatch(formAgain(id));
    await dispatch(fetchProjectComposition(id));
  };

  const handleOpenDownloadPrompts = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const anchorId = isOpen ? "simple-popper" : undefined;

  const DownloadPopper = () => (
    // @ts-ignore
    <Popper id={anchorId} open={isOpen} anchorEl={anchorEl} placement="bottom">
      <div className={style.linkWrapper}>
        <TextLink
          url={`${config.localDomain}/project-composition/to-pdf?id=${id}${TYPES[tab]}`}
          redirect
          target="_blank"
          variant={style.link}
        >
          PDF
        </TextLink>
        <TextLink
          url={`${config.localDomain}/project-composition/to-xlsx?id=${id}${TYPES[tab]}`}
          redirect
          target="_blank"
          variant={style.link}
        >
          XLS
        </TextLink>
      </div>
    </Popper>
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={cn(style.composition, style.controls)}>
          <h1>Состав проекта</h1>
          {showFormAgain && (
            <CustomButton
              onClick={handleFormAgain}
              background={palette.green}
              disabled={pending.formAgain}
              width={160 + Number(pending.formAgain) * 30}
            >
              Сформировать
            </CustomButton>
          )}
          <CustomButton
            onClick={handleOpenDownloadPrompts}
            background={palette.green}
            width={110}
          >
            Скачать
          </CustomButton>
          <DownloadPopper />
        </div>
      </ClickAwayListener>
      <div className={style.composition}>
        <h2>№</h2>
        <h2>Обозначение</h2>
        <h2>Наименование</h2>
        <h2>Примечание</h2>
      </div>
      <div className={style.compositions}>
        {data[tab].map((composition: TComposition) => {
          const {
            num,
            header,
            type,
            typeNum,
            name,
            comment,
            objectId,
            objectClass,
            can,
          } = composition;

          const showButton = can.create || can.update || can.delete;
          const url = `${config.localDomain}/${LINKS_BY_CLASS[objectClass]}/${objectId}`;

          const formedName = `${type} ${typeNum}. ${name}`;

          return (
            <div className={style.composition}>
              <p>{num}</p>
              <p>{header}</p>
              {objectId ? (
                <TextLink
                  url={url}
                  variant={style.url}
                  redirect
                  target="_blank"
                >
                  {formedName}
                </TextLink>
              ) : (
                <p>{formedName}</p>
              )}
              <p>{comment}</p>
              {!workId && showButton && (
                <ThreeDotButton>
                  {can.create && (
                    <MenuItem disableRipple onClick={() => setWorkId(objectId)}>
                      Создать книгу
                    </MenuItem>
                  )}
                  {can.update && (
                    <MenuItem
                      disableRipple
                      onClick={() => {
                        setWorkId(objectId);
                        setEditing({ name, num: typeNum });
                      }}
                    >
                      Редактировать
                    </MenuItem>
                  )}
                  {can.delete && (
                    <MenuItem
                      disableRipple
                      onClick={() => handleDeleteBook(objectId as number)}
                    >
                      Удалить
                    </MenuItem>
                  )}
                </ThreeDotButton>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CompositionsList;
