import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import moment from "moment";
import {
  fetchGetUsersToAssign,
  fetchGetUsersTypeToAssign,
  fetchPublishInfo,
  fetchPublishProcess,
  TValuesData,
} from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { getProjectSections } from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { getExecutorFullTypes } from "src/app/feature/project/projectProcessPublication/thunks";
import { useAppSelector } from "src/app/store";
import { getGroupPublishProcessData } from "src/app/feature/project/projectProcessPublication";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { ApiPrimaryProjectProcess } from "src/types/api/primaryTypes/apiPrimaryProjectProcess";
import { ProjectViewData } from "src/app/feature/ProjectView/createData/createData";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";
import { ModalSchema } from "../ModalSchema";
import { useDocsValue, useSecureValue } from "./useSecureAndDocsValue";
import { TermAndCost } from "./TermAndCost";
import { SecureAndDocs } from "./SecureAndDocs";
import { AdditionalRequirements } from "./AdditionalRequirements";
import { FormOfResponsibility } from "./FormOfResponsibility";
import { Executor } from "./Executor";
import { TermsPayment } from "./TermsPayment";
import styles from "./PublishModalContent.module.scss";

interface PublishModalContentProps {
  node: TValuesData | ApiPrimaryProjectProcess;
  project: ProjectViewData | ApiPrimaryProjectType;
  closeModal: () => void;
}

type Inputs = {
  price: number | null;
  secure: string;
  with_docs: string;
  type: TSelectItem[];
  date_start: Date | null;
  date_limit: Date | null;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  executor: TSelectItem | null;
  executorType: TSelectItem | null;
  pay1: number;
  pay2: number;
  pay3: number;
  saveStatus: number;
  paymentSum: number;
  project_id: string;
};

export const PublishModalContent = memo(
  ({ node, project, closeModal }: PublishModalContentProps) => {
    const dispatch = useDispatch();

    const {
      executors,
      isLoadExecutor,
      TypeExecutors,
      error422,
      publish,
      publishInfo,
      isLoadPublish,
      pending,
    } = useSelector(getProjectSections);

    const {
      data: { fullTypes },
    } = useAppSelector(getGroupPublishProcessData);

    const {
      id,
      haveExpertise,
      projectPart,
      price,
      secure,
      vor,
      vor_pnr,
      with_docs,
      date_limit,
      date_start,
      kac,
    } = node;
    const canAllCheck = vor && vor_pnr && kac;
    const additional =
      publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
    const defaultAgreementPrice = price === "Договорная";

    const [executorDiv, setExecutorDiv] = useState(false);
    const [payDiv, setPayDiv] = useState(false);
    const [docsValueList, setDocsValueList] = useState(["Нет", "Да"]);
    const [docsChangeValue, setDocsChangeValue] = useState(
      with_docs ? "Да" : "Нет"
    );

    const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
    const [checkVor, setCheckVor] = useState(vor);
    const [checkVorPnr, setCheckVorPnr] = useState(vor_pnr);
    const [checkKac, setCheckKac] = useState(kac);
    const [checkAll, setCheckAll] = useState(canAllCheck);
    const [firstRender, setFirstRender] = useState(true);

    const schema = ModalSchema(haveExpertise, with_docs, checkPrice);

    const isAddExecutorBtn = Object.keys(executors).length !== 0;

    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

    useEffect(() => {
      setFirstRender(false);
    }, []);

    const {
      register,
      handleSubmit,
      control,
      setValue,
      setError,
      watch,
      trigger,
      clearErrors,
      getValues,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        pay1: project.advance_pay,
        pay2: !haveExpertise ? project.pay2 + project.pay1 : project.pay1,
        pay3: project.pay2,
        price: defaultAgreementPrice ? null : +price,
        secure: secure ? "Да" : "Нет",
        with_docs: with_docs ? "Да" : "Нет",
        date_start: start,
        date_limit: end,
        vor,
        vor_pnr,
        kac,
        type: [],
      },
    });

    const getDateStart = getValues("date_start");
    const getDateEnd = getValues("date_limit");

    useEffect(() => {
      if (!payDiv) {
        setValue("pay1", project.advance_pay);
        setValue(
          "pay2",
          !haveExpertise ? project.pay2 + project.pay1 : project.pay1
        );
        setValue("pay3", project.pay2);
      }
    }, [payDiv]);

    useEffect(() => {
      // при изменении checkAll меняем состояние всех чекбоксов
      if (!firstRender) {
        setCheckVor(checkAll);
        setCheckVorPnr(checkAll);
        setCheckKac(checkAll);
      }
    }, [checkAll]);

    useEffect(() => {
      if (!checkVor && !checkVorPnr && !checkKac) {
        setCheckAll(false);
      }
      if (checkVor && checkVorPnr && checkKac) {
        setCheckAll(true);
      }
    }, [checkVor, checkVorPnr, checkKac]);

    const handleCheckAll = () => {
      setCheckAll(!checkAll);
    };
    const watchType = watch("type");
    const executorValue = watch("executor");
    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        agreementPrice: checkPrice,
        vor: !publishInfo.vor ? false : checkVor,
        vor_pnr: !publishInfo.vor_pnr ? false : checkVorPnr,
        kac: !publishInfo.kac ? false : checkKac,
        price: (!checkPrice && dataValues.price) || null,
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        with_docs: !publishInfo.canNoSecure ? true : docsChangeValue !== "Нет",
        secure: !publishInfo.canNoSecure ? true : dataValues.secure !== "Нет",
        type: watchType.map((item: TSelectItem) => item.id),
        executor_type: watchType.map((item: TSelectItem) => item.id),
        pay1: payDiv ? +dataValues.pay1 : null,
        pay2: payDiv ? +dataValues.pay2 : null,
        pay3: payDiv && haveExpertise ? +dataValues.pay3 : null,
        executorByAdminId:
          executorDiv && dataValues.executor?.id
            ? dataValues.executor.id
            : null,
        executorTypeByAdminId:
          executorDiv && dataValues.executorType?.id
            ? dataValues.executorType.id
            : null,
        save: dataValues.saveStatus,
      };
      await dispatch(fetchPublishProcess({ id, data }));
      if (error422) {
        await parseUnprocessableFields(error422, setError);
      }
    };

    const addExecutorClick = async () => {
      setExecutorDiv(!executorDiv);
    };

    const secureValue = watch("secure");

    useEffect(() => {
      if (secureValue === "Да") {
        setDocsChangeValue("Да");
      }
    }, [secureValue]);

    useEffect(() => {
      dispatch(fetchGetUsersToAssign(id));
      dispatch(fetchPublishInfo({ id }));

      // получение списка форм собственности
      dispatch(getExecutorFullTypes());
    }, []);

    useEffect(() => {
      if (publish) {
        clearErrors();
        closeModal();
      }
    }, [publish]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    // получение возможных значений для селекта docs and secure
    useSecureValue(secureValue, setDocsValueList);
    useDocsValue(docsChangeValue, publishInfo.canNoSecure, setPayDiv);

    const changeHandler = (data: (TSelectItem | undefined)[]) => {
      if (data.includes(undefined)) {
        setValue(
          "type",
          fullTypes.length === watchType.length ? [] : fullTypes
        );

        trigger("type");

        return;
      }

      setValue("type", data as TSelectItem[]);
      trigger("type");
    };

    // управление полями "назначенный исполнитель" и "Форма собственности"

    useEffect(() => {
      if (executorValue) {
        setValue("executorType", null);
        dispatch(
          fetchGetUsersTypeToAssign({
            id,
            executorValue: executorValue.id as number,
          })
        );
      }
    }, [executorValue, dispatch, id]);

    useEffect(() => {
      if (executors.length && !pending.executors) {
        setValue("executor", executors[0]);
      }
    }, [pending.executors]);

    useEffect(() => {
      if (TypeExecutors.length && !pending.TypeExecutors) {
        setValue("executorType", TypeExecutors[0]);
      }
    }, [pending.TypeExecutors]);

    if (isLoadExecutor || isLoadPublish) {
      return <NewModalPreloader />;
    }

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={styles.form}>
        <ScrollWrap height={"612px"} classname={styles.scroll}>
          <TermAndCost
            checkPrice={checkPrice}
            control={control}
            dateStart={getDateStart}
            dateEnd={getDateEnd}
            errors={errors}
            price={price}
            register={register}
            setCheckPrice={setCheckPrice}
          />
          {publishInfo.canNoSecure && (
            <SecureAndDocs
              control={control}
              secure={secure}
              docsChangeValue={docsChangeValue}
              docsValueList={docsValueList}
              setDocsChangeValue={setDocsChangeValue}
              errors={errors}
            />
          )}
          {additional && (
            <AdditionalRequirements
              publishInfo={publishInfo}
              register={register}
              checkVor={checkVor}
              setCheckVor={setCheckVor}
              errors={errors}
              checkVorPnr={checkVorPnr}
              setCheckVorPnr={setCheckVorPnr}
              handleCheckAll={handleCheckAll}
              checkAll={checkAll}
              canAllCheck={canAllCheck}
              checkKac={checkKac}
              setCheckKac={setCheckKac}
            />
          )}
          <FormOfResponsibility
            watchType={watchType}
            changeHandler={changeHandler}
            fullTypes={fullTypes}
            errors={errors?.type?.message}
          />
          {isAddExecutorBtn && (
            <Executor
              control={control}
              executors={executors}
              executorsDisabled={pending.executors}
              executorsType={TypeExecutors}
              executorsTypeDisabled={pending.TypeExecutors}
              addExecutorClick={addExecutorClick}
              executorDiv={executorDiv}
            />
          )}
        </ScrollWrap>
        <div className={styles.payBlock}>
          <TermsPayment
            projectPart={projectPart}
            register={register}
            project={project}
            errors={errors}
            haveExpertise={haveExpertise}
            payDiv={payDiv}
          />
          <input type="hidden" {...register("project_id")} />
          {errors.project_id && (
            <p className={styles.error}>{errors.project_id.message}</p>
          )}
          <div className={styles.form_submit}>
            <FormControl>
              <Button type="submit" onClick={() => setValue("saveStatus", 0)}>
                Опубликовать
              </Button>
            </FormControl>
            <FormControl>
              <Button
                variant="outlined"
                type="submit"
                className={styles.btnNoWrap}
                onClick={() => setValue("saveStatus", 1)}
              >
                Сохранить в черновике
              </Button>
            </FormControl>
          </div>
        </div>
      </form>
    );
  }
);
