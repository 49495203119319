import React, { memo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { ProjectProcessSourceDataChild } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { fetchTaskDataUpdate } from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { fetchProjectProcessSourceData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceData";
import {
  getProjectProcessInfoProjectId,
  getProjectProcessInfoProjectParentId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getCanProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { SourceDataView } from "src/FSD/widgets/v2/project/InitialData/SourceDataView/SourceDataView";

type SourceDataCardProps = ProjectProcessSourceDataChild & {
  isMobile?: boolean;
  parentId: number;
  index: number;
  type?: number;
  canAddFile: boolean;
};
export const SourceDataCard = memo(
  ({
    status,
    isMobile,
    checklist,
    date_deadline,
    date_create,
    critical,
    date_complete,
    can,
    task_id,
    id,
    parentId,
    index,
    type,
    canAddFile,
  }: SourceDataCardProps) => {
    const dispatch = useAppDispatch();
    const { isLoadDateUpdate } = useSelector(getProjectInitialData);
    const projectId = useAppSelector(getProjectProcessInfoProjectId);
    const projectParentId = useAppSelector(
      getProjectProcessInfoProjectParentId
    );
    const canProjectProcess = useAppSelector(getCanProcessInfo);

    const onChangeDate = async (data: any) => {
      const dataParams = {
        date_create: moment(data.startDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
        date_deadline: moment(data.endDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
      };
      await dispatch(fetchTaskDataUpdate({ id: task_id, dataParams }));
      if (projectId) {
        await dispatch(
          fetchProjectProcessSourceData({ projectId, parentId, index, type })
        );
      }
    };

    if (projectId) {
      return (
        <SourceDataView
          isMobile={isMobile}
          isLoadDateUpdate={isLoadDateUpdate}
          status={status}
          checklist={checklist}
          date_deadline={date_deadline}
          date_create={date_create}
          date_complete={date_complete}
          critical={critical}
          can={can}
          task_id={task_id}
          onChangeDate={onChangeDate}
          canUpdateSourceData={canProjectProcess?.updateSourceData || false}
          canAddFile={canAddFile}
          id={id}
          projectId={projectId}
          parentId={parentId}
          projectParentId={projectParentId}
          index={index}
          type={"process"}
        />
      );
    }
    return <></>;
  }
);
