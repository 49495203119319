import { AppUserTypes } from "../../../types/stateTypes/indexPages/appUserTypes";

// на localhost для того чтобы модалка
// Изменение политик и документов Платформы ПИРС не вылазала постоянно
// в agreement_personal записать строку с любой датой "11.11. 2021"
// а после вернуть null
// todo -> записать null в agreement_personal перед пушем изменений

export const AppUserInitialState: AppUserTypes = {
  agreement_personal: null,
};
