import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../../features";
import {
  fetchHeaderItems,
  selectorNavigation,
} from "../../../app/feature/header/header";

export const LoadDataIndexPage: React.FC<unknown> = ({ children }) => {
  const [isReady, setReady] = useState(false);
  const headerData = useSelector(selectorNavigation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (headerData.length > 0) {
      setReady(true);
    } else {
      dispatch(fetchHeaderItems());
    }
  }, [headerData, dispatch]);

  return <>{isReady ? <>{children}</> : <Preloader />}</>;
};
