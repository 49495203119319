import { useSelector } from "react-redux";
import { useEffect } from "react";
import { selectorAppTheme } from "../../app/feature/app/app";

export const useTheme = () => {
  const theme = useSelector(selectorAppTheme);

  useEffect(() => {
    const { body } = document;
    body.classList.remove("global--dark");
    body.classList.remove("global--old");
    if (theme === "dark") body.classList.add("global--dark");
    if (theme === "old") body.classList.add("global--old");
  }, [theme]);
};
