import {
  clearDestinations,
  getDashboardDestinations,
  setProjectId,
  setSectionId,
  setWorkId,
} from "../../../../app/feature/dashboard/destinations";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { clearTasksData } from "../../../../app/feature/dashboard/tasksAndWorks";
import { clearApplicationsData } from "../../../../app/feature/dashboard/applications";
import { clearSectionsData } from "../../../../app/feature/dashboard/sections";
import {
  clearProjectsData,
  getDashboardProjects,
} from "../../../../app/feature/dashboard/projects";
import useNonInitialEffect from "../../../hooks/useNonInitialEffect";
import { useIsMobile } from "../../../hooks/useIsMobile";

export default () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile(1100);

  const { fulfilled: projectsFulfilled } = useAppSelector(getDashboardProjects);
  const {
    tab,
    projectId,
    sectionId,
    showFulfilledSections,
    showFulfilledWorks,
  } = useAppSelector(getDashboardDestinations);

  useNonInitialEffect(() => {
    dispatch(clearTasksData());
    dispatch(clearApplicationsData());

    dispatch(setWorkId(0)); // под вопросом
  }, [projectId, sectionId, projectsFulfilled]);

  useNonInitialEffect(() => {
    dispatch(clearSectionsData());
  }, [projectId, projectsFulfilled]);

  useNonInitialEffect(() => {
    dispatch(setProjectId(0)); // под вопросом
  }, [projectsFulfilled]);

  useNonInitialEffect(() => {
    dispatch(setSectionId(0)); // под вопросом
  }, [showFulfilledSections]);

  useNonInitialEffect(() => {
    dispatch(setWorkId(0)); // под вопросом
  }, [showFulfilledWorks]);

  useNonInitialEffect(() => {
    if (!isMobile || tab !== "project->section") {
      return;
    }

    dispatch(clearSectionsData());
  }, [tab]);

  useNonInitialEffect(() => {
    return () => {
      sessionStorage.removeItem("userTypeId");
      dispatch(clearProjectsData());
      dispatch(clearTasksData());
      dispatch(clearApplicationsData());
      dispatch(clearSectionsData());
      dispatch(clearDestinations());
    };
  }, []);
};
