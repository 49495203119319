import { RootState } from "../../../../store";

export const getProjectProcessInfoData = (state: RootState) =>
  state.projectProcessView.info.infoData;

export const getProjectProcessInfoDateStart = (state: RootState) =>
  state.projectProcessView.info.infoData?.date_start;

export const getProjectProcessInfoDateLimit = (state: RootState) =>
  state.projectProcessView.info.infoData?.date_limit;
export const getProjectProcessInfoName = (state: RootState) =>
  state.projectProcessView.info.name;
export const getProjectProcessInfoId = (state: RootState) =>
  state.projectProcessView.info.id;
export const getProjectProcessInfoProjectId = (state: RootState) =>
  state.projectProcessView.info.projectId;

export const getProjectProcessInfoProject = (state: RootState) =>
  state.projectProcessView.info.project;
export const getProjectProcessInfoProjectParentId = (state: RootState) =>
  state.projectProcessView.info.projectParentId;
export const getProjectProcessInfoProjectName = (state: RootState) =>
  state.projectProcessView.info.infoData?.project.name;
export const getProjectProcessInfoProjectPartType = (state: RootState) =>
  state.projectProcessView.info.projectPartType;

export const getProjectProcessInfoPublished = (state: RootState) =>
  state.projectProcessView.info.published;
export const getProjectProcessInfoProjectChatId = (state: RootState) =>
  state.projectProcessView.info.projectChatId;
export const getProjectProcessInfoActiveAccessId = (state: RootState) =>
  state.projectProcessView.info.activeAccessId;
export const getProjectProcessInfoStatus = (state: RootState) =>
  state.projectProcessView.info.status;

export const getProjectProcessInfoisLoad = (state: RootState) =>
  state.projectProcessView.info.isLoad;

export const getProjectProcessFiles = (state: RootState) =>
  state.projectProcessView.info.files;

export const getProjectProcessIsPause = (state: RootState) =>
  state.projectProcessView.info.paused;

export const getProjectProcessIsStopped = (state: RootState) =>
  state.projectProcessView.info.stopped;

export const getProjectProcessPrice = (state: RootState) =>
  state.projectProcessView.info.price;

export const getProjectProcessDateStart = (state: RootState) =>
  state.projectProcessView.info.dateStart;

export const getProjectProcessDateLimit = (state: RootState) =>
  state.projectProcessView.info.dateLimit;

export const getProjectProcessExecutorTypeForm = (state: RootState) =>
  state.projectProcessView.info.executorTypeForm;

export const getProjectProcessPriceUpdateIsLoad = (state: RootState) =>
  state.projectProcessView.info.isLoadPriceUpdate;

export const getProjectProcessInfoPayBlockIsLoad = (state: RootState) =>
  state.projectProcessView.info.isLoadInfoPayBlock;
