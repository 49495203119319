import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import toggleElementInArray from "src/utils/toggleElementInArray";
import {
  getAvailableProcesses,
  getBfList,
  getFirmMilestones,
  addProcess,
  removeProcess,
  changeActive,
  changeActiveMilestone,
  deleteMilestone,
  addMilestone,
  getGroupControl,
  createGroup,
  deleteGroup,
  deleteLink,
  editLinks,
  getClassList,
  getMethodList,
  saveAnchor,
  deleteAnchor,
  getAnchorParams,
} from "./thunks";
import {
  createList,
  createMilestones,
  createOpenGroupIds,
  prepareListWithHints,
} from "./utils";

export type TSelectItemWithLink = TSelectItem & { linkId: number };

export type TStage = {
  id: number;
  num: number;
  name: string;
  active: boolean;
  firm_milestone_id: number;
};

export type TFirmMilestone = {
  id: number;
  partId: number;
  active: boolean;
  name: string;
  stages: TStage[];
};

export type TGroup = any; // релизовать

export type TFirmMilestonesList = Record<string, TFirmMilestone[]>;

export type TProcess = {
  id: number;
  name: string;
};

export type TLink = {
  partId: number;
  linkId: number;
};

export type TState = {
  data: {
    bfList: TSelectItem[];
    buildingFunction: TSelectItem | null;
    availableProcesses: TSelectItem[];
    availableProcess: TSelectItem | null;
    firmMilestones: TFirmMilestonesList;
    processes: TProcess[];
    groups: TGroup[];
    openGroups: number[];
    openMilestones: number[];
    groupSum: Record<string, number>;
    classList: Record<string, any>[];
    methodList: Record<string, any>[];
    showAnchorModal: boolean;
    toggledForms: number[];
    params: Record<string, any>;
  };
  pending: {
    addProcess: boolean;
    bfList: boolean;
    availableProcesses: boolean;
    firmMilestones: boolean;
    changeActive: boolean;
    groups: boolean;
    createGroup: number | null;
    deleteGroup: number | null;
    deleteLink: number | null;
    editLinks: boolean;
    getClassList: boolean;
    getMethodList: boolean;
    anchorModify: boolean;
    anchorDelete: boolean;
    getParams: boolean;
  };
  error: {
    bfList: string | null;
    availableProcesses: string | null;
    firmMilestones: string | null;
    groups: string | null;
  };
};

const defaultSelectValue = {
  id: 0,
  title: "",
};

const initialState: TState = {
  data: {
    bfList: [],
    buildingFunction: defaultSelectValue,
    availableProcesses: [],
    availableProcess: null,
    firmMilestones: {},
    processes: [],
    groups: [],
    openGroups: [],
    openMilestones: [],
    groupSum: {},
    classList: [],
    methodList: [],
    toggledForms: [],
    showAnchorModal: false,
    params: {},
  },
  pending: {
    addProcess: false,
    bfList: false,
    availableProcesses: false,
    firmMilestones: false,
    changeActive: false,
    groups: false,
    createGroup: null,
    deleteGroup: null,
    deleteLink: null,
    editLinks: false,
    getClassList: false,
    getMethodList: false,
    anchorModify: false,
    anchorDelete: false,
    getParams: false,
  },
  error: {
    bfList: null,
    availableProcesses: null,
    firmMilestones: null,
    groups: null,
  },
};

const milestone = createSlice({
  name: "milestone",
  initialState,
  reducers: {
    setToggledForms(state, action) {
      state.data.toggledForms = action.payload;
    },
    setBuildingFunction(state, action) {
      state.data.buildingFunction = action.payload;
    },
    setAvailvableProcess(state, action) {
      state.data.availableProcess = action.payload;
    },
    setStages(state, action) {
      const { items, bfType, processId } = action.payload;

      state.data.firmMilestones[bfType].find(
        (process: TFirmMilestone) => process.id === processId
      )!.stages = items;
    },
    setOpenGroups(state, action) {
      const id = action.payload;

      state.data.openGroups = toggleElementInArray(state.data.openGroups, id);
    },
    setOpenMilestones(state, action) {
      const id = action.payload;

      state.data.openMilestones = toggleElementInArray(
        state.data.openMilestones,
        id
      );
    },
    setGroupSum(state, action) {
      const { total, id } = action.payload;
      state.data.groupSum[id] = total;
    },
    setShowAnchorModal(state, action) {
      state.data.showAnchorModal = action.payload;
    },
    clearParams(state) {
      state.data.params = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getBfList), (state) => {
        state.pending.bfList = true;
      })
      .addMatcher(isFulfilled(getBfList), (state, action) => {
        const data = action.payload;
        const id = Number(Object.keys(data).filter((key: string) => key)[0]);

        Object.keys(data).forEach((key: string) => {
          if (!key || state.data.firmMilestones[key]) {
            return;
          }

          state.data.firmMilestones[key] = [];
        });

        state.data.bfList = createList(data);
        state.data.buildingFunction = {
          id,
          title: data[id],
        };

        state.pending.bfList = false;
      })
      .addMatcher(isRejected(getBfList), (state, action) => {
        state.pending.bfList = false;
        state.error.bfList = action.payload as string;
      })
      .addMatcher(isPending(getAvailableProcesses), (state) => {
        state.pending.availableProcesses = true;
      })
      .addMatcher(isFulfilled(getAvailableProcesses), (state, action) => {
        state.data.availableProcesses = createList(action.payload);
        state.pending.availableProcesses = false;
      })
      .addMatcher(isRejected(getAvailableProcesses), (state, action) => {
        state.pending.availableProcesses = false;
        state.error.availableProcesses = action.payload as string;
      })
      .addMatcher(isPending(getClassList), (state) => {
        state.pending.getClassList = true;
      })
      .addMatcher(isFulfilled(getClassList), (state, action) => {
        state.data.classList = prepareListWithHints(action.payload);
        state.pending.getClassList = false;
      })
      .addMatcher(isPending(getMethodList), (state) => {
        state.pending.getMethodList = true;
      })
      .addMatcher(isFulfilled(getMethodList), (state, action) => {
        state.data.methodList = prepareListWithHints(action.payload);
        state.pending.getMethodList = false;
      })
      .addMatcher(isPending(getFirmMilestones), (state) => {
        state.pending.firmMilestones = true;
      })
      .addMatcher(isFulfilled(getFirmMilestones), (state, action) => {
        const additionalMilestones = createMilestones(action.payload);

        state.data.firmMilestones = {
          ...state.data.firmMilestones,
          ...additionalMilestones,
        };
        state.pending.firmMilestones = false;
      })
      .addMatcher(isRejected(getFirmMilestones), (state, action) => {
        state.pending.firmMilestones = false;
        state.error.firmMilestones = action.payload as string;
      }) // все что ниже раскидать по функциям
      .addMatcher(isFulfilled(removeProcess), (state, action) => {
        const {
          bfType,
          process: { id, partId, name: title },
        } = action.meta.arg;

        state.pending.addProcess = false;

        state.data.firmMilestones[bfType] = state.data.firmMilestones[
          bfType
        ].filter((process) => process.id !== id);

        state.data.availableProcess = null;
        state.data.availableProcesses.push({ id: partId, title });
      })
      .addMatcher(
        isPending(addProcess, removeProcess, addMilestone, deleteMilestone),
        (state) => {
          state.pending.addProcess = true;
        }
      )
      .addMatcher(
        isRejected(addProcess, removeProcess, addMilestone, deleteMilestone),
        (state) => {
          state.pending.addProcess = false;
        }
      )
      .addMatcher(isFulfilled(addProcess), (state, action) => {
        const { name, payload, bfType } = action.meta.arg;
        const { project_part_id } = payload;

        state.pending.addProcess = false;

        const id = action.payload;

        const milestone = {
          id,
          name,
          partId: project_part_id,
          active: true,
          stages: [],
        };

        state.data.availableProcess = null;
        state.data.availableProcesses = state.data.availableProcesses.filter(
          (process) => process.id !== project_part_id
        );

        state.data.firmMilestones[bfType].push(milestone);
      })
      .addMatcher(isPending(changeActive, changeActiveMilestone), (state) => {
        state.pending.changeActive = true;
      })
      .addMatcher(isFulfilled(changeActive), (state, action) => {
        const { bfType, id, active } = action.meta.arg;

        state.pending.changeActive = false;
        state.data.firmMilestones[bfType] = state.data.firmMilestones[
          bfType
        ].map((process) =>
          process.id === id ? { ...process, active } : process
        );
      })
      .addMatcher(isFulfilled(changeActiveMilestone), (state, action) => {
        const { bfType, id, processId, active } = action.meta.arg;

        // вынести
        state.pending.changeActive = false;
        state.data.firmMilestones[bfType].find(
          (process) => process.id === processId
        )!.stages = state.data.firmMilestones[bfType]
          .find((process) => process.id === processId)!
          .stages.map((stage: TStage) =>
            stage.id === id ? { ...stage, active } : stage
          );
      })
      .addMatcher(isFulfilled(deleteMilestone), (state, action) => {
        const { bfType, id, processId } = action.meta.arg;

        state.pending.addProcess = false;

        // вынести
        state.data.firmMilestones[bfType].find(
          (process) => process.id === processId
        )!.stages = state.data.firmMilestones[bfType]
          .find((process) => process.id === processId)!
          .stages.filter((stage: TStage) => stage.id !== id);
      })
      .addMatcher(isFulfilled(addMilestone), (state, action) => {
        const { bfType, processId, name } = action.meta.arg;
        const id = action.payload;

        state.pending.addProcess = false;

        const { stages } = state.data.firmMilestones[bfType].find(
          (process) => process.id === processId
        )!;

        const nextNum =
          stages.reduce(
            (maxValue, stage: TStage) => Math.max(maxValue, stage.num),
            0
          ) + 1;

        const newStage: TStage = {
          id,
          firm_milestone_id: processId,
          num: nextNum,
          active: true,
          name,
        };

        state.data.firmMilestones[bfType]
          .find((process) => process.id === processId)!
          .stages.push(newStage);
      })
      .addMatcher(isPending(getGroupControl), (state) => {
        state.pending.groups = true;
      })
      .addMatcher(isFulfilled(getGroupControl), (state, action) => {
        state.pending.groups = false;
        state.data.groups = action.payload; // возможно нужна обертка
        state.data.openGroups = createOpenGroupIds(action.payload);
      })
      .addMatcher(isPending(createGroup), (state, action) => {
        const { partType } = action.meta.arg;
        state.pending.createGroup = partType;
      })
      .addMatcher(isFulfilled(createGroup), (state, action) => {
        const { partType, groupId } = action.meta.arg;
        const { data, id } = action.payload;
        const stageGroupPercents = Object.values(data.stageGroupPercents);

        state.pending.createGroup = null;
        state.data.groups.find((item) => item.group.id === groupId).types[
          partType
        ].groups[id] = { id, stageGroupPercents, parts: [] };
      })
      .addMatcher(isRejected(createGroup), (state) => {
        state.pending.createGroup = null;
      })
      .addMatcher(isPending(deleteGroup), (state, action) => {
        const { id } = action.meta.arg;
        state.pending.deleteGroup = id;
      })
      .addMatcher(isFulfilled(deleteGroup), (state, action) => {
        const { id, groupId, partType } = action.meta.arg;

        state.pending.deleteGroup = null;

        delete state.data.groups.find((item) => item.group.id === groupId)
          .types[partType].groups[id];
      })
      .addMatcher(isRejected(deleteGroup), (state) => {
        state.pending.createGroup = null;
      })
      .addMatcher(isPending(deleteLink), (state, action) => {
        const {
          link: { linkId },
        } = action.meta.arg;
        state.pending.deleteLink = linkId;
      })
      .addMatcher(isFulfilled(deleteLink), (state, action) => {
        state.pending.deleteLink = null;

        const { id, partType, groupId, link } = action.meta.arg;

        const filteredParts = state.data.groups
          .find((item) => item.group.id === groupId)
          .types[
            partType
          ].groups[id].parts.filter((item: any) => item.linkId !== link.linkId);

        state.data.groups.find((item) => item.group.id === groupId).types[
          partType
        ].groups[id].parts = filteredParts;

        state.data.groups
          .find((item) => item.group.id === groupId)
          .types[partType].parts.push(link);
      })
      .addMatcher(isRejected(deleteLink), (state) => {
        state.pending.deleteLink = null;
      })
      .addMatcher(isPending(editLinks), (state) => {
        state.pending.editLinks = true;
      })
      .addMatcher(isFulfilled(editLinks), (state, action) => {
        const linkIds = action.payload;

        const { id, partType, groupId, data, options } = action.meta.arg;
        state.pending.editLinks = false;

        const dataIds = data.map((item: TSelectItem) => item.id);
        const preparedData = data.map(({ id, title: code }: TSelectItem) => {
          const { linkId } = linkIds.find((item: any) => item.partId === id);
          return { id, code, linkId };
        });

        // изменения в группе
        state.data.groups.find((item) => item.group.id === groupId).types[
          partType
        ].groups[id].parts = preparedData;

        // изменения вверху
        // из объединения groupParts и parts вычитаем data
        const filteredParts = options
          .filter((item: TSelectItem) => !dataIds.includes(item.id))
          .map(({ id, title: code }: TSelectItem) => ({
            id,
            code,
          }));

        state.data.groups.find((item) => item.group.id === groupId).types[
          partType
        ].parts = filteredParts;
      })
      .addMatcher(isRejected(editLinks), (state) => {
        state.pending.editLinks = false;
      })
      .addMatcher(isPending(saveAnchor), (state) => {
        state.pending.anchorModify = true;
      })
      .addMatcher(isFulfilled(saveAnchor), (state) => {
        state.pending.anchorModify = false;
        state.data.showAnchorModal = false;
      })
      .addMatcher(isRejected(saveAnchor), (state) => {
        state.pending.anchorModify = false;
      })
      .addMatcher(isPending(deleteAnchor), (state) => {
        state.pending.anchorDelete = true;
      })
      .addMatcher(isFulfilled(deleteAnchor), (state) => {
        state.pending.anchorDelete = false;
        state.data.showAnchorModal = false;
      })
      .addMatcher(isRejected(deleteAnchor), (state) => {
        state.pending.anchorDelete = false;
      })
      .addMatcher(isPending(getAnchorParams), (state) => {
        state.pending.getParams = true;
      })
      .addMatcher(isFulfilled(getAnchorParams), (state, action) => {
        state.pending.getParams = false;
        state.data.params = action.payload;
      })
      .addMatcher(isRejected(getAnchorParams), (state) => {
        state.pending.getParams = false;
      });
  },
});

export const {
  setBuildingFunction,
  setAvailvableProcess,
  setStages,
  setOpenGroups,
  setOpenMilestones,
  setGroupSum,
  setShowAnchorModal,
  clearParams,
  setToggledForms,
} = milestone.actions;

export const getMilestone = (state: CombinedState<any>) => state.milestone;

export default milestone.reducer;
