import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type OrganizationLabelProps = ComponentPropsType<{
  title: string;
  subTitle?: string | JSX.Element;
  icon?: JSX.Element;
  additionalInfo?: JSX.Element;
  isActive?: boolean;
  notHover?: boolean;
  clickHandler?: VoidFunction;
}>;

export const OrganizationLabel: React.FC<OrganizationLabelProps> = React.memo(
  ({
    title,
    isActive = false,
    icon,
    clickHandler = () => {},
    additionalInfo,
    variant = "",
  }) => {
    const activeClass = isActive && style.active;
    return (
      <div
        onClick={clickHandler}
        className={classNames(style.label, style.old, activeClass, variant)}
      >
        <div className={style.left}>
          {icon && <div className={style.icon}>{icon}</div>}
          <div className={style.textBox}>
            <p className={classNames(style.title, "title")}>
              {ReactHtmlParser(title)}
            </p>
          </div>
        </div>
        {additionalInfo && (
          <div className={style.additional}>{additionalInfo}</div>
        )}
      </div>
    );
  }
);
