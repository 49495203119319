import { AppErrorType } from "../feature/appError/appError";

export class RequestError extends Error {
  public status: number;

  public message: string;

  public name: string;

  public code: number;

  constructor(props: AppErrorType) {
    super(props.message);
    this.message = props.message;
    this.status = props.status;
    this.code = props.code;
    this.name = props.name;
  }
}
