import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  fetchProjectUsersList,
  fetchProjectUsersType,
  fetchProjectUsersUpdate,
  getAccessCodesByProject,
  getUsersToAssign,
  getFirmsInProjects,
  getAvailableUsersOnProject,
  getProjectAccessByProject,
  getMyProjects,
  createProjectAccess,
} from "./thunks";
import { toSelect } from "../../project/projectProcessPublication";
import { TSelectItem } from "../../../../components/features/Forms/SetGipForm/types";
import { getUserTypes } from "../../dashboard/header/thunks";

export type TRolesModal = {
  user: TSelectItem | null;
  userType: TSelectItem | null;
  role: TSelectItem | null;
  firm: TSelectItem | null;
  project: TSelectItem | null;
};

export type TRolesPending = {
  myProjects: boolean;
  usersToAssign: boolean;
  accessCodes: boolean;
  firmsInProject: boolean;
  availableUsersOnProject: boolean;
  projectAccessByProject: boolean;
  createProjectAccess: boolean;
  userTypes: boolean;
};

type InitialState = {
  isLoadUsersList: boolean;
  isLoadUsersType: boolean;
  isLoadUpdate: boolean;
  newUsersList: { id: string; title: string }[];
  usersType: [string, string][];
  updateUsers: boolean;
  myProjects: TSelectItem[];
  usersToAssign: TSelectItem[];
  accessCodes: TSelectItem[];
  firmsInProject: TSelectItem[];
  userTypes: TSelectItem[];
  availableUsersOnProject: TSelectItem[];
  projectAccessByProject: Record<string, any>;
  pending: TRolesPending;
  defaultValues: TRolesModal;
  error: any;
};

const initialState: InitialState = {
  isLoadUsersList: false,
  isLoadUsersType: false,
  isLoadUpdate: false,
  updateUsers: false,
  newUsersList: [],
  usersType: [],
  myProjects: [],
  usersToAssign: [],
  accessCodes: [],
  firmsInProject: [],
  availableUsersOnProject: [],
  projectAccessByProject: [],
  userTypes: [],
  pending: {
    myProjects: false,
    usersToAssign: false,
    accessCodes: false,
    firmsInProject: false,
    availableUsersOnProject: false,
    projectAccessByProject: false,
    createProjectAccess: false,
    userTypes: false,
  },
  defaultValues: {
    user: null,
    userType: null,
    role: null,
    firm: null,
    project: null,
  },
  error: null,
};

const projectUsersList = createSlice({
  name: "projectUsersList",
  initialState,
  reducers: {
    setUpdateUsers(state) {
      state.updateUsers = false;
    },
    clearAddRightsData(state) {
      state.myProjects = [];
      state.usersToAssign = [];
      state.accessCodes = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectUsersList.pending, (state) => {
      state.error = null;
      state.isLoadUsersList = true;
    });
    builder.addCase(fetchProjectUsersList.fulfilled, (state, action) => {
      state.isLoadUsersList = false;
      state.newUsersList = Object.keys(action.payload).map((id: any) => ({
        id,
        title: action.payload[id],
      }));
      state.updateUsers = false;
    });
    builder.addCase(fetchProjectUsersList.rejected, (state, action) => {
      state.isLoadUsersList = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectUsersType.pending, (state) => {
      state.error = null;
      state.isLoadUsersType = true;
    });
    builder.addCase(fetchProjectUsersType.fulfilled, (state, action) => {
      state.isLoadUsersType = false;
      state.usersType = Object.entries(action.payload);
    });
    builder.addCase(fetchProjectUsersType.rejected, (state, action) => {
      state.isLoadUsersType = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectUsersUpdate.pending, (state) => {
      state.error = null;
      state.updateUsers = false;
      state.isLoadUpdate = true;
    });
    builder.addCase(fetchProjectUsersUpdate.fulfilled, (state) => {
      state.updateUsers = true;
      state.isLoadUpdate = false;
    });
    builder.addCase(fetchProjectUsersUpdate.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoadUpdate = false;
    });

    // список типов прав
    builder.addCase(getAccessCodesByProject.pending, (state) => {
      state.error = null;
      state.pending.accessCodes = true;
    });
    builder.addCase(getAccessCodesByProject.fulfilled, (state, action) => {
      state.pending.accessCodes = false;
      const accessCodes = toSelect(action.payload);

      state.accessCodes = accessCodes;
      /*
      if (accessCodes.length) {
        [state.defaultValues.role] = accessCodes;
      }
      */
    });
    builder.addCase(getAccessCodesByProject.rejected, (state, action) => {
      state.pending.accessCodes = false;
      state.error = action.payload;
    });

    // список ф.с.
    builder.addCase(getMyProjects.pending, (state) => {
      state.error = null;
      state.pending.myProjects = true;
    });
    builder.addCase(getMyProjects.fulfilled, (state, action) => {
      state.pending.myProjects = false;

      const myProjects = action.payload.map(({ id, name: title }: any) => ({
        id,
        title,
      }));

      state.myProjects = myProjects;
      /*
      if (myProjects.length) {
        [state.defaultValues.project] = myProjects;
      }
      */
    });
    builder.addCase(getMyProjects.rejected, (state, action) => {
      state.pending.myProjects = false;
      state.error = action.payload;
    });

    // пользователи, которых можно назначить
    builder.addCase(getUsersToAssign.pending, (state) => {
      state.error = null;
      state.pending.usersToAssign = true;
    });
    builder.addCase(getUsersToAssign.fulfilled, (state, action) => {
      state.pending.usersToAssign = false;

      const usersToAssign = toSelect(action.payload);

      state.usersToAssign = usersToAssign;
      /*
      if (usersToAssign.length) {
        [state.defaultValues.user] = usersToAssign;
      }
      */
    });
    builder.addCase(getUsersToAssign.rejected, (state, action) => {
      state.pending.usersToAssign = false;
      state.error = action.payload;
    });

    // организации
    builder.addCase(getFirmsInProjects.pending, (state) => {
      state.error = null;
      state.pending.firmsInProject = true;
    });
    builder.addCase(getFirmsInProjects.fulfilled, (state, action) => {
      state.pending.firmsInProject = false;

      const firmsInProject = action.payload.map(({ id, title }: any) => ({
        id,
        title,
      }));

      state.firmsInProject = firmsInProject;
      /*
      if (firmsInProject.length) {
        [state.defaultValues.firm] = firmsInProject;
      }
      */
    });
    builder.addCase(getFirmsInProjects.rejected, (state, action) => {
      state.pending.firmsInProject = false;
      state.error = action.payload;
    });

    // список пользователь кого можно добавить
    builder.addCase(getAvailableUsersOnProject.pending, (state) => {
      state.error = null;
      state.pending.availableUsersOnProject = true;
    });
    builder.addCase(getAvailableUsersOnProject.fulfilled, (state, action) => {
      state.pending.availableUsersOnProject = false;

      const availableUsersOnProject = toSelect(action.payload);

      state.availableUsersOnProject = availableUsersOnProject;
      /*
      if (availableUsersOnProject.length) {
        [state.defaultValues.user] = availableUsersOnProject;
      }
      */
    });
    builder.addCase(getAvailableUsersOnProject.rejected, (state, action) => {
      state.pending.firmsInProject = false;
      state.error = action.payload;
    });

    // права доступа на проект
    builder.addCase(getProjectAccessByProject.pending, (state) => {
      state.error = null;
      state.pending.projectAccessByProject = true;
    });
    builder.addCase(getProjectAccessByProject.fulfilled, (state, action) => {
      state.pending.projectAccessByProject = false;
      state.projectAccessByProject = action.payload;
    });
    builder.addCase(getProjectAccessByProject.rejected, (state, action) => {
      state.pending.projectAccessByProject = false;
      state.error = action.payload;
    });

    // приглашение участника на проекты
    builder.addCase(createProjectAccess.pending, (state) => {
      state.error = null;
      state.pending.createProjectAccess = true;
    });
    builder.addCase(createProjectAccess.fulfilled, (state) => {
      state.pending.createProjectAccess = false;
    });
    builder.addCase(createProjectAccess.rejected, (state, action) => {
      state.pending.createProjectAccess = false;
      state.error = action.payload;
    });

    // получение списка форм собственности
    builder.addCase(getUserTypes.pending, (state) => {
      state.error = null;
      state.pending.userTypes = true;
    });
    builder.addCase(getUserTypes.fulfilled, (state, action) => {
      state.pending.userTypes = false;

      const userTypes = action.payload.userTypes.map(
        ({ id, listName: title }: any) => ({ id, title })
      );

      state.userTypes = userTypes;
      /*
      if (userTypes.length) {
        [state.defaultValues.userType] = userTypes;
      }
      */
    });
    builder.addCase(getUserTypes.rejected, (state, action) => {
      state.pending.userTypes = false;
      state.error = action.payload;
    });
  },
});
export const { setUpdateUsers, clearAddRightsData } = projectUsersList.actions;
export const getProjectUsersList = (state: RootState) => state.projectUsersList;
export default projectUsersList.reducer;
