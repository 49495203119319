import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { ChecklistProjectData, fetchProjectCheckListTree } from "./thunks";
import {
  collectNums,
  setAllToggleNode,
  setToggleNode,
} from "../../../../utils/helpersOpenNode";

type InitialState = {
  isLoadCheckList: boolean;
  checklistTree: ChecklistProjectData[] | null;
  error: any;
  isAllToggle: boolean;
  openNode: any;
  openNodeAll: boolean;
};

const initialState: InitialState = {
  isLoadCheckList: false,
  checklistTree: null,
  error: null,
  isAllToggle: false,
  openNode: {},
  openNodeAll: false,
};

const projectCheckListTree = createSlice({
  name: "projectCheckListTree",
  initialState,
  reducers: {
    setError(state) {
      state.error = false;
    },
    setOpenNode: (state, action) => {
      setToggleNode(state.openNode, action.payload);
    },
    setOpenAllNode: (state) => {
      setAllToggleNode(state);
    },
    setAllToggle(state) {
      state.isAllToggle = !state.isAllToggle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCheckListTree.pending, (state) => {
      state.isLoadCheckList = true;
    });
    builder.addCase(fetchProjectCheckListTree.fulfilled, (state, action) => {
      state.isLoadCheckList = false;
      state.checklistTree = Object.values(action.payload);
      state.openNode = collectNums(action.payload);
    });
    builder.addCase(fetchProjectCheckListTree.rejected, (state, action) => {
      state.isLoadCheckList = false;
      state.error = action.payload;
    });
  },
});
export const { setError, setAllToggle, setOpenAllNode, setOpenNode } =
  projectCheckListTree.actions;
export const getProjectCheckList = (state: RootState) => state.projectCheckList;
export default projectCheckListTree.reducer;
