import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import styles from "../Sections.module.scss";
import { config } from "../../../../../../app.cofig";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import Modal from "../../../../../newUI/Modal/Modal";
import { PublishModalContent } from "../PublishModalContent/PublishModalContent";
import {
  fetchProjectSectionsTreeDuplicate,
  fetchUnPublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { UpdatePublishModalContent } from "../UpdatePublishModalContent/UpdatePublishModalContent";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { ModalBackToWork } from "../ModalBackToWork/ModalBackToWork";
import {
  setTouched,
  setOpen as setOpenGroupProcessPublishing,
} from "../../../../../../app/feature/project/projectProcessPublication";
import { setOpen as setOpenGroupProcessRequest } from "../../../../../../app/feature/project/projectProcessRequest";
import { NewModal } from "../../../../../newUI/NewModal/NewModal";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";

interface ThreeDotButtonsProps {
  node: TValuesData;
}

export const ThreeDotButtons = ({ node }: ThreeDotButtonsProps) => {
  const dispatch = useDispatch();
  const isTablet = useIsMobile(1600);
  const { project } = useSelector(getProjectViewData);
  const threeDotButtonRef = useRef<RefType>(null);
  const { isLoadPublish, isLoadUpdatePublish } =
    useSelector(getProjectSections);
  const [publishModal, setPublishModal] = useState(false);
  const [backToWorkModal, setBackToWorkModal] = useState(false);
  const [updatePublishModal, setUpdatePublishModal] = useState(false);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const memoCloseModal = useCallback(() => {
    setPublishModal(false);
    setUpdatePublishModal(false);
    setBackToWorkModal(false);
    if (project) {
      dispatch(fetchProjectSectionsTreeDuplicate(+project.id));
    }
  }, []);

  const handleOpenPublishProcess = () => {
    dispatch(setOpenGroupProcessPublishing(node));
  };

  const handleOpenRequestProcess = () => {
    dispatch(setOpenGroupProcessRequest(node));
  };

  const handleClose = () => {
    closeThreeDot();
    dispatch(setTouched(false)); // для групповой публикации
  };

  const handleNavigate = () => {
    window.open(`${config.localDomain}/project-process/${node.id}`, "_blank");
  };

  const unPublishClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );
    if (result) {
      await dispatch(fetchUnPublishProcess(node.id));
      await dispatch(fetchProjectSectionsTreeDuplicate(node.project_id));
    }
  };

  const canPublishGroup = Boolean(node.partGroup);

  const canRequestGroup = Boolean(node.partGroup) && node.can?.requestExecution;

  const canPublishProjectPublished =
    node.can?.publish && node.projectPart.published;

  return (
    <div
      className={classNames(styles.btnFlex, isTablet && styles.btnFlex_tablet)}
    >
      {project && canPublishProjectPublished && (
        <NewModal
          active={publishModal}
          setActive={setPublishModal}
          isLoad={isLoadPublish}
          title={`Публикация раздела «${node.projectPart.name}» по проекту «${project?.name}»`}
          activeClass={styles.modal}
        >
          <PublishModalContent
            node={node}
            project={project}
            closeModal={memoCloseModal}
          />
        </NewModal>
      )}
      {project && node.can.publish && !node.projectPart.published && (
        <Modal
          active={backToWorkModal}
          setActive={setBackToWorkModal}
          activeClass={styles.overflowModal}
        >
          <ModalBackToWork
            node={node}
            project={project}
            closeModal={memoCloseModal}
          />
        </Modal>
      )}
      {project && node.can.unpublish && (
        <NewModal
          active={updatePublishModal}
          setActive={setUpdatePublishModal}
          isLoad={isLoadUpdatePublish}
          title={`Редактирование раздела «${node.projectPart.name}» по проекту «${project?.name}»`}
          activeClass={styles.modal}
        >
          <UpdatePublishModalContent
            node={node}
            project={project}
            closeModal={memoCloseModal}
          />
        </NewModal>
      )}
      <Button
        className={classNames(isTablet && styles.btn)}
        onClick={handleNavigate}
      >
        Открыть
      </Button>
      <ThreeDotButton ref={threeDotButtonRef}>
        <MenuItem
          onClick={() => {
            handleNavigate();
            handleClose();
          }}
          disableRipple
        >
          Перейти к разделу
        </MenuItem>
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              setUpdatePublishModal(true);
              handleClose();
            }}
            disableRipple
          >
            Редактировать
          </MenuItem>
        )}
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              unPublishClick();
              handleClose();
            }}
            disableRipple
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {canPublishProjectPublished && (
          <MenuItem
            onClick={() => {
              setPublishModal(true);
              handleClose();
            }}
            disableRipple
          >
            Опубликовать
          </MenuItem>
        )}
        {canPublishProjectPublished && canPublishGroup && (
          <MenuItem
            onClick={() => {
              handleOpenPublishProcess();
              handleClose();
            }}
            disableRipple
          >
            Опубликовать группу
          </MenuItem>
        )}
        {canRequestGroup && (
          <MenuItem
            onClick={() => {
              handleOpenRequestProcess();
              handleClose();
            }}
            disableRipple
          >
            Заявиться на группу
          </MenuItem>
        )}
        {node.can?.publish &&
          !node.projectPart.published &&
          node.can?.expertiseTakeToWork && (
            <MenuItem
              onClick={() => {
                setBackToWorkModal(true);
                handleClose();
              }}
              disableRipple
            >
              Взять в работу
            </MenuItem>
          )}
      </ThreeDotButton>
    </div>
  );
};
