import { chatType } from "../../../types/stateTypes/chatType";

export const chatInitialState: chatType = {
  rooms: [],
  updDate: new Date(),
  messages: [],
  userAction: {
    inRoom: false,
  },
  userData: {
    avatarUrl: "",
    id: 0,
    name: "",
  },
  chatActiveRoom: {
    id: 0,
    avatarUrl: "",
    userName: "",
  },
  inRoom: false,
  filteredContacts: {
    items: [],
    value: "",
  },
  openRooms: [], // id групп, что были открыты
};
