import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiGetExecutorBankAccount } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessBank = createAsyncThunk<any, number>(
  "projectProcess/fetchProjectProcessBank",
  async (userTypeId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiGetExecutorBankAccount(userTypeId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
