import React, { useCallback, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { RefType, ThreeDotButton } from "../../ThreeDotButton/ThreeDotButton";
import cls from "./Version.module.scss";
import { VersionTaskType } from "../../../../app/feature/ProjectView/TaskReducer/types";
import {
  fetchAcceptTask,
  fetchBackToWork,
  fetchNotAcceptTask,
  fetchProjectTaskAll,
} from "../../../../app/feature/ProjectView/TaskReducer/thunks";
import {
  fetchProjectProcessInTasks,
  fetchProjectProcessOutTasks,
} from "../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import { useAppDispatch } from "../../../../app/store";
import { ModalComplete } from "../../../pages/Project/NewView/Tasks/ModalComplete/ModalComplete";
import { NewModal } from "../../NewModal/NewModal";
import { ModalNotAccept } from "../../../pages/Project/NewView/Tasks/ModalNotAccept/ModalNotAccept";

interface VersionThreeDotProps {
  task: VersionTaskType;
  projectId?: number;
  processId?: number;
  type?: "project" | "process";
}
export const VersionThreeDot = ({
  task,
  projectId,
  processId,
  type,
}: VersionThreeDotProps) => {
  const dispatch = useAppDispatch();
  const threeDotButtonRef = useRef<RefType>(null);
  const { can } = task;
  const typeProject = type === "project";
  const typeProcess = type === "process";
  const canIsThreeDotBtn = can.check || can.complete || can["back-to-work"];
  const [modalComplete, setModalComplete] = useState(false);
  const [modalNotAccept, setModalNotAccept] = useState(false);
  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const completeHandler = () => {
    setModalComplete(true);
    closeThreeDot();
  };
  const backToWork = async (id: number) => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задание в работу?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectTaskAll({ id: projectId, status: "" }));
      closeThreeDot();
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectProcessOutTasks(processId));
      closeThreeDot();
    }
  };

  const acceptHandler = async (id: number) => {
    const result = window.confirm("Вы уверены, что хотите принять задание?");
    if (typeProject && projectId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectTaskAll({ id: projectId, status: "" }));
      closeThreeDot();
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectProcessInTasks(processId));
      closeThreeDot();
    }
  };

  const notCompleteTask = async (id: number) => {
    const data = {
      comment: "Не требуется",
      status: 7,
    };
    const result = window.confirm(
      "Вы уверены, что хотите отменить данное задание?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectTaskAll({ id: projectId, status: "" }));
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectProcessOutTasks(processId));
    }
  };

  const notAcceptHandler = () => {
    setModalNotAccept(true);
    closeThreeDot();
  };

  const setCompleteModalCloseMemo = useCallback(() => {
    setModalComplete(false);
  }, []);

  return (
    <>
      {can.complete && (
        <NewModal
          active={modalComplete}
          setActive={setModalComplete}
          title={`Разработать ${task.title}`}
        >
          <ModalComplete
            task={task}
            setCompleteModalCloseMemo={setCompleteModalCloseMemo}
            projectId={projectId}
            projectProcessID={processId}
            type={type}
          />
        </NewModal>
      )}
      {can.check && (
        <NewModal
          active={modalNotAccept}
          setActive={setModalNotAccept}
          title={task.title}
        >
          <ModalNotAccept
            id={task.id}
            content={task.comment}
            projectID={projectId}
            processID={processId}
            type={type}
          />
        </NewModal>
      )}
      {canIsThreeDotBtn && (
        <ThreeDotButton
          ref={threeDotButtonRef}
          newDesign
          classname={cls.threeDot}
        >
          {can.check && (
            <>
              <MenuItem onClick={() => acceptHandler(task.id)} disableRipple>
                Принять
              </MenuItem>
              <MenuItem onClick={notAcceptHandler} disableRipple>
                Отказать
              </MenuItem>
            </>
          )}
          {can.complete && (
            <>
              <MenuItem onClick={completeHandler} disableRipple>
                Сформировать задание
              </MenuItem>
              <MenuItem onClick={() => notCompleteTask(task.id)} disableRipple>
                Не требуется
              </MenuItem>
            </>
          )}
          {can["back-to-work"] && (
            <MenuItem onClick={() => backToWork(task.id)} disableRipple>
              Удалить
            </MenuItem>
          )}
        </ThreeDotButton>
      )}
      {!canIsThreeDotBtn && <div />}
    </>
  );
};
