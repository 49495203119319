import React from "react";
import classNames from "classnames";

export type GlyphiconProps = {
  type: string | "glyphicon-cutlery";
  size?: string | "15px";
  variant?: string;
};
export const Glyphicon: React.FC<GlyphiconProps> = ({
  type,
  size = "15px",
  variant = "",
}) => {
  return (
    <i
      style={{ fontSize: size }}
      className={classNames(`glyphicon ${type}`, variant)}
      aria-hidden="true"
    />
  );
};
