import React from "react";
import classNames from "classnames";
import { Counter } from "./Counter/Counter";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { useInnerWidth } from "../indexPageLists/UserList/User/subcomponents/Organisation/Organisation";

export type CounterListProps = ComponentPropsType<{
  size: "small" | "normal" | "big";
  items: { title: string; value: string | number; url: string }[];
  clickHandlerLink?: (url: string) => void;
  redirect?: boolean;
}>;

export const CounterList: React.FC<CounterListProps> = ({
  variant = "",
  items,
  size = "normal",
  theme = "dark",
  clickHandlerLink = () => {},
  redirect = false,
}) => {
  const innerWidth = useInnerWidth();

  return (
    <ul
      style={
        innerWidth < 420 && window.location.href.includes("/project/search")
          ? { flexDirection: "column" }
          : {}
      }
      className={classNames(variant, style.counterList, style[size])}
    >
      {items.map((item, i) => {
        return item.url ? (
          <a
            href={item.url}
            onClick={(e) => {
              if (!redirect) {
                e.preventDefault();
                window.location.href = item.url;
              }
              clickHandlerLink(item.url);
            }}
            className={style.link}
            key={i}
          >
            <li
              aria-label={"link"}
              className={classNames(
                style.counterList,
                style.link,
                style[theme]
              )}
            >
              <Counter {...item} size={size} theme={theme} />
            </li>
          </a>
        ) : (
          <li
            className={classNames(
              style.counterList,
              style.notLink,
              style[theme]
            )}
            key={i}
          >
            <Counter {...item} size={size} theme={theme} />
          </li>
        );
      })}
    </ul>
  );
};
