import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessPriceUpdate } from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";
import { fetchProjectProcessViewInfoIsLoadDuplicate } from "./fetchProjectProcessView";

export const fetchProjectProcessUpdatePrice = createAsyncThunk<boolean, any>(
  "projectProcess/fetchProjectProcessUpdatePrice",
  async ({ id, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessPriceUpdate(id, data),
      (data) => {
        dispatch(fetchProjectProcessViewInfoIsLoadDuplicate(id));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
