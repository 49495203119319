import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы установки проектировщика на проект
 */

export const showEventSetExecutorReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventSetExecutor: {
      isShow: true,
      data: {
        ...state.eventSetExecutor.data,
      },
    },
  };
};

export const closeEventSetExecutorReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventSetExecutor: {
    isShow: false,
    data: {
      ...state.eventSetExecutor.data,
    },
  },
});

export const setEventExecutorReducer = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventSetExecutor: {
    isShow: false,
    data: {
      ...action.payload,
    },
  },
});

export const selectorEESetExecutor = (state: StateType) =>
  state.eventEmitter.eventSetExecutor.isShow;

export const selectorEESetExecutorData = (state: StateType) =>
  state.eventEmitter.eventSetExecutor.data;
