import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Wrapper } from "../../../UI/templates";
import style from "../style.module.scss";
import { withClassCurry } from "../../../utils";
import { UserList } from "../../../UI/components/indexPageLists/UserList/UserList";
import {
  selectorExecutorsList,
  selectorMetaData,
  setExecutors,
} from "../../../../app/feature/indexPages/users/executorsPage/executorsPage";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { SortButton } from "../../../UI/components/Buttons/SortButton/SortButton";
import { Paginate } from "../../../UI/components/Pagination/Pagination";
import { UserSkeletonList } from "../../../UI/components/indexPageLists/UserList/UserSkeletonList";
import { ApiGetExecutorList } from "../../../../app/services/api/indexPages/executorsPage";
import { createPaginationMeta } from "../../../../app/feature/indexPages/createData";
import { request } from "../../../../app/services/api/requestHandler";
import { useTitle } from "../../../hooks/useTitle";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../../app/services/url/createUrlSearchString";
import { IndexPageTitle } from "../../../features/IndexPageTitle/IndexPageTitle";
import { ExecutorFormHead } from "./ExecutorFormHead";

export const ExecutorsPage = () => {
  useTitle("Проектировщики");
  const theme = useSelector(selectorAppTheme);
  const withTheme = withClassCurry(style[theme]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);

  const [searchString, setSearchString] = useState(location.search);
  const [currentFilters, setCurrentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({
    sort: "-rating.executor",
  });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    users: useSelector(selectorExecutorsList),
    meta: useSelector(selectorMetaData),
  };

  const params = queryString.parse(location.search);

  useEffect(() => {
    setInitialSearchParams(params as InitialParamType);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    navigate({
      search: searchString,
    });

    setLoad(true);
    if (searchString.length === 0) return;
    request(ApiGetExecutorList(searchString), (res, _, headersValues) => {
      const meta = createPaginationMeta(headersValues);
      dispatch(setExecutors(res, meta));
      setLoad(false);
    })(dispatch);
  }, [searchString]);

  useEffect(() => {
    if (firstLoad) return;
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const onResetFilter = () => {
    setInitialSearchParams({});
    setCurrentFilters({});
  };

  const onSubmitFilter = (data: any) => {
    setCurrentFilters(data);
  };

  const onClickLink = (url: string) => {
    const params = url.split("?");
    setCurrentFilters(queryString.parse(params[1]) as { [key: string]: any });
  };

  const paginate = (
    <Paginate
      page={Number(data.meta.paginationCurrentPage)}
      handleChange={onChangePage}
      count={data.meta.paginationPageCount}
      theme={theme}
      variant="outlined"
      shape="rounded"
    />
  );

  return (
    <div className={style.wrapper}>
      <div
        className={classNames(withTheme(style.container), style.listWrapper)}
      >
        <IndexPageTitle
          clickLink={onClickLink}
          title={"Проектировщики"}
          description={`Лучшие из лучших ждут Проекты и готовы к работе.`}
          theme={theme}
          page={"executor"}
        />
        <ExecutorFormHead
          initialValues={params}
          submitHandler={onSubmitFilter}
          resetHandler={onResetFilter}
          theme={theme}
        />
      </div>
      <Wrapper>
        <div className={style.paginationContainer}>
          {data.users?.length > 0 && paginate}
          <div className={style.sortingContainer}>
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="id"
              text="По дате регистрации"
              clickHandler={onClickSort}
              isShow={params.sort === "id" || params.sort === "-id"}
              isUp={params.sort === "id"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
              style={{ paddingTop: "8px" }}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="rating.executor"
              text="По рейтингу"
              clickHandler={onClickSort}
              isShow={
                params.sort === "rating.executor" ||
                params.sort === "-rating.executor"
              }
              isUp={params.sort === "rating.executor"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
              style={{ paddingTop: "8px" }}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="additionalInfo.job_duration"
              text="По стажу в отрасли"
              clickHandler={onClickSort}
              isShow={
                params.sort === "additionalInfo.job_duration" ||
                params.sort === "-additionalInfo.job_duration"
              }
              isUp={params.sort === "additionalInfo.job_duration"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
              style={{ paddingTop: "8px" }}
            />
          </div>
        </div>
      </Wrapper>
      {isLoad ? (
        <UserSkeletonList theme={theme} />
      ) : (
        <UserList
          executorSkill={currentFilters?.["ExecutorUserSearch[skillIds]"]}
          executors
          items={data.users}
          theme={theme}
        />
      )}
      {data.users?.length > 0 && (
        <Wrapper>
          <div className={style.paginationContainer}>{paginate}</div>
        </Wrapper>
      )}
    </div>
  );
};
