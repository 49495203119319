export const emojiConfig = {
  showSkinTones: false,
  title: "Эмодзи",
  i18n: {
    search: "Поиск",
    clear: "Очистить", // Accessible label on "clear" button
    notfound: "Эмодзи не найден",
    categories: {
      search: "Поиск",
      recent: "Частые",
      smileys: "Эмоции",
      people: "Люди",
      nature: "Животные",
      foods: "Еда",
      activity: "Активность",
      places: "Путешествия",
      objects: "Обьекты",
      symbols: "Символы",
      flags: "Флаги",
      custom: "Custom",
    },
    showPreview: false,
    categorieslabel: "Категории", // Accessible title for the list of categories
  },
  showPreview: false,
};
