import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../api";

export const ApiGetCountriesTypes = (): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/country/get-full-list`);

export const ApiGetRegionsOfCountry = (
  code: string,
  page = 1
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/region/index?RegionSearch[country_code]=${code}&per-page=30&page=${page}`
  );
