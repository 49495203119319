import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { customerPageInitialState } from "./customerPageIninialState";
import { createCustomerListFromApi } from "./createData/createData";

import C from "./constants";
import { StateType } from "../../../../initialState";
import {
  ApiCustomerPageDataType,
  ApiUserIndexPageDataType,
} from "../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const customerPageReducer = createReducer(
  customerPageInitialState,
  (builder) => {
    /*
      Установка значений на страницу заказчиков
    */

    builder.addCase(
      C.SET_CUSTOMER_PAGE_DATA,
      (state, action: PayloadAction<ApiCustomerPageDataType>) => {
        return {
          ...state,
          users: createCustomerListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorCustomerList = (state: StateType) =>
  state.customerPage.users;

export const selectorCustomerMeta = (state: StateType) =>
  state.customerPage.meta;

export const setCustomers = (
  data: ApiCustomerPageDataType,
  meta: ApiUserIndexPageDataType["meta"]
) => ({
  type: C.SET_CUSTOMER_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
