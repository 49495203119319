import { useEffect } from "react";
import { KanbanModel, KanbanType } from "src/FSD/entities/kanban";
import { useAppSelector } from "../../../../../../../app/store";
import { areArraysEqual } from "../../../../../../../components/utils";

export const useClearUpFields = (
  groupValue: KanbanType.FilterValues["group"],
  setValue: any
) => {
  const { groups: groupOptions } = useAppSelector(KanbanModel.getSelectOptions);
  const { getGroups } = useAppSelector(KanbanModel.getFilterPending);

  useEffect(() => {
    if (getGroups) {
      return;
    }

    const groupValueIds = groupValue.map((group) => group.id);

    const groupOptionsIds = groupOptions.map((group) => group.id);

    const newValue = groupValue.filter((value) =>
      groupOptionsIds.includes(value.id)
    );

    const newValueIds = newValue.map((group) => group.id);

    if (!areArraysEqual(groupValueIds, newValueIds)) {
      setValue("group", newValue);
    }
  }, [getGroups, groupOptions, groupValue]);
};
