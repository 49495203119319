import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProcessWorkCreateMutation } from "src/FSD/entities/processWork/api";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";

type Inputs = {
  name: string;
  description: string;
  upload_files: TFile[];
};

const schema = Yup.object().shape({
  name: Yup.string().required("Поле обязательно для заполнения").max(200),
});

export const useProcessWorkCreate = () => {
  const infoId = useAppSelector(getProjectProcessInfoId);
  const { handleClose } = useModalContext();
  const [create, { isLoading }] = useProcessWorkCreateMutation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const formData = new FormData();
    formData.append("name", dataValues.name);
    formData.append("description", dataValues.description);
    dataValues.upload_files.forEach(({ file }) => {
      formData.append("upload_files", file!);
    });
    if (infoId) {
      await create({ id: infoId, data: formData });
    }
    handleClose();
  };

  return {
    register,
    handleSubmit,
    formSubmitHandler,
    control,
    errors,
    isLoading,
  };
};
