import React, { useState } from "react";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import cn from "classnames";
import styles from "./style.module.scss";
import { StarRating } from "../../../../newUI/SVG";
import {
  clearSelectedExecutors,
  getGroupApplications,
  selectExecutor,
  TExecutor,
} from "../../../../../app/feature/ProjectView/GroupApplications";
import { config } from "../../../../../app.cofig";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { NewAvatar, ThemeAvatar } from "../../../../newUI/NewAvatar/NewAvatar";
import { helpersYears } from "../../../../../utils/helpersYears";
import { trimPrice } from "../../../../../utils/trimNumber";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import TransitionShowContent from "../../../../newUI/TransitionShowContent/TransitionShowContent";
import {
  TooltipTheme,
  TooltipWrapper,
} from "../../../../newUI/TooltipWrapper/TooltipWrapper";

type ExecutorCardProps = {
  executor: TExecutor;
  groupId: number;
};

const ExecutorCard = ({ executor, groupId }: ExecutorCardProps) => {
  const {
    id,
    photoId,
    fio,
    experience,
    rating,
    userTypeText,
    price,
    deadlines,
    newApplications,
  } = executor;

  const dispatch = useAppDispatch();
  const [toggleApplications, setToggleApplications] = useState(false);

  const {
    data: { selectedExecutors },
  } = useAppSelector(getGroupApplications);

  const valuesNewApplications = Object.values(newApplications);

  const countCorrectApplications = valuesNewApplications.filter(
    (applic) => applic.correct.statusText === "Подана"
  ).length;

  const countAllApplications = valuesNewApplications.length;

  const isSelected = Boolean(
    selectedExecutors.find(
      (executor: TExecutor & { groupId: number }) =>
        executor.id === id && executor.groupId === groupId
    )
  );

  const handleSelectExecutor = () => {
    const ids = selectedExecutors.map(
      (executor: TExecutor & { groupId: number }) => executor.groupId
    );

    const isDisabled = Array.from(new Set([...ids, groupId])).length > 1;

    if (isDisabled) {
      alert("Выбранные для сравнения заявки из других групп будут сброшены!");
      dispatch(clearSelectedExecutors());
      dispatch(selectExecutor({ ...executor, groupId }));
      return;
    }

    if (!isSelected && selectedExecutors.length === 3) {
      alert("Для сравнения можно выбрать только 3 исполнителей");
      return;
    }

    dispatch(selectExecutor({ ...executor, groupId }));
  };

  const BookMark = () => (
    <div onClick={handleSelectExecutor} className={styles.bookMark}>
      {isSelected ? (
        <BookmarkFill width={12} height={15} color="orange" />
      ) : (
        <Bookmark width={12} height={15} />
      )}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <BookMark />
      <div className={styles.executorCard}>
        <NewAvatar
          photoUrl={`${config.localDomain}/file/get?id=${photoId}`}
          theme={ThemeAvatar.NAME}
          fio={fio}
          userType={userTypeText}
          classname={styles.avatar}
        />
        <div className={styles.skills}>
          <div className={styles.star}>
            <p>{rating}</p>
            <StarRating />
          </div>
          <p>{`Опыт ${helpersYears(experience)}`}</p>
        </div>
        <div className={styles.dates}>
          <p>{deadlines.replace("•", "-")}</p>
          <span>Общий срок</span>
        </div>
        <div className={styles.price}>
          <p>{trimPrice(price)}₽</p>
          <span>В том числе НДС</span>
        </div>
      </div>
      <div
        onClick={() => setToggleApplications(!toggleApplications)}
        className={styles.applications}
      >
        <p>{`Заявки, поданные на разделы группы (${countCorrectApplications}/${countAllApplications})`}</p>
        <ToggleAccordion toggle={toggleApplications} secondRoot />
      </div>
      <TransitionShowContent open={toggleApplications}>
        <div className={styles.grid}>
          <p className={cn(styles.grid_header, styles.gridFirst)}>
            Наименование раздела
          </p>
          <p className={styles.grid_header}>Соответствие требованиям</p>
          <p className={styles.grid_header}>Стоимость и сроки</p>
          <p className={styles.grid_header}>Коментарий</p>
          {valuesNewApplications.map((applicaton) => {
            const isActual = applicaton.correct.statusText !== "Отсутствует";
            return (
              <>
                <p
                  className={cn(
                    styles.name,
                    styles.gridFirst,
                    isActual ? styles.nameActual : styles.nameNotActual
                  )}
                >
                  {applicaton.nameProcess}
                </p>
                <div className={styles.status}>
                  {isActual ? (
                    <>
                      {applicaton.correct.reason && (
                        <>
                          <p>{applicaton.correct.statusText}</p>
                          <span>{applicaton.correct.reason}</span>
                        </>
                      )}
                      {!applicaton.correct.reason && (
                        <>
                          <p>Соответствует</p>
                          <span>{applicaton.correct.reason || ""}</span>
                        </>
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                <div className={styles.gridThird}>
                  {isActual ? (
                    <>
                      <div className={styles.priceApplication}>
                        <p>{applicaton.price}₽</p>
                        <span>{applicaton.ndsText}</span>
                      </div>
                      <p>
                        {applicaton.date.start} - {applicaton.date.end}
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                <div className={styles.comment}>
                  {isActual && applicaton.comment ? (
                    <TooltipWrapper
                      content={applicaton.comment}
                      theme={TooltipTheme.CONTROL_WIDTH}
                      width={"auto"}
                    >
                      <p className={styles.comment_text}>
                        {applicaton.comment}
                      </p>
                    </TooltipWrapper>
                  ) : (
                    "-"
                  )}
                </div>
              </>
            );
          })}
        </div>
      </TransitionShowContent>
    </div>
  );
};

export default ExecutorCard;
