import { processCostValue } from "src/app/services/price/price";
import styles from "./styles.module.scss";

type TPieLegendProps = {
  price: string | number;
  payPercent: number;
  title: string;
  payClass: string;
};

export const PieLegend = (props: TPieLegendProps) => {
  const { price, payPercent, title, payClass } = props;

  const payPartValueFormatted = processCostValue(
    (Number(price) * payPercent) / 100
  );

  return (
    <div>
      <div className={payClass}>
        <span />
        {payPercent}%
      </div>
      <div className={styles.price}>
        {title}&nbsp;
        <br className={styles.price_break} />
        {price && <>({payPartValueFormatted} ₽)</>}
      </div>
    </div>
  );
};
