import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы закрытия конфликта
 */

export const showEventCloseDeleteGipConflictReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventCloseConflictDeleteGip: {
      isShow: true,
      data: {
        ...state.eventCloseConflictDeleteGip.data,
      },
    },
  };
};

export const closeEventCloseDeleteGipConflictReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventCloseConflictDeleteGip: {
    isShow: false,
    data: {
      ...state.eventCloseConflictDeleteGip.data,
    },
  },
});

export const selectorEECloseDeleteGipConflictIsShow = (state: StateType) =>
  state.eventEmitter.eventCloseConflictDeleteGip.isShow;
