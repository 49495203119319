import { Nullable } from "../../../../types/generics";
import { arraysEqual } from "./arrayEqual";
import { isObject } from "./isObject";

export const isDeepEqual = (
  object1: Nullable<Record<string, any>>,
  object2: Nullable<Record<string, any>>
) => {
  if (object1 === null || object2 === null) {
    return false;
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  let flag = true;

  objKeys1.forEach((key) => {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);
    const isArrays = Array.isArray(value1) && Array.isArray(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (isArrays && !arraysEqual(value1, value2)) ||
      (!isObjects && !isArrays && value1 !== value2)
    ) {
      flag = false;
    }
  });

  return flag;
};
