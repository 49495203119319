import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../../../app.cofig";
import { MetaType } from "../../../../types/other/pages/formationOfParameters";
import { httpRequestGet } from "../../../services/api/api";
import { request } from "../../../services/api/requestHandler";
import { pushError } from "../../errorTrace";
import { createPaginationMeta } from "../../indexPages/createData";

const ApiGetTreaties = (url: string): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/document/index?${url}&fields=id,name,status,type,projectProcesses,data,childDocs.id,childDocs.name,childDocs.status,childDocs.type,childDocs.projectProcesses,childDocs.data&expand=projectProcesses.partGroup,documentsOut.projectProcesses.partGroup,documentsOut.documentAccesses.documentSign,childDocs,projectProcesses.executorNds,processWorks.executorNds,documentAccesses.userType.user,documentAccesses.documentSign,totalPrice`
  );

export const getTreaties = createAsyncThunk(
  "dashboard/getTreaties",
  async (
    { type, id, userType }: { type: string; id: number; userType: number },
    { dispatch }
  ) => {
    let treaties: any = [];
    let totalCount = 0;
    let meta: MetaType;

    const getData = async (page: number) =>
      request(
        ApiGetTreaties(
          `DocumentSearch[${type}]=${id}&page=${page}&DocumentSearch[userTypeId]=${userType}`
        ),
        (res, _, headersValue) => {
          meta = createPaginationMeta(headersValue);
          treaties = treaties.concat(res);

          if (page === 1) {
            totalCount = +meta.paginationPageCount;
          }
        },
        () => (err) => {
          dispatch(pushError(err));
        }
      )();

    await getData(1);

    const dataPromises = [];
    for (let i = 2; i <= totalCount; ++i) {
      dataPromises.push(getData(i));
    }

    await Promise.all(dataPromises);

    return treaties;
  }
);
