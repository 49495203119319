import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { Deposit } from "src/FSD/widgets/v2/projectProcess/modals/Deposit/Deposit";
import { useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoisLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { fetchUnPublishProcess } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import {
  getPublishState,
  getUpdate,
  setPublishNull,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { fetchProjectProcessPause } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessPause";
import { getProjectProcessIsLoadDeposit } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadDeposit";
import { ModalBackToWork } from "src/components/pages/Project/NewView/Sections/ModalBackToWork/ModalBackToWork";
import { UpdatePublishModalContent } from "src/components/pages/Project/NewView/Sections/UpdatePublishModalContent/UpdatePublishModalContent";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import styles from "./TitleTabsHamburger.module.scss";
import { Modal } from "../../../shared/uiKit/v2/Modal";
import PublishObjectModals from "../publishObject/modals";

const sxMenuItem = {
  color: "#D5D6DE",
};
const TitleTabsHamburger = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const threeDotButtonRef = useRef<RefType>(null);
  const publish = useAppSelector(getPublishState);
  const updatePublish = useAppSelector(getUpdate);
  const isLoad = useAppSelector(getProjectProcessInfoisLoad);
  const isLoadDeposit = useAppSelector(getProjectProcessIsLoadDeposit);
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const [modalDeposit, setModalDeposit] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [backToWorkModal, setBackToWorkModal] = useState(false);
  const [updatePublishModal, setUpdatePublishModal] = useState(false);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const unPublishClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );
    if (result) {
      await dispatch(fetchUnPublishProcess(infoData.id));
      await dispatch(fetchProjectProcessViewDuplicate(+infoData.id));
    }
  };

  async function pauseHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить раздел?"
    );
    if (params.id && result) {
      await dispatch(fetchProjectProcessPause({ id: +params.id, pause: true }));
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  async function unPauseHandler() {
    const result = window.confirm("Вы уверены, что хотите возобновить раздел?");
    if (params.id && result) {
      await dispatch(
        fetchProjectProcessPause({ id: +params.id, pause: false })
      );
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  function handleDepositClick() {
    setModalDeposit(true);
    closeThreeDot();
  }

  const closeModalDeposit = useCallback(() => {
    setModalDeposit(false);
  }, []);

  const closePublishModal = useCallback(() => {
    setPublishModal(false);
    dispatch(setPublishNull());
  }, []);

  const closeUpdatePublishModal = useCallback(() => {
    setUpdatePublishModal(false);
    dispatch(setPublishNull());
  }, []);

  const closeBackToWorkModal = useCallback(() => {
    setBackToWorkModal(false);
  }, []);

  useEffect(() => {
    if (publish || updatePublish) {
      dispatch(fetchProjectProcessViewDuplicate(+infoData.id));
    }
  }, [publish, updatePublish]);

  return (
    <div>
      {infoData.can.publish && infoData.projectPart.published && (
        // <NewModal
        //   title={`Публикация раздела «${infoData.projectPart.name}»`}
        //   active={publishModal}
        //   setActive={setPublishModal}
        //   isLoad={isLoad}
        //   activeClass={styles.modal}
        // >
        //   <PublishModalContent
        //     node={infoData}
        //     project={infoData.project}
        //     closeModal={closePublishModal}
        //   />
        // </NewModal>
        <Modal isActive={publishModal} handleClose={closePublishModal}>
          <PublishObjectModals.PublishContentForm
            id={infoData.id}
            header={`Публикация раздела «${infoData.projectPart.name}»`}
          />
        </Modal>
      )}
      {infoData.can.unpublish && !infoData.projectPart.published && (
        <NewModal
          title={`Вернуть раздел «${infoData.projectPart.name}» с публикации`}
          active={backToWorkModal}
          setActive={setBackToWorkModal}
          activeClass={styles.modal}
        >
          <ModalBackToWork
            node={infoData}
            project={infoData.project}
            closeModal={closeBackToWorkModal}
          />
        </NewModal>
      )}
      {infoData.can.freezeFunds && (
        <NewModal
          title={"Пополнение баланса раздела"}
          active={modalDeposit}
          setActive={setModalDeposit}
          isLoad={isLoadDeposit}
        >
          <Deposit
            closeModal={closeModalDeposit}
            includesNds={Boolean(infoData?.executor_nds)}
          />
        </NewModal>
      )}
      {(infoData.can.update || infoData.can.unpublish) && (
        <NewModal
          title={`Редактирование раздела «${infoData.projectPart.name}»`}
          active={updatePublishModal}
          setActive={setUpdatePublishModal}
          isLoad={isLoad}
          activeClass={styles.modal}
        >
          <UpdatePublishModalContent
            node={infoData}
            project={infoData.project}
            closeModal={closeUpdatePublishModal}
          />
        </NewModal>
      )}
      <ThreeDotButton ref={threeDotButtonRef}>
        {infoData.can.publish && infoData.projectPart.published && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              setPublishModal(true);
            }}
            disableRipple
          >
            Опубликовать
          </MenuItem>
        )}
        {(infoData.can.update || infoData.can.unpublish) && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              setUpdatePublishModal(true);
            }}
            disableRipple
          >
            Редактировать
          </MenuItem>
        )}
        {infoData.can.unpublish && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              unPublishClick();
            }}
            disableRipple
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {infoData.can.freezeFunds && (
          <MenuItem onClick={handleDepositClick} disableRipple>
            Пополнить
          </MenuItem>
        )}
        {infoData.can.pause && (
          <MenuItem onClick={pauseHandler} disableRipple>
            Приостановить
          </MenuItem>
        )}
        {infoData.can.unpause && (
          <MenuItem onClick={unPauseHandler} disableRipple>
            Возобновить
          </MenuItem>
        )}
        <Link to={`/chat/${infoData.project.chatId}`}>
          <MenuItem sx={sxMenuItem} disableRipple>
            Чат
          </MenuItem>
        </Link>
      </ThreeDotButton>
    </div>
  );
};

export default TitleTabsHamburger;
