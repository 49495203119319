import { dateKeys } from "src/FSD/widgets/v2/project/modals/EditDate/lib/constants/dateKeys";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { ApiSchedulePlanResponses } from "src/FSD/entities/schedulePlan/model/responseTypes";

export const dateMapper = (data?: ApiSchedulePlanResponses.View) => {
  return dateKeys.reduce(
    (acc, key) => {
      const item = data?.[key] ? data[key] : null;
      acc[key] = timestampToDate(item);
      return acc;
    },
    {} as Record<keyof ApiSchedulePlanResponses.View, Date | null>
  );
};
