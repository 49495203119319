import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { fetchRemoveExecutors } from "../../../../app/feature/ProjectProcessView/RemoveExecutors/services/fetchRemoveExecutors";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getProjectProcessTree } from "../../../../app/feature/ProjectProcessView/RemoveExecutors/selectors/getRemoveExecutors";
import cls from "./RemoveExecutors.module.scss";
import { getRemoveExecutorsIsLoad } from "../../../../app/feature/ProjectProcessView/RemoveExecutors/selectors/getRemoveExecutorsIsLoad";
import { fetchProjectProcessViewDuplicate } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { SkeletonRemoveExecutors } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { RemoveExecutorCard } from "./RemoveExecutorCard";

export const RemoveExecutors = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useAppSelector(getProjectProcessInfoId);
  const executors = useAppSelector(getProjectProcessTree);
  const isLoad = useAppSelector(getRemoveExecutorsIsLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchRemoveExecutors(infoId));
    }
  }, [infoId]);

  if (isLoad) {
    return <SkeletonRemoveExecutors />;
  }

  if (executors) {
    return (
      <div className={cls.wrapper}>
        {executors.map((el) => (
          <RemoveExecutorCard key={el.executorId} executor={el} />
        ))}
      </div>
    );
  }
  return <></>;
};
