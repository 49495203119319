import React from "react";
import {
  PersonCheckSvg,
  PersonSvg,
} from "src/FSD/widgets/v2/project/Documents/ui/DocumentsSvg";

export function iconType(type: boolean) {
  if (type) return <PersonCheckSvg color={"#0AA699"} />;
  return <PersonSvg color={"#EEA527"} />;
}
