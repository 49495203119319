import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы удаления проекта
 */

export const showEventDeleteProject = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventDeleteProject: {
      isShow: true,
      data: {
        ...state.eventDeleteProject.data,
      },
    },
  };
};

export const closeEventDeleteProject = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventDeleteProject: {
    isShow: false,
    data: {
      ...state.eventDeleteProject.data,
    },
  },
});

export const selectorEEDeleteProjectIsShow = (state: StateType) =>
  state.eventEmitter.eventDeleteProject.isShow;
