import { useProjectProcessCanCheckQuery } from "src/FSD/entities/projectProcess/api";

type UseModalProps = {
  processId: number;
};

export const useCheckProcess = ({ processId }: UseModalProps) => {
  const { data: canCheckData, isFetching } = useProjectProcessCanCheckQuery({
    id: processId,
  });

  return {
    isCanCheckPending: isFetching || !canCheckData,
    canCheckData,
  } as const;
};
