import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { ApiRequestJoinFirmMulti } from "../../../app/services/api/organisation/organisation";
import {
  getErrorMessage,
  request,
} from "../../../app/services/api/requestHandler";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import style from "./style.module.scss";
import { selectorEditRequestJoinData } from "../../../app/feature/eventEmitter/organisation/eventEditRequestJoin";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { palette, textFieldSX } from "../../../styles/restyle";

const inputProps = {
  style: {
    height: "100px",
    fontSize: 14,
  },
};

export type SelectItemProps = ComponentPropsType<unknown>;

export type SetUserRoleFormProps = ComponentPropsType<{
  submitHandler?: () => void;
  submitClose?: () => void;
  closeHandler?: VoidFunction;
}>;

export const EditRequestJoinForm: React.FC<SetUserRoleFormProps> = ({
  variant = "",
  closeHandler = () => {},
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      comment: "Я сотрудник этой организации",
    },
  });

  const { id, title } = useSelector(selectorEditRequestJoinData);
  const modalClosed: boolean = useSelector(selectorModalClosed);

  const onSubmit = async (data: any) => {
    setDisabled(true);

    const formData = new FormData();
    formData.append("StartForm[content]", data.comment);

    await request(
      ApiRequestJoinFirmMulti(id, formData),
      () => {
        enqueueSnackbar("Запрос на вступление успешно отправлен", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.joinRequest.set({
            sent: true,
            id,
          });
          closeHandler();
        }, 500);
      },
      () => (err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.joinRequest.set({});
          closeHandler();
        }, 500);
      }
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  const watchComment = watch("comment");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 0 }}
      className={classNames(style.modalWindow, variant)}
    >
      <p className={style.headerText} style={{ paddingBottom: "20px" }}>
        Уточните текст запроса доступа к организации {title}. Запрос будет
        направлен руководителю организации
      </p>
      <TextField
        {...register("comment")}
        name={"comment"}
        variant="outlined"
        label="Содержание"
        size={"small"}
        autoComplete={"off"}
        value={watchComment}
        inputProps={inputProps}
        multiline
        {...textFieldSX}
      />

      <br />
      <div className={style.footerButtons}>
        <CustomButton
          width={160}
          disabled={disabled}
          type="submit"
          background={palette.blue}
        >
          Отправить
        </CustomButton>
      </div>
    </form>
  );
};
