import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../../styles/restyle";
import {
  fetchGipHelperApplicateInvite,
  fetchProjectViewSearchGip,
} from "../../../../../../../../app/feature/ProjectView/thunks";
import cls from "./Modal.module.scss";

interface IGipHelperAcceptModalProps {
  projectName: string;
  id: number;
}
export const GipHelperAcceptModal: React.FC<IGipHelperAcceptModalProps> = memo(
  ({ projectName, id }) => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    async function onAcceptClick(status: string) {
      if (status === "Принять") {
        await dispatch(fetchGipHelperApplicateInvite({ id, status: 4 }));
        if (params.id) {
          await dispatch(fetchProjectViewSearchGip(params.id));
        }
      }
      if (status === "Отказать") {
        dispatch(fetchGipHelperApplicateInvite({ id, status: 5 }));
        if (params.id) {
          await dispatch(fetchProjectViewSearchGip(params.id));
        }
      }
    }

    return (
      <div className={cls.modalApplicate}>
        <h1>{`Рассмотрение заявки на роль помощника ГИП в проекте «${projectName}»`}</h1>
        <div className={cls.modalApplicate_btn}>
          <CustomButton
            width={160}
            onClick={() => onAcceptClick("Принять")}
            background={palette.blue}
          >
            Принять
          </CustomButton>
          <CustomButton
            width={160}
            onClick={() => onAcceptClick("Отказать")}
            background={palette.red}
          >
            Отказать
          </CustomButton>
        </div>
      </div>
    );
  }
);
