import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectMilestone,
  getProjectMilestoneIsAllToggle,
  getProjectMilestoneIsLoad,
} from "../../../../../app/feature/ProjectView/milestone/selectors/milestoneSelectors";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { MilestoneTree } from "./MilestoneTree/MilestoneTree";
import { setOpenAllNode } from "../../../../../app/feature/ProjectView/milestone/slice/projectMilestoneSlice";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { Header } from "./Header/Header";
import styles from "./Milestone.module.scss";
import { fetchProjectMilestone } from "../../../../../app/feature/ProjectView/milestone/services/fetchProjectMilestone";
import { MilestoneSkeletonFullPage } from "./MilestoneSkeleton";

export const Milestone = () => {
  const isAllToggle = useAppSelector(getProjectMilestoneIsAllToggle);
  const milestone = useAppSelector(getProjectMilestone);
  const isLoad = useAppSelector(getProjectMilestoneIsLoad);

  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const isMilestone = Object.keys(milestone).length > 0;

  useEffect(() => {
    if (params.id && !isMilestone) {
      dispatch(fetchProjectView(params.id));
      dispatch(fetchProjectMilestone({ projectId: +params.id }));
    }
  }, [dispatch, params.id]);

  function handleAllClick() {
    dispatch(setOpenAllNode());
  }

  if (isLoad) {
    return <MilestoneSkeletonFullPage />;
  }

  if (milestone) {
    return (
      <div className={styles.wrapper}>
        <Header />
        <ScrollWrap height={"859px"}>
          <MilestoneTree tree={Object.values(milestone)} />
          <AllToggleWidget
            isAllToggle={isAllToggle}
            setIsAllToggle={handleAllClick}
          />
        </ScrollWrap>
      </div>
    );
  }
  return null;
};
