import React, { useCallback, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { ModalEditGip } from "../ModalEditGip/ModalEditGip";
import { ModalGipRemove } from "../ModalGipRemove/ModalGipRemove";
import {
  getGipRemoveIsLoad,
  getGipUpdateIsLoad,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { userIsAdmin } from "../../../../../../../app/services/user/userRoles";
import {
  fetchDeleteProjectObject,
  fetchProjectComplexObject,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/thunks";
import { NewModal } from "../../../../../../newUI/NewModal/NewModal";

interface ButtonThreeDotProps {
  can: any;
  id: number;
  gip: any;
  gipAccess: any;
}

export const ButtonThreeDot = ({
  can,
  id,
  gip,
  gipAccess,
}: ButtonThreeDotProps) => {
  const isLoadGipUpdate = useSelector(getGipUpdateIsLoad);
  const isLoadRemoveGip = useSelector(getGipRemoveIsLoad);
  const threeDotButtonRef = useRef<RefType>(null);
  const [modalGipEdit, setModalGipEdit] = useState(false);
  const [modalGipRemove, setModalGipRemove] = useState(false);
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const canBtn = can.delete || can.updateGip || can.requestRemoveGip;
  const isAdmin = userIsAdmin();

  const modalClose = useCallback(() => {
    setModalGipEdit(false);
  }, []);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const deleteObject = async () => {
    const result = window.confirm("Вы уверены, что хотите удалить проект?");
    if (result && params.id) {
      await dispatch(fetchDeleteProjectObject(id));
      await dispatch(fetchProjectComplexObject({ id: +params.id }));
    }
  };

  return (
    <div>
      {(can.updateGip || isAdmin) && gip && (
        <NewModal
          active={modalGipEdit}
          setActive={setModalGipEdit}
          isLoad={isLoadGipUpdate}
          title={"Редактировать ГИП"}
        >
          <ModalEditGip id={id} gipAccess={gipAccess} modalClose={modalClose} />
        </NewModal>
      )}
      {can.requestRemoveGip && (
        <NewModal
          active={modalGipRemove}
          setActive={setModalGipRemove}
          isLoad={isLoadRemoveGip}
          title={"Заявка на снятие ГИП объекта"}
        >
          <ModalGipRemove id={id} />
        </NewModal>
      )}
      {canBtn && (
        <ThreeDotButton ref={threeDotButtonRef}>
          {can.delete && (
            <MenuItem
              onClick={() => {
                deleteObject();
                closeThreeDot();
              }}
              disableRipple
            >
              Удалить
            </MenuItem>
          )}
          {(can.updateGip || isAdmin) && gip && (
            <>
              <MenuItem
                onClick={() => {
                  setModalGipEdit(true);
                  closeThreeDot();
                }}
                disableRipple
              >
                Сменить ГИПа
              </MenuItem>
            </>
          )}
          {can.requestRemoveGip && (
            <>
              <MenuItem
                onClick={() => {
                  setModalGipRemove(true);
                  closeThreeDot();
                }}
                disableRipple
              >
                Снять ГИПа
              </MenuItem>
            </>
          )}
        </ThreeDotButton>
      )}
    </div>
  );
};
