import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { SelectWithPopper } from "../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";

export type BankAccountTypeItem = {
  title: string;
  id: string;
};

export type BankAccountTypesProps = ComponentPropsType<{
  options: any;
  changeHandler?: (type: BankAccountTypeItem) => void;
  blurHandler?: (event: any) => void;
  changeFieldHandler?: (event: any) => void;
  value?: any | undefined;
  paperOptions?: { [key: string]: any };
  label?: string;
  error?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
}>;

export const BankAccountTypes: React.FC<BankAccountTypesProps> = ({
  options,
  variant = "",
  changeHandler = () => {},
  blurHandler,
  changeFieldHandler,
  value,
  paperOptions = { marginTop: 4, color: "#26313a" },
  label = "",
  error,
  id,
  name = "",
  placeholder = "",
  disabled,
}) => {
  const [selectedValue, setSelectValue] = useState<
    BankAccountTypeItem | undefined
  >(undefined);

  useEffect(() => {
    const result = options.find(
      (item: any) => Number(item.id) === Number(value)
    );
    setSelectValue(result || undefined);
  }, [value, options]);

  return (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      id={id}
      name={name}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      options={options}
      label={label}
      variant={classNames(variant)}
      disableClear={true}
      fullWidth={true}
      paperOptions={paperOptions}
      blurHandler={blurHandler}
      changeHandlerField={changeFieldHandler}
      error={error}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
