import { memo } from "react";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { useProjectProcessToProcessesQuery } from "src/FSD/entities/projectProcess/api";
import { toSelect } from "../../../../../app/feature/project/projectProcessPublication";
import { TSelectItem } from "../../../../../components/features/Forms/SetGipForm/types";
import { useDataMapper } from "../../../../shared/lib/hooks/useDataMapper";

type Props = {
  processId: number;
  projectId: number;
  all?: boolean;
  value: TSelectItem[];
  changeHandler: (newValue: TSelectItem[]) => void;
};

export const SelectTasksForProcesses = memo(
  ({ processId, projectId, all, value, changeHandler }: Props) => {
    const { data: projectProcessesRaw, isFetching } =
      useProjectProcessToProcessesQuery({
        processId,
        projectId,
        all,
      });

    const projectProcesses = useDataMapper(projectProcessesRaw, toSelect);

    const isProjectProcessPending = isFetching || !projectProcesses;

    return (
      <SelectMultiple
        value={value}
        changeHandler={changeHandler}
        label="Задания для разделов"
        options={projectProcesses ?? []}
        isLoading={isProjectProcessPending}
        showSelectAll
      />
    );
  }
);
