import { CheckCustomerAlignForm } from "./CheckCustomerAlign";
import { RequestCustomerAlignCompleteForm } from "./RequestCustomerAlignComplete";
import { CustomerAlignCompleteForm } from "./CustomerAlignComplete";
import { ExpertiseRemarkRowCompleteForm } from "./ExpertiseRemarkRowComplete";
import { CheckExpertiseRemarkRowCompleteForm } from "./CheckExpertiseRemarkRowComplete";
import { CheckExecuteCompleteForm } from "./CheckExecuteComplete";
import { ExecuteCompleteForm } from "./ExecuteComplete";
import { RemoveExecutorForms } from "./RemoveExecutor";
import { ProcessWorkCreate } from "./ProcessWorkCreate";
import { Delegate } from "./Delegate";

const ProcessWorkModals = {
  CheckCustomerAlignForm,
  RequestCustomerAlignCompleteForm,
  CustomerAlignCompleteForm,
  ExpertiseRemarkRowCompleteForm,
  CheckExpertiseRemarkRowCompleteForm,
  CheckExecuteCompleteForm,
  ExecuteCompleteForm,
  RemoveExecutorForms,
  ProcessWorkCreate,
  Delegate,
};

export default ProcessWorkModals;
