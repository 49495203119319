import { combineReducers } from "redux";
import { KanbanModel } from "src/FSD/entities/kanban";
import { ProcessWorkModel } from "src/FSD/entities/processWork";
import { TaskModel } from "src/FSD/entities/task";
import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { PublishObjectModel } from "src/FSD/entities/publishObject";
import { appReducer } from "./feature/app/app";
import { headerReducer } from "./feature/header/header";
import { projectReducer } from "./feature/project/projectIndividual/projectIndividual";
import { projectLogsReducer } from "./feature/project/projectLogs/projectLogs";
import { projectProcessReducer } from "./feature/project/projectProcess/projectProcess";
import { projectPartReducer } from "./feature/project/projectPart/ProjectPartReducer";
import { eventEmitterReducer } from "./feature/eventEmitter/EventEmitter";
import { chatReducer } from "./feature/chat/chat";
import { executorPageReducer } from "./feature/indexPages/users/executorsPage/executorsPage";
import { projectGanttReducer } from "./feature/project/projectGantt/ProjectGanttReducer";
import { customerPageReducer } from "./feature/indexPages/users/customerPage/customerPage";
import { gipPageReducer } from "./feature/indexPages/users/gipPage/gipPage";
import { providersPageReducer } from "./feature/indexPages/users/providersPage/providersPage";
import { appErrorReducer } from "./feature/appError/appError";
import { userPageReducer } from "./feature/userPage/userPage";
import { projectsPageReducer } from "./feature/indexPages/projects/projectsPage";
import { materialsPageReducer } from "./feature/indexPages/materials/materialsPage";
import { mainPageReducer } from "./feature/mainPage/mainPage";
import { tasksPageReducer } from "./feature/indexPages/tasks/tasksPage";
import { noticesPageReducer } from "./feature/noticesPage/noticesPage";
import { foldersState } from "./feature/project/keepingFoldersState/foldersState";
import { projectDocumentsReducer } from "./feature/project/projectContracts/projectDocumentsReducer";
import {
  postBlogReducer,
  postPageReducer,
} from "./feature/indexPages/postPage/postPage";
import { postViewReducer } from "./feature/postView/postView";
import { postEditReducer } from "./feature/editPost/postEdit";
import { appUserReducer } from "./feature/appUser/appUser";
import { processControlsPage } from "./feature/projectProcessControlsPage/processControlsPage";
import { processesStateReducer } from "./feature/projectProcessControlsPage/processControlsOpenedState/openedProcessesState";
import { organisationPageReducer } from "./feature/organisationPage/organisationPage";
import { modalCloseReducer } from "./feature/modalClose/eventModalClose";
import { projectProcessPageReducer } from "./feature/indexPages/projectProcess/projectProcessPage";
import profileReducer from "./feature/profileReducer/profileReducer";
import projectViewSlice from "./feature/ProjectView/projectView";
import projectInitialDataSlice from "./feature/ProjectView/InitialDataReducer/initialDataReducer";
import projectSectionsSlice from "./feature/ProjectView/SectionsReducer/projectSections";
import projectCheckListTree from "./feature/ProjectView/CheckListReducer/projectCheckList";
import projectDocumentSlice from "./feature/ProjectView/DocumentReducer/projectDocuments";
import projectTaskSlice from "./feature/ProjectView/TaskReducer/projectTask";
import projectCheckTaskSlice from "./feature/ProjectView/CheckTaskReducer/projectCheckTask";
import projectUsersList from "./feature/ProjectView/AdminReducer/projectAdmin";
import projectCompareGipSlice from "./feature/ProjectView/CompareReducer/projectCompareGip";
import projectGraphSlice from "./feature/ProjectView/GraphReducer/graphReducer";
import projectHistorySlice from "./feature/ProjectView/HistoryReducer/projectHistory";
import projectComplexObjectSlice from "./feature/ProjectView/ComplexReducer/projectComplex";
import modalVisibilitySlice from "./feature/modalController/modalVisibility";
import unsavedSlice from "./feature/unsaved/unsavedController";
import constantValuesSlice from "./feature/constantValues/slice";
import dashboardSlice from "./feature/dashboard";
import projectProcessSlice from "./feature/ProjectProcessView/projectProcessSlice";
import projectGroupApplicationsSlice from "./feature/ProjectView/GroupApplications";
import errorTraceSlice from "./feature/errorTrace";
import milestoneSlice from "./feature/milestone";
import projectGroupProcessPublicationSlice from "./feature/project/projectProcessPublication";
import projectGroupProcessRequestSlice from "./feature/project/projectProcessRequest";
import getListSlice from "./feature/getList/getListSlice";
import taskCompleteSlice from "./feature/taskComplete/slice/taskCompleteSlice";
import projectMilestoneSlice from "./feature/ProjectView/milestone/slice/projectMilestoneSlice";
import projectCompositionSlice from "./feature/ProjectView/Contents/getComposition";
import projectChecklistSlice from "./feature/project/projectChecklist";

export const rootReducer = combineReducers({
  projectComposition: projectCompositionSlice,
  projectGroupProcessPublication: projectGroupProcessPublicationSlice,
  projectGroupProcessRequest: projectGroupProcessRequestSlice,
  projectProcessView: projectProcessSlice,
  app: appReducer,
  appUser: appUserReducer,
  header: headerReducer,
  appError: appErrorReducer,
  projectsPage: projectsPageReducer,
  projectProcessPage: projectProcessPageReducer,
  project: projectReducer,
  projectLogs: projectLogsReducer,
  projectProcessFoldersState: foldersState,
  projectProcess: projectProcessReducer,
  projectPart: projectPartReducer,
  projectGantt: projectGanttReducer,
  projectDocuments: projectDocumentsReducer,
  projectProcessControls: processControlsPage,
  projectProcessControlsOpened: processesStateReducer,
  eventEmitter: eventEmitterReducer,
  chat: chatReducer,
  executorPage: executorPageReducer,
  gipPage: gipPageReducer,
  providersPage: providersPageReducer,
  customerPage: customerPageReducer,
  materialsPage: materialsPageReducer,
  userPage: userPageReducer,
  mainPage: mainPageReducer,
  profilePage: profileReducer,
  noticesPage: noticesPageReducer,
  tasksPage: tasksPageReducer,
  postPage: postPageReducer,
  postView: postViewReducer,
  postEdit: postEditReducer,
  blogPage: postBlogReducer,
  organisationPage: organisationPageReducer,
  constants: constantValuesSlice,
  projectView: projectViewSlice,
  projectInitialData: projectInitialDataSlice,
  projectSections: projectSectionsSlice,
  projectTask: projectTaskSlice,
  projectCheckTask: projectCheckTaskSlice,
  projectCheckList: projectCheckListTree,
  projectGraph: projectGraphSlice,
  projectUsersList,
  projectDocumentGet: projectDocumentSlice,
  projectCompareGip: projectCompareGipSlice,
  projectHistory: projectHistorySlice,
  projectGroupApplications: projectGroupApplicationsSlice,
  projectComplexObjects: projectComplexObjectSlice,
  modalClose: modalCloseReducer,
  modalVisibility: modalVisibilitySlice,
  unsaved: unsavedSlice,
  dashboard: dashboardSlice,
  errorTrace: errorTraceSlice,
  milestone: milestoneSlice,
  getList: getListSlice,
  taskComplete: taskCompleteSlice,
  projectMilestone: projectMilestoneSlice,
  projectChecklist: projectChecklistSlice,
  // models из слоя entities
  kanban: KanbanModel.sliceReducer,
  processWork: ProcessWorkModel.sliceReducer,
  task: TaskModel.sliceReducer,
  publishObject: PublishObjectModel.sliceReducer,
  [rootApi.reducerPath]: rootApi.reducer,
});
