import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { stringMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";

export const checkExecuteCompleteSchema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.REJECT,
    then: stringMaxLengthRequired("Замечания", 2000),
  }),
});
