import Filter from "./Filter";

class FilterTreaties extends Filter {
  static getSectionsCombined(sections: any) {
    return sections.reduce(
      (combined: string, section: any) => combined + section.name,
      ""
    );
  }

  static filterByQuery(data: any, query: string, fulfilled: boolean) {
    if (query === "") {
      return FilterTreaties.filterByFulfilled(data, fulfilled);
    }

    let ids: number[] = [];
    Object.values(data).forEach((treaties: any) => {
      const treatyIds = treaties.filter((item: any) => {
        const sectionsCombined = FilterTreaties.getSectionsCombined(
          item.sections
        );

        const nameIncludes = Filter.valueContains(item.title, query);
        const sectionIncludes = Filter.valueContains(sectionsCombined, query);

        return nameIncludes || sectionIncludes;
      });

      ids = [...ids, ...treatyIds];
    });

    ids = ids.filter((item: any) => {
      const condition = item.statusKey === 4;
      return fulfilled ? condition : !condition;
    });

    return ids.map((item: any) => item.id);
  }

  static filterByFulfilled(data: any, fulfilled: boolean) {
    let ids: number[] = [];
    Object.values(data).forEach((treaties: any) => {
      const treatyIds = treaties.filter((item: any) => {
        const condition = item.statusKey === 4;
        return fulfilled ? condition : !condition;
      });

      ids = [...ids, ...treatyIds];
    });

    return ids.map((item: any) => item.id);
  }
}

export default FilterTreaties;
