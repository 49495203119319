import React, { useState } from "react";
import { CheckTaskDataType } from "../../../../../app/feature/ProjectView/CheckTaskReducer/thunks";
import style from "./style.module.scss";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { Dot } from "../../../../newUI/Dot/Dot";
import { ChecksCard } from "./ChecksCard";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import ModalComment from "../Tasks/ModalComment/ModalComment";

export type TModalData = {
  open: boolean;
  id: number | null;
  name: string;
};

export const modalInitialData = {
  open: false,
  id: null,
  name: "",
};

type CheckTaskCardProps = {
  checkTask: CheckTaskDataType;
};

export const CheckTaskCard: React.FC<CheckTaskCardProps> = ({ checkTask }) => {
  const [nodeToggle, setNodeToggle] = useState(false);

  const [modalData, setModalData] = useState<TModalData>(modalInitialData);

  const nodeIconType = <ToggleAccordion toggle={nodeToggle} />;

  return (
    <>
      <div className={style.node} onClick={() => setNodeToggle((v) => !v)}>
        <div className={style.dot}>
          {checkTask.dot && <Dot status={checkTask.dot} size={8} />}
          <span>{`${checkTask.title} (${checkTask.checks.length})`}</span>
        </div>
        <span>{nodeIconType}</span>
      </div>
      {nodeToggle && (
        <div className={style.cardList}>
          {checkTask.checks.map((task) => (
            <div>
              <ChecksCard {...task} setModalData={setModalData} />
            </div>
          ))}
        </div>
      )}
      <ModalWrapper
        show={modalData.open}
        handleCloseModal={() => setModalData(modalInitialData)}
        head={<h1 className={style.commentModalHeader}>Комментарии</h1>}
      >
        <ModalComment name="" taskId={modalData.id} />
      </ModalWrapper>
    </>
  );
};
