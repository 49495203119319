import React from "react";
import {
  Pagination,
  PaginationProps as MuiPaginationProps,
} from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";

const oldBorder = "#9dafbd";
const darkBorder = "#919191";

const oldColor = "#9dafbd";
const darkColor = "#30ab69";

const oldBgc = "#3B93AF";
const darkBgc = "#30AD69";

const oldTheme = "old";

export type PaginateProps = {
  posVariant?: string;
  theme?: "old" | "dark" | "light";
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& button[class^='MuiButtonBase-root']": {
        border: "none",
        borderColor: (props: PaginateProps) =>
          props.theme === oldTheme ? oldBorder : darkBorder,
        color: (props: PaginateProps) =>
          props.theme === oldTheme ? oldColor : darkColor,
      },
      "& .MuiButtonBase-root.Mui-selected": {
        background: (props: PaginateProps) =>
          props.theme === oldTheme ? oldBgc : darkBgc,
        color: "#ffffff",
      },
      "& .MuiPaginationItem-root.MuiPaginationItem-ellipsis": {
        color: (props: PaginateProps) =>
          props.theme === oldTheme ? oldColor : darkColor,
      },
    },
  })
);

export const Paginate: React.FC<
  PaginateProps & Omit<MuiPaginationProps, keyof PaginateProps>
> = (props) => {
  const { posVariant = "", handleChange, ...other } = props;
  const classesMui = useStyles(props);
  return (
    <Pagination
      siblingCount={window.innerWidth > 680 ? 1 : 0}
      className={classNames(classesMui.root, posVariant)}
      onChange={handleChange}
      {...other}
    />
  );
};
