import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы сравнения гипов
 */

export const showEventCompareGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventCompareGip: {
      isShow: true,
      data: {
        ...state.eventCompareGip.data,
      },
    },
  };
};

export const closeEventCompareGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventCompareGip: {
    isShow: false,
    data: {
      ...state.eventCompareGip.data,
    },
  },
});

export const selectorEECompareGipIsShow = (state: StateType) =>
  state.eventEmitter.eventCompareGip.isShow;
