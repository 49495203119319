import {
  sectionContainsApplications,
  sectionContainsTasks,
} from "../../../../components/pages/User/Dashboard/Secondary/Sections/Section";

// id разделов, у которых есть заявки
export const getIdsWithApplications = (data: any) => {
  return Object.values(data)
    .filter((item: any) => {
      if (item?.id) {
        return sectionContainsApplications(item.tabs, "show");
      }

      return false;
    })
    .map((item: any) => item.id);
};

export const getIdsWithTasks = (data: any) => {
  return Object.values(data)
    .filter((item: any) => {
      if (item?.id) {
        return sectionContainsTasks(item.tabs, "show");
      }

      return false;
    })
    .map((item: any) => item.id);
};

// раскраска папок
export const getFodlerColors = (data: any, considerCount: boolean) => {
  const initialColor = {
    section: null,
    tasks: null,
    applications: null,
    treaty: null,
  };

  const treeRaw = JSON.parse(JSON.stringify(data));

  const getColor = (node: string): Record<string, string | null> => {
    const currentNode = treeRaw[node];
    if (currentNode?.id) {
      if (considerCount && !currentNode.count) {
        return initialColor;
      }

      return currentNode?.color;
    }

    let color: Record<string, string | null> = initialColor;

    currentNode.children.forEach((item: string) => {
      const nodeColor = getColor(item);

      const getCurrentNodeColor = (field: string) => {
        let currentColor = color[field];

        if (currentColor !== "red") {
          currentColor = nodeColor[field] || currentColor;
        }

        return currentColor;
      };

      color = {
        section: getCurrentNodeColor("section"),
        tasks: getCurrentNodeColor("tasks"),
        applications: getCurrentNodeColor("applications"),
        treaty: getCurrentNodeColor("treaty"),
      };
    });

    currentNode.color = color;
    return color;
  };

  getColor("0");

  return treeRaw;
};
