import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox/Checkbox";
import * as Yup from "yup";
import { stringRequired } from "src/utils/SimplifiedYup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { createChecklist } from "src/app/feature/project/projectChecklist/thunks";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import styles from "./SourceDataUpdateForm.module.scss";

type TAddCheckList = {
  parentId: number;
  projectId: number;
  updateAddCheckList: () => void;
};

type TFieldValues = {
  name: string;
  hidden: boolean;
  executor: boolean;
  critical: boolean;
};

const schema = Yup.object().shape({
  name: stringRequired("Наименование"),
});

export const AddCheckList = ({
  parentId,
  projectId,
  updateAddCheckList,
}: TAddCheckList) => {
  const dispatch = useAppDispatch();

  const {
    data: { type },
    pending,
  } = useAppSelector(getProjectChecklist);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TFieldValues>({
    defaultValues: {
      name: "",
      executor: false,
      hidden: false,
      critical: false,
    },
    resolver: yupResolver(schema),
  });

  const [formShow, setFormShow] = useState(false);
  const handleFormShow = () => {
    setFormShow(true);
  };

  const onSubmit = async (data: TFieldValues) => {
    await dispatch(createChecklist({ parentId, projectId, data, type }));
    updateAddCheckList();
    reset();
    setFormShow(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formShow ? (
        <div>
          <div className={styles.addParent}>
            <TextField
              {...register("name")}
              placeholder="Наименование"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
            <Controller
              control={control}
              name="hidden"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Скрытые - исходные данные, которые доступны только участникам проекта"
                  onChange={() => {
                    setValue("hidden", !value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="executor"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Заказчик - за предоставление исходных данных отвечает Заказчик"
                  onChange={() => {
                    setValue("executor", !value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="critical"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Критичные - важные исходные данные, которые влияют на длительность выполнения работ"
                  onChange={() => {
                    setValue("critical", !value);
                  }}
                />
              )}
            />
          </div>
          <Button disabled={pending.create} type="submit">
            Добавить
          </Button>
        </div>
      ) : (
        <div className={styles.addNewItem}>
          <Button onClick={handleFormShow}>
            Добавить новый пункт в список +
          </Button>
        </div>
      )}
    </form>
  );
};
