import { styled } from "@mui/material/styles";
import { Switch as MuiSwitch } from "@mui/material";

const generalStyle: Record<string, any> = {
  width: 42,
  height: 26,
  padding: 0,
  transform: "scale(85%)",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "1px 0",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      margin: "2px 0",
      transform: "translateX(16px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        border: 0,
        width: 22,
        height: 22,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#3BAF6A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
    backgroundColor: "#26313A",
    border: "3px solid #A7B0B7",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#26313A",
    border: "3px solid #A7B0B7",
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
};

const lightStyle: Record<string, any> = {
  ...generalStyle,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "1px 0",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      margin: "2px 0",
      transform: "translateX(16px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        border: 0,
        width: 22,
        height: 22,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#3BAF6A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
    backgroundColor: "#9dafbd",
    border: "3px solid #A7B0B7",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#26313A",
    border: "3px solid #A7B0B7",
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
};

const oceanicStyle: Record<string, any> = {
  width: 42,
  height: 26,
  padding: 0,
  transform: "scale(85%)",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "1px 0",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      margin: "2px 0",
      transform: "translateX(16px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#0AA699",
        border: 0,
        width: 22,
        height: 22,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(10, 166, 153, 0.50)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
    backgroundColor: "#838A93",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#6B7D8A",
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
};

const Switch = styled(MuiSwitch)(() => generalStyle);

export const LightSwitch = styled(MuiSwitch)(() => lightStyle);
export const OceanicSwitch = styled(MuiSwitch)(() => oceanicStyle);
export default Switch;
