import { createSlice } from "@reduxjs/toolkit";
import { modalsInitialState } from "./modalsState";
import { RootState } from "../../../../app/store";

const modalsSlice = createSlice({
  name: "publishObjectModalsSlice",
  initialState: modalsInitialState,
  reducers: {
    setRequestUpdateActive(state, action) {
      state.requestUpdate = action.payload;
    },
    setRemoveRequestExecutionActive(state, action) {
      state.removeRequestExecution = action.payload;
    },
    setFeedbackTaskActive(state, action) {
      state.feedbackTask = action.payload;
    },
  },
});

export const {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
} = modalsSlice.actions;

export const getModalsData = (state: RootState) => state.publishObject.modals;

export default modalsSlice.reducer;
