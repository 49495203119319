import { useEffect, FC } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getAccessCodesByProject,
  getMyProjects,
  createProjectAccess,
} from "../../../../../app/feature/ProjectView/AdminReducer/thunks";
import {
  clearAddRightsData,
  getProjectUsersList,
} from "../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";
import style from "../../../Project/View/Admin/modals/AddRights.module.scss";
import { notNullRequired } from "../../../../../utils/SimplifiedYup";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";

const validationSchema = Yup.object().shape({
  project: notNullRequired("Пользователь"),
  role: notNullRequired("Роль"),
});

const ERROR_MESSAGES = {
  NO_ACTIVE_PROJECTS: "У Вас нет активных проектов",
};

type TInviteAsGipAssistantModal = {
  project: TSelectItem | null;
  role: TSelectItem | null;
};

type InviteAsGipAssistantProps = {
  userId: number;
  handleCloseModal: () => void;
};

const InviteAsGipAssistant: FC<InviteAsGipAssistantProps> = ({
  userId,
  handleCloseModal,
}) => {
  const dispatch = useAppDispatch();

  const { accessCodes, myProjects, pending, defaultValues } =
    useAppSelector(getProjectUsersList);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<TInviteAsGipAssistantModal>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const projectValue = watch("project");

  const onSubmit = async (data: TInviteAsGipAssistantModal) => {
    await dispatch(
      createProjectAccess({
        projectId: projectValue!.id as number,
        data: {
          userId,
          accessCode: data!.role!.id,
          firmId: null,
          needComplete: false,
        },
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(getMyProjects());

    return () => {
      dispatch(clearAddRightsData());
    };
  }, []);

  useEffect(() => {
    if (!projectValue?.id) {
      return;
    }

    dispatch(
      getAccessCodesByProject({
        projectId: projectValue.id as number,
        isInviteGip: false,
      })
    );
  }, [projectValue?.id]);

  useNonInitialEffect(() => {
    if (pending.myProjects) {
      return;
    }

    if (!myProjects.length) {
      setError("project", { message: ERROR_MESSAGES.NO_ACTIVE_PROJECTS });
    }
  }, [pending.myProjects]);

  const isDisabled =
    Object.values(pending).some(Boolean) || pending.createProjectAccess;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.wrapper}>
      <Controller
        name="project"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            isLoading={pending.myProjects}
            label="Проект"
            options={myProjects}
            error={Boolean(errors.project)}
            helperText={errors.project?.message}
          />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            isLoading={pending.accessCodes}
            label="Роль"
            options={accessCodes}
            error={Boolean(errors.role)}
            helperText={errors.role?.message}
          />
        )}
      />
      <CustomButton
        className={style.submitButton}
        background={palette.blue}
        width={120}
        disabled={isDisabled}
        forceDisabled={!isSubmitting}
        type="submit"
      >
        Добавить
      </CustomButton>
    </form>
  );
};

export default InviteAsGipAssistant;
