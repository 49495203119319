import { memo } from "react";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  ListItemText,
  TextField,
} from "@mui/material";
import { TSelectItem } from "../../features/Forms/SetGipForm/types";
import { textFieldSX } from "../../../styles/restyle";
import { getCompleteOptions, getNewValue, listItemSX } from "./utils";

type Props = {
  value: TSelectItem[];
  label: string;
  changeHandler: (newValue: TSelectItem[]) => void;
  options: TSelectItem[];
  canSelectAll?: boolean;
  disabled?: boolean;
} & ({ error?: false } | { error: true; helperText: string });

// Todo Перенесен в src/FSD/shared/uiKit/v2/SelectMultiple
const CustomMultupleSelect = (props: Props) => {
  const {
    value = [],
    label,
    changeHandler,
    options,
    canSelectAll = false,
    error = false,
    disabled = false,
  } = props;

  const completeOptions = getCompleteOptions(value, options, canSelectAll);

  const handleChange = (_: unknown, newValue: TSelectItem[]) => {
    const newOptions = getNewValue(options, newValue);
    changeHandler(newOptions);
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        id="tags-outlined"
        onChange={handleChange}
        options={completeOptions}
        getOptionLabel={(option) => option.title}
        value={value}
        disabled={disabled}
        filterSelectedOptions
        renderOption={(props, item) => {
          return (
            <li {...props} key={item.id}>
              <ListItemText sx={listItemSX}>{item.title}</ListItemText>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldSX}
            label={label}
            placeholder="Поиск"
          />
        )}
      />
      {/* @ts-ignore */}
      {error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default memo(CustomMultupleSelect);
