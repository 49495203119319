import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import {
  getProjectTasks,
  setAllToggle,
  setError,
} from "../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import styles from "./TreeTask.module.scss";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { fetchProjectTaskAll } from "../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { Header } from "./Header/Header";
import { TreeTask } from "./TreeTasks";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { TasksSkeletonBody, TasksSkeletonFullPage } from "./TasksSkeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface ITasksProps {
  setDefaultTab: () => void;
}
export const Tasks = ({ setDefaultTab }: ITasksProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const isTablet = useIsMobile(1600);

  const {
    allTasks,
    error,
    counts,
    isAllToggle,
    status,
    fullPageLoad,
    bodyLoad,
  } = useSelector(getProjectTasks);
  const { project } = useSelector(getProjectViewData);

  const isTasks = allTasks.length > 0;
  const statusNull = !status.check && !status.work && !status.done;

  useEffect(() => {
    if (project && !project?.can.editByGipHelper) {
      setDefaultTab();
    }
  }, [project]);

  useEffect(() => {
    if (params.id && statusNull && !isTasks) {
      dispatch(
        fetchProjectTaskAll({
          id: +params.id,
          status: "",
          load: "fullPageLoad",
        })
      );
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  const handleClick = () => {
    if (isAllToggle) {
      dispatch(setAllToggle(false));
    } else {
      dispatch(setAllToggle(true));
    }
  };

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (fullPageLoad) {
    return <TasksSkeletonFullPage />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Header counts={counts} />
        {bodyLoad ? (
          <TasksSkeletonBody />
        ) : (
          <ScrollWrap height={isTablet ? "auto" : "867px"}>
            {allTasks.map(([processId, value], index) => (
              <TreeTask
                key={processId}
                data={value}
                processId={processId}
                index={index}
              />
            ))}
            <AllToggleWidget
              isAllToggle={isAllToggle}
              setIsAllToggle={handleClick}
            />
          </ScrollWrap>
        )}
      </div>
    </>
  );
};
