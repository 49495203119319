import { IconButton } from "@mui/material";
import { StarHalf } from "react-bootstrap-icons";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { Application as ApplicationCard } from "src/FSD/entities/application";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { useModalsFeedbackTask } from "src/FSD/entities/publishObject/lib/hooks/useModalsFeedbacktask";
import styles from "./Removed.module.scss";
import { helpersYears } from "../../../../../../../utils/helpersYears";
import { palette } from "../../../../../../../styles/restyle";
import { useRemoveExecutor } from "../lib/hooks/useRemovedExecutor";

type Props = {
  removed: ApiCommonResponses.Removed;
};

export const RemovedExecutor = ({ removed }: Props) => {
  const { isTablet, isMobile, fullName, jobDuration, dateStart, dateEnd } =
    useRemoveExecutor(removed);

  const { handleOpenFeedbackTaskRequest } = useModalsFeedbackTask();

  const actions = removed.can?.feedbackToExecutor ? (
    <IconButton
      className={styles.iconButton}
      onClick={() => handleOpenFeedbackTaskRequest(removed.id)}
    >
      <StarHalf color="white" />
    </IconButton>
  ) : (
    <div className={styles.iconButton} />
  );

  if (isTablet) {
    const jobDurationText = `Опыт ${helpersYears(jobDuration)}`;

    const fullNameTrimmed = isMobile ? `${fullName.slice(0, 26)}...` : fullName;

    return (
      <ApplicationCard
        className={styles.removedExecutor}
        avatar={
          <ApplicationCard.Avatar
            photoUrl={`${config.localDomain}${removed.executor.photo}`}
            borderColor={palette.persianGreen}
            upperText={jobDurationText}
            lowerText={removed.executorUserType.listName}
            fio={fullNameTrimmed}
            userId={removed.executor.id as number}
          />
        }
        rating={
          <ApplicationCard.Rating rating={+removed.executor.rating.executor} />
        }
        duration={
          <ApplicationCard.Segment
            prompt={`${dateStart} - ${dateEnd}`}
            className={styles.dates}
          >
            Сроки выполнения
          </ApplicationCard.Segment>
        }
      />
    );
  }

  return (
    <ApplicationCard
      className={styles.removedExecutor}
      avatar={
        <ApplicationCard.Avatar
          photoUrl={`${config.localDomain}${removed.executor.photo}`}
          borderColor={palette.persianGreen}
          lowerText={removed.executorUserType.listName}
          fio={fullName}
          userId={removed.executor.id as number}
        />
      }
      rating={
        <ApplicationCard.Rating
          rating={+removed.executor.rating.executor}
          jobDuration={Number(jobDuration)}
        />
      }
      duration={
        <ApplicationCard.Segment prompt={`${dateStart} - ${dateEnd}`}>
          Сроки выполнения
        </ApplicationCard.Segment>
      }
      actions={actions}
    />
  );
};
