import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { parse } from "date-fns";
import { config } from "../../../app.cofig";
import {
  loadProfile,
  loadRegions,
  loadRoles,
  loadCountries,
  loadTimeZones,
  loadSkillsList,
  loadAddressOptions,
  loadJobPlaces,
  loadBuildingFunctions,
  uploadProfile,
  createJobPlace,
  deleteJobPlace,
  editJobPlace,
  loadEducationList,
  createEducationPlace,
  deleteEducation,
  editEducation,
  loadRewardList,
  createReward,
  deleteReward,
  updateReward,
  loadPortfolioList,
  createPortfolio,
  deletePortfolio,
  updatePortfolio,
  loadPaymentInfo,
  updatePaymentInfo,
  changePassword,
  loadNotifications,
  updateNotifications,
  searchOrganisations,
  getUserFirmsList,
  loadProfileRequest,
} from "./thunks";
import { AppDispatch, RootState } from "../../store";

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useProfile = () => {
  const dispatch = useAppDispatch();
  const {
    profile,
    profileIsLoading,
    regionsIsLoading,
    rolesIsLoading,
    countriesIsLoading,
    timeZonesIsLoading,
    skillsListIsLoading,
    addressOptionsIsLoading,
    jobPlacesListIsLoading,
    educationListIsLoading,
    rewardListIsLoading,
    portfolioListIsLoading,
    buildingFunctionsIsLoading,
    paymentInfoIsLoading,
    notificationsIsLoading,
    searchOrganisationsIsLoading,
    userFirmsListIsLoading,
    regions,
    userRoles,
    countriesList,
    timeZones,
    skillsList,
    addressOptions,
    jobPlacesList,
    educationList,
    rewardList,
    buildingOptions,
    portfolioList,
    paymentInfo,
    notifications,
    searchOrganisationsList,
    searchOrganisationsMeta,
    userFirmsList,
    profileRequestData,
    profileRequestLoading,
  } = useAppSelector((state) => state.profilePage);

  const userTypes = profile.userTypes.find((userType) => userType.type === 4);

  return {
    isLoading:
      profileIsLoading ||
      regionsIsLoading ||
      rolesIsLoading ||
      timeZonesIsLoading ||
      countriesIsLoading ||
      skillsListIsLoading,
    jobPlacesListIsLoading,
    educationListIsLoading,
    rewardListIsLoading,
    portfolioPageIsLoading:
      portfolioListIsLoading || buildingFunctionsIsLoading,
    paymentInfoIsLoading,
    notificationsIsLoading,
    searchOrganisationsIsLoading,
    userFirmsListIsLoading,
    profileRequestData,
    profileRequestLoading,
    regions: regions.map((region) => {
      return {
        title: region.title,
        id: region.code,
      };
    }),
    address: profile.address,
    city: profile.city,
    region: {
      title: profile.region?.title,
      id: profile.region?.code,
    },
    country: {
      code: profile.region?.country?.code,
      title: profile.region?.country?.title,
    },
    name: profile.name,
    surname: profile.surname,
    patronymic: profile.patronymic,
    birthdate: profile.birthdate
      ? parse(profile.birthdate, "dd.MM.yyyy", new Date())
      : null,
    email: profile.email,
    phone: profile.phone,
    photo: profile.photo ? `${config.localDomain}${profile.photo}` : undefined,
    sex: profile.sex.key,
    role: profile.type,
    timezone: profile.timezone,
    timeZones,
    userRoles,
    countriesList,
    skills: profile.skills,
    skillsList,
    userTypes: userTypes?.title ?? "",
    work_with_bim: profile.work_with_bim,
    about: profile.about,
    canEdit: !profile.can.hasDocumentAccess,
    addressOptions,
    addressOptionsIsLoading,
    jobPlacesList,
    educationList,
    rewardList,
    buildingOptions,
    portfolioList,
    paymentInfo,
    notifications,
    searchOrganisationsList,
    searchOrganisationsMeta,
    userFirmsList,
    loadProfile: (id: string) => dispatch(loadProfile(id)),
    loadRegions: () => dispatch(loadRegions()),
    loadRoles: () => dispatch(loadRoles()),
    loadCountries: () => dispatch(loadCountries()),
    loadTimeZones: () => dispatch(loadTimeZones()),
    loadSkillsList: () => dispatch(loadSkillsList()),
    loadAddressOptions: (query: string) => dispatch(loadAddressOptions(query)),
    loadJobPlaces: (id: string) => dispatch(loadJobPlaces(id)),
    uploadProfile: (params: any) => dispatch(uploadProfile(params)),
    createJobPlace: (params: any) => dispatch(createJobPlace(params)),
    deleteJobPlace: (id: number) => dispatch(deleteJobPlace(id)),
    editJobPlace: (params: any) => dispatch(editJobPlace(params)),
    loadEducationList: (id: string) => dispatch(loadEducationList(id)),
    createEducationPlace: (params: any) =>
      dispatch(createEducationPlace(params)),
    deleteEducation: (id: number) => dispatch(deleteEducation(id)),
    editEducation: (params: any) => dispatch(editEducation(params)),
    loadRewardList: (id: string) => dispatch(loadRewardList(id)),
    createReward: (params: any) => dispatch(createReward(params)),
    deleteReward: (id: number) => dispatch(deleteReward(id)),
    updateReward: (params: any) => dispatch(updateReward(params)),
    loadBuildingFunctions: () => dispatch(loadBuildingFunctions()),
    loadPortfolioList: (id: string) => dispatch(loadPortfolioList(id)),
    createPortfolio: (params: any) => dispatch(createPortfolio(params)),
    deletePortfolio: (id: number) => dispatch(deletePortfolio(id)),
    updatePortfolio: (params: any) => dispatch(updatePortfolio(params)),
    loadPaymentInfo: (id: string) => dispatch(loadPaymentInfo(id)),
    updatePaymentInfo: (params: any) => dispatch(updatePaymentInfo(params)),
    changePassword: (params: any) => dispatch(changePassword(params)),
    loadNotifications: (id: string) => dispatch(loadNotifications(id)),
    updateNotifications: (params: any) => dispatch(updateNotifications(params)),
    searchOrganisation: ({ query, page }: { query: string; page: number }) =>
      dispatch(searchOrganisations({ query, page })),
    loadProfileRequest: (id: string) => dispatch(loadProfileRequest(id)),
    getUserFirmsList: () => dispatch(getUserFirmsList(null)),
  };
};

export default useProfile;
