import { appErrorHandler } from "../../../../../app/services/api/errorHandlers";
import { request } from "../../../../../app/services/api/requestHandler";
import { ApiGetPrompts } from "../../../../../app/services/api/organisation/organisation";

export const validateByBik = async (bik: string) => {
  let result: any[] = [];
  await request(
    ApiGetPrompts(bik),
    (res) => {
      result = [...res];
    },
    appErrorHandler
  )();

  return result;
};

export const bankAccount1 = {
  bankAccount1bik: "",
  bankAccount1bank_name: "",
  bankAccount1correspondent_account: "",
  bankAccount1bank_account: "",
};

export const bankAccount2 = {
  bankAccount2bik: "",
  bankAccount2bank_name: "",
  bankAccount2correspondent_account: "",
  bankAccount2bank_account: "",
};

export const treasuryAccount = {
  treasuryAccount1bik: "",
  treasuryAccount1bank_name: "",
  treasuryAccount1correspondent_account: "",
  treasuryAccount1bank_account: "",
  treasuryAccount1inn: "",
  treasuryAccount1kpp: "",
  treasuryAccount1ufk: "",
  treasuryAccount1client_number: "",
  treasuryAccount1analysis_code: "",
};

export const generate = (data: any) => {
  let defaultNumber = 1;
  if (!data) {
    return {
      ...bankAccount1,
      ...bankAccount2,
      ...treasuryAccount,
      defaultNumber,
    };
  }

  const bankAccounts = data.filter((item: any) => item.type === 1);
  const treasuryAccounts = data.filter((item: any) => item.type === 2);

  let formedData = {};

  if (!bankAccounts.length) {
    formedData = { ...bankAccount1, ...bankAccount2 };
  }
  if (bankAccounts.length >= 1) {
    formedData = {
      bankAccount1id: bankAccounts[0].id,
      bankAccount1bik: bankAccounts[0].bik,
      bankAccount1bank_name: bankAccounts[0].bank_name,
      bankAccount1correspondent_account: bankAccounts[0].correspondent_account,
      bankAccount1bank_account: bankAccounts[0].bank_account,
      ...bankAccount2,
    };
    if (bankAccounts[0].default) {
      defaultNumber = 1;
    }
  }
  if (bankAccounts.length >= 2) {
    formedData = {
      ...formedData,
      bankAccount2id: bankAccounts[1].id,
      bankAccount2bik: bankAccounts[1].bik,
      bankAccount2bank_name: bankAccounts[1].bank_name,
      bankAccount2correspondent_account: bankAccounts[1].correspondent_account,
      bankAccount2bank_account: bankAccounts[1].bank_account,
    };
    if (bankAccounts[1].default) {
      defaultNumber = 2;
    }
  }

  if (treasuryAccounts.length) {
    formedData = {
      ...formedData,
      treasuryAccount1id: treasuryAccounts[0].id,
      treasuryAccount1bik: treasuryAccounts[0].bik,
      treasuryAccount1bank_name: treasuryAccounts[0].bank_name,
      treasuryAccount1correspondent_account:
        treasuryAccounts[0].correspondent_account,
      treasuryAccount1bank_account: treasuryAccounts[0].bank_account,
      treasuryAccount1inn: treasuryAccounts[0].inn,
      treasuryAccount1kpp: treasuryAccounts[0].kpp,
      treasuryAccount1ufk: treasuryAccounts[0].ufk,
      treasuryAccount1client_number: treasuryAccounts[0].client_number,
      treasuryAccount1analysis_code: treasuryAccounts[0].analysis_code,
    };
    if (treasuryAccounts[0].default) {
      defaultNumber = 3;
    }
  } else {
    formedData = {
      ...formedData,
      ...treasuryAccount,
    };
  }

  return { ...formedData, defaultNumber };
};
