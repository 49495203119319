import React from "react";
import { useSelector } from "react-redux";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { Input } from "../../UI/components/FormComponentns/Input";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";

export type FirmNameProps = ComponentPropsType<{
  changeHandler?: (value: any) => void;
  value?: string;
}>;

export const FirmName: React.FC<FirmNameProps> = ({
  changeHandler = () => {},
  value = "",
  variant = "",
}) => {
  const theme = useSelector(selectorAppTheme);

  return (
    <Input
      className={variant}
      theme={theme}
      value={value}
      label={"Название организации"}
      autoComplete={"off"}
      onChange={(event) => changeHandler(event.currentTarget.value)}
    />
  );
};
