import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import useOutside from "use-onclickoutside";
import { useSelector } from "react-redux";
import * as Popper from "popper.js";
import {
  DatePickerContentFromTo,
  MyDatePickerFromToProps,
} from "./DatePickerContentFromTo";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../../app/feature/app/app";

export type DatePickerProps = ComponentPropsType<{
  label?: string;
  startDateProp?: Date | null;
  endDateProp?: Date | null;
  changeDateHandler?: (dates: Date | Date[] | null[] | null) => void;
  multiple?: boolean;
  helperText?: string;
  error?: boolean;
  placement?: Popper.Placement;
}>;

export const DatePicker: React.FC<DatePickerProps> = ({
  variant = "",
  label = "Дата",
  startDateProp = null,
  endDateProp = null,
  changeDateHandler = () => {},
  multiple = false,
  error = false,
  helperText = "",
  placement,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(startDateProp);
  const [endDate, setEndDate] = useState<Date | null>(endDateProp);
  const formRef = useRef(null);
  const theme = useSelector(selectorAppTheme);
  const onChangeDates: MyDatePickerFromToProps["changeHandler"] = (dates) => {
    // @ts-ignore
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    changeDateHandler([start, end]);
  };
  const onChangeNotMulti: MyDatePickerFromToProps["changeHandler"] = (
    dates
  ) => {
    // @ts-ignore
    setStartDate(dates);
    changeDateHandler(dates);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onToggle = () => {
    setOpen(!isOpen);
  };
  const onReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (startDateProp === null && endDateProp === null) {
      onReset();
    }
  }, [startDateProp, endDateProp]);

  useOutside(formRef, onClose);

  return (
    <div className={classNames(variant, style.wrapperOut)}>
      <div
        className={classNames(
          style.wrapper,
          style[theme],
          isOpen && style.open,
          error && style.err
        )}
        ref={formRef}
      >
        <label
          className={classNames(
            style.label,
            style[theme],
            isOpen && style.openLabel,
            error && style.errLabel
          )}
        >
          {label}
        </label>
        {multiple ? (
          <>
            <div className={style.dateWrap} onClick={onToggle}>
              <input
                placeholder={"дд.мм.гггг"}
                value={startDate ? moment(startDate).format("DD.MM.YYYY") : ""}
                className={classNames(style.dateInput, style.decor)}
              />
              &nbsp; 一 &nbsp;
              <input
                placeholder={"дд.мм.гггг"}
                value={endDate ? moment(endDate).format("DD.MM.YYYY") : ""}
                className={style.dateInput}
              />
            </div>
            <DatePickerContentFromTo
              theme={theme}
              resetDateHandler={onReset}
              startDate={startDate}
              changeHandler={onChangeDates}
              endDate={endDate}
              isOpen={isOpen}
            />
          </>
        ) : (
          <>
            <div className={style.dateWrap} onClick={onToggle}>
              <input
                placeholder={"дд.мм.гггг"}
                value={startDate ? moment(startDate).format("DD.MM.YYYY") : ""}
                className={classNames(style.dateInput, style.decor)}
                disabled={true}
              />
            </div>
            <DatePickerContentFromTo
              theme={theme}
              resetDateHandler={onReset}
              startDate={startDate}
              changeHandler={onChangeNotMulti}
              isOpen={isOpen}
              placement={placement}
              selectsRange={false}
            />
          </>
        )}
      </div>
      {error && <span className={style.error}>{helperText}</span>}
    </div>
  );
};
