import React, { memo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import styles from "./Statistics.module.scss";

export const Statistics = memo(() => {
  const { initialDataCounters } = useSelector(getProjectInitialData);

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.item}>
        <span className={styles.title}>Всего</span>
        <span className={styles.count}>
          {initialDataCounters?.allSourceData}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Просрочено</span>
        <span className={styles.count}>
          {initialDataCounters?.overdueSourceData}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Получено</span>
        <span className={styles.count}>
          {initialDataCounters?.receivedSourceData}
        </span>
      </div>
    </div>
  );
});
