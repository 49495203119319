import React from "react";
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { TextLink } from "../TextLink/TextLink";
import { htmlParserTransform } from "./createData";

export type NewsItemProps = ComponentPropsType<{
  id: number;
  content: string;
  title: string | null;
  photoUrl: string;
  url: string;
}>;

export const NewsItem: React.FC<NewsItemProps> = ({
  title = "",
  content = "",
  photoUrl = "",
  url = "",
}) => {
  return (
    <div
      className={classNames(
        style.newsItem,
        photoUrl.length === 0 && style.noImage
      )}
    >
      <div className={style.titlesWrapper}>
        <h1 className={style.bigTitle}>{title}</h1>
      </div>
      {photoUrl && (
        <div className={style.newsPhoto}>
          <img src={photoUrl} alt="" />
        </div>
      )}
      <div className={style.newsShortInfo}>
        <div className={style.newsDescription}>
          {ReactHtmlParser(content, {
            transform: htmlParserTransform,
          })}
        </div>
        {Boolean(url.trim()) && (
          <TextLink redirect={true} variant={style.newsLink} url={url}>
            Ознакомиться
          </TextLink>
        )}
      </div>
    </div>
  );
};
