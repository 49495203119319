import {
  createAction,
  createReducer,
  current,
  Dispatch,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StateType } from "../../../initialState";
import {
  projectProcessDataObject,
  projectProcessDataType,
  projectProcessProjectType,
} from "../../../../types/stateTypes/projectProcess";
import { ApiGetProcessData } from "../../../services/api/project/project";

import {
  apiProcessIndexDataType,
  apiProcessIndexType,
} from "../../../../types/api/responseTypes/apiProcessIndex";
import { apiProjectPartType } from "../../../../types/api/responseTypes/apiProjectPart";
import { getUserNameSurname } from "../../../services/user/user";
import { processCostValue } from "../../../services/price/price";
import { request } from "../../../services/api/requestHandler";
import { appErrorHandler } from "../../../services/api/errorHandlers";
import C from "./contants";

export const setActionObject = (
  data: (projectProcessDataObject | projectProcessProjectType)[],
  id: number
) => {
  const fn = (
    data: (projectProcessDataObject | projectProcessProjectType)[]
  ): (projectProcessDataObject | projectProcessProjectType)[] => {
    return data.map((item) => {
      if (item.hasOwnProperty("processName")) {
        return {
          folderId: (item as projectProcessProjectType).folderId,
          processName: (item as projectProcessProjectType).processName,
          isOpen: item.isOpen,
          data: fn((item as projectProcessProjectType).data),
        };
      }
      if ((item as projectProcessDataObject).id === id) {
        return {
          ...item,
          isActive: true,
        };
      }
      return {
        ...item,
        isActive: false,
      };
    });
  };
  return fn(data);
};

export const allOpen = (
  data: (projectProcessDataObject | projectProcessProjectType)[]
) => {
  const fn = (
    data: (projectProcessDataObject | projectProcessProjectType)[]
  ): (projectProcessDataObject | projectProcessProjectType)[] => {
    return data.map((item) => {
      if (item.hasOwnProperty("folderId")) {
        return {
          folderId: (item as projectProcessProjectType).folderId,
          processName: (item as projectProcessProjectType).processName,
          isOpen: true,
          data: fn((item as projectProcessProjectType).data),
        };
      }
      return {
        ...item,
        isOpen: true,
      };
    });
  };
  return fn(data);
};

export const closeAll = (
  data: (projectProcessDataObject | projectProcessProjectType)[]
) => {
  const fn = (
    data: (projectProcessDataObject | projectProcessProjectType)[]
  ): (projectProcessDataObject | projectProcessProjectType)[] => {
    return data.map((item) => {
      if (item.hasOwnProperty("folderId")) {
        return {
          folderId: (item as projectProcessProjectType).folderId,
          processName: (item as projectProcessProjectType).processName,
          isOpen: false,
          data: fn((item as projectProcessProjectType).data),
        };
      }
      return {
        ...item,
        isOpen: false,
      };
    });
  };
  return fn(data);
};

export const setToggleObject = (
  data: (projectProcessDataObject | projectProcessProjectType)[],
  id: string
) => {
  const fn = (
    data: (projectProcessDataObject | projectProcessProjectType)[]
  ): (projectProcessDataObject | projectProcessProjectType)[] => {
    return data.map((item) => {
      if (item.hasOwnProperty("folderId")) {
        if ((item as projectProcessProjectType).folderId === id) {
          return {
            folderId: (item as projectProcessProjectType).folderId,
            processName: (item as projectProcessProjectType).processName,
            isOpen: !item.isOpen,
            data: fn((item as projectProcessProjectType).data),
          };
        }
        return {
          folderId: (item as projectProcessProjectType).folderId,
          processName: (item as projectProcessProjectType).processName,
          isOpen: item.isOpen,
          data: fn((item as projectProcessProjectType).data),
        };
      }
      return {
        ...item,
        isOpen: item.isOpen,
      };
    });
  };
  return fn(data);
};

export const setFoldersStateFromStorage = (data: any, arrayIds: string[]) => {
  const fn = (
    data: (projectProcessDataObject | projectProcessProjectType)[]
  ): (projectProcessDataObject | projectProcessProjectType)[] => {
    return data.map((item) => {
      if (item.hasOwnProperty("folderId")) {
        if (
          arrayIds.includes(`${(item as projectProcessProjectType).folderId}`)
        ) {
          return {
            folderId: (item as projectProcessProjectType).folderId,
            processName: (item as projectProcessProjectType).processName,
            isOpen: !item.isOpen,
            data: fn((item as projectProcessProjectType).data),
          };
        }
        return {
          folderId: (item as projectProcessProjectType).folderId,
          processName: (item as projectProcessProjectType).processName,
          isOpen: item.isOpen,
          data: fn((item as projectProcessProjectType).data),
        };
      }
      return {
        ...item,
        isOpen: item.isOpen,
      };
    });
  };
  return fn(data);
};

const sectionId = (parentId: string, itemId: string) =>
  `${parentId}${parentId ? "." : ""}${itemId}`;

// формирование стейта, для отображения
export const createData = ({
  data,
}: {
  data: apiProcessIndexDataType;
  headData: apiProjectPartType[];
}): projectProcessDataType["data"] => {
  let firstObjectActive = false;

  const fn = (
    data: apiProcessIndexDataType,
    parentFolderId: string
  ): projectProcessDataType["data"] => {
    const arr = Object.entries(data).map(([idFolder, folder]) => {
      return { idFolder, folder };
    });

    return arr.map((item) => {
      if (
        item.folder.hasOwnProperty("data") &&
        Object.values(item.folder.data).length > 0
      ) {
        return {
          folderId: sectionId(parentFolderId, item.idFolder),
          processName: item.folder.name,
          isOpen: false,
          data: fn(item.folder.data, sectionId(parentFolderId, item.idFolder)),
        };
      }
      const object: apiProcessIndexType = item.folder;
      return {
        isActive: firstObjectActive
          ? false
          : (() => {
              firstObjectActive = true;
              return true;
            })(),
        id: object.id,
        projectId: object.project_id,
        name: object.projectPart.code,
        fullName: object.projectPart.name,
        isOpen: false,
        secure: object.secure || false,
        executor:
          object.activeAccess && object.activeAccess.user
            ? {
                value: `${getUserNameSurname({
                  name: object.activeAccess.user.name,
                  surname: object.activeAccess.user.surname,
                })} ${
                  // @ts-ignore
                  object.activeAccess.userType.title
                    ? // @ts-ignore
                      `, ${object.activeAccess.userType.title}`
                    : ""
                }`,
                url: `/user/${object.activeAccess.user.id}?tab=info`,
              }
            : {
                value: "",
              },
        activeAccess: null,
        countAccesses: object.countAccesses,
        executorType: object.executorType,
        parentNum: object.projectPart.parent_num,
        status: object.status.value,
        statusCode: object.status.key,
        formData: {
          dateFrom: object.date_start,
          dateTo: object.date_limit,
          priceNum: object.price,
          ...object.projectPart,
        },
        date: `${object.date_start || ""} − ${object.date_limit || ""}`,
        price: object.price
          ? // @ts-ignore
            `${
              // @ts-ignore
              object.price === "Договорная"
                ? "Договорная"
                : processCostValue(object.price)
              // @ts-ignore
            } ${object.price === "Договорная" ? "" : "₽"}`
          : "−",
        num: object.projectPart.num,
        can: object.can || null,
      } as projectProcessDataObject;
    });
  };
  return fn(data, "");
};

const initialStateProjectProcess: projectProcessDataType = {
  headerData: [],
  data: [],
};

const projectProcess = createReducer(initialStateProjectProcess, (builder) => {
  builder.addCase(
    C.PROJECT_PROCESS_SET_ACTION,
    (state, action: PayloadAction<{ id: number }>) => {
      const { data } = state;
      return {
        ...state,
        data: setActionObject(current(data), action.payload.id),
      };
    }
  );

  builder.addCase(C.SET_PROCESS_DATA, (state, action) => {
    // @ts-ignore
    return {
      headerData: state.headerData,
      // @ts-ignore
      data: createData(action.payload),
    };
  });

  builder.addCase(C.PROJECT_PROCESS_TOGGLE_OPEN, (state, action) => {
    // @ts-ignore
    return {
      headerData: state.headerData,
      // @ts-ignore
      data: setToggleObject(state.data, action.payload.id),
    };
  });

  builder.addCase(C.OPEN_ALL_PROCESSES, (state) => {
    return {
      headerData: state.headerData,
      data: allOpen(state.data),
    };
  });

  builder.addCase(C.CLOSE_ALL_PROCESSES, (state) => {
    return {
      headerData: state.headerData,
      data: closeAll(state.data),
    };
  });
  builder.addCase(
    C.SET_STATE_FOLDER_FROM_STORAGE,
    (state, action: PayloadAction<{ arrayIds: string[] }>) => {
      return {
        headerData: state.headerData,
        data: setFoldersStateFromStorage(state.data, action.payload.arrayIds),
      };
    }
  );
});

export const setOpenProcessAction = createAction(
  C.PROJECT_PROCESS_TOGGLE_OPEN,
  (action) => {
    return {
      payload: {
        id: action.id,
      },
    };
  }
);

export const setActiveAction = createAction(
  C.PROJECT_PROCESS_SET_ACTION,
  (action) => {
    return {
      payload: {
        id: action.id,
      },
    };
  }
);

export const setFolderState = createAction(
  C.SET_STATE_FOLDER_FROM_STORAGE,
  (action) => {
    return {
      payload: {
        arrayIds: action.arrayIds,
      },
    };
  }
);

export const openAllProcesses = (dispatch: Dispatch) => {
  dispatch({
    type: C.OPEN_ALL_PROCESSES,
  });
};

export const closeAllProcesses = (dispatch: Dispatch) => {
  dispatch({
    type: C.CLOSE_ALL_PROCESSES,
  });
};

export const fetchProcessData =
  (headData: apiProjectPartType[], id: number) => (dispatch: Dispatch) => {
    const action = (data: apiProcessIndexDataType) => {
      dispatch({
        type: C.SET_PROCESS_DATA,
        payload: {
          data,
          headData,
        },
      });
    };
    request(ApiGetProcessData(id), action, appErrorHandler)(dispatch);
  };

export const projectProcessReducer = projectProcess;

export const selectorProjectProcess = (state: StateType) =>
  state.projectProcess;
