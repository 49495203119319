import React from "react";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { UseFormRegister } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import parentStyles from "./PublishModalContent.module.scss";

export type TInputs = {
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  secureMissingAttributes: any;
};

type TProps<TFieldValues extends TInputs> = {
  publishInfo: {
    vor: boolean;
    vor_pnr: boolean;
    kac: boolean;
    secureMissingAttributes: any;
  };
  // register: UseFormRegister<TInputs>;
  register: any;
  checkVor: boolean;
  setCheckVor: (value: boolean) => void;
  errors: FieldErrors<TFieldValues>;
  checkVorPnr: boolean;
  setCheckVorPnr: (value: boolean) => void;
  handleCheckAll: any;
  checkAll: boolean;
  canAllCheck: boolean;
  checkKac: boolean;
  setCheckKac: (value: boolean) => void;
};

export const AdditionalRequirements = ({
  publishInfo,
  register,
  checkVor,
  setCheckVor,
  errors,
  checkVorPnr,
  setCheckVorPnr,
  handleCheckAll,
  checkAll,
  canAllCheck,
  checkKac,
  setCheckKac,
}: TProps<TInputs>) => {
  const { secureMissingAttributes, vor, vor_pnr } = publishInfo;

  return (
    <div className={parentStyles.fieldGroup}>
      <h3
        className={classNames(
          parentStyles.groupTitle,
          parentStyles.groupTitle_mb15
        )}
      >
        Дополнительные требования к разработке раздела
      </h3>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {vor && (
          <Grid item xs={12} md={4}>
            <Checkbox
              {...register("vor")}
              label="Ведомость объемов работ"
              checked={checkVor}
              onChange={() => setCheckVor(!checkVor)}
            />
            {errors.vor && (
              <p
                className={classNames(
                  parentStyles.error,
                  parentStyles.error_check
                )}
              >
                {errors.vor.message}
              </p>
            )}
          </Grid>
        )}
        {vor_pnr && (
          <Grid item xs={12} md={4}>
            <Checkbox
              {...register("vor_pnr")}
              label="Ведомость объемов работ на пусконаладочные работы"
              checked={checkVorPnr}
              onChange={() => setCheckVorPnr(!checkVorPnr)}
            />
            {errors.vor_pnr && (
              <p
                className={classNames(
                  parentStyles.error,
                  parentStyles.error_check
                )}
              >
                {errors.vor_pnr.message}
              </p>
            )}
          </Grid>
        )}
        {publishInfo.kac && (
          <Grid item xs={12} md={5}>
            <Checkbox
              {...register("kac")}
              label="Конъюнктурный анализ цен"
              checked={checkKac}
              onChange={() => setCheckKac(!checkKac)}
            />
          </Grid>
        )}
        {canAllCheck && (
          <Grid item xs={12} md={5}>
            <Checkbox
              label="Выбрать все"
              checked={checkAll}
              onChange={handleCheckAll}
            />
          </Grid>
        )}
      </Grid>

      {secureMissingAttributes.length > 0 && (
        <div className={parentStyles.flexAttributes}>
          <p>
            Работа с электронными договорами невозможна, так как не заполнены
            необходимые реквизиты:
          </p>
          {publishInfo?.secureMissingAttributes.map((item: any) => (
            <div className={parentStyles.missingAttributes}>
              <p>{`- ${item.title}:`}</p>
              {item.attributes.map((attribute: any) => (
                <span>{attribute}</span>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
