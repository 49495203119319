import React, { memo } from "react";
import classNames from "classnames";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import cls from "./TermsBlock.module.scss";
import { CheckIconType } from "../../../../newUI/CheckIconType/CheckIconType";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoPublished,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { DateEndPopper } from "../../../../newUI/SourceDataView/DateEndPopper";
import { fetchTaskDataUpdate } from "../../../../../app/feature/ProjectView/InitialDataReducer/thunks";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectInitialData } from "../../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { ApiPrimaryTaskType } from "../../../../../types/api/primaryTypes/apiPrimaryTaskType";

interface ITermsBlockProps {
  secure: boolean;
  with_docs: boolean;
  date_start: string;
  date_limit: string;
  isExpertise?: boolean;
  task: ApiPrimaryTaskType;
}
export const TermsBlock = memo(
  ({
    secure,
    date_limit,
    date_start,
    with_docs,
    isExpertise,
    task,
  }: ITermsBlockProps) => {
    const published = useAppSelector(getProjectProcessInfoPublished);
    const dispatch = useAppDispatch();
    const processData = useAppSelector(getProjectProcessInfoData);
    const { isLoadDateUpdate } = useSelector(getProjectInitialData);

    const canEditDate = !!processData?.task && processData?.can?.update;

    const factDateCreate =
      task && task.date_create
        ? moment(task.date_create, "DD.MM.YYYY HH:mm:ss Z").format("DD.MM.YYYY")
        : "";
    const factDateEnd =
      task && (task.date_deadline || task.date_complete)
        ? moment(
            task.date_complete || task.date_deadline,
            "DD.MM.YYYY HH:mm:ss Z"
          ).format("DD.MM.YYYY")
        : "";

    const onChangeDate = async (data: any) => {
      const dataParams = {
        date_create: moment(data.startDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
        date_deadline: moment(data.endDate, "DD.MM.YYYY").format("DD.MM.YYYY"),
      };
      if (processData?.task?.id) {
        const { id } = processData.task;
        await dispatch(fetchTaskDataUpdate({ id, dataParams }));
      }
      if (processData?.id) {
        dispatch(fetchProjectProcessViewDuplicate(processData.id));
      }
    };

    return (
      <div className={cls.terms}>
        <div className={classNames(cls.terms_block, cls.card)}>
          <h1>Сроки выполнения</h1>
          <div className={cls.terms_date}>
            <p>Плановый</p>
            <span>{`${date_start || ""} -`}</span>
            <DateEndPopper
              date_create={date_start}
              date_deadline={date_limit}
              canEditDate={canEditDate}
              onChangeDate={onChangeDate}
              isLoadDateUpdate={isLoadDateUpdate}
              isMobile={false}
              classname={cls.dateEdit}
            />
          </div>
          {factDateCreate && (
            <div className={cls.terms_dateEnd}>
              <p>Фактический</p>
              <span>{`${factDateCreate} - ${factDateEnd}`}</span>
            </div>
          )}
        </div>
        {!isExpertise && (
          <div className={classNames(cls.terms_block, cls.card)}>
            <h1>Договор</h1>
            {published && (
              <>
                <CheckIconType
                  colorCheck="#3BAF6A"
                  colorClose="#883636"
                  isCheck={secure}
                  text="Безопасная сделка"
                  fontSize="19px"
                />
                <CheckIconType
                  colorCheck="#3BAF6A"
                  colorClose="#883636"
                  isCheck={with_docs}
                  text="Заключение договора"
                  fontSize="19px"
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);
