import { IndexProjectProcessDataType } from "../../../../types/stateTypes/indexPages/IndexProjectProcessDataType";

export const projectsProcessPageInitialState: IndexProjectProcessDataType = {
  projects: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
