import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Application as ApplicationCard } from "src/FSD/entities/application";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { useFeedbackTask } from "../lib/hooks/useFeedbackTask";
import { palette } from "../../../../../../../styles/restyle";
import styles from "./FeedbackTaskForm.module.scss";
import { TextField } from "../../../../../../shared/uiKit/v2/TextField";
import { RatingStarsFeedback } from "./RatingStarsFeedback";

const defaultValues = {
  comment: "",
  quality: 0,
  reliability: 0,
  loyalty: 0,
};

type Props = {
  taskId: number;
  header: string;
};

export const FeedbackTaskForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    isSubmitting,
    taskInfo,
    isTaskViewPending,
    jobDuration,
    control,
  } = useFeedbackTask({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <ApplicationCard
            className={styles.feedbackTask}
            avatar={
              <ApplicationCard.Avatar
                photoUrl={`${config.localDomain}${taskInfo!.executor.photo}`}
                borderColor={palette.persianGreen}
                lowerText={taskInfo!.executorUserType!.listName}
                fio={taskInfo!.executorUserType!.title}
                userId={taskInfo!.executor.id as number}
                className={styles.avatar}
              />
            }
            rating={
              <ApplicationCard.Rating
                rating={taskInfo!.executor.rating.executor}
                jobDuration={Number(jobDuration)}
              />
            }
          />

          <RatingStarsFeedback control={control} />

          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Оценить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
