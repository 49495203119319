import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { PencilSquare } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import cls from "../../RemarkCard.module.scss";
import { RemarkRowsValue } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import { getCanProcessInfo } from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { iconBtnSx } from "../../../../../../../../styles/restyle";
import {
  BoxArrowInLeftIcon,
  BoxArrowInRightIcon,
  DeleteButtonIcon,
} from "../../../../../../../newUI/SVG";
import { fetchExpertiseRemarkRowStop } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowStop";
import { getProjectProcessInfoId } from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseRemarks } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { fetchExpertiseRemarkRowStart } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowStart";
import { fetchExpertiseRemarkRowDelete } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowDelete";
import { ModalUpdateRemarkRow } from "./ModalUpdateRemarkRow/ModalUpdateRemarkRow";
import { getExpertiseUpdateIsLoad } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { EditRemarkRowForm } from "../../../../../../../../FSD/widgets/v2/expertise/remarkRow/modals/EditRemarkRow/ui/EditRemarkRowForm";

interface GridCardBodyProps {
  data: RemarkRowsValue;
  projectId: number;
}
export const GridCardBody = memo(({ data, projectId }: GridCardBodyProps) => {
  const dispatch = useAppDispatch();
  const { row, partCodes } = data;
  const can = useAppSelector(getCanProcessInfo);
  const processId = useAppSelector(getProjectProcessInfoId);
  const isLoadExpertise = useAppSelector(getExpertiseUpdateIsLoad);

  const [modalUpdateRemarkRow, setUpdateRemarkRow] = useState(false);

  const handleCloseModal = useCallback(() => {
    setUpdateRemarkRow(false);
  }, []);

  async function remarkRowStopHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть замечание в черновик?"
    );
    if (result) {
      await dispatch(fetchExpertiseRemarkRowStop(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  async function remarkRowStartHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите направить замечание в работу?"
    );
    if (result) {
      await dispatch(fetchExpertiseRemarkRowStart(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  async function remarkRowDeleteHandler() {
    const result = window.confirm("Вы уверены, что хотите удалить замечание ?");
    if (result) {
      await dispatch(fetchExpertiseRemarkRowDelete(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  return (
    <>
      <div className={cls.grid_item}>{row.num}</div>
      <div className={cls.grid_item}>{row.text}</div>
      <div className={cls.grid_item}>{row.comment}</div>
      <div className={classNames(cls.grid_item, cls.grid_codes)}>
        {partCodes.map((code) => (
          <div key={code}>{code}</div>
        ))}
      </div>
      <div className={cls.grid_item}>{row.date_limit}</div>
      <div className={cls.grid_item}>{row.status.value}</div>
      <div className={cls.grid_item}>
        {can?.edit && (
          <div>
            {
              <Modal
                isActive={modalUpdateRemarkRow}
                handleClose={handleCloseModal}
              >
                <EditRemarkRowForm
                  processId={processId!}
                  remarkRowId={row.id}
                />
              </Modal>
            }
            <IconButton
              sx={iconBtnSx}
              title={"Изменить"}
              onClick={() => setUpdateRemarkRow(true)}
            >
              <PencilSquare />
            </IconButton>
            {row.status.key !== 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Вернуть в черновик"}
                onClick={remarkRowStopHandler}
              >
                <BoxArrowInLeftIcon />
              </IconButton>
            )}
            {row.status.key === 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Отправить в работу"}
                onClick={remarkRowStartHandler}
              >
                <BoxArrowInRightIcon />
              </IconButton>
            )}
            {row.status.key === 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Удалить"}
                onClick={remarkRowDeleteHandler}
              >
                <DeleteButtonIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </>
  );
});
