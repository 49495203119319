import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessCheckTask } from "../../../../app/feature/ProjectProcessView/CheckTask/services/fetchProjectProcessCheckTask";
import {
  getProjectProcessInCheckTask,
  getProjectProcessOutCheckTask,
} from "../../../../app/feature/ProjectProcessView/CheckTask/selectors/getProjectProcessInCheckTask";
import cls from "./CheckTask.module.scss";
import { HorizontalTabsPanel } from "../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { SkeletonTask } from "../../../UI/components/Project/Skeleton";
import { getProjectProcessCheckTaskIsLoad } from "../../../../app/feature/ProjectProcessView/CheckTask/selectors/getProjectProcessCheckTaskIsLoad";
import { CheckTaskTab } from "./CheckTaskIn/CheckTaskTab";
import { fetchProjectProcessViewDuplicate } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getTabShowProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { ModalWrapper } from "../../User/Edit/PersonalDataChange/PersonalDataChange";
import {
  TModalData,
  modalInitialData,
} from "../../Project/View/CheckTasks/CheckTaskCard";
import ModalComment from "../../Project/View/Tasks/ModalComment/ModalComment";

export const CheckTask = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useSelector(getProjectProcessInfoId);
  const inCheckTask = useSelector(getProjectProcessInCheckTask);
  const outCheckTask = useSelector(getProjectProcessOutCheckTask);
  const isLoad = useSelector(getProjectProcessCheckTaskIsLoad);
  const tabsDot = useAppSelector(getTabShowProcessInfo);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessCheckTask(infoId));
    }
  }, [infoId]);

  const tabs = [
    {
      id: 1,
      label: "У меня на проверке",
      incomplete: tabsDot?.outSubCheckTask.dot,
    },
    {
      id: 2,
      label: "Меня проверяют",
      incomplete: tabsDot?.inSubCheckTask.dot,
    },
  ];

  const [activeTab, setActiveTab] = useState<number | string>(tabs[0].id);
  const setActiveTabMemo = useCallback((tabId: number | string) => {
    setActiveTab(tabId);
  }, []);

  const [modalData, setModalData] = useState<TModalData>(modalInitialData);

  const components = [
    {
      id: 1,
      component: (
        <CheckTaskTab
          id={1}
          setModalData={setModalData}
          checkTasks={outCheckTask}
        />
      ),
    },
    {
      id: 2,
      component: (
        <CheckTaskTab
          id={2}
          setModalData={setModalData}
          checkTasks={inCheckTask}
        />
      ),
    },
  ];

  return (
    <div className={cls.wrapper}>
      <HorizontalTabsPanel
        tabs={tabs}
        components={components}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        isLoad={isLoad}
        skeleton={<SkeletonTask />}
      />
      <ModalWrapper
        show={modalData.open}
        handleCloseModal={() => setModalData(modalInitialData)}
        head={<h1 className={cls.commentModalHeader}>Комментарии</h1>}
      >
        <ModalComment name="" taskId={modalData.id} />
      </ModalWrapper>
    </div>
  );
};
