import { RootState } from "../../../../../store";

export const getExpertiseProjectList = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.expertiseProjectList;

export const getExpertiseProjectListIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseProjectList;

export const getExpertiseProjectPartList = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.expertiseProjectPartList;

export const getExpertiseProjectPartListIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseProjectPartList;
