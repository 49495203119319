import parse from "html-react-parser";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { CanCheckType } from "src/app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import styles from "./TransferFunds.module.scss";
import { useDepositFunds } from "../lib/hooks/useTransferFunds";

type Props = {
  canCheckData: CanCheckType;
  processId: number;
};

export const TransferFunds = ({ canCheckData, processId }: Props) => {
  const { transferDepositFunds } = useDepositFunds({ processId });

  return (
    <>
      <div
        className={
          canCheckData.showBtn ? styles.checkParseBtn : styles.checkParse
        }
      >
        {parse(canCheckData.msg)}
      </div>
      {canCheckData.showBtn && (
        <Modal.Controls>
          <Button width={250} onClick={transferDepositFunds}>
            Перевести средства
          </Button>
        </Modal.Controls>
      )}
    </>
  );
};
