import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import style from "./style.module.scss";
import { Wrapper } from "../../UI/templates";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiGetNdsList,
  ApiRemoveRequest,
  ApiLeaveFirm,
  ApiRemoveFirm,
} from "../../../app/services/api/organisation/organisation";
import {
  selectorOrganisationPageContentData,
  setOrganisationPageContentData,
  setOrganisationPageContentDefault,
} from "../../../app/feature/organisationPage/organisationPage";
import {
  OrganisationPageStateType,
  OrganisationResponseType,
  OrganisationTabsMenuType,
  CountryType,
} from "../../../types/stateTypes/organisationType";
import {
  getUserTokenByLocalStorage,
  parseTokenBody,
  userIsAuth,
} from "../../../app/services/auth/auth";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import { useTitle } from "../../hooks/useTitle";
import TitlePanel from "../../newUI/TitlePanel/TitlePanel";
import Task from "./tabs/Task";
import TabsPanel, { areFormsEqual } from "../../newUI/TabsPanel/TabsPanel";
import Settings from "./tabs/Settings/Settings";
import Staff from "./tabs/Staff";
import Milestone from "./tabs/Milestone/Milestone";
import {
  ClearSquareSVG,
  DoorOpenSVG,
  PersonSquare,
  TeamSVG,
} from "../../newUI/SVG";
import Accounts from "./tabs/BankAccount/NewForm/BankAccount";
import Appliances from "./tabs/Appliances";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { RedirectToExternal } from "../../features/RedirectToExternal/RedirectToExternal";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useSnackbar } from "../../hooks/useSnackbar";
import { getOrganisation, getOrganisationStaff } from "./requests";
import {
  initialAdminTabs,
  initialEditTabsFirst,
  initialEditTabsSecond,
  initialStaffTab,
  initialTabs,
  initialWalletTab,
  initialListsTab,
  initialBankAccountTabs,
} from "./fragments";
import { palette } from "../../../styles/restyle";
import Lists from "./tabs/Lists";
import useTabs from "../../hooks/useTabs";
import OrganisationForm from "./tabs/Info/Form";
import { useAppSelector } from "../../../app/store";
import { getForms } from "../../../app/feature/unsaved/unsavedController";
import ProjectView from "../Project/View";
import { setVisible } from "../../../app/feature/modalController/modalVisibility";

const INFO = "info";
const ROLES = "roles";

const sx = { fontSize: 26, color: "white", width: 40 };

export const OrganisationPage = () => {
  const isAuth = userIsAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string; tab: string }>();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const { Popup } = useUserWorkflow();

  const isMobile = useIsMobile();

  const [canLeave, setCanLeave] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<
    number | null | undefined
  >();
  const [exists, setExists] = useState(true);
  const [roles, setRoles] = useState<any>({});

  const [defaultTab, setDefaultTab] = useState<OrganisationTabsMenuType[]>([]);
  const [staffTab, setStaffTab] = useState<OrganisationTabsMenuType[]>([]);
  const [listsTab, setListsTab] = useState<OrganisationTabsMenuType[]>([]);
  const [adminTabs, setAdminTabs] = useState<OrganisationTabsMenuType[]>([]);
  const [editTabs, setEditTabs] = useState<OrganisationTabsMenuType[]>([]);
  const [walletTab, setWalletTab] = useState<OrganisationTabsMenuType[]>([]);
  const [bankAccountTab, setBankAccountTab] = useState<
    OrganisationTabsMenuType[]
  >([]);

  const [ndsList, setNdsList] = useState<CountryType[]>([]);

  const [unsaved, setUnsaved] = useState<boolean>(false);

  const [canEditLists, setCanEditLists] = useState<boolean>(false);

  const tabsData = [
    ...defaultTab,
    ...bankAccountTab,
    ...staffTab,
    ...adminTabs,
    ...editTabs,
    ...walletTab,
    ...listsTab,
  ];

  const [staffUserId, setStaffUserId] = useState<number | null>();

  const [searchString] = useState("");
  const [isContentLoad, setContentLoad] = useState(true);

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const [currentTab, setCurrentTab] = useState<string>("");
  const [editing, setEditing] = useState(false);
  const [toTab, setToTab] = useState<string>("");

  const data: OrganisationPageStateType = useSelector(
    selectorOrganisationPageContentData
  );

  const { inbound, outbound } = useAppSelector(getForms);

  useTabs(currentTab, setCurrentTab, INFO);

  useTitle(data.info.title);

  useEffect(() => {
    (async () => {
      const roles: OrganisationResponseType = await getOrganisation(
        ROLES,
        params,
        searchString
      );
      setRoles(roles.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setContentLoad(true);

      if (Number.isNaN(Number(params.id))) {
        setExists(false);
        return;
      }

      const info: OrganisationResponseType = await getOrganisation(
        INFO,
        params,
        searchString
      );

      if (info.status === 404) {
        setExists(false);
      }

      let staff: OrganisationResponseType = {
        data: [],
        meta: null,
        status: 0,
      };

      if (info.data.can.isMember || info.data.can.isAdmin) {
        staff = await getOrganisationStaff(params, "");
        setStaffTab([...initialStaffTab]);
      }

      if (info.data.can.editBankAccount || info.data.can.isAdmin) {
        setBankAccountTab([...initialBankAccountTabs]);
      }

      if (info.data.can.edit) {
        const list = info.data.milestone
          ? initialEditTabsFirst
          : initialEditTabsFirst.filter((tab) => tab.url !== "milestone");

        setEditTabs(list);
      }

      if (info.data.account_id) {
        const tabs = [];

        if (info.data.can.viewAccount) {
          tabs.push({
            ...initialWalletTab,
            url: `/account/view?id=${info.data.account_id}`,
          });
        }

        if (info.data.can.edit) {
          tabs.push({
            ...initialEditTabsSecond,
            url: `/task-report/report?firmId=${params.id}`,
          });
        }

        setWalletTab(tabs);
      } else if (info.data.can.edit) {
        setWalletTab([
          {
            ...initialEditTabsSecond,
            url: `/task-report/report?firmId=${params.id}`,
          },
        ]);
      }

      if (
        info.data.promote &&
        (info.data.can.isMember || info.data.can.isAdmin)
      ) {
        setListsTab([...initialListsTab]);
        setCanEditLists(true);
      }

      const history: OrganisationResponseType = {
        data: [],
        meta: null,
        status: 0,
      };

      if (
        (info.data.can.isAdmin || (!info.data.tested && info.data.can.edit)) &&
        info.data.taskCheck
      ) {
        setAdminTabs([...initialAdminTabs]);
      }

      const todayDateFormatted = moment(new Date(), "YYYY-MM-DD").format(
        "DD.MM.YYYY"
      );

      let isIncomplete = !!(
        info.data.title_full &&
        info.data.ogrn &&
        info.data.nds?.key &&
        info.data.city &&
        info.data.address &&
        info.data.manager_post &&
        info.data.manager_name &&
        info.data.boss
      );

      isIncomplete = isIncomplete && !!(info.data.is_ip ? true : info.data.kpp);

      isIncomplete =
        isIncomplete &&
        (info.data.is_ip
          ? info.data.doc_type?.title === undefined ||
            info.data.doc_type?.title === "Доверенность"
          : info.data.doc_type?.title === "Доверенность" ||
            info.data.doc_type?.title === "Устав");

      isIncomplete =
        isIncomplete &&
        !!(info.data.doc_type?.title === "Доверенность"
          ? info.data.boss_prof &&
            info.data.firmAttorney?.date_start &&
            info.data.firmAttorney?.date_end &&
            info.data.firmAttorney?.date_sign &&
            info.data.firmAttorney?.file?.url &&
            moment(todayDateFormatted, "DD.MM.YYYY").toDate() <=
              moment(info.data.firmAttorney?.date_end, "DD.MM.YYYY").toDate()
          : true);

      if (!isIncomplete) {
        const initialTabsEdited = initialTabs.map((item: any) =>
          item.url === "info" ? { ...item, incomplete: true } : item
        );
        setDefaultTab(initialTabsEdited);
      } else {
        setDefaultTab(initialTabs);
      }

      const token = getUserTokenByLocalStorage();
      if (token && parseTokenBody(token).data.id) {
        const currentUserId = parseTokenBody(token).data.id;
        setCurrentUserId(currentUserId);

        const numberOfSupervisors = staff.data.filter(
          (item: any) => item.role_id === 3
        );

        setCanLeave(
          info.data?.boss?.id !== currentUserId &&
            info.data.can?.isMember &&
            numberOfSupervisors.length > 1
        );

        if (info.data.can?.isMember) {
          const currentRole = staff.data.find(
            (user: any) => user.user_id === currentUserId
          );
          setStaffUserId(currentRole.id);
        }
      }

      dispatch(
        setOrganisationPageContentData({
          info: info.data,
          staff: staff.data,
          staffMeta: staff.meta,
          task: info.data.taskCheck,
          bank: info.data.bankAccounts ? info.data.bankAccounts : [],
          history: history.data,
          historyMeta: history.meta,
          requests: info.data.requestToJoin,
          can: info.data.can,
        })
      );

      setContentLoad(false);
    })();

    return () => {
      dispatch(setOrganisationPageContentDefault());
    };
  }, [searchString, modalClosed]);

  useEffect(() => {
    setUnsaved(false);
  }, [currentTab]);

  useEffect(() => {
    if (!exists) {
      navigate("/");
    }
  }, [navigate, exists, params.id, params.tab, isContentLoad]);

  useEffect(() => {
    (async () => {
      await request(ApiGetNdsList(), (res) => {
        const formattedList = res.map((item: any) => ({
          id: item.id,
          title: item.name,
        }));

        setNdsList(formattedList);
      })();
    })();
  }, []);

  useEffect(() => {
    if (data.can?.edit) {
      const list = data.info.milestone
        ? initialEditTabsFirst
        : initialEditTabsFirst.filter((tab) => tab.url !== "milestone");

      setEditTabs(list);
    }
  }, [data.info]);

  const handleRequestJoin = () => {
    Popup.joinRequest.set({
      title: data.title,
      id: params.id,
    });
    Popup.joinRequest.open();
  };

  const handleRequestTestedFirm = () => {
    Popup.editOrganisationTask.set({
      content: "",
      comment: "",
      id: params.id,
      taskId: null,
      files: [],
      title: data.title,
    });
    Popup.editOrganisationTask.open();
  };

  const handleEditOrganisationTask = () => {
    Popup.editOrganisationTask.set({
      id: params.id,
      taskId: data!.task!.id,
      files: data!.task!.files,
      title: data.title,
      comment: data!.task!.comment,
      content: data.task!.content,
    });
    Popup.editOrganisationTask.open();
  };

  const handleDeclineJoin = async () => {
    if (window.confirm("Вы уверены что хотите отозвать заявку?")) {
      await request(
        ApiRemoveRequest(data.info.joinToFirmTasks!.id),
        () => {
          successSnackbar("Заявка успешно отозвана");
        },
        () => (err) => {
          if (err.status === 403) {
            errorSnackbar(`${err.message}`);
          } else {
            errorSnackbar("Ошибка отозвания заявки");
          }
        }
      )();

      dispatch(setModalClosed(!modalClosed));
    }
  };

  const handleLeaveFirm = async () => {
    if (window.confirm("Вы уверены что хотите выйти из организации?")) {
      await request(
        ApiLeaveFirm(staffUserId!),
        () => {
          successSnackbar("Вы успешно покинули организацию");
        },
        () => (err) => {
          if (err.status === 403) {
            errorSnackbar(`${err.message}`);
          } else {
            errorSnackbar("Ошибка при выходе из организации");
          }
        }
      )();

      dispatch(setModalClosed(!modalClosed));
    }
  };

  const handleDeleteFirm = async () => {
    if (window.confirm("Вы уверены, что хотите удалить организацию?")) {
      await request(
        ApiRemoveFirm(Number(params.id)!),
        () => {
          successSnackbar("Организация успешно удалена");
        },
        () => (err) => {
          if (err.status === 403) {
            errorSnackbar(`${err.message}`);
          } else {
            errorSnackbar("Ошибка удаления организации");
          }
        }
      )();

      dispatch(setModalClosed(!modalClosed));
    }
  };

  const handleSetEdit = () => {
    if (
      currentTab === "info" ||
      currentTab === "accounts" ||
      currentTab === "settings"
    ) {
      setEditing(true);
    } else if (currentTab === "task") {
      handleEditOrganisationTask();
    }
  };

  const handleModalOnSwitch = (tab: string): boolean => {
    if (currentTab === tab) {
      return true;
    }

    if (unsaved || !areFormsEqual(inbound, outbound)) {
      setToTab(tab);
      dispatch(setVisible());
      return false;
    }

    return true;
  };

  const editingCondition = () => {
    const obj: any = {
      staff: false,
      application: false,
      accounts: data.can.editBankAccount,
    };

    return obj[currentTab] ?? true;
  };

  const handleCreateTeam = () => {
    Popup.createTeam.set({
      id: Number(params.id),
      userId: currentUserId,
    });
    Popup.createTeam.open();
  };

  const components = (
    <>
      {currentTab === "info" && (
        <OrganisationForm
          firmData={data}
          ndsList={ndsList}
          editingCondition={editingCondition}
          handleRequestTestedFirm={handleRequestTestedFirm}
          setUnsaved={setUnsaved}
          unsaved={unsaved}
        />
      )}
      {currentTab === "accounts" && (
        <Accounts
          data={data.bank}
          editing={editing}
          setEditing={setEditing}
          handleSetEdit={handleSetEdit}
          sx={sx}
          isMobile={isMobile}
          access={data.can}
          editingCondition={editingCondition}
        />
      )}
      {currentTab === "staff" && (
        <Staff
          isMobile={isMobile}
          access={data.can}
          roles={roles}
          data={data.staff}
        />
      )}
      {currentTab === "task" && (
        <Task
          data={data.task}
          title={data.title}
          sx={sx}
          isMobile={isMobile}
          access={data.can}
          editingCondition={editingCondition}
          editing={editing}
          handleSetEdit={handleSetEdit}
        />
      )}
      {currentTab === "application" && (
        <Appliances isMobile={isMobile} data={data.requests} />
      )}
      {currentTab === "settings" && (
        <Settings
          data={data.info}
          editing={editing}
          access={data.can}
          setEditing={setEditing}
          editingCondition={editingCondition}
          handleSetEdit={handleSetEdit}
          isMobile={isMobile}
          sx={sx}
        />
      )}
      {currentTab === "lists" && (
        <Lists canBeEdited={canEditLists} firmId={params.id || ""} />
      )}
      {currentTab === "milestone" && <Milestone firmId={Number(params.id)} />}
    </>
  );

  if (!isAuth) return <RedirectToExternal to={"/site/login"} />;

  return (
    <div className={style.wrapper}>
      <Wrapper>
        <TitlePanel
          isContentLoad={isContentLoad}
          setEditing={setEditing}
          primaryText={data.title}
          secondaryText={data.info?.tested ? "Проверенная" : "Не проверенная"}
          can={data.can}
        >
          {!editing && (
            <>
              {data.can?.createTeam &&
                currentTab === "staff" &&
                (isMobile ? (
                  <IconButton sx={sx} onClick={handleCreateTeam}>
                    <TeamSVG />
                  </IconButton>
                ) : (
                  <CustomButton
                    width={160}
                    onClick={handleCreateTeam}
                    background={palette.green}
                  >
                    Создать команду
                  </CustomButton>
                ))}
              {data.can?.delete && (
                <IconButton onClick={handleDeleteFirm}>
                  <Delete style={{ color: "white" }} />
                </IconButton>
              )}
              {data.can?.sendJoinRequest &&
                (isMobile ? (
                  <IconButton sx={sx} onClick={handleRequestJoin}>
                    <PersonSquare />
                  </IconButton>
                ) : (
                  <CustomButton
                    width={180}
                    onClick={handleRequestJoin}
                    background={palette.green}
                  >
                    Запрос на вступление
                  </CustomButton>
                ))}
              {!data.can?.isMember &&
                data.info?.joinToFirmTasks &&
                (isMobile ? (
                  <IconButton sx={sx} onClick={handleDeclineJoin}>
                    <ClearSquareSVG />
                  </IconButton>
                ) : (
                  <CustomButton
                    width={160}
                    onClick={handleDeclineJoin}
                    background={palette.red}
                  >
                    Отозвать заявку
                  </CustomButton>
                ))}
              {canLeave &&
                (isMobile ? (
                  <IconButton sx={sx} onClick={handleLeaveFirm}>
                    <DoorOpenSVG />
                  </IconButton>
                ) : (
                  <CustomButton
                    width={210}
                    onClick={handleLeaveFirm}
                    background={palette.red}
                  >
                    Выйти из организации
                  </CustomButton>
                ))}
            </>
          )}
        </TitlePanel>
        <div className={style.contents}>
          <TabsPanel
            isContentLoad={isContentLoad}
            setEditing={setEditing}
            tabsInfo={tabsData}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            components={components}
            onSwitching={handleModalOnSwitch}
          />
        </div>
        <ProjectView toTab={toTab} setCurrentTab={setCurrentTab} />
      </Wrapper>
    </div>
  );
};
