import { RootState } from "../../../../store";

export const getProjectProcessInfoWarningInput = (state: RootState) =>
  state.projectProcessView.info.warningInput;

export const getProjectProcessInfoWarningActual = (state: RootState) =>
  state.projectProcessView.info.warningActual;

export const getProjectProcessInfoWarningOutput = (state: RootState) =>
  state.projectProcessView.info.warningOutput;

export const getProjectProcessInfoWarningRequired = (state: RootState) =>
  state.projectProcessView.info.warningRequired;
