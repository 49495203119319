import { RootState } from "../../../../store";

export const getProjectProcessSourceChild = (state: RootState) =>
  state.projectProcessView.initialData.sourceChild;

export const getProjectProcessSourceChildIsLoad = (state: RootState) =>
  state.projectProcessView.initialData.isLoadSourceChild;

export const getProjectProcessOpenSourceData = (state: RootState) =>
  state.projectProcessView.initialData.openSourceData;
