import { createSlice } from "@reduxjs/toolkit";
import { modalsInitialState } from "./modelState";
import { RootState } from "../../../../app/store";

const modalsSlice = createSlice({
  name: "taskModalsSlice",
  initialState: modalsInitialState,
  reducers: {
    setCommentsActive(state, action) {
      state.comments = action.payload;
    },
  },
});

export const { setCommentsActive } = modalsSlice.actions;

export const getModalsData = (state: RootState) => state.task.modals;

export default modalsSlice.reducer;
