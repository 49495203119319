import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseRemarkStart } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

export const fetchExpertiseRemarkStart = createAsyncThunk<boolean, any>(
  "expertise/fetchExpertiseRemarkStart",
  async (remarkId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseRemarkStart(remarkId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
