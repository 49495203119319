import React, { memo, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import cls from "./ModalCreateTask.module.scss";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../styles/restyle";
import { fetchExpertiseProjectList } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectList";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertiseProjectList,
  getExpertiseProjectListIsLoad,
  getExpertiseProjectPartList,
  getExpertiseProjectPartListIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseProjectList";
import { fetchExpertiseProjectPartList } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProcesses";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { SelectField } from "../../../../../newUI/SelectMui/SelectMui";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import { fetchProjectProcessCreateTask } from "../../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessCreateTask";
import { fetchProjectProcessOutTasks } from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import useNonInitialEffect from "../../../../../hooks/useNonInitialEffect";

const schema = Yup.object().shape({
  toProcessesIds: Yup.mixed().test(
    "required",
    "Необходимо указать хотя бы один раздел",
    (value: any) => {
      return Array.isArray(value) && Boolean(value.length);
    }
  ),
});

type TInputs = {
  toProjectId: string;
  toProcessesIds: string[];
  upload_files: any;
  content: string;
};

interface ModalCreateTaskProps {
  projectId: string;
  closeModal: () => void;
}

export const ModalCreateTask = memo(
  ({ projectId, closeModal }: ModalCreateTaskProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    const projectList = useAppSelector(getExpertiseProjectList);
    const projectPartList = useAppSelector(getExpertiseProjectPartList);
    const isLoadProjectList = useAppSelector(getExpertiseProjectListIsLoad);
    const isLoadProjectPartList = useAppSelector(
      getExpertiseProjectPartListIsLoad
    );

    const [valueMulti, setValueMulti] = useState<string[] | number[]>([]);

    useEffect(() => {
      if (processId) {
        dispatch(fetchExpertiseProjectList(processId));
      }
    }, [processId]);

    const {
      register,
      handleSubmit,
      setValue,
      watch,
      trigger,
      formState: { errors },
    } = useForm<TInputs>({
      defaultValues: {
        toProjectId: projectId,
      },
      resolver: yupResolver(schema),
      mode: "onChange",
    });

    const projectIdValue = watch("toProjectId");
    const toProcessesIdsValue = watch("toProcessesIds");

    useNonInitialEffect(() => {
      trigger("toProcessesIds");
    }, [toProcessesIdsValue]);

    useEffect(() => {
      if (processId && projectIdValue) {
        dispatch(
          fetchExpertiseProjectPartList({
            projectId: +projectIdValue,
            processId,
          })
        );
      }
    }, [projectIdValue]);

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();

      formData.append("content", dataValues?.content);
      formData.append("toProjectId", dataValues?.toProjectId);
      formData.append("status", "6");
      dataValues.toProcessesIds.forEach((id: string) => {
        formData.append("toProcessesIds[]", id);
      });
      dataValues.upload_files.forEach((file: BinaryType) => {
        formData.append("upload_files[]", file);
      });
      if (processId) {
        await dispatch(fetchProjectProcessCreateTask({ processId, formData }));
        dispatch(fetchProjectProcessOutTasks(processId));
      }
      closeModal();
    };

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all") && projectPartList) {
        const all = Object.keys(projectPartList);
        setValueMulti(all);
        setValue("toProcessesIds", all);
      } else {
        setValueMulti(event.target.value);
        setValue("toProcessesIds", event.target.value);
      }
    };

    const handleClearClick = () => {
      setValueMulti([]);
      setValue("toProcessesIds", []);
    };

    const mapExpertiseProjectList = projectList
      ? projectList?.map((project) => (
          <MenuItem key={project.id} sx={menuItemSx} value={project.id}>
            {project.name}
          </MenuItem>
        ))
      : null;

    if (isLoadProjectList) {
      return <ModalPreloader />;
    }

    return (
      <form className={cls.form} onSubmit={handleSubmit(formSubmitHandler)}>
        <h1>Выдать задание</h1>
        <FormControl {...textFieldSX} className={cls.form_tasks}>
          <InputLabel id="project_id">Объект</InputLabel>
          <Select
            {...register("toProjectId")}
            defaultValue={projectId}
            labelId="project_id"
            label="Объект"
          >
            {mapExpertiseProjectList}
          </Select>
        </FormControl>
        <SelectField
          {...register("toProcessesIds")}
          name="project_part_ids"
          label="Разделы"
          disabled={isLoadProjectPartList}
          multiple
          multiValue={valueMulti}
          isAllClick
          onChange={onChangeMulti}
          options={(projectPartList && Object.entries(projectPartList)) || []}
          handleClearClick={handleClearClick}
          error={errors.toProcessesIds ? errors.toProcessesIds.message : null}
        />
        <Uploader
          canBeEdited
          autoUpload={false}
          identifier={null}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          fileHandler={(data: any) => {
            setValue("upload_files", data);
          }}
          uploadAsActive
        />
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("content")}
          defaultValue={""}
          placeholder="Введите текст"
          error={!!errors.content}
          helperText={errors.content ? errors.content.message : null}
        />
        <CustomButton
          className={cls.form_submit}
          background={palette.green}
          width={160}
          type="submit"
        >
          Отправить
        </CustomButton>
      </form>
    );
  }
);
