import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import cls from "./HeaderRemarks.module.scss";
import { AddFile, FilterIcon } from "../../../../../newUI/SVG";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getCanProcessInfo } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { iconBtnSx } from "../../../../../../styles/restyle";
import { setVisible } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import { getExpertiseFilterVisible } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarkFilter";
import { Filter } from "./Filter/Filter";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalCreateRemark } from "./ModalCreateRemark/ModalCreateRemark";
import { getExpertiseRemarkCreateIsLoad } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";

export const HeaderRemarks = memo(() => {
  const dispatch = useAppDispatch();
  const can = useAppSelector(getCanProcessInfo);
  const filterVisible = useAppSelector(getExpertiseFilterVisible);
  const isLoadCreateRemark = useAppSelector(getExpertiseRemarkCreateIsLoad);

  const [modalCreateRemark, setModalCreateRemark] = useState(false);
  const closeModal = useCallback(() => {
    setModalCreateRemark(false);
  }, []);
  function toggleVisibleFilter() {
    dispatch(setVisible());
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.header}>
        <h1>Замечания</h1>
        <div className={cls.header_btn}>
          {can?.edit && (
            <>
              <IconButton
                sx={iconBtnSx}
                onClick={() => setModalCreateRemark(true)}
              >
                <AddFile />
              </IconButton>
              <Modal
                active={modalCreateRemark}
                setActive={setModalCreateRemark}
                isLoad={isLoadCreateRemark}
              >
                <ModalCreateRemark closeModal={closeModal} />
              </Modal>
            </>
          )}
          <IconButton sx={iconBtnSx} onClick={toggleVisibleFilter}>
            <FilterIcon />
          </IconButton>
        </div>
      </div>
      {filterVisible && <Filter />}
    </div>
  );
});
