import { IndexTasksPageDataType } from "../../../../types/stateTypes/tasksStateTypes";

export const tasksPageInitialState: IndexTasksPageDataType = {
  tasks: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
