import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { TProjectProcessCheckList } from "../../../../../../app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { fetchProjectProcessSourceDataUpdate } from "../../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceDataUpdate";
import { SourceDataUpdateForm } from "../../../../../newUI/SourceDataUpdateForm/SourceDataUpdateForm";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProject,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "../../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";

interface ModalGearProps {
  parentId: number;
  projectId: number;
  handleDeleteSourceDataName: (id: number) => void;
  checkList: TProjectProcessCheckList[];
  name: string;
  index: number;
  closeModal: () => void;
  type: number;
}
export const ModalGear = ({
  projectId,
  parentId,
  checkList,
  closeModal,
  name,
  handleDeleteSourceDataName,
  type,
}: ModalGearProps) => {
  const dispatch = useAppDispatch();
  const projectData = useAppSelector(getProjectProcessInfoProject);
  const infoId = useAppSelector(getProjectProcessInfoId);

  const formSubmitHandler = async (dataValue: TProjectProcessCheckList[]) => {
    const data = dataValue.map((el) => {
      return {
        id: el.id,
        select: el.author,
        executor: el.executor,
        critical: el.critical,
        hidden: el.hidden,
        name: el.name,
      };
    });
    await dispatch(
      fetchProjectProcessSourceDataUpdate({
        projectId,
        parentId,
        data,
        type,
      })
    );
    closeModal();
    await dispatch(fetchProjectProcessInitialData(infoId as number));
  };

  return (
    <SourceDataUpdateForm
      name={name}
      formSubmitHandler={formSubmitHandler}
      checkList={checkList}
      closeModal={closeModal}
      canExecutorEdit={!!projectData?.can?.edit}
      handleDeleteSourceDataName={handleDeleteSourceDataName}
    />
  );
};
