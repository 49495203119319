import { useEffect, useRef, useState } from "react";
import { checkPrices } from "../helpers/checkPrices";
import { Steps } from "../../model/constants";
import { TFieldValues, TProcessesPriceLimits } from "../../model/types";
import { Props } from "../../ui/ProcessesForm";
import { setPriceLimits } from "../helpers/setPriceLimits";
import { setPriceValues } from "../helpers/setPriceValues";

type UseModalProps = Omit<Props, "isRemovingExecutor">;

export const useProcessesForm = ({
  dataRemoveExecutor,
  onSubmit,
  form,
}: UseModalProps) => {
  const {
    watch,
    control,
    register,
    clearErrors,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const [processesPriceLimits, setProcessesPriceLimits] =
    useState<TProcessesPriceLimits>({});

  const isInitialEffect = useRef<boolean>(true);

  const paidByProcessesValue = watch("advancesPaidByProcesses");

  useEffect(() => {
    if (!isInitialEffect.current) {
      return;
    }

    setPriceLimits(
      dataRemoveExecutor,
      processesPriceLimits,
      setProcessesPriceLimits
    );
    isInitialEffect.current = false;
  }, []);

  const onPreSubmit = (data: TFieldValues) => {
    const pricesAreCorrect = checkPrices(
      data.processes,
      processesPriceLimits,
      setError,
      clearErrors
    );

    if (pricesAreCorrect) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (!dataRemoveExecutor) {
      return;
    }

    setPriceValues(dataRemoveExecutor, setValue);
  }, [dataRemoveExecutor, paidByProcessesValue]);

  const handleGoBack = () => {
    setValue("currentStep", Steps.REFUND);
  };

  return {
    handleSubmit,
    onPreSubmit,
    control,
    register,
    paidByProcessesValue,
    errors,
    handleGoBack,
    clearErrors,
  } as const;
};
