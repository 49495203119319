import React, { memo, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { MileStoneTabIcon } from "../../SVG";
import cls from "./MileStoneCheck.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { fetchProjectProcessStageComplete } from "../../../../app/feature/ProjectProcessView/MileStone/services/fetchProjectProcessStageComplete";
import { fetchProjectProcessStage } from "../../../../app/feature/ProjectProcessView/MileStone/services/fetchProjectProcessStage";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { iconBtnSx } from "../../../../styles/restyle";
import { ModalConfirm } from "./ModalConfirm/ModalConfirm";
import Modal from "../../Modal/Modal";
import { MILESTONE_TYPE } from "../MileStoneCard";
import { getProjectProcessMileStoneCompleteIsLoad } from "../../../../app/feature/ProjectProcessView/MileStone/selectors/getProjectProcessMileStone";
import { fetchProjectMilestone } from "../../../../app/feature/ProjectView/milestone/services/fetchProjectMilestone";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { config } from "../../../../app.cofig";

interface MileStoneCheckProps {
  down: boolean;
  text: string;
  status: "greenCheck" | "grayCheck" | "gray" | "";
  stageId: number;
  link: string;
  canMile: boolean;
  type: MILESTONE_TYPE;
  processName?: string;
}
export const MileStoneCheck = memo(
  ({
    down,
    text,
    status,
    stageId,
    canMile,
    type,
    processName,
    link,
  }: MileStoneCheckProps) => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const greenCheck = status === "greenCheck";
    const gray = status === "gray";
    const grayCheck = status === "grayCheck";

    const processId = useAppSelector(getProjectProcessInfoId);
    const isLoadComplete = useAppSelector(
      getProjectProcessMileStoneCompleteIsLoad
    );

    const [modalConfirm, setModalConfirm] = useState(false);

    async function completeMileStoneHandler(result: boolean) {
      if (result && processId && type === MILESTONE_TYPE.PROCESS) {
        await dispatch(fetchProjectProcessStageComplete(stageId));
        dispatch(fetchProjectProcessStage(processId));
        setModalConfirm(false);
      }
      if (result && type === MILESTONE_TYPE.PROJECT && params.id) {
        await dispatch(fetchProjectProcessStageComplete(stageId));
        dispatch(fetchProjectMilestone({ projectId: +params.id, load: true }));
        setModalConfirm(false);
      }
      if (!result) {
        setModalConfirm(false);
      }
    }

    return (
      <div className={classNames(!down && cls.check, down && cls.checkDown)}>
        <div
          className={classNames(
            !down && cls.footnote,
            down && cls.footnoteDown
          )}
        >
          {link ? (
            <TextLink
              redirect
              target="_blank"
              url={`${config.localDomain}${link}`}
              variant={classNames(cls.name, link && cls.link)}
            >
              <p>{text}</p>
            </TextLink>
          ) : (
            <div className={cls.name}>
              <p>{text}</p>
            </div>
          )}
          <span />
        </div>
        {canMile && (
          <Modal
            active={modalConfirm}
            setActive={setModalConfirm}
            isLoad={isLoadComplete}
          >
            <ModalConfirm
              text={text}
              processName={processName}
              completeMileStoneHandler={completeMileStoneHandler}
            />
          </Modal>
        )}
        <div
          className={classNames(
            cls.icon,
            greenCheck && cls.green,
            grayCheck && cls.gray
          )}
        >
          {greenCheck && <MileStoneTabIcon width={"36"} height={"36"} />}
          {canMile && (
            <IconButton sx={iconBtnSx} onClick={() => setModalConfirm(true)}>
              <MileStoneTabIcon width={"36"} height={"36"} />
            </IconButton>
          )}
          {grayCheck && !canMile && (
            <>
              <MileStoneTabIcon width={"36"} height={"36"} />
            </>
          )}
          {!canMile && gray && <div className={cls.circle} />}
        </div>
      </div>
    );
  }
);
