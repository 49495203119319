import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment/moment";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { DateField } from "../../../../../../features/SpecialForm/DateField/DateField";
import styles from "./FilterModal.module.scss";
import {
  applyFilter,
  getProjectHistory,
  resetData,
  setCountFilter,
  setDate1,
  setDate2,
  setEvents,
  setUser,
} from "../../../../../../../app/feature/ProjectView/HistoryReducer/projectHistory";
import { useAppDispatch } from "../../../../../../../app/store";
import { Select } from "../../../../../../UI/components/FormComponentns/Select";
import { Input } from "../../../../../../UI/components/FormComponentns/Input";

function filterUniqueArray(arr: any) {
  const uniqueValues = new Set();
  const filteredArray = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const key = JSON.stringify(item);

    if (!uniqueValues.has(key)) {
      uniqueValues.add(key);
      filteredArray.push(item);
    }
  }

  return filteredArray;
}

const parseDate = (value: Date, originalValue: string) =>
  moment(originalValue).isValid()
    ? value
    : moment(originalValue, "DD.MM.YYYY").toDate();

type TInputs = {
  date1: Date | undefined | null;
  date2: Date | undefined | null;
};

const schema = Yup.object().shape({
  date2: Yup.date()
    .transform(parseDate)
    .min(Yup.ref("date1"), "Конец периода не может быть раньше начального"),
});

interface FilterModalProps {
  closeModal: () => void;
}

export const FilterModal = ({ closeModal }: FilterModalProps) => {
  const dispatch = useAppDispatch();
  const { date1, date2, events, newTypes, user } =
    useSelector(getProjectHistory);
  const [recentFilter, setRecentFilter] = useState([]);

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputs>({
    defaultValues: {
      date1,
      date2,
    },
    resolver: date1 && date2 ? yupResolver(schema) : undefined,
  });

  useEffect(() => {
    const sessionValue = sessionStorage.getItem("recent");
    const storedFilters = sessionValue ? JSON.parse(sessionValue) : [];
    setRecentFilter(storedFilters);
  }, []);
  function onChangeDate1(date: any) {
    dispatch(setDate1(date));
  }

  function onChangeDate2(date: any) {
    dispatch(setDate2(date));
  }

  function onChangeEvents(events: any) {
    dispatch(setEvents(events));
  }

  function onChangeUser(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setUser(event.target.value));
  }

  function resetClick() {
    dispatch(setCountFilter(0));
    dispatch(resetData());
  }

  async function saveFilter() {
    const filterData = {
      userFio: user,
      date1,
      date2,
      type: events ?? { id: "", title: "" },
    };

    const buferData = Object.entries(filterData).reduce(
      (acc: any, [key, value]) => {
        // @ts-ignore
        if (key === "type" && value?.id) {
          acc.push([key, value]);
        }
        if (value && key !== "type") {
          acc.push([key, value]);
        }
        return acc;
      },
      []
    );

    const updateFilter = [...buferData, ...recentFilter];
    const uniqueFilter = filterUniqueArray(updateFilter).slice(0, 10);

    if (Object.keys(errors).length === 0) {
      const countFilter = Object.values(filterData).filter(Boolean).length;
      sessionStorage.setItem("recent", JSON.stringify(uniqueFilter));
      dispatch(setCountFilter(countFilter));
      dispatch(applyFilter(filterData));
      closeModal();
    }
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(saveFilter)}>
      <div className={styles.block}>
        <p>Период события</p>
        <div className={styles.dates}>
          <Controller
            control={control}
            name="date1"
            render={() => (
              <DateField
                newDesign
                placeholderText={"от __.__.____"}
                startDateProp={date1}
                changeDateHandler={(value) => {
                  onChangeDate1(value);
                  setValue("date1", value);
                  trigger("date1");
                }}
                error={!!errors.date1}
                helperText={errors.date1?.message ?? undefined}
              />
            )}
          />
          <Controller
            control={control}
            name="date2"
            render={() => (
              <DateField
                newDesign
                placeholderText={"до __.__.____"}
                startDateProp={date2}
                changeDateHandler={(value) => {
                  onChangeDate2(value);
                  setValue("date2", value);
                  trigger("date2");
                }}
                error={!!errors.date2}
                helperText={errors.date2?.message ?? undefined}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.block}>
        <p>Событие</p>
        <Select
          label={""}
          value={events}
          name={"events"}
          changeHandler={(_, value) => {
            onChangeEvents(value);
          }}
          options={newTypes}
          isLoading={false}
          disableClear
          newDesign
        />
      </div>
      <div className={styles.block}>
        <p>Пользователь</p>
        <Input
          value={user}
          autoComplete={"off"}
          onChange={onChangeUser}
          placeholder={"Введите ФИО"}
        />
      </div>
      <div className={styles.btn}>
        <Button variant="outlined" onClick={resetClick}>
          Сбросить
        </Button>
        <Button type={"submit"}>Показать</Button>
      </div>
    </form>
  );
};
