import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  editing?: boolean;
  disabled?: boolean;
  sign?: boolean;
  showSign?: boolean;
  grey?: boolean;
  inactive?: boolean;
  classname?: string;
  value?: any;
  name?: string;
  id?: string;
  title?: string;
  type?: string;
};

const CustomCheckbox: React.FC<Props> = ({
  onChange,
  checked,
  editing,
  disabled,
  sign,
  showSign,
  grey,
  inactive,
  classname,
  value,
  name,
  id,
  title,
  type = "checkbox",
}) => (
  <input
    className={classNames(
      style.checkbox,
      editing && style.checked,
      showSign ? (sign ? style.plus : style.minus) : "",
      grey && style.grey,
      (disabled || inactive) && style.disabled,
      classname && [classname]
    )}
    title={title}
    type={type}
    onChange={onChange}
    checked={checked}
    disabled={disabled}
    value={value}
    name={name}
    id={id}
  />
);

export default CustomCheckbox;
