import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectCheckTask = (
  id: number,
  status: string
): Promise<Response> => {
  // получить дерево Проверки
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-sub-check-tasks?id=${id}&status=${status}`
  );
};
