import KanbanModals from "src/FSD/widgets/v2/kanban/modals";
import { Filter, TaskCompletionModals } from "src/FSD/widgets/v2/kanban";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useKanbanPage } from "../lib/hooks";
import KanbanTablet from "./KanbanTablet";
import KanbanDesktop from "./KanbanDesktop";
import styles from "./Kanban.module.scss";

export const KanbanPage = () => {
  const {
    isMobile,
    isTablet,
    isCreateFreeTaskOpen,
    isEditFreeTaskOpen,
    editFreeTaskId,
    handleCloseCreateFreeTaskModal,
    handleCloseEditFreeTaskModal,
    onFreeTaskDelete,
    onFreeTaskUpdate,
    viewTask,
    handleCloseViewTaskModal,
  } = useKanbanPage();

  if (isMobile) {
    return null;
  }

  return (
    <>
      <TaskCompletionModals />
      <Filter />
      <Modal isActive={viewTask.isOpen} handleClose={handleCloseViewTaskModal}>
        <KanbanModals.ViewTaskInfo
          taskId={viewTask.taskId!}
          header={viewTask.header!}
          isCompletion={viewTask.isCompletion!}
        />
      </Modal>
      <Modal
        isActive={isCreateFreeTaskOpen}
        handleClose={handleCloseCreateFreeTaskModal}
      >
        <KanbanModals.CreateFreeTaskForm />
      </Modal>
      <Modal
        isActive={isEditFreeTaskOpen}
        handleClose={handleCloseEditFreeTaskModal}
      >
        <KanbanModals.EditFreeTaskForm
          taskId={editFreeTaskId!}
          onTaskDelete={onFreeTaskDelete}
          onTaskUpdate={onFreeTaskUpdate}
        />
      </Modal>
      <div className={styles.wrapper}>
        {isTablet ? <KanbanTablet /> : <KanbanDesktop />}
      </div>
    </>
  );
};
