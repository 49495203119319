import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TTabsPanel } from "../../model/types";
import { createTabsData } from "../helpers/createTabsData";
import { getCurrentTabChildren } from "../helpers/getCurrentTabChildren";
import useNonInitialEffect from "../../../../../../components/hooks/useNonInitialEffect";

type TUseTabsPanel = {
  tabsList?: TTabsPanel.TabsList;
  componentsList: TTabsPanel.ComponentsList;
};

export const useTabsPanel = ({ tabsList, componentsList }: TUseTabsPanel) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get("tab") ?? "info";
  const currentChildTab = searchParams.get("horizontalTab") ?? "";

  const setCurrentTab = (tab: string) => {
    setSearchParams({ tab, horizontalTab: currentChildTab });
  };

  const setCurrentChildTab = (horizontalTab: string | number) => {
    setSearchParams(
      horizontalTab
        ? { tab: currentTab, horizontalTab: String(horizontalTab) }
        : { tab: currentTab }
    );
  };

  const [tabs, setTabs] = useState<TTabsPanel.Tab[]>([]);

  const [currentTabChildren, setCurrentTabChildren] = useState<
    TTabsPanel.Tab[]
  >([]);

  useEffect(() => {
    const tabsArray = createTabsData(tabsList);
    setTabs(tabsArray);
  }, [tabsList]);

  useNonInitialEffect(() => {
    const currentChildItems = getCurrentTabChildren(tabs, currentTab);
    setCurrentTabChildren(currentChildItems);

    setCurrentChildTab("");

    if (currentChildItems.length) {
      const foundChildTab = currentChildItems.find(
        ({ name }) => name === currentChildTab
      );

      const active = foundChildTab?.name || currentChildItems[0]?.name;
      setCurrentChildTab(active);
    }
  }, [tabs, currentTab]);

  const checkComponent = componentsList[currentTab] || componentsList?.info;

  return {
    tabs,
    currentTab,
    setCurrentTab,
    currentTabChildren,
    currentChildTab,
    setCurrentChildTab,
    checkComponent,
  } as const;
};
