import React, { memo } from "react";
import classNames from "classnames";
import classes from "./style.module.scss";

type PayBarProps = {
  pay1: number;
  pay2: number;
  pay3: number;
  titlePay1?: string;
  titlePay2?: string;
  titlePay3?: string;
  classname?: string;
};
export const PayBar: React.FC<PayBarProps> = memo(
  ({ pay1, pay2, pay3, titlePay1, titlePay2, titlePay3, classname }) => {
    return (
      <>
        <div className={classNames(classes.info, classname)}>
          <div className={classNames(classes.flex, !pay1 && classes.hidden)}>
            <div className={classes.wrap_pay}>
              <div
                className={classNames(classes.pay, classes.pay1)}
                style={{ width: `${pay1 ?? "0"}%`, minWidth: "20%" }}
              >
                <span className={classes.text_center}>{`${pay1}%`}</span>
              </div>
              <p className={classes.text}>{titlePay1}</p>
            </div>
          </div>
          <div className={classNames(classes.flex, !pay2 && classes.hidden)}>
            <div className={classes.wrap_pay}>
              <div
                className={classNames(classes.pay, classes.pay2)}
                style={{ width: `${pay2 ?? "0"}%`, minWidth: "13%" }}
              >
                <span className={classes.text_center}>{`${pay2}%`}</span>
              </div>
              <p className={classes.text}>{titlePay2}</p>
            </div>
          </div>
          <div className={classNames(classes.flex, !pay3 && classes.hidden)}>
            <div className={classes.wrap_pay}>
              <div
                className={classNames(classes.pay, classes.pay3)}
                style={{ width: `${pay3 ?? "0"}%`, minWidth: "13%" }}
              >
                <span className={classes.text_center}>{`${pay3}%`}</span>
              </div>
              <p className={classes.text}>{titlePay3}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
);
