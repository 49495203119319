/*
  Функция делает проверку, в каком режиме было запущено приложение

  @example
  if(isDevelopment()){
    runYourDevFunction()
  }else{
    runYourBuildFunction()
  }

  @returns
  возвращает true если запуск произведен на localhost/ false в случае если билд был собран на прод;

 */
export const isDevelopment = (): boolean => {
  if (!process.env.NODE_ENV) return true;
  return process.env.NODE_ENV === "development";
};
