import React, { useCallback, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { CheckIconType } from "src/components/newUI/CheckIconType/CheckIconType";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { getProjectProcessAdditionalIsLoad } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessAdditional";
import { Addition } from "../../modals/Addition/Addition";
import styles from "./Requirements.module.scss";

export const Requirements = () => {
  const isLoadAdditional = useAppSelector(getProjectProcessAdditionalIsLoad);
  const threeDotButtonRef = useRef<RefType>(null);
  const [modalAddition, setModalAddition] = useState(false);
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const showOptions = infoData.can.update && infoData.projectPart.published;

  const CheckIconTypeParams = {
    colorCheck: "#0AA699",
    colorClose: "#CE0000",
    fontSize: "20px",
  };

  const closeModalAdditional = useCallback(() => {
    setModalAddition(false);
  }, []);

  function updateAddition() {
    setModalAddition(true);
    closeThreeDot();
  }

  return (
    <div className={styles.container}>
      {infoData.can.update && (
        <NewModal
          title={"Дополнительные требования"}
          active={modalAddition}
          setActive={setModalAddition}
          isLoad={isLoadAdditional}
        >
          <Addition closeModal={closeModalAdditional} />
        </NewModal>
      )}
      <div className={styles.container_header}>
        <h2>Требования к разделу</h2>
        {showOptions && (
          <ThreeDotButton ref={threeDotButtonRef} color="success">
            <MenuItem onClick={updateAddition} disableRipple>
              Редактировать
            </MenuItem>
          </ThreeDotButton>
        )}
      </div>
      {infoData.projectPart.published && (
        <div className={styles.container_body}>
          <h3>Договор</h3>
          <h3>Разработка</h3>
          <h3 />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.secure}
            text="Безопасная сделка"
            className={styles.container_check}
          />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.bim}
            text="BIM"
            className={styles.container_check}
          />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.vor}
            text="Ведомость объемов работ"
            className={styles.container_check}
          />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.with_docs}
            text="Заключение договора"
            className={styles.container_check}
          />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.kac}
            text="Конъюнктурный анализ цен"
            className={styles.container_check}
          />
          <CheckIconType
            {...CheckIconTypeParams}
            isCheck={infoData.vor_pnr}
            text="Ведомость пусконаладочных работ"
            className={styles.container_check}
          />
        </div>
      )}
      <hr className={styles.yellowLine} />
    </div>
  );
};
