import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { ProjectItem, ProjectProps } from "./Project/ProjectItem";
import { Wrapper } from "../../../templates";
import style from "../style.module.scss";

export type ProjectsListProps = ComponentPropsType<{
  items: ProjectProps[];
}>;

export const ProjectsList: React.FC<ProjectsListProps> = ({
  variant = "",
  items,
  theme = "dark",
}) => {
  return (
    <>
      {items.length > 0 ? (
        <ul className={classNames(variant, style.projectList)}>
          {items.map((item: any, i: number) => {
            return (
              <li
                key={i}
                className={classNames(style.projectItem, style[theme])}
              >
                <ProjectItem
                  {...item}
                  theme={theme}
                  variant={style.projectLabel}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <Wrapper>
          <div className={style.nothingFound}>Ничего не найдено</div>
        </Wrapper>
      )}
    </>
  );
};
