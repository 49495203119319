import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { memo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { palette, textFieldSX } from "../../../styles/restyle";

interface SelectFieldProps {
  label: string;
  name: string;
  options: [string, string][];
  defaultValue?: string;
  control?: any;
  disabled?: boolean;
  error?: string | null;
  value?: string;
  multiValue?: string[] | number[];
  multiple?: boolean;
  isAllClick?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
  handleClearClick?: () => void;
  newDesign?: boolean;
  newLabel?: string;
  classname?: string;
}

const sx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

const sxNewDesign = {
  bgcolor: "#1A2734",
  color: palette.silverGrey,
  padding: "10px 0",
  "&:not(:last-child)": {
    borderBottom: "1px solid #101920",
  },
  fontSize: "16px",
  fontFamily: "Manrope",
  fontWeight: "400",
};

const sxSelectAllBtn = {
  bgcolor: "#3B93AF!important",
  color: "#FFF!important",
  "&:hover": {
    bgcolor: "#3B93AF!important",
    color: "#FFF!important",
  },
};

export const SelectField: React.FC<SelectFieldProps> = memo((props) => {
  const {
    label,
    name,
    options,
    control,
    error,
    onChange,
    value,
    multiple,
    multiValue,
    isAllClick,
    handleClearClick,
    disabled,
    newDesign,
    newLabel,
    classname,
  } = props;

  const optionsList =
    options.length > 0 ? (
      options.map(([id, value]) => (
        <MenuItem key={value} sx={newDesign ? sxNewDesign : sx} value={id}>
          {value}
        </MenuItem>
      ))
    ) : (
      <p
        style={{
          color: "#26313a",
          padding: "10px",
        }}
      >
        Совпадений не найдено
      </p>
    );

  return (
    <FormControl {...textFieldSX} className={classname}>
      {newLabel && (
        <p
          style={{
            color: "#838a93",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginBottom: "2px",
          }}
        >
          {newLabel}
        </p>
      )}
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        labelId={name}
        name={name}
        label={label}
        value={multiple ? multiValue : value}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
        inputProps={
          newDesign && {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: "#1A2734",
                  padding: "0 20px",
                },
              },
            },
          }
        }
        error={!!error}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              overflowY: "auto",
            },
          },
        }}
        endAdornment={
          <IconButton
            sx={{
              display: multiValue && multiValue.length ? "" : "none",
              fontSize: "14px",
              marginRight: "5px",
            }}
            onClick={handleClearClick}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        }
        {...control}
      >
        {isAllClick && (
          <MenuItem sx={newDesign ? sxNewDesign : sxSelectAllBtn} value="all">
            Выбрать все
          </MenuItem>
        )}
        {optionsList}
      </Select>
      {error ? (
        <p
          style={{
            fontSize: "12px",
            color: "#d32f2f",
            fontWeight: 400,
            margin: "4px 14px 0 14px",
          }}
        >
          {error}
        </p>
      ) : null}
    </FormControl>
  );
});
