import { styled } from "@mui/material/styles";
import { MenuProps } from "@mui/material";
import Menu from "@mui/material/Menu";
import React from "react";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    backgroundColor: "#1B252C",
    minWidth: 180,
    color: "#9BACB9",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:hover": {
        color: "#FFFFFF",
      },
    },
  },
}));
