import { ReasonsOfRemoval, Steps } from "../../model/constants";
import { Props } from "../../ui/ReasonForm";
import useNonInitialEffect from "../../../../../../../../components/hooks/useNonInitialEffect";

type UseModalProps = Omit<Props, "isRemovingExecutor">;

export const useReasonForm = ({
  form,
  dataRemoveExecutor,
  onSubmit,
}: UseModalProps) => {
  const {
    watch,
    control,
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const reasonOfRemovalValue = watch("reason");

  const isAcceptedCustomerAlignReceivings = watch("reason3");

  const isRemovalBy715 = reasonOfRemovalValue.id === ReasonsOfRemoval.BY_715;

  const isContinueDisabled =
    isRemovalBy715 && !isAcceptedCustomerAlignReceivings;

  const containsObjects = Boolean(
    Object.keys(dataRemoveExecutor.objects).length
  );

  const handleGoForward = async () => {
    if (!containsObjects) {
      handleSubmit(onSubmit);
      return;
    }

    const result = await trigger(["content", "reason3"]);

    if (result) {
      setValue("currentStep", Steps.REFUND);
    }
  };

  useNonInitialEffect(() => {
    setValue("reason3", false);
  }, [reasonOfRemovalValue.id]);

  return {
    control,
    reasonOfRemovalValue,
    isRemovalBy715,
    register,
    errors,
    handleGoForward,
    isContinueDisabled,
    containsObjects,
  } as const;
};
