import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import cls from "./PublishProcess.module.scss";
import { useDocsValue, useSecureValue } from "./useSecureAndDocsValue";
import { ModalSchema } from "./ModalSchema";
import {
  getProjectSections,
  setPublishNull,
} from "../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { palette, textFieldSX } from "../../../styles/restyle";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomButton from "../CustomButton/CustomButton";
import {
  fetchGetUsersToAssign,
  fetchGetUsersTypeToAssign,
  fetchPublishInfo,
  fetchPublishProcess,
} from "../../../app/feature/ProjectView/SectionsReducer/thunks";
import { parseUnprocessableFields } from "../../../app/services/api/requestHandler";
import { ModalPreloader } from "../Modal/ModalPreloader";
import { SelectField } from "../SelectMui/SelectMui";
import { fetchProjectProcessWorkPublish } from "../../../app/feature/ProjectProcessView/Work/services/fetchProjectProcessWorkPublish";
import { useAppSelector } from "../../../app/store";
import { getProjectProcessWorkPublishError422 } from "../../../app/feature/ProjectProcessView/Work/selectors/getProjectProcessWorkPublish";
import { DateField } from "../../features/SpecialForm/DateField/DateField";
import { getProjectProcessInfoId } from "../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

interface PublishProcessProps {
  id: number;
  haveExpertise: boolean;
  projectPart: any;
  price: string;
  secure: boolean;
  vor: boolean;
  vor_pnr: boolean;
  with_docs: boolean;
  date_limit: any;
  date_start: any;
  executorType: { value: string; full: string; key: number }[];
  pay1: number;
  pay2: number;
  pay3: number;
  kac: boolean;
  closeModal: () => void;
  title: string;
  work?: boolean;
  bim: boolean;
}

const types = {
  1: "Физическое лицо",
  2: "Юридическое лицо",
  3: "Индивидуальный предприниматель",
  4: "Самозанятый",
};

const secureValueList = ["Нет", "Да"];

const sx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

type Inputs = {
  price: number | null;
  secure: string;
  with_docs: string;
  type: string;
  date_start: any;
  date_limit: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  executor: string;
  executorType: string;
  pay1: number;
  pay2: number;
  pay3: number;
  saveStatus: number;
  paymentSum: number;
  project_id: string;
  bim: boolean;
};

export const PublishProcess: FC<PublishProcessProps> = memo((props) => {
  const dispatch = useDispatch();
  const {
    closeModal,
    id,
    haveExpertise,
    projectPart,
    price,
    secure,
    vor,
    vor_pnr,
    with_docs,
    date_limit,
    date_start,
    executorType,
    kac,
    pay1,
    pay2,
    pay3,
    title,
    work,
    bim,
  } = props;
  const {
    executorsToArray,
    isLoadExecutor,
    typeExecutorsToArray,
    isLoadExecutorType,
    error422,
    publish,
    publishInfo,
    isLoadPublishInfo,
  } = useSelector(getProjectSections);
  const error422Work = useAppSelector(getProjectProcessWorkPublishError422);
  const processId = useSelector(getProjectProcessInfoId);

  const canAllCheck = vor && vor_pnr && kac && bim;
  const additional = publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
  const defaultAgreementPrice = price === "Договорная";
  const defaultExecutorType = executorType?.map((el) => el.key.toString());

  const [executorDiv, setExecutorDiv] = useState(false);
  const [payDiv, setPayDiv] = useState(false);
  const [docsValueList, setDocsValueList] = useState(["Нет", "Да"]);
  const [docsChangeValue, setDocsChangeValue] = useState(
    with_docs ? "Да" : "Нет"
  );

  const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
  const [checkVor, setCheckVor] = useState(vor);
  const [checkVorPnr, setCheckVorPnr] = useState(vor_pnr);
  const [checkKac, setCheckKac] = useState(kac);
  const [checkBim, setCheckBim] = useState(bim);
  const [checkAll, setCheckAll] = useState(canAllCheck);
  const [firstRender, setFirstRender] = useState(true);
  const [valueMulti, setValueMulti] = useState<string[]>(
    defaultExecutorType || []
  );

  const schema = ModalSchema(
    haveExpertise,
    checkPrice,
    !!work,
    executorDiv,
    pay3
  );

  const isAddExecutorBtn = executorsToArray.length !== 0;

  const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
  const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      pay1,
      pay2: !haveExpertise ? pay2 + pay3 : pay2,
      pay3,
      price: defaultAgreementPrice ? null : +price,
      secure: secure ? "Да" : "Нет",
      with_docs: with_docs ? "Да" : "Нет",
      date_start: start,
      date_limit: end,
      vor,
      vor_pnr,
      kac,
      bim,
    },
  });

  useEffect(() => {
    if (!payDiv) {
      setValue("pay1", pay1);
      setValue("pay2", !haveExpertise ? pay2 + pay3 : pay2);
      setValue("pay3", pay3);
    }
  }, [payDiv]);

  useEffect(() => {
    // при изменении checkAll меняем состояние всех чекбоксов
    if (!firstRender) {
      setCheckVor(checkAll);
      setCheckVorPnr(checkAll);
      setCheckKac(checkAll);
      setCheckBim(checkAll);
    }
  }, [checkAll]);

  useEffect(() => {
    if (!checkVor && !checkVorPnr && !checkKac && !checkBim) {
      setCheckAll(false);
    }
    if (checkVor && checkVorPnr && checkKac && checkBim) {
      setCheckAll(true);
    }
  }, [checkVor, checkVorPnr, checkKac, checkBim]);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
  };

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      agreementPrice: checkPrice,
      vor: !publishInfo.vor ? false : checkVor,
      vor_pnr: !publishInfo.vor_pnr ? false : checkVorPnr,
      kac: !publishInfo.kac ? false : checkKac,
      price: (!checkPrice && dataValues.price) || null,
      date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      with_docs: !publishInfo.canNoSecure ? true : docsChangeValue !== "Нет",
      secure: !publishInfo.canNoSecure ? true : dataValues.secure !== "Нет",
      executor_type: dataValues.type.split(","),
      pay1: payDiv ? +dataValues.pay1 : null,
      pay2: payDiv ? +dataValues.pay2 : null,
      pay3: payDiv && haveExpertise ? +dataValues.pay3 : null,
      executorByAdminId: executorDiv ? +dataValues.executor : null,
      executorTypeByAdminId: executorDiv ? +dataValues.executorType : null,
      save: dataValues.saveStatus,
      bim: checkBim,
    };
    if (work) {
      await dispatch(fetchProjectProcessWorkPublish({ id, data }));
      if (error422Work) {
        await parseUnprocessableFields(error422Work, setError);
      }
    } else {
      await dispatch(fetchPublishProcess({ id, data }));
      if (error422) {
        await parseUnprocessableFields(error422, setError);
      }
    }
  };

  useEffect(() => {
    if (defaultExecutorType) {
      setValue("type", defaultExecutorType.join());
    }
  }, []);

  const onChangeMulti = (event: any) => {
    if (event.target.value.includes("all")) {
      const all = Object.keys(types);
      setValueMulti(all);
      setValue("type", "1,2,3,4");
    } else {
      setValueMulti(event.target.value);
      setValue("type", event.target.value);
    }
  };
  const handleClearClick = () => {
    setValueMulti([]);
    setValue("type", "");
  };

  const addExecutorClick = async () => {
    setExecutorDiv(!executorDiv);
  };

  const executorValue = +watch("executor");
  const secureValue = watch("secure");

  useEffect(() => {
    if (secureValue === "Да") {
      setDocsChangeValue("Да");
    }
  }, [secureValue]);

  useEffect(() => {
    if (processId) {
      dispatch(fetchGetUsersToAssign(processId));
    }
    dispatch(fetchPublishInfo({ id, isWork: work }));
  }, []);

  useEffect(() => {
    if (executorValue && processId) {
      dispatch(fetchGetUsersTypeToAssign({ id: processId, executorValue }));
    }
  }, [executorValue, dispatch, processId]);

  useEffect(() => {
    if (publish) {
      clearErrors();
      dispatch(setPublishNull());
      closeModal();
    }
  }, [publish]);

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  // получение возможных значений для селекта docs and secure
  useSecureValue(secureValue, setDocsValueList);
  useDocsValue(docsChangeValue, publishInfo.canNoSecure, setPayDiv);

  if (isLoadExecutor || isLoadPublishInfo) {
    return <ModalPreloader />;
  }

  const priceRender = (
    <div className={cls.form_flex}>
      <label className={cls.form_label}>
        <CustomCheckbox
          editing
          onChange={() => setCheckPrice(!checkPrice)}
          checked={checkPrice}
        />
        Договорная стоимость
      </label>
      {!checkPrice && (
        <div className={cls.form_price}>
          <TextField
            {...register("price")}
            {...textFieldSX}
            label="Стоимость"
            defaultValue={price}
            error={!!errors.price}
          />
          {errors.price && <p className={cls.error}>{errors.price.message}</p>}
        </div>
      )}
    </div>
  );

  const secureAndDocsRender = publishInfo.canNoSecure && (
    <div className={cls.form_flex}>
      <FormControl {...textFieldSX}>
        <InputLabel id="docs">Договора и Акты</InputLabel>
        <Select
          {...register("with_docs")}
          labelId="docs"
          label="Договора и Акты"
          value={docsChangeValue}
          onChange={(e) => setDocsChangeValue(e.target.value)}
        >
          {docsValueList.map((value) => (
            <MenuItem key={value} sx={sx} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {errors.with_docs && (
          <p className={cls.error}>{errors.with_docs.message}</p>
        )}
      </FormControl>
      <FormControl {...textFieldSX}>
        <InputLabel id="secure">Безопасная сделка</InputLabel>
        <Select
          {...register("secure")}
          labelId="secure"
          label="Безопасная сделка"
          defaultValue={secure ? "Да" : "Нет"}
          error={!!errors.secure}
        >
          {secureValueList.map((value) => (
            <MenuItem key={value} sx={sx} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {errors.secure && <p className={cls.error}>{errors.secure.message}</p>}
      </FormControl>
    </div>
  );

  const payRender = (
    <>
      <div className={cls.form_pay}>
        <FormControl className={cls.form_pay_first}>
          <TextField
            {...register("pay1")}
            {...textFieldSX}
            label="Аванс (%)"
            defaultValue={pay1}
            error={!!errors.pay1}
          />
          {errors.pay1 && <p className={cls.error}>{errors.pay1.message}</p>}
        </FormControl>
        <FormControl className={cls.form_pay_last}>
          <TextField
            {...register("pay2")}
            {...textFieldSX}
            label="Оплата при завершении (%)"
            defaultValue={!haveExpertise ? pay3 + pay2 : pay2}
            error={!!errors.pay2}
          />
          {errors.pay2 && <p className={cls.error}>{errors.pay2.message}</p>}
        </FormControl>
        {haveExpertise && projectPart.published && (
          <FormControl className={cls.form_pay_second}>
            <TextField
              {...register("pay3")}
              {...textFieldSX}
              label="Оплата после экспертизы (%)"
              defaultValue={pay3}
              error={!!errors.pay3}
            />
            {errors.pay3 && <p className={cls.error}>{errors.pay3.message}</p>}
          </FormControl>
        )}
        <input type="hidden" {...register("paymentSum")} />
      </div>
      {errors.paymentSum && (
        <p className={cls.form_pay_paymentSum}>{errors.paymentSum.message}</p>
      )}
    </>
  );

  const typeRender = (
    <div>
      <SelectField
        {...register("type")}
        name="type"
        label="Форма собственности"
        multiple
        multiValue={valueMulti}
        isAllClick
        onChange={onChangeMulti}
        options={Object.entries(types)}
        handleClearClick={handleClearClick}
        error={errors.type ? errors.type.message : null}
      />
    </div>
  );

  const dateRender = (
    <div className={cls.form_date}>
      <div className={cls.form_date_start}>
        <Controller
          control={control}
          name="date_start"
          render={() => (
            <DateField
              variant={cls.formElement}
              label="Начальный срок"
              changeDateHandler={(date) => {
                setValue("date_start", date);
              }}
              startDateProp={start}
              error={!!errors.date_start}
            />
          )}
        />
        {errors.date_start && (
          <p className={cls.error}>{errors.date_start.message}</p>
        )}
      </div>
      <div className={cls.form_date_end}>
        <Controller
          control={control}
          name="date_limit"
          render={() => (
            <DateField
              variant={cls.formElement}
              label="Конечный срок"
              changeDateHandler={(date) => {
                setValue("date_limit", date);
              }}
              startDateProp={end}
              error={!!errors.date_limit}
            />
          )}
        />
        {errors.date_limit && (
          <p className={cls.error}>{errors.date_limit.message}</p>
        )}
      </div>
    </div>
  );

  const vorRender = (
    <div className={cls.form_flexColumn}>
      {(publishInfo.vor || publishInfo.vor_pnr) && (
        <div className={cls.form_flex}>
          {publishInfo.vor && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                {...register("vor")}
                editing
                id="vor"
                checked={checkVor}
                onChange={() => setCheckVor(!checkVor)}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="vor">Ведомость объемов работ</label>
            </div>
          )}
          {errors.vor && <p className={cls.error}>{errors.vor.message}</p>}
          {publishInfo.vor_pnr && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                {...register("vor_pnr")}
                editing
                id="vorPnr"
                checked={checkVorPnr}
                onChange={() => setCheckVorPnr(!checkVorPnr)}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="vorPnr">
                Ведомость объемов работ на пусконаладочные работы
              </label>
            </div>
          )}
          {errors.vor_pnr && (
            <p className={cls.error}>{errors.vor_pnr.message}</p>
          )}
        </div>
      )}
      <div className={cls.form_flex}>
        {publishInfo.kac && !work && (
          <div className={cls.form_checkBox}>
            <CustomCheckbox
              {...register("kac")}
              editing
              id="kac"
              checked={checkKac}
              onChange={() => setCheckKac(!checkKac)}
              classname={cls.form_checkBox_input}
            />
            <label htmlFor="kac">Конъюнктурный анализ цен</label>
          </div>
        )}
        <div className={cls.form_checkBox}>
          <CustomCheckbox
            {...register("bim")}
            editing
            id="bim"
            checked={checkBim}
            onChange={() => setCheckBim(!checkBim)}
            classname={cls.form_checkBox_input}
          />
          <label htmlFor="bim">BIM</label>
        </div>
        {canAllCheck && (
          <div className={cls.form_checkBox}>
            <CustomCheckbox
              id="selectAll"
              checked={checkAll}
              onChange={handleCheckAll}
              classname={cls.form_checkBox_input}
            />
            <label htmlFor="selectAll">Выбрать все</label>
          </div>
        )}
      </div>
    </div>
  );
  const labelExecutor = isLoadExecutor
    ? "...loading"
    : "Назначенный исполнитель";

  const labelExecutorType = isLoadExecutorType
    ? "...loading"
    : " Форма собственности";

  const executorRender = (
    <div className={cls.form_flexCol}>
      <FormControl {...textFieldSX} className={cls.form_executor}>
        <SelectField
          {...register("executor")}
          name={"executor"}
          label={labelExecutor}
          options={executorsToArray}
        />
      </FormControl>
      <FormControl {...textFieldSX}>
        <SelectField
          {...register("executorType")}
          name={"executorType"}
          label={labelExecutorType}
          options={typeExecutorsToArray}
          error={errors.executorType?.message}
        />
      </FormControl>
    </div>
  );

  return (
    <div className={cls.modal}>
      <h1>{title}</h1>
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <input type="hidden" {...register("project_id")} />
        {errors.project_id && (
          <p className={cls.form_pay_paymentSum}>{errors.project_id.message}</p>
        )}
        <h3>Стоимость, сроки и условия оплаты</h3>
        {priceRender}
        {secureAndDocsRender}
        {payDiv && payRender}
        {dateRender}
        <h3>Требования к исполнителю</h3>
        {typeRender}
        {/* {additional && !work && ( */}
        <h3>Дополнительные требования к разработке раздела</h3>
        {/* )} */}
        {vorRender}
        {isAddExecutorBtn && (
          <FormControl className={cls.form_toggleBtn}>
            <CustomButton
              background={palette.red}
              width={200}
              onClick={addExecutorClick}
            >
              Назначение исполнителя
            </CustomButton>
          </FormControl>
        )}
        {executorDiv && executorRender}
        <div className={cls.form_submit}>
          <FormControl className={cls.form_submit_saveBtn}>
            <CustomButton
              background={palette.grey}
              width={200}
              type="submit"
              onClick={() => setValue("saveStatus", 1)}
            >
              Сохранить в черновике
            </CustomButton>
          </FormControl>
          <FormControl>
            <CustomButton
              background={palette.green}
              width={160}
              type="submit"
              onClick={() => setValue("saveStatus", 0)}
            >
              Опубликовать
            </CustomButton>
          </FormControl>
        </div>
      </form>
    </div>
  );
});
