export const processInOutTasks = (
  payload: Record<string, any>,
  isIn?: boolean
) => {
  if (Array.isArray(payload)) {
    return [];
  }

  const { tasks } = Object.values(payload)[0];

  return Object.entries(tasks).map(([id, task]: any) => {
    const { executor } = task.tasks[0].task;
    const executorName = executor
      ? `${executor.surname} ${executor.name} ${executor.patronymic}`
      : "";

    return {
      id: Number(id),
      name: task.code,
      count: task.count,
      folder: isIn ? "incoming" : "outcoming",
      executorName: [executorName],
      date: task.taskStatusInfo.date,
      workflow: task.tasks[task.tasks.length - 1].task.workflow_data,
      partId: task.partId,
      status: task.taskStatusInfo.statusString,
    };
  });
};

export const processCheckTasks = (payload: any) => {
  return Object.entries(payload)
    .map(([tab, check]: [string, any]) =>
      check.map((task: any) => ({
        id: Number(task.id),
        name: task.taskToProcess
          ? task.taskToProcess.projectPart.code
          : task.taskProcess.projectPart.code,
        count: null,
        folder: "check",
        executorName: task.executor
          ? [
              `${task.executor.surname} ${task.executor.name} ${task.executor.patronymic}`,
            ]
          : [""],
        date: (task.date_complete || task.date_deadline || "").substr(0, 10),
        partId: task.workflow_data.processId,
        status: task.status.value,
        taskToProcess: task.taskToProcess,
        tab,
      }))
    )
    .reduce((final, item) => [...final, ...item], []);
};

export const processWorks = (payload: any) =>
  payload.map((work: any) => ({
    id: Number(work.id),
    processId: work.process_id,
    title: work.title,
    date:
      work.date_start && work.date_limit
        ? `${work.date_start} • ${work.date_limit}`
        : "",
    status: work.status.value,
    folder: "work",
    executorName: work.activeAccess
      ? [
          `${work.activeAccess.user.surname} ${work.activeAccess.user.name} ${work.activeAccess.user.patronymic}`,
          `${work.activeAccess.userType.listName}`,
        ]
      : ["", ""],
    price: work.price,
    secure: work.secure,
    executorNds: work.executorNds,
    countAccesses: work?.countAccesses,
  }));

export const processDocumentations = (payload: any) => {
  const { task } = payload.task;

  if (!task?.id) {
    return [];
  }

  return [
    {
      id: task.id,
      name: task.name,
      count: null,
      folder: "documentations",
      executorName: [
        `${task.executor.surname} ${task.executor.name} ${task.executor.patronymic}`,
      ],
      date: task.date_deadline.substr(0, 10),
      workflow: null,
      partId: 0,
      status: task.status.value,
    },
  ];
};

export const processAligns = (payload: any) => {
  return Object.values(payload).map((work: any) => ({
    id: Number(work.id),
    name: work.name,
    count: null,
    folder: "aligns",
    executorName: [
      `${work.executor.surname} ${work.executor.name} ${work.executor.patronymic}`,
    ],
    date: work.date_deadline.substr(0, 10),
    workflow: null,
    partId: 0,
    status: work.status.value,
  }));
};
