import React, { memo } from "react";
import CustomButton from "../../../CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import cls from "./ModalConfirm.module.scss";
import { useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoName } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

interface ModalConfirmProps {
  text: string;
  completeMileStoneHandler: (result: boolean) => void;
  processName?: string;
}
export const ModalConfirm = memo(
  ({ completeMileStoneHandler, text, processName }: ModalConfirmProps) => {
    const processNameTypeProject = useAppSelector(getProjectProcessInfoName);
    const handleConfirm = () => {
      completeMileStoneHandler(true);
    };

    const handleCancel = () => {
      completeMileStoneHandler(false);
    };

    return (
      <div className={cls.confirm}>
        <h1>Внимание!</h1>
        {`Вы подтверждаете принятие контрольной точки «${text}» и
      открываете финансирование следующего этапа по разделу «${
        processNameTypeProject || processName
      }»?`}
        <span>Отменить приёмку будет невозможно.</span>
        <div className={cls.confirm_btn}>
          <CustomButton
            background={palette.red}
            onClick={handleCancel}
            width={80}
          >
            Нет
          </CustomButton>
          <CustomButton
            background={palette.green}
            onClick={handleConfirm}
            width={80}
          >
            Да
          </CustomButton>
        </div>
      </div>
    );
  }
);
