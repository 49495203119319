import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { parse } from "date-fns";
import cn from "classnames";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import style from "./style.module.scss";
import infoStyle from "../../Info/style.module.scss";
import { SubmitDataProps } from "../Experience";
import OrganizationField from "./OrganizationField/OrganizationField";
import { UnsavedType } from "../../../../Project/types";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import useNonInitialEffect from "../../../../../hooks/useNonInitialEffect";
import DateRange from "../../Education/EducationItem/DateRange";
import {
  datePassed,
  datePassedRequired,
  stringMaxLengthRequired,
  stringMaxLength,
} from "../../../../../../utils/SimplifiedYup";
import { palette, textFieldSX } from "../../../../../../styles/restyle";

type ComponentProps = {
  onCreate?: (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onUpdate?: (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onDelete?: (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  jobPlace?: any;
  setUnsaved: SetState<UnsavedType>;
};

export const schema = Yup.object().shape({
  firm_name: stringMaxLengthRequired("Наименование организации", 500),
  date_start: datePassedRequired("Дата начала"),
  date_end: datePassed(),
  prof: stringMaxLengthRequired("Должность", 500),
  job_functions: stringMaxLength(500),
});

const ExperienceItem: React.FC<ComponentProps> = ({
  setUnsaved,
  onCreate,
  onUpdate,
  onDelete,
  jobPlace,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getValues,
    watch,
    formState: { dirtyFields, errors },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      date_start:
        jobPlace && jobPlace.date_start
          ? parse(jobPlace.date_start, "dd.MM.yyyy", new Date())
          : null,
      date_end:
        jobPlace && jobPlace.date_end
          ? parse(jobPlace.date_end, "dd.MM.yyyy", new Date())
          : null,
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const dateStart = watch("date_start");
  const dateEnd = watch("date_end");
  const firmName = watch("firm_name");

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, experience: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  useNonInitialEffect(() => {
    setUnsaved((prev) => ({
      ...prev,
      experience: jobPlace?.firm_name
        ? jobPlace.firm_name !== firmName
        : Boolean(firmName),
    }));
  }, [firmName, setUnsaved]);

  return (
    <div className={style.container}>
      <form
        onSubmit={handleSubmit(
          jobPlace
            ? (data = getValues()) =>
                onUpdate!(data, jobPlace.id, setIsFetching)
            : (data = getValues()) => onCreate!(data, setIsFetching)
        )}
      >
        <div className={style.formContent}>
          <div className={style.formChunk}>
            <OrganizationField
              name="firm_name"
              register={register}
              defaultValue={jobPlace?.firm_name || ""}
              setValue={setValue}
              hasError={!!errors.firm_name}
              helperText={errors.firm_name ? errors.firm_name.message : null}
            />
            <DateRange
              dateStart={dateStart}
              dateEnd={dateEnd}
              setValue={setValue}
              trigger={trigger}
              dateStartError={errors.date_start?.message}
              dateEndError={errors.date_end?.message}
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Должность"
              defaultValue={jobPlace ? jobPlace.prof : ""}
              autoComplete="off"
              {...register("prof")}
              {...textFieldSX}
              error={!!errors.prof}
              helperText={errors.prof?.message}
            />
          </div>
          <div className={cn(style.formChunk, infoStyle.multiScroll)}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={7}
              maxRows={7}
              label="Описание выполняемых работ"
              defaultValue={jobPlace ? jobPlace.job_functions : ""}
              autoComplete="off"
              error={!!errors.job_functions}
              helperText={errors.job_functions?.message}
              {...register("job_functions")}
              {...textFieldSX}
            />
          </div>
        </div>
        <div
          className={cn(style.bottomContainer, {
            [style.onlyButtons]: jobPlace,
          })}
        >
          {jobPlace ? (
            <CustomButton
              width={160}
              type="button"
              style={{ marginRight: "16px" }}
              background={palette.red}
              onClick={() => onDelete!(jobPlace.id, setIsFetching)}
              disabled={isFetching}
            >
              Удалить
            </CustomButton>
          ) : (
            <span>
              Если вы не хотите раскрывать название организации, то можете
              ввести "*****"
            </span>
          )}
          <CustomButton
            width={160}
            type="submit"
            background={palette.blue}
            disabled={isFetching}
          >
            Сохранить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default ExperienceItem;
