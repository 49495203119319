import { DeleteHandlerType } from "../UploaderTypes/UploaderTypes";
import { request } from "../../../../app/services/api/requestHandler";
import {
  ApiDeleteFile,
  ApiVisibilityFile,
} from "../../../../app/services/api/file/file";

/**
 * функия для удаления файла по id, из базы данных и initialList если
 * есть редактирование и фалы подгружены в initialList
 * */

export const deleteHandler: DeleteHandlerType = async (
  id,
  cb,
  update = () => {}
) => {
  if (id) {
    await request(ApiDeleteFile(id), (res) => {
      if (res && cb) {
        cb(res);
      }
    })();
  }
  await update();
};

export const actuallyHandler = async (
  id: string | number,
  cb?: any,
  update?: any
) => {
  if (id) {
    await request(ApiVisibilityFile(id), (res) => {
      if (res && cb) {
        cb(res, id);
      }
    })();
  }
  await update();
};
