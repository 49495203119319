import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  fetchGetLogTypes,
  fetchProjectHistory,
} from "../../../../../app/feature/ProjectView/HistoryReducer/thunks";
import { getProjectHistory } from "../../../../../app/feature/ProjectView/HistoryReducer/projectHistory";
import style from "./History.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { Paginate } from "../../../../UI/components/Pagination/Pagination";
import { selectorAppTheme } from "../../../../../app/feature/app/app";
import { HistoryForm } from "./HistoryForm";
import { Preloader } from "../../../../features";
import { SortButton } from "../../../../UI/components/Buttons/SortButton/SortButton";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { userIsAdmin } from "../../../../../app/services/user/userRoles";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";

interface IHistoryProps {
  setCurrentTabMemo: () => void;
}

export const History: React.FC<IHistoryProps> = ({ setCurrentTabMemo }) => {
  const { project } = useSelector(getProjectViewData);
  const dispatch = useDispatch();
  const { logs, isLoad, meta, personValue } = useSelector(getProjectHistory);
  const params = useParams<{ id: string }>();
  const isAdmin = userIsAdmin();
  const [page, setPage] = useState(1);

  const [currentMetaPage, setCurrentMeta] = useState({
    sort: "type",
  });

  if (!isAdmin && !project?.can.editByGipHelper) {
    setCurrentTabMemo();
  }

  let date1 = "";
  let date2 = "";
  if (personValue.date1) {
    date1 = moment(personValue.date1, "DD.MM.YYYY").format("DD.MM.YYYY");
  }
  if (personValue.date2) {
    date2 = moment(personValue.date2, "DD.MM.YYYY").format("DD.MM.YYYY");
  }

  const baseUrl = `?id=${params.id}&page=${page}&per-page=10&LogSearch[userFio]=${personValue.userFio}&LogSearch[type]=${personValue.type.id}&LogSearch[data]=${personValue.data}&LogSearch[date1]=${date1}&LogSearch[date2]=${date2}&sort=${currentMetaPage.sort}`;
  const theme = useSelector(selectorAppTheme);
  const [searchString, setSearchString] = useState(baseUrl);

  useEffect(() => {
    if (params.id) {
      setSearchString(baseUrl);
    }
  }, [page, personValue, currentMetaPage]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
    dispatch(fetchProjectHistory(searchString));
    dispatch(fetchGetLogTypes());
  }, [searchString]);

  const onChangePage = (event: any, page: number) => {
    setPage(page);
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const paginate = (
    <Paginate
      page={Number(meta.paginationCurrentPage)}
      handleChange={onChangePage}
      theme={theme}
      count={meta.paginationPageCount}
      variant="outlined"
      shape="rounded"
    />
  );

  if (isLoad) {
    return <Preloader variant={style.preloaderSections} />;
  }

  if (logs) {
    const mapLogs = logs.map((el) => (
      <React.Fragment key={el.id}>
        <div className={style.parent_container}>
          {el.date_create.slice(0, -3)}
        </div>
        <div className={style.parent_container}>
          <TextLink url={`/user/${el.user.id}`} variant={style.mainLink}>
            {`${el.user.name} ${el.user.surname} ${el.user.patronymic} `}
          </TextLink>
        </div>
        <div className={style.parent_container}>{el.type}</div>
        <div className={style.parent_container}>
          <div>
            {el.data.map((item) => (
              <>
                <p>
                  {" "}
                  {item.attribute && `${item.attribute}: ${item.newValue}`}
                </p>
              </>
            ))}
          </div>
        </div>
      </React.Fragment>
    ));

    return (
      <div className={style.wrapper}>
        <div className={style.parent}>
          <div className={style.parent_container}>
            <SortButton
              posVariant={style.sortBtn}
              clickHandler={onClickSort}
              sortType="date_create"
              text="Время"
              isUp={currentMetaPage.sort === "date_create"}
              isShow={
                currentMetaPage.sort === "date_create" ||
                currentMetaPage.sort === "-date_create"
              }
              isDisable={isLoad}
            />
          </div>
          <div className={style.parent_container}>
            <SortButton
              posVariant={style.sortBtn}
              clickHandler={onClickSort}
              sortType="user_id"
              text="Пользователь"
              isUp={currentMetaPage.sort === "user_id"}
              isShow={
                currentMetaPage.sort === "user_id" ||
                currentMetaPage.sort === "-user_id"
              }
              isDisable={isLoad}
            />
          </div>
          <div className={style.parent_container}>
            <SortButton
              posVariant={style.sortBtn}
              clickHandler={onClickSort}
              sortType="type"
              text="События"
              isUp={currentMetaPage.sort === "type"}
              isShow={
                currentMetaPage.sort === "type" ||
                currentMetaPage.sort === "-type"
              }
              isDisable={isLoad}
            />
          </div>
          <div className={style.parent_container}>
            <SortButton
              posVariant={style.sortBtn}
              clickHandler={onClickSort}
              sortType="id"
              text="Данные"
              isUp={currentMetaPage.sort === "id"}
              isShow={
                currentMetaPage.sort === "id" || currentMetaPage.sort === "-id"
              }
              isDisable={isLoad}
            />
          </div>
        </div>
        <HistoryForm />
        <div className={style.parent}>{mapLogs}</div>
        <div className={style.paginationContainer}>{paginate}</div>
      </div>
    );
  }
  return <div></div>;
};
