import { Link } from "react-router-dom";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { getFiles } from "src/FSD/shared/lib/helpers/getFiles";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import styles from "./SourceFiles.module.scss";
import { config } from "../../../../../../app.cofig";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const SourceFiles = ({ processWork }: Props) => {
  return (
    <div className={styles.sourceFiles}>
      <Card
        header={
          <Card.Header className={styles.sourceFiles__header}>
            Исходные данные к задаче
          </Card.Header>
        }
      />
      <Card
        header={
          <Card.Header fontSize={18}>
            Дополнительные данные к задаче
          </Card.Header>
        }
      >
        <Uploader
          canEdit={processWork.can!.edit}
          postUrl={`${config.localDomain}/v1/process-work/add-file?id=${processWork.id}`}
          autoUpload
          uploadAsActive
          getDataCallback={() => getFiles("process-work", processWork.id)}
          stretch
        />
        {Boolean(processWork.files && processWork.files.length) && (
          <TextLink
            className={styles.downloadAll}
            title={"Скачать все"}
            redirect
            target="blank"
            url={`/process-work/download-files?id=${processWork.id}`}
          >
            Скачать все
          </TextLink>
        )}
      </Card>
      <Card
        header={
          <Card.Header fontSize={18}>Исходные данные по разделу</Card.Header>
        }
        controls={
          <Card.Controls width={100} location="center">
            <Link
              target="_blank"
              to={`${config.localDomain}/project-process/${processWork.process_id}?tab=sourceFiles&horizontalTab=1`}
            >
              <Button>Перейти</Button>
            </Link>
          </Card.Controls>
        }
      />
    </div>
  );
};
