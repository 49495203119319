import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../../../services/api/api";
import { request } from "../../../services/api/requestHandler";
import { pushError } from "../../errorTrace";

export const ApiGetApplicationsBySectionId = (
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/application?id=${id}&expand=executorNds`
  );
};

export const ApiGetOldApplicationsBySectionId = (
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/old-application?id=${id}&expand=executorNds`
  );
};
// &fields=author,task.date_create,task.date_deadline,author.id,author.surname,author.name,author.patronymic,userType.listName,executor_nds

export const ApiGetApplicationsByWorkId = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/process-work/application?id=${id}&expand=executorNds,author,workflow_data`
  );
};

export const ApiGetOldApplicationsByWorkId = (
  id: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/process-work/old-application?id=${id}&expand=executorNds,author,workflow_data`
  );
};

export const getApplicationsByTask = createAsyncThunk(
  "dashboard/getApplicationsByTask",
  async (id: number, { dispatch }) => {
    let newApplications: any = {
      good: [],
      bad: [],
    };

    let oldApplications: any = {
      good: [],
      bad: [],
    };

    await request(
      ApiGetApplicationsByWorkId(id),
      (data) => {
        newApplications = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    await request(
      ApiGetOldApplicationsByWorkId(id),
      (data) => {
        oldApplications = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return {
      newApplications,
      oldApplications,
    };
  }
);

export const getApplicationsBySection = createAsyncThunk(
  "dashboard/getApplicationsBySection",
  async (id: number, { dispatch }) => {
    let newApplications: any = {
      good: [],
      bad: [],
    };

    let oldApplications: any = {
      good: [],
      bad: [],
    };

    await request(
      ApiGetApplicationsBySectionId(id),
      (data) => {
        newApplications = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    await request(
      ApiGetOldApplicationsBySectionId(id),
      (data) => {
        oldApplications = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return {
      newApplications,
      oldApplications,
    };
  }
);
