import React, { memo, useState } from "react";
import cls from "../Materials.module.scss";
import { config } from "../../../../../app.cofig";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { ProjectProcessMaterialsFirmTypes } from "../../../../../app/feature/ProjectProcessView/Material/types/projectProcessMaterialsFirmTypes";

interface HeaderCardProps {
  firm: ProjectProcessMaterialsFirmTypes;
}
export const HeaderCard = memo(({ firm }: HeaderCardProps) => {
  const [visibleContact, setVisibleContact] = useState(false);
  const canContact = firm.phone || firm.phone;
  return (
    <div className={cls.firm} key={firm.id}>
      {firm.photo ? (
        <img src={`${config.localDomain}${firm.photo}`} alt="Логотип" />
      ) : (
        <p className={cls.firm_title}>{firm.title}</p>
      )}
      <div className={cls.firm_flex}>
        <p
          className={cls.firm_contact}
          onMouseOver={() => setVisibleContact(true)}
          onMouseOut={() => setVisibleContact(false)}
        >
          Контакты
        </p>
        {visibleContact && (
          <div className={cls.tooltip}>
            <p>Контактные данные</p>
            {canContact ? (
              <>
                <p>{`Телефон:  ${firm.phone ? firm.phone : ""}`}</p>
                <p>{`Почта: ${firm.email}`}</p>
              </>
            ) : (
              <span>не заполнены</span>
            )}
          </div>
        )}
        {firm.site && (
          <div className={cls.flexLink}>
            <span>Перейти на</span>
            <TextLink
              variant={cls.textLink}
              redirect
              target="blank"
              url={firm.site}
            >
              cайт
            </TextLink>
          </div>
        )}
        {firm?.questionnaires.length > 0 && (
          <div className={cls.flexLink}>
            <TextLink
              variant={cls.textLink}
              redirect
              target="blank"
              url={`/firm/download-files?id=${firm.id}`}
            >
              Скачать
            </TextLink>
            <span>опросные листы</span>
          </div>
        )}
      </div>
    </div>
  );
});
