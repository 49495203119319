import { TTabsPanel } from "../../model/types";

export const createTabsData = (tabs?: TTabsPanel.TabsList) => {
  if (tabs) {
    return Object.values(tabs).filter(({ show }) => show);
  }
  return [
    {
      title: "Информация",
      name: "info",
      icon: "bi-info-square",
      dot: false,
      show: true,
      stop: false,
      items: [],
    },
  ];
};
