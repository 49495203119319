import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Wrapper } from "../../../UI/templates";
import style from "../style.module.scss";
import { withClassCurry } from "../../../utils";
import { UserList } from "../../../UI/components/indexPageLists/UserList/UserList";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { SortButton } from "../../../UI/components/Buttons/SortButton/SortButton";
import { Paginate } from "../../../UI/components/Pagination/Pagination";
import { UserSkeletonList } from "../../../UI/components/indexPageLists/UserList/UserSkeletonList";
import { CustomerFormHead } from "./GipFormHead";
import {
  selectorGipList,
  selectorGipMeta,
  setGips,
} from "../../../../app/feature/indexPages/users/gipPage/gipPage";
import { ApiGetGipList } from "../../../../app/services/api/indexPages/gipPage";
import { createPaginationMeta } from "../../../../app/feature/indexPages/createData";
import { IndexPageTitle } from "../../../features/IndexPageTitle/IndexPageTitle";
import { request } from "../../../../app/services/api/requestHandler";
import { useTitle } from "../../../hooks/useTitle";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../../app/services/url/createUrlSearchString";

export const GipPage = () => {
  useTitle("Главный инженер проекта");
  const theme = useSelector(selectorAppTheme);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);

  const [searchString, setSearchString] = useState(location.search);
  const [currentFilters, setCurrentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({
    sort: "-rating.gip",
  });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    users: useSelector(selectorGipList),
    meta: useSelector(selectorGipMeta),
  };

  const params = queryString.parse(location.search);

  useEffect(() => {
    setInitialSearchParams(params as InitialParamType);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    navigate({
      search: searchString,
    });

    setLoad(true);
    if (searchString.length === 0) return;
    request(ApiGetGipList(searchString), (res, _, headersValues) => {
      const meta = createPaginationMeta(headersValues);
      dispatch(setGips(res, meta));
      setLoad(false);
    })(dispatch);
  }, [searchString]);

  useEffect(() => {
    if (firstLoad) return;
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const onResetFilter = () => {
    setInitialSearchParams({});
    setCurrentFilters({});
  };

  const onSubmitFilter = (data: any) => {
    setCurrentFilters(data);
  };

  const paginate = (
    <Paginate
      page={Number(data.meta.paginationCurrentPage)}
      handleChange={onChangePage}
      count={data.meta.paginationPageCount}
      theme={theme}
      variant="outlined"
      shape="rounded"
    />
  );
  const onClickLink = (url: string) => {
    const params = url.split("?");
    setCurrentFilters(queryString.parse(params[1]) as { [key: string]: any });
  };

  const withTheme = withClassCurry(style[theme]);
  return (
    <div className={style.wrapper}>
      <div
        className={classNames(withTheme(style.container), style.listWrapper)}
      >
        <IndexPageTitle
          clickLink={onClickLink}
          title={"Главный инженер проекта"}
          description={`Человек, способный решить вопросы Заказчика <br> сформировать команду проектировщиков, выдать результат работы`}
          theme={theme}
          page={"gip"}
        />
        <CustomerFormHead
          initialValues={params}
          submitHandler={onSubmitFilter}
          resetHandler={onResetFilter}
          theme={theme}
        />
      </div>
      <Wrapper>
        <div className={style.paginationContainer}>
          {data.users?.length > 0 && paginate}
          <div className={style.sortingContainer}>
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="id"
              text="По дате регистрации"
              clickHandler={onClickSort}
              isShow={params.sort === "id" || params.sort === "-id"}
              isUp={params.sort === "id"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="rating.gip"
              text="По рейтингу"
              clickHandler={onClickSort}
              isShow={
                params.sort === "rating.gip" || params.sort === "-rating.gip"
              }
              isUp={params.sort === "rating.gip"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="countActiveProject"
              text="По активным проектам"
              clickHandler={onClickSort}
              isShow={
                params.sort === "countActiveProject" ||
                params.sort === "-countActiveProject"
              }
              isUp={params.sort === "countActiveProject"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
          </div>
        </div>
      </Wrapper>
      {isLoad ? (
        <UserSkeletonList theme={theme} />
      ) : (
        <UserList
          gip
          isShowUserLabels={false}
          items={data.users}
          theme={theme}
        />
      )}
      {data.users?.length > 0 && (
        <Wrapper>
          <div className={style.paginationContainer}>{paginate}</div>
        </Wrapper>
      )}
    </div>
  );
};
