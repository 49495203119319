import React, { memo } from "react";
import moment from "moment/moment";
import classNames from "classnames";
import cls from "./ChecklistCardDate.module.scss";
import {
  calculateOverdue,
  daysBetweenDates,
  daysBetweenTodayAndDate,
} from "../helpers";
import { helpersDay } from "../../../../utils/helpersYears";

interface ChecklistCardDateProps {
  datePercent: number;
  dateEnd: string;
  dateStart: string;
  dateEndFact?: string;
}
export const ChecklistCardDate = memo(
  ({
    datePercent,
    dateEnd,
    dateStart,
    dateEndFact,
  }: ChecklistCardDateProps) => {
    const overDays = calculateOverdue(dateEnd);
    let nowToStartDays;
    if (dateEndFact) {
      const format = "YYYY-MM-DD HH:mm:ss.SSSSSS";
      const d1 = moment(dateEndFact, format);
      const d2 = moment(dateStart, "DD.MM.YYYY");
      nowToStartDays = d1.diff(d2, "days");
    } else {
      nowToStartDays = daysBetweenTodayAndDate(dateStart);
    }
    const diffEndToStartDays = daysBetweenDates(dateStart, dateEnd);
    const sumOverDays = diffEndToStartDays + overDays;
    const textDays = helpersDay(overDays);

    const resultNowToStartDays = nowToStartDays >= 0 ? nowToStartDays : "-";
    const resultDiffEndToStartDays = dateEnd ? diffEndToStartDays : "-";

    return (
      <div className={cls.deadlines}>
        <div className={cls.deadlines_flex}>
          <p>Сроки</p>
          <span>
            {datePercent > 100
              ? `(просрочено на ${overDays} ${textDays}!)`
              : "(в пределах плана)"}
          </span>
        </div>
        <div className={cls.date}>
          <p>{dateStart}</p>
          <p>{dateEnd}</p>
        </div>
        {datePercent > 100 ? (
          <div className={cls.terms}>
            <div className={cls.terms_redSlider} style={{ width: `100%` }} />
            <p className={cls.terms_date_red}>
              {`${sumOverDays}/${diffEndToStartDays} дней`}
            </p>
          </div>
        ) : (
          <div className={cls.terms}>
            <div
              className={cls.terms_slider}
              style={{ width: `${datePercent}%` }}
            >
              <p
                className={classNames(
                  cls.terms_date_default,
                  datePercent < 20 && cls.left
                )}
              >
                {`${resultNowToStartDays}/${resultDiffEndToStartDays} дней`}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
);
