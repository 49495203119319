import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../services/api/requestHandler";
import { ApiSectionsTreeForDashboard } from "../../../services/api/project/sections";
import { pushError } from "../../errorTrace";

export type TProject = {
  id: number;
  name: string;
};

export type TApiGetProjects = {
  userType: number;
  page: number;
  query: string;
  fulfilled: boolean;
};

export const getSections = createAsyncThunk(
  "dashboard/getProjectsPartTree",
  async (projectId: number, { dispatch }) => {
    let response: any = {};
    await request(
      ApiSectionsTreeForDashboard(projectId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);
