import React, { useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { getText, MAX_SYMBOLS } from "../../../../User/View/Info/Info";
import classes from "./InfoDescription.module.scss";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";

type InfoDescriptionProps = {
  description: ProjectViewData["description"];
};

export const InfoDescription: React.FC<InfoDescriptionProps> = ({
  description,
}) => {
  const [open, setOpen] = useState(false);

  const arrowIconType = description?.length > MAX_SYMBOLS && (
    <IconButton style={{ padding: 0 }} onClick={() => setOpen(!open)}>
      <ToggleAccordion toggle={open} />
    </IconButton>
  );

  function createMarkup() {
    if (description) return { __html: getText(open, description) };
    return { __html: "Пользователь не предоставил информацию" };
  }

  return (
    <div className={classNames(classes.description, classes.basic)}>
      <div className={classes.description_btn}>
        <h1>Описание</h1>
        <div>{arrowIconType}</div>
      </div>
      <div
        className={classes.description_text}
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};
