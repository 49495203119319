import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import cls from "./ModalCreateRemark.module.scss";
import CustomCheckbox from "../../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { Uploader } from "../../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../../app.cofig";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getExpertiseProjectList,
  getExpertiseProjectListIsLoad,
} from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseProjectList";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseProjectList } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectList";
import { fetchExpertiseRemarkCreate } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkCreate";
import { fetchExpertiseRemarks } from "../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { DateField } from "../../../../../../features/SpecialForm/DateField/DateField";

type TInputs = {
  project_id: string;
  date_create: any;
  parseSpreadsheet: boolean;
  files: any;
};

interface ModalCreateRemarkProps {
  closeModal: () => void;
}

const schema = Yup.object().shape({
  files: Yup.array()
    .min(1, "Необходимо загрузить хотя бы один файл")
    .required("Необходимо загрузить хотя бы один файл"),
});

export const ModalCreateRemark = memo(
  ({ closeModal }: ModalCreateRemarkProps) => {
    const dispatch = useAppDispatch();
    const projectList = useAppSelector(getExpertiseProjectList);
    const isLoadProjectList = useAppSelector(getExpertiseProjectListIsLoad);
    const projectId = useAppSelector(getProjectProcessInfoProjectId);
    const processId = useAppSelector(getProjectProcessInfoId);
    const [checkImport, setCheckImport] = useState(false);

    useEffect(() => {
      if (processId && checkImport) {
        dispatch(fetchExpertiseProjectList(processId));
      }
    }, [processId, checkImport]);

    const {
      register,
      handleSubmit,
      setValue,
      control,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        project_id: projectId ? projectId.toString() : "",
      },
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();
      formData.append("projectId", dataValues.project_id);
      formData.append(
        "date_create",
        moment(dataValues.date_create, "DD.MM.YYYY").format("DD.MM.YYYY")
      );
      formData.append("parseSpreadsheet", checkImport ? "1" : "0");
      dataValues.files.forEach((file: BinaryType) => {
        formData.append("upload_files[]", file);
      });
      if (processId) {
        await dispatch(fetchExpertiseRemarkCreate({ processId, formData }));
        dispatch(fetchExpertiseRemarks(processId));
      }
      closeModal();
    };

    const mapExpertiseProjectList = projectList
      ? projectList?.map((project) => (
          <MenuItem key={project.id} sx={menuItemSx} value={project.id}>
            {project.name}
          </MenuItem>
        ))
      : null;

    return (
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={cls.form}
        encType={"multipart/form-data"}
      >
        <h1>Замечания</h1>
        <label className={cls.form_label}>
          <CustomCheckbox
            onChange={() => setCheckImport(!checkImport)}
            checked={checkImport}
            editing
          />
          Импорт вопросов из таблицы (.xlsx, .xls, .ods, .csv)
        </label>
        {checkImport && (
          <div className={cls.form_alert}>
            <p>
              Импортируемый документ должен иметь следующий вид: в первой
              колонке нумерация строк должна быть в числовом формате, во второй
              колонке - текст в текстовом формате, в третьей колонке дата должна
              иметь вид ДД.MM.ГГ или ДД.ММ.ГГГГ и иметь формат текстовый или
              даты.
            </p>
            <div className={cls.form_alert_grid}>
              <div className={cls.form_alert_grid_item}>№ п/п</div>
              <div className={cls.form_alert_grid_item}>Замечание</div>
              <div className={cls.form_alert_grid_item}>Срок</div>
            </div>
          </div>
        )}
        <Controller
          control={control}
          name="date_create"
          render={() => (
            <DateField
              variant={cls.date}
              label="Дата создания"
              changeDateHandler={(date) => {
                setValue("date_create", date);
              }}
              error={!!errors.date_create}
            />
          )}
        />
        {checkImport && (
          <FormControl {...textFieldSX} className={cls.form_tasks}>
            <InputLabel id="project_id">Объект</InputLabel>
            <Select
              {...register("project_id")}
              defaultValue={projectId}
              disabled={isLoadProjectList}
              labelId="project_id"
              label="Объект"
            >
              {mapExpertiseProjectList}
            </Select>
          </FormControl>
        )}
        <div>
          <Uploader
            canBeEdited
            autoUpload={false}
            identifier={null}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              setValue("files", data);
            }}
          />
          {errors.files && (
            <div className={cls.error}>
              <p>{errors.files.message}</p>
            </div>
          )}
        </div>

        <CustomButton
          type="submit"
          background={palette.green}
          width={160}
          className={cls.form_submit}
        >
          Создать
        </CustomButton>
      </form>
    );
  }
);
