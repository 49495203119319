import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { TextField, Chip } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

import cn from "classnames";
import style from "./style.module.scss";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import {
  ISkill,
  ISkillForSelect,
} from "../../../../../app/feature/profileReducer/types";
import { prepareDataForUpdateProfile } from "../../../../../app/feature/profileReducer/dataPreparers";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { Phone } from "../../../../features/SpecialForm/Phone/Phone";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { Regions } from "../../../../features/SpecialForm/Country/RegionsOfTheCountry";
import { CountriesType } from "../../../../features/SpecialForm/Country/Country";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";
import AdvancedAddressField from "./AdvancedAddressField/AdvancedAddressField";
import {
  parseUnprocessableFields,
  request,
} from "../../../../../app/services/api/requestHandler";
import { httpRequestPatchMultiData } from "../../../../../app/services/api/api";
import { config } from "../../../../../app.cofig";
import { UnsavedType } from "../../../Project/types";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import {
  setOutboundForm,
  setInboundForm,
  clearForms,
} from "../../../../../app/feature/unsaved/unsavedController";
import { useAppDispatch } from "../../../../../app/store";

const defaultCountry = "RU";

const ApiUpdateProfile = (id: string, formData: any): Promise<Response> => {
  return httpRequestPatchMultiData(
    `${config.localDomain}/v1/user/update-profile?id=${id}`,
    {},
    formData
  );
};

export const calculateAge = (birthday: Date | null) => {
  if (!birthday) {
    return false;
  }

  const now = new Date();

  if (
    moment(birthday).format("MM") === moment(now).format("MM") &&
    Number(moment(now).format("YYYY")) -
      Number(moment(birthday).format("YYYY")) ===
      18
  ) {
    const day2 = Number(moment(birthday).format("DD"));
    const day1 = Number(moment(now).format("DD"));

    return day2 > day1;
  }

  return birthday >= new Date(Date.now() - 567648000000);
};

const workWithBimOptions = [
  { id: 0, title: "Нет" },
  { id: 1, title: "Да" },
];

const paperOpt = {
  position: "absolute",
  left: "0px",
  width: "100%",
  marginTop: 4,
  color: "#26313a",
};

const chipSX = {
  color: "#fff !important",
  background: "#6B7D8A !important",
  fontSize: "14px",
  borderRadius: "5px",
};

export type Address = {
  city: string | null;
  city_type: string | null;
  city_type_full: string | null;
  country: string | null;
  country_iso_code: string | null;
  geo_lat: string | null;
  geo_lon: string | null;
  region: string | null;
  region_name: string | null;
  region_kladr_id: string | null;
  region_type: string | null;
  region_type_full: string | null;
  street: string | null;
  street_type: string | null;
  street_type_full: string | null;
  value: string | null;
};

export type SubmitDataProps = {
  name: string;
  surname: string;
  patronymic: string;
  sex: number;
  phone: string | null;
  email: string | null;
  timezone: string | null;
  birthdate: Date | null;
  address?: Address | string;
  city: string;
  region: number;
  country: string;
  role?: {
    key: number | null;
    value: string | null;
  };
  skills: ISkill[];
  work_with_bim: boolean;
  about: string;
  photo_upload?: File[];
};

type ComponentProps = {
  userId: string;
  unsaved: UnsavedType;
  currentSkills: ISkillForSelect[];
  setCurrentSkills: SetState<ISkillForSelect[]>;
  setUnsaved: SetState<UnsavedType>;
};

const defaultSchema = Yup.object().shape({
  email: Yup.string().email("Введите валидный email").required("Введите email"),
  skills: Yup.array().max(5, "Не больше пяти специализаций"),
  region: Yup.mixed().test("region", "Укажите регион", function (val) {
    return this.parent.country !== undefined && val;
  }),
  country: Yup.mixed().test(
    "country",
    "Укажите страну",
    (val) => val.length === 2
  ),
});

const advancedSchema = defaultSchema.concat(
  // @ts-ignore
  Yup.object().shape({
    name: Yup.string()
      .required("Введите имя")
      .matches(/^[-а-яА-ЯёЁ0-9\s]+$/, "Используйте кириллицу"),
    surname: Yup.string()
      .required("Введите фамилию")
      .matches(/^[-а-яА-ЯёЁ0-9\s]+$/, "Используйте кириллицу"),
    sex: Yup.number().required("Выберите пол"),
  })
);

const Info: React.FC<ComponentProps> = ({
  userId,
  unsaved,
  setUnsaved,
  currentSkills,
  setCurrentSkills,
}) => {
  const dispatch = useAppDispatch();

  const {
    name,
    surname,
    patronymic,
    birthdate,
    phone,
    email,
    photo,
    sex,
    address,
    city,
    region,
    country,
    role,
    userRoles,
    skillsList,
    timezone,
    timeZones,
    work_with_bim,
    about,
    canEdit,
    skills,
    loadProfile,
  } = useProfile();

  const phoneRef = useRef<string>(phone);

  const [birthDate, setBirthDate] = useState<Date | null>(birthdate);
  const [workWithBim, setWorkWithBim] = useState<ISkillForSelect>(
    // @ts-ignore
    workWithBimOptions[+work_with_bim]
  );

  const [timeZone, setTimeZone] = useState<ISkillForSelect>(
    // @ts-ignore
    timeZones.find((item: string) => item.title === timezone)
  );

  const [disabled, setDisabled] = useState<boolean>(false);

  const [userRole, setUserRole] = useState<ISkillForSelect>({
    id: role.key,
    title: role.value,
  });

  const {
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { dirtyFields, errors },
  } = useForm<SubmitDataProps>({
    // @ts-ignore
    resolver: yupResolver(canEdit ? advancedSchema : defaultSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      country: String(country?.code) || defaultCountry,
      region: region.id || 1,
    },
  });

  const watchCountry = watch("country");
  const watchRegion = watch("region");

  const dirtyForm = Object.keys(dirtyFields).length;

  const handleRemoveSkill = (id: number) => {
    const list = currentSkills.filter(
      (skill: ISkillForSelect) => skill.id !== id
    );
    setCurrentSkills(list);
  };

  const handleAddSkill = (value: ISkillForSelect) => {
    if (currentSkills.length === 5) {
      return;
    }

    const alreadyIncludes = currentSkills.some(
      (item: ISkillForSelect) => item.id === value.id
    );

    if (!alreadyIncludes) {
      setCurrentSkills([...currentSkills, value]);
    }
  };

  const handleSetUnsaved = (info = true) => {
    setUnsaved((prev) => ({
      ...prev,
      info,
    }));
  };

  useEffect(() => {
    handleSetUnsaved(Boolean(dirtyForm));
  }, [dirtyForm]);

  useEffect(() => {
    if (calculateAge(birthDate)) {
      setError("birthdate", {
        message: "Вы должны быть совершеннолетним, чтобы пользоваться сервисом",
      });
    } else {
      clearErrors("birthdate");
    }
  }, [birthDate, setError, clearErrors]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<SubmitDataProps> = async (data) => {
    setDisabled(true);

    const stateValues = {
      region: watchRegion,
      role: userRole.id,
      country: watchCountry,
      timezone: timeZone.title,
      skills: currentSkills.map((skill: ISkillForSelect) => skill.id),
      workWithBim: String(workWithBim.id),
      birthdate: birthDate,
      phone: phoneRef.current || "",
    };

    const preparedData = prepareDataForUpdateProfile({
      ...data,
      ...stateValues,
    });

    await request(
      ApiUpdateProfile(userId, preparedData),
      () => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setUnsaved((prev) => ({ ...prev, info: false }));
        loadProfile(userId);
      },
      () => (err) => {
        if (err.status === 422) {
          parseUnprocessableFields(err.message, setError);
        }
        enqueueSnackbar("Произошла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setDisabled(false);
  };

  const avatar = watch("photo_upload");
  const watchRole = watch("role");

  useEffect(() => {
    dispatch(
      setInboundForm({
        birthdate,
        phone,
        region: region.id,
        country: country.code,
        role: role.key,
        timezone,
        skills: skills.map((item) => item.id).sort(),
        work_with_bim,
      })
    );

    return () => {
      dispatch(clearForms());
    };
  }, []);

  useEffect(() => {
    dispatch(
      setOutboundForm({
        birthdate: birthDate,
        phone: phoneRef.current,
        region: watchRegion,
        country: watchCountry,
        role: userRole.id,
        timezone,
        skills: currentSkills.map((item) => item.id).sort(),
        work_with_bim: Boolean(workWithBim.id),
      })
    );
  }, [
    workWithBim,
    birthDate,
    watchRole,
    timezone,
    userRole,
    currentSkills,
    phoneRef.current,
  ]);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h2>Внимание !</h2>
        <span>
          Заполните данные физического лица (самозанятого). Информация об
          организации заполняется во вкладке “Организация”
        </span>
        {!canEdit && (
          <>
            <br />
            <br />
            <span>
              Редактирование Ф.И.О., даты рождения, адреса и паспортных данных
              невозможно без модерации администратора. Если вам необходимо их
              поменять, то создайте запрос на изменение реквизитов с уточнением
              причин изменения!
            </span>
          </>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.container}>
          <h2>Общая информация</h2>
          <div className={style.formContainer}>
            <div className={style.formContent}>
              <div className={style.formChunk}>
                <div className={style.avatarContainer}>
                  <img
                    className={style.avatar}
                    src={
                      avatar?.length
                        ? URL.createObjectURL(avatar[0])
                        : `${photo}&${new Date().valueOf()}`
                    }
                    alt="Аватар"
                  />
                  <input
                    {...register("photo_upload")}
                    type="file"
                    accept="image/*"
                    id="avatarInput"
                    className={style.avatarInput}
                  />
                  <label htmlFor="avatarInput" className={style.avatarLabel}>
                    <span>Заменить</span>
                  </label>
                </div>
                <div className={style.radioContainer}>
                  <label>
                    <input
                      {...register("sex")}
                      type="radio"
                      value="1"
                      defaultChecked={sex === 1}
                      disabled={!canEdit}
                    />
                    Мужчина
                  </label>
                  <label>
                    <input
                      {...register("sex")}
                      type="radio"
                      value="2"
                      defaultChecked={sex === 2}
                      disabled={!canEdit}
                    />
                    Женщина
                  </label>
                  {errors.sex?.message}
                </div>
                <Phone
                  value={phoneRef.current || ""}
                  changeHandler={(data) => {
                    // @ts-ignore
                    phoneRef.current = data;
                    if (!unsaved.info) {
                      handleSetUnsaved();
                    }
                  }}
                  error={Boolean(errors.phone?.message)}
                  errorText={errors.phone?.message || ""}
                  errorStyles={style.error}
                />
                <AdvancedAddressField
                  name="address"
                  register={register}
                  defaultValue={address}
                  setValue={setValue}
                  trigger={trigger}
                  disabled={!canEdit}
                />
                <Regions
                  name="region"
                  label="Регион"
                  value={watchRegion}
                  countryCode={watchCountry}
                  changeHandler={(data) => {
                    if (data.id) {
                      clearErrors("region");
                      setValue("region", data.id);
                    }
                  }}
                  paperOptions={paperOpt}
                  error={Boolean(errors.region?.message)}
                  helperText={errors.region?.message}
                  disabled={!canEdit}
                />
                <Select
                  value={userRole}
                  changeHandler={(_, value) => {
                    setUserRole(value);
                  }}
                  options={userRoles}
                  disableClear
                  label={"Основная роль"}
                  size={"small"}
                  forNewDesign
                />
                <Select
                  value={workWithBim}
                  changeHandler={(_, value) => {
                    setWorkWithBim(value);
                  }}
                  options={workWithBimOptions}
                  disableClear
                  label={"Работаю с БИМ"}
                  size={"small"}
                  forNewDesign
                  error={Boolean(errors.work_with_bim?.message)}
                  helperText={
                    Boolean(errors.work_with_bim?.message) &&
                    errors.work_with_bim?.message
                  }
                />
              </div>
              <div className={style.formChunk}>
                <TextField
                  label="Имя"
                  defaultValue={name}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                  {...register("name")}
                  {...textFieldSX}
                  disabled={!canEdit}
                />
                <TextField
                  label="Фамилия"
                  defaultValue={surname}
                  error={!!errors.surname}
                  helperText={errors.surname ? errors.surname.message : null}
                  {...register("surname")}
                  {...textFieldSX}
                  disabled={!canEdit}
                />
                <TextField
                  label="Отчество"
                  defaultValue={patronymic}
                  {...register("patronymic")}
                  {...textFieldSX}
                  disabled={!canEdit}
                />
                <DateField
                  label="Дата рождения"
                  startDateProp={birthDate}
                  changeDateHandler={(data) => {
                    setBirthDate(data);
                  }}
                  error={Boolean(errors.birthdate?.message)}
                  helperText={errors.birthdate?.message}
                  disabled={!canEdit}
                />
                <TextField
                  label="Email"
                  defaultValue={email}
                  {...register("email")}
                  {...textFieldSX}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
                <CountriesType
                  variant={style.countries}
                  changeHandler={async (data) => {
                    setValue("country", data.id);
                    setValue("region", 0);
                    await trigger("country");
                    await trigger("region");
                  }}
                  value={watchCountry}
                  label="Страна"
                  disabled={!canEdit}
                  paperOptions={paperOpt}
                  error={Boolean(errors.country?.message)}
                  helperText={errors.country?.message}
                />
                <TextField
                  label="Город"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={city || ""}
                  disabled={!canEdit}
                  {...register("city")}
                  {...textFieldSX}
                />
                <Select
                  value={timeZone}
                  changeHandler={(_, value) => {
                    setTimeZone(value);
                  }}
                  options={timeZones}
                  label={"Часовой пояс"}
                  size={"small"}
                  disableClear
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <h2>Выбор специализации</h2>
          <Select
            changeHandler={(_, value) => handleAddSkill(value)}
            options={skillsList}
            label={"Специализация"}
            size={"small"}
            disableClear
            disabled={currentSkills.length === 5}
          />
          {currentSkills.length === 5 && (
            <p className={cn(style.error, style.yellow)}>
              Максимальное количество специализаций для проектировщика не более
              пяти
            </p>
          )}
          <div className={style.specializations}>
            {currentSkills.map((skill: ISkillForSelect) => (
              <Chip
                sx={chipSX}
                label={skill.title}
                onDelete={() => handleRemoveSkill(skill.id)}
              />
            ))}
          </div>
          <span className={style.warning}>
            Выбор специализации «Функции ГИП» активирует роль ГИП на платформе!
          </span>
        </div>
        <div className={style.container}>
          <h2>О себе</h2>
          <div className={cn(style.lastFormContainer, style.multiScroll)}>
            <TextField
              multiline
              minRows={1}
              maxRows={10}
              defaultValue={about}
              {...register("about")}
              {...textFieldSX}
            />
          </div>
        </div>
        <CustomButton
          width={160}
          type="submit"
          background={palette.blue}
          className={style.submitButton}
          disabled={disabled}
          style={{
            margin: "0 calc(100% - 160px) 0 0",
            opacity: disabled ? "0.5" : "1",
          }}
        >
          Сохранить
        </CustomButton>
      </form>
    </div>
  );
};

export default Info;
