import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import styles from "./RemoveRequestExecutionForm.module.scss";
import { useRemoveRequestExecution } from "../lib/hooks/useRemoveRequestExecution";
import { Paragraph } from "../../../../../../shared/uiKit/v2/Paragraph";

type Props = {
  taskId: number;
  header: string;
  processWork?: boolean;
};

export const RemoveRequestExecutionForm = ({
  taskId,
  header,
  processWork = false,
}: Props) => {
  const { handleClose } = useModalContext();

  const { handleSubmit, onSubmit, isSubmitting } = useRemoveRequestExecution({
    taskId,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <Paragraph color="light" className={styles.text}>
          {`Вы уверены, что хотите удалить свою заявку на исполнение ${processWork ? "задачи" : "раздела"}?`}
        </Paragraph>
        <Modal.Controls>
          <Button
            disabled={isSubmitting}
            color="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Нет
          </Button>
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
          >
            Да
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
