import React, { useState, useEffect } from "react";
import { Skeleton, TextField } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSnackbar } from "notistack";
import cn from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import style from "./style.module.scss";
import { UnsavedType } from "../../../Project/types";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import { getErrorMessage } from "../../../../../app/services/api/requestHandler";

type ComponentProps = {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
};

type SubmitDataProps = {
  User: {
    mailing_subscribe: boolean;
    telegram: string;
  };
  EmailNotifyParams: {
    mail_new_process: boolean;
    expired_process: boolean;
    ending_process: boolean;
    refresh_process: boolean;
    new_requests: boolean;
    new_message: boolean;
    material_response: boolean;
  };
};

const schema = Yup.object().shape({
  User: Yup.object({
    telegram: Yup.string().matches(
      /^($|[A-Za-z0-9])+$/,
      "Значение не должно содержать кириллицу, спецсимволы или пробелы"
    ),
  }),
});

const Notifications: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const {
    loadNotifications,
    updateNotifications,
    notificationsIsLoading,
    notifications,
  } = useProfile();

  useEffect(() => {
    loadNotifications(userId);
  }, [userId]);

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, notifications: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<SubmitDataProps> = (data) => {
    setDisabled(true);
    updateNotifications({ id: userId, ...data })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setDisabled(false);
        setUnsaved((prev) => ({ ...prev, notifications: false }));
        loadNotifications(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setDisabled(false);
      });
  };

  return (
    <div className={style.wrapper}>
      {notificationsIsLoading || !notifications ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="500px"
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.container}>
            <div className={style.statusContainer}>
              <h2>E-mail</h2>
              <div className={style.status}>
                <span className={style.statusText}>
                  {notifications.User.mailing_subscribe
                    ? "Включено"
                    : "Не включено"}
                </span>
                <div
                  className={cn(style.statusColor, {
                    [style.greenStatus]: notifications.User.mailing_subscribe,
                  })}
                />
              </div>
            </div>
            <div className={style.formContainer}>
              <label className={style.mainLabel}>
                <input
                  {...register("User.mailing_subscribe")}
                  type="checkbox"
                  defaultChecked={notifications.User.mailing_subscribe}
                />
                <p>Разрешить отправку уведомлений на E-mail</p>
              </label>
              <div className={style.formContent}>
                <div className={style.formChunk}>
                  <label>
                    <input
                      {...register("EmailNotifyParams.mail_new_process")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.mail_new_process
                      }
                    />
                    <p>Новые разделы</p>
                  </label>
                  <label>
                    <input
                      {...register("EmailNotifyParams.new_requests")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.new_requests
                      }
                    />
                    <p>Новые заявки</p>
                  </label>
                  <label>
                    <input
                      {...register("EmailNotifyParams.new_message")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.new_message
                      }
                    />
                    <p>Новые сообщения</p>
                  </label>
                </div>
                <div className={style.formChunk}>
                  <label>
                    <input
                      {...register("EmailNotifyParams.expired_process")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.expired_process
                      }
                    />
                    <p>Просроченные разделы</p>
                  </label>
                  <label>
                    <input
                      {...register("EmailNotifyParams.ending_process")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.ending_process
                      }
                    />
                    <p>Разделы подходящие к завершению (3 дн.)</p>
                  </label>
                  <label>
                    <input
                      {...register("EmailNotifyParams.refresh_process")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.refresh_process
                      }
                    />
                    <p>Разделы без исполнителя</p>
                  </label>
                  <label>
                    <input
                      {...register("EmailNotifyParams.material_response")}
                      type="checkbox"
                      defaultChecked={
                        notifications.EmailNotifyParams.material_response
                      }
                    />
                    <p>Предложения поставщиков</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={style.container}>
            <div className={style.statusContainer}>
              <h2>Telegram</h2>
              <div className={style.status}>
                <span className={style.statusText}>
                  {notifications.User.telegram ? "Включено" : "Не включено"}
                </span>
                <div
                  className={cn(style.statusColor, {
                    [style.greenStatus]: notifications.User.telegram,
                  })}
                />
              </div>
            </div>
            <div className={style.telegramChunk}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: <>@</>,
                }}
                label="Введите ваше публичное имя"
                defaultValue={notifications.User.telegram}
                autoComplete="off"
                error={Boolean(errors.User?.telegram)}
                helperText={errors.User?.telegram?.message}
                {...register("User.telegram")}
                {...textFieldSX}
              />
              <CustomButton
                width={234}
                height={32}
                type="button"
                background={palette.green}
                onClick={() => window.open("https://t.me/pirs_3_bot", "_blank")}
              >
                Запустить ПИРС-бот
              </CustomButton>
            </div>
          </div>
          <CustomButton
            width={160}
            type="submit"
            background={palette.blue}
            className={style.button}
            disabled={disabled}
            style={{
              margin: "0 calc(100% - 160px) 0 0",
              opacity: disabled ? "0.5" : "1",
            }}
          >
            Сохранить
          </CustomButton>
        </form>
      )}
    </div>
  );
};

export default Notifications;
