import Filter from "./Filter";

class FilterApplications extends Filter {
  static filterByQuery(data: any, query: string) {
    const allData = [...data.good, ...data.bad];

    const filteredApplications = allData.filter((application: any) => {
      if (!application?.task?.author) {
        return false;
      }

      const { surname, name, patronymic } = application.task.author;

      const p0 = Filter.valueContains(
        `${surname} ${name} ${patronymic}`,
        query
      );
      const p1 = Filter.valueContains(surname, query);
      const p2 = Filter.valueContains(name, query);
      const p3 = Filter.valueContains(patronymic, query);

      const p4 = application.userType.listName
        .toLowerCase()
        .includes(query.toLowerCase());

      return p0 || p1 || p2 || p3 || p4;
    });

    return filteredApplications.map((application: any) => application.id);
  }

  static filterByFulfilled(data: any, fulfilled: boolean) {
    return [];
  }
}

export default FilterApplications;
