import { config } from "../../../../app.cofig";
import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPatch,
  httpRequestPost,
  httpRequestPostMultiData,
  httpRequestPut,
} from "../api";
import { projectLogsSortType } from "../../../../types/stateTypes/projectLogs";

export type ApiSetGipOnProjectDataProps = {
  price: number;
  gip_type: number[];
  user_id: number;
  user_typeByAdmin: number;
};

export const ApiSetGipOnProject = (
  // Выбор ГИПа на проект
  id: string,
  data: ApiSetGipOnProjectDataProps
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project/search-gip?id=${id}`,
    {},
    {
      GipProcess: {
        ...data,
      },
    }
  ).then((res) => res.json());
};

export const ApiDeleteProject = (id: string | number): Promise<Response> => {
  // Удаление проекта
  return httpRequestDelete(`${config.localDomain}/v1/project/delete?id=${id}`);
};

export const ApiProjectPause = (id: number): Promise<Response> => {
  // Поставить проект на паузу
  return httpRequestPost(`${config.localDomain}/v1/project/pause?id=${id}`);
};

export const ApiProjectUnPause = (id: number): Promise<Response> => {
  // Снять проект с паузы
  return httpRequestPost(`${config.localDomain}/v1/project/unpause?id=${id}`);
};

export const ApiRecalculationsRelations = (id: number): Promise<Response> => {
  // Обновить связи
  return httpRequestPost(
    `${config.localDomain}/v1/project/recalculation-relations?id=${id}`
  );
};

export const ApiProjectDeleteGip = (id: string): Promise<Response> => {
  // Удалить ГИПа на проекте
  return httpRequestPost(
    `${config.localDomain}/v1/project/delete-gip?id=${id}`
  );
};

export const ApiProjectCancelSearchGip = (
  // отменить поиск ГИПа на проекте
  id: string | number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project/cancel-search-gip?id=${id}`
  );
};

const expands = `
processPublishedCost,
industry,
accountId,
files,
expertise,
buildingFunction,
fundType,
requestRemoveGipTask,
projectParameters,
gipProcess,
designType,
can,
constructionType,
files,
gipHelpers,
gipAccess,
gipAccess.userType,
chatId,
authorAccess,
tabs,
gipHelpersApplication,
expertise,
seismicity,
resultFilesSize,
authorPremium
`;
export const ApiActuallyProject = (id: string | number): Promise<Response> => {
  // получить данные для отображения проекта
  return httpRequestGet(
    `${config.localDomain}/v1/project/view?id=${id}&expand=${expands}`
  );
};

export const ApiInitialDataProject = (
  id: number,
  searchValue: string,
  type: number
): Promise<Response> => {
  return httpRequestGet(
    `${
      config.localDomain
    }/v1/project-checklist/list-by-project-with-count?projectId=${id}&ProjectChecklistSearch%5Bname%5D=${searchValue}&ProjectChecklistSearch[type]=${
      type ?? ""
    }`
  );
};

export const ApiGipHelperList = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/firm/get-members?id=${id}&expand=user&UserRoleSearch[role_id]=7`
  );
};

export const ApiGipHelperDelegate = (
  id: number,
  delegate: boolean
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/delegate?accessId=${id}&delegate=${delegate}`
  );
};

export const ApiGipHelperInvite = (
  id: string,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project/invite-gip-helper-by-project?projectId=${id}&needComplete=true`,
    {},
    data
  );
};

export const ApiGipHelperApplicateInvite = (
  id: number,
  status: number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: {
        status,
      },
    }
  );
};

export const ApiGipHelperRemove = (
  projectId: string,
  userId: string | number,
  active: boolean
): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/project/remove-gip-helper?projectId=${projectId}&userId=${userId}&active=${active}`
  );
};

export const ApiParentCheckList = (
  parentId: number,
  id: number,
  searchValue: string,
  type: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-checklist/list-by-project?projectId=${id}&parentId=${parentId}&searchStr=${searchValue}&type=${type}`
  );
};

const expandSource = `checklist,status,date_create,date_deadline,date_complete,can.download`;
export const ApiProjectSourceView = (
  id: number,
  parentId: number,
  searchValue = "",
  type: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-source-data/index?per-page=30&ProjectSourceDataSearch[project_id]=${id}&expand=${expandSource}&ProjectSourceDataSearch[parentId]=${parentId}&ProjectSourceDataSearch[checklistName]=${searchValue}&ProjectSourceDataSearch[type]=${type}`
  );
};

export const ApiTaskDateUpdate = (
  id: number,
  data: {
    date_create?: string;
    date_deadline: string;
  }
): Promise<Response> => {
  return httpRequestPatch(
    `${config.localDomain}/v1/task/update-date?id=${id}`,
    {},
    data
  );
};

export const ApiUpdateInitialDataProject = (
  id: string,
  data: any,
  parentId: number,
  type: number
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/project-source-data/update?projectId=${id}&parentChecklistId=${parentId}&type=${type}`,
    {},
    data
  );
};
export const ApiSearchGip = (
  // запрос на поиск ГИПа в проект
  id: string,
  data: { price: number; gip_type: number[] | string[] }
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project/search-gip?id=${id}`,
    {},
    {
      GipProcess: {
        ...data,
      },
    }
  );
};

export const ApiCompareGip = (id: string, sortType = ""): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/compare-gip?id=${id}&expand=user,task,room_id,userType,job_duration,rating,price&sort=${sortType}`
  );
};

export const ApiTaskComplete = (id: string) => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/auto-complete?id=${id}`
  );
};

export const ApiDeleteProjectChecklist = (id: number) => {
  return httpRequestDelete(
    `${config.localDomain}/v1/project-checklist/delete?id=${id}`
  );
};

export const ApiGetLogs = (
  id: number,
  currentPage: number,
  perPage: number,
  filterParams: {
    fio: string;
    dateTo: string;
    dateFrom: string;
    data: string;
    type: string;
  } = {
    dateFrom: "",
    dateTo: "",
    fio: "",
    data: "",
    type: "1",
  },
  sortOption: projectLogsSortType
): Promise<Response> => {
  const logSearchType = filterParams.type
    ? `&LogSearch[type]=${filterParams.type}`
    : "";
  const logSearchDateTo =
    filterParams.dateTo !== "0"
      ? `&LogSearch[date2]=${filterParams.dateTo}`
      : "";
  const logSearchDateFrom =
    filterParams.dateFrom !== "0"
      ? `&LogSearch[date1]=${filterParams.dateFrom}`
      : "";
  const sort =
    sortOption.type === "asc"
      ? `&sort=${sortOption.sort.name}`
      : `&sort=-${sortOption.sort.name}`;

  return httpRequestGet(
    `${config.localDomain}/v1/project/get-logs?id=${id}&page=${currentPage}${logSearchType}&per-page=${perPage}${sort}&LogSearch[userFio]=${filterParams.fio}${logSearchDateFrom}${logSearchDateTo}&LogSearch[data]=${filterParams.data}`
  );
};

export const ApiGetLogsTypes = (): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/project/get-log-types`);

export const ApiGetProcessData = (id: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-process/get-project-process-tree?id=${id}&expand=activeAccess.user,activeAccess.userType,countAccesses,can`
  );

export const ApiRequestRemoveGipStart = (
  // Снять
  data: { StartForm: { content: string } },
  id: string | number
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/request-remove-gip/start?projectId=${id}`,
    {},
    data
  );

export const ApiRevokeRequestGipProject = (id: string | number) =>
  httpRequestPost(
    `${config.localDomain}/v1/project-access/request-gip?projectId=${id}`,
    {},
    {}
  );

export const ApiRequestGipProject = (
  // заявиться Гипом на проект
  id: string,
  data: { price: number; gip_type: number[]; content: string }
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/request-gip?projectId=${id}`,
    {},
    {
      StartForm: {
        ...data,
      },
    }
  );
};
export const ApiRemoveGipRequest = (id: string | number) =>
  httpRequestPost(
    `${config.localDomain}/v1/project-access/remove-gip-request?projectId=${id}`,
    {},
    {}
  );

export const ApiRequestRemoveGipValidate: (
  data: { comment: string; status: number },
  id: number | string
) => Promise<Response> = (data, id) =>
  httpRequestPost(
    `${config.localDomain}/v1/request-remove-gip/complete-validate?taskId=${id}`,
    {},
    {
      CompleteForm: data,
    }
  );

export const ApiRequestRemoveGip: (
  data: { comment: string; status: number },
  id: number | string
) => Promise<Response> = (data, id) =>
  httpRequestPost(
    `${config.localDomain}/v1/request-remove-gip/complete?taskId=${id}`,
    {},
    {
      CompleteForm: data,
    }
  );

export const ApiAddFileTask = (
  id: string,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/task/add-file?id=${id}`,
    {},
    formData
  );
};

export const ApiProjectInvite = (
  id: string | number,
  data: {
    ProjectInviteForm: {
      project_id: number;
      comment: string;
    };
  }
) =>
  httpRequestPost(
    `${config.localDomain}/v1/notice/project-invite?user_id=${id}`,
    {},
    { ...data }
  );

export const ApiChangeProcess = (
  id: string | number,
  data: string[] | number[]
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/project/change-process?id=${id}`,
    {},
    {
      ids: data,
    }
  );

export const ApiCompleteSubTask = (
  id: number,
  data: Record<string, any>
): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/task/complete?id=${id}`, {}, data);

export const ApiSubTaskBackToWork = (id: number): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/task/back-to-work?id=${id}`);
