import { RootState } from "../../../../store";

export const getProjectProcessWorkGroups = (state: RootState) =>
  state.projectProcessView.work.groups;

export const getProjectProcessWorkIsLoad = (state: RootState) =>
  state.projectProcessView.work.isLoad;

export const getProjectProcessWorkCardIsLoad = (state: RootState) =>
  state.projectProcessView.work.isLoadCard;
export const getProjectProcessDelegateLoad = (state: RootState) =>
  state.projectProcessView.work.isLoadDelegate;
export const getProjectProcessWorkAllPrice = (state: RootState) =>
  state.projectProcessView.work.fullCost;
