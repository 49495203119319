import React from "react";
import classNames from "classnames";

import { Wrapper } from "../../../templates";
import style from "../style.module.scss";
import { ProjectProcessItem } from "./ProjectProcess/ProjectProcessItem";
import { ProjectsProcessDataType } from "../../../../../types/stateTypes/indexPages/IndexProjectProcessDataType";

type ProjectProcessList = {
  variant?: string;
  items: ProjectsProcessDataType[];
  theme?: string;
};

export const ProjectProcList: React.FC<ProjectProcessList> = ({
  variant = "",
  items,
  theme = "dark",
}) => {
  return (
    <>
      {items.length > 0 ? (
        <ul className={classNames(variant, style.projectList)}>
          {items.map((item) => {
            return (
              <li
                key={item.id}
                className={classNames(style.projectItem, style[theme])}
              >
                <ProjectProcessItem {...item} />
              </li>
            );
          })}
        </ul>
      ) : (
        <Wrapper>
          <div className={style.nothingFound}>Ничего не найдено</div>
        </Wrapper>
      )}
    </>
  );
};
