import { RootState } from "../../../../store";

export const getProjectProcessMaterialsFirm = (state: RootState) =>
  state.projectProcessView.material.firms;

export const getProjectProcessMaterialsFirmForHeader = (state: RootState) =>
  state.projectProcessView.material.firmsForHeader;

export const getProjectProcessMaterialsFirmIsLoad = (state: RootState) =>
  state.projectProcessView.material.isLoadFirms;
