import { useMemo } from "react";
import { useProcessWorkGetDocumentationsQuery } from "src/FSD/entities/processWork/api";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { useTabsPanelContext } from "../../../../TabsPanel";

type TExecutionTask = {
  customerAlign: ApiTaskResponses.View[];
  workTask: ApiTaskResponses.View[];
};

export const useExecutionTask = (processWorkId: number) => {
  const { data, isFetching } = useProcessWorkGetDocumentationsQuery({
    id: processWorkId,
  });

  const { currentChildTab } = useTabsPanelContext();

  const isExecutionTaskLoading = isFetching || !data;

  const executionTaskData: TExecutionTask = useMemo(() => {
    if (!data) {
      return {
        customerAlign: [],
        workTask: [],
      };
    }

    return {
      customerAlign: [
        ...data.requestCustomerAlign.map((item: any) => ({
          ...item,
          isRequest: true,
        })),
        ...data.customerAlign,
      ],
      workTask: [data.task],
    };
  }, [data]);

  const executionTaskList =
    executionTaskData[currentChildTab as keyof TExecutionTask] || [];

  return {
    isExecutionTaskLoading,
    executionTaskList,
    currentChildTab,
  } as const;
};
