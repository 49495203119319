import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "../../../UI/components/Modal/Modal";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { UserAgreements } from "../../../UI/components/Conditions/UserAgreements";

export const ShowUserAgreement = () => {
  const modalRoot = document.querySelector("#root-conditions");
  const { Popup } = useUserWorkflow();

  const Portal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  const onClose = () => {
    Popup.showUserAgreement.close();
  };

  return (
    <Portal>
      <Modal
        head={"Пользовательское соглашение"}
        body={<UserAgreements />}
        closeHandler={onClose}
      />
    </Portal>
  );
};
