import { Controller } from "react-hook-form";
import { Rating } from "../../../../../../shared/uiKit/v2/Rating";
import styles from "./FeedbackTaskForm.module.scss";

type Props = {
  control: any;
};

export const RatingStarsFeedback = ({ control }: Props) => {
  return (
    <div className={styles.ratingBlock}>
      <Controller
        name="quality"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Rating.Stars
            handleChange={(value: number) => {
              onChange(value);
            }}
            value={value}
            max={5}
            size="large"
            label="Качество"
            precision={0.5}
            className={styles.ratingBlock__item}
            style={{ stroke: "#FFF" }}
          />
        )}
      />
      <Controller
        name="reliability"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Rating.Stars
            handleChange={(value: number) => {
              onChange(value);
            }}
            value={value}
            max={5}
            size="large"
            label="Надежность"
            precision={0.5}
            className={styles.ratingBlock__item}
            style={{ stroke: "#FFF" }}
          />
        )}
      />
      <Controller
        name="loyalty"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Rating.Stars
            handleChange={(value: number) => {
              onChange(value);
            }}
            value={value}
            max={5}
            size="large"
            label="Лояльность"
            precision={0.5}
            className={styles.ratingBlock__item}
            style={{ stroke: "#FFF" }}
          />
        )}
      />
    </div>
  );
};
