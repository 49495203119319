import React from "react";
import { SourceDataType } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessInitialDataTypes";
import { NothingFound } from "src/FSD/shared/uiKit/v2/NothingFound/NothingFound";
import { TabsSourceData } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabsSourceData/TabsSourceData";
import { TabDataByProcess } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabDataByProcess";

type TProps = {
  activeTab: number;
  sourceData: Record<string, SourceDataType[]>;
};

export const SourceDataContent = ({ activeTab, sourceData }: TProps) => {
  if (activeTab === 5) {
    return <TabDataByProcess />;
  }

  const data = sourceData?.[activeTab];
  if (!data || !data.length) {
    return <NothingFound />;
  }

  return (
    <>
      {data.map((el, index) => (
        <TabsSourceData key={el.name} {...el} index={index} type={activeTab} />
      ))}
    </>
  );
};
