import React, { FC, useEffect } from "react";
import classNames from "classnames";
import classes from "./Modal.module.scss";
import { ModalCloseIcon } from "../SVG";
import { ModalPreloader } from "./ModalPreloader";
import { setCheckInput } from "../../../app/feature/ProjectView/projectView";
import { useAppDispatch } from "../../../app/store";
import { SetState } from "../../features/ProjectEditing/SectionEditing/SectionEditing";

interface ModalProp {
  active: boolean;
  setActive: (active: boolean) => void | SetState<boolean>;
  isLoad?: boolean;
  activeClass?: string;
  handleClose?: () => void;
}

const Modal: FC<ModalProp> = ({
  active,
  setActive,
  children,
  isLoad,
  activeClass,
  handleClose,
}) => {
  const dispatch = useAppDispatch();

  function closeModal() {
    if (handleClose) {
      handleClose(); // доп обработчик из компонента
    }

    dispatch(setCheckInput(false));
    setActive(false);
  }

  const handleClosePopup = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    const handleClosePopup = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleClosePopup);

    return () => {
      document.removeEventListener("keydown", handleClosePopup);
    };
  }, []);

  const activeModal = classNames({
    [classes.active_modal]: active,
    [classes.modal]: !active,
  });
  const activeContent = classNames(
    {
      [classes.active_content]: active,
      [classes.modal__content]: !active,
    },
    activeClass
  );

  return (
    <div
      role="button"
      tabIndex={0}
      className={activeModal}
      // onClick={() => setActive(false)}
      onKeyDown={(event) => handleClosePopup(event)}
    >
      <div
        role="button"
        tabIndex={0}
        className={activeContent}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => handleClosePopup(event)}
      >
        <div className={classes.header}>
          <div
            className={classes.btnClose}
            onKeyDown={(event) => handleClosePopup(event)}
            onClick={closeModal}
          >
            <ModalCloseIcon />
          </div>
        </div>
        {isLoad && <ModalPreloader />}
        {active && !isLoad ? children : null}
      </div>
    </div>
  );
};
export default Modal;
