import { Controller } from "react-hook-form";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import styles from "./PublishContentProcess.module.scss";
import { TValuesPublication } from "../../lib/hooks/usePublishContent";

type Props = {
  control: any;
  valuesPublication: TValuesPublication;
  textProcess: string;
};

export const AdditionalDevelopment = ({
  control,
  valuesPublication,
  textProcess,
}: Props) => {
  const { vor, vorPnr, kac } = valuesPublication;

  return (
    <div className={styles.processPublication__wrapper}>
      <p className={styles.processPublication__text}>
        {`Дополнительные требования к разработке ${textProcess}`}
      </p>
      <div className={styles.additionalDevelopment}>
        {vor && (
          <Controller
            control={control}
            name="vor"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Ведомость объемов работ"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
        {kac && (
          <Controller
            control={control}
            name="kac"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Конъюнктурный анализ цен"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
        {vorPnr && (
          <Controller
            control={control}
            name="vorPnr"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="ВОР на пусконаладочные работы"
                onChange={onChange}
                value={value}
                checked={!!value}
                editing
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="bim"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label="BIM"
              onChange={onChange}
              value={value}
              checked={value}
              editing
            />
          )}
        />
      </div>
    </div>
  );
};
