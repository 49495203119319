import React from "react";
import classNames from "classnames";
import { UseFormRegister } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ProjectViewData } from "src/app/feature/ProjectView/createData/createData";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { ApiPrimaryProjectPart } from "src/types/api/primaryTypes/apiPrimaryProjectPart";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";
import styles from "./PublishModalContent.module.scss";

type TInputs = {
  pay1: string;
  pay2: string;
  pay3: string;
  paymentSum: string;
};

type TProps<TFieldValues extends TInputs> = {
  // register: UseFormRegister<TInputs>;
  register: any;
  project: ProjectViewData | ApiPrimaryProjectType;
  projectPart: ApiPrimaryProjectPart;
  errors: FieldErrors<TFieldValues>;
  haveExpertise: boolean;
  payDiv: boolean;
};

export const TermsPayment = ({
  register,
  project,
  errors,
  haveExpertise,
  projectPart,
  payDiv,
}: TProps<TInputs>) => {
  return (
    <div>
      <h3 className={styles.payBlock_title}>Условия оплаты</h3>
      {payDiv && (
        <div className={classNames(styles.form_pay)}>
          <TextField
            {...register("pay1")}
            label="Аванс (%)"
            InputLabelProps={{ shrink: false }}
            defaultValue={project.advance_pay}
            error={!!errors.pay1}
            helperText={errors?.pay1?.message}
          />
          <TextField
            {...register("pay2")}
            label="После приемки (%)"
            defaultValue={
              !haveExpertise ? project.pay2 + project.pay1 : project.pay1
            }
            InputLabelProps={{ shrink: false }}
            error={!!errors.pay2}
            helperText={errors?.pay2?.message}
          />
          {haveExpertise && projectPart.published && (
            <TextField
              {...register("pay3")}
              label="После экспертизы (%)"
              defaultValue={project.pay2}
              error={!!errors.pay3}
              helperText={errors?.pay3?.message}
              InputLabelProps={{ shrink: false }}
              className={styles.form_pay_second}
            />
          )}
          <input type="hidden" {...register("paymentSum")} />
          {errors.paymentSum && (
            <p className={styles.error}>{errors.paymentSum?.message}</p>
          )}
        </div>
      )}
    </div>
  );
};
