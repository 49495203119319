import { FC, ReactNode, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Popup } from "../../../UI/components/Popup/Popup";
import style from "./style.module.scss";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import {
  selectorEEErrorHeader,
  selectorEEErrorMessage,
} from "../../../../app/feature/eventEmitter/error/error";
import { getModalRootElement } from "../../../../utils/getModalRootElement";
import { selectorAppTheme } from "../../../../app/feature/app/app";

const modalRoot = getModalRootElement();

const Modal: FC<{ children: ReactNode }> = ({ children }) =>
  ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<
    any,
    any
  > as ReactElement<any, any>;

export const ErrorPopup: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectorAppTheme);

  const message = useSelector(selectorEEErrorMessage);
  const header = useSelector(selectorEEErrorHeader);

  const onClose = () => {
    dispatch({
      type: "CLOSE_EVENT_ERROR",
    });
  };

  return (
    <Modal>
      <Popup
        size={"small"}
        head={
          <p className={classNames(style.errorName, style[theme])}>{header}</p>
        }
        body={<p>{message}</p>}
        bottom={
          <div className={style.buttonWrapper}>
            <Button theme={theme} color={"blue"} clickHandler={onClose}>
              Ок
            </Button>
          </div>
        }
        closeHandler={onClose}
      />
    </Modal>
  );
};
