import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiProjectAccess } from "../../model/requestsTypes";

type TParams = RequestParams<{
  id: number;
  formedData: ApiProjectAccess.Request;
}>;

export const requestWork = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, formedData }) => ({
      url: `v1/project-access/request-work`,
      params: {
        workId: id,
      },
      data: { StartForm: formedData },
      method: HTTPMethods.POST,
    }),
    invalidatesTags: ["ProjectAccess.RequestWork"],
  });
