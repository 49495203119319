import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Регистрация, управление показом политики конфидециальности
 */

export const showEventUserPolicy = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventShowUserPolicy: {
      ...state.eventShowUserPolicy,
      isShow: true,
    },
  };
};

export const closeEventUserPolicy = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventShowUserPolicy: {
      isShow: false,
      data: {},
    },
  };
};

export const selectorEEShowUserPolicy = (state: StateType) =>
  state.eventEmitter.eventShowUserPolicy.isShow;
