import { useEffect, useState, ChangeEvent } from "react";
import { IconButton, Skeleton } from "@mui/material";
import { Add } from "@mui/icons-material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  TFirmMilestone,
  TFirmMilestonesList,
  TStage,
  getMilestone,
  setBuildingFunction,
  setOpenMilestones,
  setShowAnchorModal,
} from "src/app/feature/milestone";
import {
  getBfList,
  getFirmMilestones,
  getGroupControl,
} from "src/app/feature/milestone/thunks";
import { palette } from "src/styles/restyle";
import style from "../style.module.scss";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";
import { EditSVG } from "../../../../newUI/SVG";
import CreateProcessModal from "./modals/CreateProcessModal";
import EditingProcessModal from "./modals/EditingProcessModal";
import AnchorModal from "./modals/Anchor";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import Setup, { SkeletonGroup } from "./Setup";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";

type SetupListProps = {
  bfType: number;
  firmId: number;
};

const SetupList = ({ bfType, firmId }: SetupListProps) => (
  <Setup bfType={bfType} firmId={firmId} />
);

type MileStoneNameProps = {
  stage: any;
  order: number;
  handleEditAnchor: (stage: any) => void;
};

const MilestoneName = ({
  stage,
  order,
  handleEditAnchor,
}: MileStoneNameProps) => {
  const { name, stageAnchor } = stage;
  const hasAgreement =
    !stageAnchor || Array.isArray(stageAnchor?.params)
      ? null
      : Boolean(
          Number(stageAnchor?.params?.approve?.value) ||
            Number(stageAnchor?.params?.["kac-vor-pnr"]?.value)
        );

  const className = stageAnchor?.class.name;
  const methodName = stageAnchor?.method.name;

  const classIcon = stageAnchor?.class.icon;
  const methodIcon = stageAnchor?.method.icon;

  const isNameEmpty = !className && !methodName;

  const Agreement = () => (
    <div className={style.milestoneAgreement}>
      {hasAgreement !== null && (
        <>
          {hasAgreement ? (
            <>
              <i className="bi bi-check-lg" />
              <p>Да</p>
            </>
          ) : (
            <i className="bi bi-x-lg" />
          )}
        </>
      )}
    </div>
  );

  const Class = () => (
    <div className={style.milestoneClass}>
      {isNameEmpty ? (
        <>
          <i className="bi bi-hand-index" />
          <p>Ручное управление</p>
        </>
      ) : (
        <>
          <i className={classIcon} />
          <p>{className}</p>
        </>
      )}
    </div>
  );

  const Method = () => (
    <div className={style.milestoneMethod}>
      {!isNameEmpty && (
        <>
          <i className={methodIcon} />
          <p>{methodName}</p>
        </>
      )}
    </div>
  );

  const Order = () => <div className={style.order}>{order}</div>;

  const Name = () => <div className={style.milestoneName}>{name}</div>;

  const EditingButton = () => (
    <IconButton
      onClick={() => handleEditAnchor(stage)}
      className={style.smallEdit}
    >
      <EditSVG />
    </IconButton>
  );

  return (
    <li className={style.milestoneRow}>
      <Order />
      <Name />
      <Class />
      <Method />
      <Agreement />
      <EditingButton />
    </li>
  );
};

type MilestonesGroupProps = {
  firmMilestones: TFirmMilestonesList;
  pending: boolean;
  bfType: number;
  handleEditProcess: (milestone: TFirmMilestone) => void;
  handleEditAnchor: (stage: any) => void;
};

const MilestonesGroup = ({
  firmMilestones,
  pending,
  bfType,
  handleEditProcess,
  handleEditAnchor,
}: MilestonesGroupProps) => {
  const dispatch = useAppDispatch();
  const {
    data: { openMilestones },
  } = useAppSelector(getMilestone);

  const milestones = firmMilestones?.[bfType] ?? [];

  const handleToggle = (id: number) => {
    dispatch(setOpenMilestones(id));
  };

  const Head = () => (
    <li className={cn(style.milestoneRow, style.firstRow)}>
      <div className={cn(style.milestoneName, style.nameLong)}>
        Наименование вехи
      </div>
      <div className={cn(style.milestoneClass, style.longClass)}>Тип</div>
      <div className={cn(style.milestoneMethod, style.methodLong)}>
        Завершение
      </div>
      <div className={cn(style.milestoneAgreement, style.agreementLong)}>
        Доп. согл
      </div>
    </li>
  );

  if (pending) {
    return <Skeleton className={style.skeleton} />;
  }

  if (!milestones.length) {
    return <div className={style.center}>Ничего не найдено</div>;
  }

  return (
    <>
      {milestones
        .filter((milestone: TFirmMilestone) => milestone.active)
        .map((milestone: TFirmMilestone) => {
          const isToggled = openMilestones.includes(milestone.id);

          return (
            <div
              key={`milestone-${milestone.id}`}
              className={style.processGroup}
            >
              <Accordion
                onToggle={() => {
                  handleToggle(milestone.id);
                }}
                toogle={isToggled}
                text={
                  <div className={style.processName}>
                    <p>{milestone.name}</p>
                    <IconButton
                      onClick={(e) => {
                        handleEditProcess(milestone);
                        e.stopPropagation();
                      }}
                      className={style.darkEditButton}
                    >
                      <EditSVG />
                    </IconButton>
                  </div>
                }
                light
              />
              {isToggled && (
                <ol className={style.groupList}>
                  <Head />
                  {milestone.stages
                    .filter((stage: TStage) => stage.active)
                    .map((stage: TStage, index: number) => (
                      <MilestoneName
                        key={`stage-${stage.id}`}
                        order={index + 1}
                        stage={stage}
                        handleEditAnchor={handleEditAnchor}
                      />
                    ))}
                </ol>
              )}
            </div>
          );
        })}
    </>
  );
};

type MilestoneProps = {
  firmId: number;
};

const Milestone = ({ firmId }: MilestoneProps) => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const [currentStage, setCurrentStage] = useState<any>(null);
  const [process, setProcess] = useState<TSelectItem>({ id: 0, title: "" });
  const [selectedFirst, setSelectedFirst] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const { data, pending } = useAppSelector(getMilestone);

  useEffect(() => {
    dispatch(getBfList());
  }, []);

  useEffect(() => {
    if (pending.anchorModify || pending.anchorDelete) {
      return;
    }

    dispatch(getFirmMilestones(firmId));
  }, [pending.anchorModify, pending.anchorDelete]);

  useEffect(() => {
    if (!data.buildingFunction.id || pending.deleteGroup) {
      return;
    }

    dispatch(getGroupControl({ bfType: data.buildingFunction.id, firmId }));
  }, [data.buildingFunction.id, pending.deleteGroup, selectedFirst]);

  const handleEditProcess = (milestone: TFirmMilestone) => {
    setShowEditModal(true);
    setProcess({ id: milestone.id, title: milestone.name });
  };

  const handleEditAnchor = (stage: TFirmMilestone) => {
    dispatch(setShowAnchorModal(true));
    setCurrentStage(stage);
  };

  const handleChangeBf = (_: ChangeEvent, value: TSelectItem) => {
    dispatch(setBuildingFunction(value));
  };

  const TabToggler = () => (
    <div className={style.tabs}>
      <p
        onClick={() => setSelectedFirst(true)}
        className={cn(selectedFirst && style.selectedTab)}
      >
        Группы контрольных точек
      </p>
      <p
        onClick={() => setSelectedFirst(false)}
        className={cn(!selectedFirst && style.selectedTab)}
      >
        Настройка распределения процентов
      </p>
    </div>
  );

  const MilestonesList = () => (
    <div className={style.wrapper}>
      {pending.firmMilestones ? (
        <SkeletonGroup />
      ) : (
        <>
          <CustomButton
            className={style.addButton}
            onClick={() => setShowCreateModal(true)}
            background={palette.blue}
            width={200}
          >
            <Add />
            <p>Создать категории</p>
          </CustomButton>
          <MilestonesGroup
            bfType={data.buildingFunction.id}
            firmMilestones={data.firmMilestones}
            pending={pending.firmMilestones}
            handleEditProcess={handleEditProcess}
            handleEditAnchor={handleEditAnchor}
          />
        </>
      )}
    </div>
  );

  return (
    <div className={style.pane}>
      <div className={style.wrapper}>
        <div className={cn(style.title, style.milestone)}>
          <div>Управление контрольными точками</div>
        </div>
      </div>
      <Select
        label=""
        variant={style.objectType}
        options={data.bfList}
        value={data.buildingFunction}
        isLoading={pending.bfList}
        changeHandler={handleChangeBf}
        disableClear
        size="small"
      />
      <div className={cn(style.wrapper, style.noMargin)}>
        <TabToggler />
        {selectedFirst ? (
          <MilestonesList />
        ) : (
          <SetupList bfType={data.buildingFunction.id} firmId={firmId} />
        )}
        <ModalWrapper
          show={showCreateModal}
          handleCloseModal={() => setShowCreateModal(false)}
        >
          <CreateProcessModal
            bfType={data.buildingFunction.id}
            firmId={firmId}
          />
        </ModalWrapper>
        <ModalWrapper
          show={showEditModal}
          handleCloseModal={() => setShowEditModal(false)}
        >
          <EditingProcessModal
            processTitle={process.title}
            processId={process.id as number}
            bfType={data.buildingFunction.id}
            firmId={firmId}
          />
        </ModalWrapper>
        <ModalWrapper
          show={data.showAnchorModal}
          head={
            <h2 className={cn(style.modalTitle, style.shortTitle)}>
              Настройка контрольной точки «{currentStage?.name ?? ""}»
            </h2>
          }
          handleCloseModal={() => dispatch(setShowAnchorModal(false))}
        >
          <AnchorModal stage={currentStage} />
        </ModalWrapper>
      </div>
    </div>
  );
};

export default Milestone;
