import { Routes, Route } from "react-router-dom";
import { ProjectProcessView as ProjectProcessNewView } from "src/FSD/pages/v2/projectProcess";
import {
  IndexPageTemplate,
  IndexPageTemplateNew,
} from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import ProjectProcessPage from "../components/pages/Search/ProjectProcess/ProjectProcessPage";
import ProjectProcessView from "../components/pages/ProjectProcessView/ProjectProcessView";
import style from "../components/pages/Project/NewView/ProjectView.module.scss";

export const ProjectProcessRoutes = () => (
  <Routes>
    <Route
      path="/project-process/search"
      element={
        <IndexPageTemplate>
          <ProjectProcessPage />
        </IndexPageTemplate>
      }
    />
    <Route
      path="/project-process/:id"
      element={
        <IndexPageTemplate>
          <ProjectProcessView />
        </IndexPageTemplate>
      }
    />
    <Route path="/project-process/test">
      <Route
        path={":id"}
        element={
          <IndexPageTemplateNew className={style.footer}>
            <ProjectProcessNewView />
          </IndexPageTemplateNew>
        }
      />
    </Route>
  </Routes>
);
