import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import styles from "./GipCard.module.scss";
import { GipInfo } from "./GipInfo/GipInfo";
import { GipHelpers } from "./GipHelpers/GipHelpers";
import { GipHelperApplications } from "./GipHelperApplications/GipHelperApplications";

export const GipCard = memo(() => {
  const { gipHelperDelete, gipHelperInvite } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (gipHelperDelete) {
      enqueueSnackbar("Удаление помощника ГИПа прошло успешно", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (gipHelperInvite) {
      enqueueSnackbar("Помощник ГИПа успешно добавлен", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [gipHelperDelete, gipHelperInvite]);

  return (
    <div className={styles.gip}>
      <GipInfo />
      <div className={styles.gip_helpers}>
        <GipHelpers />
        <GipHelperApplications />
      </div>
    </div>
  );
});
