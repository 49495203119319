import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { textFieldSX } from "../../../../styles/restyle";

export type MyInputProps = ComponentPropsType<unknown>;

export const Input: React.FC<
  // todo -> в разработке
  // @ts-ignore
  MyInputProps & Omit<TextFieldProps, MyInputProps>
> = (props) => {
  const { ...other } = props;

  return (
    <TextField
      {...other}
      id="outlined-full-width"
      style={{ marginTop: 0, marginBottom: 0 }}
      placeholder=""
      margin="normal"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      {...props}
      {...textFieldSX}
    />
  );
};
