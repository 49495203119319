import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { pushError } from "../../../../errorTrace";
import { ApiExpertiseDeleteConclusion } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseConclusion";

export const fetchDeleteConclusion = createAsyncThunk<any, number>(
  "expertise/fetchDeleteConclusion",
  async (conclusionId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseDeleteConclusion(conclusionId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
