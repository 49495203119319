import React from "react";
import classNames from "classnames";
import { DropdownMenuHeader } from "../DropdownMenuHeader/AdditionalNavigation";
import style from "./style.module.scss";
import { NavigationItemHead } from "../NavigationItemHead";
import { navigationsType } from "../../../../types/stateTypes/navigation";

export type NavigationProps = {
  isActive?: boolean;
  variant?: string;
  items: navigationsType;
  openHandler?: VoidFunction;
  closeHandler?: VoidFunction;
};

export const Navigation: React.FC<NavigationProps> = React.memo(
  ({ items, variant = "" }) => {
    return (
      <nav className={classNames(variant, style.navigation)}>
        <ul className={style.list}>
          {items.map((item, i) => {
            if (item.items && items.length > 0) {
              return (
                <li key={i} className={style.item}>
                  <DropdownMenuHeader firstElement item={item} />
                </li>
              );
            }
            return (
              <li key={i} className={style.item}>
                <NavigationItemHead item={item} clickHandler={() => {}} />
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
);
