export default (
  name: string | undefined,
  can: Record<string, boolean> | undefined
) => {
  if (!name) {
    return "";
  }

  const canLength = can ? Object.keys(can).length : 0;

  return name.length > 36 && canLength >= 3
    ? `${name.substring(0, 35)}...`
    : name;
};
