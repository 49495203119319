import { createSlice } from "@reduxjs/toolkit";
import { modalsInitialState } from "./modelState";
import { RootState } from "../../../../app/store";

const modalsSlice = createSlice({
  name: "processWorkModalsSlice",
  initialState: modalsInitialState,
  reducers: {
    setRequestRemoveExecutorActive(state, action) {
      state.requestRemoveExecutor = action.payload;
    },
    setUpdateExecutorActive(state, action) {
      state.updateExecutor = action.payload;
    },
    setFeedBackExecutorActive(state, action) {
      state.feedBackExecutor = action.payload;
    },
    setConsiderConflictActive(state, action) {
      state.considerConflict = action.payload;
    },
    setRequestExecutionActive(state, action) {
      state.requestExecution = action.payload;
    },
    setUpdateProcessWorkActive(state, action) {
      state.updateProcessWork = action.payload;
    },
    setWorkPublicationActive(state, action) {
      state.workPublication = action.payload;
    },
    setCheckCustomerAlignActive(state, action) {
      state.checkCustomerAlign = action.payload;
    },
    setExecuteCompleteActive(state, action) {
      state.executeComplete = action.payload;
    },
  },
});

export const {
  setRequestRemoveExecutorActive,
  setUpdateExecutorActive,
  setFeedBackExecutorActive,
  setConsiderConflictActive,
  setRequestExecutionActive,
  setUpdateProcessWorkActive,
  setWorkPublicationActive,
  setCheckCustomerAlignActive,
  setExecuteCompleteActive,
} = modalsSlice.actions;

export const getModalsData = (state: RootState) => state.processWork.modals;

export default modalsSlice.reducer;
