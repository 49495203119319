import React, { FC } from "react";
import { Skeleton } from "@mui/material";
import style from "./Primary/style.module.scss";

type DashboardSkeletonProps = {
  count?: number;
};

const DashboardSkeleton: FC<DashboardSkeletonProps> = ({ count = 8 }) => {
  const skeletonArray = Array.from(new Array(count));

  return (
    <div className={style.list}>
      {skeletonArray.map((_, i) => (
        <div key={i} className={style.row}>
          <Skeleton variant="circular" className={style.circ} />
          <Skeleton variant="rectangular" className={style.rect} />
        </div>
      ))}
    </div>
  );
};

export default DashboardSkeleton;
