import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProjectProcessDocuments } from "src/app/feature/ProjectProcessView/Documents/services/fetchProjectProcessDocuments";
import { fetchProjectProcessView } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectProcessDocuments } from "src/app/feature/ProjectProcessView/Documents/selectors/getProjectProcessDocuments";
import { getProjectProcessDocumentsIsLoad } from "src/app/feature/ProjectProcessView/Documents/selectors/getProjectProcessDocumentsIsLoad";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoisLoad,
  getProjectProcessInfoProjectId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { DocumentCard } from "../../Project/View/Documents/DocumentCard";
import cls from "./Documents.module.scss";
import { SkeletonDocuments } from "../../../UI/components/Project/Skeleton";

export const Documents = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useSelector(getProjectProcessInfoId);
  const projectId = useSelector(getProjectProcessInfoProjectId);
  const tree = useSelector(getProjectProcessDocuments);
  const isLoad = useSelector(getProjectProcessDocumentsIsLoad);
  const isLoadProcess = useAppSelector(getProjectProcessInfoisLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId && projectId) {
      dispatch(
        fetchProjectProcessDocuments({
          projectId,
          processId: infoId,
        })
      );
    }
  }, [infoId, projectId]);

  if (isLoad || isLoadProcess) {
    return <SkeletonDocuments />;
  }

  return (
    <div className={cls.wrapper}>
      {tree?.map((el) => <DocumentCard key={el.id} data={el} />)}
    </div>
  );
};
