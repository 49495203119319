import React, { memo } from "react";
import cls from "./BudgetGraph.module.scss";
import { trimPrice } from "../../../../../../../utils/trimNumber";

export const BudgetGraph = memo(({ budget, name }: any) => {
  const isWork = name === "work";
  const isExpertise = name === "expertise";
  const lengthExpertise = budget.length > 1;
  const isProject = name === "project";
  const isResearch = name === "research";
  const isInspection = name === "inspection";
  const isToday = name === "today";

  const inspectionGraphRender = isInspection && (
    <div className={cls.budget_work}>
      {budget.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <div
            className={cls.budget_work_block}
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
            }}
          >
            {index === 0 && (
              <>
                <span className={cls.budget_inspection}>План ОБС</span>
                <span className={cls.budget_work_block_price}>
                  {trimPrice(value.price)}
                </span>
                <div className={cls.budget_work_block_dateEnd}>
                  {value.dateEnd}
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <span className={cls.budget_inspection}>Факт ОБС</span>
                <div className={cls.budget_work_block_dateStart}>
                  {value.dateStart}
                </div>
              </>
            )}
            {index === 0 && value?.width > 2 && (
              <p title={value.priceCurrent}>
                {trimPrice(value?.priceCurrent) || "0"}
              </p>
            )}
            {index === 1 && value?.width > 2 && (
              <p title={value.priceFact}>
                {trimPrice(value?.priceFact) || "0"}
              </p>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const researchGraphRender = isResearch && (
    <div className={cls.budget_work}>
      {budget.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <div
            className={cls.budget_work_block}
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
            }}
          >
            {index === 0 && (
              <>
                <span className={cls.budget_work_block_title}>План ИИ</span>
                <span className={cls.budget_work_block_price}>
                  {trimPrice(value.price)}
                </span>
                <div className={cls.budget_work_block_dateEnd}>
                  {value.dateEnd}
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <span className={cls.budget_work_block_title}>Факт ИИ</span>
                <div className={cls.budget_work_block_dateStart}>
                  {value.dateStart}
                </div>
              </>
            )}
            {index === 0 && value?.width > 2 && (
              <p title={value.priceCurrent}>
                {trimPrice(value?.priceCurrent) || "0"}
              </p>
            )}
            {index === 1 && value?.width > 2 && (
              <p title={value.priceFact}>
                {trimPrice(value?.priceFact) || "0"}
              </p>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const workGraphRender = isWork && (
    <div className={cls.budget_work}>
      {budget.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <div
            className={cls.budget_work_block}
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
            }}
          >
            {index === 0 && (
              <>
                <span className={cls.budget_work_block_title}>План РД</span>
                <span className={cls.budget_work_block_price}>
                  {trimPrice(value.price)}
                </span>
                <div className={cls.budget_work_block_dateEnd}>
                  {value.dateEnd}
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <span className={cls.budget_work_block_title}>Факт РД</span>
                <div className={cls.budget_work_block_dateStart}>
                  {value.dateStart}
                </div>
              </>
            )}
            {index === 0 && value?.width > 2 && (
              <p title={value.priceCurrent}>
                {trimPrice(value?.priceCurrent) || "0"}
              </p>
            )}
            {index === 1 && value?.width > 2 && (
              <p title={value.priceFact}>
                {trimPrice(value?.priceFact) || "0"}
              </p>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const projectGraphRender = isProject && (
    <div className={cls.budget_work}>
      {budget.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <div
            className={cls.budget_work_block}
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
            }}
          >
            {index === 0 && (
              <>
                <span className={cls.budget_work_block_title}>План ПД</span>
                <div className={cls.budget_work_block_dateEnd}>
                  {value.dateEnd}
                </div>
                <div className={cls.budget_work_block_price}>
                  {trimPrice(value.price)}
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <span className={cls.budget_work_block_title}>Факт ПД</span>
                <div className={cls.budget_work_block_dateStart}>
                  {value.dateStart}
                </div>
              </>
            )}
            {index === 0 && value?.width > 2 && (
              <p title={value.priceCurrent}>
                {trimPrice(value?.priceCurrent) || "0"}
              </p>
            )}
            {index === 1 && value?.width > 2 && (
              <p title={value.priceFact}>
                {trimPrice(value?.priceFact) || "0"}
              </p>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const expertiseGraphRender = isExpertise && (
    <div className={cls.budget_expertise}>
      {budget.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <div
            className={cls.budget_expertise_block}
            style={{
              marginLeft: (index === 0 && `${value?.marginLeft}%`) || 0,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
            }}
          >
            {index === 0 && (
              <>
                <span className={cls.budget_expertise_block_title}>Факт</span>
                <div className={cls.budget_expertise_block_price}>
                  {!lengthExpertise && trimPrice(value.price)}
                </div>
                <div className={cls.budget_expertise_block_dateStart}>
                  {value.dateStart}
                </div>
                <div className={cls.budget_expertise_block_dateEnd}>
                  {!lengthExpertise && value.dateEnd}
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <div className={cls.budget_expertise_block_price}>
                  {trimPrice(value.price)}
                </div>
                <div className={cls.budget_expertise_block_dateEnd}>
                  {value.dateEnd}
                </div>
              </>
            )}
            <p title={value?.title}>{value?.width > 2 && value?.title}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <>
      {isToday && (
        <div
          style={{ marginLeft: `${budget?.marginLeft}%`, top: "-15px" }}
          className={cls.budget_today}
        ></div>
      )}
      {inspectionGraphRender}
      {researchGraphRender}
      {projectGraphRender}
      {expertiseGraphRender}
      {workGraphRender}
    </>
  );
});
