import React, { memo } from "react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { menuItemSx, textFieldSX } from "src/styles/restyle";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { fetchProjectProcessRemoveExecutorComplete } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessRemoveExecutorComplete";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import styles from "./CompleteRemove.module.scss";

type TInputs = {
  status: number;
  withoutFeedback: string;
  comment: string;
};

interface ICompleteRemoveProps {
  closeModal: () => void;
}

export const CompleteRemove = memo(({ closeModal }: ICompleteRemoveProps) => {
  const processData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);

  const { handleSubmit, register, setValue } = useForm<TInputs>();

  const formSubmitHandler = async (dataValue: TInputs) => {
    const data = {
      CompleteForm: {
        status: dataValue.status,
        withoutFeedback: dataValue.withoutFeedback === "Да",
        comment: dataValue.comment,
      },
    };
    const taskId = processData.activeAccess?.task_remove_id;
    if (taskId && processId) {
      await dispatch(
        fetchProjectProcessRemoveExecutorComplete({ taskId, data })
      );
      dispatch(fetchProjectProcessViewDuplicate(processId));
      closeModal();
    }
  };

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)} className={styles.form}>
      <TextField
        {...textFieldSX}
        multiline
        minRows={2}
        InputLabelProps={{
          shrink: true,
        }}
        label="Комментарий от руководителя проекта"
        value={processData.activeAccess?.taskRemove?.content}
        disabled={true}
      />
      {processData.activeAccess?.taskRemove?.data?.visibleWithoutFeedback && (
        <FormControl {...textFieldSX}>
          <InputLabel id="type">Запретить оценку</InputLabel>
          <Select
            {...register("withoutFeedback")}
            labelId="withoutFeedback"
            label="Запретить оценку"
            defaultValue="Нет"
          >
            <MenuItem sx={menuItemSx} value="Нет">
              Нет
            </MenuItem>
            <MenuItem sx={menuItemSx} value="Да">
              Да
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <TextField
        {...textFieldSX}
        {...register("comment")}
        multiline
        minRows={2}
        label="Комментарий"
        placeholder="Введите комментарий"
      />
      <div className={styles.form_submit}>
        <Button
          width={132}
          variant="outlined"
          type="submit"
          onClick={() => setValue("status", 5)}
        >
          Отказать
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={() => setValue("status", 4)}
        >
          Согласиться
        </Button>
      </div>
    </form>
  );
});
