import { MouseEvent, memo, useState, ReactNode } from "react";
import { CheckSquareFill } from "react-bootstrap-icons";
import { CircularProgress, ClickAwayListener, Popper } from "@mui/material";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import styles from "./Popper.module.scss";
import { NotApproveIcon } from "../../../../../components/newUI/SVG";
import { Nullable } from "../../../../../types/generics";
import { TextField } from "../TextField";

type TFieldValues = {
  text: string;
};

type Props = {
  value: string;
  title: string;
  canEdit: boolean;
  node: ReactNode;
  onChange: (value: string) => void;
  isLoading: boolean;
  className?: string;
};

export const TextFieldPopper = memo(
  ({ canEdit, title, onChange, isLoading, value, className, node }: Props) => {
    const [anchorEnd, setAnchorEnd] = useState<Nullable<HTMLElement>>(null);

    const { register, handleSubmit } = useForm<TFieldValues>({
      defaultValues: {
        text: value,
      },
    });

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEnd(anchorEnd ? null : event.currentTarget);
    };

    const handleClickAway = () => {
      setAnchorEnd(null);
    };

    const onSubmit = async (data: TFieldValues) => {
      await onChange(data.text);
      setAnchorEnd(null);
    };

    const isOpen = Boolean(anchorEnd);
    const id = isOpen ? "simple-popper" : undefined;

    return (
      <div className={classNames(styles.container, className)}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <div onClick={handleClick}>{node}</div>
            {isOpen && canEdit && (
              // @ts-ignore
              <Popper
                id={id}
                open={isOpen}
                anchorEl={anchorEnd}
                placement={"top"}
              >
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.createData}
                >
                  <div className={styles.createData_flex}>
                    <h3>{title}</h3>
                    <div onClick={() => setAnchorEnd(null)}>
                      <NotApproveIcon width="16" heigth="16" />
                    </div>
                  </div>
                  <TextField className={styles.price} {...register("text")} />
                  <div className={styles.createData__submitButton}>
                    {isLoading ? (
                      <div
                        className={styles.createData__submitButton__preloader}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <button className={styles.button} type="submit">
                        <CheckSquareFill />
                      </button>
                    )}
                  </div>
                </form>
              </Popper>
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
);
