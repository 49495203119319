import { memo } from "react";
import { Clock, QuestionCircleFill } from "react-bootstrap-icons";
import classNames from "classnames";
import {
  CriticalIcon,
  HiddenEyeIcon,
  SuccessIcon,
  WarningIcon,
} from "src/components/newUI/SVG";
import style from "src/components/pages/Project/View/InitialData/style.module.scss";

interface SourceDataIconTypeProps {
  critical: boolean;
  status: string;
  className?: string;
}
export const SourceDataIconType = memo(
  ({ critical, status, className }: SourceDataIconTypeProps) => {
    const canTitle = status === "hidden";
    return (
      <div
        className={classNames(style.statusIcon, className)}
        title={
          (canTitle &&
            "Файлы не публикуемые и доступные только участникам проекта") ||
          ""
        }
      >
        {critical && status !== "success" && status !== "hidden" && (
          <CriticalIcon />
        )}
        {status === "success" && <SuccessIcon />}
        {status === "warning" && <WarningIcon />}
        {status === "hidden" && <HiddenEyeIcon />}
        {status === "undefined" && <QuestionCircleFill color="orange" />}
        {status === "inprocess" && <Clock />}
      </div>
    );
  }
);
