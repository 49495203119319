import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import { useProcessWorkViewQuery } from "src/FSD/entities/processWork/api";
import { mapProcessWork } from "../mappers/mapProcessWork";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

type TUsePageProps = {
  processWorkId: number;
};

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProcessWorkViewPage = ({ processWorkId }: TUsePageProps) => {
  const { data: processWorkRaw, isLoading } = useProcessWorkViewQuery({
    id: processWorkId,
    expand: [
      "files",
      "can",
      "activeAccess.taskRemove",
      "activeAccess.user",
      "activeAccess.userType",
      "project.chatId",
      "tabs",
      "activeContract",
    ],
  });

  const processWork = useDataMapper(processWorkRaw, mapProcessWork);

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadCrumbs = getBreadcrumbs(processWork);

  const isProcessWorkLoading = !processWork || isLoading;

  const showThreeDot = processWork?.can?.publish;

  const nameProjectProcess = `«${processWork?.projectPart.name}${processWork?.projectPart.code ? ` (${processWork.projectPart.code})` : ""}»`;

  const header = `Публикация задачи «${processWork?.title}» по проекту ${nameProjectProcess}`;

  return {
    processWork,
    device,
    isProcessWorkLoading,
    breadCrumbs,
    showThreeDot,
    header,
  } as const;
};
