import { RequestUpdateForm } from "./RequestUpdate";
import { RemoveRequestExecutionForm } from "./RemoveRequestExecution";
import { FeedbackTaskForm } from "./FeedbackTask";
import { PublishContentForm } from "./PublishContent";

const PublishObjectModals = {
  RequestUpdateForm,
  RemoveRequestExecutionForm,
  FeedbackTaskForm,
  PublishContentForm,
};

export default PublishObjectModals;
