import React, { memo } from "react";
import { Accordion } from "../../../../../newUI/Accordion/Accordion";
import { RowsType } from "../../../../../../app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessRemarksIndexes } from "../../../../../../app/feature/ProjectProcessView/Expertise/selectors/getProjectProcessExpertise";
import { setOpenRemarks } from "../../../../../../app/feature/ProjectProcessView/Expertise/slice/projectProcessExpertise";
import { RemarkTable } from "./RemarkTable/RemarkTable";
import cls from "./Remark.module.scss";
import { ApiPrimaryTaskType } from "../../../../../../types/api/primaryTypes/apiPrimaryTaskType";

type RemarkProps = {
  date_create: string;
  index: string;
  dot: boolean;
  rows: RowsType[];
  canComplete: boolean;
  task: ApiPrimaryTaskType;
  name: string;
  files: any;
  num_by_project: string;
};
export const Remark = memo(
  ({
    date_create,
    index,
    dot,
    rows,
    canComplete,
    task,
    files,
    name,
    num_by_project,
  }: RemarkProps) => {
    const dispatch = useAppDispatch();
    const remarkIndexes = useAppSelector(getProjectProcessRemarksIndexes);
    const dotStatus = dot ? "warning" : undefined;
    const handleRemarkClick = (index: string) => {
      dispatch(setOpenRemarks(index));
    };

    return (
      <div>
        <div onClick={() => handleRemarkClick(index)}>
          <Accordion
            text={`Изм. №${num_by_project}  от ${date_create}`}
            toogle={Boolean(remarkIndexes[index])}
            dotStatus={dotStatus}
            dotSize={10}
            classname={cls.accordion}
          />
        </div>

        {!!remarkIndexes[index] && (
          <RemarkTable
            rows={rows}
            canComplete={canComplete}
            task={task}
            name={name}
            files={files}
          />
        )}
      </div>
    );
  }
);
