import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Модаллка перед выходом из редактирования проекта
 */

export const showEventViewProject = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventViewProject: {
      isShow: true,
      data: {
        ...state.eventViewProject.data,
      },
    },
  };
};

export const closeEventViewProject = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventViewProject: {
    isShow: false,
    data: {
      ...state.eventViewProject.data,
    },
  },
});

export const selectorEventViewProject = (state: StateType) =>
  state.eventEmitter.eventViewProject.isShow;
