import React from "react";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import parentStyles from "./PublishModalContent.module.scss";

type TItem = {
  level?: number;
  title: string;
  id: number | string;
};

type TProps = {
  control: any;
  executors: TItem[];
  executorsDisabled?: boolean;
  executorsType: TItem[];
  executorsTypeDisabled?: boolean;
  addExecutorClick: () => void;
  executorDiv: boolean;
};

export const Executor = ({
  control,
  executors,
  executorsDisabled,
  executorsType,
  executorsTypeDisabled,
  addExecutorClick,
  executorDiv,
}: TProps) => {
  return (
    <>
      <Button onClick={addExecutorClick} className={parentStyles.toggleBtn}>
        Назначение исполнителя
      </Button>
      {executorDiv && (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ paddingTop: "15px" }}
        >
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="executor"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  defaultValue={value}
                  options={executors}
                  disableClearable
                  disabled={executorsDisabled}
                  onChange={(_: unknown, currentValue: string) => {
                    onChange(currentValue);
                  }}
                  getOptionLabel={(option: TItem) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="Назначенный исполнитель" />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="executorType"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  defaultValue={value}
                  options={executorsType}
                  disableClearable
                  disabled={executorsTypeDisabled}
                  onChange={(_: unknown, currentValue: string) => {
                    onChange(currentValue);
                  }}
                  getOptionLabel={(option: TItem) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="Форма собственности" />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
