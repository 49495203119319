import { isDevelopment } from "./app/services/app/app";

export const config = {
  localDomain: isDevelopment() ? "https://dev.tizh.ru" : "",
  localSocketPath: `wss://${
    isDevelopment()
      ? "test.tizh.ru" // todo -> поменять после разработки
      : `${window.location.hostname}:${window.location.port}`
  }`,
  testRootPath: "/site/front",
};
