import { ProjectIndividualType } from "./projectIndividual";
import {
  CanUserStateType,
  MainInformationProjectStateType,
} from "../../../../types/stateTypes/project";

export const mainInformationProjectInitialState: MainInformationProjectStateType =
  {
    dateFromTo: "",
    buildingFunctionName: "",
    constructionTypeName: "",
    clientType: "",
    firm: null,
    region: "",
    status: "",
    designType: "",
    client: {
      name: "",
      link: "",
      id: "",
    },
    advance: "",
  };

export const canUserInitialState: CanUserStateType = {
  isAdmin: false,
  view: false,
  edit: false,
  editByGipHelper: false,
  delete: false,
  update: false,
  startSearchGip: false,
  cancelSearchGip: false,
  requestGip: false,
  requestRemoveGip: false,
  updateGip: false,
  deleteRequestGip: false,
  completeRemoveGip: false,
  viewDocs: false,
  downloadSourceDocs: false,
  downloadDocs: false,
  inviteGipHelpers: false,
  removeGipHelpers: false,
  updateGanttData: false,
  updateSourceData: false,
  addAccess: false,
};

export const projectInitialState: ProjectIndividualType = {
  id: -1,
  canUser: canUserInitialState,
  info: mainInformationProjectInitialState,
  parameters: [],
  files: [],
  description: "",
  gip: null,
  name: "",
  gipAccess: [],
  gipProcess: null,
  canMenu: {
    canSearchGip: false,
    canStopSearch: false,
    canCompareGip: false,
    canExecutionGipRequest: false,
    canRequestGip: false,
    canSetGip: false,
    canDeleteRequestGip: false,
    canRequestDeleteGip: false,
    canConsiderConflictDeleteGip: false,
  },
  requestRemoveGipTask: [],
};
