import { useEffect, useState } from "react";
import { Devices } from "src/FSD/widgets/v2/TabsPanel/lib/constants/devices";
import { useIsMobile } from "./useIsMobile";

export const useDevice = (mobileSize: number, tabletSize: number) => {
  const isMobile = useIsMobile(mobileSize);
  const isTablet = useIsMobile(tabletSize);

  const [device, setDevice] = useState(() => {
    let device = Devices.DESKTOP;
    if (isTablet && !isMobile) {
      device = Devices.TABLET;
    } else if (isMobile) {
      device = Devices.MOBILE;
    }
    return device;
  });

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < mobileSize + 1;
      const isTablet = window.innerWidth < tabletSize + 1;
      if (isTablet && !isMobile) {
        setDevice(Devices.TABLET);
      } else if (isMobile) {
        setDevice(Devices.MOBILE);
      } else {
        setDevice(Devices.DESKTOP);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return device;
};
