import { useProcessWorkRemovedQuery } from "src/FSD/entities/processWork/api";

export const useRemoved = (processWorkId: number) => {
  const { data: removedExecutors, isFetching } = useProcessWorkRemovedQuery({
    id: processWorkId,
  });

  const isExecutorsLoading = !removedExecutors || isFetching;

  return { removedExecutors, isExecutorsLoading } as const;
};
