import { UseFormReturn } from "react-hook-form";
import { Steps } from "../../model/constants";
import { TFieldValues } from "../../model/types";

type UseModalProps = {
  form: UseFormReturn<TFieldValues, object>;
  onSubmit: (data: TFieldValues) => void;
};

export const useRefundForm = ({ form, onSubmit }: UseModalProps) => {
  const { watch, control, setValue, handleSubmit } = form;

  const refundValue = watch("refund");

  const handleGoBack = () => {
    setValue("currentStep", Steps.REASON);
  };

  const onPreSubmit = (dataValues: TFieldValues) => {
    if (refundValue) {
      setValue("currentStep", Steps.PROCESSES);
    } else {
      onSubmit(dataValues);
    }
  };

  return {
    refundValue,
    control,
    onPreSubmit,
    handleGoBack,
    handleSubmit,
  } as const;
};
