import React, { useState } from "react";
import classNames from "classnames";
import parse from "html-react-parser";
import { ProjectProcessValueType } from "../../../app/feature/ProjectView/TaskReducer/types";
import cls from "./NewTaskCard.module.scss";
import { Version } from "./Version/Version";
import { NameBlock } from "./NameBlock/NameBlock";
import { VersionBlock } from "./VersionBlock/VersionBlock";

type NewTaskCardProps = ProjectProcessValueType & {
  projectId?: number;
  processId?: number;
  type?: "process" | "project";
};
export const NewTaskCard = ({
  tasks,
  dot,
  title,
  status,
  author,
  name,
  projectId,
  type = "project",
  processId,
}: NewTaskCardProps) => {
  const isRedDate = status.color === "pirs-red";
  const [versionToggle, setVersionToggle] = useState(false);
  const tasksEntries = Object.entries(tasks);
  const statusBlock = (
    <div className={classNames(cls.status, versionToggle && cls.hidden)}>
      <p>{parse(status.title)}</p>
      <span className={classNames(isRedDate && cls.redDate)}>
        {status.date}
      </span>
    </div>
  );

  return (
    <div className={classNames(cls.wrapper, cls[status.color])}>
      <div className={classNames(cls.card, versionToggle && cls.card_version)}>
        <NameBlock name={name} author={author} title={title} dot={dot} />
        {statusBlock}
        <VersionBlock
          versionToggle={versionToggle}
          setVersionToggle={setVersionToggle}
          versionCount={tasksEntries.length}
        />
      </div>
      {versionToggle &&
        tasksEntries.map(([key, value]) => (
          <Version
            key={value.id}
            num={key}
            task={value}
            type={type}
            projectId={projectId}
            processId={processId}
          />
        ))}
    </div>
  );
};
