import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "src/app/store";
import { IProfileReducer, IBuildingOption, ISkill, IUserRole } from "./types";
import { initialState } from "./initialState";
import {
  loadProfileRequest,
  loadProfile,
  loadRegions,
  loadRoles,
  loadCountries,
  loadTimeZones,
  loadSkillsList,
  loadAddressOptions,
  loadJobPlaces,
  loadBuildingFunctions,
  uploadProfile,
  createJobPlace,
  deleteJobPlace,
  editJobPlace,
  loadEducationList,
  createEducationPlace,
  loadRewardList,
  createReward,
  loadPortfolioList,
  createPortfolio,
  deletePortfolio,
  loadPaymentInfo,
  updatePaymentInfo,
  loadNotifications,
  searchOrganisations,
  getUserFirmsList,
} from "./thunks";

const profileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
    setFirmData: (state, action) => {
      state.firmData = action.payload;
    },
    clearSearchList: (state) => {
      state.searchOrganisationsList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProfile.pending, (state: IProfileReducer) => {
      state.profileIsLoading = true;
    });
    builder.addCase(loadProfile.rejected, (state: IProfileReducer) => {
      state.profileIsLoading = false;
    });
    builder.addCase(
      loadProfile.fulfilled,
      (state: IProfileReducer, { payload }) => {
        const skills = payload.skills.map((item: any) => ({
          id: item.id,
          title: item.name,
        }));

        state.profile = { ...payload, skills };
        state.profileIsLoading = false;
      }
    );
    builder.addCase(loadRegions.pending, (state: IProfileReducer) => {
      state.regionsIsLoading = true;
    });
    builder.addCase(
      loadRegions.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.regions = payload;
        state.regionsIsLoading = false;
      }
    );
    builder.addCase(
      loadRegions.rejected,
      (state: IProfileReducer, { error }) => {
        state.regionsIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(loadRoles.pending, (state: IProfileReducer) => {
      state.rolesIsLoading = true;
    });
    builder.addCase(
      loadRoles.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.userRoles = payload.map((item: IUserRole) => ({
          title: item.value,
          id: item.key,
        }));
        state.rolesIsLoading = false;
      }
    );
    builder.addCase(loadCountries.pending, (state: IProfileReducer) => {
      state.countriesIsLoading = true;
    });
    builder.addCase(
      loadCountries.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.countriesList = payload;
        state.countriesIsLoading = false;
      }
    );
    builder.addCase(loadTimeZones.pending, (state: IProfileReducer) => {
      state.timeZonesIsLoading = true;
    });
    builder.addCase(
      loadTimeZones.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.timeZones = payload.map((title: string, index) => ({
          title,
          id: index,
        }));
        state.timeZonesIsLoading = false;
      }
    );
    builder.addCase(loadSkillsList.pending, (state: IProfileReducer) => {
      state.skillsListIsLoading = true;
    });
    builder.addCase(
      loadSkillsList.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.skillsList = payload.map((item: ISkill) => ({
          id: item.id,
          title: item.name,
        }));
        state.skillsListIsLoading = false;
      }
    );
    builder.addCase(loadAddressOptions.pending, (state: IProfileReducer) => {
      state.addressOptionsIsLoading = true;
    });
    builder.addCase(
      loadAddressOptions.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.addressOptionsIsLoading = false;
        state.addressOptions = payload;
      }
    );
    builder.addCase(loadJobPlaces.pending, (state: IProfileReducer) => {
      state.jobPlacesListIsLoading = true;
    });
    builder.addCase(
      loadJobPlaces.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.jobPlacesListIsLoading = false;
        state.jobPlacesList = payload;
      }
    );
    builder.addCase(
      uploadProfile.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      uploadProfile.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(
      createJobPlace.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      createJobPlace.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(
      deleteJobPlace.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      deleteJobPlace.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(
      editJobPlace.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      editJobPlace.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(loadEducationList.pending, (state: IProfileReducer) => {
      state.educationListIsLoading = true;
    });
    builder.addCase(
      loadEducationList.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.educationList = payload;
        state.educationListIsLoading = false;
      }
    );
    builder.addCase(
      loadEducationList.rejected,
      (state: IProfileReducer, { error }) => {
        state.educationListIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(
      createEducationPlace.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      createEducationPlace.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(loadRewardList.pending, (state: IProfileReducer) => {
      state.rewardListIsLoading = true;
    });
    builder.addCase(
      loadRewardList.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.rewardList = payload;
        state.rewardListIsLoading = false;
      }
    );
    builder.addCase(
      loadRewardList.rejected,
      (state: IProfileReducer, { error }) => {
        state.rewardListIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(
      createReward.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      createReward.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(loadBuildingFunctions.pending, (state: IProfileReducer) => {
      state.buildingFunctionsIsLoading = true;
    });
    builder.addCase(
      loadBuildingFunctions.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.buildingOptions = payload.map((item: IBuildingOption) => ({
          id: item.id,
          title: item.name,
        }));
        state.buildingFunctionsIsLoading = false;
      }
    );
    builder.addCase(
      loadBuildingFunctions.rejected,
      (state: IProfileReducer, { error }) => {
        state.buildingFunctionsIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(loadPortfolioList.pending, (state: IProfileReducer) => {
      state.portfolioListIsLoading = true;
    });
    builder.addCase(
      loadPortfolioList.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.portfolioList = payload;
        state.portfolioListIsLoading = false;
      }
    );
    builder.addCase(
      loadPortfolioList.rejected,
      (state: IProfileReducer, { error }) => {
        state.portfolioListIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(
      createPortfolio.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      createPortfolio.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(
      deletePortfolio.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      deletePortfolio.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(loadPaymentInfo.pending, (state: IProfileReducer) => {
      state.paymentInfoIsLoading = true;
    });
    builder.addCase(
      loadPaymentInfo.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.paymentInfo = payload;
        state.paymentInfoIsLoading = false;
      }
    );
    builder.addCase(
      loadPaymentInfo.rejected,
      (state: IProfileReducer, { error }) => {
        state.paymentInfoIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(
      updatePaymentInfo.fulfilled,
      (state: IProfileReducer, { payload }) => {
        console.log(payload);
      }
    );
    builder.addCase(
      updatePaymentInfo.rejected,
      (state: IProfileReducer, { error }) => {
        console.log(error);
      }
    );
    builder.addCase(loadNotifications.pending, (state: IProfileReducer) => {
      state.notificationsIsLoading = true;
    });
    builder.addCase(
      loadNotifications.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.notifications = payload;
        state.notificationsIsLoading = false;
      }
    );
    builder.addCase(
      loadNotifications.rejected,
      (state: IProfileReducer, { error }) => {
        state.notificationsIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(searchOrganisations.pending, (state: IProfileReducer) => {
      state.searchOrganisationsIsLoading = true;
    });
    builder.addCase(
      searchOrganisations.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.searchOrganisationsIsLoading = false;

        // @ts-ignore
        const { data, meta } = payload;

        state.searchOrganisationsList = data;
        state.searchOrganisationsMeta = meta;
      }
    );
    builder.addCase(
      searchOrganisations.rejected,
      (state: IProfileReducer, { error }) => {
        state.searchOrganisationsIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(getUserFirmsList.pending, (state: IProfileReducer) => {
      state.userFirmsListIsLoading = true;
    });
    builder.addCase(
      getUserFirmsList.fulfilled,
      (state: IProfileReducer, { payload }) => {
        state.userFirmsListIsLoading = false;
        // @ts-ignore
        state.userFirmsList = payload;
      }
    );
    builder.addCase(
      getUserFirmsList.rejected,
      (state: IProfileReducer, { error }) => {
        state.userFirmsListIsLoading = false;
        console.log(error);
      }
    );
    builder.addCase(loadProfileRequest.pending, (state: IProfileReducer) => {
      state.profileRequestLoading = true;
    });
    builder.addCase(
      loadProfileRequest.fulfilled,
      (state: IProfileReducer, action: any) => {
        const dateFormat = "DD.MM.YYYY";
        const { payload } = action;

        state.profileRequestLoading = false;

        if (!action.payload.task?.id) {
          state.profileRequestData = {
            taskId: null,
            id: payload.id,
            surname: payload.surname ?? "",
            name: payload.name ?? "",
            patronymic: payload.patronymic ?? "",
            city: payload.city ?? "",
            passport_issued_by:
              payload.individualInfo?.passport_issued_by ?? "",
            content: "",
            address: payload.address ?? "",
            passport_serial: payload.individualInfo?.passport_serial ?? "",
            passport_num: payload.individualInfo?.passport_num ?? "",
            country_code: payload?.region?.country_code ?? "",
            region_code: payload?.region?.code ?? "",
            passport_date: payload.individualInfo?.passport_date
              ? moment(
                  payload.individualInfo?.passport_date,
                  dateFormat
                ).toDate()
              : moment(new Date(), dateFormat).toDate(),
            birthdate: payload.birthdate
              ? moment(payload.birthdate, dateFormat).toDate()
              : moment(new Date(), dateFormat).subtract(18, "years").toDate(),
            upload_files: [],
          };

          return;
        }

        const workflowData = action.payload.task.workflow_data;

        state.profileRequestData = {
          taskId: action.payload.task?.id,
          id: workflowData.userId,
          surname: workflowData.surname ?? "",
          name: workflowData.name ?? "",
          patronymic: workflowData.patronymic ?? "",
          city: workflowData.city ?? "",
          passport_issued_by: workflowData.passport_issued_by ?? "",
          content: action.payload.task.content,
          address: workflowData.address ?? "",
          passport_serial: workflowData.passport_serial ?? "",
          passport_num: workflowData.passport_num ?? "",
          country_code: workflowData.country_code ?? "",
          region_code: workflowData.region_code ?? "",
          passport_date: workflowData.passport_date
            ? moment(workflowData.passport_date, dateFormat).toDate()
            : moment(new Date(), dateFormat).toDate(),
          birthdate: workflowData.birthdate
            ? moment(workflowData.birthdate, dateFormat).toDate()
            : moment(new Date(), dateFormat).subtract(18, "years").toDate(),
          upload_files: action.payload.task?.files,
        };
      }
    );
    builder.addCase(
      loadProfileRequest.rejected,
      (state: IProfileReducer, { error }) => {
        state.profileRequestLoading = false;
        console.log(error);
      }
    );
  },
});

export const { setRegions, setFirmData, clearSearchList } =
  profileSlice.actions;

export const selectorOrganisationCreation = (state: RootState) =>
  state.profilePage.firmData;

export default profileSlice.reducer;
