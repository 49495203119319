import React, { memo } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import cls from "./ObjectGraph.module.scss";

interface IObjectChildProps {
  task: any;
  name: string;
}
export const ObjectChild: React.FC<IObjectChildProps> = memo(
  ({ task, name }) => {
    let expertiseValue;
    const { div1 } = task;

    const isToday = name === "today";
    const isProject = name === "project";
    const isWork = name === "work";

    if (name === "expertise") {
      expertiseValue = Object.values(task);
    }

    const projectGraphRender = isProject && (
      <div className={cls.object_container} style={{ marginBottom: 0 }}>
        <div
          className={cls.object_project}
          style={{
            marginLeft: `${div1?.marginLeft}%`,
            width: `${div1?.width}%`,
          }}
        >
          <p className={cls.object_project_date1}>{div1?.dateStart}</p>
          <p className={cls.object_project_date2}>{div1?.dateEnd}</p>
        </div>
        <div
          className={cls.object_block}
          title={parse(div1?.title) as string}
          style={{
            marginLeft: `${div1?.marginLeft}%`,
            width: `${div1?.width > 3 ? div1.width : 3}%`,
            background: div1?.color,
            height: "12px",
          }}
        ></div>
      </div>
    );

    const workGraphRender = isWork && (
      <div className={cls.object_container}>
        <div
          className={cls.object_block}
          title={parse(div1?.title) as string}
          style={{
            marginLeft: `${div1?.marginLeft}%`,
            width: `${div1?.width > 3 ? div1.width : 3}%`,
            background: div1?.color,
            height: "12px",
          }}
        />
        <div
          className={cls.object_work}
          style={{
            marginLeft: `${div1?.marginLeft}%`,
            width: `${div1?.width}%`,
          }}
        >
          <p className={cls.object_work_date1}>{div1?.dateStart}</p>
          <p className={cls.object_work_date2}>{div1?.dateEnd}</p>
        </div>
      </div>
    );

    const expertiseGraphRender = expertiseValue && (
      <div className={cls.object_containerExpertise}>
        {expertiseValue.map((value: any, index) => (
          <div
            title={parse(value?.title) as string}
            key={value.shortTitle}
            className={cls.object_expertise}
            style={{
              marginLeft: `${index === 0 ? value?.marginLeft : 0}%`,
              width: `${value?.width}%`,
              background: value?.color,
            }}
          >
            <div className={cls.object_block} style={{ height: "12px" }} />
            <div className={cls.object_expertiseDate}>
              {index === 0 && (
                <p className={cls.object_expertiseDate_date1}>
                  {value?.dateStart}
                </p>
              )}
              <p
                className={classNames(
                  index === 0 && cls.object_expertiseDate_date2,
                  index === 1 && cls.object_expertiseDate_dateEnd
                )}
              >
                {value?.dateEnd}
              </p>
            </div>
          </div>
        ))}
      </div>
    );

    return (
      <>
        {isToday && (
          <div
            style={{ marginLeft: `${div1?.marginLeft}%`, top: "-15px" }}
            className={cls.object_today}
          />
        )}
        {projectGraphRender}
        {expertiseGraphRender}
        {workGraphRender}
      </>
    );
  }
);
