import React, { memo, useState, ChangeEvent, KeyboardEvent } from "react";
import classNames from "classnames";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import style from "./Header.module.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { DownloadArrow, SearchIcon } from "../../../../../newUI/SVG";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { iconBtnSx, palette } from "../../../../../../styles/restyle";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { config } from "../../../../../../app.cofig";
import {
  getProjectInitialData,
  setSearchValue,
} from "../../../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";

export const Header = memo(() => {
  const { initialDataCounters, searchValue } = useSelector(
    getProjectInitialData
  );
  const { project } = useSelector(getProjectViewData);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(681);
  const [valueInput, setValueInput] = useState(searchValue);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const id = open ? "simple-popper" : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValueInput(event.target.value);
  };

  const sendInputValue = () => {
    dispatch(setSearchValue(valueInput));
  };

  const onKeyDownInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(setSearchValue(valueInput));
    }
  };

  const downloadHeaderBtn = isMobile ? (
    <IconButton
      onClick={handleClick}
      className={style.header_download}
      sx={iconBtnSx}
    >
      <DownloadArrow width="24" height="24" />
    </IconButton>
  ) : (
    <CustomButton background={palette.green} onClick={handleClick} width={160}>
      Скачать отчет
    </CustomButton>
  );

  const btnPopperContent = open ? (
    // @ts-ignore
    <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom">
      <div className={style.header_linkWrapper}>
        <TextLink
          url={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}`}
          redirect
        >
          Общий
        </TextLink>
        <TextLink
          url={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}&executor=1`}
          redirect
        >
          По заказчику
        </TextLink>
      </div>
    </Popper>
  ) : null;

  return (
    <div className={classNames(style.header)}>
      <div className={style.header_title}>
        <h1>Исходные данные по проекту</h1>
        <div className={style.header_counters}>
          <div className={style.header_counters_div}>
            <p>Всего</p>
            <span>{initialDataCounters?.allSourceData}</span>
          </div>
          <div className={style.header_counters_div}>
            <p>Получено</p>
            <span>{initialDataCounters?.receivedSourceData}</span>
          </div>
          <div className={style.header_counters_div}>
            <p>Просрочено</p>
            <span>{initialDataCounters?.overdueSourceData}</span>
          </div>
        </div>
        {isMobile && (
          <TextField
            id="input-with-icon-textfield"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ color: "#9BACB9;" }}
                  onClick={sendInputValue}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={onKeyDownInput}
            variant="standard"
            value={valueInput}
            onChange={onChangeInput}
            className={style.search}
          />
        )}
      </div>
      <div className={style.header_btn}>
        {!isMobile && (
          <TextField
            id="input-with-icon-textfield"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ color: "#9BACB9;" }}
                  onClick={sendInputValue}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={onKeyDownInput}
            variant="standard"
            value={valueInput}
            onChange={onChangeInput}
          />
        )}
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            {project?.can.view && downloadHeaderBtn}
            {btnPopperContent}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
});
