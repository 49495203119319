import Filter from "./Filter";

class FilterSections extends Filter {
  static filterByQuery(data: any, query: string, fulfilled: boolean) {
    if (query === "") {
      const ids = Object.values(data)
        .filter((item: any) => item?.id)
        .filter((item: any) => {
          const condition = item?.status?.key === 3;
          return fulfilled ? condition : !condition;
        })
        .map((item: any) => item.id);

      return ids;
    }

    let ids = Object.values(data)
      .filter((item: any) => item?.id)
      .filter((item: any) => {
        const nameIncludes = Filter.valueContains(
          item?.projectPart?.name,
          query
        );
        const codeIncludes = Filter.valueContains(
          item?.projectPart?.code,
          query
        );

        return nameIncludes || codeIncludes;
      });

    ids = ids.filter((item: any) => {
      const condition = item?.status?.key === 3;
      return fulfilled ? condition : !condition;
    });

    return ids.map((item: any) => item.id);
  }

  static filterByFulfilled(data: any, fulfilled: boolean, query: string) {
    return FilterSections.filterByQuery(data, query, fulfilled);
  }
}

export default FilterSections;
