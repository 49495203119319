import React from "react";
import cls from "./TaskCard.module.scss";
import { TaskCheckType } from "../../../../app/feature/ProjectView/CheckTaskReducer/thunks";
import { TaskIconType } from "./TaskIconType/TaskIconType";
import { useIsMobile } from "../../../hooks/useIsMobile";

export const TaskCard = ({
  taskProcess,
  executor,
  statusText,
  status,
  color,
}: TaskCheckType) => {
  const isTablet = useIsMobile(1600);
  const fullName = `${executor.surname} ${executor?.name[0]}. ${executor?.patronymic[0]}.`;
  return (
    <div className={cls.task}>
      <div className={cls.task_code}>
        <TaskIconType statusKey={status.key} color={color} />
        {!isTablet && <p>{taskProcess?.projectPart?.code}</p>}
        {isTablet && (
          <div className={cls.tablet}>
            <p className={cls.tablet_code}>{taskProcess?.projectPart?.code}</p>
            <p className={cls.task_status}>{statusText}</p>
          </div>
        )}
      </div>
      <p className={cls.task_fio}>{fullName}</p>
      {!isTablet && <p className={cls.task_status}>{statusText}</p>}
    </div>
  );
};
