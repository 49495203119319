import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import {
  ApiProjectProcessInTasks,
  ApiProjectProcessOutTasks,
} from "../../../../services/api/projectProcess/tasks";

export const fetchProjectProcessOutTasks = createAsyncThunk<any, number>(
  "projectProcess/fetchProjectProcessOutTasks",
  async (id, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessOutTasks(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessInTasks = createAsyncThunk<any, number>(
  "projectProcess/fetchProjectProcessTasks",
  async (id, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessInTasks(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
