import { ApiUserPageHeader } from "../../../../../types/api/responseTypes/userIndividualPage/apiUserPageHeader";
import { UserPageHeaderProps } from "../../../../features/User/UserPageHeader/UserPageHeader";
import {
  appRoles,
  appRolesCatalog,
  isExecutor,
} from "../../../../../app/services/user/userRoles";
import {
  ApiPrimaryUserAdditionalInfo,
  ApiPrimaryUserType,
} from "../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../../../app.cofig";

const banRoles = (item: string) =>
  +item !== appRoles.admin &&
  +item !== appRoles.teamlead &&
  +item !== appRoles.worker;

// Функция бизнес логики с костылями, которая не отображает остальные роли, если основная поставщик и наоборот
export const prepareUserRoles = (
  mainUserType: ApiPrimaryUserType,
  additionalData: ApiPrimaryUserAdditionalInfo
) => {
  if (Number(mainUserType.key) === 6) {
    return Object.values(additionalData.roles).filter(
      (item) => +item === appRoles.provider
    );
  }
  return Object.values(additionalData.roles).filter((item) => banRoles(item));
};

export const createUserTypes = (
  data: ApiPrimaryUserType[]
): UserPageHeaderProps["responsibility"] =>
  data.filter((item) => item.type !== 2).map((item) => item.title);

export const createUserRatingsData = (
  data: ApiUserPageHeader
): UserPageHeaderProps["ratingItems"] => {
  const { rating } = data;

  const ratingStringData: {
    [key: string]: number | string;
  } = {
    executor: rating.executor,
    customer: rating.customer,
    gip: rating.gip,
    provider: rating.provider,
  };

  const roles = prepareUserRoles(data.type, data.additionalInfo);

  type KeyAppRolesCatalog = keyof typeof appRolesCatalog;

  return roles.map((item) => ({
    label: appRolesCatalog[item as KeyAppRolesCatalog].rating,
    roundUp: 5,
    userTypeName: appRolesCatalog[item as KeyAppRolesCatalog].en,
    id: data.id,
    value:
      Number(
        ratingStringData[`${appRolesCatalog[item as KeyAppRolesCatalog].en}`]
      ) * 20,
    done:
      // @ts-ignore
      data[`${appRolesCatalog[item as KeyAppRolesCatalog].en}DoneProjectCount`],
    work:
      // @ts-ignore
      data[`${appRolesCatalog[item as KeyAppRolesCatalog].en}WorkProjectCount`],
  }));
};

export const createHeaderData = (
  data: ApiUserPageHeader
): Omit<UserPageHeaderProps, "dataProgressProfile" | "isMy"> | null => {
  try {
    return {
      name: `${data.name} ${data.surname}`,
      avatarUrl: `${config.localDomain}${data.photo}` || "",
      skills: data.skills.map((item) => item.name),
      responsibility: data.userTypes ? createUserTypes(data.userTypes) : [],
      profileProgressValue: data.additionalInfo.profile_fullness,
      isPremiumUser: data.premium,
      isTesterUser: data.tested,
      roles: data.additionalInfo
        ? Object.values(data.additionalInfo!.roles)
            .filter((item) => banRoles(item))
            .map(
              (item) =>
                appRolesCatalog[item as keyof typeof appRolesCatalog]?.ru
            )
        : [],
      userId: data.id,
      userIsExecutor: isExecutor(
        Object.values(data.additionalInfo.roles).map((item) => Number(item))
      ),
      ratingItems: createUserRatingsData(data),
      region: data.region
        ? `${data.region?.country.title}, ${data.region?.title}`
        : null,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
