import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectMilestone,
  getProjectMilestoneIsAllToggle,
} from "../../../../../app/feature/ProjectView/milestone/selectors/milestoneSelectors";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { MilestoneTree } from "./MilestoneTree/MilestoneTree";
import style from "../CheckList/checklist.module.scss";
import { AllToggleFalse, AllToggleTrue } from "../../../../newUI/SVG";
import { setOpenAllNode } from "../../../../../app/feature/ProjectView/milestone/slice/projectMilestoneSlice";

const sx = { color: "white" };

export const Milestone = () => {
  const milestone = useAppSelector(getProjectMilestone);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isAllToggle = useAppSelector(getProjectMilestoneIsAllToggle);

  const allToggleIcon = isAllToggle ? (
    <AllToggleFalse width="33" heigth="33" />
  ) : (
    <AllToggleTrue width="33" heigth="33" />
  );

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  function handleAllClick() {
    dispatch(setOpenAllNode());
  }
  if (milestone) {
    return (
      <>
        <MilestoneTree tree={Object.values(milestone)} />
        <div className={style.allToggle}>
          <IconButton sx={sx} onClick={handleAllClick}>
            {allToggleIcon}
          </IconButton>
        </div>
      </>
    );
  }
  return null;
};
