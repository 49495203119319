import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { pushError } from "../../../../errorTrace";
import { ApiExpertiseConclusion } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseConclusion";
import { ExpertiseConclusionTypes } from "../types/expertiseConclusionTypes";

export const fetchConclusionExpertise = createAsyncThunk<
  ExpertiseConclusionTypes[],
  number
>("expertise/fetchConclusionExpertise", async (processId, { dispatch }) => {
  let response: any = {};
  await request(
    ApiExpertiseConclusion(processId),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
