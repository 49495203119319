import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExpertiseProjectPartType } from "../types/expertisePartGroupType";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseProjectPartByGroup } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

export const fetchExpertiseProjectPartByGroup = createAsyncThunk<
  ExpertiseProjectPartType[],
  { projectId: number; groupId: number }
>(
  "expertise/fetchExpertiseProjectPartByGroup",
  async ({ projectId, groupId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseProjectPartByGroup(projectId, groupId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
