import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

type TUsePageProps = {
  id: number;
};

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProjectProcessView = ({ id }: TUsePageProps) => {
  const {
    data: projectProcess,
    isFetching,
    isLoading,
  } = useProjectProcessViewQuery({ id }, { skip: !id });

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadcrumbs = getBreadcrumbs(projectProcess);
  const isProjectProcessFetching = !projectProcess || isFetching;

  return {
    isProjectProcessFetching,
    breadcrumbs,
    isLoading,
    device,
    projectProcess,
  } as const;
};
