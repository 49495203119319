import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы cнятия заявки с роли ГИП
  актуально для ГИПов
 */

export const showEventRevokeRequestGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventRevokeRequestGip: {
      ...state.eventRevokeRequestGip,
      isShow: true,
    },
  };
};

export const closeEventRevokeRequestGipReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventRevokeRequestGip: {
    isShow: false,
    data: {
      ...state.eventRevokeRequestGip.data,
    },
  },
});

export const selectorEERevokeRequestGipProjectIsShow = (state: StateType) =>
  state.eventEmitter.eventRevokeRequestGip.isShow;
