import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPatch,
  httpRequestPost,
  httpRequestPostMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiProcessMaterial = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/index?MaterialProcessSearch[process_id]=${id}&expand=countTasks,countKP,tasks,can`
  );
};

export const ApiProcessMaterialFirms = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/get-provider-firms?processId=${id}&expand=questionnaires`
  );
};

export const ApiProcessMaterialViewForm = (
  materialId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/view-form-columns?id=${materialId}`
  );
};

export const ApiProcessMaterialTemplateList = (
  partId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/get-template-list?partNum=${partId}`
  );
};

export const ApiProjectProcessMaterialCreate = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/material-process/create?processId=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessMaterialDelete = (
  materialId: number
): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/material-process/delete?id=${materialId}`
  );
};

export const ApiProcessMaterialOfferBack = (
  taskId: number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/back-to-work?id=${taskId}`
  );
};

export const ApiProcessMaterialOfferSelect = (
  materialId: number,
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPatch(
    `${config.localDomain}/v1/material-process/select-request?id=${materialId}&taskId=${taskId}`,
    {},
    data
  );
};

export const ApiMaterialTakeToWork = (
  materialId: number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/material-process/pull-request?id=${materialId}`
  );
};

export const ApiProcessMaterialsSubmit = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};
