import React from "react";
import styles from "src/FSD/shared/uiKit/v2/Accordion/ui/Accordion.module.scss";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { PencilFill } from "react-bootstrap-icons";

type TProps = {
  onClick: () => void;
};

export const TitleButton = ({ onClick }: TProps) => {
  const pencilClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <span className={classNames(styles.title__button)}>
      <IconButton onClick={(e) => pencilClick(e)}>
        <PencilFill />
      </IconButton>
    </span>
  );
};
