import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import style from "../../../Project/View/Tasks/style.module.scss";
import { OutTaskCard } from "./OutTaskCard/OutTaskCard";
import { iconBtnSx } from "../../../../../styles/restyle";
import { AddFile } from "../../../../newUI/SVG";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalCreateTask } from "./ModalCreateTask/ModalCreateTask";
import { useAppSelector } from "../../../../../app/store";
import { getProjectProcessCreateTask } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessCreateTask";
import { getProjectProcessTasksActiveTab } from "../../../../../app/feature/ProjectProcessView/Tasks/selectors/getProjectProcessTasksActiveTab";
import { getCanProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "../../../Project/View/CheckTasks/CheckTaskCard";

type OutTaskListProps = {
  tasks: any;
  chatId?: number;
  setActiveTabMemo?: (id: string | number) => void;
  projectId?: number;
  processId?: number;
  type: "project" | "process";
  setModalData?: SetState<TModalData>;
};

export const OutTaskList = ({
  tasks,
  setActiveTabMemo,
  projectId,
  type,
  processId,
  chatId,
  setModalData,
}: OutTaskListProps) => {
  const isLoadCreateTask = useAppSelector(getProjectProcessCreateTask);
  const activeTasksTab = useAppSelector(getProjectProcessTasksActiveTab);
  const can = useAppSelector(getCanProcessInfo);

  const [modalCreateTask, setModalCreateTask] = useState(false);

  const canCreateTask = activeTasksTab === 2 && can?.outputTask;

  const closeModalCreateTask = useCallback(() => {
    setModalCreateTask(false);
  }, []);

  return (
    <>
      {canCreateTask && (
        <div className={style.headerBtn}>
          <Modal
            active={modalCreateTask}
            setActive={setModalCreateTask}
            isLoad={isLoadCreateTask}
          >
            <ModalCreateTask
              projectId={projectId?.toString() || ""}
              closeModal={closeModalCreateTask}
            />
          </Modal>
          <span>Выдать задание</span>
          <IconButton
            sx={iconBtnSx}
            title="Выдать задание"
            onClick={() => setModalCreateTask(true)}
          >
            <AddFile width="24" height="24" />
          </IconButton>
        </div>
      )}
      <div className={style.cardList}>
        {tasks.map((task: any) => (
          <div key={task.header}>
            <OutTaskCard
              task={task}
              chatId={chatId}
              projectId={projectId}
              processId={processId}
              type={type}
              setActiveTabMemo={setActiveTabMemo}
              setModalData={setModalData}
            />
          </div>
        ))}
      </div>
    </>
  );
};
