import { Controller } from "react-hook-form";
import cn from "classnames";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SelectExecutor } from "src/FSD/features/v2/SelectExecutor";
import { SelectUserTypeToAssign } from "src/FSD/features/v2/SelectUserTypeToAssign";
import styles from "./PublishContentProcess.module.scss";
import { Nullable } from "../../../../../../../../types/generics";
import { TSelectItem } from "../../../../../../../shared/uiKit/v2/Select";

type Props = {
  control: any;
  id: number;
  userType: Nullable<TSelectItem>;
  executor: Nullable<TSelectItem>;
  errors: any;
  isProcessWork: boolean;
};

export const AppointmentExecutor = ({
  control,
  id,
  userType,
  executor,
  errors,
  isProcessWork,
}: Props) => {
  return (
    <div className={styles.appointExecute}>
      <div
        className={cn(
          styles.appointExecute__selects,
          !isProcessWork && styles.appointExecute__reverse
        )}
      >
        <Controller
          name="userTypeToAssign"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectUserTypeToAssign
              publishObjectId={id}
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
              }}
              disabled={!executor && !isProcessWork}
              executor={executor}
              isProcessWork={isProcessWork}
              error={!!errors.userTypeToAssign}
              helperText={errors?.userTypeToAssign?.message}
            />
          )}
        />

        <Controller
          name="userToAssign"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectExecutor
              publishObjectId={id}
              userType={userType}
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
              }}
              disabled={!userType && isProcessWork}
              error={!!errors.userToAssign}
              helperText={errors?.userToAssign?.message}
              isProcessWork={isProcessWork}
            />
          )}
        />
      </div>
      {isProcessWork && (
        <Controller
          control={control}
          name="checkDelegation"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label="Делегирование прав на исполнение раздела"
              onChange={onChange}
              value={value}
              checked={!!value}
              disabled={!executor}
              className={!executor ? styles.disabled : ""}
              editing
            />
          )}
        />
      )}
    </div>
  );
};
