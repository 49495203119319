import { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { ModalCheckDocumentations } from "src/components/pages/ProjectProcessView/CheckTask/CheckTaskIn/CheckTaskCard/Task/ModalCheckDocumentations/ModalCheckDocumentations";
import { ModalWrapper } from "src/components/pages/User/Edit/PersonalDataChange/PersonalDataChange";
import { clearTaskCompletion } from "src/FSD/entities/kanban/model";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ModalCheck } from "src/components/pages/ProjectProcessView/Documentations/CustomerAlign/CustomerAlignCard/ModalCheck/ModalCheck";
import { ModalPreliminaryDone } from "src/components/pages/ProjectProcessView/Documentations/CustomerAlign/CustomerAlignCard/ModalPreliminaryDone/ModalPreliminaryDone";

import { ModalPreliminaryDone as ProcessRequestCustomerAlign } from "src/components/pages/ProjectProcessView/Documentations/CustomerAlign/ModalPreliminaryDone/ModalPreliminaryDone";

import { FileUploader } from "src/components/newUI/SourceDataView/AddFilesButton/ModalAddFiles";
import { ModalPreliminaryRequest } from "src/components/pages/ProjectProcessView/HeaderBtn/ModalPreliminaryRequest/ModalPreliminaryRequest";
import { ModalCheckProcessTask } from "src/components/pages/ProjectProcessView/Documentations/ProcessTask/ProcessTaskCard/ModalCheckProcessTask/ModalCheckProcessTask";
import { ModalCompleteShort } from "src/components/pages/Project/View/Tasks/ModalComplete/ModalCompleteShort";
import { pushSuccessMessage } from "src/app/feature/errorTrace";
import {
  TWorkFlowClasses,
  WorkFlowClasses,
} from "src/FSD/shared/lib/constants/workFlowClasses";

import ProcessWorkModals from "src/FSD/widgets/v2/processWork/modals";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { KanbanModel } from "src/FSD/entities/kanban";

import { ModalComplete as ModalExpertiseComplete } from "src/components/pages/ProjectProcessView/Documentations/ProcessTask/ExpertiseInCard/ModalComplete/ModalComplete";
import { ModalCheck as ModalExpertiseCheck } from "src/components/pages/ProjectProcessView/Documentations/ProcessTask/ExpertiseInCard/ModalCheck/ModalCheck";

import { ModalCompleteRow as ModalExpertiseRowComplete } from "src/components/pages/ProjectProcessView/Expertise/ExpertiseCard/Remark/RemarkTable/RemarkTableContent/ModalCompleteRow/ModalCompleteRow";

import Check from "../../../../../../components/newUI/StatusCardWrapper/Additional/Check";
import { CheckOutputTask } from "./CheckOutputTask";
import { CompleteTaskWithoutModal } from "./CompleteTaskWithoutModal";
import { ModalComplete as ModalCompleteExecution } from "../../../../../../components/pages/ProjectProcessView/Documentations/ProcessTask/ProcessTaskCard/ModalComplete/ModalComplete";
import styles from "./TaskCompletion.module.scss";
import useNonInitialEffect from "../../../../../../components/hooks/useNonInitialEffect";
import KanbanModals from "../../modals";
import TaskModals from "../../../task";

/*
  "app\\workflow\\verification\\Workflow": <></>, // пока пусто
*/

export const TaskCompletionModals = () => {
  const dispatch = useAppDispatch();

  const taskCompletion = useAppSelector(KanbanModel.getTaskCompletion);
  const cachedTasks = useAppSelector(KanbanModel.getCache);

  const handleCloseModal = () => {
    dispatch(clearTaskCompletion());
  };

  useNonInitialEffect(() => {
    if (!cachedTasks) {
      dispatch(pushSuccessMessage());
    }
  }, [cachedTasks]);

  if (!taskCompletion || !cachedTasks) {
    return null;
  }

  const { id, workFlowClass, header, process } = taskCompletion;

  const classesWithoutModals: Partial<Record<TWorkFlowClasses, ReactNode>> = {
    [WorkFlowClasses.CHECK_OUTPUT_TASK]: (
      <div className={styles.c1}>
        <CheckOutputTask taskId={id} handleCloseModal={handleCloseModal} />
      </div>
    ),
  };

  // const workFlowClass = WorkFlowClasses.EXECUTE_WORK;

  // отдельно можно указать класс для дебага

  // убрать доп классы
  // сделать отдельный Layout для модалок
  // поместить его в @/shared/uiKit
  const classesWithModals: Partial<Record<TWorkFlowClasses, ReactNode>> = {
    // [WorkFlowClasses.CHECK_SUB_CHECK_PROCESS]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <ModalCheckDocumentations
    //         header={header}
    //         taskId={id}
    //         files={files}
    //         closeModal={handleCloseModal}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.EXECUTE_PROCESS]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <ModalCompleteExecution
    //         taskId={id}
    //         processIdDerrived={process.id}
    //         header={header}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.CHECK_EXECUTE_PROCESS]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c3}>
    //       <ModalCheckProcessTask
    //         taskId={id}
    //         comment={comment}
    //         files={files}
    //         processIdDerrived={process.id}
    //         header={header}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.SUB_TASK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <Check
    //         isProceeded={false}
    //         taskStatus={status}
    //         name={taskName}
    //         files={files}
    //         can={can}
    //         taskId={id}
    //         comment={comment}
    //         content={content}
    //         handleCloseModal={handleCloseModal}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.CHECK_SUB_TASK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <Check
    //         isProceeded={true}
    //         taskStatus={status}
    //         name={taskName}
    //         files={files}
    //         can={can}
    //         taskId={id}
    //         comment={comment}
    //         content={content}
    //         handleCloseModal={handleCloseModal}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.CHECK_SUB_CHECK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <ModalCheckDocumentations
    //         closeModal={handleCloseModal}
    //         files={files}
    //         taskId={id}
    //         header={header}
    //         processIdDerrived={process.id}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.OUTPUT_TASK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c1}>
    //       <ModalCompleteShort
    //         taskId={id}
    //         header={header}
    //         handleCloseModal={handleCloseModal}
    //         files={files}
    //         comment={comment}
    //         needsFiles={needFiles}
    //         projectId={project.id}
    //         processId={process.id}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.CHECK_EXPERTISE_REMARK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c2}>
    //       <ModalExpertiseCheck
    //         header={header}
    //         taskId={id}
    //         closeModal={handleCloseModal}
    //         files={files}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    // [WorkFlowClasses.EXPERTISE_REMARK]: (
    //   <ModalWrapper show handleCloseModal={handleCloseModal}>
    //     <div className={styles.c2}>
    //       <ModalExpertiseComplete
    //         taskId={id}
    //         header={header}
    //         addText={addText}
    //         closeModal={handleCloseModal}
    //       />
    //     </div>
    //   </ModalWrapper>
    // ),
    [WorkFlowClasses.CHECK_EXPERTISE_REMARK_ROW]: (
      <ProcessWorkModals.CheckExpertiseRemarkRowCompleteForm
        id={id}
        header={header}
      />
    ),
    [WorkFlowClasses.EXPERTISE_REMARK_ROW]: (
      <ProcessWorkModals.ExpertiseRemarkRowCompleteForm
        id={id}
        header={header}
      />
    ),
    [WorkFlowClasses.CUSTOMER_ALIGN_WORK]: (
      <ProcessWorkModals.CustomerAlignCompleteForm id={id} header={header} />
    ), // A
    [WorkFlowClasses.REQUEST_WORK_CUSTOMER_ALIGN]: (
      <ProcessWorkModals.RequestCustomerAlignCompleteForm
        id={id}
        header={header}
      />
    ), // B

    [WorkFlowClasses.CHECK_WORK_CUSTOMER_ALIGN]: (
      <ProcessWorkModals.CheckCustomerAlignForm id={id} header={header} />
    ), // C

    [WorkFlowClasses.CHECK_EXECUTE_WORK]: (
      <ProcessWorkModals.CheckExecuteCompleteForm id={id} header={header} />
    ),
    [WorkFlowClasses.EXECUTE_WORK]: (
      <ProcessWorkModals.ExecuteCompleteForm id={id} header={header} />
    ),
    [WorkFlowClasses.REQUEST_PROCESS_CUSTOMER_ALIGN]: (
      <ProjectProcessModals.RequestCustomerAlignCompleteForm
        id={id}
        header={header}
      />
    ),

    [WorkFlowClasses.CUSTOMER_ALIGN_PROCESS]: (
      <ProjectProcessModals.CustomerAlignCompleteForm id={id} header={header} />
    ),

    [WorkFlowClasses.CHECK_PROCESS_CUSTOMER_ALIGN]: (
      <ProjectProcessModals.CheckCustomerAlignForm id={id} header={header} />
    ),

    [WorkFlowClasses.TECHNICAL_SPECIFICATIONS]: (
      <TaskModals.TechnicalSpecificationsForm header={header} taskId={id} />
    ),
    [WorkFlowClasses.CHECK_FREE_TASK]: (
      <KanbanModals.CheckFreeTaskForm taskId={id} />
    ),
    [WorkFlowClasses.SUB_TASK]: (
      <ProjectProcessModals.SubTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_TASK]: (
      <ProjectProcessModals.CheckSubTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_CHECK]: (
      <ProjectProcessModals.CheckSubCheckForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.EXECUTE_PROCESS]: (
      <ProjectProcessModals.ExecuteProcessForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.OUTPUT_TASK]: (
      <ProjectProcessModals.OutputTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_CHECK_PROCESS]: (
      <ProjectProcessModals.CheckSubCheckProcessForm
        taskId={id}
        header={header}
      />
    ),
    [WorkFlowClasses.CHECK_EXPERTISE_REMARK]: (
      <ProjectProcessModals.CheckExpertiseRemarkForm
        taskId={id}
        header={header}
      />
    ),
    [WorkFlowClasses.EXPERTISE_REMARK]: (
      <ProjectProcessModals.ExpertiseRemarkForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_EXECUTE_PROCESS]: (
      <ProjectProcessModals.CheckExecuteProcess
        taskId={id}
        processId={process.id}
        header={header}
      />
    ),
  };

  // завершение задачи без модалки (появляются различные сообщения для конферма)
  if (classesWithoutModals[workFlowClass]) {
    return <>{classesWithoutModals[workFlowClass]}</>;
  }

  // завершение задачи с помощью модалки
  if (classesWithModals[workFlowClass]) {
    return (
      <Modal isActive handleClose={handleCloseModal}>
        {classesWithModals[workFlowClass]}
      </Modal>
    );
  }

  // завершение задачи без модалок и конфермов
  return <CompleteTaskWithoutModal id={id} workFlowClass={workFlowClass} />;
};

// <ModalExpertiseRowComplete />
