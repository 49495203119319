import React, { useCallback, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/app/store";
import { getExecutorProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import { NewChatIcon } from "src/components/newUI/SVG";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoProjectChatId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getCanTaskProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanTaskProcessInfo";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import {
  getRemoveExecutorCompleteIsLoad,
  getRemoveExecutorIsLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { getApplicationsRemoveRequestSuccess } from "src/app/feature/ProjectProcessView/Applications/selectors/getApplicationsRemoveRequest";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { getUserUpdateIsLoad } from "src/app/feature/ProjectProcessView/Info/selectors/getUserUpdate";
import { getFeedbackExecutorIsLoad } from "src/app/feature/ProjectProcessView/Info/selectors/getFeedbackExecutorIsLoad";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { EditExecutor } from "../../modals/EditExecutor/EditExecutor";
import { RemoveExecutor } from "../../modals/RemoveExecutor/RemoveExecutor";
import { CompleteRemove } from "../../modals/CompleteRemove/CompleteRemove";
import { FeedbackExecutor } from "../../modals/FeedbackExecutor/FeedbackExecutor";
import styles from "./Executor.module.scss";

const sx = {
  color: "#0AA699",
  padding: 0,
  transition: "0.3s ease-in-out 0s!important",
  "&:hover": {
    transform: "scale(1.2)",
  },
};

export const ExecutorHave = () => {
  const threeDotButtonRef = useRef<RefType>(null);
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const executor = useAppSelector(getExecutorProcessInfo);
  const chatId = useAppSelector(getProjectProcessInfoProjectChatId);
  const canTask = useAppSelector(getCanTaskProcessInfo);
  const isLoadUserUpdate = useAppSelector(getUserUpdateIsLoad);
  const isLoadExecutorFeedback = useAppSelector(getFeedbackExecutorIsLoad);
  const removeExecutorIsLoad = useAppSelector(getRemoveExecutorIsLoad);

  const canThreeDotBtn =
    infoData.can.requestRemoveExecutor ||
    infoData.can.updateExecutor ||
    infoData.activeAccess?.taskRemove?.can?.complete ||
    canTask?.feedbackToExecutor;

  const [modalExecutor, setModalExecutor] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalEditExecutor, setModalEditExecutor] = useState(false);
  const [modalCompleteRemove, setModalCompleteRemove] = useState(false);

  const modalExecutorClose = useCallback(() => {
    setModalExecutor(false);
  }, []);

  const modalFeedbackClose = useCallback(() => {
    setModalFeedback(false);
  }, []);

  const modalCompleteRemoveClose = useCallback(() => {
    setModalCompleteRemove(false);
  }, []);

  function closeModalEditExecutor() {
    setModalEditExecutor(false);
  }

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  function removeExecutor() {
    setModalExecutor(true);
    closeThreeDot();
  }

  function editExecutor() {
    setModalEditExecutor(true);
    closeThreeDot();
  }

  function completeRemove() {
    setModalCompleteRemove(true);
    closeThreeDot();
  }

  function feedBackExecutor() {
    setModalFeedback(true);
    closeThreeDot();
  }

  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <h2>Исполнитель</h2>
        {executor && (
          <>
            {infoData.can.requestRemoveExecutor && (
              <NewModal
                title={"Запросить снятие исполнителя"}
                active={modalExecutor}
                setActive={setModalExecutor}
                isLoad={removeExecutorIsLoad}
              >
                <RemoveExecutor closeModal={modalExecutorClose} />
              </NewModal>
            )}
            <NewModal
              title={"Рассмотрение заявки на снятие исполнителя"}
              active={modalCompleteRemove}
              setActive={setModalCompleteRemove}
            >
              <CompleteRemove closeModal={modalCompleteRemoveClose} />
            </NewModal>
            {canTask?.feedbackToExecutor && (
              <NewModal
                active={modalFeedback}
                setActive={setModalFeedback}
                isLoad={isLoadExecutorFeedback}
              >
                <FeedbackExecutor modalClose={modalFeedbackClose} />
              </NewModal>
            )}
            {infoData.can.updateExecutor && (
              <NewModal
                title={`Изменить доступ к проекту`}
                active={modalEditExecutor}
                setActive={setModalEditExecutor}
                isLoad={isLoadUserUpdate}
              >
                <EditExecutor onClose={closeModalEditExecutor} />
              </NewModal>
            )}
            <div className={styles.executor_header_btn}>
              {infoData.can.chat && (
                <Link to={`/chat/${chatId}`}>
                  <IconButton title="Чат" sx={sx}>
                    <NewChatIcon />
                  </IconButton>
                </Link>
              )}
              <div className={styles.buttons}>
                {canThreeDotBtn && (
                  <ThreeDotButton ref={threeDotButtonRef} color="success">
                    {infoData.can.requestRemoveExecutor && (
                      <MenuItem onClick={removeExecutor} disableRipple>
                        Снять исполнителя
                      </MenuItem>
                    )}
                    {infoData.can.updateExecutor && (
                      <MenuItem onClick={editExecutor} disableRipple>
                        Сменить исполнителя
                      </MenuItem>
                    )}
                    {infoData?.activeAccess?.taskRemove?.can?.complete && (
                      <MenuItem onClick={completeRemove} disableRipple>
                        Рассмотреть конфликт
                      </MenuItem>
                    )}
                    {canTask?.feedbackToExecutor && (
                      <MenuItem onClick={feedBackExecutor} disableRipple>
                        Оценить
                      </MenuItem>
                    )}
                  </ThreeDotButton>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {executor && (
        <div className={styles.executor_info}>
          <div className={styles.executor_text}>
            <TextLink url={executor.link}>
              <p className={styles.executor_name}>{executor.name}</p>
            </TextLink>
            <span>{executor.firm}</span>
          </div>
          <div className={styles.executor_avatar_container}>
            <hr className={styles.yellowLine} />
            <NewAvatar
              photoUrl={executor.avatar}
              rating={executor.rating}
              jobDuration={executor.jobDuration}
              borderColor={"#F5A623"}
              classname={styles.executor_avatar}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExecutorHave;
