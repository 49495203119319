import React from "react";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { TProjectProcessCheckList } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { fetchProjectProcessSourceDataUpdate } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceDataUpdate";
import { SourceDataUpdateForm } from "src/FSD/shared/uiKit/v2/SourceDataUpdateForm";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProject,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";

interface ModalGearProps {
  parentId: number;
  projectId: number;
  handleDeleteSourceDataName: (id: number) => void;
  checkList: TProjectProcessCheckList[];
  name: string;
  closeModal: () => void;
  updateAddCheckList: () => void;
  type: number;
  isLoad: boolean;
}

export const ModalGear = ({
  projectId,
  parentId,
  checkList,
  closeModal,
  updateAddCheckList,
  name,
  handleDeleteSourceDataName,
  type,
  isLoad,
}: ModalGearProps) => {
  const dispatch = useAppDispatch();
  const projectData = useAppSelector(getProjectProcessInfoProject);
  const infoId = useAppSelector(getProjectProcessInfoId);

  const formSubmitHandler = async (dataValue: TProjectProcessCheckList[]) => {
    const data = dataValue.map((el) => {
      return {
        id: el.id,
        select: el.author,
        executor: el.executor,
        critical: el.critical,
        hidden: el.hidden,
        name: el.name,
      };
    });
    await dispatch(
      fetchProjectProcessSourceDataUpdate({
        projectId,
        parentId,
        data,
        type,
      })
    );
    closeModal();
    await dispatch(fetchProjectProcessInitialData(infoId as number));
  };

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{name}</Modal.Header>
      {isLoad ? (
        <Modal.Preloader />
      ) : (
        <SourceDataUpdateForm
          formSubmitHandler={formSubmitHandler}
          checkList={checkList}
          closeModal={closeModal}
          canExecutorEdit={!!projectData?.can?.edit}
          handleDeleteSourceDataName={handleDeleteSourceDataName}
          parentId={parentId}
          projectId={projectId}
          updateAddCheckList={updateAddCheckList}
        />
      )}
    </Modal.Layout>
  );
};
