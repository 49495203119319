import React, { ChangeEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { fetchPublishInfo } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { getProjectSections } from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  setAdditionalChecked,
  setInitialAdditionalChecked,
} from "src/app/feature/ProjectProcessView/Info/slice/projectProcessInfo";
import { getProjectProcessAdditionalChecked } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessAdditional";
import {
  fetchProjectProcessUpdateAdditional,
  fetchProjectProcessUpdateAdditionalWork,
} from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessUpdateAdditional";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import styles from "./Additional.module.scss";
import style from "../Stop/Stop.module.scss";

interface IModalAdditionProps {
  closeModal: () => void;
}

export const Addition = ({ closeModal }: IModalAdditionProps) => {
  const dispatch = useAppDispatch();
  const { publishInfo, isLoadPublishInfo } = useAppSelector(getProjectSections);
  const processId = useAppSelector(getProjectProcessInfoId);
  const checked = useAppSelector(getProjectProcessAdditionalChecked);
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;

  useEffect(() => {
    if (processId) {
      dispatch(fetchPublishInfo({ id: processId }));
    }
  }, [dispatch, processId]);

  useEffect(() => {
    const data = {
      kac: infoData.kac,
      vor: infoData.vor,
      vorPnr: infoData.vor_pnr,
    };
    dispatch(setInitialAdditionalChecked(data));
  }, [infoData]);

  const { handleSubmit } = useForm();

  const formSubmitHandler = async () => {
    const data: any = {};
    if (publishInfo.kac) {
      data.kac = checked.kac;
    }
    if (publishInfo.vor) {
      data.vor = checked.vor;
    }
    if (publishInfo.vor_pnr) {
      data.vor_pnr = checked.vorPnr;
    }
    if (!Object.keys(data).length) {
      closeModal();
      return;
    }

    if (infoData?.typeName === "Раздел" && processId) {
      await dispatch(fetchProjectProcessUpdateAdditional({ processId, data }));
      closeModal();
    }
    if (infoData?.typeName === "Задание" && processId) {
      await dispatch(
        fetchProjectProcessUpdateAdditionalWork({ processId, data })
      );
      closeModal();
    }
  };

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    dispatch(setAdditionalChecked({ name, checked }));
  }

  if (isLoadPublishInfo) {
    return <NewModalPreloader />;
  }

  return (
    <form className={style.form} onSubmit={handleSubmit(formSubmitHandler)}>
      {publishInfo.kac && (
        <label className={styles.label}>
          <Checkbox
            editing
            value={checked.kac}
            checked={checked.kac}
            name={"kac"}
            onChange={onChange}
          />
          <p>Конъюнктурный анализ цен</p>
        </label>
      )}
      {publishInfo.vor && (
        <label className={styles.label}>
          <Checkbox
            editing
            value={checked.vor}
            checked={checked.vor}
            name={"vor"}
            onChange={onChange}
          />
          Ведомость объемов работ
        </label>
      )}
      {publishInfo.vor_pnr && (
        <label className={styles.label}>
          <Checkbox
            editing
            value={checked.vorPnr}
            checked={checked.vorPnr}
            name={"vorPnr"}
            onChange={onChange}
          />
          ВОР на пусконаладочные работы
        </label>
      )}
      <Button
        width={160}
        variant="contained"
        type={"submit"}
        className={style.form_submit}
      >
        Сохранить
      </Button>
    </form>
  );
};
