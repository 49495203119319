import {
  CounterType,
  ProviderDataListType,
} from "../../../../../../types/stateTypes/indexPages/indexPageUserDataType";
import {
  createUserRating,
  getUserFullStringAddress,
  getUserName,
} from "../../../../../services/user/user";
import { ApiUserProviderType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../../../../app.cofig";

export const createCounterByGip: (
  data: ApiUserProviderType
) => CounterType[] = (data) => {
  return [
    {
      title: "Получено <br> запросов",
      value: data.providerTotalCount,
      url: `/project/search?ProjectSearch[providerId]=${data.id}`,
    },
    {
      title: "Отправлено <br> предложений",
      value: data.providerPullCount,
      url: `/project/search?ProjectSearch[providerId]=${data.id}&ProjectSearch[materialProcessStatus][0]=6&ProjectSearch[materialProcessStatus][1]=5&ProjectSearch[materialProcessStatus][2]=4`,
    },
    {
      title: "Отзывы",
      value: data.feedbacks?.length || 0,
      url: `/user/${data.id}?tab=feedback`,
    },
  ];
};

export const createProvidersListFromApi: (
  data: ApiUserProviderType[]
) => ProviderDataListType = (data) =>
  data.map((item) => ({
    name: getUserName(item),
    address: item.region ? getUserFullStringAddress(item) : null,
    aboutText: item.about!,
    counterItems: createCounterByGip(item),
    id: item.id,
    isConfirm: item.tested!,
    ratingValue: createUserRating(Number(item.rating!.provider)),
    specialItems: item.skills!.map((item) => item.name),
    avatarUrl: `${config.localDomain}${item.photo}`,
    workWithBim: item.work_with_bim!,
    providerPullCount: item.providerPullCount,
    providerTotalCount: item.providerTotalCount,
    premium: item.premium,
    skills: item.skills,
  }));
