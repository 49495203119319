import { Route, Routes } from "react-router-dom";
import { ProjectView as ProjectViewPage } from "src/components/pages/Project/NewView/ProjectView";
import { Editing } from "../components/pages/Project/Editing";
import { ProjectsPage } from "../components/pages/Search/Project/ProjectsPage";
import {
  IndexPageTemplateNew,
  IndexPageTemplate,
} from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import { ProjectView } from "../components/pages/Project/View/ProjectView";
import style from "../components/pages/Project/NewView/ProjectView.module.scss";

export const ProjectRoutes = () => (
  <Routes>
    <Route path="/project">
      <Route
        path={"create"}
        element={
          <IndexPageTemplate>
            <Editing />
          </IndexPageTemplate>
        }
      />
      <Route
        path={"init"}
        element={
          <IndexPageTemplate>
            <Editing />
          </IndexPageTemplate>
        }
      />
      <Route
        path={"update"}
        element={
          <IndexPageTemplate>
            <Editing />
          </IndexPageTemplate>
        }
      />
      <Route
        path={"search"}
        element={
          <IndexPageTemplate>
            <ProjectsPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={":id"}
        element={
          <IndexPageTemplate>
            <ProjectView />
          </IndexPageTemplate>
        }
      />
    </Route>
    <Route path="/project/test">
      <Route
        path={":id"}
        element={
          <IndexPageTemplateNew className={style.footer}>
            <ProjectViewPage />
          </IndexPageTemplateNew>
        }
      />
    </Route>
  </Routes>
);
