import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import EducationItem from "./EducationItem/EducationItem";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import C from "../../../../../constants";
import style from "./style.module.scss";
import { UnsavedType } from "../../../Project/types";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { getErrorMessage } from "../../../../../app/services/api/requestHandler";

type ComponentProps = {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
};

export type SubmitDataProps = {
  organization: string;
  date_start: Date | null;
  date_end: Date | null;
  specialty: string;
};

const Education: React.FC<ComponentProps> = ({ setUnsaved, userId }) => {
  const {
    educationListIsLoading,
    educationList,
    loadEducationList,
    createEducationPlace,
    editEducation,
    deleteEducation,
  } = useProfile();

  useEffect(() => {
    loadEducationList(userId);
  }, [userId]);

  const { enqueueSnackbar } = useSnackbar();

  const onCreate = (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    createEducationPlace({ user_id: userId, ...data })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, education: false }));
        loadEducationList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onUpdate = (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    editEducation({ user_id: userId, ...data, id })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, education: false }));
        loadEducationList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onDelete = (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!window.confirm(C.CONFIRM_DELETE)) {
      return;
    }

    setIsFetching(true);
    deleteEducation(id)
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        loadEducationList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  return (
    <div className={style.wrapper}>
      {educationListIsLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="232px"
          width="100%"
        />
      ) : (
        <>
          <EducationItem setUnsaved={setUnsaved} onCreate={onCreate} />
          {educationList.map((educationPlace: any) => {
            return (
              <EducationItem
                setUnsaved={setUnsaved}
                key={educationPlace.id}
                onUpdate={onUpdate}
                onDelete={onDelete}
                educationPlace={educationPlace}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Education;
