import { IndexPageMaterialsDataType } from "../../../../types/stateTypes/indexPages/indexPageMaterialsDataTypes";

export const materialsPageInitialState: IndexPageMaterialsDataType = {
  materials: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
