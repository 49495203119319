import React, { memo } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import cls from "./WarningCard.module.scss";
import { ApplicationsMessages } from "../../../app/feature/ProjectProcessView/Applications/types/projectProcessApplicationTypes";
import CustomButton from "../CustomButton/CustomButton";
import { palette } from "../../../styles/restyle";
import { ApiCommonResponses } from "../../../FSD/entities/apiCommonResponses";

interface WarningCardProps {
  warningsInput?: ApiCommonResponses.Warning["input"] | undefined;
  warningsActual?: ApiCommonResponses.Warning["actual"] | undefined;
  warningsOutput?: ApiCommonResponses.Warning["output"] | undefined;
  warningRequired?: ApiCommonResponses.Warning["required"] | undefined;
  applicationWarning?: ApplicationsMessages[] | undefined;
  classname?: string;
}
export const WarningCard: React.FC<WarningCardProps> = memo(
  ({
    warningsInput,
    warningsActual,
    warningsOutput,
    warningRequired,
    applicationWarning,
    classname,
  }) => {
    const canWarning =
      warningsInput || warningsActual || warningsOutput || warningRequired;
    return (
      <>
        {canWarning && (
          <div className={classNames(cls.warning, classname)}>
            <h1>Внимание !</h1>
            {warningsInput && (
              <>
                <h3>Требуемые задания</h3>
                <p className={cls.warning_lineHeight}>{warningsInput.title}</p>
                <div className={cls.warning_flex}>
                  {warningsInput.data.map((warning) => (
                    <TextLink
                      url={`/project-process/${warning.processId}`}
                      key={warning.processId}
                      variant={cls.warningLink}
                    >
                      {warning.name}
                    </TextLink>
                  ))}
                </div>
              </>
            )}
            {warningsActual && (
              <div className={cls.actual}>
                <h3>{warningsActual.title}</h3>
                <div className={cls.actual_flex}>
                  {warningsActual.data.map((warning) => (
                    <p key={warning}>{warning}</p>
                  ))}
                </div>
              </div>
            )}
            {warningsOutput && (
              <div className={cls.actual}>
                <p>{warningsOutput.title}</p>
              </div>
            )}
            {warningRequired && (
              <>
                <div className={cls.warning_lineHeight}>
                  {parse(warningRequired.title)}
                </div>
                <div className={cls.warning_flex}>
                  {warningRequired.data.map((warning) => (
                    <TextLink
                      url={`/project-process/${warning.processId}`}
                      key={warning.processId}
                      variant={cls.warningLink}
                    >
                      {warning.name}
                    </TextLink>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {applicationWarning && (
          <div className={classNames(cls.warning, classname)}>
            <h1>Внимание !</h1>
            <p>
              Выбор исполнителя невозможен, так как не заполнены данные в личном
              кабинете
            </p>
            <div className={cls.applications}>
              {applicationWarning.map((warning: any) => (
                <div className={cls.applications_block}>
                  <div className={cls.applications_block_text}>
                    <h3>{warning.title}</h3>
                    {warning.attributes.map((attribute: any) => (
                      <p>{attribute}</p>
                    ))}
                  </div>
                  {warning.link && (
                    <TextLink url={warning.link} redirect target={"_blank"}>
                      <CustomButton width={140} background={palette.green}>
                        Заполнить
                      </CustomButton>
                    </TextLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
);
