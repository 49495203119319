import React, { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { fetchProjectProcessFeedbackExecutor } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessFeedbackExecutor";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { FeedBackForm } from "../../../../newUI/FeedBackForm/FeedBackForm";
import { getProjectProcessInfoData } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getExecutorProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";

interface IModalFeedbackExecutorProps {
  modalClose: () => void;
}

export const ModalFeedbackExecutor: React.FC<IModalFeedbackExecutorProps> =
  memo(({ modalClose }) => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const infoData = useAppSelector(getProjectProcessInfoData);
    const executor = useAppSelector(getExecutorProcessInfo);

    const formSubmitHandler = useCallback(
      async (dataValues) => {
        const data = {
          quality: dataValues.quality,
          reliability: dataValues.reliability,
          loyalty: dataValues.loyalty,
          comment: dataValues.comment,
          type: 1,
        };
        if (infoData && executor) {
          await dispatch(
            fetchProjectProcessFeedbackExecutor({
              taskId: infoData.task.id,
              userId: executor.id,
              data,
            })
          );
        }
        if (params.id) {
          dispatch(fetchProjectProcessViewDuplicate(+params.id));
        }
        modalClose();
      },
      [fetchProjectProcessFeedbackExecutor]
    );

    if (executor) {
      return (
        <FeedBackForm formSubmitHandler={formSubmitHandler} data={[executor]} />
      );
    }
    return <></>;
  });
