import React, { FC, useState, useEffect, useMemo } from "react";
import { Skeleton, TextField } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import style from "./OrganisationItem/style.module.scss";
import OrganisationItem from "./OrganisationItem/OrganisationItem";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import {
  OrganisationSearch,
  SkeletonOrganisationSearch,
} from "./OrganisationSearch/OrganisationSearch";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import { clearSearchList } from "../../../../../app/feature/profileReducer/profileReducer";
import { Paginate } from "../../../../UI/components/Pagination/Pagination";
import { selectorAppTheme } from "../../../../../app/feature/app/app";
import { palette, textFieldSX } from "../../../../../styles/restyle";

type SubmitDataProps = { query: string };

const skeletonSX = {
  bgcolor: "#37474F",
  borderRadius: "10px",
  marginBottom: "30px",
  height: "207px",
};

const SearchPreloader: FC = () => (
  <>
    <SkeletonOrganisationSearch />
    <SkeletonOrganisationSearch />
    <SkeletonOrganisationSearch />
  </>
);

const Organisation: FC = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectorAppTheme);
  const { handleSubmit, register, reset, watch } = useForm<SubmitDataProps>();

  const [requestSent, setRequestSent] = useState<boolean>(false);

  const {
    searchOrganisation,
    searchOrganisationsIsLoading,
    searchOrganisationsList,
    searchOrganisationsMeta,
    userFirmsList,
    userFirmsListIsLoading,
    getUserFirmsList,
  } = useProfile();

  useEffect(() => {
    getUserFirmsList();
  }, []);

  const onSubmit: SubmitHandler<SubmitDataProps> = (data) => {
    setRequestSent(true);
    searchOrganisation({ query: data.query, page: 1 });
  };

  const handleClearForm = () => {
    reset();
    setRequestSent(false);
    dispatch(clearSearchList());
  };

  const onChangePage = (event: any, page: number) => {
    const query = watch("query");

    setRequestSent(true);
    searchOrganisation({ query, page });
  };

  const idsList = useMemo(
    () => userFirmsList.filter((item) => item.id).map((item) => item.id),
    [userFirmsList]
  );

  const organisations = userFirmsList.length ? (
    userFirmsList.map((organisation) => <OrganisationItem {...organisation} />)
  ) : (
    <p className={style.firmsNotFound}>Организации не найдены</p>
  );

  const searchOrganisations =
    searchOrganisationsList.length > 0
      ? searchOrganisationsList.map((organisation) => (
          <OrganisationSearch {...organisation} idsList={idsList} />
        ))
      : requestSent && (
          <div className={cn(style.notFound, style.forSearch)}>
            Ничего не найдено
          </div>
        );

  const paginate =
    searchOrganisationsList.length > 0 && searchOrganisationsMeta ? (
      <Paginate
        page={+searchOrganisationsMeta.paginationCurrentPage}
        handleChange={onChangePage}
        count={+searchOrganisationsMeta.paginationPageCount}
        theme={theme}
        variant="outlined"
        shape="rounded"
      />
    ) : (
      <></>
    );

  const searchOrganisationsBlock = searchOrganisationsIsLoading ? (
    <SearchPreloader />
  ) : (
    searchOrganisations
  );

  return (
    <div className={style.wrapper}>
      {userFirmsListIsLoading ? (
        <Skeleton variant="rectangular" sx={skeletonSX} />
      ) : (
        organisations
      )}
      <div className={style.organisation}>
        <div className={style.header}>
          <h1 className={style.textLink}>Поиск организации</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={style.content}>
          <TextField
            {...register("query")}
            {...textFieldSX}
            variant="outlined"
            size="small"
            fullWidth
            label="Наименование или ИНН и юр. адрес организации"
            autoComplete="off"
          />
          <div className={style.buttons}>
            <CustomButton width={130} background={palette.blue} type="submit">
              Поиск
            </CustomButton>
            <CustomButton
              width={130}
              background={palette.grey}
              type="button"
              onClick={handleClearForm}
            >
              Сбросить
            </CustomButton>
          </div>
          <div className={style.organisationsList}>
            <div className={style.paginate}>{paginate}</div>
            {searchOrganisationsBlock}
            <div className={style.paginate}>{paginate}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Organisation;
