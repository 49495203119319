import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { projectLog } from "../../../../types/stateTypes/projectLogs";

export type ProjectLogsType = projectLog;

export const projectLogsInitialState: ProjectLogsType = {
  perPage: 1,
  currentPage: 1,
  data: [],
  paginationPageCount: 1,
  paginationAllPageCount: 1,
  filterParams: {
    data: "",
    dateTo: "",
    dateFrom: "",
    person: "",
  },
  logTypes: {},
  update: false,
  sortParams: {
    sort: {
      id: 1,
      name: "date_create",
    },
    type: "desc",
  },
};

export const projectLogsReducer = createReducer(
  projectLogsInitialState,
  (builder) => {
    builder.addCase(
      C.SET_PROJECT_LOGS,
      (state, action: PayloadAction<ProjectLogsType>) => ({
        ...state,
        data: action.payload.data,
        currentPage: action.payload.currentPage,
        perPage: action.payload.perPage,
        paginationPageCount: action.payload.paginationPageCount,
        paginationAllPageCount: action.payload.paginationAllPageCount,
        filterParams: {
          data: state.filterParams.data || "",
          dateTo: state.filterParams.dateTo || "",
          dateFrom: state.filterParams.dateFrom || "",
          person: state.filterParams.person || "",
          event: state.filterParams.event || "",
        },
      })
    );
    builder.addCase(
      C.SET_FILTER_PROJECT_LOG,
      (state, action: PayloadAction<ProjectLogsType>) => ({
        ...state,
        filterParams: {
          ...state.filterParams,
          [action.payload.filterParams.type]: action.payload.filterParams.value,
        },
      })
    );
    builder.addCase(
      C.PROJECT_LOG_UPDATE,
      (state, action: PayloadAction<ProjectLogsType>) => ({
        ...state,
        update: action.payload.update,
      })
    );
    builder.addCase(
      C.SET_PROJECT_LOGS_TYPES,
      (state, action: PayloadAction<ProjectLogsType>) => ({
        ...state,
        logTypes: {
          ...action.payload.logTypes,
        },
      })
    );
    builder.addCase(
      C.SET_PROJECT_LOGS_SORT,
      (state, action: PayloadAction<ProjectLogsType>) => ({
        ...state,
        sortParams: {
          sort: {
            name: action.payload.sortParams.sort.name,
            id: action.payload.sortParams.sort.id,
          },
          type: action.payload.sortParams.type,
        },
      })
    );
  }
);
