import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getProjectDocument,
  setError,
  setToggle,
} from "../../../../../app/feature/ProjectView/DocumentReducer/projectDocuments";
import { DocumentCard } from "./DocumentCard";
import style from "./Document.module.scss";
import { AllToggleFalse, AllToggleTrue } from "../../../../newUI/SVG";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { SkeletonDocuments } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";

const sx = { color: "white" };

interface IDocumentsProps {
  setCurrentTabMemo: () => void;
}
export const Documents = ({ setCurrentTabMemo }: IDocumentsProps) => {
  const { documents, isLoad, error, isAllToggle } =
    useSelector(getProjectDocument);
  const { project } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  if (project && !project?.can.viewDocs) {
    setCurrentTabMemo();
  }
  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  const handleClick = () => {
    dispatch(setToggle());
  };

  const allToggleIcon = !isAllToggle ? (
    <AllToggleTrue width="33" heigth="33" />
  ) : (
    <AllToggleFalse width="33" heigth="33" />
  );

  if (isLoad) {
    return <SkeletonDocuments />;
  }

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  return (
    <div className={style.wrapper}>
      {documents.map((el) => (
        <DocumentCard key={el.id} data={el} />
      ))}
      <div className={style.allToggle}>
        <IconButton sx={sx} onClick={handleClick}>
          {allToggleIcon}
        </IconButton>
      </div>
    </div>
  );
};
