import React, { memo } from "react";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/app.cofig";
import styles from "./ModalSeismicity.module.scss";

type ModalSeismicityProps = {
  seismicity: { a: string; b: string; c: string } | null;
  location: string;
  region: string;
  closeModal: () => void;
};

export const ModalSeismicity = memo(
  ({ seismicity, location, region }: ModalSeismicityProps) => {
    const containsSeismicity = Boolean(
      seismicity?.a || seismicity?.b || seismicity?.c
    );

    const containsAstrisk =
      seismicity?.a?.includes("*") ||
      seismicity?.b?.includes("*") ||
      seismicity?.c?.includes("*");

    const Astring = `${
      seismicity?.a?.includes("*")
        ? `A - ${seismicity?.a}*`
        : `A - ${seismicity?.a}`
    }`;
    const Bstring = `${
      seismicity?.b?.includes("*")
        ? `B - ${seismicity?.b}*`
        : `B - ${seismicity?.b}`
    }`;
    const Cstring = `C - ${seismicity?.c}`;
    const locationContent = containsSeismicity ? (
      <span>{`карта (${Astring}, ${Bstring}, ${Cstring})`}</span>
    ) : (
      <span>
        <TextLink
          target="_blank"
          redirect
          url={`${config.localDomain}/files/Карты_OCP_2016_А_В_С.pdf`}
          className={styles.link}
        >
          Уточнить по картам
        </TextLink>
      </span>
    );

    return (
      <div className={styles.seismicity}>
        <div className={styles.seismicity_location}>
          <div className={styles.seismicity_flex}>
            <p>Регион:</p>
            <span>{`${region} (сейсмоактивный)`}</span>
          </div>
          {location && (
            <div className={styles.seismicity_flex}>
              <p>Населенный пункт:</p>
              {location}
            </div>
          )}
          <div className={styles.seismicity_flex}>
            <p>Сейсмическая интенсивность(баллы):</p>
            {locationContent}
          </div>
          <div className={styles.seismicity_flex}></div>
        </div>
        {containsAstrisk && (
          <p className={styles.astrisk}>
            * Фактическая балльность очередного по времени землетрясения
            превышает некоторый заранее заданный уровень интенсивности для
            данной территории
          </p>
        )}
        <p className={styles.info}>
          Данные указаны согласно списку населенных пунктов Российской
          Федерации, расположенных в сейсмоактивных зонах, характеризуемых
          сейсмической интенсивностью 6 и более баллов по шкале MSK-64 для
          средних грунтовых условий и трёх уровней сейсмической опасности
          (вероятностей превышения расчётной интенсивности в течение 50 лет,
          равных 10, 5, 1% для карт ОСР-2016-А, ОСР-2016-В, ОСР-2016-С)
        </p>
      </div>
    );
  }
);
