import { getFodlerColors } from "../../../../../app/feature/dashboard/sections/utils";

abstract class Filter {
  static valueContains(value: string, query: string) {
    if (!value || !query) {
      return false;
    }

    return value.toLowerCase().includes(query.toLowerCase());
  }

  static updateTreeByFilterParams(data: any, ticked: number[]) {
    let treeRaw = JSON.parse(JSON.stringify(data));

    const getCounts = (node: string): boolean => {
      const currentNode = treeRaw[node];
      if (currentNode?.id) {
        const includesCount = ticked.includes(currentNode.id);
        currentNode.count = includesCount;
        return includesCount;
      }

      let count = false;
      currentNode.children.forEach((item: string) => {
        count = getCounts(item) || count;
      });

      currentNode.count = count;
      return count;
    };

    getCounts("0");

    treeRaw = getFodlerColors(treeRaw, true);

    return treeRaw;
  }
}

export default Filter;
