import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой изменения задачи в организации
 */

export const showEventEditOrganisationTask = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventEditOrganisationTask: {
      isShow: true,
      data: {
        ...state.eventEditOrganisationTask.data,
      },
    },
  };
};

export const closeEventEditOrganisationTask = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventEditOrganisationTask: {
    isShow: false,
    data: {
      ...state.eventEditOrganisationTask.data,
    },
  },
});

export const setEventEditOrganisationTask = (
  state: EventEmitterEventType,
  action: {
    payload: any;
  }
): EventEmitterEventType => ({
  ...state,
  eventEditOrganisationTask: {
    isShow: false,
    data: action.payload,
  },
});

export const selectorEditOrganisationTask = (state: StateType) =>
  state.eventEmitter.eventEditOrganisationTask.isShow;

export const selectorEditOrganisationTaskData = (state: StateType) =>
  state.eventEmitter.eventEditOrganisationTask.data;
