import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
  postPageInitialState,
  blogPageInitialState,
} from "./postPageInitialState";
import C from "./constants";
import {
  ApiBlogType,
  ApiPostType,
  ApiPostProjectType,
} from "../../../../types/api/responseTypes/apiPostType";
import { StateType } from "../../../initialState";
import { createPostList } from "./createData/createData";

/*
  postPageReducer
 */

export const postPageReducer = createReducer(
  postPageInitialState,
  (builder) => {
    builder.addCase(
      C.SET_POST_PAGE_DATA,
      (state, action: PayloadAction<ApiPostType>) => {
        return {
          ...state,
          post: createPostList(action.payload.post),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorPostItems = (state: StateType) => state.postPage.post;
export const selectorPostMeta = (state: StateType) => state.postPage.meta;

export const setPostItems = (
  post: ApiPostType["post"],
  meta: ApiPostType["meta"]
) => ({
  type: C.SET_POST_PAGE_DATA,
  payload: {
    post,
    meta,
  },
});

/*
  Блог с 4 разделами
 */

export const postBlogReducer = createReducer(
  blogPageInitialState,
  (builder) => {
    builder.addCase(
      C.SET_BLOG_PAGE_DATA,
      (state, action: PayloadAction<ApiBlogType>) => {
        return {
          ...state,
          library: createPostList(action.payload.library),
          platform: createPostList(action.payload.platform),
          firstFace: createPostList(action.payload.firstFace),
          media: createPostList(action.payload.media),
          project: action.payload.project,
        };
      }
    );
  }
);

export const selectorBlogLibraryItems = (state: StateType) =>
  state.blogPage.library;
export const selectorBlogPlatformItems = (state: StateType) =>
  state.blogPage.platform;
export const selectorBlogFirstFaceItems = (state: StateType) =>
  state.blogPage.firstFace;
export const selectorBlogMediaItems = (state: StateType) =>
  state.blogPage.media;
export const selectorBlogProjectItem = (state: StateType) =>
  state.blogPage.project;

export const setBlogItems = (
  library: ApiPostType["post"],
  platform: ApiPostType["post"],
  firstFace: ApiPostType["post"],
  media: ApiPostType["post"],
  project: ApiPostProjectType
) => ({
  type: C.SET_BLOG_PAGE_DATA,
  payload: {
    library,
    platform,
    firstFace,
    media,
    project,
  },
});
