import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  filesAreRequired,
  stringMinMaxLengthRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const outputTaskSchema = Yup.object().shape({
  files: Yup.array().when(["status", "needFiles"], {
    is: (status, needFiles) => status === Statuses.IS_DONE && needFiles,
    then: filesAreRequired(),
  }),
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.IS_DONE,
    then: stringMinMaxLengthRequired("Комментарий", 15, 200),
  }),
});
