import { Nullable } from "../../../../types/generics";

type TModalsState = {
  requestRemoveExecutor: boolean;
  updateExecutor: boolean;
  feedBackExecutor: boolean;
  considerConflict: boolean;
  requestExecution: boolean;
  updateProcessWork: boolean;
  workPublication: boolean;
  checkCustomerAlign: {
    isOpen: boolean;
    taskId: Nullable<number>;
    header: string;
  };
  executeComplete: {
    isOpen: boolean;
    taskId: Nullable<number>;
    header: string;
    comment: string;
  };
};

export const modalsInitialState: TModalsState = {
  requestRemoveExecutor: false,
  updateExecutor: false,
  feedBackExecutor: false,
  considerConflict: false,
  requestExecution: false,
  updateProcessWork: false,
  workPublication: false,
  checkCustomerAlign: {
    isOpen: false,
    taskId: null,
    header: "",
  },
  executeComplete: {
    isOpen: false,
    taskId: null,
    header: "",
    comment: "",
  },
};
