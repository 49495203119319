import moment, { Moment } from "moment";
import { TSelectItem } from "../../../../../../../../components/features/Forms/SetGipForm/types";
import { httpRequestGet } from "../../../../../../../../app/services/api/api";
import { config } from "../../../../../../../../app.cofig";

export type TCommentValues = {
  id?: number;
  comment: string;
  date_limit: Moment | Date | null;
  status: TSelectItem;
  uploadFiles: File[];
};

export const ApiGetCommentById = (id: string | number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/comment-source-data/view?id=${id}&expand=files`
  );
};

export const statusOptions = [
  {
    id: 2,
    title: "В работе",
  },
  {
    id: 16,
    title: "Предварительно согласовано",
  },
  {
    id: 3,
    title: "Выполнено",
  },
];

export const formDefaultValues = (isEditing: boolean, currentComment: any) => {
  if (!isEditing) {
    return {
      comment: "",
      date_limit: null,
      status: statusOptions[0],
      uploadFiles: [],
    };
  }

  const {
    id,
    comment,
    date_limit,
    status,
    files: uploadFiles,
  } = currentComment;

  return {
    id,
    comment,
    date_limit: moment(date_limit, "DD.MM.YYYY").toDate(),
    status: statusOptions.find((option) => option.id === status),
    uploadFiles,
  };
};
