// функция для инициализации ключей num по уровням вложенности
// просто передать сюда объект с бэка, главное чтобы ключи назывались num
// в state делаем   openNode: {}, isAllToggle: false
export function collectNums(obj: any) {
  const nums = {};
  function collect(obj: any) {
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          collect(obj[key].data);
        }
        // @ts-ignore
        nums[obj[key].num] = undefined;
      });
    }
  }
  collect(obj);
  return nums;
}

// функция для открытия и закрытия одной ноды, node-объект ноды, key - num
export function setToggleNode(node: any, key: string) {
  if (node[key] !== undefined) {
    node[key] = undefined;
  } else {
    node[key] = key;
  }
}

// для кнопки скрыть/раскрыть все аккордеоны
export function setAllToggleNode(state: any) {
  const openNodes = Object.keys(state.openNode);
  if (openNodes.every((key) => state.openNode[key] !== undefined)) {
    openNodes.forEach((key) => {
      state.openNode[key] = undefined;
    });
    state.isAllToggle = false;
  } else {
    openNodes.forEach((key) => {
      state.openNode[key] = key;
    });
    state.isAllToggle = true;
  }
}

// уровень вложенности в дереве
export function selectLvlClass(lvl: number) {
  switch (lvl) {
    case 2:
      return "lvl2";
    case 3:
      return "lvl3";
    case 4:
      return "lvl4";
    default:
      return "lvl2";
  }
}
