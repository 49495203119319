import { v4 as uuidv4 } from "uuid";

export const generateFilesListFromClient = <
  T extends File & { id?: number | null },
>(
  files: T[],
  uploadAsActive: boolean | undefined
) =>
  files.map((file: T) => ({
    file,
    visibility: Boolean(uploadAsActive),
    svgIcon: null,
    name: file.name,
    serverId: file?.id ?? null,
    id: uuidv4(),
  }));

export const generateFilesListFromServer = <
  T extends { id: number; visibility: boolean; svgIcon: string; name: string },
>(
  files: T[]
) =>
  files.map(({ visibility, id: serverId, svgIcon, name }: T) => ({
    file: null,
    visibility,
    svgIcon,
    name,
    serverId,
    id: uuidv4(),
  }));
