import * as Yup from "yup";
import moment from "moment";
import { messages } from "../../../../../../../utils/SimplifiedYup";

export const filterSchema = Yup.object().shape({
  dateLimit: Yup.mixed()
    .nullable()
    .test(
      "dateLimitExceedsDateStart",
      messages.dateLimitExceedsDateStart,
      function (value) {
        if (!this.parent.dateStart) {
          return true;
        }

        return value ? moment(value) >= moment(this.parent.dateStart) : true;
      }
    ),
  dateStart: Yup.mixed()
    .nullable()
    .test(
      "dateStartLessDateLimit",
      messages.dateLimitExceedsDateStart,
      function (value) {
        if (!this.parent.dateLimit) {
          return true;
        }

        return value ? moment(value) <= moment(this.parent.dateLimit) : true;
      }
    ),
});
