import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { secureMissingAttributes } from "./queries";

export const userTypeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    userTypeSecureMissingAttributes: secureMissingAttributes(builder),
  }),
  overrideExisting: false,
});

export const { useLazyUserTypeSecureMissingAttributesQuery } = userTypeApi;
