import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { Wrapper } from "../../../templates";
import { NoticeItem, NoticeItemType } from "./Notice/NoticeItem";
import style from "../style.module.scss";

export type NoticesListProps = ComponentPropsType<{
  items: NoticeItemType[];
  toggleHandler: (toggle: string, id: number) => void;
  deleteHandler: (id: number) => void;
}>;

export const NoticesList: React.FC<NoticesListProps> = ({
  variant = "",
  items,
  theme = "dark",
  toggleHandler = () => {},
  deleteHandler = () => {},
}) => {
  return (
    <>
      {items.length > 0 ? (
        <ul className={classNames(variant, style.noticeList)}>
          {items.map((item: any, i: number) => {
            return (
              <li key={i}>
                <Wrapper>
                  <NoticeItem
                    {...item}
                    toggleHandler={toggleHandler}
                    deleteHandler={deleteHandler}
                    theme={theme}
                    variant={style.noticeLabel}
                  />
                </Wrapper>
              </li>
            );
          })}
        </ul>
      ) : (
        <Wrapper>
          <div>Ничего не найдено</div>
        </Wrapper>
      )}
    </>
  );
};
