import React, { memo } from "react";
import { CardApplications } from "./CardApplications";
import cls from "./ApplicationList.module.scss";
import { ApplicationsType } from "../../../../../app/feature/ProjectProcessView/Applications/types/projectProcessApplicationTypes";

interface IGoodApplicationsProps {
  applications: ApplicationsType[] | undefined;
  setInfoTab?: () => void;
}

export const ApplicationList: React.FC<IGoodApplicationsProps> = memo(
  ({ applications, setInfoTab }) => {
    return (
      <div className={cls.card}>
        {applications &&
          applications.map((application) => (
            <CardApplications
              key={application.userType.listName}
              application={application}
              setInfoTab={setInfoTab}
            />
          ))}
      </div>
    );
  }
);
