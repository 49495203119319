import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessInitialData } from "../../../../services/api/projectProcess/initialData";
import { ProjectProcessInitialDataTypes } from "../types/projectProcessInitialDataTypes";

export const fetchProjectProcessInitialData = createAsyncThunk<
  ProjectProcessInitialDataTypes,
  number
>("projectProcess/fetchProjectProcessInitialData", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProjectProcessInitialData(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
