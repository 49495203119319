import * as Yup from "yup";

export const bikValidation = Yup.string().test(
  "bikTest",
  "БИК должен быть числом из 9 цифр!",
  (value: any) => {
    const containsDigitsOnly = value
      .split("")
      .every((digit: string) => Number.isInteger(Number(digit)));

    return (value.length === 9 && containsDigitsOnly) || !value.length;
  }
);

export const bankNameValidation = (field: string) =>
  Yup.string().test(
    "bankName",
    "Необходимо заполнить «Наименование банка»",
    function (value) {
      return (
        (value || "").length > 0 ||
        (!this.parent[`${field}bik`] && !value?.length)
      );
    }
  );

export const accountValidation = (field: string) =>
  Yup.string()
    .test(
      "accountRequired",
      "Поле обязательно для заполнения",
      function (value) {
        return (
          (value || "").length > 0 ||
          (!this.parent[`${field}bik`] && !value?.length)
        );
      }
    )
    .test(
      "accountFormat",
      "Значение должно быть числом из 20 цифр",
      // @ts-ignore
      function (value) {
        return this.parent[`${field}bik`]
          ? value?.length === 20 && value?.match(/^\d+$/)
          : true;
      }
    );

export const kpp = Yup.string()
  .test("kppReq", "Необходимо заполнить «КПП»", function (value) {
    return (
      (value || "").length > 0 ||
      (!this.parent.treasuryAccount1bik && !value?.length)
    );
  })
  .test(
    "kppFormat",
    "Значение КПП должно содержать максимум 9 цифр",
    // @ts-ignore
    function (value) {
      return this.parent.treasuryAccount1bik
        ? (value || "").length <= 9 && value?.match(/^\d+$/)
        : true;
    }
  );

export const clientNumber = Yup.string()
  .test("clientReq", "Необходимо заполнить «Лицевой счет»", function (value) {
    return (
      (value || "").length > 0 ||
      (!this.parent.treasuryAccount1bik && !value?.length)
    );
  })
  .test(
    "clientFormat",
    "Значение «Лицевой счет» должно содержать максимум 20 цифр",
    // @ts-ignore
    function (value) {
      return this.parent.treasuryAccount1bik
        ? (value || "").length <= 20 && value?.match(/^\d+$/)
        : true;
    }
  );

export const analysisCode = Yup.string().test(
  "bankName",
  "Необходимо заполнить «Аналитический код раздела»",
  function (value) {
    return (
      (value || "").length > 0 ||
      (!this.parent.treasuryAccount1bik && !value?.length)
    );
  }
);

export const inn = Yup.string()
  .test("innReq", "Необходимо заполнить «ИНН счет»", function (value) {
    return (
      (value || "").length > 0 ||
      (!this.parent.treasuryAccount1bik && !value?.length)
    );
  })
  .test(
    "innFormat",
    "ИНН должен иметь длину 10 (юрлицо) или 12 (физлицо) цифр",
    // @ts-ignore
    function (value) {
      return this.parent.treasuryAccount1bik
        ? (value?.length === 10 || value?.length === 12) &&
            value?.match(/^\d+$/)
        : true;
    }
  );

export const ufk = Yup.string()
  .test("ufkReq", "Необходимо заполнить «УФК»", function (value) {
    return (
      (value || "").length > 0 ||
      (!this.parent.treasuryAccount1bik && !value?.length)
    );
  })
  .test(
    "ufkFormat",
    "Значение УФК должно содержать максимум 15 цифр",
    // @ts-ignore
    function (value) {
      return this.parent.treasuryAccount1bik
        ? (value || "").length <= 15 && value?.match(/^\d+$/)
        : true;
    }
  );

export const validationSchema = Yup.object().shape({
  bankAccount1bik: bikValidation,
  bankAccount1bank_name: bankNameValidation("bankAccount1"),
  bankAccount1correspondent_account: accountValidation("bankAccount1"),
  bankAccount1bank_account: accountValidation("bankAccount1"),

  bankAccount2bik: bikValidation,
  bankAccount2bank_name: bankNameValidation("bankAccount2"),
  bankAccount2correspondent_account: accountValidation("bankAccount2"),
  bankAccount2bank_account: accountValidation("bankAccount2"),

  treasuryAccount1bik: bikValidation,
  treasuryAccount1bank_name: bankNameValidation("treasuryAccount1"),
  treasuryAccount1correspondent_account: accountValidation("treasuryAccount1"),
  treasuryAccount1bank_account: accountValidation("treasuryAccount1"),
  treasuryAccount1inn: inn,
  treasuryAccount1kpp: kpp,
  treasuryAccount1ufk: ufk,
  treasuryAccount1client_number: clientNumber,
  treasuryAccount1analysis_code: analysisCode,
});
