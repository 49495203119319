import React, { ReactNode, memo } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import cls from "./Accordion.module.scss";
import { Dot } from "../Dot/Dot";
import { ToggleAccordion } from "../ToggleAccordion/ToggleAccordion";
import {
  FolderIcon,
  FolderStopIcon,
  FolderWarningIcon,
  GearFillSVG,
  PencilEditSVG,
} from "../SVG";
import {
  SecondNodeIcon,
  ThirdNodeIcon,
} from "./AccordionSVGIcon/AccordionSVGIcon";

interface AccordionProps {
  text: string | ReactNode;
  count?: string;
  dotSize?: number;
  dotStatus?: string;
  toogle: boolean;
  classname?: string;
  gearBtnClick?: (e: Event) => void;
  pencilBtnClick?: (e: Event) => void;
  canGearBtn?: boolean;
  canPencilBtn?: boolean;
  isDelegate?: boolean;
  delegate?: boolean;
  doneCount?: number;
  outDateCount?: number;
  inWorkCount?: number;
  onToggle?: (event: any) => void;
  light?: boolean;
  noMargin?: boolean;
  numNode?: string;
  marginTop?: string;
  colorNodeIcon?: string;
  stopFolder?: boolean;
  warningFolder?: boolean;
}

const sx = {
  color: "white",
  padding: 0,
  transition: "0.3s ease-in-out 0s!important",
  "&:hover": {
    transform: "scale(1.2)",
  },
};

function countDots(str: string | undefined) {
  if (str) {
    const res = str.split(".").length - 1;
    if (res === 0) {
      return "rootNode";
    }
    if (res === 1) {
      return "secondNode";
    }
    return "thirdNode";
  }
}

export const Accordion: React.FC<AccordionProps> = memo(
  ({
    toogle,
    text,
    dotSize = 8,
    dotStatus,
    classname,
    canGearBtn,
    gearBtnClick,
    canPencilBtn,
    pencilBtnClick,
    isDelegate,
    delegate,
    doneCount,
    outDateCount,
    inWorkCount,
    onToggle,
    light = false,
    noMargin = false,
    count,
    numNode,
    marginTop,
    colorNodeIcon = "#D5D6DE",
    warningFolder = false,
    stopFolder = false,
  }) => {
    const textAccordion = count ? (
      <span>{`${text} (${count})`}</span>
    ) : (
      <span>{text}</span>
    );

    const typeNode = countDots(numNode);

    return (
      <div
        onClick={onToggle}
        className={classNames(
          cls.wrapper,
          cls[typeNode || ""],
          classname,
          light && cls.light,
          noMargin && cls.noMargin
        )}
        style={{
          marginTop,
        }}
      >
        <div className={cls.flex}>
          <div className={cls.dot}>
            {dotStatus && <Dot status={dotStatus} size={dotSize} />}
            {!stopFolder && !warningFolder && typeNode === "rootNode" && (
              <FolderIcon width={"24"} height={"24"} />
            )}
            {stopFolder && !warningFolder && typeNode === "rootNode" && (
              <FolderStopIcon />
            )}
            {!stopFolder && warningFolder && typeNode === "rootNode" && (
              <FolderWarningIcon />
            )}
            {typeNode === "secondNode" && (
              <SecondNodeIcon color={colorNodeIcon} />
            )}
            {typeNode === "thirdNode" && (
              <ThirdNodeIcon color={colorNodeIcon} />
            )}
            {textAccordion}
            {canPencilBtn && (
              <IconButton sx={sx} onClick={pencilBtnClick as any}>
                <PencilEditSVG width="18" height="18" />
              </IconButton>
            )}
          </div>
          <ToggleAccordion
            toggle={toogle}
            light={light}
            root={typeNode === "rootNode" || typeNode === "thirdNode"}
            secondRoot={typeNode === "secondNode"}
            classes={(canGearBtn && cls.accordion) || ""}
          />
          {canGearBtn && (
            <IconButton
              sx={{ color: "white" }}
              className={cls.gearBtn}
              onClick={gearBtnClick as any}
            >
              <GearFillSVG width="22" height="22" />
            </IconButton>
          )}
        </div>
        {isDelegate && (
          <div className={cls.delegate}>
            {delegate && <p>Делегирование прав на исполнение раздела</p>}
            <div className={cls.delegate_count}>
              {outDateCount !== 0 && (
                <div className={cls.delegate_count_content}>
                  <p className={cls.delegate_count_out}>{outDateCount}</p>
                  <span>Просрочено</span>
                </div>
              )}
              {doneCount !== 0 && (
                <div className={cls.delegate_count_content}>
                  <p className={cls.delegate_count_done}>{doneCount}</p>
                  <span>Выполнено</span>
                </div>
              )}
              {inWorkCount !== 0 && (
                <div className={cls.delegate_count_content}>
                  <p className={cls.delegate_count_inWork}>{inWorkCount}</p>
                  <span>В работе</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
