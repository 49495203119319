import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { getProjectGraphData } from "../../../../../../app/feature/ProjectView/GraphReducer/graphReducer";
import style from "./style.module.scss";
import cls from "./BudgetGraph/BudgetGraph.module.scss";
import { GipHelpersIcon } from "../../../../../newUI/SVG";
import { EditDateModal, ModalRefType } from "./EditDateModal/EditDateModal";
import Modal from "../../../../../newUI/Modal/Modal";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";
import { BudgetGraph } from "./BudgetGraph/BudgetGraph";
import { ObjectGraph } from "./ObjectGraph/ObjectGraph";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { ObjectBudgetGraph } from "./ObjectBudgetGraph/ObjectBudgetGraph";
import Switch from "../../../../../newUI/Switch/Switch";
import { GraphRender } from "./GraphRender/GraphRender";

const sx = { color: "white" };
export const GraphProject = () => {
  const {
    fact,
    forecast,
    plan,
    budget,
    priceFactCurrent,
    pricePlanCurrent,
    pricePlan,
    isLoadUpdateDate,
    factChild,
    budgetChild,
  } = useSelector(getProjectGraphData);
  const formRef = useRef<ModalRefType>(null);

  const { project } = useSelector(getProjectViewData);

  const [editDateModal, setEditDateModal] = useState(false);
  const [toggleBudget, setToggleBudget] = useState(false);
  const [toggleObject, setToggleObject] = useState(false);
  const params = useParams<{ id: string }>();

  const modalCloseMemo = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleClose();
    }
  }, []);

  const canGraph = !!plan?.length || !!forecast?.length || !!fact?.length;
  const renderDesktop = (
    <>
      {!toggleBudget && (
        <>
          {!!plan?.length && (
            <>
              <p className={style.taskTitle}>План (расчётный)</p>
              <div className={style.graphView}>
                <div className={style.fact}>
                  {plan.map(([name, task], index: number) => (
                    <GraphRender key={index} graph={task} name={name} />
                  ))}
                </div>
              </div>
            </>
          )}
          {!!forecast?.length && (
            <>
              <p className={style.taskTitle}>План по договору</p>
              <div className={style.graphView}>
                <div className={style.fact}>
                  {forecast.map(([name, task], index: number) => (
                    <GraphRender key={index} graph={task} name={name} />
                  ))}
                </div>
              </div>
            </>
          )}
          {!!fact?.length && (
            <>
              <p className={style.taskTitle}>Факт-прогноз</p>
              <div className={style.graphView}>
                <div className={style.fact}>
                  {fact.map(([name, task], index: number) => (
                    <GraphRender key={index} graph={task} name={name} />
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
      {toggleBudget && project?.can.view && (
        <>
          {budget && (
            <div className={style.graphView}>
              <div className={style.priceBudget}>
                {pricePlan && (
                  <div className={style.priceBudget_flex}>
                    <p>План общий</p>
                    <span>{pricePlan} ₽</span>
                  </div>
                )}
                {pricePlanCurrent && (
                  <div className={style.priceBudget_flex}>
                    <p>План текущий</p>
                    <span>{pricePlanCurrent} ₽</span>
                  </div>
                )}
                {priceFactCurrent && (
                  <div className={style.priceBudget_flex}>
                    <p>Факт текущий</p>
                    <span>{priceFactCurrent} ₽</span>
                  </div>
                )}
              </div>
              <div className={cls.budget}>
                {budget.map(([name, task]: any, index: number) => (
                  <BudgetGraph key={index} budget={task} name={name} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {!project?.parentId && (
        <>
          {factChild && !!factChild.length && !toggleBudget && (
            <div
              onClick={() => setToggleObject(!toggleObject)}
              className={style.objectIcon}
            >
              <h3>Фактические графики по объектам</h3>
              <ToggleAccordion toggle={toggleObject} />
            </div>
          )}
          {toggleObject && !!factChild.length && !toggleBudget && (
            <div className={style.graphView}>
              {factChild.map((object: any) => (
                <ObjectGraph key={object.name} object={object} />
              ))}
            </div>
          )}
          {budgetChild && !!budgetChild.length && toggleBudget && (
            <div
              onClick={() => setToggleObject(!toggleObject)}
              className={classNames(style.objectIcon_budget, style.objectIcon)}
            >
              <h3>Фактический бюджет по объектам</h3>
              <ToggleAccordion toggle={toggleObject} />
            </div>
          )}
          {toggleObject && !!budgetChild.length && toggleBudget && (
            <div className={style.objectBudget}>
              {budgetChild.map((object: any) => (
                <ObjectBudgetGraph key={object.project.id} object={object} />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {canGraph && (
        <div className={style.title}>
          <h1>График</h1>
          <div className={style.btnContainer}>
            {project?.can.view && (
              <div
                className={style.toggleIcon}
                onClick={() => setToggleBudget(!toggleBudget)}
              >
                <p>Оплата</p>
                <Switch checked={toggleBudget} />
              </div>
            )}
            {project?.can.updateGanttData && (
              <Modal
                active={editDateModal}
                setActive={modalCloseMemo}
                activeClass={style.overflowModal}
                isLoad={isLoadUpdateDate}
              >
                {params.id && (
                  <EditDateModal
                    id={+params.id}
                    closeModal={() => setEditDateModal(false)}
                    // @ts-ignore
                    ref={formRef}
                  />
                )}
              </Modal>
            )}
            {project?.can.updateGanttData && (
              <div>
                <IconButton
                  title="Редактировать даты"
                  sx={sx}
                  onClick={() => setEditDateModal(true)}
                >
                  <GipHelpersIcon width="20" height="24" />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      )}
      {renderDesktop}
    </>
  );
};
