import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { materialsPageInitialState } from "./materialsPageInitialsState";
import { StateType } from "../../../initialState";
import { ApiMaterialsDataType } from "../../../../types/api/responseTypes/apiMaterialsType";
import { createMaterialsListFromApi } from "./createData/createData";

export const materialsPageReducer = createReducer(
  materialsPageInitialState,
  (builder) => {
    /*
      Установка значений на страницу материалов
     */

    builder.addCase(
      C.SET_MATERIALS_PAGE_DATA,
      (state, action: PayloadAction<ApiMaterialsDataType>) => {
        return {
          ...state,
          materials: createMaterialsListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorMaterialsItems = (state: StateType) =>
  state.materialsPage.materials;

export const selectorMaterialsMeta = (state: StateType) =>
  state.materialsPage.meta;

export const setMaterials = (
  data: ApiMaterialsDataType["data"],
  meta: ApiMaterialsDataType["meta"]
) => ({
  type: C.SET_MATERIALS_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
