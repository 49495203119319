import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExpertiseType } from "../types/projectProcessExpertiseTypes";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessExpertiseComplete } from "../../../../services/api/projectProcess/expertise";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessExpertiseComplete = createAsyncThunk<
  ExpertiseType[],
  { taskId: number; data: any }
>(
  "projectProcess/fetchProjectProcessExpertiseComplete",
  async ({ taskId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessExpertiseComplete(taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
