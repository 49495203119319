import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTitle } from "../../../hooks/useTitle";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";
import { RegistrationForm } from "../../../features/Forms/RegistrationForm/RegistrationForm";
import { useMetrika } from "../../../hooks/useMetrik";

export const RegistrationPage = () => {
  useTitle("Регистрация");
  useMetrika("/site/registration");

  const theme = useSelector(selectorAppTheme);

  return (
    <div className={style.pageContainer}>
      <div className={classNames(style.titleContainer, style[theme])}>
        <Wrapper>
          <div className={style.titleWrapper}>
            <h1 className={style.pageTitle}>Регистрация</h1>
          </div>
        </Wrapper>
      </div>
      <div className={classNames(style.formContainer, style[theme])}>
        <Wrapper>
          <div className={classNames(style.formWrapper, style[theme])}>
            <RegistrationForm />
          </div>
        </Wrapper>
      </div>
    </div>
  );
};
