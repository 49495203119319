import {
  createSlice,
  CombinedState,
  isPending,
  isFulfilled,
} from "@reduxjs/toolkit";
import { getProcessesData } from "../projectProcessPublication/utils";
import { getGroupRequestProcesses, groupRequest } from "./thunks";

export type TProcessShort = {
  id: number;
  name: string;
  dateStart: string;
  dateLimit: string;
  price: number | null;
  active: boolean;
};

type TState = {
  data: {
    isOpen: boolean;
    processId: number | null;
    groupName: string;
    processesData: Record<number, TProcessShort>;
    executors: any;
    types: any;
    fullTypes: any;
    isTouched: boolean;
  };
  pending: {
    getProcesses: boolean;
    publishing: boolean;
  };
  error: {
    getProcesses: string | null;
    publishProcesses: string | null;
    executors: string | null;
    types: string | null;
    fullTypes: string | null;
  };
};

const initialState: TState = {
  data: {
    isOpen: false,
    processId: null,
    executors: [],
    groupName: "",
    types: [],
    processesData: {},
    fullTypes: [],
    isTouched: false,
  },
  pending: {
    getProcesses: false,
    publishing: false,
  },
  error: {
    getProcesses: null,
    publishProcesses: null,
    executors: null,
    types: null,
    fullTypes: null,
  },
};

const projectGroupProcessRequest = createSlice({
  name: "groupRequestProcess",
  initialState,
  reducers: {
    setOpen(state, action) {
      const { id, partGroup } = action.payload;

      state.data.groupName = partGroup.name;
      state.data.processId = id;
      state.data.isOpen = true;
    },
    setClosed(state) {
      state.data.isOpen = false;
      state.data.processId = null;
    },
    clearGroupRequestProcess() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(groupRequest.pending, (state) => {
        state.pending.publishing = true;
      })
      .addCase(groupRequest.fulfilled, (state) => {
        state.pending.publishing = false;
        state.data.isOpen = false;
        state.data.processId = null;
      })
      .addCase(groupRequest.rejected, (state) => {
        state.pending.publishing = false;
      })
      .addMatcher(isPending(getGroupRequestProcesses), (state) => {
        state.pending.getProcesses = true;
      })
      .addMatcher(isFulfilled(getGroupRequestProcesses), (state, action) => {
        state.data.processesData = getProcessesData(action.payload, "object");
        state.pending.getProcesses = false;
      });
  },
});

export const { setOpen, setClosed, clearGroupRequestProcess } =
  projectGroupProcessRequest.actions;

export const getGroupRequestProcessData = (state: CombinedState<any>) =>
  state.projectGroupProcessRequest;

export default projectGroupProcessRequest.reducer;
