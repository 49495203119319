import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import style from "./style.module.scss";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { MultipleSelect } from "../../../../../UI/components/FormComponentns/MultipleSelect";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { fetchGetUsersTypeToAssign } from "../../../../../../app/feature/project/projectProcessPublication/thunks";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";

export const agreementOptions = [
  {
    id: 0,
    title: "Нет",
  },
  {
    id: 1,
    title: "Да",
  },
];

const Requirements: FC<any> = ({
  id,
  processId,
  watch,
  executors,
  trigger,
  types,
  fullTypes,
  setValue,
  pending,
  control,
  errors,
  isAddExecutorBtn,
  setAddExecutor,
}) => {
  const watchSelectedTypes = watch("selectedTypes");
  const watchExecutor = watch("executor");
  const watchType = watch("type");

  const watchIsSafe = watch("isSafe");
  const watchDocs = watch("isDocs");

  const watchVolumes = watch("isVolumes");
  const watchThief = watch("isThief");
  const watchAnalyze = watch("isAnalyze");

  const dispatch = useAppDispatch();

  const {
    publishInfo: { canNoSecure },
  } = useAppSelector(getProjectSections);

  const changeHandler = (data: (TSelectItem | undefined)[]) => {
    if (data.includes(undefined)) {
      setValue(
        "selectedTypes",
        fullTypes.length === watchSelectedTypes.length ? [] : fullTypes
      );

      trigger("selectedTypes");

      return;
    }

    setValue("selectedTypes", data as TSelectItem[]);
    trigger("selectedTypes");
  };

  // управление полями "назначенный исполнитель" и "Форма собственности"

  useEffect(() => {
    if (watchExecutor) {
      setValue("type", null);
      dispatch(
        fetchGetUsersTypeToAssign({
          id: processId,
          executorValue: watchExecutor.id,
        })
      );
    }
  }, [watchExecutor, dispatch, id]);

  useEffect(() => {
    if (executors.length && !pending.executors) {
      setValue("executor", executors[0]);
    }
  }, [pending.executors]);

  useEffect(() => {
    if (types.length && !pending.types) {
      setValue("type", types[0]);
    }
  }, [pending.types]);

  useEffect(() => {
    if (!canNoSecure) {
      return;
    }

    setValue("isDocs", agreementOptions[1]);
    setValue("isSafe", agreementOptions[1]);
  }, [canNoSecure]);

  // **************

  return (
    <>
      {canNoSecure && (
        <>
          <p>Договор</p>
          <div className={style.selectGrid}>
            <Select
              changeHandler={(_, value) => {
                setValue("isDocs", value);
              }}
              label={"Договоры и Акты"}
              options={agreementOptions}
              value={watchDocs}
              disabled={Boolean(watchIsSafe.id)}
              disableClear
            />
            <Select
              changeHandler={(_, value) => {
                setValue("isSafe", value);

                if (value.id) {
                  setValue("isDocs", agreementOptions[1]);
                }
              }}
              label={"Безопасная сделка"}
              options={agreementOptions}
              value={watchIsSafe}
              disableClear
            />
          </div>
        </>
      )}
      <p>Дополнительные требования к разработке раздела</p>
      <div className={style.selectGrid}>
        <div className={style.checkBox}>
          <Controller
            name="isVolumes"
            control={control}
            render={({ field: { value } }) => (
              <CustomCheckbox
                editing
                checked={watchVolumes}
                onChange={() => {
                  setValue("isVolumes", !value);
                }}
              />
            )}
          />
          <p>Ведомость объемов работ</p>
        </div>
        <div className={style.checkBox}>
          <Controller
            name="isThief"
            control={control}
            render={({ field: { value } }) => (
              <CustomCheckbox
                editing
                checked={watchThief}
                onChange={() => {
                  setValue("isThief", !value);
                }}
              />
            )}
          />
          <p>ВОР на пусконаладочные работы</p>
        </div>
        <div className={style.checkBox}>
          <Controller
            name="isAnalyze"
            control={control}
            render={({ field: { value } }) => (
              <CustomCheckbox
                editing
                checked={watchAnalyze}
                onChange={() => {
                  setValue("isAnalyze", !value);
                }}
              />
            )}
          />
          <p>Конъюнктурный анализ цен</p>
        </div>
      </div>
      <p>Требование к исполнителю</p>
      <MultipleSelect
        variant={style.field}
        value={watchSelectedTypes}
        label="Форма собственности"
        changeHandler={changeHandler}
        options={fullTypes}
        canSelectAll
        error={Boolean(errors.selectedTypes)}
        helperText={errors.selectedTypes?.message}
      />
      {Boolean(executors.length) && (
        <>
          <CustomButton
            onClick={() => setAddExecutor(!isAddExecutorBtn)}
            width={220}
            className={style.responsibilityForm}
            background={palette.red}
          >
            Назначение исполнителя
          </CustomButton>
          {isAddExecutorBtn && (
            <div className={style.executors}>
              <Select
                value={watchExecutor}
                changeHandler={(_, value) => {
                  setValue("executor", value);
                }}
                label={"Назначенный исполнитель"}
                options={executors}
                disableClear
                isLoading={pending.executors}
              />
              <Select
                changeHandler={(_, value) => {
                  setValue("type", value);
                }}
                value={watchType}
                label={"Форма собственности"}
                options={types}
                disableClear
                isLoading={pending.types}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Requirements;
