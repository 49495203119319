import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError, pushSuccessMessage } from "../../../errorTrace";
import {
  ApiDeleteDocumentationTask,
  ApiProjectProcessDocumentations,
} from "../../../../services/api/projectProcess/documentations";
import { DocumentationsType } from "../types/projectProcessDocumentationsTypes";

export const fetchProjectProcessDocumentations = createAsyncThunk<
  DocumentationsType,
  number
>(
  "projectProcess/fetchProjectProcessDocumentations",
  async (id, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDocumentations(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessDocumentationsDuplicate = createAsyncThunk<
  DocumentationsType,
  number
>(
  "projectProcess/fetchProjectProcessDocumentationsDuplicate",
  async (id, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDocumentations(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const deleteByTaskId = createAsyncThunk<void, number>(
  "projectProcess/deleteDocumentationTask",
  async (id, { dispatch }) => {
    await request(
      ApiDeleteDocumentationTask(id),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);
