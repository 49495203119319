import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../styles/restyle";
import cls from "./Objects.module.scss";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { fetchProjectComplexObject } from "../../../../../app/feature/ProjectView/ComplexReducer/thunks";
import {
  getGipRemove,
  getProjectComplexError,
  getProjectComplexLoad,
  getProjectComplexObjects,
  setErrorNull,
  setParentProjectId,
} from "../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { SkeletonComplex } from "../../../../UI/components/Project/Skeleton";
import { ObjectCard } from "./ObjectCard/ObjectCard";

export const Objects = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { project } = useSelector(getProjectViewData);
  const objects = useSelector(getProjectComplexObjects);
  const gipRemoveSuccess = useSelector(getGipRemove);
  const isLoad = useSelector(getProjectComplexLoad);
  const error = useSelector(getProjectComplexError);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
      dispatch(fetchProjectComplexObject({ id: +params.id }));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (gipRemoveSuccess) {
      enqueueSnackbar("Запрос на снятие ГИПа прошел успешно", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [gipRemoveSuccess]);

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setErrorNull());
  }

  const handleCreateSubProject = () => {
    dispatch(setParentProjectId(params.id));
    navigate("/project/create");
  };

  if (isLoad) {
    return <SkeletonComplex />;
  }

  return (
    <div className={cls.objects}>
      <div className={cls.objects_header}>
        <h1>Объекты</h1>
        {project?.can?.edit && (
          <CustomButton
            onClick={handleCreateSubProject}
            width={160}
            background={palette.green}
          >
            Создать объект
          </CustomButton>
        )}
      </div>
      <div className={cls.objects_cardWrapper}>
        {objects.map((object) => (
          <ObjectCard key={object.name} {...object} />
        ))}
      </div>
    </div>
  );
};
