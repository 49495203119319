import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type CopyrightProps = ComponentPropsType<{
  variant?: string;
}>;

export const Copyright: React.FC<CopyrightProps> = React.memo(
  ({ variant = "" }) => {
    const currentYear = new Date().getFullYear();
    return (
      <div className={classNames(style.copyright, variant)}>
        <p className={style.text}>
          <span>
            {"\u00A9"}2019 - {currentYear}. Спроектировано
          </span>
          <br />
          <a href="https://tizh.ru/" title="ООО «Технология»">
            в ООО {"\u00AB"}Технология{"\u00BB"}.
          </a>
        </p>
      </div>
    );
  }
);
