import React from "react";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";
import { EmailConfirmForm } from "../../../features/Forms/EmailConfirmForm/EmailConfirmForm";
import { useTitle } from "../../../hooks/useTitle";
import { useMetrika } from "../../../hooks/useMetrik";

export const EmailConfirmPage = () => {
  useTitle("Подтверждение email");
  useMetrika("/user/confirm");

  return (
    <div className={style.pageContainer}>
      <Wrapper>
        <div className={style.titleContainer}>
          <h1 className={style.pageTitle}>Подтверждение email</h1>
          <h3 className={style.pageSubtitle}>
            На указанный Вами email адрес было выслано письмо с кодом
            подтверждения. Пожалуйста, введите его.
          </h3>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <EmailConfirmForm />
        </div>
      </Wrapper>
    </div>
  );
};
