import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { filesAreRequired } from "../../../../../../../../utils/SimplifiedYup";

export const subTaskSchema = Yup.object().shape({
  files: Yup.array().when("status", {
    is: (status) => status === Statuses.IS_DONE,
    then: filesAreRequired(),
  }),
});
