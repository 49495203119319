import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { eventEmitterInitialState } from "./eventEmitterInitialState";
import { StateType } from "../../initialState";
import { C } from "./constants";
import {
  closeEventUnPublishProcessReducer,
  SetTaskIdActionType,
  setTaskIdReducer,
  showEventUnPublishProcessReducer,
} from "./projectProcess/eventUnPublishProjectProcess";
import {
  closeEventPublishTaskReducer,
  setEventPublishReduces,
  showEventPublishTaskReducer,
} from "./projectProcess/eventPublishProjectProcess";
import {
  closeEventErrorReducer,
  SetMessageActionType,
  setMessageEventErrorReducer,
  showEventErrorReducer,
} from "./error/error";
import {
  closeEventSetExecutorReducer,
  setEventExecutorReducer,
  showEventSetExecutorReducer,
} from "./projectProcess/eventSetExecutorForm";
import { ProjectSetGipPayloadType } from "../../../types/stateTypes/events/eventsPayloadTypes";
import {
  closeRequestRemoveGipReducer,
  showRequestRemoveGipReducer,
} from "./projectProcess/eventRequestRemoveGip";
import {
  closeEventSetGipReducer,
  setEventDataGipReducer,
  showEventSetGipReducer,
} from "./project/eventSetGip";
import {
  closeEventCloseDeleteGipConflictReducer,
  showEventCloseDeleteGipConflictReducer,
} from "./project/eventCloseConfictGip";
import {
  closeEventRequestGipReducer,
  setEventRequestDataGipReducer,
  showEventRequestGipReducer,
} from "./project/eventRequestGip";
import {
  closeEventRevokeRequestGipReducer,
  showEventRevokeRequestGipReducer,
} from "./project/eventRevokeRequestGip";
import {
  closeEventRemoveRequestSearchGip,
  showEventRemoveRequestSearchGip,
} from "./project/eventRemoveRequestSearchGip";
import {
  closeEventSearchProjectGip,
  showEventSearchProjectGip,
} from "./project/eventSearchGip";
import {
  closeEventCompareGipReducer,
  showEventCompareGipReducer,
} from "./project/eventCompareGip";
import {
  closeEventDeleteProject,
  showEventDeleteProject,
} from "./project/eventDeleteProject";
import {
  closeEventJoinUserFirm,
  showEventJoinUserFirm,
} from "./user/eventJoinFirm";
import {
  closeEventJoinUserProject,
  showEventJoinUserProject,
} from "./user/eventJoinProject";
import {
  closeEventUserAgreement,
  showEventUserAgreement,
} from "./registration/eventShowUserAgreement";
import {
  closeEventUserPolicy,
  showEventUserPolicy,
} from "./registration/eventShowUserPolicy";
import {
  closeEventChangeUserRole,
  setEventChangeUserRole,
  showEventChangeUserRole,
} from "./organisation/eventShowUserRole";
import {
  closeEventEditOrganisationTask,
  setEventEditOrganisationTask,
  showEventEditOrganisationTask,
} from "./organisation/eventEditOrganisationTask";
import {
  closeEventEditBankAccount,
  setEventEditBankAccount,
  showEventEditBankAccount,
} from "./organisation/eventEditBankAccount";
import {
  closeEventSendMoney,
  setEventSendMoney,
  showEventSendMoney,
} from "./organisation/eventSendMoney";
import {
  closeEventUserJoin,
  setEventUserJoin,
  showEventUserJoin,
} from "./organisation/eventUserJoin";
import {
  closeEventEditRequestJoin,
  setEventEditRequestJoin,
  showEventEditRequestJoin,
} from "./organisation/eventEditRequestJoin";
import {
  closeEventUserAtUserPage,
  setEventUserAtUserPage,
  showEventUserAtUserPage,
} from "./user/eventUserJoin";
import {
  showEventCreateTeam,
  closeEventCreateTeam,
  setEventCreateTeam,
} from "./organisation/eventCreateTeam";
import {
  closeEventManageTeammate,
  setEventManageTeammate,
  showEventManageTeammate,
} from "./organisation/eventManageTeammate";
import {
  closeEventInviteToTeam,
  setEventUserInviteToTeam,
  showEventInviteToTeam,
} from "./user/eventInviteToTeam";
import {
  showEventViewProject,
  closeEventViewProject,
} from "./project/eventViewProject";

export const eventEmitterReducer = createReducer(
  eventEmitterInitialState,
  (builder) => {
    // projectProcess publish
    builder.addCase(C.SHOW_EVENT_PUBLISH_TASK, showEventPublishTaskReducer);
    builder.addCase(C.ClOSE_EVENT_PUBLISH_TASK, closeEventPublishTaskReducer);
    builder.addCase(C.SET_EVENT_PUBLISH_DATA, setEventPublishReduces);

    // projectProcess unpublish
    builder.addCase(
      C.SHOW_EVENT_UNPUBLISH_TASK,
      showEventUnPublishProcessReducer
    );
    builder.addCase(
      C.CLOSE_EVENT_UNPUBLISH_TASK,
      closeEventUnPublishProcessReducer
    );
    builder.addCase(
      C.UNPUBLISH_EVENT_SET_TASK_ID,
      // @ts-ignore
      (state, action: SetTaskIdActionType) => setTaskIdReducer(state, action)
    );

    // error event
    builder.addCase(C.SHOW_EVENT_ERROR, showEventErrorReducer);
    builder.addCase(C.CLOSE_EVENT_ERROR, closeEventErrorReducer);
    builder.addCase(
      C.SET_ERROR_EVENT_MESSAGE,
      (state, action: SetMessageActionType) =>
        setMessageEventErrorReducer(state, action)
    );

    // setExecutor event
    builder.addCase(C.SHOW_EVENT_SET_EXECUTOR, showEventSetExecutorReducer);
    builder.addCase(C.CLOSE_EVENT_SET_EXECUTOR, closeEventSetExecutorReducer);
    builder.addCase(C.EVENT_SET_EXECUTOR_DATA, (state, action: any) =>
      setEventExecutorReducer(state, action)
    );

    // requestGipEvent
    builder.addCase(
      C.SHOW_EVENT_REQUEST_GIP_PROJECT,
      showEventRequestGipReducer
    );
    builder.addCase(
      C.CLOSE_EVENT_REQUEST_GIP_PROJECT,
      closeEventRequestGipReducer
    );
    builder.addCase(C.SET_EVENT_REQUEST_GIP_PROJECT_DATA, (state, action) =>
      setEventRequestDataGipReducer(state, action as any)
    );

    // removeRequestGipEvent
    builder.addCase(
      C.SHOW_EVENT_REQUEST_REMOVE_GIP,
      showRequestRemoveGipReducer
    );
    builder.addCase(
      C.CLOSE_EVENT_REQUEST_REMOVE_GIP,
      closeRequestRemoveGipReducer
    );

    // setProjectGip
    builder.addCase(C.SHOW_EVENT_SET_GIP_PROJECT, showEventSetGipReducer);
    builder.addCase(C.CLOSE_EVENT_SET_GIP_PROJECT, closeEventSetGipReducer);
    builder.addCase(
      C.SET_EVENT_GIP_PROJECT_DATA,
      (state, action: PayloadAction<ProjectSetGipPayloadType>) =>
        setEventDataGipReducer(state, action)
    );

    // revokeRequestGipProject
    builder.addCase(
      C.SHOW_EVENT_REVOKE_REQUEST_GIP,
      showEventRevokeRequestGipReducer
    );
    builder.addCase(
      C.CLOSE_EVENT_REVOKE_REQUEST_GIP,
      closeEventRevokeRequestGipReducer
    );

    // revokeRequestGipProject
    builder.addCase(
      C.SHOW_EVENT_REMOVE_REQUEST_SEARCH_GIP,
      showEventRemoveRequestSearchGip
    );
    builder.addCase(
      C.CLOSE_EVENT_REMOVE_REQUEST_SEARCH_GIP,
      closeEventRemoveRequestSearchGip
    );

    builder.addCase(C.SHOW_EVENT_SEARCH_GIP, showEventSearchProjectGip);
    builder.addCase(C.CLOSE_EVENT_SEARCH_GIP, closeEventSearchProjectGip);

    // closeConflictDeleteGip
    builder.addCase(
      C.SHOW_EVENT_CLOSE_CONFLICT,
      showEventCloseDeleteGipConflictReducer
    );
    builder.addCase(
      C.CLOSE_EVENT_CLOSE_CONFLICT,
      closeEventCloseDeleteGipConflictReducer
    );

    // compareGIP
    builder.addCase(C.SHOW_EVENT_COMPARE_GIP, showEventCompareGipReducer);
    builder.addCase(C.CLOSE_EVENT_COMPARE_GIP, closeEventCompareGipReducer);

    // deleteProject
    builder.addCase(C.SHOW_EVENT_DELETE_PROJECT, showEventDeleteProject);
    builder.addCase(C.CLOSE_EVENT_DELETE_PROJECT, closeEventDeleteProject);

    builder.addCase(C.SHOW_EVENT_JOIN_USER_FIRM, showEventJoinUserFirm);
    builder.addCase(C.CLOSE_EVENT_JOIN_USER_FIRM, closeEventJoinUserFirm);

    builder.addCase(C.SHOW_EVENT_JOIN_USER_PROJECT, showEventJoinUserProject);
    builder.addCase(C.CLOSE_EVENT_JOIN_USER_PROJECT, closeEventJoinUserProject);

    // registration show agreement
    builder.addCase(C.SHOW_EVENT_USER_AGREEMENT, showEventUserAgreement);
    builder.addCase(C.CLOSE_EVENT_USER_AGREEMENT, closeEventUserAgreement);

    // registration show policy
    builder.addCase(C.SHOW_EVENT_USER_POLICY, showEventUserPolicy);
    builder.addCase(C.CLOSE_EVENT_USER_POLICY, closeEventUserPolicy);

    // organisation user role change
    builder.addCase(C.SHOW_EVENT_USER_ROLE, showEventChangeUserRole);
    builder.addCase(C.CLOSE_EVENT_USER_ROLE, closeEventChangeUserRole);
    builder.addCase(C.EVENT_SET_USER_ROLE_DATA, (state, action: any) =>
      setEventChangeUserRole(state, action)
    );

    // organisation task change
    builder.addCase(
      C.SHOW_EVENT_EDIT_ORGANISATION_TASK,
      showEventEditOrganisationTask
    );
    builder.addCase(
      C.CLOSE_EVENT_EDIT_ORGANISATION_TASK,
      closeEventEditOrganisationTask
    );
    builder.addCase(C.EVENT_SET_EDIT_ORGANISATION_TASK, (state, action: any) =>
      setEventEditOrganisationTask(state, action)
    );

    // organisation bank account change
    builder.addCase(C.SHOW_EVENT_EDIT_BANK_ACCOUNT, showEventEditBankAccount);
    builder.addCase(C.CLOSE_EVENT_EDIT_BANK_ACCOUNT, closeEventEditBankAccount);
    builder.addCase(C.EVENT_SET_EDIT_BANK_ACCOUNT, (state, action: any) =>
      setEventEditBankAccount(state, action)
    );

    // organisation staff send money
    builder.addCase(C.SHOW_EVENT_SEND_MONEY, showEventSendMoney);
    builder.addCase(C.CLOSE_EVENT_SEND_MONEY, closeEventSendMoney);
    builder.addCase(C.EVENT_SET_SEND_MONEY, (state, action: any) =>
      setEventSendMoney(state, action)
    );

    // organisation user join request
    builder.addCase(C.SHOW_EVENT_USER_JOIN, showEventUserJoin);
    builder.addCase(C.CLOSE_EVENT_USER_JOIN, closeEventUserJoin);
    builder.addCase(C.EVENT_SET_USER_JOIN, (state, action: any) =>
      setEventUserJoin(state, action)
    );

    // organisation join request
    builder.addCase(C.SHOW_EVENT_REQUEST_JOIN, showEventEditRequestJoin);
    builder.addCase(C.CLOSE_EVENT_REQUEST_JOIN, closeEventEditRequestJoin);
    builder.addCase(C.EVENT_SET_REQUEST_JOIN, (state, action: any) =>
      setEventEditRequestJoin(state, action)
    );

    builder.addCase(
      C.SHOW_EVENT_USER_JOIN_AT_USER_PAGE,
      showEventUserAtUserPage
    );
    builder.addCase(
      C.CLOSE_EVENT_USER_JOIN_AT_USER_PAGE,
      closeEventUserAtUserPage
    );
    builder.addCase(C.EVENT_SET_USER_JOIN_AT_USER_PAGE, (state, action: any) =>
      setEventUserAtUserPage(state, action)
    );

    builder.addCase(C.SHOW_CREATE_TEAM, showEventCreateTeam);
    builder.addCase(C.CLOSE_CREATE_TEAM, closeEventCreateTeam);
    builder.addCase(C.EVENT_SET_CREATE_TEAM, (state, action: any) =>
      setEventCreateTeam(state, action)
    );

    builder.addCase(C.SHOW_MANAGE_TEAMMATE, showEventManageTeammate);
    builder.addCase(C.CLOSE_MANAGE_TEAMMATE, closeEventManageTeammate);
    builder.addCase(C.EVENT_SET_MANAGE_TEAMMATE, (state, action: any) =>
      setEventManageTeammate(state, action)
    );

    builder.addCase(C.SHOW_INVITE_TO_TEAM, showEventInviteToTeam);
    builder.addCase(C.CLOSE_INVITE_TO_TEAM, closeEventInviteToTeam);
    builder.addCase(C.EVENT_SET_INVITE_TO_TEAM, (state, action: any) =>
      setEventUserInviteToTeam(state, action)
    );

    builder.addCase(C.SHOW_VIEW_PROJECT, showEventViewProject);
    builder.addCase(C.CLOSE_VIEW_PROJECT, closeEventViewProject);
  }
);

export const selectorEEPublishTaskIsShow = (state: StateType): boolean =>
  state.eventEmitter.eventPublishTask.isShow;

export const selectorEEPublishTaskData = (state: StateType): any =>
  state.eventEmitter.eventPublishTask.data;

export const selectorUnpublishTaskId = (state: StateType) =>
  state.eventEmitter.eventUnPublishTask.data.taskId;
