import React, { memo } from "react";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { ProjectProcessesType } from "src/types/api/responseTypes/apiDocumentsType";
import { DocumentsProcessDocs } from "src/FSD/widgets/v2/project/Documents/ui/DocumentsProcessDocs";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectDocument,
  setToggleProcess,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import styles from "./DocumentProcess.module.scss";

interface DocumentProcessProps {
  name: string;
  processes: ProjectProcessesType[];
  index: number;
}

export const DocumentProcess = memo(
  ({ name, processes, index }: DocumentProcessProps) => {
    const dispatch = useAppDispatch();
    const { process } = useAppSelector(getProjectDocument);
    function handleToggleProcesses(index: number) {
      dispatch(setToggleProcess(index));
    }

    return (
      <>
        <div
          className={styles.flex}
          onClick={() => handleToggleProcesses(index)}
        >
          <div className={styles.project}>
            <div className={styles.divider} />
            <p>{name}</p>
          </div>
          <ToggleAccordion secondRoot toggle={process[index]} />
        </div>
        {process[index] &&
          processes.map((processes, i) => (
            <DocumentsProcessDocs
              key={processes.id}
              el={processes}
              index={index + i}
            />
          ))}
      </>
    );
  }
);
