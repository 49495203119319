import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { providersPageInitialState } from "./providersPageInitialState";
import { createProvidersListFromApi } from "./createData/createData";
import C from "./constants";
import { StateType } from "../../../../initialState";
import {
  ApiProviderPageDataType,
  ApiUserIndexPageDataType,
} from "../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const providersPageReducer = createReducer(
  providersPageInitialState,
  (builder) => {
    /*
        Установка значений на страницу поставщиков    
     */

    builder.addCase(
      C.SET_PROVIDER_PAGE_DATA,
      (state, action: PayloadAction<ApiProviderPageDataType>) => {
        return {
          ...state,
          users: createProvidersListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorProvidersList = (state: StateType) =>
  state.providersPage.users;

export const selectorProvidersMeta = (state: StateType) =>
  state.providersPage.meta;

export const setProviders = (
  data: ApiProviderPageDataType,
  meta: ApiUserIndexPageDataType["meta"]
) => ({
  type: C.SET_PROVIDER_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
