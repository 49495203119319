import { ReactNode } from "react";
import styles from "./TabWarning.module.scss";

type Props = {
  children: ReactNode;
};

export const TabWarning = ({ children }: Props) => {
  return <div className={styles.warning}>{children}</div>;
};
