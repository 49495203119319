import { forwardRef } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { remarkReasons } from "../model";

type Props = Omit<SelectProps, "label" | "options">;

export const SelectRemarkReason = forwardRef((props: Props, ref) => {
  const { value } = props;

  const optionValue =
    remarkReasons.find((option) => option.title === value) ?? null;

  return (
    <Select
      {...props}
      ref={ref}
      disableClear
      value={optionValue}
      label="Статус замечания"
      options={remarkReasons}
    />
  );
});
