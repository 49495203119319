import React from "react";
import { IInputProps } from "react-dropzone-uploader/dist/Dropzone";
import classNames from "classnames";
import style from "../style.module.scss";
import { UploaderFolderSvg } from "../../../newUI/SVG";

interface Props extends IInputProps {
  id?: string | null;
  newDesign?: boolean;
}

export const UploaderInput = ({
  accept,
  onFiles,
  getFilesFromEvent,
  id,
  newDesign,
}: Props) => {
  return (
    <label className={style.inputLabel}>
      <div
        className={classNames(
          style.inputContainer,
          newDesign && style.newDesign
        )}
      >
        <UploaderFolderSvg />
        <label className={style.inputLabelUpload} htmlFor={`upload_${id}`}>
          <span className={style.inputLabelLoadText}>Загрузите</span>
          <span className={style.inputLabelOrText}>или</span>
          <span>перетащите файлы</span>
        </label>
      </div>
      <input
        className={style.input}
        type="file"
        id={`upload_${id}`}
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </label>
  );
};
