import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList/FilesList";

import { SelectRemarkReason } from "src/FSD/features/v2/SelectRemarkReason";
import { useExpertiseRemarkRowComplete } from "../lib/hooks";

type Props = {
  id: number;
  header: string;
};

export const ExpertiseRemarkRowCompleteForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    control,
    errors,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useExpertiseRemarkRowComplete({
    id,
    defaultValues: {
      remarkStatus: null,
      responseToRemark: "",
      documentationsWithRemarksRecieved: "",
      status: null,
    },
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            value={taskData!.remarkRow!.text}
            label="Замечание"
            multiline
            disabled
          />
          <FilesList
            fileLinks={taskData!.remarkRow!.remark!.files ?? []}
            label="Файлы замечания"
          />
          <Controller
            name="remarkStatus"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectRemarkReason
                value={value} // передается строка
                changeHandler={(_, newValue) => {
                  onChange(newValue.title);
                }}
              />
            )}
          />
          <TextField
            {...register("responseToRemark")}
            label="Ответ на замечание"
            multiline
            error={Boolean(errors.responseToRemark)}
            helperText={errors.responseToRemark?.message}
          />
          <TextField
            {...register("documentationsWithRemarksRecieved")}
            label="Документация, в которую внесены изменения"
            multiline
            error={Boolean(errors.documentationsWithRemarksRecieved)}
            helperText={errors.documentationsWithRemarksRecieved?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Отправить на проверку
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
