import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { fetchRemoveExecutors } from "../services/fetchRemoveExecutors";
import { config } from "../../../../../app.cofig";
import { initialStateExecutors } from "../types/projectProcessRemoveExecutorsType";

type InitialState = {
  isLoad: boolean;
  executors: initialStateExecutors[] | undefined;
};

const initialState: InitialState = {
  isLoad: false,
  executors: undefined,
};

const projectProcessRemoveExecutors = createSlice({
  name: "projectProcessRemoveExecutors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRemoveExecutors.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchRemoveExecutors.fulfilled, (state, action) => {
      state.isLoad = false;
      state.executors = action.payload.map((el: any) => {
        return {
          avatar:
            el.executor?.photo && `${config.localDomain}${el.executor.photo}`,
          link: `/user/${el.executor?.id}`,
          name: `${el.executor.surname} ${el.executor.name} ${el.executor?.patronymic}`,
          role: "Исполнитель",
          id: el.id,
          executorId: el.executor.id,
          rating: el.executor?.rating?.executor,
          jobDuration: Math.floor(el.executor?.job_duration / 365),
          firm: el.executorUserType?.listName,
          date_complete: moment(
            el.date_complete,
            "DD.MM.YYYY HH:mm:ss Z"
          ).format("DD.MM.YYYY"),
          date_create: moment(el.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
            "DD.MM.YYYY"
          ),
          can: el.can.feedbackToExecutor,
        };
      });
    });
  },
});
export default projectProcessRemoveExecutors;
