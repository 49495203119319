import { useEffect, useState } from "react";
import { throttle } from "lodash";

export type UseWindowSizeReturnProps = {
  width: number | undefined;
  height: number | undefined;
};

export function useWindowSize(): UseWindowSizeReturnProps {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize

    function handleResize() {
      // Set window width/height to state

      // @ts-ignore
      setWindowSize({
        // @ts-ignore
        width: window.innerWidth,
        // @ts-ignore
        height: window.innerHeight,
      });
    }

    // Add event listener

    window.addEventListener("resize", throttle(handleResize, 300));

    // Call handler right away so state gets updated with initial window size

    handleResize();

    // Remove event listener on cleanup

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
