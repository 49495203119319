import { RootState } from "../../../store";

export const getTaskCompleteIsLoad = (state: RootState) =>
  state.taskComplete.isLoadTaskComplete;

export const getTaskCompleteSuccess = (state: RootState) =>
  state.taskComplete.completeSuccess;

export const getTaskCompleteError422 = (state: RootState) =>
  state.taskComplete.error422;
