import {
  BlogPageStateTypes,
  PostPageStateTypes,
} from "../../../../types/stateTypes/postsStateTypes";

export const postPageInitialState: PostPageStateTypes = {
  post: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};

export const blogPageInitialState: BlogPageStateTypes = {
  library: [],
  platform: [],
  firstFace: [],
  media: [],
  project: {
    name: "",
    processPublishedCost: "",
    date_end: "",
    id: 0,
    bannerUrl: "",
  },
};
