import { KanbanType } from "..";
import { config } from "../../../../app.cofig";
import {
  httpRequestDelete,
  httpRequestPatch,
  httpRequestPost,
} from "../../../../app/services/api/api";
import { httpRequestGet } from "../../../shared/lib/services/api/httpRequests";

export const ApiGetKanbanView = (
  userTypeId: number,
  filterSearchParams: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/kanban/view?userTypeId=${userTypeId}&${filterSearchParams}`
  );
};

export const ApiGetMultilevelProjectsByUserTypeId = (
  userTypeId: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v2/user-type/get-my-projects?userTypeId=${userTypeId}`
  );
};

export const ApiGetGroupsByFirmIdAndPartGroupId = (
  firmId: number,
  partGroupsUrl: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/team/index?TeamSearch[firm_id]=${firmId}&${partGroupsUrl}`
  );
};

export const ApiGetUsersListByUserTypeId = (
  searchParams: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/kanban/get-user-list?${searchParams}`
  );
};

export const ApiGetWorkFlowList = (): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/kanban/get-wf-list`);
};

export const ApiCreateFreeTask = (
  data: KanbanType.FreeTaskFormedFields
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/create-free-task`,
    {},
    data
  );
};

export const ApiUpdateKanbanTask = (
  taskId: number,
  data: Record<string, number>
): Promise<Response> => {
  return httpRequestPatch(
    `${config.localDomain}/v1/kanban/update?taskId=${taskId}`,
    {},
    data
  );
};

export const ApiDeleteKanbanTask = (taskId: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/kanban/delete?taskId=${taskId}`
  );
};

export const ApiKanbanComplete = (
  taskId: number,
  data: Record<string, number | string>
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/kanban/complete?id=${taskId}`,
    {},
    data
  );
};
