export const ProcessWorkTabs = {
  applications: {
    good: "good",
    bad: "bad",
  },
  executionTask: {
    customerAlign: "customerAlign",
    workTask: "workTask",
  },
};
