import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  completeSubTask,
  fetchGipCancelSearch,
  fetchGipHelperApplicateInvite,
  fetchGipHelperApplicationDelete,
  fetchGipHelperDelegate,
  fetchGipHelperDelete,
  fetchGipHelperInvite,
  fetchGipHelpersList,
  fetchGipRemove,
  fetchGipRemoveComplete,
  fetchGipRemoveRequest,
  fetchGipRequest,
  fetchGipSearch,
  fetchProjectDelete,
  fetchProjectPause,
  fetchProjectUnPause,
  fetchProjectView,
  fetchProjectViewSearchGip,
  fetchRecalculationsRelations,
  GipHelperListData,
  taskBackToWork,
} from "./thunks";
import { RootState } from "../../store";
import {
  createData,
  createGipHelpers,
  ProjectViewData,
} from "./createData/createData";
import { ApiGipType } from "../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../app.cofig";

type InitialStateProjectView = {
  project: ProjectViewData | null;
  isLoad: boolean;
  isLoadGip: boolean;
  isLoadSearchGip: boolean;
  isLoadRemoveGip: boolean;
  isLoadCompleteRemoveGip: boolean;
  isLoadRequestGip: boolean;
  searchGipStatus: string;
  cancelSearchGip: boolean;
  requestGip: boolean;
  gipTypes: ApiGipType | null;
  cancelRequestGip: boolean;
  removeGip: boolean;
  removeGipComplete: boolean;
  error: any;
  gipHelpersError: any;
  gipHelpersList: GipHelperListData[] | null;
  gipHelperInvite: boolean;
  isLoadGipHelperInvite: boolean;
  gipHelperDelete: boolean;
  actually: boolean;
  isLoadDelete: boolean;
  isLoadGipHelperDelete: boolean;
  isLoadGipHelperApplication: boolean;
  isLoadGipHelperAppInvite: boolean;
  isLoadGipHelperDelegate: boolean;
  // для подзадач в групповой документации
  pending: {
    subTaskCompletion: number | null;
    subTaskBackToWork: boolean;
    Completion: number | null;
  };
};
const initialState: InitialStateProjectView = {
  project: null,
  searchGipStatus: "",
  cancelSearchGip: false,
  requestGip: false,
  cancelRequestGip: false,
  removeGip: false,
  removeGipComplete: false,
  isLoad: true,
  isLoadSearchGip: false,
  isLoadRemoveGip: false,
  isLoadCompleteRemoveGip: false,
  isLoadRequestGip: false,
  isLoadGip: false,
  gipTypes: null,
  error: null,
  gipHelpersError: null,
  gipHelpersList: null,
  gipHelperInvite: false,
  isLoadGipHelperInvite: false,
  gipHelperDelete: false,
  actually: false,
  isLoadDelete: false,
  isLoadGipHelperDelete: false,
  isLoadGipHelperApplication: false,
  isLoadGipHelperAppInvite: false,
  isLoadGipHelperDelegate: false,
  pending: {
    subTaskCompletion: null,
    subTaskBackToWork: false,
    Completion: null,
  },
};

const projectViewSlice = createSlice({
  name: "projectViewSlice",
  initialState,
  reducers: {
    setGipTypes(state, action: PayloadAction<ApiGipType>) {
      state.gipTypes = action.payload;
    },
    setCheckInput(state, action: PayloadAction<boolean>) {
      state.actually = action.payload;
    },
    setError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectView.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectView.fulfilled, (state, action) => {
      state.project = createData(action.payload);
      state.isLoad = false;
      state.cancelSearchGip = false;
      state.searchGipStatus = "";
      state.requestGip = false;
      state.cancelRequestGip = false;
      state.removeGip = false;
      state.removeGipComplete = false;
    });
    builder.addCase(fetchProjectView.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
    });
    builder.addCase(fetchProjectDelete.pending, (state) => {
      state.isLoadDelete = true;
    });
    builder.addCase(fetchProjectDelete.fulfilled, (state) => {
      state.isLoadDelete = false;
      window.location.href = `${config.localDomain}/user/new-dashboard`;
    });
    builder.addCase(fetchProjectDelete.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
    });
    builder.addCase(fetchProjectPause.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchProjectPause.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
    });
    builder.addCase(fetchProjectUnPause.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchProjectUnPause.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
    });
    builder.addCase(fetchRecalculationsRelations.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchRecalculationsRelations.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
    });
    builder.addCase(fetchProjectViewSearchGip.pending, (state) => {
      state.isLoadGip = true;
    });
    builder.addCase(fetchProjectViewSearchGip.fulfilled, (state, action) => {
      state.cancelSearchGip = false;
      state.searchGipStatus = "";
      state.requestGip = false;
      state.cancelRequestGip = false;
      state.removeGip = false;
      state.removeGipComplete = false;
      state.project = createData(action.payload);
      state.isLoadGip = false;
      state.gipHelperInvite = false;
      state.gipHelperDelete = false;
    });
    builder.addCase(fetchProjectViewSearchGip.rejected, (state, action) => {
      state.isLoadGip = false;
      state.error = action.payload;
      state.gipHelperInvite = false;
      state.gipHelperDelete = false;
    });
    builder.addCase(fetchGipSearch.pending, (state) => {
      state.isLoadSearchGip = true;
      state.searchGipStatus = "";
    });
    builder.addCase(fetchGipSearch.fulfilled, (state, action) => {
      state.searchGipStatus = action.payload
        ? "Гип назначен"
        : "Начат поиск ГИП";
      state.cancelSearchGip = false;
      state.isLoadSearchGip = false;
      // Поиск ГИП
    });
    builder.addCase(fetchGipSearch.rejected, (state, action) => {
      state.error = action.payload;
      state.cancelSearchGip = false;
      state.isLoadSearchGip = false;
      state.searchGipStatus = "";
    });
    builder.addCase(fetchGipCancelSearch.pending, (state) => {
      state.cancelSearchGip = false;
    });
    builder.addCase(fetchGipCancelSearch.fulfilled, (state) => {
      // Отмена поиска ГИП
      state.cancelSearchGip = true;
      state.searchGipStatus = "";
    });
    builder.addCase(fetchGipCancelSearch.rejected, (state, action) => {
      state.error = action.payload;
      state.cancelSearchGip = false;
      state.searchGipStatus = "";
    });
    builder.addCase(fetchGipRequest.pending, (state) => {
      state.isLoadRequestGip = true;
      state.requestGip = false;
    });
    builder.addCase(fetchGipRequest.fulfilled, (state) => {
      // заявиться ГИПом на проект
      state.requestGip = true;
      state.cancelRequestGip = false;
      state.isLoadRequestGip = false;
    });
    builder.addCase(fetchGipRequest.rejected, (state, action) => {
      state.error = action.payload;
      state.cancelRequestGip = false;
      state.requestGip = false;
      state.isLoadRequestGip = false;
    });
    builder.addCase(fetchGipRemoveRequest.pending, (state) => {
      state.cancelRequestGip = false;
    });
    builder.addCase(fetchGipRemoveRequest.fulfilled, (state) => {
      // Отменить заявку ГИПом на проект
      state.cancelRequestGip = true;
      state.requestGip = false;
    });
    builder.addCase(fetchGipRemoveRequest.rejected, (state, action) => {
      state.error = action.payload;
      state.cancelRequestGip = false;
      state.requestGip = false;
    });
    builder.addCase(fetchGipRemove.pending, (state) => {
      state.removeGip = false;
      state.isLoadRemoveGip = true;
    });
    builder.addCase(fetchGipRemove.fulfilled, (state) => {
      // Снять
      state.removeGip = true;
      state.removeGipComplete = false;
      state.isLoadRemoveGip = false;
    });
    builder.addCase(fetchGipRemove.rejected, (state, action) => {
      state.error = action.payload;
      state.removeGipComplete = false;
      state.removeGip = false;
      state.isLoadRemoveGip = false;
    });
    builder.addCase(fetchGipRemoveComplete.pending, (state) => {
      state.isLoadCompleteRemoveGip = true;
      state.removeGipComplete = false;
    });
    builder.addCase(fetchGipRemoveComplete.fulfilled, (state) => {
      // Рассмотреть
      state.removeGipComplete = true;
      state.isLoadCompleteRemoveGip = false;
      state.removeGip = false;
    });
    builder.addCase(fetchGipRemoveComplete.rejected, (state, action) => {
      state.error = action.payload;
      state.removeGip = false;
      state.removeGipComplete = false;
      state.isLoadCompleteRemoveGip = false;
    });
    builder.addCase(fetchGipHelpersList.fulfilled, (state, action) => {
      state.gipHelpersList = createGipHelpers(action.payload);
    });
    builder.addCase(fetchGipHelpersList.rejected, (state, action) => {
      state.gipHelpersError = action.payload;
    });
    builder.addCase(fetchGipHelperInvite.pending, (state) => {
      state.isLoadGipHelperInvite = true;
      state.gipHelperInvite = false;
    });
    builder.addCase(fetchGipHelperInvite.fulfilled, (state) => {
      state.gipHelperInvite = true;
      state.isLoadGipHelperInvite = false;
    });
    builder.addCase(fetchGipHelperInvite.rejected, (state, action) => {
      state.error = action.payload;
      state.gipHelperInvite = false;
      state.isLoadGipHelperInvite = false;
    });
    builder.addCase(fetchGipHelperDelete.pending, (state) => {
      state.isLoadGipHelperDelete = true;
    });
    builder.addCase(fetchGipHelperDelete.fulfilled, (state) => {
      state.gipHelperDelete = true;
      state.isLoadGipHelperDelete = false;
    });
    builder.addCase(fetchGipHelperDelete.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoadGipHelperDelete = false;
    });
    builder.addCase(fetchGipHelperApplicateInvite.pending, (state) => {
      state.isLoadGipHelperAppInvite = true;
    });
    builder.addCase(fetchGipHelperApplicateInvite.fulfilled, (state) => {
      state.isLoadGipHelperAppInvite = false;
    });
    builder.addCase(fetchGipHelperApplicateInvite.rejected, (state) => {
      state.isLoadGipHelperAppInvite = false;
    });
    builder.addCase(fetchGipHelperApplicationDelete.pending, (state) => {
      state.isLoadGipHelperApplication = true;
    });
    builder.addCase(fetchGipHelperApplicationDelete.fulfilled, (state) => {
      state.isLoadGipHelperApplication = false;
    });
    builder.addCase(
      fetchGipHelperApplicationDelete.rejected,
      (state, action) => {
        state.error = action.payload;
        state.isLoadGipHelperApplication = false;
      }
    );
    builder.addCase(fetchGipHelperDelegate.pending, (state) => {
      state.isLoadGipHelperDelegate = true;
    });
    builder.addCase(fetchGipHelperDelegate.fulfilled, (state) => {
      state.isLoadGipHelperDelegate = false;
    });
    builder.addCase(fetchGipHelperDelegate.rejected, (state, action) => {
      state.isLoadGipHelperDelegate = false;
      state.error = action.payload;
    });

    // для подзадач в готовой документации
    builder.addCase(completeSubTask.pending, (state, action) => {
      state.pending.subTaskCompletion = action.meta.arg.status;
    });
    builder.addCase(completeSubTask.fulfilled, (state) => {
      state.pending.subTaskCompletion = null;
    });
    builder.addCase(completeSubTask.rejected, (state) => {
      state.pending.subTaskCompletion = null;
    });

    builder.addCase(taskBackToWork.pending, (state) => {
      state.pending.subTaskBackToWork = true;
    });
    builder.addCase(taskBackToWork.fulfilled, (state) => {
      state.pending.subTaskBackToWork = false;
    });
    builder.addCase(taskBackToWork.rejected, (state) => {
      state.pending.subTaskBackToWork = false;
    });
  },
});
export const { setGipTypes, setCheckInput, setError } =
  projectViewSlice.actions;
export const getProjectViewData = (state: RootState) => state.projectView;
export default projectViewSlice.reducer;
