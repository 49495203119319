import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  filesAreRequired,
  stringMaxLengthRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const executeCompleteSchema = Yup.object().shape({
  files: Yup.array().when("status", {
    is: (status) => status === Statuses.IS_DONE,
    then: filesAreRequired,
  }),
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.IS_REJECT,
    then: stringMaxLengthRequired("Комментарий", 2000),
  }),
});
