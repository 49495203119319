import React from "react";
import classNames from "classnames";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getProjectProcessApplicationsMessages } from "src/app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import {
  Header,
  Warnings,
  Deadlines,
  Payment,
  Executor,
  Requirements,
  SourcesFiles,
} from "./ui";
import styles from "./Info.module.scss";

export const Info = () => {
  const infoData = useAppSelector(getProjectProcessInfoData);
  const applicationWarning = useAppSelector(
    getProjectProcessApplicationsMessages
  );

  return (
    <div className={classNames(styles.info)}>
      <Header />
      {infoData!.warning && (
        <Warnings
          warnings={infoData!.warning}
          applicationWarning={applicationWarning}
          classname={classNames(styles.newWarningCard)}
        />
      )}
      <div className={styles.object}>
        <div className={styles.object_row}>
          <Deadlines />
          <Payment />
        </div>
        <div className={styles.object_row}>
          <Executor />
          <Requirements />
        </div>
      </div>
      {infoData!.files && infoData!.files.length > 0 && (
        <SourcesFiles files={infoData!.files} />
      )}
    </div>
  );
};
