import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiSchedulePlanResponses } from "../../model/responseTypes";

export type TParams = RequestParams<{
  id: number;
}>;

export const view = (builder: QueryBuilder) =>
  builder.query<ApiSchedulePlanResponses.View, TParams>({
    query: ({ id }) => ({
      url: `v1/schedule-plan/view`,
      params: {
        projectId: id,
      },
    }),
  });
