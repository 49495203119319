import React, { MouseEvent, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { newIconBtnSx } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectComposition } from "src/app/feature/ProjectView/Contents/getComposition";
import {
  fetchProjectComposition,
  formAgain,
} from "src/app/feature/ProjectView/Contents/thunks";
import { config } from "src/app.cofig";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import { ContentDownloadSvg } from "../CompositionSvg";
import styles from "./Header.module.scss";

const TYPES: Record<string, string> = {
  project: "&types[0]=2&types[1]=1&types[2]=6",
  work: "&types[0]=3",
};

interface HeaderProps {
  showFormAgain?: boolean;
  id: string | undefined;
  activeTab: string | number;
}

export const Header = ({ showFormAgain, id, activeTab }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const { pending } = useAppSelector(getProjectComposition);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);
  const tab = activeTab;
  const handleFormAgain = async () => {
    if (id) {
      await dispatch(formAgain(+id));
      await dispatch(fetchProjectComposition(+id));
    }
  };

  const handleOpenDownloadPrompts = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DownloadMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        disableRipple
        onClick={() => {
          handleClose();
        }}
      >
        <TextLink
          url={`${config.localDomain}/project-composition/to-pdf?id=${id}${TYPES[tab]}`}
          redirect
          target="_blank"
          className={styles.link}
        >
          PDF
        </TextLink>
      </MenuItem>
      <MenuItem
        disableRipple
        onClick={() => {
          handleClose();
        }}
      >
        <TextLink
          url={`${config.localDomain}/project-composition/to-xlsx?id=${id}${TYPES[tab]}`}
          redirect
          target="_blank"
          className={styles.link}
        >
          XLS
        </TextLink>
      </MenuItem>
    </Menu>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {showFormAgain && (
          <Button
            onClick={handleFormAgain}
            color="primary"
            disabled={pending.formAgain}
          >
            Сформировать
          </Button>
        )}
        <IconButton
          onClick={handleOpenDownloadPrompts}
          sx={newIconBtnSx}
          className={styles.download}
        >
          <ContentDownloadSvg />
        </IconButton>
        <DownloadMenu />
      </div>
    </ClickAwayListener>
  );
};
