import React, { memo } from "react";
import { useSelector } from "react-redux";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/app.cofig";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { palette } from "src/styles/restyle";
import styles from "./InfoCustomer.module.scss";

const InfoExecutor = memo(() => {
  const { project } = useSelector(getProjectViewData);

  if (!project) {
    return null;
  }

  const { author, authorPremium } = project;

  return (
    <div className={styles.customer}>
      <div className={styles.customer_text}>
        <h1>Заказчик</h1>
        <TextLink url={`/user/${author.id}`}>
          <p className={styles.customer_name}>{author.name}</p>
        </TextLink>
        <span>{author.type}</span>
        {authorPremium && (
          <div className={styles.premium}>
            <span>Подписка: ПИРС</span>
            <span
              style={{ fontWeight: 700 }}
            >{`PRO (до ${authorPremium.date_end})`}</span>
          </div>
        )}
      </div>
      <div className={styles.customer_avatar}>
        <NewAvatar
          photoUrl={`${config.localDomain}${author.photo}`}
          rating={author.rating}
          jobDuration={author.jobDuration}
          borderColor={palette.signalOrange}
        />
        <div className={styles.customer_avatar_vector} />
      </div>
    </div>
  );
});

export default InfoExecutor;
