import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, TextField } from "@mui/material";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import classes from "./style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import {
  fetchGipRemove,
  fetchProjectViewSearchGip,
} from "../../../../../../app/feature/ProjectView/thunks";

type Inputs = {
  content: string;
};

const schema = Yup.object().shape({
  content: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

export const RemoveGip = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      StartForm: {
        content: dataValues.content,
      },
    };
    const { id } = params;
    if (id) {
      await dispatch(fetchGipRemove({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(id));
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={classes.removeGipForm}
      >
        <h1>Заявка на снятие ГИП проекта</h1>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          placeholder="Введите комментарий"
          label="Комментарий к заявке"
          className={classes.removeGipForm_textarea}
          {...register("content")}
        />
        {errors.content ? (
          <p className={classes.error}>{errors.content.message}</p>
        ) : null}
        <FormControl className={classes.removeGipForm_btn}>
          <CustomButton background={palette.blue} width={160} type="submit">
            Отправить
          </CustomButton>
        </FormControl>
      </form>
    </div>
  );
};
