import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import style from "../style.module.scss";
import { GraphRender } from "./GraphRender/GraphRender";
import { Header } from "./Header/Header";
import styles from "./GraphRender/GraphRender.module.scss";

export const BudgetGraph = () => {
  const {
    budget,
    today,
    priceFactCurrent,
    pricePlanCurrent,
    pricePlan,
    widthAll,
    colorAll,
    dateEndAll,
    dateStartAll,
  } = useSelector(getProjectGraphData);
  if (budget) {
    const allGraphRender = (
      <div className={styles.flex}>
        <p>Всего</p>
        <div className={styles.flexCol}>
          <div className={styles.parent}>
            <p>План</p>
            <div className={styles.container}>
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "0",
                }}
                className={styles.todayPlan}
              />
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "-25px",
                }}
                className={styles.shadow}
              />
              <div
                style={{
                  width: `${widthAll}%`,
                }}
                className={classNames(styles.plan, styles.allPlan)}
              >
                <p>{pricePlanCurrent}</p>
                <p className={styles.price}>{pricePlan}₽</p>
                <div className={styles.date}>
                  <p>{`c ${dateStartAll}`}</p>
                  <p>{`по ${dateEndAll}`}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.parent}>
            <p>Факт</p>
            <div className={styles.container}>
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "0",
                }}
                className={styles.todayLast}
              />
              <div
                style={{
                  marginLeft: `${today?.div1?.marginLeft}%`,
                  top: "0",
                }}
                className={styles.shadowFact}
              />
              <div
                style={{
                  width: `${today?.div1?.marginLeft}%`,
                  background: `${colorAll}`,
                }}
                className={classNames(
                  styles.fact,
                  today?.div1?.marginLeft !== 100 && styles.factEnd
                )}
              >
                <p>{priceFactCurrent}</p>
              </div>
            </div>
            <div className={styles.date} />
          </div>
        </div>
      </div>
    );

    return (
      <div className={style.basic}>
        <Header />
        <div className={style.graphBlockBudget}>
          {allGraphRender}
          {budget.map(([name, task]: any, index: number) => (
            <GraphRender
              key={index}
              budget={task}
              name={name}
              today={today}
              last={index === budget.length - 1}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
};
