import {
  projectProcessApi,
  useProjectProcessDepositFundsMutation,
} from "src/FSD/entities/projectProcess/api";

type UseModalProps = {
  processId: number;
};

export const useDepositFunds = ({ processId }: UseModalProps) => {
  const [depositFunds] = useProjectProcessDepositFundsMutation();
  const [canCheck] = projectProcessApi.useLazyProjectProcessCanCheckQuery();

  const transferDepositFunds = async () => {
    try {
      await depositFunds({ id: processId });
      await canCheck({ id: processId });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    transferDepositFunds,
  } as const;
};
