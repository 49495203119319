import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type CounterProps = ComponentPropsType<{
  count: number;
  /**
   * Расцветка
   */
  variantStyle?: "orange" | "";
  /**
   * Дополнительный класс
   */
  url?: string;
}>;

export const Counter: React.FC<CounterProps> = ({
  count = 0,
  variant = "",
  variantStyle = "",
  url = "",
}) => {
  const styleLabel = variantStyle && style[variantStyle];

  return (
    <span
      onClick={() => {
        window.location.pathname = url;
      }}
      title={"Уведомления"}
      className={classNames(variant, style.wrapper)}
    >
      <div className={classNames(style.counter, styleLabel, variant)}>
        <span>{count}</span>
      </div>
    </span>
  );
};
