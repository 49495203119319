import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление формой приглашения человека на проект
 */

export const showEventJoinUserProject = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventJoinUserProject: {
      ...state.eventJoinUserProject,
      isShow: true,
    },
  };
};

export const closeEventJoinUserProject = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventJoinUserProject: {
    isShow: false,
    data: {
      ...state.eventJoinUserProject.data,
    },
  },
});

export const selectorEEJoinUserProject = (state: StateType) =>
  state.eventEmitter.eventJoinUserProject.isShow;
