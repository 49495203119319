import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { HeaderType } from "../../../types/stateTypes/header";
import { request } from "../../services/api/requestHandler";
import { ApiHeaderData } from "../../services/api/site/site";
import C from "./constants";
import { apiNavigationItemsType } from "../../../types/api/responseTypes/apiNavigation";
import { createItems } from "./createData";
import { StateType } from "../../initialState";
import { navigationsType } from "../../../types/stateTypes/navigation";

export const headerInitialState: HeaderType = {
  items: [],
};

export const headerReducer = createReducer(headerInitialState, (builder) => {
  /* 
    Установка значений хедера 
   */

  builder.addCase(
    C.SET_HEADER_ITEMS,
    (state, action: PayloadAction<apiNavigationItemsType>) => ({
      ...state,
      items: createItems(action.payload),
    })
  );

  /*
    Установка счетчика уведомлений
   */

  builder.addCase(
    C.SET_HEADER_NOTICE_COUNT,
    (state, action: PayloadAction<number>) => {
      return {
        ...state,
        items: state.items.map((item) =>
          item.url === "/notice/index"
            ? {
                ...item,
                info: {
                  ...item.info,
                  counter:
                    Number(action.payload) === 0
                      ? undefined
                      : Number(action.payload),
                },
              }
            : { ...item }
        ),
      };
    }
  );

  /*
    Установка счетчика сообщений
   */

  builder.addCase(
    C.SET_HEADER_MESSAGE_COUNT,
    (state, action: PayloadAction<number>) => {
      return {
        ...state,
        items: state.items.map((item) =>
          item.url === "/chat/all"
            ? {
                ...item,
                info: {
                  ...item.info,
                  counter:
                    Number(action.payload) === 0
                      ? undefined
                      : Number(action.payload),
                },
              }
            : { ...item }
        ),
      };
    }
  );
});

export const fetchHeaderItems =
  () =>
  (dispatch: Dispatch): void => {
    request(ApiHeaderData(), (res) =>
      dispatch({
        type: C.SET_HEADER_ITEMS,
        payload: res,
      })
    )(dispatch);
  };

export const setHeaderCountNotice = (dispatch: Dispatch) => (count: number) => {
  dispatch({
    type: C.SET_HEADER_NOTICE_COUNT,
    payload: count,
  });
};

export const setHeaderCountMessage =
  (dispatch: Dispatch) => (count: number) => {
    dispatch({
      type: C.SET_HEADER_MESSAGE_COUNT,
      payload: count,
    });
  };

export const selectorNavigation = (state: StateType): navigationsType => {
  return state.header.items;
};
