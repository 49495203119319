import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom";
import { getModalRootElement } from "../../utils/getModalRootElement";

type ModalWindowProps = {
  children?: ReactNode;
};

const ModalWindow: FC<ModalWindowProps> = ({ children }) => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;

  return <Modal>{children}</Modal>;
};

export default ModalWindow;
