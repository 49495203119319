import { MutableRefObject } from "react";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { TOriginalFilesRef } from "../hooks/useTechnicalSpecifications";

export const setOriginalFilesRef = (
  objectRef: MutableRefObject<TOriginalFilesRef>,
  files: TFile[]
) => {
  if (!objectRef.current.isFirstlyLoaded) {
    objectRef.current = {
      files,
      isFirstlyLoaded: true,
    };
  }
};
