import { Skeleton } from "@mui/material";

type Props = {
  count?: number;
  width?: string;
  height?: number;
  className?: string;
};

export const SkeletonList = ({
  count = 5,
  width = "100%",
  height = 150,
  className = "",
}: Props) => {
  const skeletonArray = Array.from(new Array(count));

  return (
    <>
      {skeletonArray.map((_, i) => (
        <Skeleton
          key={i}
          width={`${width}`}
          height={height}
          variant="rectangular"
          className={className}
        />
      ))}
    </>
  );
};
