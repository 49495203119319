import {
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { areArraysEqual } from "../../../../utils";
import { useAppSelector } from "../../../../../app/store";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";

const useFilter = (
  data: any,
  query: string,
  fulfilled: boolean,
  instance: any,
  setQuery: Dispatch<SetStateAction<string>>
) => {
  const { userType } = useAppSelector(getDashboardDestinations);
  const [ticked, setTicked] = useState<number[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const queryValue = event.target.value;
    setQuery(queryValue);
  };

  useEffect(() => {
    setQuery("");

    const ids = instance.filterByFulfilled(data, fulfilled, query);
    setTicked(ids);
  }, [fulfilled]);

  useEffect(() => {
    const ids = instance.filterByQuery(data, query, fulfilled);

    setTicked(ids);
  }, [query, fulfilled]);

  useEffect(() => {
    if (query || fulfilled) {
      return;
    }

    if (instance?.getIdsWithApplications) {
      const ids = instance.getIdsWithApplications(data);
      setTicked(ids);
    }
  }, [query, fulfilled]);

  useEffect(() => {
    const ids = instance.filterByFulfilled(data, fulfilled, query);

    if (!areArraysEqual(ids, ticked)) {
      setTicked(ids);
    }
  }, [data]);

  useEffect(() => {
    setQuery("");
  }, [userType]);

  return { ticked, handleChange } as const;
};

export default useFilter;
