import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { palette } from "src/styles/restyle";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { getProjectProcessIsLoadCreateStop } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadCreateStop";
import { getProjectProcessTaskCompleteIsLoad } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessStopped";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { Stop } from "../../modals/Stop/Stop";
import styles from "./Header.module.scss";

export const Header = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const isLoadCreateStop = useAppSelector(getProjectProcessIsLoadCreateStop);
  const isLoadTaskComplete = useAppSelector(
    getProjectProcessTaskCompleteIsLoad
  );
  const [stop, setStop] = useState(false);

  const stopModalClose = useCallback((state: boolean) => {
    setStop(state);
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  return (
    <>
      {infoData.can.stop && (
        <NewModal
          title={"Приостановка выполнения раздела"}
          active={stop}
          setActive={stopModalClose}
          isLoad={isLoadCreateStop || isLoadTaskComplete}
        >
          <Stop modalClose={stopModalClose} />
        </NewModal>
      )}
      <div className={classNames(styles.container, styles.basis)}>
        <div>
          <h1>{infoData.projectPart.name}</h1>
          <div className={styles.stage}>{infoData.projectPart.type.value}</div>
        </div>
        {infoData.can.stop ? (
          <CustomButton
            onClick={() => setStop(true)}
            width={238}
            height={32}
            fontSize={16}
            borderRadius={4}
            background={palette.oceanic}
            color={palette.midnightBlack}
          >
            Приостановить работу
          </CustomButton>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
