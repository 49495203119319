import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Wrapper } from "../../UI/templates";
import style from "./style.module.scss";
import { useTitle } from "../../hooks/useTitle";
import {
  selectorPostItems,
  selectorPostMeta,
  setPostItems,
} from "../../../app/feature/indexPages/postPage/postPage";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../types/other/pages/formationOfParameters";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetPostsList } from "../../../app/services/api/posts/posts";
import { createPaginationMeta } from "../../../app/feature/indexPages/createData";
import { PostList } from "../../UI/components/indexPageLists/PostsList/PostsList";
import { Paginate } from "../../UI/components/Pagination/Pagination";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { SortButton } from "../../UI/components/Buttons/SortButton/SortButton";
import { createUrlSearchString } from "../../../app/services/url/createUrlSearchString";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { userIsAdmin } from "../../../app/services/user/userRoles";
import { PostSkeletonList } from "../../UI/components/indexPageLists/PostsList/PostSkeletonList";
import { LibrarySearchForm } from "./LibrarySearchForm";

export const Library = () => {
  useTitle("Библиотека");

  const theme = useSelector(selectorAppTheme);

  const isAdmin = userIsAdmin();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);

  const [searchString, setSearchString] = useState(location.search);
  const [currentFilters, setCurrentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({
    sort: "-date_publication",
  });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    posts: useSelector(selectorPostItems),
    meta: useSelector(selectorPostMeta),
  };

  const params = queryString.parse(location.search);

  useEffect(() => {
    // @ts-ignore
    setInitialSearchParams(params);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    navigate({
      search: searchString,
    });

    setLoad(true);
    if (searchString.length === 0) return;
    request(ApiGetPostsList(searchString), (res, _, headersValue) => {
      const meta = createPaginationMeta(headersValue);
      dispatch(setPostItems(res, meta));
      setLoad(false);
    })(dispatch);
  }, [dispatch, searchString, navigate]);

  useEffect(() => {
    if (firstLoad) return;

    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const onSubmitFilter = (data: any) => {
    setCurrentFilters(data);
  };

  const paginate =
    data.posts.length > 0 && data.meta.paginationAllPageCount > 4 ? (
      <Paginate
        page={Number(data.meta.paginationCurrentPage)}
        handleChange={onChangePage}
        count={data.meta.paginationPageCount}
        theme={theme}
        variant="outlined"
        shape="rounded"
      />
    ) : null;

  return (
    <div className={style.library}>
      <div className={style.headerContainer}>
        <Wrapper>
          <div className={style.titleContainer}>
            <h1 className={style.title}>Библиотека</h1>
          </div>
          {isAdmin ? (
            <div className={style.adminControls}>
              <TextLink variant={style.addPost} url={"/post/create"}>
                Добавить запись
              </TextLink>
              <TextLink
                variant={style.tagLink}
                url={"/tag/index"}
                redirect={true}
              >
                Список разделов
              </TextLink>
            </div>
          ) : null}
          <LibrarySearchForm
            initialValues={params}
            submitHandler={onSubmitFilter}
            theme={theme}
          />
        </Wrapper>
      </div>
      <div className={style.postsContainer}>
        <Wrapper>
          <div className={style.controls}>
            {paginate}
            <SortButton
              posVariant={style.sortButtonPost}
              clickHandler={onClickSort}
              sortType={"date_publication"}
              text="По дате"
              isUp={params.sort === "date_publication"}
              isShow={
                params.sort === "date_publication" ||
                params.sort === "-date_publication"
              }
              isDisable={isLoad}
            />
          </div>
        </Wrapper>
        <div className={style.postsWrapper}>
          {isLoad ? <PostSkeletonList /> : <PostList items={data.posts} />}
        </div>
        <Wrapper>
          <div className={style.controls}>{paginate}</div>
        </Wrapper>
      </div>
    </div>
  );
};
