import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { SelectWithPopper } from "../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetUserTypes } from "../../../app/services/api/userTypes/userTypes";
import { removeProperty } from "../../../app/services/other";

export type UserTypeItem = {
  title: string;
  id: string;
};

export type UserTypeProps = ComponentPropsType<{
  changeHandler?: (type: UserTypeItem) => void;
  value?: any | undefined;
  paperOptions?: { [key: string]: any };
}>;

const typesWithoutAdmin = removeProperty(4);

export const UserTypes: React.FC<UserTypeProps> = ({
  changeHandler = () => {},
  value,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<UserTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<UserTypeItem>({
    id: "1",
    title: "Проектировщик",
  });

  const onLoadTypes = () => {
    request(
      ApiGetUserTypes(),
      (data) => {
        setAllTypes(
          Object.entries<string>(typesWithoutAdmin(data)).map(
            ([id, title]) => ({
              title,
              id,
            })
          )
        );
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов пользователей", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  useEffect(() => {
    onLoadTypes();
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => item.id === value);
    if (result) {
      setSelectValue(result);
    }
  }, [value, allTypes]);

  return (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      options={allTypes}
      label={"Роль"}
      disableClear={true}
      fullWidth
    />
  );
};
