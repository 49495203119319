import { httpRequestGet, httpRequestPatch } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectGanttData = (id: number): Promise<Response> => {
  // данные для графика
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-project-gantt?id=${id}`
  );
};

export const ApiProjectGanttDataNew = (id: number): Promise<Response> => {
  // данные для графика
  return httpRequestGet(
    `${config.localDomain}/v2/project/get-project-gantt?id=${id}`
  );
};

export const ApiUpdateDates = (
  id: number,
  data: Record<string, string | number | null>
): Promise<Response> => {
  return httpRequestPatch(
    `${config.localDomain}/v1/schedule-plan/update-dates?projectId=${id}`,
    {},
    data
  );
};

export const ApiSchedulePlan = (id: number): Promise<Response> => {
  // даты по умолчанию
  return httpRequestGet(
    `${config.localDomain}/v1/schedule-plan/view?projectId=${id}`
  );
};
