import { RootState } from "../../../../store";

export const getApplicationsRequestUpdate = (state: RootState) =>
  state.projectProcessView.applications.requestUpdateSuccess;

export const getApplicationsRequestUpdateIsLoad = (state: RootState) =>
  state.projectProcessView.applications.isLoadRequestUpdate;

export const getApplicationsRequestUpdateIsError422 = (state: RootState) =>
  state.projectProcessView.applications.error422;
