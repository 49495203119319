import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessApplications } from "../services/fetchProjectProcessApplications";
import {
  ApplicationsMessages,
  ApplicationsType,
} from "../types/projectProcessApplicationTypes";
import { fetchProjectProcessSelectExecutor } from "../services/fetchProjectProcessSelectExecutor";
import { fetchProjectProcessRemoveRequest } from "../services/fetchProjectProcessRemoveRequest";
import { fetchProjectProcessUpdateRequest } from "../services/fetchProjectProcessUpdateRequest";

type InitialState = {
  isLoad: boolean;
  isLoadSelectExecutor: boolean;
  isLoadRemoveRequest: boolean;
  goodApplications: ApplicationsType[] | undefined;
  badApplications: ApplicationsType[] | undefined;
  isLoadRequestUpdate: boolean;
  error: any;
  error422: any;
  requestUpdateSuccess: boolean;
  removeRequestSuccess: boolean;
  selectExecutorSuccess: boolean;
  messages: ApplicationsMessages[];
};

const initialState: InitialState = {
  isLoad: false,
  isLoadSelectExecutor: false,
  isLoadRemoveRequest: false,
  isLoadRequestUpdate: false,
  error422: undefined,
  error: undefined,
  requestUpdateSuccess: false,
  removeRequestSuccess: false,
  selectExecutorSuccess: false,
  goodApplications: undefined,
  badApplications: undefined,
  messages: [],
};

const projectProcessApplications = createSlice({
  name: "projectProcessApplications",
  initialState,
  reducers: {
    setNullRequestUpdate: (state) => {
      state.requestUpdateSuccess = false;
      state.removeRequestSuccess = false;
      state.selectExecutorSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessApplications.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(
      fetchProjectProcessApplications.fulfilled,
      (state, action) => {
        state.isLoad = false;
        state.goodApplications = action.payload?.good;
        state.badApplications = action.payload?.bad;
        state.messages = action.payload?.messages;
      }
    );
    builder.addCase(fetchProjectProcessSelectExecutor.pending, (state) => {
      state.isLoadSelectExecutor = true;
    });
    builder.addCase(fetchProjectProcessSelectExecutor.fulfilled, (state) => {
      state.isLoadSelectExecutor = false;
      state.selectExecutorSuccess = true;
    });
    builder.addCase(fetchProjectProcessRemoveRequest.pending, (state) => {
      state.isLoadRemoveRequest = true;
    });
    builder.addCase(fetchProjectProcessRemoveRequest.fulfilled, (state) => {
      state.isLoadRemoveRequest = false;
      state.removeRequestSuccess = true;
    });
    builder.addCase(fetchProjectProcessUpdateRequest.pending, (state) => {
      state.isLoadRequestUpdate = true;
      state.error = undefined;
      state.error422 = undefined;
      state.requestUpdateSuccess = false;
    });
    builder.addCase(fetchProjectProcessUpdateRequest.fulfilled, (state) => {
      state.isLoadRequestUpdate = false;
      state.error = undefined;
      state.error422 = undefined;
      state.requestUpdateSuccess = true;
    });
    builder.addCase(
      fetchProjectProcessUpdateRequest.rejected,
      (state, action) => {
        state.isLoadRequestUpdate = false;
        if (action?.payload?.status === "none") {
          state.error = action.payload.message;
        }
        if (action?.payload?.status === "422") {
          state.error422 = action.payload.message;
        }
      }
    );
  },
});
export const { setNullRequestUpdate } = projectProcessApplications.actions;
export default projectProcessApplications;
