import { httpRequestDelete, httpRequestGet } from "../../api";
import { config } from "../../../../../app.cofig";

export const ApiExpertiseDocuments = (processId: number): Promise<Response> => {
  // получить дерево договоры
  return httpRequestGet(
    `${config.localDomain}/v1/expertise/document/index?DocumentSearch[process_id]=${processId}&expand=file`
  );
};

export const ApiExpertiseDeleteDocuments = (
  documentId: number
): Promise<Response> => {
  // удалить документ
  return httpRequestDelete(
    `${config.localDomain}/v1/expertise/document/delete?id=${documentId}`
  );
};
