import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessDonePreliminary } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessDonePreliminary = createAsyncThunk<
  boolean,
  { id: number; data: any }
>(
  "projectProcess/fetchProjectProcessDonePreliminary",
  async ({ id, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDonePreliminary(id, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
