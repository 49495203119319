import { memo } from "react";
import { PeopleFill, CheckLg, X } from "react-bootstrap-icons";
import cn from "classnames";
import {
  KanbanColumns,
  KanbanStatuses,
  KanbanType,
} from "src/FSD/entities/kanban";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import styles from "./Column.module.scss";
import { Dot } from "../../../../../../components/newUI/Dot/Dot";
import { palette } from "../../../../../../styles/restyle";
import { getTaskPosition } from "../lib/helpers/getTaskPosition";
import { CheckValues } from "../lib/constants/checkValues";
import { getMarkBackgroundColor } from "../lib/helpers/getBackgroundColor";

type ColumnTaskProps = KanbanType.Task & {
  position: string;
};

const ColumnTask = memo(
  ({
    id,
    isStop,
    isCheck,
    partGroup,
    process,
    executor,
    name,
    date,
    project,
    isImportant,
    workFlowClass,
    position,
  }: ColumnTaskProps) => {
    const hasMark = isImportant;

    const showRedCross = isCheck === CheckValues.RED_CROSS;
    const showGreenCheck = isCheck === CheckValues.GREEN_CHECK;

    const isFreeTask =
      workFlowClass === WorkFlowClasses.FREE_TASK ||
      workFlowClass === WorkFlowClasses.CHECK_FREE_TASK;

    const { columnId } = getTaskPosition(position);

    const isDoneColumn = columnId === KanbanStatuses[KanbanColumns.DONE];

    const code = project.code ? `Шифр ${project.code}` : "Без шифра";

    const backgroundColor = getMarkBackgroundColor(isImportant);

    const showCheckIcon =
      columnId === KanbanStatuses[KanbanColumns.IS_DONE] && isCheck !== null;

    return (
      <div id={`task-anchor-${id}`} className={styles.taskWrapper}>
        {hasMark && (
          <div style={{ backgroundColor }} className={styles.taskMark}>
            {isImportant && <>Важно!</>}
          </div>
        )}
        <div
          className={cn(styles.task, hasMark && styles.task_hasMark)}
          style={{ borderColor: partGroup.color }}
        >
          <p
            className={styles.task__groupName}
            style={{ backgroundColor: partGroup.color }}
          >
            {partGroup.code}
          </p>
          <div className={styles.task__data}>
            {showCheckIcon && (
              <div
                className={cn(
                  styles.checkIcon,
                  showGreenCheck && styles.checkIcon_checked,
                  showRedCross && styles.checkIcon_unchecked
                )}
              >
                {showRedCross && <X />}
                {showGreenCheck && <CheckLg />}
              </div>
            )}
            {Boolean(process.name) && (
              <div className={styles.task__data__process}>
                <p>{process.name}</p>
                {isStop && <Dot status={"error"} size={12} />}
              </div>
            )}
            {isFreeTask && (
              <div className={styles.task__data__process}>
                <p>Оперативная задача</p>
              </div>
            )}
            <div className={styles.task__data__executor}>
              <p>{executor.fullName}</p>
              {executor.isDelegate && <PeopleFill color={palette.green} />}
            </div>
            <p className={styles.task__data__taskName}>{name}</p>
            <div className={styles.task__data__dateAndCode}>
              <p className={styles.task__data__code}>{code}</p>
              <p
                className={cn(
                  styles.task__data__date,
                  isDoneColumn
                    ? null
                    : (date.isExpiring && styles.task__data__date_isExpiring,
                      date.isExpired && styles.task__data__date_expired)
                )}
              >
                {date.value}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ColumnTask;
