import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { task } from "./queries";

export const feedbackApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    feedbackTask: task(builder),
  }),
  overrideExisting: false,
});

export const { useFeedbackTaskMutation } = feedbackApi;
