import React, { Fragment } from "react";
import parse from "html-react-parser";
import classNames from "classnames";
import style from "./GraphRender.module.scss";
import { TaskType } from "../../../../../../../app/feature/ProjectView/GraphReducer/thunks";

interface PlanGraphProps {
  graph: TaskType;
  name: string;
}

export const GraphRender = ({ graph, name }: PlanGraphProps) => {
  let expertiseValue;
  let otrValue;
  let workValue;
  const { div1 } = graph;

  const isToday = name === "today";
  const isProject = name === "project";
  const isSource = name === "source";
  const isResearch = name === "research";
  const isInspection = name === "inspection";

  if (name === "expertise") {
    expertiseValue = Object.values(graph);
  }
  if (name === "projectOtr") {
    otrValue = Object.values(graph);
  }
  if (name === "work") {
    workValue = Object.values(graph);
  }

  const irdGraphRender = isSource && (
    <div className={style.fact_container}>
      <div
        className={style.fact_ird}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width}%`,
        }}
      >
        <p className={style.fact_ird_date1}>{div1?.dateStart}</p>
        <p
          className={style.fact_ird_date2}
          style={{ left: `${div1?.width < 3 && "30px"}` }}
        >
          {div1?.dateEnd}
        </p>
      </div>
      <div
        className={style.fact_block}
        title={parse(div1?.title) as string}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width > 3 ? div1.width : 3}%`,
          background: div1?.color,
          height: "22px",
        }}
      >
        <p>{div1?.shortTitle}</p>
      </div>
    </div>
  );

  const researchGraphRender = isResearch && (
    <div className={style.fact_container}>
      <div
        className={style.fact_ird}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width}%`,
        }}
      >
        <p className={style.fact_ird_date1}>{div1?.dateStart}</p>
        <p
          className={style.fact_ird_date2}
          style={{ left: `${div1?.width < 3 && "30px"}` }}
        >
          {div1?.dateEnd}
        </p>
      </div>
      <div
        className={style.fact_block}
        title={parse(div1?.title) as string}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width > 3 ? div1.width : 3}%`,
          background: div1?.color,
          height: "22px",
        }}
      >
        <p>{div1?.shortTitle}</p>
      </div>
    </div>
  );

  const inspectionGraphRender = isInspection && (
    <div className={style.fact_container}>
      <div
        className={style.fact_ird}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width}%`,
        }}
      >
        <p className={style.fact_ird_date1}>{div1?.dateStart}</p>
        <p
          className={style.fact_ird_date2}
          style={{ left: `${div1?.width < 3 && "30px"}` }}
        >
          {div1?.dateEnd}
        </p>
      </div>
      <div
        className={style.fact_block}
        title={parse(div1?.title) as string}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width > 3 ? div1.width : 3}%`,
          background: div1?.color,
          height: "22px",
        }}
      >
        <p>{div1?.shortTitle}</p>
      </div>
    </div>
  );

  const projectGraphRender = isProject && (
    <div className={style.fact_container} style={{ marginBottom: 0 }}>
      <div
        className={style.fact_project}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width}%`,
        }}
      >
        <p className={style.fact_project_date1}>{div1?.dateStart}</p>
        <p className={style.fact_project_date2}>{div1?.dateEnd}</p>
      </div>
      <div
        className={style.fact_block}
        title={parse(div1?.title) as string}
        style={{
          marginLeft: `${div1?.marginLeft}%`,
          width: `${div1?.width > 3 ? div1.width : 3}%`,
          background: div1?.color,
          height: "22px",
        }}
      >
        <p>{div1?.shortTitle}</p>
      </div>
    </div>
  );

  const otrGraphRender = otrValue && (
    <div className={style.fact_container}>
      {otrValue.map((value, index) => (
        <Fragment key={value.shortTitle}>
          <div
            className={style.blockOtr}
            title={parse(value?.title) as string}
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              background: value?.color,
              height: "22px",
              overflow: "hidden",
            }}
          >
            <p>{value?.shortTitle}</p>
          </div>
          <div
            className={
              index === 1 ? style.blockOtrDateIndex1 : style.blockOtrDate
            }
            style={{
              marginLeft: `${value?.marginLeft}%`,
              width: `${value?.width}%`,
              top: "22px",
              paddingTop: index === 2 ? "11px" : 0,
            }}
          >
            {(index === 2 || index === 1) && <p>{value?.dateStart}</p>}
          </div>
        </Fragment>
      ))}
    </div>
  );

  const workGraphRender = workValue && (
    <div className={style.fact_containerExpertise}>
      {workValue.map((value, index) => (
        <div
          title={parse(value?.title) as string}
          key={value.shortTitle}
          className={style.fact_expertise}
          style={{
            marginLeft: `${index === 0 ? value?.marginLeft : 0}%`,
            width: `${value?.width}%`,
            background: value?.color,
          }}
        >
          <div className={style.fact_block}>
            <p style={{ height: "22px" }}>{value?.shortTitle}</p>
          </div>
          <div className={style.fact_expertiseDate}>
            {index === 0 && (
              <p className={style.fact_expertiseDate_date1}>
                {value?.dateStart}
              </p>
            )}
            <p
              className={classNames(
                index === 0 && style.fact_expertiseDate_date2,
                index === 1 && style.fact_expertiseDate_dateEnd
              )}
            >
              {value?.dateEnd}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
  const expertiseLength = expertiseValue && expertiseValue.length > 2;
  const expertiseGraphRender = expertiseValue && (
    <div className={style.fact_containerExpertise}>
      {expertiseValue.map((value, index) => (
        <div
          title={parse(value?.title) as string}
          key={value.shortTitle}
          className={style.fact_expertise}
          style={{
            marginLeft: `${index === 0 ? value?.marginLeft : 0}%`,
            width: `${value?.width}%`,
            background: value?.color,
          }}
        >
          <div className={style.fact_block}>
            <p style={{ height: "22px" }}>{value?.shortTitle}</p>
          </div>
          <div className={style.fact_expertiseDate}>
            {index === 0 && (
              <p className={style.fact_expertiseDate_date1}>
                {value?.dateStart}
              </p>
            )}
            <p
              className={classNames(
                index === 0 && style.fact_expertiseDate_date2,
                index === 1 &&
                  expertiseLength &&
                  style.fact_expertiseDate_date2,
                index === 1 &&
                  !expertiseLength &&
                  style.fact_expertiseDate_dateEnd,
                index === 2 && style.fact_expertiseDate_dateEnd
              )}
            >
              {value?.dateEnd}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {isToday && (
        <div
          style={{ marginLeft: `${div1?.marginLeft}%`, top: "-15px" }}
          className={style.fact_branchToday}
        ></div>
      )}
      {irdGraphRender}
      {researchGraphRender}
      {inspectionGraphRender}
      {projectGraphRender}
      {otrGraphRender}
      {expertiseValue && (
        <div className={style.expertiseContainer}>{expertiseGraphRender}</div>
      )}
      {workGraphRender}
    </>
  );
};
