import { usersLogin } from "../../../../userConfigLogin";
import { apiLogin } from "../../api/site/site";
import { isBadStatusRequest } from "../../api/api";

export const setTestTokens = (): void => {
  if (usersLogin) {
    if (usersLogin.length === 0) {
      alert("Заполните обьект с данными входа");
      return;
    }
    Promise.all(
      usersLogin.map((item) =>
        apiLogin(item)
          .then((x) => x.json())
          .then((x) => {
            if (isBadStatusRequest(x.status)) {
              alert("Неверные данные в файле");
              throw new Error(x.message);
            }
            return x;
          })
          .then((token) => {
            return {
              // @ts-ignore
              type: item.type,
              token,
            };
          })
          .then((x) => {
            localStorage.setItem("testRolesTokens", JSON.stringify([]));
            return x;
          })
      )
    )
      .then((x) =>
        localStorage.setItem(
          "testRolesTokens",
          JSON.stringify([
            // @ts-ignore
            ...JSON.parse(localStorage.getItem("testRolesTokens")),
            ...x,
          ])
        )
      )
      .then(() => document.location.reload())
      .catch(console.log);
  }
};

export const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${
        // eslint-disable-next-line
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")
      }=([^;]*)`
    )
  );
  if (matches) {
    // @ts-ignore
    const value = decodeURIComponent(matches[1])
      // eslint-disable-next-line
      .match(/\"([\w-+/.]*)\"/g)[1]
      .replace(/"/g, "");
    if (value) {
      return value;
    }
  }
  return undefined;
};
