import React from "react";
import { Skeleton } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import cn from "classnames";
import style from "./style.module.scss";
import secondaryStyle from "./Secondary/style.module.scss";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";
import { useAppSelector } from "../../../../app/store";
import { getDashboardHeader } from "../../../../app/feature/dashboard/header";
import { trimNumber } from "../../../../utils/trimNumber";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { config } from "../../../../app.cofig";
import { useIsMobile } from "../../../hooks/useIsMobile";

const WalletSkeleton = () => (
  <Skeleton variant="rectangular" className={style.walletSkelton} />
);

const Wallet = () => {
  const { bank, accountPending } = useAppSelector(getDashboardHeader);
  const trimmedNumber = bank?.value ? trimNumber(bank.value) : "";
  const isRuble = bank?.currency === "RUB";

  const isMobile = useIsMobile(1350);

  return (
    <div className={cn(style.subdiv, style.wallet)}>
      <h2>Кошелек</h2>
      {accountPending ? (
        <WalletSkeleton />
      ) : (
        <h1 className={cn(style.leftBottom, isRuble && style.ruble)}>
          {trimmedNumber}
        </h1>
      )}
      {/*
      <IconButton className={cn(primaryStyle.roundButton, style.rightTop)}>
        <Add />
      </IconButton>
      */}
      {bank?.id && (
        <TextLink
          url={`${config.localDomain}/account/view?id=${bank.id}`}
          redirect
          target="_blank"
          variant={style.openOnTablette}
        >
          {isMobile ? (
            <OpenInNew />
          ) : (
            <CustomButton
              background={palette.blue}
              width={120}
              className={cn(secondaryStyle.button, style.rightBottom)}
            >
              Открыть
            </CustomButton>
          )}
        </TextLink>
      )}
    </div>
  );
};

export default Wallet;
