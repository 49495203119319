import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessCheckTask } from "../../../../services/api/projectProcess/checkTask";
import { CheckTaskType } from "../types/projectProcessCheckTaskTypes";

export const fetchProjectProcessCheckTask = createAsyncThunk<
  CheckTaskType,
  number
>("projectProcess/fetchProjectProcessCheckTask", async (id, { dispatch }) => {
  let response: any = {};
  await request(
    ApiProjectProcessCheckTask(id),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
