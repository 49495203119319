import { useEffect } from "react";
import ym from "react-yandex-metrika";

export const useMetrika = (str: string) => {
  useEffect(() => {
    if (window.location.hostname === "pirs.online") {
      ym("hit", str);
    }
  }, [str]);
};
