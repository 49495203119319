import React, { ChangeEvent, memo, useEffect } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { palette, textFieldSX } from "../../../../../../../../styles/restyle";
import cls from "./FormModalCheck.module.scss";
import { TextLink } from "../../../../../../../UI/components/TextLink/TextLink";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import { ApiPrimaryFileType } from "../../../../../../../../types/api/primaryTypes/apiPrimaryFileType";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessCheckAlign } from "../../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessCheckAlign";
import Fieldset from "../../../../../../../newUI/Fieldset/Fieldset";
import { parseUnprocessableFields } from "../../../../../../../../app/services/api/requestHandler";
import {
  getProjectProcessCheckComment,
  getProjectProcessDocumentsCheckError422,
} from "../../../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { setComment } from "../../../../../../../../app/feature/ProjectProcessView/Documentations/slice/projectProcessDocumentations";
import { fetchProjectProcessDocumentationsDuplicate } from "../../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";

type TInputs = {
  comment: string;
  status: number;
};
interface FormModalCheckProps {
  header: string;
  taskId?: number;
  comment: string;
  files: ApiPrimaryFileType[];
}
export const FormModalCheck = memo(
  ({ header, taskId, comment, files }: FormModalCheckProps) => {
    const processId = useAppSelector(getProjectProcessInfoId);
    const error422 = useAppSelector(getProjectProcessDocumentsCheckError422);
    const dispatch = useAppDispatch();
    const commentValue = useAppSelector(getProjectProcessCheckComment);

    useEffect(() => {
      if (processId) {
        dispatch(fetchProjectProcessDocumentationsDuplicate(processId));
      }
    }, [dispatch]);

    const {
      handleSubmit,
      setValue,
      register,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<TInputs>({
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          status: dataValues.status,
          comment: dataValues.comment,
        },
      };
      if (taskId) {
        await dispatch(
          fetchProjectProcessCheckAlign({
            taskId,
            data,
            processId,
          })
        );
      }
    };

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    function commentChangeHandle(event: ChangeEvent<HTMLInputElement>) {
      dispatch(setComment(event.target.value));
      clearErrors("comment");
    }

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{header}</h1>
        {comment && (
          <Fieldset legend={"Комментарий проверяющего"}>{comment}</Fieldset>
        )}
        <fieldset className={cls.fieldset}>
          <legend>Файлы решения</legend>
          <div className={cls.fieldset_files}>
            {files.map((file) => (
              <TextLink url={file.url} redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <TextField
          {...textFieldSX}
          {...register("comment")}
          value={commentValue}
          onChange={commentChangeHandle}
          multiline
          minRows={2}
          label="Замечания"
          placeholder="Введите комментарий"
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        <div className={cls.form_submit}>
          <CustomButton
            type="submit"
            width={120}
            background={palette.red}
            onClick={() => setValue("status", 5)}
          >
            Отказать
          </CustomButton>
          <CustomButton
            type="submit"
            width={120}
            background={palette.blue}
            onClick={() => setValue("status", 4)}
          >
            Принять
          </CustomButton>
        </div>
      </form>
    );
  }
);
