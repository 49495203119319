import React from "react";
import { useForm } from "react-hook-form";
import { palette } from "../../../../../../styles/restyle";
import cls from "../ModalRemoveExecutor.module.scss";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { FormWrapper } from "../../../../../newUI/FormWrapper/FormWrapper";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessCheckedRefund } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { setCheckedRefund } from "../../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

type Inputs = {
  refund: boolean;
};

interface RefundFormProps {
  onSubmit: (data: any) => void;
  title: string;
  handleBackClick: (refund: string) => void;
}

export const RefundForm = ({
  onSubmit,
  title,
  handleBackClick,
}: RefundFormProps) => {
  const dispatch = useAppDispatch();
  const checkedRefund = useAppSelector(getProjectProcessCheckedRefund);
  const { handleSubmit, register } = useForm<Inputs>();
  const processId = useAppSelector(getProjectProcessInfoId);
  const formSubmitHandler = async (dataValue: Inputs) => {
    onSubmit(dataValue);
  };

  function onChangeRefund() {
    dispatch(setCheckedRefund());
  }

  function downloadBtnClick() {}

  const refundText = (
    <div className={cls.refundText}>
      <span>Внимание!</span>
      <p>
        В случае отсутствия в кошельке исполнителя необходимой суммы для
        возврата Заказчику, возврат средств через платформу не выполняется.
        Пожалуйста, подготовьте{" "}
        <TextLink redirect url={`/project-process/get-email?id=${processId}`}>
          официальное письмо
        </TextLink>{" "}
        на исполнителя раздела о возврате выплаченных средств.
      </p>
      <p>
        Не забудьте подписать дополнительное соглашение или соглашение о
        расторжении, в случае снятия исполнителя со всех разделов без частичной
        оплаты за выполненные работы.
      </p>
      <p>
        Проект оформленного текста письма с указанием банковских реквизитов.
      </p>
    </div>
  );

  return (
    <FormWrapper title={title} submitHandler={handleSubmit(formSubmitHandler)}>
      <p className={cls.subtitle}>Необходим ли возврат выплаченного аванса?</p>
      <label className={cls.labelAgree}>
        <CustomCheckbox
          {...register("refund")}
          onChange={onChangeRefund}
          checked={checkedRefund}
          value={checkedRefund}
        />
        Да
      </label>
      {checkedRefund && (
        <>
          {refundText}
          <TextLink
            variant={cls.btn_download}
            redirect
            url={`/project-process/get-email?id=${processId}`}
          >
            <CustomButton
              background={palette.blue}
              onClick={downloadBtnClick}
              width={140}
            >
              Скачать
            </CustomButton>
          </TextLink>
        </>
      )}

      <div className={cls.btn}>
        <CustomButton onClick={() => handleBackClick("refund")} width={140}>
          Назад
        </CustomButton>
        <CustomButton background={palette.blue} width={140} type="submit">
          Далее
        </CustomButton>
      </div>
    </FormWrapper>
  );
};
