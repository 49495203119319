import React, { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { IExpertiseIn } from "../../../../../../app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import cls from "./ExpertiseInCard.module.scss";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { AddFile, CheckSVG, NotApproveIcon } from "../../../../../newUI/SVG";
import { StatusCardWrapper } from "../../../../../newUI/StatusCardWrapper/StatusCardWrapper";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalComplete } from "./ModalComplete/ModalComplete";
import { iconBtnSx } from "../../../../../../styles/restyle";
import { setCheckInput } from "../../../../../../app/feature/ProjectView/projectView";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessTaskCompleteIsLoad } from "../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { fetchBackToWorkDocumentations } from "../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchBackToWorkDocumentations";
import { fetchProjectProcessDocumentations } from "../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ModalCheck } from "./ModalCheck/ModalCheck";

export const ExpertiseInCard = memo(
  ({ status, executorName, files, task, header, can }: IExpertiseIn) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const [toggleFiles, setToggleFiles] = useState(false);
    const [modalComplete, setModalComplete] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const isLoadCompleteTask = useAppSelector(
      getProjectProcessTaskCompleteIsLoad
    );

    function onModalComplete() {
      setModalComplete(true);
      dispatch(setCheckInput(true));
    }

    function onModalCheck() {
      setModalCheck(true);
      dispatch(setCheckInput(true));
    }

    const closeModalComplete = async () => {
      setModalComplete(false);

      if (processId) {
        await dispatch(fetchProjectProcessDocumentations(processId));
      }
    };

    const closeModalCheck = () => {
      setModalCheck(false);
    };

    async function backToWorkHandler() {
      const confirmResult = window.confirm(
        "Вы уверены, что хотите вернуть задачу?"
      );
      if (confirmResult && task?.id && processId) {
        await dispatch(fetchBackToWorkDocumentations(task.id));
        await dispatch(fetchProjectProcessDocumentations(processId));
      }
    }

    return (
      <div>
        <StatusCardWrapper
          status={status}
          date={status?.date}
          files={files}
          color={status.color}
          viewFiles={toggleFiles}
          viewStatus={!toggleFiles}
          classname={cls.card}
          downloadAllLink={
            files.length > 0 ? `/task/download-files?id=${task.id}` : undefined
          }
        >
          <>
            <div className={cls.content}>
              <div className={cls.content_header}>
                <h1>{header}</h1>
                <p>{executorName}</p>
              </div>
              {files && (
                <div
                  className={cls.toggle}
                  onClick={() => setToggleFiles(!toggleFiles)}
                >
                  <p>Вложения</p>
                  <ToggleAccordion toggle={toggleFiles} />
                </div>
              )}
              {task.data?.addText && (
                <p className={cls.addText}>{task.data?.addText}</p>
              )}
            </div>
            <div className={cls.btn}>
              {can?.execute && (
                <Modal
                  active={modalComplete}
                  setActive={setModalComplete}
                  isLoad={isLoadCompleteTask}
                  handleClose={closeModalComplete}
                >
                  <ModalComplete
                    taskId={task.id}
                    addText={task.data?.addText}
                    closeModal={closeModalComplete}
                  />
                </Modal>
              )}
              {can?.execute && (
                <IconButton
                  sx={iconBtnSx}
                  title="Завершить раздел"
                  onClick={onModalComplete}
                >
                  <AddFile width="24" height="24" />
                </IconButton>
              )}
              {can?.check && (
                <Modal
                  active={modalCheck}
                  setActive={setModalCheck}
                  isLoad={isLoadCompleteTask}
                >
                  <ModalCheck
                    closeModal={closeModalCheck}
                    taskId={task.id}
                    files={task.files}
                  />
                </Modal>
              )}
              {can?.check && (
                <IconButton
                  sx={iconBtnSx}
                  title="Завершить раздел"
                  onClick={onModalCheck}
                >
                  <CheckSVG width="24" height="24" />
                </IconButton>
              )}
              {can?.backToWork && (
                <IconButton
                  sx={iconBtnSx}
                  title="Вернуть"
                  onClick={backToWorkHandler}
                >
                  <NotApproveIcon width="24" heigth="24" />
                </IconButton>
              )}
            </div>
          </>
        </StatusCardWrapper>
      </div>
    );
  }
);
