import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import { DoneAll, Done } from "@mui/icons-material";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { Avatar } from "../../Avatar/Avatar";

export type MessageProps = ComponentPropsType<{
  isReverse?: boolean;
  content?: string;
  avatarUrl?: string;
  time?: string;
  isOverlook?: boolean;
  date?: string;
}>;

export const Message: React.FC<MessageProps> = ({
  isReverse = false,
  content = "",
  avatarUrl = "",
  time = "",
  theme = "dark",
  isOverlook = false,
  variant = "",
}) => {
  return (
    <div
      className={classNames(
        style.messageWrapper,
        variant,
        isReverse && style.reverse
      )}
    >
      <div className={style.avatarWrapper}>
        <Avatar src={avatarUrl || ""} variant={style.avatarMessage} />
      </div>
      <div className={style.messageBody}>
        <div className={style.message}>{ReactHtmlParser(content)}</div>
        <div className={style.bottom}>
          <div className={style.check}>
            {isReverse ? (
              isOverlook ? (
                <DoneAll className={style.doneIcon} />
              ) : (
                <Done className={style.doneIcon} />
              )
            ) : (
              <></>
            )}
          </div>
          <div className={classNames(style.time, style[theme])}>
            Сообщение отправлено в {time}&nbsp;
          </div>
        </div>
      </div>
      <div className={style.additional} />
    </div>
  );
};
