import { useState, useEffect, forwardRef, ReactNode } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { useProcessWorkGetWorkAccountQuery } from "src/FSD/entities/processWork/api";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { toSelect } from "../../../../../app/feature/project/projectProcessPublication";
import { TSelectItem } from "../../../../../components/features/Forms/SetGipForm/types";

type Props = {
  workId: number;
  error?: boolean;
  helperText?: ReactNode;
  setValue?: any;
  processData?: ApiProcessWorkResponses.View;
} & Omit<SelectProps, "label" | "options">;

export const SelectMoneyFromProcess = forwardRef(
  (
    {
      workId,
      value,
      error = false,
      helperText = "",
      processData,
      setValue,
      ...props
    }: Props,
    ref
  ) => {
    const { data: workAccountRaw, isFetching } =
      useProcessWorkGetWorkAccountQuery({
        id: workId,
      });

    const isPendingWorkAccount = isFetching || !workAccountRaw;

    const [workAccount, setWorkAccount] = useState<TSelectItem[]>([]);

    useEffect(() => {
      if (workAccountRaw) {
        setWorkAccount(toSelect(workAccountRaw, false));
      }
    }, [workAccountRaw]);

    useEffect(() => {
      if (!processData) {
        return;
      }

      if (
        processData.status.key === Statuses.UNREADY &&
        workAccount &&
        processData.executorType
      ) {
        const workAccountValue = processData.money_from_process
          ? workAccount.find((item) => item.id === "true")
          : workAccount.find((item) => item.id === "false");

        setValue("workAccount", workAccountValue);
      }
    }, [processData, workAccount]);

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={workAccount}
        isLoading={isPendingWorkAccount}
        label="Кошелек"
        error={error}
        helperText={helperText}
      />
    );
  }
);
