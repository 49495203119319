import React from "react";
import { useForm } from "react-hook-form";
import { palette } from "src/styles/restyle";
import CustomCheckbox from "src/components/newUI/CustomCheckbox/CustomCheckbox";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { getProjectProcessCheckedRefund } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { setCheckedRefund } from "src/app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useAppDispatch, useAppSelector } from "src/app/store";
import cls from "../RemoveExecutor.module.scss";

type Inputs = {
  refund: boolean;
};

interface RefundFormProps {
  onSubmit: (data: any) => void;
  handleBackClick: (refund: string) => void;
}

export const RefundForm = ({ onSubmit, handleBackClick }: RefundFormProps) => {
  const dispatch = useAppDispatch();
  const checkedRefund = useAppSelector(getProjectProcessCheckedRefund);
  const { handleSubmit, register } = useForm<Inputs>();
  const processId = useAppSelector(getProjectProcessInfoId);
  const formSubmitHandler = async (dataValue: Inputs) => {
    onSubmit(dataValue);
  };

  function onChangeRefund() {
    dispatch(setCheckedRefund());
  }

  const refundText = (
    <div className={cls.refundText}>
      <span>Внимание!</span>
      <p>
        В случае отсутствия в кошельке исполнителя необходимой суммы для
        возврата Заказчику, возврат средств через платформу не выполняется.
        Пожалуйста, подготовьте{" "}
        <TextLink redirect url={`/project-process/get-email?id=${processId}`}>
          официальное письмо
        </TextLink>{" "}
        на исполнителя раздела о возврате выплаченных средств.
      </p>
      <p>
        Не забудьте подписать дополнительное соглашение или соглашение о
        расторжении, в случае снятия исполнителя со всех разделов без частичной
        оплаты за выполненные работы.
      </p>
      <TextLink redirect url={`/project-process/get-email?id=${processId}`}>
        Скачать проект оформленного текста письма с указанием банковских
        реквизитов.
      </TextLink>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <p className={cls.checkSubtitle}>
        Необходим ли возврат выплаченного аванса?
      </p>
      <label className={cls.labelAgree}>
        <CustomCheckbox
          {...register("refund")}
          onChange={onChangeRefund}
          checked={checkedRefund}
          value={checkedRefund}
          classname={cls.checkbox}
        />
        Да
      </label>
      {checkedRefund && <>{refundText}</>}

      <div className={cls.btn}>
        <CustomButton
          onClick={() => handleBackClick("refund")}
          width={108}
          height={32}
          fontSize={16}
          borderRadius={4}
          background={palette.darkGrey}
          color={palette.silverGrey}
        >
          Назад
        </CustomButton>
        <CustomButton
          width={110}
          height={32}
          fontSize={16}
          borderRadius={4}
          background={palette.oceanic}
          color={palette.midnightBlack}
          type="submit"
        >
          Далее
        </CustomButton>
      </div>
    </form>
  );
};
