import { useEffect } from "react";

export const useHeaderOutsideClick = (state: boolean, handler: any) => {
  const onClick = (event: MouseEvent) => {
    const elem = event.target as HTMLElement;

    if (!elem.closest(".js-header-wrapper")) {
      handler(false);
    }
  };

  useEffect(() => {
    if (state) {
      document.addEventListener("click", onClick);
    }
  }, [onClick]);
};
