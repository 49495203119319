import { config } from "src/app.cofig";
import { httpRequestGet } from "src/app/services/api/api";
import { request } from "src/app/services/api/requestHandler";

const ApiGetTaskFile = (id: number | string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/view?id=${id}&expand=files.svgIcon`
  );
};

export const getFiles = async (id: string) => {
  let response: any = [];

  await request(ApiGetTaskFile(id), (data) => {
    response = data;
  })();

  return response.files;
};
