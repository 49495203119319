import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessToProcesses } from "../services/fetchProjectProcessToProcesses";

type InitialState = {
  isLoadProcessList: boolean;
  processList: Record<string, string> | undefined;
};

const initialState: InitialState = {
  isLoadProcessList: false,
  processList: undefined,
};

const projectProcessToProcess = createSlice({
  name: "projectProcessToProcess",
  initialState,
  reducers: {
    clearProcessList: (state) => {
      state.processList = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessToProcesses.pending, (state) => {
      state.isLoadProcessList = true;
    });
    builder.addCase(
      fetchProjectProcessToProcesses.fulfilled,
      (state, action) => {
        state.isLoadProcessList = false;
        state.processList = action.payload;
      }
    );
  },
});

export const { clearProcessList } = projectProcessToProcess.actions;
export default projectProcessToProcess;
