import { validateByBik } from "./constants";

export const bankAccount1bikValidator = async (
  data: any,
  setError: any,
  setCounter: (val: boolean) => void
) => {
  const validated = await validateByBik(data.bankAccount1bik);

  if (validated.length > 0) {
    if (validated[0]?.bik !== data.bankAccount1bik) {
      setError("bankAccount1bik", {
        message: "«БИК» не соответствует наименованию",
      });
      setCounter(true);
    }
    if (validated[0]?.name !== data.bankAccount1bank_name) {
      setError("bankAccount1bank_name", {
        message: "«Наименование» не соответствует БИК",
      });
      setCounter(true);
    }
    if (
      validated[0].correspondent_account &&
      validated[0].correspondent_account !==
        data.bankAccount1correspondent_account
    ) {
      setError("bankAccount1correspondent_account", {
        message: "«Корреспондентский счет» не соответствует БИК",
      });
      setCounter(true);
    }
  } else {
    setError("bankAccount1bik", {
      message: "«БИК» не соответствует наименованию",
    });
    setError("bankAccount1bank_name", {
      message: "«Наименование» не соответствует БИК",
    });
    setError("bankAccount1correspondent_account", {
      message: "«Корреспондентский счет» не соответствует БИК",
    });
    setCounter(true);
  }
};

export const bankAccount2bikValidator = async (
  data: any,
  setError: any,
  setCounter: (val: boolean) => void
) => {
  const validated = await validateByBik(data.bankAccount2bik);

  if (validated.length > 0) {
    if (validated[0]?.bik !== data.bankAccount2bik) {
      setError("bankAccount2bik", {
        message: "«БИК» не соответствует наименованию",
      });
      setCounter(true);
    }
    if (validated[0]?.name !== data.bankAccount2bank_name) {
      setError("bankAccount2bank_name", {
        message: "«Наименование» не соответствует БИК",
      });
      setCounter(true);
    }
    if (
      validated[0].correspondent_account &&
      validated[0].correspondent_account !==
        data.bankAccount2correspondent_account
    ) {
      setError("bankAccount2correspondent_account", {
        message: "«Корреспондентский счет» не соответствует БИК",
      });
      setCounter(true);
    }
  } else {
    setError("bankAccount2bik", {
      message: "«БИК» не соответствует наименованию",
    });
    setError("bankAccount2bank_name", {
      message: "«Наименование» не соответствует БИК",
    });
    setError("bankAccount2correspondent_account", {
      message: "«Корреспондентский счет» не соответствует БИК",
    });
    setCounter(true);
  }
};

export const treasuryAccount1bikValidator = async (
  data: any,
  setError: any,
  setCounter: (val: boolean) => void
) => {
  const validated = await validateByBik(data.treasuryAccount1bik);

  if (validated.length > 0) {
    if (validated[0]?.bik !== data.treasuryAccount1bik) {
      setError("treasuryAccount1bik", {
        message: "«БИК» не соответствует наименованию",
      });
      setCounter(true);
    }
    if (validated[0]?.name !== data.treasuryAccount1bank_name) {
      setError("treasuryAccount1bank_name", {
        message: "«Наименование» не соответствует БИК",
      });
      setCounter(true);
    }
    if (
      validated[0].correspondent_account &&
      validated[0].correspondent_account !==
        data.treasuryAccount1correspondent_account
    ) {
      setError("treasuryAccount1correspondent_account", {
        message: "«Корреспондентский счет» не соответствует БИК",
      });
      setCounter(true);
    }
    if (validated[0].inn && validated[0].inn !== data.treasuryAccount1inn) {
      setError("treasuryAccount1inn", {
        message: "«ИНН» не соответствует БИК",
      });
      setCounter(true);
    }
  } else {
    setError("treasuryAccount1bik", {
      message: "«БИК» не соответствует наименованию",
    });
    setError("treasuryAccount1bank_name", {
      message: "«Наименование» не соответствует БИК",
    });
    setError("treasuryAccount1correspondent_account", {
      message: "«Корреспондентский счет» не соответствует БИК",
    });
    setError("treasuryAccount1inn", { message: "«ИНН» не соответствует БИК" });
    setCounter(true);
  }
};
