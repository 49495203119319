import React from "react";

export const ArrowLeftSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="18"
    viewBox="0 0 28 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4274 9.00118C27.4274 8.75254 27.3264 8.51409 27.1465 8.33827C26.9667 8.16246 26.7228 8.06368 26.4685 8.06368H3.85247L9.88763 2.16493C9.97678 2.07777 10.0475 1.97429 10.0958 1.8604C10.144 1.74652 10.1688 1.62445 10.1688 1.50118C10.1688 1.37791 10.144 1.25585 10.0958 1.14197C10.0475 1.02808 9.97678 0.924599 9.88763 0.837434C9.79848 0.750269 9.69264 0.681126 9.57616 0.633953C9.45967 0.58678 9.33483 0.5625 9.20875 0.5625C9.08267 0.5625 8.95782 0.58678 8.84134 0.633953C8.72485 0.681126 8.61902 0.750269 8.52986 0.837434L0.858854 8.33743C0.769558 8.42452 0.69871 8.52797 0.650371 8.64187C0.602031 8.75577 0.577148 8.87787 0.577148 9.00118C0.577148 9.1245 0.602031 9.2466 0.650371 9.3605C0.69871 9.47439 0.769558 9.57785 0.858854 9.66493L8.52986 17.1649C8.61902 17.2521 8.72485 17.3212 8.84134 17.3684C8.95782 17.4156 9.08267 17.4399 9.20875 17.4399C9.33483 17.4399 9.45967 17.4156 9.57616 17.3684C9.69264 17.3212 9.79848 17.2521 9.88763 17.1649C9.97678 17.0778 10.0475 16.9743 10.0958 16.8604C10.144 16.7465 10.1688 16.6245 10.1688 16.5012C10.1688 16.3779 10.144 16.2559 10.0958 16.142C10.0475 16.0281 9.97678 15.9246 9.88763 15.8374L3.85247 9.93868H26.4685C26.7228 9.93868 26.9667 9.83991 27.1465 9.6641C27.3264 9.48828 27.4274 9.24982 27.4274 9.00118Z"
      fill="#838A93"
    />
  </svg>
);
