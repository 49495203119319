import React, { FC, useEffect } from "react";
import classNames from "classnames";
import classes from "./NewModal.module.scss";
import { ModalCloseIcon } from "../SVG";
import { NewModalPreloader } from "./NewModalPreloader";
import { setCheckInput } from "../../../app/feature/ProjectView/projectView";
import { useAppDispatch } from "../../../app/store";

interface ModalProp {
  active: boolean;
  setActive: (active: boolean) => void;
  isLoad?: boolean;
  activeClass?: string;
  title?: string;
}

export const NewModal: FC<ModalProp> = ({
  active,
  setActive,
  children,
  isLoad,
  activeClass,
  title = "",
}) => {
  const dispatch = useAppDispatch();
  const handleClosePopap = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 27) {
      setActive(false);
      dispatch(setCheckInput(false));
    }
  };

  function closeModal() {
    dispatch(setCheckInput(false));
    setActive(false);
  }

  useEffect(() => {
    const handleClosePopap = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleClosePopap);

    return () => {
      document.removeEventListener("keydown", handleClosePopap);
    };
  }, []);

  const activeModal = classNames({
    [classes.active_modal]: active,
    [classes.modal]: !active,
  });
  const activeContent = classNames(
    {
      [classes.active_content]: active,
      [classes.modal__content]: !active,
    },
    activeClass
  );

  return (
    <div
      role="button"
      tabIndex={0}
      className={activeModal}
      // onClick={() => setActive(false)}
      onKeyDown={(event) => handleClosePopap(event)}
    >
      <div
        role="button"
        tabIndex={0}
        className={activeContent}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => handleClosePopap(event)}
      >
        <div className={classes.header}>
          <h1>{title}</h1>
          <div
            className={classes.btnClose}
            onKeyDown={(event) => handleClosePopap(event)}
            onClick={closeModal}
          >
            <ModalCloseIcon />
          </div>
        </div>
        {isLoad && <NewModalPreloader />}
        {active && !isLoad ? children : null}
      </div>
    </div>
  );
};
