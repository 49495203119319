import { useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import DefaultAvatar from "../../../../../../../../../media/images/avatarDefault.jpg";
import { helpersYears } from "../../../../../../../../../utils/helpersYears";
import { useAppDispatch } from "../../../../../../../../../app/store";
import styles from "./styles.module.scss";
import { TextLink } from "../../../../../../TextLink/TextLink";
import {
  delegateProjectAccess,
  getProjectAccessByProject,
} from "../../../../../../../../../app/feature/ProjectView/AdminReducer/thunks";
import CustomCheckbox from "../../../../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { StarRating } from "../../../../../../../../newUI/SVG";

type AvatarProjectViewProps = {
  project: any;
};

const AvatarProjectView = ({ project }: AvatarProjectViewProps) => {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();

  const handleToggleDelegation = async () => {
    setCheckboxDisabled(true);
    await dispatch(
      delegateProjectAccess({
        accessId: project?.id as number,
        delegate: !project?.isDelegated,
      })
    );
    await dispatch(getProjectAccessByProject(Number(params.id)));
    setCheckboxDisabled(false);
  };

  const isDelegated = project?.isDelegated;
  const showCheckbox = project?.canDelegate;
  const showDelegationLabel = showCheckbox || isDelegated;
  const hasRating = Boolean(project.rating);

  const DelegationCheckbox = () => (
    <label className={cn(styles.delegationCheckbox)}>
      <p>Делегирование прав</p>
      {showCheckbox && (
        <CustomCheckbox
          editing={!checkboxDisabled}
          disabled={checkboxDisabled}
          onChange={handleToggleDelegation}
          checked={Boolean(project?.isDelegated)}
        />
      )}
    </label>
  );

  return (
    <div className={styles.avatar}>
      <div
        className={cn(
          styles.info,
          hasRating ? styles.ratingHeight : styles.noRatingMargin
        )}
      >
        {project.link ? (
          <>
            <img
              src={project.avatar || DefaultAvatar}
              alt="Аватар пользователя"
            />
            <div className={styles.info__credentials}>
              <TextLink
                redirect
                target="_blank"
                url={project.link}
                variant={styles.info__credentials__link}
              >
                {project?.name}
              </TextLink>
              <p className={styles.info__credentials__firm}>{project.firm}</p>
              {showDelegationLabel && <DelegationCheckbox />}
            </div>
          </>
        ) : (
          <p className={styles.darkFont}>{project.name}</p>
        )}
      </div>
      <div className={styles.rating}>
        <div className={styles.rating__experience}>
          {hasRating && <StarRating />}
          <p>Опыт {helpersYears(project.jobDuration)}</p>
        </div>
        <div className={styles.rating__value}>{project.rating}</div>
      </div>
    </div>
  );
};

export default AvatarProjectView;
