import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { ProjectProcessTabs } from "src/FSD/entities/projectProcess/model/tabs";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { useModalsRemoveExecution } from "src/FSD/entities/publishObject/lib/hooks/useModalsRemoveExecution";
import { useModalsRequestUpdates } from "src/FSD/entities/publishObject/lib/hooks/useModalsRequestUpdates";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useApplications } from "../lib/hooks/useApplications";
import { TabChildrenHeader, TabWarning } from "../../../../TabsPanel";
import { Application } from "./Application";
import styles from "./Applications.module.scss";
import PublishObjectModals from "../../../../publishObject/modals";

type Props = {
  projectProcessId: number;
  secure: boolean;
};

export const Applications = ({ projectProcessId, secure }: Props) => {
  const { applicationsList, currentChildTab, isApplicationsLoading } =
    useApplications(projectProcessId);

  const media = currentChildTab === ProjectProcessTabs.applications.bad && (
    <TabWarning>{WarningMessages.BAD_APPLICATIONS}</TabWarning>
  );

  const { requestUpdate, handleCloseRequestUpdate } = useModalsRequestUpdates();

  const { removeRequestExecution, handleCloseRemoveExecutionRequest } =
    useModalsRemoveExecution();

  return (
    <>
      <Modal
        isActive={removeRequestExecution.isOpen}
        handleClose={handleCloseRemoveExecutionRequest}
      >
        <PublishObjectModals.RemoveRequestExecutionForm
          taskId={removeRequestExecution.taskId!}
          header={removeRequestExecution.header}
        />
      </Modal>
      <Modal
        isActive={requestUpdate.isOpen}
        handleClose={handleCloseRequestUpdate}
      >
        <PublishObjectModals.RequestUpdateForm
          publicationObjectValues={requestUpdate}
          edit
          secure={secure}
        />
      </Modal>
      <TabChildrenHeader
        title="Заявки"
        media={media}
        mediaClassName={styles.media}
      />
      <Column maxHeight="865px">
        {isApplicationsLoading ? (
          <Skeleton.List />
        ) : (
          applicationsList.map((application: any) => (
            <Application key={application.id} application={application} />
          ))
        )}
      </Column>
    </>
  );
};
