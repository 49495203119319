import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import style from "./style.module.scss";
import CustomCheckbox from "../../../newUI/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { request } from "../../../../app/services/api/requestHandler";
import {
  ApiSetDependenciesProject,
  ApiPutEditProject,
} from "../../../../app/services/api/projectCreate/projectCreate";
import { SetState } from "../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { setRedirectVisible } from "../../../../app/feature/modalController/modalVisibility";

import { config } from "../../../../app.cofig";
import { AuthorType, UnsavedType } from "../types";
import { palette } from "../../../../styles/restyle";

type ProjectSettingsProps = {
  projectId: number;
  hidePrices: boolean;
  dependencies: boolean;
  authorType: AuthorType;
  setDependencies: SetState<boolean>;
  setUnsaved: SetState<UnsavedType>;
  setHidePrices: SetState<boolean>;
};

export const ProjectSettings: React.FC<ProjectSettingsProps> = ({
  projectId,
  hidePrices,
  dependencies,
  authorType,
  setDependencies,
  setUnsaved,
  setHidePrices,
}) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState<boolean>(false);

  const [hideEnabled, setHideEnabled] = useState<boolean>(hidePrices);
  const [depEnabled, setDepEnabled] = useState<boolean>(dependencies);

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useIsMobile(800);

  useEffect(() => {
    setUnsaved((prev) => ({
      ...prev,
      settings: depEnabled !== dependencies || hideEnabled !== hidePrices,
    }));
  }, [depEnabled, dependencies, hideEnabled, hidePrices]);

  const handleFinish = () => {
    if (depEnabled !== dependencies) {
      dispatch(setRedirectVisible());
    } else {
      window.location.href = `${config.localDomain}/project/${projectId}`;
    }
  };

  const handleSubmit = async () => {
    let isOk = true;

    setDisabled(true);
    await request(
      ApiSetDependenciesProject(projectId, {
        dependencies: depEnabled,
      }),
      () => {},
      () => (err) => {
        if (Object.keys(err).length > 0) {
          isOk = false;
        }
      }
    )();

    await request(
      ApiPutEditProject(projectId, {
        Project: {
          private: hideEnabled,
          author_type: authorType.id,
        },
      }),
      () => {},
      () => (err) => {
        if (Object.keys(err).length > 0) {
          isOk = false;
        }
      }
    )();

    if (isOk) {
      setHidePrices(hideEnabled);
      setDependencies(depEnabled);

      enqueueSnackbar("Данные успешно сохранены", {
        variant: "success",
        autoHideDuration: 5000,
      });
    } else {
      enqueueSnackbar("Ошибка сохранения данных", {
        variant: "error",
        autoHideDuration: 5000,
      });
    }

    setDisabled(false);
  };

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.title}>
          <h1>Функции управления проектом</h1>
          {!isMobile && (
            <CustomButton
              disabled={disabled}
              onClick={handleSubmit}
              background={palette.green}
              className={style.button}
            >
              Сохранить
            </CustomButton>
          )}
        </div>
        <div className={style.ticker}>
          <CustomCheckbox
            checked={depEnabled}
            onChange={() => setDepEnabled(true)}
            editing
          />
          <p>Включить зависимости</p>
        </div>
        <p>
          Исполнители (подрядчики) смогут приступать к выполнению работ в
          заданной последовательности. После получения всех исходных данных от
          смежников, будут обязаны выдавать задания смежникам, проверять
          выданную исполнителем готовую документацию на соответствие ранее
          выданным заданиям. ГИП или Заказчик не смогут принять (одобрить)
          документацию исполнителя до полного согласования этой документации
          всеми исполнителями «по цепочке».
        </p>
        <div className={style.ticker}>
          <CustomCheckbox
            checked={!depEnabled}
            onChange={() => setDepEnabled(false)}
            editing
          />
          <p>Отключить зависимости</p>
        </div>
        <p>
          При отключении зависимостей в процессе выполнения проектных работ,
          исполнители (подрядчики), нанятые ранее продолжат работу в рамках
          данного функционала. Сформированный алгоритм сохраняется на весь
          период разработки документации. Последующие опубликованные разделы и
          их исполнители, соответственно, - без функции управления проектом.
        </p>
      </div>
      <div className={style.wrapper}>
        <div className={style.title}>
          <h1>Отображение стоимости</h1>
        </div>
        <div className={style.ticker}>
          <CustomCheckbox
            checked={hideEnabled}
            onChange={() => setHideEnabled(!hideEnabled)}
            editing
          />
          <p>Скрывать стоимость разделов</p>
        </div>
        <p>
          Стоимость разделов будет скрыта для всех участников, кроме автора
          проекта, ГИПа по проекту, его помощников. Если проект создан от
          организации, то стоимость разделов будет доступна также руководителям,
          экономистам и бухгалтерам организации.
        </p>
        {isMobile && (
          <>
            <CustomButton
              disabled={disabled}
              onClick={handleSubmit}
              background={palette.green}
              className={style.button}
            >
              Сохранить
            </CustomButton>
            <CustomButton onClick={handleFinish} background={palette.green}>
              Завершить
            </CustomButton>
          </>
        )}
      </div>
    </>
  );
};
