import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const Rates = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.condition, style[theme])}>
      <div>
        <p>
          <strong>
            <b>Тариф ПИРС</b>
          </strong>
        </p>
        Создание проекта по упрощённой схеме взаимодействия:
        <ol className={style.olItem}>
          <li className={style.liNumItem}>
            Не требует полного заполнения профиля пользователя;
          </li>
          <li className={style.liNumItem}>
            Позволяет создать проект с минимальным количеством данных о проекте:
            <ol className={style.olItem}>
              <li className={style.liLetterItem}>Название проекта;</li>
              <li className={style.liLetterItem}>Регион объекта;</li>
              <li className={style.liLetterItem}>Тип строительства;</li>
              <li className={style.liLetterItem}>Банковский счёт;</li>
              <li className={style.liLetterItem}>
                Описание работ (в том числе подгружаемые документы отдельными
                файлами).
              </li>
            </ol>
          </li>
          <li className={style.liNumItem}>
            Позволяет выбирать исполнителей/подрядчиков;
          </li>
          <li className={style.liNumItem}>
            Не позволяет выдавать и получать задания от смежников (функционал
            управления проектом выключен);
          </li>
          <li className={style.liNumItem}>
            Не позволяет выполнять проверки документации смежниками (функционал
            управления проектом выключен).
          </li>
        </ol>
        <p>
          Тариф доступен Заказчикам - физические лица и самозанятые
          индивидуально, юридические лица и индивидуальные предприниматели,
          численностью пользователей не более двух
        </p>
        <table className={style.tableBordered}>
          <thead>
            <tr>
              <th className={style.paddingXv3}>№ п.п.</th>
              <th className={style.paddingXv3}>
                Количество сотрудников заказчика
              </th>
              <th className={style.paddingXv3}>Количество пользователей</th>
              <th className={style.paddingXv3}>
                Стоимость руб., без НДС, в мес.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={style.textCenter}>1</td>
              <td className={style.paddingXv3}>
                Подписка для всех форм собственности
              </td>
              <td className={style.textCenter}>1</td>
              <td className={style.textCenter}>3 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>2</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>2</td>
              <td className={style.textCenter}>7 999</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          В период действия тарифа пользователь может публиковать разделы
          проекта для поиска подрядчика на выполнение работ и выбирать
          исполнителей/подрядчиков.
        </p>
        <p>
          Формирование договоров, дополнительных соглашений, соглашений о
          расторжении, актов выполненных работ и других документов, относящихся
          к документообороту, по данному тарифу недоступны.
        </p>
        <p>
          В случае необходимости заключения договора и осуществления
          документооборота в период действия тарифа ПИРС пользователь при
          публикации раздела может выбрать сервис «Безопасная сделка». От
          пользователя перед созданием проекта потребуется полное заполнение
          профиля физического лица, а в случае регистрации на платформе ПИРС
          юридического лица или индивидуального предпринимателя, необходимо
          направить запрос посредством функционала платформы ПИРС на проверку
          данных об организации или индивидуальном предпринимателе
          администратору платформы. После согласования данных администратором
          ПИРС, пользователь может опубликовать разделы с сервисом «Безопасная
          сделка».
        </p>
        Тариф включает хранение данных:
        <ol className={style.olItem}>
          <li className={style.liNumItem}>
            Исходные данные, архив проектной и рабочей документации;
          </li>
          <li className={style.liNumItem}>
            При выборе сервиса «Безопасная сделка» - исходные данные, договоры,
            дополнительные соглашения, акты приёма-передачи выполненных
            работ/услуг, соглашения о расторжении, отчёты, архивы проектной и
            рабочей документации.
          </li>
        </ol>
      </div>
      <br />
      <div>
        <p>
          <strong>
            <b>Тариф ПИРС</b>PRO
          </strong>
        </p>
        <p>
          Создание проекта по упрощённой или стандартной схеме взаимодействия
          (на выбор пользователя).
        </p>
        Стандартная схема взаимодействия:
        <ol className={style.olItem}>
          <li className={style.liNumItem}>
            Требует полного заполнения профиля пользователя;
          </li>
          <li className={style.liNumItem}>
            Позволяет создать проект с полным количеством данных о проекте:
          </li>
          <ol className={style.olItem}>
            <li className={style.liLetterItem}>Название проекта;</li>
            <li className={style.liLetterItem}>
              Название проекта для договора;
            </li>
            <li className={style.liLetterItem}>Вид проектирования;</li>
            <li className={style.liLetterItem}>Общие сроки проектирования;</li>
            <li className={style.liLetterItem}>Вид экспертизы;</li>
            <li className={style.liLetterItem}>Страна объекта;</li>
            <li className={style.liLetterItem}>Регион объекта;</li>
            <li className={style.liLetterItem}>Тип строительства;</li>
            <li className={style.liLetterItem}>Назначение объекта;</li>
            <li className={style.liLetterItem}>
              Физические параметры объекта;
            </li>
            <li className={style.liLetterItem}>Условия оплаты;</li>
            <li className={style.liLetterItem}>Банковский счёт;</li>
            <li className={style.liLetterItem}>
              Описание работ (в том числе подгружаемые документы отдельными
              файлами).
            </li>
          </ol>
          <li className={style.liNumItem}>
            Позволяет выбирать исполнителей/подрядчиков;
          </li>
          <li className={style.liNumItem}>
            Позволяет выдавать и получать задания от смежников (функционал
            управления проектом включён);
          </li>
          <li className={style.liNumItem}>
            Позволяет выполнять проверки документации смежниками (функционал
            управления проектом включён).
          </li>
        </ol>
        <p>
          Тариф доступен Заказчикам - физическим лицам и самозанятым
          индивидуально, юридическим лицам и индивидуальным предпринимателям не
          ограничено.
        </p>
        <p>
          В зависимости от формы собственности и численности сотрудников
          компании, зарегистрированных на платформе ПИРС, формируются тарифы:
        </p>
        <table className={style.tableBordered}>
          <thead>
            <tr>
              <th className={style.paddingXv3}>№ п.п.</th>
              <th className={style.paddingXv3}>
                Количество сотрудников заказчика
              </th>
              <th className={style.paddingXv3}>Количество пользователей</th>
              <th className={style.paddingXv3}>
                Стоимость руб., без НДС, в мес.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={style.textCenter}>1</td>
              <td className={style.paddingXv3}>
                Подписка для всех форм собственности
              </td>
              <td className={style.textCenter}>1</td>
              <td className={style.textCenter}>4 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>2</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>2</td>
              <td className={style.textCenter}>7 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>3</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>от 3 до 5</td>
              <td className={style.textCenter}>14 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>4</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>от 6 до 10</td>
              <td className={style.textCenter}>24 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>5</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>от 11 до 30</td>
              <td className={style.textCenter}>39 999</td>
            </tr>
            <tr>
              <td className={style.textCenter}>6</td>
              <td className={style.paddingXv3}>
                Подписка для юридических лиц и индивидуальных предпринимателей
              </td>
              <td className={style.textCenter}>свыше 31</td>
              <td className={style.textCenter}>49 999</td>
            </tr>
          </tbody>
        </table>
        <p>
          В период действия тарифа пользователь может публиковать разделы
          проекта и выбирать исполнителей с заключением договора как с
          использованием сервиса «Безопасная сделка», так и без него.
        </p>
        <p>
          Тариф включает хранение следующих данных: исходные данные по проекту,
          договоры, дополнительные соглашения, акты приема-передачи выполненных
          работ/услуг, соглашения о расторжении, отчёты, архивы проектной и
          рабочей документации.
        </p>
      </div>
      <br />
      <div>
        <p>
          <strong>Общее для всех:</strong>
        </p>
        <p>
          При нажатии на кнопку "Выбрать" в соответствующем тарифе формируется
          счёт на оплату. После оплаты и поступления средств на расчётный счёт
          ООО «ЦП «ПИРС» администратором платформы активируется период действия
          выбранного тарифа. Период действия тарифа отображается на вкладке
          «Тарифный план» сайта. Пользователь в любой момент времени может
          перейти с тарифа <b>ПИРС</b> на тариф <b>ПИРС</b>PRO. Переход с тарифа{" "}
          <b>ПИРС</b>PRO на тариф <b>ПИРС</b> до окончания действия тарифа{" "}
          <b>ПИРС</b>PRO невозможен. При переходе с тарифного плана <b>ПИРС</b>{" "}
          на тарифный план <b>ПИРС</b>PRO до окончания действия тарифа{" "}
          <b>ПИРС</b> стоимость тарифного плана <b>ПИРС</b>PRO будет
          рассчитываться за вычетом неиспользованного остатка по тарифу{" "}
          <b>ПИРС</b> на день поступления денежных средств на расчётный счёт ООО
          «ЦП «ПИРС».
        </p>
        <p>
          Пользователь, оплативший тарифный план для выбранной формы
          собственности, может публиковать разделы и выбирать исполнителей
          только от этого лица.
        </p>
        <p>
          Публикация разделов и выбор исполнителей с использованием сервиса
          «Безопасная сделка» доступны всем пользователям без необходимости
          оплаты тарифов.
        </p>
        <p>
          Изменение стоимости тарифного плана возможно только после окончания
          действия оплаченного периода по тарифу.
        </p>
        <p>
          При изменении стоимости тарифных планов и необходимости перехода с
          тарифа <b>ПИРС</b> на тариф <b>ПИРС</b>PRO до завершения оплаченного
          периода по тарифу <b>ПИРС</b>, стоимость тарифа будет рассчитываться
          по действующей стоимости тарифа <b>ПИРС</b>PRO на дату формирования
          счёта на оплату.
        </p>
        <p>
          В случае отказа от использования платформы ПИРС до окончания действия
          оплаченного периода, пользователь обязан направить письмо на
          электронную почту{" "}
          <a href="mailto:support@pirs.online">support@pirs.online</a> в службу
          поддержки за личной подписью, если пользователь является физическим
          лицом или самозанятым, или индивидуальным предпринимателем. В случае
          юридических лиц письмо на фирменном бланке за подписью директора или
          лица его замещающего по доверенности с печатью организации.
        </p>
      </div>
    </div>
  );
};
