import { useState, useMemo, ChangeEvent } from "react";
import { config } from "../../../../../app.cofig";

const useImage = (photo: string, editing: boolean) => {
  const [photoLink, setPhotoLink] = useState<string | null>(photo);
  const [photoFile, setPhotoFile] = useState<File | null>(null);

  const clearImage = () => {
    setPhotoFile(null);
    setPhotoLink(photo);
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const link = URL.createObjectURL(file);

      setPhotoFile(file);
      setPhotoLink(link);
    }
  };

  const photoUrl = useMemo(() => {
    if (!photoLink) {
      return null;
    }

    let url = photoLink;

    if (photoLink && photoLink.includes("id=")) {
      const timeStamp = new Date().getTime();
      url = `${config.localDomain}${photoLink}&${timeStamp}`;
    }

    return url;
  }, [photo, editing, photoLink, photoFile]);

  return { photoUrl, photoFile, clearImage, handleImageUpload } as const;
};

export default useImage;
