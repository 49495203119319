import React, { FC, useState, useMemo, cloneElement } from "react";
import { IconButton } from "@mui/material";
import cn from "classnames";
import style from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardDestinations,
  setSectionId,
  setTab,
  setWorkId,
} from "../../../../../app/feature/dashboard/destinations";
import { config } from "../../../../../app.cofig";
import { TFirm } from "./Header";
import { getTabByPrompt, getTypeByPrompt } from "./utils";
import { getDashboardProjects } from "../../../../../app/feature/dashboard/projects";
import { getDashboardSections } from "../../../../../app/feature/dashboard/sections";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { ModalWrapper } from "../../Edit/PersonalDataChange/PersonalDataChange";
import ModalSourceDataReport from "./modals/ModalSourceDataReport/ModalSourceDataReport";

const reportTitles: Record<string, string> = {
  ГИП: "Отчет ГИПа",
  Заказчик: "Отчет Заказчика",
  Помощник: "Отчет Помощника",
};

const buttonColors: Record<string, string> = {
  disabled: "#6B7D8A",
  active: "#26313A",
  selected: "#3baf6a",
};

type NavButtonProps = {
  svg: JSX.Element;
  prompt: string;
  currentFirm: TFirm;
  unfulfilled?: boolean;
};

type ButtonType = "disabled" | "active" | "selected" | "none";

const NavButton: FC<NavButtonProps> = ({ svg, prompt }) => {
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile(1100);

  const [isReportOpen, setReportOpen] = useState<boolean>(false);
  const [isSourceDataReportOpen, setSourceDataReportOpen] = useState(false);

  const { data: sections, pending } = useAppSelector(getDashboardSections);
  const { projectStatus } = useAppSelector(getDashboardProjects);
  const { tab, projectId, sectionId, workId, reportLinks } = useAppSelector(
    getDashboardDestinations
  );

  const isReport = prompt === "Отчет";
  const isSourceDataReport = prompt === "Отчет по ИД";
  const isProjects = prompt === "Проекты";
  const isKanban = prompt === "Канбан";

  const treatiesDisabled = pending; // !Object.keys(sections).length || projectStatus === 1 у черновиков тоже могут быть??

  const type: ButtonType = getTypeByPrompt(
    prompt,
    projectId,
    tab,
    treatiesDisabled
  );

  const disabled = useMemo(() => {
    if (isSourceDataReport) {
      return false;
    }

    if (prompt !== "Договоры") {
      return type === "disabled";
    }

    return type === "disabled" || treatiesDisabled;
  }, [type, projectStatus, pending, sections]);

  const icon = cloneElement(svg, {
    color: type === "selected" ? "dark" : "white",
  });

  const openReport = (link: string) => {
    setReportOpen(false);
    window.open(`${config.localDomain}${link}`, "_blank");
  };

  const handleClick = () => {
    if (isSourceDataReport) {
      setSourceDataReportOpen(true);
      return;
    }

    if (isReport) {
      setReportOpen(true);
      return;
    }

    const newTab = getTabByPrompt(prompt, projectId, sectionId, workId);

    if (isProjects) {
      dispatch(setSectionId(0));
      dispatch(setWorkId(0));
    }

    dispatch(setTab(newTab));
  };

  if (type === "none" || (isReport && (!reportLinks.length || isMobile))) {
    return null;
  }

  return (
    <div className={style.navButton}>
      <IconButton
        disabled={disabled}
        style={{
          background: buttonColors[type],
        }}
        className={style.icon}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      {isReportOpen && (
        <div className={cn(style.prompt, style.reportPrompt)}>
          {reportLinks.map((reportLink: any) => {
            const linkTitle = reportTitles[reportLink?.title] ?? "";
            return (
              <p onClick={() => openReport(reportLink?.link)}>{linkTitle}</p>
            );
          })}
        </div>
      )}
      <ModalWrapper
        show={isSourceDataReportOpen}
        handleCloseModal={() => {
          setSourceDataReportOpen(false);
        }}
      >
        <ModalSourceDataReport />
      </ModalWrapper>
      {!isReportOpen && !isSourceDataReportOpen && (
        <div className={style.prompt}>{prompt}</div>
      )}
    </div>
  );
};

export default NavButton;
