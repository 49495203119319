import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { httpRequestGet } from "../../../../services/api/api";
import { config } from "../../../../../app.cofig";

const ApiProjectProcessToProcesses = (
  processId: number,
  projectId: number
): Promise<Response> => {
  // получить разделы
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-processes?processId=${processId}&projectId=${projectId}`
  );
};

export const fetchProjectProcessToProcesses = createAsyncThunk<
  Record<string, string>,
  {
    projectId: number;
    processId: number;
  }
>(
  "getList/fetchProjectProcessToProcesses",
  async ({ processId, projectId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessToProcesses(processId, projectId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
