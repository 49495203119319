import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjectProcessInTasks,
  fetchProjectProcessOutTasks,
} from "../services/fetchProjectProcessTasks";
import { processOutTasks } from "./utils";

type InitialState = {
  isLoad: boolean;
  error: any;
  inTasks: any;
  outTasks: any;
  taskActiveTab: number;
};

const initialState: InitialState = {
  isLoad: false,
  error: null,
  inTasks: null,
  outTasks: null,
  taskActiveTab: 1,
};

const projectProcessTasks = createSlice({
  name: "projectProcessTasks",
  initialState,
  reducers: {
    setTaskActiveTab: (state, action) => {
      state.taskActiveTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessInTasks.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProjectProcessInTasks.fulfilled, (state, action) => {
      state.isLoad = false;
      state.inTasks = Object.values(action.payload);
    });
    builder.addCase(fetchProjectProcessInTasks.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectProcessOutTasks.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProjectProcessOutTasks.fulfilled, (state, action) => {
      state.isLoad = false;
      const valuesOutTasks: any = action.payload
        ? Object.values(action.payload)
        : undefined;

      state.outTasks = valuesOutTasks
        ? processOutTasks(valuesOutTasks[0]?.tasks)
        : valuesOutTasks;
    });
    builder.addCase(fetchProjectProcessOutTasks.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
});

export const { setTaskActiveTab } = projectProcessTasks.actions;
export default projectProcessTasks;
