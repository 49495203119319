import React, { memo } from "react";
import classNames from "classnames";
import { ApiExecutorTypesFull } from "../../../types/api/responseTypes/apiProjectType";
import cls from "./RequiredTypes.module.scss";

interface RequiredTypesProps {
  types: ApiExecutorTypesFull;
  classname?: string;
}
export const RequiredTypes = memo(
  ({ types, classname }: RequiredTypesProps) => {
    return (
      <div className={classNames(cls.types, classname)}>
        {types &&
          types.map((type) => <span key={type.value}>{type.value}</span>)}
      </div>
    );
  }
);
