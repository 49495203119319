import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import classNames from "classnames";
import styles from "./FilesList.module.scss";

type TFileLink = {
  id: number;
  url: string;
  name: string;
};

export type TFilesList = TFileLink[];

type Props = {
  label?: string;
  fileLinks: TFileLink[] | undefined;
  className?: string;
};

export const FilesList = ({ fileLinks, label, className }: Props) => {
  return (
    <div className={classNames(styles.files, className)}>
      {label && <label className={styles.files__label}>{label}</label>}
      <div className={styles.files__list}>
        {fileLinks?.length ? (
          fileLinks.map(({ id, url, name }) => (
            <TextLink download redirect target="_blank" key={id} url={url}>
              {name}
            </TextLink>
          ))
        ) : (
          <p>Файлы отсутствуют</p>
        )}
      </div>
    </div>
  );
};
