import React from "react";
import { useSelector } from "react-redux";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import TabChildrenHeader from "src/FSD/widgets/v2/TabsPanel/ui/TabChildrenHeader/TabChildrenHeader";
import { PerformanceGraph } from "./PerformanceGraph/PerformanceGraph";
import { BudgetGraph } from "./BudgetGraph/BudgetGraph";
import { BudgetChild } from "./BudgetChild/BudgetChild";
import styles from "./style.module.scss";
import { useTabsPanelContext } from "../../../TabsPanel/ui/TabsPanel/TabsPanelContext";

export const GraphProject = () => {
  const { fact, forecast, budgetChild, plan } =
    useSelector(getProjectGraphData);
  const { project } = useSelector(getProjectViewData);
  const isTablet = useIsMobile(1600);
  const { currentChildTab } = useTabsPanelContext();

  const canGraph = !!plan?.length || !!forecast?.length || !!fact?.length;
  const canBudgetChild =
    !project?.parentId &&
    budgetChild &&
    !!budgetChild.length &&
    currentChildTab === "budget";

  const renderDesktop = (
    <>
      {currentChildTab === "dates" && <PerformanceGraph />}
      {currentChildTab === "budget" && project?.can.view && <BudgetGraph />}
      {canBudgetChild && <BudgetChild />}
    </>
  );

  return (
    <div className={styles.wrapper}>
      {canGraph && <TabChildrenHeader title="Графики" />}
      <ScrollWrap height={isTablet ? "auto" : "860"} classname={styles.gap}>
        {renderDesktop}
      </ScrollWrap>
    </div>
  );
};
