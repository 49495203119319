import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";
import {
  statusObject,
  TStatusType,
  TParams,
} from "src/FSD/entities/project/api/queries/getDocTree";

export const getDocTree = (builder: QueryBuilder) =>
  builder.query<ApiDocumentsResponses.View[], TParams>({
    query: ({ id, statusType }) => {
      const statusValue =
        statusObject[statusType as TStatusType] || statusObject.work;
      const queryString = `id=${id}&${statusValue}`;
      return {
        url: `v1/project-process/get-doc-tree?${queryString}`,
      };
    },
  });
