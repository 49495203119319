import React, { memo, useState } from "react";
import { Uploader } from "../../../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../../../app.cofig";
import { ApiGetTaskFiles } from "../../../../../../../../app/services/api/organisation/organisation";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "./ModalComplete.module.scss";
import {
  fetchProjectProcessExpertise,
  fetchProjectProcessExpertiseDuplicate,
} from "../../../../../../../../app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../../../styles/restyle";
import { taskCompleteThunk } from "../../../../../../../../app/feature/taskComplete/services/taskCompleteThunk";

export const ModalComplete = memo(({ taskId, addText, closeModal }: any) => {
  const processName = useAppSelector(getProjectProcessInfoName);
  const process = useAppSelector(getProjectProcessInfoData);
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);
  const [responseCallback, setResponseCallback] = useState<any>({});
  const canBtn =
    !responseCallback?.can?.edit && responseCallback?.can?.complete;
  async function updateFileLoad() {
    if (processId) {
      await dispatch(fetchProjectProcessExpertiseDuplicate(processId));
    }
  }

  async function completeHandler() {
    const data = {
      CompleteForm: {
        status: 6,
      },
    };
    if (processId) {
      await dispatch(taskCompleteThunk({ taskId, data }));
      dispatch(fetchProjectProcessExpertise(processId));
      closeModal();
    }
  }

  return (
    <form className={cls.form}>
      <h1>
        Выдать документацию по разделу «{processName}» по проекту «
        {process?.project.name}»
      </h1>
      <Uploader
        identifier={taskId}
        autoUpload
        canBeEdited
        getDataCallback={ApiGetTaskFiles}
        responseDataCallback={(data) => setResponseCallback(data)}
        postUrlString={(id) =>
          `${config.localDomain}/v1/task/add-file?id=${id}`
        }
        handleUpdate={updateFileLoad}
        uploadAsActive
      />
      {addText && <p className={cls.addText}>{addText}</p>}
      {canBtn && !addText && (
        <CustomButton
          className={cls.form_submit}
          width={200}
          background={palette.blue}
          onClick={completeHandler}
        >
          Отправить на проверку
        </CustomButton>
      )}
    </form>
  );
});
