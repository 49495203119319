import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectProcessInfoTypes } from "../types/projectProcessInfoTypes";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessFeedbackExecutor } from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessFeedbackExecutor = createAsyncThunk<
  ProjectProcessInfoTypes,
  any
>(
  "projectProcess/fetchProjectProcessFeedbackExecutor",
  async ({ taskId, userId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessFeedbackExecutor(taskId, userId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
