import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import TransitionShowContent from "src/components/newUI/TransitionShowContent/TransitionShowContent";
import { ChildGraph } from "./ChildGraph/ChildGraph";
import styles from "../style.module.scss";
import { useTabsPanelContext } from "../../../../TabsPanel/ui/TabsPanel/TabsPanelContext";

export const BudgetChild = () => {
  const { budgetChild } = useSelector(getProjectGraphData);
  const [toggleChild, setToggleChild] = useState(false);
  const { currentChildTab } = useTabsPanelContext();

  return (
    <div className={styles.basic}>
      <div onClick={() => setToggleChild(!toggleChild)} className={styles.flex}>
        <p>Фактические затраты по объектам комплекса</p>
        <ToggleAccordion toggle={toggleChild} secondRoot />
      </div>

      <TransitionShowContent open={toggleChild}>
        {!!budgetChild.length && currentChildTab === "budget" && <ChildGraph />}
      </TransitionShowContent>
    </div>
  );
};
