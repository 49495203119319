import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CheckListResult,
  fetchProjectViewInitialData,
  fetchProjectViewInitialDataUpdate,
} from "../../../../../app/feature/ProjectView/InitialDataReducer/thunks";
import { getProjectInitialData } from "../../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { SourceDataUpdateForm } from "../../../../newUI/SourceDataUpdateForm/SourceDataUpdateForm";
import { TProjectProcessCheckList } from "../../../../../app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { useAppSelector } from "../../../../../app/store";
import { getProjectChecklist } from "../../../../../app/feature/project/projectChecklist";

type ModalInitialProps = {
  data: CheckListResult;
  handleDeleteSourceDataName: (id: number) => void;
  closeModal: () => void;
  index: number;
  parentId: number;
  name: string;
};

export const ModalInitial: React.FC<ModalInitialProps> = ({
  data,
  handleDeleteSourceDataName,
  parentId,
  name,
  closeModal,
}) => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { searchValue } = useSelector(getProjectInitialData);
  const { project } = useSelector(getProjectViewData);

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const formSubmitHandler = async (dataValue: TProjectProcessCheckList[]) => {
    const data = dataValue.map((el) => {
      return {
        id: el.id,
        select: el.author,
        executor: el.executor,
        critical: el.critical,
        hidden: el.hidden,
        name: el.name,
      };
    });
    const { id } = params;
    if (id) {
      await dispatch(
        fetchProjectViewInitialDataUpdate({
          update: { id, data, parentId, type },
        })
      );
      closeModal();
      dispatch(fetchProjectViewInitialData({ id: +id, searchValue, type }));
    }
  };

  return (
    <SourceDataUpdateForm
      name={name}
      formSubmitHandler={formSubmitHandler}
      checkList={data.result}
      closeModal={closeModal}
      canExecutorEdit={!!project?.can?.edit}
      handleDeleteSourceDataName={handleDeleteSourceDataName}
    />
  );
};
