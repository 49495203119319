import React from "react";
import { styled } from "@mui/material/styles";
import { MenuProps } from "@mui/material";
import MenuMui from "@mui/material/Menu";
import styles from "./Menu.module.scss";

export const Menu = styled((props: MenuProps) => (
  <>
    {props.open && <div className={styles.blackout} />}
    <MenuMui
      elevation={0}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  </>
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    backgroundColor: "#0B1016",
    padding: "10px 0",
    minWidth: 180,
    color: "#D5D6DE",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      display: "flex",
      flexDirection: "column",
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Manrope",
      position: "relative",
      transition: "0.3s ease",
      "&:not(:last-child)": {
        "&:after": {
          content: '""',
          borderBottom: "1px solid #101920",
          position: "absolute",
          bottom: 0,
          left: 20,
          right: 20,
        },
      },
      "&:has(a)": {
        padding: 0,
        "> a": {
          padding: "10px 20px",
          flex: "1 0 auto",
        },
      },
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
}));
