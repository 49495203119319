export const clientAppRoutePatterns: RegExp[] = [
  // паттерны текущих роутов клиента
  /\/user\/new-dashboard/,
  /\/user\/\d/,
  /\/user\/search-(executors|gip|providers|customers)/,
  /\/project\/search/,
  /\/notice\/index/,
  /\/project-process\/search/,
  /\/chat\/\D/,
  /\/blog/,
];

export const isMatchPatterns = (str: string, patterns: RegExp[]): boolean => {
  if (!str) return false;
  return patterns.reduce<boolean>(
    (acc, pattern) => acc || new RegExp(pattern).test(str),
    false
  );
};

/*
  Фунция, что проверяет, есть ли роут в приложении
  @example
  if (!isClientRoute(link)) {
    window.location.href = `...`; // полный редирект на страницу бекенда
  } else {
    history.push(
      history.createHref({
        pathname: `...`, // переход на внутренний клиентский роут
      })
    );
  }
  @remark Чаще всего нужна для того, чтобы понять делать редирект на клиент или на бэк страницы
 */
export const isClientRoute = (path: string) =>
  isMatchPatterns(path, clientAppRoutePatterns);
