import React, { useState } from "react";
import classNames from "classnames";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ChecksType } from "src/app/feature/ProjectView/CheckTaskReducer/thunks";
import cls from "./ChecksCard.module.scss";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import { TaskCard } from "./TaskCard/TaskCard";
import { ScrollWrap } from "../ScrollWrap/ScrollWrap";
import { NewModal } from "../NewModal/NewModal";
import { ModalComment } from "../NewTaskCard/Version/ModalComment/ModalComment";

const sxYellow = { color: "#E8A037", fontSize: "12px" };
export const ChecksCard = (props: ChecksType) => {
  const {
    statusText,
    comment,
    code,
    tasks,
    taskId,
    dot,
    executor,
    name,
    date,
    color,
  } = props;
  const [modalComment, setModalComment] = useState(false);
  return (
    <div className={classNames(cls.wrapper, cls[color])}>
      <div className={cls.info}>
        <div className={cls.info_code}>
          <div className={cls.info_code_dot}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "10px",
              }}
            >
              <FiberManualRecordIcon sx={sxYellow} />
            </div>
            <TooltipWrapper content={name} classname={cls.code}>
              <p>{code}</p>
            </TooltipWrapper>
          </div>
          <p>{executor}</p>
          <div className={cls.info_code_status}>
            <p>{statusText}</p>
            <span>{date}</span>
          </div>
        </div>
        <div className={cls.info_comment}>
          <p>Комментарий</p>
          <div
            className={cls.info_comment_text}
            onClick={() => setModalComment(true)}
          >
            {comment}
          </div>
          <span onClick={() => setModalComment(true)}>
            История комментариев
          </span>
          <NewModal
            active={modalComment}
            setActive={setModalComment}
            activeClass={cls.modal}
            title={"История комментариев"}
          >
            <ModalComment taskId={taskId} />
          </NewModal>
        </div>
      </div>
      <div className={cls.tasks}>
        <p>{`Согласующие(${tasks?.length})`}</p>
        <ScrollWrap height={"128px"} classname={cls.scroll}>
          {tasks?.map((task) => <TaskCard key={task.id} {...task} />)}
        </ScrollWrap>
      </div>
    </div>
  );
};
