import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { ProcessWorkModel } from "../../../../../entities/processWork";
import { useThreeDotButton } from "../../../../../shared/uiKit/v2/ThreeDotButton/useThreeDotButton";

export const useModals = () => {
  const dispatch = useAppDispatch();

  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const { workPublication } = useAppSelector(
    ProcessWorkModel.modals.getModalsData
  );

  const handleOpenWorkPublication = () => {
    dispatch(ProcessWorkModel.modals.setWorkPublicationActive(true));
    closeThreeDot();
  };

  const handleCloseWorkPublication = () => {
    dispatch(ProcessWorkModel.modals.setWorkPublicationActive(false));
  };

  return {
    workPublication,
    handleCloseWorkPublication,
    threeDotButtonRef,
    handleOpenWorkPublication,
  } as const;
};
