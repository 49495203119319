import React, { memo } from "react";
import cls from "./ObjectBudgetGraph.module.scss";
import { trimPrice } from "../../../../../../../utils/trimNumber";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";

export const ObjectBudgetGraph = memo(({ object }: any) => {
  const value = object.gantt;
  const isProject = object.gantt.project;
  const isWork = object.gantt.work;
  const isResearch = object.gantt.research;
  const isInspection = object.gantt.inspection;
  const { name } = object.project;
  const isMobile = useIsMobile(801);

  const inspectionRender = isInspection && (
    <div className={cls.object_price}>
      <div
        className={cls.object_price_dot}
        style={{ background: value.inspection.color }}
      />
      <p>{value.inspection?.title}</p>
      <p>{`${trimPrice(value.inspection?.price)} ₽`} </p>
    </div>
  );

  const projectRender = isProject && (
    <div className={cls.object_price}>
      <div
        className={cls.object_price_dot}
        style={{ background: value.project.color }}
      />
      <p>{value.project?.title}</p>
      <p>{`${trimPrice(value.project?.price)} ₽`} </p>
    </div>
  );

  const workRender = isWork && (
    <div className={cls.object_price}>
      <div
        className={cls.object_price_dot}
        style={{ background: value.work.color }}
      />
      <p>{value.work?.title}</p>
      <p>{`${trimPrice(value.work?.price)} ₽`} </p>
    </div>
  );

  const researchRender = isResearch && (
    <div className={cls.object_price}>
      <div
        className={cls.object_price_dot}
        style={{ background: value.research.color }}
      />
      <p>{value.research?.title}</p>
      <p>{`${trimPrice(value.research?.price)} ₽`} </p>
    </div>
  );

  const allPriceRender = (
    <div className={cls.object_price}>
      <div
        className={cls.object_price_dot}
        style={{ background: object.priceFact.color }}
      />
      <p>Всего</p>
      <p>{`${trimPrice(object.priceFact.price)} ₽`} </p>
    </div>
  );

  return (
    <>
      <span className={cls.object_name}>{name}</span>
      {!isMobile && (
        <div className={cls.object}>
          <span>Факт</span>
          <div className={cls.object_container}>{inspectionRender}</div>
          <div className={cls.object_container}> {researchRender}</div>
          <div className={cls.object_container}> {projectRender}</div>
          <div className={cls.object_container}> {workRender}</div>
          <div className={cls.object_container}>{allPriceRender}</div>
        </div>
      )}
      {isMobile && (
        <div className={cls.object}>
          {inspectionRender}
          {researchRender}
          {projectRender}
          {workRender}
          {allPriceRender}
        </div>
      )}
    </>
  );
});
