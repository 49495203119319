import { useState, MouseEvent } from "react";
import { CircularProgress, ClickAwayListener, Popper } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { CheckSquareFill } from "react-bootstrap-icons";
import classNames from "classnames";
import styles from "./Popper.module.scss";
import { NotApproveIcon } from "../../../../../components/newUI/SVG";
import { DateField } from "../DateField";
import { Nullable } from "../../../../../types/generics";
import { DDMMYYYYToDate } from "../../../lib/helpers";

type TDates = {
  date: Nullable<Date>;
};

type Props = {
  canEdit: boolean;
  title: string;
  date: Nullable<string>;
  isMobile?: boolean;
  onChange: (dates: TDates) => void;
  isLoading: boolean;
  className?: string;
};

export const DateFieldPopper = ({
  canEdit,
  date,
  onChange,
  isLoading,
  isMobile = false,
  title,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const dateParsed = date ? DDMMYYYYToDate(date) : null;

  const { control } = useForm({
    defaultValues: {
      date: dateParsed,
    },
  });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "simple-popper" : undefined;

  const handleChangeDateStart = async (date: Nullable<Date>) => {
    await onChange({
      date,
    });
  };

  return (
    <div className={styles.text}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <span
            className={classNames(
              styles.text_edit,
              !canEdit && styles.text_noEdit
            )}
            onClick={handleClick}
          >
            {date}
          </span>
          {isOpen && canEdit && (
            // @ts-ignore
            <Popper
              id={id}
              open={isOpen}
              anchorEl={anchorEl}
              placement={isMobile ? "bottom" : "left-start"}
            >
              <form className={styles.createData}>
                <div className={styles.createData_flex}>
                  <h3>{title}</h3>
                  <button
                    className={styles.button}
                    onClick={() => setAnchorEl(null)}
                  >
                    <NotApproveIcon />
                  </button>
                </div>
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { value, onChange: onChangeValue } }) => (
                    <DateField
                      disableClear
                      changeDateHandler={(date) => {
                        handleChangeDateStart(date);
                        onChangeValue(date);
                      }}
                      startDateProp={value}
                      className={styles.createData__field}
                    />
                  )}
                />
                <div className={styles.createData__submitButton}>
                  {isLoading ? (
                    <div className={styles.createData__submitButton__preloader}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <button className={styles.button} onClick={handleClickAway}>
                      <CheckSquareFill />
                    </button>
                  )}
                </div>
              </form>
            </Popper>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
