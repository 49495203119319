import moment from "moment";
import {
  OrganisationPageInfoType,
  OrganisationPageStaffType,
  OrganisationPageTaskType,
  defaultFirmAttorney,
} from "./initialState";
import { ApiOrganisationDataType } from "../../../types/api/responseTypes/apiOrganisationType";

export const createOrganisationInfo = (payload: ApiOrganisationDataType) => {
  const data: OrganisationPageInfoType = {
    id: payload.id,
    photo: payload.photo,
    title: payload.title,
    title_full: payload.title_full,
    is_ip: payload.is_ip,
    inn: payload.inn,
    ogrn: payload.ogrn,
    kpp: payload.kpp,
    boss: payload.boss
      ? {
          name: `${payload.boss.surname} ${payload.boss.name} ${payload.boss.patronymic}`,
          id: payload.boss.id,
        }
      : null,
    country: {
      id: payload.region.country.code,
      title: payload.region.country.title,
    },
    region_code: {
      id: payload.region.code,
      title: payload.region.title,
    },
    city: payload.city,
    address: payload.address,
    address_fact: payload.address_fact,
    tested: payload.tested,
    phone: payload.phone,
    email: payload?.email ?? payload?.boss?.email ?? "",
    nds: payload?.nds
      ? {
          id: payload?.nds.key,
          title: payload?.nds.compact,
        }
      : null,
    bank_name: payload.bank_name,
    correspondent_account: payload.correspondent_account,
    bank_account: payload.bank_account,
    employees: null,
    account_id: payload.account_id,
    manager_post: payload.manager_post,
    manager_name: payload.manager_name,
    doc_type: payload.doc_type
      ? {
          id: payload.doc_type.key,
          title: payload.doc_type.title,
        }
      : null,
    doc_num: payload.firmAttorney?.num,
    membersCount: payload.membersCount,
    doc_date_reg: payload.firmAttorney?.date_sign
      ? moment(payload.firmAttorney?.date_sign, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        )
      : null,
    doc_date_start: payload.firmAttorney?.date_start
      ? moment(payload.firmAttorney?.date_start, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        )
      : null,
    doc_date_end: payload.firmAttorney?.date_end
      ? moment(payload.firmAttorney?.date_end, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        )
      : null,
    boss_prof: payload.boss_prof,
    gipBudget: payload.gip_budget,
    milestone: payload.milestone,
    joinToFirmTasks: payload.joinToFirmTasks,
    firmAttorney: payload.firmAttorney ?? defaultFirmAttorney,
    canBeFree: payload.can_be_free,
    promote: payload.promote,
    site: payload.site ?? "",
    dayOfWeek: payload.day_of_week,
    numberOfWeeks: payload.number_of_weeks,
  };

  return data;
};

export const createOrganisationStaff = (payload: ApiOrganisationDataType) => {
  const data: OrganisationPageStaffType = payload.map((item: any) => ({
    id: item.id,
    user_id: item.user_id,
    can: item.can,
    role: item?.role?.name,
    role_id: item.role_id,
    skills: item.user.skills,
    boss: item.firmBoss
      ? item.firmBoss.boss_prof || item.firmBoss.manager_post
      : "",
    name: `${item.user?.surname} ${item.user?.name} ${item.user?.patronymic}`,
    accountId: item.gipAccountId,
    photo: item.user?.photo ?? "",
    teams: [...item.teams],
    firmAttorney: item?.firmAttorney?.length ? item.firmAttorney[0] : null,
  }));

  return data;
};

export const createOrganisationTask = (payload: any) => {
  const data: OrganisationPageTaskType = payload
    ? {
        id: payload.id,
        date_create: payload.date_create,
        date_complete: payload.date_complete,
        content: payload.content,
        files: payload.files
          ? payload.files.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
          : null,
        comment: payload.comment,
        status: payload.status,
      }
    : null;

  return data;
};

export const createOrganisationBank = (payload: any) => {
  const data: any = payload.map((item: any) => {
    return {
      id: item.id,
      firm_id: item.firm_id,
      bik: item.bik,
      bank_name: item.bank_name,
      correspondent_account: item.correspondent_account,
      bank_account: item.bank_account,
      default: item.default,
      inn: item.inn,
      kpp: item.kpp,
      ufk: item.ufk,
      client_number: item.client_number,
      analysis_code: item.analysis_code,
      type: item.type,
    };
  });

  return data;
};

export const createOrganisationHistory = (payload: any) => {
  const data: any = payload.map((item: any) => {
    return {
      date: item.date_create,
      user: {
        id: item.user.id,
        userFio: `${item.user.surname} ${item.user.name} ${
          item.user.patronymic ?? ""
        }`,
      },
      type: item.type,
      data: item.data,
    };
  });

  return data;
};
