import React, { FC } from "react";
import cn from "classnames";
import { IconButton } from "@mui/material";
import globalStyle from "../../Primary/style.module.scss";
import style from "../style.module.scss";
import { TaskProps } from "../types";
import { config } from "../../../../../../app.cofig";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getDashboardDestinations,
  setTab,
  setWorkId,
} from "../../../../../../app/feature/dashboard/destinations";
import { Price } from "../Sections/Section";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { OpenIcon } from "../../../../../newUI/SVG";

const useTaskLink = ({
  type,
  workflow,
  partId,
  workOrTaskId,
}: {
  type: string;
  workflow: Record<string, number>;
  partId: number;
  workOrTaskId: number;
  tab: string | undefined;
}) => {
  const { sectionId } = useAppSelector(getDashboardDestinations);

  // ссылки для раздел->задача

  if (type === "incoming") {
    // входящие
    const processId = workflow?.processId;
    const fromProcessId = workflow?.fromProcessId;
    return `/project-process/${sectionId}?tab=input-task&#action=input-task-${partId}/action=input-task-version-${processId}-${fromProcessId}`;
  }

  if (type === "outcoming") {
    // исходящие
    const processId = workflow?.processId ?? "";
    const toProcessId = workflow?.toProcessId ?? "";
    return `/project-process/${sectionId}?tab=output-task&#action=output-task-${partId}/action=output-task-version-${processId}-${toProcessId}`;
  }

  if (type === "check") {
    // проверка документации
    return `/project-process/${sectionId}?tab=sub-check-task`;
  }

  if (type === "documentations") {
    // гот. документация
    return `/project-process/${sectionId}?tab=out-sub-check-task`;
  }

  if (type === "aligns") {
    // пред. решения
    return `/project-process/${sectionId}?tab=out-sub-check-task`;
  }

  if (type === "work") {
    // задача
    return `/process-work/view?id=${workOrTaskId}`;
  }

  // ссылки для задача->задача

  const workId = workflow?.workId ?? workOrTaskId;

  if (type === "taskCheck") {
    // проверки
    return `/process-work/view?id=${workId}&tab=sub-check-task`;
  }

  if (type === "task") {
    // гот. документация
    return `/process-work/view?id=${workId}&tab=work-task`;
  }

  if (type === "customerAlign") {
    // пред. решения
    return `/process-work/view?id=${workId}&tab=customer-align`;
  }

  return "";
};

// Для задания
const Task: FC<TaskProps> = ({
  id,
  name,
  date,
  executorName,
  count,
  type,
  workflow,
  partId,
  countAccesses,
  short,
  executorNds,
  price,
  secure,
  tab: taskTab,
}) => {
  const dispatch = useAppDispatch();

  const { tab, workId } = useAppSelector(getDashboardDestinations);

  const isSelected = id === workId;

  const link = useTaskLink({
    type,
    workflow,
    partId,
    workOrTaskId: id,
    tab: taskTab,
  });

  const isMobile = useIsMobile(1600);

  const isRealMobile = useIsMobile(1100);

  const isAdaptive = isMobile || short;

  const openTask = () => {
    window.open(`${config.localDomain}${link}`, "_blank");
  };

  const handleSetTaskApplications = () => {
    if (type === "work") {
      dispatch(setWorkId(id));

      if (!tab.includes("work->")) {
        dispatch(setTab("work->tasks"));
      }

      return;
    }

    if (tab.includes("work->") && isAdaptive) {
      return;
    }

    openTask();
  };

  const Dates = () => {
    if (isRealMobile) {
      const dates = date.split(" • ");
      return (
        <h2 className={cn(style.taskDate, isAdaptive && style.taskDateShort)}>
          {dates.map((date: string) => (
            <p>{date}</p>
          ))}
        </h2>
      );
    }

    return (
      <h2 className={cn(style.taskDate, isAdaptive && style.taskDateShort)}>
        {date}
      </h2>
    );
  };

  const executorExists = Boolean(executorName[0]?.length);

  const nameElement = executorExists ? (
    <div className={cn(style.executors, isAdaptive && style.executorsShort)}>
      {executorName.map((name: string) => (
        <b>{name}</b>
      ))}
    </div>
  ) : (
    <div
      className={cn(
        style.appliance,
        style.leftSide,
        isAdaptive && style.applicationCounts
      )}
    >
      <p>Заявки</p>
      <h3>{countAccesses}</h3>
    </div>
  );

  const codeElement = (
    <div
      className={cn(
        style.code,
        style.longCode,
        !executorExists && style.taskCodeMobile
      )}
    >
      <p className={style.taskLink}>{name}</p>
      {count !== null && <p>(Вер. {count})</p>}
    </div>
  );

  if (isAdaptive) {
    return (
      <div
        className={cn(
          style.adaptiveTask,
          isSelected && style.selectedSectionTablette
        )}
      >
        <div
          onClick={handleSetTaskApplications}
          className={cn(style.shortTask, isSelected && globalStyle.selected)}
        >
          <div className={style.shortTaskSection}>
            {codeElement}
            <Price
              message={executorNds ?? ""}
              price={price ?? null}
              secure={Boolean(secure)}
              flexBasis={160}
            />
          </div>
          <div className={style.shortTaskSection}>
            {nameElement}
            <Dates />
          </div>
        </div>
        {isSelected && (
          <IconButton
            onClick={openTask}
            className={cn(
              style.openButton,
              style.sectionOpenButton,
              style.openButtonAdaptive
            )}
          >
            <OpenIcon />
          </IconButton>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={handleSetTaskApplications}
      className={cn(style.section, style.noHover)}
    >
      {codeElement}
      {nameElement}
      <Dates />
      <Price
        message={executorNds ?? ""}
        price={price ?? null}
        flexBasis={160}
        secure={Boolean(secure)}
      />
    </div>
  );
};

export default Task;
