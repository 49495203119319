import { createAsyncThunk } from "@reduxjs/toolkit";
import { pushError, pushMessage } from "../../errorTrace";
import { getErrorMessage, request } from "../../../services/api/requestHandler";
import { httpRequestGet, httpRequestPost } from "../../../services/api/api";
import { config } from "../../../../app.cofig";
import {
  TUsersToAssign,
  TUsersTypeToAssign,
  TUsersTypeToAssignProps,
} from "../../ProjectView/SectionsReducer/thunks";
import { getPreparedBody } from "./utils";
import {
  ApiGetUsersToAssign,
  ApiGetUsersTypeToAssign,
} from "../../../services/api/project/sections";

const ApiGetGroupPublishProcess = (projectId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-process/get-group-publish-process?id=${projectId}`
  );

const ApiGetExecutorFullTypes = (): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/project/get-executor-full-types`);

const ApiGroupPublication = (id: number, body: any): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/project-process/group-publish?id=${id}`,
    {},
    body
  );

export const getGroupPublishProcess = createAsyncThunk(
  "project/getGroupPublishProcess",
  async (projectId: number, { dispatch }) => {
    let processes: any = [];
    await request(
      ApiGetGroupPublishProcess(projectId),
      (response) => {
        processes = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return processes;
  }
);

export const fetchGetUsersToAssign = createAsyncThunk<TUsersToAssign, number>(
  "fetchGetUsersToAssign/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiGetUsersToAssign(id);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGetUsersTypeToAssign = createAsyncThunk<
  TUsersTypeToAssign,
  TUsersTypeToAssignProps
>("fetchGetUsersTypeToAssign/fetch", async (props, { rejectWithValue }) => {
  const { id, executorValue } = props;
  try {
    const response = await ApiGetUsersTypeToAssign(id, executorValue);
    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const getExecutorFullTypes = createAsyncThunk(
  "project/getExecutorFullTypes",
  async (_, { dispatch }) => {
    let types: any = [];
    await request(
      ApiGetExecutorFullTypes(),
      (response) => {
        types = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return types;
  }
);

export const groupPublication = createAsyncThunk(
  "project/groupPublication",
  async (
    {
      processId,
      body,
      isAddExecutorBtn,
    }: { processId: number; body: any; isAddExecutorBtn: boolean },
    { dispatch, rejectWithValue }
  ) => {
    const preparedBody = getPreparedBody(body, isAddExecutorBtn);
    let hasError = false;

    await request(
      ApiGroupPublication(processId, preparedBody),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        hasError = true;
      }
    )();

    if (!hasError) {
      return;
    }

    return rejectWithValue("");
  }
);
