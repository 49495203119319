import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DeleteButtonIcon, GipHelpersIcon } from "src/components/newUI/SVG";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import {
  fetchGipHelperApplicationDelete,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import styles from "../GipCard.module.scss";

const sx = { color: "white" };
export const GipHelperApplications = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { project } = useSelector(getProjectViewData);
  const { isLoadGipHelperApplication, isLoadGipHelperAppInvite } =
    useSelector(getProjectViewData);
  const [gipAcceptModal, setGipAcceptModal] = useState(false);

  const closeGipAcceptModal = () => {
    setGipAcceptModal(false);
  };

  const deleteGipHelperRequest = async (id: string | number) => {
    const result = window.confirm("Вы уверены, что хотите отменить заявку?");
    if (result && params.id) {
      const deleteGip = {
        projectId: params.id,
        userId: id,
        active: false,
      };
      await dispatch(fetchGipHelperApplicationDelete({ deleteGip }));
      if (params.id) {
        await dispatch(fetchProjectViewSearchGip(params.id));
      }
    }
  };
  return (
    <div className={styles.gip_applications}>
      {!!project?.gipHelpersApplication.length && (
        <>
          <h4>Мои приглашения</h4>
          {project?.gipHelpersApplication.map((el) => (
            <div className={styles.gip_applications_block} key={el.fio}>
              <p>{el.fio}</p>
              {el.can.delete && (
                <IconButton
                  onClick={() => deleteGipHelperRequest(el.userId)}
                  className={styles.gip_helpers_icon}
                  title="Отменить заявку"
                  sx={sx}
                >
                  <DeleteButtonIcon width="20" height="20" />
                </IconButton>
              )}
              {el.can.accept && (
                <>
                  <Modal
                    isActive={gipAcceptModal}
                    handleClose={closeGipAcceptModal}
                  >
                    <ProjectModals.GipHelperAccept
                      id={el.taskId}
                      projectName={project?.name}
                      isLoad={isLoadGipHelperAppInvite}
                    />
                  </Modal>
                  <IconButton
                    className={styles.gip_helpers_icon}
                    title="Рассмотреть"
                    sx={sx}
                    onClick={() => setGipAcceptModal(true)}
                  >
                    <GipHelpersIcon width="20" height="20" />
                  </IconButton>
                </>
              )}
            </div>
          ))}
        </>
      )}
      {isLoadGipHelperApplication && (
        <div className={styles.gip_helpers_load}>
          <CircularIndeterminate />
        </div>
      )}
    </div>
  );
};
