import { createReducer, Dispatch } from "@reduxjs/toolkit";

import { ApiGetProjectPart } from "../../../services/api/projectPart/projectPart";
import { StateType } from "../../../initialState";
import { isBadStatusRequest } from "../../../services/api/api";

export const projectPartInitialState = [];

export const projectPartReducer = createReducer(
  projectPartInitialState,
  (builder) =>
    builder.addCase("SET_PROJECT_PART", (state, action) => {
      // @ts-ignore
      return action.payload;
    })
);

export const fetchProjectPartData = () => (dispatch: Dispatch) => {
  ApiGetProjectPart()
    .then((x) => x.json())
    .then((x) => {
      if (isBadStatusRequest(x.code)) {
        throw new Error(x.message);
      } else {
        return x;
      }
    })
    .then((x) => dispatch({ type: "SET_PROJECT_PART", payload: x }))
    .catch(console.log);
};

export const selectorProjectPartData = (state: StateType) => state.projectPart;
