import { forwardRef } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { useRequestUpdate } from "../lib/hooks/useSelectUserTypes";

type Props = { setValue?: any; userTypeId?: number } & Omit<
  SelectProps,
  "label" | "options"
>;

export const SelectUserTypes = forwardRef(
  (
    {
      value,
      setValue,
      userTypeId,
      className = "",
      disabled = false,
      error = false,
      helperText = "",
      ...props
    }: Props,
    ref
  ) => {
    const { userTypes, isPendingUserTypes } = useRequestUpdate({
      setValue,
      userTypeId,
    });

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={userTypes ?? []}
        isLoading={isPendingUserTypes}
        label="Форма собственности"
        className={className}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    );
  }
);
