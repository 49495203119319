import React, { memo } from "react";
import { useParams } from "react-router-dom";
import style from "../../../../Project/View/InitialData/style.module.scss";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { httpRequestGet } from "../../../../../../app/services/api/api";

interface ModalFilesProps {
  modalClose: () => void;
}
export const ModalFiles = memo(({ modalClose }: ModalFilesProps) => {
  const params = useParams<{ id: string }>();
  const ApiGetTaskFile = (id: number | string): Promise<Response> => {
    return httpRequestGet(
      `${config.localDomain}/v1/project-process/view?id=${id}&expand=files.svgIcon`
    );
  };
  return (
    <form>
      <h1 className={style.name}>Загрузить файлы</h1>
      <Uploader
        identifier={params.id || ""}
        autoUpload
        canBeEdited
        getDataCallback={ApiGetTaskFile}
        postUrlString={(id) =>
          `${config.localDomain}/v1/project-process/add-file?id=${id}`
        }
      />
      <div className={style.uploaderFile}>
        <CustomButton
          width={120}
          onClick={modalClose}
          background="#9BACB9"
          color="#15191c"
        >
          Закрыть
        </CustomButton>
      </div>
    </form>
  );
});
