import { KanbanColumns, KanbanStatuses } from "src/FSD/entities/kanban";

export const getTaskPosition = (position: string) => {
  if (position === KanbanColumns.ALL_TASKS) {
    return {
      rowId: KanbanStatuses[KanbanColumns.ALL_TASKS],
      columnId: KanbanStatuses[KanbanColumns.ALL_TASKS],
    };
  }

  const [columnId, rowId] = position.split(".");
  return {
    columnId: Number.isNaN(Number(columnId)) ? columnId : Number(columnId),
    rowId: Number.isNaN(Number(rowId)) ? rowId : Number(rowId),
  };
};
