import { styled } from "@mui/material/styles";
import { MenuProps } from "@mui/material";
import Menu from "@mui/material/Menu";
import React from "react";
import cls from "./NewMenu.module.scss";

export const NewMenu = styled((props: MenuProps) => (
  <>
    {props.open && <div className={cls.blackout} />}
    <Menu
      elevation={0}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  </>
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    backgroundColor: "#0B1016",
    padding: "20px 10px",
    minWidth: 180,
    color: "#D5D6DE",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      padding: "3px 0",
      borderBottom: "1px solid #101920",
      "&:hover": {
        opacity: 0.8,
      },
      fontSize: "16px",
      fontWeight: 400,
    },
  },
}));
