import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { palette } from "src/styles/restyle";

const sx = { bgcolor: palette.darkSlate };

export const SkeletonWorks = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={109} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={90} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={90} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={90} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={90} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={90} />
      </Grid>
    </Grid>
  );
};
