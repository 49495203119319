import { useProcessWorkApplicationQuery } from "src/FSD/entities/processWork/api";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { useTabsPanelContext } from "../../../../TabsPanel";

export const useApplications = (processWorkId: number) => {
  const { data: applications, isFetching } = useProcessWorkApplicationQuery({
    id: processWorkId,
  });

  const { currentChildTab } = useTabsPanelContext();

  const applicationsList =
    applications?.[
      currentChildTab as keyof ApiProcessWorkResponses.Application
    ] || [];

  return {
    applicationsList,
    currentChildTab,
    isApplicationsLoading: !applications || isFetching,
  } as const;
};
