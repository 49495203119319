import { memo, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getProjectProcessFiles,
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoisLoad,
  getProjectProcessInfoPublished,
} from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "./Info.module.scss";
import { PayBlock } from "./PayBlock/PayBlock";
import { TermsBlock } from "./TermsBlock/TermsBlock";
import Avatar from "../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { getExecutorProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { TreatyBlock } from "./TreatyBlock/TreatyBlock";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { trim } from "../../User/View/Portfolio/Portfolio";
import { RequiredTypes } from "../../../newUI/RequiredTypes/RequiredTypes";
import Modal from "../../../newUI/Modal/Modal";
import { ModalRemoveExecutor } from "./ModalRemoveExecutor/ModalRemoveExecutor";
import {
  getProjectProcessInfoWarningActual,
  getProjectProcessInfoWarningInput,
  getProjectProcessInfoWarningOutput,
  getProjectProcessInfoWarningRequired,
} from "../../../../app/feature/ProjectProcessView/Info/selectors/getWarningProcessInfo";
import { WarningCard } from "../../../newUI/WarningCard/WarningCard";
import { getCanProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { ModalFeedbackExecutor } from "./ModalFeedbackExecutor/ModalFeedbackExecutor";
import { getFeedbackExecutorIsLoad } from "../../../../app/feature/ProjectProcessView/Info/selectors/getFeedbackExecutorIsLoad";
import { getCanTaskProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getCanTaskProcessInfo";
import { ModalEditExecutor } from "./ModalEditExecutor/ModalEditExecutor";
import { getUserUpdateIsLoad } from "../../../../app/feature/ProjectProcessView/Info/selectors/getUserUpdate";
import { fetchProjectProcessApplications } from "../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import {
  getProjectProcessBadApplications,
  getProjectProcessGoodApplications,
} from "../../../../app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import {
  ExecutorProcessInfoSkeleton,
  SkeletonInfoProjectProcess,
} from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { ModalCompleteRemove } from "./ModalCompleteRemove/ModalCompleteRemove";
import { ModalRequest } from "./ModalRequest/ModalRequest";
import { getProjectProcessRequestIsLoad } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";
import { getApplicationsRemoveRequestSuccess } from "../../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsRemoveRequest";
import {
  RefType,
  ThreeDotButton,
} from "../../../newUI/ThreeDotButton/ThreeDotButton";
import {
  getRemoveExecutorCompleteIsLoad,
  getRemoveExecutorIsLoad,
} from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { getTabShowProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";

interface InfoProps {
  setApplicationsTab: () => void;
}
export const Info = memo(({ setApplicationsTab }: InfoProps) => {
  const dispatch = useAppDispatch();
  const threeDotButtonRef = useRef<RefType>(null);

  const infoData = useAppSelector(getProjectProcessInfoData);
  const tabShow = useAppSelector(getTabShowProcessInfo);
  const published = useAppSelector(getProjectProcessInfoPublished);
  const executor = useAppSelector(getExecutorProcessInfo);
  const files = useAppSelector(getProjectProcessFiles);
  const isLoad = useAppSelector(getProjectProcessInfoisLoad);
  const isLoadExecutorFeedback = useAppSelector(getFeedbackExecutorIsLoad);
  const warningsInput = useAppSelector(getProjectProcessInfoWarningInput);
  const warningsActual = useAppSelector(getProjectProcessInfoWarningActual);
  const warningsOutput = useAppSelector(getProjectProcessInfoWarningOutput);
  const warningRequired = useAppSelector(getProjectProcessInfoWarningRequired);
  const can = useAppSelector(getCanProcessInfo);
  const canTask = useAppSelector(getCanTaskProcessInfo);
  const isLoadUserUpdate = useAppSelector(getUserUpdateIsLoad);
  const infoId = useAppSelector(getProjectProcessInfoId);
  const goodApplications = useSelector(getProjectProcessGoodApplications);
  const badApplications = useSelector(getProjectProcessBadApplications);
  const isLoadRequest = useAppSelector(getProjectProcessRequestIsLoad);
  const removeExecutorIsLoad = useAppSelector(getRemoveExecutorIsLoad);
  const removeRequestSuccess = useAppSelector(
    getApplicationsRemoveRequestSuccess
  );
  const isLoadRemoveExecutorComplete = useAppSelector(
    getRemoveExecutorCompleteIsLoad
  );

  const [modalExecutor, setModalExecutor] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalEditExecutor, setModalEditExecutor] = useState(false);
  const [modalCompleteRemove, setModalCompleteRemove] = useState(false);
  const [modalRequest, setModalRequest] = useState(false);

  const countApplications =
    (goodApplications?.length || 0) + (badApplications?.length || 0);
  const canThreeDotBtn =
    can?.requestRemoveExecutor ||
    can?.updateExecutor ||
    infoData?.activeAccess?.taskRemove?.can?.complete ||
    canTask?.feedbackToExecutor;

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const modalExecutorClose = useCallback(() => {
    setModalExecutor(false);
  }, []);

  const modalFeedbackClose = useCallback(() => {
    setModalFeedback(false);
  }, []);

  const modalCompleteRemoveClose = useCallback(() => {
    setModalCompleteRemove(false);
  }, []);

  const closeModalRequest = useCallback(() => {
    setModalRequest(false);
  }, []);

  function closeModalEditExecutor() {
    setModalEditExecutor(false);
  }

  function removeExecutor() {
    setModalExecutor(true);
    closeThreeDot();
  }

  function editExecutor() {
    setModalEditExecutor(true);
    closeThreeDot();
  }

  function completeRemove() {
    setModalCompleteRemove(true);
    closeThreeDot();
  }

  function feedBackExecutor() {
    setModalFeedback(true);
    closeThreeDot();
  }

  useEffect(() => {
    if (infoId && tabShow?.applications?.show) {
      dispatch(fetchProjectProcessApplications(infoId));
    }
  }, [infoId, removeRequestSuccess]);

  if (isLoad) {
    return <SkeletonInfoProjectProcess />;
  }

  if (infoData) {
    return (
      <div className={cls.info}>
        <WarningCard
          warningsInput={warningsInput}
          warningsActual={warningsActual}
          warningsOutput={warningsOutput}
          warningRequired={warningRequired}
        />
        <div className={cls.block}>
          <PayBlock {...infoData} deposit={infoData.account?.value || 0.0} />
          <TermsBlock {...infoData} />
        </div>
        <div className={cls.block}>
          {executor && (
            <div className={classNames(cls.card, cls.avatar)}>
              {can?.requestRemoveExecutor && (
                <Modal
                  active={modalExecutor}
                  setActive={setModalExecutor}
                  isLoad={removeExecutorIsLoad}
                >
                  <ModalRemoveExecutor
                    projectPart={infoData.projectPart}
                    projectName={infoData.project.name}
                    closeModal={modalExecutorClose}
                  />
                </Modal>
              )}
              <Modal
                active={modalCompleteRemove}
                setActive={setModalCompleteRemove}
              >
                <ModalCompleteRemove closeModal={modalCompleteRemoveClose} />
              </Modal>
              {canTask?.feedbackToExecutor && (
                <Modal
                  active={modalFeedback}
                  setActive={setModalFeedback}
                  isLoad={isLoadExecutorFeedback}
                >
                  <ModalFeedbackExecutor modalClose={modalFeedbackClose} />
                </Modal>
              )}
              {can?.updateExecutor && (
                <Modal
                  active={modalEditExecutor}
                  setActive={setModalEditExecutor}
                  isLoad={isLoadUserUpdate}
                >
                  <ModalEditExecutor onClose={closeModalEditExecutor} />
                </Modal>
              )}
              {isLoadRequest ||
              isLoadExecutorFeedback ||
              isLoadUserUpdate ||
              isLoadRemoveExecutorComplete ? (
                <ExecutorProcessInfoSkeleton />
              ) : (
                <>
                  <div className={cls.avatar_header}>
                    <h1>Исполнитель</h1>
                    <div className={cls.buttons}>
                      {canThreeDotBtn && (
                        <ThreeDotButton ref={threeDotButtonRef}>
                          {can?.requestRemoveExecutor && (
                            <MenuItem onClick={removeExecutor} disableRipple>
                              Снять исполнителя
                            </MenuItem>
                          )}
                          {can?.updateExecutor && (
                            <MenuItem onClick={editExecutor} disableRipple>
                              Сменить исполнителя
                            </MenuItem>
                          )}
                          {infoData?.activeAccess?.taskRemove?.can
                            ?.complete && (
                            <MenuItem onClick={completeRemove} disableRipple>
                              Рассмотреть конфликт
                            </MenuItem>
                          )}
                          {canTask?.feedbackToExecutor && (
                            <MenuItem onClick={feedBackExecutor} disableRipple>
                              Оценить
                            </MenuItem>
                          )}
                        </ThreeDotButton>
                      )}
                    </div>
                  </div>
                  <Avatar data={[executor]} projectView />
                </>
              )}
            </div>
          )}
          {!executor && (
            <div className={classNames(cls.card, cls.types)}>
              <div className={cls.card_header}>
                <h1>Исполнитель</h1>
                {can?.requestExecution && (
                  <Modal
                    active={modalRequest}
                    setActive={setModalRequest}
                    isLoad={isLoadRequest}
                  >
                    <ModalRequest closeModal={closeModalRequest} />
                  </Modal>
                )}
                {can?.requestExecution && (
                  <CustomButton
                    width={160}
                    background={palette.green}
                    onClick={() => setModalRequest(true)}
                  >
                    Заявиться
                  </CustomButton>
                )}
              </div>
              {infoData.status.key === 1 && (
                <div className={cls.types_block}>
                  <p>Нет исполнителя</p>
                </div>
              )}
              {infoData.status.key !== 1 && (
                <div className={cls.types_block}>
                  <p>Требуемая форма собственности</p>
                  <div className={cls.types_applications}>
                    <RequiredTypes types={infoData?.executorType} />
                    {countApplications > 0 && (
                      <div className={cls.applications}>
                        <p>Заявок</p>
                        <span onClick={setApplicationsTab}>
                          {countApplications}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <TreatyBlock {...infoData} />
        </div>
        {files && files.length > 0 && published && (
          <div className={classNames(cls.files, cls.card)}>
            <h1>Исходные данные</h1>
            <div className={cls.files_flex}>
              {files &&
                files.map((el) => (
                  <TextLink
                    key={el?.url}
                    variant={cls.textLink}
                    redirect
                    url={el?.url}
                  >
                    {trim(el?.name)}
                  </TextLink>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
});
