import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../app/services/api/requestHandler";
import { ApiGetProjectProcessToProcesses } from "./queries";
import { ThunkArg } from "../../../lib/types/thunkArg";

export const getProjectProcessToProcesses = createAsyncThunk(
  "projectProcess/getProjectProcessToProcesses",
  ({
    data: { id, projectId, processId },
    events,
  }: ThunkArg<{ id: number; projectId: number; processId: number }>) => {
    events.onPending?.();

    request(
      ApiGetProjectProcessToProcesses(id, projectId, processId, 1),
      (data) => {
        events.onFulfilled?.(data);
      },
      () => (err) => {
        events.onRejected?.(err);
      }
    )();
  }
);
