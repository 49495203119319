import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiProjectResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: string | undefined;
}>;

const expands = [
  "processPublishedCost",
  "industry",
  "accountId",
  "files",
  "expertise",
  "buildingFunction",
  "fundType",
  "requestRemoveGipTask",
  "projectParameters",
  "gipProcess",
  "designType",
  "can",
  "constructionType",
  "files",
  "gipHelpers",
  "gipAccess",
  "gipAccess.userType",
  "chatId",
  "authorAccess",
  "tabs",
  "gipHelpersApplication",
  "expertise",
  "seismicity",
  "resultFilesSize",
  "authorPremium",
];

export const view = (builder: QueryBuilder) =>
  builder.query<ApiProjectResponses.View, TParams>({
    query: (params) => ({
      url: `v1/project/view`,
      params: {
        id: params.id,
        expand: concatenateParams(expands),
      },
    }),
    providesTags: ["Project"],
  });
