import React, { memo, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import styles from "./ModalEditGip.module.scss";
import {
  getGipList,
  getGipTypeIsLoad,
  getGipTypeList,
  getisLoadGip,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import {
  fetchGipList,
  fetchGipTypeList,
  fetchProjectComplexObject,
  fetchProjectGipUpdate,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/thunks";
import { useAppDispatch } from "../../../../../../../app/store";
import { SelectField } from "../../../../../../newUI/SelectMui/SelectMui";

interface IModalEditGipProps {
  id: number;
  gipAccess: {
    id: number;
    user_id: number;
    user_type_id: number;
  };
  modalClose: () => void;
}

type TInputs = {
  gip: string;
  gipType: string;
};

export const ModalEditGip = memo(
  ({ id, gipAccess, modalClose }: IModalEditGipProps) => {
    const gipList = useSelector(getGipList);
    const isLoadGip = useSelector(getisLoadGip);
    const gipTypeList = useSelector(getGipTypeList);
    const isLoadGipType = useSelector(getGipTypeIsLoad);

    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const [valueGip, setValueGip] = useState("");
    const [typeValueGip, setTypeValueGip] = useState("");

    useEffect(() => {
      dispatch(fetchGipList(id));
    }, []);

    useEffect(() => {
      if (gipAccess.user_id) {
        setValueGip(gipAccess.user_id.toString());
      }
    }, [gipAccess]);

    useEffect(() => {
      if (gipAccess.user_type_id && gipTypeList) {
        setTypeValueGip(gipAccess.user_type_id.toString());
      }
    }, [gipAccess, gipTypeList]);

    const { handleSubmit, setValue } = useForm<TInputs>({
      defaultValues: {
        gip: gipAccess.user_id.toString(),
        gipType: gipAccess.user_type_id.toString(),
      },
    });

    const handleChangeUser = (event: SelectChangeEvent) => {
      setValueGip(event.target.value);
      setValue("gip", event.target.value);
    };

    const handleChangeType = (event: SelectChangeEvent) => {
      setTypeValueGip(event.target.value);
      setValue("gipType", event.target.value);
    };

    useEffect(() => {
      if (valueGip) {
        dispatch(fetchGipTypeList({ id, gipId: +valueGip }));
      }
    }, [valueGip]);

    const formSubmitHandler = async (dataValue: TInputs) => {
      const data = {
        ProjectAccess: {
          access_code: 4,
          user_id: +dataValue.gip,
          user_type_id: +dataValue.gipType,
        },
      };
      await dispatch(fetchProjectGipUpdate({ id: gipAccess.id, data }));
      if (params.id) {
        await dispatch(fetchProjectComplexObject({ id: +params.id }));
      }
      modalClose();
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={styles.form}>
        <SelectField
          label={""}
          name="gip"
          options={Object.entries(gipList)}
          onChange={handleChangeUser}
          value={valueGip}
          disabled={isLoadGip}
          newDesign
          newLabel={"ГИП"}
          classname={styles.select}
        />
        <SelectField
          label={""}
          name="gipType"
          options={Object.entries(gipTypeList)}
          disabled={isLoadGipType}
          onChange={handleChangeType}
          value={typeValueGip}
          newDesign
          newLabel={"Форма собственности"}
          classname={styles.select}
        />
        <div className={styles.form_submit}>
          <Button type="submit">Сменить Гипа</Button>
        </div>
      </form>
    );
  }
);
