import { KanbanType } from "src/FSD/entities/kanban";

export const checkGroup = (
  task: KanbanType.Task,
  destinationRowId: number | string,
  groupBy: KanbanType.GroupByValue
) => {
  const { id } = groupBy;

  if (id === "skill") {
    return task.partGroup.id;
  }

  if (id === "type") {
    return task.workFlowClass;
  }

  return destinationRowId;
};
