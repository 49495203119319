import { createReducer, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { StateType } from "../../initialState";

export const modalCloseReducer = createReducer(false, (builder) => {
  // @ts-ignore
  builder.addCase(
    C.ON_MODAL_SUCCESS,
    (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  );
});

export const setModalClosed = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: C.ON_MODAL_SUCCESS, payload: data });

export const selectorModalClosed = (state: StateType) => state.modalClose;
