import React, { memo } from "react";
import { Clock } from "react-bootstrap-icons";
import cls from "./SourceDataView.module.scss";

interface CommentIconTypeProps {
  status: string;
}

const CommentSuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
      fill="#0AA699"
    />
    <path
      d="M10.9703 4.97004C10.9632 4.97694 10.9565 4.98429 10.9503 4.99204L7.47734 9.41704L5.38434 7.32304C5.24216 7.19056 5.05412 7.11843 4.85982 7.12186C4.66551 7.12529 4.48013 7.204 4.34272 7.34141C4.2053 7.47883 4.12659 7.66421 4.12316 7.85851C4.11974 8.05281 4.19186 8.24086 4.32434 8.38304L6.97034 11.03C7.04162 11.1012 7.1265 11.1573 7.21992 11.1949C7.31334 11.2325 7.41339 11.251 7.51408 11.2491C7.61478 11.2472 7.71407 11.2251 7.80604 11.184C7.898 11.143 7.98074 11.0838 8.04934 11.01L12.0413 6.02004C12.1773 5.87737 12.2516 5.68693 12.2482 5.4899C12.2449 5.29288 12.1641 5.10508 12.0234 4.96713C11.8827 4.82918 11.6933 4.75214 11.4963 4.75269C11.2992 4.75323 11.1103 4.83131 10.9703 4.97004Z"
      fill="#0AA699"
    />
  </svg>
);

const CommentInProcessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="8"
    viewBox="0 0 2 8"
    fill="none"
  >
    <path
      d="M0.00195312 7.00012C0.00195312 6.8688 0.0278188 6.73876 0.0780735 6.61743C0.128328 6.49611 0.201988 6.38587 0.294847 6.29301C0.387705 6.20015 0.497944 6.12649 0.61927 6.07624C0.740595 6.02598 0.870631 6.00012 1.00195 6.00012C1.13328 6.00012 1.26331 6.02598 1.38464 6.07624C1.50596 6.12649 1.6162 6.20015 1.70906 6.29301C1.80192 6.38587 1.87558 6.49611 1.92583 6.61743C1.97609 6.73876 2.00195 6.8688 2.00195 7.00012C2.00195 7.26533 1.8966 7.51969 1.70906 7.70722C1.52152 7.89476 1.26717 8.00012 1.00195 8.00012C0.736737 8.00012 0.482383 7.89476 0.294847 7.70722C0.10731 7.51969 0.00195312 7.26533 0.00195312 7.00012ZM0.0999532 0.995117C0.0866364 0.868958 0.099992 0.741409 0.139153 0.620745C0.178315 0.500081 0.242407 0.388999 0.327272 0.294704C0.412137 0.20041 0.515878 0.125011 0.631764 0.0733995C0.747651 0.0217881 0.873093 -0.00488281 0.999953 -0.00488281C1.12681 -0.00488281 1.25226 0.0217881 1.36814 0.0733995C1.48403 0.125011 1.58777 0.20041 1.67263 0.294704C1.7575 0.388999 1.82159 0.500081 1.86075 0.620745C1.89991 0.741409 1.91327 0.868958 1.89995 0.995117L1.54995 4.50212C1.53819 4.63989 1.47516 4.76823 1.37331 4.86175C1.27146 4.95528 1.13823 5.00717 0.999953 5.00717C0.861681 5.00717 0.728444 4.95528 0.626597 4.86175C0.524751 4.76823 0.461713 4.63989 0.449953 4.50212L0.0999532 0.995117Z"
      fill="#F39508"
    />
  </svg>
);

export const CommentIconType = memo(({ status }: CommentIconTypeProps) => {
  return (
    <div className={cls.commentIcon}>
      {status === "success" && <CommentSuccessIcon />}
      {status === "undefined" && (
        <div className={cls.commentIcon_flex}>
          <CommentInProcessIcon />
        </div>
      )}
      {status === "inprocess" && <Clock fontSize={16} />}
    </div>
  );
});
