import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchConclusionExpertise } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/services/fetchConclusionExpertise";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertiseConclusion,
  getExpertiseConclusionIsLoad,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseConclusion/selectors/getExpertiseConclusion";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import cls from "./ConclusionExpertise.module.scss";
import { ConclusionCard } from "./ConclusionCard/ConclusionCard";
import { ExpertiseConclusionsTab } from "../../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";

export const ConclusionExpertise = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const processId = useAppSelector(getProjectProcessInfoId);
  const conclusions = useAppSelector(getExpertiseConclusion);
  const isLoadConclusions = useAppSelector(getExpertiseConclusionIsLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (processId) {
      dispatch(fetchConclusionExpertise(processId));
    }
  }, [processId]);

  if (isLoadConclusions) {
    return <ExpertiseConclusionsTab />;
  }

  return (
    <div className={cls.wrapper}>
      {conclusions?.map((conclusion) => <ConclusionCard {...conclusion} />)}
    </div>
  );
});
