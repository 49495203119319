import { ChangeEvent, useState } from "react";
import { getFileSizeInMb } from "./utils";
import { config } from "../../../../../app.cofig";

export type TAttorneyFileError = {
  required: boolean;
  size: boolean;
};

export type TFile = {
  url: string;
  name: string;
};

const initialFileError = {
  required: false,
  size: false,
};

const emptyFile = {
  url: "",
  name: "",
};

const useAttorneyFile = (file: any) => {
  const initialAttorneyFile = file
    ? {
        url: `${config.localDomain}${file.url}`,
        name: file.name,
      }
    : emptyFile;

  const [attorneyFileError, setAttorneyFileError] =
    useState<TAttorneyFileError>(initialFileError);
  const [attorneyFile, setAttorneyFile] = useState<TFile>(initialAttorneyFile);
  const [fileForUploading, setFileForUploading] = useState<File | boolean>(
    Boolean(file)
  );

  const handleAttorneyFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length) {
      const { name, size } = files[0];
      const fileSizeInMb = getFileSizeInMb(size);
      const url = URL.createObjectURL(files[0]);

      setAttorneyFile({ name, url });
      setFileForUploading(files[0]);

      setAttorneyFileError({
        required: false,
        size: fileSizeInMb > 5,
      });
      e.target.value = "";
    }
  };

  const clearAttorneyFile = () => {
    setAttorneyFile(initialAttorneyFile);
    setAttorneyFileError(initialFileError);
  };

  return {
    fileForUploading,
    setFileForUploading,
    attorneyFile,
    attorneyFileError,
    handleAttorneyFileChange,
    setAttorneyFileError,
    clearAttorneyFile,
  } as const;
};

export default useAttorneyFile;
