export enum Statuses {
  UNREADY = 1,
  WORK = 2,
  DONE = 3,
  ACCEPT = 4,
  REJECT = 5,
  IS_DONE = 6,
  IS_REJECT = 7,
  CANCELLED = 8,
  WAITING_REQUIRED = 9,
  WAITING_SUBCHECK = 10,
  WAITING_DOCUMENTSIGN = 11,
  WAITING_ACT_SIGN = 12,
  EXPERTISE = 14,
  PAUSED = 15,
  PREAGREED = 16,
  PLAN = 17,
  VERIFICATION = 18,
}
