import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertisePartGroup } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";
import { ExpertisePartGroupType } from "../types/expertisePartGroupType";

export const fetchExpertisePartGroup = createAsyncThunk<
  ExpertisePartGroupType[]
>("expertise/fetchExpertisePartGroup", async (_, { dispatch }) => {
  let response: any = {};
  await request(
    ApiExpertisePartGroup(),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
