import {
  setRequestRemoveExecutorActive,
  setUpdateExecutorActive,
  setFeedBackExecutorActive,
  setConsiderConflictActive,
  setRequestExecutionActive,
  setUpdateProcessWorkActive,
  setCheckCustomerAlignActive,
  setExecuteCompleteActive,
  setWorkPublicationActive,
  getModalsData,
} from "./modalsSlice";

export const modals = {
  setRequestRemoveExecutorActive,
  setUpdateExecutorActive,
  setFeedBackExecutorActive,
  setConsiderConflictActive,
  setRequestExecutionActive,
  setUpdateProcessWorkActive,
  setCheckCustomerAlignActive,
  setExecuteCompleteActive,
  setWorkPublicationActive,
  getModalsData,
};

export { processWorkSlice as sliceReducer } from "./slice";
