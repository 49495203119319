import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessApplications } from "../../../../services/api/projectProcess/applications";
import { ApplicationsTypeApi } from "../types/projectProcessApplicationTypes";

export const fetchProjectProcessApplications = createAsyncThunk<
  ApplicationsTypeApi,
  number
>(
  "projectProcess/fetchProjectProcessApplications",
  async (id, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessApplications(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
