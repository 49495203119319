import React, { memo } from "react";
import { TCheckTask } from "../../../../../../app/feature/ProjectProcessView/CheckTask/types/projectProcessCheckTaskTypes";
import { Accordion } from "../../../../../newUI/Accordion/Accordion";
import { Task } from "./Task/Task";
import cls from "./CheckTaskCard.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectProcessOpenTask } from "../../../../../../app/feature/ProjectProcessView/CheckTask/selectors/getProjectProcessOpenTask";
import { setOpenTask } from "../../../../../../app/feature/ProjectProcessView/CheckTask/slice/projectProcessCheckTask";

export const CheckTaskCard: React.FC<
  TCheckTask & { index: number; id: number }
> = memo(({ partName, tasks, dot, count, index, setModalData, id }) => {
  const openTask = useAppSelector(getProjectProcessOpenTask);
  const dispatch = useAppDispatch();
  const dotStatus = dot ? "warning" : undefined;
  const tasksArray = Object.entries(tasks);

  const toggleAccordion = (index: number) => {
    dispatch(setOpenTask(index));
  };

  return (
    <>
      <div onClick={() => toggleAccordion(index)}>
        <Accordion
          classname={cls.accordion}
          dotStatus={dotStatus}
          dotSize={10}
          text={`${partName} (${count})`}
          toogle={openTask[index]}
        />
      </div>
      {!!openTask[index] && (
        <div className={cls.cardWrapper}>
          {tasksArray.map(([taskId, task], index) => (
            <div key={index}>
              <Task {...task} setModalData={setModalData} taskId={+taskId} />
            </div>
          ))}
        </div>
      )}
    </>
  );
});
