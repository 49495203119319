import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessPause } from "../services/fetchProjectProcessPause";
import { fetchProjectProcessStopped } from "../services/fetchProjectProcessStopped";
import { InitialStateStoppedType } from "../types/projectProcessStoppedTypes";
import { fetchProjectProcessCreateStop } from "../services/fetchProjectProcessCreateStop";
import { fetchProjectProcessDeposit } from "../services/fetchProjectProcessDeposit";
import { fetchUserTypes } from "../services/fetchUserTypes";
import { fetchProjectProcessBank } from "../services/fetchProjectProcessBank";
import { fetchProjectProcessRequest } from "../services/fetchProjectProcessRequest";
import { fetchProjectProcessCreateWork } from "../services/fetchProjectProcessCreateWork";
import { fetchProjectProcessRequestPreliminary } from "../services/fetchProjectProcessRequestPreliminary";
import { fetchProjectProcessDonePreliminary } from "../services/fetchProjectProcessDonePreliminary";
import { fetchProjectProcessToProcesses } from "../services/fetchProjectProcessToProcesses";
import { fetchProjectProcessTaskComplete } from "../services/fetchProjectProcessTaskComplete";
import { fetchProjectProcessCreateTask } from "../services/fetchProjectProcessCreateTask";
import { fetchRequestTask } from "../services/fetchRequestTask";

type InitialState = {
  isLoadPause: boolean;
  isLoadGetStopped: boolean;
  isLoadCreateStop: boolean;
  isLoadDeposit: boolean;
  isLoadUserTypes: boolean;
  isLoadExecutorBank: boolean;
  isLoadRequest: boolean;
  isLoadCreateWork: boolean;
  isLoadRequestPreliminary: boolean;
  isLoadDonePreliminary: boolean;
  isLoadToProcesses: boolean;
  isLoadTaskComplete: boolean;
  isLoadCreateTask: boolean;
  isLoadRequestTask: boolean;
  typeToProcesses: Record<string, string>;
  createWork: boolean;
  requestSuccess: boolean;
  requestTaskSuccess: boolean;
  error422: any;
  error: any;
  executorBank: any;
  userTypes: any;
  stopped: InitialStateStoppedType[] | undefined;
};

const initialState: InitialState = {
  isLoadPause: false,
  isLoadGetStopped: false,
  isLoadCreateStop: false,
  isLoadDeposit: false,
  isLoadUserTypes: false,
  isLoadExecutorBank: false,
  isLoadRequest: false,
  isLoadCreateWork: false,
  isLoadRequestPreliminary: false,
  isLoadDonePreliminary: false,
  isLoadToProcesses: false,
  isLoadTaskComplete: false,
  isLoadCreateTask: false,
  isLoadRequestTask: false,
  typeToProcesses: {},
  createWork: false,
  requestSuccess: false,
  requestTaskSuccess: false,
  error422: undefined,
  error: undefined,
  executorBank: undefined,
  userTypes: undefined,
  stopped: undefined,
};

const projectProcessHeaderBtn = createSlice({
  name: "projectProcessHeaderBtn",
  initialState,
  reducers: {
    setNullRequest: (state) => {
      state.requestSuccess = false;
      state.requestTaskSuccess = false;
    },
    setErrorClear(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessPause.pending, (state) => {
      state.isLoadPause = true;
    });
    builder.addCase(fetchProjectProcessPause.fulfilled, (state) => {
      state.isLoadPause = false;
    });
    builder.addCase(fetchProjectProcessStopped.pending, (state) => {
      state.isLoadGetStopped = true;
    });
    builder.addCase(fetchProjectProcessStopped.fulfilled, (state, action) => {
      state.isLoadGetStopped = false;
      state.stopped = action.payload.map((stop) => {
        return { ...stop, checked: !!stop.date_complete };
      });
    });
    builder.addCase(fetchProjectProcessCreateStop.pending, (state) => {
      state.isLoadCreateStop = true;
    });
    builder.addCase(fetchProjectProcessCreateStop.fulfilled, (state) => {
      state.isLoadCreateStop = false;
    });
    builder.addCase(fetchProjectProcessDeposit.pending, (state) => {
      state.isLoadDeposit = true;
    });
    builder.addCase(fetchProjectProcessDeposit.fulfilled, (state) => {
      state.isLoadDeposit = false;
    });
    builder.addCase(fetchUserTypes.pending, (state) => {
      state.isLoadUserTypes = true;
    });
    builder.addCase(fetchUserTypes.fulfilled, (state, action) => {
      state.isLoadUserTypes = false;
      state.userTypes = action.payload.userTypes;
    });
    builder.addCase(fetchProjectProcessBank.pending, (state) => {
      state.isLoadExecutorBank = true;
    });
    builder.addCase(fetchProjectProcessBank.fulfilled, (state, action) => {
      state.isLoadExecutorBank = false;
      state.executorBank = action.payload;
    });
    builder.addCase(fetchProjectProcessRequest.pending, (state) => {
      state.isLoadRequest = true;
      state.error = undefined;
      state.error422 = undefined;
      state.requestSuccess = false;
    });
    builder.addCase(fetchProjectProcessRequest.fulfilled, (state) => {
      state.isLoadRequest = false;
      state.error = undefined;
      state.error422 = undefined;
      state.requestSuccess = true;
    });
    builder.addCase(fetchProjectProcessRequest.rejected, (state, action) => {
      state.isLoadRequest = false;
      if (action?.payload?.status === "none") {
        state.error = action.payload.message;
      }
      if (action?.payload?.status === "422") {
        state.error422 = action.payload.message;
      }
    });
    builder.addCase(fetchRequestTask.pending, (state) => {
      state.isLoadRequestTask = true;
      state.error422 = undefined;
      state.requestTaskSuccess = false;
    });
    builder.addCase(fetchRequestTask.fulfilled, (state) => {
      state.error422 = undefined;
      state.requestTaskSuccess = true;
      state.isLoadRequestTask = false;
    });
    builder.addCase(fetchRequestTask.rejected, (state, action) => {
      state.isLoadRequestTask = false;
      if (action?.payload?.status === "422") {
        state.error422 = action.payload.message;
      }
    });
    builder.addCase(fetchProjectProcessCreateWork.pending, (state) => {
      state.isLoadCreateWork = true;
    });
    builder.addCase(fetchProjectProcessCreateWork.fulfilled, (state) => {
      state.isLoadCreateWork = false;
      state.createWork = true;
    });
    builder.addCase(fetchProjectProcessCreateTask.pending, (state) => {
      state.isLoadCreateTask = true;
    });
    builder.addCase(fetchProjectProcessCreateTask.fulfilled, (state) => {
      state.isLoadCreateTask = false;
    });
    builder.addCase(fetchProjectProcessRequestPreliminary.pending, (state) => {
      state.isLoadRequestPreliminary = true;
    });
    builder.addCase(
      fetchProjectProcessRequestPreliminary.fulfilled,
      (state) => {
        state.isLoadRequestPreliminary = false;
      }
    );
    builder.addCase(fetchProjectProcessDonePreliminary.pending, (state) => {
      state.isLoadDonePreliminary = true;
    });
    builder.addCase(fetchProjectProcessDonePreliminary.fulfilled, (state) => {
      state.isLoadDonePreliminary = false;
    });
    builder.addCase(fetchProjectProcessToProcesses.pending, (state) => {
      state.isLoadToProcesses = true;
    });
    builder.addCase(
      fetchProjectProcessToProcesses.fulfilled,
      (state, action) => {
        state.isLoadToProcesses = false;
        state.typeToProcesses = action.payload;
      }
    );
    builder.addCase(fetchProjectProcessTaskComplete.pending, (state) => {
      state.isLoadTaskComplete = true;
    });
    builder.addCase(fetchProjectProcessTaskComplete.fulfilled, (state) => {
      state.isLoadTaskComplete = false;
    });
  },
});
export const { setNullRequest, setErrorClear } =
  projectProcessHeaderBtn.actions;
export default projectProcessHeaderBtn;
