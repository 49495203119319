import { compose } from "lodash/fp";
import { tokenRolesType } from "../../../types/stateTypes/userType";
import { getParsedUserToken, getUserTokenByLocalStorage } from "../auth/auth";

export const appRoles = {
  provider: 6,
  admin: 4,
  gip: 3,
  author: 2, // заказчик
  executor: 1,
  teamlead: 12,
  worker: 7,
};

export const appRolesCatalog = {
  "1": {
    ru: "Проектировщик",
    rating: "Рейтинг проектировщика",
    en: "executor",
    about: "Проектировщике",
  },
  "2": {
    ru: "Заказчик",
    rating: "Рейтинг заказчика",
    en: "customer",
    about: "Заказчике",
  },
  "3": {
    ru: "ГИП",
    rating: "Рейтинг ГИП",
    en: "gip",
    about: "ГИП",
  },
  "4": {
    ru: "Администратор",
    rating: "Рейтинг администратора",
    en: "administrator",
    about: "Администраторе",
  },
  "6": {
    ru: "Поставщик",
    rating: "Рейтинг поставщика",
    en: "provider",
    about: "Поставщике",
  },
  "7": {
    ru: "Сотрудник организации",
    rating: "Рейтинг сотрудника",
    en: "worker",
    about: "Сотруднике",
  },
  "12": {
    ru: "Руководитель группы",
    rating: "Рейтинг руководителя",
    en: "teamlead",
    about: "Руководителе",
  },
};

export const isAdmin = (userRoles: number[]): boolean => {
  return userRoles.includes(appRoles.admin);
};

export const isProvider = (userRoles: number[]): boolean => {
  return userRoles.includes(appRoles.provider);
};

export const isCustomer = (userRoles: number[]): boolean => {
  return userRoles.includes(appRoles.author);
};

export const isGip = (userRoles: number[]): boolean => {
  return userRoles.includes(appRoles.gip);
};

export const isExecutor = (userRoles: number[]): boolean => {
  return userRoles.includes(appRoles.executor);
};

export const convertRolesToArrayType = (userRoles: tokenRolesType) =>
  Object.keys(userRoles).map((item) => Number(item));

export const getUserRoles = () => {
  const userObject = compose(getParsedUserToken, getUserTokenByLocalStorage)();
  return userObject.data.roles;
};

export const getConvertedUserRoles = () =>
  compose(convertRolesToArrayType, getUserRoles)();

export const userIsAdmin = () => compose(isAdmin, getConvertedUserRoles)();

export const userIsCustomer = () =>
  compose(isCustomer, getConvertedUserRoles)();

export const userIsGip = () => compose(isGip, getConvertedUserRoles)();

export const userIsProvider = () =>
  compose(isProvider, getConvertedUserRoles)();

export const userIsExecutor = () =>
  compose(isExecutor, getConvertedUserRoles)();
