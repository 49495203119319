import { pushErrorMessage } from "../../../../../../../app/feature/errorTrace";
import {
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "../../../../../../entities/kanban";
import { checkGroup } from "../../helpers/checkGroup";
import { ColumnShift, Errors } from "./useKanbanDnD";

export const inWorkToIsDone = ({
  dispatch,
  sourceColumnId,
  destinationColumnId,
  sourceRowId,
  destinationRowId,
  rowTasks,
  result,
  groupBy,
}: ColumnShift) => {
  // из "В работе" в "Проверка"
  if (
    sourceColumnId === KanbanStatuses[KanbanColumns.IN_WORK] &&
    destinationColumnId === KanbanStatuses[KanbanColumns.IS_DONE]
  ) {
    // получаем задачи из "В работе" и "Проверка"
    const sourceColumnTasks = [
      ...rowTasks[sourceRowId].columns[sourceColumnId],
    ];
    const destinationColumnTasks = [
      ...rowTasks[destinationRowId].columns[destinationColumnId],
    ];

    // удаляем задачу из колонки-истока "В работе", добавляем в колонку-сток "Проверка"
    const [reorderedTask] = sourceColumnTasks.splice(result.source.index, 1);
    destinationColumnTasks.splice(result.destination!.index, 0, reorderedTask);

    if (!reorderedTask.can.complete && !reorderedTask.can.backToWork) {
      dispatch(pushErrorMessage(Errors.ACTION_NOT_ALLOWED));
      return;
    }

    const taskRowId = checkGroup(reorderedTask, destinationRowId, groupBy);

    if (taskRowId !== destinationRowId) {
      dispatch(pushErrorMessage(Errors.NO_SUCH_DIRECTION));
      return;
    }

    // обновляем колонки для группы
    const updatedTasks = {
      ...rowTasks[sourceRowId].columns,
      [sourceColumnId]: sourceColumnTasks,
      [destinationColumnId]: destinationColumnTasks,
    };

    // записываем обновленные колонки группы в кэш
    dispatch(
      KanbanModel.setCache({
        destinationRowId,
        destinationColumnId,
        tasks: updatedTasks,
      })
    );

    // открываем соотв. модальное окно на завершение задачи
    dispatch(KanbanModel.setTaskCompletion(reorderedTask));
  }
};
