import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { fetchProjectComplexObject } from "../../../../../app/feature/ProjectView/ComplexReducer/thunks";

export function fetchTabsData(
  currentTab: string,
  projectId: string,
  setContentLoad: (state: boolean) => void,
  dispatch: any
) {
  if (currentTab === "info") {
    dispatch(fetchProjectView(projectId));
    setContentLoad(false);
  }
  if (currentTab === "objects") {
    dispatch(fetchProjectComplexObject({ id: +projectId }));
    setContentLoad(false);
  }

  if (
    currentTab === "history" ||
    currentTab === "admin" ||
    currentTab === "sourceData" ||
    currentTab === "tasks" ||
    currentTab === "checkTasks" ||
    currentTab === "milestone" ||
    currentTab === "checklist" ||
    currentTab === "documents" ||
    currentTab === "processes" ||
    currentTab === "applications"
  ) {
    setContentLoad(false);
  }
}
