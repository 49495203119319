import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import styles from "./Admin.module.scss";
import {
  deleteProjectAccess,
  getProjectAccessByProject,
} from "../../../../../app/feature/ProjectView/AdminReducer/thunks";
import { getProjectUsersList } from "../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import { useAppSelector } from "../../../../../app/store";
import UserCard from "./UserCard";
import CompleteTask from "./modals/CompleteTask";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { Header } from "./Header/Header";
import { NewModal } from "../../../../newUI/NewModal/NewModal";
import { AdminSkeleton } from "./AdminSkeleton";
import { RedirectToExternal } from "../../../../features/RedirectToExternal/RedirectToExternal";
import { userIsAuth } from "../../../../../app/services/auth/auth";

export type TModalTaskComplete = Record<string, any> | null;

export const Admin = () => {
  const { project, isLoad } = useSelector(getProjectViewData);
  const { projectAccessByProject, pending } =
    useAppSelector(getProjectUsersList);

  const dispatch = useDispatch();
  const { error } = useSelector(getProjectUsersList);
  const [modalTaskComplete, setModalTaskComplete] =
    useState<TModalTaskComplete>(null);

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams<{ id: string }>();

  const isAuth = userIsAuth();

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [error]);

  useEffect(() => {
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  }, []);

  const handleCloseTaskCompleteModal = () => {
    setModalTaskComplete(null);
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  };

  const handleRemoveUser = async (id: number) => {
    if (
      !window.confirm("Вы уверены, что хотите исключить данного пользователя?")
    ) {
      return;
    }

    await dispatch(deleteProjectAccess(id));
    if (params.id) {
      dispatch(getProjectAccessByProject(Number(params.id)));
    }
  };

  const canAddRoles = Boolean(
    project?.can?.edit && projectAccessByProject.length
  );

  if (!isAuth) {
    return <RedirectToExternal to={"/site/login"} />;
  }

  if (pending.projectAccessByProject || isLoad) {
    return <AdminSkeleton />;
  }

  if (project) {
    return (
      <div className={styles.wrapper}>
        <Header canAddRoles={canAddRoles} projectId={+project.id} />
        <div className={styles.grid}>
          {projectAccessByProject.map((data: any, index: number) => (
            <UserCard
              key={index}
              {...data}
              setModalTaskComplete={setModalTaskComplete}
              handleRemoveUser={handleRemoveUser}
            />
          ))}
          <NewModal
            active={Boolean(modalTaskComplete)}
            setActive={handleCloseTaskCompleteModal}
            title={`Рассмотрение заявки на роль «${
              modalTaskComplete?.role ?? ""
            }» в
                проекте «${project.name}»`}
          >
            <CompleteTask
              handleCloseModal={handleCloseTaskCompleteModal}
              modalTaskComplete={modalTaskComplete}
              setModalTaskComplete={setModalTaskComplete}
            />
          </NewModal>
        </div>
      </div>
    );
  }
  return <div></div>;
};
