import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { executorPageInitialState } from "./executorPageInitialState";
import C from "./constants";
import { createExecutorListFromApi } from "./lib/createData";
import { StateType } from "../../../../initialState";
import {
  ApiExecutorPageDataType,
  ApiUserIndexPageDataType,
} from "../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const executorPageReducer = createReducer(
  executorPageInitialState,
  (builder) => {
    /*
        Установка значений на страницу проектировщиков
      */

    builder.addCase(
      C.SET_EXECUTOR_PAGE_DATA,
      (state, action: PayloadAction<ApiExecutorPageDataType>) => {
        return {
          ...state,
          users: createExecutorListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorExecutorsList = (state: StateType) =>
  state.executorPage.users;

export const selectorMetaData = (state: StateType) => state.executorPage.meta;

export const setExecutors = (
  data: ApiExecutorPageDataType,
  meta: ApiUserIndexPageDataType["meta"]
) => ({
  type: C.SET_EXECUTOR_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
