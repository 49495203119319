import React, { memo } from "react";
import classNames from "classnames";
import { Dash } from "react-bootstrap-icons";
import { ChecklistProjectData } from "../../../../app/feature/ProjectView/CheckListReducer/thunks";
import cls from "./CheckListDash.module.scss";

interface CheckListDashProps {
  node: ChecklistProjectData;
}
export const CheckListDash = memo(({ node }: CheckListDashProps) => {
  return (
    <div className={cls.dash}>
      <div className={classNames(node.prepareTask.status && cls.dash_status)}>
        {(node.prepareTask.all &&
          `${node.prepareTask.done}/${node.prepareTask.all}`) || <Dash />}
      </div>
      <div className={classNames(node.acceptTask.status && cls.dash_status)}>
        {(node.acceptTask.all &&
          `${node.acceptTask.done}/${node.acceptTask.all}`) || <Dash />}
      </div>
      <div className={classNames(node.customerAlign.status && cls.dash_status)}>
        {(node.customerAlign.all &&
          `${node.customerAlign.done}/${node.customerAlign.all}`) || <Dash />}
      </div>
      <div className={classNames(node.prepareDoc.status && cls.dash_status)}>
        {(node.prepareDoc.all &&
          `${node.prepareDoc.done}/${node.prepareDoc.all}`) || <Dash />}
      </div>
      <div className={classNames(node.checkDoc.status && cls.dash_status)}>
        {(node.checkDoc.all &&
          `${node.checkDoc.done}/${node.checkDoc.all}`) || <Dash />}
      </div>
      <div className={classNames(node.regAct.status && cls.dash_status)}>
        {(node.regAct.all && `${node.regAct.done}/${node.regAct.all}`) || (
          <Dash />
        )}
      </div>
    </div>
  );
});
