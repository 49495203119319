import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cls from "../ModalRemoveExecutor.module.scss";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import {
  getProjectProcessCheckedAgree,
  getProjectProcessCheckedReason2,
  getProjectProcessContentValue,
  getProjectProcessReasonValue,
  getProjectProcessRemoveExecutorArticleList,
  getProjectProcessRemoveExecutorContract,
  getProjectProcessRemoveExecutorObjects,
  getProjectProcessRemoveExecutorReasons,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import {
  setCheckedAgree,
  setCheckedReason2,
  setContentValue,
  setReasonValue,
} from "../../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { getProjectProcessInfoData } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

interface Inputs {
  reason: string;
  reason2: number;
  reason3: boolean;
  content: string;
}

interface FirstFormProps {
  onSubmit: (data: any) => void;
  title: string;
}

const menuItemSx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
  userSelect: "none",
  WebkitUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
};

const textAgree = (
  <div className={cls.textAgree}>
    Выбрать данный пункт можно после рассмотрения выданного предварительного
    решения или после окончания контрольного срока по запросу предварительного
    решения. <br />
    <br />
    Срок выполнения работ по заключенному договору не вышел.
    <br />
    <br />
    Если вы предполагаете, что исполнитель не работает, то запросите
    предварительное решение и установите контрольный срок его предоставления.
  </div>
);

const schema = Yup.object().shape({
  reason: Yup.string()
    .ensure()
    .required("Необходимо выбрать причину снятия исполнителя"),
  reason2: Yup.string().when("reason", {
    is: "article-715",
    then: Yup.string().required("Уточните причину снятия исполнителя"),
    otherwise: Yup.string(),
  }),
  content: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
  reason3: Yup.boolean().when("reason", {
    is: "article-715",
    then: Yup.boolean().oneOf(
      [true],
      "Подтвердите получение предварительных решений"
    ),
    otherwise: Yup.boolean().notRequired(),
  }),
});

export const FirstForm = ({ onSubmit, title }: FirstFormProps) => {
  const dispatch = useAppDispatch();
  const processData = useAppSelector(getProjectProcessInfoData);

  const reasons = useAppSelector(getProjectProcessRemoveExecutorReasons);
  const objects = useAppSelector(getProjectProcessRemoveExecutorObjects);
  const contractName = useAppSelector(getProjectProcessRemoveExecutorContract);
  const article717List = useAppSelector(
    getProjectProcessRemoveExecutorArticleList
  );

  const checkedReason2 = useAppSelector(getProjectProcessCheckedReason2);
  const checkedAgree = useAppSelector(getProjectProcessCheckedAgree);
  const reasonValue = useAppSelector(getProjectProcessReasonValue);
  const contentValue = useAppSelector(getProjectProcessContentValue);

  const canRefund = processData && +processData?.paid_out !== 0;
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: reasonValue,
      reason2: +checkedReason2,
      reason3: checkedAgree,
      content: contentValue,
    },
  });

  const reason = watch("reason");
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCheckedReason2(event.target.value));
    setValue("reason2", +event.target.value as 1 | 2 | 3);
  };

  const onChangeAgree = () => {
    dispatch(setCheckedAgree(!checkedAgree));
    setValue("reason3", !checkedAgree);
  };

  const onChangeReason = (event: SelectChangeEvent<any>) => {
    dispatch(setReasonValue(event.target.value));
    setValue("reason", event.target.value);
  };

  const onChangeContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setContentValue(event.target.value));
    setValue("content", event.target.value);
  };
  const formSubmitHandler = async () => {
    const dataIfNotObjects = {
      status: "end",
      data: {
        class: "ProjectProcess",
        content: contentValue,
        reason: reasonValue,
        reason2: +checkedReason2,
        reason3: checkedAgree,
        refund: false,
        data: [
          {
            id: processData?.id,
            price: 0,
          },
        ],
      },
    };
    const data = {
      status: canRefund ? "refund" : "notRefund",
    };
    onSubmit(objects.length > 0 ? data : dataIfNotObjects);
  };

  let renderReason2;

  if (reasonValue === "agreement") {
    renderReason2 = (
      <p className={cls.reason_text}>{reasons?.agreement.text}</p>
    );
  }

  if (reasonValue === "article-715") {
    renderReason2 = (
      <div className={cls.reason}>
        <p className={cls.reason_text}>
          {(reasons && reasons["article-715"].text) || ""}
        </p>
        <div className={cls.reason_checkBox}>
          {article717List.map(([id, text]) => (
            <label>
              <CustomCheckbox
                {...register("reason2")}
                onChange={handleCheckboxChange}
                checked={checkedReason2 === id}
                value={id}
              />
              {text}
            </label>
          ))}
        </div>
        {errors.reason2 && (
          <p className={cls.error}>{errors.reason2.message}</p>
        )}
      </div>
    );
  }

  if (reasonValue === "article-717") {
    renderReason2 = (
      <p className={cls.reason_text}>
        {(reasons && reasons["article-717"].text) || ""}
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
      <h1>{title}</h1>
      {contractName && <p>{contractName}</p>}
      <FormControl {...textFieldSX}>
        <InputLabel id="reason">Причина снятия исполнителя</InputLabel>
        <Select
          {...register("reason")}
          onChange={onChangeReason}
          value={reasonValue}
          labelId="reason"
          label="Причина снятия исполнителя"
          sx={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          <MenuItem sx={menuItemSx} value="agreement">
            {reasons?.agreement.label}
          </MenuItem>
          <MenuItem sx={menuItemSx} value="article-715">
            {reasons && reasons["article-715"].label}
          </MenuItem>
          <MenuItem sx={menuItemSx} value="article-717">
            {reasons && reasons["article-717"].label}
          </MenuItem>
        </Select>
        {errors.reason && <p className={cls.error}>{errors.reason.message}</p>}
      </FormControl>
      {renderReason2}
      <FormControl>
        <TextField
          {...textFieldSX}
          {...register("content")}
          onChange={onChangeContent}
          value={contentValue}
          multiline
          minRows={2}
          label="Комментарий"
          placeholder="Введите комментарий"
        />
        {errors.content && (
          <p className={cls.error}>{errors.content.message}</p>
        )}
      </FormControl>
      {reason === "article-715" && (
        <>
          {" "}
          {textAgree}
          <label className={cls.labelAgree}>
            <CustomCheckbox
              {...register("reason3")}
              onChange={onChangeAgree}
              checked={checkedAgree}
              value={checkedAgree}
            />
            Подтверждаю получение предварительных решений от исполнителя
          </label>
          {errors.reason3 && (
            <p className={cls.error}>{errors.reason3.message}</p>
          )}
        </>
      )}
      <CustomButton
        className={cls.form_submit}
        background={palette.blue}
        width={140}
        type="submit"
      >
        {objects.length > 0 ? "Далее" : "Снять"}
      </CustomButton>
    </form>
  );
};
