import React, { memo, useRef, useState } from "react";
import { useParams } from "react-router";
import { IconButton } from "@mui/material";
import { AddFile } from "src/components/newUI/SVG";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { RefType } from "src/components/newUI/ThreeDotButton/ThreeDotButton";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiPrimaryFileType } from "src/types/api/primaryTypes/apiPrimaryFileType";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { fetchStageList } from "src/app/feature/project/projectChecklist/thunks";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { CardHeader } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/CardHeader/CardHeader";
import { iconBtnSx } from "src/styles/restyle";
import classNames from "classnames";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import styles from "./Processes.module.scss";

interface ProcessesProps {
  name?: string;
  files?: ApiPrimaryFileType[];
  canAdd?: boolean;
}
export const Processes = memo(({ name, files, canAdd }: ProcessesProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [toggleProcesses, setToggleProcesses] = useState(false);
  const [modalFiles, setModalFiles] = useState(false);
  const threeDotButtonRef = useRef<RefType>(null);
  const processId = useAppSelector(getProjectProcessInfoId);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  function addFilesHandler() {
    setModalFiles(true);
    closeThreeDot();
  }

  function modalFilesClose() {
    setModalFiles(false);

    if (processId) {
      dispatch(fetchProjectProcessInitialData(processId as number));
      return;
    }
    if (params.id) {
      dispatch(fetchStageList(Number(params.id)));
    }
  }

  return (
    <>
      <CardHeader
        title={name || "Дополнительные исходные данные к разделу"}
        expanded={toggleProcesses}
        onClick={() => setToggleProcesses((prev) => !prev)}
      />
      {toggleProcesses && (
        <div className={styles.container}>
          <FilesList
            fileLinks={files}
            className={classNames(canAdd && styles.showAddFile)}
          />
          <div className={styles.footer}>
            {files && files.length > 0 && (
              <TextLink
                className={styles.downloadAll}
                title={"Скачать все"}
                redirect
                target="blank"
                url={`/project-process/download-files?id=${processId}`}
              >
                Скачать все
              </TextLink>
            )}
            {canAdd && (
              <>
                <Modal isActive={modalFiles} handleClose={modalFilesClose}>
                  <ProjectProcessModals.ModalFiles
                    modalClose={modalFilesClose}
                  />
                </Modal>
                <IconButton
                  onClick={addFilesHandler}
                  sx={iconBtnSx}
                  className={styles.addFile}
                >
                  <AddFile />
                  <span className={styles.addFileText}>Загрузить файлы</span>
                </IconButton>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
});
