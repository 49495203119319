import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInitialDataIsLoad,
  getProjectProcessInitialDataSourceData,
} from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessInitialData";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { setChecklistType } from "src/app/feature/project/projectChecklist";
import { clearOpenSourceData } from "src/app/feature/ProjectProcessView/InitialData/slice/projectProcessInitialData";
import {
  TabChildrenHeader,
  useTabsPanelContext,
} from "src/FSD/widgets/v2/TabsPanel";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import styles from "./SourceData.module.scss";
import { useTabMapping } from "./lib/hook/useTabMapping";
import { SourceDataContent } from "./ui/SourceDataContent";
import { SourceDataSkeleton } from "./ui/SourceDataSkeleton";

export const SourceData = () => {
  const dispatch = useAppDispatch();
  const infoId = useSelector(getProjectProcessInfoId);
  const sourceData = useAppSelector(getProjectProcessInitialDataSourceData);
  const isLoad = useAppSelector(getProjectProcessInitialDataIsLoad);
  const { currentChildTab } = useTabsPanelContext();
  const activeTab = useTabMapping(currentChildTab);

  useEffect(() => {
    dispatch(setChecklistType(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessInitialData(infoId));
    }
  }, [infoId]);

  useEffect(() => {
    dispatch(clearOpenSourceData());
  }, [activeTab]);

  if (isLoad) {
    return <SourceDataSkeleton />;
  }

  return (
    <div className={styles.wrapper}>
      <TabChildrenHeader
        title="Исходные данные по проекту"
        className={styles.tab__header}
      />
      <ScrollWrap height={"876px"}>
        <SourceDataContent activeTab={activeTab} sourceData={sourceData} />
      </ScrollWrap>
    </div>
  );
};
