import { ProcessWorkModel } from "src/FSD/entities/processWork";
import { TaskModel } from "src/FSD/entities/task";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";

export const useModals = () => {
  const dispatch = useAppDispatch();

  const { comments } = useAppSelector(TaskModel.modals.getModalsData);

  const { checkCustomerAlign, executeComplete } = useAppSelector(
    ProcessWorkModel.modals.getModalsData
  );

  const handleHideComments = () => {
    dispatch(
      TaskModel.modals.setCommentsActive({
        isOpen: false,
        taskId: null,
      })
    );
  };

  const handleShowCheckTask = (taskId: number, header: string) => {
    dispatch(
      ProcessWorkModel.modals.setCheckCustomerAlignActive({
        isOpen: true,
        taskId,
        header,
      })
    );
  };

  const handleHideCheckTask = () => {
    dispatch(
      ProcessWorkModel.modals.setCheckCustomerAlignActive({
        isOpen: false,
        taskId: null,
        header: "",
      })
    );
  };

  const handleShowExecuteComplete = (
    taskId: number,
    header: string,
    comment: string
  ) => {
    dispatch(
      ProcessWorkModel.modals.setExecuteCompleteActive({
        isOpen: true,
        taskId,
        header,
        comment,
      })
    );
  };

  const handleHideExecuteComplete = () => {
    dispatch(
      ProcessWorkModel.modals.setExecuteCompleteActive({
        isOpen: false,
        taskId: null,
        header: "",
        comment: "",
      })
    );
  };

  return {
    handleHideComments,
    handleShowCheckTask,
    handleHideCheckTask,
    handleShowExecuteComplete,
    handleHideExecuteComplete,
    comments,
    checkCustomerAlign,
    executeComplete,
  } as const;
};
