import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../services/api/requestHandler";
import {
  ApiProjectGanttData,
  ApiProjectGanttDataNew,
  ApiSchedulePlan,
  ApiUpdateDates,
} from "../../../services/api/project/graph";

export interface TDiv {
  dateEnd: string;
  dateStart: string;
  marginLeft: number;
  shortTitle: string;
  title: string;
  width: number;
  color: string;
}

interface TDivBudget {
  color: string;
  dateEnd: string;
  dateStart: string;
  marginLeft: number;
  price: number;
  priceCurrent: number;
  priceFact: number;
  title: string;
  width: number;
}

type TKeys = "div" | "div1" | "div2" | "div3" | "projectOtr";
export type TaskType = Record<TKeys, TDiv>;
export type TTaskName =
  | "expertise"
  | "ird"
  | "project"
  | "projectOtr"
  | "today"
  | "work"
  | "source"
  | "inspection"
  | "research";
export type TFactDataParent = Record<TTaskName, TaskType>;
export type TPlanData = Record<TTaskName, TaskType>;
export type TForecastData = Record<TTaskName, TaskType>;

export interface IProjectGanttDataReturn {
  budget: {
    data: {
      parent: {
        expertise: TDivBudget[];
        project: TDivBudget[];
        work: TDivBudget[];
        today: TDiv;
      };
      childs: any;
    };
    priceFactCurrent: number;
    pricePlan: number;
    pricePlanCurrent: number;
    width: number;
    color: string;
    dateEndFact: string;
    dateStartFact: string;
  };
  fact: {
    parent: TFactDataParent;
    childs: any;
  };
  plan: TPlanData;
  forecast: TForecastData;
}
export const fetchProjectGanttData = createAsyncThunk<
  IProjectGanttDataReturn,
  number
>("fetchProjectGanttData/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiProjectGanttData(id);
    const result = await response.json();
    if (!response.ok) {
      throw await result;
    }
    return result;
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchProjectGanttDataNew = createAsyncThunk<
  IProjectGanttDataReturn,
  number
>("fetchProjectGanttDataNew/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiProjectGanttDataNew(id);
    const result = await response.json();
    if (!response.ok) {
      throw await result;
    }
    return result;
  } catch (err) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

interface IUpdateDatesProps {
  id: number;
  data: Record<string, string | number | null>;
}
export const fetchUpdateDates = createAsyncThunk<
  boolean,
  IUpdateDatesProps,
  { rejectValue: { status: string; message: string } }
>("fetchUpdateDates/fetch", async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await ApiUpdateDates(id, data);
    const result = await response.json();
    if (!response.ok) {
      throw await result;
    }
    return result;
  } catch (err) {
    const error = getErrorMessage(err);
    if (err.status === 422) {
      return rejectWithValue({ status: "422", message: err.message });
    }
    return rejectWithValue({ status: "none", message: error });
  }
});

export const fetchSchedulePlan = createAsyncThunk<any, number>(
  "fetchSchedulePlan/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiSchedulePlan(id);
      const result = await response.json();
      if (!response.ok) {
        throw await result;
      }
      return result;
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
