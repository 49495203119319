import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseRemarkRowUpdate } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";
import { RowType } from "../types/expertiseRemarksTypes";

type DataType = {
  text: string;
  comment: string;
  date_limit: any;
  project_id: number;
  project_part_ids: number[];
};
export const fetchExpertiseRemarkRowUpdate = createAsyncThunk<
  RowType,
  { rowId: number; data: DataType }
>(
  "expertise/fetchExpertiseRemarkRowUpdate",
  async ({ rowId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseRemarkRowUpdate(rowId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
