import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { index } from "./queries";

export const projectSourceDataApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectSourceDataIndex: index(builder),
  }),
  overrideExisting: false,
});

export const { useProjectSourceDataIndexQuery } = projectSourceDataApi;
