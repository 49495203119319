export default {
  CHAT_INIT: "CHAT_INIT",
  CHAT_SET_CONTACTS: "CHAT_SET_CONTACTS",
  CHAT_SET_HISTORY: "CHAT_SET_HISTORY",
  CHAT_SET_ROOM_DATA: "CHAT_SET_ROOM_DATA",
  CHAT_ADD_MESSAGE: "CHAT_ADD_MESSAGE",
  CHAT_SET_OVERLOOK_MESSAGES: "CHAT_SET_OVERLOOK_MESSAGES",
  CHAT_USER_ENTER_ROOM: "CHAT_USER_ENTER_ROOM",
  CHAT_USER_EXIT_ROOM: "CHAT_USER_EXIT_ROOM",
  CHAT_SET_ACTIVE_ROOM_DATA: "CHAT_SET_ACTIVE_ROOM_DATA",
  CHAT_USER_LEAVE: "CHAT_USER_LEAVE",
  CHAT_FILTER_CONTACTS: "CHAT_FILTER_CONTACTS",
  CHAT_CLEAR_FILTER_CONTACTS: "CHAT_CLEAR_FILTER_CONTACTS",
  CHAT_TOGGLE_ACTIVE_ROOM: "CHAT_TOGGLE_ACTIVE_ROOM",
  CHAT_UPDATE_ROOMS_LIST: "CHAT_UPDATE_ROOMS_LIST",
  CHAT_ADD_OPENED_ROOM: "CHAT_ADD_OPENED_ROOM",
  CHAT_REMOVE_OPENED_ROOM: "CHAT_REMOVE_OPENED_ROOM",
  CHAT_SET_GROUP_COUNTERS: "CHAT_SET_GROUP_COUNTERS",
};
