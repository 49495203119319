import React, { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FormWrapper } from "../../../../../newUI/FormWrapper/FormWrapper";
import cls from "../ModalRemoveExecutor.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessCheckedAgree,
  getProjectProcessCheckedPrice,
  getProjectProcessCheckedReason2,
  getProjectProcessCheckedRefund,
  getProjectProcessContentValue,
  getProjectProcessReasonValue,
  getProjectProcessRemoveExecutorObjects,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import {
  setCheckedPrice,
  setPriceObject,
  setRemoveExecutorObjectChecked,
} from "../../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { getProjectProcessInfoData } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { LastFormCard } from "./LastFormCard";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";

interface LastFormProps {
  onSubmit: (data: any) => void;
  title: string;
  handleBackClick: (refund: string) => void;
}
export const LastForm = ({
  title,
  onSubmit,
  handleBackClick,
}: LastFormProps) => {
  const { handleSubmit } = useForm<any>();
  const objects = useAppSelector(getProjectProcessRemoveExecutorObjects);
  const dispatch = useAppDispatch();
  const processData = useAppSelector(getProjectProcessInfoData);

  const checkedReason2 = useAppSelector(getProjectProcessCheckedReason2);
  const checkedAgree = useAppSelector(getProjectProcessCheckedAgree);
  const reasonValue = useAppSelector(getProjectProcessReasonValue);
  const contentValue = useAppSelector(getProjectProcessContentValue);
  const checkedRefund = useAppSelector(getProjectProcessCheckedRefund);
  const checkedPrice = useAppSelector(getProjectProcessCheckedPrice);

  const canRefund = processData && +processData?.paid_out !== 0;
  const formSubmitHandler = async () => {
    const resultChecked: any = [];
    objects.forEach((obj) => {
      obj.processes.forEach((process) => {
        if (process.checked) {
          resultChecked.push({
            id: process.id,
            price: process.price,
          });
        }
      });
    });

    const submitData = {
      status: "end",
      data: {
        class: "ProjectProcess",
        content: contentValue,
        reason: reasonValue,
        reason2: +checkedReason2,
        reason3: checkedAgree,
        refund: checkedRefund,
        price: checkedPrice ? processData?.paid_out : null,
        data: resultChecked,
      },
    };
    onSubmit(submitData);
  };

  function onChangeObjects(event: ChangeEvent<HTMLInputElement>, name: string) {
    dispatch(
      setRemoveExecutorObjectChecked({
        id: event.target.value,
        name,
      })
    );
  }

  function onChangePrice(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    objId: number,
    name: string
  ) {
    dispatch(setPriceObject({ id: objId, newPrice: event.target.value, name }));
  }

  function onChangeCheckedPrice() {
    dispatch(setCheckedPrice());
  }

  function handleBackClickLast() {
    const refund = canRefund ? "refund" : "notRefund";
    handleBackClick(refund);
  }

  return (
    <FormWrapper title={title} submitHandler={handleSubmit(formSubmitHandler)}>
      <p className={cls.subtitle}>
        Выберите разделы, с которых вы хотите снять исполнителя , и укажите
        сумму фактической оплаты за выполненные работы
      </p>

      {objects &&
        objects.map((object) => {
          return (
            <>
              <p>{object.projectName}</p>
              <div
                className={classNames(
                  cls.grid,
                  checkedRefund && cls.flexColumn
                )}
              >
                <p>Раздел</p>
                {!checkedRefund && <p>Оплачено</p>}
                {object.processes.map((process) => (
                  <LastFormCard
                    checkedRefund={checkedRefund}
                    process={process}
                    name={object.projectName}
                    onChangeObjects={onChangeObjects}
                    onChangePrice={onChangePrice}
                  />
                ))}
              </div>
            </>
          );
        })}
      <label className={cls.labelAgree}>
        <CustomCheckbox
          onChange={onChangeCheckedPrice}
          checked={checkedPrice}
        />
        Оплата исполнителю в сумме выплаченных авансов по разделам
      </label>
      <div className={cls.attentionText}>
        <p>Внимание!</p>
        <span>
          Не забудьте подписать дополнительное соглашение или соглашение о
          расторжении после нажатия кнопки "Снять".
        </span>
      </div>
      <div className={cls.btn}>
        <CustomButton onClick={handleBackClickLast} width={140}>
          Назад
        </CustomButton>
        <CustomButton background={palette.blue} width={140} type="submit">
          Снять
        </CustomButton>
      </div>
    </FormWrapper>
  );
};
