import { processCostValue } from "src/app/services/price/price";

type PayPartInfoProps = {
  price: string | number;
  payPercent: number;
  title: string;
  payClass: string;
};

export const PayPartInfo = (props: PayPartInfoProps) => {
  const { price, payPercent, title, payClass } = props;

  const payPartValueFormatted = processCostValue(
    (Number(price) * payPercent) / 100
  );

  return (
    <div>
      <div className={payClass}>
        <span />
        {payPercent}%
      </div>
      <div>{title}</div>
      {price && <div>&nbsp;({payPartValueFormatted} ₽)</div>}
    </div>
  );
};
