import { RootState } from "../../../../store";

export const getUpdateFormMaterialsError422 = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.error422;

export const getUpdateFormMaterialsPrice = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.price;

export const getUpdateFormMaterialsUserTypeId = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.userTypeId;

export const getUpdateFormMaterialsDateDeadline = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.dateDeadline;

export const getUpdateFormMaterialsComment = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.comment;

export const getUpdateFormMaterialsErrorFlag = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.errorFlag;

export const getUpdateFormMaterialsSuccess = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.successUpdate;

export const getUpdateFormMaterialsIsLoad = (state: RootState) =>
  state.projectProcessView.updateMaterialsForm.isLoadUpdate;
