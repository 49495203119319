import cn from "classnames";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import styles from "./TermsPayment.module.scss";
import { TValuesPublication } from "../../lib/hooks/usePublishContent";

type Props = {
  register: any;
  valuesPublication: TValuesPublication;
  setValue: any;
  isSubmittingSave: boolean;
  isSubmittingPublish: boolean;
  errors: any;
};

export const TermsPayment = ({
  register,
  valuesPublication,
  setValue,
  isSubmittingSave,
  isSubmittingPublish,
  errors,
}: Props) => {
  return (
    <div
      className={cn(
        styles.termsPayment,
        !valuesPublication.showTermsPayment && styles.termsPayment__show
      )}
    >
      {valuesPublication.showTermsPayment && (
        <div className={styles.termsPayment__wrapper}>
          <p className={styles.termsPayment__text}>Условия оплаты</p>
          <TextField
            {...register("pay1")}
            label="Аванс %"
            error={!!errors.pay1}
            helperText={errors?.pay1?.message}
          />

          <TextField
            {...register("pay2")}
            label="Оплата после приемки %"
            error={!!errors.pay2}
            helperText={errors?.pay2?.message}
          />
          {valuesPublication.pay3 && (
            <TextField
              {...register("pay3")}
              label="Оплата после экспертизы %"
              error={!!errors.pay3}
              helperText={errors?.pay3?.message}
            />
          )}

          <p className={styles.error}>{errors?.payError?.message}</p>
        </div>
      )}
      <div className={styles.termsPayment__controls}>
        <Button
          type="submit"
          color="primary"
          isLoading={isSubmittingPublish}
          onClick={() => {
            setValue("save", 0);
          }}
        >
          Опубликовать
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="outlined"
          width={250}
          onClick={() => {
            setValue("save", Statuses.UNREADY);
          }}
          isLoading={isSubmittingSave}
          disabled={valuesPublication.disabledSaveBtn}
        >
          Сохранить в черновике
        </Button>
      </div>
    </div>
  );
};
