import React from "react";
import ReactDOM from "react-dom";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { SetUserRoleForm } from "./SetUserRoleForm";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const SetUserRole: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;
  const { Popup } = useUserWorkflow();

  const onClose = () => {
    Popup.showUserRole.set({});
    Popup.showUserRole.close();
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={<p style={{ color: "white" }}>{`Изменить право доступа`}</p>}
        body={<SetUserRoleForm closeHandler={onClose} submitClose={onClose} />}
      />
    </Modal>
  );
};
