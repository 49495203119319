import React from "react";
import { IconButton } from "@mui/material";
import { AllToggleCloseIcon, AllToggleOpenIcon } from "./AllToggleSvg";
import styles from "./AllToggleWidget.module.scss";

interface AllToggleWidgetProps {
  isAllToggle: boolean;
  setIsAllToggle: () => void;
}

export const AllToggleWidget = ({
  isAllToggle,
  setIsAllToggle,
}: AllToggleWidgetProps) => {
  return (
    <div className={styles.allToggle}>
      <IconButton onClick={setIsAllToggle}>
        {isAllToggle ? <AllToggleOpenIcon /> : <AllToggleCloseIcon />}
      </IconButton>
    </div>
  );
};
