import React, { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  getProjectProcessCheckedAgree,
  getProjectProcessCheckedPrice,
  getProjectProcessCheckedReason2,
  getProjectProcessCheckedRefund,
  getProjectProcessContentValue,
  getProjectProcessReasonValue,
  getProjectProcessRemoveExecutorObjects,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import {
  setCheckedPrice,
  setRemoveExecutorObjectChecked,
} from "src/app/feature/ProjectProcessView/Info/slice/projectProcessRemoveExecutor";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { LastFormCard } from "./LastFormCard";
import styles from "../RemoveExecutor.module.scss";

interface LastFormProps {
  onSubmit: (data: any) => void;
  handleBackClick: (refund: string) => void;
}

export const LastForm = ({ onSubmit, handleBackClick }: LastFormProps) => {
  const { handleSubmit } = useForm<any>();
  const objects = useAppSelector(getProjectProcessRemoveExecutorObjects);
  const dispatch = useAppDispatch();
  const processData = useAppSelector(getProjectProcessInfoData);

  const checkedReason2 = useAppSelector(getProjectProcessCheckedReason2);
  const checkedAgree = useAppSelector(getProjectProcessCheckedAgree);
  const reasonValue = useAppSelector(getProjectProcessReasonValue);
  const contentValue = useAppSelector(getProjectProcessContentValue);
  const checkedRefund = useAppSelector(getProjectProcessCheckedRefund);
  const checkedPrice = useAppSelector(getProjectProcessCheckedPrice);

  const canRefund = processData && +processData?.paid_out !== 0;

  const formSubmitHandler = async () => {
    const resultChecked: any = [];
    objects.forEach((obj) => {
      obj.processes.forEach((process) => {
        if (process.checked) {
          resultChecked.push({
            id: process.id,
            price: process.price,
          });
        }
      });
    });

    const submitData = {
      status: "end",
      data: {
        class: "ProjectProcess",
        content: contentValue,
        reason: reasonValue,
        reason2: +checkedReason2,
        reason3: checkedAgree,
        refund: checkedRefund,
        price: checkedPrice ? processData?.paid_out : null,
        data: resultChecked,
      },
    };
    onSubmit(submitData);
  };

  function onChangeObjects(event: ChangeEvent<HTMLInputElement>, name: string) {
    dispatch(
      setRemoveExecutorObjectChecked({
        id: event.target.value,
        name,
      })
    );
  }

  function onChangeCheckedPrice() {
    dispatch(setCheckedPrice());
  }

  function handleBackClickLast() {
    const refund = canRefund ? "refund" : "notRefund";
    handleBackClick(refund);
  }

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <p className={styles.subtitle}>
        Выберите разделы, с которых вы хотите снять исполнителя , и укажите
        сумму фактической оплаты за выполненные работы
      </p>
      {objects &&
        objects.map((object, index) => {
          return (
            <div key={index}>
              <p>{object.projectName}</p>
              <div
                className={classNames(
                  styles.grid,
                  checkedRefund && styles.flexColumn
                )}
              >
                <p>Раздел</p>
                {!checkedRefund && <p>Оплачено</p>}
                {object.processes.map((process) => (
                  <LastFormCard
                    key={process.id}
                    checkedRefund={checkedRefund}
                    process={process}
                    name={object.projectName}
                    onChangeObjects={onChangeObjects}
                  />
                ))}
              </div>
            </div>
          );
        })}
      <label className={styles.labelAgree}>
        <Checkbox
          onChange={onChangeCheckedPrice}
          checked={checkedPrice}
          label={"Оплата исполнителю в сумме выплаченных авансов по разделам"}
        />
      </label>
      <div className={styles.attentionText}>
        <p>Внимание!</p>
        <span>
          Не забудьте подписать дополнительное соглашение или соглашение о
          расторжении после нажатия кнопки "Снять".
        </span>
      </div>
      <div className={styles.btn}>
        <Button onClick={handleBackClickLast} width={108} variant="outlined">
          Назад
        </Button>
        <Button width={108} variant="contained" type="submit">
          Снять
        </Button>
      </div>
    </form>
  );
};
