import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "../../UI/components/PageTitle/PageTitle";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { appRoles } from "../../../app/services/user/userRoles";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiGetNewMaterials,
  ApiGetNewProjectsCount,
  ApiGetOnlineCount,
  ApiGetTotalCount,
  ApiGetTotalMaterials,
  ApiGetTotalProjects,
  ApiGetNewProcessProjectCount,
  ApiGetTotalProcessProject,
} from "../../../app/services/api/indexPages/counters";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { CounterListProps } from "../../UI/components/CounterList/CounterList";

export type IndexPage = typeof appRoles & {
  projects: string;
  materials: string;
  projectsProcess: string;
};

const pages: IndexPage = {
  ...appRoles,
  projects: "",
  materials: "",
  projectsProcess: "",
};

export type IndexPageTitleProps = ComponentPropsType<{
  title: string;
  description: string;
  page: keyof IndexPage;
  clickLink: CounterListProps["clickHandlerLink"];
}>;

export const IndexPageTitle: React.FC<IndexPageTitleProps> = ({
  page,
  title,
  description,
  clickLink = () => {},
}) => {
  const [allCounter, setAllCounter] = useState(0);
  const [onlineCounter, setOnlineCounter] = useState(0);
  const [newProjects, setNewProjects] = useState(0);
  const [newProjectProcess, setNewProjectProcess] = useState(0);
  const [newMaterials, setNewMaterials] = useState(0);
  const theme = useSelector(selectorAppTheme);

  const userTitleCounter: {
    [key: string]: {
      title: string[];
      url: string;
    };
  } = {
    executor: {
      title: ["Всего", "Онлайн"],
      url: "/user/search-executors?sort=-rating.executor&ExecutorUserSearch[online]=true",
    },
    gip: {
      title: ["Всего", "Онлайн"],
      url: "/user/search-gip?sort=-rating.gip&GipUserSearch[online]=true",
    },
    author: {
      title: ["Всего", "Онлайн"],
      url: "/user/search-customers?sort=-rating.customers&CustomerUserSearch[online]=true",
    },
    provider: {
      title: ["Всего", "Онлайн"],
      url: "/user/search-providers?sort=-rating.providers&ProviderUserSearch[online]=true",
    },
    projects: {
      title: ["Всего проектов", "Новых проектов"],
      url: "/project/search?ProjectSearch[new]=true",
    },
    projectsProcess: {
      title: ["Всего заказов", "Новых заказов"],
      // Нужен урл для перехода на новые проекты
      url: "",
    },
    materials: {
      title: ["Запросов всего, <br> на этот час", "Новых за сутки"],
      url: "",
    },
  };

  useEffect(() => {
    if (!window.location.pathname.includes("user/")) return;

    request(ApiGetTotalCount(pages[page]), (data: number) => {
      setAllCounter(data);
    })();

    request(ApiGetOnlineCount(pages[page]), (data: number) => {
      setOnlineCounter(data);
    })();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("project/search")) return;

    request(ApiGetTotalProjects(), (data: number) => {
      setAllCounter(data);
    })();

    request(ApiGetNewProjectsCount(), (data: number) => {
      setNewProjects(data);
    })();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("project-process/search")) return;

    request(ApiGetTotalProcessProject(), (data: number) => {
      setAllCounter(data);
    })();

    request(ApiGetNewProcessProjectCount(), (data: number) => {
      setNewProjectProcess(data);
    })();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("material-process/search")) return;

    request(ApiGetTotalMaterials(), (data: number) => {
      setAllCounter(data);
    })();

    request(ApiGetNewMaterials(), (data: number) => {
      setNewMaterials(data);
    })();
  }, []);

  return (
    <PageTitle
      clickLinkHandler={clickLink}
      title={title}
      description={description}
      theme={theme}
      items={[
        {
          title: userTitleCounter[page].title[0],
          value: allCounter,
          url: "",
        },
        {
          title: userTitleCounter[page].title[1],
          value:
            onlineCounter || newProjects || newMaterials || newProjectProcess,
          url: userTitleCounter[page].url || "",
        },
      ]}
    />
  );
};
