import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { config } from "src/app.cofig";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { getFiles } from "src/FSD/widgets/v2/projectProcess/modals/ModalFiles/lib/helpers/utils";

interface ModalFilesProps {
  modalClose: () => void;
}

export const ModalFiles = memo(({ modalClose }: ModalFilesProps) => {
  const params = useParams<{ id: string }>();
  const paramsId = params.id || "";

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Загрузить файлы</Modal.Header>
      <Modal.Form>
        <Uploader
          autoUpload
          canEdit
          getDataCallback={() => getFiles(paramsId)}
          postUrl={`${config.localDomain}/v1/project-process/add-file?id=${paramsId}`}
        />
        <Modal.Controls>
          <Button onClick={modalClose}>Закрыть</Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
});
