import { createReducer, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  organisationPageInitialState,
  OrganisationPageInitialStateType,
} from "./initialState";
import C from "./constants";
import { StateType } from "../../initialState";
import { ApiOrganisationDataType } from "../../../types/api/responseTypes/apiOrganisationType";
import {
  createOrganisationInfo,
  createOrganisationStaff,
  createOrganisationTask,
  createOrganisationBank,
  createOrganisationHistory,
} from "./creators";

export const organisationPageReducer = createReducer(
  organisationPageInitialState,
  (builder) => {
    // @ts-ignore
    builder.addCase(
      C.SET_ORGANISATION_PAGE_CONTENT_DATA,
      (
        state: OrganisationPageInitialStateType,
        action: PayloadAction<ApiOrganisationDataType>
      ) => {
        return {
          ...state,
          title: action.payload.info.title,
          info: createOrganisationInfo(action.payload.info),
          staff: createOrganisationStaff(action.payload.staff),
          staffMeta: action.payload.staffMeta,
          task: createOrganisationTask(action.payload.task),
          bank: createOrganisationBank(action.payload.bank),
          history: createOrganisationHistory(action.payload.history),
          historyMeta: action.payload.historyMeta,
          requests: action.payload.requests,
          can: action.payload.can,
        };
      }
    );

    builder.addCase(
      C.SET_ORGANISATION_PAGE_CONTENT_DEFAULT,
      () => organisationPageInitialState
    );
  }
);

export const setOrganisationPageContentData =
  (data: ApiOrganisationDataType) => (dispatch: Dispatch) =>
    dispatch({ type: C.SET_ORGANISATION_PAGE_CONTENT_DATA, payload: data });

export const setOrganisationPageContentDefault = () => (dispatch: Dispatch) =>
  dispatch({
    type: C.SET_ORGANISATION_PAGE_CONTENT_DEFAULT,
    payload: organisationPageInitialState,
  });

export const selectorOrganisationPageContentData = (state: StateType) =>
  state.organisationPage;
