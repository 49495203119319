import { ChangeEvent, FC } from "react";
import { Folder2 } from "react-bootstrap-icons";
import styles from "./styles.module.scss";

type DropzoneProps = {
  handleUpload: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Dropzone: FC<DropzoneProps> = ({ handleUpload }) => (
  <label className={styles.dropzone}>
    <Folder2 className={styles.inputIcon} />
    <p className={styles.description}>
      <span>Загрузите</span>&nbsp;
      <span>файлы</span>
    </p>
    <input onChange={handleUpload} type="file" hidden multiple />
  </label>
);
