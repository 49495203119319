import { useState, useEffect, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import style from "./SourceDataView.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  deleteSourceComment,
  fetchSourceComments,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import {
  getProjectSections,
  clearSourceComments,
  TSourceComment,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { useIsMobile } from "../../../../../../components/hooks/useIsMobile";
import { SourceCommentItem } from "./SourceCommentItem";

type TCommentProps = {
  sourceDataId: number;
  name: string;
  can: Record<string, boolean>;
};

const Comment = ({ sourceDataId, name, can }: TCommentProps) => {
  const dispatch = useAppDispatch();
  const [commentId, setCommentId] = useState<number | null>(null);
  const isTablet = useIsMobile(1600);

  const {
    sourceComments,
    pending: { getSourceComments, deletingSourceComment },
  } = useAppSelector(getProjectSections);

  const currentComment = useMemo(() => {
    if (!sourceComments[sourceDataId]) {
      return null;
    }

    const foundComment = sourceComments[sourceDataId].find(
      (comment) => comment.id === commentId
    );

    return foundComment ?? null;
  }, [commentId]);

  const handleDeleteComment = async (id: number) => {
    if (!window.confirm("Вы уверены, что хотите удалить данный комментарий?")) {
      return;
    }

    await dispatch(deleteSourceComment(id));
    await dispatch(fetchSourceComments(sourceDataId));
  };

  useEffect(() => {
    if (!sourceDataId) {
      return;
    }

    dispatch(fetchSourceComments(sourceDataId));

    return () => {
      dispatch(clearSourceComments(sourceDataId));
    };
  }, [deletingSourceComment]);

  const SourceCommentsList = () => (
    <>
      {getSourceComments.includes(sourceDataId) ? (
        <div className={style.pending}>
          <CircularProgress />
        </div>
      ) : sourceComments[sourceDataId]?.length ? (
        sourceComments[sourceDataId].map((comment: TSourceComment) => (
          <SourceCommentItem
            key={comment.id}
            setCommentId={setCommentId}
            handleDeleteComment={handleDeleteComment}
            {...comment}
          />
        ))
      ) : (
        <p className={style.noCommentsFound}>Комментарии отсутствуют</p>
      )}
    </>
  );

  return (
    <div className={style.commentBlock}>
      {sourceComments[sourceDataId]?.length > 0 && (
        <div className={style.comment__grid}>
          <p>{isTablet ? "Стат." : "Статус"}</p>
          <p>Комментарий</p>
          <p>Контр. срок</p>
          <p />
          <SourceCommentsList />
        </div>
      )}
      {can?.addComment && (
        <Button
          variant="outlined"
          className={style.addCommentButton}
          onClick={() => {
            setCommentId(0);
          }}
        >
          Создать комментарий +
        </Button>
      )}
      <Modal
        isActive={commentId !== null}
        handleClose={() => {
          setCommentId(null);
        }}
      >
        <ProjectModals.AddCommentModal
          currentComment={currentComment}
          setCommentId={setCommentId}
          isEditing={Boolean(commentId)}
          sourceDataId={sourceDataId}
          name={name}
        />
      </Modal>
    </div>
  );
};

export default Comment;
