import { useMemo, useCallback } from "react";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import { useProcessWorkUpdatePriceMutation } from "src/FSD/entities/processWork/api";
import { Colors } from "src/FSD/shared/uiKit/v2/Pie";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";

export const usePayment = (processWork: ApiProcessWorkResponses.View) => {
  const isMobile = useIsMobile(560);

  const [updatePrice, { isLoading: isPriceUpdating }] =
    useProcessWorkUpdatePriceMutation();

  const pieChartDataSet = useMemo(() => {
    const dataSet = [
      {
        payPart: processWork.pay1,
        colorHex: Colors.PRIMARY,
      },
      {
        payPart: processWork.pay2,
        colorHex: Colors.SECONDARY,
      },
    ];

    if (processWork.pay3) {
      dataSet.push({
        payPart: processWork.pay3,
        colorHex: Colors.TERTIARY,
      });
    }

    return dataSet;
  }, [processWork]);

  const ndsText = getNdsText(Boolean(processWork.executor_nds));

  const canEditPrice = Boolean(processWork.task) && processWork.can!.update;

  const handleChangePrice = useCallback(async (value: string) => {
    const data = {
      price: Number(value),
    };

    await updatePrice({ id: processWork.id, data });
  }, []);

  return {
    pieChartDataSet,
    ndsText,
    isPriceUpdating,
    handleChangePrice,
    canEditPrice,
    isMobile,
  } as const;
};
