import React, { useEffect, useState } from "react";
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
  IPreviewProps,
} from "react-dropzone-uploader";
import { IInputProps } from "react-dropzone-uploader/dist/Dropzone";
// @ts-ignore
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import cn from "classnames";
import style from "./style.module.scss";
import { headersContentMultiDataType } from "../../../app/services/api/header";
import { request } from "../../../app/services/api/requestHandler";
import { InitialListType, UploaderProps } from "./UploaderTypes/UploaderTypes";
import { UploaderPreview } from "./UploaderPreview/UploaderPreview";
import { UploaderInput } from "./UploaderInput/UploaderInput";
import { UploaderLayout } from "./UploaderLayout/UploaderLayout";

/**
 * Загрузчик файлов.
 *
 * В качестве загрузчика был выбран React Dropzone Uploader {@link https://react-dropzone-uploader.js.org/}.
 *
 * В зависимссти от задачи RDU позволяет отправлять файлы сразу или вручную (по кнопке).
 *
 * Например при создании проекта, проект уже создан в базе и загрузчик можно настроить на автоматическую загрузку.
 *
 * Другой случай, мы создаем задачу и файлы нужно прикрепить к объекту отправки,
 * тогда отключаем автоматическую загрузку и RDU вернет массив файлов, которые можно
 * передать функционалу обработки форм, на Пирсе это Formik
 *  {
 *      comment: "коментарий",
 *      files: [File, File]
 *  }
 *
 * identifier - идентификатор сущности с которой работаем например projectID или taskID
 *
 * postUrlString - строка запроса куда нужно отправить файлы, для автоматической отправки,
 * когда сущность уже создана, например проект
 *
 * canBeEdited - применяется если нужна возможность редактированиия сущности, например удалить неактуальные файлы
 *
 * getDataCallback - запрос на файлы которые уже были загружены для сущности, например для редактирования
 *
 *  { canBeEdited и getDataCallback работают вместе }
 *
 * maxSizeBytes - максимальный размер файла,
 *
 * autoUpload - переключение на авто или ручную загрузку
 *
 * multiple - переключатель количества файлов один или несколько
 *
 * fileHandler - функция callback для передачи файлов для формы отправки
 *
 * */

export const Uploader: React.FC<UploaderProps> = ({
  identifier,
  postUrlString,
  canBeEdited = false,
  getDataCallback,
  responseDataCallback,
  defaultValue,
  autoUpload = true,
  multiple = true,
  fileHandler,
  setInitialListLength,
  listLength,
  setListLength,
  field,
  outFiles,
  handleUpdate,
  uploadAsActive,
  background = "#26313a",
  setNoFilesError,
  newDesign,
}) => {
  const newMaxSizeBytes = String(postUrlString).includes("task/add-file")
    ? 536870912
    : 104857600;

  const [initialList, setInitialList] = useState<any>([]);
  useEffect(() => {
    if (setInitialListLength) {
      setInitialListLength(initialList.length);
    }
  }, [initialList, setInitialListLength]);

  useEffect(() => {
    if (defaultValue) {
      setInitialList(defaultValue);

      if (setListLength) {
        setListLength(defaultValue.length);
      }
    }
  }, [defaultValue]);

  const handleRemove = (id: number) => {
    const newList = initialList.filter((item: InitialListType) => {
      return item.id !== id;
    });

    if (setListLength) {
      setListLength((p) => p - 1);
    }
    setInitialList(newList);
  };
  const handleCheck = (visibility: any, id: string) => {
    const newList = initialList.map((item: any) => {
      if (item.id === id) {
        return { ...item, visibility: visibility.visibility };
      }
      return item;
    });
    setInitialList(newList);
  };
  useEffect(() => {
    if (getDataCallback && identifier) {
      request(getDataCallback(identifier), (res) => {
        if (res) {
          if (responseDataCallback) {
            responseDataCallback(res);
          }
          let files;
          if (field) {
            files = res[field];
          }
          if (!field) {
            files = res.files;
          }
          if (outFiles) {
            files = res[outFiles];
          }
          setInitialList(files);
          if (setListLength) {
            setListLength(files.length);
          }
        }
      })();
    }
  }, [identifier, getDataCallback]);

  const getFilesFromEvent = async (e: any) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);

    if (setListLength) {
      setListLength((p) => p + chosenFiles.length);
    }

    return chosenFiles.map(({ fileObject }: any) => fileObject);
  };

  const getUploadParams: IDropzoneProps["getUploadParams"] = ({ file }) => {
    const body = new FormData();
    body.append("file", file);

    if (uploadAsActive) {
      body.append("visibility", "1");
    }

    return {
      url: identifier ? postUrlString(identifier) : "",
      body,
      headers: { ...headersContentMultiDataType() },
    };
  };

  useEffect(() => {
    if (setNoFilesError) {
      setNoFilesError(!listLength);
    }
  }, [listLength]);

  const getDropzoneParams = (isAuto: boolean) => {
    const config = {
      classNames: {
        dropzone: style.dropzone,
      },
      autoUpload: false,
      multiple,
      maxSizeBytes: newMaxSizeBytes,
      getFilesFromEvent,
      PreviewComponent: (props: IPreviewProps) => (
        <UploaderPreview
          {...props}
          uploadAsActive={uploadAsActive}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
          fileHandler={fileHandler}
          setListLength={setListLength}
        />
      ),
      InputComponent: (props: IInputProps) =>
        canBeEdited ? (
          <UploaderInput
            {...props}
            id={identifier || "newUploading"}
            newDesign={newDesign}
          />
        ) : null,
      LayoutComponent: (props: ILayoutProps) => (
        <UploaderLayout
          {...props}
          initialList={initialList}
          handleRemove={handleRemove}
          handleCheck={handleCheck}
          handleUpdate={handleUpdate}
          fileHandler={fileHandler}
          disabled={!canBeEdited}
          newDesign={newDesign}
        />
      ),
    };

    if (isAuto) {
      return {
        ...config,
        autoUpload: true,
        getUploadParams,
      };
    }

    return config;
  };

  const params = getDropzoneParams(autoUpload);

  return (
    <div
      className={cn(
        style.dropzoneContainer,
        !canBeEdited && style.disabledEditing
      )}
      style={{
        background,
      }}
    >
      <Dropzone {...params} />
    </div>
  );
};
