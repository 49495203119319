import * as Yup from "yup";
import { stringMinMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";
import { ReasonsOfRemoval } from "../../model/constants";

export const validationSchema = Yup.object().shape({
  content: stringMinMaxLengthRequired("Комментарий", 15, 2000),
  reason3: Yup.boolean().when("reason", {
    is: ReasonsOfRemoval.BY_715,
    then: Yup.boolean().oneOf(
      [true],
      "Подтвердите получение предварительных решений"
    ),
    otherwise: Yup.boolean().notRequired(),
  }),
});
