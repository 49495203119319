import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { SelectTasksForProcesses } from "src/FSD/features/v2/SelectMultipleCheck";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { useOutputTask } from "../lib/hooks/useOutputTask";
import { Statuses } from "../../../../../../shared/lib/constants/statuses";
import { config } from "../../../../../../shared/lib/configs/app.config";

type Props = {
  taskId: number;
  header: string;
};

export const OutputTaskForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    control,
    isSubmitting,
    isRejecting,
    isDone,
    isTaskViewPending,
    taskData,
  } = useOutputTask({
    taskId,
    defaultValues: {
      status: null,
      comment: "",
      files: [],
      processes: [],
      check: false,
      needFiles: false,
    },
    handleClose,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          {taskData!.need_files && (
            <>
              <Controller
                name="processes"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectTasksForProcesses
                    processId={taskData!.taskProcess!.id}
                    projectId={taskData!.project!.id}
                    changeHandler={(newValue) => {
                      onChange(newValue);
                    }}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="check"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label="Выдавать всем новым смежникам"
                    onChange={onChange}
                    value={value}
                    checked={!!value}
                  />
                )}
              />
              <Controller
                name="files"
                control={control}
                render={({ field: { onChange } }) => (
                  <Uploader
                    canEdit
                    getDataCallback={() => getTaskFiles(taskId)}
                    setFiles={(files) => {
                      onChange(files);
                    }}
                    error={Boolean(errors.files)}
                    helperText={errors.files?.message}
                    autoUpload
                    postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
                  />
                )}
              />
            </>
          )}
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isRejecting}
              type="submit"
              color="error"
              variant="outlined"
              onClick={() => {
                setValue("status", Statuses.IS_REJECT);
              }}
            >
              Не требуется
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isDone}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.IS_DONE);
              }}
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
