import React from "react";
import style from "./style.module.scss";
import { PostSkeletonItem } from "./PostSkeleton/PostSkeleton";

export const PostSkeletonList = () => {
  return (
    <div className={style.postSkeletonList}>
      <PostSkeletonItem />
      <PostSkeletonItem />
      <PostSkeletonItem />
      <PostSkeletonItem />
      <PostSkeletonItem />
    </div>
  );
};
