import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type HamburgerProps = ComponentPropsType<{
  isActive?: boolean;
  openHandler?: VoidFunction;
  closeHandler?: VoidFunction;
  variant?: string;
}>;

export const Hamburger: React.FC<HamburgerProps> = ({
  isActive = false,
  variant = "",
  closeHandler = () => {},
  openHandler = () => {},
}) => {
  const onClick = isActive ? closeHandler : openHandler;

  return (
    <div
      tabIndex={-1}
      onClick={onClick}
      aria-haspopup="true"
      aria-controls="menu2"
      role="button"
      className={classNames(style.hamburger, variant, isActive && style.active)}
    >
      <div className={classNames(style.line, style.top)} />
      <div className={classNames(style.line, style.center)} />
      <div className={classNames(style.line, style.bottom)} />
    </div>
  );
};
