import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessDelegate } from "../../../../services/api/projectProcess/processWork";
import { pushError } from "../../../errorTrace";

interface DelegateParams {
  processId: number;
  userId: number;
  data: {
    delegate: boolean;
  };
}
export const fetchProjectProcessDelegate = createAsyncThunk<
  boolean,
  DelegateParams
>(
  "processWork/fetchProjectProcessDelegate",
  async ({ processId, userId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDelegate(processId, userId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
