type ButtonType = "disabled" | "active" | "selected" | "none";

export const getTabByPrompt = (
  prompt: string,
  projectId: number,
  sectionId: number,
  workId: number
) => {
  let newTab = "";
  if (prompt === "Проекты") {
    newTab = "project->section";
  }
  if (prompt === "Канбан") {
    newTab = "kanban";
  }
  if (prompt === "Договоры") {
    if (projectId) {
      newTab = "project->treaty";
    }

    if (sectionId) {
      newTab = "section->treaty";
    }

    if (workId) {
      newTab = "work->treaty";
    }
  }
  if (prompt === "Заявки") {
    newTab = "section->application";

    if (workId) {
      newTab = "work->application";
    }
  }
  if (prompt === "Задачи") {
    newTab = "section->tasksAndWorks";

    if (workId) {
      newTab = "work->tasks";
    }
  }

  return newTab;
};

export const getTypeByPrompt = (
  prompt: string,
  projectId: number,
  tab: string,
  treatiesDisabled: boolean
): ButtonType => {
  if (prompt === "Отчет по ИД") {
    return "active";
  }

  if (prompt === "Проекты") {
    if (tab === "project->section" || tab === "section") {
      return "selected";
    }

    return "active";
  }
  if (prompt === "Канбан") {
    if (tab === "kanban") {
      return "selected";
    }

    return "active";
  }
  if (prompt === "Договоры") {
    if (!projectId || treatiesDisabled) {
      return "disabled";
    }

    if (
      tab === "project->treaty" ||
      tab === "section->treaty" ||
      tab === "work->treaty"
    ) {
      return "selected";
    }

    return "active";
  }
  if (prompt === "Заявки") {
    if (tab === "section->application" || tab === "work->application") {
      return "selected";
    }

    if (!projectId || treatiesDisabled) {
      return "disabled";
    }

    return "active";
  }
  if (prompt === "Задачи") {
    if (tab === "section->tasksAndWorks" || tab === "work->tasks") {
      return "selected";
    }

    if (!projectId || treatiesDisabled) {
      return "disabled";
    }

    return "active";
  }
  if (prompt === "Отчет") {
    return "active";
  }

  return "disabled";
};

/*

  sectionId: number,
  containsApplications: boolean
*/
