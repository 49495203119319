import React from "react";
import cls from "./Fieldset.module.scss";

interface FieldsetProps {
  legend: string;
  children: React.ReactNode;
}

export const Fieldset: React.FC<FieldsetProps> = ({ legend, children }) => {
  return (
    <fieldset className={cls.fieldset}>
      <legend>{legend}</legend>
      <div className={cls.fieldset_files}>{children}</div>
    </fieldset>
  );
};

export default Fieldset;
