import React from "react";
import { trimPrice } from "src/utils/trimNumber";
import styles from "./ChildDot.module.scss";

export const ChildDot = ({ child }: any) => {
  const value = child.gantt;
  const isProject = child.gantt.project;
  const isWork = child.gantt.work;
  const isResearch = child.gantt.research;
  const isInspection = child.gantt.inspection;
  const { name } = child.project;
  return (
    <>
      <p className={styles.firstCol}>{name}</p>
      <div className={styles.container}>
        {isInspection && (
          <>
            <div
              className={styles.dot}
              style={{ background: value.inspection.color }}
            />
            <p>{`${trimPrice(value.inspection?.price)} ₽`} </p>
          </>
        )}
      </div>
      <div className={styles.container}>
        {isResearch && (
          <>
            <div
              className={styles.dot}
              style={{ background: value.research.color }}
            />
            <p>{`${trimPrice(value.research?.price)} ₽`} </p>
          </>
        )}
      </div>
      <div className={styles.container}>
        {isProject && (
          <>
            <div
              className={styles.dot}
              style={{ background: value.project.color }}
            />
            <p>{`${trimPrice(value.project?.price)} ₽`} </p>
          </>
        )}
      </div>
      <div className={styles.container}>
        {isWork && (
          <>
            <div
              className={styles.dot}
              style={{ background: value.work.color }}
            />
            <p>{`${trimPrice(value.work?.price)} ₽`} </p>
          </>
        )}
      </div>
      <div className={styles.container}>
        <div
          className={styles.dot}
          style={{ background: child.priceFact.color }}
        />
        <p>{`${trimPrice(child.priceFact.price)} ₽`} </p>
      </div>
    </>
  );
};
