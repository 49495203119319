import React from "react";
import { Skeleton } from "@mui/material";
import style from "./style.module.scss";

export const CarouselSkeleton = () => {
  return (
    <div className={style.carousel}>
      <div className={style.carouselContainer}>
        <div className={style.buttonWrapper}>
          {/* <Skeleton variant="rect" className={style.button} /> */}
        </div>
        <div className={style.infoWrapper}>
          <div className={style.titles}>
            <Skeleton variant="rectangular" className={style.titleSmall} />
            <Skeleton variant="rectangular" className={style.titleBig} />
          </div>
          <div className={style.shortInfo}>
            <Skeleton
              variant="rectangular"
              className={style.shortDescription}
            />
            <Skeleton variant="rectangular" className={style.newsLink} />
          </div>
        </div>
        <div className={style.buttonWrapper}>
          <Skeleton variant="rectangular" className={style.button} />
        </div>
      </div>
    </div>
  );
};
