import React from "react";
import classNames from "classnames";
import { KeyboardArrowIcon } from "src/components/newUI/KeyboardArrowIcon/KeyboardArrowIcon";
import { FolderIcon, FolderWarningIcon } from "src/components/newUI/SVG";
import styles from "./CardHeader.module.scss";

type TProps = {
  title: string;
  expanded: boolean;
  onClick?: () => void;
  dot?: boolean;
  inWork?: number;
  all?: number;
};

export const CardHeader = ({
  title,
  onClick,
  dot = false,
  expanded = false,
  inWork,
  all,
}: TProps) => {
  return (
    <div className={styles.layout} onClick={onClick}>
      <div className={styles.body}>
        {dot ? (
          <FolderWarningIcon />
        ) : (
          <FolderIcon width={"24"} height={"24"} />
        )}
        <div className={styles.text}>
          <span>{title}</span>
          {!!all && (
            <span
              className={classNames(
                inWork === all && inWork !== 0 && styles.complete
              )}
            >{`(${inWork}/${all})`}</span>
          )}
        </div>
      </div>
      <KeyboardArrowIcon isOpen={expanded} color={"#D5D6DE"} />
    </div>
  );
};
