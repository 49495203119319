import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetOnlineCount = (
  userRoleKey: number | string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/get-online-count?type=${userRoleKey}`,
    {}
  );
};

export const ApiGetTotalCount = (
  userRoleKey: number | string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/get-total-count?type=${userRoleKey}`,
    {}
  );
};

export const ApiGetTotalProjects = (): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/project/get-total-count`, {});
};

export const ApiGetNewProjectsCount = (): Promise<Response> => {
  return httpRequestGet(`${config.localDomain}/v1/project/get-new-count`, {});
};

export const ApiGetTotalProcessProject = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/published-object/get-total-count`,
    {}
  );
};

export const ApiGetNewProcessProjectCount = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/published-object/get-new-count`,
    {}
  );
};

export const ApiGetTotalMaterials = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/get-total-count`,
    {}
  );
};

export const ApiGetNewMaterials = (): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/material-process/get-new-count`,
    {}
  );
};
