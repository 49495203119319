import React, { useState } from "react";
import classNames from "classnames";
import { TaskValueType } from "src/app/feature/ProjectView/TaskReducer/thunks";
import { selectColor } from "src/utils/selectColor";
import style from "./style.module.scss";
import { ChatIcon } from "../../../../newUI/SVG";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { EditDateDeadLine } from "./EditDateDeadLine/EditDateDeadLine";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { Dot } from "../../../../newUI/Dot/Dot";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "../CheckTasks/CheckTaskCard";
import Task from "./Task";

type TreeProps = {
  id?: number;
  data?: TaskValueType[];
  chatId?: number;
  setActiveTabMemo?: (id: string | number) => void;
  projectId?: number;
  processId?: number;
  type: "project" | "process";
  setModalData?: SetState<TModalData>;
};
type TreeNodeProps = {
  id?: number;
  node: TaskValueType;
  chatId?: number;
  setActiveTabMemo?: (id: string | number) => void;
  projectId?: number;
  processId?: number;
  type: "project" | "process";
  setModalData?: SetState<TModalData>;
};

const TreeTask = ({
  id,
  data = [],
  chatId,
  setActiveTabMemo,
  projectId,
  type,
  processId,
  setModalData,
}: TreeProps) => {
  return (
    <>
      {data.map((tree, index) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode
          node={tree}
          type={type}
          id={id}
          chatId={chatId}
          setActiveTabMemo={setActiveTabMemo}
          projectId={projectId}
          key={index}
          processId={processId}
          setModalData={setModalData}
        />
      ))}
    </>
  );
};

const TreeNode = ({
  id,
  node,
  setActiveTabMemo,
  projectId,
  type,
  processId,
  chatId,
  setModalData,
}: TreeNodeProps) => {
  const [childVisible, setChildVisibility] = useState(false);
  const [toggleDiv, setToggleDiv] = useState(false);

  const hasChild = !!node.tasks;
  const hasCard = !!node.title;
  const canEditDate =
    node.tasks &&
    node.tasks[node.tasks.length - 1]?.can["update-date-deadline"];
  const isRedStatus = node?.taskStatusInfo?.bgClass === "pirs-red";

  // @ts-ignore
  const access = node?.tasks[0]?.can;

  const arrowIcon = <ToggleAccordion toggle={childVisible} />;

  const arrowIconType = <ToggleAccordion toggle={toggleDiv} />;

  return (
    <>
      <div
        className={classNames(hasChild && !hasCard && style.node)}
        onClick={() => setChildVisibility((v) => !v)}
      >
        {node.partName && (
          <div className={style.dot}>
            {node.dot && <Dot status={node.dot} size={8} />}
            <span>{`${node.partName} (${node.count})`}</span>
          </div>
        )}
        {!hasChild && <div className={style.parent}>{node.header}</div>}
        {node.title && (
          <div
            className={classNames(style.cardList_color)}
            style={{ background: selectColor(node.taskStatusInfo.bgClass) }}
          >
            <div className={style.content}>
              <div className={style.content_text}>
                <h3 title={node.title}>{node.header}</h3>
                <p className={style.projectText}>{node.project}</p>
                <p>{node.executorName}</p>
                <div onClick={() => setToggleDiv((v) => !v)}>
                  <p>Открыть версии</p>
                  {arrowIconType}
                </div>
              </div>
              <div className={style.content_btn}>
                {access.chat && (
                  <TextLink
                    title={"Чат"}
                    redirect
                    url={`/site/front/chat/${chatId}`}
                  >
                    <ChatIcon width="22" height="22" />
                  </TextLink>
                )}
              </div>
            </div>
            {!toggleDiv && (
              <div className={style.status}>
                <div className={style.status_dot}>
                  <Dot status={node?.taskStatusInfo?.dot} />
                  <p
                    className={classNames(isRedStatus && style.statusRed)}
                    dangerouslySetInnerHTML={{
                      __html: node.taskStatusInfo.statusString,
                    }}
                  />
                </div>
                {!canEditDate && (
                  <p className={classNames(isRedStatus && style.statusRed)}>
                    {node.taskStatusInfo.date}
                  </p>
                )}
                {canEditDate && (
                  <EditDateDeadLine
                    node={node}
                    projectId={projectId}
                    processId={processId}
                    type={type}
                  />
                )}
              </div>
            )}
            {toggleDiv && (
              <div className={style.taskContainer}>
                <h3>Версии заданий</h3>
                {node.tasks?.map((task) => {
                  return (
                    <Task
                      id={id}
                      key={task.task.id}
                      projectId={projectId}
                      type={type}
                      processId={processId}
                      setActiveTabMemo={setActiveTabMemo}
                      setModalData={setModalData}
                      node={node}
                      task={task}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
        {hasChild && !hasCard && <div>{arrowIcon}</div>}
      </div>

      {node.tasks && childVisible && !hasCard && (
        <div className={classNames(hasChild && !hasCard && style.cardList)}>
          <TreeTask
            data={Object.values(node?.tasks)}
            projectId={projectId}
            processId={processId}
            type={type}
            id={id}
            chatId={chatId}
            setModalData={setModalData}
          />
        </div>
      )}
    </>
  );
};

export default TreeTask;
