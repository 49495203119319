import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";

export type TStatusType = "work" | "terminated" | "done";

export type TParams = RequestParams<{
  id: string | undefined;
  statusType: string;
}>;

export const statusObject: Record<TStatusType, string> = {
  work: "DocumentSearch[status][]=1&DocumentSearch[status][]=2&DocumentSearch[status][]=3",
  terminated: "DocumentSearch[status]=4",
  done: "DocumentSearch[status]=5",
};

export const getDocTree = (builder: QueryBuilder) =>
  builder.query<ApiDocumentsResponses.View[], TParams>({
    query: ({ id, statusType }) => {
      const statusValue =
        statusObject[statusType as TStatusType] || statusObject.work;
      const queryString = `id=${id}&${statusValue}`;

      return {
        url: `v1/project/get-doc-tree?${queryString}`,
      };
    },
  });
