import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const sxHeader = { bgcolor: "#101920" };
const sxBody = { bgcolor: "#1A2734" };

export const AdminSkeleton = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sxHeader} height={102} />
      </Grid>
      <Grid item xs={4} md={4}>
        <Skeleton variant="rounded" sx={sxBody} height={246} />
      </Grid>
      <Grid item xs={4} md={4}>
        <Skeleton variant="rounded" sx={sxBody} height={246} />
      </Grid>
      <Grid item xs={4} md={4}>
        <Skeleton variant="rounded" sx={sxBody} height={246} />
      </Grid>
    </Grid>
  );
};
