import { useEffect, FC } from "react";
import cn from "classnames";
import moment from "moment";
import { InputAdornment } from "@mui/material";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Controller } from "react-hook-form";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import styles from "../ProcessPublication/style.module.scss";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";
import { useAppSelector } from "../../../../../../app/store";
import { getGroupPublishProcessData } from "../../../../../../app/feature/project/projectProcessPublication";

const DatesForm: FC<any> = ({
  list,
  title,
  register,
  control,
  setValue,
  watch,
  trigger,
  errors,
  processId,
  typeId,
  maybeExpertise,
}) => {
  const {
    data: { isTouched },
  } = useAppSelector(getGroupPublishProcessData);

  const watchIsUnique = watch(`${typeId}.isUnique`);
  const watchIsDiverse = watch(`${typeId}.isDiverse`);
  const watchTotalPrice = watch(`${typeId}.totalPrice`);
  const watchDateStart = watch(`${typeId}.dateStart`);
  const watchDateLimit = watch(`${typeId}.dateLimit`);

  const activeProcesses = Object.keys(list).filter((id: string) => {
    const isActive = watch(`processes.${typeId}.list.${id}.active`);
    return isActive;
  }).length;

  const areAllSelected = activeProcesses === Object.keys(list).length;

  const handleSelectAll = () => {
    const isSelected = !areAllSelected;

    Object.keys(list).forEach((id: string) => {
      setValue(
        `processes.${typeId}.list.${id}.active`,
        isSelected || Number(id) === processId
      );
    });
  };

  const handleUpdatePrices = () => {
    const activeProcessesCount = Object.keys(list).reduce(
      (count: number, id: string) => {
        const isActive = Number(
          Boolean(watch(`processes.${typeId}.list.${id}.active`))
        );

        return count + isActive;
      },
      0
    );

    const value = Math.floor(Number(watchTotalPrice) / activeProcessesCount);

    Object.keys(list).forEach((id: string) => {
      const isActive = watch(`processes.${typeId}.list.${id}.active`);

      if (watchIsDiverse && !Number.isNaN(value)) {
        setValue(`processes.${typeId}.list.${id}.price`, isActive ? value : "");
      }

      setTimeout(() => {
        trigger(`processes.${typeId}.list.${id}.price`);
      });
    });
  };

  const handleUpdateDates = () => {
    Object.keys(list).forEach(async (id: string) => {
      const isActive = watch(`processes.${typeId}.list.${id}.active`);

      if (isActive && watchIsUnique) {
        setValue(`processes.${typeId}.list.${id}.dateStart`, watchDateStart);
        setValue(`processes.${typeId}.list.${id}.dateLimit`, watchDateLimit);
      }

      setTimeout(() => {
        trigger(`processes.${typeId}.list.${id}.dateStart`);
        trigger(`processes.${typeId}.list.${id}.dateLimit`);
      });
    });
  };

  useEffect(() => {
    if (!isTouched) {
      return;
    }

    handleUpdateDates();
  }, [watchIsUnique, watchDateLimit, watchDateStart, areAllSelected]);

  useEffect(() => {
    if (!isTouched) {
      return;
    }

    handleUpdatePrices();
  }, [watchIsDiverse, watchTotalPrice, areAllSelected]);

  useEffect(() => {
    Object.entries(list).forEach(([id, item]: any) => {
      const { dateStart, dateLimit, price } = item;

      const isDateStartAlreadySet = Boolean(
        watch(`processes.${typeId}.list.${id}.dateLimit`)
      );
      const isDateLimitAlreadySet = Boolean(
        watch(`processes.${typeId}.list.${id}.dateLimit`)
      );
      const isPriceAlreadySet = Boolean(
        watch(`processes.${typeId}.list.${id}.dateLimit`)
      );

      if (dateStart && !isDateStartAlreadySet) {
        const parsedDate = moment(dateStart, "DD.MM.YYYY").toDate();
        setValue(`processes.${typeId}.list.${id}.dateStart`, parsedDate);
      }

      if (dateLimit && !isDateLimitAlreadySet) {
        const parsedDate = moment(dateLimit, "DD.MM.YYYY").toDate();
        setValue(`processes.${typeId}.list.${id}.dateLimit`, parsedDate);
      }

      if (price && !isPriceAlreadySet) {
        setValue(`processes.${typeId}.list.${id}.price`, Math.floor(price));
      }
    });
  }, []);

  const Head = () => (
    <>
      <p>Наименование</p>
      <p>Начальный срок</p>
      <p>Конечный срок</p>
      <p>Стоимость</p>
      <div className={styles.checkBox}>
        <CustomCheckbox
          editing
          checked={areAllSelected}
          onChange={handleSelectAll}
        />
        <p>Выбрать все</p>
      </div>
      <div />
      <div />
      <div />
    </>
  );

  return (
    <>
      <p className={cn(styles.title, styles.processGroupName)}>{title}</p>
      <p className={styles.header}>
        Укажите общие стоимость, сроки и условия оплаты для всех разделов
      </p>
      <div
        className={cn(styles.gridFields, !maybeExpertise && styles.lessColumns)}
      >
        <TextField
          label="Стоимость"
          {...register(`${typeId}.totalPrice`)}
          className={styles.field1}
          InputProps={{
            endAdornment: <InputAdornment position="end">₽</InputAdornment>,
          }}
        />
        <Controller
          control={control}
          name={`${typeId}.dateStart`}
          render={({ field: { value }, fieldState: { error } }) => (
            <DateField
              changeDateHandler={async (newValue) => {
                setValue(`${typeId}.dateStart`, newValue);
                await trigger(`${typeId}.dateStart`);
              }}
              label="Начальный срок"
              className={styles.field2}
              startDateProp={value as Date}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`${typeId}.dateLimit`}
          render={({ field: { value }, fieldState: { error } }) => {
            const isError = error || errors.dateStart;

            return (
              <DateField
                changeDateHandler={async (newValue) => {
                  setValue(`${typeId}.dateLimit`, newValue);
                  await trigger(`${typeId}.dateStart`);
                }}
                className={styles.field3}
                label="Конечный срок"
                startDateProp={value as Date}
                error={Boolean(isError)}
                helperText={error?.message}
              />
            );
          }}
        />
      </div>
      <div className={styles.checkBox}>
        <Controller
          control={control}
          name={`${typeId}.isDiverse`}
          render={({ field: { value } }) => (
            <CustomCheckbox
              editing
              checked={value}
              onChange={() => {
                setValue(`${typeId}.isDiverse`, !value);
              }}
            />
          )}
        />
        <p>
          Распределить общую стоимость пропорционально количеству выбранных
          разделов
        </p>
      </div>
      <div className={styles.checkBox}>
        <Controller
          control={control}
          name={`${typeId}.isUnique`}
          render={({ field: { value } }) => (
            <CustomCheckbox
              editing
              checked={value}
              onChange={() => {
                setValue(`${typeId}.isUnique`, !value);
              }}
            />
          )}
        />
        <p>Единый срок выполнения для всех разделов</p>
      </div>
      <p className={styles.header}>
        Выберите разделы и укажите индивидуальные сроки и стоимость
      </p>
      <div className={styles.checkboxGrid}>
        <Head />
        {/* список: | дата нач. | дата конца | стоимость */}
        <>
          {Object.entries(list).map(([id, item]: any) => {
            return (
              <>
                <div className={styles.checkBox}>
                  <Controller
                    control={control}
                    name={`processes.${typeId}.list.${id}.active`}
                    render={({ field: { value } }) => {
                      return (
                        <CustomCheckbox
                          checked={value}
                          editing
                          disabled={Number(id) === processId}
                          onChange={() => {
                            setValue(
                              `processes.${typeId}.list.${id}.active`,
                              !value
                            );

                            setTimeout(() => {
                              handleUpdateDates();
                              handleUpdatePrices();
                            });
                          }}
                        />
                      );
                    }}
                  />
                  <p>{item.name}</p>
                </div>
                <Controller
                  control={control}
                  name={`processes.${typeId}.list.${id}.dateStart`}
                  render={({ field: { value }, fieldState: { error } }) => {
                    const isActive = watch(
                      `processes.${typeId}.list.${id}.active`
                    );

                    return (
                      <DateField
                        isCorrect={isActive && !error && value}
                        changeDateHandler={async (newValue) => {
                          setValue(
                            `processes.${typeId}.list.${id}.dateStart`,
                            newValue
                          );
                          await trigger(
                            `processes.${typeId}.list.${id}.dateStart`
                          );
                        }}
                        label=""
                        startDateProp={value}
                        error={Boolean(error)}
                        helperText={error?.message}
                        disabled={watchIsUnique}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name={`processes.${typeId}.list.${id}.dateLimit`}
                  render={({ field: { value }, fieldState: { error } }) => {
                    const isActive = watch(
                      `processes.${typeId}.list.${id}.active`
                    );
                    const isError = error || errors.processes?.[id]?.dateStart;

                    return (
                      <DateField
                        isCorrect={isActive && !isError && value}
                        changeDateHandler={async (newValue) => {
                          setValue(
                            `processes.${typeId}.list.${id}.dateLimit`,
                            newValue
                          );
                          await trigger(
                            `processes.${typeId}.list.${id}.dateLimit`
                          );
                          await trigger(
                            `processes.${typeId}.list.${id}.dateStart`
                          );
                        }}
                        label=""
                        startDateProp={value}
                        error={Boolean(isError)}
                        helperText={error?.message}
                        disabled={watchIsUnique}
                      />
                    );
                  }}
                />
                <TextField
                  {...register(`processes.${typeId}.list.${id}.price`)}
                  error={Boolean(
                    errors?.processes?.[typeId]?.list?.[id]?.price
                  )}
                  helperText={
                    errors?.processes?.[typeId]?.list?.[id]?.price?.message
                  }
                  InputLabelProps={{ shrink: true }}
                  disabled={watchIsDiverse}
                />
              </>
            );
          })}
        </>
      </div>
    </>
  );
};

export default DatesForm;
