export const getTeamsList = (data: any[], userId: number) => {
  const teams = data
    .map((staff: any) => staff.teams)
    .reduce((allTeams, teams: any) => [...allTeams, ...teams], [])
    .filter((team: any) => team.teamlead.id === userId);

  const teamsIds: number[] = Array.from(
    new Set(teams.map((team: any) => team.id))
  );

  const teamsList: any[] = [];
  teamsIds.forEach((id: number) => {
    const team = teams.find((team: any) => team.id === id);
    const title = Object.values(team.part_group_id)[0];

    teamsList.push({ id: team.id, title });
  });

  return teamsList;
};

export const getUsersToRemoveFromTeam = (data: any[], teamId: number) => {
  const members = data
    .filter((staff: any) => {
      const isInTeam = Boolean(
        staff.teams.find((team: any) => team.id === teamId)
      );

      return isInTeam && staff.can?.beRemovedFromTeam;
    })
    .map((staff: any) => ({ id: staff.user_id, title: staff.name }));

  return members;
};
