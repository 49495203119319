import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { Content } from "./Content/Content";
import { BodyHeader } from "./BodyHeader/BodyHeader";
import { BodyFooter } from "./BodyFooter/BodyFooter";
import { ChatProps } from "./chatProps";
import { withClassCurry } from "../../../utils";
import { FilterField } from "./FilterField/FilterField";
import { RoomList } from "./RoomGroup/RoomListUI";

export const Chat: React.FC<ChatProps> = React.memo(
  ({
    theme = "dark",
    roomsData = [],
    updDate,
    messagesData = [],
    initialPositionScrollY = "",
    setMessageHandler = () => {},
    clickContactHandler = () => {},
    scrollYPositionHandler = () => {},
    backButtonHandler = () => {},
    filterValue = "",
    filterHandler = () => {},
    userData = {
      userName: "",
      avatarUrl: "",
    },
    activeRoomId = -1,
    userInRoom = false,
    isDisableBottom = true,
    isShowMenu = true,
    closeGroupHandler = () => {},
    openGroupHandler = () => {},
  }) => {
    const withTheme = withClassCurry(style[theme]);
    return (
      <>
        <div className={style.chatWrapper}>
          <div
            className={withTheme(
              classNames(style.chat, userInRoom && style.active)
            )}
          >
            {isShowMenu && (
              <div className={withTheme(style.menu)}>
                <div className={withTheme(style.menuHead)}>
                  <FilterField
                    value={filterValue}
                    theme={theme}
                    changeHandler={filterHandler}
                  />
                </div>

                <div className={style.usersWrapper} id="roomList">
                  <ul className={withTheme(style.userList)}>
                    <RoomList
                      theme={theme}
                      updDate={updDate}
                      activeRoomId={activeRoomId}
                      data={roomsData}
                      joinRoomHandler={(roomId) =>
                        clickContactHandler({
                          roomId: String(roomId),
                          avatarUrl: "",
                          userName: "",
                        })
                      }
                      closeGroupHandler={closeGroupHandler}
                      openGroupHandler={openGroupHandler}
                    />
                  </ul>
                </div>
              </div>
            )}

            <div className={withTheme(style.body)}>
              <BodyHeader
                backButtonHandler={backButtonHandler}
                userData={userData}
                theme={theme}
              />

              <Content
                initialPositionScrollY={initialPositionScrollY}
                scrollYPositionHandler={scrollYPositionHandler}
                messages={messagesData}
                theme={theme}
              />
              {!isDisableBottom && (
                <BodyFooter onSetMessage={setMessageHandler} theme={theme} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);
