export type ColorType =
  | "pirs-gray"
  | "pirs-red"
  | "pirs-green"
  | "grey"
  | "ok"
  | "warning"
  | "error"
  | "pirs-bg3";

export function selectColor(color: ColorType) {
  switch (color) {
    case "pirs-gray":
    case "grey":
      return "#A7B0B7";
    case "pirs-green":
    case "ok":
      return "#3BAF6A";
    case "pirs-red":
    case "error":
      return "#BB4242";
    case "warning":
      return "#E8A037";
    case "pirs-bg3":
      return "#26313A";
    default:
      return "#A7B0B7";
  }
}
