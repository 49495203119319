import { RootState } from "../../../../store";

export const getProjectProcessRemoveExecutorObjects = (state: RootState) =>
  state.projectProcessView.removeExecutor.objects;

export const getProjectProcessRemoveExecutorReasons = (state: RootState) =>
  state.projectProcessView.removeExecutor.executorRemoveReasons;

export const getProjectProcessCheckedReason2 = (state: RootState) =>
  state.projectProcessView.removeExecutor.checkedReason2;

export const getProjectProcessCheckedAgree = (state: RootState) =>
  state.projectProcessView.removeExecutor.checkedAgree;

export const getProjectProcessReasonValue = (state: RootState) =>
  state.projectProcessView.removeExecutor.reasonValue;

export const getProjectProcessContentValue = (state: RootState) =>
  state.projectProcessView.removeExecutor.contentValue;

export const getProjectProcessCheckedRefund = (state: RootState) =>
  state.projectProcessView.removeExecutor.checkedRefund;

export const getProjectProcessCheckedPrice = (state: RootState) =>
  state.projectProcessView.removeExecutor.checkedPrice;

export const getProjectProcessRemoveExecutorArticleList = (state: RootState) =>
  state.projectProcessView.removeExecutor.article717List;

export const getProjectProcessRemoveExecutorContract = (state: RootState) =>
  state.projectProcessView.removeExecutor.contractName;
export const getProjectProcessRemoveExecutorDataIsLoad = (state: RootState) =>
  state.projectProcessView.removeExecutor.isLoadExecutorRemoveData;

export const getRemoveExecutorCompleteIsLoad = (state: RootState) =>
  state.projectProcessView.removeExecutor.isLoadRemoveExecutorComplete;
export const getRemoveExecutorIsLoad = (state: RootState) =>
  state.projectProcessView.removeExecutor.isLoadExecutorRemove;
