import { useEffect, useState } from "react";
import { useUserViewQuery } from "src/FSD/entities/user/api";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { getUserId } from "../../../../../../app/services/auth/auth";
import { TSelectItem } from "../../../../../../components/features/Forms/SetGipForm/types";

type UseSelectProps = {
  setValue?: any;
  userTypeId?: number;
};

export const useRequestUpdate = ({ setValue, userTypeId }: UseSelectProps) => {
  const userId = getUserId();

  const { data: userInfo, isFetching: isFetchingUserInfo } = useUserViewQuery({
    id: userId,
    expand: ["userTypes"],
  });

  const [userTypes, setUserTypes] = useState<TSelectItem[]>([]);

  const isPendingUserTypes = !userInfo || isFetchingUserInfo;

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const userTypesToSelect = userInfo.userTypes.map(
      (item: ApiCommonResponses.UserType) => ({
        id: item.id,
        title: item.listName,
        shortTypeName: item.shortTypeName,
      })
    );
    setUserTypes(userTypesToSelect);
    setValue("bimUser", userInfo.work_with_bim);
  }, [userInfo]);

  useEffect(() => {
    if (!userTypeId || !userTypes) {
      return;
    }

    const userType = userTypes.find((item) => item.id === Number(userTypeId));

    setValue("userType", userType);
  }, [userTypeId, userTypes]);

  return {
    isPendingUserTypes,
    userTypes,
  } as const;
};
