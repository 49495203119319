import { KanbanModel, KanbanSortValues } from "src/FSD/entities/kanban";
import { getFilterValuesFromLocalStorage } from "src/FSD/pages/v2/kanban/lib/helpers/getFilterValuesFromLocalStorage";
import { saveFilterValuesInLocalStorage } from "src/FSD/pages/v2/kanban/lib/helpers/saveFilterValuesInLocalStorage";
import { getUserId } from "../../../../../../../app/services/auth/auth";

export const getFilterValues = (userTypeId: number) => {
  const userId = getUserId();
  let actualFilterParams = getFilterValuesFromLocalStorage(userId, userTypeId);

  if (!actualFilterParams) {
    actualFilterParams = {
      ...KanbanModel.initialFilterParams,
      sort: KanbanSortValues.ASC,
      groupBy: {
        id: "",
        title: "Без группипровки",
      },
    };

    saveFilterValuesInLocalStorage(userTypeId, actualFilterParams);
  }

  return actualFilterParams;
};
