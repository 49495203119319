export const DoneSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2347_33188)">
      <path
        d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
        fill="#0AA699"
      />
      <path
        d="M10.97 4.96979C10.9628 4.9767 10.9562 4.98404 10.95 4.99179L7.47697 9.41679L5.38397 7.32279C5.2418 7.19031 5.05375 7.11819 4.85945 7.12162C4.66515 7.12505 4.47976 7.20376 4.34235 7.34117C4.20494 7.47858 4.12623 7.66397 4.1228 7.85827C4.11937 8.05257 4.19149 8.24062 4.32397 8.38279L6.96997 11.0298C7.04125 11.1009 7.12614 11.157 7.21956 11.1946C7.31298 11.2323 7.41302 11.2507 7.51372 11.2488C7.61442 11.247 7.71371 11.2249 7.80567 11.1838C7.89763 11.1427 7.98038 11.0835 8.04897 11.0098L12.041 6.01979C12.1769 5.87712 12.2512 5.68669 12.2479 5.48966C12.2445 5.29263 12.1637 5.10484 12.023 4.96689C11.8823 4.82893 11.693 4.7519 11.4959 4.75244C11.2988 4.75299 11.1099 4.83106 10.97 4.96979Z"
        fill="#0AA699"
      />
    </g>
    <defs>
      <clipPath id="clip0_2347_33188">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const WorkSvg = ({ fill = "#0AA699" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2347_33212)">
      <path
        d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2347_33212">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
