import { httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiPostRegistrationForm = (data: {
  [key: string]: { [key: string]: string | boolean | number[] };
}): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/site/registration`, {}, data);

export const ApiPostEmailConfirm = (
  id = "",
  data: { [key: string]: { [key: string]: string } }
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/site/email-confirm?id=${id}`,
    {},
    data
  );
