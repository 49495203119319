import { ChangeEvent, ReactNode } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import classNames from "classnames";
import styles from "./SwitchLabel.module.scss";

type TProps = SwitchProps & {
  checked: boolean;
  label?: ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  labelPlacement?: "start" | "end" | "top" | "bottom";
};

const switchStyles: Record<string, any> = {
  width: 42,
  height: 24,
  padding: 0,
  alignItems: "center",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    top: "auto",
    transform: "translateX(2px)",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#0AA699",
        border: 0,
        width: 20,
        height: 20,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(10, 166, 153, 0.50)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    backgroundColor: "#838A93",
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: "#6B7D8A",
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
};

const Switch = styled(MuiSwitch)(() => switchStyles);

export const SwitchLabel = ({
  checked,
  label,
  onChange,
  labelPlacement,
  ...props
}: TProps) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      className={classNames(styles.switchLabel, {
        [styles.emptyLabel]: !label,
      })}
      control={<Switch {...props} checked={checked} onChange={onChange} />}
    />
  );
};
