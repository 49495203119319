export const frontFooterNavigationMock = [
  {
    title: "Проекты",
    url: "/project/search",
    outer: false,
  },
  {
    title: "Заказчики",
    url: "/user/search-customers",
    outer: false,
  },
  {
    title: "ГИП",
    alt: "Главный инженер проекта",
    url: "/user/search-gip",
    outer: false,
  },
  {
    title: "Проектировщики",
    url: "/user/search-executors",
    outer: false,
  },
  {
    title: "Задать вопрос",
    url: "/site/contact",
    outer: true,
  },
];
