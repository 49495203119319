import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../api";

export const ApiGetCustomersList = (
  paramsString: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/index-customer${paramsString}&expand=portfolios,jobPlaces,educations,rewards,feedbacks,additionalInfo,rating,customerDoneProjectCount,customerWorkProjectCount,firms`,
    {}
  );
};
