import React from "react";
import { TWork } from "../../../../../../../app/feature/ProjectProcessView/Work/types/projectProcessWorkTypes";
import { useAppSelector } from "../../../../../../../app/store";
import { getProjectProcessInfoData } from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { UpdatePublishProcess } from "../../../../../../newUI/UpdatePublishProcess/UpdatePublishProcess";

type ModalTaskPublishUpdateProps = TWork & {
  closeModal: () => void;
};
export const ModalTaskPublishUpdate = ({
  date_limit,
  date_start,
  pay2,
  pay1,
  pay3,
  price,
  projectPart,
  id,
  executorType,
  vor,
  vor_pnr,
  title,
  closeModal,
}: ModalTaskPublishUpdateProps) => {
  const dataProcess = useAppSelector(getProjectProcessInfoData);

  return (
    <UpdatePublishProcess
      id={id}
      price={price}
      pay1={pay1}
      pay2={pay2}
      pay3={pay3}
      closeModal={closeModal}
      projectPart={projectPart}
      title={`Изменение опубликованной задачи «${title}»`}
      vor={vor}
      vor_pnr={vor_pnr}
      executorType={executorType}
      date_limit={date_limit}
      date_start={date_start}
      kac={false}
      haveExpertise={dataProcess?.haveExpertise || false}
      work
    />
  );
};
