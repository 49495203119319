import { useForm } from "react-hook-form";
import { useFeedbackTaskMutation } from "src/FSD/entities/feedback/api";
import { useTaskViewQuery } from "src/FSD/entities/task/api";

type TFieldValues = {
  comment: string;
  quality: number;
  reliability: number;
  loyalty: number;
};

type UseModalProps = {
  taskId: number;
  handleClose: () => void;
  defaultValues: TFieldValues;
};

export const useFeedbackTask = ({
  taskId,
  handleClose,
  defaultValues,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues,
  });

  const { data: taskInfo, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["executorUserType"],
  });

  const [feedbackTask] = useFeedbackTaskMutation();

  const jobDuration = ((taskInfo?.executor.job_duration ?? 0) / 365).toFixed(0);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      Feedback: {
        quality: data.quality,
        reliability: data.reliability,
        loyalty: data.loyalty,
        comment: data.comment,
        type: 1,
      },
    };

    await feedbackTask({
      taskId: taskInfo!.id,
      userId: taskInfo!.executor.id,
      data: formedData,
    });

    handleClose();
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isTaskViewPending: isFetching || !taskInfo,
    taskInfo,
    jobDuration,
  } as const;
};
