import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { processWorkApi } from "src/FSD/entities/processWork/api";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";
import { Nullable } from "../../../../../../types/generics";

export const useQueries = (
  id: number,
  isProcessWork: boolean,
  userType?: Nullable<TSelectItem>
) => {
  const [
    getUsersToAssignWork,
    { data: usersToAssignWorkRaw, isLoading: isLoadingWork },
  ] = processWorkApi.useLazyProcessWorkGetUsersToAssignQuery();

  const [
    getUsersToAssignProcess,
    { data: usersToAssignProcessRaw, isLoading: isLoadingProcess },
  ] = projectProcessApi.useLazyProjectProcessGetUsersToAssignQuery();

  const fetchGetExecutorWork = async () => {
    if (!userType) {
      return;
    }

    await getUsersToAssignWork({
      id,
      firmId: +userType.id,
    });
  };

  const fetchGetExecutorProcess = async () => {
    await getUsersToAssignProcess({
      id,
    });
  };

  const fetchGetExecutor = isProcessWork
    ? fetchGetExecutorWork
    : fetchGetExecutorProcess;

  const usersToAssignRaw = isProcessWork
    ? usersToAssignWorkRaw
    : usersToAssignProcessRaw;

  const isLoading = isProcessWork ? isLoadingWork : isLoadingProcess;

  return { fetchGetExecutor, usersToAssignRaw, isLoading };
};
