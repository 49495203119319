import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { palette, textFieldSX } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessPreliminaryDone } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessPreliminaryDone";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessDocumentations } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { getProjectProcessToProcessType } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessToProcessType";
import { fetchProjectProcessToProcesses } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessToProcesses";
import { toSelect } from "src/app/feature/project/projectProcessPublication";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import NewUploader from "../../../../../../features/NewUploader/NewUploader";
import CustomMultipleSelect from "../../../../../../newUI/CustomMultipleSelect/CustomMultipleSelect";
import cls from "./ModalPreliminaryDone.module.scss";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";

const statuses = {
  NOT_REQUIRED: 7,
  REQUIRED: 6,
};

type TFieldValues = {
  comment: string;
  checkingProcesses: TSelectItem[];
  files: any;
};

const defaultValues = {
  comment: "",
  checkingProcesses: [],
  files: [],
};

interface ModalPreliminaryDoneProps {
  taskId: number;
  name: string;
}

const errorMessages = {
  FILES_REQUIRED: "Необходимо приложить хотя бы один файл",
};

const schema = Yup.object().shape({
  comment: Yup.string().required("Необходимо заполнить поле"),
});

export const ModalPreliminaryDone = memo(
  ({ taskId, name }: ModalPreliminaryDoneProps) => {
    const projectId = useAppSelector(getProjectProcessInfoProjectId);
    const processId = useAppSelector(getProjectProcessInfoId);

    const processType = useAppSelector(getProjectProcessToProcessType);
    const allCheckingProcesses = toSelect(processType);

    const dispatch = useAppDispatch();
    const {
      handleSubmit,
      setValue,
      register,
      watch,
      trigger,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<TFieldValues>({
      defaultValues,
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const checkingProcessesValue = watch("checkingProcesses");

    const formSubmitHandler = async (
      dataValues: TFieldValues,
      status: number
    ) => {
      if (status === statuses.REQUIRED && !dataValues.files.length) {
        setError("files", { message: errorMessages.FILES_REQUIRED });
        return;
      }

      clearErrors();

      const formData = new FormData();
      formData.append("CompleteForm[status]", `${status}`);
      formData.append("CompleteForm[comment]", dataValues.comment);
      dataValues.files.forEach(({ file }: any) => {
        formData.append("CompleteForm[upload_files][]", file!);
      });
      dataValues.checkingProcesses.forEach(({ id }: TSelectItem) => {
        formData.append("CompleteForm[reviewProcessIds][]", `${id}`);
      });

      await dispatch(
        fetchProjectProcessPreliminaryDone({ taskId, data: formData })
      );
      if (processId) {
        await dispatch(fetchProjectProcessDocumentations(processId));
      }
    };

    useEffect(() => {
      if (processId && projectId) {
        dispatch(
          fetchProjectProcessToProcesses({ processId, projectId, all: 1 })
        );
      }
    }, []);

    return (
      <form className={cls.form}>
        <h1>{name}</h1>
        <NewUploader
          canEdit
          id={taskId}
          initialFiles={[]}
          setFiles={(files) => {
            setValue("files", files);
            trigger("files");
          }}
          error={Boolean(errors.files)}
          helperText={errors.files?.message}
        />
        <TextField
          {...textFieldSX}
          multiline
          minRows={4}
          placeholder="Введите комментарий"
          label="Комментарий"
          {...register("comment")}
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        <CustomMultipleSelect
          changeHandler={(processes) => {
            setValue("checkingProcesses", processes);
          }}
          value={checkingProcessesValue}
          label="Проверяющие"
          options={allCheckingProcesses}
          canSelectAll
        />
        <div className={cls.controls}>
          <CustomButton
            onClick={handleSubmit((data) =>
              formSubmitHandler(data, statuses.NOT_REQUIRED)
            )}
            width={160}
            background={palette.red}
            className={cls.form_submit}
          >
            Не требуется
          </CustomButton>
          <CustomButton
            onClick={handleSubmit((data) =>
              formSubmitHandler(data, statuses.REQUIRED)
            )}
            width={160}
            background={palette.blue}
            className={cls.form_submit}
          >
            Отправить
          </CustomButton>
        </div>
      </form>
    );
  }
);
