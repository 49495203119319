import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useCheckProcess } from "../lib/hooks/useCheckProcess";
import { CheckExecuteProcessForm } from "./CheckExecuteProcessForm";
import { TransferFunds } from "./TransferFunds";

type Props = {
  taskId: number;
  processId: number;
  header: string;
};

export const CheckExecuteProcess = ({ taskId, processId, header }: Props) => {
  const { isCanCheckPending, canCheckData } = useCheckProcess({
    processId,
  });

  const contentModal =
    canCheckData === true ? (
      <CheckExecuteProcessForm taskId={taskId} />
    ) : (
      <TransferFunds canCheckData={canCheckData!} processId={processId} />
    );

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>

      {isCanCheckPending ? <Modal.Preloader /> : contentModal}
    </Modal.Layout>
  );
};
