import React from "react";
import { useNavigate } from "react-router-dom";
import { ProjectsProcessDataType } from "../../../../../../types/stateTypes/indexPages/IndexProjectProcessDataType";
import { useInnerWidth } from "../../UserList/User/subcomponents/Organisation/Organisation";
import { config } from "../../../../../../app.cofig";
import style from "../../UserList/User/style.module.scss";
import classes from "./style.module.scss";
import Avatar from "../../UserList/User/subcomponents/Avatar/Avatar";
import Region from "../../UserList/User/subcomponents/Region/Region";
import { PayBar } from "../../../PayBar/PayBar";
import Treaty from "../../../Treaty/Treaty";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { palette } from "../../../../../../styles/restyle";
import { Price } from "../../../Price/Price";

export const ProjectProcessItem: React.FC<ProjectsProcessDataType> = ({
  id,
  author,
  dateEnd,
  dateStart,
  name,
  gip,
  designType,
  expertise,
  vor,
  vor_pnr,
  pay1,
  pay2,
  pay3,
  typeName,
  price,
  secure,
  withDocs,
}) => {
  const innerWidth = useInnerWidth();
  const navigate = useNavigate();
  const trimmedName =
    name.length > 50 && innerWidth < 1050
      ? `${name.substring(0, 45)}...`
      : name;

  const expertiseTrimmed = expertise.length
    ? expertise
        .slice(0, 1)
        .map((item) => item.title)
        .join(", ")
    : "Экспертиза не требуется";

  const data = [
    {
      avatar: `${config.localDomain}${author.photo}`,
      link: `/user/${author.id}?tab=info`,
      name: `${author.name} ${author.surname}`,
      firm: author.type,
      role: "Заказчик",
    },
  ];

  data.push(
    gip
      ? {
          avatar: `${config.localDomain}${gip.photo}`,
          link: `/user/${gip.id}?tab=info`,
          name: `${gip?.name} ${gip?.surname}`,
          firm: gip?.type,
          role: "Главный инженер проекта",
        }
      : {
          avatar: ``,
          link: ``,
          name: "Не назначен",
          firm: "",
          role: "Главный инженер проекта",
        }
  );

  const handleNavigate = (typeName: string) => {
    if (typeName === "Раздел") {
      navigate(`/project-process/${id}`);
    }
    if (typeName === "Задание") {
      window.location.href = `${config.localDomain}/process-work/view?id=${id}`;
    }
  };

  const isMobile = useIsMobile(1050);

  return (
    <div className={style.container}>
      <div className={classes.container__info}>
        {isMobile ? (
          <h1 className={classes.title_link}>{trimmedName as string}</h1>
        ) : (
          <h1
            className={classes.title_link}
            onClick={() => handleNavigate(typeName)}
          >
            {trimmedName as string}
          </h1>
        )}
        <div className={style.container__info__who}>
          <p>{typeName}</p>
        </div>
      </div>
      <div className={style.contents}>
        <Avatar data={data} />
        <Region
          processProject
          vor={vor}
          vorPnr={vor_pnr}
          data={{
            logo: designType,
            expertise: expertiseTrimmed,
          }}
        />
        <div className={classes.treaty}>
          <div className={classes.price}>
            <h1>{isMobile ? "Стоимость" : "Условия оплаты"}</h1>
            <Price price={price} />
          </div>
          <PayBar
            pay1={pay1}
            pay2={pay2}
            pay3={pay3}
            titlePay1={
              isMobile ? "Предварит. решения" : "Предварительные решения"
            }
            titlePay2={"Готовая документация"}
            titlePay3={"После экспертизы"}
          />
          <Treaty
            secure={secure}
            withDocs={withDocs}
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
        </div>
      </div>
      <CustomButton
        className={style.moreButton}
        onClick={() => handleNavigate(typeName)}
        background={palette.green}
        color={"#1B252C"}
      >
        Открыть
      </CustomButton>
    </div>
  );
};
