import React, { FC, memo, useCallback } from "react";
import classNames from "classnames";
import cls from "./PayBlock.module.scss";
import { PayBar } from "../../../../UI/components/PayBar/PayBar";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoPayBlockIsLoad,
  getProjectProcessInfoPublished,
  getProjectProcessIsPause,
  getProjectProcessPriceUpdateIsLoad,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getProjectProcessIsLoadPause } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadPause";
import { getProjectProcessIsLoadDeposit } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadDeposit";
import { PayProcessInfoSkeleton } from "../../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { PriceEditPopper } from "../../../../events/ProcessEvents/PriceEditPopper/PriceEditPopper";
import { fetchProjectProcessUpdatePrice } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessUpdatePrice";
import { Price } from "../../../../UI/components/Price/Price";

type NdsInfoProps = {
  executorNds?: number;
};

export const NdsInfo: FC<NdsInfoProps> = ({ executorNds }) => (
  <span>{executorNds ? <>в том чиcле НДС</> : <>без налога (НДС)</>}</span>
);

interface IPayBlockProps {
  price: string;
  pay1: number;
  pay2: number;
  pay3: number;
  deposit: number;
  paid_out: number;
  executor_nds: number;
}
export const PayBlock: React.FC<IPayBlockProps> = memo(
  ({ price, pay1, pay2, pay3, paid_out, deposit, executor_nds }) => {
    const dispatch = useAppDispatch();
    const pauseStatus = useAppSelector(getProjectProcessIsPause);
    const isLoadPause = useAppSelector(getProjectProcessIsLoadPause);
    const isLoadDeposit = useAppSelector(getProjectProcessIsLoadDeposit);
    const published = useAppSelector(getProjectProcessInfoPublished);
    const processId = useAppSelector(getProjectProcessInfoId);
    const processData = useAppSelector(getProjectProcessInfoData);
    const isLoadInfoPayBlock = useAppSelector(
      getProjectProcessInfoPayBlockIsLoad
    );
    const isLoadPriceUpdate = useAppSelector(
      getProjectProcessPriceUpdateIsLoad
    );

    const canEditPrice = !!processData?.task && processData?.can?.update;

    const onChangePrice = useCallback((value: string) => {
      const data = {
        price: value,
      };
      if (processId) {
        dispatch(fetchProjectProcessUpdatePrice({ id: processId, data }));
      }
    }, []);

    if (isLoadDeposit || isLoadPause || isLoadInfoPayBlock) {
      return <PayProcessInfoSkeleton />;
    }

    const priceNode = (
      <Price
        price={price}
        classname={classNames(
          cls.price_textEdit,
          !canEditPrice && cls.price_textNoEdit
        )}
        isProcess
      />
    );

    return (
      <div className={classNames(cls.pay, cls.card, pauseStatus && cls.paused)}>
        <div className={cls.pay_price}>
          <h1>Стоимость и условия оплаты</h1>
          {published && (
            <PriceEditPopper
              price={price}
              priceNode={priceNode}
              canEditPrice={canEditPrice}
              isLoadPriceUpdate={isLoadPriceUpdate}
              onEditPrice={onChangePrice}
            />
          )}
        </div>
        {published && (
          <>
            <PayBar
              pay1={pay1}
              pay2={pay2}
              pay3={pay3}
              classname={cls.payBar}
              titlePay1={"Аванс"}
              titlePay2={"После приёмки документации "}
              titlePay3={"После экспертизы"}
            />
            <div className={cls.pay_footer}>
              <div className={cls.pay_deposit}>
                <p>Доступно</p>
                <span>{deposit}</span>
                <NdsInfo executorNds={executor_nds} />
              </div>
              <div className={cls.pay_deposit}>
                <p>Выплачено</p>
                <span>{paid_out}</span>
                <NdsInfo executorNds={executor_nds} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);
