import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../services/api/requestHandler";
import { pushError, pushSuccessMessage } from "../../errorTrace";
import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPut,
} from "../../../services/api/api";
import { config } from "../../../../app.cofig";

export const ApiGetComposition = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-composition/view?id=${id}`
  );
};

export const ApiDeleteBookByWorkId = (workId: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/process-work/delete?id=${workId}`
  );
};

export const ApiCreateBook = (
  id: number,
  name: string,
  num: number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/process-work/create?processId=${id}`,
    {},
    {
      name,
      num,
      book: 1,
    }
  );
};

export const ApiUpdateBook = (
  id: number,
  name: string,
  num: number
): Promise<Response> => {
  return httpRequestPut(
    `${config.localDomain}/v1/process-work/update?id=${id}`,
    {},
    {
      num,
      name,
    }
  );
};

export const ApiFormProjectContentsAgain = (id: number): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-composition/create?id=${id}&anew=1`
  );
};

export const fetchProjectComposition = createAsyncThunk(
  "project/fetchComposition",
  async (id: number, { dispatch, rejectWithValue }) => {
    let response: any = {};
    let error = null;
    await request(
      ApiGetComposition(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const deleteBookByWorkId = createAsyncThunk(
  "project/deleteBookByWorkId",
  async (workId: number, { dispatch }) => {
    await request(
      ApiDeleteBookByWorkId(workId),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const createBook = createAsyncThunk(
  "project/createBook",
  async (
    { id, name, num }: { id: number; name: string; num: number },
    { dispatch }
  ) => {
    await request(
      ApiCreateBook(id, name, num),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const updateBook = createAsyncThunk(
  "project/updateBook",
  async (
    { id, name, num }: { id: number; name: string; num: number },
    { dispatch }
  ) => {
    await request(
      ApiUpdateBook(id, name, num),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const formAgain = createAsyncThunk(
  "project/formAgain",
  async (id: number, { dispatch }) => {
    await request(
      ApiFormProjectContentsAgain(id),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);
