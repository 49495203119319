import React, { useState } from "react";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { getProjectAccessByProject } from "../../../../../../app/feature/ProjectView/AdminReducer/thunks";
import AddRights from "../modals/AddRights";
import { useAppDispatch } from "../../../../../../app/store";
import styles from "./Header.module.scss";
import { NewModal } from "../../../../../newUI/NewModal/NewModal";

interface HeaderProps {
  canAddRoles: boolean;
  projectId: number;
}
export const Header = ({ canAddRoles, projectId }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const [showAddRights, setShowAddRights] = useState(false);
  const handleCloseAddRightsModal = () => {
    setShowAddRights(false);
    dispatch(getProjectAccessByProject(projectId));
  };

  return (
    <div className={styles.header}>
      <p>Администрирование</p>
      {canAddRoles && (
        <Button onClick={() => setShowAddRights(true)}>
          Добавить права доступа
        </Button>
      )}
      <NewModal
        active={showAddRights}
        setActive={setShowAddRights}
        title={"Добавление прав доступа к проекту"}
      >
        <AddRights
          projectId={projectId}
          handleCloseModal={handleCloseAddRightsModal}
        />
      </NewModal>
    </div>
  );
};
