import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../services/api/requestHandler";
import { pushError, pushMessage } from "../errorTrace";
import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPut,
} from "../../services/api/api";
import { config } from "../../../app.cofig";
import { TStage } from ".";
import { TSelectItem } from "../../../components/features/Forms/SetGipForm/types";
import { TPart } from "../../../components/pages/Organisation/tabs/Milestone/ProcessesList";
import { TProcess } from "../../../components/pages/User/Dashboard/Secondary/types";
import { prepareAnchorData } from "./utils";

type TNewProcess = {
  project_part_id: number;
  active: boolean;
};

type TDragNDrop = Pick<TStage, "num" | "id">[];

const ApiGetFirmMilestones = (firmId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/firm-milestone/index?FirmMilestoneSearch[firm_id]=${firmId}&expand=stages,projectPart,stages.stageAnchor`
  );

const ApiGetAvailableProcesses = (
  firmId: number,
  bfType: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/firm-milestone/get-parts-list?firmId=${firmId}&bfType=${bfType}`
  );

const ApiGetBFList = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/firm-milestone/get-bf-list`
  );

const ApiRemoveProcess = (id: number): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/milestone/firm-milestone/delete?id=${id}`
  );

const ApiChangeActive = (id: number, active: boolean): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/firm-milestone/change-active?id=${id}`,
    {},
    { active }
  );

const ApiChangeActiveMilestone = (
  id: number,
  active: boolean
): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/stage/change-active?id=${id}`,
    {},
    { active }
  );

const ApiAddProcess = (
  firmId: number,
  bfType: number,
  payload: TNewProcess
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/milestone/firm-milestone/create?firmId=${firmId}&bfType=${bfType}`,
    {},
    payload
  );

const ApiDeleteMilestone = (id: number): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/milestone/stage/delete?id=${id}`,
    {}
  );

const ApiAddMilestone = (id: number, name: string): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/milestone/stage/create?firmMilestoneId=${id}`,
    {},
    { name }
  );

const ApiDragNDrop = (id: number, stages: TDragNDrop): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/stage/drag-n-drop?firmMilestoneId=${id}`,
    {},
    stages
  );

const ApiGetGroupControl = (
  firmId: number,
  bfType: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/firm-milestone/group-control?firmId=${firmId}&bfType=${bfType}`
  );

const ApiGetGroupById = (groupId: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/group-project-part/view?id=${groupId}&expand=activeStages,projectParts,stageGroupPercents,stageGroupPercents.stage`
  );

const ApiUpdateStagesPercents = (
  data: Record<string, number>
): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/stage-group-percent/update`,
    {},
    data
  );

const ApiCreateGroup = (
  firmId: number,
  bfType: number,
  groupId: number,
  partType: number
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/milestone/group-project-part/create?firmId=${firmId}&bfType=${bfType}&groupId=${groupId}&partType=${partType}`
  );

const ApiDeleteGroup = (id: number): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/milestone/group-project-part/delete?id=${id}`
  );

const ApiDeleteLink = (id: number): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/milestone/group-project-part/delete-link?id=${id}`
  );

const ApiEditLinks = (
  id: number,
  body: Record<string, (string | number)[]>
): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/group-project-part/update?id=${id}`,
    {},
    body
  );

const ApiGetClassList = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/stage-anchor/get-class-list`
  );

const ApiGetMethodList = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/stage-anchor/get-method-list`
  );

const ApiDeleteStageAnchor = (id: number): Promise<Response> =>
  httpRequestDelete(
    `${config.localDomain}/v1/milestone/stage-anchor/delete?id=${id}`
  );

const ApiGetParams = (className: string): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/milestone/stage-anchor/get-params?class=${className}`
  );

const ApiCreateStageAnchor = (
  stageId: number,
  body: Record<string, any>
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/milestone/stage-anchor/create?stageId=${stageId}`,
    {},
    body
  );

const ApiEditStageAnchor = (
  id: number,
  body: Record<string, any>
): Promise<Response> =>
  httpRequestPut(
    `${config.localDomain}/v1/milestone/stage-anchor/update?id=${id}`,
    {},
    body
  );

export const saveAnchor = createAsyncThunk(
  "milestone/saveAnchor",
  async ({ id, data, isNew, anchorId }: any, { dispatch }) => {
    const preparedData = prepareAnchorData(id, data);

    const endPoint = isNew
      ? ApiCreateStageAnchor(id, preparedData)
      : ApiEditStageAnchor(anchorId, preparedData);

    await request(
      endPoint,
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const deleteAnchor = createAsyncThunk(
  "milestone/deleteAnchor",
  async (id: number, { dispatch }) => {
    await request(
      ApiDeleteStageAnchor(id),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const getClassList = createAsyncThunk(
  "milestone/getClassList",
  async (_, { dispatch }) => {
    let methodList: any = [];
    await request(
      ApiGetClassList(),
      (response) => {
        methodList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return methodList;
  }
);

export const getMethodList = createAsyncThunk(
  "milestone/getMethodList",
  async (_, { dispatch }) => {
    let methodList: any = [];
    await request(
      ApiGetMethodList(),
      (response) => {
        methodList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return methodList;
  }
);

export const getBfList = createAsyncThunk(
  "milestone/getBfList",
  async (_, { dispatch }) => {
    let bfList: any = [];
    await request(
      ApiGetBFList(),
      (response) => {
        bfList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return bfList;
  }
);

export const getAvailableProcesses = createAsyncThunk(
  "milestone/availableProcesses",
  async (
    { firmId, bfType }: { firmId: number; bfType: number },
    { dispatch }
  ) => {
    let processesList: any = [];
    await request(
      ApiGetAvailableProcesses(firmId, bfType),
      (response) => {
        processesList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return processesList;
  }
);

export const getFirmMilestones = createAsyncThunk(
  "milestone/getFirmMilestones",
  async (firmId: number, { dispatch }) => {
    let bfList: any = [];
    await request(
      ApiGetFirmMilestones(firmId),
      (response) => {
        bfList = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return bfList;
  }
);

export const removeProcess = createAsyncThunk(
  "milestone/removeProcess",
  async (
    { process: { id } }: { bfType: number; process: TProcess },
    { dispatch }
  ) => {
    await request(
      ApiRemoveProcess(id),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const addProcess = createAsyncThunk(
  "milestone/addProcess",
  async (
    {
      firmId,
      bfType,
      payload,
    }: { firmId: number; bfType: number; name: string; payload: TNewProcess },
    { dispatch }
  ) => {
    let id: any;

    await request(
      ApiAddProcess(firmId, bfType, payload),
      (newId) => {
        dispatch(pushMessage("Успешно"));
        id = newId;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return id;
  }
);

export const changeActive = createAsyncThunk(
  "milestone/changeActive",
  async (
    { id, active }: { id: number; active: boolean; bfType: number },
    { dispatch }
  ) => {
    await request(
      ApiChangeActive(id, active),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const changeActiveMilestone = createAsyncThunk(
  "milestone/changeActiveMilestone",
  async (
    {
      id,
      active,
    }: {
      bfType: number;
      id: number;
      active: boolean;
      processId: number;
    },
    { dispatch }
  ) => {
    await request(
      ApiChangeActiveMilestone(id, active),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const deleteMilestone = createAsyncThunk(
  "milestone/deleteMilestone",
  async (
    {
      id,
    }: {
      bfType: number;
      id: number;
      processId: number;
    },
    { dispatch }
  ) => {
    await request(
      ApiDeleteMilestone(id),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const addMilestone = createAsyncThunk(
  "milestone/addMilestone",
  async (
    {
      processId,
      name,
    }: {
      bfType: number;
      processId: number;
      name: string;
    },
    { dispatch }
  ) => {
    let id: any;
    await request(
      ApiAddMilestone(processId, name),
      (newId) => {
        dispatch(pushMessage("Успешно"));
        id = newId;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return id;
  }
);

export const dragNDrop = createAsyncThunk(
  "milestone/dragNDrop",
  async (
    {
      processId,
      items,
    }: {
      bfType: number;
      processId: number;
      items: TDragNDrop;
    },
    { dispatch }
  ) => {
    await request(
      ApiDragNDrop(processId, items),
      () => {},
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const getGroupControl = createAsyncThunk(
  "milestone/getGroupControl",
  async (
    {
      firmId,
      bfType,
    }: {
      bfType: number;
      firmId: number;
    },
    { dispatch }
  ) => {
    let data: any;

    await request(
      ApiGetGroupControl(firmId, bfType),
      (response) => {
        data = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return data;
  }
);

export const saveStagesPercents = createAsyncThunk(
  "milestone/saveStagesPercents",
  async (data: Record<string, number>, { dispatch }) => {
    await request(
      ApiUpdateStagesPercents(data),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return data;
  }
);

export const createGroup = createAsyncThunk(
  "milestone/createGroup",
  async (
    { firmId, bfType, groupId, partType }: Record<string, number>,
    { dispatch }
  ) => {
    let id: any;
    let data: any;

    await request(
      ApiCreateGroup(firmId, bfType, groupId, partType),
      (response) => {
        id = response.id;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    await request(
      ApiGetGroupById(id),
      (response) => {
        data = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return { id, data };
  }
);

export const deleteGroup = createAsyncThunk(
  "milestone/deleteGroup",
  async (
    { id }: { id: number; groupId: number; partType: number },
    { dispatch }
  ) => {
    await request(
      ApiDeleteGroup(id),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const deleteLink = createAsyncThunk(
  "milestone/deleteLink",
  async (
    {
      link: { linkId },
    }: {
      id: number;
      partType: number;
      groupId: number;
      link: TPart & { linkId: number };
    },
    { dispatch }
  ) => {
    await request(
      ApiDeleteLink(linkId),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();
  }
);

export const editLinks = createAsyncThunk(
  "milestone/editLinks",
  async (
    {
      id,
      body,
    }: {
      id: number;
      partType: number;
      groupId: number;
      body: Record<string, (string | number)[]>;
      data: TSelectItem[];
      options: TSelectItem[];
    },
    { dispatch }
  ) => {
    let data: any;

    await request(
      ApiEditLinks(id, body),
      (response) => {
        data = response;
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return data;
  }
);

export const getAnchorParams = createAsyncThunk(
  "milestone/getAnchorParams",
  async (className: string, { dispatch }) => {
    let paramsList = {};

    await request(
      ApiGetParams(className),
      (data) => {
        if (Array.isArray(paramsList)) {
          return;
        }

        paramsList = { ...data };
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return paramsList;
  }
);
