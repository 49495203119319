import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import { Info } from "src/FSD/widgets/v2/processWork/Info";
import { Removed } from "src/FSD/widgets/v2/processWork/Removed";
import { SourceFiles } from "src/FSD/widgets/v2/processWork/SourceFiles";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Applications } from "src/FSD/widgets/v2/processWork/Applications";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { Documents } from "src/FSD/widgets/v2/processWork/Documents";
import { ExecutionTasks } from "src/FSD/widgets/v2/processWork/ExecutionTask";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import PublishObjectModals from "src/FSD/widgets/v2/publishObject/modals";
import { useProcessWorkViewPage } from "../lib/hooks/useProcessWorkViewPage";
import { SkeletonPage } from "./SkeletonPage";
import { useModals } from "../lib/hooks/useModals";
import { MOBILE_SIZE, TABLET_SIZE } from "../lib/constants/screenSizes";

export const ProcessWorkViewPage = () => {
  const isMobile = useIsMobile(MOBILE_SIZE);
  const isTablet = useIsMobile(TABLET_SIZE);

  const { id } = useParams();

  const {
    processWork,
    device,
    breadCrumbs,
    isProcessWorkLoading,
    showThreeDot,
    header,
  } = useProcessWorkViewPage({
    processWorkId: Number(id),
  });

  const {
    workPublication,
    handleCloseWorkPublication,
    threeDotButtonRef,
    handleOpenWorkPublication,
  } = useModals();

  const componentsList = useMemo(() => {
    if (!processWork) {
      return {};
    }

    return {
      info: <Info processWork={processWork} />,
      sourceFiles: <SourceFiles processWork={processWork} />,
      applications: (
        <Applications
          processWorkId={processWork.id}
          secure={processWork.secure}
        />
      ),
      removed: <Removed processWorkId={processWork.id} />,
      executionTask: <ExecutionTasks processWorkId={processWork.id} />,
      documents: <Documents activeContract={processWork.activeContract} />,
    };
  }, [processWork]);

  return (
    <PageWrapper>
      {isProcessWorkLoading ? (
        <SkeletonPage isMobile={isMobile} isTablet={isTablet} />
      ) : (
        <>
          <Modal
            isActive={workPublication}
            handleClose={handleCloseWorkPublication}
          >
            <PublishObjectModals.PublishContentForm
              id={processWork.id}
              header={header}
              isProcessWork
            />
          </Modal>
          <Breadcrumbs items={breadCrumbs} />
          <TabsPanel
            title={processWork.title}
            subTitle={processWork.status.value}
            tabsList={processWork.tabs}
            componentsList={componentsList}
            device={device}
            actions={
              showThreeDot && (
                <ThreeDotButton ref={threeDotButtonRef}>
                  {processWork.can.publish && (
                    <MenuItem onClick={handleOpenWorkPublication}>
                      Опубликовать задачу
                    </MenuItem>
                  )}
                </ThreeDotButton>
              )
            }
          />
        </>
      )}
    </PageWrapper>
  );
};
