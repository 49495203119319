import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import { selectorUserPageContentData } from "../../../../../app/feature/userPage/userPage";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";

export const CHECKED = "Проверенная";
export const UNCHECKED = "Не проверенная";

export const Organisation: React.FC = () => {
  const firmsData = useSelector(selectorUserPageContentData)!.firms;
  // @ts-ignore
  const data: any = Object.keys(firmsData).map((key: string) => firmsData[key]);
  return (
    <div className={style.wrapper}>
      {data?.length > 0 ? (
        data.map((item: any) => (
          <div className={style.organisation}>
            <div className={style.header}>
              <TextLink variant={style.textLink} url={`/firm/${item.id}`}>
                {item.title}
              </TextLink>
              <p>{item.tested ? CHECKED : UNCHECKED}</p>
            </div>
            <div className={style.flex}>
              <div className={style.section}>
                <div className={style.title}>Руководитель</div>
                <div className={classNames(style.text, style.small)}>
                  {item.manager_post}
                </div>
                <div className={classNames(style.text, style.small)}>
                  {item.manager_name}
                </div>
              </div>

              <div className={style.section}>
                <div className={style.title}>Коды</div>
                <div className={style.text}>
                  {item.inn && `ИНН   ${item.inn}`}
                </div>
                <div className={style.text}>
                  {item.ogrn && `ОГРН   ${item.ogrn}`}
                </div>
                <div className={style.text}>
                  {item.kpp && `КПП   ${item.kpp}`}
                </div>
              </div>

              <div className={style.section}>
                <div className={style.title}>Адреса</div>
                <div className={classNames(style.text, style.small)}>
                  {item.address && `Юридический: ${item.address}`}
                </div>
                <div className={classNames(style.text, style.small)}>
                  {item.address_fact && `Фактический: ${item.address_fact}`}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};
