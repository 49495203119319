import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiFeedbackRequest } from "../../model/requestsTypes";

type TParams = RequestParams<{
  taskId: number;
  userId: number;
  data: ApiFeedbackRequest.Task;
}>;

export const task = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ taskId, userId, data }) => ({
      url: `v1/feedback/task`,
      params: {
        taskId,
        userId,
      },
      data,
      method: HTTPMethods.POST,
    }),
    invalidatesTags: ["Feedback.Task"],
  });
