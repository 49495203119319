import { useEffect } from "react";
import { config } from "src/FSD/shared/lib/configs/app.config";

type Props = {
  activeContract: Record<string, string> | { id: number };
};

export const Documents = ({ activeContract }: Props) => {
  useEffect(() => {
    if (!activeContract) {
      return;
    }

    window.open(
      `${config.localDomain}/document/${activeContract.id}`,
      "_blank"
    );
  }, [activeContract]);

  return null;
};
