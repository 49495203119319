import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Skeleton } from "@mui/material";
import cn from "classnames";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import style from "./style.module.scss";
import DatesForm from "./DatesForm";
import Requirements, { agreementOptions } from "./Requirements";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  fetchGetUsersToAssign,
  getExecutorFullTypes,
  getGroupPublishProcess,
  groupPublication,
} from "../../../../../../app/feature/project/projectProcessPublication/thunks";
import {
  clearGroupPublishProcess,
  getGroupPublishProcessData,
  setTouched,
} from "../../../../../../app/feature/project/projectProcessPublication";

export enum ERROR_MESSAGES {
  PRICE_1000 = "Стоимость не может быть меньше 1000 рублей",
  PRICE_IS_INT = "Стоимость должна быть целым числом",
  DATE_RANGE = "Начальный срок должен быть раньше конечного",
  DATE_START_REQUIRED = "Укажите начальный срок",
  DATE_LIMIT_REQUIRED = "Укажите конечный срок",
  MIN_DATE_RANGE_8_DAYS = "Продолжительность работ должна быть от 8 дней с учетом проверки результата у ГИП и смежных исполнителей!",
  DATE_IS_NEXT = "Дата должна быть будущей",
}

export type TFormValues = Record<string, any>;

const ProcessPublication = () => {
  const [currentPage, setPage] = useState<number>(0);
  const [isAddExecutorBtn, setAddExecutor] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    data: { processId, processesData, executors, types, fullTypes, payments },
    pending,
  } = useAppSelector(getGroupPublishProcessData);

  const { id: projectId } = useParams<{ id: string }>();
  const id = Number(projectId);

  const showLastPage =
    Object.keys(processesData).length &&
    currentPage === Object.keys(processesData).length;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      // основные страницы заполняются динамически
      // последняя страница
      isSafe: agreementOptions[0],
      isDocs: agreementOptions[0],
      isVolumes: false,
      isThief: false,
      isAnalyze: false,
      selectedTypes: [],
      executor: null,
      type: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        selectedTypes: Yup.array().test(
          "notEmpty",
          "Необходимо заполнить «Форма собственности»",
          (value: any) => value && value.length
        ),
        ...Object.entries(processesData).reduce(
          (finalSchema: any, [typeId, { list, maybeExpertise }]: any) => {
            const prePaidKey = `${typeId}.prePaid`;
            const afterAcceptKey = `${typeId}.afterAccept`;
            const afterExpertiseKey = `${typeId}.afterExpertise`;

            return {
              ...finalSchema,
              [prePaidKey]: Yup.number()
                .nullable()
                .test("req", "Поле обязательно", (): boolean => {
                  const prePaid = watch(prePaidKey);
                  return Boolean(prePaid);
                })
                .test(
                  "isNumber",
                  "Значение должно быть числом",
                  (): boolean => {
                    const prePaid = Number(watch(prePaidKey));
                    return !Number.isNaN(prePaid);
                  }
                )
                .test("min20", "Минимальное значение - 20%", (): boolean => {
                  const prePaid = Number(watch(prePaidKey));
                  return prePaid >= 20;
                })
                .test("max100", "Максимальное значение - 100%", (): boolean => {
                  const prePaid = Number(watch(prePaidKey));
                  return prePaid <= 100;
                })
                .test("sum100", "Сумма должна быть равна 100%", (): boolean => {
                  const prePaid = Number(watch(prePaidKey));
                  const afterAccept = Number(watch(afterAcceptKey));
                  const afterExpertise = Number(watch(afterExpertiseKey));

                  if (!maybeExpertise) {
                    return prePaid + afterAccept === 100;
                  }

                  return prePaid + afterAccept + afterExpertise === 100;
                }),
              [afterAcceptKey]: Yup.number()
                .test("req", "Поле обязательно", (): boolean => {
                  const afterAccept = watch(afterAcceptKey);
                  return Boolean(afterAccept);
                })
                .test(
                  "isNumber",
                  "Значение должно быть числом",
                  (): boolean => {
                    const afterAccept = Number(watch(afterAcceptKey));
                    return !Number.isNaN(afterAccept);
                  }
                )
                .test("max100", "Максимальное значение - 100%", (): boolean => {
                  const afterAccept = Number(watch(afterAcceptKey));
                  return afterAccept <= 100;
                }),
              [afterExpertiseKey]: Yup.number()
                .test("req", "Поле обязательно", (): boolean => {
                  const afterExpertise = watch(afterExpertiseKey);
                  return !maybeExpertise || Boolean(afterExpertise);
                })
                .test(
                  "isNumber",
                  "Значение должно быть числом",
                  (): boolean => {
                    const afterExpertise = Number(watch(afterExpertiseKey));
                    return !maybeExpertise || !Number.isNaN(afterExpertise);
                  }
                )
                .test("max100", "Максимальное значение - 100%", (): boolean => {
                  const afterExpertise = Number(watch(afterExpertiseKey));
                  return !maybeExpertise || afterExpertise <= 100;
                }),
              ...Object.keys(list).reduce((result: any, id: string) => {
                const dateStartKey = `processes.${typeId}.list.${id}.dateStart`;
                const dateLimitKey = `processes.${typeId}.list.${id}.dateLimit`;
                const priceKey = `processes.${typeId}.list.${id}.price`;

                return {
                  ...result,
                  [dateStartKey]: Yup.string()
                    .nullable()
                    .test(
                      `datesRange.${id}`,
                      ERROR_MESSAGES.DATE_RANGE,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !dateLimit || !isActive) {
                          return true;
                        }

                        return moment(dateStart) <= moment(dateLimit);
                      }
                    )
                    .test(
                      `dateStartPick.${id}`,
                      ERROR_MESSAGES.DATE_IS_NEXT,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !isActive) {
                          return true;
                        }

                        return moment(dateStart) > moment();
                      }
                    )
                    .test(
                      `dateRequired.${id}`,
                      ERROR_MESSAGES.DATE_START_REQUIRED,
                      (): boolean => {
                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );

                        return isActive ? Boolean(dateStart) : true;
                      }
                    ),
                  [dateLimitKey]: Yup.string()
                    .nullable()
                    .test(
                      `dateRequired.${id}`,
                      ERROR_MESSAGES.DATE_LIMIT_REQUIRED,
                      (): boolean => {
                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        return isActive ? Boolean(dateLimit) : true;
                      }
                    )
                    .test(
                      `dateLimitPick.${id}`,
                      ERROR_MESSAGES.DATE_IS_NEXT,
                      (): boolean => {
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateLimit || !isActive) {
                          return true;
                        }

                        return moment(dateLimit) > moment();
                      }
                    )
                    .test(
                      `dateRangeMin.${id}`,
                      ERROR_MESSAGES.MIN_DATE_RANGE_8_DAYS,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !dateLimit || !isActive) {
                          return true;
                        }

                        const dateStartParsed = moment(dateStart);
                        const dateLimitParsed = moment(dateLimit);

                        const daysDifference = dateLimitParsed.diff(
                          dateStartParsed,
                          "days"
                        );

                        return daysDifference >= 8;
                      }
                    ),
                  [priceKey]: Yup.number()
                    .test(
                      `price.${id}`,
                      ERROR_MESSAGES.PRICE_1000,
                      (): boolean => {
                        const price: string | undefined = watch(
                          `processes.${typeId}.list.${id}.price`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!isActive) {
                          return true;
                        }

                        return price ? Number(price) >= 1000 : false;
                      }
                    )
                    .test(
                      `price.${id}.isInt`,
                      ERROR_MESSAGES.PRICE_IS_INT,
                      (): boolean => {
                        const price: string | undefined = watch(
                          `processes.${typeId}.list.${id}.price`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!isActive) {
                          return true;
                        }

                        return Number.isInteger(Number(price));
                      }
                    ),
                };
              }, {}),
            };
          },
          {}
        ),
      })
    ),
    mode: "all",
  });

  const onSubmit = (data: TFormValues, save: boolean) => {
    setIsSave(save);

    dispatch(
      groupPublication({ processId, body: { ...data, save }, isAddExecutorBtn })
    );
  };

  const handleSetTouched = () => {
    dispatch(setTouched(true));
  };

  useEffect(() => {
    dispatch(getExecutorFullTypes());
    dispatch(getGroupPublishProcess(processId));

    return () => {
      dispatch(clearGroupPublishProcess());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGetUsersToAssign(processId));
  }, [processId, dispatch]);

  useEffect(() => {
    Object.keys(processesData).forEach((typeId: string) => {
      if (processesData[typeId].list[processId]) {
        setValue(`processes.${typeId}.list.${processId}.active`, true);
      }

      const watchTotalPrice = watch(`${typeId}.totalPrice`);
      if (watchTotalPrice === undefined) {
        setValue(`${typeId}.totalPrice`, 0);
      }

      const payment2 = processesData[typeId].maybeExpertise
        ? payments.pay2
        : payments.pay2 + payments.pay3;

      const watchPrePaid = watch(`${typeId}.prePaid`);
      if (watchPrePaid === "") {
        setValue(`${typeId}.prePaid`, payments.pay1);
      }

      const watchAfterAccept = watch(`${typeId}.afterAccept`);
      if (watchAfterAccept === "") {
        setValue(`${typeId}.afterAccept`, payment2);
      }

      const watchAfterExpertise = watch(`${typeId}.afterExpertise`);
      if (watchAfterExpertise === "") {
        setValue(`${typeId}.afterExpertise`, payments.pay3);
      }

      const watchDateStart = watch(`${typeId}.dateStart`);
      if (watchDateStart === undefined) {
        setValue(`${typeId}.dateStart`, null);
      }

      const watchDateLimit = watch(`${typeId}.dateLimit`);
      if (!watchDateLimit === undefined) {
        setValue(`${typeId}.dateLimit`, null);
      }

      const watchIsDiverse = watch(`${typeId}.isDiverse`);
      if (watchIsDiverse === undefined) {
        setValue(`${typeId}.isDiverse`, false);
      }

      const watchIsUnique = watch(`${typeId}.isUnique`);
      if (watchIsUnique === undefined) {
        setValue(`${typeId}.isUnique`, false);
      }
    });
  }, [processesData, currentPage]);

  if (pending.getProcesses) {
    return (
      <div className={cn(style.wrapper, style.loading)}>
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
      </div>
    );
  }

  return (
    <form onClick={handleSetTouched} className={style.wrapper}>
      {Object.entries(processesData).map(
        ([typeId, value]: any, index: number) => {
          if (index !== currentPage) {
            return;
          }

          return (
            <>
              <DatesForm
                list={value.list}
                title={value.title}
                maybeExpertise={value.maybeExpertise}
                typeId={typeId}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                pending={pending}
                processId={processId}
              />
              <div className={style.buttons}>
                {Boolean(currentPage && !showLastPage) && (
                  <CustomButton
                    onClick={() => {
                      setPage(currentPage - 1);
                      dispatch(setTouched(false));
                    }}
                    background={palette.red}
                    width={120}
                    disabled={pending.publishing}
                  >
                    Назад
                  </CustomButton>
                )}
                <CustomButton
                  onClick={async () => {
                    let containesError = false;
                    const values = Object.keys(processesData[typeId].list);

                    const validateProcess = async (id: number) => {
                      const isValidDateStart = await trigger(
                        `processes.${typeId}.list.${id}.dateStart`
                      );

                      const isValidDateLimit = await trigger(
                        `processes.${typeId}.list.${id}.dateLimit`
                      );

                      const isValidPrice = await trigger(
                        `processes.${typeId}.list.${id}.price`
                      );

                      containesError =
                        containesError ||
                        !isValidDateStart ||
                        !isValidDateLimit ||
                        !isValidPrice;
                    };

                    const promises = values.map((id: any) =>
                      validateProcess(id)
                    );
                    await Promise.all(promises);

                    if (containesError) {
                      return;
                    }

                    setPage(currentPage + 1);
                    dispatch(setTouched(false));
                  }}
                  disabled={Boolean(Object.keys(errors).length)}
                  background={palette.blue}
                  forceDisabled
                  width={150}
                >
                  Далее
                </CustomButton>
              </div>
            </>
          );
        }
      )}
      {showLastPage && (
        <>
          <Requirements
            watch={watch}
            id={id}
            processId={processId}
            setValue={setValue}
            fullTypes={fullTypes}
            executors={executors}
            pending={pending}
            types={types}
            control={control}
            errors={errors}
            trigger={trigger}
            isAddExecutorBtn={isAddExecutorBtn}
            setAddExecutor={setAddExecutor}
          />
          <div className={style.buttons}>
            <CustomButton
              onClick={() => setPage(currentPage - 1)}
              background={palette.red}
              width={120}
              disabled={pending.publishing}
              forceDisabled
            >
              Назад
            </CustomButton>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data, true))}
              background={palette.grey}
              width={220}
              disabled={pending.publishing}
              forceDisabled={Boolean(Object.keys(errors).length) || !isSave}
            >
              Сохранить в черновике
            </CustomButton>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data, false))}
              background={palette.green}
              width={140}
              disabled={pending.publishing}
              forceDisabled={Boolean(Object.keys(errors).length) || isSave}
            >
              Опубликовать
            </CustomButton>
          </div>
        </>
      )}
    </form>
  );
};

export default ProcessPublication;
