import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { AllTaskValueType } from "../../../../../app/feature/ProjectView/TaskReducer/types";
import styles from "./TreeTask.module.scss";
import { NewTaskCard } from "../../../../newUI/NewTaskCard/NewTaskCard";
import { useAppDispatch } from "../../../../../app/store";
import {
  getProjectTasks,
  setToggleProcess,
  setToggleProject,
} from "../../../../../app/feature/ProjectView/TaskReducer/projectTask";

type TreeNodeProps = {
  data: AllTaskValueType;
  processId: string;
  index: number;
};

export const TreeTask = ({ data, processId, index }: TreeNodeProps) => {
  const dispatch = useAppDispatch();
  const { projects, process } = data;
  const { processToggle, projectToggle } = useSelector(getProjectTasks);
  const params = useParams<{ id: string }>();

  const projectsValue = Object.values(projects);
  const processesValue = Object.values(process);

  function toggleProcess(id: number) {
    dispatch(setToggleProcess(id));
  }

  function toggleProject(id: number) {
    dispatch(setToggleProject(id));
  }

  return (
    <>
      {data?.title && (
        <Accordion
          text={data?.title}
          toogle={processToggle[processId]}
          warningFolder={data.dot}
          numNode={"1"}
          noMargin={index === 0}
          onToggle={() => toggleProcess(+processId)}
        />
      )}
      {processToggle[processId] &&
        processesValue.map((el) => (
          <NewTaskCard
            projectId={params.id ? +params.id : undefined}
            processId={+processId}
            type={"project"}
            key={el.title}
            {...el}
          />
        ))}
      {processToggle[processId] &&
        projectsValue.map((el, index) => (
          <>
            <Accordion
              text={el?.title}
              toogle={projectToggle[+processId + index]}
              warningFolder={el.dot}
              numNode={"1.1.1"}
              onToggle={() => toggleProject(+processId + index)}
              classname={styles.accordionProject}
              colorNodeIcon={el.dot ? "#EEA527" : "#D5D6DE"}
            />
            {projectToggle[+processId + index] &&
              Object.values(el.process).map((el) => (
                <NewTaskCard key={el.title} {...el} />
              ))}
          </>
        ))}
    </>
  );
};
