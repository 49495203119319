import { useForm } from "react-hook-form";
import { useProjectAccessRemoveRequestExecutionMutation } from "src/FSD/entities/projectAccess/api";

type UseModalProps = {
  taskId: number;
  handleClose: () => void;
};

export const useRemoveRequestExecution = ({
  taskId,
  handleClose,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const [removeRequestExecution] =
    useProjectAccessRemoveRequestExecutionMutation();

  const onSubmit = async () => {
    await removeRequestExecution({ id: taskId });

    handleClose();
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
  } as const;
};
