import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";
import { QueryBuilder, LocalOffer, ArrowBack } from "@mui/icons-material";
import { debounce } from "lodash";
import style from "./style.module.scss";
import { Wrapper } from "../../../UI/templates";

import { useTitle } from "../../../hooks/useTitle";
import { request } from "../../../../app/services/api/requestHandler";
import {
  ApiDeletePost,
  ApiGetPostForView,
} from "../../../../app/services/api/posts/posts";
import {
  selectorPostView,
  setPostForView,
} from "../../../../app/feature/postView/postView";
import { userIsAdmin } from "../../../../app/services/user/userRoles";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { Skeleton } from "./Skeleton/Skeleton";

export const View = () => {
  useTitle("Пост");
  const dispatch = useDispatch();
  const theme = useSelector(selectorAppTheme);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const isAdmin = userIsAdmin();
  const navigate = useNavigate();

  const [isLoad, setLoad] = useState(true);
  const [idPost, setIdPost] = useState("");
  const [backLinkClass, setBackLinkClass] = useState(false);

  const data = {
    post: useSelector(selectorPostView),
  };

  useEffect(() => {
    // @ts-ignore
    setIdPost(params.id);
  }, []);

  useEffect(() => {
    setLoad(true);
    if (idPost) {
      request(ApiGetPostForView(idPost), (res) => {
        dispatch(setPostForView(res));
        setLoad(false);
      })(dispatch);
    }
  }, [idPost, dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 1101 && window.pageYOffset > 90) {
        setBackLinkClass(true);
      } else {
        setBackLinkClass(false);
      }
    };

    window.addEventListener("scroll", debounce(handleScroll, 300));

    return () => window.removeEventListener("scroll", handleScroll);
  }, [backLinkClass]);

  const deleteHandler = async (id: string | number) => {
    const isDelete = window.confirm("Вы уверены, что хотите удалить?");

    if (isDelete) {
      const result = await ApiDeletePost(id);

      if (result.status === 204) {
        navigate("/post");
      }
    }
  };

  return (
    <div className={style.postViewContainer}>
      {isLoad ? (
        <Skeleton />
      ) : (
        <Wrapper>
          <div className={style.container}>
            <div className={style.contentWrapper}>
              {data.post.photoUrl ? (
                <div className={style.imagePostContainer}>
                  <img
                    className={style.imagePost}
                    src={data.post.photoUrl}
                    alt=""
                  />
                </div>
              ) : null}
              <div className={style.controls}>
                {data.post.date_publication ? (
                  <div className={style.dateWrapper}>
                    <QueryBuilder fontSize={"small"} />
                    <div className={style.datePublication}>
                      {data.post.date_publication}
                    </div>
                  </div>
                ) : (
                  <div className={style.notPublished}>Не опубликовано</div>
                )}
                {data.post.tags.length > 0 ? (
                  <div className={style.tagWrapper}>
                    <LocalOffer fontSize="small" />
                    <div className={style.labelNews}>
                      <TextLink
                        variant={style.tagLink}
                        theme={theme}
                        url={`/post/?PostSearch[tag_id]=${
                          data.post.tags[0].id || ""
                        }`}
                      >
                        <>{data.post.tags[0].title}</>
                      </TextLink>
                    </div>
                  </div>
                ) : null}
                <button
                  className={classNames(
                    style.linkBack,
                    backLinkClass ? style.linkBackMobile : ""
                  )}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </button>
              </div>
              {isAdmin ? (
                <>
                  <div className={style.adminControls}>
                    <TextLink
                      variant={style.linkToEdit}
                      theme={theme}
                      url={`/post/update?id=${data.post.id || ""}`}
                    >
                      Редактировать
                    </TextLink>
                    <button
                      onClick={() => {
                        deleteHandler(data.post.id);
                      }}
                      className={style.deleteButton}
                    >
                      Удалить
                    </button>
                  </div>
                </>
              ) : null}
              <h1 className={style.postTitle}>{data.post.title}</h1>
              <div
                className={classNames(style.contentContainer, style.ckEditor)}
              >
                {ReactHtmlParser(`<div>${data.post.content}</div>`)}
              </div>
            </div>
          </div>
        </Wrapper>
      )}
    </div>
  );
};
