import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import { Wrapper } from "../../UI/templates";
import style from "./style.module.scss";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { ParticipantCount } from "../../UI/components/ParticipantCount/ParticipantCount";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetMainPageCounts } from "../../../app/services/api/mainPageCounts/mainPageCounts";
import {
  executorsByExperience,
  executorsByNovelty,
  executorsByRating,
  news,
  pageCounts,
  pageProjectsCounts,
  projects,
  selectorMainPageCounts,
  selectorMainPageExecutors,
  selectorMainPageProjects,
  selectorMainPageProjectsCounts,
  selectorMainPaNews,
} from "../../../app/feature/mainPage/mainPage";
import { ApiGetTotalProjects } from "../../../app/services/api/indexPages/counters";
import { ApiGetMainPageProjects } from "../../../app/services/api/mainPageProjects/mainPageProjects";
import {
  ApiGetMainPageExecutorsExperience,
  ApiGetMainPageExecutorsNew,
  ApiGetMainPageExecutorsRating,
} from "../../../app/services/api/mainPageExecutors/mainPageExecutors";
import { ExecutorsList } from "../../UI/components/ExecutorsList/ExecutorsList";
import { CarouselComponent } from "../../UI/components/Carousel/Carousel";
import { NewsItem } from "../../UI/components/NewsItem/NewsItem";
import Gip from "../../../media/images/mainPageProcessImages/gips.png";
import Providers from "../../../media/images/mainPageProcessImages/providers.png";
import Customers from "../../../media/images/mainPageProcessImages/customers.png";
import Executors from "../../../media/images/mainPageProcessImages/executors.png";
import { CarouselSkeleton } from "../../UI/components/Carousel/CarouselSkeleton/CarouselSkeleton";
import { ParticipantSkeleton } from "../../UI/components/ParticipantCount/ParticipantSkeleton/ParticipantSkeleton";
import { ExecutorsSkeleton } from "../../UI/components/ExecutorsList/ExecutorsSkeleton/ExecutorsSkeleton";
import { FullAdditionalInfoProps } from "./FullAdditionalInfo/FullAdditionalInfo";
import { ApiGetPosts } from "../../../app/services/api/posts/posts";
import { userIsAuth } from "../../../app/services/auth/auth";
import { SafeDealSchema } from "../../UI/components/SafeDealSchema/SafeDealSchema";
import Provider from "./FullAdditionalInfo/Provider";
import Customer from "./FullAdditionalInfo/Customer";
import Executor from "./FullAdditionalInfo/Executor";
import GipPage from "./FullAdditionalInfo/Gip";
import { config } from "../../../app.cofig";

export const MainPage = () => {
  const [sliderImageContent, setSliderImageContent] = useState({
    isShow: true,
    path: "",
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentTab, setCurrentTab] =
    useState<FullAdditionalInfoProps["type"]>("");

  const dispatch = useDispatch();
  const theme = useSelector(selectorAppTheme);
  const counts = useSelector(selectorMainPageCounts);
  const projectsCounts = useSelector(selectorMainPageProjectsCounts);
  const projectsList = useSelector(selectorMainPageProjects);
  const executors = useSelector(selectorMainPageExecutors);
  const newsList = useSelector(selectorMainPaNews);

  const data = {
    counts: {
      authors: counts.authors,
      gips: counts.gips,
      executors: counts.executors,
      providers: counts.providers,
      projects: projectsCounts,
    },
    projects: projectsList,
    executors,
    news: newsList,
  };

  const handleShowMainPage = () => {
    setCurrentTab("");
  };

  const handleShowCustomer = () => {
    setCurrentTab("customer");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleShowGip = () => {
    setCurrentTab("gip");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleShowExecutor = () => {
    setCurrentTab("executor");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleShowProvider = () => {
    setCurrentTab("provider");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    request(ApiGetMainPageCounts(), (res) => {
      dispatch(pageCounts(res));
    })(dispatch);

    request(ApiGetTotalProjects(), (res) => {
      dispatch(pageProjectsCounts(res));
    })(dispatch);

    request(ApiGetMainPageProjects(), (res) => {
      dispatch(projects(res));
    })(dispatch);

    request(ApiGetMainPageExecutorsRating(), (res) => {
      dispatch(executorsByRating(res));
    })(dispatch);

    request(ApiGetMainPageExecutorsExperience(), (res) => {
      dispatch(executorsByExperience(res));
    })(dispatch);

    request(ApiGetMainPageExecutorsNew(), (res) => {
      dispatch(executorsByNovelty(res));
    })(dispatch);

    request(ApiGetPosts(), (res) => {
      dispatch(news(res));
    })(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!firstLoad) return;
    if (!data.news.length) return;
    setSliderImageContent({
      isShow: !!data.news[0].imgUrl,
      path: data.news[0].imgUrl,
    });

    setFirstLoad(false);
  }, [data.news]);

  return (
    <>
      <div className={classNames(style.mainSlider)}>
        <div>
          <div className={style.enterContainer}>
            {currentTab === "" ? (
              <div className={style.infoApp}>
                <Wrapper>
                  <div className={style.registration}>
                    <h1 className={style.pageTitle}>
                      Управление проектными работами
                    </h1>
                    <p className={style.pageDescription}>
                      Привлекайте для решения конкретной проектной задачи
                      свободных исполнителей с различным опытом работы, из всех
                      уголков нашей необъятной Родины. Свобода выбора, контроль
                      работ, документооборот, командная работа, подбор
                      материалов в проект... и многое, многое другое в одном
                      решении.
                    </p>
                    <p
                      className={classNames(
                        style.pageDescription,
                        style.noMargin
                      )}
                    >
                      Презентационные материалы можно скачать{" "}
                      <TextLink
                        redirect
                        target={"blank"}
                        variant={style.textLink}
                        url={`${config.localDomain}/files/Цифровая платформа ПИРС.pdf`}
                      >
                        Здесь
                      </TextLink>
                    </p>
                    {userIsAuth() ? (
                      <></>
                    ) : (
                      <div className={style.linksWrapper}>
                        <TextLink
                          theme={theme}
                          variant={style.registrationLink}
                          url={"/site/registration"}
                        >
                          Регистрация
                        </TextLink>
                        <TextLink
                          theme={theme}
                          variant={style.loginLink}
                          url={`${window.origin}/site/login`}
                          redirect={true}
                        >
                          Войти
                        </TextLink>
                      </div>
                    )}
                  </div>
                </Wrapper>
                <div className={style.forUsers}>
                  <Wrapper>
                    <div className={style.forUsersContainer}>
                      <div className={style.forUserWrapper}>
                        <div className={style.forUserContainer}>
                          <div className={style.forUserImgContainer}>
                            <img
                              className={style.forUserImage}
                              src={Customers}
                              alt=""
                            />
                          </div>
                          <h2 className={style.userTitle}>Заказчику</h2>
                          <p className={style.userDescription}>
                            Управление проектом. Проверенные пользователи.
                            Работа с договорами. Облачное хранение документации.
                          </p>
                        </div>
                        <button
                          onClick={handleShowCustomer}
                          className={style.detail}
                        >
                          Подробнее
                        </button>
                      </div>
                      <div className={style.forUserWrapper}>
                        <div className={style.forUserContainer}>
                          <div className={style.forUserImgContainer}>
                            <img
                              className={style.forUserImage}
                              src={Gip}
                              alt=""
                            />
                          </div>

                          <h2 className={style.userTitle}>ГИПу</h2>
                          <p className={style.userDescription}>
                            Проверенные исполнители. Планирование и мониторинг
                            проектных работ. Диаграмма Ганта по проекту.
                            Контроль заданий.
                          </p>
                        </div>
                        <button
                          onClick={handleShowGip}
                          className={style.detail}
                        >
                          Подробнее
                        </button>
                      </div>
                      <div className={style.forUserWrapper}>
                        <div className={style.forUserContainer}>
                          <div className={style.forUserImgContainer}>
                            <img
                              className={style.forUserImage}
                              src={Executors}
                              alt=""
                            />
                          </div>

                          <h2 className={style.userTitle}>Проектировщику</h2>
                          <p className={style.userDescription}>
                            Найти работу. Безопасная сделка. Электронный
                            договор. Подбор материалов и оборудования.
                          </p>
                        </div>
                        <button
                          onClick={handleShowExecutor}
                          className={style.detail}
                        >
                          Подробнее
                        </button>
                      </div>
                      <div className={style.forUserWrapper}>
                        <div className={style.forUserContainer}>
                          <div className={style.forUserImgContainer}>
                            <img
                              className={style.forUserImage}
                              src={Providers}
                              alt=""
                            />
                          </div>

                          <h2 className={style.userTitle}>Поставщику</h2>
                          <p className={style.userDescription}>
                            Информация об объекте. Запросы от проектировщиков и
                            заказчиков. Подача коммерческих предложений.
                          </p>
                        </div>
                        <button
                          onClick={handleShowProvider}
                          className={style.detail}
                        >
                          Подробнее
                        </button>
                      </div>
                    </div>
                  </Wrapper>
                </div>
                <SafeDealSchema />
              </div>
            ) : (
              <div className={style.additionalInfo}>
                <Wrapper>
                  {currentTab === "customer" && (
                    <Customer onBack={handleShowMainPage} />
                  )}
                  {currentTab === "executor" && (
                    <Executor onBack={handleShowMainPage} />
                  )}
                  {currentTab === "gip" && (
                    <GipPage onBack={handleShowMainPage} />
                  )}
                  {currentTab === "provider" && (
                    <Provider onBack={handleShowMainPage} />
                  )}
                </Wrapper>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={style.news}>
        <Wrapper>
          <div
            className={classNames(
              style.sliderWrapper,
              !sliderImageContent.isShow && style.bgHide
            )}
          >
            {sliderImageContent.isShow && (
              <div
                className={classNames(
                  style.imageContainer,
                  !sliderImageContent.path && style.noImage
                )}
              >
                {sliderImageContent.path ? (
                  <img src={sliderImageContent.path} alt="" />
                ) : (
                  <Skeleton
                    animation={false}
                    variant="rectangular"
                    className={style.imageSkeleton}
                  />
                )}
              </div>
            )}
            <div className={style.newsContainer}>
              {data.news.length > 0 ? (
                <CarouselComponent
                  autoPlay={true}
                  interval={7000} // todo -> before release to set 7000
                  showArrows={true}
                  onChange={(current) => {
                    setSliderImageContent({
                      isShow: !!data.news[current].imgUrl,
                      path: data.news[current].imgUrl,
                    });
                  }}
                  showIndicators={false}
                  infiniteLoop
                  showThumbs={false}
                  dynamicHeight={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        className={style.arrowPrev}
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                      >
                        <ArrowBackIos className={style.arrowPrevIcon} />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        className={style.arrowNext}
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                      >
                        <ArrowForwardIos className={style.arrowNextIcon} />
                      </button>
                    )
                  }
                >
                  {data.news.length > 0 &&
                    data.news.map((item, i) => {
                      return (
                        <NewsItem
                          key={i}
                          photoUrl={item.imgUrl}
                          id={item.id}
                          url={item.url}
                          content={item.content}
                          title={item.title}
                        />
                      );
                    })}
                </CarouselComponent>
              ) : (
                <CarouselSkeleton />
              )}
            </div>
          </div>
        </Wrapper>
      </div>
      <div className={style.countsContainer}>
        <Wrapper>
          <div className={style.counts}>
            {data.counts.executors !== null ? (
              <ParticipantCount
                title="Инженеров"
                count={data.counts.executors}
                description="Профессионалы, готовые к работе, с опытом и портфолио"
              />
            ) : (
              <ParticipantSkeleton />
            )}
            {data.counts.projects !== null ? (
              <ParticipantCount
                title="Проектов"
                count={data.counts.projects}
                description="От Калининграда до Камчатки"
              />
            ) : (
              <ParticipantSkeleton />
            )}
            {data.counts.gips !== null ? (
              <ParticipantCount
                title="ГИПов"
                count={data.counts.gips}
                description="Энергичные и толковые, умные, пробивные"
              />
            ) : (
              <ParticipantSkeleton />
            )}

            {data.counts.authors !== null ? (
              <ParticipantCount
                title="Заказчиков"
                count={data.counts.authors}
                description="Ищут и находят своих исполнителей"
              />
            ) : (
              <ParticipantSkeleton />
            )}
            {data.counts.providers !== null ? (
              <ParticipantCount
                title="Поставщиков"
                count={data.counts.providers}
                description="Всегда найдут что предложить"
              />
            ) : (
              <ParticipantSkeleton />
            )}
          </div>
        </Wrapper>
      </div>
      <div className={style.projectsContainer}>
        <Wrapper>
          <div className={style.titleWrapper}>
            <h1 className={style.projectsTitle}>Проекты</h1>
            <p className={style.projectsDescription}>
              Объекты и разделы в ожидании творческого подхода, грамотных
              решений и положительной экспертизы.
            </p>
          </div>
          <div className={style.projectsWrapper}>
            {projectsList.length > 0 ? (
              <ul className={style.projectsList}>
                {projectsList.map((item: any, i: number) => {
                  return (
                    <li key={i}>
                      <TextLink
                        variant={style.projectLink}
                        url={`${config.localDomain}/project/${item.id}`}
                      >
                        <div className={style.projectItem}>
                          <h1 className={style.projectAuthor}>
                            {item.author.name}
                          </h1>
                          <h3 className={style.projectRegion}>
                            {item.region.title}
                          </h3>
                          <div className={style.projectNameContainer}>
                            <h1 className={style.projectName}>{item.name}</h1>
                          </div>
                          <h1 className={style.publishedCost}>
                            {item.publishedCost}
                          </h1>
                          <ul className={style.projectProcesses}>
                            {item.projectProcesses
                              .slice(0, 3)
                              .map((item: any, i: number) => {
                                return (
                                  <li
                                    className={style.projectProcessesItem}
                                    key={i}
                                  >
                                    {item.code}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </TextLink>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div>
                <div className={style.skeletonList}>
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                  <Skeleton
                    variant="rectangular"
                    className={style.projectItemSkeleton}
                  />
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      <div className={style.executors}>
        <div className={style.executorsContainer}>
          <Wrapper>
            <div className={style.titleWrapper}>
              <h1 className={style.executorsTitle}>Проектировщики</h1>
              <p className={style.executorsDescription}>
                Лучшие из лучших ждут Проекты и готовы к работе. Сотни умнейших,
                из самых разных регионов России, от Волги до Енисея – умеют,
                могут, практикуют.
              </p>
            </div>
          </Wrapper>
          <Wrapper>
            <div className={style.executorsWrapper}>
              <div className={style.executorsCategory}>
                <h2 className={style.categoryTitle}>Рейтинг</h2>
                <p className={style.categorySubTitle}>
                  Исполнители с высоким рейтингом и прекрасной репутацией
                </p>
                {data.executors.rating.length > 0 ? (
                  <ExecutorsList theme={theme} items={data.executors.rating} />
                ) : (
                  <ExecutorsSkeleton />
                )}
              </div>
              <div className={style.executorsCategory}>
                <h2 className={style.categoryTitle}>Опыт</h2>
                <p className={style.categorySubTitle}>
                  Передовики производства, <br /> ударники ПИРСа
                </p>
                {data.executors.experience.length > 0 ? (
                  <ExecutorsList
                    theme={theme}
                    items={data.executors.experience}
                  />
                ) : (
                  <ExecutorsSkeleton />
                )}
              </div>
              <div className={style.executorsCategory}>
                <h2 className={style.categoryTitle}>Новенькие</h2>
                <p className={style.categorySubTitle}>
                  Новые на ПИРСе, но опытные в профессии. Добро пожаловать!
                </p>
                {data.executors.new.length > 0 ? (
                  <ExecutorsList theme={theme} items={data.executors.new} />
                ) : (
                  <ExecutorsSkeleton />
                )}
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  );
};
