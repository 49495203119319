import { IMileStone } from "../../../app/feature/ProjectProcessView/MileStone/types/projectProcessMileStoneTypes";

export function createMap(arr: IMileStone[], type: "keys"): string[];
export function createMap(
  arr: IMileStone[],
  type: "entries"
): [string, IMileStone[]][];

export function createMap(arr: IMileStone[], type: "keys" | "entries") {
  let index = 0;
  let key = 1;
  const obj: Record<string, IMileStone[]> = {};
  while (index < arr.length) {
    if (key % 2 !== 0) {
      obj[key] = arr.slice(index, index + 5);
      index += 5;
    } else {
      obj[key] = arr.slice(index, index + 4);
      index += 4;
    }
    key++;
  }
  if (type === "entries") {
    return Object.entries(obj);
  }
  if (type === "keys") {
    return Object.keys(obj);
  }
}
