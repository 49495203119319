import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal/ModalContext";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useCheckCustomerAlign } from "../lib/hooks";

const defaultValues = {
  comment: "",
  paymentSum: 0,
  status: null,
  hasNds: false,
  processBalance: 0,
};

type Props = {
  id: number;
  header: string;
};

export const CheckCustomerAlignForm = ({ id, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    ndsText,
    status,
    isSubmitting,
    isTaskViewPending,
    taskData,
    processBalance,
  } = useCheckCustomerAlign({
    id,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Комментарий исполнителя"
            value={taskData!.content}
            disabled
            multiline
          />
          <FilesList
            label="Файлы решения"
            fileLinks={taskData?.parent?.files || taskData!.files}
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          {Boolean(Number(processBalance)) && (
            <>
              <p>
                Баланс раздела: {processBalance}, {ndsText}
              </p>
              <TextField
                {...register("paymentSum")}
                label={`Сумма для оплаты ${ndsText}`}
                error={Boolean(errors.paymentSum)}
                helperText={errors.paymentSum?.message}
              />
            </>
          )}
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
              color="error"
              variant="outlined"
              width={120}
              isLoading={isSubmitting && status === Statuses.REJECT}
            >
              Отказать
            </Button>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
              width={120}
              isLoading={isSubmitting && status === Statuses.ACCEPT}
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
