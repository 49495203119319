import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { fetchExpertiseDocuments } from "../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseDocuments/services/fetchExpertiseDocuments";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertiseDocuments,
  getExpertiseDocumentsIsLoad,
} from "../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseDocuments/selectors/getExpertiseDocuments";
import { DocumentCard } from "./DocumentCard/DocumentCard";
import cls from "./DocumentsExpertise.module.scss";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { ExpertiseDocumentsTab } from "../../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";

export const DocumentsExpertise = memo(() => {
  const dispatch = useAppDispatch();
  const proccesId = useAppSelector(getProjectProcessInfoId);
  const params = useParams<{ id: string }>();
  const documents = useAppSelector(getExpertiseDocuments);
  const isLoadDocuments = useAppSelector(getExpertiseDocumentsIsLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (proccesId) {
      dispatch(fetchExpertiseDocuments(proccesId));
    }
  }, [proccesId]);

  if (isLoadDocuments) {
    return <ExpertiseDocumentsTab />;
  }

  if (documents) {
    return (
      <div className={cls.documents}>
        {documents.map((document) => (
          <DocumentCard {...document} />
        ))}
      </div>
    );
  }

  return <></>;
});
