import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { NewChatIcon } from "src/components/newUI/SVG";
import { GipThreeDotBtn } from "./GipThreeDotBtn/GipThreeDotBtn";
import styles from "../GipCard.module.scss";

const sx = {
  color: "#0AA699",
  padding: 0,
  transition: "0.3s ease-in-out 0s!important",
  "&:hover": {
    transform: "scale(1.2)",
  },
};
export const GipInfo = memo(() => {
  const { project } = useSelector(getProjectViewData);
  const gip = project?.gip;
  const canThreeDot =
    project?.can?.completeRemoveGip || project?.can?.requestRemoveGip;
  return (
    <>
      <div className={styles.gip_header}>
        <div>
          <h1>ГИП</h1>
          <div className={styles.gip_header_flex}>
            {project?.can?.view && (
              <Link to={`/chat/${project?.chatId}`}>
                <IconButton title="Чат" sx={sx}>
                  <NewChatIcon />
                </IconButton>
              </Link>
            )}
            {canThreeDot && <GipThreeDotBtn />}
          </div>
        </div>
        {gip?.id ? (
          <TextLink url={`/user/${gip?.id}`}>
            <p className={styles.gip_header_name}>{gip?.name}</p>
          </TextLink>
        ) : (
          <p className={styles.gip_header_name}>{gip?.name}</p>
        )}
        <span className={styles.gip_type}>{gip?.type}</span>
      </div>
    </>
  );
});
