import React, { memo } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { FormWrapper } from "../../../../../../newUI/FormWrapper/FormWrapper";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { fetchProcessMaterialSelect } from "../../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialSelect";
import { fetchProcessMaterialDuplicate } from "../../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterial";
import { getProjectProcessInfoId } from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

type TInputs = {
  criteria: string;
};

const schema = Yup.object().shape({
  criteria: Yup.string().required("Необходимо заполнить комментарий"),
});

interface ModalCheckProps {
  materialId: number;
  taskId: number;
  closeModal: () => void;
}
export const ModalCheck = memo(
  ({ materialId, taskId, closeModal }: ModalCheckProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<TInputs>({
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

    async function formSubmitHandler(dataValues: TInputs) {
      const data = {
        criteria: dataValues.criteria,
      };
      await dispatch(fetchProcessMaterialSelect({ materialId, taskId, data }));
      if (processId) {
        await dispatch(fetchProcessMaterialDuplicate(processId));
      }
      closeModal();
    }

    return (
      <FormWrapper
        title={`Критерии выбора коммерческого предложения`}
        submitHandler={handleSubmit(formSubmitHandler)}
      >
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("criteria")}
          placeholder="Введите текст"
          error={!!errors.criteria}
          helperText={errors.criteria ? errors.criteria.message : null}
        />
        <CustomButton
          style={{
            marginLeft: "auto!important",
          }}
          width={140}
          type={"submit"}
          background={palette.blue}
          onClick={() => {}}
        >
          Отправить
        </CustomButton>
      </FormWrapper>
    );
  }
);
