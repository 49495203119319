import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const Offer = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.condition, style[theme])}>
      <strong>
        Публичная оферта о заключении договора оказания услуг сопровождения
        сделок между пользователями платформы ПИРС
      </strong>
      <strong>«Безопасная сделка»</strong>
      <p>
        Настоящий документ представляет собой официальное предложение{" "}
        <b>
          Платформы - Общества с ограниченной ответственностью «Цифровая
          платформа «ПИРС»
        </b>{" "}
        авторизированным пользователям платформы ПИРС, именуемого в дальнейшем
        «Платформа», заключить договор возмездного оказания услуг на изложенных
        ниже условиях.
      </p>
      <strong>1. Акцепт Оферты и заключение Договора</strong>
      <p>
        1.1. Акцепт Оферты создает Договор между пользователями платформы ПИРС и
        Платформой (статьи 433, 438 Гражданского Кодекса Российской Федерации)
        на условиях Оферты.
      </p>
      <p>
        1.2. Акцепт Оферты совершается пользователем платформы ПИРС путем
        оформления и направления Заказа на услугу с помощью программных средств
        на платформе ПИРС;
      </p>
      <p>
        1.3. Договор считается заключенным с момента получения платформой ПИРС
        Акцепта Оферты.
      </p>
      <p>
        1.4. На основании Оферты с пользователем платформы ПИРС может быть
        заключено неограниченное количество Договоров.
      </p>
      <strong>2. Предмет Договора</strong>
      <p>
        2.1. Настоящий Договор оформляет правовые отношения, возникающие между
        Заказчиком, Подрядчиком и Платформой в том числе в связи с
        использованием онлайн сервиса «Безопасная Сделка» применительно к
        Договору подряда, заключаемому Заказчиком и Подрядчиком через Защищенные
        страницы платформы ПИРС.
      </p>
      <p>
        2.2. Платформа обязуется оказать за вознаграждение следующие виды услуг:
      </p>
      <p>
        2.2.1. Обеспечение возможности информационно-технологического
        взаимодействия между Заказчиком и Подрядчиком в рамках онлайн сервиса
        «Безопасная сделка» посредством Защищенных страниц Сайта;
      </p>
      <p>
        2.2.2. Услуги Заказчику, заключающиеся в совершении от своего имени, но
        за счет Заказчика юридических и фактических действий по исполнению
        обязанностей, принятых на себя Заказчиком при заключении с Подрядчиком
        Договора подряда, а именно: обязанности по перечислению оплаты за
        результат выполненной Подрядчиком Работы;
      </p>
      <p>
        2.2.3. Услуги по проведению независимого и объективного анализа
        результата Работы в случае обращения к Платформе Заказчика и (или)
        Подрядчика в порядке, предусмотренном разделом настоящим Договором.
      </p>
      <p>
        2.3. Настоящим Заказчик и Подрядчик также наделяют Платформу в случае
        выбора того или иного Способа оплаты всеми соответствующими правами и
        полномочиями, необходимыми для того, чтобы реализовать такой Способ
        оплаты.
      </p>
      <p>
        2.4. Платформа вправе для оказания услуг, предусмотренных п. 2.2
        Договора, привлекать третьих лиц без предварительного получения на то
        согласия остальных Сторон настоящего Договора.
      </p>
      <p>
        2.5. Платформа ПИРС обязуется оказывать Услуги на основании размещенных
        Заказов, а пользователь платформы ПИРС обязуется принимать и оплачивать
        Услуги на условиях настоящей Оферты.
      </p>
      <p>
        2.6. Содержание Услуг, сроки и стоимость их оказания, а также прочие
        необходимые условия Договора определяются на основании сведений,
        предоставленных Заказчиком при оформлении Заказа, в соответствии с
        условиями Обязательных документов.
      </p>
      <p>
        2.7. Обязательным условием заключения Договора и его исполнения является
        безоговорочное принятие и соблюдение Заказчиком, применяемых к
        отношениям сторон по Договору требований и положений, определенных
        следующими документами («Обязательные документы»):
      </p>
      <p>
        2.7.1. Пользовательское соглашение, размещенное и/или доступное в сети
        Интернет по адресу{" "}
        <a href="https://pirs.online/site/agreement">
          https://pirs.online/site/agreement
        </a>{" "}
        и включающее общие условия регистрации на платформы ПИРС и использования
        Сервиса платформы ПИРС.
      </p>
      <p>
        2.7.2. Политика конфиденциальности, размещенная и/или доступная в сети
        Интернет по адресу{" "}
        <a href="https://pirs.online/site/policy">
          https://pirs.online/site/policy
        </a>{" "}
        и содержащая правила предоставления и использования персональной
        информации, включая персональные данные пользователя платформы ПИРС.
      </p>
      <p>
        2.7.3. Термины и определения, используемые ПИРС, размещенное и/или
        доступное в сети Интернет по адресу{" "}
        <a href="https://pirs.online/site/terms">
          https://pirs.online/site/terms
        </a>{" "}
        и включающее применяемые в целях исполнения сделки описание и толкование
        тех или иных терминов, условий и определений в документах платформы
        ПИРС.
      </p>
      <p>
        2.8. Указанные в п.2.7. Обязательные документы составляют неотъемлемую
        часть заключаемого в соответствии с настоящей Офертой Договора.
      </p>
      <p>
        Для прохождения Подтвержденной регистрации Пользователь в разделе
        «Профиль пользователя» заполняет данные, идентифицирующие Пользователя,
        в том числе:
      </p>
      <ul>
        <li>
          Пользователь-физическое лицо указывает свою фамилию, имя и отчество
          (при наличии), дату рождения, адрес регистрации, паспортные данные;
        </li>
        <li>
          Лицо, осуществляющее функции единоличного исполнительного органа
          Пользователя, являющегося юридическим лицом, и имеющее электронную
          цифровую подпись, указывает наименование Пользователя в соответствии с
          ЕГРЮЛ, ИНН Пользователя, ИНН и дату рождения физического лица,
          осуществляющего функции единоличного исполнительного органа
          Пользователя, сведения о котором содержатся в ЕГРЮЛ.
        </li>
      </ul>
      <p>
        Совершением действий, направленных на регистрацию
        Пользователя-юридического лица, лицо, осуществляющее функции
        единоличного исполнительного органа Пользователя, являющегося
        юридическим лицом, дает согласие Платформе ПИРС на обработку своих
        персональных данных в соответствии с политикой конфиденциальности.
        Платформа ПИРС в срок до 2 (двух) рабочих дней с даты получения всех
        сведений, предусмотренных настоящим Договором, осуществляет верификацию
        предоставленных Пользователем сведений. После верификации данных
        Пользователя и лица, осуществляющего функции единоличного
        исполнительного органа Пользователя, являющегося юридическим лицом,
        Пользователь имеет право участвовать в сделках.
      </p>
      <strong>3. Обеспечительный платеж</strong>
      <p>
        3.1. Стороны соглашаются, что в целях обеспечения исполнения взаимных
        обязательств Заказчика и Подрядчика по Договору подряда Заказчик до
        заключения Договора подряда с Подрядчиком с целью резервирования
        денежных средств для оплаты сделки и услуг Платформы обеспечивает
        зачисление на счет Платформы в качестве Обеспечительного платежа
        денежные средства, отображаемые Платформой в личном кабинете Заказчика.
      </p>
      <p>
        3.2. В соответствии с п. 1 ст. 329, п. 1 ст. 381.1. Гражданского кодекса
        РФ Обеспечительный платеж является предусмотренным Договором способом
        обеспечения исполнения обязательств Заказчика по оплате Подрядчику
        Стоимости Работы, указанной в Техническом задании, а также гарантий
        возврата денежных средств в случае расторжения, прекращения договора
        Подряда.
      </p>
      <p>
        3.3. Обеспечительный платеж поступает на счет Платформы исключительно
        для того, чтобы гарантировать и обеспечить выплату Стоимости Работы (в
        том числе соразмерно уменьшенную) Подрядчику в случаях, предусмотренных
        настоящим Договором, а также гарантий возврата денежных средств в случае
        расторжения, прекращения договора Подряда.
      </p>
      <p>3.4. Проценты на Обеспечительный платеж не начисляются.</p>
      <p>
        3.5. Платформа вправе разместить сумму Обеспечительного платежа на
        банковском депозите (выбор банковского учреждения осуществляется
        Платформой самостоятельно), при этом уведомление Заказчика или
        Подрядчика о таком размещении не требуется, начисление процентов в
        пользу Заказчика или Подрядчика на сумму денежных средств, размещенных
        на депозите, не осуществляется.
      </p>
      <p>
        3.6. Платформа может удерживать при Осуществлении выплаты Стоимости
        работы (в том числе соразмерно уменьшенной) необходимые налоги, выступая
        в качестве налогового агента, а также сборы, взносы и иные обязательные
        платежи в случаях, предусмотренных законодательством Российской
        Федерации, и в случае вынесения соответствующего решения государственным
        органом. Названное в настоящем пункте Договора право Платформы
        распространяется в том числе на случаи, когда Подрядчик своевременно или
        надлежащим образом не сообщил Платформе о наличии у первого статуса
        налогоплательщика налога на профессиональный доход в соответствии с
        Федеральным законом от 27.11.2018 N 422-ФЗ, а также на случаи, когда
        Платформа получила сведения о прекращении или утрате статуса
        налогоплательщика налога на профессиональный доход.
      </p>
      <p>
        3.7. В полном соответствии со ст. ст. 996, 1011 Гражданского кодекса
        Российской Федерации право собственности на Обеспечительный платеж
        остается за Заказчиком вплоть до перечисления в соответствии с настоящим
        Договором Подрядчику.
      </p>
      <p>
        3.8. В случаях, предусмотренных разделом 6, а также п. 10.15 Договора,
        Обеспечительный платеж подлежит возврату Заказчику.
      </p>
      <p>
        3.9. После осуществления Заказчиком зачисления на счет Платформы
        обеспечительного платежа Платформа обязуется производить Осуществление
        выплаты Стоимости Работы (в том числе соразмерно уменьшенной) Подрядчику
        или Осуществление возврата Обеспечительного платежа (ее части) Заказчику
        исключительно в том порядке и в таких соответствующих случаях, как это
        предусмотрено настоящим Договором. Заказчик безотзывно уполномочивает и
        поручает Платформе производить Осуществление выплаты Стоимости Работы (в
        том числе соразмерно уменьшенной) Подрядчику или Осуществление возврата
        Обеспечительного платежа (ее части) исключительно в том порядке и в
        таких соответствующих случаях, как это предусмотрено настоящим
        Договором, а Подрядчик осведомлен и согласен с таким порядком
        Осуществления выплаты Стоимости Работы (в том числе соразмерно
        уменьшенной) Подрядчику или Осуществления возврата Обеспечительного
        платежа (ее части).
      </p>
      <p>
        3.10. Заказчик и Подрядчик совместно и каждый по отдельности
        подтверждают, что установленный Договором порядок внесения и содержания
        на счете Платформы Обеспечительного платежа, и следующие из него порядок
        Осуществления выплаты Стоимости Работы Подрядчику (в том числе
        соразмерно уменьшенной) и порядок Осуществления возврата
        Обеспечительного платежа (его части) является для Заказчика и Подрядчика
        способом обеспечения исполнения взаимных обязательств Заказчика и
        Подрядчика по Договору подряда, который обусловлен особенностями сферы
        деятельности, в которой функционирует платформа ПИРС, и содержанием
        онлайн сервиса «Безопасная сделка».
      </p>
      <strong>
        4. Осуществление выплаты Стоимости Работы (в том числе соразмерно
        уменьшенной) Подрядчику
      </strong>
      <p>
        4.1. Основанием для Осуществления выплаты Стоимости Работы Подрядчику
        является одно из следующих обстоятельств:
      </p>
      <p>
        4.1.1. Заказчик сообщил Платформе о надлежащем выполнении Работы
        Подрядчиком и сдаче результата такой Работы Подрядчиком, причем такое
        сообщение может быть сделано только с использованием
        программно-технических средств платформы ПИРС через Защищенные страницы
        сайта ПИРС путем нажатия кнопки «Принять», направления подписанного
        электронной подписью Подрядчика документа, подтверждающего факт
        окончания работы (Акта) и подписания электронной подписью Заказчиком
        данного документа через сервис Платформы
      </p>
      <p>
        4.1.2. По результатам рассмотрения обращения, направленного Заказчиком и
        (или) Подрядчиком в порядке, предусмотренном п. 6 Договора, Платформа по
        своему собственному независимому усмотрению и убеждению приняло решение,
        что Работа выполнена надлежащим образом и в срок в соответствии с
        Договор подряда и Техническим заданием.
      </p>
      <p>
        4.2. Основанием для осуществления выплаты соразмерно уменьшенной
        Стоимости Работы Подрядчику является принятое Платформой по своему
        собственному независимому усмотрению и убеждению решение, согласно
        которому Результат Работы частично соответствует Договору подряда и
        Техническому заданию и представлен Заказчику в срок, предусмотренный в
        Техническом задании.
      </p>
      <p>
        В таком случае сумма, подлежащая выплате Подрядчику, определяется исходя
        из установленного процентного соотношения, в котором представленный
        Подрядчиком результат Работы соответствует Договору подряда и
        Техническому заданию.
      </p>
      <p>
        4.3. Платформа, действуя как агент Заказчика, обязуется (и во избежание
        сомнений Заказчик безотзывно поручает и уполномочивает Платформу, а
        Подрядчик осведомлен и согласен с этим) производить Осуществление
        выплаты Стоимости Работы Подрядчику в срок не позднее 10 (десяти)
        рабочих дней с момента выполнения всех следующих, но не некоторых из
        них, условий:
      </p>
      <p>
        4.3.1. Наступило одно из обстоятельств, предусмотренных п. 4.1.
        настоящего Договора;
      </p>
      <p>
        4.3.2. Подрядчик оформил и передал все соответствующие документы, в том
        числе для ведения бухгалтерского и налогового учета, включая, документы
        и сведения в отношении Подрядчика, акты приема-передачи выполненной
        Работы, счета-фактуры и т.п. При этом копии указанных выше документов
        должны быть размещены Подрядчиком на Защищенных страницах платформы
        ПИРС;
      </p>
      <p>
        4.3.3. Подрядчик произвел выбор Способа оплаты Стоимости работы и
        сообщил Платформе все необходимые для этого реквизиты, причем такой
        выбор и такое сообщение могут быть сделаны только с использованием
        программно-технических средств Сайта через Защищенные страницы Сайта.
      </p>
      <p>
        4.4. Платформа, обязуется (и во избежание сомнений Заказчик безотзывно
        инструктирует и уполномочивает Платформу, а Подрядчик осведомлен и
        согласен с этим) производить Осуществление выплаты соразмерно
        уменьшенной Стоимости Работы Подрядчику в соответствии с п. 4.2 Договора
        в срок не позднее 10 (десяти) рабочих дней с момента выполнения всех
        следующих, но не некоторых из них, условий:
      </p>
      <p>
        4.4.1. Подрядчик оформил и передал Платформе все соответствующие
        документы, которые необходимы Платформе в соответствии с применимым
        законодательством, в том числе для ведения бухгалтерского и налогового
        учета, документы и сведения в отношении Подрядчика, акты приема-передачи
        выполненной Работы, счета-фактуры и т.п. При этом копии указанных выше
        документов должны быть размещены Подрядчиком на Защищенных страницах
        платформы ПИРС;
      </p>
      <p>
        4.4.2. Подрядчик произвел выбор Способа оплаты Стоимости работы и
        сообщил Платформе все необходимые для этого реквизиты, причем такой
        выбор и такое сообщение могут быть сделаны только с использованием
        программно-технических средств платформы ПИРС через Защищенные страницы
        платформы ПИРС.
      </p>
      <p>
        {" "}
        4.5. При Осуществлении выплаты Стоимости Работы (в том числе соразмерно
        уменьшенной) Стоимости Работы все необходимые суммы налогов, сборов и
        взносов, как это предусмотрено в п.п. 4.5. и 4.6 Договора Платформа
        вправе удерживать из перечисляемой Подрядчику.
      </p>
      <p>
        4.6. На Заказчике, являющимся юридическим лицом лежит обязанность по
        уплате страховых взносов на сумму вознаграждения, выплачиваемого
        Подрядчику – физическому лицу по Договору подряда в соответствии с
        применимым правом, за исключением случаев, когда Подрядчик является
        Самозанятым.
      </p>
      <p>
        4.7. Обязанность Платформы по Осуществлению выплаты стоимости Работы (в
        том числе соразмерно уменьшенной) Подрядчику будет считаться исполненной
        с момента совершения Платформой всех объективно зависящих от нее
        действий. Исполнение Платформой обязанности по Осуществлению выплаты
        Стоимости Работы (в том числе соразмерно уменьшенной) Подрядчику,
        независимо от того, были ли проведены Платформой какие-либо удержания
        или зачеты в соответствии с положениями Договора, рассматривается в
        качестве надлежащего исполнения Заказчиком обязанности по оплате
        Результата Работы.
      </p>
      <p>
        4.8. Если Подрядчик указал на Защищенных страницах платформы ПИРС
        неправильные реквизиты своего счета или учетной записи, или указал
        несуществующий счет или учетную запись, или вследствие подобных или иных
        причин, объективно не зависящих от Платформы, Осуществление выплаты
        Стоимости Работы (в том числе соразмер0но уменьшенной) стало
        невозможным, Платформа направляет уведомление об этом Подрядчику на
        Адрес электронной почты трижды в течение 3 календарных дней с момента
        первой попытки Осуществления выплаты Стоимости Работы (в том числе
        соразмерно уменьшенной).
      </p>
      <p>
        4.9. В течение 3-х календарных дней, предусмотренного в п. 4.8.
        Договора, Подрядчик обязуется предоставить Платформе корректные
        сведения, по которым возможно Осуществление выплаты Стоимости Работы (в
        том числе соразмерно уменьшенной).
      </p>
      <p>
        {" "}
        4.10. Если в течение 3-х календарных дней, предусмотренного в п. 4.8.
        Договора, корректные сведения, по которым возможно Осуществление выплаты
        Стоимости Работы (в том числе соразмерно уменьшенной), не были
        представлены Платформе Подрядчиком, Платформа возвращает Стоимость
        Работы (в том числе соразмерно уменьшенную) Заказчику. При этом
        Платформа посредством программно-технических средств платформы ПИРС
        направляет Заказчику имеющуюся информацию о Подрядчике, имеющем право
        требовать выплаты ему Стоимости Работы (в том числе соразмерно
        уменьшенной). Платформа предоставляет Подрядчику информацию о Заказчике.
      </p>
      <p>
        4.11. Заключая настоящий Договор, Заказчик выражает согласие с
        предоставлением Подрядчику необходимых сведений о Заказчике в случае,
        предусмотренном в п. 4.10 Договора.
      </p>
      <p>
        4.12. Заключая настоящий Договор, Подрядчик выражает согласие с
        предоставлением Заказчику необходимых сведений о Подрядчике в случае,
        предусмотренном в п. 4.10 Договора.
      </p>
      <p>
        4.13. Стороны исходят из того, что при возврате Заказчику Стоимости
        Работы в случае, предусмотренном в п. 4.10. настоящего Договора,
        принятые на себя Платформой обязательства по настоящему Договору,
        считаются надлежащим образом и в полной мере исполненными.
      </p>
      <strong>5. Передача и приемка результата Работы</strong>
      <p>
        5.1. Подрядчик передает результат Работы с помощью
        программно-технических средств платформы ПИРС посредством загрузки
        файлов на Защищенные Страницы платформы ПИРС. В случае, когда Результат
        Работы не передается Заказчику в виде файла, Подрядчик передает
        результат Работы способом, согласованным с Заказчиком, в соответствии с
        заключенным Договором подряда и Техническим заданием. Результат Работы
        не считается переданным Заказчику, если передача не состоялась в
        соответствии с настоящим пунктом Договора. В случае, если передача файла
        произошла через систему обмена электронного документооборота, Заказчик и
        Подрядчик обязуются не удалять и не изменять загруженный файл, а также
        обеспечивать и не закрывать доступ к нему до Осуществления выплаты
        Стоимости Работы Подрядчику и (или) возврата Обеспечительного платежа
        Заказчику, при этом срок хранения файла в первоначальном виде на систему
        обмена электронного документооборота в любом случае не должен быть
        меньше 30 календарных дней с момента указания на Защищенных Страницах
        платформы ПИРС действительной постоянной ссылки.
      </p>
      <p>
        5.2. Если Работа была выполнена Подрядчиком надлежащим образом, Заказчик
        сообщает об этом Подрядчику и Платформе при помощи
        программно-технических средств на Защищенных Страницах платформы ПИРС.
        Такое уведомление является основанием для Осуществления выплаты
        Стоимости Работы полностью в соответствии с п.п. 4.1. и 4.3 Договора.
      </p>
      <p>
        5.3. В случае, когда, по мнению Заказчика, Работа выполнена ненадлежащим
        образом, Заказчик вправе обратиться к Платформе в порядке,
        предусмотренном п. 6 Договора.
      </p>
      <p>
        5.4. Платформа вправе по собственной инициативе обращаться к Заказчику и
        Подрядчику для выяснения ситуации в связи с передачей и приемкой Работы,
        однако Платформа не обязана это делать. Платформа не обязана вести
        мониторинг процесса выполнения Работы.
      </p>
      <p>
        5.5. Согласно требованиям применимого законодательства, Подрядчик и
        Заказчик оформляют между собой акт сдачи-приемки выполненных работ
        Подрядчиком на основании Договора подряда и Технического задания; а
        также оформляется чек в соответствии с пунктами 5.11-5.13 Договора. В
        случаях, предусмотренных применимым правом, Подрядчик самостоятельно
        выставляет счет-фактуру (УПД) Заказчику на выполненную им Работу.
        Заказчик обязуется своевременно предоставить Платформе и Подрядчику
        корректные сведения о Заказчике, включая идентификационный номер
        налогоплательщика. Платформа и Подрядчик не несут ответственности в
        случае предоставления Заказчиком некорректных сведений о себе.
      </p>
      <p>
        5.6. Акт сдачи-приемки выполненных работ представляется Заказчику и
        Подрядчику в электронной форме на Защищенных страницах Сайта платформы,
        подлежит подписанию ЭП каждой стороной и обмена подписанными документами
        через сервис Платформы.
      </p>
      <p>
        5.7. При наличии обстоятельств уклонения от возврата документов, обмена
        ими через сервис Платформы по запросу Заказчика, в целях подтверждения
        факта передачи Подрядчиком и приема результата Работы Заказчиком, а
        также факта оформления Заказчиком и Подрядчиком акта приема-передачи
        результата Работы, Платформа может направить Заказчику акт
        приема-передачи результата Работы в письменном виде посредством
        электронной связи. В таком случае в акте приема-передачи результата
        Работы Платформа, используя базы данных Сайта ПИРС, может подтвердить
        факт того, что Подрядчик выполнил Работу и передал ее Результат
        Заказчику надлежащим образом. Такое подтверждение признается Сторонами
        надлежащим доказательством факта оформления акта приема-передачи Работы
        со стороны Подрядчика при рассмотрении спора в суде.
      </p>
      <p>
        5.8. При наличии обстоятельств уклонения от возврата документов, обмена
        ими через сервис Платформы по запросу Подрядчика, в целях подтверждения
        факта передачи Подрядчиком и приема результата Работы Заказчиком, а
        также факта оформления Заказчиком и Подрядчиком акта приема-передачи
        результата Работы, Платформа может направить Подрядчику акт
        приема-передачи результата Работы в письменном виде посредством
        электронной связи. В таком случае в акте приема-передачи результата
        Работы Платформа, используя базы данных Сайта, может подтвердить факт
        того, что Заказчик с помощью программно-технических средств Сайта принял
        результат Работы Подрядчика. Такое подтверждение признается Сторонами
        надлежащим доказательством факта оформления акта приема-передачи Работы
        со стороны Подрядчика при рассмотрении спора в суде.
      </p>
      <p>
        5.9. Подтверждая в соответствии с п.п. 5.7 и 5.8 Договора факт передачи
        и приема результата Работы и факт оформления Заказчиком и Подрядчиком
        акта приема-передачи результата Работы, Платформа не является
        представителем, поверенным, агентом или комиссионером Заказчика или
        Подрядчика, а выступает исключительно в качестве лица, предоставляющего
        доказательства передачи Подрядчиком и приема Заказчиком результата
        Работы, а также факта оформления Заказчиком и Подрядчиком акта
        приема-передачи результата Работы с помощью программно-технических
        средств Сайта.
      </p>
      <p>
        5.10. В запросе Заказчика или Подрядчика о предоставлении акта
        сдачи-приемки выполненных работ, оформленного с помощью
        программно-технических средств Сайта, указывается адрес, по которому
        производится отправка акта. В таком случае акт приема-передачи
        Результата Работы направляется соответственно Заказчику или Подрядчику
        посредством электронной почты указанной при регистрации Пользователя.
      </p>
      <p>
        5.11. В случае, когда Подрядчик является Самозанятым, Заказчику
        передается сформированный чек в электронной форме.
      </p>
      <p>
        5.12. Самозанятый Подрядчик обязуется самостоятельно сформировать и
        передать чек Заказчику в момент получения суммы денежных средств,
        составляющих Стоимость Работы (в том числе Соразмерно уменьшенную), но
        не позднее 10-го числа месяца, следующего за календарным месяцем, в
        котором произведены расчеты, в следующих случаях:
      </p>
      <p>
        5.13. Нарушение указанной в п. 5.12 Договора обязанности со стороны
        самозанятого Подрядчика признается основанием для применения положений
        пунктов 10.6-10.11 настоящего Договора.
      </p>
      <strong>6. Рассмотрение обращений Заказчика и Подрядчика</strong>
      <p>
        6.1. Заказчик и Подрядчик, в случае возникновения между ними
        разногласий, вправе обратиться к Платформе для целей проведения
        Платформой независимого анализа соответствия выполненной и переданной
        Заказчику Работы Договору подряда и Техническому заданию.
      </p>
      <p>
        6.2. Обращение к Платформе осуществляется при помощи
        программно-технических средств Сайта путем нажатия на Защищенных
        страницах Сайта кнопки «Запросить снятие исполнителя» или «Отказаться от
        исполнения».
      </p>
      <p>
        6.3. Платформой не рассматриваются по существу обращения (а поступившие
        обращения считаются не сделанными), которые:
      </p>
      <p>
        6.3.1. Сделаны Заказчиком после того, как он сообщил о надлежащем
        выполнении Работы Подрядчиком и сдаче результата такой Работы
        Подрядчиком;
      </p>
      <p>
        6.3.2. Сделаны после принятия Платформой одного из решений,
        предусмотренных п. 6.9 Договора;
      </p>
      <p>
        6.3.3. Сделаны в отношении результата Работы, содержание которого
        противоречит законодательству Российской Федерации.
      </p>
      <p>
        6.3.4. Сделаны Заказчиком до истечения срока, отведенного на выполнение
        Работы, если Подрядчик фактически приступил к выполнению работы и из
        действий Подрядчика следует намерение выполнить Работу в срок,
        согласованный Заказчиком и Подрядчиком.
      </p>
      <p>
        6.4. После получения обращения Платформа должна принять меры для связи с
        Заказчиком и Подрядчиком (по усмотрению Платформы: посредством
        Защищенных страниц платформы ПИРС, уведомлений на Адрес электронной
        почты или иным доступным Платформе способом), выяснить, какие
        разногласия относительно Работы имеются между ними, принять на свое
        усмотрение меры по мирному урегулированию возникших разногласий.
        Продолжительность установленной в настоящем Договоре процедуры мирного
        урегулирования разногласий между Заказчиком и Подрядчиком определяется
        Платформой для каждой конкретной ситуации, но не может быть дольше, чем
        5 (пять) календарных дней с момента получения Платформой обращения.
      </p>
      <p>
        6.5. В случае если меры, предпринятые в соответствии с п.6.4 Договора,
        не приведут к мирному урегулированию разногласий между Заказчиком и
        Подрядчиком относительно Работы, Платформа в срок, не превышающий 30
        (тридцати) календарных дней с момента получения обращения, проводит
        независимый анализ соответствия выполненной и переданной Заказчику
        Работы Договору подряда и Техническому заданию. Проводя такой анализ,
        Платформа действует не в качестве оценщика или эксперта, а исключительно
        в качестве независимой стороны, которая на основании настоящего Договора
        уполномочена осуществить такой анализ, руководствуясь собственным
        независимым убеждением и усмотрением. При этом Платформа не является
        представителем ни Заказчика, ни Подрядчика. По желанию любой из сторон
        Платформа вправе привлечь за счет стороны, изъявившей желание для
        урегулирования разногласий независимого эксперта либо медиатора.
      </p>
      <p>
        6.6. Предметом анализа является результат Работы, переданный Подрядчиком
        в соответствии с п. 7.1 настоящего Договора. При анализе результата
        Работы Платформа руководствуется условиями настоящего Договора, Договора
        подряда и Технического задания, в том числе согласованным сроком
        выполнения Работы. При анализе Работы принимаются во внимание изменения,
        внесенные в Техническое задание, а также доступная переписка на
        Защищенных страницах платформы ПИРС Заказчика и Подрядчика в связи с
        выполнением Работы. Переписку, а также иные договоренности, совершенные
        вне Защищенных страниц платформы ПИРС, Платформа не учитывает. При этом
        при анализе результата Работы Платформа может также учитывать
        профессиональный уровень Подрядчика на основе демонстрируемых им работ
        во вкладке «Портфолио» на личной странице Подрядчика на платформе ПИРС
        (в частности, при помощи которых Платформа устанавливает, на какой
        профессиональный уровень выполнения Работы Заказчик мог рассчитывать при
        заключении Договора подряда и настоящего Договора).
      </p>
      <p>
        6.6.1. Платформа не рассматривает и не принимает во внимание материалы и
        файлы, в которые были внесены изменения после истечения срока выполнения
        Работы, согласованного Заказчиком и Подрядчиком, за исключением случаев,
        когда внесение таких изменений было согласовано между Заказчиком и
        Подрядчиком посредством Защищенных страниц платформы ПИРС. Платформа не
        рассматривает и не принимает во внимание материалы и файлы, в отношении
        которых невозможно получить достоверные сведения о том, что после
        истечения срока выполнения Работы, согласованного в Техническом задании,
        в них не вносились изменения.
      </p>
      <p>
        6.6.2. В случае, когда материалы и файлы не могут быть приняты во
        внимание на основании п. 6.6.1 настоящего Договора, а иных материалов,
        подтверждающих надлежащее выполнение Работы, не имеется, Платформа
        выносит решение, предусмотренное п. 6.9.3 настоящего Договора.
      </p>
      <p>
        6.7. Заказчик и Подрядчик обязуются предоставлять Платформе результат
        выполненной Работы для проведения анализа, предоставлять иные
        необходимые сведения и материалы, необходимые для анализа соответствия
        Работы Договору подряда и Техническому заданию. При анализе используются
        материалы как предоставленные Заказчиком, так и Подрядчиком. Заказчик и
        Подрядчик самостоятельно несут все риски непредоставления Платформе
        необходимых материалов
      </p>
      <p>
        6.8. В случае если Заказчиком и Подрядчиком, по мнению Платформы, не
        представлены все необходимые материалы и сведения для принятия
        Платформой одного из решений, указанного в п. 6.9 Договора, обращение
        считается несделанным, за исключением случаев, предусмотренных п. 6.6.2
        Договора.
      </p>
      <p>
        6.9. По результатам рассмотрения обращения и проведения анализа
        соответствия результата Работы Договору подряда и Техническому заданию
        Платформа по своему собственному независимому усмотрению и убеждению
        принимает одно из следующих решений:
      </p>
      <p>
        6.9.1. Работа выполнена надлежащим образом и в срок в соответствии с
        Договор подрядам и Техническим заданием;
      </p>
      <p>
        6.9.2. Результат работы частично соответствует Договору подряда и
        Техническому заданию и представлен Заказчику в срок, определенный в
        Техническом задании. В таком случае Платформа обязуется указать
        процентное соотношение, в котором представленный результат Работы
        соответствует Договору подряда и Техническому заданию;
      </p>
      <p>
        6.9.3. Работа выполнена ненадлежащим образом и/или с нарушением срока,
        предусмотренного Договором подряда и Техническим заданием.
      </p>
      <p>
        6.10. Принятие одного из указанных в п. 6.9 Договора решений является
        обязанностью Платформы в соответствии с условиями настоящего Договора.
        Принятие одного из указанных решений Платформой является окончательным
        для целей настоящего Договора и не может быть повторно пересмотрено в
        порядке раздела 6 настоящего Договора. Заказчик и Подрядчик, заключая
        настоящий Договор, выражают свое безоговорочное согласие с любым
        решением Платформы.
      </p>
      <p>
        6.11. О проделанном Платформой независимом анализе на соответствие
        результата Работы Договору подряда и Техническому заданию и принятом в
        результате такого анализа решении Платформа информирует Заказчика и
        Подрядчика посредством отчета о рассмотрении обращения. Данный отчет
        предоставляется Платформой Заказчику и Подрядчику через Защищенные
        страницы Сайта.
      </p>
      <p>
        6.12. Заключая настоящий Договор, Заказчик и Подрядчик тем самым дают
        свое безусловное согласие на то, что решение, принятое по результатам
        рассмотрения обращения Заказчика и (или) Подрядчика, которое было
        подготовлено Платформой в исполнение его обязательства по настоящему
        Договору, является обязательным для Заказчика и Подрядчика.
      </p>
      <p>
        6.13. Решение Платформы, указанное в п. 6.9.1. настоящего Договора,
        является основанием для Осуществления выплаты Стоимости Работы
        Подрядчику в порядке, установленном в п. 6.3 Договора.
      </p>
      <p>
        6.14. Решение Платформы, указанное в п. 6.9.2. настоящего Договора,
        является основанием для соразмерного уменьшения Стоимости Работы (о чем
        формируется уведомление сторонам) и Осуществления выплаты соразмерно
        уменьшенной Стоимости Работы Подрядчику в порядке, установленном в п.п.
        4.2 и 4.4. Договора. Платформа возвращает Заказчику оставшуюся часть
        Обеспечительного платежа в порядке, установленном в разделе 6 Договора.
      </p>
      <p>
        6.15. Решение Платформы, указанное в п. 6.9.3. настоящего Договора,
        является основанием для Осуществления возврата Обеспечительного платежа
        полностью Заказчику в порядке, установленном в разделе 6 Договора.
      </p>
      <p>
        6.16. Стороны настоящего Договора безоговорочно исходят из того, что тот
        факт, что Обеспечительный платеж и Вознаграждение Платформы поступает к
        Платформе от Заказчика и в случаях, предусмотренных Договором,
        установлена обязанность Платформы осуществить выплату Стоимости Работы
        Подрядчику, не препятствует независимому и объективному рассмотрению
        обращений Заказчика и Подрядчика.
      </p>
      <p>
        6.17. Обращение к Платформе в порядке, предусмотренном разделом 6
        Договора, не исключает возможности для Заказчика или Подрядчика
        обратиться в компетентный суд для разрешения любых неурегулированных
        спорных вопросов в связи с Договором подряда и настоящим Договором без
        привлечения к этому Платформы, при этом подобное обращение Заказчика или
        Подрядчика в компетентный суд никак не будет влиять на действительность
        отчета Платформы о рассмотрении обращения. Анализ соответствия
        результата Работы, осуществляемый Платформой, имеет значение
        исключительно для целей настоящего Договора и не может быть использован
        в каких-либо иных целях.
      </p>
      <p>
        6.18. Односторонний отказ Заказчика от исполнения Договора подряда,
        расторжение Договора подряда
      </p>
      <p>
        6.18.1 Заказчик вправе направить Подрядчику посредством защищенных
        страниц Платформы уведомление об одностороннем отказе от исполнения
        Договора подряда. Такое уведомление направляется в соответствии с
        положениями статьей 715, 717 Гражданского кодекса РФ и в соответствии с
        условиями Платформы, предусмотренными для направления данных
        уведомлений.
      </p>
      <p>
        6.18.2 В случае, если уведомление направлено Заказчиком в соответствии с
        положениями статьи 715 ГК РФ, такое уведомление подлежит направлению в
        личный кабинет Подрядчика и по адресу электронной почты Подрядчика,
        указанному при регистрации на Платформе (если в последующем адрес
        электронной почты изменен Подрядчиком, уведомление направляет по
        актуальному адресу, указанному Подрядчиком в личном кабинете).
      </p>
      <p>
        6.18.3. Срок рассмотрения Подрядчиком уведомления, направленного
        Заказчиком в соответствии с пунктом 6.18.2, составляет 10 рабочих дней.
        Если по истечении 10 рабочих дней со дня поступления в личный кабинет и
        на адрес электронной почты Подрядчика, последний не направил
        мотивированных возражений относительно поступившего уведомления (в том
        числе, если Подрядчик не ознакомился в течение указанного срока с
        поступившим уведомлением) Договор подряда является расторгнутым с даты
        истечения десятидневного срока, предусмотренного для рассмотрения такого
        уведомления, а Подрядчик снятым с выполнения работы.
      </p>
      <p>
        6.18.4. В случае, если уведомление направлено Заказчиком в соответствии
        с положениями статьи 717 ГК РФ, Заказчик может сформировать к данному
        уведомлению дополнительное соглашение об уменьшении стоимости работ (в
        случае, если часть работ является пригодной к дальнейшему использованию
        и принимается Заказчиком).
      </p>
      <p>
        6.18.5. Срок рассмотрения Подрядчиком уведомления, направленного
        Заказчиком в соответствии с пунктом 6.18.4, составляет 10 рабочих дней.
        Если по истечении 10 рабочих дней со дня поступления в личный кабинет и
        на адрес электронной почты Подрядчика, последний не направил
        мотивированных возражений относительно поступившего уведомления (в том
        числе, если Подрядчик не ознакомился в течение указанного срока с
        поступившим уведомлением) Договор подряда является расторгнутым с даты
        истечения десятидневного срока, предусмотренного для рассмотрения такого
        уведомления, а Подрядчик снятым с выполнения работы. Стоимость части
        работ (в случае, если было сформировано дополнительное соглашение) по
        согласованию с Заказчиком перечисляется Платформой в кошелек Подрядчика.
      </p>
      <p>
        Мотивированные возражения в соответствии с настоящим пунктом могут быть
        направлены Подрядчиком исключительно в части объема принимаемых
        Заказчиком работ и их стоимости.
      </p>
      <p>
        6.18.6. В случае, если Заказчиком сформировано соглашение о расторжении
        Договора подряда (применяется при расторжении Договора подряда по
        соглашению сторон), такое соглашение подлежит направлению в личный
        кабинет Подрядчика и по адресу электронной почты Подрядчика, указанному
        при регистрации на Платформе (если в последующем адрес электронной почты
        изменен Подрядчиком, уведомление направляет по актуальному адресу,
        указанному Подрядчиком в личном кабинете).
      </p>
      <p>
        6.18.7. Срок рассмотрения Подрядчиком соглашения, указанного в пункте
        6.18.6 составляет 5 рабочих дней со дня поступления в личный кабинет и
        на адрес электронной почты Подрядчика. В случае, если в указанный срок
        соглашение о расторжении не подписано Подрядчиком, соглашение является
        аннулированным, о чем Заказчику Платформой направляется уведомление, в
        котором также указывается возможность направить Подрядчику уведомление в
        соответствии с пунктом 6.18.1.
      </p>
      <strong>7. Возврат Обеспечительного платежа Заказчику</strong>
      <p>
        7.1. В случаях, предусмотренных п.п. 6.9.3., 6.15; 6.9.2., 6.14.;
        11.23-11.26 настоящего Договора, Платформа обязуется возвратить
        Заказчику Обеспечительный платеж отраженный в личном кабинете Заказчика
        или соответствующую его часть по его требованию в течение тридцати
        рабочих дней. Сумма Вознаграждения Платформы в таком случае не
        изменяется и не возвращается.
      </p>
      <p>
        7.2. Осуществление возврата Обеспечительного платежа (его части)
        Заказчику с лицевого счета Заказчика производится тем же Способом
        оплаты, которым был оплачен Обеспечительный платеж, и по тем же
        реквизитам банковского счета или учетной записи в платежной системе, с
        которого были переведены денежные суммы Платформе. На сумму, подлежащую
        к возврату, проценты по правилам ст. 317, 395 ГК РФ не начисляются.
      </p>
      <p>
        7.3. В случае, если возврат Заказчику Обеспечительного платежа (его
        части) по реквизитам, указанным в п. 8.2. настоящего Договора, по
        каким-либо причинам невозможен, Заказчик обязан сообщить Платформе новые
        реквизиты банковского счета или учетной записи в платежной системе, по
        которым возврат Обеспечительного платежа (его части) возможен. Платформа
        не несет ответственности за несвоевременный возврат Обеспечительного
        платежа (его части), если Заказчик несвоевременно сообщил или не сообщил
        новые реквизиты. Платформа обязуется возвратить Обеспечительный платеж
        (его часть) в течение тридцати рабочих дней с момента представления
        Заказчиком новых реквизитов банковского счета или учетной записи в
        платежной системе, по которым возврат Обеспечительного платежа (ее
        части) возможен.
      </p>
      <strong>8. Вознаграждение Платформы</strong>
      <p>
        8.1. Заказчик обязуется перечислить Платформе в полном размере
        определенный в Техническом задании Обеспечительный платеж, включающий в
        себя также соответствующую сумму Вознаграждения Платформы.
      </p>
      <p>
        8.2. Вознаграждение Платформы включает в себя в том числе компенсацию
        расходов, понесенных при оказании услуг, предусмотренных в п. 2.2.
        настоящего Договора.
      </p>
      <p>
        8.3. Вознаграждение Платформы определяется в размере 15% стоимости работ
        Договора подряда – с Заказчика и включает в себя сумму налога. Общий
        размер вознаграждения Платформы ПИРС составляет 15% от суммы сделки.
        Стоимость работ, публикуемая Заказчиком при размещении Заказа,
        подразумевает обязательный размер 15 % удерживаемый Платформой ПИРС,
        акцепт оферты Заказчика Подрядчиком подразумевает получение Подрядчиком
        стоимости работ из Обеспечительного платежа.
      </p>
      <p>
        8.4. Размер вознаграждения может быть изменен Платформой в одностороннем
        порядке без специального уведомления других Сторон. Новый размер
        вознаграждения вступает в силу в течение 3 (трех) календарных дней с
        момента опубликования на платформе ПИРС. Применение нового размера
        Вознаграждения к правоотношениям, возникшим до вступления в силу нового
        размера Вознаграждения, не допускается. Заказчик и Подрядчик обязуются
        самостоятельно следить за изменениями размера Вознаграждения и
        своевременно знакомиться с ними.
      </p>
      <p>
        8.5. Платформа вправе проводить различные мероприятия, предполагающие
        временное уменьшение Вознаграждения Платформы. Информация о проводимом
        мероприятии размещается на открытых и/или закрытых страницах Сайта (в
        зависимости от характера проводимого мероприятия, круга лиц, которому
        предлагается участие в мероприятии). Проведение мероприятия ограничено
        временным интервалом, указанным в соответствующем уведомлении,
        размещенном на открытых и/или закрытых страницах Сайта.
      </p>
      <strong>9. Порядок оформления Акта и Отчета об оказанных услугах</strong>
      <p>
        9.1. Услуги Платформы, перечисленные в п. 2.2. настоящего Договора,
        считаются оказанными надлежащим образом в момент Осуществления выплаты
        Стоимости Работы Подрядчику, либо возврата Обеспечительного платежа
        Заказчику, если иное не предусмотрено Договором. Услуги Платформы,
        перечисленные в п. 2.2. настоящего Договора, считаются оказанными
        надлежащим образом в момент вынесения Платформой решений,
        предусмотренных в п.п. 11.23-11.26 Договора.
      </p>
      <p>
        9.2. В случае, когда в результате рассмотрения обращения Заказчика и
        (или) Подрядчика Платформа в порядке раздела 6 Договора приняла решение,
        указанное в п. 6.9.2 Договора, услуги Платформы считаются оказанными в
        наиболее позднюю из следующих дат:
      </p>
      <p>
        9.2.1. В момент Осуществления зачисления на лицевой счет в личном
        кабинете Подрядчика соразмерно уменьшенной суммы Стоимости Работы
        Подрядчику в порядке, установленном в п. 4.2, 4.4. Договора;
      </p>
      <p>
        9.2.2. В момент возврата Заказчику оставшейся части Обеспечительного
        платежа в порядке, установленном разделом 8 Договора.
      </p>
      <p>
        9.3. В случае, когда Осуществление выплаты Стоимости Работы (в том числе
        соразмерно уменьшенной) Подрядчику невозможно по причине непредставления
        Подрядчиком корректных сведений о своем счете или учетной записи, в
        соответствии с п. 5.8. Договора Платформа направляет уведомление об этом
        на Адрес электронной почты Подрядчика трижды в течение сорока пяти
        календарных дней с момента первой попытки Осуществления выплаты
        Стоимости Работы (в том числе соразмерно уменьшенной). Если по истечении
        этого срока сведения о новом счете или учетной записи не были
        предоставлены Подрядчиком, услуги Платформы, указанные в п. 3.2.
        настоящего Договора, считаются надлежащим образом оказанными.
      </p>
      <p>
        9.4. В случае, когда возврат Обеспечительного платежа (ее части)
        Заказчику является невозможным по причине непредставления Заказчиком
        корректной информации о своем счете или учетной записи, Платформа
        направляет уведомление об этом Заказчику на Адрес электронной почты
        трижды в течение 10 календарных дней с момента первой попытки возврата
        Обеспечительного платежа (ее части). Если по истечении этого срока
        сведения о новом счете или учетной записи не были предоставлены
        Заказчиком, услуги Платформы, указанные в п. 2.2. настоящего Договора,
        считаются надлежащим образом оказанными.
      </p>
      <p>
        9.5. Платформа обязуется представить Заказчику Акт (Акты) об оказанных
        услугах и Отчет Агента по настоящему Договору. Платформа вправе
        выставить единый Акт по услугам, предусмотренным в п.п. 2.2.1-2.2.3
        настоящего Договора. Акт и Отчет представляются в течение десяти рабочих
        дней с момента оказания услуг в соответствии с п. 9.1.- 9.4 настоящего
        Договора.
      </p>
      <p>
        9.6. Акт (Акты) об оказанных услугах и Отчет Агента представляются в
        электронной форме на Защищенных страницах платформы ПИРС.
      </p>
      <p>
        9.7. Заказчик обязуется ознакомиться с Актами об оказанных услугах и
        Отчетом Агента и утвердить его посредством программно-технических
        средств платформы ПИРС. Заказчик, имеющий возражения по Акту (Актам) или
        Отчету, должен сообщить о них Платформе в течение двух календарных дней
        со дня получения Акта (Актов) и Отчета. При отсутствии возражений со
        стороны Заказчик в указанный срок Акт (Акты) и Отчет Агента считаются
        принятыми.
      </p>
      <p>
        9.8. Акт об оказании услуг и Отчет Агента может быть направлен в
        письменной (бумажной) форме посредством почтовой связи по запросу
        Заказчика.
      </p>
      <strong>10. Ответственность</strong>
      <p>
        10.1. За неисполнение или ненадлежащее исполнение обязательств по
        Договору Стороны несут ответственность в соответствии с действующим
        законодательством Российской Федерации и Договором.
      </p>
      <p>
        10.2. Платформа несет ответственность по Договору исключительно в случае
        наличия умысла на нарушение принятых на себя обязательств. С учетом ст.
        400 Гражданского кодекса РФ ответственность Платформы по настоящему
        Договору ограничена размером Обеспечительного платежа, при этом
        упущенная выгода любой из сторон Платформой не возмещается. Платформа не
        несет ответственности за принятие на основе независимого анализа любого
        решения (или его последствия) в результате рассмотрения обращений и
        подготовки отчета о рассмотрении обращения в порядке, предусмотренном
        разделом 6 Договора, а также при вынесении решений, предусмотренных п.п.
        11.23-11.26 Договора.
      </p>
      <p>
        10.3. Стороны подтверждают и признают, что любые меры, предпринятые
        каким-либо компетентным судом, уполномоченным органом власти или третьим
        лицом в отношении Платформы, могут создать препятствия последнему в
        исполнении своих обязательств по настоящему Договору, и будут являться
        обстоятельством, освобождающим Платформу от ответственности за просрочку
        в исполнении, в том числе в отношении несвоевременного Осуществления
        выплаты Стоимости Работы (в том числе соразмерно уменьшенной) Подрядчику
        и/или возврата Зарезервированной суммы Заказчику, в течение всего такого
        периода времени, пока указанные меры остаются в силе.
      </p>
      <p>
        10.4. Сторона освобождается от ответственности за частичное или полное
        неисполнение обязательств по настоящему Договору, если это неисполнение
        явилось следствием обстоятельств непреодолимой силы (форс-мажор),
        возникших в результате обстоятельств чрезвычайного характера, которые
        Сторона не могла ни предвидеть, ни предотвратить разумными мерами. К
        таким обстоятельствам чрезвычайного характера относятся: стихийные
        бедствия, аварии, наводнения, землетрясения, эпидемии (пандемии),
        введения режима повышенной готовности в связи с угрозой распространения
        опасного заболевания пожары, массовые беспорядки, забастовки, революции,
        военные действия, вступление в силу законодательных актов,
        правительственных постановлений и распоряжений государственных органов,
        прямо или косвенно запрещающих указанные в настоящем Договоре виды
        деятельности или иным образом препятствующих исполнению настоящего
        Договора; обстоятельства, связанные с отказом/сбоем работы системы
        расчетов Банка России, телекоммуникационные сбои всеобщего характера, а
        также любые другие обстоятельства вне разумного контроля Сторон,
        препятствующие исполнению обязательств по настоящему Договору.
      </p>
      <p>
        10.5. Платформа не несет ответственности за соответствие применимому
        праву Работы, согласованной Заказчиком и Подрядчиком, и результата такой
        Работы.
      </p>
      <p>
        10.6. В случае, когда Самозанятый Подрядчик самостоятельно, уже после
        формирования чека и его передачи Заказчику проведет корректировку ранее
        переданных налоговому органу сведений о сумме расчетов в сторону их
        уменьшения на сумму, составляющую Стоимость Работы или соразмерно
        уменьшенную в соответствии с настоящим Договором Стоимость Работы, или
        какую-либо их часть; или иным образом аннулирует полностью или частично
        ранее сформированный чек, Самозанятый Подрядчик обязан по требованию
        Заказчика возвратить последнему сумму, на которую была произведена
        корректировка.
      </p>
      <p>
        10.7. В случае, когда Самозанятый Подрядчик самостоятельно, уже после
        формирования чека и передачи его Заказчику проведет корректировку ранее
        переданных налоговому органу сведений о сумме расчетов в сторону их
        уменьшения на сумму, составляющую Стоимость Работы или соразмерно
        уменьшенную в соответствии с настоящим Договором Стоимость Работы, или
        какую-либо их часть; или иным образом аннулирует полностью или частично
        ранее сформированный чек; или другим способом нарушит обязанности и
        (или) злоупотребит правами, предусмотренными положениями Федерального
        закона от 27.11.2018 N 422-ФЗ и настоящим Договором, Платформа вправе
        немедленно заблокировать доступ Подрядчика к Защищенным страницам
        платформе ПИРС, а также вправе расторгнуть договоры об использовании
        онлайн сервиса «Безопасная Сделка», заключенные таким Подрядчиком с
        другими заказчиками на платформе ПИРС.
      </p>
      <p>
        10.8. Заключая настоящий Договор, Самозанятый Подрядчик выражает свое
        безоговорочное согласие на сообщение Заказчиком или Платформе в ФНС
        России о нарушении или злоупотреблении, допущенным со стороны
        Самозанятого Подрядчика, с предоставлением имеющихся сведений об
        Самозанятом Подрядчике, а также обстоятельств исполнения настоящего
        Договора и Договора подряда.
      </p>
      <p>
        10.9. Платформа вправе разблокировать доступ Самозанятого Подрядчика к
        Защищенным страницам платформе ПИРС в следующих случаях:
      </p>
      <p>
        10.9.1. при наличии соответствующего сообщения Заказчика, в котором
        последний изъявляет свое согласие с корректировкой сведений о сумме
        расчетов, произведенной Самозанятым Подрядчиком, или
      </p>
      <p>
        10.9.2. в случае, когда Самозанятый Подрядчик, ошибочно аннулировавший
        чек, повторно сформирует чек, направит его в ФНС России и передаст его
        Заказчику;
      </p>
      <p>
        10.9.3. в случае, когда Самозанятый Подрядчик, который своевременно не
        исполнил свои обязанности, предусмотренные положениями Федерального
        закона от 27.11.2018 N 422-ФЗ, в том числе не сформировал и (или) не
        передал чек Заказчику, исполнит в разумный срок названные обязанности.
      </p>
      <p>
        10.10. Платформа не несет имущественной ответственности за действия
        Самозанятого Подрядчика, указанные в п. 10.7 Договора. Платформа не
        несет ответственности перед Сторонами за реализацию своих прав,
        предусмотренных в пунктах 11.7-11.9 настоящего Договора.
      </p>
      <p>
        10.11. Платформа не несет имущественной ответственности перед Сторонами
        Договора при реализации им положений, предусмотренных в п. 3.6
        настоящего Договора.
      </p>
      <strong>11. Заключение, изменение и расторжение Договора</strong>
      <p>
        11.1. Настоящий Договор заключается путем акцепта Оферты со стороны
        Заказчика и Подрядчика.
      </p>
      <p>
        11.2. Акцептом Оферты со стороны Заказчика признается выполнение всех
        условий:
      </p>
      <p>
        11.2.1. Регистрация в качестве Заказчика на платформе ПИРС или получение
        статус «Заказчик» в связи со своими действиями по созданию проекта,
        путем нажатия кнопки «Создать проект» дает возможность размещать заказы
        на выполнение соответствующих работ на Сайте путем заполнения бланка
        заказа.
      </p>
      <p>
        11.2.2. Открытие «Безопасной сделки» на Защищенных страницах с помощью
        программно-технических средств платформы ПИРС и направление Подрядчику
        приглашения вступить в «Безопасную сделку» путем нажатия кнопки
        «Выбрать»;
      </p>
      <p>
        11.2.3. Согласование с Подрядчиком Технического задания с помощью
        программно-технических средств платформы ПИРС;
      </p>
      <p>
        11.2.4. Нажатие размещенной на Защищенных страницах платформы ПИРС
        кнопки «Выбрать».
      </p>
      <p>
        11.2.5. Обеспечение до заключения договора Подряда внесения
        Обеспечительного платежа, равного Стоимости Работы, включая сумму
        Вознаграждения Платформе в соответствии с п. 10.3 Договора.
      </p>
      <p>
        11.3. Перечисление платформе иной суммы, кроме той, что определена в п.
        12.2.5 настоящего Договора, не признается акцептом настоящей Оферты.
      </p>
      <p>
        11.4. Акцептом Оферты со стороны Подрядчика признается одновременное
        выполнение следующих условий:
      </p>
      <p>
        11.4.1. Регистрация в качестве ГИП, Проектировщика, Поставщика,
        Исполнителя или иного участника на Платформе ПИРС;
      </p>
      <p>
        11.4.2. Вступление в «Безопасную сделку» на Защищенных страницах
        платформы ПИРС;
      </p>
      <p>
        11.4.3. Согласование с Заказчиком Технического задания с помощью
        программно-технических средств платформы ПИРС
      </p>
      <p>
        11.4.4. Нажатие на размещенную на Защищенных страницах платформы ПИРС
        кнопку «Выбрать».
      </p>
      <p>
        11.5. Акцепт Оферты возможен только Заказчиком и Подрядчиком, которые
        заключили Договор подряда при помощи платформы ПИРС и оформили
        Техническое задание. Акцепт Оферты возможен только Заказчиком и
        Подрядчиком, которые являются сторонами одного и того же Договора
        подряда, при этом акцепт Оферты возможен только в отношении этого же
        Договора подряда. Акцепт Оферты возможен только Заказчиком и
        Подрядчиком, в отношении которых не установлены в соответствии с
        правилами платформы ПИРС и Пользовательским соглашением платформы ПИРС
        ограничения на использование онлайн сервиса «Безопасная сделка».
      </p>
      <p>
        11.6. Договор считается заключенным в момент, когда и Заказчик, и
        Подрядчик акцептовали настоящую Оферту.
      </p>
      <p>
        11.7. Нажатие кнопок «Создать проект», «Выбрать», «Подписать» ,
        «Принять» и других рассматривается Сторонами как Аналог собственноручной
        подписи Заказчика. Технические средства платформы ПИРС позволяют
        идентифицировать нажатие указанной кнопки, совершенное под Логином
        Заказчика или Подрядчика. Сведения о нажатии указанной кнопки
        сохраняются при помощи программно-технических средств платформы ПИРС.
      </p>
      <p>
        11.8. Платформа уведомляет Заказчика и Подрядчика о нажатии другой
        Стороной соответствующей кнопки, размещенной на Защищенных страницах
        платформы ПИРС. Такое уведомление может быть сделано через Защищенные
        страницы платформы ПИРС.
      </p>
      <p>
        11.9. При обоюдном акцепте Оферты Заказчиком и Подрядчиком Платформа
        посредством программно-технических средств платформы ПИРС присваивает
        Договору внутренний номер и дату, которые незамедлительно доводятся до
        других Сторон посредством программно-технических средств платформы ПИРС
        через Защищенные страницы платформы ПИРС и/или иным доступным способом.
      </p>
      <p>
        11.10. По запросу Заказчика или Подрядчика, в целях подтверждения
        наличия заключенного между Сторонами Договора, Платформа со своей
        стороны может подписать настоящий Договор, указать в нем дату и номер
        Договора, которые были ему присвоены в соответствии с п. 11.9 Договора,
        наименование Заказчика и Подрядчика, акцептовавших настоящую Оферту. При
        этом Платформа, используя базы данных платформы ПИРС, может подтвердить
        в подписанном со своей стороны Договоре факт того, что Заказчик или
        Подрядчик акцептовал настоящую Оферту с помощью программно-технических
        средств Сайта. Такое подтверждение признается Сторонами надлежащим
        доказательством факта заключения настоящего Договора со стороны
        Заказчика и Подрядчика.
      </p>
      <p>
        11.11. Подтверждая в соответствии с п. 11.10 Договора факт акцепта
        настоящей Оферты Заказчиком и (или) Подрядчиком и факт заключения
        настоящего Договора, Платформа не является представителем, поверенным,
        агентом или комиссионером Заказчика или Подрядчика, а выступает
        исключительно в качестве лица, предоставляющего доказательства
        заключения с помощью программно-технических средств платформы ПИРС
        настоящего Договора между Платформы, Заказчиком и Подрядчиком.
      </p>
      <p>
        11.12. Экземпляр Договора на электронном носителе, предоставляемый
        Заказчику или Подрядчику согласно п. 11.10 Договора, по усмотрению
        Платформы может быть подписан с использованием простой электронной
        подписи, о чем в электронном виде договора формируется соответствующая
        отметка.
      </p>
      <p>
        11.13. Настоящий Договор может быть изменен только по заявлению обеих
        Сторон, а также в одностороннем порядке Платформой. Об изменении
        Договора Платформа обязана уведомить Заказчика и Подрядчика. Договор
        считается измененным с момента такого уведомления.
      </p>
      <p>
        11.14. Настоящий Договор может быть расторгнут по согласию всех сторон
        Договора, по взаимному согласию Заказчика и Подрядчика или по инициативе
        одной из Сторон в случаях и в порядке, предусмотренном настоящим
        Договором.
      </p>
      <p>
        11.15. Платформа вправе в любое время расторгнуть Договор в
        одностороннем внесудебном порядке, предварительно уведомив об этом
        Заказчика и Подрядчика не позднее, чем за два календарных дня до даты
        предполагаемого расторжения Договора. Такое уведомление может быть
        сделано через Защищенные страницы Платформы. При расторжении договора
        Платформа обязана возвратить Заказчику Обеспечительный платеж за минусом
        стоимости соразмерно оказанных Платформой услуг. В случае, когда
        Самозанятый Подрядчик, заключив настоящий Договор или иной договор об
        использовании онлайн сервиса «Безопасная Сделка» с другими заказчиками
        на Платформе, самостоятельно, уже после формирования чека и передачи его
        Заказчику проведет корректировку ранее переданных налоговому органу
        сведений о сумме расчетов в сторону их уменьшения на сумму, составляющую
        Стоимость Работы или соразмерно уменьшенную в соответствии с настоящим
        Договором Стоимость Работы, или какую-либо их часть, или иным образом
        аннулирует полностью или частично ранее сформированный чек, или другим
        способом нарушит обязанности или злоупотребит правами, предусмотренными
        положениями Федерального закона от 27.11.2018 N 422-ФЗ, Платформа вправе
        расторгнуть настоящий Договор. Договор считается расторгнутым в момент
        направления такого сообщения самозанятому Подрядчику.
      </p>
      <p>
        11.16. Заказчик вправе расторгнуть настоящий Договор, если в порядке,
        предусмотренном настоящим разделом Договора, Платформой подтверждено,
        что на момент выражения такого намерения Заказчиком Подрядчик фактически
        не приступил к выполнению Работы.
      </p>
      <p>
        11.17. Заявление Заказчика о намерении расторгнуть Договор считается
        сделанным в случае, когда Заказчик с помощью программно-технических
        средств Платформы нажал на Защищенных страницах Сайта кнопку «Запросить
        снятие исполнителя» и указал в качестве причины обращения на свое
        намерение расторгнуть Договор и получить обратно от Платформы
        Зарезервированную сумму. При невыполнении условий, предусмотренных
        настоящим пунктом, намерение Заказчика не считается заявленным.
      </p>
      <p>
        11.18. С момента нажатия Заказчиком на Защищенных страницах Сайта кнопки
        “Запросить снятие исполнителя” Подрядчик считается уведомленным о
        намерении Заказчика. Будучи уведомленным, Подрядчик не вправе начинать
        выполнение Работы, если на момент направления уведомления Подрядчик еще
        не приступил к выполнению Работы.
      </p>
      <p>
        11.19. После нажатия Заказчиком на Защищенных страницах Сайта кнопки
        “Запросить снятие исполнителя” Платформа в порядке, предусмотренном п.
        6.4 настоящего Договора, принимает необходимые меры (по усмотрению
        Платформы: посредством Защищенных страниц Сайта, направления уведомлений
        в личном кабинете на Сайте и/или на Адрес электронной почты или иным
        доступным Платформе способом) для связи с Подрядчиком для установления
        факта начала производства работ Подрядчиком. При неполучении ответа от
        Подрядчика, последний считается не приступившим к Работе.
      </p>
      <p>
        11.20. Стороны безоговорочно признают право Платформы запрашивать у
        Заказчика и Подрядчика материалы и информацию, относящиеся к выполнению
        Работы, оценивать объем и время выполнения Работы по своему независимому
        усмотрению и убеждению. Платформа не принимает во внимание то, что было
        сделано Подрядчиком после нажатия Заказчиком на Защищенных страницах
        Платформы ПИРС кнопки “Запросить снятие исполнителя”.
      </p>
      <p>
        11.21. Для того, чтобы установить, приступил ли Подрядчик к выполнению
        Работы до направления Заказчиком уведомления о намерении расторгнуть
        Договор, Платформа проводит исследование и анализ представленных
        материалов и информации в срок, не превышающий 10 (десять) рабочих дней
        с момента получения уведомления от Заказчика о намерении расторгнуть
        Договор. Проводя такой анализ, Платформа действует не в качестве
        оценщика или эксперта, а исключительно в качестве независимой стороны,
        которая на основании настоящего Договора уполномочена осуществить такой
        анализ, руководствуясь собственным независимым усмотрением и убеждением.
        При этом Платформа не является представителем Заказчика или Подрядчика.
      </p>
      <p>
        11.22. В случае, когда будет установлено, что Подрядчик приступил к
        выполнению Работы, Договор сохраняет свою силу.
      </p>
      <p>
        11.23. В случае, когда Заказчиком надлежащим образом заявлен отказ от
        Договора, а Подрядчик фактически не приступил к выполнению Работы,
        Платформа по результатам рассмотрения, проведенного в соответствии с п.
        11.21 Договора, принимает решение о расторжении Договора и делает его
        доступным для Заказчика и Подрядчика. Договор считается расторгнутым в
        момент вынесения соответствующего решения Платформой. После вынесения
        решения Платформа делает его доступным для Заказчика и Подрядчика. Если
        иное не согласовано Заказчиком и Подрядчиком в порядке, предусмотренном
        п. 11.25. настоящего Договора, Платформа производит возврат
        Обеспечительного платежа Заказчику в полном размере в порядке,
        предусмотренном разделом 8 настоящего Договора.
      </p>
      <p>
        11.24. Подрядчик вправе выразить намерение отказаться от настоящего
        Договора в любой момент, сообщив об этом Заказчику и Платформе с помощью
        программно-технических средств Сайта путем нажатия на Защищенных
        страницах Сайта кнопки «Отказаться от исполнения». В таком случае
        Платформа выносит решение о расторжении Договора и делает его доступным
        для Заказчика и Подрядчика. Настоящий Договор считается расторгнутым в
        момент вынесения такого решения. Если Сторонами не достигнуто
        соглашение, предусмотренное п. 11.25 настоящего Договора, Платформа
        производит возврат Обеспечительного платежа в полном размере Заказчику в
        порядке, предусмотренном разделом 8 настоящего Договора.
      </p>
      При отказе Подрядчика от настоящего Договора, если между Заказчиком и
      Подрядчиком было достигнута договоренность о распределении
      Обеспечительного платежа между Заказчиком и Подрядчиком, Заказчик и
      Подрядчик вправе самостоятельно, не привлекая Платформу, договориться о
      передаче Заказчику фактически сделанной Работы и ее результата (при
      наличии).
      <p>
        11.25. В случаях, предусмотренных п.п. 11.23 и 11.24 настоящего Договора
        Заказчик и Подрядчик по взаимной договоренности вправе обратиться к
        Платформе через Защищенные страницы с обращением о распределении
        Обеспечительного платежа между Заказчиком и Подрядчиком в соответствии с
        достигнутой договоренностью.
      </p>
      <p>
        11.26. Заказчик и Подрядчик вправе расторгнуть настоящий Договор и
        Договор подряда по обоюдному согласию, заявив о своем намерении
        Платформе путем нажатия на Защищенных страницах платформы ПИРС кнопок
        «Запроситьснятие исполнителя» или «Отказаться от исполнения» и
        представив достигнутый результат работы, если таковой имеется. При этом
        Заказчик и Подрядчик должны прийти к взаимному согласию относительно
        распределения Обеспечительного платежа между Заказчиком и Подрядчиком и
        передачи достигнутого результата Работы, если такой имеется. Если
        Заказчик и Подрядчик не пришли к такому согласию, Платформа по
        независимому усмотрению и убеждению оценивает достигнутый результат
        Работы, принимает во внимание обстоятельства исполнения Договора подряда
        и определяет процентное соотношение, в котором должна быть распределена
        Зарезервированная сумма между Заказчиком и Подрядчиком. Договор
        считается расторгнутым по обоюдному согласию в момент вынесения
        Платформой соответствующего решения.
      </p>
      <p>
        11.27. Положения раздела 6 Договора в части полномочий Платформы по
        рассмотрению обращений Заказчика и Подрядчика применяются к п.п.
        11.16-11.26 настоящего Договора в той мере, в какой нормы раздела 7
        Договора не противоречат положениям раздела 12 Договора.
      </p>
      <p>
        11.28. При толковании настоящего раздела Договора Стороны исходят из
        того, что уже после исполнения своих обязанностей Платформой по выплате
        Обеспечительного платежа (его части) Заказчику и (или) Подрядчику,
        последние могут самостоятельно договориться об ином распределении
        Зарезервированной суммы без привлечения к этому Платформы. Расходы,
        связанные с достижением иной договоренности и перечислением денежных
        сумм, ложатся на Заказчика и (или) Подрядчика в соответствии с
        достигнутой договоренностью.
      </p>
      <p>
        11.29. Расторжение Договора в порядке, предусмотренном п.п 11.23 и 11.24
        Договора, не освобождает Заказчика и Подрядчика от самостоятельного
        исполнения предусмотренных применимым законодательством обязанностей,
        возникших в связи с расторжением Договора.
      </p>
      <strong>12. Применимое право и порядок разрешения споров</strong>
      <p>
        12.1. Договор регулируется и толкуется в соответствии с материальным
        правом Российской Федерации без учета его коллизионных норм.
      </p>
      <p>
        12.2. В случае возникновения любых споров или разногласий, связанных с
        исполнением Договора, Стороны приложат все усилия для их разрешения
        путем переговоров в том числе с привлечением Платформы в порядке,
        предусмотренном настоящим Договором. Стороны обязуются до обращения в
        любые органы, разрешающие споры, предпринять все возможные меры для
        урегулирования любых разногласий в досудебном претензионном порядке в
        течение срока не менее 30 рабочих дней.
      </p>
      <p>
        12.3. Если возникший спор и разногласия не будут разрешены путем
        переговоров, они подлежат разрешению в компетентном суде по месту
        нахождения Заказчика или Платформы.
      </p>
      <strong>13. Конфиденциальность</strong>
      <p>
        13.1. Платформа обязуется соблюдать конфиденциальность в отношении
        персональных данных Заказчика и Подрядчика, а также иной информации о
        Заказчике и Подрядчике, ставшей известной Платформе в связи с настоящим
        Договора, за исключением случаев, когда:
      </p>
      <p>13.1.1. такая информация является общедоступной;</p>
      <p>
        13.1.2. информация раскрыта по требованию или с разрешения Заказчика
        и/или Подрядчика;
      </p>
      <p>
        13.1.3. информация подлежит предоставлению контрагентам в объеме,
        необходимом для исполнения условий Договора и Договора подряда, в
        частности, в случае, установленном п. 5.10 Договора;
      </p>
      <p>
        13.1.4. информация требует раскрытия по основаниям, предусмотренным
        действующим законодательством Российской Федерации, или по вызывающим
        подозрение сделкам, или при поступлении соответствующих запросов суда
        или уполномоченных органов власти.
      </p>
      <p>
        13.2. Стороны принимают на себя обязательства не разглашать полученные в
        ходе исполнения настоящего Договора сведения, являющиеся
        конфиденциальными для каждой из Сторон. Под конфиденциальной информацией
        в настоящем Договоре понимаются не являющиеся общедоступными сведения,
        разглашение которых может привести к возникновению убытков и/или
        повлиять на деловую репутацию любой из Сторон, а именно:
      </p>
      <p>13.2.1. информация о переводах, объемах операций</p>
      <p>13.2.2. информация, составляющая коммерческую и банковскую тайну.</p>
      <p>
        Факт заключения настоящего Договора и предмет Договора не являются
        конфиденциальной информацией.
      </p>
      <p>
        13.3. Стороны обязуются не разглашать указанную в настоящем пункте
        информацию третьим лицам, за исключением ответственных лиц Сторон,
        уполномоченных получать и передавать информацию от имени каждой из
        Сторон в связи с исполнением обязательств по настоящему Договору.
        Указанная информация может быть предоставлена третьим лицам только в
        порядке, установленном действующим законодательством Российской
        Федерации. В случае прекращения действия настоящего Договора, Стороны
        обязуются не разглашать и не использовать в своих интересах и/или в
        интересах третьих лиц информацию, указанную в настоящем пункте, в
        течение 1 (Одного) года с момента прекращения действия настоящего
        Договора.
      </p>
      <p>
        13.4. Сторона, получившая в целях исполнения своих обязательств по
        настоящему Договору конфиденциальную информацию, сведения, составляющие
        коммерческую тайну другой из Сторон, не вправе сообщать эту информацию и
        сведения третьим лицам без письменного разрешения первой Стороны, за
        исключением случаев, установленных законом.
      </p>
      <p>
        13.5. При нарушении обязанности, предусмотренной в п. 13.4 настоящего
        Договора, Платформа, Заказчик и Подрядчик несут ответственность в
        соответствии с нормами законодательства Российской Федерации.
      </p>
      <strong>14. Заключительные положения</strong>
      <p>
        14.1. Заказчик и Подрядчик обязуются не пользоваться правами,
        предусмотренными настоящим Договором и Договором подряда, с
        исключительным или преимущественным намерением причинить вред другой
        Стороне настоящего Договора, а также обязуются не допускать каким-либо
        иным образом злоупотребление правом. При несоблюдении такой обязанности
        Платформа, по своему независимому убеждению оценивая характер и
        последствия допущенного нарушения, вправе продлить сроки на выполнение
        тех или иных действий; отказать в защите права лица, допустившего
        злоупотребление правом; принять решение, прямо не предусмотренное
        настоящим Договором, а также предпринять иные действия для
        предотвращения злоупотребления правом, нивелирования или уменьшения его
        последствий.
      </p>
      <p>
        14.2. Стороны при толковании настоящего Договора исходят из того, что
        настоящий Договор содержит в себе элементы нескольких договоров,
        предусмотренных применимым законодательством, а также предполагает
        способ обеспечения обязательств, прямо не предусмотренный применимым
        законодательством. Стороны также принимают во внимание неразрывность
        связи между Договором и Договор подрядам. В связи с этим ни одно
        положение Договора и Договора Подряда не может толковаться в отдельности
        от других положений указанных документов. При толковании Договора и
        Договора подряда должна приниматься во внимание цель вступления Сторон в
        правовые отношения, обозначенная в преамбуле к настоящему Договору, а
        также следующая из размещенной Платформой на Сайте информации об онлайн
        сервисе “Безопасная сделка”.
      </p>
      <p>
        14.3. Стороны направляют все уведомления, обращения и документы через
        специальные программно-технические средства платформы ПИРС,
        расположенные на Защищенных страницах платформы ПИРС.
      </p>
      <p>
        14.3.1. Некоторые из уведомлений, обращений и документов могут быть
        также направлены Заказчику или Подрядчику по усмотрению Платформы в
        качестве SMS, писем на Адрес электронной почты, кроме того, Платформа
        вправе использовать иные доступные для нее способы связи с Заказчиком
        или Подрядчиком. Заключая настоящий Договор, Заказчик и Подрядчик дают
        согласие на получение таких уведомлений, обращений и документов от
        Платформы.
      </p>
      <p>
        14.3.2. Уведомления и обращения Сторон сохраняются при помощи
        программно-технических средств платформы ПИРС. Стороны соглашаются, что
        такие сообщения, уведомления и документы будут считаться надлежащими для
        целей настоящего Договора (в том числе и в случае, когда было
        использовано факсимильное воспроизведение подписи), приравниваются к
        письменным документам, подписанным уполномоченными представителями
        Сторон, и имеют такое же юридическое значение.
      </p>
      <p>
        14.3.3. Заказчик и Подрядчик безоговорочно соглашаются с тем, что
        указанные в настоящем пункте уведомления, обращения и документы
        считаются полученными в момент их направления адресатам, в связи с чем
        Заказчик и Подрядчик обязуются принимать своевременные меры по
        мониторингу процессов, происходящих в соответствии с настоящим Договором
        и Договор подрядам. Заказчик и Подрядчик самостоятельно несут риски
        наступления неблагоприятных последствий в результате фактического
        непринятия мер по получению от любой другой Стороны Договора информации,
        направленной в соответствии с настоящим Договором и Договор подрядам.
      </p>
      <p>
        14.3.4. Нажатие Заказчиком и (или) Подрядчиком кнопок «Создать проект»,
        «Выбрать», «Принять», «Подписать», «Запросить снятие исполнителя»,
        «Отказаться от исполнения» и иных кнопок в связи с функционированием
        онлайн сервиса «Безопасная сделка» является Аналогами собственноручных
        подписей и обеспечивают проверку подлинности электронного документа и
        удостоверение личности лица, его направившего. Использование Аналога
        собственноручной подписи Заказчика и (или) Подрядчика порождает
        юридические последствия, аналогичные использованию собственноручных
        подписей, в соответствии с требованиями применимого права, и все
        распоряжения, заявления и иные документы, связанные с исполнением
        условий Договора и удостоверенные аналогом собственноручной подписи,
        признаются документами в письменной форме.
      </p>
      <p>
        14.3.5. В случае возникновения разногласий между Сторонами сведения,
        которые зафиксированы при помощи технических средств платформы ПИРС,
        имеют преимущественное значение при разрешении таких разногласий.
        Платформа по запросу заинтересованных лиц и/или уполномоченного органа
        власти предоставляет соответствующие сведения.
      </p>
      <p>
        14.4. Положения настоящего Договора в части, регулирующей отношения
        между Заказчиком и Подрядчиком, дополняют положения Договора подряда и
        считаются включенными в Договор подряда. В случае несоответствия
        положений настоящего Договора и Договора подряда положения настоящего
        Договора будут иметь преимущественную силу над положениями Договора
        подряда в отношениях между Заказчиком и Подрядчиком.
      </p>
      <p>
        14.5. Стороны принимают во внимание и соглашаются, что исполнение,
        неисполнение или ненадлежащее исполнение обязательств по настоящему
        Договору и/или Договору подряда может влиять на статус и/или
        характеристики зарегистрированного на платформе ПИРС лица, через которое
        действует Заказчик и/или Подрядчик, в соответствии с правилами платформы
        ПИРС, сведения об этом могут отражаться и комментироваться на
        соответствующих страницах Платформы ПИРС, использоваться иным образом в
        связи с функционированием платформы ПИРС и ее сервисов.
      </p>
      <p>
        14.6. Стороны подтверждают, что по Договору подряда всех Сторон порядок
        Осуществления выплаты Стоимости Работы (в том числе соразмерно
        уменьшенной) и/или возврата Обеспечительного платежа (его части) может
        быть изменен по сравнению с тем, что предусмотрено настоящим Договором.
        В этом случае Осуществление выплаты Стоимости работы (в том числе
        соразмерно уменьшенной) и (или) возврата Обеспечительного платежа (его
        части) будет производиться в соответствии с договоренностями Сторон.
      </p>
      <p>
        14.7. Заказчик и Подрядчик гарантируют, что пользуются платформой ПИРС в
        соответствии с условиями и правилами его использования, информация о них
        на Защищенных страницах платформы ПИРС отражена правильным и полным
        образом, они действуют под собственными, а не вымышленными именами, все
        действия, совершенные на платформе ПИРС под Логином и Паролем,
        совершаются ими лично или уполномоченными лицами, обязательны и
        юридически действительны для Заказчика и Подрядчика. Стороны обязуются
        сообщать друг другу о любом факте несанкционированного разглашения
        третьим лицам Логина и Пароля.
      </p>
      <p>
        14.8. При заключении настоящего Договора Заказчик и Подрядчик
        предоставляют Платформе свои персональные данные и дают согласие на их
        обработку для целей надлежащего исполнения Платформой ее обязательств по
        настоящему Договору и для целей функционирования Сайта, и заключения
        аналогичных договоров в будущем. При этом нажатие как Заказчиком на
        кнопку «Создать проект», так и Подрядчиком на кнопку «Заявиться»
        рассматривается в качестве Аналога собственноручной подписи Заказчика и
        Подрядчика.
      </p>
      <p>
        14.9. В случае возникновения у Платформы сомнений в достоверности
        данных, указанных Заказчиком или Подрядчиком на Защищенных страницах
        платформы ПИРС и/или предоставленных посредством программно-технических
        средств платформы ПИРС, Платформа имеет право запросить копии
        документов, в том числе нотариально заверенные, позволяющих
        идентифицировать Заказчика и/или Подрядчика (в частности, но, не
        ограничиваясь этим, паспорт физического лица, свидетельство о
        государственной регистрации юридического лица или индивидуального
        предпринимателя и др.). Пока запрашиваемые Платформой документы не будут
        предоставлены, Платформа имеет право не осуществлять выплату Стоимости
        Работы (в том числе соразмерно уменьшенной) Подрядчику и (или) не
        возвращать Обеспечительный платеж Заказчику (его части). Такая задержка
        в исполнении не рассматривается в качестве нарушения Платформой сроков,
        предусмотренных настоящим Договором.
      </p>
      <p>
        14.10. Подрядчик и Заказчик обязуются самостоятельно соблюдать
        законодательство о валютном регулировании и валютном контроле, а также
        таможенное законодательство и обязуются предоставлять все необходимые
        для этого документы и сведения соответствующим компетентным органам и
        организациям, другим Сторонам настоящего Договора.
      </p>
      <p>
        14.11. Платформа вправе использовать созданный Подрядчиком в процессе
        выполнения Работы по Договору подряда результат интеллектуальной
        деятельности и его элементы для их демонстрации на платформе ПИРС, для
        распространения информации о платформе ПИРС на других ресурсах в сети
        Интернет, а также иным образом исключительно в целях рекламы и
        продвижения платформы ПИРС. Дополнительное вознаграждение ни Заказчику,
        ни Подрядчику за это не выплачивается. Через защищенные страницы
        Заказчик и Подрядчик вправе запретить Платформе использовать результат
        интеллектуальной деятельности, созданный Подрядчиком в процессе
        выполнения Работы по Договору подряда.
      </p>
      <p>
        14.12. Все расчеты по настоящему Договору осуществляются исключительно в
        валюте Российской Федерации – в рублях.
      </p>
      <p>
        14.13. Платформа вправе без предварительного уведомления Заказчика и
        Подрядчика приостановить работу платформы ПИРС в случае необходимости
        проведения профилактических работ.
      </p>
      <p>
        14.14. В случае предъявления третьими лицами любых претензий или
        требований к Заказчику и (или) Подрядчику в связи с исполнением
        настоящего Договора и (или) Договора Подряда, Заказчик и (или) Подрядчик
        обязаны незамедлительно сообщить об этом Платформе.
      </p>
      <p>
        14.15. Все изменения к Договору, а также иные Договора подряда между
        Сторонами, переписка между ними, уведомления и обращения осуществляются
        исключительно на русском языке.
      </p>
      <p>
        14.16. По запросу Заказчика или Подрядчика Платформа предоставляет в
        электронном виде заверенную Платформой электронную форму Технического
        задания, переписку Сторон, совершенную на Защищенных страницах, а также
        Отчет о рассмотрении обращения Заказчика и (или) Подрядчика. Документы
        могут быть отправлены Заказчику и (или) Подрядчику посредством
        электронной почтовой связи указанных при регистрации Пользователя на
        Платформе.
      </p>
      <p>
        14.17. По запросу Заказчика Платформа, используя базы данных платформы
        ПИРС, может подтвердить факт заключения Договора подряда между
        Заказчиком и Подрядчиком. В таком случае Платформа направляет Заказчику
        посредством электронной почтовой связи Договор подряда в виде
        электронного документа с отметкой Платформы о том, что Подрядчик с
        помощью программно-технических средств акцептовал Оферту на заключение
        Договора подряда. В случае уклонения или задержки подписания Подрядчиком
        договора подряда электронной подписью такое подтверждение признается
        Сторонами, надлежащим доказательством факта заключения Договора подряда
        между Заказчиком и Подрядчиком.
      </p>
      <p>
        14.18. По запросу Подрядчика Платформа, используя базы данных Сайта,
        может подтвердить факт заключения Договора подряда между Заказчиком и
        Подрядчиком. В таком случае Платформа направляет Подрядчику посредством
        электронной почтовой связи Договор подряда в виде электронного документа
        с отметкой Платформы о том, что Заказчик с помощью
        программно-технических средств заключил Договор подряда. В случае
        уклонения или задержки подписания Заказчиком договора подряда
        электронной подписью, такое подтверждение признается Сторонами,
        надлежащим доказательством факта заключения Договора Подряда между
        Заказчиком и Подрядчиком.
      </p>
      <p>
        14.19. Подтверждая в соответствии с п.п. 14.17 и 14.18 Договора факт
        заключения Договора подряда, Платформа не является представителем,
        поверенным, агентом или комиссионером Заказчика или Подрядчика, а
        выступает исключительно в качестве лица, предоставляющего доказательства
        заключения с помощью программно-технических средств Сайта Договора
        подряда между Заказчиком и Подрядчиком.
      </p>
      <p>
        14.20. Экземпляр документов, предоставляемых Заказчику и Подрядчику
        согласно п. 14.16, 14.17, 14.18 Договора, по усмотрению Платформы может
        быть подписан с использованием простой электронной подписи.
      </p>
      <strong>15. Реквизиты Платформы</strong>
      <p>
        <b>Наименование организации:</b> Общество с ограниченной
        ответственностью «Цифровая платформа «ПИРС»
      </p>
      <p>
        <b>Краткое наименование организации:</b> ООО «ЦП «ПИРС»
      </p>
      <p>
        <b>ИНН:</b> 1840106879
      </p>
      <p>
        <b>ОГРН:</b> 1211800017340
      </p>
      <p>
        <b>КПП:</b> 184001001
      </p>
      <p>
        <b>Юридический адрес:</b> г Ижевск, ул Грибоедова, д 30А, оф 4
      </p>
    </div>
  );
};
