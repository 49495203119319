import React from "react";
import { Carousel } from "react-responsive-carousel";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export type CarouselProps = ComponentPropsType<{
  children?: any;
  autoPlay?: boolean;
  interval?: number;
  dynamicHeight?: boolean;
  showArrows?: boolean;
  showIndicators?: boolean;
  infiniteLoop?: boolean;
  showThumbs?: boolean;
  showStatus?: boolean;
  selectedItem?: number;
  axis?: "horizontal" | "vertical" | undefined;
  renderArrowPrev?: (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => React.ReactNode;
  renderArrowNext?: (
    clickHandler: () => void,
    hasNext: boolean,
    label: string
  ) => React.ReactNode;
  onChange?: (index: number) => void;
}>;

export const CarouselComponent: React.FC<CarouselProps> = ({
  children,
  autoPlay = true,
  interval = 3000,
  showArrows = true,
  showIndicators = true,
  infiniteLoop = true,
  showThumbs = true,
  axis = "horizontal",
  showStatus = false,
  dynamicHeight = true,
  renderArrowPrev,
  renderArrowNext,
  selectedItem,
  onChange,
}) => {
  return (
    <Carousel
      onChange={onChange}
      autoPlay={autoPlay}
      interval={interval}
      showArrows={showArrows}
      showIndicators={showIndicators}
      infiniteLoop={infiniteLoop}
      showThumbs={showThumbs}
      dynamicHeight={dynamicHeight}
      axis={axis}
      showStatus={showStatus}
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      selectedItem={selectedItem}
    >
      {children}
    </Carousel>
  );
};
