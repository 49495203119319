import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

const expands = `project.author,
  project.gip,
  project.expertise,
  project.gipAccess.userType,
  project.designType,`;

export const GetProjectProcessPageExercise = (
  paramsString: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/published-object/index${paramsString}&expand=${expands}`,
    {}
  );
};
