import React, { memo, useEffect } from "react";
import { TextField } from "@mui/material";
import moment from "moment/moment";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../app/store";
import { fetchExpertiseCommentView } from "../../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseCommentView";
import {
  getExpertiseRemarkComment,
  getExpertiseRemarkCommentIsLoad,
} from "../../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { ModalPreloader } from "../../../../../../../../newUI/Modal/ModalPreloader";
import {
  palette,
  textFieldSX,
} from "../../../../../../../../../styles/restyle";
import cls from "./ModalComment.module.scss";
import CustomButton from "../../../../../../../../newUI/CustomButton/CustomButton";
import { fetchExpertiseCreateComment } from "../../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseCreateComment";
import { fetchExpertiseRemarks } from "../../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getProjectProcessInfoId } from "../../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

interface ModalCommentProps {
  rowId: number;
  closeModal: () => void;
}

type TInputs = {
  text: string;
};

const schema = Yup.object().shape({
  text: Yup.string().ensure().required("Необходимо заполнить «Комментарий»"),
});

export const ModalComment = memo(({ rowId, closeModal }: ModalCommentProps) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(getExpertiseRemarkComment);
  const isLoadComment = useAppSelector(getExpertiseRemarkCommentIsLoad);
  const processId = useAppSelector(getProjectProcessInfoId);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(fetchExpertiseCommentView(rowId));
  }, [rowId]);

  if (isLoadComment) {
    return <ModalPreloader />;
  }

  async function handleSubmitForm(dataValues: TInputs) {
    const data = {
      text: dataValues.text,
    };
    await dispatch(fetchExpertiseCreateComment({ rowId, data }));
    if (processId) {
      dispatch(fetchExpertiseRemarks(processId));
    }
    closeModal();
  }

  return (
    <form className={cls.form} onSubmit={handleSubmit(handleSubmitForm)}>
      <h1>Комментарии</h1>
      <TextField
        {...textFieldSX}
        {...register("text")}
        multiline
        minRows={2}
        placeholder="Введите комментарий"
        label="Комментарий"
        error={!!errors.text}
        helperText={errors.text?.message}
      />
      {comments?.map((comment) => (
        <div className={cls.form_comment}>
          <div className={cls.form_comment_flex}>
            <p>{comment.text}</p>
            <p>
              {moment(comment.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
                "DD.MM.YYYY"
              )}
            </p>
          </div>
          <p></p>
        </div>
      ))}
      <CustomButton
        width={160}
        background={palette.blue}
        className={cls.form_submit}
        type="submit"
      >
        Отправить
      </CustomButton>
    </form>
  );
});
