import { useEffect } from "react";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useDataMapper } from "../../../../../shared/lib/hooks/useDataMapper";
import { toSelect } from "../../../../../../app/feature/project/projectProcessPublication";
import { useQueries } from "./useQueries";
import { Nullable } from "../../../../../../types/generics";

type UseModalProps = {
  id: number;
  userType?: Nullable<TSelectItem>;
  isProcessWork: boolean;
};

export const useSelectExecutor = ({
  id,
  userType,
  isProcessWork,
}: UseModalProps) => {
  const { fetchGetExecutor, usersToAssignRaw, isLoading } = useQueries(
    id,
    isProcessWork,
    userType
  );

  useEffect(() => {
    if (userType && isProcessWork) {
      fetchGetExecutor();
    }
  }, [userType, isProcessWork]);

  useEffect(() => {
    if (!isProcessWork) {
      fetchGetExecutor();
    }
  }, [isProcessWork]);

  const usersToAssign = useDataMapper(usersToAssignRaw, toSelect);

  const isPendingUsersAssign = (isLoading || !usersToAssign) && !!userType;

  return {
    usersToAssign,
    isPendingUsersAssign,
  } as const;
};
