import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { fetchProjectProcessStopped } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessStopped";
import {
  getProjectProcessStopped,
  getProjectProcessStoppedIsLoad,
} from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessStopped";
import { fetchProjectProcessCreateStop } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessCreateStop";
import { textFieldSX } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import StopRow from "./StopRow";
import style from "./Stop.module.scss";

const schema = Yup.object().shape({
  content: Yup.string().required("Необходимо заполнить поле «Причина»."),
});

type TInput = {
  content: string;
};

interface StopProps {
  modalClose: (state: boolean) => void;
}

export const Stop = memo(({ modalClose }: StopProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const getStoppedIsLoad = useAppSelector(getProjectProcessStoppedIsLoad);
  const stopped = useAppSelector(getProjectProcessStopped);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessStopped(+params.id));
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TInput>({
    resolver: yupResolver(schema),
  });
  const formSubmitHandler = async (dataValue: TInput) => {
    const data = {
      content: dataValue.content,
    };
    if (params.id) {
      await dispatch(fetchProjectProcessCreateStop({ id: +params.id, data }));
    }
    modalClose(false);
  };

  if (getStoppedIsLoad) {
    return <NewModalPreloader />;
  }

  if (!stopped) {
    return <></>;
  }

  return (
    <form className={style.form} onSubmit={handleSubmit(formSubmitHandler)}>
      <div className={style.grid}>
        <div>Причина остановки</div>
        <div>Дата создания</div>
        <div>Дата закрытия</div>
        <div />
        {stopped.map((stop) => (
          <StopRow stop={stop} key={stop.id} />
        ))}
      </div>
      <TextField
        {...textFieldSX}
        multiline
        minRows={2}
        placeholder="Введите причину"
        label="Причина"
        className={style.form_content}
        {...register("content")}
        error={!!errors.content}
        helperText={errors.content?.message}
      />
      <Button
        width={160}
        variant="contained"
        type="submit"
        className={style.form_submit}
      >
        Отправить
      </Button>
    </form>
  );
});
