import { ReactNode } from "react";
import styles from "./Modal.module.scss";

type Props = {
  children: ReactNode;
};

const ModalControls = ({ children }: Props) => {
  return <div className={styles.modal__controls}>{children}</div>;
};

export default ModalControls;
