import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const Terms = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.condition, style[theme])}>
      <p>
        <b>Администратор Платформы</b> – Общество с ограниченной
        ответственностью «ЦП «ПИРС» (ОГРН 1211800017340, ИНН 1840106879, КПП
        184001001, место нахождения: Россия, Удмуртская Республика, г. Ижевск,
        ул Грибоедова, д.30А, оф.4) или иное лицо, которому по установленным
        законом или сделкой основаниям переданы права и обязанности
        Администратора, осуществляющее функции по предоставлению технической
        возможности получения Пользователем доступа к Платформе в порядке,
        определенном Пользовательским соглашением.
      </p>
      <p>
        <b>Аккаунт</b> – учетная запись, используемая после регистрации на
        Платформе.
      </p>
      <p>
        <b>Акцепт Оферты</b> — полное и безоговорочное принятие Оферты о
        заключении договора оказания услуг сопровождения сделок между
        пользователями платформы ПИРС «Безопасная сделка» (далее – Оферта) путем
        совершения Заказчиком действий, указанных в Оферте, создающее
        обязательства между Заказчиком и Подрядчиком с участием услуги
        «Безопасная сделка».
      </p>
      <p>
        <b>Аналог собственноручной подписи</b> – Электронная подпись
        используемая при подтверждении документов в электронной форме, в силу
        использования которой подписанный электронной подписью любой акт сделки
        признается электронным документом, равнозначным документу на бумажном
        носителе, подписанному собственноручной подписью, а также и последующие
        действия на Защищенных страницах Платформы, нажатие кнопки «Создать
        проект», «Выбрать», «Запросить снятие исполнителя», «Рассмотреть
        конфликт» и иных кнопок в связи с функционированием онлайн сервиса
        «Безопасная сделка», иных сервисов сайта, связанных с исполнением
        Договоров, Договоров подряда.
      </p>
      <p>
        <b>Блокирование аккаунта</b> – осуществляемое Администратором Платформы
        действие, направленное на лишение Пользователя статуса
        «Зарегистрированный пользователь» и ограничение возможности совершения,
        инициирования сделок, просмотра информации о Сделках.
      </p>
      <p>
        <b>Вознаграждение Платформы</b> — плата, причитающаяся Платформе за
        предоставление онлайн сервиса «Безопасная Сделка», а именно: за оказание
        услуг, предусмотренных разделом 8 Договора. Размер и порядок уплаты
        вознаграждения Платформы определяется Договором.
      </p>
      <p>
        <b>Договор</b> — договор возмездного оказания Услуг, заключенный путем
        акцепта Оферты между Платформой, Подрядчиком и Заказчиком на ее
        условиях.
      </p>
      <p>
        <b>Договор подряда</b> — Договор подряда о выполнении работ и/или
        оказании услуг, заключенный между Подрядчиком и Заказчиком при помощи
        программно-технических средств платформы ПИРС, неотъемлемой частью
        которого является Техническое задание.
      </p>
      <p>
        <b>Заказ</b> — условия оказания Услуг, согласованные Сторонами путем
        совершения Заказчиком действий, перечисленных на соответствующей
        странице Сайта или его Личного кабинета, необходимых для заключения
        отдельного Договора с контрагентом – пользователем платформы ПИРС.
      </p>
      <p>
        <b>Заказчик (ГИП, Генподрядчик)</b> – возможный статус любого
        Пользователя Платформы, размещающего заказ на выполнение работы
        (оказание услуги) на Платформе ПИРС
      </p>
      <p>
        <b>Заказчик (как участник сервиса «Безопасная сделка»</b> – пользователь
        Сайта- платформы ПИРС, способный совершить Акцепт Оферты (применительно
        к порядку заключения Договора) либо совершивший Акцепт Оферты
        (применительно к исполнению заключенного Договора, зарегистрированный на
        платформе ПИРС в качестве Заказчика, или получивший статус «Заказчик» в
        связи со своими действиями по созданию проекта, путем нажатия кнопки
        «Создать проект» получает возможность размещать заказы на выполнение
        соответствующих работ на Сайте путем заполнения бланка заказа,
        заключивший с Подрядчиком Договор подряда, используя Защищенные страницы
        Сайта. Заказчиком может быть полностью дееспособное физическое лицо,
        достигшее восемнадцатилетнего возраста, резидент Российской Федерации
        или нерезидент Российской Федерации, а также индивидуальный
        предприниматель, зарегистрированный в соответствии с законодательством
        Российской Федерации или любого иного государства, или юридическое лицо,
        созданное в соответствии с законодательством Российской Федерации или
        любого иного государства.
      </p>
      <p>
        <b>Защищенные страницы Платформы</b> — страницы сайта доступ к которым
        возможен только при вводе Заказчиком или Подрядчиком Логина и Пароля;
      </p>
      <p>
        <b>Идентификация Пользователей</b> – совокупность мероприятий по
        подтверждению/ опровержению достоверности сведений о Пользователях/
        представителях Пользователей, сообщенных Пользователем при регистрации
        Пользователя / допуске представителя Пользователя в целях использования
        Платформы
      </p>
      <p>
        <b>IP-адрес</b> — уникальный сетевой адрес узла в компьютерной сети,
        построенной по протоколу IP (Internet Protocol).
      </p>
      <p>
        <b>Личный кабинет</b> — персональный раздел платформы ПИРС, для входа в
        который требуется ввод Логина и Пароля Пользователя доступ к которому
        Пользователь получает после прохождения регистрации и/или авторизации на
        Сайте. Личный кабинет предназначен для хранения персональной информации
        Заказчика, оформления Заказов, просмотра статистической информации о
        совершенных Заказах, текущем состоянии Лицевого счета, и получения
        уведомлений.
      </p>
      <p>
        <b>Контент</b> – информация, данные, тексты, программы, музыка, звуки,
        фотографии, графика, видео, сообщения и другие материалы, размещенные
        Пользователями на страницах Платформы.
      </p>
      <p>
        <b>Кошелек</b> – вкладка в личном кабинете, отображающая поступление
        денежных средств на расчетный счет Платформы перечисленных Пользователем
        в целях осуществления и обеспечения исполнения и совершения Сделок на
        Платформе.
      </p>
      <p>
        <b>Конфиденциальность персональных данных</b> – обязательное для
        соблюдения Оператором или иным получившим доступ к персональным данным
        лицом требование не допускать их распространения без согласия субъекта
        персональных данных или наличия иного законного основания.
      </p>
      <p>
        <b>Конфиденциальная информация юридического лица</b> - сведения, не
        подлежащие публичному распространению и охраняемые законом
        (государственная тайна), либо ограничительным письменным документом
        (коммерческая тайна), прилагаемым юридическим лицом к требованиям при
        формировании заявки на Платформе. Любое указание на конфиденциальность
        при исполнении сделок на Платформе должно быть удостоверено
        документально путем обмена сообщениями между сторонами сделки на
        защищенных страницах Платформы.
      </p>
      <p>
        <b>Логин</b> — уникальное имя Заказчика или Подрядчика, используемое им
        на платформе ПИРС в целях доступа к платформе ПИРС, включая защищенные
        страницы сервиса.
      </p>
      <p>
        <b>Отмена регистрации Пользователя</b> – прекращение статуса
        Пользователя Платформы Администратором Платформы в случаях,
        предусмотренных Пользовательским соглашением.
      </p>
      <p>
        <b>Обработка персональных данных</b> – любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение персональных данных.
      </p>
      <p>
        <b>Обеспечительный платеж</b> — денежная сумма, соответствующая
        согласованной Заказчиком и Подрядчиком Стоимости Работы, которая
        перечисляется Заказчиком на счет Платформы для последующего
        Осуществления выплаты Стоимости Работы (в том числе соразмерно
        уменьшенной) Подрядчику в случаях, предусмотренных Договором и
        отображаемый в личном кабинете Заказчика в сведениях лицевого счета.
      </p>
      <p>
        <b>Осуществление возврата Обеспечительного платежа</b> — принятие
        Платформой необходимых и зависящих от нее мер для того, чтобы
        Обеспечительный платеж (либо ее часть) в случаях, предусмотренных
        Договором и Договором подряда, была возвращена Заказчику обслуживающим
        банком/иной платежной системой (платежным агентом) с участием или без
        такового третьих лиц.
      </p>
      <p>
        <b>Осуществление выплаты Стоимости Работы</b> - принятие Платформой как
        Агентом Заказчика в соответствии с п. 2.2. Договора необходимых и
        зависящих от него мер для того, чтобы Стоимость Работы, указанная в
        Техническом задании, в случаях, предусмотренных Договором подряда и
        Договором, была перечислена Подрядчику обслуживающим банком/иной
        платежной системой (платежным агентом) с участием или без такового
        третьих лиц.
      </p>
      <p>
        <b>Осуществление выплаты соразмерно уменьшенной Стоимости Работы</b> -
        принятие Платформой в соответствии с п. 2.2. Договора необходимых и
        зависящих от неё мер для того, чтобы в случаях, предусмотренных
        Договором подряда, публичной офертой, Стоимость Работы, соразмерно
        уменьшенная в соответствии с решением, принятым по результатам обращения
        Заказчика и (или) Подрядчика, была перечислена Подрядчику обслуживающим
        банком/иной платежной системой (платежным агентом) с участием или без
        такового третьих лиц.
      </p>
      <p>
        <b>Пароль</b> сочетание букв, цифр и символов, уникальное для Логина,
        позволяющее при одновременном вводе с Логином войти на Платформу,
        включая Защищенные страницы Сайта.
      </p>
      <p>
        <b>Персональные данные</b> – любая информация, относящаяся к прямо или
        косвенно определенному или определяемому физическому лицу (субъекту
        персональных данных).
      </p>
      <p>
        <b>ПИРС/Платформа ПИРС</b> – правообладатель и организатор Платформы,
        выступающий от имени ООО «ЦП «ПИРС» (ОГРН 1211800017340, ИНН 1840106879,
        КПП 184001001, место нахождения: Россия, Удмуртская Республика, г.
        Ижевск, ул Грибоедова, д.30А, оф.4), являющийся стороной
        Пользовательского соглашения со стороны Платформы, предоставляющий
        Пользователям услуги сервисов Платформы pirs.online
      </p>
      <p>
        <b>Пользователь Платформы</b> – физическое или юридическое лицо,
        прошедшее процедуру регистрации в целях использования Платформы, в
        порядке, предусмотренном пользовательским Соглашением.
      </p>
      <p>
        <b>Подрядчик (Исполнитель, проектировщик, ГИП)</b> – возможный статус
        любого Пользователя, принимающий условия размещенного на Платформе ПИРС
        заказа Заказчика.
      </p>
      <p>
        <b>Подрядчик (как участник сервиса сайта «Безопасная сделка»</b> —
        пользователь Сайта, зарегистрированный на Сайте в качестве
        профессионального Подрядчика, Проектировщика, ГИПа, Специалиста в
        качестве специалиста/специализированной организации в области
        проектно-изыскательских, инженерно-технических и иных работ и услуг,
        заключивший с Заказчиком Договор подряда (посредством сервиса
        «Безопасная сделка» или по форме Договора подряда, предлагаемого
        Сайтом). Подрядчиком может быть полностью дееспособное физическое лицо,
        достигшее восемнадцатилетнего возраста, резидент Российской Федерации
        или нерезидентом Российской Федерации, а также индивидуальный
        предприниматель, самозанятое физическое лицо, зарегистрированный в
        соответствии с законодательством Российской Федерации или любого иного
        государства, или юридическое лицо, созданное в соответствии с
        законодательством Российской Федерации или любого иного государства;
      </p>
      <p>
        <b>Пользовательское соглашение</b> — соглашение об условиях пользования
        платформой ПИРС, заключенное между пользователями платформы ПИРС и
        Платформой. Текст Пользовательского соглашения размещен по адресу:&nbsp;
        <a href="https://pirs.online/site/agreement">
          https://pirs.online/site/agreement
        </a>
      </p>
      <p>
        <b>Простая электронная подпись</b> – электронная подпись, которая
        посредством использования кодов, паролей или иных средств подтверждает
        факт формирования электронной подписи определенным Пользователем.
        Платформа ПИРС - программно-аппаратный комплекс с использованием
        которого обеспечивается участникам сделок, пользователям сайта право
        использования подписи в составе Платформы ПИРС, позволяющая обмениваться
        Электронными документами между участниками сделок, заключенных на
        Платформе
      </p>
      <p>
        <b>Публичная оферта / Оферта</b> — текст о заключении договора оказания
        услуг сопровождения сделок между пользователями платформы ПИРС
        «Безопасная сделка» со всеми приложениями, изменениями и дополнениями к
        нему, размещенный на Сайте/ платформе ПИРС и доступный в сети Интернет
        по адресу: <a href="https://pirs.online">https://pirs.online</a> (далее
        – Оферта).
      </p>
      <p>
        <b>Работа</b> — проектные, проектно-изыскательские,
        инженерно-технические иные и/или работы услуги, выполняемые и
        оказываемые Подрядчиком в рамках Договора подряда в соответствии с
        Техническим заданием.
      </p>
      <p>
        <b>Сайт / Сайт ПИРС</b> –{" "}
        <a href="https://pirs.online">https://pirs.online</a>, осуществляющий
        свою деятельность посредством Интернет-ресурса и сопутствующих ему
        сервисов, совокупность предоставляемых возможностей по обеспечению
        взаимодействия между Пользователями по вопросам, связанным с совершением
        (заключением, исполнением, прекращением, расторжением) Сделок
      </p>
      <p>
        <b>Самозанятый Подрядчик</b> - Подрядчик, являющийся налогоплательщиком
        налога на профессиональный доход в соответствии с Федеральным законом. О
        наличии статуса налогоплательщика налога на профессиональный доход
        Подрядчик немедленно после получения должен сообщить Платформе
        посредством использования Защищенных Страниц платформы ПИРС. При
        несвоевременном или ненадлежащем сообщении о наличии статуса
        Самозанятого, физическое лицо не считается для целей Договора
        Самозанятым, и Платформа вправе не применять положения Договора подряда,
        определяющие положение, права и обязанности плательщиков налога на
        профессиональный доход в соответствии с Федеральным законом. Подрядчик
        должен немедленно сообщить Заказчику и Платформе о прекращении или
        утрате статуса налогоплательщика налога на профессиональный доход. К
        Самозанятому Подрядчику применяются положения Договора подряда о
        Подрядчике если иное не вытекает из положений Договора подряда или
        существа отношений.
      </p>
      <p>
        <b>Сделки</b> – гражданско-правовые сделки (включая договоры),
        предусмотренные действующим законодательством Российской Федерации,
        которые могут быть заключены Подтвержденным пользователем посредством
        использования Платформы.
      </p>
      <p>
        <b>Способ оплаты</b> – любой из возможных и доступных для выбора на
        Сайте применительно к «Безопасной сделке» способов внесения через счет
        Платформы суммы сделки для Обеспечительного платежа, Осуществления
        возврата Обеспечительного платежа Заказчику (ее части), Осуществления
        выплаты Стоимости Работы (в том числе соразмерно уменьшенной)
        Подрядчику. При Осуществлении Выплаты Подрядчику, физическому лицу,
        доступен Способ оплаты - банковский перевод. При Осуществлении Выплаты
        Подрядчику, юридическому лицу, доступен Способ оплаты — банковский
        перевод.
      </p>
      <p>
        <b>Стоимость Работы</b> — цена Работы, выраженная исключительно в рублях
        Российской Федерации, которая была согласована Заказчиком и Подрядчиком
        в Техническом задании.
      </p>
      <p>
        <b>Техническое задание</b> — совокупность договоренностей Заказчика и
        Подрядчика применительно к предмету и условиям выполнения Работы
        Подрядчиком в рамках Договора подряда, оформленное при помощи
        программно-технических средств платформы ПИРС. Техническое задание
        должно содержать описание Работы, достаточное для ее выполнения,
        стоимость Работы, срок выполнения Работы, иные условия выполнения
        Работы, имеющие существенное значение для Заказчика. Техническое задание
        является неотъемлемой частью Договора подряда.
      </p>
      <p>
        <b>Cookies</b> — небольшой фрагмент данных, отправленный веб-сервером и
        хранимый на компьютере пользователя, который веб-клиент или веб-браузер
        каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть
        страницу Платформы.
      </p>
      <p>
        <b>Удаление аккаунта</b> – Переключение записи о пользователе в режим
        невидимости производимое по решению Пользователя или Платформы ПИРС
        только администратором.
      </p>
      <p>
        <b>Урегулирование спора</b> – электронная услуга платформы ПИРС,
        предоставляемая участникам сделки, заключенной посредством сервиса
        платформы ПИРС «Безопасная сделка» и направлена на досудебное договорное
        урегулирование разногласий, возникших между сторонами договора подряда с
        участием Платформы, либо приглашенных экспертов.
      </p>
      <p>
        <b>Услуга «Безопасная сделка»</b> — онлайн-сервис, услуга по
        сопровождению сделки договора подряда между пользователями платформы
        ПИРС. Для начала использования онлайн сервиса «Безопасная сделка»
        Платформа предлагает Заказчику и Подрядчику акцептовать Оферту, отметив
        «Подписать» в конце документа.
      </p>
      <p>
        <b>Участник сделок</b> – подтвержденный Пользователь, предоставивший
        Платформе документы, позволяющие верифицировать (идентифицировать,
        проверить) сведения, относительно Пользователя в качестве
        профессионального участника проектно- изыскательских работ, заказчика
        принявшего условия пользовательского соглашения, публичной оферты.
      </p>
      <p>
        <b>Электронный документ</b> – документированная информация,
        представленная в электронной форме, то есть в виде, пригодном для
        восприятия человеком с использованием электронных вычислительных машин,
        а также для передачи по информационно-телекоммуникационным сетям или
        обработки в информационных системах. На сайте могут быть использованы
        термины и определения, не определенные в настоящем документе. В этом
        случае толкование такого термина производится в соответствии с текстом и
        в контексте документа и юридически значимых действий при которых такой
        термин используется. В случае отсутствия однозначного толкования термина
        или определения следует руководствоваться его толкованием, определенным:
        в первую очередь — документами, образующими Договор между Сторонами, во
        вторую очередь — законодательством Российской Федерации, и в последующем
        — обычаями делового оборота и научной доктриной. Использование сервисов
        сайта совершение Пользователем юридически значимых действий означает
        согласие с используемой на сайте ПИРС терминологией и определениями,
        согласие и ознакомление с основными документами сайта ПИРС –
        ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ, ПУБЛИЧНАЯ ОФЕРТА, ДОГОВОР, ДОГОВОР ПОДРЯДА,
        БЕЗОПАСНАЯ СДЕЛКА.
      </p>
    </div>
  );
};
