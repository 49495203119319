import React, { memo, useCallback, useState } from "react";
import moment from "moment/moment";
import { IconButton } from "@mui/material";
import { RemarkRowsValue } from "../../../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import cls from "../TableCard.module.scss";
import { selectColor } from "../../../../../../../../utils/selectColor";
import { CommentIcon, DownloadArrow } from "../../../../../../../newUI/SVG";
import Modal from "../../../../../../../newUI/Modal/Modal";
import { ModalComment } from "./ModalComment/ModalComment";
import TableCardBodyPencilBtn from "./TableCardBodyPencilBtn/TableCardBodyPencilBtn";
import { BackToWorkBtn } from "./BackToWorkBtn/BackToWorkBtn";
import { CompleteBtn } from "./CompleteBtn/CompleteBtn";
import { TextLink } from "../../../../../../../UI/components/TextLink/TextLink";
import { iconBtnSx } from "../../../../../../../../styles/restyle";

interface TableCardBodyProps {
  data: RemarkRowsValue;
}
export const TableCardBody = memo(({ data }: TableCardBodyProps) => {
  const { row, count, groups, commentCount } = data;
  const groupsArray = Object.values(groups);
  const [modalComment, setModalComment] = useState(false);

  const closeModal = useCallback(() => {
    setModalComment(false);
  }, []);

  return (
    <>
      <tr>
        <td rowSpan={count}>{row.num}</td>
        <td rowSpan={count}>{row.text}</td>
        <td rowSpan={count}>{row.comment}</td>
      </tr>
      {groupsArray.map((group) => (
        <React.Fragment key={group.partGroup.id}>
          <tr>
            <td colSpan={3}>
              <div className={cls.flex}>
                <p>{group?.partGroup?.name}</p>
                <p style={{ color: selectColor(group.status.color) }}>
                  {group.status.value}
                </p>
                <p>
                  {moment(row.date_limit, "DD.MM.YYYY HH:mm:ss Z").format(
                    "DD.MM.YYYY"
                  )}
                </p>
              </div>
            </td>
          </tr>
          {group.tasks?.map((task) => (
            <tr>
              <td>
                <div className={cls.flex}>
                  <div className={cls.flexCol}>
                    <p>{`${task.taskToProcess?.projectPart?.code} (${task.executor?.surname})`}</p>
                    <span style={{ color: selectColor(task.status.color) }}>
                      {task.status.value}
                    </span>
                    <p
                      style={{ color: selectColor(task.workflow_data?.color) }}
                    >
                      {task.workflow_data.reason}
                    </p>
                    <p>{task.comment}</p>
                  </div>
                  {
                    <Modal active={modalComment} setActive={setModalComment}>
                      <ModalComment rowId={row.id} closeModal={closeModal} />
                    </Modal>
                  }
                  {commentCount === 0 ? (
                    <IconButton onClick={() => setModalComment(true)}>
                      <CommentIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setModalComment(true)}>
                      <CommentIcon
                        fonsSize={"10"}
                        color={"#fa990e"}
                        count={String(commentCount)}
                      />
                    </IconButton>
                  )}
                </div>
              </td>
              <td>
                <div className={cls.changes}>
                  <span>{task.workflow_data.changes}</span>
                  {task.files.length === 0 && (
                    <p>(Документация не приложена)</p>
                  )}
                </div>
              </td>
              <td>
                <div>
                  {task.can.edit && (
                    <TableCardBodyPencilBtn task={task} row={row} />
                  )}
                  {task.can.backToWork && (
                    <BackToWorkBtn taskId={task.children[0].id} />
                  )}
                  {task.can.check && (
                    <CompleteBtn taskId={task.children[0].id} />
                  )}
                  {task.can.view && task.files?.length > 0 && (
                    <TextLink
                      url={`/task/download-files?id=${task.parent_id}`}
                      redirect
                      target={"_blank"}
                    >
                      <IconButton sx={iconBtnSx}>
                        <DownloadArrow />
                      </IconButton>
                    </TextLink>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
});
