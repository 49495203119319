import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { projectsProcessPageInitialState } from "./projectProcessPageInitialState";
import P from "./constants";

import { StateType } from "../../../initialState";
import { ApiProjectsProcessDataType } from "../../../../types/api/responseTypes/apiProjectProcessType";
import { createProjectProcessFromApi } from "./createData/createData";

export const projectProcessPageReducer = createReducer(
  projectsProcessPageInitialState,
  (builder) => {
    builder.addCase(
      P.SET_PROJECTS_PROCESS_PAGE_DATA,
      (state, action: PayloadAction<ApiProjectsProcessDataType>) => {
        return {
          ...state,
          projects: createProjectProcessFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorProjectProcessItems = (state: StateType) =>
  state.projectProcessPage.projects;

export const selectorProjectProcessMeta = (state: StateType) =>
  state.projectProcessPage.meta;

export const setProjectProcess = (
  data: ApiProjectsProcessDataType["data"],
  meta: ApiProjectsProcessDataType["meta"]
) => ({
  type: P.SET_PROJECTS_PROCESS_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
