import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { DesignTypeValue } from "src/app/feature/ProjectView/thunks";
import { BIMIcon, Icon2D, Icon3D } from "./DesignSVG";
import styles from "./InfoDesign.module.scss";

type DesignTypeIcon = {
  [key in DesignTypeValue]: React.ReactNode;
};

export const InfoDesign = () => {
  const { project } = useSelector(getProjectViewData);
  if (project) {
    const onPause = project.statusKey === 15;
    const designTypeIcon: DesignTypeIcon = {
      "2D": <Icon2D />,
      "3D": <Icon3D />,
      BIM: <BIMIcon />,
    };

    return (
      <div
        className={classNames(
          styles.project,
          styles.basic,
          onPause && styles.pause
        )}
      >
        <h1
          className={classNames(
            styles.project_title,
            onPause && styles.project_titlePause
          )}
        >
          Проектирование
        </h1>
        <div>
          <p
            className={classNames(
              styles.subtitle,
              onPause && styles.subtitlePause
            )}
          >
            Сроки:
          </p>
          {project.dateStart && (
            <h3 className={classNames(onPause && styles.datesPause)}>{`${
              project.dateStart
            } - ${project.dateEnd || "Не указано"}`}</h3>
          )}
        </div>
        <div>
          <p className={styles.subtitle}>Экспертиза:</p>
          <h3 className={styles.relative}>
            {project.expertise || "Не требуется"}
          </h3>
        </div>
        <div className={classNames(styles.icon, onPause && styles.iconPause)}>
          {designTypeIcon?.[project?.designType]}
        </div>
        <div
          className={classNames(
            styles.design_type,
            onPause && styles.pauseDesign
          )}
        >
          {project.designType}
        </div>
      </div>
    );
  }
  return <div></div>;
};
