import React, { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "../../../../styles/restyle";
import { AddFile } from "../../SVG";
import { setCheckInput } from "../../../../app/feature/ProjectView/projectView";
import { useAppDispatch } from "../../../../app/store";
import { ModalAddFiles } from "./ModalAddFiles";
import { NewModal } from "../../NewModal/NewModal";

interface AddFilesButtonProps {
  task_id: number;
  parentId: number;
  index?: number;
  projectId: number;
  type: "process" | "project";
}
export const AddFilesButton = memo(
  ({ task_id, parentId, index, projectId, type }: AddFilesButtonProps) => {
    const dispatch = useAppDispatch();
    const [modalFiles, setModalFiles] = useState(false);

    const handleUploader = () => {
      setModalFiles(true);
      dispatch(setCheckInput(true));
    };

    return (
      <>
        <NewModal
          active={modalFiles}
          setActive={setModalFiles}
          title={"Загрузить файлы"}
        >
          <ModalAddFiles
            setModalFiles={setModalFiles}
            taskId={task_id}
            parentId={parentId}
            index={index}
            projectId={projectId}
            type={type}
          />
        </NewModal>
        <IconButton onClick={handleUploader} sx={iconBtnSx}>
          <AddFile />
        </IconButton>
      </>
    );
  }
);
