import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { EditUserJoinForm } from "./EditUserJoinForm";
import { selectorUserJoinData } from "../../../app/feature/eventEmitter/organisation/eventUserJoin";
import style from "./style.module.scss";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const EditUserJoinHeader: React.FC<any> = ({
  author,
  title,
  content,
}) => {
  return (
    <div className={style.joinHeader}>
      Пользователь{" "}
      <TextLink
        variant={classNames(style.link, style.inline)}
        url={`/user/${author.id}?tab=info`}
      >
        {author.name}
      </TextLink>{" "}
      запросил доступ на включение в список сотрудников организации {title} с
      комментарием: "{content}".
    </div>
  );
};

export const EditUserJoin: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;
  const { Popup } = useUserWorkflow();

  const { author, title, content, id } = useSelector(selectorUserJoinData);

  const onClose = () => {
    Popup.userJoin.set({});
    Popup.userJoin.close();
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={
          <EditUserJoinHeader author={author} title={title} content={content} />
        }
        body={<EditUserJoinForm id={id} />}
      />
    </Modal>
  );
};
