import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchConstantValues, TCountry, TObject } from "./thunks";

export type TConstantValuesSlice = {
  roles: TObject;
  countries: TCountry[];
  designTypes: TObject;
};

const initialState: TConstantValuesSlice = {
  roles: {},
  countries: [],
  designTypes: {},
};

const constantValuesSlice = createSlice({
  name: "constantValuesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConstantValues.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const getConstants = (state: RootState) => state.constants;

export default constantValuesSlice.reducer;
