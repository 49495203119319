import * as KanbanType from "../../types";
import { mapColumn } from "./mapColumn";

export function mapTasks(payload: any) {
  const response: Pick<KanbanType.State["tasks"], "allTasks" | "rowTasks"> = {
    allTasks: mapColumn({ data: payload.all.data, columnKey: payload.all.key }),
    rowTasks: {},
  };

  delete payload.all;
  delete payload.setting;

  Object.values(payload).forEach(({ data, key: columnKey }: any) => {
    data.forEach(({ key: rowKey, header, data }: any) => {
      if (!response.rowTasks[rowKey]) {
        // @ts-ignore
        response.rowTasks[rowKey] = {
          header,
        };
      }

      if (response.rowTasks[rowKey].columns) {
        response.rowTasks[rowKey].columns[columnKey] = mapColumn({
          data,
          columnKey,
        });
      } else {
        response.rowTasks[rowKey].columns = {
          [columnKey]: mapColumn({
            data,
            columnKey,
          }),
        };
      }
    });
  });

  return response;
}
