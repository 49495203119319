import { createSlice, CombinedState } from "@reduxjs/toolkit";

const getUserTypeFromSessionStorage = () =>
  Number(sessionStorage.getItem("userTypeId") ?? 0);

export type TState = {
  tab:
    | "staff"
    | "groups"
    | "section"
    | "project->section"
    | "project->treaty"
    | "section->application"
    | "section->tasksAndWorks"
    | "section->treaty"
    | "work->application"
    | "work->treaty"
    | "work->tasks"
    | "staff->groups"
    | "kanban";
  userType: number;
  projectId: number;
  sectionId: number;
  staffId: number;
  treatyId: number;
  workId: number;
  showFulfilledWorks: boolean;
  showFulfilledSections: boolean;
  dashboardCounts: Record<string, number>;
  reportLinks: Record<string, string>[];
  sectionData: {
    containsApplications: boolean;
    containsTasks: boolean;
    isDraft: boolean;
  };
};

const initialState: TState = {
  tab: "project->section",
  userType: getUserTypeFromSessionStorage(),
  projectId: 0,
  sectionId: 0,
  staffId: 0,
  treatyId: 0,
  workId: 0,
  showFulfilledWorks: false,
  showFulfilledSections: false,
  dashboardCounts: {
    countApplication: 0,
    countUnSignedDocs: 0,
    countTasks: 0,
  },
  reportLinks: [],
  sectionData: {
    containsApplications: false,
    containsTasks: false,
    isDraft: false,
  },
};

const dashboardDestinations = createSlice({
  name: "dashboardApplications",
  initialState,
  reducers: {
    clearDestinations() {
      return initialState;
    },
    setUserType(state, action) {
      const userType = action.payload;

      sessionStorage.setItem("userTypeId", userType);
      state.userType = userType;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setSectionId(state, action) {
      state.sectionId = action.payload;
    },
    setStaffId(state, action) {
      state.staffId = action.payload;
    },
    setTreatyId(state, action) {
      state.treatyId = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setDashboardCounts(state, action) {
      state.dashboardCounts = action.payload;
    },
    setReportLinks(state, action) {
      state.reportLinks = action.payload;
    },
    setSectionData(state, action) {
      state.sectionData = action.payload;
    },
    setWorkId(state, action) {
      state.workId = action.payload;
    },
    setShowFulfilledWorks(state, action) {
      state.showFulfilledWorks = action.payload;
    },
    setShowFulfilledSections(state, action) {
      state.showFulfilledSections = action.payload;
    },
  },
});

export const {
  clearDestinations,
  setUserType,
  setProjectId,
  setSectionId,
  setStaffId,
  setTreatyId,
  setTab,
  setDashboardCounts,
  setReportLinks,
  setSectionData,
  setWorkId,
  setShowFulfilledWorks,
  setShowFulfilledSections,
} = dashboardDestinations.actions;

export const getDashboardDestinations = (state: CombinedState<any>) =>
  state.dashboard.destinations;

export default dashboardDestinations;
