import React, { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 5 8"
    {...props}
  >
    <path
      stroke="#0AA699"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m1 1 3 3-3 3"
    />
  </svg>
);

const BreadcrumbsSeparator = memo(SvgComponent);
export default BreadcrumbsSeparator;
