import * as Yup from "yup";
import { messages } from "../../../../../utils/SimplifiedYup";

export const dateFormat = "DD.MM.YYYY";

export const paperOpt = {
  position: "absolute",
  left: "0px",
  width: "100%",
  marginTop: 4,
  color: "#26313a",
};

export const validationSchema = Yup.object().shape({
  surname: Yup.string().required("Необходимо заполнить «Фамилия»"),
  name: Yup.string().required("Необходимо заполнить «Имя»"),
  patronymic: Yup.string().required("Необходимо заполнить «Отчество»"),
  birthdate: Yup.mixed()
    .nullable()
    .required('Необходимо заполнить "Дата рождения"'),
  region_code: Yup.string().required("Необходимо заполнить «Регион»"),
  address: Yup.string().required("Необходимо заполнить «Адрес»"),
  city: Yup.string().required("Необходимо заполнить «Город»"),
  passport_serial: Yup.string()
    .test("isNum", "Поле состоит из 4 цифр", (value) =>
      Boolean(value && !Number.isNaN(Number(value)) && value.length === 4)
    )
    .required("Необходимо заполнить «Серия паспорта»"),
  passport_num: Yup.string()
    .test("isNum", "Поле состоит из 6 цифр", (value) =>
      Boolean(value && !Number.isNaN(Number(value)) && value.length === 6)
    )
    .required("Необходимо заполнить «Номер паспорта»"),
  passport_issued_by: Yup.string().required("Необходимо заполнить «Кем выдан»"),
  passport_date: Yup.mixed()
    .nullable()
    .required('Необходимо заполнить "Когда выдан"'),
  content: Yup.string().test(
    "strictlyRequired",
    messages.requiredField("Причина изменения персональных данных"),
    (value) => Boolean(value && value.length && value.replace(/\s/g, "").length)
  ),
});

export const uploadFilesError = "Необходимо приложить хотя бы 1 файл";
