import moment from "moment";
import { Nullable } from "src/types/generics";

/*
  Преобразует объект даты в формат строки вида DD.MM.YYYY
  (в данном строковом формате сервер принимает даты).
  Чаще всего используется при парсинге форм перед их отправкой на сервер.
*/

/*
  Преобразует объект даты в формат строки вида DD.MM.YYYY
  (в данном строкоовом формате сервер примнимает даты).
  Чаще всего используется при парсинге форм перед их отправкой на сервер.
*/

export const toServerDate = (dateValue: Nullable<Date>) =>
  dateValue ? moment(dateValue).format("DD.MM.YYYY") : null;
