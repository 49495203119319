import React, { memo } from "react";
import { TCheckTask } from "../../../../../app/feature/ProjectProcessView/CheckTask/types/projectProcessCheckTaskTypes";
import { CheckTaskCard } from "./CheckTaskCard/CheckTaskCard";
import { TModalData } from "../../../Project/View/CheckTasks/CheckTaskCard";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";

interface ICheckTaskInProps {
  id: number;
  checkTasks: TCheckTask[] | null;
  setModalData?: SetState<TModalData>;
}
export const CheckTaskTab: React.FC<ICheckTaskInProps> = memo(
  ({ id, checkTasks, setModalData }) => {
    if (checkTasks) {
      return (
        <>
          {checkTasks.map((checkTask, index) => (
            <CheckTaskCard
              key={checkTask.partName}
              id={id}
              {...checkTask}
              setModalData={setModalData}
              index={index + 1}
            />
          ))}
        </>
      );
    }
    return <></>;
  }
);
