import { httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiPostForgotPasswordForm = (data: {
  [key: string]: { [key: string]: string };
}): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/site/forgot`, {}, data);

export const ApiPostResetPasswordForm = (
  token = "",
  data: { [key: string]: { [key: string]: string } }
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/site/reset-pwd?token=${token}`,
    {},
    data
  );
