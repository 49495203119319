import moment from "moment";
import { toUnixTimestamp } from "../../../../app/services/date/date";

export const isDateRangeNPercentLeft = (
  dateBegin: string,
  dateEnd: string,
  percent: number
) => {
  const dateRangeDifference =
    toUnixTimestamp(dateEnd) - toUnixTimestamp(dateBegin);

  const currentDateDifference = moment().unix() - toUnixTimestamp(dateBegin);

  if (currentDateDifference < 0) {
    return false;
  }

  // За день до окончания задачи перекрашиваем обратно
  if (dateRangeDifference <= 86400) {
    return true;
  }

  return currentDateDifference / dateRangeDifference >= (100 - percent) * 0.01;
};
