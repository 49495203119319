import { useCallback, useState, useRef } from "react";
import { IconButton } from "@mui/material";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { CaretRightFill, Download } from "react-bootstrap-icons";
import style from "../../../../Project/View/Tasks/style.module.scss";
import { AddFile, CheckSVG, NotApproveIcon } from "../../../../../newUI/SVG";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalNotAccept } from "../../../../Project/View/Tasks/ModalNotAccept/ModalNotAccept";
import { ModalComplete } from "../../../../Project/View/Tasks/ModalComplete/ModalComplete";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { config } from "../../../../../../app.cofig";
import { DefaultFileIcon } from "../../../../../features/Uploader/UploaderUtils/UI";
import { Dot } from "../../../../../newUI/Dot/Dot";
import {
  fetchAcceptTask,
  fetchBackToWork,
  fetchNotAcceptTask,
  fetchProjectTaskInTree,
  fetchProjectTaskOutTree,
} from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import {
  fetchProjectProcessInTasks,
  fetchProjectProcessOutTasks,
} from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import useSubRoutes from "../../../../../hooks/useSubRoutes";
import { palette } from "../../../../../../styles/restyle";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";

const sxBtn = {
  color: "#9DAFBD",
  "&:hover": {
    cursor: "pointer",
    color: "#FFFFFF",
  },
};

export const OutTaskCardVersion = ({
  task,
  type,
  projectId,
  processId,
  setActiveTabMemo,
  code,
  setModalData,
}: any) => {
  const dispatch = useDispatch();
  const [commentModal, setCommentModal] = useState(false);
  const [modalNotAccept, setModalNotAccept] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);
  const [modalFiles, setModalFiles] = useState(false);
  const typeProject = type === "project";
  const typeProcess = type === "process";
  const visibleAccept = task.can.accept;
  const visibleComplete = task.can.complete;
  const visibleBackToWork = task.can["back-to-work"];

  const taskVersionRef = useRef<HTMLDivElement>(null);

  const acceptHandler = async (id: number) => {
    const result = window.confirm("Вы уверены, что хотите принять задание?");
    if (typeProject && projectId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectTaskInTree(projectId));
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchAcceptTask(id));
      await dispatch(fetchProjectProcessInTasks(processId));
    }
  };

  const handleScrollToVersion = () => {
    taskVersionRef.current?.scrollIntoView();
  };

  const notCompleteTask = async (id: number) => {
    const data = {
      comment: "Не требуется",
      status: 7,
    };
    const result = window.confirm(
      "Вы уверены, что хотите отменить данное задание?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectTaskOutTree(projectId));
      if (setActiveTabMemo) {
        setActiveTabMemo(2);
      }
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectProcessOutTasks(processId));
      if (setActiveTabMemo) {
        setActiveTabMemo(2);
      }
    }
  };

  const notAcceptHandler = () => {
    setModalNotAccept(true);
  };

  const completeHandler = () => {
    setModalComplete(true);
  };

  const backToWork = async (id: number) => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задание в работу?"
    );
    if (typeProject && projectId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectTaskOutTree(projectId));
    }
    if (typeProcess && processId && result) {
      await dispatch(fetchBackToWork(id));
      await dispatch(fetchProjectProcessOutTasks(processId));
    }
  };

  const setCompleteModalCloseMemo = useCallback(() => {
    setModalComplete(false);
  }, []);

  useSubRoutes(task.task.id, handleScrollToVersion, 1);

  return (
    <div ref={taskVersionRef} className={style.task} key={task.task.id}>
      <div className={style.task_header}>
        <div className={style.task_header_text}>
          <h4>{task.header}</h4>
          <p>{task.taskStatusInfo.date}</p>
        </div>
        <div className={style.task_header_btn}>
          {visibleAccept && (
            <IconButton
              title="Принять"
              sx={sxBtn}
              onClick={() => acceptHandler(task.task.id)}
            >
              <CheckSVG width="22" height="22" />
            </IconButton>
          )}
          {task.can.reject && (
            <>
              <Modal active={modalNotAccept} setActive={setModalNotAccept}>
                <ModalNotAccept
                  id={task.task.id}
                  content={task.comment}
                  name={task.task.name}
                  projectID={projectId}
                  processID={processId}
                  type={type}
                />
              </Modal>
              <IconButton
                title="Отказать"
                sx={sxBtn}
                onClick={notAcceptHandler}
              >
                <NotApproveIcon />
              </IconButton>
            </>
          )}
          {visibleComplete && (
            <>
              <Modal active={modalComplete} setActive={setModalComplete}>
                <ModalComplete
                  task={task}
                  code={code}
                  setCompleteModalCloseMemo={setCompleteModalCloseMemo}
                  projectId={projectId}
                  projectProcessID={processId}
                  type={type}
                />
              </Modal>
              <IconButton
                title="Сформировать задание"
                sx={sxBtn}
                onClick={completeHandler}
              >
                <AddFile width="22" height="22" />
              </IconButton>
            </>
          )}
          {task.can["not-required"] && (
            <IconButton
              title="Не требуется"
              sx={sxBtn}
              onClick={() => notCompleteTask(task.task.id)}
            >
              <NotApproveIcon />
            </IconButton>
          )}
          {visibleBackToWork && (
            <IconButton
              title="Вернуть в работу"
              sx={sxBtn}
              onClick={() => backToWork(task.task.id)}
            >
              <NotApproveIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className={style.task_footer}>
        <div className={style.task_footer_text}>
          <Modal active={commentModal} setActive={setCommentModal}>
            <div className={style.modalComment}>
              <h1>Комментарий к задаче {task?.task?.name}</h1>
              <p>{task?.comment}</p>
            </div>
          </Modal>
          <div
            className={style.taskLineWithIcon}
            onClick={() => {
              if (setModalData) {
                setModalData({
                  id: task?.task?.id,
                  name: task?.task?.name ?? 0,
                  open: true,
                });
              }
            }}
          >
            <p>Комментарии</p>
            <CaretRightFill />
          </div>
          {task.files.length !== 0 && (
            <>
              <div
                className={style.taskLineWithIcon}
                onClick={() => setModalFiles(true)}
              >
                <p>Вложений {task.files.length}</p>
                <Download />
              </div>
              <Modal active={modalFiles} setActive={setModalFiles}>
                <h1 className={style.modalFiles_h1}>Вложения</h1>
                <div className={style.modalFiles}>
                  {task.files.map((file: any) => (
                    <TextLink
                      variant={style.modalFiles_link}
                      redirect
                      download
                      url={`${config.localDomain}${file.url}`}
                      key={file.id}
                    >
                      <div className={style.modalFiles_block} title="скачать">
                        <div className={style.svgIconContainer} title="скачать">
                          {file.svgIcon ? (
                            parse(file.svgIcon)
                          ) : (
                            <DefaultFileIcon />
                          )}
                        </div>
                        <p>{file.name}</p>
                      </div>
                    </TextLink>
                  ))}
                </div>
                <TextLink
                  url={`/task/download-files?id=${task?.task?.id}`}
                  redirect
                  target={"_blank"}
                  variant={style.download}
                >
                  <CustomButton background={palette.green} width={130}>
                    Скачать все
                  </CustomButton>
                </TextLink>
              </Modal>
            </>
          )}
        </div>
        <div className={style.task_footer_status}>
          <Dot status={task?.taskStatusInfo?.dot} />
          <p
            dangerouslySetInnerHTML={{
              __html: task.taskStatusInfo.statusString,
            }}
          />
        </div>
      </div>
    </div>
  );
};
