import { ReactNode } from "react";
import classNames from "classnames";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import styles from "./TabChildrenHeader.module.scss";

type TProps = {
  title?: string;
  media?: ReactNode;
  className?: string;
  mediaClassName?: string;
};

export const TabChildrenHeader = ({
  title,
  media,
  className,
  mediaClassName,
}: TProps) => {
  const { currentChildTab, setCurrentChildTab, currentTabChildren } =
    useTabsPanelContext();

  const handleClick = (value: string) => {
    setCurrentChildTab(value);
  };

  return (
    <Card
      className={classNames(styles.tabHeader, className)}
      header={<Card.Header>{title}</Card.Header>}
      contentClassName={styles.buttonsList}
      controls={
        <Card.Controls
          location="center"
          className={classNames(styles.media, mediaClassName)}
          width={0}
        >
          {media}
        </Card.Controls>
      }
    >
      <div className={styles.buttons}>
        {currentTabChildren.map(({ name, title, dot }) => (
          <Button
            key={name}
            color={name === currentChildTab ? "success" : "secondary"}
            variant={name === currentChildTab ? "outlined" : "contained"}
            onClick={() => handleClick(name)}
            className={classNames(dot && styles.dot)}
          >
            {title}
          </Button>
        ))}
      </div>
    </Card>
  );
};

export default TabChildrenHeader;
