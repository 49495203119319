import {
  apiProjectAuthorType,
  apiProjectType,
} from "../../../../types/api/responseTypes/apiProjectType";
import { projectMainInformation } from "../../../../types/stateTypes/project";

export const createAdvance = (advance: string | number): string => {
  if (Number(advance) === 0) return `без аванса`;
  return `аванс: ${advance}%`;
};

export const createClient = (
  client: apiProjectAuthorType
): {
  name: string;
  link: string;
  id: string | number;
} => {
  if (client === null) {
    return {
      name: "",
      link: "",
      id: "",
    };
  }
  return {
    name: `${client!.name || ""} ${client!.surname || ""}`,
    link: `/user/${client!.id}?tab=info`,
    id: client!.id,
  };
};

export const createData = (data: apiProjectType): projectMainInformation => {
  return {
    dateFromTo: `${data.date_start || ""} - ${data.date_end || ""}`,
    buildingFunctionName: data.buildingFunction
      ? data.buildingFunction.name
      : " ",
    constructionTypeName: data.constructionType
      ? data.constructionType.name
      : "",
    client: createClient(data.author!),
    firm: data.authorType.firmId
      ? {
          name: data.authorType.listName,
          url: `${window.origin}/firm/${data.authorType.firmId}?tab=info`,
        }
      : null,
    clientType: data.authorType ? data.authorType.full : "",
    status: `${data.status.value}`,
    designType: data.designType.value,
    region: `${data.region.country.title}, ${data.region.title}`,
    advance: createAdvance(data.advance_pay),
  };
};
