import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganisationStaff } from "../../../../components/pages/Organisation/requests";
import { TValues } from "../../../../components/pages/User/Dashboard/modals/GroupToggle";
import {
  ApiGetOrganisationInfo,
  ApiGetSpecializations,
  ApiGetUsersByTeamId,
  ApiRemoveTeammate,
  ApiTeamInvitation,
  ApiUserJoin,
} from "../../../services/api/organisation/organisation";
import { request } from "../../../services/api/requestHandler";
import { pushError, pushMessage } from "../../errorTrace";

export const getStaff = createAsyncThunk(
  "dashboard/getStaff",
  async (id: number, { dispatch }) => {
    const staff = await getOrganisationStaff({ id }, "");

    let requests: any = [];
    await request(
      ApiGetOrganisationInfo(
        `view?id=${id}&expand=requestToJoin,requestToJoin.author.skills`
      ),
      (response) => {
        requests = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    requests =
      requests.requestToJoin?.map((request: any) => ({
        name: `${request.author.surname} ${request.author.name} ${request.author.patronymic}`,
        id: request.id,
      })) ?? [];

    return { data: staff.data, requests };
  }
);

export const handleRequest = createAsyncThunk(
  "dashboard/handleRequest",
  async ({ id, accepted }: { accepted: boolean; id: number }, { dispatch }) => {
    const formData = new FormData();
    formData.append("CompleteForm[status]", accepted ? "4" : "5");
    formData.append(
      "CompleteForm[comment]",
      accepted ? "" : "Вам отказано во вступлении в организацию."
    );

    await request(
      ApiUserJoin(id, formData),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return id;
  }
);

export const getSpecializations = createAsyncThunk(
  "dashboard/getSpecializations",
  async (_, { dispatch }) => {
    let response: any;

    await request(
      ApiGetSpecializations(),
      (data) => {
        response = Object.keys(data).map((id: string) => ({
          id: Number(id),
          title: data[id],
        }));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const sendInvitation = createAsyncThunk(
  "dashboard/sendInvitation",
  async (data: TValues, { dispatch }) => {
    const { user, team, title, firmId } = data;

    await request(
      ApiTeamInvitation(user!.id as number, team!.id as number, firmId!),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return { userId: user!.id, teamId: team!.id, title };
  }
);

export const removeTeammate = createAsyncThunk(
  "dashboard/removeTeammate",
  async (data: TValues, { dispatch }) => {
    const { user, team } = data;

    await request(
      ApiRemoveTeammate(user!.id as number, team!.id as number),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return { userId: user!.id, teamId: team!.id };
  }
);

export const getUsersByTeamId = createAsyncThunk(
  "dashboard/getUsersByTeamId",
  async (id: number, { dispatch }) => {
    let users: any = [];

    await request(
      ApiGetUsersByTeamId(id),
      (response) => {
        users = Object.entries(response).map(([id, title]) => ({
          id: Number(id),
          title,
        }));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return users;
  }
);
