import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormControl, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { palette } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { getProjectUsersList } from "../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import {
  fetchProjectUsersList,
  fetchProjectUsersType,
  fetchProjectUsersUpdate,
} from "../../../../../app/feature/ProjectView/AdminReducer/thunks";
import style from "./Admin.module.scss";
import { fetchProjectViewSearchGip } from "../../../../../app/feature/ProjectView/thunks";
import { SelectField } from "../../../../newUI/SelectMui/SelectMui";
import { Select } from "../../../../UI/components/FormComponentns/Select";

type Inputs = {
  type: number;
  user: { title: string; id: string };
};
type GipEditModalProps = {
  gipAccess: {
    access_code: number;
    id: number;
    userType: {
      listName: string;
      type: number;
      firmId: number;
    };
    user_id: string;
    user_type_id: string;
  }[];
};

const schema = Yup.object().shape({
  user: Yup.string().required("Необходимо выбрать пользователя"),
  type: Yup.string().required("Необходимо выбрать форму ответственности"),
});

export const GipEditModal: React.FC<GipEditModalProps> = ({ gipAccess }) => {
  const { newUsersList, isLoadUsersList, usersType, isLoadUsersType } =
    useSelector(getProjectUsersList);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: newUsersList.filter(
        (user) => +user.id === +gipAccess[0].user_id
      )[0],
    },
  });

  const watchUser = watch("user");

  useEffect(() => {
    dispatch(fetchProjectUsersList());

    if (watchUser) {
      dispatch(fetchProjectUsersType(watchUser.id));
    }
  }, [watchUser?.id, dispatch]);

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      ProjectAccess: {
        access_code: gipAccess[0].access_code,
        user_id: +watchUser.id,
        user_type_id: dataValues.type,
      },
    };
    if (params.id) {
      await dispatch(fetchProjectUsersUpdate({ id: gipAccess[0].id, data }));
      await dispatch(fetchProjectViewSearchGip(params.id));
    }
  };

  if (isLoadUsersList) {
    return <Skeleton className={style.skeleton} />;
  }

  if (newUsersList) {
    const labelType = isLoadUsersType ? "...loading" : "Форма собственности";

    return (
      <div className={style.adminWrapper}>
        <h1 className={style.adminWrapper_title}>Изменить доступ к проекту</h1>
        <form
          className={style.adminWrapper_form}
          onSubmit={handleSubmit(formSubmitHandler)}
        >
          <Select
            value={watchUser}
            name={"user"}
            changeHandler={(_, value) => {
              setValue("user", value);
            }}
            label="Пользователь"
            options={newUsersList}
            error={!!errors.user}
            helperText={errors.user ? errors.user.message : null}
            isLoading={false}
            disableClear
          />
          <SelectField
            {...register("type")}
            label={labelType}
            name="type"
            options={usersType}
            error={errors.type ? errors.type.message : null}
          />
          <FormControl className={style.adminWrapper_btn}>
            <CustomButton background={palette.green} width={160} type="submit">
              Сохранить
            </CustomButton>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
