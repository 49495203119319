import React from "react";
import cn from "classnames";
import { OpenInNew } from "@mui/icons-material";
import secondaryStyle from "./Secondary/style.module.scss";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { useIsMobile } from "../../../hooks/useIsMobile";
import style from "./style.module.scss";

const Instruction = () => {
  const isMobile = useIsMobile(1350);
  return (
    <div className={cn(style.subdiv, style.instruction)}>
      <h2>Инструкция пользователя</h2>
      <p className={style.leftBottom}>О том, как работать на ПИРСе</p>
      <TextLink
        url="https://wiki.pirs.online/"
        redirect
        target="_blank"
        variant={style.openOnTablette}
      >
        {isMobile ? (
          <OpenInNew />
        ) : (
          <CustomButton
            background={palette.blue}
            width={120}
            className={cn(secondaryStyle.button, style.rightBottom)}
          >
            Открыть
          </CustomButton>
        )}
      </TextLink>
    </div>
  );
};

export default Instruction;
