import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import styles from "./Objects.module.scss";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import {
  getBodyIsLoad,
  getGipRemove,
  getProjectComplexError,
  getProjectComplexLoad,
  getProjectComplexObjects,
  setErrorNull,
} from "../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { ObjectCard } from "./ObjectCard/ObjectCard";
import { SkeletonObjects, SkeletonObjectsBody } from "./SkeletonObjects";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { Header } from "./Header/Header";
import { useAppSelector } from "../../../../../app/store";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export const Objects = () => {
  const params = useParams<{ id: string }>();
  const objects = useAppSelector(getProjectComplexObjects);
  const gipRemoveSuccess = useAppSelector(getGipRemove);
  const isLoad = useAppSelector(getProjectComplexLoad);
  const isLoadBody = useAppSelector(getBodyIsLoad);
  const error = useAppSelector(getProjectComplexError);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isTablet = useIsMobile(1600);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (gipRemoveSuccess) {
      enqueueSnackbar("Запрос на снятие ГИПа прошел успешно", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [gipRemoveSuccess]);

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setErrorNull());
  }

  if (isLoad) {
    return <SkeletonObjects />;
  }

  return (
    <div className={styles.objects}>
      <Header />
      <ScrollWrap height={isTablet ? "auto" : "864px"}>
        {isLoadBody ? (
          <SkeletonObjectsBody />
        ) : (
          <div className={styles.objects_cardWrapper}>
            {objects.map((object) => (
              <ObjectCard key={object.name} {...object} />
            ))}
          </div>
        )}
      </ScrollWrap>
    </div>
  );
};
