import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const application = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.Application, TParams>({
    query: (params) => ({
      url: `v1/project-process/application`,
      params: {
        id: params.id,
      },
    }),
    providesTags: [
      "ProjectAccess.RemoveRequestExecution",
      "ProjectAccess.RequestUpdate",
      "ProjectAccess.Request",
    ],
  });
