import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiGetUserTypes } from "../../../../services/api/user/user";

export const fetchUserTypes = createAsyncThunk<any, number>(
  "user/fetchUserTypes",
  async (userId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiGetUserTypes(userId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
