import React from "react";
import { Check } from "@mui/icons-material";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";

export type VerifyPops = ComponentPropsType<{
  confirm: boolean;
}>;

export const Verify: React.FC<VerifyPops> = ({ confirm, variant = "" }) => {
  return (
    <div title={"Пользователь проверен сервисом"} className={variant}>
      {confirm ? (
        <div className={style.iconContainer}>
          <Check className={style.iconVerify} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
