import React from "react";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { Wrapper } from "../../../templates";
import { User, UserProps } from "./User/User";
import style from "../style.module.scss";

export type UserListProps = ComponentPropsType<{
  items: UserProps[];
  isShowUserLabels?: boolean;
  executors?: boolean;
  customers?: boolean;
  gip?: boolean;
  providers?: boolean;
  executorSkill?: any;
}>;

export const UserList: React.FC<UserListProps> = ({
  variant = "",
  items,
  theme = "dark",
  isShowUserLabels = true,
  executors,
  customers,
  gip,
  providers,
  executorSkill,
}) => {
  return (
    <>
      {items.length > 0 ? (
        <ul className={classNames(variant, style.userList)}>
          {items.map((item, i) => {
            return (
              <li key={i} className={classNames(style.userItem, style[theme])}>
                <User
                  executorSkill={executorSkill}
                  customers={customers}
                  executors={executors}
                  gip={gip}
                  providers={providers}
                  isShowLabels={isShowUserLabels}
                  {...item}
                  key={item.id}
                  theme={theme}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={classNames(style.userItem, style[theme])}>
          <Wrapper>
            <div className={style.notFound}>Ничего не найдено</div>
          </Wrapper>
        </div>
      )}
    </>
  );
};
