import React from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import style from "../style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { RegionSelect } from "../../../features/SpecialForm/RegionSelect";
import { createSearchObjectDataFromFormParams } from "../../../../app/services/searchParams/searchParamsCreateData";
import { Rating } from "../../../features/SpecialForm/Rating";
// import { ExecutedProject } from "../../../features/SpecialForm/ExecutedProject";
import { buttonStyle } from "../Executors/ExecutorFormHead";
import { findRating } from "../Gip/GipFormHead";
import { textFieldSX } from "../../../../styles/restyle";

type TFormValues = Record<string, any>;

export type CustomerFormHeadType = ComponentPropsType<{
  submitHandler?: (params: { [key: string]: any }) => void;
  resetHandler?: () => void;
  initialValues?: {
    [key: string]: any;
  };
}>;

export const CustomerFormHead: React.FC<CustomerFormHeadType> = ({
  theme = "dark",
  submitHandler = () => {},
  resetHandler = () => {},
  initialValues = {},
}) => {
  const { register, setValue, handleSubmit, reset, watch } =
    useForm<TFormValues>({
      defaultValues: {
        fio: initialValues["CustomerUserSearch[fio]"],
        region: initialValues["CustomerUserSearch[region_code]"],
        specialization: initialValues["CustomerUserSearch[skillIds]"],
        rating: findRating(initialValues["CustomerUserSearch[rating]"]),
        executedProject: initialValues["CustomerUserSearch[executedProject]"],
      },
    });

  const watchRegion = watch("region");
  const watchRating = watch("rating");

  const onSubmit = (data: TFormValues) => {
    const searchData = createSearchObjectDataFromFormParams(data, "customer");
    submitHandler(searchData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div className={style.formContainer}>
        <div style={{ flexBasis: 560 }} className={style.formGroup}>
          <TextField
            {...register("fio")}
            {...textFieldSX}
            label="ФИО"
            className={style.formElement}
          />
          <RegionSelect
            variant={style.formElement}
            changeHandler={(data) => {
              setValue("region", data ? data.code : undefined);
            }}
            value={watchRegion}
          />
          <Rating
            value={watchRating}
            changeHandler={(val) => {
              setValue("rating", val);
            }}
          />
        </div>
        <div className={style.right}>
          <div className={style.buttonFormWrapper}>
            <Button
              theme={theme}
              style={buttonStyle}
              variant="contained"
              color="primary"
              type={"submit"}
            >
              Поиск
            </Button>
            <Button
              theme={theme}
              variant="contained"
              color="grey"
              style={buttonStyle}
              clickHandler={() => {
                resetHandler();
                reset();
                setValue("fio", "");
                setValue("region", undefined);
                setValue("rating", "5");
                setValue("executedProject", undefined);
              }}
            >
              Сбросить
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
