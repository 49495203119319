import React, { Fragment, ChangeEventHandler, memo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { TProjectProcessCheckList } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { useAppSelector } from "src/app/store";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  CriticalIcon,
  HiddenEyeIcon,
  PersonIcon,
} from "src/components/newUI/SVG";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SwitchLabel } from "src/FSD/shared/uiKit/v2/SwitchLabel";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Tooltip } from "src/FSD/shared/uiKit/v2/Tooltip";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TSourceDataUpdateForm } from "src/FSD/shared/uiKit/v2/SourceDataUpdateForm/model/types";
import classNames from "classnames";
import {
  handleFilterChange,
  getAuthorIds,
  getAuthorNames,
} from "../lib/helpers";
import { SourceDataNameEdit } from "./SourceDataNameEdit";
import { AddCheckList } from "./AddCheckList";
import styles from "./SourceDataUpdateForm.module.scss";

interface TSourceDataUpdateFormProps {
  formSubmitHandler: (data: TProjectProcessCheckList[]) => void;
  checkList: TProjectProcessCheckList[];
  closeModal: () => void;
  updateAddCheckList: () => void;
  canExecutorEdit: boolean;
  handleDeleteSourceDataName: (id: number) => void;
  parentId: number;
  projectId: number;
}

export const SourceDataUpdateForm = memo(
  ({
    formSubmitHandler,
    checkList,
    closeModal,
    updateAddCheckList,
    canExecutorEdit,
    handleDeleteSourceDataName,
    parentId,
    projectId,
  }: TSourceDataUpdateFormProps) => {
    const [allCheck, setAllCheck] = useState(checkList);

    const { handleSubmit, control, register } =
      useForm<TSourceDataUpdateForm.TInputs>({
        defaultValues: {
          author: getAuthorIds(allCheck, "author"),
          critical: getAuthorIds(allCheck, "critical"),
          executor: getAuthorIds(allCheck, "executor"),
          hidden: getAuthorIds(allCheck, "hidden"),
          names: getAuthorNames(allCheck),
        },
      });

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const { name, checked, value } = event.target;

      handleFilterChange(
        name,
        checked,
        canExecutorEdit,
        value,
        allCheck,
        setAllCheck
      );
    };

    const {
      data: { disabled },
    } = useAppSelector(getProjectChecklist);

    const formSubmitCallback = (data: TSourceDataUpdateForm.TInputs) => {
      const { names } = data;
      const updatedFormData = allCheck.map((check: any) =>
        names[check.id] ? { ...check, name: names[check.id] } : check
      );

      formSubmitHandler(updatedFormData);
    };

    return (
      <div>
        <ScrollWrap height={"500px"} classname={styles.scrollWrapper}>
          <div
            className={classNames(styles.modalParent, styles.modalParent_top)}
          >
            <div>
              <SwitchLabel
                onChange={(e) => handleChange(e)}
                checked={
                  allCheck.filter((item) => item?.author !== true).length < 1
                }
                name="All"
                labelPlacement="start"
                label="Выбрать все"
              />
            </div>
            <Tooltip
              title="Скрытые"
              content="(Ид, которые доступны только участникам проекта)"
            >
              <HiddenEyeIcon width={"20"} height={"20"} />
            </Tooltip>
            <Tooltip
              title="Заказчик"
              content="(за предоставление ИД  отвечает Заказчик)"
            >
              <PersonIcon />
            </Tooltip>
            <Tooltip
              title="Критичные"
              content="(важные ИД, которые влияют на длительность выполнения работ) "
            >
              <CriticalIcon width={"20"} height={"20"} />
            </Tooltip>
          </div>
          <Modal.Form
            onSubmit={handleSubmit(formSubmitCallback)}
            className={styles.form}
            id="source-update-form"
          >
            <div className={styles.modalParent}>
              {allCheck.map((el) => (
                <Fragment key={el.id}>
                  <div className={styles.author}>
                    <Controller
                      control={control}
                      name="author"
                      render={() => (
                        <SwitchLabel
                          onChange={(e) => handleChange(e)}
                          checked={
                            el?.author || el.executor || el.critical || false
                          }
                          value={el.id}
                          name="author"
                          label={!el.delete && el.name}
                        />
                      )}
                    />
                    {el.delete && (
                      <SourceDataNameEdit
                        handleDeleteSourceDataName={handleDeleteSourceDataName}
                        register={register}
                        {...el}
                      />
                    )}
                  </div>
                  <Controller
                    control={control}
                    name="hidden"
                    render={() => (
                      <Checkbox
                        title={
                          "Файлы не публикуемые и доступные только участникам проекта"
                        }
                        onChange={(e) => handleChange(e)}
                        checked={el.hidden || false}
                        value={el.id}
                        editing
                        name="hidden"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="executor"
                    render={() => (
                      <Checkbox
                        onChange={(e) => handleChange(e)}
                        checked={el.executor || false}
                        value={el.id}
                        editing
                        name="executor"
                        disabled={false}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="critical"
                    render={() => (
                      <Checkbox
                        onChange={(e) => handleChange(e)}
                        checked={el.critical || false}
                        value={el.id}
                        editing
                        name="critical"
                      />
                    )}
                  />
                </Fragment>
              ))}
            </div>
          </Modal.Form>
          <AddCheckList
            parentId={parentId}
            projectId={projectId}
            updateAddCheckList={updateAddCheckList}
          />
        </ScrollWrap>
        <Modal.Controls>
          <Button onClick={closeModal} variant="outlined">
            Отменить
          </Button>
          <Button
            form="source-update-form"
            type="submit"
            disabled={Boolean(disabled)}
          >
            Сохранить
          </Button>
        </Modal.Controls>
      </div>
    );
  }
);
