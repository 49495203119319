import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import moment from "moment/moment";
import * as Yup from "yup";
import { ApiGetOutTaskFiles } from "src/app/services/api/organisation/organisation";
import { DateField } from "src/components/features/SpecialForm/DateField/DateField";
import { FormWrapper } from "src/components/newUI/FormWrapper/FormWrapper";
import { ModalPreloader } from "src/components/newUI/Modal/ModalPreloader";
import { Select } from "src/components/UI/components/FormComponentns/Select";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { Uploader } from "src/components/features/Uploader/Uploader";
import { config } from "src/app.cofig";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { fetchMaterialsOfferUpdate } from "src/app/feature/ProjectProcessView/Material/services/fetchMaterialsOfferSubmit";
import { fetchUserType } from "src/app/feature/getList/getListUserTypeById/services/fetchUserType";
import { getUserId } from "src/app/services/auth/auth";
import {
  getUserTypeList,
  getUserTypeListIsLoad,
} from "src/app/feature/getList/getListUserTypeById/selectors/getUserTypeList";
import { palette, textFieldSX } from "src/styles/restyle";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getUpdateFormMaterialsComment,
  getUpdateFormMaterialsDateDeadline,
  getUpdateFormMaterialsError422,
  getUpdateFormMaterialsErrorFlag,
  getUpdateFormMaterialsPrice,
  getUpdateFormMaterialsUserTypeId,
} from "src/app/feature/ProjectProcessView/Material/selectors/getUpdateFormMaterials";
import {
  setClearUpdate,
  setCommentUpdate,
  setDateDeadlineUpdate,
  setInitialUpdate,
  setPriceUpdate,
  setUserTypeIdUpdate,
} from "src/app/feature/ProjectProcessView/Material/slice/updateFormMaterials";
import cls from "../ModalSubmit/ModalSubmit.module.scss";

type TInputs = {
  price: string;
  status: number;
  comment: string;
  dateDeadline: Date | null;
  userTypeId: any;
};
const schema = Yup.object().shape({
  userTypeId: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
  dateDeadline: Yup.date()
    .typeError("Необходимо заполнить «Действительно до»")
    .required("Необходимо заполнить «Действительно до»"),
  price: Yup.string().required("Необходимо заполнить «Стоимость»"),
  comment: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

interface ModalUpdateProps {
  name: string;
  content: string;
  comment: string;
  files: { name: string; id: number }[];
  date_deadline: any;
  taskId: number;
  price: string;
}

export const ModalUpdate = (props: ModalUpdateProps) => {
  const dispatch = useAppDispatch();
  const { name, price, content, comment, files, taskId, date_deadline } = props;

  const userTypeList = useAppSelector(getUserTypeList);
  const isLoadUserTypeList = useAppSelector(getUserTypeListIsLoad);
  const userId = getUserId();

  const priceValue = useAppSelector(getUpdateFormMaterialsPrice);
  const userTypeIdValue = useAppSelector(getUpdateFormMaterialsUserTypeId);
  const dateDeadlineValue = useAppSelector(getUpdateFormMaterialsDateDeadline);
  const commentValue = useAppSelector(getUpdateFormMaterialsComment);
  const error422 = useAppSelector(getUpdateFormMaterialsError422);
  const errorFlag = useAppSelector(getUpdateFormMaterialsErrorFlag);

  const dateDeadLineInitial = date_deadline
    ? moment(date_deadline, "DD.MM.YYYY").toDate()
    : null;

  const {
    handleSubmit,
    setValue,
    register,
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<TInputs>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      userTypeId: userTypeList[0],
      dateDeadline: dateDeadLineInitial,
      price,
      comment,
    },
  });

  useEffect(() => {
    if (userTypeList.length > 0) {
      if (!errorFlag) {
        dispatch(
          setInitialUpdate({
            userTypeId: userTypeList[0],
            price,
            dateDeadline: dateDeadLineInitial,
            comment,
          })
        );
        setValue("userTypeId", userTypeList[0]);
      }
    }
  }, [userTypeList]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserType({ userId }));
    }
  }, [userId]);

  const formSubmitHandler = async (dataValues: TInputs) => {
    const data = {
      CompleteForm: {
        status: dataValues.status,
        comment: commentValue,
        price: priceValue,
        dateDeadline: moment(dateDeadlineValue, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        userTypeId: userTypeIdValue.id,
      },
    };
    await dispatch(fetchMaterialsOfferUpdate({ taskId, data }));
  };

  useEffect(() => {
    dispatch(setClearUpdate());
    clearErrors();
  }, []);

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  if (isLoadUserTypeList) {
    return <ModalPreloader />;
  }

  if (userTypeIdValue) {
    return (
      <FormWrapper
        classname={cls.form}
        title={"Входящий запрос оборудования"}
        submitHandler={handleSubmit(formSubmitHandler)}
      >
        <p>Наименование</p>
        <span>{name}</span>
        <p>Комментарий </p>
        <span>{content}</span>
        <p>Опросный лист </p>
        {files?.map((file) => <span key={file.id}>{file.name}</span>)}
        <TextLink url={"1"}>Скачать</TextLink>
        <p className={cls.form_offer}>Сформировать коммерческое предложение:</p>
        <div className={cls.inputs}>
          <TextField
            {...textFieldSX}
            {...register("price")}
            className={cls.inputs_price}
            type={"number"}
            label="Стоимость"
            value={priceValue}
            onChange={(e) => {
              dispatch(setPriceUpdate(e.target.value));
            }}
            error={!!errors.price}
            helperText={errors.price?.message}
          />
          <Controller
            control={control}
            name="dateDeadline"
            render={() => (
              <DateField
                variant={cls.inputs_date}
                label="Действительно до"
                changeDateHandler={(date) => {
                  dispatch(setDateDeadlineUpdate(date));
                  setValue("dateDeadline", date);
                }}
                startDateProp={dateDeadlineValue}
                error={!!errors.dateDeadline}
                helperText={errors.dateDeadline?.message}
              />
            )}
          />
          <Select
            variant={cls.inputs_type}
            changeHandler={(_, value) => {
              dispatch(setUserTypeIdUpdate(value));
              setValue("userTypeId", value);
            }}
            label={"Форма собственности"}
            value={userTypeIdValue}
            defaultValue={userTypeList[0]}
            options={userTypeList}
            error={!!errors.userTypeId}
            helperText={errors.userTypeId?.message}
            disableClear
          />
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("comment")}
          value={commentValue}
          defaultValue={comment}
          onChange={(e) => {
            dispatch(setCommentUpdate(e.target.value));
          }}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment ? errors.comment.message : null}
        />
        <div className={cls.form_uploader}>
          <Uploader
            identifier={String(taskId)}
            autoUpload
            canBeEdited
            getDataCallback={ApiGetOutTaskFiles}
            outFiles={"outFiles"}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
          />
        </div>
        <div className={cls.form_submit}>
          <CustomButton
            width={140}
            type={"submit"}
            background={palette.red}
            onClick={() => {
              setValue("status", 8);
            }}
          >
            Отказать
          </CustomButton>
          <CustomButton
            width={140}
            type={"submit"}
            background={palette.blue}
            onClick={() => {
              setValue("status", 6);
            }}
          >
            Отправить
          </CustomButton>
        </div>
      </FormWrapper>
    );
  }
  return null;
};
