import { TextField } from "@mui/material";
import React, { useState, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useSnackbar } from "notistack";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";
import AdvancedAddressField from "../Info/AdvancedAddressField/AdvancedAddressField";
import style from "./style.module.scss";
import { CountriesType } from "../../../../features/SpecialForm/Country/Country";
import { Regions } from "../../../../features/SpecialForm/Country/RegionsOfTheCountry";
import { Uploader } from "../../../../features/Uploader/Uploader";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { ErrorMessage } from "../../../../features/Forms/ErrorMessage/ErrorMessage";
import { calculateAge } from "../Info/Info";
import { Values } from "../../../Project/StandardForm/StandardForm";
import { request } from "../../../../../app/services/api/requestHandler";
import { ApiPostRequestChangeUserAttr } from "../../../../../app/services/api/user/user";
import { dateFormat, paperOpt, validationSchema } from "./fragments";
import { config } from "../../../../../app.cofig";
import { ApiGetTaskFiles } from "../../../../../app/services/api/organisation/organisation";
import { palette, textFieldSX } from "../../../../../styles/restyle";

type PersonalDataFormProps = {
  profileRequestData: any;
  handleCloseModal: () => void;
};

const PersonalDataForm: FC<PersonalDataFormProps> = ({
  profileRequestData,
  handleCloseModal,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const containsTask = Boolean(profileRequestData.taskId);

  const {
    register,
    trigger,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      ...profileRequestData,
    },
  });

  const { surname, name, patronymic, id } = profileRequestData;

  const userName = `${surname} ${
    name ? `${name.charAt(0).toUpperCase()}.` : ""
  } ${patronymic ? `${patronymic.charAt(0).toUpperCase()}.` : ""}`;

  const watchCountryCode = watch("country_code");
  const watchRegionCode = watch("region_code");
  const watchPassportDate = watch("passport_date");
  const watchBirthDate = watch("birthdate");
  const watchAddress = watch("address");

  const handleBirthdateChange = async (data: Date | null) => {
    const value = moment(data, dateFormat).toDate();

    setValue("birthdate", data ? value : null);

    if (!data) {
      await trigger("birthdate");
      return;
    }

    if (calculateAge(value)) {
      setError("birthdate", {
        message: "Вы должны быть совершеннолетним, чтобы пользоваться сервисом",
      });
    } else {
      clearErrors("birthdate");
    }
  };

  const submitForm = async (data: Values) => {
    setDisabled(true);

    const formData = new FormData();

    formData.append("birthdate", moment(data.birthdate).format(dateFormat));
    formData.append(
      "passport_date",
      moment(data.passport_date).format(dateFormat)
    );

    if (!containsTask) {
      data.upload_files.forEach((file: BinaryType) => {
        formData.append("upload_files[]", file);
      });
    }

    delete data.upload_files;
    delete data.birthdate;
    delete data.passport_date;

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    await request(
      ApiPostRequestChangeUserAttr(id, formData),
      () => {
        enqueueSnackbar("Запрос успешно отправлен", {
          variant: "success",
          autoHideDuration: 5000,
        });
        handleCloseModal();
      },
      () => () => {
        enqueueSnackbar("Произошла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setDisabled(false);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className={style.modal}>
      <p className={style.title}>
        Запрос на изменение реквизитов в профиле пользователя {userName}
      </p>
      <div className={style.content}>
        <div className={style.grid}>
          <TextField
            {...textFieldSX}
            label="Фамилия"
            {...register("surname")}
            error={Boolean(errors.surname?.message)}
            helperText={errors.surname?.message}
          />
          <TextField
            {...textFieldSX}
            label="Имя"
            {...register("name", {
              value: profileRequestData.name,
            })}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
          />
          <TextField
            {...textFieldSX}
            label="Отчество"
            {...register("patronymic", {
              value: profileRequestData.patronymic,
            })}
            error={Boolean(errors.patronymic?.message)}
            helperText={errors.patronymic?.message}
          />
          <div className={style.address}>
            <AdvancedAddressField
              name="address"
              register={register}
              defaultValue={watchAddress}
              error={Boolean(errors.address?.message)}
              helperText={String(errors.address?.message)}
              setValue={setValue}
              trigger={trigger}
            />
          </div>
          <div className={style.birth}>
            <DateField
              label="Дата рождения"
              variant={style.designType}
              startDateProp={watchBirthDate}
              changeBlur={async () => {
                await trigger("birthdate");
              }}
              changeDateHandler={handleBirthdateChange}
              error={Boolean(errors.birthdate?.message)}
              helperText={String(errors.birthdate?.message)}
            />
          </div>
          <CountriesType
            variant={style.select}
            changeHandler={async (data) => {
              setValue("country_code", data.id);
              setValue("region_code", "");

              await trigger("country_code");
              await trigger("region_code");
            }}
            value={watchCountryCode}
            label="Страна"
            paperOptions={paperOpt}
          />
          <div>
            <Regions
              id="region_code"
              name="region_code"
              label="Регион"
              value={watchRegionCode}
              countryCode={watchCountryCode}
              blurHandler={async () => {
                await trigger("region_code");
              }}
              changeHandler={(data) => {
                setValue("region_code", data.id);
              }}
              error={Boolean(errors.region_code?.message)}
              paperOptions={paperOpt}
            />
            {Boolean(errors.region_code?.message) && (
              <ErrorMessage
                variant={style.inputError}
                message={String(errors.region_code?.message)}
              />
            )}
          </div>
          <TextField
            fullWidth
            label="Город"
            {...register("city", {
              value: profileRequestData.city,
            })}
            {...textFieldSX}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.city?.message)}
            helperText={errors.city?.message}
          />
        </div>
        <div className={style.lowerGrid}>
          <div className={style.flex}>
            <TextField
              label="Серия паспорта"
              inputProps={{ maxLength: 4 }}
              className={style.byWho}
              {...textFieldSX}
              {...register("passport_serial")}
              error={Boolean(errors.passport_serial?.message)}
              helperText={errors.passport_serial?.message}
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Номер паспорта"
              autoComplete="off"
              inputProps={{ maxLength: 6 }}
              {...textFieldSX}
              {...register("passport_num", {
                value: profileRequestData.passport_num,
              })}
              error={Boolean(errors.passport_num?.message)}
              helperText={errors.passport_num?.message}
            />
            <DateField
              label="Когда выдан"
              variant={style.designType}
              startDateProp={watchPassportDate}
              changeBlur={async () => {
                await trigger("passport_date");
              }}
              changeDateHandler={async (data) => {
                setValue(
                  "passport_date",
                  data ? moment(data, dateFormat).toDate() : null
                );
                await trigger("passport_date");
              }}
              error={Boolean(errors.passport_date?.message)}
              helperText={String(errors.passport_date?.message)}
            />
          </div>
          <TextField
            label="Кем выдан"
            autoComplete="off"
            multiline
            rows={6}
            {...textFieldSX}
            {...register("passport_issued_by", {
              value: profileRequestData.passport_issued_by,
            })}
            error={Boolean(errors.passport_issued_by?.message)}
            helperText={errors.passport_issued_by?.message}
          />
        </div>
        <TextField
          label="Причина изменения персональных данных"
          autoComplete="off"
          multiline
          rows={2}
          className={style.reason}
          {...textFieldSX}
          {...register("content", {
            value: profileRequestData.content,
          })}
          error={Boolean(errors.content?.message)}
          helperText={errors.content?.message}
        />
        <h2 className={style.attach}>Приложите сканы паспорта</h2>
        <Uploader
          autoUpload={containsTask}
          identifier={profileRequestData.taskId}
          canBeEdited={true}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          getDataCallback={ApiGetTaskFiles}
          fileHandler={(data: any) => {
            setValue("upload_files", data);
          }}
        />
      </div>
      <div className={style.button}>
        <CustomButton
          type="submit"
          disabled={disabled}
          style={{ opacity: disabled ? "0.5" : "1" }}
          width={150}
          background={palette.blue}
        >
          Отправить
        </CustomButton>
      </div>
    </form>
  );
};

export default PersonalDataForm;
