import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import classNames from "classnames";
import { Wrapper } from "../../UI/templates";
import style from "./style.module.scss";
import { Paginate } from "../../UI/components/Pagination/Pagination";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { useTitle } from "../../hooks/useTitle";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiDeleteNotice,
  ApiGetNotices,
  ApiPostAllRead,
  ApiPostReadToggle,
} from "../../../app/services/api/notices/notices";
import {
  selectorNotices,
  selectorNoticesMeta,
  setNotices,
} from "../../../app/feature/noticesPage/noticesPage";
import { createPaginationMeta } from "../../../app/feature/indexPages/createData";
import { NoticesList } from "../../UI/components/indexPageLists/NoticesList/NoticesList";
import { NoticeSkeletonList } from "../../UI/components/indexPageLists/NoticesList/NoticeSkeletonList";
import { SortButton } from "../../UI/components/Buttons/SortButton/SortButton";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../app/services/url/createUrlSearchString";
import { ApiGetCountNotices } from "../../../app/services/api/globalCounts/counts";
import { setHeaderCountNotice } from "../../../app/feature/header/header";

export const NoticesPage = () => {
  useTitle("Уведомления");
  const theme = useSelector(selectorAppTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);

  const [searchString, setSearchString] = useState(location.search);
  const [currentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({ sort: "-id" });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    notices: useSelector(selectorNotices),
    meta: useSelector(selectorNoticesMeta),
  };

  const getCountOfNotices = () => {
    request(ApiGetCountNotices(), dispatch(setHeaderCountNotice))();
  };

  const getNotices = (search: string) => {
    return request(ApiGetNotices(search), (res, _, headersValue) => {
      const meta = createPaginationMeta(headersValue);
      dispatch(setNotices(res, meta));
      setLoad(false);
    });
  };

  const onDeleteNotice = async (id: number) => {
    const result = await ApiDeleteNotice(id);
    if (result.status === 204) {
      await getNotices(searchString)(dispatch);
      getCountOfNotices();
    }
  };

  const onReadToggle = (toggle: string, id: number) => {
    request(
      ApiPostReadToggle(toggle, id),
      (data, headersValue, headers, status) => {
        if (status === 200) {
          getNotices(searchString)(dispatch);
          getCountOfNotices();
        }
      }
    )();
  };

  const onReadAll = () => {
    setLoad(true);
    request(ApiPostAllRead(), (data, headersValue, headers, status) => {
      if (status === 200) {
        getNotices(searchString)(dispatch);
        getCountOfNotices();
      }
    })();
  };

  const params = queryString.parse(location.search);

  useEffect(() => {
    // @ts-ignore
    setInitialSearchParams(params);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    navigate({
      search: searchString,
    });

    setLoad(true);
    getNotices(searchString)(dispatch);
  }, [dispatch, searchString, navigate]);

  useEffect(() => {
    if (firstLoad) return;
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const paginate =
    data.notices.length > 0 ? (
      <Paginate
        page={Number(data.meta.paginationCurrentPage)}
        handleChange={onChangePage}
        count={data.meta.paginationPageCount}
        theme={theme}
        variant="outlined"
        shape="rounded"
      />
    ) : (
      <></>
    );

  return (
    <div className={style.pageContainer}>
      <div className={classNames(style.titleContainer, style[theme])}>
        <Wrapper>
          <h1 className={style.pageTitle}>Уведомления</h1>
        </Wrapper>
      </div>
      <div className={classNames(style.controlPanelContainer, style[theme])}>
        <Wrapper>
          <div className={style.controlPanel}>
            <div className={style.paginationWrapper}>{paginate}</div>
            <div className={style.filtersWrapper}>
              <SortButton
                clickHandler={onClickSort}
                posVariant={style.sortButtonMargin}
                sortType="id"
                text="По дате"
                isUp={params.sort === "id"}
                isShow={params.sort === "id" || params.sort === "-id"}
                isDisable={isLoad}
              />
              <SortButton
                clickHandler={onClickSort}
                posVariant={style.sortButtonMargin}
                sortType="relevance"
                text="По релевантности"
                isUp={params.sort === "relevance"}
                isShow={
                  params.sort === "relevance" || params.sort === "-relevance"
                }
                isDisable={isLoad}
              />
              <button
                className={classNames(style.readAllButton, style[theme])}
                onClick={onReadAll}
              >
                Отметить все как прочитанные
              </button>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className={classNames(style.noticesContainer, style[theme])}>
        {isLoad ? (
          <NoticeSkeletonList theme={theme} />
        ) : (
          <NoticesList
            items={data.notices}
            toggleHandler={onReadToggle}
            deleteHandler={onDeleteNotice}
            theme={theme}
          />
        )}
      </div>
      <div className={style.controlPanelContainer}>
        <div className={style.controlPanel}>
          <Wrapper>
            <div>
              <div className={style.paginationWrapper}>{paginate}</div>
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};
