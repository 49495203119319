import { ChangeEvent, FC, useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import style from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getMilestone,
  setAvailvableProcess,
} from "../../../../../../app/feature/milestone";
import {
  addProcess,
  changeActive,
  getAvailableProcesses,
  removeProcess,
} from "../../../../../../app/feature/milestone/thunks";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { TProcess } from "../../../../User/Dashboard/Secondary/types";

export type ProcessModalProps = {
  firmId: number;
  bfType: number;
};

const CreateProcessModal: FC<ProcessModalProps> = ({ firmId, bfType }) => {
  const [selectedId, setSelectedId] = useState<number>(0);

  const dispatch = useAppDispatch();
  const { data, pending } = useAppSelector(getMilestone);

  const processes = data.firmMilestones[bfType] ?? [];

  const handleChangeAvailvableProcess = (
    _: ChangeEvent<HTMLInputElement>,
    value: TSelectItem | null
  ) => {
    if (!value) {
      return;
    }

    dispatch(setAvailvableProcess(value));
  };

  const handleRemoveProcess = (process: TProcess) => {
    if (!window.confirm("Вы уверены, что хотите удалить данную категорию?")) {
      return;
    }

    dispatch(removeProcess({ bfType, process }));
  };

  const handleAddProcess = () => {
    const payload = {
      firm_id: firmId,
      building_function_type: bfType,
      project_part_id: data.availableProcess.id,
      active: true,
    };

    dispatch(
      addProcess({ firmId, bfType, name: data.availableProcess.title, payload })
    );
  };

  const handleChangeActiveProcess = (id: number, active: boolean) => {
    dispatch(changeActive({ id, active, bfType }));
    setSelectedId(id);
  };

  useEffect(() => {
    dispatch(getAvailableProcesses({ firmId, bfType }));

    return () => {
      dispatch(setAvailvableProcess(null));
    };
  }, []);

  return (
    <div className={style.createProcess}>
      <h2>Создание категории</h2>
      <div className={style.controls}>
        <Select
          label="Наименование раздела"
          variant={style.objectType}
          options={data.availableProcesses}
          value={data.availableProcess}
          isLoading={pending.availableProcesses}
          changeHandler={handleChangeAvailvableProcess}
          disableClear
          size="small"
        />
        <CustomButton
          onClick={handleAddProcess}
          background={palette.blue}
          className={style.addButton}
          disabled={pending.addProcess}
          width={150}
        >
          Добавить
        </CustomButton>
      </div>
      {processes.map((process: TProcess, i: number) => (
        <div className={style.process}>
          <CustomCheckbox
            onChange={() =>
              handleChangeActiveProcess(process.id, !process.active)
            }
            checked={process.active}
            editing
            disabled={pending.changeActive && process.id === selectedId}
          />
          <p>{i + 1}</p>
          <p>{process.name}</p>
          <IconButton
            onClick={() => handleRemoveProcess(process)}
            className={style.deleteButton}
          >
            <Trash />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default CreateProcessModal;
