import * as Yup from "yup";
import { datePassedRequired } from "../../../../../utils/SimplifiedYup";

const todayDate = new Date().getDate();

const inn = Yup.mixed()
  .nullable()
  .required("Необходимо заполнить «ИНН»")
  .test(
    "innFormat",
    "ИНН должен иметь длину 10 (юрлицо) или 12 (физлицо) цифр",
    function (value) {
      return Boolean(
        (value?.length === 10 || value?.length === 12) && value?.match(/^\d+$/)
      );
    }
  );

const kpp = Yup.mixed()
  .nullable()
  .test(
    "kppFormat",
    "Значение «КПП» должно содержать максимум 9 цифр",
    function (value) {
      return Boolean(
        (value || "").length
          ? (value || "").length <= 9 && value?.match(/^\d+$/)
          : true
      );
    }
  );

const ogrn = Yup.string().test(
  "ogrnFormat",
  "Значение «ОГРН» должно содержать максимум 15 цифр",
  function (value) {
    return Boolean(
      (value || "").length
        ? (value || "").length <= 15 && value?.match(/^\d+$/)
        : true
    );
  }
);

const dateStart = Yup.date()
  .nullable()
  .required("Необходимо заполнить дату начала действия")
  .test(
    "dateStart",
    "Дата начала действия документа не может быть раньше даты подписи доверенности",
    function (value) {
      if (!value) {
        return true;
      }

      return this.parent.doc_date_reg.getTime() <= value.getTime();
    }
  );

const dateEnd = Yup.date()
  .nullable()
  .required("Необходимо заполнить дату окончания действия")
  .test(
    "dateRange",
    "Дата окончания действия документа не может быть раньше даты начала",
    function (value) {
      if (!value) {
        return true;
      }

      return this.parent.doc_date_start.getTime() <= value.getTime();
    }
  )
  .test(
    "dateValid",
    "Дата окончания действия документа не может быть раньше текущей даты",
    function (value) {
      if (!value) {
        return true;
      }

      return value.getTime() >= todayDate;
    }
  );

const dateReg = datePassedRequired("Дата подписи доверенности");

export const advancedSchema = Yup.object().shape({
  title: Yup.string().required("Необходимо заполнить «Краткое наименование»"),
  email: Yup.string().email("Неверный формат «E-mail»"),
  region: Yup.mixed().test(
    "requiredRegion",
    'Необходимо заполнить "Регион"',
    (value) => !Number.isNaN(Number(value)) && value > 0
  ),
  fio: Yup.string().required("Необходимо заполнить «ФИО доверенного лица»"),
  doc_date_start: dateStart,
  doc_date_end: dateEnd,
  doc_date_reg: dateReg,
  kpp,
  inn,
  ogrn,
});

export const schema = Yup.object().shape({
  title: Yup.string().required("Необходимо заполнить «Краткое наименование»"),
  email: Yup.string().email("Неверный формат «E-mail»"),
  kpp,
  inn,
  ogrn,
});
