import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import {
  getProjectProcessBadApplications,
  getProjectProcessGoodApplications,
} from "src/app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { getProjectProcessRequestIsLoad } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";
import { palette } from "src/styles/restyle";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useModalsRequestUpdates } from "src/FSD/entities/publishObject/lib/hooks/useModalsRequestUpdates";
import styles from "./Executor.module.scss";
import PublishObjectModals from "../../../../../publishObject/modals";

export const ExecutorSearch = () => {
  const goodApplications = useSelector(getProjectProcessGoodApplications);
  const badApplications = useSelector(getProjectProcessBadApplications);
  const isLoadRequest = useAppSelector(getProjectProcessRequestIsLoad);
  const [currentTab, setCurrentTab] = useState("");
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const [modalRequest, setModalRequest] = useState(false);

  const countApplications =
    (goodApplications?.length || 0) + (badApplications?.length || 0);

  const closeModalRequest = useCallback(() => {
    setModalRequest(false);
  }, []);

  const setApplicationsTab = useCallback(
    () => setCurrentTab("applications"),
    []
  );

  const {
    requestUpdate: requestProcess,
    handleCloseRequestUpdate: handleCloseRequestProcess,
    handleOpenRequestUpdate: handleOpenRequestProcess,
  } = useModalsRequestUpdates();

  const {
    id,
    secure,
    with_docs: withDocs,
    executorType,
    title,
    bim,
    date_start: dateStartCustomer,
    date_limit: dateLimitCustomer,
    price: priceCustomer,
  } = infoData;

  const executorTypes = executorType.map((item) => item.value);
  const nameProject = `«${infoData.project.name}${infoData.project.code ? ` (${infoData.project.code})` : ""}»`;
  const header = `Заявка на исполнение раздела «${title}» по проекту ${nameProject}`;

  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <Modal
          isActive={requestProcess.isOpen}
          handleClose={handleCloseRequestProcess}
        >
          <PublishObjectModals.RequestUpdateForm
            publicationObjectValues={requestProcess}
            secure={secure}
          />
        </Modal>
        <h2>Исполнитель</h2>
        {infoData.can.requestExecution && (
          <>
            <CustomButton
              onClick={() => {
                handleOpenRequestProcess(
                  id,
                  header,
                  executorTypes,
                  bim,
                  withDocs,
                  dateStartCustomer,
                  dateLimitCustomer!,
                  +priceCustomer
                );
              }}
              width={141}
              height={32}
              fontSize={16}
              borderRadius={4}
              background={palette.oceanic}
              color={palette.midnightBlack}
            >
              Заявиться
            </CustomButton>
          </>
        )}
      </div>
      <div className={styles.executor_info}>
        <div className={styles.executor_text}>
          <span>Требуемая форма собственности</span>
          <RequiredTypes
            types={infoData.executorType}
            classname={styles.executor_type}
          />
        </div>
        {countApplications > 0 && (
          <div className={styles.applications}>
            <div onClick={setApplicationsTab}>{countApplications}</div>
            <p>Кол-во заявок</p>
          </div>
        )}
        <hr className={styles.yellowLine} />
      </div>
    </div>
  );
};

export default ExecutorSearch;
