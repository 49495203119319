import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  fetchProjectCompareGip,
  fetchTaskComplete,
  ProjectCompareGipReturn,
} from "./thunks";

type InitialState = {
  isLoad: boolean;
  compareGip: ProjectCompareGipReturn[];
  error: any;
  isCompleteGip: boolean;
};

const initialState: InitialState = {
  isLoad: false,
  compareGip: [],
  isCompleteGip: false,
  error: null,
};

const projectCompareGipSlice = createSlice({
  name: "projectCompareGip",
  initialState,
  reducers: {
    setError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCompareGip.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectCompareGip.fulfilled, (state, action) => {
      state.isLoad = false;
      state.compareGip = action.payload;
    });
    builder.addCase(fetchProjectCompareGip.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchTaskComplete.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchTaskComplete.fulfilled, (state) => {
      state.isLoad = false;
      state.isCompleteGip = true;
    });
    builder.addCase(fetchTaskComplete.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
});
export const getProjectCompareGip = (state: RootState) =>
  state.projectCompareGip;
export const { setError } = projectCompareGipSlice.actions;
export default projectCompareGipSlice.reducer;
