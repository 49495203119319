import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы поиска ГИПа на проект
 */

export const showEventSearchProjectGip = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventSearchProjectGip: {
      ...state.eventSearchProjectGip,
      isShow: true,
    },
  };
};

export const closeEventSearchProjectGip = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventSearchProjectGip: {
    isShow: false,
    data: {
      ...state.eventSearchProjectGip.data,
    },
  },
});

export const selectorEESearchProjectGip = (state: StateType) =>
  state.eventEmitter.eventSearchProjectGip.isShow;
