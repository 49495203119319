import { PublishObjectModel } from "src/FSD/entities/publishObject";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalsRequestUpdates = () => {
  const dispatch = useAppDispatch();

  const { requestUpdate } = useAppSelector(
    PublishObjectModel.modals.getModalsData
  );

  const handleOpenRequestUpdate = (
    id: number,
    header: string,
    executorTypes: string[],
    bim: boolean,
    withDocs: boolean,
    dateStartCustomer: string,
    dateLimitCustomer: string,
    priceCustomer: number
  ) => {
    dispatch(
      PublishObjectModel.modals.setRequestUpdateActive({
        isOpen: true,
        id,
        header,
        executorTypes,
        bim,
        withDocs,
        dateStartCustomer,
        dateLimitCustomer,
        priceCustomer,
      })
    );
  };

  const handleCloseRequestUpdate = () => {
    dispatch(
      PublishObjectModel.modals.setRequestUpdateActive({
        isOpen: false,
        id: null,
        header: "",
        executorTypes: [],
        bim: false,
        withDocs: false,
        dateStartCustomer: null,
        dateLimitCustomer: null,
        priceCustomer: null,
      })
    );
  };

  return {
    requestUpdate,
    handleOpenRequestUpdate,
    handleCloseRequestUpdate,
  } as const;
};
