import { IProfileReducer } from "./types";

export const initialState: IProfileReducer = {
  profileIsLoading: false,
  regionsIsLoading: false,
  rolesIsLoading: false,
  countriesIsLoading: false,
  timeZonesIsLoading: false,
  skillsListIsLoading: false,
  addressOptionsIsLoading: false,
  jobPlacesListIsLoading: false,
  educationListIsLoading: false,
  rewardListIsLoading: false,
  buildingFunctionsIsLoading: false,
  portfolioListIsLoading: false,
  paymentInfoIsLoading: false,
  notificationsIsLoading: false,
  searchOrganisationsIsLoading: false,
  userFirmsListIsLoading: false,
  profileRequestLoading: false,
  profile: {
    region: null,
    city: "",
    address: "",
    name: "",
    surname: "",
    patronymic: "",
    birthdate: "",
    timezone: null,
    phone: null,
    email: null,
    photo: "",
    sex: {
      key: null,
      value: null,
    },
    skills: [],
    userTypes: [],
    type: {
      key: 0,
      value: "",
    },
    work_with_bim: false,
    about: "",
    can: {
      hasDocumentAccess: false,
      invFirm: false,
      invFirm_v2: [],
      invProject: false,
    },
  },
  paymentInfo: null,
  notifications: null,
  regions: [],
  skillsList: [],
  userRoles: [],
  countriesList: [],
  timeZones: [],
  addressOptions: [],
  jobPlacesList: [],
  educationList: [],
  rewardList: [],
  buildingOptions: [],
  portfolioList: [],
  searchOrganisationsList: [],
  searchOrganisationsMeta: null,
  userFirmsList: [],
  firmData: {
    ogrn: "",
    inn: "",
    title: "",
    title_full: "",
    address: "",
    country_code: "RU",
    region_code: 0,
    kpp: "",
    manager_name: "",
    manager_post: "",
    boss_prof: "",
    city: "",
  },
  profileRequestData: {
    taskId: 0,
    id: 0,
    surname: "",
    name: "",
    patronymic: "",
    city: "",
    passport_issued_by: "",
    content: "",
    address: "",
    passport_serial: "",
    passport_num: "",
    country_code: "RU",
    region_code: "1",
    passport_date: new Date(),
    birthdate: new Date(),
    upload_files: [],
  },
};
