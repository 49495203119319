import { createAsyncThunk } from "@reduxjs/toolkit";
import { pushError, pushMessage } from "../../errorTrace";
import { request } from "../../../services/api/requestHandler";
import { httpRequestGet, httpRequestPost } from "../../../services/api/api";
import { config } from "../../../../app.cofig";
import { getPreparedBody } from "./utils";

const ApiGetGroupRequestProcess = (id: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-access/get-group-request-start-forms?processId=${id}`
  );

const ApiGroupRequest = (id: number, body: any): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/project-access/group-request?processId=${id}`,
    {},
    body
  );

export const getGroupRequestProcesses = createAsyncThunk(
  "project/getGroupRequestProcess",
  async (projectId: number, { dispatch }) => {
    let processes: any = [];
    await request(
      ApiGetGroupRequestProcess(projectId),
      (response) => {
        processes = response;
      },
      () => (err) => {
        dispatch(pushError(err));
        throw Error();
      }
    )();

    return processes;
  }
);

export const groupRequest = createAsyncThunk(
  "project/groupRequest",
  async (
    { processId, body }: { processId: number; body: any },
    { dispatch, rejectWithValue }
  ) => {
    let hasError = false;
    const preparedBody = getPreparedBody(body);

    await request(
      ApiGroupRequest(processId, preparedBody),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        hasError = true;
        dispatch(pushError(err));
      }
    )();

    if (!hasError) {
      return;
    }

    return rejectWithValue("");
  }
);
