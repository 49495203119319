import React from "react";
import { ClickAwayListener, Popper } from "@mui/material";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { NotApproveIcon } from "../SVG";
import style from "./SourceDataView.module.scss";
import { DateCreate } from "../../features/SpecialForm/DateCreate/DateCreate";
import { CircularIndeterminate } from "../../UI/components/Project/Skeleton";

interface DateStartPopperProps {
  canEditDate: boolean;
  date_create: string;
  date_deadline: string;
  isMobile: boolean;
  onChangeDate: (data: any) => void;
  isLoadDateUpdate: boolean;
}
export const DateStartPopper = ({
  canEditDate,
  date_create,
  date_deadline,
  onChangeDate,
  isLoadDateUpdate,
  isMobile,
}: DateStartPopperProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { control } = useForm();
  const start = moment(date_create, "DD.MM.YYYY").toDate();
  const end = moment(date_deadline, "DD.MM.YYYY").toDate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const changeDateStart = async (date: Date | null) => {
    await onChangeDate({
      startDate: date,
      endDate: end,
    });
    setAnchorEl(null);
  };

  return (
    <div className={style.text}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <span
            className={classNames(
              style.text_edit,
              !canEditDate && style.text_noEdit
            )}
            onClick={handleClick}
          >
            {date_create}
          </span>
          {open && canEditDate ? (
            // @ts-ignore
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement={isMobile ? "bottom" : "left-start"}
            >
              <form className={style.createData}>
                <div className={style.createData_flex}>
                  <h3>Дата запроса</h3>
                  <div onClick={() => setAnchorEl(null)}>
                    <NotApproveIcon width="16" heigth="16" />
                  </div>
                </div>
                <Controller
                  control={control}
                  name="startDate"
                  render={() => (
                    <DateCreate
                      changeDateHandler={(date) => changeDateStart(date)}
                      startDateProp={start}
                      minDate={end}
                    />
                  )}
                />
                <div className={style.createData_btn}>
                  {isLoadDateUpdate && (
                    <div className={style.createData_btn_load}>
                      <CircularIndeterminate />
                    </div>
                  )}
                </div>
              </form>
            </Popper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};
