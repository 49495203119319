import React from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import style from "../style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { RegionSelect } from "../../../features/SpecialForm/RegionSelect";
import { createSearchObjectDataFromFormParams } from "../../../../app/services/searchParams/searchParamsCreateData";
import { FirmName } from "../../../features/SpecialForm/FirmName";
import { Specialization } from "../../../features/SpecialForm/Specialization";
import { buttonStyle } from "../Executors/ExecutorFormHead";
import { textFieldSX } from "../../../../styles/restyle";

type TFormValues = Record<string, any>;

export type ProvidersFormHeadType = ComponentPropsType<{
  submitHandler?: (params: { [key: string]: any }) => void;
  resetHandler?: () => void;
  initialValues?: {
    [key: string]: any;
  };
}>;

export const ProvidersFormHead: React.FC<ProvidersFormHeadType> = ({
  theme = "dark",
  submitHandler = () => {},
  resetHandler = () => {},
  initialValues = {},
}) => {
  const { register, setValue, watch, handleSubmit, reset } =
    useForm<TFormValues>({
      defaultValues: {
        fio: initialValues["ProviderUserSearch[fio]"],
        region: initialValues["ProviderUserSearch[region_code]"],
        firmName: initialValues["ProviderUserSearch[firmName]"],
        specialization: initialValues["ProviderUserSearch[skillIds]"],
        text: initialValues["ProviderUserSearch[about]"],
      },
    });

  const onSubmit = (data: TFormValues) => {
    const searchData = createSearchObjectDataFromFormParams(data, "provider");
    submitHandler(searchData);
  };

  const watchFirmName = watch("firmName");
  const watchRegion = watch("region");
  const watchSpecialization = watch("specialization");

  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div className={style.formContainer}>
        <div className={style.formGroup}>
          <TextField
            {...register("text")}
            {...textFieldSX}
            label={"По тексту"}
            className={classNames(style.formElement, style.firstField)}
          />
          <FirmName
            variant={style.formElement}
            changeHandler={(data) => {
              setValue("firmName", data);
            }}
            value={watchFirmName}
          />
          <RegionSelect
            variant={style.formElement}
            changeHandler={(data) => {
              setValue("region", data ? data.code : undefined);
            }}
            value={watchRegion}
          />
          <Specialization
            changeHandler={(data) =>
              setValue("specialization", data ? data.id : undefined)
            }
            value={watchSpecialization}
            variant={style.formElement}
            label={"Специализация"}
          />
        </div>
        <div className={style.right}>
          <div className={style.buttonFormWrapper}>
            <Button
              theme={theme}
              variant="contained"
              color="primary"
              type={"submit"}
              style={buttonStyle}
            >
              Поиск
            </Button>
            <Button
              theme={theme}
              variant="contained"
              color="grey"
              style={buttonStyle}
              clickHandler={() => {
                resetHandler();
                reset();
                setValue("fio", "");
                setValue("region", undefined);
                setValue("specialization", undefined);
                setValue("text", "");
                setValue("firmName", "");
              }}
            >
              Сбросить
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
