import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { ManageTeammateForm } from "./ManageTeammateForm";
import { RemoveTeammateForm } from "./RemoveTeammateForm";
import { selectorManageTeammateData } from "../../../app/feature/eventEmitter/organisation/eventManageTeammate";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const ManageTeammate: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;
  const { Popup } = useUserWorkflow();

  const { add } = useSelector(selectorManageTeammateData);

  const onClose = () => {
    Popup.manageTeammate.set({});
    Popup.manageTeammate.close();
  };

  return (
    <Modal>
      {add ? (
        <PopupUI
          closeHandler={onClose}
          head={<p style={{ color: "white" }}>Добавить в команду</p>}
          body={<ManageTeammateForm />}
        />
      ) : (
        <PopupUI
          closeHandler={onClose}
          head={<p style={{ color: "white" }}>Удалить из команды</p>}
          body={<RemoveTeammateForm />}
        />
      )}
    </Modal>
  );
};
