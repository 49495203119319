import React from "react";
import ReactHtmlParser from "react-html-parser";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { OrganizationLabelProps } from "../../OrganizationLabel/OgranizationLabel";
import style from "../style.module.scss";
import { AvatarMenu } from "../AvatarMenu/AvatarMenu";
import { AdditionalInfoProps } from "../AdditionalInfo/AdditionalInfo";

type CreateAvatarProps = ComponentPropsType<{
  avatarUrl?: string;
  titleAvatar?: string;
  isOnline?: boolean;
}>;

export type MenuItemProps = ComponentPropsType<
  {
    isActive?: boolean;
    chatIconType: CreateAvatarProps;
    paddingLeft?: number;
  } & OrganizationLabelProps &
    AdditionalInfoProps & {
      clickItemHandler?: (userData: {
        roomId: string;
        userName: string;
        avatarUrl: string;
      }) => void;
    }
>;

const CreateAvatar: React.FC<CreateAvatarProps> = React.memo(
  ({ avatarUrl = "", titleAvatar = "", theme = "dark" }) => {
    return <AvatarMenu url={avatarUrl} title={titleAvatar} theme={theme} />;
  }
);

export const MenuItem: React.FC<MenuItemProps> = React.memo(
  ({ title, chatIconType, newMessage, paddingLeft = 0 }) => {
    return (
      <>
        <div
          className={style.labelItemMenu}
          style={{ paddingLeft, width: "100%" }}
        >
          <div className={"group"}>
            <div>
              <CreateAvatar {...chatIconType} titleAvatar={title} />
            </div>
            <span className={"title"}>{ReactHtmlParser(title)}</span>
          </div>
          {!!Number(newMessage) && (
            <span className={"count"}>{newMessage}</span>
          )}
        </div>
      </>
    );
  }
);
