import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../app/services/api/requestHandler";
import {
  ApiDeleteTask,
  ApiGetTaskView,
  ApiTaskComplete,
  ApiTaskCompleteMultiData,
} from "./queries";
import { TCompleteTaskForm } from "./requestTypes";
import { ThunkArg } from "../../../lib/types/thunkArg";

export const getView = createAsyncThunk(
  "task/getView",
  async (
    { data: { id }, events }: ThunkArg<{ id: number }>,
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    events.onPending?.();

    await request(
      ApiGetTaskView(id),
      (data) => {
        events.onFulfilled?.(data);
        response = data;
      },
      () => (err) => {
        events.onRejected?.(err);
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    if (response) {
      return response;
    }
  }
);

export const complete = createAsyncThunk(
  "task/complete",
  async (
    {
      data: { id, formedData },
      events,
    }: ThunkArg<{ id: number; formedData: TCompleteTaskForm }>,
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    events.onPending?.();

    await request(
      ApiTaskComplete(id, formedData),
      (data) => {
        events.onFulfilled?.(data);
        response = data;
      },
      () => (err) => {
        events.onRejected?.(err);
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    if (response) {
      return response;
    }
  }
);

export const completeMultiData = createAsyncThunk(
  "task/complete",
  async (
    {
      data: { id, formData },
      events,
    }: ThunkArg<{ id: number; formData: FormData }>,
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    events.onPending?.();

    await request(
      ApiTaskCompleteMultiData(id, formData),
      (data) => {
        events.onFulfilled?.(data);
        response = data;
      },
      () => (err) => {
        events.onRejected?.(err);
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    if (response) {
      return response;
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/delete",
  async (
    { data: { id }, events }: ThunkArg<{ id: number }>,
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    events.onPending?.();

    await request(
      ApiDeleteTask(id),
      (data) => {
        events.onFulfilled?.(data);
      },
      () => (err) => {
        events.onRejected?.(err);
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    if (response) {
      return response;
    }
  }
);
