import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  getProjectCheckTasks,
  setError,
} from "../../../../../app/feature/ProjectView/CheckTaskReducer/projectCheckTask";
import style from "./style.module.scss";
import { CheckTaskCard } from "./CheckTaskCard";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { SkeletonCheckTask } from "../../../../UI/components/Project/Skeleton";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";

interface ICheckTasksProps {
  setCurrentTabMemo: () => void;
}
export const CheckTasks: React.FC<ICheckTasksProps> = ({
  setCurrentTabMemo,
}) => {
  const { checkTasks, isLoad, error } = useSelector(getProjectCheckTasks);
  const { project } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  if (project && (!project.can.edit || !project.can.editByGipHelper)) {
    setCurrentTabMemo();
  }

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  if (isLoad) {
    return <SkeletonCheckTask />;
  }
  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (checkTasks) {
    return (
      <div className={style.wrapper}>
        {checkTasks.map((checkTask) => (
          <CheckTaskCard checkTask={checkTask} key={checkTask.title} />
        ))}
      </div>
    );
  }
  return <div></div>;
};
