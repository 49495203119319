import React from "react";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { ApiPrimaryFileType } from "src/types/api/primaryTypes/apiPrimaryFileType";
import styles from "./SourcesFiles.module.scss";

interface ISourcesFilesProps {
  files: ApiPrimaryFileType[];
}

export const SourcesFiles = (props: ISourcesFilesProps) => {
  const { files } = props;

  return (
    <div className={styles.sourceFiles}>
      <h2>Исходные данные</h2>
      {files.map((file) => {
        return (
          <TextLink
            variant={styles.textLink}
            redirect
            target="blank"
            url={file.url}
            key={file.id}
          >
            {file.name}
          </TextLink>
        );
      })}
    </div>
  );
};
