import { ReactNode } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import styles from "./RequestUpdateForm.module.scss";
import { setMissingAttributes } from "../lib/helpers/setMissingAttributes";

type TProps = {
  typeMissingAttributes?: ApiCommonResponses.SecureMissingAttributes[];
  children: ReactNode;
};

export const MissingAttributesError = ({
  typeMissingAttributes,
  children,
}: TProps) => {
  return (
    <div className={styles.missingAttributes}>
      <Paragraph color="error">{children}</Paragraph>
      <ul className={styles.missingAttributes__ul}>
        {typeMissingAttributes?.map((item, i) => (
          <li key={i} className={styles.missingAttributes__li}>
            {setMissingAttributes(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};
