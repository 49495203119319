import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import StarsRating from "./StarsRating";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../app/feature/app/app";
import { ratingDefaultStringValue } from "../../../app/services/searchParams/configFilterParams";

export type RatingProps = {
  changeHandler: (value: any) => void;
  value: string;
};

export const Rating: React.FC<RatingProps> = ({
  changeHandler = () => {},
  value = ratingDefaultStringValue,
}) => {
  const theme = useSelector(selectorAppTheme);
  const handleChange = (newValue: any) => changeHandler(newValue);

  return (
    <div className={classNames(style.sliderWrapper, style[theme])}>
      <p>Рейтинг</p>
      <StarsRating value={value} handleChange={handleChange} />
    </div>
  );
};

/*

      <Slider
        value={value.split(";").map((item) => Number(item))}
        onChange={handleChange}
        valueLabelDisplay="auto"
        defaultValue={0}
        aria-labelledby="discrete-slider-small-steps"
        step={1}
        marks
        className={style.sliderWrapperInner}
        min={Number(min)}
        max={Number(max)}
      />

* */
