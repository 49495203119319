import { ApiProjectProcessDataType } from "../../../../../types/api/responseTypes/apiProjectProcessType";
import { ProjectsProcessDataType } from "../../../../../types/stateTypes/indexPages/IndexProjectProcessDataType";

export const createProjectProcessFromApi = (
  data: ApiProjectProcessDataType[]
): ProjectsProcessDataType[] => {
  return data.map((item) => {
    return {
      id: item.id,
      name: item.title,
      dateStart: item.project.date_start,
      dateEnd: item.project.date_end,
      author: {
        id: item.project.author?.id,
        name: item.project.author?.name,
        surname: item.project.author?.surname,
        photo: item.project.author?.photo,
        type: item.project.authorType?.listName,
      },
      gip: item.project.gip
        ? {
            id: item.project.gip.id,
            name: item.project.gip.name,
            surname: item.project.gip.surname,
            photo: item.project.gip.photo,
            type: item.project.gipAccess[0]?.userType?.listName,
          }
        : null,
      designType: item.project.designType.value,
      expertise: item.project.expertise,
      vor: item.vor,
      vor_pnr: item.vor_pnr,
      pay1: item.pay1,
      pay2: item.pay2,
      pay3: item.pay3,
      typeName: item.typeName,
      price: item.price,
      secure: item.secure,
      withDocs: item.with_docs,
    };
  });
};
