import React, { memo, useRef, useState } from "react";
import { useParams } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import cls from "./Processes.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import {
  ThreeDotButton,
  RefType,
} from "../../../../newUI/ThreeDotButton/ThreeDotButton";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalFiles } from "./ModalFiles/ModalFiles";
import { ApiPrimaryFileType } from "../../../../../types/api/primaryTypes/apiPrimaryFileType";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "../../../../../app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { fetchStageList } from "../../../../../app/feature/project/projectChecklist/thunks";

interface ProcessesProps {
  name?: string;
  files?: ApiPrimaryFileType[];
  canAdd?: boolean;
}
export const Processes: React.FC<ProcessesProps> = memo(
  ({ name, files, canAdd }) => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [toggleProcesses, setToggleProcesses] = useState(false);
    const [modalFiles, setModalFiles] = useState(false);
    const threeDotButtonRef = useRef<RefType>(null);
    const processId = useAppSelector(getProjectProcessInfoId);

    const closeThreeDot = () => {
      if (threeDotButtonRef.current) {
        threeDotButtonRef.current.handleClose();
      }
    };

    function addFilesHandler() {
      setModalFiles(true);
      closeThreeDot();
    }

    function modalFilesClose() {
      setModalFiles(false);

      if (processId) {
        dispatch(fetchProjectProcessInitialData(processId as number));
        return;
      }
      if (params.id) {
        dispatch(fetchStageList(Number(params.id)));
      }
    }

    return (
      <>
        <div onClick={() => setToggleProcesses(!toggleProcesses)}>
          <Accordion
            text={name || "Дополнительные исходные данные к разделу"}
            toogle={toggleProcesses}
          />
        </div>
        {toggleProcesses && (
          <div className={cls.card}>
            <div className={cls.card_header}>
              <p>Наименование</p>
              <div className={cls.card_header_btn}>
                {canAdd && (
                  <Modal active={modalFiles} setActive={setModalFiles}>
                    <ModalFiles modalClose={modalFilesClose} />
                  </Modal>
                )}
                {canAdd && (
                  <ThreeDotButton ref={threeDotButtonRef}>
                    {files && files.length > 0 && (
                      <TextLink
                        url={`/project-process/download-files?id=${processId}`}
                        variant={cls.textLink}
                        redirect
                        target="_blank"
                      >
                        <MenuItem disableRipple>Скачать все</MenuItem>
                      </TextLink>
                    )}
                    <MenuItem onClick={addFilesHandler} disableRipple>
                      Добавить исходные данные
                    </MenuItem>
                  </ThreeDotButton>
                )}
              </div>
            </div>
            <div className={cls.card_content}>
              {files?.map((el) => (
                <TextLink
                  key={el?.url}
                  variant={cls.textLink}
                  redirect
                  target="_blank"
                  url={el?.url}
                >
                  {el?.name}
                </TextLink>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
);
