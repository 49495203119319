import { RootState } from "../../../../store";

export const getProjectProcessGoodApplications = (state: RootState) =>
  state.projectProcessView.applications.goodApplications;

export const getProjectProcessBadApplications = (state: RootState) =>
  state.projectProcessView.applications.badApplications;

export const getProjectProcessApplicationsMessages = (state: RootState) =>
  state.projectProcessView.applications.messages;
