import {
  useViewQuery,
  useUpdateDatesMutation,
} from "src/FSD/entities/schedulePlan/api";
import { dateMapper } from "../helpers/dateMapper";

type TProps = {
  id: number;
};

export const useEditDate = ({ id }: TProps) => {
  const skip = !id;
  const { data, isFetching, isLoading } = useViewQuery(
    {
      id,
    },
    { skip }
  );

  const [schedulePlanUpdate, { isLoading: graphUpdate }] =
    useUpdateDatesMutation();

  const dates = dateMapper(data);

  return {
    ...dates,
    isLoadSchedule: isFetching && isLoading,
    schedulePlanUpdate,
    graphUpdate,
  };
};
