export function getAuthorNames(arr: any) {
  const result: Record<number, string> = {};
  for (let i = 0; i < arr.length; i++) {
    const { id, name, delete: canDelete } = arr[i];

    if (canDelete) {
      result[id] = name;
    }
  }
  return result;
}
