import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";

export const selectArrayToUrlParams = (
  variable: string,
  value: TSelectItem[] | undefined
) => {
  if (!value) {
    return {};
  }

  return {
    [variable]: value.map((item) => item.id),
  };
};
