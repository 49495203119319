import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { ArrowBack } from "@mui/icons-material";
import { useTitle } from "../../hooks/useTitle";
import style from "./style.module.scss";
import {
  selectorPostItems,
  selectorPostMeta,
  setPostItems,
} from "../../../app/feature/indexPages/postPage/postPage";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetPostsListByTag } from "../../../app/services/api/posts/posts";
import { createPaginationMeta } from "../../../app/feature/indexPages/createData";
import { NewsListSkeleton } from "../../UI/components/indexPageLists/ArticlesList/ArticlesSkeleton/ArticlesListSkeleton";

import {
  NewsListLayoutProps,
  NewsPageTitle,
  MainPost,
  Post,
  NothingFound,
  PaginationProps,
} from "../../UI/components/indexPageLists/ArticlesList/ArticlesFragments";
import { Paginate } from "../../UI/components/Pagination/Pagination";
import { selectorAppTheme } from "../../../app/feature/app/app";
import {
  InitialParamType,
  MetaType,
} from "../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../app/services/url/createUrlSearchString";
import { titleInfo } from "./NewsPage";

export const Pagination: React.FC<PaginationProps> = ({ pagination }) => {
  return <div className={style.pagination}>{pagination}</div>;
};

export const NewsPageLayout: React.FC<NewsListLayoutProps> = ({ posts }) => {
  const navigate = useNavigate();
  const paramsObj = useParams<{ params: string }>();
  const params = paramsObj.params ?? "";

  const threePosts: boolean = posts.length === 3;
  const remainedPosts: number = -Math.max(0, posts.length - 4);
  const existingPosts: boolean = remainedPosts < 0;

  return (
    <div className={classNames(style.wrapper, style.extraPadding)}>
      <br />
      <button className={style.linkBack} onClick={() => navigate("/blog")}>
        <ArrowBack />
      </button>
      <NewsPageTitle
        bigFont
        boldTitle={titleInfo[params].boldTitle}
        normalTitle={titleInfo[params].normalTitle}
        text={titleInfo[params].text}
      />
      {posts.length ? (
        <div className={style.generalPosts}>
          <MainPost
            key={posts[0]?.id}
            id={posts[0]?.id}
            imageSource={posts[0]?.photoUrl}
            date={posts[0]?.date_publication}
            title={posts[0]?.title}
            content={posts[0]?.content}
          />
          <div className={style.articlesList}>
            {posts.slice(0, 4).map((post) => (
              <Post
                id={post?.id}
                key={post?.id}
                imageSource={post?.photoUrl}
                date={post?.date_publication}
                title={post?.title}
                content={post?.content}
              />
            ))}
            {threePosts && <div className={style.article} />}
          </div>
        </div>
      ) : (
        <NothingFound />
      )}
      {existingPosts && (
        <div className={classNames(style.platformNews, style.wide)}>
          {posts.slice(remainedPosts).map((post) => (
            <Post
              id={post?.id}
              key={post?.id}
              imageSource={post?.photoUrl}
              date={post?.date_publication}
              title={post?.title}
              content={post?.content}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const converter: { readonly [key: string]: string } = {
  library: "Библиотека",
  platform: "Новости Платформы",
  face: "От первого лица",
  media: "ИноСМИ",
};

export const NewsList = () => {
  const theme = useSelector(selectorAppTheme);
  const paramsObj = useParams<{ params: string }>();
  const params = paramsObj.params ?? "";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchString, setSearchString] = useState(location.search);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({});

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    posts: useSelector(selectorPostItems),
    meta: useSelector(selectorPostMeta),
  };

  const id = queryString.parse(location.search);

  useTitle(converter[params] ?? "Неизвестная страница");

  useEffect(() => {
    // @ts-ignore
    setInitialSearchParams(id);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    setLoad(true);

    navigate({
      search: searchString,
    });

    if (!converter[params]) {
      navigate("/blog");
      return;
    }

    const searchStringParam =
      searchString.includes("?") && searchString.length > 1
        ? `${searchString}&`
        : "?";
    request(
      ApiGetPostsListByTag(searchStringParam, converter[params], 10),
      (res, _, headersValue) => {
        const meta = createPaginationMeta(headersValue);
        dispatch(setPostItems(res, meta));
        setLoad(false);

        window.scrollTo(0, 0);
      }
    )(dispatch);
  }, [dispatch, navigate, searchString, params]);

  useEffect(() => {
    if (firstLoad) {
      return;
    }
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
      },
      setSearchString
    );
  }, [currentMetaPage, currentInitialSearchParams, firstLoad]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const pagination =
    data.posts.length > 0 && data.meta.paginationAllPageCount > 10 ? (
      <Paginate
        page={Number(data.meta.paginationCurrentPage)}
        handleChange={onChangePage}
        count={data.meta.paginationPageCount}
        theme={theme}
        variant="outlined"
        shape="rounded"
      />
    ) : null;

  return (
    <>
      {data.meta.paginationAllPageCount > 10 && (
        <Pagination meta={data.meta} pagination={pagination} />
      )}
      {isLoad ? <NewsListSkeleton /> : <NewsPageLayout posts={data.posts} />}
      {data.meta.paginationAllPageCount > 10 && (
        <Pagination meta={data.meta} pagination={pagination} />
      )}
    </>
  );
};
