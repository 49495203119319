import {
  CounterType,
  ExecutorDataListType,
} from "../../../../../../types/stateTypes/indexPages/indexPageUserDataType";
import {
  createUserRating,
  getUserFullStringAddress,
  getUserName,
  userExperience,
} from "../../../../../services/user/user";
import { ApiUserExecutorType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { config } from "../../../../../../app.cofig";

export const createCounterByExecutor: (
  data: ApiUserExecutorType
) => CounterType[] = (data) => {
  return [
    {
      title: "Стаж в отрасли",
      value: userExperience(data.additionalInfo!.job_duration, false),
      url: `/user/${data.id}?tab=jobPlace`,
    },
    {
      title: "Выполнено работ",
      value: data.executorDoneProjectCount,
      url: `/project/search?ProjectSearch[status]=&ProjectSearch[executorId]=${data.id}&ProjectSearch[executorProcessStatus]=3&ProjectSearch[withoutGip]=0`,
    },
    {
      title: "Отзывы",
      value: data.feedbacks?.length || 0,
      url: `/user/${data.id}?tab=feedback`,
    },
  ];
};

export const createExecutorListFromApi: (
  data: ApiUserExecutorType[]
) => ExecutorDataListType = (data) =>
  data.map((item) => ({
    name: getUserName(item),
    address: item.region ? getUserFullStringAddress(item) : null,
    aboutText: item.about!,
    counterItems: createCounterByExecutor(item),
    id: item.id,
    isConfirm: item.tested!,
    premium: item.premium,
    ratingValue: createUserRating(Number(item.rating!.executor)),
    specialItems: item.skills!.map((item) => item.name),
    avatarUrl: `${config.localDomain}${item.photo}`,
    executorDoneProjectCount: item.executorDoneProjectCount,
    workWithBim: item.work_with_bim!,
    skills: item.skills,
  }));
