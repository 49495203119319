import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = RequestParams<{
  id: number;
}>;

export const projectUnPause = (builder: QueryBuilder) =>
  builder.mutation<boolean, TParams>({
    query: (params) => ({
      url: `v1/project/unpause`,
      params: {
        id: params.id,
      },
      method: HTTPMethods.POST,
    }),
    invalidatesTags: ["Project"],
  });
