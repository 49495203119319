import { RootState } from "../../../../store";

export const getProjectProcessMaterialTemplateListIsLoad = (state: RootState) =>
  state.projectProcessView.material.isLoadTemplateList;

export const getProjectProcessMaterialTemplateCreateListIsLoad = (
  state: RootState
) => state.projectProcessView.material.isLoadCreateMaterial;

export const getProjectProcessMaterialTemplateList = (state: RootState) =>
  state.projectProcessView.material.templateList;
