import { httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessStage = (
  processId: number
): Promise<Response> => {
  // получить вехи
  return httpRequestGet(
    `${config.localDomain}/v1/milestone/project-process-stage/index?ProjectProcessStageSearch[project_process_id]=${processId}&expand=can,link`
  );
};

export const ApiProjectProcessStageComplete = (
  stageId: number
): Promise<Response> => {
  // завершить веху
  return httpRequestPost(
    `${config.localDomain}/v1/milestone/project-process-stage/complete?id=${stageId}`
  );
};
