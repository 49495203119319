import { createSlice } from "@reduxjs/toolkit";
import { taskCompleteThunk } from "../services/taskCompleteThunk";

type InitialState = {
  isLoadTaskComplete: boolean;
  completeSuccess: boolean;
  error422: any;
};

const initialState: InitialState = {
  isLoadTaskComplete: false,
  completeSuccess: false,
  error422: undefined,
};

const taskCompleteSlice = createSlice({
  name: "taskCompleteSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(taskCompleteThunk.pending, (state) => {
      state.isLoadTaskComplete = true;
      state.completeSuccess = false;
    });
    builder.addCase(taskCompleteThunk.fulfilled, (state) => {
      state.isLoadTaskComplete = false;
      state.completeSuccess = true;
    });
    builder.addCase(taskCompleteThunk.rejected, (state, action) => {
      state.isLoadTaskComplete = false;
      state.error422 = action.payload?.message;
    });
  },
});
export default taskCompleteSlice.reducer;
