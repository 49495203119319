import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useTaskViewQuery,
  useTaskUpdateMutation,
  useTaskRemoveMutation,
} from "src/FSD/entities/task/api";
import { KanbanModel, KanbanApi } from "src/FSD/entities/kanban";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import { editFreeTaskSchema } from "../schemas";
import { mapTask } from "../mappers";
import { TSelectItem } from "../../../../../../../../components/features/Forms/SetGipForm/types";
import { Nullable } from "../../../../../../../../types/generics";
import { getDashboardDestinations } from "../../../../../../../../app/feature/dashboard/destinations";
import { useModalContext } from "../../../../../../../shared/uiKit/v2/Modal";
import { toServerDate } from "../../../../../../../shared/lib/helpers/toServerDate";
import { Props as TUseModalProps } from "../../ui/EditFreeTaskForm";

type TFieldValues = {
  content: string;
  partGroup: Nullable<TSelectItem>;
  executor: Nullable<TSelectItem>;
  dateDeadline: Nullable<Date>;
};

export const useEditFreeTask = ({
  taskId,
  onTaskUpdate,
  onTaskDelete,
}: TUseModalProps) => {
  const dispatch = useAppDispatch();

  const { handleClose } = useModalContext();

  const [removeTask, { isLoading: isRemoving }] = useTaskRemoveMutation();

  const [updateTask, { isLoading: isUpdating }] = useTaskUpdateMutation();

  const { data: taskRawData, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["project", "partGroup", "can"],
  });

  const taskData = useMemo(() => {
    if (!taskRawData) {
      return undefined;
    }

    return mapTask(taskRawData);
  }, [taskRawData]);

  // массивы с данными для селектов (использовались изначально в фильтре)
  const selectOptions = useAppSelector(KanbanModel.getSelectOptions);

  // вынести в fsd
  const { userType: userTypeId } = useAppSelector(getDashboardDestinations);

  const taskPending = useAppSelector(KanbanModel.getFilterPending);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<TFieldValues>({
    mode: "onChange",
    resolver: yupResolver(editFreeTaskSchema),
  });

  const partGroupValue = watch("partGroup");

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("content", taskData.content);
    setValue("partGroup", taskData.partGroup);
    setValue("executor", taskData.executor);
    setValue("dateDeadline", taskData.dateDeadline);
  }, [taskData]);

  const onSubmit = (data: TFieldValues) => {
    const formedData = {
      StartForm: {
        content: data.content,
        date_deadline: toServerDate(data.dateDeadline!),
        executor_id: data.executor!.id,
        part_group_id: Number(data.partGroup!.id),
      },
    };

    updateTask({
      id: taskId,
      task: formedData,
    })
      .unwrap()
      .then((newTask) => {
        onTaskUpdate(newTask);
        handleClose();
      });
  };

  const handleDeleteFreeTask = () => {
    if (!window.confirm("Вы уверены, что хотите удалить данную задачу?")) {
      return;
    }

    removeTask(taskId)
      .unwrap()
      .then(() => {
        onTaskDelete({
          taskId,
          status: taskData!.status,
          partGroupId: taskData!.partGroup!.id,
        });
        handleClose();
      });
  };

  useEffect(() => {
    if (!taskData) {
      return;
    }

    const partGroups = [];

    if (taskData.partGroup?.id) {
      partGroups.push({
        id: taskData.partGroup.id,
        title: "",
      });
    }

    dispatch(
      KanbanApi.fetchUsersListByUserTypeId({
        userTypeId,
        projectId: taskData.project?.id as number,
        partGroups: [
          {
            id: taskData.partGroup?.id,
            title: "",
          },
        ],
      })
    );
  }, [taskData, userTypeId, partGroupValue]);

  return {
    control,
    handleSubmit,
    onSubmit,
    register,
    errors,
    selectOptions,
    isRemoving,
    isUpdating,
    isSubmitting: isUpdating || isRemoving,
    isTaskViewPending: isFetching || !taskData,
    handleDeleteFreeTask,
    watch,
    taskData,
    setValue,
    taskPending,
    canEdit: taskData?.canEdit ?? false,
  } as const;
};
