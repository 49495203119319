import React, { memo } from "react";
import cn from "classnames";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import style from "./style.module.scss";
import {
  CaretArrowDown,
  CaretArrowUp,
  DefaultArrowClose,
  DefaultArrowOpen,
  RootNodeArrowClose,
  RootNodeArrowOpen,
  VersionArrowClose,
  VersionArrowOpen,
} from "./ToggleAccordionSvg/ToggleAccordionSvg";

interface ToggleAccordionProps {
  toggle: boolean;
  classes?: string;
  light?: boolean;
  root?: boolean;
  secondRoot?: boolean;
  version?: boolean;
  caret?: boolean;
  color?: string;
}

const sx = {
  padding: "0px",
};
export const ToggleAccordion: React.FC<ToggleAccordionProps> = memo(
  ({ toggle, classes, light, root, secondRoot, version, caret, color }) => {
    if (root) {
      return (
        <IconButton sx={sx} className={cn(classes, light && style.light)}>
          {toggle ? <RootNodeArrowOpen /> : <RootNodeArrowClose />}
        </IconButton>
      );
    }
    if (secondRoot) {
      return (
        <IconButton sx={sx} className={cn(classes, light && style.light)}>
          {toggle ? (
            <KeyboardArrowUpIcon sx={{ color: "#D5D6DE" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "#0AA699" }} />
          )}
        </IconButton>
      );
    }
    if (version) {
      return <>{toggle ? <VersionArrowOpen /> : <VersionArrowClose />}</>;
    }

    if (caret) {
      return (
        <IconButton sx={sx} className={cn(classes)}>
          {toggle ? (
            <CaretArrowUp fill={color} />
          ) : (
            <CaretArrowDown fill={color} />
          )}
        </IconButton>
      );
    }
    return (
      <IconButton sx={sx} className={cn(classes, light && style.light)}>
        {toggle ? <DefaultArrowClose /> : <DefaultArrowOpen />}
      </IconButton>
    );
  }
);
