import { ReactNode } from "react";
import styles from "./Column.module.scss";

type Props = {
  children: ReactNode;
};

const ColumnHeader = ({ children }: Props) => {
  return <div className={styles.header}>{children}</div>;
};

export default ColumnHeader;
