import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import styles from "./Deadlines.module.scss";
import { useDeadlines } from "../../lib/hooks/useDeadlines";
import { Popper } from "../../../../../../shared/uiKit/v2/Popper";
import { Paragraph } from "../../../../../../shared/uiKit/v2/Paragraph/Paragraph";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Deadlines = ({ processWork }: Props) => {
  const {
    canEditDates,
    handleChangeDates,
    factDateCreate,
    factDateEnd,
    isDatesUpdating,
  } = useDeadlines(processWork);

  return (
    <Card header={<Card.Header>Сроки выполнения</Card.Header>}>
      <div className={styles.deadlines}>
        <Paragraph className={styles.deadlines__label}>Плановый:</Paragraph>
        <Paragraph className={styles.deadlines__date}>
          {processWork.date_start}&nbsp;-&nbsp;
        </Paragraph>
        <Popper.DateField
          date={processWork.date_limit}
          canEdit={canEditDates}
          title="Конечный срок"
          onChange={handleChangeDates}
          isLoading={isDatesUpdating}
          className={styles.deadlines__date}
        />
      </div>
      {factDateCreate && (
        <div className={styles.deadlines}>
          <Paragraph className={styles.deadlines__label}>
            Фактический:
          </Paragraph>
          <Paragraph className={styles.deadlines__date}>
            {factDateCreate}&nbsp;-&nbsp;
          </Paragraph>
          <Paragraph className={styles.deadlines__date}>
            {factDateEnd}
          </Paragraph>
        </div>
      )}
    </Card>
  );
};
