import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./baseQuery";

export const rootApi = createApi({
  keepUnusedDataFor: 0.001,
  reducerPath: "rootApi",
  tagTypes: ["ProcessWork.View"],
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});
