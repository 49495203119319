import React, { FC } from "react";
import { Uploader } from "../../../features/Uploader/Uploader";
import { config } from "../../../../app.cofig";
import style from "./style.module.scss";
import { httpRequestGet } from "../../../../app/services/api/api";

type TProps = {
  firmId: string;
  canBeEdited: boolean;
};

export const ApiGetFiles = (id: number | string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/firm/view?id=${id}&expand=questionnaires.svgIcon`
  );
};

const Lists: FC<TProps> = ({ firmId, canBeEdited }) => {
  return (
    <div className={style.wrapper}>
      <Uploader
        autoUpload
        canBeEdited={canBeEdited}
        getDataCallback={ApiGetFiles}
        identifier={firmId}
        postUrlString={(id) =>
          `${config.localDomain}/v1/firm/add-file?id=${id}`
        }
        field={"questionnaires"}
      />
    </div>
  );
};

export default Lists;
