import React from "react";
import style from "./style.module.scss";
import { Uploader } from "../../Uploader/Uploader";
import { config } from "../../../../app.cofig";
import { ApiGetProjectFilesForEditing } from "../../../../app/services/api/project/projectEditing";
import { useIsMobile } from "../../../hooks/useIsMobile";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";

export type FileEditingProps = {
  projectId: string | number;
  canFinish?: boolean;
};

export const FileEditing: React.FC<FileEditingProps> = ({
  projectId,
  canFinish = false,
}) => {
  const isMobile = useIsMobile(800);
  const handleFinish = () => {};
  return (
    <div className={style.wrapper}>
      <h1>Добавить файлы</h1>
      <h2 className={style.clue}>
        Здесь можно загрузить файлы с исходными данными, такие как ТЗ, ГПЗУ, ТУ
      </h2>
      <Uploader
        identifier={`${projectId}`}
        autoUpload
        postUrlString={(id) =>
          `${config.localDomain}/v1/project/add-file?id=${id}`
        }
        getDataCallback={ApiGetProjectFilesForEditing}
        canBeEdited={true}
      />
      {canFinish && isMobile && (
        <CustomButton onClick={handleFinish} background={palette.green}>
          Завершить
        </CustomButton>
      )}
    </div>
  );
};
