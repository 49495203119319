import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
  CombinedState,
} from "@reduxjs/toolkit";
import {
  getUserTypes,
  getCounters,
  getAccounts,
  getSubscription,
} from "./thunks";

export type TUserType = {
  id: number;
  shortTitle: string;
  title: string;
  fullTitle: string;
  subtitle: string;
  isEntity: boolean;
  count: number;
  reportLinks: Record<string, string>[];
};

export type TState = {
  data: TUserType[];
  bank: any;
  counters: Record<string, number>;
  subscription: Record<string, any>;
  pending: boolean;
  accountPending: boolean;
  countersPending: boolean;
  subscriptionPending: boolean;
  error: string | null;
};

const initialState: TState = {
  data: [],
  bank: 0,
  subscription: {},
  counters: {
    total: 0,
    outDateTasks: 0,
    applications: 0,
    notSignDocuments: 0,
  },
  pending: false,
  countersPending: false,
  accountPending: true,
  subscriptionPending: false,
  error: null,
};

const dashboardHeader = createSlice({
  name: "dashboardHeader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getUserTypes), (state) => {
        state.pending = true;
      })
      .addMatcher(isFulfilled(getUserTypes), (state, action) => {
        const { userTypes } = action.payload;

        const getShortTitle = (userType: any) =>
          userType.listName.length > 15
            ? `${userType.listName.slice(0, 15 - userType.listName.length)}…`
            : userType.listName;

        const getTitle = (userType: any, short: boolean) => {
          if (
            userType.typeName === "Физическое лицо" ||
            userType.typeName === "Самозанятый"
          ) {
            return userType.title;
          }

          return short ? getShortTitle(userType) : userType.listName;
        };

        const formattedData: TUserType[] = userTypes.map((userType: any) => ({
          id: userType.id,
          title: getTitle(userType, true),
          fullTitle: getTitle(userType, false),
          firmId: userType.firmId,
          shortTitle: getShortTitle(userType),
          subtitle: userType.typeName,
          isEntity: userType.type === 2,
          reportLinks: userType.reportLinks,
        }));

        state.pending = false;
        state.data = formattedData;
      })
      .addMatcher(isPending(getCounters), (state) => {
        state.countersPending = true;
      })
      .addMatcher(isFulfilled(getCounters), (state, action) => {
        state.counters = action.payload;
        state.countersPending = false;
      })
      .addMatcher(isRejected(getCounters), (state, action) => {
        state.error = action.payload as string;
        state.subscriptionPending = false;
      })
      .addMatcher(isPending(getSubscription), (state) => {
        state.subscriptionPending = true;
      })
      .addMatcher(isFulfilled(getSubscription), (state, action) => {
        state.subscriptionPending = false;
        const data = action.payload;

        if (Array.isArray(data)) {
          state.subscription = {};
          return;
        }

        state.subscription = data;
      })
      .addMatcher(isRejected(getSubscription), (state, action) => {
        state.error = action.payload as string;
        state.countersPending = false;
      })
      .addMatcher(isRejected(getUserTypes), (state, action) => {
        state.pending = false;
        state.error = action.payload as string;
      })
      .addMatcher(isPending(getAccounts), (state) => {
        state.accountPending = true;
      })
      .addMatcher(isFulfilled(getAccounts), (state, action) => {
        state.accountPending = false;
        state.bank = action.payload;
      })
      .addMatcher(isRejected(getAccounts), (state, action) => {
        state.accountPending = false;
        state.error = action.payload as string;
      });
  },
});

export const getDashboardHeader = (state: CombinedState<any>) =>
  state.dashboard.header;

export default dashboardHeader;
