import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseRemarkDelete } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

export const fetchExpertiseRemarkDelete = createAsyncThunk<boolean, number>(
  "expertise/fetchExpertiseRemarkDelete",
  async (remarkId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseRemarkDelete(remarkId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
