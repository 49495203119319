import React, { memo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const KeyboardArrowIcon = memo(
  ({ isOpen, color }: { isOpen: boolean; color: string }) => {
    return (
      <>
        {isOpen ? (
          <KeyboardArrowUpIcon sx={{ color }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color }} />
        )}
      </>
    );
  }
);
