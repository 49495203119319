import { httpRequestGet, httpRequestPost, httpRequestPut } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProcessWork = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-works?id=${id}&expand=activeAccess.userType,can`
  );
};

export const ApiProjectProcessDelegate = (
  processId: number,
  userId: number,
  data: {
    delegate: boolean;
  }
): Promise<Response> => {
  // публикация задачи
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/delegate?id=${processId}&userId=${userId}`,
    {},
    data
  );
};

export const ApiPublishWork = (id: string, data: any): Promise<Response> => {
  // публикация задачи
  return httpRequestPost(
    `${config.localDomain}/v1/process-work/publish?id=${id}`,
    {},
    { ...data }
  );
};

export const ApiUpdatePublishWork = (
  id: number,
  data: any
): Promise<Response> => {
  // редактировать публикацию задачи
  return httpRequestPut(
    `${config.localDomain}/v1/process-work/update-published?id=${id}`,
    {},
    { ...data }
  );
};

export const ApiUnPublishWork = (id: number): Promise<Response> => {
  // снятие публикации задачи
  return httpRequestPost(
    `${config.localDomain}/v1/process-work/unpublish?id=${id}`
  );
};
