import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { foldersInitialState } from "./initialState";
import C from "./constants";
import { StateType } from "../../../initialState";

const setFolderForState = (state: any, id: number) => {
  if (state.hasOwnProperty(id)) {
    return state;
  }

  return {
    ...state,
    [id]: [],
  };
};

const setClear = (state: any, projectId: number) => {
  return {
    ...state,
    [projectId]: [],
  };
};

const setOpenAll = (state: any, projectId: number, arrayIds: string[]) => {
  return {
    ...state,
    [projectId]: [...arrayIds],
  };
};

const setOpenState = (state: any, folderId: string, projectId: number) => {
  const copy = { ...state };

  if (copy[projectId].includes(folderId)) {
    copy[projectId] = copy[projectId].filter((id: string) => id !== folderId);

    return copy;
  }
  copy[projectId] = [...copy[projectId], folderId];

  return copy;
};

export const foldersState = createReducer(foldersInitialState, (builder) => {
  builder.addCase(
    C.CREATE_PROJECT_FOR_FOLDER_STATE,
    (state, action: PayloadAction<{ projectId: number }>) => {
      return setFolderForState(state, action.payload.projectId);
    }
  );

  builder.addCase(
    C.SAVE_FOLDER_STATE,
    (
      state,
      action: PayloadAction<{
        projectId: number;
        folderId: string;
      }>
    ) => {
      return setOpenState(
        state,
        action.payload.folderId,
        action.payload.projectId
      );
    }
  );

  builder.addCase(
    C.CLEAR_STATE_ALL_FOLDERS,
    (state, action: PayloadAction<{ projectId: number }>) => {
      return setClear(state, action.payload.projectId);
    }
  );

  builder.addCase(
    C.FILL_STATE_ALL_FOLDERS,
    (
      state,
      action: PayloadAction<{ projectId: number; arrayIds: string[] }>
    ) => {
      return setOpenAll(
        state,
        action.payload.projectId,
        action.payload.arrayIds
      );
    }
  );
});

export const clearFolderState = createAction(
  C.CLEAR_STATE_ALL_FOLDERS,
  (payload) => {
    return {
      payload: {
        projectId: payload.projectId,
      },
    };
  }
);

export const fillFolderState = createAction(
  C.FILL_STATE_ALL_FOLDERS,
  (payload) => {
    return {
      payload: {
        projectId: payload.projectId,
        arrayIds: payload.arrayIds,
      },
    };
  }
);

export const setProjectFolders = createAction(
  C.CREATE_PROJECT_FOR_FOLDER_STATE,
  (payload) => {
    return {
      payload: {
        projectId: payload.projectId,
      },
    };
  }
);

export const saveFolderState = createAction(C.SAVE_FOLDER_STATE, (payload) => {
  return {
    payload: {
      projectId: payload.projectId,
      folderId: payload.folderId,
    },
  };
});

export const selectorFoldersState = (state: StateType) =>
  state.projectProcessFoldersState;
