import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import * as React from "react";

const sx = { bgcolor: "#26313a" };

export const SkeletonProjectProcessMaterialsTab = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={94} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={218} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={218} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={218} />
      </Grid>
    </Grid>
  );
};

export const SkeletonInfoProjectProcess = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={169} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={296} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" sx={sx} height={140} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" sx={sx} height={140} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={296} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={296} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={118} />
      </Grid>
    </Grid>
  );
};

export const ExecutorProcessInfoSkeleton = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={232} />
      </Grid>
    </Grid>
  );
};

export const PayProcessInfoSkeleton = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={296} />
      </Grid>
    </Grid>
  );
};

export const MileStoneSkeleton = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={540} />
      </Grid>
    </Grid>
  );
};

export const ExpertiseProjectProcess = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={66} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={66} />
      </Grid>
    </Grid>
  );
};

export const ExpertiseDocumentsTab = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={70} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={70} />
      </Grid>
    </Grid>
  );
};

export const ExpertiseFilterRemarks = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
    </Grid>
  );
};

export const ExpertiseRemarksTab = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={70} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
    </Grid>
  );
};

export const ExpertiseConclusionsTab = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={70} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" sx={sx} height={70} />
      </Grid>
    </Grid>
  );
};

export const SkeletonProjectProcessWorkTab = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={72} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={61} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={91} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={91} />
      </Grid>
    </Grid>
  );
};

export const SkeletonApplicationsTab = () => (
  <Grid
    container
    rowSpacing={2}
    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    sx={{ marginTop: "6px" }}
  >
    <Grid item xs={6} md={6}>
      <Skeleton variant="rounded" sx={sx} height={438} />
    </Grid>
    <Grid item xs={6} md={6}>
      <Skeleton variant="rounded" sx={sx} height={438} />
    </Grid>
  </Grid>
);

export const SkeletonDocumentationsTab = () => (
  <Grid
    container
    rowSpacing={2}
    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    sx={{ marginTop: "6px" }}
  >
    <Grid item xs={6} md={6}>
      <Skeleton variant="rounded" sx={sx} height={324} />
    </Grid>
    <Grid item xs={6} md={6}>
      <Skeleton variant="rounded" sx={sx} height={324} />
    </Grid>
  </Grid>
);

export const SkeletonProjectProcessInitialData = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ marginTop: "2px" }}
    >
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={41} />
      </Grid>
    </Grid>
  );
};

export const SkeletonRemoveExecutors = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={139} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={139} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={139} />
      </Grid>
      <Grid item xs={6} md={6}>
        <Skeleton variant="rounded" sx={sx} height={139} />
      </Grid>
    </Grid>
  );
};
