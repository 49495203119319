import { CircularProgress } from "@mui/material";
import styles from "./Modal.module.scss";

const ModalPreloader = () => {
  return (
    <div className={styles.modal__preloader}>
      <CircularProgress />
    </div>
  );
};

export default ModalPreloader;
