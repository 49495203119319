import React from "react";
import {
  getProjectProcessInitialDataProcesses,
  getProjectProcessInitialDataProjects,
} from "src/app/feature/ProjectProcessView/InitialData/selectors/getProjectProcessInitialData";
import { useAppSelector } from "src/app/store";
import { Processes } from "./Processes";
import { Project } from "./Project";

export const TabDataByProcess = () => {
  const projects = useAppSelector(getProjectProcessInitialDataProjects);
  const processes = useAppSelector(getProjectProcessInitialDataProcesses);

  return (
    <>
      {projects &&
        projects.map(([id, project]) => (
          <Project key={id} {...project} id={id} />
        ))}
      {processes && (
        <Processes
          canAdd={processes?.canAdd}
          name={processes?.name}
          files={processes?.files}
        />
      )}
    </>
  );
};
