import { ReactNode } from "react";
import { NothingFound } from "../NothingFound";

type Props = {
  children: ReactNode;
};

export const ListMapper = ({ children }: Props) => {
  if (!Array.isArray(children)) {
    throw new Error("Component renders lists only!");
  }

  if (!children.length) {
    return <NothingFound />;
  }

  return <>{children}</>;
};
