import React, { FC, useEffect, useRef, MouseEvent } from "react";
import cn from "classnames";
import { IconButton } from "@mui/material";
import globalStyle from "../../Primary/style.module.scss";
import style from "../style.module.scss";
import { VerifiedSVG } from "../svgs";
import { SectionProps } from "../types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getDashboardDestinations,
  setTab,
  setSectionId,
  setSectionData,
} from "../../../../../../app/feature/dashboard/destinations";
import { config } from "../../../../../../app.cofig";
import { OpenIcon } from "../../../../../newUI/SVG";
import useHover from "../../../../../hooks/useHover";
import { getDashboardProjects } from "../../../../../../app/feature/dashboard/projects";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

export const sectionContainsTasks = (
  tabs: Record<string, Record<string, boolean>>,
  field: string
) =>
  tabs.expertiseInTasks?.[field] ||
  tabs.inputTasks?.[field] ||
  tabs.outSubCheckTask?.[field] ||
  tabs.outputTasks?.[field] ||
  tabs.processTask?.[field];

export const sectionContainsApplications = (
  tabs: Record<string, Record<string, boolean>>,
  field: string
) => tabs.applications?.[field];

export const sectionContainsTreaties = (
  tabs: Record<string, Record<string, boolean>>,
  field: string
) => tabs.documents?.[field];

export const sectionContainsUnfulfilled = (
  tabs: Record<string, Record<string, boolean>>,
  field: string
) => Object.values(tabs).some((tab: any) => tab[field]);

type PriceProps = {
  secure: boolean;
  price: number | string | null | undefined;
  message?: string;
  reverse?: boolean;
  color?: string;
  flexBasis?: number;
};

export const Price = ({
  secure,
  price,
  message,
  reverse,
  color = "",
  flexBasis,
}: PriceProps) => {
  const formattedPrice = Number(price).toLocaleString();

  if (price === null) {
    return <div className={style.price} />;
  }

  return (
    <div
      style={{ color, flexBasis: flexBasis ? `${flexBasis}px` : "inherit" }}
      className={style.price}
    >
      {!Number.isNaN(Number(price)) ? (
        <>
          <div className={cn(reverse && style.reverse)}>
            <p>{formattedPrice}&nbsp;</p>
            {secure && <VerifiedSVG />}
          </div>
          {message}
        </>
      ) : (
        <div className={cn(reverse && style.reverse)}>
          <p className={style.treatyLike}>{price}&nbsp;</p>
          {secure && <VerifiedSVG />}
        </div>
      )}
    </div>
  );
};

const Section: FC<SectionProps> = ({
  id,
  projectPart,
  date_start,
  date_limit,
  secure,
  executorNds,
  countAccesses,
  countProcessWorks,
  activeAccess,
  dotColor,
  short,
  price,
  tabs,
  title,
  status,
}) => {
  const [hovered, eventHandlers, setHovered] = useHover();
  const [hoveredFullName, eventHandlersFullName] = useHover();
  const scrollRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { projectStatus } = useAppSelector(getDashboardProjects);
  const { sectionId, tab } = useAppSelector(getDashboardDestinations);
  const { code } = projectPart;

  const isDraft = projectStatus === 1 || status.key === 1;

  const isMobile = useIsMobile(1600);
  const isRealMobile = useIsMobile(1100);

  useEffect(() => {
    return () => {
      setHovered(false);
    };
  }, []);

  const handleOpenSection = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    window
      .open(`${config.localDomain}/project-process/${id}`, "_blank")!
      .focus();
  };

  const Dates = () => {
    if (isRealMobile) {
      return date_start ? (
        <h2 className={style.deadLines}>
          <p>{date_start}</p>
          <p>{date_limit}</p>
        </h2>
      ) : isDraft ? (
        <h2 className={style.deadLines}>
          <p>__.__.____</p>
          <p>__.__.____</p>
        </h2>
      ) : (
        <h2 className={style.deadLines} />
      );
    }

    return date_start ? (
      <h2 className={style.deadLines}>
        {date_start}
        &nbsp;&nbsp;•&nbsp;&nbsp;
        {date_limit}
      </h2>
    ) : isDraft ? (
      <h2 className={style.deadLines}>
        __.__.____ &nbsp;&nbsp;•&nbsp;&nbsp; __.__.____
      </h2>
    ) : (
      <h2 className={style.deadLines} />
    );
  };

  const codeElement = (
    <a className={style.code} {...eventHandlersFullName}>
      {dotColor === "orange" && (
        <div className={cn(globalStyle.red, globalStyle.orange)} />
      )}
      {dotColor === "red" && (
        <div className={cn(globalStyle.red, globalStyle.red)} />
      )}
      <div className={cn(style.codeWithStatus, !dotColor && style.noMargin)}>
        <h1>{code}</h1>
        <p>{status.value}</p>
      </div>
      {hoveredFullName && (
        <div className={cn(style.prompt, style.fullNamePrompt)}>{title}</div>
      )}
    </a>
  );

  const ordersCount = (
    <div
      className={cn(
        style.appliance,
        !activeAccess && style.applianceNoExecutor
      )}
    >
      {isDraft ? (
        <p>Черновик</p>
      ) : (
        <>
          {(tab === "section->application" ||
            ((tab === "project->section" || tab === "section") &&
              Boolean(countAccesses))) &&
            !activeAccess && (
              <>
                <p>Заявки</p>
                <h3>{countAccesses}</h3>
              </>
            )}
          {(tab === "section->tasksAndWorks" ||
            ((tab === "project->section" || tab === "section") &&
              Boolean(countProcessWorks))) && (
            <>
              <p>Задачи</p>
              <h3>{countProcessWorks}</h3>
            </>
          )}
          {tab === "project->section" &&
            Boolean(!countProcessWorks && !countAccesses) && (
              <>
                <p>Заявки</p>
                <h3>0</h3>
              </>
            )}
        </>
      )}
    </div>
  );

  const priceComponent = (
    <Price
      message={isDraft ? "" : executorNds}
      price={isDraft ? "0" : price}
      secure={secure}
      reverse={isRealMobile}
      flexBasis={160}
    />
  );

  const executor = Boolean(activeAccess) && (
    <div className={style.sectionExecutor}>
      <p>{`${activeAccess?.user?.surname} ${activeAccess?.user?.name} ${activeAccess?.user?.patronymic}`}</p>
      <p>{activeAccess.userType.listName}</p>
    </div>
  );

  const isSelected = sectionId === id;

  // изменение текущего выбранного раздела
  const handleChangeSection = () => {
    if (isSelected && short && !isRealMobile) {
      return;
    }

    dispatch(setSectionId(id));

    const containsTasks =
      sectionContainsTasks(tabs, "show") || countProcessWorks;
    const containsApplications =
      sectionContainsApplications(tabs, "show") || countAccesses;

    dispatch(setSectionData({ containsTasks, containsApplications, isDraft }));

    if ((short && !isMobile) || isRealMobile) {
      return;
    }

    const destination = containsApplications
      ? "section->application"
      : "section->tasksAndWorks";

    dispatch(setTab(destination));
  };

  useEffect(() => {
    if (scrollRef.current && isSelected && short) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, []);

  if (isRealMobile) {
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        ref={scrollRef}
        onClick={handleChangeSection}
        className={cn(
          style.short,
          isSelected && globalStyle.selected,
          isSelected && style.selectedSection,
          style.shortSection
        )}
      >
        <div className={style.flexSection}>
          <div className={style.sectionFirst}>
            {codeElement}
            <div className={style.mobileSectionInfo}>
              {executor}
              {ordersCount}
            </div>
          </div>
          {priceComponent}
          <div className={cn(style.shortSubtitle, style.mobileDates)}>
            <Dates />
          </div>
        </div>
        {isSelected && (
          <IconButton onClick={handleOpenSection} className={style.openButton}>
            <OpenIcon />
          </IconButton>
        )}
      </div>
    );
  }

  if (short || isMobile) {
    return (
      <div
        className={cn(
          style.sectionForTablette,
          isSelected && style.selectedSectionTablette
        )}
      >
        <div
          onMouseLeave={() => setHovered(false)}
          ref={scrollRef}
          onClick={handleChangeSection}
          className={cn(
            style.short,
            isSelected && globalStyle.selected,
            style.shortSection
          )}
        >
          <div className={style.flexSection}>
            <div className={style.sectionFirst}>
              {codeElement}
              <div className={style.shortSubtitle}>
                <Dates />
              </div>
              {executor}
            </div>
            <div className={style.shortSubtitle}>{ordersCount}</div>
            {priceComponent}
          </div>
        </div>
        {isSelected && (
          <IconButton
            onClick={handleOpenSection}
            className={cn(style.openButton, style.sectionOpenButton)}
            {...(eventHandlers as any)}
          >
            <OpenIcon />
            {hovered && <div className={style.prompt}>Открыть</div>}
          </IconButton>
        )}
      </div>
    );
  }

  return (
    <div
      onMouseLeave={() => setHovered(false)}
      onClick={handleChangeSection}
      className={cn(style.section, isSelected && globalStyle.selected)}
    >
      {codeElement}
      {activeAccess ? executor : ordersCount}
      <Dates />
      {priceComponent}
      <div>
        <IconButton
          onClick={handleOpenSection}
          className={style.openButtonFull}
        >
          <OpenIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Section;
