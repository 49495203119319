import React from "react";
import { Controller, UseFormRegister } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import Grid from "@mui/material/Grid";
import styles from "./TermAndCost.module.scss";

export type TInputs = {
  date_start: Date | null;
  date_limit: Date | null;
  price: string;
};

type TProps<TFieldValues extends TInputs> = {
  control: any;
  dateStart: Date | null;
  dateEnd: Date | null;
  // register: UseFormRegister<TInputs>;
  register: any;
  price: string;
  checkPrice: boolean;
  setCheckPrice: (value: boolean) => void;
  errors: FieldErrors<TFieldValues>;
};

export const TermAndCost = ({
  checkPrice,
  control,
  dateEnd,
  dateStart,
  errors,
  price,
  register,
  setCheckPrice,
}: TProps<TInputs>) => {
  return (
    <div className={styles.layout}>
      <h3 className={styles.title}>Укажите срок и стоимость раздела</h3>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md="auto">
          <Controller
            control={control}
            name="date_start"
            render={({ field: { onChange } }) => (
              <DateField
                label="Начальный срок"
                changeDateHandler={(date) => {
                  onChange(date);
                }}
                className={styles.field}
                startDateProp={dateStart}
                error={!!errors.date_start}
                helperText={errors?.date_start?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Controller
            control={control}
            name="date_limit"
            render={({ field: { onChange } }) => (
              <DateField
                label="Конечный срок"
                changeDateHandler={(date) => {
                  onChange(date);
                }}
                className={styles.field}
                startDateProp={dateEnd}
                error={!!errors.date_limit}
                helperText={errors?.date_limit?.message}
              />
            )}
          />
        </Grid>
        {!checkPrice && (
          <Grid item xs={12} md="auto">
            <TextField
              {...register("price")}
              defaultValue={price}
              label="Стоимость"
              className={styles.field}
              error={!!errors.price}
              helperText={errors?.price?.message}
              InputLabelProps={{ shrink: false }}
            />
          </Grid>
        )}
        <Grid item xs="auto">
          <Checkbox
            label="Договорная стоимость"
            checked={checkPrice}
            onChange={() => setCheckPrice(!checkPrice)}
            className={styles.check}
          />
        </Grid>
      </Grid>
    </div>
  );
};
