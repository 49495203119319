import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProcessMaterialViewForm } from "../../../../services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";
import { ViewFormType } from "../types/projectProcessViewFormTypes";

export const fetchProcessMaterialViewForm = createAsyncThunk<
  ViewFormType[],
  number
>(
  "materialProcess/fetchProcessMaterialViewForm",
  async (materialId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProcessMaterialViewForm(materialId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
