import { httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const apiAutoComplete = (id: number): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/auto-complete?id=${id}`,
    {},
    {}
  );
};

export const ApiTaskComplete = (
  id: number,
  data: Record<string, any>
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: { ...data },
    }
  );
};
