import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getDashboardDestinations } from "../../../../../app/feature/dashboard/destinations";
import globalStyle from "../Primary/style.module.scss";
import FolderPanel from "./Sections/FolderPanel";
import style from "./style.module.scss";
import DashboardSkeleton from "../Skeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import Task from "./Tasks/Task";
import { getDashboardWorkTasks } from "../../../../../app/feature/dashboard/workTasks";
import { getWorkTasks } from "../../../../../app/feature/dashboard/workTasks/thunks";

const initialOpen = {
  checks: false,
  solutions: false,
  documentation: false,
};

const WorkTasks: FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: { checks, solutions, documentation },
    pending,
  } = useAppSelector(getDashboardWorkTasks);

  const { workId } = useAppSelector(getDashboardDestinations);

  const [open, setOpen] = useState<Record<string, boolean>>(initialOpen);

  const isMobile = useIsMobile(1600);

  useEffect(() => {
    if (!workId) {
      return;
    }

    dispatch(getWorkTasks(workId));

    setOpen(initialOpen);
  }, [dispatch, workId]);

  const head = (
    <div className={cn(style.generalHeader, style.tasksHeader)}>
      <h1>Название</h1>
      <p>Ответственный</p>
      <p>Срок</p>
      <p>Стоимость</p>
    </div>
  );

  const checkTasks =
    open.checks &&
    checks.map((task: any) => (
      <Task key={task.id} {...task} type="taskCheck" />
    ));

  const solutionTasks =
    open.solutions &&
    solutions.map((task: any) => (
      <Task key={task.id} {...task} type="customerAlign" />
    ));

  const documentationTasks =
    open.documentation &&
    documentation.map((task: any) => (
      <Task key={task.id} {...task} type="task" />
    ));

  const showTasks = Boolean(workId);

  const tasksFolders = showTasks && (
    <div className={globalStyle.list}>
      {!isMobile && head}
      <FolderPanel
        canOpen={Boolean(solutions.length)}
        isOpen={open.solutions}
        name={`Предварительные решения (${solutions.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, solutions: !open.solutions });
        }}
      />
      {solutionTasks}
      <FolderPanel
        canOpen={Boolean(documentation.length)}
        isOpen={open.documentation}
        name={`Готовая документация (${documentation.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, documentation: !open.documentation });
        }}
      />
      {documentationTasks}
      <FolderPanel
        canOpen={Boolean(checks.length)}
        isOpen={open.incoming}
        name={`Проверки (${checks.length})`}
        handleShowSubtree={() => {
          setOpen({ ...open, checks: !open.checks });
        }}
      />
      {checkTasks}
    </div>
  );

  return (
    <div className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}>
      <div className={cn(globalStyle.header, style.sectionsFullHeader)}>
        <h3>Задача</h3>
      </div>
      {showTasks && <> {pending ? <DashboardSkeleton /> : tasksFolders}</>}
    </div>
  );
};

export default WorkTasks;
