import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { Button } from "../../UI/components/Buttons/Button/Button";
import { ApiUserJoin } from "../../../app/services/api/organisation/organisation";
import { request } from "../../../app/services/api/requestHandler";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import style from "./style.module.scss";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import { textFieldSX } from "../../../styles/restyle";

export type SelectItemProps = ComponentPropsType<unknown>;

export type SetUserRoleFormProps = ComponentPropsType<{
  submitHandler?: () => void;
  submitClose?: () => void;
  closeHandler?: VoidFunction;
  id: number;
}>;

export const EditUserJoinForm: React.FC<SetUserRoleFormProps> = ({
  theme = "old",
  variant = "",
  closeHandler = () => {},
  id,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  const schema = Yup.object().shape({
    comment: Yup.string()
      .nullable()
      .test(
        "rejection_reason",
        "При отказе укажите пожалуйста причину!",
        (val) => !(!val && status === "5")
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: "",
    },
  });

  const acceptEmployee = () => setStatus("4");

  const dismissEmployee = () => setStatus("5");

  const modalClosed: any = useSelector(selectorModalClosed);

  const getOpacity = () => (disabled ? ".5" : "1");

  const onSubmit = async (data: any) => {
    setDisabled(true);

    const formData = new FormData();
    formData.append("CompleteForm[status]", status);
    formData.append("CompleteForm[comment]", data.comment);

    await request(
      ApiUserJoin(id, formData),
      () => {
        enqueueSnackbar("Запрос на вступление успешно рассмотрен", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.userJoin.set({});
          closeHandler();
        }, 500);
      },
      () => (err) => {
        if (err.status === 403) {
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar("Ошибка при рассмотрении запроса на вступление", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        setTimeout(() => {
          setDisabled(false);
          Popup.userJoin.set({});
          closeHandler();
        }, 500);
      }
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, variant)}
    >
      <TextField
        {...register("comment")}
        name={"comment"}
        variant="outlined"
        label="Комментарий"
        size={"small"}
        autoComplete={"off"}
        inputProps={{
          style: {
            height: "100px",
            fontSize: 14,
          },
        }}
        error={Boolean(errors.comment)}
        helperText={errors.comment?.message}
        {...textFieldSX}
      />
      <br />
      <div className={style.footerButtons}>
        <Button
          onClick={acceptEmployee}
          disabled={disabled}
          style={{
            opacity: disabled ? ".5" : "1",
          }}
          theme={theme}
          type="submit"
          color={"blue"}
        >
          Принять
        </Button>
        <Button
          onClick={dismissEmployee}
          disabled={disabled}
          style={{
            opacity: getOpacity(),
          }}
          theme={theme}
          type="submit"
          color={"red"}
        >
          Отказать
        </Button>
      </div>
    </form>
  );
};
