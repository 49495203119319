import Filter from "./Filter";

class FilterTasks extends Filter {
  static filterByQuery(data: any, query: string, fulfilled: boolean) {
    if (query === "") {
      const filteredTasks = data.filter((task: any) => {
        const condition =
          task.status === "Принято" ||
          task.status === "Выполнено" ||
          task.status === "Отменено";

        return fulfilled ? condition : !condition;
      });

      return filteredTasks.reduce(
        (result: Record<string, string>, task: any) => {
          return { ...result, [task.id]: task.folder };
        },
        {}
      );
    }

    const filteredTasks = data.filter((task: any) => {
      const p: boolean[] = [];

      p[0] = Filter.valueContains(task?.name ?? "", query);
      p[1] = Filter.valueContains(task?.title ?? "", query);

      task.executorName.forEach((executor: string) => {
        p.push(Filter.valueContains(executor, query));
      });

      const cond1 = p.some((item: boolean) => item);

      const condition =
        task.status === "Принято" ||
        task.status === "Выполнено" ||
        task.status === "Отменено";

      const cond2 = fulfilled ? condition : !condition;

      return cond1 && cond2;
    });

    return filteredTasks.reduce((result: Record<string, string>, task: any) => {
      return { ...result, [task.id]: task.folder };
    }, {});
  }

  static filterByFulfilled(data: any, fulfilled: boolean, query: string) {
    return FilterTasks.filterByQuery(data, query, fulfilled);
  }
}

export default FilterTasks;
