import React from "react";
import styles from "./Header.module.scss";
import {
  HexagonComplete,
  CircleComplete,
} from "../../../../../newUI/NewMilestoneCard/MilestoneType/MilestoneTypeSvg";

export const Header = () => {
  return (
    <div className={styles.header}>
      <p>Вехи</p>
      <div className={styles.header_right}>
        <p>
          Уведомляем пользователей платформы ПИРС, что закрытие вех может
          происходить двумя способами:
          <br />
          <HexagonComplete width={"16"} height={"19"} /> - вручную,{" "}
          <CircleComplete /> - автоматически. Выбор способа устанавливается в
          настройках управления контрольными точками.
        </p>
      </div>
    </div>
  );
};
