import { RootState } from "../../../../store";

export const getProjectProcessRequestIsLoad = (state: RootState) =>
  state.projectProcessView.headerBtn.isLoadRequest;

export const getProjectProcessRequestError422 = (state: RootState) =>
  state.projectProcessView.headerBtn.error422;

export const getProjectProcessRequestError = (state: RootState) =>
  state.projectProcessView.headerBtn.error;

export const getProjectProcessRequestSuccess = (state: RootState) =>
  state.projectProcessView.headerBtn.requestSuccess;

export const getProjectProcessRequestBanksLoading = (state: RootState) =>
  state.projectProcessView.headerBtn.isLoadExecutorBank;

export const getProjectProcessRequestUserTypesLoading = (state: RootState) =>
  state.projectProcessView.headerBtn.isLoadUserTypes;
