import { httpRequestDelete, httpRequestGet } from "../../api";
import { config } from "../../../../../app.cofig";

export const ApiExpertiseConclusion = (
  processId: number
): Promise<Response> => {
  // получить заключения
  return httpRequestGet(
    `${config.localDomain}/v1/expertise/conclusion/index?ConclusionSearch[process_id]=${processId}&expand=file`
  );
};

export const ApiExpertiseDeleteConclusion = (
  conclusionId: number
): Promise<Response> => {
  // удалить заключение
  return httpRequestDelete(
    `${config.localDomain}/v1/expertise/conclusion/delete?id=${conclusionId}`
  );
};
