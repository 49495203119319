import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import {
  ApiProjectProcessDonePreliminaryByTask,
  ApiProjectProcessTaskComplete,
} from "../../../../services/api/projectProcess/documentations";
import { pushError } from "../../../errorTrace";
import { fetchProjectProcessDocumentationsDuplicate } from "./fetchProjectProcessDocumentations";
import { fetchProjectProcessViewDuplicate } from "../../Info/services/fetchProjectProcessView";

export const fetchProjectProcessPreliminaryDone = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data: any;
  }
>(
  "documentations/fetchProjectProcessPreliminaryDone",
  async ({ taskId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessTaskComplete(taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessPreliminaryDoneByTask = createAsyncThunk<
  boolean,
  {
    processId: number;
    taskId: number;
    formData: any;
  }
>(
  "documentations/fetchProjectProcessPreliminaryDoneByTask",
  async ({ processId, taskId, formData }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDonePreliminaryByTask(processId, taskId, formData),
      (data) => {
        dispatch(fetchProjectProcessDocumentationsDuplicate(processId));
        dispatch(fetchProjectProcessViewDuplicate(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
