import { FC } from "react";
import { Tooltip } from "@mui/material";
import cn from "classnames";
import { CaseSVG, PersonSVG } from "./svgs";
import style from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  clearProjectsData,
  getDashboardProjects,
  setProjectStatus,
} from "../../../../../app/feature/dashboard/projects";
import { TFirm } from "./UserFirms";
import {
  setUserType,
  setTab,
  setProjectId,
  setReportLinks,
  getDashboardDestinations,
} from "../../../../../app/feature/dashboard/destinations";
import { getDashboardHeader } from "../../../../../app/feature/dashboard/header";

type FirmProps = {
  isSelected: boolean;
  firm: TFirm;
};

const svgColors: Record<string, string> = {
  disabled: "grey",
  active: "white",
  selected: "dark",
};

const Firm: FC<FirmProps> = ({ firm, isSelected }) => {
  const dispatch = useAppDispatch();
  const { isEntity, fullTitle, shortTitle, id, reportLinks } = firm;
  const { counters, countersPending } = useAppSelector(getDashboardHeader);
  const { pending } = useAppSelector(getDashboardProjects);
  const { tab } = useAppSelector(getDashboardDestinations);

  const count = counters?.[id]?.total ?? 0;
  const color = isSelected ? svgColors.selected : svgColors.active;

  const handleChange = () => {
    if (pending || isSelected) {
      return;
    }

    dispatch(clearProjectsData());

    dispatch(setUserType(id));

    if (tab !== "kanban") {
      dispatch(setTab("project->section"));
    }

    dispatch(setReportLinks(reportLinks));
    dispatch(setProjectId(0));
    dispatch(setProjectStatus(id));
  };

  return (
    <Tooltip title={fullTitle} followCursor placement="bottom-end">
      <div
        onClick={handleChange}
        className={cn(style.firm, isSelected && style.selected)}
      >
        {isEntity ? <CaseSVG color={color} /> : <PersonSVG color={color} />}
        <p>{shortTitle}</p>
        <h3>{!countersPending && count}</h3>
      </div>
    </Tooltip>
  );
};

export default Firm;
