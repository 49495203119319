import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { view, updateDates } from "./queries";

export const schedulePlanApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    view: view(builder),
    updateDates: updateDates(builder),
  }),
  overrideExisting: false,
});

export const { useViewQuery, useUpdateDatesMutation } = schedulePlanApi;
