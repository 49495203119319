import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import {
  ApiProjectProcessUpdateAdditional,
  ApiProjectProcessUpdateAdditionalWork,
} from "../../../../services/api/projectProcess/info";
import { fetchProjectProcessViewDuplicate } from "./fetchProjectProcessView";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessUpdateAdditional = createAsyncThunk<
  boolean,
  any
>(
  "projectProcess/fetchProjectProcessUpdateAdditional",
  async ({ processId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessUpdateAdditional(processId, data),
      (data) => {
        dispatch(fetchProjectProcessViewDuplicate(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessUpdateAdditionalWork = createAsyncThunk<
  boolean,
  any
>(
  "projectProcess/fetchProjectProcessUpdateAdditionalWork",
  async ({ processId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessUpdateAdditionalWork(processId, data),
      (data) => {
        dispatch(fetchProjectProcessViewDuplicate(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
