import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = RequestParams<{
  id: number;
}>;

export const depositFunds = (builder: QueryBuilder) =>
  builder.mutation<boolean, TParams>({
    query: (params) => ({
      url: `v1/project-process/deposit-funds`,
      params: {
        id: params.id,
      },
      method: HTTPMethods.POST,
    }),
  });
