import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { config } from "../../../../../../shared/lib/configs/app.config";
import { useExecuteProcess } from "../lib/hooks/useExecuteProcess";

const defaultValues = {
  files: [],
  comment: "",
  status: null,
};

type Props = {
  taskId: number;
  header: string;
};

export const ExecuteProcessForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    isRejecting,
    isAccepting,
    isSubmitting,
    isTaskViewPending,
    control,
  } = useExecuteProcess({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>

      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange } }) => (
              <Uploader
                canEdit
                getDataCallback={() => getTaskFiles(taskId)}
                setFiles={(files) => {
                  onChange(files);
                }}
                autoUpload
                postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
                error={Boolean(errors.files)}
                helperText={errors.files?.message}
              />
            )}
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isRejecting}
              type="submit"
              color="error"
              variant="outlined"
              width={280}
              onClick={() => {
                setValue("status", Statuses.IS_REJECT);
              }}
            >
              Отказаться от исполнения
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isAccepting}
              type="submit"
              width={250}
              onClick={() => {
                setValue("status", Statuses.IS_DONE);
              }}
            >
              Отправить на проверку
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
