import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessDocuments } from "../services/fetchProjectProcessDocuments";
import { ProjectProcessDocumentsType } from "../types/projectProcessDocumentsTypes";

type InitialState = {
  isLoad: boolean;
  error: any;
  documents: ProjectProcessDocumentsType[] | null;
};

const initialState: InitialState = {
  isLoad: false,
  error: null,
  documents: null,
};

const projectProcessDocuments = createSlice({
  name: "projectProcessInfoDocuments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessDocuments.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProjectProcessDocuments.fulfilled, (state, action) => {
      state.isLoad = false;
      state.documents = action.payload;
    });
    builder.addCase(fetchProjectProcessDocuments.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
});
export default projectProcessDocuments;
