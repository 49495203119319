import React, { memo } from "react";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { ExpertiseRemarks } from "../../../../../app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import cls from "./ExpertiseCard.module.scss";
import { Remark } from "./Remark/Remark";
import { getProjectProcessExpertiseIndexes } from "../../../../../app/feature/ProjectProcessView/Expertise/selectors/getProjectProcessExpertise";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { setOpenExpertise } from "../../../../../app/feature/ProjectProcessView/Expertise/slice/projectProcessExpertise";

interface ExpertiseCardProps {
  name: string;
  remarks: ExpertiseRemarks[];
  index: number;
  dot: boolean;
}
export const ExpertiseCard = memo(
  ({ name, remarks, index, dot }: ExpertiseCardProps) => {
    const dispatch = useAppDispatch();
    const expertiseIndexes = useAppSelector(getProjectProcessExpertiseIndexes);
    const dotStatus = dot ? "warning" : undefined;
    const handleExpertiseClick = (index: number) => {
      dispatch(setOpenExpertise(index));
    };

    return (
      <div>
        <div onClick={() => handleExpertiseClick(index)}>
          <Accordion
            text={name}
            dotStatus={dotStatus}
            dotSize={10}
            toogle={Boolean(expertiseIndexes[index])}
            classname={cls.accordion}
          />
        </div>
        {!!expertiseIndexes[index] && (
          <>
            {remarks.map((remark, i) => (
              <Remark
                key={remark.remark.id}
                num_by_project={remark.remark.num_by_project}
                files={remark.remark?.files || []}
                date_create={remark.remark.date_create}
                rows={remark.rows}
                dot={remark.dot}
                canComplete={remark?.can?.complete}
                index={`${index}.${i}`}
                task={remark.task}
                name={name}
              />
            ))}
          </>
        )}
      </div>
    );
  }
);
