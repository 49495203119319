import React from "react";
import { Link } from "react-router-dom";
import styles from "./Info.module.scss";
import { DoneSvg, WorkSvg } from "../ObjectSvg/ObjectSvg";
import { StageType } from "../../../../../../../app/feature/ProjectView/ComplexReducer/thunks";

interface InfoProps {
  name: string;
  id: number;
  gip: any;
  stage: any;
}

function selectSvgIcon(stage: StageType) {
  if (stage.done) {
    return <DoneSvg />;
  }
  if (stage.work) {
    return <WorkSvg />;
  }
  if (stage.enable) {
    return <WorkSvg fill={"#838A93"} />;
  }
  return null;
}

export const Info = ({ name, id, gip, stage }: InfoProps) => {
  const typePdIcon = selectSvgIcon(stage.pd);
  const typeRdIcon = selectSvgIcon(stage.pd);
  const typeAhIcon = selectSvgIcon(stage.pd);
  const isStage = typePdIcon || typeRdIcon || typeAhIcon;
  return (
    <div className={styles.info}>
      <div>
        <Link to={`/project/${id}?tab=info`}>{name}</Link>
        {gip && <p className={styles.info_gip}>{`ГИП: ${gip}`}</p>}
        {!gip && <p className={styles.info_gip}>{`ГИП: отсутствует`}</p>}
      </div>
      {isStage && (
        <div className={styles.milestones}>
          <h3>Стадии:</h3>
          <div className={styles.milestones_stages}>
            {typePdIcon && (
              <div className={styles.milestones_circle}>
                {typePdIcon}
                <p>ПД</p>
              </div>
            )}
            {typeRdIcon && (
              <div className={styles.milestones_circle}>
                {typeRdIcon}
                <p>РД</p>
              </div>
            )}
            {typeAhIcon && (
              <div className={styles.milestones_circle}>
                {typeAhIcon}
                <p>АН</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
