import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import cls from "./Graph.module.scss";
import { GraphProject } from "./GraphProject/GraphProject";
import { fetchProjectGanttData } from "../../../../../app/feature/ProjectView/GraphReducer/thunks";
import { fetchProjectView } from "../../../../../app/feature/ProjectView/thunks";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { getProjectGraphData } from "../../../../../app/feature/ProjectView/GraphReducer/graphReducer";
import { SkeletonGraphTab } from "../../../../UI/components/Project/Skeleton";

export const Graph = () => {
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMobile = useIsMobile(500);

  const { isLoadGraph } = useSelector(getProjectGraphData);
  const { error } = useSelector(getProjectGraphData);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectView(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectGanttData(+params.id));
    }
  }, [params.id]);

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
  }

  if (isLoadGraph) {
    return <SkeletonGraphTab />;
  }
  return (
    <>
      {!isMobile && (
        <div className={cls.wrapper}>
          <div className={cls.graph}>
            <GraphProject />
          </div>
        </div>
      )}
    </>
  );
};
