import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  publish,
  unPublish,
  create,
  removed,
  getUserTypeToAssign,
  getUsersToAssign,
  getWorkAccount,
  view,
  updatePrice,
  application,
  getDocumentations,
  dataRemoveExecutor,
  publishInfo,
  updatePublished,
} from "./queries";

export const processWorkApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    processWorkView: view(builder),
    processWorkUpdatePrice: updatePrice(builder),
    processWorkApplication: application(builder),
    processWorkGetDocumentations: getDocumentations(builder),
    processWorkPublish: publish(builder),
    processWorkUnPublish: unPublish(builder),
    processWorkCreate: create(builder),
    processWorkRemoved: removed(builder),
    processWorkGetUserTypeToAssign: getUserTypeToAssign(builder),
    processWorkGetUsersToAssign: getUsersToAssign(builder),
    processWorkGetWorkAccount: getWorkAccount(builder),
    processWorkPublishInfo: publishInfo(builder),
    processWorkDataRemoveExecutor: dataRemoveExecutor(builder),
    processWorkUpdatePublished: updatePublished(builder),
  }),
  overrideExisting: false,
});

export const {
  useProcessWorkViewQuery,
  useProcessWorkUpdatePriceMutation,
  useProcessWorkApplicationQuery,
  useProcessWorkGetDocumentationsQuery,
  useProcessWorkPublishMutation,
  useProcessWorkUnPublishMutation,
  useProcessWorkCreateMutation,
  useProcessWorkRemovedQuery,
  useProcessWorkGetUserTypeToAssignQuery,
  useProcessWorkGetUsersToAssignQuery,
  useProcessWorkGetWorkAccountQuery,
  useProcessWorkPublishInfoQuery,
  useProcessWorkDataRemoveExecutorQuery,
  useProcessWorkUpdatePublishedMutation,
} = processWorkApi;
