import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { TextField } from "@mui/material";
import classNames from "classnames";
import ReactDatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { getMonth, getYear } from "date-fns";
import { range } from "lodash";
import { palette, textFieldSX } from "../../../styles/restyle";
import style from "./NewDatePicker.module.scss";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { ModalCloseIcon } from "../SVG";
import CustomButton from "../CustomButton/CustomButton";
import { CircularIndeterminate } from "../../UI/components/Project/Skeleton";

type DateFieldProps = ComponentPropsType<{
  startDateProp?: Date | null;
  changeDateHandler?: (date: Date | null) => void;
  changeBlur?: () => void;
  changeFocus?: () => void;
  onClose?: () => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  onSave?: () => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  inline?: boolean;
  helperText?: string;
  disableClear?: boolean;
  inputProps?: any;
  inputLabelPropsModified?: any;
  disableForward?: boolean;
  isCorrect?: boolean;
  isLoad?: boolean;
  className?: string;
}>;

const years = range(2020, getYear(new Date()) + 5, 1);
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const NewDatePicker: React.FC<DateFieldProps> = ({
  startDateProp = null,
  changeDateHandler = () => {},
  changeBlur = () => {},
  changeFocus = () => {},
  minDate,
  maxDate,
  inline,
  onClose,
  onSave,
  label = "Дата",
  error,
  disabled,
  helperText,
  disableClear,
  className,
  isCorrect = false,
  isLoad,
}) => {
  const [startDate, setStartDate] = useState<any>(startDateProp);
  const refDate = useRef(null);
  const onChangeDates: DateFieldProps["changeDateHandler"] = (date) => {
    // @ts-ignore
    setStartDate(date);
    changeDateHandler(date);
  };

  const onReset = () => {
    setStartDate(null);
  };

  useEffect(() => {
    if (startDateProp === null) {
      onReset();
    }

    if (startDateProp) {
      setStartDate(startDateProp);
    }
  }, [startDateProp]);

  const disabledDate = startDateProp
    ? moment(startDateProp).format("DD.MM.YYYY")
    : "";

  if (disabled) {
    return (
      <TextField
        disabled
        value={disabledDate}
        label={label}
        error={error}
        helperText={helperText}
        className={classNames(isCorrect && style.correct)}
        {...textFieldSX}
      />
    );
  }

  return (
    <>
      <div className={classNames(className, style.datePickerComponent)}>
        <div className={style.datePickerInputContainer}>
          <ReactDatePicker
            id="date-picker"
            className={classNames(
              style.datePickerInput,
              !label && style.noLabelDate
            )}
            ref={refDate}
            locale={ru}
            autoComplete="off"
            inline={inline}
            maxDate={minDate}
            minDate={maxDate}
            dateFormat={"dd.MM.yyyy"}
            selected={startDate}
            onFocus={changeFocus}
            onChange={onChangeDates}
            onBlur={changeBlur}
            popperPlacement={"bottom"}
            isClearable={!disabled && !disableClear}
            disabled={disabled}
            wrapperClassName={style.fullWidth}
            popperClassName={style.datePickerPopper}
            renderCustomHeader={({
              date,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={style.head}>
                <div className={style.year}>
                  <p>{months[getMonth(date)]}</p>
                  <select
                    className={style.select}
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(Number(value))
                    }
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={style.arrows}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      decreaseMonth();
                    }}
                    className={style.button}
                    disabled={prevMonthButtonDisabled}
                  >
                    <ChevronLeft className={style.chevron} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      increaseMonth();
                    }}
                    className={style.button}
                    disabled={nextMonthButtonDisabled}
                  >
                    <ChevronRight className={style.chevron} />
                  </button>
                </div>
                <div className={style.close} onClick={onClose}>
                  <ModalCloseIcon />
                </div>
              </div>
            )}
          >
            <CustomButton
              onClick={onSave}
              borderRadius={4}
              background={palette.oceanic}
              color={palette.midnightBlack}
              width={130}
              height={32}
              style={{ margin: "0 12px 12px auto" }}
            >
              {isLoad ? <CircularIndeterminate /> : "Сохранить"}
            </CustomButton>
          </ReactDatePicker>
        </div>
        {Boolean(error && helperText) && (
          <div className={style.errorText}>{helperText}</div>
        )}
      </div>
      <div className={style.blackout} />
    </>
  );
};
