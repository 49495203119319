import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";

export type TriangleDecorProps = ComponentPropsType<{
  isActive: boolean;
}>;

export const TriangleDecor: React.FC<TriangleDecorProps> = ({
  isActive,
  theme = "dark",
}) => {
  return (
    <div
      className={classNames(
        style.triangle,
        isActive && style.active,
        style[theme]
      )}
    />
  );
};
