import { useMemo } from "react";

export const useDataMapper = (rawData: any, mapper: (data: any) => any) => {
  const data = useMemo(() => {
    if (!rawData) {
      return undefined;
    }

    return mapper(rawData);
  }, [rawData]);

  return data;
};
