import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import style from "./style.module.scss";
import { selectorAppTheme } from "../../../../app/feature/app/app";

export const UserAgreements = () => {
  const theme = useSelector(selectorAppTheme);

  return (
    <div className={classNames(style.condition, style[theme])}>
      <p>
        <strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
      </p>
      <p>
        1.1. Настоящее Соглашение и Приложения к нему определяют порядок
        использования сайта{" "}
        <a href="https://pirs.online">https://pirs.online</a> («Платформа»)
        Пользователями – юридическими, совершеннолетними физическими лицами,
        индивидуальными предпринимателями, а также лицами, осуществляющими
        предпринимательскую деятельность без образования юридического лица
        (далее – «Пользователь»), является соглашением между ООО «ЦП «ПИРС»
        (далее – «ПИРС») и Пользователями (далее совместно также «Стороны»).
      </p>
      <p>
        1.2. Соглашение распространяются на использование бесплатных и платных
        сервисов («далее – Услуги»). Соглашение распространяются также на
        отношения, связанные с правами и интересами третьих лиц, не являющимися
        Пользователями, но чьи права и интересы могут быть затронуты в
        результате действий Пользователей (когда применимо).
      </p>
      <p>
        1.3. Настоящее Соглашение заключается в порядке п. 3 ст. 434, п. 2 ст.
        437, п. 3 ст. 438 Гражданского Кодекса Российской Федерации (далее — ГК
        РФ) путем размещения настоящей публичной оферты и получения акцепта на
        нее от любого лица, согласившегося с ее условиями.
      </p>
      <p>
        1.4. Настоящее Соглашение согласно ст. 428 ГК РФ представляет собой
        договор присоединения, т.е. договор, условия которого определены ПИРС в
        настоящей стандартной форме и могут быть приняты Пользователем не иначе
        как путем присоединения к предложенному Соглашению в целом, без права на
        внесение в него каких-либо изменений и дополнений.
      </p>
      <p>
        1.5. Согласно п. 3 ст. 438 ГК РФ безусловным принятием (акцептом)
        условий настоящей оферты считаются действия заинтересованного лица по
        регистрации своего Личного кабинета на Платформе путем заполнения им
        регистрационной формы, располагающейся по адресу:{" "}
        <a href="https://pirs.online/site/registration">
          https://pirs.online/site/registration
        </a>
        , выбора типа Пользователя, выражения согласия с условиями настоящего
        Соглашения путем проставления «галочки» в регистрационной форме или
        форме добавления заказа в соответствующем поле при регистрации или
        добавлении заказа. Акцептом также могут рассматриваться иные действия
        заинтересованного лица, прямо указанные в настоящем Соглашении. С
        момента совершения акцепта Пользователь считается ознакомившимся и
        согласившимся с настоящей офертой и в соответствии с ГК РФ считается
        вступившим с ПИРС в договорные отношения на условиях настоящего
        Соглашения.
      </p>
      <p>
        1.6. Настоящее Соглашение, заключаемое путем акцепта настоящей публичной
        оферты, не требует его двустороннего подписания сторонами в форме
        отдельного документа.
      </p>
      <p>
        1.7. Пользователь, заключая настоящее Соглашение, выражает свое согласие
        на получение на свой адрес электронной почты, указанный им при
        регистрации, заполнении бланка заказа сообщений от ПИРС, содержащих
        различную информацию, в том числе рекламного содержания.
      </p>
      <p>
        1.8. Пользователь обязуется периодически знакомиться с содержанием
        Соглашения, приложений к нему, размещённых на Платформе.
      </p>
      <p>
        1.9. Настоящие Соглашение могут быть изменены и/или дополнены ПИРС в
        одностороннем порядке без какого-либо специального уведомления.
        Настоящее Соглашение является открытым и общедоступным документом.
        Действующая редакция Соглашения располагается в сети Интернет по адресу:{" "}
        <a href="https://pirs.online/site/policy">
          https://pirs.online/site/policy
        </a>
        . Продолжение использования Платформы Пользователем после внесения
        изменений и/или дополнений в настоящие Соглашение означает принятие и
        согласие Пользователя с такими изменениями и/или дополнениями.
      </p>
      <p>
        1.10. Администратор Платформы предоставляет Пользователю следующие
        услуги по обеспечению взаимодействия между сторонами по вопросам,
        связанным с совершением (заключением, исполнением, прекращением,
        расторжением) Сделок:
      </p>
      <ul>
        <li>
          фиксация волеизъявления сторон на заключение, изменение или
          расторжение Сделки; фиксация и воспроизведение информации о
          заключенных Пользователями Сделках;
        </li>
        <li>
          информационный обмен между Пользователями по вопросам, связанным с
          совершением Сделок; обеспечение Пользователям технической возможности
          размещения информации, связанной с совершением Сделок
        </li>
        <li>
          предоставление услуги сопровождения Сделки посредством сервиса
          «Безопасная сделка».
        </li>
      </ul>
      <p>
        1.11. Все существующие на данный момент составные части и/или элементы
        Платформы, а также любое развитие их и/или добавление новых является
        предметом настоящего Соглашения. Пользователь понимает и соглашается с
        тем, что все сервисы предоставляются "как есть" и что Платформа не несет
        ответственности ни за какие задержки, сбои, неверную или несвоевременную
        доставку, удаление или не сохранность какой-либо пользовательской
        персональной информации.
      </p>
      <p>
        1.12. Присоединяясь к настоящему Соглашению, Пользователь подтверждает
        свою осведомленность о том, что задачей Платформы и предметом настоящего
        Соглашения является исключительно оказание услуг, указанных в пункте
        1.10 настоящего Соглашения, состоящих в фиксации информации о
        волеизъявлении Участников Сделок в понимании ст. 160 Гражданского
        кодекса Российской Федерации при заключении ими сделок в простой
        письменной форме. При этом Пользователи должны предпринимать
        самостоятельные усилия с целью однозначной идентификации сторон Сделок,
        в том числе путем прямого обмена информацией друг с другом как в
        электронном виде, так и на материальных носителях.
      </p>
      <p>
        1.13. Пользователь подтверждает, что использование простой электронной
        подписи для подписания документов, связанных с заключением и исполнением
        обязательств по Договору подряда (в том числе и для заключения Договора
        подряда) и иных соглашений, совершаемых на платформе ПИРС, является
        аналогичным подписанию таких документов собственноручной подписью.
      </p>
      <p>
        <strong>2. СТАТУС ПИРС И САЙТА</strong>
      </p>
      <p>
        2.1. Платформа является интернет-ресурсом и представляет собой
        совокупность информации и программ для ЭВМ, содержащихся в
        информационной системе, обеспечивающей доступность такой информации в
        сети Интернет по сетевому адресу{" "}
        <a href="https://pirs.online">https://pirs.online</a> Платформа
        предназначена для организации взаимодействия между лицами, оказывающими
        услуги в проектировании и строительстве, предоставлении экологических
        услуг, услуг сбора предложений по поставке материалов и оборудования и
        потребителями таких работ и услуг.
      </p>
      <p>
        2.2. Все права на Платформу в целом и на использование сетевого адреса
        (доменного имени) <a href="https://pirs.online">https://pirs.online</a>{" "}
        принадлежат ПИРС. ПИРС предоставляет доступ к сайту всем
        заинтересованным лицам в соответствии с настоящими Соглашением и
        действующим законодательством Российской Федерации.
      </p>
      <p>
        2.3. Никакие положения настоящего Соглашения не предоставляет
        Пользователю право на использование фирменного наименования, товарных
        знаков, доменных имен и иных отличительных знаков ПИРС. Право на
        использование фирменного наименования, товарных знаков, доменных имен и
        иных отличительных знаков ПИРС может быть предоставлено исключительно по
        письменному соглашению с ПИРС.
      </p>
      <p>
        <strong>3. РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ</strong>
      </p>
      <p>
        3.1. При регистрации на Платформе Пользователь обязан предоставить ПИРС
        необходимую достоверную и актуальную информацию для формирования
        персональной страницы Пользователя (далее – «Личный кабинет»), включая
        сведения об образовании, сфере профессиональной деятельности, а также
        контактные данные. Пользователь должен выбрать один из профилей –
        «Заказчик», «Проектировщик», «Поставщик» или «ГИП». Для завершения
        регистрации Пользователь должен заполнить все поля соответствующих
        анкет. Присоединяясь к настоящему Соглашению, пользователь гарантирует,
        что является субъектом права и обладает всей предусмотренной законом
        правоспособностью и дееспособностью для заключения настоящего Соглашения
        и совершения Сделок
      </p>
      <p>
        3.2. Логин и пароль, используемые Пользователем для доступа к Личному
        кабинету, не восстанавливаются ПИРС. Восстановление пароля
        осуществляется Пользователем при помощи инструкций на Сайте
        самостоятельно при условии наличии у Пользователя доступа к адресу
        электронной почты, информация о котором содержится в данных его Личного
        кабинета.
      </p>
      <p>
        3.3. Пользователь самостоятельно несет ответственность за безопасность
        (устойчивость к угадыванию) выбранного им пароля, а также самостоятельно
        обеспечивает конфиденциальность своего пароля
      </p>
      <p>
        3.4. Личный кабинет позволяет Пользователю: осуществлять выбор и заказ
        Услуг, отслеживать движение внесенных денежных средств, поступивших от
        Пользователя на счет ПИРС и отображенных на лицевом счете Пользователя в
        его профиле на закладке «Кошелек» Лицевой счет – система взаиморасчетов
        Сторон по расчетным операциям), производить оплату Услуг, учет средств,
        историю платежей, размещать заказы/услуги и др.
      </p>
      <p>
        3.5. Доступ к информации о заказах иных Пользователей возможен только
        после регистрации.
      </p>
      <p>
        3.6. Пользователь несет ответственность за достоверность, актуальность,
        полноту и соответствие законодательству Российской Федерации
        предоставленной при регистрации информации и ее чистоту от претензий
        третьих лиц. Пользователь обязан при изменении своих данных,
        содержащихся в сведениях его Личного кабинета, внести соответствующие
        изменения в сведения Личного кабинета в течение 3 календарных дней
      </p>
      <p>
        3.7. При регистрации Пользователь соглашается с настоящим Соглашением и
        принимает на себя указанные в них права и обязанности, связанные с
        использованием и функционированием Платформы. Пользователь приобретает
        права пользования Услугами Платформы при условии заполнения анкеты
        регистрации. Пользователь, регистрирующийся как «Проектировщик»
        настоящим осознает, что полнота и достоверность регистрации определяет
        его возможности получения информации о размещаемых на Платформе заказах.
        ПИРС не гарантирует получение Пользователем необходимой информации, если
        данные о его профессиональной деятельности, его контактные данные или
        иные сведения являются недостоверными или неполными.
      </p>
      <p>При регистрации Пользователь соглашается с тем, что:</p>
      <ul>
        <li>
          данное Пользовательское соглашение подлежит неукоснительному
          выполнению;
        </li>
        <li>
          запрещена множественная регистрация аккаунтов одним пользователем;
        </li>
        <li>
          выбор условного имени (логина) является правом Пользователя.
          Администратор оставляет за собой право принятия мер, если его (логина)
          использование нарушает общепринятые нормы, или является оскорбительным
          для других Пользователей;
        </li>
        <li>
          изменение условного имени (логина) возможно, но по согласованию с
          Администратором
        </li>
      </ul>
      <p>
        3.8. После успешной регистрации Пользователя на Платформе ПИРС,
        Платформа принимает на себя права и обязанности перед Пользователем,
        указанные в настоящем Соглашении.
      </p>
      <p>
        3.9. Обработка персональных данных Пользователя осуществляется в
        соответствии с законодательством Российской Федерации. ПИРС принимает
        все необходимые меры для защиты персональных данных Пользователя от
        неправомерного доступа, изменения, раскрытия или уничтожения. ПИРС
        предоставляет доступ к персональным данным Пользователя только тем
        работникам, подрядчикам и агентам ПИРС, которым эта информация
        необходима для обеспечения функционирования Платформы и предоставления
        Услуг Пользователю.
      </p>
      <p>
        3.10. ПИРС вправе использовать предоставленную Пользователем информацию,
        в том числе персональные данные, в целях обеспечения соблюдения
        требований действующего законодательства Российской Федерации (в том
        числе в целях предупреждения и/или пресечения незаконных и/или
        противоправных действий Пользователей). Раскрытие предоставленной
        Пользователем информации может быть произведено лишь в соответствии с
        действующим законодательством Российской Федерации по требованию суда,
        правоохранительных органов, а равно в иных предусмотренных
        законодательством Российской Федерации случаях. Фактом регистрации на
        Платформе Пользователь дает согласие на использование своих персональных
        данных ПИРС, включая, но не ограничиваясь, на получение рассылок по
        электронной почте.
      </p>
      <p>
        <strong>4. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</strong>
      </p>
      <p>
        4.1. Пользователь не имеет права передавать свои логин и пароль третьим
        лицам, несет полную ответственность за их сохранность, самостоятельно
        выбирая способ их хранения. Пользователь на используемом им
        аппаратно-программном обеспечении может разрешить хранение логина и
        пароля для последующей автоматической авторизации на Платформе.
      </p>
      <p>
        4.2. Пользователь несет полную ответственность за действия и/или
        бездействия, приведшие к разглашению, потере, краже и т.п. его учетных
        данных и иной информации, индивидуализирующей Пользователя, а также за
        любые действия и/или бездействие третьих лиц, использующих учетные
        данные Пользователя. При этом все действия, совершенные с использованием
        Личного кабинета Пользователя, считаются произведенными самим
        Пользователем, за исключением случаев, когда Пользователь, уведомил ПИРС
        о несанкционированном доступе к своему Личному кабинету и/или о любом
        нарушении (подозрениях о нарушении) конфиденциальности своего пароля.
        ПИРС не несет ответственности за вышеуказанные действия Пользователя
        и/или третьих лиц, использующих его учетные данные.
      </p>
      <p>4.3. При пользовании Услугами Платформы Пользователь обязан:</p>
      <p>
        4.3.1. соблюдать положения действующего законодательства Российской
        Федерации, настоящих Соглашения и иных специальных документов ПИРС;
      </p>
      <p>
        4.3.2. предоставлять при регистрации достоверные, полные и актуальные
        данные, следить за их актуализацией.
      </p>
      <p>
        4.3.3. информировать ПИРС о несанкционированном доступе к Личному
        кабинету и/или о несанкционированном доступе и/или использовании пароля
        и логина Пользователя;
      </p>
      <p>
        4.3.4. не предоставлять доступ другим Пользователям к своему Личному
        кабинету или к отдельной содержащейся на нем информации в случае, если
        это может привести к нарушению законодательства Российской Федерации
        и/или настоящих Соглашения, специальных документов ПИР.
      </p>
      <p>
        4.3.5. не размещать в Личном кабинете, а также не использовать в общении
        с другими Пользователями информацию и объекты (включая ссылки на них),
        которые могут нарушать права и интересы других лиц.
      </p>
      <p>
        4.3.6. хранить в тайне и не предоставлять другим Пользователям и третьим
        лицам ставшие ему известными в результате общения с другими
        Пользователями и иного использования Платформы персональные данные.
      </p>
      <p>
        4.3.7. осуществлять резервное копирование важной для Пользователя
        хранящейся в Личном кабинете информации.
      </p>
      <p>
        4.3.8. при размещении заказа, а также при направлении заявки на
        выполнение заказа следовать алгоритму действий, указанному на Платформе.
      </p>
      <p>
        4.3.9. Пользователь самостоятельно отслеживает изменение и/или
        дополнение любой информации, размещаемой на Платформе, в том числе,
        сведения размещаемые Пользователями во всех разделах, доступных для
        ознакомления Пользователю и используемых для выполнения принятых
        заказов.
      </p>
      <p>4.4. Пользователю при использовании Платформы запрещается:</p>
      <p>
        4.4.1. регистрироваться в качестве Пользователя от имени или вместо
        другого лица без доверенности от этого лица.
      </p>
      <p>
        4.4.2. вводить других Пользователей в заблуждение относительно своей
        личности.
      </p>
      <p>
        4.4.3. загружать, хранить, публиковать, распространять и предоставлять
        доступ или иным образом использовать любую информацию, использование
        которой не соответствует законодательству Российской Федерации.
      </p>
      <p>
        4.4.4. незаконно загружать, хранить, публиковать, распространять и
        предоставлять доступ или иным образом использовать интеллектуальную
        собственность Пользователей и третьих лиц.
      </p>
      <p>
        4.4.5. использовать программное обеспечение и осуществлять действия,
        направленные на нарушение нормального функционирования Платформы и его
        сервисов или Личных кабинетов Пользователей.
      </p>
      <p>
        4.4.6. загружать, хранить, публиковать, распространять и предоставлять
        доступ или иным образом использовать вредоносные программы.
      </p>
      <p>
        4.4.7. использовать без специального на то разрешения ПИРС
        автоматизированные скрипты (программы) для сбора информации на Платформе
        и(или) взаимодействия с Платформой и его сервисами.
      </p>
      <p>
        4.4.8. любым способом, в том числе, но не ограничиваясь, путем обмана,
        злоупотребления доверием, взлома, пытаться получить доступ к логину и
        паролю другого Пользователя.
      </p>
      <p>
        4.4.9. осуществлять незаконные сбор и обработку персональных данных
        других лиц.
      </p>
      <p>
        4.4.10. осуществлять (пытаться получить) доступ к каким-либо Услугам
        иным способом, кроме как через интерфейс, предоставленный ПИРС.
      </p>
      <p>
        4.4.11. размещать любую информацию, которая, по личному мнению ПИРС,
        является нежелательной, не соответствует целям создания Платформы,
        ущемляет интересы Пользователей или по другим причинам является
        нежелательной для размещения на Платформе.
      </p>
      <p>
        4.5. Пользователь несет личную ответственность за любую информацию,
        которую размещает на Платформе, сообщает другим Пользователям, а также
        за любые взаимодействия с другими Пользователями, осуществляемые на свой
        риск.
      </p>
      <p>
        4.6. На Платформе действует положение о присвоении рейтинга для
        Пользователей. Рейтинг определяется по двум основаниям: первый зависит
        от заполненности профиля Пользователя, второй – от количества и
        содержания отзывов о пользователе. Основанием для повышения/снижения
        рейтинга профиля является: предоставление/отсутствие достоверных
        документов о профессиональном образовании, наличие/отсутствие
        документов, подтверждающих право осуществления предлагаемого вида
        деятельности (лицензии, членство в СРО и иные). Основанием для
        повышения/снижения рейтинга Пользователя - проставляемый иными
        Пользователями самостоятельно с использованием соответствующего
        функционала Платформы после завершения оказания Пользователем
        соответствующей услуги/отказа от оказания услуги. Основанием для
        снижения рейтинга также являются любые действия Пользователя, которые
        могут повредить ПИРС или другим Пользователям, включая, но не
        ограничиваясь: недостоверность данных о Пользователе, мотивированные
        негативные отзывы других Пользователей, и др. Пользователь гарантирует
        наличие у допускаемого представителя необходимого объема полномочий
        действовать от имени Пользователя. Действия, связанные с использованием
        Платформы, совершенные представителем Пользователя, допущенным
        Пользователем к использованию Платформой, считаются действиями
        Пользователя и порождают юридические последствия для Пользователя.
        Платформа ПИРС не осуществляет проверку действительности полномочий
        представителя Пользователя, и не несет ответственности за наступившие
        для Пользователя неблагоприятные последствия совершения от имени
        Пользователя Сделок и иных действий с использованием Платформы ПИРС
        неуполномоченным лицом, допущенным Пользователем к использованию
        Платформой. Пользователь обязуется немедленно уведомить Платформу о
        любом случае неразрешенного Пользователем доступа к Платформе с
        использованием идентификационных данных Пользователя и/или о любом
        нарушении безопасности. Пользователь самостоятельно осуществляет
        завершение работы на Платформе под своим паролем (ссылка "Выйти") по
        окончании каждой сессии работы с Платформой. Платформа ПИРС не отвечает
        за возможную потерю или порчу данных, которая может произойти из-за
        невыполнения Пользователем вышеуказанной обязанности.
      </p>
      <p>
        4.7. Ни присоединение Пользователя к настоящему Соглашению, ни
        реализация целей настоящего Соглашения, ни его исполнение не нарушают
        действующих нормативных актов и не вступают в противоречие с этими
        актами, равно как и не наносят ущерба любым правам и обязательствам
        Пользователя и Администратора и/или правам и обязательствам третьих лиц.
      </p>
      <p>
        4.8. Исполнение пользователем условий настоящего Соглашения не приведет
        к нарушению и не входит в противоречие с какими-либо иными соглашениями
        или договоренностями Пользователя и Администратора, в которых они
        являются сторонами или участниками, равно как и не входит в противоречие
        с положениями любых нормативных актов, а также судебных решений,
        распространяющихся на каждую сторону.
      </p>
      <p>
        4.9. Настоящее Соглашение заключается сторонами не в результате стечения
        тяжелых обстоятельств, а условия настоящего Соглашения не являются для
        Пользователя невыгодными и/или кабальными.
      </p>
      <p>
        4.10. Все обязательства каждой из сторон в соответствии с настоящим
        Соглашением приняты Пользователем осознанно, добровольно, добросовестно,
        разумно, и дают основания другой стороне требовать их неукоснительного и
        тщательного выполнения.
      </p>
      <p>
        4.11. Администратор Платформы не является стороной, представителем,
        посредником по Сделкам и не участвует в Сделках в каком бы то ни было
        качестве, не приобретает прав по Сделкам и не принимает на себя никаких
        обязательств по Сделкам, заключаемым Пользователем, и не ручается за
        стороны Сделок и никоим образом не гарантирует их исполнение, равно как
        и не несет никакой ответственности ни по каким договорам между
        Пользователями и/или между Пользователями и третьими лицами.
      </p>
      <p>
        4.12. Администратор Платформы имеет право размещать на странице Вашего
        аккаунта рекламную информацию или любую другую информацию для публичного
        распространения, по своему усмотрению определяя объем, тематику такой
        информации и продолжительность ее распространения, однако без ущерба для
        содержания Вашего аккаунта.
      </p>
      <p>
        <strong>5. ПОРЯДОК ФУНКЦИОНИРОВАНИЯ ПИРС</strong>
      </p>
      <p>
        5.1. Платформа ПИРС содержит общедоступные материалы, а также материалы
        и информацию, доступную только зарегистрированным Пользователям.
      </p>
      <p>
        5.2. Пользователь, зарегистрировавшийся как «Заказчик или получивший
        статус «Заказчик» в связи со своими действиями по созданию проекта,
        путем нажатия кнопки «Создать проект» получает возможность размещать
        заказы на выполнение соответствующих работ на Платформе путем заполнения
        формы заказа/публикации. Для целей наиболее эффективного использования
        Платформы ПИРС рекомендует максимально полно и точно заполнять форму
        заказа/публикации. При заполнении формы публикации раздела в описании
        задачи указывается только публичная информация. «Заказчик» обязан
        установить срок рассмотрения заявок «Проектировщиков» и в случае
        получения удовлетворяющего его требованиям предложения выбрать
        «Проектировщика» для выполнения заказа в соответствии с алгоритмом на
        соответствующей странице (кнопка «Выбрать»)
      </p>
      <p>
        5.3. Пользователь, зарегистрировавшийся как «Проектировщик» имеет
        возможность получить заказ на выполнение соответствующих работ при
        условии, что его заявка соответствует требованиям «Заказчика», или
        заказчик согласился с иными условиями, предложенными проектировщиком и
        Пользователь выполнил все условия настоящего Соглашения.
      </p>
      <p>
        5.4. Информация о заказе размещается на страницах «Проекты» и «Заказы»
        Платформы, а также направляется по электронной почте зарегистрированным
        как «Проектировщик» Пользователям, соответствующим требованиям
        «Заказчика» о квалификации, месте нахождения, специализации и др.
      </p>
      <p>
        5.5. «Проектировщик» не получает уведомление о заказе в том случае, если
        данные в его профиле не соответствуют параметрам заказа, а также в
        случае, если им не указаны достоверные контактные данные в анкете. Такой
        «Проектировщик» также не может подать заявку на выполнение заказа на
        страницах «Заказы» и «Проекты» Платформы.
      </p>
      <p>
        5.6. «Проектировщик» получает доступ к имеющейся у ПИРС информации о
        заказе и «Заказчике» и затем право отправить заявку на исполнение
        заказа.
      </p>
      <p>
        5.7. До выбора «Заказчиком» проектировщика «Проектировщик» имеет право
        отозвать свою заявку.
      </p>
      <p>
        5.8. В информационной системе Платформы и его программном обеспечении
        отсутствуют технические решения, осуществляющие автоматические цензуру и
        контроль действий и информационных отношений Пользователей по
        использованию Платформы.
      </p>
      <p>
        5.9. ПИРС сохраняет за собой право в любое время изменять оформление
        Платформы, его содержание, список сервисов, изменять или дополнять
        используемые скрипты, программное обеспечение и другие объекты,
        используемые или хранящиеся на Платформе, любые серверные приложения в
        любое время с предварительным уведомлением или без такового.
      </p>
      <p>
        5.10. На Платформе пользователю, зарегистрировавшемуся как «Заказчик»
        или получившему статус «Заказчик» в связи со своими действиями по
        созданию проекта, предоставляется возможность использования
        дополнительных возможностей Платформы. Перечень, условия, и стоимость
        использования дополнительных возможностей Платформы соответствует
        Тарифу, выбранному Заказчиком. Информация о Тарифах размещена по адресу:{" "}
        <a href="https://pirs.online/site/rates">
          https://pirs.online/site/rates
        </a>
        .
      </p>
      <p>
        5.10.1. Начало использования Тарифа, выбранного Заказчиком, возможно
        после осуществления его оплаты в размере не менее месячной стоимости.
        Дальнейшее пользование Тарифом осуществляется при условии внесения
        соответствующего платежа на период от одного месяца до года. При
        отсутствии оплаты Тарифа на последующий месяц пользование Тарифом
        прекращается в дату завершения оплаченного периода. При оплате другого
        Тарифа до завершения действия оплаченного периода, пользование ранее
        выбранным Тарифом прекращается, Пользователю предоставляется право
        пользования оплаченным Тарифом (взамен ранее выбранного) с даты оплаты
        нового Тарифа. В оплаченный период Тарифа невозможна смена с Тарифа с
        большей стоимостью на Тариф с меньшей стоимостью. Применение Тарифа с
        меньшей стоимость относительно текущего Тарифа возможно только после
        завершения оплаченного периода Тарифа. Выбранный Тариф может
        использоваться только в пределах количества подключаемых сотрудников
        (пользователей), указанного в Тарифе. При увеличении количества
        подключаемых сотрудников (пользователей) с превышением, количества,
        предусмотренного Тарифом, Пользователю направляется соответствующее
        уведомление о необходимости осуществления дополнительной оплаты либо
        исключении существующего (их) сотрудников (пользователей) для добавления
        нового (новых) сотрудника (пользователя).
      </p>
      <p>
        5.10.2. Оказание услуг в соответствии с пунктами 5.10.-5.10.1.
        подтверждается подписанием актов оказанных услуг за отчетный период –
        месяц использования. По окончании отчетного периода на Платформе
        формируется акт оказанных услуг, подписанный простой электронной
        подписью, данный акт Пользователь обязан подписать в течение 5
        календарных дней со дня его формирования. В случае, если акт не подписан
        в течение указанного срока и от Пользователя не поступили мотивированные
        возражения по его подписанию (указание на недостатки оказанных услуг),
        то акт считается подписанным по истечение 5 календарных дней со дня его
        формирования на Платформе.
      </p>
      <p>
        <strong>6. САЙТЫ И МАТЕРИАЛЫ (КОНТЕНТ) ТРЕТЬИХ ЛИЦ</strong>
      </p>
      <p>
        6.1. Платформа содержит (или может содержать) ссылки на другие сайты в
        сети Интернет (сайты третьих лиц), статьи, фотографии, иллюстрации,
        графические изображения, видео, информацию, приложения, программы и
        другой Контент, принадлежащий или исходящий от третьих лиц (Контент
        третьих лиц), являющийся результатом интеллектуальной деятельности, и
        охраняемых в соответствии с законодательством Российской Федерации.
      </p>
      <p>
        6.2. Указанные третьи лица и их Контент не проверяются ПИРС. ПИРС не
        несет ответственность за любую информацию, размещенную на сайтах третьих
        лиц, к которым Пользователь получает доступ через Платформу или через
        Контент третьих лиц.
      </p>
      <p>
        6.3. Размещенные на Платформе ссылки не означают поддержки, рекомендации
        к использованию или одобрения ПИРС.
      </p>
      <p>
        <strong>7. ОГРАНИЧЕНИЯ ПРИ ИСПОЛЬЗОВАНИИ ПЛАТФОРМЫ</strong>
      </p>
      <p>7.1. Пользователю при исполнении настоящего Соглашения запрещается:</p>
      <p>7.1.1. Нарушать правила настоящего Соглашения;</p>
      <p>
        7.1.2. Любые действия, направленные на получение несанкционированного
        доступа к ресурсам сервера ПИРС, персональным учетным и иным данным
        других клиентов, не доступных на их Личных страницах; В случае, если
        Пользователю становится известна информация о каких-либо уязвимостях
        позволяющая получить несанкционированный доступ к ресурсам сервера ПИРС,
        персональным, учетным и иным данным других клиентов, не доступных на их
        Личных страницах и/или других уязвимостях он обязан незамедлительно
        предоставить данную информацию ПИРС;
      </p>
      <p>
        7.1.3. Размещать рекламные сообщения в разделе «Заказы» или отправлять
        при помощи системы личных сообщений на Платформе;
      </p>
      <p>
        7.1.4. Размещать и/или распространять и/или передавать контент
        (информацию), который является незаконным, вредоносным, клеветническим,
        оскорбляет ПИРС, третьих лиц, нравственность, демонстрирует (или
        является пропагандой) насилия и жестокости, нарушает права
        интеллектуальной собственности, пропагандирует ненависть и/или
        дискриминацию людей по расовому, этническому, половому, религиозному,
        социальному и другим признакам, содержит оскорбления в адрес каких-либо
        лиц или организаций, содержит элементы (или является пропагандой)
        порнографии, детской эротики, представляет собой рекламу (или является
        пропагандой) услуг сексуального характера (в том числе под видом иных
        услуг), разъясняет порядок изготовления, применения или иного
        использования наркотических веществ или их аналогов, взрывчатых веществ
        или иного оружия и т.п.;
      </p>
      <p>
        7.1.5. Размещать ссылки на своей Личной странице, содержание которых
        противоречит законодательству;
      </p>
      <p>
        7.1.6. Нарушать права третьих лиц, в том числе несовершеннолетних лиц,
        и/или причинять им вред в любой форме; выдавать себя за другого человека
        или представителя организации и/или сообщества без достаточных на то
        прав, в том числе за сотрудников Администратора, за модераторов, за
        владельца Платформы, а также введения в заблуждение относительно свойств
        и характеристик каких-либо субъектов или объектов;
      </p>
      <p>
        7.1.7. Загружать, посылать, передавать или любым другим способом
        размещать и/или распространять контент (информацию) при отсутствии прав
        на такие действия согласно законодательству или каким-либо договорным
        отношениям;
      </p>
      <p>
        7.1.8. Осуществлять через Платформу массовую рассылку Пользователям
        электронных сообщений коммерческого, рекламного и иного характера, не
        согласованных с (не запрошенных) получателем информации («спам»);
      </p>
      <p>
        7.1.9. Размещать и/или распространять какие-либо материалы, содержащие
        вирусы или другие компьютерные коды, файлы или программы,
        предназначенные для нарушения, уничтожения либо ограничения
        функциональности любого компьютерного или телекоммуникационного
        оборудования или программ, для осуществления несанкционированного
        доступа, а также серийные номера к коммерческим программным продуктам и
        программы для их генерации, логины, пароли и прочие средства для
        получения несанкционированного доступа к платным ресурсам в Интернете, а
        также размещать ссылки на вышеуказанную информацию;
      </p>
      <p>
        7.1.10. Предпринимать любые действия косвенно или непосредственно
        связанные с избеганием репутационной ответственности на Платформе, в том
        числе создания новой учетной записи, в том числе на третьих лиц, в том
        числе при наличии низкого рейтинга, а также при информировании (не
        своевременном информировании) ПИРС о том, что он не является
        «Проектировщиком» и/или «ГИП» и/или «Заказчиком» или «Поставщиком» по
        конкретному заказу, при фактическом согласии на проведение работ
        (оказании услуг) и/или их проведении (оказании);
      </p>
      <p>
        7.1.11. Публикация и/или передача, и/или распространение заведомо ложной
        информации, в том числе отзывов, создание дополнительных учетных записей
        для публикации информации и отзывов, в том числе «Проектировщиком» и/или
        «Поставщиком» и/или «ГИП» «Заказчику» в рамках реализации функциональных
        возможностей сайта и/или в рамках телефонного соединения (звонка).
      </p>
      <p>
        7.1.12. Отправка однотипных предложений, в том числе текстов в
        отправляемых предложениях, на заказы без учета индивидуальных
        особенностей заказа, пожеланий «Заказчика», иных существенных деталей
        («Спам»). В случае осуществления подобной отправки ПИРС имеет право
        технически ограничить максимальное количество предложений, которые может
        отправить Пользователь, до трех в сутки или заблокировать учетную запись
        Пользователя.
      </p>
      <p>
        7.1.13. За содержание информационных узлов, создаваемых и используемых
        Пользователем, несет ответственность непосредственно сам Пользователь.
        ПИРС не осуществляет предварительного контроля за содержанием
        размещаемой на Платформе и/или распространяемой Пользователем с его
        помощью информации, однако, когда размещение и распространение такой
        информации противоречит законодательству, ПИРС вправе приостановить
        действие настоящего Соглашения или заблокировать, или удалить Личный
        кабинет Пользователя без предупреждения.
      </p>
      <p>
        7.1.14. ПИРС в любое время вправе по своему усмотрению отказать
        Пользователю в размещении им любой информации на Платформе (письменной
        информации, фотографий и т.д.), включая, в том числе, право ПИРС на
        удаление такой информации, размещенной на Платформе.
      </p>
      <p>7.1.15. При использовании Платформы запрещается:</p>
      <ul>
        <li>
          выносить на обсуждение действия Платформы и / или поступки сотрудников
          Платформы;
        </li>
        <li>
          использование в своих сообщениях нецензурных слов, брани, иных слов,
          словосочетаний и выражений, оскорбительных для других пользователей;
        </li>
        <li>
          понижать/повышать рейтинг несанкционированными методами (при помощи
          отзывов, рекомендаций, рекламных сообщений в блогах, проектах, личных
          сообщениях, комментариях);
        </li>
        <li>
          публиковать проекты: в разделы, не соответствующие тематике и
          специализации проекта; однотипные проекты (проекты с одним и тем же
          содержанием) одним и тем же пользователем чаще одного раза в сутки с
          явно заниженным бюджетом или вознаграждением за выполнение которых
          является только отзыв; не соответствующие специализации Платформы
          которые подразумевают написание ложных отзывов о продуктах или
          сервисах; которые подразумевают покупку лайков или репостов в
          социальных сетях. созданные с целью привлечения в MLM и пирамидные
          схемы, содержащие нецензурную лексику, провоцирующие конфликты на
          политической, религиозной или национальной теме, нарушающие права
          законных правообладателей; связанные с нецелевой рассылкой рекламной
          информации в личных сообщениях.
        </li>
        <li>выяснение личных отношений через Платформу.</li>
      </ul>
      <p>
        При публикации отзывов рекомендуется руководствоваться обоснованными
        фактами и впечатлениями после сотрудничества с пользователем.
      </p>
      <p>
        В случае нарушения данных условий и прочих обязательств, установленных
        настоящим Соглашением, Администратор имеет право заблокировать аккаунт
        Пользователя и/или понизить рейтинг Пользователя на свое усмотрение.
      </p>
      <p>
        7.2. Пользователь соглашается с тем, что он несет личную ответственность
        за любой контент (информацию), который он загружает или иным образом
        доводит до всеобщего сведения (публикует) на Платформе или с его
        помощью. Пользователь не вправе загружать, передавать или публиковать
        контент (информацию), если он не был создан лично Пользователем или на
        размещение которого у Пользователя нет разрешения правообладателя.
      </p>
      <p>
        7.3. В случае поступления претензий от третьих лиц, связанных с
        размещением контента (информации) Пользователем, Пользователь
        самостоятельно и за свой счет урегулирует указанные претензии. ПИРС
        оставляет за собой право по своему собственному усмотрению, а также при
        получении информации от других пользователей либо третьих лиц о
        нарушении Пользователем настоящих Соглашения, изменять (модерировать)
        или удалять любую публикуемую Пользователем информацию, нарушающую
        запреты, установленные настоящим Соглашением, приостанавливать,
        ограничивать или прекращать доступ Пользователя ко всем или к любому из
        разделов или сервисов Платформы в любое время по любой причине или без
        объяснения причин, с предварительным уведомлением или без такового, не
        отвечая за любой вред, который может быть причинен таким действием. ПИРС
        оставляет за собой право удалить Личный кабинет Пользователя,
        аннулировать его регистрацию и(или) приостановить, ограничить или
        прекратить доступ Пользователя к любому из сервисов Платформы в случае
        неоднократного нарушения Пользователем настоящих Соглашения.
      </p>
      <p>
        7.4. В случае предъявления третьими лицами к ПИРС претензий, исков,
        требований по вопросу незаконного размещения Пользователем на Платформе
        объектов интеллектуальных прав, Пользователь обязуется возместить ПИРС
        все убытки, которые последний понес в результате такого нарушения или
        предъявления таких претензий, а ПИРС вправе прекратить действие
        Соглашения в одностороннем порядке, осуществив удаление Личного кабинета
        Пользователя.
      </p>
      <p>
        <strong>8. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ ПИРС</strong>
      </p>
      <p>
        8.1. ПИРС принимает все возможные усилия для того, чтобы Пользователи –
        «Проектировщики» и/или «ГИП» и/или «Поставщики» и их услуги
        соответствовали требованиям «Заказчиков» к уровню профессионализма и
        качеству. Однако ПИРС не несет ответственность за предоставляемые
        «Проектировщиками» и/или «ГИП» и/или «Поставщиками» услуги.
      </p>
      <p>
        8.2. ПИРС не несет ответственность за нарушение Пользователем настоящего
        Соглашения. ПИРС не несет ответственности за осуществленное в
        соответствии с настоящим Соглашением временное блокирование или удаление
        информации, или удаление Личного кабинета (прекращение регистрации)
        Пользователя.
      </p>
      <p>
        8.3. ПИРС обязуется прилагать максимум усилий для обеспечения
        бесперебойной работы Платформы. Однако ПИРС не несет ответственность за
        временные сбои и перерывы в работе Платформы и вызванные ими потерю
        информации. ПИРС не несет ответственности за любой ущерб компьютеру
        Пользователя или иного лица, мобильным устройствам, любому другому
        оборудованию или программному обеспечению, вызванный или связанный со
        скачиванием материалов с Платформы или по ссылкам, размещенным на
        Платформе.
      </p>
      <p>
        8.4. ПИРС имеет право распоряжаться статистической информацией,
        связанной с функционированием Платформы, а также информацией
        Пользователей для обеспечения адресного показа рекламной информации
        различным аудиториям Пользователей Платформы. Для целей организации
        функционирования и технической поддержки Платформа и исполнения
        настоящих Соглашения ПИРС имеет техническую возможность доступа в Личные
        кабинеты пользователей.
      </p>
      <p>
        8.5. Платформа и его сервисы, включая все скрипты, приложения, контент и
        оформление Платформы поставляются «как есть». ПИРС не может
        гарантировать и не обещает никаких специфических результатов от
        использования Платформы и/или его сервисов;
      </p>
      <p>
        8.6. Ни при каких обстоятельствах ПИРС или ее представители не несут
        ответственность перед Пользователем или перед любыми третьими лицами за
        любой ущерб, в том числе косвенный, случайный, неумышленный ущерб,
        включая упущенную выгоду или потерянные данные, вред имуществу, чести,
        достоинству или деловой репутации, вызванный в связи с использованием
        Платформы, содержимого Платформы или иных материалов, к которым вы или
        иные лица получили доступ с помощью Платформы.
      </p>
      <p>
        <strong>9. ОТВЕТСВЕННОСТЬ СТОРОН</strong>
      </p>
      <p>
        9.1. При наличии у Пользователя претензий, касающихся исполнения
        настоящего Соглашения, он обязан в течение 5 (пяти) календарных дней
        после их исполнения соответствующих обязательств направить ПИРС
        претензию в письменном виде путем составления сообщения на страницах
        Платформы, указав при этом свои учетные данные, а также суть претензии.
        По истечении сроков, указанных выше, претензии Пользователя не
        принимаются, а соответствующие обязательства ПИРС по настоящему
        Соглашению считаются исполненными надлежащим образом и принятыми
        Пользователем. ПИРС в течение пятнадцати дней со дня получения претензии
        направляет Пользователю свой ответ, и в том случае, если рассматривает
        ее обоснованной, предлагает Пользователю способ разрешения сложившейся
        ситуации.
      </p>
      <p>
        9.2. «Заказчик» соглашается с тем, что у «Проектировщика» при отправке
        предложения нет возможности оценить объем и стоимость работ, и составить
        коммерческое предложение. Предложение «Проектировщика» не содержит всех
        обязательных признаков договора и не является офертой. Исключения
        составляет заявка Заказчика и выбор Подрядчика с оформленным
        предложением через услугу Платформы «Безопасная сделка».
      </p>
      <p>
        9.3. «Проектировщик» соглашается с тем, что у «Заказчика» при размещении
        заказа нет возможности указать в достаточном объеме информацию для
        составления коммерческого предложения, в том числе объем работ. Если
        «Заказчик» ошибочно или намеренно указал или не указал какую-либо
        информацию, за исключением контактных данных, это не может являться
        причиной для подачи претензии к ПИРС. Исключения составляет заявка
        Заказчика с оформленным предложением через услугу Платформы «Безопасная
        сделка».
      </p>
      <p>
        9.4. Претензия направляется Пользователем путем размещения сообщения на
        страницах Платформы и/или на адрес электронной почты ПИРС, указанный в
        настоящем Соглашении. Ответ на претензию размещается в личном кабинете
        пользователя, либо направляется ПИРС на адрес электронный почты
        Пользователя, указанный в данных его Личного кабинета.
      </p>
      <p>
        9.5. По всем вопросам, не урегулированным настоящей офертой, а также при
        разрешении споров, возникших в процессе ее исполнения, Стороны
        руководствуются законодательством.
      </p>
      <p>
        9.6. ПИРС не несет ответственности за ущерб, понесенный Пользователем
        и/или третьими лицами из-за разглашения, потери Пользователем или кражи
        у него учетных или каких-либо других данных.
      </p>
      <p>
        9.7. Пользователь несет ответственность за любые неправомерные действия
        и/или бездействие лиц, использующих его учетные данные, повлекшие
        причинение любого вреда ПИРС, включая нарушение деловой репутации, и
        возмещает ПИРС убытки.
      </p>
      <p>
        9.8. ПИРС не возмещает Пользователю убытки (включая упущенную выгоду),
        понесенные Лицензиатом в период использования или неиспользования
        функционала Платформы.
      </p>
      <p>
        9.9. ПИРС не несет ответственности за ненадлежащее функционирование сети
        Интернет, ее частей или за качество линий связи, не имеющих отношения к
        собственным ресурсам ПИРС, и за их доступность для Пользователя, а также
        не несет ответственности за изменение свойств, функций Платформы.
      </p>
      <p>
        9.10. В случае если надлежащее исполнение сторонами настоящего
        Соглашения невозможно в силу объективных причин (действие непреодолимой
        силы), которые Стороны не могли ни предвидеть, ни предотвратить
        (стихийные бедствия, изменения текущего законодательства РФ, действия
        органов государственной власти и управления, военные действия всех видов
        и т. д.), ни одна из сторон не вправе требовать от другой стороны
        возмещения причиненных ненадлежащим исполнением или неисполнением
        настоящего договора убытков (включая упущенную выгоду).
      </p>
      <p>
        9.11. Стороны договорились, что все споры, вытекающие из настоящего
        Соглашения, подлежат рассмотрению по месту нахождения ПИРС.
      </p>
      <p>
        <strong>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
      </p>
      <p>
        10.1. Настоящее Соглашение регулируется и толкуется в соответствии с
        законодательством Российской Федерации. Вопросы, не урегулированные
        Соглашением, подлежат разрешению в соответствии с законодательством
        Российской Федерации.
      </p>
      <p>
        10.2. В случае возникновения любых споров или разногласий, связанных с
        исполнением настоящего Соглашения, Пользователь и ПИРС приложат все
        усилия для их разрешения путем проведения переговоров между ними. В
        случае, если споры не будут разрешены путем переговоров, споры подлежат
        разрешению в порядке, установленном действующим законодательством
        Российской Федерации.
      </p>
      <p>
        10.3. Настоящее Соглашение вступают в силу для Пользователя с момента
        его присоединения к ним и действуют в течение неопределенного срока.
      </p>
      <p>
        10.4. Если по тем или иным причинам одно или несколько положений
        настоящих Соглашения будут признаны недействительными или не имеющими
        юридической силы, это не оказывает влияния на действительность или
        применимость остальных положений.
      </p>
      <p>Приложения к Соглашению:</p>
      <p>
        –{" "}
        <a href="https://pirs.online/site/policy">
          Политика конфиденциальности.
        </a>
      </p>
    </div>
  );
};
