import React, { ChangeEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormWrapper } from "../../../../../newUI/FormWrapper/FormWrapper";
import cls from "./ModalAdditional.module.scss";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { fetchPublishInfo } from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  setAdditionalChecked,
  setInitialAdditionalChecked,
} from "../../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessInfo";
import { getProjectProcessAdditionalChecked } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessAdditional";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import {
  fetchProjectProcessUpdateAdditional,
  fetchProjectProcessUpdateAdditionalWork,
} from "../../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessUpdateAdditional";

interface ModalAdditionProps {
  closeModal: () => void;
}
export const ModalAddition = ({ closeModal }: ModalAdditionProps) => {
  const dispatch = useAppDispatch();
  const { publishInfo, isLoadPublishInfo } = useAppSelector(getProjectSections);
  const processId = useAppSelector(getProjectProcessInfoId);
  const checked = useAppSelector(getProjectProcessAdditionalChecked);
  const processData = useAppSelector(getProjectProcessInfoData);

  useEffect(() => {
    if (processId) {
      dispatch(fetchPublishInfo({ id: processId }));
    }
  }, [dispatch, processId]);

  useEffect(() => {
    const data = {
      kac: processData?.kac,
      vor: processData?.vor,
      vorPnr: processData?.vor_pnr,
    };
    dispatch(setInitialAdditionalChecked(data));
  }, [processData]);

  const { handleSubmit } = useForm();

  const formSubmitHandler = async () => {
    const data: any = {};
    if (publishInfo.kac) {
      data.kac = checked.kac;
    }
    if (publishInfo.vor) {
      data.vor = checked.vor;
    }
    if (publishInfo.vor_pnr) {
      data.vor_pnr = checked.vorPnr;
    }

    if (!Object.keys(data).length) {
      closeModal();
      return;
    }

    if (processData?.typeName === "Раздел" && processId) {
      await dispatch(fetchProjectProcessUpdateAdditional({ processId, data }));
      closeModal();
    }
    if (processData?.typeName === "Задание" && processId) {
      await dispatch(
        fetchProjectProcessUpdateAdditionalWork({ processId, data })
      );
      closeModal();
    }
  };

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    dispatch(setAdditionalChecked({ name, checked }));
  }

  if (isLoadPublishInfo) {
    return <ModalPreloader />;
  }

  return (
    <FormWrapper
      title={"Дополнительные требования"}
      submitHandler={handleSubmit(formSubmitHandler)}
    >
      {publishInfo.kac && (
        <label className={cls.label}>
          <CustomCheckbox
            editing
            value={checked.kac}
            checked={checked.kac}
            name={"kac"}
            onChange={onChange}
          />
          <p>Конъюнктурный анализ цен</p>
        </label>
      )}
      {publishInfo.vor && (
        <label className={cls.label}>
          <CustomCheckbox
            editing
            value={checked.vor}
            checked={checked.vor}
            name={"vor"}
            onChange={onChange}
          />
          Ведомость объемов работ
        </label>
      )}
      {publishInfo.vor_pnr && (
        <label className={cls.label}>
          <CustomCheckbox
            editing
            value={checked.vorPnr}
            checked={checked.vorPnr}
            name={"vorPnr"}
            onChange={onChange}
          />
          ВОР на пусконаладочные работы
        </label>
      )}
      <CustomButton
        className={cls.submit}
        background={palette.green}
        width={160}
        type={"submit"}
      >
        Сохранить
      </CustomButton>
    </FormWrapper>
  );
};
