import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useProjectAccessRequestMutation,
  useProjectAccessRequestUpdateMutation,
  useProjectAccessRequestWorkMutation,
} from "src/FSD/entities/projectAccess/api";
import { useLazyTaskViewQuery } from "src/FSD/entities/task/api";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLazyUserTypeSecureMissingAttributesQuery } from "src/FSD/entities/userType/api";
import { ApiProjectAccess } from "src/FSD/entities/projectAccess/model/requestsTypes";
import { requestUpdateSchema } from "../schemas/requestUpdate";
import { Nullable } from "../../../../../../../../types/generics";
import { TSelectItem } from "../../../../../../../../components/features/Forms/SetGipForm/types";
import { WarningMessages } from "../../../../../../../shared/lib/constants/warnings";

export type TPublicationObjectValues = {
  isOpen: boolean;
  id: Nullable<number>;
  header: string;
  executorTypes: string[];
  bim: boolean;
  withDocs: boolean;
  dateStartCustomer: Nullable<string>;
  dateLimitCustomer: Nullable<string>;
  priceCustomer: Nullable<number>;
};

type TFieldValues = {
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  price: number;
  userType: Nullable<TSelectItem>;
  comment: string;
  bankAccount: Nullable<TSelectItem>;
  bimUser: boolean;
  withDocs: boolean;
  showUserTypeWarning: boolean;
  showFirmAttorneyWarning: boolean;
  dateStartCustomer: Nullable<string>;
};

type UseModalProps = {
  handleClose: () => void;
  defaultValues: TFieldValues;
  processWork?: boolean;
  edit?: boolean;
  secure: boolean;
  publicationObjectValues: TPublicationObjectValues;
};

export const useRequestUpdate = ({
  handleClose,
  defaultValues,
  processWork,
  edit,
  secure,
  publicationObjectValues,
}: UseModalProps) => {
  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(requestUpdateSchema),
    mode: "onChange",
  });

  const [taskView, { data: taskData, isLoading }] = useLazyTaskViewQuery();
  const [requestProcess] = useProjectAccessRequestMutation();
  const [requestWork] = useProjectAccessRequestWorkMutation();
  const [requestUpdate] = useProjectAccessRequestUpdateMutation();
  const [getTypeMissingAttributes, { data: typeMissingAttributes }] =
    useLazyUserTypeSecureMissingAttributesQuery();

  const fetchTaskView = async () => {
    await taskView({ id: publicationObjectValues.id! });
  };

  useEffect(() => {
    if (edit) {
      fetchTaskView();
    }
  }, [edit]);

  useEffect(() => {
    if (publicationObjectValues.withDocs) {
      setValue("withDocs", publicationObjectValues.withDocs);
    }
  }, [publicationObjectValues.withDocs]);

  useEffect(() => {
    if (edit && taskData) {
      if (taskData.content) {
        setValue("comment", taskData.content);
      }

      if (taskData.workflow_data?.date_start) {
        setValue(
          "dateStart",
          timestampToDate(taskData.workflow_data.date_start)
        );
      }

      if (taskData.workflow_data.date_limit) {
        setValue(
          "dateLimit",
          timestampToDate(taskData.workflow_data?.date_limit)
        );
      }

      if (taskData.workflow_data.price) {
        setValue("price", taskData.workflow_data.price);
      }
    } else {
      if (publicationObjectValues.dateStartCustomer) {
        setValue(
          "dateStart",
          timestampToDate(publicationObjectValues.dateStartCustomer)
        );
      }
      if (publicationObjectValues.dateLimitCustomer) {
        setValue(
          "dateLimit",
          timestampToDate(publicationObjectValues.dateLimitCustomer)
        );
      }
      if (publicationObjectValues.priceCustomer) {
        setValue("price", publicationObjectValues.priceCustomer);
      }
    }
  }, [edit, taskData]);

  useEffect(() => {
    if (publicationObjectValues.dateStartCustomer) {
      setValue("dateStartCustomer", publicationObjectValues.dateStartCustomer);
    }
  }, [publicationObjectValues.dateStartCustomer]);

  const valuesRequestUpdate = {
    userType: watch("userType"),
    process: processWork ? "задачи" : "раздела",
    showBimWarning: !watch("bimUser") && publicationObjectValues.bim,
    showUserTypeWarning: !watch("showUserTypeWarning"),
    showMissingAttributesError: !!(
      watch("userType") && typeMissingAttributes?.length
    ),
    isBankAccount: !!watch("bankAccount"),
  };

  const fetchTypeMissingAttributes = async () => {
    const userTypeId = +valuesRequestUpdate.userType!.id;

    await getTypeMissingAttributes({
      id: userTypeId,
      secure,
    });
  };

  useEffect(() => {
    if (valuesRequestUpdate.userType) {
      const userTypeShortName = valuesRequestUpdate.userType.shortTypeName;

      setValue(
        "showUserTypeWarning",
        publicationObjectValues.executorTypes.some(
          (item) => item === userTypeShortName
        )
      );

      fetchTypeMissingAttributes();
    }
    setValue("bankAccount", null);
  }, [valuesRequestUpdate.userType]);

  const fetchRequest = processWork ? requestWork : requestProcess;
  const fetchFormedData = edit ? requestUpdate : fetchRequest;
  const warningMessagesMandatoryBankDetails = publicationObjectValues.withDocs
    ? WarningMessages.MANDATORY_BANK_DETAILS
    : "";

  const onSubmit = async (data: TFieldValues) => {
    if (!typeMissingAttributes?.length) {
      const formedData: ApiProjectAccess.Request = {
        content: data.comment,
        date_start: toServerDate(data.dateStart)!,
        date_limit: toServerDate(data.dateLimit)!,
        price: data.price,
        executor_type: +data.userType!.id,
      };

      if (data.bankAccount) {
        formedData.bank_account_id = +data.bankAccount.id;
      }

      await fetchFormedData({ id: publicationObjectValues.id!, formedData });
      handleClose();
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isTaskViewPending: (isLoading || !taskData) && edit,
    valuesRequestUpdate,
    taskData,
    typeMissingAttributes,
    warningMessagesMandatoryBankDetails,
  } as const;
};
