import { Controller } from "react-hook-form";
import cn from "classnames";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SelectExecutorTypesMultiple } from "src/FSD/features/v2/SelectExecutorTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { SelectAgreement } from "src/FSD/features/v2/SelectAgreement";
import { WarningMessagePublication } from "src/FSD/shared/lib/constants/warningComponents/WarningComponents";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { AppointmentExecutor } from "./AppointmentExecutor";
import { AdditionalDevelopment } from "./AdditionalDevelopment";
import styles from "./PublishContentProcess.module.scss";
import { MissingAttributesError } from "../../../../../publishObject/modals/RequestUpdate/ui/MissingAttributesError";
import { ErrorTextFiles } from "./ErrorTextFiles";
import { SelectMoneyFromProcess } from "../../../../../../../features/v2/SelectMoneyFromProcess";
import { TValuesPublication } from "../../lib/hooks/usePublishContent";

type Props = {
  control: any;
  register: any;
  valuesPublication: TValuesPublication;
  id: number;
  setValue: any;
  errors: any;
  processData: any;
  showErrorFiles: boolean;
  secureMissingAttributes: ApiCommonResponses.SecureMissingAttributes[];
  isProcessWork: boolean;
  textProcess: string;
};

export const PublishContentProcess = ({
  control,
  register,
  valuesPublication,
  id,
  setValue,
  errors,
  processData,
  showErrorFiles,
  secureMissingAttributes,
  isProcessWork,
  textProcess,
}: Props) => {
  return (
    <div className={styles.processPublication}>
      {showErrorFiles && <ErrorTextFiles />}
      <div className={styles.processPublication__block}>
        <div className={styles.processPublication__wrapper}>
          <p className={styles.processPublication__text}>
            {`Укажите срок и стоимость ${textProcess}`}
          </p>
          <div className={styles.processPublication__container}>
            <Controller
              control={control}
              name="dateStart"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Начальный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateStart}
                  helperText={errors?.dateStart?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="dateLimit"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Конечный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateLimit}
                  helperText={errors?.dateLimit?.message}
                />
              )}
            />
            <TextField
              {...register("price")}
              label="Стоимость"
              disabled={valuesPublication.disabled}
              error={!!errors.price}
              helperText={errors?.price?.message}
            />

            {!valuesPublication.userTypeToAssign && (
              <Controller
                control={control}
                name="checkNegotiatedPrice"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label="Договорная стоимость"
                    onChange={onChange}
                    value={value}
                    checked={!!value}
                    className={styles.processPublication__treatyPrice}
                    editing
                  />
                )}
              />
            )}
          </div>
        </div>
        {isProcessWork && (
          <div className={styles.processPublication__wrapper}>
            <p className={styles.processPublication__text}>
              Определить источник финансирования
            </p>
            <Controller
              name="workAccount"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectMoneyFromProcess
                  workId={id}
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  error={!!errors.workAccount}
                  helperText={errors?.workAccount?.message}
                  setValue={setValue}
                  processData={processData}
                />
              )}
            />
          </div>
        )}
        <div className={styles.processPublication__wrapper}>
          <p className={styles.processPublication__text}>Договор</p>
          {!secureMissingAttributes.length && (
            <div
              className={cn(
                styles.processPublication__container,
                styles.processPublication__selects
              )}
            >
              <Controller
                name="agreementsActs"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectAgreement
                    value={value}
                    label="Договоры и акты"
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    disabled={!valuesPublication.canNoSecure}
                  />
                )}
              />
              <Controller
                name="safeTransaction"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectAgreement
                    value={value}
                    label="Безопасная сделка"
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    disabled={!valuesPublication.canNoSecure}
                  />
                )}
              />
            </div>
          )}
          {!!secureMissingAttributes.length && (
            <MissingAttributesError
              typeMissingAttributes={secureMissingAttributes}
            >
              {WarningMessages.WORKING_ELECTRONIC_CONTACTS_IMPOSSIBLE}
            </MissingAttributesError>
          )}
          {!valuesPublication.canNoSecure && <WarningMessagePublication />}
        </div>
        <AdditionalDevelopment
          control={control}
          valuesPublication={valuesPublication}
          textProcess={textProcess}
        />
        <div className={styles.processPublication__wrapper}>
          <p className={styles.processPublication__text}>
            Требование к исполнителю
          </p>
          <Controller
            name="executorTypes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectExecutorTypesMultiple
                value={value}
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                error={!!errors.executorTypes}
                helperText={errors?.executorTypes?.message}
                setValue={setValue}
                processWork={processData}
              />
            )}
          />
        </div>
      </div>

      <Button
        type="button"
        color="primary"
        onClick={() =>
          setValue(
            "isAppointmentExecutor",
            !valuesPublication.isAppointmentExecutor
          )
        }
      >
        Назначение исполнителя
      </Button>

      {valuesPublication.isAppointmentExecutor && (
        <AppointmentExecutor
          control={control}
          id={id}
          userType={valuesPublication.userTypeToAssign}
          executor={valuesPublication.userToAssign}
          errors={errors}
          isProcessWork={isProcessWork}
        />
      )}
    </div>
  );
};
