import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  view,
  getDocTree,
  recalculationsRelations,
  deleteProject,
  projectPause,
  projectUnPause,
  getExecutorFullTypes,
} from "./queries";

export const projectApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectView: view(builder),
    projectGetDocTree: getDocTree(builder),
    projectRecalculationsRelations: recalculationsRelations(builder),
    projectDelete: deleteProject(builder),
    projectPause: projectPause(builder),
    projectUnPause: projectUnPause(builder),
    projectGetExecutorFullTypes: getExecutorFullTypes(builder),
  }),
  overrideExisting: false,
});

export const {
  useProjectViewQuery,
  useProjectGetDocTreeQuery,
  useProjectRecalculationsRelationsMutation,
  useProjectDeleteMutation,
  useProjectPauseMutation,
  useProjectUnPauseMutation,
  useProjectGetExecutorFullTypesQuery,
} = projectApi;
