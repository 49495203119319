import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { Select } from "../../UI/components/FormComponentns/Select";
import {
  request,
  requestAllData,
} from "../../../app/services/api/requestHandler";
import {
  ApiRegionListNameAndCode,
  ApiRegionListPage,
} from "../../../app/services/api/Region/region";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";

export type RegionSelectItem = {
  title: string;
  code: number;
};

export type RegionSelectProps = ComponentPropsType<{
  changeHandler?: (region: RegionSelectItem) => void;
  value: number | null;
}>;

export const RegionSelect: React.FC<RegionSelectProps> = ({
  changeHandler = () => {},
  value = undefined,
  variant,
}) => {
  const dispatch = useDispatch();
  const [allRegions, setAllRegions] = useState<RegionSelectItem[]>([]);
  const [regionOptions, setRegionOptions] = useState<RegionSelectItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    RegionSelectItem | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const searchHandler = (regionTitle: string) => {
    if (regionTitle === "") {
      setRegionOptions(allRegions);
      return;
    }
    request(ApiRegionListNameAndCode(regionTitle), (data) => {
      setRegionOptions(data);
    })(dispatch);
  };

  const onLoadRegion = () => {
    requestAllData(
      ApiRegionListPage("1"),
      (data) => {
        setAllRegions(data.flat(Infinity));
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса списка регионов", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  useEffect(() => {
    if (allRegions.length > 0) {
      setRegionOptions(allRegions);
    }
  }, [allRegions]);

  useEffect(() => {
    setSelectedValue(
      allRegions.find((item) => Number(item.code) === Number(value))
    );
  }, [value, allRegions]);

  useEffect(() => {
    onLoadRegion();
  }, []);

  return (
    <>
      {
        <Select
          changeHandler={(event, value) => {
            changeHandler(value);
          }}
          key={selectedValue ? selectedValue.title : "none"}
          value={selectedValue}
          searchHandler={searchHandler}
          variant={classNames(variant)}
          label={"Регион"}
          options={regionOptions}
        />
      }
    </>
  );
};
