import * as Yup from "yup";

export const resetPasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .required("Необходимо заполнить «Пароль»")
    .min(6, "Значение «Пароль» должно содержать минимум 6 символов")
    .max(50, "Значение «Пароль» должно содержать максимум 50 символов"),
  password_confirm: Yup.string()
    .required("Необходимо заполнить «Повторить пароль»")
    .when("password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref("password")], "Пароли не совпадают"),
    }),
});
