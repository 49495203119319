import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import classNames from "classnames";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { textFieldSX } from "../../../../styles/restyle";

type PhoneTypeProps = ComponentPropsType<{
  title?: string;
  value?: string;
  changeHandler?: (type: any) => void;
  changeBlur?: (type: any) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  phoneContainerStyles?: string;
  inputContainerStyles?: string;
  phoneInputStyles?: string;
  fieldSetStyles?: string;
  labelStyles?: string;
  errorStyles?: string;
}>;

export const Phone: React.FC<PhoneTypeProps> = ({
  title = "Телефон",
  value = "",
  changeHandler = () => {},
  changeBlur = () => {},
  error,
  disabled,
  errorText = "",
  phoneContainerStyles = "",
  inputContainerStyles = "",
  phoneInputStyles = "",
  fieldSetStyles = "",
  labelStyles = "",
  errorStyles = "",
}) => {
  const theme = useSelector(selectorAppTheme);
  const errorClass = `${error ? style.error : ""}`;

  return (
    <div className={classNames(style.phoneContainer, phoneContainerStyles)}>
      <label
        htmlFor="phone"
        className={classNames(
          style.label,
          style[theme],
          labelStyles,
          errorClass
        )}
      >
        {title}
      </label>
      <div className={classNames(style.inputContainer, inputContainerStyles)}>
        <PhoneInput
          autocompleteSearch={false}
          containerClass={style.phoneInputContainer}
          inputClass={classNames(
            style.phoneInput,
            style[theme],
            phoneInputStyles,
            disabled && style.isDisabled
          )}
          dropdownStyle={{}}
          inputStyle={{
            backgroundColor: "red",
          }}
          inputProps={{
            id: "phone",
            name: "phone",
            autoComplete: "off",
          }}
          placeholder=""
          specialLabel=""
          country={"ru"}
          preferredCountries={["ru", "kz", "by", "md", "az", "tm", "tj", "ua"]}
          value={value}
          onChange={(value, data, event, formattedValue) => {
            changeHandler(formattedValue);
          }}
          onBlur={changeBlur}
          disabled={disabled}
          {...textFieldSX}
        />
        <fieldset
          className={classNames(
            style.fieldSet,
            style[theme],
            fieldSetStyles,
            errorClass
          )}
        >
          <legend className={style.legend}>
            <span>{title}</span>
          </legend>
        </fieldset>
      </div>
      {error && <div className={errorStyles}>{errorText}</div>}
    </div>
  );
};
