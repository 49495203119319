import { useEffect } from "react";

export const useSecureValue = (
  value: string,
  setValue: (arg: string[]) => void
) => {
  useEffect(() => {
    if (value === "Да") {
      setValue(["Да"]);
    }
    if (value === "Нет") {
      setValue(["Нет", "Да"]);
    }
  }, [value]);
};

export const useDocsValue = (
  value: string,
  canNoSecure: boolean,
  setValue: (arg: boolean) => void
) => {
  useEffect(() => {
    if (!canNoSecure) {
      setValue(true);
      return;
    }
    if (value === "Да") {
      setValue(true);
    }
    if (value === "Нет") {
      setValue(false);
    }
  }, [value, canNoSecure]);
};
