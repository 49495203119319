import { IconButton, Skeleton } from "@mui/material";
import cn from "classnames";
import React, { FC, useEffect } from "react";
import style from "./style.module.scss";
import { GetStaff } from "../../../newUI/SVG";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getDashboardHeader,
  TUserType,
} from "../../../../app/feature/dashboard/header";
import {
  getDashboardDestinations,
  setTab,
} from "../../../../app/feature/dashboard/destinations";
import { getDashboardStaff } from "../../../../app/feature/dashboard/staff";
import { getStaff } from "../../../../app/feature/dashboard/staff/thunks";
import { getUserId } from "../../../../app/services/auth/auth";

type TeamsProps = {
  teams: any;
};

const Teams: FC<TeamsProps> = ({ teams }) => {
  return teams.map((team: any) => {
    const { part_group_id, members } = team;

    const name = part_group_id?.[Object.keys(part_group_id)[0]];

    return (
      <div className={style.team}>
        <h3>{name}</h3>
        <p>{members.length}</p>
      </div>
    );
  });
};

const Staff = () => {
  const dispatch = useAppDispatch();
  const { data: headerData } = useAppSelector(getDashboardHeader);
  const { userType } = useAppSelector(getDashboardDestinations);

  const { data, pending } = useAppSelector(getDashboardStaff);

  const userId = getUserId();

  const currentFirmId = headerData.find(
    (type: TUserType) => type.id === userType
  )?.firmId;

  const handleShowStaff = () => {
    if (currentFirmId) {
      dispatch(setTab("staff->groups"));
    }
  };

  useEffect(() => {
    if (userType && currentFirmId) {
      dispatch(getStaff(currentFirmId));
    }
  }, [userType, currentFirmId]);

  const currentStaff = data.staff.find(
    (staff: any) => staff.user_id === userId
  );

  const needsToAccept = Boolean(data.requests.length);

  return (
    <div
      className={cn(
        style.subdiv,
        style.staffGlobal,
        needsToAccept && style.needsToAccept
      )}
    >
      <h2>Сотрудники</h2>
      {pending.staff ? (
        <Skeleton className={style.staffSkeleton} />
      ) : (
        <>
          {currentFirmId && (
            <>
              <IconButton onClick={handleShowStaff} className={style.rightTop}>
                <GetStaff />
              </IconButton>
              <div
                className={cn(style.staff, needsToAccept && style.needsAccept)}
              >
                <Teams teams={currentStaff?.teams ?? []} />
              </div>
            </>
          )}
          <h1 className={style.rightBottom}>
            {currentFirmId ? data.staff.length : 0}
          </h1>
          {needsToAccept && (
            <div onClick={handleShowStaff} className={style.containsRequest}>
              <div />
              <p>Принять</p>
              <h3>{data.requests.length}</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Staff;
