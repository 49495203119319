import { IndexPageProjectsDataType } from "../../../../types/stateTypes/indexPages/indexPageProjectsDataTypes";

export const projectsPageInitialState: IndexPageProjectsDataType = {
  projects: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
