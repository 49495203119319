import * as Yup from "yup";
import { stringMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";

export const expertiseRemarkRowCompleteSchema = Yup.object().shape({
  responseToRemark: stringMaxLengthRequired("Ответ на замечание", 2000),
  documentationsWithRemarksRecieved: stringMaxLengthRequired(
    "Документация, в которую внесены изменения",
    2000
  ),
});
