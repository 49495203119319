import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { fetchProjectProcessDocumentations } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import cls from "../ModalCheck/ModalCheck.module.scss";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { ApiPrimaryFileType } from "../../../../../../../types/api/primaryTypes/apiPrimaryFileType";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessEdit } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessEdit";
import { fetchProjectProcessViewDuplicate } from "../../../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";

type TInputs = {
  comment: string;
  status: number;
  paid_out: number;
};

interface ModalEditProps {
  closeModal: () => void;
  comment: string;
  content: string;
  files: ApiPrimaryFileType[];
  name: string;
  taskId: number;
  price: number;
}
export const ModalEdit = memo(
  ({
    closeModal,
    comment,
    files,
    name,
    taskId,
    content,
    price,
  }: ModalEditProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const infoData = useAppSelector(getProjectProcessInfoData);

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm<TInputs>({
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          status: 4,
          comment: dataValues.comment,
          paid_out: dataValues.paid_out,
        },
      };
      await dispatch(fetchProjectProcessEdit({ taskId, data }));
      if (processId) {
        dispatch(fetchProjectProcessDocumentations(processId));
        dispatch(fetchProjectProcessViewDuplicate(processId));
      }
      closeModal();
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{name}</h1>
        <TextField
          autoComplete="off"
          {...textFieldSX}
          label="Комментарий исполнителя"
          defaultValue={comment}
          variant="outlined"
          disabled
        />
        <fieldset className={cls.fieldset}>
          <legend>Файлы решения</legend>
          <div className={cls.fieldset_files}>
            {files.map((file) => (
              <TextLink url={file.url} redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <TextField
          {...textFieldSX}
          {...register("comment")}
          multiline
          minRows={2}
          label="Комментарий"
          defaultValue={content}
          placeholder="Введите комментарий"
          error={!!errors.comment}
          helperText={errors.comment?.message}
        />
        {infoData?.account?.value && (
          <>
            <p>{`Баланс раздела: ${infoData?.account?.value}, без налога (НДС)`}</p>
            <TextField
              {...register("paid_out")}
              {...textFieldSX}
              label="Сумма для оплаты без налога (НДС)"
              defaultValue={price || 0}
              error={!!errors.paid_out}
              helperText={errors.paid_out?.message}
            />
          </>
        )}

        <div className={cls.form_submit}>
          <CustomButton type="submit" width={120} background={palette.blue}>
            Изменить
          </CustomButton>
        </div>
      </form>
    );
  }
);
