import React, { useState, useRef } from "react";
import { TextField, debounce } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { UseFormSetValue, Path } from "react-hook-form";
import cn from "classnames";
import { SubmitDataProps } from "../Payments";
import { getBankOptions } from "../../../../../../app/feature/profileReducer/thunks";
import style from "./style.module.scss";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { textFieldSX } from "../../../../../../styles/restyle";

type Props<TFormValues> = {
  name: Path<TFormValues>;
  label: string;
  register: any;
  defaultValue: string | null;
  trigger: any;
  setValue: UseFormSetValue<SubmitDataProps>;
  hasError?: boolean;
  helperText?: string | null;
  disabled?: boolean;
  targetKey?: string;
  changeHandler?: (value: Record<string, any>) => void;
};

const BankSearchField = <TFormValues extends Record<string, any>>({
  name,
  label,
  register,
  defaultValue,
  trigger,
  setValue,
  hasError,
  helperText,
  disabled,
  targetKey = "",
  changeHandler = () => {},
}: Props<TFormValues>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const outsideRef = useRef(null);
  useClickOutside(outsideRef, () => {
    setShow(false);
  });

  const searchHandler = debounce(async (val) => {
    setShow(false);
    setIsLoading(true);
    const response = await getBankOptions(val);
    if (Array.isArray(response)) {
      // @ts-ignore
      setOptions(response);
    }
    setIsLoading(false);
    setShow(true);
  }, 500);

  return (
    <div className={style.fieldContainer}>
      <TextField
        defaultValue={defaultValue}
        label={label}
        {...textFieldSX}
        {...register(name)}
        onChange={async (e) => {
          const fieldName = String(name);
          const { value } = e.currentTarget;
          await searchHandler(value);

          if (value.length === 1) {
            // @ts-ignore
            setValue(fieldName, value);
          }
        }}
        onFocus={() => {
          setShow(true);
        }}
        InputProps={{
          endAdornment: (
            <div className={style.loaderContainer}>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </div>
          ),
          shrink: true,
        }}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        error={hasError}
        helperText={helperText}
      />
      {show && !isLoading && (
        <div
          className={cn(style.optionsContainer, {
            [style.noBorder]: options.length === 0,
          })}
          ref={outsideRef}
        >
          {options.map((option: any) => {
            return (
              <button
                className={style.option}
                key={option.bik}
                type="button"
                onClick={async () => {
                  setShow(false);
                  setValue(`${targetKey}bik`, option.bik);
                  setValue(`${targetKey}bank_name`, option.name);
                  setValue(
                    `${targetKey}correspondent_account`,
                    option.correspondent_account
                  );
                  await trigger(`${targetKey}bik`);
                  await trigger(`${targetKey}bank_name`);
                  await trigger(`${targetKey}correspondent_account`);

                  if (changeHandler) {
                    changeHandler(option);
                  }
                }}
              >
                {`${option.bik} - ${option.name}`}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BankSearchField;
