import React, { useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { IMileStone } from "../../../app/feature/ProjectProcessView/MileStone/types/projectProcessMileStoneTypes";
import cls from "./NewMilestoneCard.module.scss";
import {
  LeftMilestoneArrowSvg,
  RightMilestoneArrowSvg,
} from "./SVGMilestoneCard";
import { MilestoneType } from "./MilestoneType/MilestoneType";
import { TooltipTheme, TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import Modal from "../Modal/Modal";
import { fetchProjectProcessStageComplete } from "../../../app/feature/ProjectProcessView/MileStone/services/fetchProjectProcessStageComplete";
import { fetchProjectMilestone } from "../../../app/feature/ProjectView/milestone/services/fetchProjectMilestone";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { ModalConfirm } from "./ModalConfirm/ModalConfirm";
import { getProjectProcessMileStoneCompleteIsLoad } from "../../../app/feature/ProjectProcessView/MileStone/selectors/getProjectProcessMileStone";
import { createMap } from "./helpers";
import { MilestoneSkeletonSpiner } from "../../pages/Project/NewView/Milestone/MilestoneSkeleton";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { config } from "../../../app.cofig";

interface NewMilestoneCardProps {
  stages: IMileStone[];
  processName?: string;
}

export const NewMilestoneCard = ({
  stages,
  processName,
}: NewMilestoneCardProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const isLoadComplete = useAppSelector(
    getProjectProcessMileStoneCompleteIsLoad
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const mapStages = createMap(stages, "entries");
  const keysStages: string[] = createMap(stages, "keys");
  const [isLoadStage, setIsLoadStage] = useState(false);

  function openModalConfirm(can: boolean) {
    if (can) {
      setModalConfirm(true);
    }
  }
  async function completeMileStoneHandler(result: boolean, stageId: number) {
    if (result && params.id) {
      await dispatch(fetchProjectProcessStageComplete(stageId));
      setModalConfirm(false);
      setIsLoadStage(true);
      await dispatch(
        fetchProjectMilestone({ projectId: +params.id, load: true })
      );
      setIsLoadStage(false);
    }
    if (!result) {
      setModalConfirm(false);
    }
  }

  if (isLoadStage) {
    return <MilestoneSkeletonSpiner />;
  }

  return (
    <>
      {mapStages.map(([key, stages]) => {
        const isFinish = key === keysStages[keysStages.length - 1];
        // для нечетных строк
        if (+key % 2 !== 0) {
          return (
            <div className={cls.flex}>
              {key === "1" && (
                <div className={cls.smallCircle}>
                  <p>Start</p>
                </div>
              )}
              {stages.map((stage, index) => (
                <>
                  {stage.can.complete && (
                    <Modal
                      active={modalConfirm}
                      setActive={setModalConfirm}
                      isLoad={isLoadComplete}
                      activeClass={cls.modal}
                    >
                      <ModalConfirm
                        name={stage.name}
                        processName={processName}
                        stageId={stage.id}
                        completeMileStoneHandler={completeMileStoneHandler}
                      />
                    </Modal>
                  )}
                  <div
                    className={classNames(
                      cls.mile,
                      stage.can.complete && cls.canComplete
                    )}
                    onClick={() => openModalConfirm(stage.can.complete)}
                  >
                    <div
                      className={classNames(
                        cls.divider,
                        stage.status === "greenCheck" && cls.dividerGreen
                      )}
                    />
                    <MilestoneType status={stage.status} auto={stage.auto}>
                      <div className={cls.info}>
                        <p>{stage.totalPercent}%</p>
                        <TooltipWrapper
                          content={stage.name}
                          theme={TooltipTheme.MUI}
                        >
                          {stage.link ? (
                            <TextLink
                              redirect
                              target="_blank"
                              url={`${config.localDomain}${stage.link}`}
                            >
                              <span
                                className={classNames(cls.info_name, cls.link)}
                              >
                                {stage.name}
                              </span>
                            </TextLink>
                          ) : (
                            <span className={cls.info_name}>{stage.name}</span>
                          )}
                        </TooltipWrapper>
                      </div>
                    </MilestoneType>
                    <div
                      className={classNames(
                        cls.divider,
                        stage.status === "greenCheck" && cls.dividerGreen
                      )}
                    />
                    {index === 4 && !isFinish && (
                      <div className={cls.arrowRight}>
                        <RightMilestoneArrowSvg
                          strokeColor={
                            stage.status === "greenCheck"
                              ? "#0AA699"
                              : "#838A93"
                          }
                        />
                      </div>
                    )}
                    {isFinish && index === stages.length - 1 && (
                      <div
                        className={classNames(
                          cls.smallCircleEnd,
                          stage.status === "greenCheck" &&
                            cls.smallCircleEndGreen
                        )}
                      />
                    )}
                  </div>
                </>
              ))}
            </div>
          );
        }

        // для четных строк
        return (
          <div className={cls.flexReverse}>
            <div
              className={classNames(
                cls.firstDivider,
                stages[0]?.status === "greenCheck" && cls.dividerGreen
              )}
            />
            {stages.map((stage, index) => (
              <>
                {stage.can.complete && (
                  <Modal
                    active={modalConfirm}
                    setActive={setModalConfirm}
                    isLoad={isLoadComplete}
                    activeClass={cls.modal}
                  >
                    <ModalConfirm
                      name={stage.name}
                      processName={processName}
                      stageId={stage.id}
                      completeMileStoneHandler={completeMileStoneHandler}
                    />
                  </Modal>
                )}
                <div
                  className={classNames(
                    cls.mile,
                    stage.can.complete && cls.canComplete
                  )}
                  onClick={() => openModalConfirm(stage.can.complete)}
                >
                  {isFinish && index === stages.length - 1 && (
                    <div
                      className={classNames(
                        cls.smallCircleEnd,
                        stage.status === "greenCheck" && cls.smallCircleEndGreen
                      )}
                    />
                  )}
                  <div
                    className={classNames(
                      cls.divider,
                      stage.status === "greenCheck" && cls.dividerGreen
                    )}
                  />
                  <MilestoneType status={stage.status} auto={false}>
                    <div className={cls.info}>
                      <p>{stage.totalPercent}%</p>
                      <TooltipWrapper
                        content={stage.name}
                        theme={TooltipTheme.MUI}
                      >
                        {stage.link ? (
                          <TextLink
                            redirect
                            target="_blank"
                            url={`${config.localDomain}${stage.link}`}
                          >
                            <span
                              className={classNames(cls.info_name, cls.link)}
                            >
                              {stage.name}
                            </span>
                          </TextLink>
                        ) : (
                          <span className={cls.info_name}>{stage.name}</span>
                        )}
                      </TooltipWrapper>
                    </div>
                  </MilestoneType>
                  <div
                    className={classNames(
                      cls.divider,
                      stage.status === "greenCheck" && cls.dividerGreen
                    )}
                  />
                </div>
              </>
            ))}
            {stages.length === 4 && !isFinish && (
              <>
                <div
                  className={classNames(
                    cls.firstDivider,
                    stages[stages.length - 1].status === "greenCheck" &&
                      cls.dividerGreen
                  )}
                />
                <div className={cls.arrowLeft}>
                  <LeftMilestoneArrowSvg
                    strokeColor={
                      stages[stages.length - 1].status === "greenCheck"
                        ? "#0AA699"
                        : "#838A93"
                    }
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
