import React from "react";
import { useAppSelector } from "src/app/store";
import { getExecutorProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import ExecutorHave from "./ExecutorHave";
import ExecutorSearch from "./ExecutorSearch";
import ExecutorEmpty from "./ExecutorEmpty";

export const Executor = () => {
  const executor = useAppSelector(getExecutorProcessInfo);
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;

  if (executor) {
    return <ExecutorHave />;
  }
  if (infoData.status.key === 2) {
    return <ExecutorSearch />;
  }
  return <ExecutorEmpty />;
};
