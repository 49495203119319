import React from "react";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import style from "../../../../../pages/News/style.module.scss";

export const NewsPageSkeleton: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <Skeleton className={style.titleSklt} variant="rectangular" />
      <div className={style.generalPostsSklt}>
        <Skeleton className={style.mainArticleSklt} variant="rectangular" />
        <div className={style.articlesListSklt}>
          <Skeleton className={style.articleSklt} variant="rectangular" />
          <Skeleton className={style.articleSklt} variant="rectangular" />
          <Skeleton className={style.articleSklt} variant="rectangular" />
        </div>
      </div>
      <Skeleton className={style.swiperSklt} variant="rectangular" />
      <Skeleton className={style.titleSklt} variant="rectangular" />
      <div className={style.platformNewsSklt}>
        <Skeleton className={style.articleSklt} variant="rectangular" />
        <Skeleton className={style.articleSklt} variant="rectangular" />
        <Skeleton className={style.articleSklt} variant="rectangular" />
        <Skeleton className={style.articleSklt} variant="rectangular" />
      </div>
      <Skeleton className={style.titleSklt} variant="rectangular" />
      <Skeleton className={style.firstFaceSklt} variant="rectangular" />
      <Skeleton className={style.titleSklt} variant="rectangular" />
      <div className={classNames(style.platformNewsSklt, style.shortSklt)}>
        <Skeleton className={style.articleSklt} variant="rectangular" />
        <Skeleton className={style.articleSklt} variant="rectangular" />
      </div>
    </div>
  );
};
