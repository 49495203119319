import React from "react";
import { Route, Routes } from "react-router-dom";
import { ForgotPage } from "../components/pages/PasswordRecovery/ForgotPage";
import { ResetPasswordPage } from "../components/pages/PasswordRecovery/ResetPasswordPage";
import { AgreementPage } from "../components/pages/Registration/ConditionsPage/AgreementPage";
import { DealPage } from "../components/pages/Registration/ConditionsPage/DealPage";
import { OfferPage } from "../components/pages/Registration/ConditionsPage/OfferPage";
import { PolicyPage } from "../components/pages/Registration/ConditionsPage/PolicyPage";
import { RatesPage } from "../components/pages/Registration/ConditionsPage/RatesPage";
import { TermsPage } from "../components/pages/Registration/ConditionsPage/TermsPage";
import { RegistrationPage } from "../components/pages/Registration/RegistrationPage/RegistrationPage";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";

export const SiteRoutes = () => (
  <Routes>
    <Route path="/site">
      <Route
        path={`forgot`}
        element={
          <IndexPageTemplate>
            <ForgotPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`reset-pwd`}
        element={
          <IndexPageTemplate>
            <ResetPasswordPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`registration`}
        element={
          <IndexPageTemplate>
            <RegistrationPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`agreement`}
        element={
          <IndexPageTemplate>
            <AgreementPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`rates`}
        element={
          <IndexPageTemplate>
            <RatesPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`policy`}
        element={
          <IndexPageTemplate>
            <PolicyPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`terms`}
        element={
          <IndexPageTemplate>
            <TermsPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`deal`}
        element={
          <IndexPageTemplate>
            <DealPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`offer`}
        element={
          <IndexPageTemplate>
            <OfferPage />
          </IndexPageTemplate>
        }
      />
    </Route>
  </Routes>
);
