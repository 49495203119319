import React from "react";

export const SecondNodeIcon = ({ color = "#D5D6DE" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_5982_2116)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78256 12.5L0.396563 14.8385C0.276754 14.9025 0.17658 14.9979 0.106736 15.1144C0.0368916 15.2309 0 15.3642 0 15.5C0 15.6358 0.0368916 15.7691 0.106736 15.8856C0.17658 16.0021 0.276754 16.0975 0.396563 16.1615L11.6466 22.1615C11.7551 22.2193 11.8761 22.2495 11.9991 22.2495C12.122 22.2495 12.2431 22.2193 12.3516 22.1615L23.6016 16.1615C23.7214 16.0975 23.8215 16.0021 23.8914 15.8856C23.9612 15.7691 23.9981 15.6358 23.9981 15.5C23.9981 15.3642 23.9612 15.2309 23.8914 15.1144C23.8215 14.9979 23.7214 14.9025 23.6016 14.8385L19.2201 12.5L17.6256 13.3505L21.6576 15.5L12.0006 20.6495L2.34356 15.5L6.37556 13.3505L4.78106 12.5H4.78256Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6466 2.838C11.7551 2.78022 11.8761 2.75 11.9991 2.75C12.122 2.75 12.2431 2.78022 12.3516 2.838L23.6016 8.838C23.7214 8.90201 23.8215 8.99736 23.8914 9.11387C23.9612 9.23038 23.9981 9.36366 23.9981 9.4995C23.9981 9.63534 23.9612 9.76862 23.8914 9.88513C23.8215 10.0016 23.7214 10.097 23.6016 10.161L12.3516 16.161C12.2431 16.2188 12.122 16.249 11.9991 16.249C11.8761 16.249 11.7551 16.2188 11.6466 16.161L0.396563 10.161C0.276754 10.097 0.17658 10.0016 0.106736 9.88513C0.0368916 9.76862 0 9.63534 0 9.4995C0 9.36366 0.0368916 9.23038 0.106736 9.11387C0.17658 8.99736 0.276754 8.90201 0.396563 8.838L11.6466 2.838ZM2.34356 9.4995L11.9991 14.649L21.6561 9.4995L11.9991 4.35L2.34206 9.4995H2.34356Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_5982_2116">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ThirdNodeIcon = ({ color = "#D5D6DE" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5982_2124)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78256 12L0.396563 14.3385C0.276754 14.4025 0.17658 14.4979 0.106736 14.6144C0.0368916 14.7309 0 14.8642 0 15C0 15.1358 0.0368916 15.2691 0.106736 15.3856C0.17658 15.5021 0.276754 15.5975 0.396563 15.6615L11.6466 21.6615C11.7551 21.7193 11.8761 21.7495 11.9991 21.7495C12.122 21.7495 12.2431 21.7193 12.3516 21.6615L23.6016 15.6615C23.7214 15.5975 23.8215 15.5021 23.8914 15.3856C23.9612 15.2691 23.9981 15.1358 23.9981 15C23.9981 14.8642 23.9612 14.7309 23.8914 14.6144C23.8215 14.4979 23.7214 14.4025 23.6016 14.3385L19.2201 12L12.3531 15.6615C12.2446 15.7193 12.1235 15.7495 12.0006 15.7495C11.8776 15.7495 11.7566 15.7193 11.6481 15.6615L4.78256 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6466 2.338C11.7551 2.28022 11.8761 2.25 11.9991 2.25C12.122 2.25 12.2431 2.28022 12.3516 2.338L23.6016 8.338C23.7214 8.40201 23.8215 8.49736 23.8914 8.61387C23.9612 8.73038 23.9981 8.86366 23.9981 8.9995C23.9981 9.13534 23.9612 9.26862 23.8914 9.38513C23.8215 9.50164 23.7214 9.59699 23.6016 9.661L12.3516 15.661C12.2431 15.7188 12.122 15.749 11.9991 15.749C11.8761 15.749 11.7551 15.7188 11.6466 15.661L0.396563 9.661C0.276754 9.59699 0.17658 9.50164 0.106736 9.38513C0.0368916 9.26862 0 9.13534 0 8.9995C0 8.86366 0.0368916 8.73038 0.106736 8.61387C0.17658 8.49736 0.276754 8.40201 0.396563 8.338L11.6466 2.338ZM2.34356 8.9995L11.9991 14.149L21.6561 8.9995L11.9991 3.85L2.34206 8.9995H2.34356Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_5982_2124">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
