import React from "react";
import {
  ChecksGridSVG,
  ColumnsGapSVG,
  DocumentationsTabIcon,
  ExpertiseTabIcon,
  FolderIcon,
  InfoSquareSVG,
  MaterialsTabIcon,
  MileStoneTabIcon,
  PenIcon,
  RemovePersonSVG,
  TaskSTabIcon,
  WorkTabIcon,
} from "../../../newUI/SVG";
import { TabsType } from "../../../../app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";

export const generateTabsData = (tabShow: TabsType, isExpertise: boolean) => {
  const tabExpertise =
    isExpertise &&
    [
      {
        title: "Информация",
        url: "info",
        icon: <InfoSquareSVG />,
        incomplete: false,
      },
      tabShow.expertiseDocuments.show && {
        title: "Договоры",
        url: "expertiseDocuments",
        icon: <PenIcon />,
        incomplete: tabShow.expertiseDocuments.dot,
      },
      tabShow.expertiseRemarks.show && {
        title: "Замечания",
        url: "expertiseRemark",
        icon: <PenIcon />,
        incomplete: tabShow.expertiseRemarks.dot,
      },
      tabShow.expertiseConclusion.show && {
        title: "Заключение",
        url: "expertiseConclusion",
        icon: <DocumentationsTabIcon />,
        incomplete: tabShow.expertiseConclusion.dot,
      },
    ].filter(Boolean);

  const tabDefault =
    !isExpertise &&
    [
      {
        title: "Информация",
        url: "info",
        icon: <InfoSquareSVG />,
        incomplete: false,
      },
      {
        title: "Исх. данные",
        url: "sourceFiles",
        icon: <FolderIcon />,
        incomplete: tabShow?.sourceFiles.dot,
      },
      tabShow?.documents.show && {
        title: "Договоры",
        url: "documents",
        icon: <PenIcon />,
        incomplete: tabShow.documents.dot,
      },
      (tabShow?.inputTasks.show || tabShow?.outputTasks.show) && {
        title: "Задания",
        url: "inputTask",
        icon: <TaskSTabIcon />,
        incomplete: tabShow.inputTasks.dot || tabShow.outputTasks.dot,
      },
      (tabShow?.customerAlign.show || tabShow?.processTask.show) && {
        title: "Документация",
        url: "executionTask",
        icon: <DocumentationsTabIcon />,
        incomplete: tabShow?.customerAlign.dot || tabShow?.processTask.dot,
      },
      tabShow.expertiseInTasks.show && {
        title: "Экспертиза",
        url: "expertiseInTasks",
        icon: <ExpertiseTabIcon />,
        incomplete: tabShow.expertiseInTasks.dot,
      },
      (tabShow?.inSubCheckTask.show || tabShow?.outSubCheckTask.show) && {
        title: "Проверки",
        url: "checkTasks",
        icon: <ChecksGridSVG />,
        incomplete: tabShow?.inSubCheckTask.dot || tabShow?.outSubCheckTask.dot,
      },
      tabShow?.materials.show && {
        title: "Оборудование",
        url: "materials",
        icon: <MaterialsTabIcon />,
        incomplete: tabShow.materials.dot,
      },
      tabShow?.works.show && {
        title: "Задачи",
        url: "work",
        icon: <WorkTabIcon />,
        incomplete: tabShow.works.dot,
      },
      tabShow?.applications.show && {
        title: "Заявки",
        url: "applications",
        icon: <ColumnsGapSVG />,
        incomplete: tabShow.applications.dot,
      },
      tabShow?.removed.show && {
        title: "Снятые исполнители",
        url: "remove",
        icon: <RemovePersonSVG />,
        incomplete: tabShow.removed.dot,
      },
      tabShow?.stages.show && {
        title: "Вехи",
        url: "stages",
        icon: <MileStoneTabIcon />,
        incomplete: tabShow.stages.dot,
      },
    ].filter(Boolean);
  return isExpertise ? tabExpertise : tabDefault;
};
