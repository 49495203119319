import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ColumnTitle = ({ children }: Props) => {
  return <h2>{children}</h2>;
};

export default ColumnTitle;
