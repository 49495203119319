import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Todo заменить на новые компоненты
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { palette, textFieldSX } from "src/styles/restyle";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchGipRequest,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import { ApiGipType } from "src/types/api/primaryTypes/apiPrimaryUserType";
import styles from "./style.module.scss";

type Inputs = {
  price: string;
  type: string;
  content: string;
};

type RequestGipProps = {
  gipTypes: ApiGipType["userTypes"];
  price: string;
};

const schema = Yup.object().shape({
  content: Yup.string().max(
    200,
    "Комментарий должен быть не больше 200 символов"
  ),
  price: Yup.number()
    .required("Это поле обязательно для заполнения")
    .min(1000, `Стоимость работ ГИП не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость процесса не может быть больше 10 млн рублей!`)
    .typeError("Значение «Cтоимость» должно быть числом."),
  type: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
});

const sx = {
  bgcolor: palette.darkGrey,
  color: palette.silverGrey,
  padding: "10px 0",
  "&:not(:last-child)": {
    borderBottom: "1px solid #101920",
  },
  fontSize: "16px",
  fontFamily: "Manrope",
  fontWeight: "400",
};

export const RequestGip = ({ gipTypes, price }: RequestGipProps) => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      content: dataValues.content,
      price: dataValues.price,
      gip_type: +dataValues.type,
    };
    const { id } = params;
    if (id) {
      await dispatch(fetchGipRequest({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(id));
    }
  };

  if (gipTypes) {
    return (
      <div>
        <form
          onSubmit={handleSubmit(formSubmitHandler)}
          className={styles.requestGip}
        >
          <TextField
            {...register("price")}
            label="Стоимость"
            defaultValue={price || ""}
            error={!!errors.price}
            helperText={errors.price ? errors.price.message : null}
          />
          <FormControl {...textFieldSX}>
            <InputLabel id="type">Форма собственности</InputLabel>
            <Select
              {...register("type")}
              labelId="type"
              label="Форма собственности"
              defaultValue={[]}
              error={!!errors.type}
              inputProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "#1A2734",
                      padding: "0 20px",
                    },
                  },
                },
              }}
            >
              {gipTypes.map((type: any) => (
                <MenuItem sx={sx} value={type.id} key={type.id}>
                  {type.listName}
                </MenuItem>
              ))}
            </Select>
            {errors.type ? (
              <p className={styles.error}>{errors.type.message}</p>
            ) : null}
          </FormControl>
          <TextField
            multiline
            minRows={2}
            label="Комментарий к заявке"
            {...register("content")}
            placeholder="Введите комментарий"
            error={!!errors.content}
            helperText={errors.content ? errors.content.message : null}
          />
          <FormControl className={styles.requestGip_btn}>
            <Button className={styles.searchGip_btn} type="submit">
              Заявиться
            </Button>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
