import React, { memo, FC } from "react";
import { useAppSelector } from "src/app/store";
import {
  getProjectProcessDocumentsExpertiseIn,
  getProjectProcessDocumentsProcessTask,
} from "src/app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import {
  getProjectProcessInfoWarningActual,
  getProjectProcessInfoWarningInput,
  getProjectProcessInfoWarningOutput,
  getProjectProcessInfoWarningRequired,
} from "src/app/feature/ProjectProcessView/Info/selectors/getWarningProcessInfo";
import cls from "./ProcessTask.module.scss";
import { ProcessTaskCard } from "./ProcessTaskCard/ProcessTaskCard";
import { WarningCard } from "../../../../newUI/WarningCard/WarningCard";
import { ExpertiseInCard } from "./ExpertiseInCard/ExpertiseInCard";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TModalData } from "../../../Project/View/CheckTasks/CheckTaskCard";

type ProcessTaskProps = {
  setModalData: SetState<TModalData>;
};

export const ProcessTask: FC<ProcessTaskProps> = memo(({ setModalData }) => {
  const processTask = useAppSelector(getProjectProcessDocumentsProcessTask);
  const expertiseIn = useAppSelector(getProjectProcessDocumentsExpertiseIn);
  const warningsInput = useAppSelector(getProjectProcessInfoWarningInput);
  const warningsActual = useAppSelector(getProjectProcessInfoWarningActual);
  const warningsOutput = useAppSelector(getProjectProcessInfoWarningOutput);
  const warningsRequired = useAppSelector(getProjectProcessInfoWarningRequired);

  return (
    <>
      <WarningCard
        warningsInput={warningsInput}
        warningsActual={warningsActual}
        warningsOutput={warningsOutput}
        warningRequired={warningsRequired}
        classname={cls.warning}
      />
      <div className={cls.cardWrapper}>
        {processTask && (
          <ProcessTaskCard {...processTask} setModalData={setModalData} />
        )}
        {expertiseIn &&
          expertiseIn.map((expertise) => (
            <ExpertiseInCard {...expertise} setModalData={setModalData} />
          ))}
      </div>
    </>
  );
});
