import {
  chatMessageType,
  chatRoomType,
} from "../../../../types/stateTypes/chatType";

export const getNotSeenMessages = (
  messages: chatMessageType[]
): chatMessageType[] => messages.filter((item) => !item.my && !item.msgStatus);

export const getIdMessageCollection = (messages: chatMessageType[]): string[] =>
  messages.map((item) => String(item.id));

export const isChatRoomsDataHaveProject = (
  data: chatRoomType[],
  id: number
) => {
  let result = false;
  const fn: (data: chatRoomType[]) => void = (data) => {
    data.forEach((item) => {
      if (item.isGroup) {
        return {
          ...item,
          rooms: fn(item.rooms!),
        };
      }
      if (Number(item.roomId) === id) {
        result = true;
      }
    });
  };
  fn(data);
  return result;
};

export const getAllParentsElements = (id: string) => {
  let to = document.getElementById(id);
  const els = [];
  while (to) {
    els.unshift(to);
    // @ts-ignore
    to = to.parentNode;
  }
  return els;
};
