import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { Select } from "../../UI/components/FormComponentns/Select";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiTeamInvitation,
  ApiGetAvailableTeams,
} from "../../../app/services/api/organisation/organisation";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { selectorManageTeammateData } from "../../../app/feature/eventEmitter/organisation/eventManageTeammate";
import { margin } from "./SendMoneyForm";
import { palette } from "../../../styles/restyle";
import { TSelectItem } from "../../features/Forms/SetGipForm/types";

type TFormValues = {
  specialization: TSelectItem | null;
};

export const ManageTeammateForm: React.FC = () => {
  const { id, candidateId } = useSelector(selectorManageTeammateData);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [specializationList, setSpecializationList] = useState<TSelectItem[]>(
    []
  );

  const dispatch = useDispatch();
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  const { setValue, handleSubmit, watch } = useForm<TFormValues>({
    defaultValues: {
      specialization:
        specializationList.length > 0 ? specializationList[0] : null,
    },
  });

  const watchSpecialization = watch("specialization");

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const onSubmit = async (data: any) => {
    setDisabled(true);
    await request(
      ApiTeamInvitation(candidateId, +data.specialization?.id, Number(id)),
      () => {
        enqueueSnackbar("Успешно", {
          variant: "success",
          autoHideDuration: 5000,
        });
        Popup.manageTeammate.set({});
      },
      () => (err) => {
        const message =
          typeof err.message === "string" ? err.message : "Возникла ошибка";
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setDisabled(false);
    dispatch(setModalClosed(!modalClosed));
  };

  useEffect(() => {
    request(
      ApiGetAvailableTeams(candidateId, id),
      (data) => {
        const formedData = Object.keys(data).map((key: any) => ({
          id: key,
          title: data[key],
        }));

        setSpecializationList(formedData);
      },
      () => () => {
        enqueueSnackbar("Возникла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, style.noPaddings)}
    >
      <Select
        value={watchSpecialization}
        name={"specialization"}
        changeHandler={(_, value) => {
          setValue("specialization", value);
        }}
        label={"Специализация"}
        options={specializationList}
        isLoading={false}
        disableClear
      />
      <br />
      <CustomButton
        width={160}
        disabled={disabled}
        type="submit"
        background={palette.blue}
        style={{ margin }}
      >
        Добавить
      </CustomButton>
    </form>
  );
};
