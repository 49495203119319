import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseProjectList } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";
import { ExpertiseProjectListType } from "../types/expertiseProjectListType";

export const fetchExpertiseProjectList = createAsyncThunk<
  ExpertiseProjectListType[],
  number
>("expertise/fetchExpertiseProjectList", async (processId, { dispatch }) => {
  let response: any = {};
  await request(
    ApiExpertiseProjectList(processId),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
