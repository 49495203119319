import {
  ProjectProcessControlTypes,
  ProjectSubProcess,
} from "../../../../types/stateTypes/projectProcessControlTypes";
import { ApiPrimaryProjectPartTree } from "../../../../types/api/primaryTypes/apiPrimaryProjectPartTree";

export const setToggleOpen = (
  data: ProjectProcessControlTypes["data"],
  id: string
): any => {
  const fn = (
    data: ProjectProcessControlTypes["data"]
  ): ProjectProcessControlTypes["data"] => {
    return data.map((item: any) => {
      if (item.hasOwnProperty("processId")) {
        if (item.processId === id) {
          return {
            processId: item.processId,
            processName: item.processName,
            isOpen: !item.isOpen,
            processes: fn(item.processes),
          };
        }
        return {
          processId: item.processId,
          processName: item.processName,
          isOpen: item.isOpen,
          processes: fn(item.processes),
        };
      }

      return {
        ...item,
      };
    });
  };

  return fn(data);
};

// =====

// с функционалом ниже нужно внимательно ознакомиться
// потому что очень много рекурсиных действий
// основная работа будет вестись с кусочками
// дерева разделов и массивом выбранных id
// массив является источником правды для переключателей разделов

const getChildrenNumsByGraph = (
  graph: ProjectProcessControlTypes["data"] | null
): string[] => {
  if (!graph) return [];
  // @ts-ignore
  if (!graph?.processes) {
    return [];
  }

  // для совместимости с findElemGraphByNum
  // @ts-ignore
  return graph.processes.reduce((acc: string[], item) => {
    const id = item.processId || item.subProcessId;

    return [...acc, id, ...getChildrenNumsByGraph(item)];
  }, []);
};

// получение объекта на котором переключение switch
export const findElemGraphByNum = (
  graph: ProjectProcessControlTypes["data"],
  num: string
): any | null => {
  // @ts-ignore
  if (graph?.processId === num || graph?.subProcessId === num) return graph; // нужный вариант
  // @ts-ignore
  if (!graph?.processes) {
    return null;
  }
  // @ts-ignore
  const result = graph.processes.reduce(
    // @ts-ignore
    (acc: ProjectProcessControlTypes["data"] | null, item) => {
      const result = findElemGraphByNum(item, num);
      if (result) return result;
      return acc;
    },
    null
  );

  return result;
};

// возвращаем идентификатор родителя
export const getParentID = (id: string): string | null => {
  const splittedID = id.split(".");

  if (splittedID.length === 1) {
    // родителя нет
    return null;
  }

  return splittedID.slice(0, splittedID.length - 1).join(".");
};

// возвращаем массив идентификаторов всех родителей
export const getAllParents = (id: string): string[] => {
  const splittedID = id.split(".");
  const countsParents = splittedID.slice(0, splittedID.length - 1).length;

  const result = new Array(countsParents)
    .fill(null)
    .reduce((acc: string[], item, i) => {
      return [...acc, getParentID(acc[i - 1] ?? id)];
    }, []);

  return result.reverse();
};

// =====

// удаляем id из массива разделов
export const setProcessUnchecked = (
  objState: ProjectProcessControlTypes,
  arrayChecked: string[],
  id: string
): ProjectProcessControlTypes => {
  // при отключении одного элемента, возвращаем,
  // все оставшиеся id вложенных элементов и id родителя
  const filterChildrenArray = arrayChecked.filter(
    (item) => !item.startsWith(id)
  );

  // ищем родителя, по id вложенного раздела
  const parent = getParentID(id);

  // если родителя нету
  if (!parent) {
    return {
      ...objState,
      checked: filterChildrenArray,
    };
  }

  // проверям на наличие соседних элементов
  const siblingsOfElements = filterChildrenArray.filter((item) =>
    item.startsWith(`${parent}.`)
  );

  // если соседи существуют, то ничего не делаем
  // и не переключаем родителя
  if (siblingsOfElements.length) {
    return {
      ...objState,
      checked: filterChildrenArray,
    };
  }

  // если соседних элементов нету,
  // то рекурсируем на отключение, плюс родительский элемент
  return setProcessUnchecked(objState, filterChildrenArray, parent);
};

// ======
// добавляем id в массив разделов
export const setProcessChecked = (
  objState: ProjectProcessControlTypes,
  arrayChecked: string[],
  id: string
): any => {
  const { data } = objState;

  const parentsIds = getAllParents(id);

  const CURRENT_ID = parentsIds[0] || id;

  const getBitTree = data.find((item: any) => item.processId === CURRENT_ID);
  // @ts-ignore
  if (!findElemGraphByNum(getBitTree, id)) {
    return objState;
  }

  const checkedChildren = [
    // @ts-ignore
    ...getChildrenNumsByGraph(findElemGraphByNum(getBitTree, id)),
  ];

  const result = [...arrayChecked, ...parentsIds, id, ...checkedChildren];

  return {
    ...objState,
    checked: Array.from(new Set(result)),
  };
};

// создание стейта из session storage, нужно на тот случай
// если пользователь вернется обратно, если вдруг забыл что то выбрать
// то открются посещенные ранее разделы
export const setOpenStateFromStorage = (
  data: ProjectProcessControlTypes["data"],
  arrayIds: string[]
): any => {
  const fn = (
    data: ProjectProcessControlTypes["data"]
  ): ProjectProcessControlTypes["data"] => {
    return data.map((item: any) => {
      if (item.hasOwnProperty("processId")) {
        if (arrayIds && arrayIds.includes(item.processId)) {
          return {
            processId: item.processId,
            processName: item.processName,
            isOpen: !item.isOpen,
            processes: fn(item.processes),
          };
        }
        return {
          processId: item.processId,
          processName: item.processName,
          isOpen: item.isOpen,
          processes: fn(item.processes),
        };
      }

      return {
        ...item,
      };
    });
  };

  return fn(data);
};

// создание первоначального состояния при загрузке страницы
export const createData = (
  data: ApiPrimaryProjectPartTree["data"]
): ProjectProcessControlTypes["data"] => {
  const fn = (
    data: ApiPrimaryProjectPartTree["data"]
  ): ProjectProcessControlTypes["data"] => {
    const arr = Object.values(data);

    return arr.map((item: any) => {
      if (item.hasOwnProperty("data") && Object.values(item.data).length > 0) {
        return {
          processId: item.num,
          processName: item.name,
          isOpen: false,
          processes: fn(item.data),
        };
      }

      return {
        subProcessName: item.name,
        subProcessId: item.num,
        id: item.id,
      } as ProjectSubProcess;
    });
  };

  return fn(data);
};
