import { Nullable } from "../../../../types/generics";

type TModalsState = {
  comments: {
    isOpen: boolean;
    taskId: Nullable<number>;
  };
};

export const modalsInitialState: TModalsState = {
  comments: {
    isOpen: false,
    taskId: null,
  },
};
