import React from "react";
import classNames from "classnames";
import { Text } from "../Text/Text";
import style from "./style.module.scss";
import { frontFooterNavigationMock } from "../../../../mocks/frontFooterNavigationMock";
import { frontFooterDocumentationMock } from "../../../../mocks/frontFooterDocumentationMock";
import { TextLink } from "../TextLink/TextLink";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type FooterNavigationProps = ComponentPropsType<{
  isDocumentation?: boolean;
}>;

export type FrontFooterType = ComponentPropsType<{
  url: string;
  title: string;
  alt?: string;
  outer: boolean;
}>;

export const FooterNavigationItem: React.FC<FrontFooterType> = ({
  url,
  title,
  alt,
  outer,
  theme = "dark",
}) => {
  const handleScrollToTop = () => window.scrollTo(0, 0);

  return (
    <li onClick={handleScrollToTop} className={style.item}>
      <TextLink
        url={url}
        title={alt || title}
        variant={style.link}
        theme={theme}
        redirect={outer}
      >
        {title}
      </TextLink>
    </li>
  );
};

export const FooterNavigation: React.FC<FooterNavigationProps> = React.memo(
  ({ variant = "", isDocumentation = false, theme }) => {
    const textTitle: string = isDocumentation ? "Документация" : "Навигация";
    const frontFooterDataMock: Array<FrontFooterType> = isDocumentation
      ? frontFooterDocumentationMock
      : frontFooterNavigationMock;

    return (
      <section className={classNames(variant, style.sectionNavigation)}>
        <div className={classNames(style.head)}>
          <Text variant={style.headText}>{textTitle}</Text>
        </div>
        <ul>
          {frontFooterDataMock.map((item: FrontFooterType) => (
            <FooterNavigationItem
              theme={theme}
              key={item.url}
              title={item.title}
              alt={item.alt}
              url={item.url}
              outer={item.outer}
            />
          ))}
        </ul>
      </section>
    );
  }
);
