import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = RequestParams<{
  id: number;
  data: {
    price: number;
  };
}>;

export const updatePrice = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/process-work/update-price?id=${id}`,
      method: HTTPMethods.PUT,
      data,
    }),
    invalidatesTags: ["ProcessWork.View"],
  });
