import React, { useState, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce, TextField } from "@mui/material";
import { UseFormSetValue, UseFormRegister, Path } from "react-hook-form";
import cn from "classnames";
import { getAddressOptions } from "../../../../../../app/feature/profileReducer/thunks";
import style from "./style.module.scss";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { textFieldSX } from "../../../../../../styles/restyle";

// дописать поля из api в camelCase
type TOption = {
  value: string;
  city: string;
  region_kladr_id: string;
  country_iso_code: string;
};

type Props<TFormValues> = {
  name: Path<TFormValues>;
  register?: UseFormRegister<any>;
  disabled?: boolean;
  label?: string;
  defaultValue: string | null;
  setValue?: UseFormSetValue<any>;
  handler?: (option: any) => void;
  changeHandler?: (value: string) => void;
  trigger?: any;
  error?: boolean;
  helperText?: string;
  value?: string;
};

const AdvancedAddressField = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  defaultValue,
  setValue,
  label,
  disabled,
  error,
  helperText,
  handler,
  value,
  changeHandler,
}: Props<TFormValues>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const outsideRef = useRef(null);
  useClickOutside(outsideRef, () => {
    setShow(false);
  });

  const searchHandler = debounce(async (val) => {
    setShow(false);
    setIsLoading(true);
    const response = await getAddressOptions(val);
    setOptions(response);
    setIsLoading(false);
    setShow(true);
  }, 500);

  const reg = register ? register(name) : {};
  const optionalValue = value ? { value } : {};

  return (
    <div className={style.fieldContainer}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        defaultValue={defaultValue}
        label={label ?? "Адрес"}
        error={error}
        helperText={error && helperText}
        autoComplete="off"
        disabled={disabled}
        {...optionalValue}
        {...reg}
        {...textFieldSX}
        onChange={(e) => {
          const { value } = e.currentTarget;
          searchHandler(value);

          if (changeHandler) {
            changeHandler(value);
          }
        }}
        onFocus={() => {
          setShow(true);
        }}
        InputProps={{
          endAdornment: (
            <div className={style.loaderContainer}>
              {isLoading ? <CircularProgress color="info" size={20} /> : null}
            </div>
          ),
        }}
      />
      {show && !isLoading && (
        <div
          className={cn(style.optionsContainer, {
            [style.noBorder]: options.length === 0,
          })}
          ref={outsideRef}
        >
          {options.map((option: any) => {
            return (
              <button
                className={style.option}
                key={option.value}
                type="button"
                onClick={() => {
                  setShow(false);

                  if (handler) {
                    handler(option);
                  }

                  if (setValue) {
                    setValue("address", option.value);
                    setValue("city", option.city);
                    if (option.region_kladr_id !== "") {
                      setValue(
                        "region",
                        option.country_iso_code === "RU"
                          ? Number(option.region_kladr_id)
                          : 0
                      );
                    }
                    setValue("country", option.country_iso_code);
                  }
                }}
              >
                {option.value}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AdvancedAddressField;
