import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../../types/other/components/componentPropsType";

type CurrencyTypeProps = ComponentPropsType<{
  type?: "bring" | "payment";
}>;

export const Operations: React.FC<CurrencyTypeProps> = ({
  type = "bring",
  variant,
}) => {
  return (
    <div className={classNames(style.currencyContainer, variant)}>
      <div
        className={classNames(
          style.label,
          type === "bring" ? style.bring : style.payment
        )}
        style={{
          position: "absolute",
          top: `${type === "bring" ? -25 : 100}%`,
        }}
      >
        {type === "bring" ? "Внесение" : "Оплата"}
      </div>
      <div
        className={classNames(
          style.currencyWrapper,
          type === "bring" ? style.bring : style.payment
        )}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.56189 13.1099V14.5899H13.2419V16.7799H7.53186V19.1599H3.24188V16.7799H0.671875V14.5899H3.24188V0.669922H11.2419C16.1819 0.669922 19.2419 2.7499 19.2419 6.8999C19.2419 11.0499 16.1519 13.1099 11.2419 13.1099H7.56189ZM11.2319 4.10992H7.56189V9.70993H11.2419C13.6219 9.70993 14.9719 8.70992 14.9719 6.88992C14.9719 5.06992 13.6119 4.1499 11.2419 4.1499L11.2319 4.10992Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
