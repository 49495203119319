import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Description as UIDescription } from "src/FSD/shared/uiKit/v2/Description/Description";
import styles from "./Description.module.scss";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Description = ({ processWork }: Props) => {
  return (
    <Card
      className={styles.description}
      header={<Card.Header>{processWork.title}</Card.Header>}
    >
      <UIDescription text={processWork.project!.description} linesCount={3} />
    </Card>
  );
};
