import { httpRequestGet } from "../../../../../app/services/api/api";
import { config } from "../../../lib/configs/app.config";
import {
  httpRequestDelete,
  httpRequestPost,
  httpRequestPostMultiData,
} from "../../../lib/services/api/httpRequests";

export const ApiTaskComplete = (
  id: number,
  data: Record<string, any>
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    {
      CompleteForm: { ...data },
    }
  );
};

export const ApiTaskCompleteMultiData = (
  id: number,
  formData: FormData
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/task/complete?id=${id}`,
    {},
    formData
  );
};

export const ApiTaskGetFiles = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&fields=files&expand=files.svgIcon,can`
  );
};

export const ApiGetTaskView = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&fields=files&expand=files.svgIcon,can`
  );
};

export const ApiDeleteTask = (taskId: number): Promise<Response> => {
  return httpRequestDelete(`${config.localDomain}/v1/task/delete?id=${taskId}`);
};
