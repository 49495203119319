import React from "react";
import styles from "./Executor.module.scss";

export const ExecutorEmpty = () => {
  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <h2>Исполнитель</h2>
      </div>
      <div className={styles.executor_info}>
        <div className={styles.executor_text}>
          <span>Не назначен</span>
        </div>
        <hr className={styles.yellowLine} />
      </div>
    </div>
  );
};

export default ExecutorEmpty;
