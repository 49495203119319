import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { executeCompleteSchema } from "src/FSD/widgets/v2/processWork/modals/ExecuteComplete/lib/schemas";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
} from "../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { config } from "../../../../../../../app.cofig";
import { ApiGetTaskFiles } from "../../../../../../../app/services/api/organisation/organisation";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import cls from "./ModalComplete.module.scss";
import { fetchProjectProcessTaskComplete } from "../../../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessTaskComplete";
import {
  parseUnprocessableFields,
  request,
} from "../../../../../../../app/services/api/requestHandler";
import { getProjectProcessDocumentsCheckError422 } from "../../../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import NewUploader from "../../../../../../features/NewUploader/NewUploader";
import { Statuses } from "../../../../../../../FSD/shared/lib/constants/statuses";

const getTaskFiles = async (taskId: number) => {
  let response: any;

  await request(ApiGetTaskFiles(taskId), (data) => {
    response = data;
  })();

  return response.files;
};

type TInputs = {
  status: number;
  comment: string;
  files: any[];
};

interface ModalCompleteProps {
  taskId: number | undefined;
  processIdDerrived?: number;
  header?: string;
}

export const ModalComplete = memo(
  ({ taskId, processIdDerrived, header }: ModalCompleteProps) => {
    const dispatch = useAppDispatch();
    const processData = useAppSelector(getProjectProcessInfoData);
    const processId = useAppSelector(getProjectProcessInfoId);
    const error422 = useAppSelector(getProjectProcessDocumentsCheckError422);

    const actualProcessId = processId ?? processIdDerrived;

    const actualHeader =
      header ??
      `Выдать документацию по разделу «${processData?.projectPart.name}» по проекту «
    ${processData?.project?.name}»`;

    const {
      handleSubmit,
      setValue,
      register,
      setError,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(executeCompleteSchema),
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        CompleteForm: {
          comment: dataValues.comment,
          status: dataValues.status,
        },
      };

      if (taskId && actualProcessId) {
        await dispatch(
          fetchProjectProcessTaskComplete({
            taskId,
            data,
            processId: actualProcessId,
          })
        );
      }
    };

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    if (taskId) {
      return (
        <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
          <h1>{actualHeader}</h1>
          <div>
            <NewUploader
              autoUpload={Boolean(taskId)}
              canEdit
              getDataCallback={() => getTaskFiles(taskId)}
              postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
              setFiles={(data) => {
                setValue("files", data);
              }}
              uploadAsActive
            />
            {errors.files && (
              <p className={cls.error}>{errors.files.message}</p>
            )}
          </div>
          <TextField
            {...textFieldSX}
            multiline
            minRows={2}
            label="Комментарий"
            {...register("comment")}
            placeholder="Введите текст"
            error={!!errors.comment}
            helperText={errors.comment ? errors.comment.message : null}
          />
          <div className={cls.form_submit}>
            <CustomButton
              background={palette.red}
              width={220}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.IS_REJECT);
              }}
            >
              Отказаться от исполнения
            </CustomButton>
            <CustomButton
              background={palette.blue}
              width={200}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.IS_DONE);
              }}
            >
              Отправить на проверку
            </CustomButton>
          </div>
        </form>
      );
    }
    return <></>;
  }
);
