import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import ModalWindow from "../../newUI/ModalWindow";
import {
  setInvisible,
  getModalVisibility,
} from "../../../app/feature/modalController/modalVisibility";
import { SetState } from "../../features/ProjectEditing/SectionEditing/SectionEditing";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { useIsMobile } from "../../hooks/useIsMobile";
import { palette } from "../../../styles/restyle";

const View: React.FC<{
  toTab: string;
  redirectURL?: string;
  setCurrentTab: SetState<string>;
}> = memo(({ redirectURL, setCurrentTab, toTab }) => {
  const dispatch = useDispatch();
  const { visible, redirect } = useSelector(getModalVisibility);

  const handleCloseModal = () => dispatch(setInvisible());

  const isMobile = useIsMobile(680);

  const handleSwitchTab = () => {
    setCurrentTab(toTab);
    dispatch(setInvisible());
  };

  const ModalBody = () => (
    <div className={style.modalForm}>
      <p>Все несохраненные изменения будут потеряны</p>
      <div>
        <CustomButton
          width={120}
          background={palette.red}
          onClick={handleCloseModal}
        >
          Остаться
        </CustomButton>
        {redirect ? (
          <TextLink url={redirectURL!} redirect>
            <CustomButton width={120} background={palette.blue}>
              Перейти
            </CustomButton>
          </TextLink>
        ) : (
          <CustomButton
            width={120}
            background={palette.blue}
            onClick={handleSwitchTab}
          >
            Перейти
          </CustomButton>
        )}
      </div>
    </div>
  );

  if (!visible) {
    return null;
  }

  return (
    <ModalWindow>
      <PopupUI
        height={110 + Number(isMobile) * 20}
        head={<p className={style.modalTitle}>Внимание</p>}
        body={<ModalBody />}
        closeHandler={handleCloseModal}
      />
    </ModalWindow>
  );
});

export default View;
