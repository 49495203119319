type TUser = {
  surname: string;
  name: string;
  patronymic: string;
};

export const formFullUserName = (user: TUser) => {
  const { surname, name, patronymic } = user;
  return [surname, name, patronymic].filter(Boolean).join(" ");
};
