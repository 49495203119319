import React from "react";
import { Skeleton } from "@mui/material";
import style from "./style.module.scss";

export const UserSkeleton: React.FC<{ variant?: string }> = () => {
  return (
    <div className={style.wrapper}>
      <Skeleton variant={"rectangular"} className={style.rect} />
      <Skeleton variant={"rectangular"} className={style.rect} />
      <Skeleton variant={"rectangular"} className={style.rect} />
    </div>
  );
};
