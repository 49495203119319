import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { projectsPageInitialState } from "./projectsPageInitialState";
import C from "./constants";
import { ApiProjectsDataType } from "../../../../types/api/responseTypes/apiProjectsType";
import { createProjectsListFromApi } from "./createData/createData";
import { StateType } from "../../../initialState";

export const projectsPageReducer = createReducer(
  projectsPageInitialState,
  (builder) => {
    /*
      Установка значений на страницу материалов
    */

    builder.addCase(
      C.SET_PROJECTS_PAGE_DATA,
      (state, action: PayloadAction<ApiProjectsDataType>) => {
        return {
          ...state,
          projects: createProjectsListFromApi(action.payload.data),
          meta: action.payload.meta,
        };
      }
    );
  }
);

export const selectorProjectsItems = (state: StateType) =>
  state.projectsPage.projects;

export const selectorProjectsMeta = (state: StateType) =>
  state.projectsPage.meta;

export const setProjects = (
  data: ApiProjectsDataType["data"],
  meta: ApiProjectsDataType["meta"]
) => ({
  type: C.SET_PROJECTS_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
