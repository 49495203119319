import { useEffect } from "react";
import {
  TWorkFlowClasses,
  WorkFlowClasses,
} from "src/FSD/shared/lib/constants/workFlowClasses";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useAppDispatch } from "src/app/store";
import { completeTask } from "src/app/feature/dashboard/header/thunks";

const TasksWithoutModals: Partial<Record<TWorkFlowClasses, number>> = {
  [WorkFlowClasses.STOP_PROCESS]: Statuses.DONE,
  [WorkFlowClasses.FREE_TASK]: Statuses.IS_DONE,
};

type CompleteTaskWithoutModalProps = {
  id: number;
  workFlowClass: TWorkFlowClasses;
};

export const CompleteTaskWithoutModal = ({
  id,
  workFlowClass,
}: CompleteTaskWithoutModalProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const status = TasksWithoutModals[workFlowClass];

    if (status) {
      dispatch(
        TaskApi.complete({
          data: {
            id,
            formedData: {
              status,
            },
          },
          events: {},
        })
      );
    }
  }, [id, workFlowClass]);

  return null;
};
