import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProcessMaterialTemplateList } from "../../../../services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";

export const fetchProcessMaterialTemplateList = createAsyncThunk<
  {
    id: number;
    name: string;
  }[],
  number
>(
  "materialProcess/fetchProcessMaterialTemplateList",
  async (partId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProcessMaterialTemplateList(partId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
