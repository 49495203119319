import { Controller, UseFormReturn } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Column } from "src/FSD/shared/uiKit/v2/Column";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  ReasonsDescriptions,
  ReasonsOfRemovalBy715,
  RemovalBy715Details,
  RemovalBy715Ids,
  reasonsOfExecutorRemoval,
} from "../model/constants";
import styles from "./Forms.module.scss";
import { TFieldValues } from "../model/types";
import { useReasonForm } from "../lib/hooks/useReasonForm";

export type Props = {
  form: UseFormReturn<TFieldValues, object>;
  onSubmit: (data: TFieldValues) => void;
  dataRemoveExecutor: any;
  isRemovingExecutor: boolean;
};

export const ReasonForm = ({
  form,
  onSubmit,
  dataRemoveExecutor,
  isRemovingExecutor,
}: Props) => {
  const {
    control,
    reasonOfRemovalValue,
    isRemovalBy715,
    register,
    errors,
    handleGoForward,
    isContinueDisabled,
    containsObjects,
  } = useReasonForm({
    form,
    onSubmit,
    dataRemoveExecutor,
  });

  return (
    <Modal.Form>
      <Controller
        name="reason"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            options={reasonsOfExecutorRemoval}
          />
        )}
      />
      <Paragraph color="light">
        {ReasonsDescriptions[reasonOfRemovalValue.id]}
      </Paragraph>
      {isRemovalBy715 && (
        <Column className={styles.reasons}>
          <Controller
            name="reason2"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value === RemovalBy715Ids.HAS_NOT_STARTED_YET}
                editing
                onChange={() => {
                  onChange(RemovalBy715Ids.HAS_NOT_STARTED_YET);
                }}
                label={ReasonsOfRemovalBy715.HAS_NOT_STARTED_YET}
              />
            )}
          />
          <Controller
            name="reason2"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value === RemovalBy715Ids.WORKS_SLOWLY}
                editing
                onChange={() => {
                  onChange(RemovalBy715Ids.WORKS_SLOWLY);
                }}
                label={ReasonsOfRemovalBy715.WORKS_SLOWLY}
              />
            )}
          />
          <Controller
            name="reason2"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value === RemovalBy715Ids.WORKS_BADLY}
                editing
                onChange={() => {
                  onChange(RemovalBy715Ids.WORKS_BADLY);
                }}
                label={ReasonsOfRemovalBy715.WORKS_BADLY}
              />
            )}
          />
        </Column>
      )}
      <TextField
        {...register("content")}
        label="Комментарий"
        error={Boolean(errors.content)}
        helperText={errors.content?.message}
        multiline
        minRows={3}
      />
      {isRemovalBy715 && (
        <>
          {RemovalBy715Details.map((detail, index) => (
            <Paragraph key={index} color="light">
              {detail}
            </Paragraph>
          ))}
          <Controller
            name="reason3"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value}
                editing
                onChange={() => {
                  onChange(!value);
                }}
                label="Подтверждаю получение предварительных решений от исполнителя"
              />
            )}
          />
        </>
      )}
      <Modal.Controls>
        <Button
          onClick={handleGoForward}
          isLoading={isRemovingExecutor}
          color="primary"
          disabled={isContinueDisabled}
        >
          {containsObjects ? <>Далее</> : <>Снять</>}
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
