export const RightMilestoneArrowSvg = ({ strokeColor = "#0AA699" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="122"
    viewBox="0 0 65 122"
    fill="none"
  >
    <path d="M50 55L57 67L64 55" stroke={strokeColor} strokeLinecap="round" />
    <path
      d="M0 1V1C31.4802 1 57 26.5198 57 58V64C57 95.4802 31.4802 121 0 121V121"
      stroke={strokeColor}
    />
  </svg>
);

export const LeftMilestoneArrowSvg = ({ strokeColor = "#0AA699" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="122"
    viewBox="0 0 65 122"
    fill="none"
  >
    <path d="M15 55L8 67L1 55" stroke={strokeColor} strokeLinecap="round" />
    <path
      d="M65 1V1C33.5198 1 8 26.5198 8 58V64C8 95.4802 33.5198 121 65 121V121"
      stroke={strokeColor}
    />
  </svg>
);
