import * as Yup from "yup";
import { stringMinMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";
import { Statuses } from "../../../../../../../shared/lib/constants/statuses";

export const checkFreeTaskSchema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.REJECT,
    then: stringMinMaxLengthRequired("Комментарий", 15, 500),
  }),
});
