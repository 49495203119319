import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

export const ErrorMessage: React.FC<{
  message: string | undefined;
  variant?: string;
}> = ({ message, variant = "" }) => {
  return <div className={classNames(style.message, variant)}>{message}</div>;
};
