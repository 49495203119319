import { memo } from "react";
import { IconButton } from "@mui/material";
import { Reply } from "react-bootstrap-icons";
import { useTaskBackToWorkMutation } from "src/FSD/entities/task/api";
import { CONFIRM_RETURN } from "../model/constants";
import { palette } from "../../../../../styles/restyle";

type Props = {
  taskId: number;
  onRemoval?: () => void;
};

export const BackToWorkButton = memo(
  ({ taskId, onRemoval = () => {} }: Props) => {
    const [backToWork] = useTaskBackToWorkMutation();

    const handleReturnBackToWork = async () => {
      if (!window.confirm(CONFIRM_RETURN)) {
        return;
      }

      await backToWork({ id: taskId });
      onRemoval();
    };

    return (
      <IconButton onClick={handleReturnBackToWork}>
        <Reply color={palette.lightGrey} />
      </IconButton>
    );
  }
);
