import { config } from "../../../../app.cofig";
import {
  httpRequestGet,
  httpRequestPost,
  httpRequestPut,
  httpRequestPatch,
} from "../api";

export const ApiPostCreateProject = (data: any): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/project/create`, {}, data);

export const ApiGetProjectPartTree = (id: string | number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/get-project-part-tree?id=${id}`
  );

export const ApiPutEditProject = (
  id: number | string,
  data: any
): Promise<Response> =>
  httpRequestPut(`${config.localDomain}/v1/project/update?id=${id}`, {}, data);

export const ApiSetDependenciesProject = (
  id: number | string,
  data: any
): Promise<Response> =>
  httpRequestPatch(
    `${config.localDomain}/v1/project/set-dependencies?id=${id}`,
    {},
    data
  );
