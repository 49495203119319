import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPut,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectComplexObject = (
  id: number,
  searchText = ""
): Promise<Response> => {
  // получить объекты
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-child-projects?id=${id}&ProjectSearch[name]=${searchText}`
  );
};

export const ApiDeleteProject = (id: number): Promise<Response> => {
  // Удаление проекта
  return httpRequestDelete(`${config.localDomain}/v1/project/delete?id=${id}`);
};

export const ApiGetUsersProjectGip = (id: number): Promise<Response> => {
  // получить Гипов
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-users-to-assign?id=${id}`
  );
};

export const ApiCreateProjectAccess = (
  projectId: number,
  data: Record<string, any>
): Promise<Response> => {
  // приглашение участника на проект
  return httpRequestPost(
    `${config.localDomain}/v2/project-access/create?projectId=${projectId}`,
    {},
    data
  );
};

export const ApiGetFirmsInProject = (projectId: number): Promise<Response> => {
  // организации автора и ГИПа проекта
  return httpRequestGet(
    `${config.localDomain}/v1/firm/get-firms-in-project?projectId=${projectId}`
  );
};

export const ApiGetUsersTypeProjectGip = (
  id: number,
  gipId: number
): Promise<Response> => {
  // получить типы Гипа
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-user-type-to-assign?id=${id}&userId=${gipId}`
  );
};

export type ApiUpdateGipParams = {
  ProjectAccess: {
    access_code: number;
    user_id: number;
    user_type_id: number;
  };
};

export const ApiUpdateGipProject = (
  id: number,
  data: ApiUpdateGipParams
): Promise<Response> => {
  // Сменить Гипа
  return httpRequestPut(
    `${config.localDomain}/v1/project-access/update?id=${id}`,
    {},
    data
  );
};

export const ApiRemoveGipObject = (
  // Снять
  data: { StartForm: { content: string } },
  id: string | number
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/request-remove-gip/start?projectId=${id}`,
    {},
    data
  );

export const ApiGetUserTypesToAssign = (
  projectId: number,
  userId: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/get-user-type-to-assign?id=${projectId}&userId=${userId}`
  );

export const ApiGetProjectAccessByProject = (id: number): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project-access/by-project?ProjectAccessSearch[project_id]=${id}&expand=can,user,rating,userType,accessCode`
  );

export const ApiGetAvailableUsersOnProject = (
  firmId: number,
  projectId: number
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/user/get-available-users-on-project?firmId=${firmId}&projectId=${projectId}`
  );

export const ApiProjectAccessDelegate = (
  accessId: number,
  delegate: boolean
): Promise<Response> =>
  httpRequestPost(
    `${
      config.localDomain
    }/v1/project-access/delegate?accessId=${accessId}&delegate=${Number(
      delegate
    )}`
  );
