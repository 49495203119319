import { TSelectItem } from "../../../../components/features/Forms/SetGipForm/types";

export const arrayToSelectMultilevel = (projects: any) => {
  const values: TSelectItem[] = [];

  if (!projects) {
    return values;
  }

  Object.values(projects).forEach((project: any) => {
    values.push({
      id: project.id,
      title: project.name,
      level: 0,
    });

    Object.values(project.objects).forEach((object: any) => {
      values.push({
        id: object.id,
        title: object.name,
        level: 1,
      });
    });
  });

  return values;
};
