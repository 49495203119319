import { getTokenForHeaders } from "../../../../app/services/api/header";
import { SetState } from "../../ProjectEditing/SectionEditing/SectionEditing";
import { TFile, TProgresses } from "../types";
import removeFileById from "./removeFileById";

export default (
  postUrl: string,
  fileId: string,
  formData: FormData,
  setProgresses: SetState<TProgresses>,
  setFiles: SetState<TFile[]>,
  setRequests: SetState<Record<string, XMLHttpRequest>>
) => {
  const xhr = new XMLHttpRequest();

  xhr.upload.onprogress = function (event) {
    setProgresses((progresses) => ({
      ...progresses,
      [fileId]: Math.round((event.loaded / event.total) * 100),
    }));
  };

  xhr.onreadystatechange = function () {
    if (this.readyState === 4) {
      setRequests((requests: Record<string, XMLHttpRequest>) => {
        if (requests[fileId]) {
          delete requests[fileId];
        }

        return requests;
      });
      setFiles((files: TFile[]) => {
        if (this.status === 200) {
          const response = JSON.parse(this.responseText);

          return files.map((file: TFile) => {
            if (file.id === fileId) {
              return {
                ...file,
                serverId: response.id,
                svgIcon: response.svgIcon,
              };
            }

            return file;
          });
        }

        return removeFileById(files, fileId);
      });
    }
  };

  xhr.open("POST", postUrl);
  xhr.setRequestHeader("Authorization", `Bearer ${getTokenForHeaders()}`);
  xhr.send(formData);

  return xhr;
};
