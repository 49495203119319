import React from "react";
import { useSelector } from "react-redux";
import { selectorAppErrorData } from "../../app/feature/appError/appError";
import { RequestError } from "../../app/services/errorHandler";

export const ErrorCreator: React.FC = ({ children }) => {
  const errorApp = useSelector(selectorAppErrorData);
  const { status } = errorApp;

  if (errorApp && status === 401) {
    throw new RequestError(errorApp);
  }

  if (errorApp && status === 403) {
    throw new RequestError(errorApp);
  }

  if (errorApp && status === 404) {
    throw new RequestError(errorApp);
  }

  if (errorApp && status === 500) {
    throw new RequestError(errorApp);
  }

  return <>{children}</>;
};
