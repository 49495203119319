import React from "react";
import classNames from "classnames";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";
import {
  getProjectDocument,
  setToggleRoot,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import { FolderIcon, FolderWarningIcon } from "src/components/newUI/SVG";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { CardNode } from "src/FSD/widgets/v2/project/Documents/ui/CardNode";
import { useAppDispatch, useAppSelector } from "src/app/store";
import styles from "./DocumentCard.module.scss";

type DocumentCardProps = {
  data: ApiDocumentsResponses.View;
  index: number;
};

export const DocumentCard = ({ data, index }: DocumentCardProps) => {
  const dispatch = useAppDispatch();
  const { root } = useAppSelector(getProjectDocument);
  const { docs } = data;

  const isWarning = docs.some(
    (doc) =>
      doc.sums.pairSignCountList.PAIR_ORANGE > 0 ||
      !doc.sign.author ||
      !doc.sign.signer
  );

  function handleRootToggle(index: number) {
    dispatch(setToggleRoot(index));
  }

  return (
    <>
      <div
        className={classNames(styles.card, index === 1 && styles.firstChild)}
        onClick={() => handleRootToggle(index)}
      >
        <div className={styles.card_wrap}>
          {isWarning ? (
            <FolderWarningIcon />
          ) : (
            <FolderIcon width={"24"} height={"24"} />
          )}
          <div className={styles.card_container}>
            <p className={styles.card_container_name}>{data.executor}</p>
            <p className={styles.card_container_type}>{data.executorType}</p>
          </div>
        </div>
        <ToggleAccordion root toggle={root[index]} />
      </div>
      {root[index] &&
        docs.map((doc) => <CardNode key={doc.id} doc={doc} index={doc.id} />)}
    </>
  );
};
