import { createSlice } from "@reduxjs/toolkit";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";
import { RootState } from "../../../store";
import { fetchProjectDocument } from "./thunks";
import {
  ProjectDocumentReturn,
  ProjectDocumentReturnV2,
} from "../../../../types/api/responseTypes/apiDocumentsType";

export interface IActiveBtn {
  work: boolean;
  terminated: boolean;
  done: boolean;
}

type InitialState = {
  isLoad: boolean;
  loadFullPage: boolean;
  loadBody: boolean;
  documents: ProjectDocumentReturn[];
  documentsNew: ProjectDocumentReturnV2[];
  error: any;
  isAllToggle: boolean;
  root: any;
  docs: any;
  process: any;
  processDocs: any;
  activeBtn: IActiveBtn;
  statusType: string;
  searchValue: string;
  isSearched: boolean;
};

const initialState: InitialState = {
  isLoad: false,
  loadFullPage: false,
  loadBody: false,
  documents: [],
  documentsNew: [],
  error: null,
  isAllToggle: false,
  root: {},
  docs: {},
  process: {},
  processDocs: {},
  activeBtn: {
    work: true,
    terminated: false,
    done: false,
  },
  statusType: "work",
  searchValue: "",
  isSearched: false,
};

const projectDocumentSlice = createSlice({
  name: "projectDocumentSlice",
  initialState,
  reducers: {
    setError(state) {
      state.error = false;
    },
    setToggleRoot: (state, action) => {
      const index = action.payload;
      state.root[index] = !state.root[index];
    },
    setToggleDocs: (state, action) => {
      const index = action.payload;
      if (state.docs[index] !== undefined) {
        state.docs[index] = undefined;
      } else {
        state.docs[index] = index;
      }
    },
    setToggleProcess: (state, action) => {
      const index = action.payload;
      if (state.process[index] !== undefined) {
        state.process[index] = undefined;
      } else {
        state.process[index] = index;
      }
    },
    setToggleProcessDocs: (state, action) => {
      const index = action.payload;
      if (state.processDocs[index] !== undefined) {
        state.processDocs[index] = undefined;
      } else {
        state.processDocs[index] = index;
      }
    },
    setRoot(state, action) {
      const initialRoot: any = {};
      const docs: any = {};

      action.payload.forEach(
        (el: ApiDocumentsResponses.View, index: number) => {
          initialRoot[index + 1] = false;
          el.docs.forEach((doc) => {
            docs[doc.id] = false;
          });
        }
      );
      state.root = initialRoot;
      state.docs = docs;
    },
    setAllToggle(state, action) {
      const newRoot: any = {};
      const newDocs: any = {};
      Object.keys(state.root).forEach((key) => {
        newRoot[key] = action.payload;
      });
      Object.keys(state.docs).forEach((key) => {
        newDocs[key] = action.payload;
      });
      state.root = newRoot;
      state.docs = newDocs;
      state.isAllToggle = action.payload;
    },
    setToggle(state) {
      state.isAllToggle = !state.isAllToggle;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsSearch(state, action) {
      state.isSearched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectDocument.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectDocument.fulfilled, (state, action) => {
      state.isLoad = false;
      state.documents = action.payload;
    });
    builder.addCase(fetchProjectDocument.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
});
export const {
  setError,
  setToggle,
  setToggleRoot,
  setToggleDocs,
  setToggleProcess,
  setToggleProcessDocs,
  setAllToggle,
  setRoot,
  setSearchValue,
  setIsSearch,
} = projectDocumentSlice.actions;
export const getProjectDocument = (state: RootState) =>
  state.projectDocumentGet;
export default projectDocumentSlice.reducer;
