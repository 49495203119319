import { ApiSchedulePlanResponses } from "src/FSD/entities/schedulePlan/model/responseTypes";

export const dateKeys: (keyof ApiSchedulePlanResponses.View)[] = [
  "exp_date_end",
  "exp_date_start",
  "ii_date_end",
  "ii_date_start",
  "obs_date_end",
  "obs_date_start",
  "pd_date_end",
  "pd_date_start",
  "rd_date_end",
  "rd_date_start",
];
