import { v4 as uuidv4 } from "uuid";
import { toUnixTimestamp } from "../../../../../app/services/date/date";
import {
  formFullUserName,
  stringDateToDDMMYYYY,
} from "../../../../shared/lib/helpers";
import { isDateRangeNPercentLeft } from "../../../../shared/lib/helpers/isDateRangeNPercentLeft";
import { ApiTaskResponses } from "../../../task/model/responseTypes";

type TCreateFreeTaskResponse = ApiTaskResponses.View & {
  project: {
    id: number;
    code: string;
  };
  partGroup: {
    code: string;
    color: string;
    fullName: string;
  };
};

export const mapFreeTask = (response: TCreateFreeTaskResponse) => {
  const dateCreateValue = stringDateToDDMMYYYY(response.date_create!);
  const dateDeadlineValue = stringDateToDDMMYYYY(response.date_deadline);

  return {
    id: response.id,
    uuid: uuidv4(),
    name: response.name,
    project: {
      id: response.project.id,
      code: response.project.code,
    },
    isStop: false,
    isCheck: null,
    isImportant: response.workflow_data?.importance ?? false,
    partGroup: {
      id: response.partGroup.id,
      code: response.partGroup.code,
      color: response.partGroup.color,
      fullName: response.partGroup.fullName,
    },
    process: {
      id: 0,
      name: "",
      deposit: null,
      nds: 0,
    },
    executor: {
      isDelegate: false,
      fullName: formFullUserName(response.executor),
    },
    date: {
      value: dateDeadlineValue,
      isExpired: toUnixTimestamp(dateDeadlineValue) > Date.now(),
      isExpiring: isDateRangeNPercentLeft(
        dateCreateValue,
        dateDeadlineValue,
        10
      ),
    },
    workFlowClass: response.workflow_class,
    files: response.files,
    comment: response.comment,
    content: response.content,
    header: response.name,
    status: response.status.key,
    can: response.can,
    needFiles: response.need_files,
    addText: response.data?.addText ?? "",
  };
};
