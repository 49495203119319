import { httpRequestGet, httpRequestPost } from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessExpertise = (
  processId: number
): Promise<Response> => {
  // получить экспертизу
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-expertise?id=${processId}&expand=data`
  );
};

export const ApiProjectProcessExpertiseComplete = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};
