import React from "react";
import { useAppSelector } from "../../../../app/store";
import { getProjectProcessInfoName } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "../../MileStoneCard/MileStoneCheck/ModalConfirm/ModalConfirm.module.scss";
import CustomButton from "../../CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";

interface ModalConfirmProps {
  name: string;
  completeMileStoneHandler: (bool: boolean, id: number) => void;
  stageId: number;
  processName?: string;
}
export const ModalConfirm = ({
  name,
  completeMileStoneHandler,
  stageId,
  processName,
}: ModalConfirmProps) => {
  const processNameTypeProject = useAppSelector(getProjectProcessInfoName);
  const handleConfirm = () => {
    completeMileStoneHandler(true, stageId);
  };

  const handleCancel = () => {
    completeMileStoneHandler(false, stageId);
  };

  return (
    <div className={cls.confirm}>
      <h1>Внимание!</h1>
      {`Вы подтверждаете принятие контрольной точки «${name}» и
      открываете финансирование следующего этапа по разделу «${
        processNameTypeProject || processName
      }»?`}
      <span>Отменить приёмку будет невозможно.</span>
      <div className={cls.confirm_btn}>
        <CustomButton
          background={palette.red}
          onClick={handleCancel}
          width={80}
        >
          Нет
        </CustomButton>
        <CustomButton
          background={palette.green}
          onClick={handleConfirm}
          width={80}
        >
          Да
        </CustomButton>
      </div>
    </div>
  );
};
