import { forwardRef, ReactNode } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { TSelectItem } from "../../../../../components/features/Forms/SetGipForm/types";
import { Nullable } from "../../../../../types/generics";
import { useSelectExecutor } from "../lib/hooks/useSelectExecutor";

type Props = {
  publishObjectId: number;
  userType?: Nullable<TSelectItem>;
  error?: boolean;
  helperText?: ReactNode;
  isProcessWork?: boolean;
} & Omit<SelectProps, "label" | "options">;

export const SelectExecutor = forwardRef(
  (
    {
      publishObjectId,
      userType,
      value,
      className = "",
      disabled = false,
      error = false,
      helperText = "",
      isProcessWork = false,
      ...props
    }: Props,
    ref
  ) => {
    const { usersToAssign, isPendingUsersAssign } = useSelectExecutor({
      id: publishObjectId,
      userType,
      isProcessWork,
    });

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={usersToAssign}
        isLoading={isPendingUsersAssign}
        label="Исполнитель"
        className={className}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    );
  }
);
