import React from "react";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { PostItem, PostItemProps } from "./PostItem/PostItem";

import style from "./style.module.scss";

export type PostsListProps = ComponentPropsType<{
  items: PostItemProps[];
}>;

export const PostList: React.FC<PostsListProps> = ({ items }) => {
  return (
    <>
      {items.length > 0 ? (
        <ul className={style.postsList}>
          {items.map((item, i) => {
            return (
              <li key={i}>
                <PostItem {...item} />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={style.postsListNotFound}>Ничего не найдено</div>
      )}
    </>
  );
};
