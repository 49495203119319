import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList/FilesList";
import { useCheckSubTask } from "../lib/hooks/useCheckSubTask";

const defaultValues = {
  comment: "",
  status: null,
};

type Props = {
  taskId: number;
  header: string;
};

export const CheckSubTaskForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    isRejecting,
    isAccepting,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useCheckSubTask({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>

      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Комментарий исполнителя"
            multiline
            minRows={3}
            disabled
            value={taskData!.content}
          />
          <FilesList
            fileLinks={taskData!.parent!.files}
            label="Файлы замечания"
          />
          <TextField
            {...register("comment")}
            label="Замечания"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isRejecting}
              type="submit"
              color="error"
              variant="outlined"
              width={250}
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
            >
              Вернуть на доработку
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isAccepting}
              type="submit"
              width={120}
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
