export enum WorkFlowClasses {
  CUSTOMER_ALIGN_WORK = "app\\workflow\\customerAlign\\work\\Workflow", //  "Предварительное решение по задаче",
  CUSTOMER_ALIGN_PROCESS = "app\\workflow\\customerAlign\\process\\Workflow", //  "Предварительное решение по разделу",
  STOP_PROCESS = "app\\workflow\\stopProcess\\Workflow", //  "Приостановка работ по разделу",
  SUB_TASK = "app\\workflow\\subTask\\Workflow", //  "Подзадачи",
  EXECUTE_WORK = "app\\workflow\\execute\\work\\Workflow", //  "Завершить задачу",
  EXECUTE_PROCESS = "app\\workflow\\execute\\process\\Workflow", //  "Завершить раздел",
  REQUEST_PROJECT_ACCESS = "app\\workflow\\requestProjectAccess\\Workflow", //  "Приглашение в проект",
  REQUEST_USER_TO_JOIN_TEAM = "app\\workflow\\requestUserToJoinTeam\\Workflow", //  "Приглашение в команду",
  MODERATION_USER_ATTRIBUTES = "app\\workflow\\moderationUserAttributes\\Workflow", //  "Изменить данные пользователя",
  REQUEST_TO_GIP = "app\\workflow\\requestToGIP\\Workflow", //  "Заявка ГИП",
  REQUEST_REMOVE_WORK_EXECUTOR = "app\\workflow\\requestRemoveExecutor\\work\\Workflow", //  "Снять исполнителя с задачи",
  REQUEST_REMOVE_PROCESS_EXECUTOR = "app\\workflow\\requestRemoveExecutor\\process\\Workflow", //  "Снять исполнителя с раздела",
  EXPERTISE_REMARK_ROW = "app\\workflow\\expertiseRemarkRow\\Workflow", //  "Замечания экспертизы",
  REQUEST_EQUIPMENT = "app\\workflow\\requestEquipment\\Workflow", //  "Заявка поставщика",
  REQUEST_TO_EXECUTE_WORK = "app\\workflow\\requestToExecute\\work\\Workflow", //  "Заявка на задачу",
  REQUEST_TO_EXECUTE_PROCESS = "app\\workflow\\requestToExecute\\process\\Workflow", //  "Заявка на раздел",
  REQUEST_USER_TO_JOIN_FIRM = "app\\workflow\\requestUserToJoinFirm\\Workflow", //  "Пригласить в организацию",
  VERIFICATION = "app\\workflow\\verification\\Workflow", //  "Согласование нормоконтроля",
  REQUEST_TO_CHECK_FIRM = "app\\workflow\\requestToCheckFirm\\Workflow", //  "Проверка организации",
  CHECK_SUB_CHECK_PROCESS = "app\\workflow\\check\\subCheckProcess\\Workflow", //  "Согласование документации по разделу",
  CHECK_WORK_CUSTOMER_ALIGN = "app\\workflow\\check\\customerAlign\\work\\Workflow", //  "Проверка предварительного решения по задаче",
  CHECK_PROCESS_CUSTOMER_ALIGN = "app\\workflow\\check\\customerAlign\\process\\Workflow", //  "Проверка предварительного решения по разделу",
  CHECK_SUB_TASK = "app\\workflow\\check\\subTask\\Workflow", //  "Проверка подзадачи",
  CHECK_EXECUTE_WORK = "app\\workflow\\check\\execute\\work\\Workflow", //  "Согласование документации по задаче",
  CHECK_EXECUTE_PROCESS = "app\\workflow\\check\\execute\\process\\Workflow", //  "Согласование документации по разделу",
  REQUEST_CHECK_REMOVE_WORK_EXECUTOR = "app\\workflow\\check\\requestRemoveExecutor\\work\\Workflow", //  "Снятие исполнителя с задачи",
  REQUEST_CHECK_REMOVE_PROCESS_EXECUTOR = "app\\workflow\\check\\requestRemoveExecutor\\process\\Workflow", //  "Снятие исполнителя с раздела",
  CHECK_EXPERTISE_REMARK_ROW = "app\\workflow\\check\\expertiseRemarkRow\\Workflow", //  "Проверка ответа на замечание экспертизы",
  CHECK_SUB_CHECK = "app\\workflow\\check\\subCheck\\Workflow", //  "Согласование предварительного решения",
  CHECK_OUTPUT_TASK = "app\\workflow\\check\\outputTask\\Workflow", //  "Согласование задания",
  REQUEST_REMOVE_GIP = "app\\workflow\\requestRemoveGip\\Workflow", //  "Снять ГИП",
  OUTPUT_TASK = "app\\workflow\\outputTask\\Workflow", //  "Исходящее задание",
  REQUEST_WORK_CUSTOMER_ALIGN = "app\\workflow\\requestCustomerAlign\\work\\Workflow", //  "Запрос предварительного решения по задаче",
  REQUEST_PROCESS_CUSTOMER_ALIGN = "app\\workflow\\requestCustomerAlign\\process\\Workflow", //  "Запрос предварительного решения по разделу",
  TECHNICAL_SPECIFICATIONS = "app\\workflow\\technicalSpecifications\\Workflow", //  "Получение ТУ",
  REQUEST_TO_GIP_HELPER = "app\\workflow\\requestToGIPHelper\\Workflow", // "Заявка помощника ГИП",
  FREE_TASK = "app\\workflow\\freeTask\\Workflow", // "Оперативная задача",
  CHECK_FREE_TASK = "app\\workflow\\check\\freeTask\\Workflow", //  "Проверка оперативной задачи",
  EXPERTISE_REMARK = "app\\workflow\\expertiseRemark\\Workflow", // "Корректировка замечания",
  CHECK_EXPERTISE_REMARK = "app\\workflow\\check\\expertiseRemark\\Workflow", //  "Проверка корректировки замечания",
  REQUEST_TO_JOIN_FIRM = "app\\workflow\\requestToJoinFirm\\Workflow", //  "Вступить в организацию"
}

export type TWorkFlowClasses =
  (typeof WorkFlowClasses)[keyof typeof WorkFlowClasses];
