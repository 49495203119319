import React from "react";
import styles from "./Progress.module.scss";
import { ChecklistCardDate } from "../../../../../../newUI/ChecklistCard/ChecklistCardDate/ChecklistCardDate";
import { ChecklistCardBudget } from "../../../../../../newUI/ChecklistCard/ChecklistCardBudget/ChecklistCardBudget";

interface ProgressProps {
  price: {
    end: number;
    percent: number;
    start: number;
  };
  dates: {
    end: string;
    percent: number;
    start: string;
    endFact: string;
  };
}

export const Progress = ({ dates, price }: ProgressProps) => {
  console.log(dates);
  return (
    <div className={styles.progress}>
      <div className={styles.progress_card}>
        {dates && (
          <ChecklistCardDate
            datePercent={dates.percent}
            dateStart={dates.start}
            dateEnd={dates.end}
            dateEndFact={dates.endFact}
          />
        )}
        {price && (
          <ChecklistCardBudget
            priceStart={price.start}
            priceEnd={price.end}
            pricePercent={price.percent}
          />
        )}
      </div>
    </div>
  );
};
