import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { httpRequestGet } from "../../../../services/api/api";
import { config } from "../../../../../app.cofig";

const ApiUserTypeById = (userId: number): Promise<Response> => {
  // получить типы пользователя
  return httpRequestGet(
    `${config.localDomain}/v1/user-type/get-user-types?userId=${userId}`
  );
};

export const fetchUserType = createAsyncThunk<
  Record<string, string>,
  {
    userId: number;
  }
>("getList/fetchUserType", async ({ userId }, { dispatch }) => {
  let response: any = {};
  await request(
    ApiUserTypeById(userId),
    (data) => {
      response = data;
    },
    () => (err) => {
      dispatch(pushError(err));
    }
  )();
  return response;
});
