import { IndexPageExecutorType } from "../../../../../types/stateTypes/indexPages/indexPageUserDataType";

export const executorPageInitialState: IndexPageExecutorType = {
  users: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
