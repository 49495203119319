import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

export const getBreadcrumbs = (
  projectProcess: ApiProjectProcessResponses.View | undefined
) => {
  if (!projectProcess) {
    return [];
  }

  return [
    {
      isActive: true,
      title: "Главная",
      url: `/user/new-dashboard`,
    },
    ...(projectProcess?.project.parent_id
      ? [
          {
            isActive: true,
            title: `${projectProcess.project.parentName || ""}`,
            url: `/project/test/${projectProcess.project.parent_id}`,
          },
        ]
      : []),
    {
      isActive: true,
      title: `${projectProcess?.project.name || ""}`,
      url: `/project/test/${projectProcess?.project.id}`,
    },
    {
      isActive: false,
      title: `${projectProcess?.projectPart.code || ""}`,
      url: `/project/test/${projectProcess?.id}`,
    },
  ];
};
