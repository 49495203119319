import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  task: any;
  isMultipart?: boolean;
}>;

export const complete = (builder: QueryBuilder) =>
  builder.mutation<ApiTaskResponses.View, TParams>({
    query: ({ id, task, isMultipart = false }) => ({
      url: `v1/task/complete?id=${id}`,
      method: HTTPMethods.POST,
      data: task,
      isMultipart,
    }),
    invalidatesTags: ["ProcessWork.View", "ProcessWork.GetDocumentations"],
  });
