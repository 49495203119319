import React from "react";
import { Wrapper } from "../../../templates";
import { NoticesSkeleton } from "./NoticeSkeleton/NoticesSkeleton";

export const NoticeSkeletonList: React.FC<{ theme: string }> = () => {
  return (
    <ul>
      <li>
        <Wrapper>
          <NoticesSkeleton />
        </Wrapper>
      </li>
      <li>
        <Wrapper>
          <NoticesSkeleton />
        </Wrapper>
      </li>
      <li>
        <Wrapper>
          <NoticesSkeleton />
        </Wrapper>
      </li>
      <li>
        <Wrapper>
          <NoticesSkeleton />
        </Wrapper>
      </li>
      <li>
        <Wrapper>
          <NoticesSkeleton />
        </Wrapper>
      </li>
    </ul>
  );
};
