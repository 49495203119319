import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchGipRemoveComplete,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import styles from "./style.module.scss";

type Inputs = {
  comment: string;
  status: number;
};

type CompleteRemoveGipProps = {
  removeGip: {
    id: number;
    content: string;
  };
};

export const CompleteRemoveGip = ({ removeGip }: CompleteRemoveGipProps) => {
  const { id, content } = removeGip;
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm<Inputs>();

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      comment: dataValues.comment,
      status: dataValues.status,
    };
    if (params.id) {
      await dispatch(fetchGipRemoveComplete({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(params.id));
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={styles.completeRemoveGip}
      >
        <TextField
          autoComplete="off"
          label="Комментарий от руководителя проекта"
          defaultValue={content}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          className={styles.textarea}
          {...register("comment")}
          multiline
          minRows={2}
          label="Комментарий"
          placeholder="Введите комментарий"
        />
        <div className={styles.completeRemoveGip_container}>
          <Button
            type="submit"
            variant="outlined"
            onClick={() => setValue("status", 5)}
          >
            Отказать
          </Button>
          <Button type="submit" onClick={() => setValue("status", 4)}>
            Согласиться
          </Button>
        </div>
      </form>
    </div>
  );
};
