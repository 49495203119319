import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage, request } from "../../../services/api/requestHandler";
import { pushError, pushMessage } from "../../errorTrace";
import { config } from "../../../../app.cofig";
import { httpRequestGet, httpRequestPost } from "../../../services/api/api";

type TSaveTasks = { id: number; body: Record<string, number[]> };

const ApiGetApplications = (projectId: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/get-applications?id=${projectId}`
  );
};

const ApiSaveTasks = (
  projectId: number,
  body: Record<string, number[]>
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/group-select-executor?projectId=${projectId}`,
    {},
    body
  );
};

export const getExecutors = createAsyncThunk(
  "projectGroupApplications/getExecutors",
  async (id: number, { dispatch }) => {
    let response: any = {};
    await request(
      ApiGetApplications(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const saveChosenTasks = createAsyncThunk(
  "projectGroupApplications/saveChosenTasks",
  async ({ id, body }: TSaveTasks, { dispatch, rejectWithValue }) => {
    let error: any;

    await request(
      ApiSaveTasks(id, body),
      () => {
        dispatch(pushMessage("Успешно"));
      },
      () => (err) => {
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
