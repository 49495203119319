import { Draft, PayloadAction } from "@reduxjs/toolkit";
import * as KanbanType from "../../types";
import { WorkFlowClasses } from "../../../../shared/lib/constants/workFlowClasses";
import { mapFreeTask } from "../dataMappers";

export const updateFreeTask = (
  state: Draft<KanbanType.State>,
  action: PayloadAction<any>
) => {
  const {
    groupBy: { id: groupById },
  } = state.data;

  const updatedFreeTask = mapFreeTask(action.payload);

  const { id: taskId, status: columnId, partGroup } = updatedFreeTask;

  let rowId: number | WorkFlowClasses = 0;

  if (groupById === "skill") {
    rowId = partGroup.id;
  }

  if (groupById === "type") {
    rowId = WorkFlowClasses.FREE_TASK;
  }

  state.tasks.rowTasks[rowId].columns[columnId] = state.tasks.rowTasks[
    rowId
  ].columns[columnId].map((task) =>
    task.id === taskId ? updatedFreeTask : task
  );
};
