import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Card.module.scss";
import { CardHeader } from "./CardHeader";
import CardContext from "./CardContext";
import { CardControls } from "./CardControls";

type CardProps = {
  header?: ReactNode;
  children?: ReactNode;
  controls?: ReactNode;
  className?: string;
  contentClassName?: string;
};

export const Card = ({
  header,
  children,
  controls,
  className,
  contentClassName,
}: CardProps) => {
  const content = (
    <div className={cn(styles.card__content__children, contentClassName)}>
      {children}
    </div>
  );

  return (
    <CardContext.Provider value={{}}>
      <div className={cn(styles.card, className)}>
        <div className={styles.card__content}>
          {header}
          {content}
        </div>
        {controls}
      </div>
    </CardContext.Provider>
  );
};

Card.Header = CardHeader;
Card.Controls = CardControls;
