export const getTokenForHeaders = () => {
  return localStorage.getItem("userToken");
};

export const headerContentTypeJSON = () => ({
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
  Authorization: `Bearer ${getTokenForHeaders()}`,
});

export const headersContentMultiDataType = () => ({
  Authorization: `Bearer ${getTokenForHeaders()}`,
});
