import { forwardRef } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { responseAgreement } from "../model/options";

type Props = Omit<SelectProps, "options">;

export const SelectAgreement = forwardRef((props: Props, ref) => {
  const { value } = props;

  return (
    <Select
      {...props}
      ref={ref}
      disableClear
      value={value}
      options={responseAgreement}
    />
  );
});
