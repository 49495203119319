import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectorAppTheme } from "../../app/feature/app/app";

export const useSetFontApp = () => {
  const theme = useSelector(selectorAppTheme);
  useEffect(() => {
    const { body } = document;
    if (theme === "old") body.style.fontFamily = `"Roboto", sans-serif`;
    if (theme === "dark") body.style.fontFamily = `"Montserrat", "Arial"`;
  }, [theme]);
};
