import { RootState } from "../../../../store";

export const getProjectProcessMilestoneStages = (state: RootState) =>
  state.projectProcessView.mileStone.stages;

export const getProjectProcessMilestoneAvailablePercent = (state: RootState) =>
  state.projectProcessView.mileStone.availablePercent;

export const getProjectProcessMileStoneIsLoad = (state: RootState) =>
  state.projectProcessView.mileStone.isLoadMileStone;

export const getProjectProcessMileStoneCompleteIsLoad = (state: RootState) =>
  state.projectProcessView.mileStone.isLoadMileStoneComplete;
