import React, { memo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormControl, SelectChangeEvent } from "@mui/material";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchGipSearch,
  fetchProjectViewSearchGip,
} from "src/app/feature/ProjectView/thunks";
import {
  fetchProjectUsersList,
  fetchProjectUsersType,
} from "src/app/feature/ProjectView/AdminReducer/thunks";
import { getProjectUsersList } from "src/app/feature/ProjectView/AdminReducer/projectAdmin";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { SelectField } from "src/components/newUI/SelectMui/SelectMui";
import { NewModalPreloader } from "src/components/newUI/NewModal/NewModalPreloader";
import { Select } from "src/components/UI/components/FormComponentns/Select";
import styles from "./style.module.scss";

type Inputs = {
  price: number;
  type: string;
  user: { title: string; id: string };
};

const types = {
  1: "Физическое лицо",
  2: "Юридическое лицо",
  3: "Индивидуальный предприниматель",
  4: "Самозанятый",
};

const schema = Yup.object().shape({
  price: Yup.number()
    .min(1000, `Стоимость работ ГИП не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость процесса не может быть больше 10 млн рублей!`)
    .required("Это поле обязательно для заполнения")
    .typeError("Значение «Cтоимость» должно быть числом."),
  type: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
});

const schemaIsAdmin = Yup.object().shape({
  price: Yup.number()
    .min(1000, `Стоимость работ ГИП не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость процесса не может быть больше 10 млн рублей!`)
    .required("Это поле обязательно для заполнения")
    .typeError("Значение «Cтоимость» должно быть числом."),
});

export const GipSearch = memo(() => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { newUsersList, usersType, isLoadUsersType, error, isLoadUsersList } =
    useSelector(getProjectUsersList);
  const { project } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();

  const [adminDiv, setAdminDiv] = useState(false);
  const [valueType, setValueType] = useState("");
  const [valueMulti, setValueMulti] = useState<string[]>([]);

  useEffect(() => {
    if (project?.can.isAdmin) {
      dispatch(fetchProjectUsersList());
    }
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setValueType(event.target.value);
  };

  useEffect(() => {
    if (usersType.length > 0) {
      setValueType(usersType[0][0]);
    }
  }, [usersType]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(!adminDiv ? schema : schemaIsAdmin),
    defaultValues: {
      user: { title: "", id: "" },
    },
  });

  const watchUser = watch("user");

  function featuresAdmin() {
    setValueType("");
    setValue("user", { title: "", id: "" });
    setAdminDiv(!adminDiv);
  }

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      price: dataValues.price,
      gip_type: !adminDiv ? dataValues.type.split(",") : [1, 2, 3, 4],
      userIdByAdmin: watchUser.id.length ? +watchUser.id : null,
      userTypeByAdminId: valueType.length ? +valueType : null,
    };
    const { id } = params;
    if (id) {
      await dispatch(fetchGipSearch({ gip: { id, data, adminDiv } }));
      await dispatch(fetchProjectViewSearchGip(id));
    }
  };

  const onChangeMulti = (event: any) => {
    if (event.target.value.includes("all")) {
      const all = Object.keys(types);
      setValueMulti(all);
      setValue("type", "1,2,3,4");
    } else {
      setValueMulti(event.target.value);
      setValue("type", event.target.value);
    }
    trigger("type");
  };
  const handleClearClick = () => {
    setValueMulti([]);
    setValue("type", "");
  };

  useEffect(() => {
    if (watchUser.id.length) {
      dispatch(fetchProjectUsersType(watchUser.id));
    }
  }, [watchUser, dispatch]);

  const labelType = isLoadUsersType ? "...loading" : "Форма собственности";

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
  }

  if (isLoadUsersList) {
    return <NewModalPreloader />;
  }

  return (
    <div className={styles.container}>
      <form
        className={styles.searchGip}
        onSubmit={handleSubmit(formSubmitHandler)}
      >
        <TextField
          {...register("price")}
          label="Стоимость"
          defaultValue=""
          error={!!errors.price}
          helperText={errors.price ? errors.price.message : null}
        />
        {!adminDiv && (
          <SelectField
            {...register("type")}
            name="type"
            label="Форма собственности"
            multiple
            multiValue={valueMulti}
            isAllClick
            onChange={onChangeMulti}
            options={Object.entries(types)}
            handleClearClick={handleClearClick}
            error={errors.type ? errors.type.message : null}
            newDesign
          />
        )}
        {project?.can.isAdmin && (
          <>
            <FormControl className={styles.adminBtn}>
              <Button variant="outlined" onClick={featuresAdmin}>
                Функция администратора
              </Button>
            </FormControl>
            {adminDiv && newUsersList && (
              <>
                <Select
                  label="Назначенный Гип"
                  name="gipByAdmin"
                  value={watchUser}
                  changeHandler={(_, value) => {
                    setValue("user", value);
                  }}
                  options={newUsersList}
                  isLoading={false}
                  disableClear
                  newDesign
                />
                {usersType.length !== 0 && (
                  <SelectField
                    label={labelType}
                    name="gipTypeByAdmin"
                    options={usersType}
                    value={valueType}
                    onChange={handleChange}
                    newDesign
                  />
                )}
                {usersType.length === 0 && (
                  <SelectField
                    label={labelType}
                    name="gipTypeByAdmin"
                    options={usersType}
                    value={valueType}
                    onChange={handleChange}
                    disabled={true}
                    newDesign
                  />
                )}
              </>
            )}
          </>
        )}
        <FormControl className={styles.searchGip_btn}>
          <Button type="submit">{adminDiv ? "Назначить" : "Поиск"}</Button>
        </FormControl>
      </form>
    </div>
  );
});
