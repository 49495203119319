import { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";

type Props = { children: ReactNode };

const modalRoot = document.getElementById("modalRoot");

const ModalWrapper = ({ children }: Props) =>
  ReactDOM.createPortal(children, modalRoot!) as ReactElement;

export default ModalWrapper;
