import { combineReducers } from "@reduxjs/toolkit";
import projectProcessToProcess from "./getListProjectProcessToProcesses/slice/projectProcessToProcess";
import projectListByProcess from "./getListProjectByProcessId/slice/projectListByProcessId";
import userTypeList from "./getListUserTypeById/slice/userTypeList";

const getListSlice = combineReducers({
  projectListByProcess: projectListByProcess.reducer,
  processList: projectProcessToProcess.reducer,
  userTypeList: userTypeList.reducer,
});

export default getListSlice;
