import { NoticeStateType } from "../../../types/stateTypes/noticeType";

export const noticesPageInitialState: NoticeStateType = {
  notices: [],
  meta: {
    paginationPageCount: 1,
    paginationPerPage: 10,
    paginationAllPageCount: 1,
    paginationCurrentPage: 1,
  },
};
