import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { trimNumber } from "src/utils/trimNumber";
import { SecureIcon } from "src/components/newUI/SVG";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { useProcessWorkUnPublishMutation } from "src/FSD/entities/processWork/api";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import styles from "./WorksAccordion.module.scss";

type TProps = ApiProjectProcessResponses.TWorks & {
  userFio: string;
  setWorkPublication: (id: boolean) => void;
  setWorkPublicationId: (id: number) => void;
};

export const AccordionWorks = ({
  work,
  appCount,
  userFio,
  setWorkPublication,
  setWorkPublicationId,
}: TProps) => {
  const {
    title,
    id,
    activeAccess,
    executorType,
    status,
    date_start,
    date_limit,
    secure,
    price,
  } = work;

  const threeDotButtonRef = useRef<RefType>(null);
  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };
  function handleWorkPublicationModal() {
    setWorkPublicationId(id);
    setWorkPublication(true);
    closeThreeDot();
  }

  const [unPublish] = useProcessWorkUnPublishMutation();
  const unPublishWorkClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задачу с публикации?"
    );
    if (result) {
      await unPublish({ id });
      closeThreeDot();
    }
  };

  const [edit, setEdit] = useState(false);
  const showEdit = () => {
    setEdit(true);
    closeThreeDot();
  };
  const hideEdit = () => {
    setEdit(false);
  };

  const isRuble = price === null;
  const priceIsNotNumber = price === "Договорная";

  return (
    <div className={classNames(styles.parent)}>
      <div className={classNames(styles.title)}>
        <TextLink
          variant="white"
          redirect
          url={`/process-work/${id}`}
          title={title}
          className={styles.title__link}
          target="_blank"
        >
          {title}
        </TextLink>
        <div className={styles.subtitle}>{status.value}</div>
      </div>
      <div className={classNames(styles.parent_flex)}>
        {!!activeAccess && (
          <>
            <div className={styles.title}>{userFio}</div>
            <div className={styles.parent_type}>
              {activeAccess.userType?.listName}
            </div>
          </>
        )}
        {!activeAccess && status.key === 2 && (
          <>
            <div className={styles.title}>Форма собственности</div>
            <div
              className={classNames(
                styles.parent_type,
                styles.parent_type_title
              )}
            >
              {executorType.map((type) => (
                <span key={type.value}>{type.value}</span>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.title}>
        {!!appCount && (
          <div className={styles.count}>
            <p>Заявки:</p>
            <TextLink
              url={`/process-work/${id}?tab=application`}
              className={styles.title}
              redirect
            >
              {appCount}
            </TextLink>
          </div>
        )}
      </div>
      <div className={classNames(styles.parent_flex)}>
        {!priceIsNotNumber && (
          <div className={styles.parent_price}>
            <span>{trimNumber(price)}</span>
            {!isRuble && <span className={styles.parent_price_ruble}>₽</span>}
            {secure && <SecureIcon width="16" heigth="18" fill="#D5D6DE" />}
          </div>
        )}
        {date_start && (
          <div className={styles.parent_date}>
            {date_start} - {date_limit}
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button component={Link} to={`/process-work/${id}`} target="_blank">
          Открыть
        </Button>
        <ThreeDotButton ref={threeDotButtonRef}>
          <MenuItem component={Link} to={`/process-work/${id}`} target="_blank">
            Перейти к задаче
          </MenuItem>
          {work.can.unpublish && (
            <MenuItem onClick={showEdit}>Редактировать</MenuItem>
          )}
          {work.can.unpublish && (
            <MenuItem onClick={unPublishWorkClick}>
              Вернуть с публикации
            </MenuItem>
          )}
          {work.can.publish && (
            <MenuItem onClick={handleWorkPublicationModal}>
              Опубликовать
            </MenuItem>
          )}
        </ThreeDotButton>
        {work.can.unpublish && (
          <Modal isActive={edit} handleClose={hideEdit}>
            <ProjectProcessModals.TaskPublishUpdate {...work} />
          </Modal>
        )}
      </div>
    </div>
  );
};
