import { useEffect } from "react";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { toSelect } from "../../../../../../app/feature/project/projectProcessPublication";
import { Nullable } from "../../../../../../types/generics";
import { useQueries } from "./useQueries";

type UseModalProps = {
  id: number;
  isProcessWork: boolean;
  executor?: Nullable<TSelectItem>;
};

export const useSelectUserTypeToAssign = ({
  id,
  isProcessWork,
  executor,
}: UseModalProps) => {
  const { fetchGetGetUserTypeToAssign, userTypeToAssignRaw, isLoading } =
    useQueries({ id, isProcessWork, executor });

  useEffect(() => {
    if (isProcessWork) {
      fetchGetGetUserTypeToAssign();
    }
  }, [isProcessWork]);

  useEffect(() => {
    if (!isProcessWork && executor) {
      fetchGetGetUserTypeToAssign();
    }
  }, [executor, isProcessWork]);

  const userTypeToAssign = useDataMapper(userTypeToAssignRaw, toSelect);

  const isPendingTypeAssign = (isLoading || !userTypeToAssign) && !!executor;

  return {
    userTypeToAssign,
    isPendingTypeAssign,
  } as const;
};
