import React from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { setOpenNode } from "../../../../../../app/feature/ProjectView/milestone/slice/projectMilestoneSlice";
import { getProjectMilestoneOpenNode } from "../../../../../../app/feature/ProjectView/milestone/selectors/milestoneSelectors";
import cls from "../../../../ProjectProcessView/MileStone/MileStone.module.scss";
import {
  MILESTONE_TYPE,
  MileStoneCard,
} from "../../../../../newUI/MileStoneCard/MileStoneCard";
import { Accordion } from "../../../../../newUI/Accordion/Accordion";

export const MilestoneTree = ({ tree }: any) => {
  return (
    <div className={cls.container}>
      {tree?.map((node: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode key={index} node={node} />
      ))}
    </div>
  );
};

const TreeNode = ({ node }: any) => {
  const dispatch = useAppDispatch();
  const hasChild = !!node?.data;
  const rootNode = node?.num?.length === 1;
  const open = useAppSelector(getProjectMilestoneOpenNode);
  function setToggleAccordion(key: any) {
    dispatch(setOpenNode(key));
  }

  return (
    <div>
      {node?.name && (
        <Accordion
          classname={classNames(rootNode && cls.accordionRoot)}
          text={node?.name}
          toogle={open[node?.num]}
          onToggle={() => setToggleAccordion(node?.num)}
        />
      )}
      {!hasChild && (
        <div className={cls.mileStone}>
          {node?.availablePercent && (
            <div className={cls.mileStone_price}>
              <p>{node.title}</p>
              <div className={cls.mileStone_price_block}>
                <p>Доступно</p>
                <span>{node.availablePercent} ₽</span>
              </div>
            </div>
          )}
          <MileStoneCard
            processName={node?.title}
            stages={node?.stages}
            type={MILESTONE_TYPE.PROJECT}
          />
        </div>
      )}
      {hasChild && open[node?.num] && node.data && (
        <div>
          <MilestoneTree tree={Object.values(node?.data)} />
        </div>
      )}
    </div>
  );
};
