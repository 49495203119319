import { RootState } from "../../../../../store";

export const getExpertiseRemarks = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.expertiseRemarks;

export const getExpertiseRemarksIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseRemarks;

export const getExpertiseOpenRemarks = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.openRemarks;

export const getExpertiseUpdateIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseUpdate;

export const getExpertiseCreateIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadExpertiseCreate;

export const getExpertisePartGroup = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.partGroup;

export const getExpertiseProjectPart = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.projectPart;

export const getExpertiseRemarkCreateIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadCreateRemark;

export const getExpertiseRemarkComment = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.comment;

export const getExpertiseRemarkCommentIsLoad = (state: RootState) =>
  state.projectProcessView.expertiseRemarks.isLoadCommentView;
