import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import classNames from "classnames";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import cls from "./BreadcrumbsMui.module.scss";

export type BreadcrumbItemType = {
  isActive: boolean;
  title: any;
  url: string;
};

export enum BreadcrumbsTheme {
  BLUE = "blue",
  WHITE = "white",
}

const sx = {
  marginBottom: "10px",
};

type BreadcrumbsProps = {
  items: BreadcrumbItemType[];
  variant?: string;
  separator?: string;
  theme?: BreadcrumbsTheme;
};

export const BreadcrumbsMui: React.FC<BreadcrumbsProps> = React.memo(
  ({
    variant = "",
    items = [],
    separator = "/",
    theme = BreadcrumbsTheme.WHITE,
  }) => {
    return (
      <Breadcrumbs sx={sx} aria-label="breadcrumb" separator={separator}>
        {items.map((item) => (
          <TextLink
            key={item.url}
            variant={classNames(
              cls.link,
              variant,
              !item.isActive && cls.notActive,
              cls[theme]
            )}
            url={item.url}
            redirect
            target={"_blank"}
          >
            {item.title}
          </TextLink>
        ))}
      </Breadcrumbs>
    );
  }
);
