import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import {
  ApiProjectProcessRemoveExecutor,
  ApiProjectProcessRemoveExecutorData,
} from "../../../../services/api/projectProcess/info";
import { pushError } from "../../../errorTrace";
import { RemoveExecutorData } from "../types/projectProcessRemoveExecutorTypes";
import { fetchProjectProcessViewDuplicate } from "./fetchProjectProcessView";

export const fetchProjectProcessRemoveExecutorData = createAsyncThunk<
  { processId: number; response: RemoveExecutorData },
  { processId: number; executorId: number }
>(
  "projectProcess/fetchProjectProcessRemoveExecutorData",
  async ({ processId, executorId }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessRemoveExecutorData(processId, executorId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return {
      processId,
      response,
    };
  }
);

export const fetchProjectProcessRemoveExecutor = createAsyncThunk<
  RemoveExecutorData,
  { processId: number; data: any }
>(
  "projectProcess/fetchProjectProcessRemoveExecutor",
  async ({ processId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessRemoveExecutor(processId, data),
      (data) => {
        dispatch(fetchProjectProcessViewDuplicate(processId));
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
