import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetConstructionType } from "../../../app/services/api/constructionType/constructionType";
import { SelectWithPopper } from "../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";

export type ConstructionTypeItem = {
  title: string;
  id: string;
};

export type ConstructionTypeProps = ComponentPropsType<{
  changeHandler?: (type: ConstructionTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
  paperOptions?: { [key: string]: any };
}>;

export const ConstructionType: React.FC<ConstructionTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  value,
  paperOptions,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<ConstructionTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<
    ConstructionTypeItem | undefined
  >(undefined);

  const onLoadTypes = () => {
    request(
      ApiGetConstructionType(),
      (data) => {
        setAllTypes(
          data.map((item: { id: number; name: string }) => ({
            title: item.name,
            id: item.id,
          }))
        );
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов строительства", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    onLoadTypes();
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => Number(item.id) === Number(value));
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  return (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      searchHandler={onSearch}
      options={allTypes}
      label={"Тип строительства"}
      variant={classNames(variant)}
      disableClear={true}
      fullWidth={true}
      paperOptions={paperOptions}
    />
  );
};
