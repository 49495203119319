import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
  Управление показом попапа формы отказа публикации раздела
 */

export const showEventUnPublishProcessReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    eventUnPublishTask: {
      isShow: true,
      data: {
        ...state.eventUnPublishTask.data,
      },
    },
  };
};

export const closeEventUnPublishProcessReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  eventUnPublishTask: {
    isShow: false,
    data: {
      ...state.eventUnPublishTask.data,
    },
  },
});

export type SetTaskIdActionType = {
  type: string;
  id: number;
  projectId: number;
};

export const setTaskIdReducer = (
  state: EventEmitterEventType,
  action: { payload: SetTaskIdActionType }
): EventEmitterEventType => {
  return {
    ...state,
    eventUnPublishTask: {
      isShow: false,
      data: {
        ...state.eventUnPublishTask.data,
        taskId: action.payload.id,
        projectId: action.payload.projectId,
      },
    },
  };
};

export const selectorEEUnPublishTaskIsShow = (state: StateType): boolean =>
  state.eventEmitter.eventUnPublishTask.isShow;
