import { combineReducers } from "@reduxjs/toolkit";
import dashboardHeader from "./header/index";
import dashboardProjects from "./projects/index";
import dashboardSections from "./sections/index";
import dashboardApplications from "./applications/index";
import dashboardDestinations from "./destinations";
import dashboardStaff from "./staff";
import dashboardTasks from "./tasksAndWorks";
import dashboardTreaties from "./treaties";
import dashboardWorkTasks from "./workTasks";

const dashboard = combineReducers({
  header: dashboardHeader.reducer,
  projects: dashboardProjects.reducer,
  sections: dashboardSections.reducer,
  applications: dashboardApplications.reducer,
  destinations: dashboardDestinations.reducer,
  staff: dashboardStaff.reducer,
  tasks: dashboardTasks.reducer,
  treaties: dashboardTreaties.reducer,
  workTasks: dashboardWorkTasks.reducer,
});

export default dashboard;
