import { RootState } from "../../../../store";

export const getProjectProcessWorkPublish = (state: RootState) =>
  state.projectProcessView.work.publish;

export const getProjectProcessWorkPublishIsLoad = (state: RootState) =>
  state.projectProcessView.work.isLoadPublishWork;

export const getProjectProcessWorkUpdatePublish = (state: RootState) =>
  state.projectProcessView.work.update;

export const getProjectProcessWorkPublishUpdateIsLoad = (state: RootState) =>
  state.projectProcessView.work.isLoadUpdatePublishWork;
export const getProjectProcessWorkPublishError422 = (state: RootState) =>
  state.projectProcessView.work.error422;
