import React, { memo, useEffect } from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessInfoDateLimit,
  getProjectProcessInfoDateStart,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
  getProjectProcessInfoProjectName,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import cls from "../ModalPublish/ModalPublish.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { fetchExpertiseUpdatePublish } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/services/fetchExpertiseUpdatePublish";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { setClearDocumentSuccess } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/slice/expertiseHeaderBtn";
import {
  getExpertisePublishUpdateError422,
  getExpertisePublishUpdateSuccess,
} from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/selectors/getExpertiseHeaderBtn";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

interface ModalUpdatePublishProps {
  closeModal: () => void;
}

const schema = Yup.object().shape({
  date_start: Yup.date().required("Необходимо заполнить «Дата начала»"),
  date_limit: Yup.date().required("Необходимо заполнить «Дата окончания»"),
});

export const ModalUpdatePublish = memo(
  ({ closeModal }: ModalUpdatePublishProps) => {
    const dispatch = useAppDispatch();
    const date_start = useAppSelector(getProjectProcessInfoDateStart);
    const date_limit = useAppSelector(getProjectProcessInfoDateLimit);
    const projectName = useAppSelector(getProjectProcessInfoProjectName);
    const processName = useAppSelector(getProjectProcessInfoName);
    const processId = useAppSelector(getProjectProcessInfoId);
    const error422Update = useAppSelector(getExpertisePublishUpdateError422);
    const publishUpdateSuccess = useAppSelector(
      getExpertisePublishUpdateSuccess
    );

    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;
    const {
      handleSubmit,
      control,
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    } = useForm<any>({
      defaultValues: {
        resolver: yupResolver(schema),
        date_start: start,
        date_limit: end,
      },
    });

    const formSubmitHandler = async (dataValues: any) => {
      const data = {
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
      };
      if (processId) {
        await dispatch(fetchExpertiseUpdatePublish({ processId, data }));
      }
    };

    useEffect(() => {
      if (error422Update) {
        parseUnprocessableFields(error422Update, setError);
      }
    }, [error422Update]);

    useEffect(() => {
      if (publishUpdateSuccess) {
        clearErrors();
        closeModal();
        dispatch(setClearDocumentSuccess());
      }
    }, [publishUpdateSuccess]);

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{`Изменение опубликованного раздела «${processName}» по проекту «${projectName}»`}</h1>
        <p>Стоимость, сроки и условия оплаты</p>
        <div className={cls.form_date}>
          <div className={cls.form_date_start}>
            <Controller
              control={control}
              name="date_start"
              render={() => (
                <DateField
                  variant={cls.formElement}
                  label="Начальный срок"
                  changeDateHandler={(date) => {
                    setValue("date_start", date);
                  }}
                  startDateProp={start}
                  error={!!errors.date_start}
                />
              )}
            />
            {errors.date_start && (
              <p className={cls.error}>{errors.date_start.message}</p>
            )}
          </div>
          <div className={cls.form_date_limit}>
            <Controller
              control={control}
              name="date_limit"
              render={() => (
                <DateField
                  variant={cls.formElement}
                  label="Конечный срок"
                  changeDateHandler={(date) => {
                    setValue("date_limit", date);
                  }}
                  startDateProp={end}
                  error={!!errors.date_limit}
                />
              )}
            />
            {errors.date_limit && (
              <p className={cls.error}>{errors.date_limit.message}</p>
            )}
          </div>
        </div>
        <CustomButton
          background={palette.green}
          width={160}
          type="submit"
          className={cls.form_submit}
        >
          Изменить
        </CustomButton>
      </form>
    );
  }
);
