import { useState, memo, useRef, useEffect } from "react";
import cn from "classnames";
import { IconButton } from "@mui/material";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styles from "./Description.module.scss";
import { palette } from "../../../../../styles/restyle";
import { Paragraph } from "../Paragraph/Paragraph";

const LINE_HEIGHT = 22;

type Props = {
  text: string;
  linesCount: 1 | 2 | 3;
};

export const Description = memo(({ text, linesCount }: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const textHeight = ref.current?.scrollHeight || 0;

    setShowButton(textHeight > linesCount * LINE_HEIGHT);
    setOpen(false);
  }, [ref.current, text]);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={styles.description}>
      <Paragraph
        ref={ref}
        className={cn(!isOpen && styles[`linesCount_${linesCount}`])}
      >
        {text}
      </Paragraph>
      {showButton && (
        <IconButton className={styles.toggleButton} onClick={handleToggle}>
          {isOpen ? (
            <ChevronUp color={palette.silverGrey} />
          ) : (
            <ChevronDown color={palette.persianGreen} />
          )}
        </IconButton>
      )}
    </div>
  );
});
