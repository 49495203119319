import React, { ReactNode, ChangeEvent, memo, useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  Popper,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import cls from "./PriceEditPopper.module.scss";
import { CheckSVG, NotApproveIcon } from "../../../newUI/SVG";
import { CircularIndeterminate } from "../../../UI/components/Project/Skeleton";
import { textFieldSX } from "../../../../styles/restyle";
import style from "../../../pages/Project/View/InitialData/style.module.scss";

interface PriceEditPopperProps {
  price: string;
  priceNode: ReactNode;
  canEditPrice: boolean;
  onEditPrice: (value: string) => void;
  isLoadPriceUpdate: boolean;
  classname?: string;
}

const iconBtnSx = {
  color: "#9DAFBD",
  "&:hover": {
    cursor: "pointer",
    color: "#FFFFFF",
  },
  padding: "0",
};

export const PriceEditPopper = memo(
  ({
    canEditPrice,
    onEditPrice,
    isLoadPriceUpdate,
    price,
    priceNode,
    classname,
  }: PriceEditPopperProps) => {
    const [valuePrice, setValuePrice] = useState(price);
    const [anchorEnd, setAnchorEnd] = React.useState<null | HTMLElement>(null);
    const handleClickEnd = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEnd(anchorEnd ? null : event.currentTarget);
    };
    const openEnd = Boolean(anchorEnd);
    const idEnd = openEnd ? "simple-popper" : undefined;

    const handleClickAwayEnd = () => {
      setAnchorEnd(null);
    };

    function onChangeValuePrice(event: ChangeEvent<HTMLInputElement>) {
      setValuePrice(event.target.value);
    }

    function handleEditPrice() {
      onEditPrice(valuePrice);
    }

    return (
      <div className={classNames(cls.container, classname)}>
        <ClickAwayListener onClickAway={handleClickAwayEnd}>
          <div>
            <div onClick={handleClickEnd}>{priceNode}</div>
            {openEnd && canEditPrice ? (
              // @ts-ignore
              <Popper
                id={idEnd}
                open={openEnd}
                anchorEl={anchorEnd}
                placement={"top"}
              >
                <form className={style.createData}>
                  <div className={style.createData_flex}>
                    <h3>Стоимость</h3>
                    <div onClick={() => setAnchorEnd(null)}>
                      <NotApproveIcon width="16" heigth="16" />
                    </div>
                  </div>
                  <TextField
                    className={cls.price}
                    {...textFieldSX}
                    type="number"
                    value={valuePrice}
                    onChange={onChangeValuePrice}
                  />
                  <div className={style.createData_btn}>
                    {isLoadPriceUpdate && (
                      <div className={style.createData_btn_load}>
                        <CircularIndeterminate />
                      </div>
                    )}
                  </div>
                  <div className={cls.btn_submit}>
                    <IconButton onClick={handleEditPrice} sx={iconBtnSx}>
                      <CheckSVG width={"16"} height={"16"} />
                    </IconButton>
                  </div>
                </form>
              </Popper>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
);
