import React, { memo, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fetchCompleteTask,
  fetchProjectProcessType,
  fetchProjectTaskOutTree,
  TaskValueType,
} from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import cls from "./ModalComplete.module.scss";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { getProjectTasks } from "../../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import { fetchProjectProcessOutTasks } from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import { setCheckInput } from "../../../../../../app/feature/ProjectView/projectView";

interface IModalCompleteProps {
  task: TaskValueType;
  code: string;
  setCompleteModalCloseMemo: () => void;
  projectId?: number;
  projectProcessID?: number;
  type: "project" | "process" | null;
}

type TInputs = {
  tasks: string[];
  comment: string;
  files: any;
};

const sx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

export const ModalComplete: React.FC<IModalCompleteProps> = memo(
  ({
    task,
    code,
    setCompleteModalCloseMemo,
    projectId,
    projectProcessID,
    type,
  }) => {
    const [check, setCheck] = useState(false);
    const { processId } = task.task.workflow_data;
    const taskId = task.task.id;

    const needsFiles = task.task.need_files;

    const { processType, isLoadProcessType } = useSelector(getProjectTasks);
    const dispatch = useDispatch();

    const [valueTask, setValueTask] = useState<string[]>([]);

    function setDefaultValueTask() {
      if (processType) {
        const defaultCode = Object.entries(processType).find(
          ([id, type]) => type === code
        );
        setValueTask(defaultCode ? [defaultCode[0]] : []);
      }
    }

    useEffect(() => {
      setDefaultValueTask();
    }, [processType]);

    useEffect(() => {
      if (projectId) {
        dispatch(fetchProjectProcessType({ projectId, processId }));
      }
    }, []);

    useEffect(() => {
      dispatch(setCheckInput(true));

      return () => {
        dispatch(setCheckInput(false));
      };
    }, []);

    const schema = Yup.object().shape({
      files:
        !needsFiles || task.files.length > 0
          ? Yup.array().notRequired()
          : Yup.array().required(
              "Выберите нужные файлы. Дождитесь окончания загрузки!"
            ),
      comment: Yup.string()
        .required("Необходимо заполнить комментарий")
        .max(200, "Комментарий не может содержать больше 200 символов"),
    });

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<TInputs>({
      defaultValues: {
        comment: task.task.comment,
      },
      resolver: yupResolver(schema),
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        status: 6,
        comment: dataValues.comment,
        upload_files: dataValues.files,
        forAll: check,
        toProcessesIds: dataValues.tasks,
      };
      await dispatch(fetchCompleteTask({ id: taskId, data }));
      if (projectId && type === "project") {
        await dispatch(fetchProjectTaskOutTree(projectId));
        setCompleteModalCloseMemo();
      }
      if (projectProcessID && type === "process") {
        await dispatch(fetchProjectProcessOutTasks(projectProcessID));
        setCompleteModalCloseMemo();
      }
    };

    const mapProjectProcessTypeList =
      Object.entries(processType).map(([id, type]) => (
        <MenuItem key={id} sx={sx} value={id}>
          {type}
        </MenuItem>
      )) || null;

    const labelExecutor = isLoadProcessType ? (
      <InputLabel id="type">...loading</InputLabel>
    ) : (
      <InputLabel id="type">Задания для разделов</InputLabel>
    );

    const handleChange = (event: SelectChangeEvent<typeof valueTask>) => {
      const {
        target: { value },
      } = event;
      setValueTask(typeof value === "string" ? value.split(",") : value);
    };

    return (
      <form className={cls.form} onSubmit={handleSubmit(formSubmitHandler)}>
        <h1>Разработать задание для {code}</h1>
        {needsFiles && (
          <>
            <FormControl {...textFieldSX} className={cls.form_tasks}>
              {labelExecutor}
              <Select
                {...register("tasks")}
                multiple
                labelId="tasks"
                label="Задания для разделов"
                value={valueTask}
                onChange={handleChange}
              >
                {mapProjectProcessTypeList}
              </Select>
            </FormControl>
            <label className={cls.form_label}>
              Выдавать всем новым смежникам
              <CustomCheckbox
                editing
                onChange={() => setCheck(!check)}
                checked={check}
              />
            </label>
            <div className={cls.form_uploader}>
              <Uploader
                canBeEdited
                defaultValue={task.files}
                autoUpload={!!task}
                identifier={task ? taskId.toString() : null}
                postUrlString={(id) =>
                  `${config.localDomain}/v1/task/add-file?id=${id}`
                }
                fileHandler={(data: any) => {
                  setValue("files", data);
                }}
                uploadAsActive
              />
              <p className={cls.error}>{errors?.files?.message}</p>
            </div>
          </>
        )}
        <div className={cls.form_comment}>
          <TextField
            {...register("comment")}
            {...textFieldSX}
            label="Комментарий"
            defaultValue={task.task.comment}
          />
          {errors.comment ? (
            <p className={cls.error}>{errors.comment.message}</p>
          ) : null}
        </div>
        <FormControl className={cls.form_submit}>
          <CustomButton background={palette.green} width={160} type="submit">
            Отправить
          </CustomButton>
        </FormControl>
      </form>
    );
  }
);
