import React from "react";
import { OrganisationTabsMenuType } from "../../../types/stateTypes/organisationType";
import {
  CreditCardSVG,
  PeopleFilledSVG,
  InfoSquareSVG,
  ColumnsGapSVG,
  ChecksGridSVG,
  WalletSVG,
  GearFillSVG,
  ReportSVG,
  CardList,
  SignPostSVG,
} from "../../newUI/SVG";

export const initialTabs: OrganisationTabsMenuType[] = [
  {
    title: "Информация",
    url: "info",
    redirect: false,
    icon: <InfoSquareSVG />,
  },
];

export const initialStaffTab: OrganisationTabsMenuType[] = [
  {
    title: "Сотрудники",
    url: "staff",
    redirect: false,
    icon: <PeopleFilledSVG />,
  },
];

export const initialListsTab: OrganisationTabsMenuType[] = [
  {
    title: "Опросные листы",
    url: "lists",
    redirect: false,
    icon: <CardList />,
  },
];

export const initialBankAccountTabs: OrganisationTabsMenuType[] = [
  {
    title: "Банк. счета",
    url: "accounts",
    redirect: false,
    icon: <CreditCardSVG />,
  },
];

export const initialEditTabsFirst: OrganisationTabsMenuType[] = [
  {
    title: "Заявки",
    url: "application",
    redirect: false,
    icon: <ColumnsGapSVG />,
  },
  {
    title: "Настройка",
    url: "settings",
    redirect: false,
    icon: <GearFillSVG />,
  },
  {
    title: "Контрольные точки",
    url: "milestone",
    redirect: false,
    icon: <SignPostSVG />,
  },
];

export const initialEditTabsSecond = {
  title: "Отчёт",
  url: "",
  redirect: true,
  icon: <ReportSVG />,
};

export const initialAdminTabs: OrganisationTabsMenuType[] = [
  {
    title: "Проверка",
    url: "task",
    redirect: false,
    icon: <ChecksGridSVG />,
  },
  // история изменений
];

export const initialWalletTab: OrganisationTabsMenuType = {
  title: "Кошелек",
  url: "",
  redirect: true,
  icon: <WalletSVG />,
};
