import React from "react";
import { QueryBuilder } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { ComponentPropsType } from "../../../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { TextLink } from "../../../TextLink/TextLink";
import { selectorAppTheme } from "../../../../../../app/feature/app/app";
import DefaultPhoto from "../../../../../../media/images/news_plug_main.png";
import { ucFirst } from "../../../../../../app/services/other";

export type PostItemProps = ComponentPropsType<{
  id: number;
  title: string;
  content: string;
  tags: { id: number; label: string }[];
  date_publication: string | null;
  photoUrl: string | null;
}>;

export const PostItem: React.FC<PostItemProps> = ({
  id,
  title = "",
  content = "",
  date_publication,
  photoUrl = "",
  tags,
}) => {
  const theme = useSelector(selectorAppTheme);
  return (
    <div className={style.postItem}>
      {date_publication ? (
        <div className={style.postDateWrapper}>
          <QueryBuilder fontSize={"small"} />
          <div className={style.postDatePublication}>{date_publication}</div>
        </div>
      ) : (
        <div className={style.notPublished}>Не опубликовано</div>
      )}
      <div className={style.postTitleWrapper}>
        <TextLink
          variant={style.postLink}
          url={`/post/view?id=${id}`}
          theme={theme}
        >
          {ucFirst(title)}
        </TextLink>
      </div>
      <div className={style.annotationWrapper}>{ReactHtmlParser(content)}</div>
      <div className={style.postImageWrapper}>
        <img
          className={style.postImage}
          src={photoUrl || DefaultPhoto}
          alt="новость"
        />
        <div className={style.postTag}>
          {tags.length > 0 ? tags[0].label : "Новости"}
        </div>
      </div>
    </div>
  );
};
