import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

export type WrapperProps = {
  variant?: string;
};

export const Wrapper: React.FC<WrapperProps> = ({ children, variant = "" }) => {
  return <div className={classNames(style.wrapper, variant)}>{children}</div>;
};
