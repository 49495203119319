import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
  CombinedState,
} from "@reduxjs/toolkit";
import { getUsersToRemoveFromTeam } from "../../../../components/pages/User/Dashboard/modals/utils";
import { createOrganisationStaff } from "../../organisationPage/creators";
import {
  getStaff,
  handleRequest,
  getUsersByTeamId,
  removeTeammate,
  sendInvitation,
} from "./thunks";

type TStaff<T> = {
  staff: T; // список сотрудников
  teams: T; // список команд
  requests: T; // список запросов на вступление в организацию
  addTeammate: T; // добавление в команду
  removeTeammate: T; // удаление из команды
  acceptToFirm: T; // принятие в фирму
};

export type TState = {
  data: Omit<TStaff<any>, "addTeammate" | "removeTeammate" | "acceptToFirm">;
  pending: TStaff<boolean>;
  error: TStaff<string | null>;
};

const getNewEmployee = (name: string) => ({
  name,
  skills: [],
  teams: [],
  firmAttorney: null,
  role: "Сотрудник предприятия",
  role_id: 7,
});

const initialState: TState = {
  data: {
    staff: [],
    requests: [],
    teams: [],
  },
  pending: {
    staff: false,
    requests: false,
    teams: false,
    addTeammate: false,
    removeTeammate: false,
    acceptToFirm: false,
  },
  error: {
    staff: null,
    requests: null,
    teams: null,
    addTeammate: null,
    removeTeammate: null,
    acceptToFirm: null,
  },
};

const dashboardStaff = createSlice({
  name: "dashboardStaff",
  initialState,
  reducers: {
    clearStaff() {
      return initialState;
    },
    clearTeams(state) {
      state.data.teams = [];
      state.pending.teams = false;
      state.error.teams = null;
    },
    getUsersForDelete(state, action) {
      const { staff, teamId } = action.payload;
      state.data.teams = getUsersToRemoveFromTeam(staff, teamId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getStaff), (state) => {
        state.pending.staff = true;
      })
      .addMatcher(isFulfilled(getStaff), (state, action) => {
        state.pending.staff = false;
        state.data.staff = createOrganisationStaff(action.payload.data);
        state.data.requests = action.payload.requests;
      })
      .addMatcher(isRejected(getStaff), (state, action) => {
        state.pending.staff = false;
        state.error.staff = action.payload as string;
      })
      .addMatcher(isPending(handleRequest), (state) => {
        state.pending.acceptToFirm = true;
      })
      .addMatcher(isFulfilled(handleRequest), (state, action) => {
        const id = action.payload;
        const { accepted } = action.meta.arg;

        state.pending.acceptToFirm = false;

        // удаление записи из списка запросов на вступление
        state.data.requests = state.data.requests.filter(
          (request: any) => request.id !== id
        );

        if (!accepted) {
          // если был отказ - выходим
          return;
        }

        // добавление пользователя в список сотрудников
        const newStaff = state.data.requests.find(
          (request: any) => request.id === id
        );
        const newEmplyee = getNewEmployee(newStaff.name);
        state.data.staff.push(newEmplyee);
      })
      .addMatcher(isRejected(handleRequest), (state, action) => {
        state.pending.acceptToFirm = false;
        state.error.acceptToFirm = action.payload as string;
      })
      .addMatcher(isPending(getUsersByTeamId), (state) => {
        state.pending.teams = true;
      })
      .addMatcher(isFulfilled(getUsersByTeamId), (state, action) => {
        state.pending.teams = false;
        state.data.teams = action.payload;
      })
      .addMatcher(isPending(sendInvitation), (state) => {
        state.pending.addTeammate = true;
      })
      .addMatcher(isFulfilled(sendInvitation), (state) => {
        state.pending.addTeammate = false;
      })
      .addMatcher(isPending(removeTeammate), (state) => {
        state.pending.removeTeammate = true;
      })
      .addMatcher(isRejected(sendInvitation), (state) => {
        state.pending.addTeammate = false;
      })
      .addMatcher(isRejected(removeTeammate), (state) => {
        state.pending.removeTeammate = false;
      });
  },
});

export const { clearStaff, clearTeams, getUsersForDelete } =
  dashboardStaff.actions;

export const getDashboardStaff = (state: CombinedState<any>) =>
  state.dashboard.staff;

export default dashboardStaff;
