import React from "react";
import { FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import {
  fetchNotAcceptTask,
  fetchProjectTaskInTree,
} from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import style from "./ModalNotAccept.module.scss";
import { fetchProjectProcessInTasks } from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";

const schema = Yup.object().shape({
  comment: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

type TInputs = {
  comment: string;
};

interface ModalNotAcceptProps {
  id: number;
  content: string;
  name: string;
  projectID: number | undefined;
  processID: number | undefined;
  type: "project" | "process";
}
export const ModalNotAccept: React.FC<ModalNotAcceptProps> = ({
  id,
  content,
  name,
  projectID,
  processID,
  type,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: TInputs) => {
    const data = {
      comment: dataValues.comment,
      status: 5,
    };
    if (projectID && type === "project") {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectTaskInTree(projectID));
    }
    if (processID && type === "process") {
      await dispatch(fetchNotAcceptTask({ id, data }));
      await dispatch(fetchProjectProcessInTasks(processID));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmitHandler)} className={style.modal}>
        <h1>{name}</h1>
        <TextField
          autoComplete="off"
          {...textFieldSX}
          label="Комментарий исполнителя"
          defaultValue={content}
          variant="outlined"
          disabled
        />
        <TextField
          {...textFieldSX}
          {...register("comment")}
          multiline
          minRows={2}
          label="Замечания"
          placeholder="Введите текст"
        />
        {errors.comment ? (
          <p className={style.modal_error}>{errors.comment.message}</p>
        ) : null}
        <FormControl className={style.modal_btn}>
          <CustomButton type="submit" width={160} background={palette.red}>
            Отказать
          </CustomButton>
        </FormControl>
      </form>
    </div>
  );
};
