import { Route, Routes } from "react-router-dom";
import { NewsList } from "../components/pages/News/NewsList";
import { NewsPage } from "../components/pages/News/NewsPage";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";

export const BlogRoutes = () => (
  <Routes>
    <Route path="/blog">
      <Route
        index
        element={
          <IndexPageTemplate>
            <NewsPage />
          </IndexPageTemplate>
        }
      />
      <Route
        path={`section/:params`}
        element={
          <IndexPageTemplate>
            <NewsList />
          </IndexPageTemplate>
        }
      />
    </Route>
  </Routes>
);
