import { createReducer, Dispatch } from "@reduxjs/toolkit";
import { ApiGetGanttData } from "../../../services/api/projectGantt/projectGantt";
import { StateType } from "../../../initialState";
import { isBadStatusRequest } from "../../../services/api/api";

export type ProjectStateGanttType = any;

export const projectGanttInitialState: ProjectStateGanttType = {};

export const projectGanttReducer = createReducer(
  projectGanttInitialState,
  (builder) => {
    builder.addCase("SET_GANTT_DATA", (state, action: any) => {
      return {
        ...state,
        data: action.payload.data,
      };
    });
  }
);

export const fetchGanttData = (id: string) => (dispatch: Dispatch) => {
  ApiGetGanttData(id)
    .then((x) => x.json())
    .then((x) => {
      if (isBadStatusRequest(x.code)) {
        throw new Error(x.message);
      } else {
        return x;
      }
    })
    .then((x) => {
      dispatch({
        type: "SET_GANTT_DATA",
        payload: {
          data: x,
        },
      });
    });
};

export const projectGantt = projectGanttReducer;

export const selectorGanttData = (state: StateType) => state.projectGantt;
