import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  fetchProjectProcessInTasks,
  fetchProjectProcessOutTasks,
} from "../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getProjectProcessInTree } from "../../../../app/feature/ProjectProcessView/Tasks/selectors/getProjectProcessInTree";
import { getProjectProcessOutTree } from "../../../../app/feature/ProjectProcessView/Tasks/selectors/getProjectProcessOutTree";
import cls from "./Tasks.module.scss";
import { HorizontalTabsPanel } from "../../../newUI/HorizontalTabsPanel/HorizontalTabsPanel";
import { SkeletonTask } from "../../../UI/components/Project/Skeleton";
import TreeTasks from "../../Project/View/Tasks/TreeTasks";
import { getProjectProcessTaskIsLoad } from "../../../../app/feature/ProjectProcessView/Tasks/selectors/getProjectProcessTaskIsLoad";
import { fetchProjectProcessView } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getTabShowProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { setTaskActiveTab } from "../../../../app/feature/ProjectProcessView/Tasks/slice/projectProcessTasks";
import { getProjectProcessTasksActiveTab } from "../../../../app/feature/ProjectProcessView/Tasks/selectors/getProjectProcessTasksActiveTab";
import { OutTaskList } from "./OutTaskList/OutTaskList";
import style from "../../Project/View/Tasks/style.module.scss";
import Modal from "../../../newUI/Modal/Modal";
import { ModalRequestTask } from "./ModalRequestTask/ModalRequestTask";
import { iconBtnSx } from "../../../../styles/restyle";
import { AddFile } from "../../../newUI/SVG";
import { getProjectProcessRequestTaskIsLoad } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequestTask";
import { getCanProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import {
  TModalData,
  modalInitialData,
} from "../../Project/View/CheckTasks/CheckTaskCard";
import { ModalWrapper } from "../../User/Edit/PersonalDataChange/PersonalDataChange";
import ModalComment from "../../Project/View/Tasks/ModalComment/ModalComment";

export const Tasks = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const activeTab = useAppSelector(getProjectProcessTasksActiveTab);
  const isLoadRequestTask = useAppSelector(getProjectProcessRequestTaskIsLoad);
  const can = useAppSelector(getCanProcessInfo);
  const infoId = useAppSelector(getProjectProcessInfoId);
  const projectId = useAppSelector(getProjectProcessInfoProjectId);
  const inTasks = useAppSelector(getProjectProcessInTree);
  const outTasks = useAppSelector(getProjectProcessOutTree);
  const tabShow = useAppSelector(getTabShowProcessInfo);
  const isLoad = useAppSelector(getProjectProcessTaskIsLoad);

  const [modalData, setModalData] = useState<TModalData>(modalInitialData);

  const [modalRequestTask, setModalRequestTask] = useState(false);

  const closeModalRequestTask = useCallback(() => {
    setModalRequestTask(false);
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, []);

  const tabs = [
    {
      id: 1,
      label: "Входящие задания",
      incomplete: tabShow?.inputTasks.dot,
    },
    {
      id: 2,
      label: "Исходящие задания",
      incomplete: tabShow?.outputTasks.dot,
    },
  ];

  const setActiveTabMemo = useCallback((tabId: number | string) => {
    dispatch(setTaskActiveTab(tabId));
  }, []);

  useEffect(() => {
    if (activeTab === 1 && infoId) {
      dispatch(fetchProjectProcessInTasks(infoId));
    }
    if (activeTab === 2 && infoId) {
      dispatch(fetchProjectProcessOutTasks(infoId));
    }
  }, [activeTab, infoId]);

  const components = [
    {
      id: 1,
      component: (
        <>
          {can?.requestOutTasks && (
            <div className={style.headerBtn}>
              <Modal
                active={modalRequestTask}
                setActive={setModalRequestTask}
                isLoad={isLoadRequestTask}
                activeClass={style.modal}
              >
                <ModalRequestTask closeModal={closeModalRequestTask} />
              </Modal>
              <span>Запросить задание</span>
              <IconButton
                sx={iconBtnSx}
                title="Запросить задание"
                onClick={() => setModalRequestTask(true)}
              >
                <AddFile width="24" height="24" />
              </IconButton>
            </div>
          )}
          <TreeTasks
            projectId={projectId}
            processId={infoId}
            key={1}
            id={1}
            data={inTasks || []}
            type={"process"}
            setModalData={setModalData}
          />
        </>
      ),
    },
    {
      id: 2,
      component: (
        <OutTaskList
          projectId={projectId}
          processId={infoId}
          key={2}
          tasks={outTasks || []}
          type={"process"}
          setActiveTabMemo={setActiveTabMemo}
          setModalData={setModalData}
        />
      ),
    },
  ];

  return (
    <div className={cls.wrapper}>
      <HorizontalTabsPanel
        tabs={tabs}
        components={components}
        activeTab={activeTab}
        setActiveTab={setActiveTabMemo}
        isLoad={isLoad}
        skeleton={<SkeletonTask />}
      />
      <ModalWrapper
        show={modalData.open}
        handleCloseModal={() => setModalData(modalInitialData)}
        head={<h1 className={style.commentModalHeader}>Комментарии</h1>}
      >
        <ModalComment name="" taskId={modalData.id} />
      </ModalWrapper>
    </div>
  );
});
