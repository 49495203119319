export const setPositionScrollRoom = (roomId: string, positionY: string) => {
  const currentPositions =
    JSON.parse(localStorage.getItem("chatPositions")!) || {};
  const newPositions = {
    ...currentPositions,
    [roomId]: positionY,
  };
  localStorage.setItem("chatPositions", JSON.stringify(newPositions));
};

export const getPositionScrollRoom = (roomId: string) => {
  const rooms = JSON.parse(localStorage.getItem("chatPositions")!);
  return rooms ? rooms[roomId] : "";
};
