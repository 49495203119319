import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

const expands = `designType,
  processPublishedCost,
  expertise,
  projectProcesses,
  countProcesses,
  gipAccess.userType,
  gipProcess`;

export const ApiGetProjectsPage = (paramsString: string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/index${paramsString}&expand=${expands}`,
    {}
  );
};
