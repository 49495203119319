import { MouseEvent, ReactNode } from "react";
import { IconButton } from "@mui/material";
import styles from "./AddButton.module.scss";
import { iconBtnSx } from "../../../../../styles/restyle";
import { AddFile } from "../../../../newUI/SVG";

type Props = {
  children: ReactNode;
  prompt?: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

const AddButton = ({ children, onClick, prompt = "" }: Props) => {
  return (
    <div className={styles.addButton}>
      <span>{children}</span>
      <IconButton sx={iconBtnSx} title={prompt} onClick={onClick}>
        <AddFile width="24" height="24" />
      </IconButton>
    </div>
  );
};

export default AddButton;
