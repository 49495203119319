import {
  KanbanApi,
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "src/FSD/entities/kanban";
import { ColumnShift } from "./useKanbanDnD";

export const inWorkToAllTasks = ({
  dispatch,
  sourceColumnId,
  destinationColumnId,
  sourceRowId,
  destinationRowId,
  allTasks,
  rowTasks,
  result,
  groupBy,
}: ColumnShift) => {
  // из "В работе" в "Все задачи"
  if (
    sourceColumnId === KanbanStatuses[KanbanColumns.IN_WORK] &&
    destinationColumnId === KanbanStatuses[KanbanColumns.ALL_TASKS]
  ) {
    // получаем задачи из "Все задачи" и "В работе"
    const sourceColumnTasks = [
      ...rowTasks[sourceRowId].columns[sourceColumnId],
    ];
    const destinationColumnTasks = [...allTasks];

    // удаляем задачу из колонки-истока "Выполнено", добавляем в колонку-сток "В работе"
    const [reorderedTask] = sourceColumnTasks.splice(result.source.index, 1);
    destinationColumnTasks.splice(result.destination!.index, 0, reorderedTask);

    // обновляем колонки для группы
    const updatedTasks = {
      ...rowTasks[sourceRowId].columns,
      [sourceColumnId]: sourceColumnTasks,
    };

    // запрос на возвращение задачи в backlog
    dispatch(KanbanApi.deleteTask(reorderedTask.id));

    // записываем обновленную колонку "Все задачи" в state
    dispatch(KanbanModel.setAllTasks(destinationColumnTasks));

    // записываем обновленные колонки группы в state
    dispatch(
      KanbanModel.setRowTasks({
        rowId: sourceRowId,
        updatedTasks,
      })
    );
  }
};
