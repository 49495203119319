import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Application.module.scss";

type Props = {
  children: ReactNode;
  prompt?: string;
  className?: string;
};

export const ApplicationSegment = ({ children, prompt, className }: Props) => {
  return (
    <div className={cn(styles.card__segment, className)}>
      <p className={styles.card__segment__range}>{children}</p>
      {prompt && <p className={styles.card__segment__prompt}>{prompt}</p>}
    </div>
  );
};
