import React, { forwardRef, useImperativeHandle, Ref, ReactNode } from "react";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { VerticalThreeDots } from "src/components/newUI/SVG";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import style from "./style.module.scss";

interface ThreeDotButtonProps {
  children: ReactNode;
  className?: string;
  color?: "success" | "white";
  disabled?: boolean;
}
// Todo Желательно это все вынести в общие файлы
declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }

  interface PaletteOptions {
    white?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

const theme = createTheme({
  palette: {
    white: {
      main: "#FFFFFF",
    },
    success: {
      main: "#0AA699",
    },
  },
});

export interface RefType {
  handleClose: () => void;
}

export const ThreeDotButton = forwardRef(
  (
    { children, color = "white", className, disabled }: ThreeDotButtonProps,
    ref: Ref<RefType>
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sx = {
      padding: 0,
      transition: "0.3s ease-in-out 0s!important",
      "&:hover": {
        transform: "scale(1.2)",
      },
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    return (
      <ThemeProvider theme={theme}>
        <div className={classNames(style.root, className)}>
          <IconButton
            sx={sx}
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disabled={disabled}
            color={color}
          >
            <VerticalThreeDots />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {children}
          </Menu>
        </div>
      </ThemeProvider>
    );
  }
);
