import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoisLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessView } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getTabShowProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { fetchProjectProcessApplications } from "src/app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import {
  SourceData as StdSourceData,
  Documents as StdDocuments,
  ExchangeTasks as StdExchangeTasks,
  ExecutionTasks as StdExecutionTasks,
  Expertise as StdExpertise,
  CheckTasks as StdCheckTasks,
  Materials as StdMaterials,
  Works as StdWorks,
  Applications as StdApplications,
  Milestone as StdMilestone,
  Info as StdInfo,
  Removed as StdRemoved,
} from "src/FSD/widgets/v2/projectProcess/std/index";
import TitleTabsHamburger from "src/FSD/widgets/v2/projectProcess/TitleTabsHamburger";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { Devices } from "src/FSD/widgets/v2/TabsPanel/lib/constants/devices";
import { useProjectProcessView } from "../lib/hooks/useProjectProcessView";
import styles from "./ProjectProcessView.module.scss";

export const ProjectProcessView = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { device, breadcrumbs } = useProjectProcessView({ id: +params.id! });
  const [currentTab, setCurrentTab] = useState("");
  const [tabs, setTabs] = useState<any>([]);
  const infoData = useAppSelector(getProjectProcessInfoData);
  const isLoad = useAppSelector(getProjectProcessInfoisLoad);
  const tabShow = useAppSelector(getTabShowProcessInfo);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
      dispatch(fetchProjectProcessApplications(+params.id));
    }
    setCurrentTab("info");
  }, [params.id]);

  const isExpertise = infoData?.projectPart.type.key === 4;

  useEffect(() => {
    if (tabShow) {
      setTabs(infoData?.tabs);
    }
  }, [tabShow]);

  const stdComponents = useMemo(() => {
    if (!infoData) {
      return {};
    }

    return {
      info: <StdInfo />,
      sourceFiles: <StdSourceData />,
      documents: <StdDocuments />,
      exchangeTask: <StdExchangeTasks />,
      executionTask: <StdExecutionTasks />,
      expertiseInTasks: <StdExpertise />,
      checkTasks: <StdCheckTasks />,
      materials: <StdMaterials />,
      works: <StdWorks />,
      applications: (
        <StdApplications
          projectProcessId={Number(params.id)}
          secure={infoData.secure}
        />
      ),
      removed: <StdRemoved projectProcessId={Number(params.id)} />,
      stages: <StdMilestone />,
    };
  }, [infoData]);

  const expertiseComponents = useMemo(() => {
    if (!infoData) {
      return {};
    }
    return {
      info: <StdInfo />,
      applications: (
        <StdApplications
          projectProcessId={Number(params.id)}
          secure={infoData.secure}
        />
      ),
    };
  }, [infoData]);

  return (
    <div className={styles.wrapper}>
      <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />
        {isLoad || !infoData ? (
          <div className={styles.skeleton}>
            {!(device === Devices.MOBILE) && (
              <Skeleton.List
                height={995}
                width={device === Devices.TABLET ? "106px" : "445px"}
                count={1}
              />
            )}
            <Skeleton.Grid height={300} count={2} />
          </div>
        ) : (
          <TabsPanel
            subTitle={infoData!.status.value}
            title={infoData!.projectPart.name}
            tabsList={tabs}
            componentsList={isExpertise ? expertiseComponents : stdComponents}
            device={device}
            actions={<TitleTabsHamburger />}
          />
        )}
      </PageWrapper>
    </div>
  );
};
