import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import cls from "./ExpertiseHeaderBtn.module.scss";
import { iconBtnSx, palette } from "../../../../../styles/restyle";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalPublish } from "./ModalPublish/ModalPublish";
import { ModalUpdatePublish } from "./ModalUpdatePublish/ModalUpdatePublish";
import { AddFile } from "../../../../newUI/SVG";
import { useAppSelector } from "../../../../../app/store";
import {
  getExpertiseCreateConclusionIsLoad,
  getExpertiseCreateDocumentIsLoad,
  getExpertisePublishIsLoad,
  getExpertiseUpdatePublishIsLoad,
} from "../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/selectors/getExpertiseHeaderBtn";
import { ModalCreateDocument } from "./ModalCreateDocument/ModalCreateDocument";
import { getCanProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { ModalConclusionCreate } from "./ModalConclusionCreate/ModalConclusionCreate";

export const ExpertiseHeaderBtn = memo(
  ({ currentTab }: { currentTab: string }) => {
    const [modalPublish, setModalPublish] = useState(false);
    const [modalUpdatePublish, setModalUpdatePublish] = useState(false);
    const [modalCreateDocument, setModalCreateDocument] = useState(false);
    const [modalConclusionCreate, setModalConclusionCreate] = useState(false);
    const isLoadUpdatePublish = useAppSelector(getExpertiseUpdatePublishIsLoad);
    const isLoadPublish = useAppSelector(getExpertisePublishIsLoad);
    const isLoadCreateConclusion = useAppSelector(
      getExpertiseCreateConclusionIsLoad
    );
    const isLoadCreateDocument = useAppSelector(
      getExpertiseCreateDocumentIsLoad
    );
    const can = useAppSelector(getCanProcessInfo);

    const closeModalConclusionCreate = useCallback(() => {
      setModalConclusionCreate(false);
    }, []);

    const closeModalPublish = useCallback(() => {
      setModalPublish(false);
    }, []);

    const closeModalPublishUpdate = useCallback(() => {
      setModalUpdatePublish(false);
    }, []);

    const closeModalCreateDocument = useCallback(() => {
      setModalCreateDocument(false);
    }, []);

    return (
      <div className={cls.btn}>
        {can?.publish && (
          <Modal
            active={modalPublish}
            setActive={setModalPublish}
            isLoad={isLoadPublish}
            activeClass={cls.modal}
          >
            <ModalPublish closeModal={closeModalPublish} />
          </Modal>
        )}
        {can?.update && (
          <Modal
            active={modalUpdatePublish}
            setActive={setModalUpdatePublish}
            isLoad={isLoadUpdatePublish}
            activeClass={cls.modal}
          >
            <ModalUpdatePublish closeModal={closeModalPublishUpdate} />
          </Modal>
        )}
        {currentTab === "expertiseDocuments" && can?.edit && (
          <Modal
            active={modalCreateDocument}
            setActive={setModalCreateDocument}
            activeClass={cls.modal}
            isLoad={isLoadCreateDocument}
          >
            <ModalCreateDocument closeModal={closeModalCreateDocument} />
          </Modal>
        )}
        {currentTab === "expertiseConclusion" && can?.edit && (
          <Modal
            active={modalConclusionCreate}
            setActive={setModalConclusionCreate}
            isLoad={isLoadCreateConclusion}
            activeClass={cls.modal}
          >
            <ModalConclusionCreate closeModal={closeModalConclusionCreate} />
          </Modal>
        )}
        {can?.publish && (
          <CustomButton
            width={200}
            background={palette.green}
            onClick={() => setModalPublish(true)}
          >
            Взять в работу
          </CustomButton>
        )}
        {can?.update && currentTab !== "expertiseConclusion" && (
          <CustomButton
            width={160}
            background={palette.green}
            onClick={() => setModalUpdatePublish(true)}
          >
            Редактировать
          </CustomButton>
        )}
        {currentTab === "expertiseDocuments" && can?.edit && (
          <IconButton
            sx={iconBtnSx}
            onClick={() => setModalCreateDocument(true)}
          >
            <AddFile />
          </IconButton>
        )}
        {currentTab === "expertiseConclusion" && can?.execute && (
          <CustomButton
            width={160}
            background={palette.green}
            onClick={() => setModalConclusionCreate(true)}
          >
            Выполнить
          </CustomButton>
        )}
      </div>
    );
  }
);
