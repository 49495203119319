import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import moment from "moment/moment";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import cls from "./ModalStop.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { fetchProjectProcessStopped } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessStopped";
import {
  getProjectProcessStopped,
  getProjectProcessStoppedIsLoad,
} from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessStopped";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { fetchProjectProcessCreateStop } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessCreateStop";
import { fetchProjectProcessTaskComplete } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessTaskComplete";

const schema = Yup.object().shape({
  content: Yup.string().required("Необходимо заполнить поле «Причина»."),
});

type TInput = {
  content: string;
};

interface ModalStopProps {
  modalClose: (state: boolean) => void;
}

export const ModalStop: React.FC<ModalStopProps> = memo(({ modalClose }) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const getStoppedIsLoad = useAppSelector(getProjectProcessStoppedIsLoad);
  const stopped = useAppSelector(getProjectProcessStopped);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessStopped(+params.id));
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TInput>({
    resolver: yupResolver(schema),
  });
  const formSubmitHandler = async (dataValue: TInput) => {
    const data = {
      content: dataValue.content,
    };
    if (params.id) {
      await dispatch(fetchProjectProcessCreateStop({ id: +params.id, data }));
    }
    modalClose(false);
  };
  const completeHandler = (id: number) => {
    dispatch(fetchProjectProcessTaskComplete(id));
  };

  if (getStoppedIsLoad) {
    return <ModalPreloader />;
  }

  if (stopped) {
    return (
      <form className={cls.form} onSubmit={handleSubmit(formSubmitHandler)}>
        <h1>Приостановка выполнения раздела</h1>
        <div className={cls.grid}>
          <div>Причина остановки</div>
          <div>Дата создания</div>
          <div>Дата закрытия</div>
          <div />
          {stopped?.map((stop) => (
            <>
              <div className={classNames(!stop.checked && cls.stop)}>
                {stop.content}
              </div>
              <div className={classNames(!stop.checked && cls.stop)}>
                {stop.date_create
                  ? moment(stop.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
                      "DD.MM.YYYY"
                    )
                  : null}
              </div>
              <div>
                {stop.date_complete
                  ? moment(stop.date_complete, "DD.MM.YYYY HH:mm:ss Z").format(
                      "DD.MM.YYYY"
                    )
                  : null}
              </div>
              <div>
                {!stop?.checked && (
                  <CustomButton
                    width={100}
                    background={palette.green}
                    onClick={() => completeHandler(stop.id)}
                  >
                    Закрыть
                  </CustomButton>
                )}
              </div>
            </>
          ))}
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          placeholder="Введите причину"
          label="Причина"
          className={cls.form_content}
          {...register("content")}
          error={!!errors.content}
          helperText={errors.content?.message}
        />
        <CustomButton
          background={palette.blue}
          width={160}
          type="submit"
          className={cls.form_submit}
        >
          Отправить
        </CustomButton>
      </form>
    );
  }
  return <></>;
});
