import { useState, useRef } from "react";
import { TextField, debounce } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Path } from "react-hook-form";
import cn from "classnames";
import { getLocationOptions } from "../../../../../../app/feature/profileReducer/thunks";
import style from "./style.module.scss";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { textFieldSX } from "../../../../../../styles/restyle";
import useNonInitialEffect from "../../../../../hooks/useNonInitialEffect";

type Props<TFormValues> = {
  name: Path<TFormValues>;
  label: string;
  register: any;
  defaultValue: string | null;
  trigger: any;
  setValue: any;
  hasError?: boolean;
  helperText?: string | null;
  disabled?: boolean;
  targetKey?: string;
  regionCode: number;
  changeHandler?: (value: Record<string, any>) => void;
};

const LocationSearchField = <TFormValues extends Record<string, any>>({
  name,
  label,
  register,
  defaultValue,
  trigger,
  setValue,
  hasError,
  helperText,
  disabled,
  regionCode = 1,
  changeHandler = () => {},
}: Props<TFormValues>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const outsideRef = useRef(null);
  useClickOutside(outsideRef, () => {
    setShow(false);
  });

  const searchHandler = debounce(async (location) => {
    setShow(false);
    setIsLoading(true);
    const response = await getLocationOptions(regionCode, location);

    if (Array.isArray(response)) {
      // @ts-ignore
      setOptions(response);
    }
    setIsLoading(false);

    if (location) {
      setShow(true);
    }
  }, 500);

  useNonInitialEffect(() => {
    searchHandler("");
  }, [regionCode]);

  const fieldName = String(name);

  return (
    <div className={style.fieldContainer}>
      <TextField
        defaultValue={defaultValue}
        label={label}
        {...textFieldSX}
        {...register(fieldName)}
        onChange={async (e) => {
          const { value } = e.currentTarget;
          await searchHandler(value);

          setValue(fieldName, value);
        }}
        onFocus={() => {
          setShow(true);
        }}
        InputProps={{
          endAdornment: (
            <div className={style.loaderContainer}>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </div>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        error={hasError}
        helperText={helperText}
      />
      {show && !isLoading && (
        <div
          className={cn(style.optionsContainer, {
            [style.noBorder]: options.length === 0,
          })}
          ref={outsideRef}
        >
          {options.map((option: any) => {
            return (
              <button
                className={style.option}
                key={option.bik}
                type="button"
                onClick={async () => {
                  setShow(false);
                  setValue(fieldName, option.name);
                  await trigger(name);

                  if (changeHandler) {
                    changeHandler(option);
                  }
                }}
              >
                {option.name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LocationSearchField;
