import React from "react";
import classNames from "classnames";
import { Search } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import style from "../style.module.scss";

export type FilterFieldType = ComponentPropsType<{
  value: string;
  changeHandler: (str: string) => void;
}>;

export const FilterField: React.FC<FilterFieldType> = ({
  value = "",
  theme = "dark",
  changeHandler = () => {},
}) => {
  return (
    <div className={classNames(style.inputWrapper, style[theme])}>
      <input
        type="text"
        autoComplete={"off"}
        placeholder={"Имя пользователя"}
        value={value}
        onChange={(e) => {
          changeHandler(e.currentTarget.value);
        }}
        className={classNames(style.field, style[theme], style.searchField)}
      />
      <SvgIcon component={Search} className={style.iconSearch} />
    </div>
  );
};
