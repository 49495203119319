import { useState, useMemo, useEffect, useRef } from "react";
import cn from "classnames";
import { IconButton, Skeleton } from "@mui/material";
import { ArrowBack, Menu } from "@mui/icons-material";
import {
  FolderSymlink,
  Kanban as KanbanIcon,
  Sliders,
} from "react-bootstrap-icons";
// import { useSearchParams } from "react-router-dom";
import { HomeSVG, PenSVG, GridSVG, SectionsSVG, LadderSVG } from "./svgs";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardHeader,
  TUserType,
} from "../../../../../app/feature/dashboard/header/index";
import {
  getCounters,
  getAccounts,
  getUserTypes,
  getSubscription,
} from "../../../../../app/feature/dashboard/header/thunks";
import NavButton from "./NavButton";
import UserFirms from "./UserFirms";
import {
  setUserType,
  getDashboardDestinations,
  setReportLinks,
  setTab,
  setProjectId,
  setWorkId,
} from "../../../../../app/feature/dashboard/destinations";
import { getUserId } from "../../../../../app/services/auth/auth";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import {
  clearProjectsData,
  setProjectStatus,
} from "../../../../../app/feature/dashboard/projects";
import useClickOutside from "../../../../hooks/useClickOutside";

const counterMessages = {
  tasks: ["Задачи", "просроченные", "outDateTasks"],
  treaties: ["Документов", "на подписании", "notSignDocuments"],
  sections: ["Заявки", "на рассмотрении", "applications"],
};

const tabCounterMessages: Record<string, string[]> = {
  "project->section": counterMessages.sections,
  "project->treaty": counterMessages.treaties,
  "section->application": counterMessages.sections,
  "section->tasksAndWorks": counterMessages.tasks,
  "section->treaty": counterMessages.treaties,
  "work->application": counterMessages.sections,
  "work->tasks": counterMessages.tasks,
  "work->treaty": counterMessages.treaties,
  "staff->groups": counterMessages.sections,
};

const getTabCounterMessages = (tab: string) =>
  tabCounterMessages[tab] ?? [0, 0, ""];

export type TFirm = {
  id: number;
  title: string;
  subtitle: string;
  firmId: number;
  count: number;
  isEntity: false;
};

const defaultfirm: TFirm = {
  id: 0,
  title: "",
  firmId: 0,
  subtitle: "",
  count: 0,
  isEntity: false,
};

const Header = () => {
  const isMobile = useIsMobile(1100);

  const dispatch = useAppDispatch();

  const respRef = useRef<HTMLDivElement>(null);
  const userId = getUserId();

  const { projectId, sectionId } = useAppSelector(getDashboardDestinations);

  const [showResponsibilities, setShowResponsibilities] =
    useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const [searchParams] = useSearchParams();
  const { userType, tab, dashboardCounts } = useAppSelector(
    getDashboardDestinations
  );
  const { data, pending, counters, subscription, subscriptionPending } =
    useAppSelector(getDashboardHeader);

  const { countApplication, countUnSignedDocs, countTasks } = dashboardCounts;

  const isKanban = tab === "kanban";

  const handleChangeResponsibility = (id: number) => {
    if (pending || id === userType) {
      return;
    }

    dispatch(clearProjectsData());

    dispatch(setUserType(id));
    dispatch(setTab("project->section"));
    dispatch(setProjectId(0));
    dispatch(setProjectStatus(id));

    const reportLinks = data.find((userType: any) => userType.id === id) ?? [];
    dispatch(setReportLinks(reportLinks));

    setShowResponsibilities(false);
  };

  const handleGoBackwars = () => {
    if (tab === "work->tasks") {
      dispatch(setTab("section->tasksAndWorks"));
      dispatch(setWorkId(0));
      return;
    }

    if (tab === "groups") {
      dispatch(setTab("staff"));
      return;
    }

    dispatch(setTab("section"));
  };

  useEffect(() => {
    dispatch(getUserTypes());
    dispatch(getCounters());
    dispatch(getSubscription());
  }, []);

  useEffect(() => {
    if (pending || !data.length) {
      return;
    }

    const { id, reportLinks } = data[0];
    dispatch(setReportLinks(reportLinks));

    if (userType) {
      const firmIndex = data.findIndex((firm: any) => firm.id === userType);

      const pageByIndex = Math.max(1, Math.floor(firmIndex / 2));
      setCurrentIndex(2 * (pageByIndex - 1));

      return;
    }

    if (!userType) {
      dispatch(setUserType(id));
    }
  }, [pending, userId, data]);

  useEffect(() => {
    if (userType) {
      dispatch(getAccounts(userType));
    }
  }, [userType]);

  const currentFirm = useMemo(
    () => data.find((firm: TUserType) => firm.id === userType) || defaultfirm,
    [data, userType]
  );

  const [counterMessageBegin, counterMessageEnd, field] =
    getTabCounterMessages(tab);

  const counterValue = counters?.[userType]?.[field] ?? 0;
  const currentSubscription = subscription?.[userType] ?? null;

  const showHeader = (tab === "project->section" && isMobile) || !isMobile;
  const showRestButtons = (isMobile && sectionId) || !isMobile;
  const showTreatyButton =
    (isMobile &&
      projectId &&
      (tab === "project->section" || tab === "project->treaty" || sectionId)) ||
    !isMobile;
  const showBackwardsButton =
    isMobile && ((Boolean(sectionId) && tab !== "section") || tab === "groups");

  useClickOutside(respRef, () => {
    setShowResponsibilities(false);
  });

  if (pending) {
    return (
      <div className={styles.header}>
        <Skeleton className={styles.skltn} variant="rectangular" />
        <div className={styles.dummy} />
        <Skeleton className={styles.skltnS} variant="rectangular" />
      </div>
    );
  }

  return (
    <div className={cn(styles.header, !showHeader && styles.mobileMainHeader)}>
      {!isMobile && (
        <UserFirms
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          firms={data}
        />
      )}
      <nav className={styles.nav}>
        {showBackwardsButton && (
          <IconButton onClick={handleGoBackwars} className={styles.backArrow}>
            <ArrowBack />
          </IconButton>
        )}
        <NavButton
          svg={<HomeSVG />}
          prompt="Проекты"
          currentFirm={currentFirm}
        />
        <NavButton
          svg={<KanbanIcon />}
          prompt="Канбан"
          currentFirm={currentFirm}
        />
        {showTreatyButton && (
          <NavButton
            svg={<PenSVG />}
            prompt="Договоры"
            currentFirm={currentFirm}
            unfulfilled={Boolean(countUnSignedDocs)}
          />
        )}
        {showRestButtons && (
          <>
            <NavButton
              svg={<GridSVG />}
              prompt="Заявки"
              currentFirm={currentFirm}
              unfulfilled={Boolean(countApplication)}
            />
            <NavButton
              svg={<SectionsSVG />}
              prompt="Задачи"
              currentFirm={currentFirm}
              unfulfilled={Boolean(countTasks)}
            />
            <NavButton
              svg={<LadderSVG />}
              prompt="Отчет"
              currentFirm={currentFirm}
            />
            <NavButton
              svg={<FolderSymlink />}
              prompt="Отчет по ИД"
              currentFirm={currentFirm}
            />
          </>
        )}
      </nav>
      <div className={styles.title}>
        <h2>{currentFirm.fullTitle}</h2>
        <p>{currentFirm.subtitle}</p>
      </div>
      {!isMobile && (
        <>
          <div className={styles.text}>
            {subscriptionPending ? (
              <Skeleton />
            ) : currentSubscription ? (
              <>
                <p>Подписка по тарифу {currentSubscription?.type?.name}</p>
                {currentSubscription?.active ? (
                  <b>действует до {currentSubscription?.date_end}</b>
                ) : (
                  <b>не активна.</b>
                )}
              </>
            ) : (
              <>
                <div>
                  Подписка&nbsp;<b>не активна.</b>
                </div>
              </>
            )}
          </div>
          <div className={styles.dummy} />
          {isKanban ? null : (
            <div className={styles.appliances}>
              <h4>
                {counterMessageBegin}
                <br />
                {counterMessageEnd}
              </h4>
              <h1>{counterValue}</h1>
            </div>
          )}
        </>
      )}
      {isMobile && (
        <IconButton
          onClick={() => setShowResponsibilities(true)}
          className={styles.menuButton}
        >
          <Menu />
        </IconButton>
      )}
      {showResponsibilities && (
        <div ref={respRef} className={styles.responsibilities}>
          {data.map(({ id, shortTitle }: any) => (
            <p
              className={cn(id === userType && styles.selectedResp)}
              onClick={() => handleChangeResponsibility(id)}
            >
              {shortTitle}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
