import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import style from "./SourceDataView.module.scss";

interface IDateProps {
  canEditDate: boolean;
  date_create: string;
  date_deadline: string;
  date_complete?: boolean;
  onChangeDate: (data: any) => void;
  isLoadDateUpdate: boolean;
  dateType: "start" | "end";
}

export const DatePopper = ({
  canEditDate,
  date_create,
  date_deadline,
  date_complete,
  onChangeDate,
  isLoadDateUpdate,
  dateType,
}: IDateProps) => {
  const start = moment(date_create, "DD.MM.YYYY").toDate();
  const end = moment(date_deadline, "DD.MM.YYYY").toDate();
  const activeData = dateType === "start" ? start : end;
  const [date, setDate] = useState(activeData);

  const minDate = dateType === "start" ? end : null;
  const maxDate = dateType === "start" ? null : start;

  async function onSaveDate() {
    await onChangeDate({
      startDate: dateType === "start" ? date : start,
      endDate: dateType === "start" ? end : date,
    });
  }

  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    if (date_complete === false) {
      const isTodayAfter = moment().isAfter(date);
      setIsExpired(isTodayAfter);
    }
  }, [date_deadline, date]);

  const CustomInput = forwardRef<HTMLSpanElement, { onClick?: () => void }>(
    ({ onClick }, ref) => (
      <span
        className={classNames(
          style.text_edit,
          !canEditDate && style.text_noEdit,
          isExpired && style.text_isExpired
        )}
        onClick={onClick}
        ref={ref}
      >
        {moment(date).format("DD.MM.YYYY")}
      </span>
    )
  );

  return (
    <div className={style.text}>
      <DateField
        customInput={<CustomInput />}
        changeDateHandler={(date) => {
          if (date) {
            setDate(date);
          }
        }}
        startDateProp={date}
        minDate={minDate}
        maxDate={maxDate}
        isLoad={isLoadDateUpdate}
        onSave={onSaveDate}
        disableClear
        readOnly={!canEditDate}
      />
    </div>
  );
};
