import React from "react";

export const HexagonComplete = ({ width = "40", height = "45" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 45"
    fill="none"
  >
    <path
      d="M20.5 0L39.9856 11.25V33.75L20.5 45L1.01443 33.75V11.25L20.5 0Z"
      fill="#0AA699"
    />
    <path
      d="M26.0969 16.3773C26.0843 16.3893 26.0725 16.4021 26.0616 16.4155L19.9259 24.0978L16.2283 20.4624C15.9771 20.2324 15.6449 20.1072 15.3016 20.1132C14.9584 20.1191 14.6308 20.2558 14.3881 20.4943C14.1453 20.7329 14.0063 21.0547 14.0002 21.3921C13.9941 21.7294 14.1216 22.0559 14.3556 22.3027L19.0302 26.8982C19.1562 27.0217 19.3061 27.1191 19.4712 27.1844C19.6362 27.2497 19.813 27.2817 19.9909 27.2785C20.1688 27.2753 20.3442 27.2368 20.5066 27.1655C20.6691 27.0942 20.8153 26.9915 20.9365 26.8635L27.989 18.2003C28.2292 17.9526 28.3605 17.622 28.3545 17.2799C28.3486 16.9378 28.2059 16.6118 27.9573 16.3723C27.7087 16.1328 27.3742 15.9991 27.0261 16C26.6779 16.0009 26.3442 16.1365 26.0969 16.3773Z"
      fill="#101920"
    />
  </svg>
);

export const CircleComplete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle
      cx="8"
      cy="8"
      r="7.8"
      fill="#0AA699"
      stroke="#0AA699"
      strokeWidth="0.4"
      strokeDasharray="1.28 1.28"
    />
    <path
      d="M10.3588 5.90875C10.3538 5.91354 10.349 5.91865 10.3447 5.92403L7.89039 8.99695L6.41134 7.54278C6.31086 7.45078 6.17798 7.40069 6.04067 7.40308C5.90337 7.40546 5.77236 7.46012 5.67525 7.55554C5.57815 7.65097 5.52252 7.77971 5.5201 7.91464C5.51768 8.04957 5.56865 8.18016 5.66227 8.27889L7.53211 10.1171C7.58249 10.1665 7.64247 10.2054 7.70849 10.2316C7.7745 10.2577 7.8452 10.2705 7.91636 10.2692C7.98752 10.2679 8.05769 10.2525 8.12267 10.224C8.18766 10.1955 8.24613 10.1544 8.29461 10.1032L11.1156 6.63792C11.2117 6.53884 11.2642 6.4066 11.2618 6.26977C11.2595 6.13295 11.2024 6.00253 11.103 5.90673C11.0035 5.81093 10.8697 5.75744 10.7304 5.75781C10.5912 5.75819 10.4577 5.81241 10.3588 5.90875Z"
      fill="#15222B"
    />
  </svg>
);

export const CircleWork = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle
      cx="20"
      cy="20"
      r="19.5"
      fill="#101920"
      stroke="#0AA699"
      strokeDasharray="3.2 3.2"
    />
    <path
      d="M25.897 14.7758C25.8844 14.7878 25.8726 14.8005 25.8616 14.814L19.726 22.4963L16.0283 18.8609C15.7772 18.6309 15.4449 18.5056 15.1017 18.5116C14.7584 18.5175 14.4309 18.6542 14.1881 18.8928C13.9454 19.1313 13.8063 19.4532 13.8003 19.7905C13.7942 20.1278 13.9216 20.4543 14.1557 20.7011L18.8303 25.2966C18.9562 25.4202 19.1062 25.5175 19.2712 25.5828C19.4363 25.6482 19.613 25.6802 19.7909 25.6769C19.9688 25.6737 20.1442 25.6353 20.3067 25.564C20.4691 25.4927 20.6153 25.3899 20.7365 25.2619L27.7891 16.5987C28.0292 16.351 28.1605 16.0204 28.1546 15.6783C28.1487 15.3363 28.006 15.0102 27.7574 14.7707C27.5088 14.5312 27.1743 14.3975 26.8261 14.3984C26.478 14.3994 26.1442 14.5349 25.897 14.7758Z"
      fill="#0AA699"
    />
  </svg>
);

export const HexagonWork = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="45"
    viewBox="0 0 40 45"
    fill="none"
  >
    <path
      d="M1.51443 11.5387L20.5 0.57735L39.4856 11.5387V33.4613L20.5 44.4226L1.51443 33.4613V11.5387Z"
      fill="#101920"
      stroke="#0AA699"
    />
    <path
      d="M25.897 16.7758C25.8844 16.7878 25.8726 16.8005 25.8616 16.814L19.726 24.4963L16.0283 20.8609C15.7772 20.6309 15.4449 20.5056 15.1017 20.5116C14.7584 20.5175 14.4309 20.6542 14.1881 20.8928C13.9454 21.1313 13.8063 21.4532 13.8003 21.7905C13.7942 22.1278 13.9216 22.4543 14.1557 22.7011L18.8303 27.2966C18.9562 27.4202 19.1062 27.5175 19.2712 27.5828C19.4363 27.6482 19.613 27.6802 19.7909 27.6769C19.9688 27.6737 20.1442 27.6353 20.3067 27.564C20.4691 27.4927 20.6153 27.3899 20.7365 27.2619L27.7891 18.5987C28.0292 18.351 28.1605 18.0204 28.1546 17.6783C28.1487 17.3363 28.006 17.0102 27.7574 16.7707C27.5088 16.5312 27.1743 16.3975 26.8261 16.3984C26.478 16.3994 26.1442 16.5349 25.897 16.7758Z"
      fill="#0AA699"
    />
  </svg>
);

export const HexagonNotActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="45"
    viewBox="0 0 40 45"
    fill="none"
  >
    <path
      d="M1.51443 11.5387L20.5 0.57735L39.4856 11.5387V33.4613L20.5 44.4226L1.51443 33.4613V11.5387Z"
      fill="#101920"
      stroke="#838A93"
    />
  </svg>
);

export const CheckSvg = ({ fill = "#101920" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill={fill}
    className="bi bi-check"
    viewBox="0 0 16 16"
  >
    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
  </svg>
);
