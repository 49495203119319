import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../../services/api/requestHandler";
import { ApiProjectProcessUpdateRequest } from "../../../../services/api/projectProcess/applications";

export const fetchProjectProcessUpdateRequest = createAsyncThunk<
  boolean,
  { taskId: number; data: any },
  { rejectValue: { status: string; message: string } }
>(
  "projectProcess/fetchProjectProcessUpdateRequest",
  async ({ taskId, data }, { rejectWithValue }) => {
    try {
      const response = await ApiProjectProcessUpdateRequest(taskId, data);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      const error = getErrorMessage(err);
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      return rejectWithValue({ status: "none", message: error });
    }
  }
);
