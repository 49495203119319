import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPublishExpertise } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseHeaderBtn";
import { pushError } from "../../../../errorTrace";
import { fetchProjectProcessView } from "../../../Info/services/fetchProjectProcessView";

export const fetchExpertisePublish = createAsyncThunk<
  boolean,
  any,
  { rejectValue: { status: string; message: string } }
>(
  "expertise/fetchExpertisePublish",
  async ({ processId, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiPublishExpertise(processId, data);
      if (!response.ok) {
        throw await response.json();
      }
      dispatch(fetchProjectProcessView(processId));
      return response.json();
    } catch (err) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);
