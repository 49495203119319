import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextField } from "@mui/material";
import cn from "classnames";
import { parse } from "date-fns";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import BankSearchField from "../BankSearchField/BankSearchField";
import { IPaymentInfo } from "../../../../../../app/feature/profileReducer/types";
import { SubmitDataProps } from "../Payments";
import { FormErrorType } from "../../../../../../app/services/api/requestHandler";
import style from "./style.module.scss";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { UnsavedType } from "../../../../Project/types";
import { datePassedRequired } from "../../../../../../utils/SimplifiedYup";
import { palette, textFieldSX } from "../../../../../../styles/restyle";

type ComponentProps = {
  errorsList: FormErrorType[];
  paymentInfo: IPaymentInfo;
  canEdit: boolean;
  onSubmit: SubmitHandler<SubmitDataProps>;
  disabled: boolean;
  setUnsaved: SetState<UnsavedType>;
};

const inn = yup
  .string()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 12 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 12;
  });
const bik = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 9 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 9;
  });
const correspondentAccount = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 20 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 20;
  });
const bankAccount = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 20 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 20;
  });
const cardNum = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 16 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 16;
  });
const snils = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 11 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 11;
  });
const passportSerial = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 4 символа", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 4;
  });
const passportNum = yup
  .string()
  .nullable()
  .matches(/^\d+$/, {
    message: "Используйте только цифры",
    excludeEmptyString: true,
  })
  .test("Проверка длины", "Введите 6 символов", (val) => {
    if (!val) {
      return true;
    }
    return val!.length === 6;
  });
const passportDate = datePassedRequired("Дата выдачи");

const editableSchema = yup.object().shape({
  inn,
  bik,
  correspondent_account: correspondentAccount,
  bank_account: bankAccount,
  card_num: cardNum,
  snils,
  passport_serial: passportSerial,
  passport_num: passportNum,
  passport_date: passportDate,
});

const lockedSchema = yup.object().shape({
  inn,
  bik,
  correspondent_account: correspondentAccount,
  bank_account: bankAccount,
  card_num: cardNum,
  snils,
});

const PaymentsForm: React.FC<ComponentProps> = ({
  paymentInfo,
  canEdit,
  setUnsaved,
  onSubmit,
  errorsList,
  disabled,
}) => {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(canEdit ? editableSchema : lockedSchema),
    defaultValues: {
      passport_file: [],
      inn_file: [],
      snils_file: [],
      passport_date: paymentInfo?.passport_date
        ? parse(paymentInfo.passport_date, "dd.MM.yyyy", new Date())
        : null,
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const watchPassportDate = watch("passport_date");

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, payments: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  useEffect(() => {
    errorsList.forEach((item) => {
      // @ts-ignore
      setError(item.field, { message: item.message });
    });
  }, [errorsList, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!paymentInfo!.is_self_employed && (
        <div className={style.container}>
          <h2>Внимание !</h2>
          <p>
            Статус самозанятого определяется автоматически при заполнении поля
            ИНН.
          </p>
          <p>Стать самозанятым можно через приложение “Мой налог”</p>
        </div>
      )}
      <div className={style.container}>
        <div className={style.statusContainer}>
          <h2>Документы</h2>
          <div className={style.status}>
            <span className={style.statusText}>Статус самозанятого</span>
            <div
              className={cn(style.statusColor, {
                [style.greenStatus]: paymentInfo!.is_self_employed,
              })}
            />
          </div>
        </div>
        <div className={style.formContainer}>
          <div className={style.formChunk}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Серия паспорта"
              inputProps={{ maxLength: 4 }}
              defaultValue={paymentInfo!.passport_serial}
              autoComplete="off"
              {...textFieldSX}
              {...register("passport_serial")}
              disabled={!canEdit}
              error={!!errors.passport_serial}
              helperText={
                errors.passport_serial ? errors.passport_serial.message : null
              }
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Номер паспорта"
              inputProps={{ maxLength: 6 }}
              defaultValue={paymentInfo!.passport_num}
              autoComplete="off"
              {...textFieldSX}
              {...register("passport_num")}
              disabled={!canEdit}
              error={!!errors.passport_num}
              helperText={
                errors.passport_num ? errors.passport_num.message : null
              }
            />
            <DateField
              label="Дата выдачи"
              startDateProp={watchPassportDate}
              changeDateHandler={async (date) => {
                setValue("passport_date", date);
                await trigger("passport_date");
              }}
              disabled={!canEdit}
              error={Boolean(errors.passport_date?.message)}
              helperText={errors.passport_date?.message as string}
            />
          </div>
          <div className={style.withFileInput}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Кем выдан паспорт"
              defaultValue={paymentInfo!.passport_issued_by}
              autoComplete="off"
              disabled={!canEdit}
              {...textFieldSX}
              {...register("passport_issued_by")}
            />
          </div>
          <div className={style.withFileInput}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="СНИЛС"
              defaultValue={paymentInfo!.snils}
              autoComplete="off"
              {...textFieldSX}
              {...register("snils")}
              inputProps={{ maxLength: 11 }}
              error={!!errors.snils}
              helperText={errors.snils ? errors.snils.message : null}
            />
          </div>
          <div className={style.withFileInput}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="ИНН"
              defaultValue={paymentInfo!.inn}
              autoComplete="off"
              {...textFieldSX}
              {...register("inn")}
              inputProps={{ maxLength: 12 }}
              error={!!errors.inn}
              helperText={errors.inn ? errors.inn.message : null}
            />
          </div>
        </div>
      </div>
      <div className={style.container}>
        <h2>Банковские реквизиты физического лица/самозанятого</h2>
        <div className={style.formChunk}>
          <BankSearchField
            name="bik"
            label="БИК Банка"
            register={register}
            defaultValue={paymentInfo!.bik}
            setValue={setValue}
            trigger={trigger}
            hasError={!!errors.bik}
            helperText={errors.bik ? errors.bik.message : null}
          />
          <BankSearchField
            name="bank_name"
            label="Наименование банка"
            register={register}
            defaultValue={paymentInfo!.bank_name}
            trigger={trigger}
            setValue={setValue}
          />
        </div>
        <div className={style.formChunk}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="Корреспондентский счет"
            defaultValue={paymentInfo!.correspondent_account}
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            {...textFieldSX}
            {...register("correspondent_account")}
            error={!!errors.correspondent_account}
            helperText={
              errors.correspondent_account
                ? errors.correspondent_account.message
                : null
            }
          />
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="Расчетный счет"
            defaultValue={paymentInfo!.bank_account}
            autoComplete="off"
            {...register("bank_account")}
            {...textFieldSX}
            error={!!errors.bank_account}
            helperText={
              errors.bank_account ? errors.bank_account.message : null
            }
          />
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="Номер банковской карты"
            defaultValue={paymentInfo!.card_num}
            autoComplete="off"
            {...textFieldSX}
            {...register("card_num")}
            error={!!errors.card_num}
            helperText={errors.card_num ? errors.card_num.message : null}
          />
        </div>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="ФИО держателя карты"
          defaultValue={paymentInfo!.card_owner_fio}
          autoComplete="off"
          {...textFieldSX}
          {...register("card_owner_fio")}
        />
      </div>
      <CustomButton
        disabled={disabled}
        className={style.submitButton}
        width={160}
        type="submit"
        background={palette.blue}
        style={{
          margin: "0 calc(100% - 160px) 0 0",
          opacity: disabled ? "0.5" : "1",
        }}
      >
        Сохранить
      </CustomButton>
    </form>
  );
};

export default PaymentsForm;
