import React, { ChangeEvent, KeyboardEvent } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { inputBaseClasses } from "@mui/material/InputBase";
import { palette } from "src/styles/restyle";
import { ModalCloseIcon, SearchIcon } from "src/components/newUI/SVG";

interface SearchBarProps {
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  searchClick: () => void;
  isSearched?: boolean;
  resetClick?: () => void;
  className?: string;
  placeholder?: string;
}

const sx = {
  color: "#D5D6DE",
  cursor: "pointer",
};

export const SearchBar = ({
  onChange,
  onKeyDown,
  searchClick,
  resetClick,
  value,
  isSearched,
  className,
  placeholder = "Введите название документа",
}: SearchBarProps) => {
  const AdornmentComponent = isSearched ? ModalCloseIcon : SearchIcon;
  const onClickHandler = isSearched ? resetClick : searchClick;

  return (
    <TextField
      variant="standard"
      className={className}
      sx={{
        width: "285px",
        "& .MuiInput-underline:before": {
          borderBottom: "none",
        },
        [`& .${inputBaseClasses.root}`]: {
          borderBottom: `1px solid ${isSearched ? palette.persianGreen : palette.silverGrey}`,
          color: isSearched ? palette.persianGreen : palette.silverGrey,
          fontWeight: "400",
          paddingLeft: 0,
          transition: "all 0.2s",
        },
        [`& .${inputBaseClasses.focused}`]: {
          borderBottom: `1px solid ${palette.persianGreen}`,
        },
        "& .MuiInputBase-input::placeholder": {
          color: palette.grey,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "none",
        },
      }}
      inputProps={{
        placeholder,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={sx} onClick={onClickHandler}>
            <AdornmentComponent width={"14"} height={"14"} />
          </InputAdornment>
        ),
      }}
      onKeyDown={onKeyDown}
      value={value}
      onChange={onChange}
    />
  );
};
