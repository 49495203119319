import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../api";

export const ApiGetLocationSeismicity = (
  regionCode: number,
  location: number
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/location-seismicy/find?regionCode=${regionCode}&location=${location}`
  );
};
