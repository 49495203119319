import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessCheckDocumentations } from "../../../../services/api/projectProcess/checkTask";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessCheckDocumentations = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data: {
      status: number;
      comment: string;
    };
  }
>(
  "projectProcess/fetchProjectProcessCheckDocumentations",
  async ({ taskId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessCheckDocumentations(taskId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
