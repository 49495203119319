import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";

export type TextLinkProps = ComponentPropsType<{
  children: ReactNode;
  url: string;
  redirect?: boolean;
  title?: string;
  target?: React.AnchorHTMLAttributes<unknown>["target"];
  download?: boolean;
  customTheme?: string;
}>;

export enum ThemeTextLink {
  "WHITE" = "white",
  "TITLE" = "title",
  "OCEANIC" = "oceanic",
}
export const TextLink: React.FC<TextLinkProps> = ({
  children,
  url,
  redirect = false,
  theme = "dark",
  variant,
  title = "",
  target = "_self",
  download,
  customTheme = "",
}) =>
  redirect ? (
    <a
      target={target}
      title={title}
      className={classNames(
        style.link,
        variant,
        style[theme],
        style[customTheme]
      )}
      href={url}
      download={download}
    >
      {children}
    </a>
  ) : (
    <NavLink
      title={title}
      className={classNames(
        style.link,
        variant,
        style[theme],
        style[customTheme]
      )}
      to={url}
    >
      {children}
    </NavLink>
  );
