import { useEffect, useState } from "react";
import { KanbanModel } from "src/FSD/entities/kanban";
import { useAppSelector } from "../../../../../../app/store";

export const useScrollControls = () => {
  const groupBy = useAppSelector(KanbanModel.getGroupByValue);
  const isLoading = useAppSelector(KanbanModel.getIsViewPending);

  const [followScrollTop, setFollowScrollTop] = useState(false);
  const [followScrollBottom, setFollowScrollBottom] = useState(false);

  useEffect(() => {
    if (groupBy.id === "") {
      setFollowScrollTop(false);
      setFollowScrollBottom(false);

      return;
    }

    const handleScrollFollow = () => {
      const someCondition =
        window.scrollY + window.screen.height >
        document.documentElement.scrollHeight - 360;

      setFollowScrollBottom(someCondition);
      setFollowScrollTop(!someCondition && window.scrollY > 245);
    };

    window.addEventListener("scroll", handleScrollFollow);
    handleScrollFollow();

    return () => {
      window.removeEventListener("scroll", handleScrollFollow);
    };
  }, [isLoading, groupBy.id]);

  return { followScrollTop, followScrollBottom } as const;
};
