import * as Yup from "yup";
import moment from "moment/moment";
import { userIsAdmin } from "../../../app/services/user/userRoles";

const parseDate = (value: Date, originalValue: string) =>
  moment(originalValue).isValid()
    ? value
    : moment(originalValue, "DD.MM.YYYY").toDate();

export function ModalSchema(
  haveExpertise: boolean,
  checkPrice: boolean,
  work: boolean,
  executorDiv?: boolean,
  pay3?: number
): any {
  const isAdmin = userIsAdmin();
  const messageDateLimit = work
    ? " Продолжительность работ не может быть меньше 1 дня"
    : "Продолжительность работ должна быть от 8 дней с учетом проверки результата у ГИП и смежных исполнителей";

  const schema = Yup.object().shape({
    price: !checkPrice
      ? Yup.number()
          .min(1000, `Стоимость не может быть меньше 1000 рублей!`)
          .max(10000000, `Стоимость не может быть больше 10 млн рублей!`)
          .required("Это поле обязательно для заполнения")
          .typeError("Значение «Cтоимость» должно быть числом.")
      : Yup.number().nullable(),
    type: Yup.string()
      .ensure()
      .required("Необходимо выбрать форму ответственности"),
    date_start: isAdmin
      ? Yup.date()
          .typeError("Необходимо заполнить «Начальный срок»")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date()
          .typeError("Необходимо заполнить «Начальный срок»")
          .required("Необходимо заполнить «Начальный срок»")
          .min(new Date(), "Начальный срок должен быть в будущем"),
    date_limit: isAdmin
      ? Yup.date()
          .typeError("Необходимо заполнить «Конечный срок»")
          .required("Необходимо заполнить «Конечный срок»")
      : Yup.date()
          .typeError("Необходимо заполнить «Конечный срок»")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(new Date(), "Конечный срок должен быть в будущем")
          .test(
            "minStart",
            "Конечный срок не может быть раньше начального",
            function (value) {
              const startDate = this.parent.date_start;
              return moment(value).isSameOrAfter(startDate);
            }
          )
          .test("minDay", messageDateLimit, function (value) {
            const expiryDate = moment(value);
            const enteredDate = moment(this.parent.date_start);
            const tmpExpiryDate = work
              ? moment(enteredDate).add(1, "days")
              : moment(enteredDate).add(8, "days");
            return tmpExpiryDate.isSameOrBefore(expiryDate);
          }),
    pay1: work
      ? Yup.number()
          .required("Это поле обязательно для заполнения")
          .typeError("Значение должно быть целым числом")
          .min(20, "Аванс должен быть не меньше 20%")
          .max(80, "Аванс должен быть не больше 80%")
      : Yup.number().when("with_docs", {
          is: "Да",
          then: Yup.number()
            .typeError("Значение должно быть целым числом")
            .required("Это поле обязательно для заполнения")
            .nullable()
            .optional()
            .min(20, "Аванс должен быть не меньше 20%")
            .max(80, "Аванс должен быть не больше 80%"),
          otherwise: Yup.number(),
        }),
    pay3: work
      ? Yup.number()
          .required("Это поле обязательно для заполнения")
          .nullable()
          .optional()
          .typeError("Значение должно быть целым числом")
          .min(0, "Минимум 0%")
          .max(80, "Максимум 80%")
      : Yup.number().when("with_docs", {
          is: "Да",
          then: Yup.number()
            .required("Это поле обязательно для заполнения")
            .nullable()
            .optional()
            .typeError("Значение должно быть целым числом")
            .min(0, "Минимум 0%")
            .max(80, "Максимум 80%"),
          otherwise: Yup.number(),
        }),
    pay2: work
      ? Yup.number()
          .typeError("Значение должно быть целым числом")
          .required("Это поле обязательно для заполнения")
          .min(0, "Минимум 0")
      : Yup.number().when("with_docs", {
          is: "Да",
          then: Yup.number()
            .typeError("Значение должно быть целым числом")
            .required("Это поле обязательно для заполнения")
            .nullable()
            .optional()
            .min(0, "Минимум 0"),
          otherwise: Yup.number(),
        }),
    paymentSum: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? 0 : value))
      .test("max", "Общая сумма оплаты должна быть равна 100%", function () {
        const { pay1 } = this.parent;
        const { pay3 } = this.parent;
        const { pay2 } = this.parent;

        if (haveExpertise) {
          return pay2 === 100 - pay1 - pay3;
        }
        if (!haveExpertise) {
          return pay2 === 100 - pay1;
        }
        return !pay2;
      }),
    executorType: Yup.string().when("executor", {
      is: (val) => !!val && executorDiv,
      then: Yup.string().required("Необходимо выбрать форму ответственности"),
      otherwise: Yup.string(),
    }),
  });

  return schema;
}
