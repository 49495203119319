import { parsedTokenDataType } from "../../../types/stateTypes/userType";
import { getCookie } from "../app/storage/cookies";

// токен юзера в localStorage как мера авторизации пользователя в приложении

/*
  Возвращает токен юзера из localStorage
  @remark dirty function, берет значение из localstorage
 */
export const getUserTokenByLocalStorage = (): string | null => {
  return localStorage.getItem("userToken");
};

/*
  Декодирует токен
 */
export const parseTokenBody = (
  token: string | undefined
): parsedTokenDataType => {
  if (token === null || !token) {
    return {
      data: {
        id: 0,
        name: "",
        avatarUrl: "",
        roles: [],
        surname: "",
        agreement_personal: "",
      },
      exp: 0,
    };
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/*
  Устанавливает токен в localStorage с cookie
  @remark dirty function, мутация localStorage
 */
export const setTokenByProd = (callback: any) => {
  const token = getCookie("access_token");
  if (token) {
    localStorage.setItem("userToken", token);
  }
  callback();
};

/*
  Устанавливает токен в localStorage с cookie
  @remark dirty function, мутация localStorage
 */
export const removeToken = () => {
  localStorage.removeItem("userToken");
};

/*
  Удаляет все cookie до которых может дотянуться.
  Необходим при logout
  @remark dirty function, мутация cookie
 */
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

/*
  Процедура выхода из аккаунта со стороны клиента
  @remark dirty function, мутация cookie
 */
export const clientLogoutClearAllData = () => {
  removeToken();
  deleteAllCookies();
};

/*
  Функция проверки авторизации пользователя
 */
export const userIsAuth = () => Boolean(getUserTokenByLocalStorage());

/*
  Функция композиции парсинга токена пользователя.
 */
export const getParsedUserToken = () =>
  parseTokenBody(getUserTokenByLocalStorage()!);

/*
  Возвращает id пользователя, если он авторизован
 */
export const getUserId = () =>
  parseTokenBody(getUserTokenByLocalStorage()!).data.id;
