import { format } from "date-fns";
import moment from "moment";
import { ISkill } from "./types";

export const prepareDataForUpdateProfile = (data: any) => {
  const newData = new FormData();

  newData.set("name", data.name);
  newData.set("surname", data.surname);
  newData.set("patronymic", data.patronymic || "");

  if (data.sex) {
    newData.set("sex", data.sex);
  }

  newData.set("phone", data.phone || "");
  newData.set("email", data.email);
  newData.set("timezone", data.timezone || "");
  newData.set("address", data.address || "");
  newData.set("city", data.city || "");
  newData.set("work_with_bim", data.workWithBim);
  newData.set("about", data.about || "");
  newData.set("type", data.role);
  newData.set("region_code", data.region);
  newData.set("country_code", data.country);

  if (data.birthdate) {
    newData.set("birthdate", moment(data.birthdate).format("DD.MM.YYYY"));
  } else {
    newData.set("birthdate", "");
  }

  if (data.skills.length) {
    data.skills.forEach((skill: ISkill) => {
      newData.append("skillIds[]", `${skill}`);
    });
  } else {
    newData.append("skillIds", "");
  }

  const newPhoto = data.photo_upload[0];
  if (newPhoto) {
    newData.set("photo_upload", newPhoto);
  }

  return newData;
};

export const prepareDataForReward = (data: any) => {
  const newData = new FormData();

  newData.set("user_id", data.user_id);
  newData.set("name", data.name);
  const newPhoto = data.file_upload[0];
  if (newPhoto) {
    newData.set("file_upload", newPhoto);
  }
  return newData;
};

export const prepareDataForPortfolio = (data: any, fileUpload = false) => {
  const newData = new FormData();
  newData.set("name", data.name);
  newData.set("building_function_id", data.buildingFunction.id);
  newData.set("description", data.description);
  const newPhoto = data.photo_upload[0];
  if (newPhoto) {
    newData.set("photo_upload", newPhoto);
  }
  if (fileUpload && data.upload_files.length > 0) {
    data.upload_files.forEach((file: File) => {
      newData.append("upload_files[]", file);
    });
  }
  return newData;
};

export const prepareDataForPaymentInfo = (canEdit: boolean, data: any) => {
  const newData = new FormData();
  newData.set("inn", data.inn);
  newData.set("bik", data.bik);
  newData.set("bank_name", data.bank_name);
  newData.set("correspondent_account", data.correspondent_account);
  newData.set("bank_account", data.bank_account);
  newData.set("card_owner_fio", data.card_owner_fio);
  newData.set("bank_name", data.bank_name);
  newData.set("card_num", data.card_num);
  newData.set("snils", data.snils);
  newData.set("bank_name", data.bank_name);
  if (canEdit) {
    newData.set("passport_serial", data.passport_serial);
    newData.set("passport_num", data.passport_num);
    newData.set("passport_issued_by", data.passport_issued_by);
    const passportDate = data.passport_date;
    if (passportDate !== null) {
      newData.append("passport_date", format(passportDate, "dd.MM.yyyy"));
    } else {
      newData.append("passport_date", "");
    }
  }
  const passportFile = data.passport_file[0];
  if (passportFile) {
    newData.set("passport_file", passportFile);
  }
  const innFile = data.inn_file[0];
  if (innFile) {
    newData.set("inn_file", innFile);
  }
  const snilsFile = data.snils_file[0];
  if (snilsFile) {
    newData.set("snils_file", snilsFile);
  }
  return newData;
};
