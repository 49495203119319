import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetMainPageExecutorsRating = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/user/index-executor?per-page=3&sort=-rating.executor&expand=rating`
  );

export const ApiGetMainPageExecutorsExperience = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/user/index-executor?per-page=3&expand=rating&sort=-additionalInfo.job_duration`
  );

export const ApiGetMainPageExecutorsNew = (): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/user/index-executor?per-page=3&expand=rating&sort=-id`
  );
