import { useEffect } from "react";
import classNames from "classnames";
import { LayoutType } from "../UploaderTypes/UploaderTypes";
import style from "../style.module.scss";
import { actuallyHandler, deleteHandler } from "../UploaderUtils/utils";
import { UploaderFile } from "../UploaderFile/UploaderFile";

/**
 * UploaderLayout компонент переопределения верстки дропзоны.
 *
 * initialList - отображение уже существующих файлов
 *
 * previews - отображает загружаемые файлы
 * */

export const UploaderLayout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles },
  initialList,
  handleRemove,
  fileHandler,
  handleCheck,
  handleUpdate,
  disabled = false,
  newDesign,
}: LayoutType) => {
  const checkAllFilesDone = files.every((file) => file.meta.status === "done");

  const filesForHandler = files.map((file) => file.file);

  const filesLength = filesForHandler.length;

  useEffect(() => {
    if (fileHandler && checkAllFilesDone && filesLength >= 0) {
      fileHandler(filesForHandler);
    }
  }, [checkAllFilesDone, filesLength, fileHandler]);

  return (
    <>
      <div>
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      </div>
      <div
        className={classNames(
          style.layoutPreviews,
          newDesign && style.newLayout
        )}
      >
        {initialList?.length > 0
          ? initialList.map((item) => (
              <UploaderFile
                key={item.id}
                url={item.url}
                name={item.name}
                visibility={item.visibility}
                svgIcon={item.svgIcon}
                disabled={disabled}
                handleDelete={() => {
                  deleteHandler(item.id, handleRemove, handleUpdate);
                }}
                handleChange={() => {
                  actuallyHandler(item.id, handleCheck, handleUpdate);
                }}
              />
            ))
          : null}
        {previews}
      </div>
    </>
  );
};
