import React from "react";
import classNames from "classnames";
import parse from "html-react-parser";
import styles from "./FactChildGraph.module.scss";

interface FactChildGraphProps {
  name: string;
  graph: any;
  today: any;
}
export const FactChildGraph = ({ name, graph, today }: FactChildGraphProps) => {
  let expertiseValue;
  let workValue;

  const isProject = name === "project";
  if (name === "expertise") {
    expertiseValue = Object.values(graph);
  }
  if (name === "work") {
    workValue = Object.values(graph);
  }

  const { div1 } = graph;

  const projectGraph = isProject && (
    <div className={styles.flex}>
      <p className={styles.text}>{div1?.shortTitle}</p>
      <div className={styles.parent}>
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "-20px",
          }}
          className={styles.today}
        />
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "0",
          }}
          className={styles.shadow}
        />
        <div
          className={classNames(styles.block, styles.pd)}
          title={parse(div1?.title) as string}
          style={{
            marginLeft: `${div1?.marginLeft}%`,
            minWidth: `${div1?.width > 3 ? div1.width : 3}%`,
          }}
        >
          <div>
            <p className={styles.date1}>{`c ${div1.dateStart}`}</p>
            <p className={styles.date2}>{`по ${div1.dateEnd}`}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const workGraph = workValue && (
    <div className={styles.flex}>
      <p className={styles.text}>{div1?.shortTitle}</p>
      <div className={styles.parentExpertise}>
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "-60px",
          }}
          className={styles.today}
        />
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "0",
          }}
          className={styles.shadow}
        />
        {workValue.map((value: any, index) => (
          <>
            {index === 0 && (
              <div
                key={value.shortTitle}
                className={classNames(styles.block, styles.work)}
                title={parse(div1?.title) as string}
                style={{
                  marginLeft: `${index === 0 ? value?.marginLeft : 0}%`,
                  width: `${value?.width > 3 ? value.width : 3}%`,
                }}
              >
                {workValue.length > 1 && <div className={styles.workCircle} />}
                {workValue.length > 1 ? (
                  <p className={styles.date1}>{`c ${value.dateStart}`}</p>
                ) : (
                  <div>
                    <p className={styles.date1}>{`c ${value.dateStart}`}</p>
                    <p className={styles.date2}>{`по ${value.dateEnd}`}</p>
                  </div>
                )}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );

  const expertiseGraph = expertiseValue && (
    <div className={styles.flex}>
      <p className={styles.text}>{div1?.shortTitle}</p>
      <div className={styles.parentExpertise}>
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "0",
          }}
          className={styles.today}
        />
        <div
          style={{
            marginLeft: `${today?.div1?.marginLeft}%`,
            top: "0",
          }}
          className={styles.shadow}
        />
        {expertiseValue.map((value: any, index) => (
          <>
            {index === 0 && (
              <div
                key={value.shortTitle}
                className={classNames(
                  expertiseValue.length === 1 && styles.block,
                  styles.expertise,
                  expertiseValue.length > 1 && styles.blockExpertise
                )}
                title={parse(div1?.title) as string}
                style={{
                  marginLeft: `${value?.marginLeft}%`,
                  width: `${value?.width}%`,
                  minWidth: "3%",
                }}
              >
                {expertiseValue.length > 1 && (
                  <div className={styles.expertiseCircle} />
                )}
                {expertiseValue.length > 1 ? (
                  <p className={styles.date1}>{`c ${value.dateStart}`}</p>
                ) : (
                  <div>
                    <p className={styles.date1}>{`c ${value.dateStart}`}</p>
                    <p className={styles.date2}>{`по ${value.dateEnd}`}</p>
                  </div>
                )}
              </div>
            )}
            {index === 1 && (
              <div
                key={value.shortTitle}
                className={classNames(
                  styles.blockExpertise2,
                  styles.expertiseEx
                )}
                title={parse(div1?.title) as string}
                style={{
                  width: `${value?.width}%`,
                  minWidth: "3%",
                }}
              >
                <p className={styles.date2}>{`по ${value.dateEnd}`}</p>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {projectGraph}
      {expertiseGraph}
      {workGraph}
    </>
  );
};
