import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { CustomIcon } from "../CustomIcon/CustomIcon";

export type LogoProps = ComponentPropsType<{
  link?: string;
  variant?: string;
}>;

export const Logo: React.FC<LogoProps> = React.memo(
  ({ link = "/", variant }) => {
    return (
      <div className={classNames(style.wrapper, variant)}>
        <Link to={link!} title="Главная страница" className={style.logo}>
          <CustomIcon
            width="150"
            height="28"
            name={"pirsLogoV3"}
            variant={style.logoIcon}
          />
        </Link>
      </div>
    );
  }
);
