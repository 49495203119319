import { ReactNode } from "react";
import cn from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { KanbanModel, KanbanType } from "src/FSD/entities/kanban";
import { CircularProgress } from "@mui/material";
import styles from "./Column.module.scss";
import ColumnTask from "./ColumnTask";
import ColumnSortFilter from "./ColumnSortFilter";
import ColumnHeader from "./ColumnHeader";
import ColumnTitle from "./ColumnTitle";
import ColumnRange from "./ColumnRange";
import ColumnAddTask from "./ColumnAddTask";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { WorkFlowClasses } from "../../../../../shared/lib/constants/workFlowClasses";

type ColumnProps = {
  position?: string;
  isEmpty?: boolean;
  isStatic?: boolean;
  isAllTasks?: boolean;
  header?: ReactNode;
  tasks?: KanbanType.Task[];
};

const Column = ({
  position = "",
  header,
  isStatic,
  isEmpty,
  isAllTasks,
  tasks = [],
}: ColumnProps) => {
  const kanbanPending = useAppSelector(KanbanModel.getIsViewPending);

  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const noGroupping = groupBy.id === "";

  const handleShowTaskInfo = (task: KanbanType.Task) => {
    if (task.workFlowClass === WorkFlowClasses.FREE_TASK && task.can.edit) {
      dispatch(
        KanbanModel.setEditFreeTask({
          isOpen: true,
          taskId: task.id,
        })
      );

      return;
    }

    dispatch(
      KanbanModel.setViewTask({
        isOpen: true,
        taskId: task.id,
        header: task.header,
        isCompletion: false,
      })
    );
  };

  const NoTasksFound = () => (
    <div
      className={cn(
        styles.column,
        styles.column_notFound,
        isStatic && styles.column_notFound_static
      )}
    >
      Задачи отсутствуют
    </div>
  );

  if (kanbanPending) {
    return (
      <div className={styles.column}>
        {header}
        <div className={styles.column__pending}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={cn(styles.column, styles.column_empty)}>{header}</div>
    );
  }

  if (isStatic) {
    return (
      <div className={styles.column}>
        <div className={cn(styles.tasksList, styles.tasksList_static)}>
          {tasks.length ? (
            tasks.map((task: KanbanType.Task) => {
              return (
                <div
                  onClick={() => handleShowTaskInfo(task)}
                  className={styles.taskCard}
                >
                  <Column.Task key={task.id} position={position} {...task} />
                </div>
              );
            })
          ) : (
            <NoTasksFound />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        styles.column,
        isAllTasks && styles.column_allTasks,
        isAllTasks && noGroupping && styles.column_allTasks_noGroups
      )}
    >
      {header}
      <Droppable droppableId={position}>
        {(provided) => (
          <div
            id={position}
            key={position}
            className={cn(styles.tasksList, styles.tasksList_dynamic)}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {tasks.length ? (
              tasks.map((task: KanbanType.Task, index: number) => {
                return (
                  <Draggable
                    key={task.id}
                    draggableId={String(task.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        onClick={() => handleShowTaskInfo(task)}
                        className={styles.taskCard}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Column.Task
                          key={task.id}
                          position={position}
                          {...task}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })
            ) : (
              <NoTasksFound />
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

Column.Header = ColumnHeader;
Column.SortFilter = ColumnSortFilter;
Column.Task = ColumnTask;
Column.Title = ColumnTitle;
Column.AddTask = ColumnAddTask;
Column.Range = ColumnRange;

export default Column;
