import React, { memo } from "react";
import classNames from "classnames";
import { trimPrice } from "../../../../utils/trimNumber";
import cls from "./ChecklistCardBudget.module.scss";

interface ChecklistCardBudgetProps {
  priceStart: number;
  priceEnd: number;
  pricePercent: number;
}
export const ChecklistCardBudget = memo(
  ({ priceStart, priceEnd, pricePercent }: ChecklistCardBudgetProps) => {
    const excessCost = priceEnd - priceStart;
    const isRed = pricePercent > 100;
    return (
      <div className={cls.budget}>
        <div className={cls.budget_flex}>
          <p>Бюджет</p>
          <span>
            {pricePercent > 100
              ? `(превышен на ${excessCost} ₽))`
              : "(в пределах плана)"}
          </span>
        </div>
        <p className={cls.priceEnd}>{trimPrice(priceStart)}</p>
        {isRed ? (
          <div className={cls.terms}>
            <div className={cls.terms_redSlider} style={{ width: `100%` }} />
            <p className={cls.terms_price_red}>{priceEnd}₽</p>
          </div>
        ) : (
          <div className={cls.terms}>
            <div
              className={cls.terms_slider}
              style={{ width: `${pricePercent}%` }}
            >
              <p
                className={classNames(
                  pricePercent < 20 ? cls.left : cls.terms_price_default
                )}
              >
                {trimPrice(priceEnd)}₽
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
);
