import React, { useCallback } from "react";
import classNames from "classnames";
import { Pie } from "src/FSD/shared/uiKit/v2/Pie";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessPriceUpdateIsLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { PriceEditPopper } from "src/components/events/ProcessEvents/PriceEditPopper/PriceEditPopper";
import { fetchProjectProcessUpdatePrice } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessUpdatePrice";
import { Price } from "src/components/UI/components/Price/Price";
import { NdsInfo, PayPartInfo } from "./ui";
import styles from "./Payment.module.scss";

export const Payment = () => {
  const dispatch = useAppDispatch();
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const isLoadPriceUpdate = useAppSelector(getProjectProcessPriceUpdateIsLoad);
  const canEditPrice = !!infoData.task && infoData.can.update;

  const onChangePrice = useCallback((value: string) => {
    const data = {
      price: value,
    };
    if (infoData.id) {
      dispatch(fetchProjectProcessUpdatePrice({ id: infoData.id, data }));
    }
  }, []);

  const priceNode = (
    <Price
      price={infoData.price}
      classname={classNames(
        styles.price_textEdit,
        !canEditPrice && styles.price_textNoEdit,
        !infoData.price && styles.noPrice
      )}
      isProcess
    />
  );

  const ndsInfo = <NdsInfo executorNds={infoData.executor_nds} />;

  const pieChartDataSet = [
    {
      payPart: infoData.pay1,
      colorHex: "#d5d6de",
    },
    {
      payPart: infoData.pay2,
      colorHex: "#46C6E9",
    },
  ];

  if (infoData.pay3) {
    pieChartDataSet.push({
      payPart: infoData.pay3,
      colorHex: "#0aa699",
    });
  }

  return (
    <div className={styles.container}>
      <div>
        <h2>Стоимость и условия оплаты</h2>
        {infoData.with_docs && (
          <div className={styles.payParts}>
            <Pie.Chart
              size={"110px"}
              strokeWidth={8}
              dataSet={pieChartDataSet}
            />
            <div className={styles.payPartsText}>
              {Boolean(infoData.pay1) && (
                <PayPartInfo
                  price={infoData.price}
                  payPercent={infoData.pay1}
                  title={"Аванс"}
                  payClass={classNames(styles.pay1, styles.pay1Color)}
                />
              )}
              {Boolean(infoData.pay2) && (
                <PayPartInfo
                  price={infoData.price}
                  payPercent={infoData.pay2}
                  title={"После приёмки документации"}
                  payClass={classNames(styles.pay2, styles.pay2Color)}
                />
              )}
              {Boolean(infoData.pay3) && (
                <PayPartInfo
                  price={infoData.price}
                  payPercent={infoData.pay3}
                  title={"После экспертизы"}
                  payClass={classNames(styles.pay3, styles.pay3Color)}
                />
              )}
            </div>
          </div>
        )}
        {infoData.secure && (
          <div className={styles.payInfo}>
            <div>
              <span className={styles.payInfo_label}>Доступно:</span>
              <span className={styles.payInfo_value}>
                {infoData.account ? infoData.account.value : "0.00"} ₽
              </span>
              {ndsInfo}
            </div>
            <div>
              <span className={styles.payInfo_label}>Выплачено:</span>
              <span className={styles.payInfo_value}>
                {infoData.paid_out} ₽
              </span>
              {ndsInfo}
            </div>
          </div>
        )}
      </div>
      {infoData.projectPart.published && (
        <PriceEditPopper
          price={infoData.price}
          priceNode={priceNode}
          canEditPrice={canEditPrice}
          isLoadPriceUpdate={isLoadPriceUpdate}
          onEditPrice={onChangePrice}
        />
      )}
    </div>
  );
};
