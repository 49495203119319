import { ApiProjectEditingResponse } from "../../../types/api/responseTypes/apiProjectEditing";
import {
  sectionContainsApplications,
  sectionContainsTasks,
  sectionContainsTreaties,
  sectionContainsUnfulfilled,
} from "../User/Dashboard/Secondary/Sections/Section";
import { ExpertiseInitialType, ExpertiseType } from "./types";

export const getColor = (section: any) => {
  if (!section?.tabs) {
    return null;
  }

  const isStopped = Boolean(section?.stopped);

  const containsUnfulfilled = sectionContainsUnfulfilled(section.tabs, "dot");
  const containsApplications = sectionContainsApplications(section.tabs, "dot");
  const containsTasks = sectionContainsTasks(section.tabs, "dot");
  const containsTreaties = sectionContainsTreaties(section.tabs, "dot");

  return {
    section: isStopped ? "red" : containsUnfulfilled ? "orange" : null,
    tasks: containsTasks ? "orange" : null,
    applications: containsApplications ? "orange" : null,
    treaty: containsTreaties ? "orange" : null,
  };
};

export const getExpertise = (
  expertise: ExpertiseInitialType
): ExpertiseType => {
  if (expertise.length) {
    return {
      title: expertise[0].title,
      id: expertise[0].partId,
      num: expertise[0].num,
    };
  }
  return { title: "Не требуется", id: 0, num: "" };
};

export const createParameters = (
  params: ApiProjectEditingResponse["data"]["projectParameters"]
) => {
  return params
    ? params.reduce(
        (acc: any, next) => [...acc, { ...next.type, value: next.value }],
        []
      )
    : [];
};

export const createValues = (
  params: ApiProjectEditingResponse["data"]["projectParameters"]
) => {
  const array = params
    ? params.reduce(
        (acc: any, next) => [...acc, { ...next.type, value: next.value }],
        []
      )
    : [];

  return array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [item.code]: item.value,
    }),
    {}
  );
};

export const getFunctionType = (
  isSimple: boolean | undefined,
  sqObject: boolean,
  decree: boolean
): number =>
  isSimple ? 5 - Number(sqObject) : 2 - Number(sqObject) + 5 * Number(decree);

export const createDepthFirstSearch = (
  treeRaw: any,
  rootChildren: string[]
) => {
  function dfs(node: any, ancestor: string) {
    Object.keys(node).forEach((item: any) => {
      const currentNode = node[item];

      if (ancestor === "0") {
        rootChildren.push(currentNode.num ?? currentNode.projectPart.num);
      }

      const currentNodeData = { ...currentNode };

      if (currentNode?.id) {
        currentNodeData.id = currentNode.id;
      } else {
        delete currentNodeData.data;
      }

      treeRaw[currentNode.num ?? currentNode.projectPart.num] = {
        ...currentNodeData,
        children: [],
        ancestor,
        count: false,
        color: getColor(currentNode),
      };
      if (treeRaw[ancestor]?.children) {
        treeRaw[ancestor].children = [
          ...treeRaw[ancestor].children,
          currentNode.num ?? currentNode.projectPart.num,
        ];
      }
      if (currentNode.data) {
        dfs(currentNode.data, currentNode.num);
      }
    });
  }

  return dfs;
};
