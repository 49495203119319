import React from "react";
import ReactDOM from "react-dom";
import { Popup as PopupUI } from "../../UI/components/Popup/Popup";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { SendMoneyForm } from "./SendMoneyForm";
import { getModalRootElement } from "../../../utils/getModalRootElement";

export const SendMoney: React.FC<unknown> = () => {
  const modalRoot = getModalRootElement();
  const Modal: React.FC<unknown> = ({ children }) =>
    ReactDOM.createPortal(children, modalRoot!) as React.ReactElement<any, any>;
  const { Popup } = useUserWorkflow();

  const onClose = () => {
    Popup.sendMoney.set({});
    Popup.sendMoney.close();
  };

  return (
    <Modal>
      <PopupUI
        closeHandler={onClose}
        head={<p style={{ color: "white" }}>Формирование бюджета ГИП</p>}
        body={<SendMoneyForm />}
      />
    </Modal>
  );
};
