import React from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import style from "../style.module.scss";
import { config } from "../../../../app.cofig";
import { UploaderFileProps } from "../UploaderTypes/UploaderTypes";
import { DefaultFileIcon, FileLoading, Name } from "../UploaderUtils/UI";
import { getProjectViewData } from "../../../../app/feature/ProjectView/projectView";
import CustomCheckbox from "../../../newUI/CustomCheckbox/CustomCheckbox";
import { DeleteButtonIcon } from "../../../newUI/SVG";

/**
 * isPreview проп для отображения статуса загрузки или только имени файла,
 * в случае уже существующих файлов
 * */
const sx = { color: "white" };
export const UploaderFile: React.FC<UploaderFileProps> = ({
  url = "",
  name = "",
  svgIcon = "",
  isPreview = false,
  status = "",
  percent = 0,
  handleDelete,
  visibility,
  disabled = false,
  handleChange,
}) => {
  const { actually } = useSelector(getProjectViewData);

  return (
    <>
      <div className={style.previewWrapper}>
        <a
          href={`${config.localDomain}${url}` || ""}
          key={name}
          download={name}
          className={style.preview}
        >
          <div className={style.svgIconContainer} title="скачать">
            {svgIcon ? parse(svgIcon) : <DefaultFileIcon />}
          </div>
          <div
            className={style.previewFileInfo}
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {isPreview ? (
              <FileLoading name={name} status={status} percent={percent} />
            ) : (
              <Name name={name} />
            )}
            {actually && (
              <label className={style.actually}>
                <CustomCheckbox
                  editing
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange();
                  }}
                  checked={visibility || false}
                  classname={style.checkbox}
                />
                Актуальный
              </label>
            )}
          </div>
        </a>
        {!disabled && (!actually || !visibility) && (
          <IconButton
            title="удалить"
            className={style.remove}
            sx={sx}
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            <DeleteButtonIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};
