import React, { FC } from "react";
import style from "./style.module.scss";
import Decor1 from "../../../../media/images/mainPageProcessImages/decor1.png";
import Decor3 from "../../../../media/images/mainPageProcessImages/decor3.png";
import Decor4 from "../../../../media/images/mainPageProcessImages/decor4.png";
import Decor6 from "../../../../media/images/mainPageProcessImages/decor6.png";
import Decor7 from "../../../../media/images/mainPageProcessImages/decor7.png";
import Decor8 from "../../../../media/images/mainPageProcessImages/decor8.png";
import ExecutorsImg from "../../../../media/images/mainPageProcessImages/executors.png";
import { TProps } from "./Gip";
import BackButton from "../BackButton";

const Executor: FC<TProps> = ({ onBack }) => (
  <>
    <>
      <header className={style.header}>
        <BackButton onBack={onBack} />
        <div className={style.left}>
          <img className={style.img} src={ExecutorsImg} alt="" />
        </div>
        <div>
          <h2>Проектировщику</h2>
          <p>Найти проект и разработать его разделы</p>
        </div>
      </header>
      <main className={style.items}>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor7} alt="" />
          </div>
          <div className={style.right}>
            <h5>Найти разделы проекта</h5>
            <p>
              Найти интересный проект в интересующем регионе по своей
              специализации. Подать заявку ГИП или Заказчику на выполнение
              раздела проекта.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor8} alt="" />
          </div>
          <div className={style.right}>
            <h5>Стать проверенным исполнителем</h5>
            <p>
              Исполнители, которые прошли проверку Платформы, завершили работы и
              получили положительные отзывы, средний бал которых более 4.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor4} alt="" />
          </div>
          <div className={style.right}>
            <h5>Разработка раздела</h5>
            <p>
              Вы можете разрабатывать разделы, выдавать задания в смежные
              подразделения, отслеживать критические отклонения на диаграмме
              Ганта по разделу.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor1} alt="" />
          </div>
          <div className={style.right}>
            <h5>Выбор материалов и оборудования</h5>
            <p>
              Запросы поставщикам и производителями материалов и оборудования по
              специализации. Сравнение коммерческих и технических предложений от
              поставщиков и производителей.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor3} alt="" />
          </div>
          <div className={style.right}>
            <h5>Работа с договорами</h5>
            <p>
              Заключение договора с Заказчиком работ по выполняемым разделам по
              Безопасной сделке по шаблону и подписание его простой электронной
              подписью.
            </p>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.left}>
            <img className={style.img} src={Decor6} alt="" />
          </div>
          <div className={style.right}>
            <h5>Облачное хранение документации</h5>
            <p>
              Хранение разработанной технической документации на облачном
              хранилище. Доступ из любой точки мира.
            </p>
          </div>
        </div>
      </main>
    </>
  </>
);
export default Executor;
