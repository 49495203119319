import { Controller } from "react-hook-form";
import moment from "moment";
import cn from "classnames";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import SelectMultipleLight from "src/FSD/shared/uiKit/v2/SelectMultiple/SelectMultipleLight";
import styles from "./Filter.module.scss";
import { useSearchFilter } from "../lib/hooks";
import CustomButton from "../../../../../../components/newUI/CustomButton/CustomButton";
import { useTimer } from "../lib/hooks/useTimeout";
import { SpecializationMultipleLight } from "../../../../../../components/features/SpecialForm/SpecializationMultipleLight";

export const Filter = () => {
  const {
    control,
    setValue,
    filterPending,
    selectOptions,
    trigger,
    errors,
    handleClear,
    hasFirm,
    datePlaceholders,
    submitKanbanFilter,
  } = useSearchFilter();

  useTimer();

  return (
    <form className={styles.filter}>
      <div className={styles.filter_left}>
        <Controller
          name="project"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
                setValue("executor", []);
              }}
              placeholder="Все проекты"
              isLoading={filterPending.getProjects}
              label="Проект"
              options={selectOptions.projects}
            />
          )}
        />
      </div>
      <div
        className={cn(
          styles.filter_right,
          hasFirm && styles.filter_right_hasFirm
        )}
      >
        <div className={styles.filter_right__dates}>
          <Controller
            name="dateStart"
            control={control}
            render={({ field: { value, onChange } }) => {
              const dateValue = value ? moment(value).utc().toDate() : null;

              return (
                <DateField
                  label="От даты"
                  changeDateHandler={(date) => {
                    onChange(date);
                    trigger("dateLimit");
                  }}
                  startDateProp={dateValue}
                  error={Boolean(errors.dateLimit)}
                  placeholderText={datePlaceholders.start}
                />
              );
            }}
          />
          <Controller
            name="dateLimit"
            control={control}
            render={({ field: { value, onChange } }) => {
              const dateValue = value ? moment(value).utc().toDate() : null;

              return (
                <DateField
                  label="До даты"
                  changeDateHandler={(date) => {
                    onChange(date);
                    trigger("dateLimit");
                  }}
                  startDateProp={dateValue}
                  error={Boolean(errors.dateLimit)}
                  placeholderText={datePlaceholders.end}
                />
              );
            }}
          />
        </div>
        <Controller
          name="workFlow"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectMultipleLight
              value={value}
              changeHandler={(newValue) => {
                onChange(newValue);
              }}
              onBlur={() => {
                submitKanbanFilter();
              }}
              placeholder="Все типы задач"
              isLoading={filterPending.getWfList}
              label="Тип задачи"
              options={selectOptions.workFlows}
            />
          )}
        />
        <Controller
          name="partGroup"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SpecializationMultipleLight
              value={value}
              changeHandler={(newValue) => {
                onChange(newValue);
                setValue("executor", []);
              }}
              onBlur={() => {
                submitKanbanFilter();
              }}
              label="Специализация"
              placeholder="Все специализации"
            />
          )}
        />
        {hasFirm && (
          <Controller
            name="group"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <SelectMultipleLight
                  value={value}
                  changeHandler={(newValue) => {
                    onChange(newValue);
                    setValue("executor", []);
                  }}
                  isLoading={filterPending.getGroups}
                  onBlur={() => {
                    submitKanbanFilter();
                  }}
                  placeholder="Все группы"
                  label="Группа"
                  options={selectOptions.groups}
                  optionsClassName={styles.optionsClassName}
                />
              );
            }}
          />
        )}
        <Controller
          name="executor"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <SelectMultipleLight
                value={value}
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                onBlur={() => {
                  submitKanbanFilter();
                }}
                isLoading={filterPending.getUsersList}
                label="Исполнитель"
                options={selectOptions.users}
                placeholder="Все исполнители"
              />
            );
          }}
        />
        <div className={styles.filter__controls}>
          <CustomButton
            onClick={handleClear}
            className={styles.filter__controls__button}
          >
            Сбросить
          </CustomButton>
        </div>
      </div>
    </form>
  );
};
