import React from "react";
import classNames from "classnames";
import { UseFormRegister } from "react-hook-form";
import { TSourceDataUpdateForm } from "src/FSD/shared/uiKit/v2/SourceDataUpdateForm/model/types";
import { useSourceDataNameEdit } from "../lib/hooks";
import styles from "./SourceDataUpdateForm.module.scss";

type TSourceDataNameProps = {
  id: number;
  name: string;
  register: UseFormRegister<TSourceDataUpdateForm.TInputs>;
  handleDeleteSourceDataName: (id: number) => void;
};

export const SourceDataNameEdit = (props: TSourceDataNameProps) => {
  const { id, name, register, handleDeleteSourceDataName } = props;
  const { edit, toggleEdit, disabled } = useSourceDataNameEdit();

  return (
    <div className={styles.sourceDataName}>
      {edit ? (
        <input
          {...register(`names.${id}`)}
          className={classNames(
            styles.sourceDataName__name,
            edit && styles.sourceDataName__name_edit
          )}
          defaultValue={name}
        />
      ) : (
        <div className={styles.sourceDataName__text}>{name}</div>
      )}

      <div className={styles.sourceDataName__control}>
        <span
          onClick={() => handleDeleteSourceDataName(id)}
          className={classNames(
            styles.sourceDataName__button,
            disabled && styles.sourceDataName__button_disabled
          )}
        >
          удалить
        </span>
        {" /"}
        <span onClick={toggleEdit} className={styles.sourceDataName__button}>
          заменить
        </span>
      </div>
    </div>
  );
};
