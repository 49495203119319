import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProcessWorkResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  workId: number;
}>;

export const dataRemoveExecutor = (builder: QueryBuilder) =>
  builder.query<ApiProcessWorkResponses.DataRemoveExecutor, TParams>({
    query: ({ workId }) => ({
      url: `v1/process-work/data-remove-executor`,
      params: {
        workId,
      },
    }),
    providesTags: [],
  });
