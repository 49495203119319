import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessGetStopped } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";
import { GetStoppedType } from "../types/projectProcessStoppedTypes";

export const fetchProjectProcessStopped = createAsyncThunk<
  GetStoppedType[],
  number
>(
  "projectProcess/fetchProjectProcessStopped",
  async (processId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessGetStopped(processId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
