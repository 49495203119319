import { RootState } from "../../../../store";

export const getProjectProcessMaterialIsLoad = (state: RootState) =>
  state.projectProcessView.material.isLoad;

export const getProjectProcessMaterialOfferBackIsload = (state: RootState) =>
  state.projectProcessView.material.isLoadOfferBack;

export const getProjectProcessMaterialOfferSelectIsLoad = (state: RootState) =>
  state.projectProcessView.material.isLoadOfferSelect;

export const getProjectProcessMaterialDeleteIsLoad = (state: RootState) =>
  state.projectProcessView.material.isLoadDeleteMaterial;
