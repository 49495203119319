import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";

export type TextHeadingProps = ComponentPropsType<{
  children: string;
  variant?: string;
}>;

export const TextHeading: React.FC<TextHeadingProps> = React.memo(
  ({ variant = "", children }) => {
    return (
      <p className={classNames(style.textHeading, variant)}>
        {ReactHtmlParser(children)}
      </p>
    );
  }
);
