import { useProjectProcessRemovedQuery } from "src/FSD/entities/projectProcess/api";

export const useRemoved = (projectProcessId: number) => {
  const { data: removedExecutors, isFetching } = useProjectProcessRemovedQuery({
    id: projectProcessId,
    expand: ["executorUserType", "can"],
  });

  const isExecutorsLoading = !removedExecutors || isFetching;

  return { removedExecutors, isExecutorsLoading } as const;
};
