import {
  apiNavigationItemsType,
  apiNavigationItemType,
} from "../../../types/api/responseTypes/apiNavigation";
import {
  navigationsType,
  navigationType,
} from "../../../types/stateTypes/navigation";

export const getNavigation = (item: apiNavigationItemType): navigationType => {
  return {
    info: {
      label: item.label,
      alt: item.label,
      icon: {
        type: item.icon || "",
      },
      image: {
        src: item.image || "",
      },
      counter: item.counter || undefined,
    },
    items:
      item.items && item.items.length > 0
        ? item.items.map((child) => getNavigation(child))
        : [],
    url: item.url || "",
  };
};
export const createItems = (items: apiNavigationItemsType): navigationsType => {
  return items.map((item) => getNavigation(item));
};
