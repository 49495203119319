import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchProjectProcessViewDuplicate } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getProjectProcessInfoId } from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessStage } from "../../../../app/feature/ProjectProcessView/MileStone/services/fetchProjectProcessStage";
import {
  getProjectProcessMilestoneAvailablePercent,
  getProjectProcessMileStoneIsLoad,
  getProjectProcessMilestoneStages,
} from "../../../../app/feature/ProjectProcessView/MileStone/selectors/getProjectProcessMileStone";
import cls from "./MileStone.module.scss";
import {
  MILESTONE_TYPE,
  MileStoneCard,
} from "../../../newUI/MileStoneCard/MileStoneCard";
import { MileStoneSkeleton } from "../../../UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";

export const MileStone = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const infoId = useAppSelector(getProjectProcessInfoId);
  const isLoadMileStone = useAppSelector(getProjectProcessMileStoneIsLoad);
  const stages = useAppSelector(getProjectProcessMilestoneStages);
  const availablePercent = useAppSelector(
    getProjectProcessMilestoneAvailablePercent
  );

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessStage(infoId));
    }
  }, [infoId]);

  if (isLoadMileStone) {
    return <MileStoneSkeleton />;
  }

  return (
    <div className={cls.mileStone}>
      {availablePercent && (
        <div className={cls.mileStone_price}>
          <div className={cls.mileStone_price_block}>
            <p>Доступно</p>
            <span>{availablePercent} ₽</span>
          </div>
        </div>
      )}
      <MileStoneCard stages={stages} type={MILESTONE_TYPE.PROCESS} />
    </div>
  );
});
