import moment from "moment/moment";

export function calculateOverdue(date: string) {
  const currentDate = moment.utc();
  const endDate = moment.utc(date, "DD.MM.YYYY");
  const diffDays = Math.ceil(currentDate.diff(endDate, "days"));
  return diffDays > 0 ? diffDays : 0;
}
export function daysBetweenDates(date1: string, date2: string) {
  const format = "DD.MM.YYYY";
  const a = moment.utc(date1, format);
  const b = moment.utc(date2, format);
  const diff = b.diff(a, "days");
  return diff;
}

export function daysBetweenTodayAndDate(date: string) {
  const format = "DD.MM.YYYY";
  const today = moment.utc();
  const a = moment.utc(today, format);
  const b = moment.utc(date, format);
  const diff = a.diff(b, "days");
  return diff;
}
