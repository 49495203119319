import React, { memo, useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import {
  fetchCompleteTask,
  fetchProjectProcessType,
  fetchProjectTaskOutTree,
} from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import styles from "./ModalComplete.module.scss";
import { newMenuItemSx, textFieldSX } from "../../../../../../styles/restyle";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "../../../../../../app.cofig";
import { getProjectTasks } from "../../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import { fetchProjectProcessOutTasks } from "../../../../../../app/feature/ProjectProcessView/Tasks/services/fetchProjectProcessTasks";
import { setCheckInput } from "../../../../../../app/feature/ProjectView/projectView";
import { VersionTaskType } from "../../../../../../app/feature/ProjectView/TaskReducer/types";
import { NewCheckbox } from "../../../../../newUI/NewCheckbox/NewCheckbox";

function cutStringFromEnd(str: string) {
  const lastIndex = str.lastIndexOf(" ");
  if (lastIndex === -1) {
    return str;
  }
  return str.slice(lastIndex + 1);
}

interface IModalCompleteProps {
  task: VersionTaskType;
  setCompleteModalCloseMemo: () => void;
  projectId?: number;
  projectProcessID?: number;
  type?: "project" | "process";
}

type TInputs = {
  tasks: string[];
  comment: string;
  files: any;
};

export const ModalComplete = memo(
  ({
    task,
    setCompleteModalCloseMemo,
    projectId,
    projectProcessID,
    type = "project",
  }: IModalCompleteProps) => {
    const [check, setCheck] = useState(false);
    const taskId = task.id;
    const code = cutStringFromEnd(task.title);
    const { processType, isLoadProcessType } = useSelector(getProjectTasks);
    const dispatch = useDispatch();

    const [valueTask, setValueTask] = useState<string[]>([]);

    function setDefaultValueTask() {
      if (processType) {
        const defaultCode = Object.entries(processType).find(
          ([id, type]) => type === code
        );
        setValueTask(defaultCode ? [defaultCode[0]] : []);
      }
    }

    useEffect(() => {
      setDefaultValueTask();
    }, [processType]);

    useEffect(() => {
      if (projectId && projectProcessID) {
        dispatch(
          fetchProjectProcessType({ projectId, processId: +projectProcessID })
        );
      }
    }, []);

    useEffect(() => {
      dispatch(setCheckInput(true));

      return () => {
        dispatch(setCheckInput(false));
      };
    }, []);

    const schema = Yup.object().shape({
      files: task.haveFiles
        ? Yup.array().notRequired()
        : Yup.array().required(
            "Выберите нужные файлы. Дождитесь окончания загрузки!"
          ),
      comment: Yup.string()
        .required("Необходимо заполнить комментарий")
        .min(15, "Комментарий должен содержать не меньше 15 символов")
        .max(200, "Комментарий не может содержать больше 200 символов"),
    });

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<TInputs>({
      defaultValues: {
        comment: task.comment,
      },
      resolver: yupResolver(schema),
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        status: 6,
        comment: dataValues.comment,
        upload_files: dataValues.files,
        forAll: check,
        toProcessesIds: dataValues.tasks,
      };
      await dispatch(fetchCompleteTask({ id: taskId, data }));
      if (projectId && type === "project") {
        await dispatch(fetchProjectTaskOutTree(projectId));
        setCompleteModalCloseMemo();
      }
      if (projectProcessID && type === "process") {
        await dispatch(fetchProjectProcessOutTasks(projectProcessID));
        setCompleteModalCloseMemo();
      }
    };

    const mapProjectProcessTypeList =
      Object.entries(processType).map(([id, type]) => (
        <MenuItem key={id} sx={newMenuItemSx} value={id}>
          {type}
        </MenuItem>
      )) || null;

    const handleChange = (event: SelectChangeEvent<typeof valueTask>) => {
      const {
        target: { value },
      } = event;
      setValueTask(typeof value === "string" ? value.split(",") : value);
    };

    return (
      <form className={styles.form} onSubmit={handleSubmit(formSubmitHandler)}>
        <FormControl {...textFieldSX} className={styles.form_tasks}>
          <p className={styles.formTitle}>Задания для разделов</p>
          <Select
            {...register("tasks")}
            multiple
            value={valueTask}
            onChange={handleChange}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: "#1A2734",
                    padding: "0 20px",
                  },
                },
              },
            }}
          >
            {mapProjectProcessTypeList}
          </Select>
        </FormControl>
        <label className={styles.form_label}>
          Выдавать всем новым смежникам
          <NewCheckbox
            editing
            onChange={() => setCheck(!check)}
            checked={check}
          />
        </label>
        <div className={styles.form_uploader}>
          <Uploader
            canBeEdited
            autoUpload={!!task}
            identifier={task ? taskId.toString() : null}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              setValue("files", data);
            }}
            uploadAsActive
            background={""}
            newDesign
          />
          <p className={styles.error}>{errors?.files?.message}</p>
        </div>
        <div className={styles.form_comment}>
          <p className={styles.formTitle}>Комментарий</p>
          <TextField
            {...register("comment")}
            defaultValue={task.comment}
            className={styles.form_tasks}
          />
          {errors.comment ? (
            <p className={styles.error}>{errors.comment.message}</p>
          ) : null}
        </div>
        <FormControl className={styles.form_submit}>
          <Button type="submit">Отправить</Button>
        </FormControl>
      </form>
    );
  }
);
