import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import cn from "classnames";
import {
  ChatLeftText,
  Download,
  FileEarmarkArrowDown,
} from "react-bootstrap-icons";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useIsMobile } from "src/FSD/shared/lib/hooks/useIsMobile";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./ExecutionTask.module.scss";
import { useAppDispatch } from "../../../../../../app/store";
import { TaskModel } from "../../../../../entities/task";

type Props = {
  taskId: number;
  taskName: string;
  userName: string;
  filesCount: number;
  dates?: ReactNode;
  media?: ReactNode;
  actions?: ReactNode;
};

export const ExecutionTask = ({
  taskId,
  taskName,
  userName,
  filesCount,
  dates,
  media,
  actions,
}: Props) => {
  const isMobile = useIsMobile(1200);

  const dispatch = useAppDispatch();

  const handleShowComments = () => {
    dispatch(
      TaskModel.modals.setCommentsActive({
        isOpen: true,
        taskId,
      })
    );
  };

  if (isMobile) {
    return (
      <Card className={styles.mobileCard}>
        <div className={styles.upper}>
          <div className={styles.upper__titles}>
            <h2>{taskName}</h2>
            <p>{userName}</p>
          </div>
          <div>{dates}</div>
        </div>
        <div className={styles.lower}>
          <div className={styles.lower__buttons}>
            {filesCount ? (
              <Link
                className={cn(styles.mobileButton, styles.mobileButton_files)}
                to={`${config.localDomain}/task/download-files?id=${taskId}`}
                target="_blank"
              >
                <IconButton>
                  <p>
                    <FileEarmarkArrowDown />
                    <span>{filesCount}</span>
                  </p>
                </IconButton>
              </Link>
            ) : (
              <IconButton
                disabled
                className={cn(styles.mobileButton, styles.mobileButton_files)}
              >
                <p>
                  <FileEarmarkArrowDown />
                  <span>{filesCount}</span>
                </p>
              </IconButton>
            )}
            <IconButton
              className={styles.mobileButton}
              onClick={handleShowComments}
            >
              <ChatLeftText />
            </IconButton>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className={styles.upper}>
        <div className={styles.upper__titles}>
          <h2>{taskName}</h2>
          <p>{userName}</p>
        </div>
        <div>{dates}</div>
        {actions}
      </div>
      <div className={styles.lower}>
        <div className={styles.lower__buttons}>
          {filesCount ? (
            <Link
              to={`${config.localDomain}/task/download-files?id=${taskId}`}
              target="_blank"
            >
              <Button
                variant="outlined"
                color="secondary"
                borderRadius={20}
                endIcon={<Download />}
              >
                Вложение ({filesCount})
              </Button>
            </Link>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              disabled
              borderRadius={20}
              endIcon={<Download />}
            >
              Вложение (0)
            </Button>
          )}
          <Button
            onClick={handleShowComments}
            variant="outlined"
            color="success"
            borderRadius={20}
          >
            Комментарии
          </Button>
        </div>
        <div>{media}</div>
      </div>
    </Card>
  );
};
