import React, { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoData } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessFeedbackExecutor } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessFeedbackExecutor";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { FeedBackForm } from "../../../../newUI/FeedBackForm/FeedBackForm";

interface IModalFeedbackRemoveExecutorProps {
  modalClose: () => void;
  executor: any;
}

export const ModalFeedbackRemoveExecutor: React.FC<IModalFeedbackRemoveExecutorProps> =
  memo(({ modalClose, executor }) => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const infoData = useAppSelector(getProjectProcessInfoData);

    const formSubmitHandler = useCallback(
      (dataValues) => {
        const submitData = async () => {
          const data = {
            quality: dataValues.quality,
            reliability: dataValues.reliability,
            loyalty: dataValues.loyalty,
            comment: dataValues.comment,
            type: 1,
          };
          if (infoData && executor) {
            await dispatch(
              fetchProjectProcessFeedbackExecutor({
                taskId: executor.id,
                userId: executor.executorId,
                data,
              })
            );
          }
          if (params.id) {
            await dispatch(fetchProjectProcessViewDuplicate(+params.id));
          }
          modalClose();
        };

        submitData();
      },
      [fetchProjectProcessFeedbackExecutor]
    );

    if (executor) {
      return (
        <FeedBackForm formSubmitHandler={formSubmitHandler} data={[executor]} />
      );
    }
    return <></>;
  });
