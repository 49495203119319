import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = RequestParams<{
  processId: number;
}>;

export const removeProcessExecutor = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ processId }) => ({
      url: `v1/project-access/remove-process-executor`,
      params: {
        processId,
      },
      method: HTTPMethods.POST,
    }),
  });
