import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { ApiProjectProcessDeposit } from "../../../../services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";

export const fetchProjectProcessDeposit = createAsyncThunk<
  boolean,
  { id: number; data: { value: number } }
>(
  "projectProcess/fetchProjectProcessDeposit",
  async ({ id, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessDeposit(id, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
