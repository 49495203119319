import { ReactNode } from "react";
import styles from "./Modal.module.scss";

type Props = {
  children: ReactNode;
};

const ModalHeader = ({ children }: Props) => {
  return <h2 className={styles.modal__header}>{children}</h2>;
};

export default ModalHeader;
