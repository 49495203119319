import { createReducer, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { gipPageInitialState } from "./gipPageIninialState";
import { createGipListFromApi } from "./createData/createData";
import C from "./constants";
import { isBadStatusRequest } from "../../../../services/api/api";
import { createAppError } from "../../../appError/appError";
import { StateType } from "../../../../initialState";
import { ApiGetGipList } from "../../../../services/api/indexPages/gipPage";
import {
  ApiGipPageDataType,
  ApiUserIndexPageDataType,
} from "../../../../../types/api/responseTypes/indexPages/apiIndexPageType";

export const gipPageReducer = createReducer(gipPageInitialState, (builder) => {
  /*
    Установка значений на страницу гипов
  */

  builder.addCase(
    C.SET_GIP_PAGE_DATA,
    (state, action: PayloadAction<ApiGipPageDataType>) => {
      return {
        ...state,
        users: createGipListFromApi(action.payload.data),
        meta: action.payload.meta,
      };
    }
  );
});

export const paginationMeta = (res: Response) => ({
  paginationPageCount: Number(res.headers.get("x-pagination-page-count")),
  paginationPerPage: Number(res.headers.get("x-pagination-per-page")),
  paginationAllPageCount: Number(res.headers.get("x-pagination-total-count")),
  paginationCurrentPage: Number(res.headers.get("x-pagination-current-page")),
});
// todo -> проверить на использование
export const fetchGipList: (
  urlParamsString?: string
) => (dispatch: Dispatch) => void =
  (urlParamsString = "") =>
  (dispatch) => {
    ApiGetGipList(urlParamsString)
      .then(async (res) => {
        if (isBadStatusRequest(res.status)) {
          const err = await res.json();
          dispatch(createAppError(err.message));
        }
        return res;
      })
      .then(async (res) => {
        const meta = paginationMeta(res);
        const data = await res.json();
        return { meta, data };
      })
      .then((res) =>
        dispatch({
          type: C.SET_GIP_PAGE_DATA,
          payload: {
            data: res.data,
            meta: res.meta,
          },
        })
      );
  };

export const selectorGipList = (state: StateType) => state.gipPage.users;

export const selectorGipMeta = (state: StateType) => state.gipPage.meta;

export const setGips = (
  data: ApiGipPageDataType,
  meta: ApiUserIndexPageDataType["meta"]
) => ({
  type: C.SET_GIP_PAGE_DATA,
  payload: {
    data,
    meta,
  },
});
