import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { request } from "../../../app/services/api/requestHandler";
import { SelectWithPopper } from "../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";
import { ApiGetTypesOfExpertise } from "../../../app/services/api/typesOfExpertise/typesOfExpertise";

export type ExpertiseTypeItem = {
  title: string;
  id: string | number;
};

export type ExpertiseTypeProps = ComponentPropsType<{
  changeHandler?: (type: ExpertiseTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
  paperOptions?: { [key: string]: any };
  disabled?: boolean;
  prompt?: string;
  options?: ExpertiseTypeItem[];
}>;

const noExpertise = { title: "не выбран", id: "", num: "" };

export const ExpertiseType: React.FC<ExpertiseTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  value,
  paperOptions,
  disabled,
  prompt,
  options = [],
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<ExpertiseTypeItem[]>(options);
  const [selectedValue, setSelectValue] = useState<
    ExpertiseTypeItem | undefined
  >(undefined);

  const onLoadTypes = () => {
    request(
      ApiGetTypesOfExpertise(),
      (data) => {
        setAllTypes([
          noExpertise,
          ...data.map((item: { id: number; name: string; num: string }) => ({
            title: item.name,
            id: item.id,
            num: item.num,
          })),
        ]);
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов экспертизы", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    if (!options.length) {
      onLoadTypes();
    }
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => item.id === value);
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  return (
    <SelectWithPopper
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue?.id ? selectedValue : noExpertise}
      searchHandler={onSearch}
      options={allTypes}
      label={"Тип экспертизы"}
      variant={classNames(variant)}
      disableClear={true}
      fullWidth={true}
      paperOptions={paperOptions}
      disabled={disabled}
      prompt={prompt}
    />
  );
};
