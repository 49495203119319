import React from "react";
import { Route, Routes } from "react-router-dom";
import { IndexPageTemplate } from "../components/UI/templates/IndexPagesTemplate/IndexPagesTemplate";
import { OrganisationPage } from "../components/pages/Organisation/OrganisationPage";
import OrganisationCreation from "../components/pages/Organisation/OrganisationCreation";

export const FirmRoutes = () => (
  <Routes>
    <Route path="/firm">
      <Route
        path="create"
        element={
          <IndexPageTemplate>
            <OrganisationCreation />
          </IndexPageTemplate>
        }
      />
      <Route
        path=":id"
        element={
          <IndexPageTemplate>
            <OrganisationPage />
          </IndexPageTemplate>
        }
      />
    </Route>
  </Routes>
);
