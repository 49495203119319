import { createTheme } from "@mui/material/styles";
import { UserConfigColorThemeType } from "../../types/stateTypes/userType";

export const getThemeProviderConfig = (theme: UserConfigColorThemeType) => {
  if (theme === "dark") {
    return createTheme({
      palette: {
        primary: {
          light: "#30ab69",
          main: "#30ab69",
          dark: "#30ab69",
          contrastText: "#b5b5b5",
        },
        text: {
          secondary: "white",
          disabled: "#b5b5b5",
          primary: "#b5b5b5",
        },
      },
      typography: {
        fontFamily: "Montserrat",
      },
    });
  }

  // OLD
  return createTheme({
    palette: {
      primary: {
        light: "#3B93AF",
        main: "#3B93AF",
        dark: "#3B93AF",
        contrastText: "#9dafbd",
      },
      text: {
        secondary: "#9dafbd",
        disabled: "#ccc",
        primary: "#9dafbd",
      },
    },
  });
};
