import { FC } from "react";
import style from "./FullAdditionalInfo/style.module.scss";

type BackButtonProps = {
  onBack: () => void;
};

const BackButton: FC<BackButtonProps> = ({ onBack }) => (
  <button className={style.back} title="Назад" onClick={onBack}>
    <svg viewBox="0 0 18 18" width="50" height="50">
      <path
        d="M13.03 8.99991C13.03 9.31633 12.7949 9.57782 12.4898 9.61921L12.405 9.62491H7.10903L9.17244 11.6804C9.3948 11.9018 9.41571 12.249 9.23468 12.494L9.17432 12.5642C8.9529 12.7866 8.60573 12.8075 8.36066 12.6265L8.29044 12.5661L5.15377 9.44279C4.93074 9.22071 4.91046 8.87227 5.09294 8.62722L5.09519 8.62462C5.11265 8.60141 5.13169 8.57945 5.15215 8.5589L5.15377 8.55703L8.29044 5.4337C8.53503 5.19014 8.93076 5.19098 9.17432 5.43558C9.39573 5.65794 9.41517 6.0052 9.2331 6.24949L9.17244 6.31946L7.10787 8.37491H12.405C12.7502 8.37491 13.03 8.65473 13.03 8.99991Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3334 5.38825V12.6124C17.3334 15.4488 15.3089 17.3332 12.4034 17.3332H5.59669C2.69415 17.3332 0.666687 15.4432 0.666687 12.6124V5.38825C0.666687 2.55176 2.69127 0.66658 5.59669 0.66658H12.4034C15.3088 0.66658 17.3334 2.55176 17.3334 5.38825ZM16.0834 12.6124V5.38825C16.0834 3.26283 14.6376 1.91658 12.4034 1.91658H5.59669C3.36249 1.91658 1.91669 3.26283 1.91669 5.38825V12.6124C1.91669 14.7324 3.36567 16.0832 5.59669 16.0832H12.4034C14.6378 16.0832 16.0834 14.7376 16.0834 12.6124Z"
        fill="currentColor"
      />
    </svg>
  </button>
);

export default BackButton;
