import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import styles from "./Info.module.scss";
import { Payment } from "./Payment/Payment";
import { Requirements } from "./Requirements/Requirements";
import { Executor } from "./Executor/Executor";
import { Description } from "./Description/Description";
import { Deadlines } from "./Deadlines/Deadlines";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Info = ({ processWork }: Props) => {
  return (
    <div className={styles.grid}>
      <Description processWork={processWork} />
      <Deadlines processWork={processWork} />
      <Payment processWork={processWork} />
      <Executor processWork={processWork} />
      <Requirements processWork={processWork} />
    </div>
  );
};
